import { FormControlLabel, Checkbox as MuiCheckbox } from "@mui/material";
import React from "react";
import { Control, Controller } from "react-hook-form";
import styled from "styled-components";

type Props = {
  name: string;
  control: Control;
  label: string;
  callback?: () => void;
  disabled?: boolean;
  checked?: boolean;
};

const Label = styled.span`
  color: var(--Gray-700, #344054);

  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  margin-left: 4px;
`;

const StyledCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 11px;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`;

const StyledCheckbox = styled.span`
  width: 16px;
  height: 16px;
  padding: 2px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: inline-block;
  position: relative;
  transition: all 150ms;
  margin-right: 8px;

  ${HiddenCheckbox}:checked + & {
    background: var(--Primary-50, #F5F8FF);
    border-color: ${(props) => props.theme.color.primary};
  }

  &::after {
    content: "";
    position: absolute;
    display: none;
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid ${(props) => props.theme.color.primary};;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  ${HiddenCheckbox}:checked + &::after {
    display: block;
  }
`;

const ZCheckbox = ({
  name,
  control,
  label,
  checked,
  callback = () => {},
  disabled = false,
}: Props) => {
  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field: props }) => (
            <StyledCheckboxContainer>
              <HiddenCheckbox
                {...props}
                disabled={disabled}
                checked={checked !== undefined ? checked : props.value}
                onChange={(e) => {
                  props.onChange(e.target.checked);
                  callback();
                }}
              />
              <StyledCheckbox />
            </StyledCheckboxContainer>
          )}
        />
      }
      label={<Label>{label}</Label>}
    />
  );
};

export default ZCheckbox;