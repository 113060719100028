import { useState } from "react";
import { AutomationPlaygroundWrapper } from "views/automationBuilder/automationBuilder.styles";
import useAutomation from "./hooks/useAutomation";
import { IActionBlock } from "./types/ABType";
import { useDispatch } from "react-redux";
import {
  rearrangeActionBlockInAutomation,
  removeActionBlockInAutomation,
} from "views/automationBuilder/reducer/automationReducer";
import ActionBlock from "views/automationBuilder/components/ActionBlock";
import TriggerBlock from "./TriggerBlock";
import DeletionConfirmationDialog from "utils/DeletionConfirmationDialog";
import {
  DELETE_ACTION_BLOCK,
  DELETE_ACTION_BLOCK_CONFIRMATION,
} from "constants/CommonMessages";
import SplitDivider from "./components/SplitDivider";

const AutomationPlayground = () => {
  const [confirmActionBlockDelete, setConfirmActionBlockDelete] =
    useState<string>("");
  const { selectedAutomation, selectedActionBlock, otherConfigs } = useAutomation();
  const dispatch = useDispatch();

  const hideActionBlock = otherConfigs?.hideActionBlock;
  return (
    <>
      <AutomationPlaygroundWrapper>

        <div className="" style={{ marginTop: '4rem' }}>
          {selectedAutomation && <TriggerBlock />}
        </div>

        {
          (selectedAutomation && !hideActionBlock) &&
          <SplitDivider showText textTitle={'Then Do This'} />
        }

        {!hideActionBlock && selectedAutomation?.actionBlocks?.map(
          (actionBlock: IActionBlock, index: number) => (
            <ActionBlock
              rowIndex={index}
              listLength={selectedAutomation?.actionBlocks.length}
              key={actionBlock.actionBlockId}
              showDeleteActionBlockBtn={
                index !== 0 || selectedAutomation?.actionBlocks.length > 1
              }
              showAddActionBlockBtn={
                selectedAutomation?.actionBlocks.length - 1 == index
              }
              rearrangeDown={() => {
                dispatch(rearrangeActionBlockInAutomation({
                  automationId: selectedAutomation.id,
                  actionBlockId: actionBlock.actionBlockId,
                  index: index,
                  step: 1
                }))
              }}
              rearrangeUp={() => {
                dispatch(rearrangeActionBlockInAutomation({
                  automationId: selectedAutomation.id,
                  actionBlockId: actionBlock.actionBlockId,
                  index: index,
                  step: -1
                }))
              }}
              onDelete={(actionBlockId: string) => {
                setConfirmActionBlockDelete(actionBlockId);
              }}
              actionBlock={actionBlock}
            />
          )
        )}
      </AutomationPlaygroundWrapper>

      <DeletionConfirmationDialog
        id={"actionBlockDelete"}
        deletionTitle={DELETE_ACTION_BLOCK}
        deletionText={DELETE_ACTION_BLOCK_CONFIRMATION}
        isOpen={confirmActionBlockDelete ? true : false}
        onConfirmDelete={() => {
          setConfirmActionBlockDelete("");
          dispatch(
            removeActionBlockInAutomation({
              actionBlockId: confirmActionBlockDelete,
            })
          );
        }}
        onClose={() => {
          setConfirmActionBlockDelete("");
        }}
      />
    </>
  );
};

export default AutomationPlayground;
