import React from "react";

type Props = {};

const ZMap = ({
    color,
    width="16",
    height="16"
  }: {
    color?: string;
    width?: string;
    height?: string;
  }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.16675 11.9066C2.62395 12.5872 1.66675 13.5356 1.66675 14.5846C1.66675 16.6557 5.39771 18.3346 10.0001 18.3346C14.6025 18.3346 18.3334 16.6557 18.3334 14.5846C18.3334 13.5356 17.3762 12.5872 15.8334 11.9066M15.0001 6.66797C15.0001 10.0544 11.2501 11.668 10.0001 14.168C8.75008 11.668 5.00008 10.0544 5.00008 6.66797C5.00008 3.90654 7.23866 1.66797 10.0001 1.66797C12.7615 1.66797 15.0001 3.90654 15.0001 6.66797ZM10.8334 6.66797C10.8334 7.12821 10.4603 7.5013 10.0001 7.5013C9.53984 7.5013 9.16675 7.12821 9.16675 6.66797C9.16675 6.20773 9.53984 5.83464 10.0001 5.83464C10.4603 5.83464 10.8334 6.20773 10.8334 6.66797Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ZMap;