import { Backdrop, Modal } from "@mui/material"
import ImageSlider from "components/imageviewer/ImageSlider"
import React, { useState } from "react"
import styled from "styled-components"
import {
  checkIfUrlIsOfImage,
  device,
  getFileExtension,
} from "utils/CommonUtils"
import { FiDownload, FiTrash2 } from "react-icons/fi"
import { FileIcon, defaultStyles } from "react-file-icon"
import { update } from "lodash"
import useAccessPermissions from "hooks/useAccessPermissions"
import { ResourceTypeConstant } from "views/entities/permissions/types"

type Props = {
  linksArray: { url: string; title: string }[]
  deleteFunc?: Function
  uploadComponent?: React.ReactNode
}

export const ImageModal = styled(Modal)`
  height: 73%;
  margin: 0 auto;
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.8) !important;
  }
  @media ${device.mobileL} {
    height: 72%;
  }
`

const Strip = styled.div`
  position: absolute;
  right: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    border-radius: 4px;
  }
`

export const ImageBox = styled.div`
  position: relative;
  min-width: 25px;
  cursor: pointer;
`

const IconContainer = styled.div`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    rgba(194, 201, 209, 0.1);
  padding: 3px;
  border-radius: 50%;
`

const ZShowImage = ({ linksArray, deleteFunc, uploadComponent }: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [currIndex, setCurrIndex] = useState<number>(0)
  const [showStrip, setShowStrip] = useState<boolean>(false)

  const [hoverIndex, setShowHoverIndex] = useState<number>(-1)
  const accessPermissions = useAccessPermissions({resourceType: ResourceTypeConstant.TABLE});

  return (
    <>
      <div style={{ display: "flex",alignContent:"center", gap: "10px" }}>
        {linksArray.length === 0 ? (
          <>{uploadComponent}</>
        ) : (
          // show only first 3 images
          <>
            {linksArray
              .slice(0, 3)
              .map((val: { url: string; title: string }, idx: number) => (
                <ImageBox
                  onMouseEnter={() => setShowHoverIndex(idx)}
                  onMouseLeave={() => setShowHoverIndex(-1)}
                  key={idx}
                >
                  <Strip
                    style={{
                      display:
                        hoverIndex >= 0 && hoverIndex === idx ? "" : "none",
                    }}
                  >
                    {/* <div>
                      <a
                      href={val.url}
                      download={`Image ${idx}`}
                      rel="noreferrer"
                      target="_blank"
                      >
                      <FiDownload />
                      </a>
                  </div> */}
                    {deleteFunc && accessPermissions.edit_record && (
                      <IconContainer>
                        <FiTrash2
                          color="white"
                          size={"8px"}
                          onClick={() => deleteFunc(idx)}
                        />
                      </IconContainer>
                    )}
                  </Strip>

                  {val.url &&
                    (checkIfUrlIsOfImage(val.url) ? (
                      <img
                        onClick={() => {
                          setCurrIndex(idx)
                          setShowModal(true)
                        }}
                        style={{
                          height: "25px",
                          borderRadius: "4px",
                          width: "25px",
                          objectFit: "fill",
                        }}
                        src={val.url}
                        alt={`${val.title}`}
                      />
                    ) : (
                      <div style={{ width: "25px" }}>
                        <a
                          href={val.url}
                          download="file"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FileIcon
                            extension={getFileExtension(val.url)}
                            {...defaultStyles.docx}
                          />
                        </a>
                      </div>
                    ))}
                </ImageBox>
              ))}
            {linksArray.length > 3 && (
              <ImageBox
                style={{
                  background: "#EAECF0",
                  borderRadius: "4px",
                  color: "#98A2B3",
                  textAlign: "center",
                  padding: "8px",
                  height: "45px",
                  width: "45px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  setCurrIndex(3)
                  setShowModal(true)
                }}
              >
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight:"500",
                    color: "#828282",
                    marginTop: "4px",
                    textAlign: "center",
                  }}
                >
                  + {linksArray?.length - 3} more
                </p>
              </ImageBox>
            )}
            {/* If uploaComponent is passed, show it */}
            {uploadComponent}
          </>
        )}
      </div>
      {/* <p onClick={() => setShowModal(true)}> Show uploaded images </p> */}
      <ImageModal
        BackdropComponent={Backdrop}
        BackdropProps={{
          color: "rgba(0,0,0,0.8)",
        }}
        open={showModal}
        onClose={() => setShowModal(false)}
        // aria-labelledby="modal-modal-title"
        // aria-describedby="modal-modal-description"
      >
        <ImageSlider
          //@ts-ignore
          onClose={() => setShowModal(false)}
          startIndex={currIndex}
          slides={linksArray}
        />
      </ImageModal>
    </>
  )
}

export default ZShowImage
