import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
};

export const LoadingStateSlice = createSlice({
  name: 'loading',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // setTaskType: (state, action) => {
    //     state.taskType = action.payload;
    // },
    setLoading: (state, action) => {
      state.loading = action.payload;
    }
  },
  extraReducers: (builder) => {},
});

export const { setLoading } = LoadingStateSlice.actions;

export default LoadingStateSlice.reducer;
