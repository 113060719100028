import { useState } from "react"
import { Input } from "../DataField.styles"
import {
  Control,
  Controller,
  UseFormGetValues,
  useFieldArray,
  useWatch,
} from "react-hook-form"
import ZCheckbox from "views/commonComponents/ZCheckbox"
import { ZButton } from "views/commonComponents/commonComponents.styles"
import ZSelect from "views/commonComponents/ZSelect"
import ColorList from "views/commonComponents/ColorList"
import { ZIconButton2 } from "views/entities/styles"
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai"
import { options } from "joi"
import { listOfValueColorPallet } from "utils/CommonUtils"
import ErrorMessage from "../ErrorMessage"
import ZInput from "views/commonComponents/ZTextInput"
import { TrashIcon } from "assests"

const ListOfValues = ({
  isEntity = false,
  control,
  getValues,
  errors,
}: {
  isEntity?: boolean
  control: Control
  getValues: UseFormGetValues<any>
  errors?: any
}) => {
  const allowColorCoding = useWatch({
    control,
    name: "allowColorCoding",
  })

  const listOfValueArr = useWatch({
    control,
    name: "listOfValueArray",
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: "listOfValueArray",
  })

  const [showPallete, setShowPallete] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState<number | any>(null as any)

  const getRandomColor = () => {
    const len = listOfValueColorPallet.length
    const randomIndex = Math.floor(Math.random() * len)
    return listOfValueColorPallet[randomIndex].primary
  }

  return (
    <>
      {isEntity ? (
        <div className="relative">
          <div
            style={{
              maxHeight: "168px",
              overflowY: "auto",
            }}
          >
            {fields.map((item, index) => (
              <div key={item.id} className="flex items-center gap-4 mb-1">
                {allowColorCoding && (
                  <div>
                    <ZIconButton2
                      onClick={() => {
                        setShowPallete((prev) => !prev)
                        setSelectedIndex(index)
                      }}
                      style={{ background: listOfValueArr?.[index]?.color }}
                    >
                      <AiOutlinePlus color={listOfValueArr?.[index]?.color} />
                    </ZIconButton2>
                  </div>
                )}
                <ZInput
                  placeholder="Enter Value"
                  name={`listOfValueArray.${index}.value`}
                  validations={{
                    required: "Required",
                  }}
                  register={control.register}
                  // defaultValue={item.value}
                />
                {errors && (
                  <ErrorMessage
                    errors={errors}
                    fieldName={`listOfValueArray.${index}.value`}
                  />
                )}

                <div>
                  <ZIconButton2 onClick={() => remove(index)}>
                    {TrashIcon}
                  </ZIconButton2>
                </div>
              </div>
            ))}
          </div>
          {showPallete && (
            <div className="mt-6">
              <Controller
                name={`listOfValueArray.${selectedIndex}.color`}
                control={control}
                render={({
                  field: { onChange: fieldOnChange, value, name, ref },
                }) => (
                  <ColorList
                    colorList={listOfValueColorPallet}
                    isSettings={false}
                    onSelect={(color, usePrimaryColor) => {
                      console.log("themeColorData", color, usePrimaryColor)

                      const currThemeColorData = {
                        primaryColor: color.primary,
                        appBarPrimaryColorActive: usePrimaryColor,
                      }
                      fieldOnChange(currThemeColorData.primaryColor)
                      setShowPallete(false)
                    }}
                    defaultUsePrimaryColor={true}
                    defaultColor={listOfValueArr?.[selectedIndex]?.color}
                  />
                )}
              />
            </div>
          )}
          {/* <ZButton
            variant="contained"
            
            fullWidth
            
          >
            Add Value
          </ZButton> */}
          <div
            onClick={() =>
              append({
                value: "",
                color: allowColorCoding ? getRandomColor() : undefined,
              })
            }
            style={{
              marginTop: "12px",
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            <AiOutlinePlus size={"1rem"} />
            <div>
              <p style={{ fontWeight: 500, color: "#475467" }}>
                {" "}
                Add an option
              </p>
            </div>
          </div>
          <div style={{ marginBottom: "10px" }}>
            <ZCheckbox
              name="allowColorCoding"
              control={control}
              label="Color-code options"
            />
          </div>
          <div>
            <ZCheckbox
              name="isMultiselect"
              control={control}
              label="Allow Multiselect"
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default ListOfValues
