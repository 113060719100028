import { Checkbox, FormControlLabel, Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BiErrorAlt } from "react-icons/bi";
import { Flex, IconWrapper } from "utils/DeletionConfirmationDialog";
import { Button } from "views/DataField/DataField.styles";

interface IConfirmationDialog {
  id: string;
  deletionTitle: string;
  deletionText: string;
  isOpen: boolean;
  onPrimaryBtnClick: (id: string) => void;
  onSecondaryClick: () => void;
  onClose:() => void;
  variant?: string;
  primaryButtonName?: string;
  secondaryButtonName?: string;
  hasDontShowAgain?: boolean;
}

export default function ConfirmationDialog({
  id,
  deletionTitle,
  deletionText,
  isOpen,
  onClose,
  onPrimaryBtnClick,
  hasDontShowAgain = false,
  onSecondaryClick,
  variant = "danger",
  primaryButtonName = "Yes",
  secondaryButtonName = "Cancel",
}: IConfirmationDialog) {
  const [open, setOpen] = React.useState(isOpen);

  React.useEffect(() => {
    setOpen(isOpen);
  });

  return (
    <Dialog
      open={open}
      maxWidth={false}
      PaperProps={{
        style: {
          borderRadius: 16,
        },
      }}
      onClose={onClose}
      aria-labelledby="title"
    >
      <Paper
        style={{
          width: 400,
          padding: 16,
        }}
      >
        {variant === "danger" && (
          <Flex>
            <IconWrapper>
              <BiErrorAlt
                size={"2rem"}
                color="#D92D20"
                className="dialog__icon"
              />
            </IconWrapper>
            <AiOutlineClose className="pointer" onClick={onClose} size={"1.6rem"} />
          </Flex>
        )}
        <DialogTitle style={{ padding: "10px 12px" }} id="title">
        <p className="dialog__title"> {deletionTitle} </p>
        </DialogTitle>
        <DialogContent style={{ padding: "12px 12px" }}>
          <DialogContentText><p className="dialog__content"> {deletionText} </p></DialogContentText>
          {
            hasDontShowAgain && (
              <FormControlLabel
          className="mt-2"
            control={
              <Checkbox

              onChange={(e) => {
                  e.stopPropagation();
                  localStorage.setItem(id, e.target.checked.toString());
              }}
              sx={{
                "&.MuiCheckbox-root": {
                  padding: "5px 0px 5px 5px !important",
                },
              }}
              />
            }
            label={
              <p className="dialog__content"> Don't show this again </p>
            }
          />
            )
          }
          
        </DialogContent>
        
          
                

        <DialogActions>
          <Button
            style={{
              display: "inline-block",
              width: "50%",
              marginRight: "0px",
            }}
            variant="secondary"
            autoFocus
            onClick={onSecondaryClick}
            id="confirm-dialog-cancel-button"
          >
            {secondaryButtonName}
          </Button>
          <Button
            style={{
              display: "inline-block",
              width: "50%",
              marginRight: "0px",
            }}
            variant={variant}
            onClick={(e) => {
              onPrimaryBtnClick(id);
              e.stopPropagation();
            }}
            autoFocus
            id="confirm-dialog-confirm-button"
          >
            {primaryButtonName}
          </Button>
        </DialogActions>
      </Paper>
    </Dialog>
  );
}
