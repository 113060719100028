import { AxiosResponse } from "axios";
import HttpUtil from "config/AxiosConfig";
import { ZorpResponse } from "providers/data/models/ZorpResponse";
import { actionType } from "views/automationBuilder/types/ABType";
import { getConfig as Config } from "../../../config/envConfig/Config";

export class BuilderService {
  public static copyNode(
    workflowId: string,
    nodeId: string
  ): Promise<ZorpResponse | undefined> {
    return HttpUtil.builderInstance
      .post(`/tasktype/${workflowId}/state/${nodeId}/copy`)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((error) => {
        throw error;
      });
  }

  public static deleteNode(
    workflowId: string,
    nodeId: string
  ): Promise<ZorpResponse | undefined> {
    return HttpUtil.builderInstance
      .delete(`/tasktype/${workflowId}/state/${nodeId}/delete`)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }

  public static publishAsPreview = (
    workflowId: string
  ): Promise<ZorpResponse | undefined> => {
    /**
     * {{builder-host}}:2000/builder-service/workflow/ga5d75/publish?preview=true
     */
    return HttpUtil.builderInstance
      .post(`/workflow/${workflowId}/publish?preview=true`)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  };

  public static updateNodeName(
    workflowId: string,
    nodeId: string,
    name: string
  ): Promise<ZorpResponse | undefined> {
    return HttpUtil.builderInstance
      .put(`/workflow/${workflowId}/state/${nodeId}/name`, { stateName: name })
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }

  // create workflow from the formID
  public static createWorkflowFromForm(
    formId: string,
    token: string
  ): Promise<ZorpResponse | undefined> {
    return HttpUtil.builderInstance
      .post(`/gform`, {
        formId: formId,
        token,
      })
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((error) => {
        throw error;
      });
  }

  public static createWorkflowFromGPT(prompt: string) {
    return HttpUtil.builderInstance
      .post(`/ai/workflow`, {
        question: prompt,
      })
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }

  public static fetchUsageInfoForGPT() {
    return HttpUtil.builderInstance
      .get("/ai/info")
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }

  public static getAutomationConfig = ({
    workflowId,
    stateId,
    accountId,
    metadataType,
    isTaskCreateScreenAutomation,
    isTaskHistoryScreenAutomation,
  }: {
    workflowId: string;
    accountId: string;
    stateId: string;
    metadataType: string;
    isTaskCreateScreenAutomation: boolean;
    isTaskHistoryScreenAutomation: boolean;
  }): Promise<ZorpResponse | undefined> => {
    return HttpUtil.builderInstance
      .post(`/automation/metadata`, {
        workflowId,
        accountId,
        stateId,
        metadataType,
        isTaskCreateScreenAutomation,
        isTaskHistoryScreenAutomation,
      })
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((error) => {
        return undefined;
      });
  };

  // task
  public static putMockData(
    taskId: string,
    mockData: {}
  ): Promise<ZorpResponse | undefined> {
    return HttpUtil.builderInstance
      .put(`/workflow/${taskId}/mockdata`, {
        mockData,
      })
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }

  public static getMockData(taskId: string): Promise<ZorpResponse | undefined> {
    return HttpUtil.builderInstance
      .get(`/workflow/${taskId}/mockdata`)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }

  public static createAPIResponseMockData(
    taskId: string,
    payload: any
  ): Promise<ZorpResponse | undefined> {
    return HttpUtil.builderInstance
      .post(`/workflow/${taskId}/mockdata/api`, payload)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }

  public static updateTaskRenderLayout = ({
    taskType,
    mode,
    layout,
  }: {
    taskType: string;
    mode: string;
    layout: any;
  }): Promise<ZorpResponse | undefined> => {
    return HttpUtil.builderInstance
      .post(`/task/${taskType}/layout`, { mode, layout })
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  };

  /**
   * {
    "actionType":"CREATE_ZORP_RECORD",
    "currentSelections" : {
        "tableType" : "xerooz"
    }
    
}
   */
  public static fetchNextStep = ({
    actionType,
    currentSelections,
  }: {
    actionType: string;
    currentSelections: any;
  }): Promise<ZorpResponse | undefined> => {
    return HttpUtil.builderInstance
      .post(`/ui/action/next-step`, {actionType,currentSelections})
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  };
}
