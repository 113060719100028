import React, { useEffect } from "react";
import {
  Control,
  Controller,
  useWatch,
  UseFormSetValue,
  UseFormRegister,
  useFormContext,
} from "react-hook-form";
import ZSelect from "views/commonComponents/ZSelect";
import useEntity from "views/entities/hooks/useEntity";
import { Checkbox } from "@mui/material";
import { ColorPalets } from "views/entities/styles";
import { ZDropdown, ZTextField } from "views/commonComponents/commonComponents.styles";
import { Label } from "views/DataField/DataField.styles";
import { IRelationReferringEntityType } from "views/entities/types";
import { TaskService } from "views/tasks/services/TaskService";
import styled from "styled-components";
import { components } from "react-select";
import { TableBlackIcon, TaskBlackIcon } from "assests"
import { useDispatch } from "react-redux";
import { EntityService } from "providers/data/services/EntityService";
import notification from "notifications/notifications";
import { isAction } from "@reduxjs/toolkit";

type Props = {
  control: Control<any>;
  register: UseFormRegister<any>;
  errors: any;
  setValue: UseFormSetValue<any>;
};

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: ${(props: any) => (props?.isAdd ? "#F5F8FF" : "")};
  color: ${(props: any) =>
    props?.isAdd ? `${props.theme.color.primary}` : "black"};
`;

const InputContainer = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const InputLabel = styled.label`
  color: rgb(52, 64, 84);
  font-size: 14px;
  font-weight: 500;
  margin-left: 8px;
`

const { Option } = components;
const IconOption = (props: any) => {
  return (
    <>
      <Option {...props}>
        <OptionWrapper isAdd={props.data.value === "newEntity"}>
          <div>
            <p style={{ fontSize: "12px", fontWeight: 500 }}>
              {props.data.data.icon}
            </p>
          </div>
          <div>{props.data.label}</div>
        </OptionWrapper>
      </Option>
    </>
  );
};

const RelationColumn = ({ control, register, errors, setValue }: Props) => {
  const { allEntities, selectedEntity, activeEditColumn } = useEntity();
  const [allTaskTypes, setAllTaskTypes] = React.useState<any[]>([]);
  const [relationEntityOptions, setRelationEntityOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [isTableSelected, setIsTableSelected] = React.useState(false);
  const [isTaskSelected, setIsTaskSelected] = React.useState(false);
  const [selectedReferringTable, setSelectedReferringTable] = React.useState("");
  const [referringTableColumns, setReferringTableColumns] = React.useState<any[]>([]);
  const [numberOfReferringColumns, setNumberOfReferringColumns] = React.useState(0);
  const { setValue: setFormValue, watch } = useFormContext();
  const [loadingReferringColumn, setLoadingReferringColumn] = React.useState(false);
  const [referringColumnName, setReferringColumnName] = React.useState("");
  const [loadingOptions, setLoadingOptions] = React.useState(false);

  const relationType = useWatch({
    control,
    name: "referringEntity",
  });

  const fetchTaskType = async () => {
    try {
      setLoading(true);
      const res:any = await TaskService.getTaskTypes(true, true);
      setAllTaskTypes(res?.data);
      return res?.data;
    } catch (error) {
      console.log(error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const getTablesForOptions = async (type: IRelationReferringEntityType) => {
    if (type === IRelationReferringEntityType.CUSTOM && allEntities) {
      const relationOptions = (allEntities)
        .filter((entity) => entity.tableType !== selectedEntity?.tableType)
        .map((entity) => {
          return {
            label: `${entity.tableDisplayName} - (${entity.tableType})`,
            value: entity.tableType,
          };
        });
      setRelationEntityOptions(relationOptions);
    }
      else {
        setRelationEntityOptions([]);
      }
  }

  const changeRelationEntityOptions = async(type: IRelationReferringEntityType) => {
    if (type === IRelationReferringEntityType.TASK && allTaskTypes.length === 0) {
      setLoadingOptions(true);
      const taskTypes = await fetchTaskType();
      if(taskTypes) {
        const relationOptions = taskTypes?.map((task) => {
          return {
            label: `${task.taskTypeDisplayName} - (${task.taskType})`,
            value: task.taskType,
          };
        });
        setRelationEntityOptions(relationOptions);
      }
      else {
        setRelationEntityOptions([]);
      }
      setLoadingOptions(false);
    } else {
      setRelationEntityOptions([]);
    }
  };

  const getDefaultValue = () => {
    if (
      activeEditColumn?.relationDetails?.referringEntity ===
      IRelationReferringEntityType.CUSTOM
      && allEntities
    ) {
      const defaultVal = allEntities.find(
        (entity) =>
          entity.tableType ===
          activeEditColumn?.relationDetails?.referringEntityType
      );
      return {
        label: `${defaultVal?.tableDisplayName} - (${defaultVal?.tableType})`,
        value: defaultVal?.tableType,
      };
    } else if (
      activeEditColumn?.relationDetails?.referringEntity ===
      IRelationReferringEntityType.TASK
    ) {
      const defaultVal = allTaskTypes?.find(
        (task) =>
          task.taskType ===
          activeEditColumn?.relationDetails?.referringEntityType
      );
      return {
        label: `${defaultVal?.taskTypeDisplayName} - (${defaultVal?.taskType})`,
        value: defaultVal?.taskType,
      };
    }
    return null;
  };

  useEffect(() => {
    if((activeEditColumn && activeEditColumn?.relationDetails?.referringEntity ===
      IRelationReferringEntityType.TASK)) fetchTaskType();
  }, [isTaskSelected, activeEditColumn]);

  const fetchColumnName = async() => {
    setLoadingReferringColumn(true);
    try {
      const referringTableId = activeEditColumn?.relationDetails?.referringEntityType;
      const referringColName = activeEditColumn?.relationDetails?.relatedEntities?.[0]?.columns?.[0]
      const res = await EntityService?.getEntityMetadata(referringTableId, 'customFields');
      const referringColDisplayName = (res?.data?.customFields || []).filter((col:any) => col.name === referringColName);
      setReferringColumnName(referringColDisplayName?.[0]?.displayName);
    }
    catch (error) {
      notification("error", "Error", "Error while fetching column name");
      console.log(error)
    }
    finally {
      setLoadingReferringColumn(false);
    }
  }

  useEffect(() => {
   if(activeEditColumn && activeEditColumn?.relationDetails?.relatedEntities?.[0]?.columns?.length > 0) {
    fetchColumnName();
   }
  }, [])

  const getUniqueSuffix = (arr:string, suffix:number, prefixString?: string) => {
    let suffixInit = suffix || 0;
    if(!arr || !prefixString) return 0;
    while (arr.includes(prefixString + '_' + suffixInit)) {
      suffixInit++;
    }
    return suffixInit;
  }

  const getRefferedColumns = async(type: string) => {
    try {const res = await EntityService?.getEntityMetadata(type, 'customFields');
      const relationColumns = (res?.data?.customFields || []).filter((col:any) => col.type === "RELATION");
      const referringColumns = relationColumns.filter((col:any) => (col?.relationDetails?.referringEntityType === selectedEntity?.tableType ));
      const nonDeletedreferringColumns = referringColumns?.filter((col:any) => col?.isDeleted ? col?.isDeleted === true ? false : true : true);
      const initColCount = referringColumns?.length;

      // finding the unique suffix for naming the column
      const columnNamesArr = res?.data?.customFields?.map((col:any) => col.name);
      const finalColCount = getUniqueSuffix(columnNamesArr, initColCount, selectedEntity?.tableDisplayName);
      const relationOptions = nonDeletedreferringColumns?.map((col:any) => {
        return {
          label: col?.displayName,
          value: col?.name,
        };
      });
      setReferringTableColumns(relationOptions);
      setNumberOfReferringColumns(nonDeletedreferringColumns?.length);
      setFormValue("numberOfColumnsInRelatedTable", finalColCount);
    } catch (error) {
      setReferringTableColumns([]);
      console.log(error);
    }
  }

  useEffect(() => {
    getRefferedColumns(selectedReferringTable)
  }, [selectedReferringTable])

  return (
    <>
      {activeEditColumn ? (
        <>
          <Label style={{ marginBottom: "8px", marginTop: "12px" }}>
            Create Relation with
          </Label>
          <ZTextField
            value={activeEditColumn?.relationDetails?.referringEntity === "TASK" ? "Zorp Task" : "Zorp Table"}
            disabled={true}
            fullWidth
          />
        </>
      ) : (
        <>
          <Label style={{ marginBottom: "8px", marginTop: "12px" }}>
            Create Relation with
          </Label>
          <Controller
            render={({ field }) => (
              <>
                <ZDropdown
                  {...field}
                  className="react-select"
                  classNamePrefix="react-select"
                  placeholder=""
                  onChange={(prop: any) => {
                    if(prop.value === "CUSTOM") getTablesForOptions(prop.value);
                    else if (allTaskTypes.length === 0) changeRelationEntityOptions(prop.value);
                    setIsTableSelected(prop.value === "CUSTOM");
                    setIsTaskSelected(prop.value === "TASK");
                    field.onChange(prop.value);
                    setValue("referringEntityType", null);
                  }}
                  options={[
                    {
                      label: "Zorp Table",
                      value: IRelationReferringEntityType.CUSTOM,
                      data: {
                        icon: TableBlackIcon,
                      },
                    },
                    {
                      label: "Zorp Task",
                      value: IRelationReferringEntityType.TASK,
                      data: {
                        icon: TaskBlackIcon,
                      },
                    }
                  ]}
                  value={field?.value?.value}
                  components={{ Option: IconOption }}
                />
              </>
            )}
            name="referringEntity"
            control={control}
          />
        </>
      )}

      {(activeEditColumn ) ? (
        activeEditColumn?.relationDetails?.referringEntity ===
        IRelationReferringEntityType.TASK ? (
        <>
          <Label style={{ marginBottom: "8px", marginTop: "12px" }}>
            {activeEditColumn?.relationDetails?.referringEntity ===
            IRelationReferringEntityType.TASK
              ? "Choose Workflow"
              : "Choose Table"}
          </Label>
          <ZTextField
            value={
              loading
                ? "Loading..."
                : getDefaultValue()?.label ||
                  activeEditColumn?.relationDetails?.referringEntityType
            }
            disabled={true}
            fullWidth
          />
        </>) : null
      ) : !isTableSelected ? (
        relationType !== IRelationReferringEntityType.USER && (
          <>
            <Label style={{ marginBottom: "8px", marginTop: "12px" }}>
              {relationType === IRelationReferringEntityType.TASK
                ? "Choose Workflow"
                : "Choose Table"}
            </Label>
            <ZSelect
              control={control}
              name="referringEntityType"
              defaultValue={getDefaultValue()}
              options={
                loadingOptions
                  ? [{ label: "Loading...", value: "" }]
                  : relationEntityOptions
              }
            />
          </>
        )
      ): null}

      {(activeEditColumn && !(activeEditColumn?.relationDetails?.referringEntity ===
            IRelationReferringEntityType.TASK)) ? (
        <>
          <Label style={{ marginBottom: "8px", marginTop: "12px" }}>
            Select Target Table
          </Label>
          <ZTextField
            value={
              loading
                ? "Loading..."
                : getDefaultValue()?.label ||
                  activeEditColumn?.relationDetails?.referringEntityType
            }
            disabled={true}
            fullWidth
          />
        </>
      ) : (!activeEditColumn && relationType !== IRelationReferringEntityType.USER && isTableSelected) ? 
          <>
            <Label style={{ marginBottom: "8px", marginTop: "12px" }}>
            Select Target Table
            </Label>
            <Controller
            render={({ field }) => (
              <>
                <ZDropdown
                  {...field}
                  className="react-select"
                  classNamePrefix="react-select"
                  placeholder=""
                  onChange={(prop: any) => {
                    setValue("referringEntityType", prop.value);
                    field.onChange(prop.value);
                    setSelectedReferringTable(prop.value);
                  }}
                  options={relationEntityOptions}
                  value={field?.value?.value}
                />
              </>
            )}
            name="referringEntityType"
            control={control}
          />
          </> : null}

          { isTableSelected && selectedReferringTable && <div>
                <InputContainer>
                  <input
                    {...register("columnOption")}
                    type="radio"
                    value="createReferenceColumnInTarget"
                    checked={watch("columnOption") === "createReferenceColumnInTarget"}
                    onClick={() => {
                      const currentValue = watch("columnOption");
                      if (currentValue === "createReferenceColumnInTarget") {
                        setValue("columnOption", null);
                      } else {
                        setValue("columnOption", "createReferenceColumnInTarget");
                      }
                    }}
                  />
                  <InputLabel>
                    Create reference column in target table
                  </InputLabel>
                </InputContainer>
                <InputContainer>
                  <input
                    {...register("columnOption")}
                    type="radio"
                    value="usePreExistingColumn"
                    onClick={() => {
                      const currentValue = watch("columnOption");
                      if (currentValue === "usePreExistingColumn") {
                        setValue("columnOption", null);
                      } else {
                        if (numberOfReferringColumns !== 0) {
                          setValue("columnOption", "usePreExistingColumn");
                        } else {
                          notification('error', 'There are no related columns in the selected table');
                          setValue("columnOption", "createReferenceColumnInTarget");
                        }
                      }
                    }}
                    checked={watch("columnOption") === "usePreExistingColumn"}
                  />
                  <InputLabel>
                    Use Pre-existing column from related table
                  </InputLabel>
                </InputContainer>
              </div>}

              {isTableSelected && watch("columnOption") === "usePreExistingColumn" && (
                <Controller
                  name="referringEntityColumn"
                  control={control}
                  rules={{
                    required: watch("columnOption") === "usePreExistingColumn" && referringTableColumns.length > 0,
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <Label style={{ marginBottom: "8px", marginTop: "12px" }}>
                        Use pre-existing column
                      </Label>
                      <ZSelect
                        required={watch("columnOption") === "usePreExistingColumn"}
                        {...field}
                        options={referringTableColumns}
                      />
                      {fieldState.error && (
                        <p style={{ color: 'rgba(244, 63, 94)', fontSize: '10px' }}>Select any column from dropdown to proceed</p>
                      )}
                    </>
                  )}
                />
              )}

            {activeEditColumn && activeEditColumn?.relationDetails?.relatedEntities?.[0]?.columns?.length > 0 && 
              <>
              <Label style={{ marginBottom: "8px", marginTop: "12px" }}>
                Referring Column
              </Label>
              <ZTextField
                value={loadingReferringColumn ? "Loading..." : referringColumnName}
                disabled={true}
                fullWidth
              />
              </>            
          }
          {activeEditColumn && (
              (activeEditColumn?.relationDetails?.createReferenceColumnInTarget || activeEditColumn?.relationDetails?.relatedEntities?.[0]?.columns.length > 0) ? <InputContainer>
              <input
                type="radio"
                checked={true}
                disabled={true}
              />
              <InputLabel>
              {activeEditColumn?.relationDetails?.createReferenceColumnInTarget ? "Create reference column in target table" : "Use pre-existing column"}
              </InputLabel>
            </InputContainer> : null
            )}
    </>
  );
};

export default RelationColumn;