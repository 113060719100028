import { AxiosResponse } from "axios";
import HttpUtil from "config/AxiosConfig";
import { ZorpResponse } from "providers/data/models/ZorpResponse";
import { viewPermissionsPayload } from "views/entities/permissions/types";
import { ITable, IListingView } from "views/entities/types";

export class EntityService {
  public static updateEntity = (
    body: any
  ): Promise<ZorpResponse | undefined> => {
    return HttpUtil.entityInstance
      .post(`/table/update`, { ...body })
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((error) => {
        throw error;
      });
  };

  public static createEntity = (
    body: any
  ): Promise<ZorpResponse | undefined> => {
    return HttpUtil.entityInstance
      .post(`/table/create`, { ...body })
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((error) => {
        throw error;
      });
  };

  public static getEntities = (): Promise<ITable[] | undefined> => {
    return HttpUtil.entityInstance
      .get(`/table/names`)
      .then((response: AxiosResponse) => {
        return response.data.data as ITable[];
      })
      .catch((error) => {
        throw error;
      });
  };

  public static getListingView = (
    tableType: string,
    mode: "listing" | "create",
    pageNumber?: number,
    pageSize?: number,
    sorting?: any[],
    searchText?: string,
    filters?: { id: string; value: unknown; filterType?: string }[],
    viewId?: string
  ): Promise<IListingView> => {
    if (mode === "listing") {
      return HttpUtil.entityInstance
        .get(
          `/table/web/listing/renderConfig/tableType/${tableType}/?mode=${mode}&pageNumber=${pageNumber}&pageSize=${pageSize}&sorting=${JSON.stringify(
            sorting
          )}&searchText=${searchText}&filters=${JSON.stringify(
            filters
          )}&viewId=${viewId}`
        )
        .then((response: AxiosResponse) => {
          return response?.data?.data;
        })
        .catch((error) => {
          throw error;
        });
    } else if (mode === "create") {
      return HttpUtil.entityInstance
        .get(
          `/table/web/create/renderConfig/tableType/${tableType}/?mode=${mode}`
        )
        .then((response: AxiosResponse) => {
          return response?.data?.data;
        })
        .catch((error) => {
          throw error;
        });
    } else {
      return Promise.reject("Invalid mode");
    }
  };

  public static deleteRecord = (
    tableType: string,
    recordId: string
  ): Promise<ZorpResponse | undefined> => {
    return HttpUtil.entityInstance
      .delete(`/table/${tableType}/record/${recordId} `)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((error) => {
        throw error;
      });
  };

  public static createEntityRecord = (
    body: any
  ): Promise<ZorpResponse | undefined> => {
    return HttpUtil.entityInstance
      .post(`/table/record`, body)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((error) => {
        throw error;
      });
  };

  public static getEntityShowView = (
    tableType: string,
    recordId: string,
    mode: "show" | "edit"
  ): Promise<any> => {
    return HttpUtil.entityInstance
      .get(`/table/${tableType}/web/renderConfig/${recordId}/?mode=${mode}`)
      .then((response: AxiosResponse) => {
        return response?.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  public static getEntityCreateView = (
    tableType: string,
    mode: "create"
  ): Promise<any> => {
    return HttpUtil.entityInstance
      .get(
        `/table/web/create/renderConfig/tableType/${tableType}/?mode=${mode}`
      )
      .then((response: AxiosResponse) => {
        return response?.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  public static updateEntityRecord = (
    tableType: string,
    recordId: string,
    body: any
  ): Promise<ZorpResponse | undefined> => {
    return HttpUtil.entityInstance
      .patch(`/table/${tableType}/record/${recordId}`, body)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((error) => {
        throw error;
      });
  };

  public static updateTableLayout = ({
    tableType,
    mode,
    layout,
  }: {
    tableType: string;
    mode: "create" | "show";
    layout: any;
  }): Promise<ZorpResponse | undefined> => {
    return HttpUtil.entityInstance
      .post(`/table/${tableType}/layout?mode=${mode}`, { layout })
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((error) => {
        throw error;
      });
  };

  public static getEntityMetadata = (
    tableType: string,
    sourceType?: string
  ): Promise<any> => {
    if (!tableType) return Promise.reject("Table type is required");
    const fetchUrl = sourceType
      ? `/table/${tableType}/metadata/?source=${sourceType}`
      : `/table/${tableType}/metadata`;
    return HttpUtil.entityInstance
      .get(fetchUrl)
      .then((response: AxiosResponse) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  public static updateEntityAutomation = (
    tableType: string,
    body: any
  ): Promise<ZorpResponse | undefined> => {
    return HttpUtil.entityInstance
      .post(`/table/automation`, { tableType: tableType, automations: body })
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((error) => {
        throw error;
      });
  };

  public static getMockData = (tableType: string): Promise<any> => {
    if (!tableType) return Promise.reject("Table type is required");
    return HttpUtil.entityInstance
      .get(`/table/${tableType}/mockdata`)
      .then((response: AxiosResponse) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  public static executeEntityColumnAutomation = ({
    tableType,
    buttonId,
    recordId,
  }: {
    tableType: string;
    buttonId: string;
    recordId: string;
  }): Promise<ZorpResponse | undefined> => {
    return HttpUtil.entityInstance
      .post(`/table/execute/automation`, {
        tableType: tableType,
        buttonId: buttonId,
        recordId: recordId,
      })
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((error) => {
        throw error;
      });
  };

  public static getRecordFromTableType = ({
    tableType,
  }: {
    tableType: string;
  }) => {
    return HttpUtil.entityInstance
      .get(`/table/${tableType}`)
      .then((response: AxiosResponse) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  public static getDisplayColumn = ({ tableType }: { tableType: string }) => {
    return HttpUtil.entityInstance
      .get(`/table/${tableType}/displayColumn`)
      .then((response: AxiosResponse) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  // update Test data
  public static updateMockData = (
    tableType: string,
    body: any
  ): Promise<ZorpResponse | undefined> => {
    if (!tableType) return Promise.reject("Table type is required");
    return HttpUtil.entityInstance
      .put(`/table/${tableType}/mockdata`, {
        tableType: tableType,
        payload: body,
      })
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((error) => {
        throw error;
      });
  };

  public static bulkDeleteRecords = (
    tableType: string,
    recordIds: string[]
  ): Promise<ZorpResponse | undefined> => {
    return HttpUtil.entityInstanceForBulk
      .delete(`/table/records`, {
        data: { tableType: tableType, recordIds: recordIds },
      })
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((error) => {
        throw error;
      });
  };

  public static recordBulkImport = (body: {
    tableType: string;
    records: any[];
  }): Promise<ZorpResponse | undefined> => {
    return HttpUtil.entityInstanceForBulk
      .post(`/table/records`, body)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((error) => {
        throw error;
      });
  };

  public static getRecords = (body: any) => {
    return HttpUtil.entityInstance
      .post(`/table/search`, body)
      .then((response: AxiosResponse) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  public static exportRecords = (body: any) => {
    return HttpUtil.entityInstanceForBulk
      .post(`/table/export`, body)
      .then((response: AxiosResponse) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  public static deleteTable = (tableType: string) => {
    return HttpUtil.entityInstance
      .delete(`/tables/${tableType}`)
      .then((response: AxiosResponse) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  // copy table
  public static copyTable = (tableType: string) => {
    return HttpUtil.entityInstance
      .post(`/table/${tableType}/copy`)
      .then((response: AxiosResponse) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  public static getViews = (tableType: string) => {
    return HttpUtil.entityInstance
      .get(`/table/${tableType}/views`)
      .then((response: AxiosResponse) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  public static getTableInfoFromTableType = (tableType: string) => {
    return HttpUtil.entityInstance
      .get(`/tables/${tableType}/info`)
      .then((response: AxiosResponse) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  public static getRelationRecords = ({
    page,
    pageSize,
    tableType,
    currentValue,
    searchText,
  }: {
    page: number;
    pageSize: number;
    tableType: string;
    currentValue: string[];
    searchText?: string;
  }) => {
    return HttpUtil.entityInstance
      .post(`/table/${tableType}/records/displayColumn`, {
        page,
        size: pageSize,
        currentValue,
        searchText,
      })
      .then((response: AxiosResponse) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };
  public static getTableAPIDocumentation = (
    tableType: string
  ): Promise<ZorpResponse | undefined> => {
    return HttpUtil.entityInstance
      .get(`/table/${tableType}/apidocs`)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((error) => {
        throw error;
      });
  };

  public static getRecordLogs(
    RecordId: string,
    tableType: string
  ): Promise<ZorpResponse | undefined> {
    return HttpUtil.entityInstance
      .post(`/table/record/${RecordId}/logs?tableType=${tableType}`)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((err) => {
        throw err;
      });
  }

  public static getTempListngConfig({
    tableType,
    recordIds,
    entityType,
  }: {
    tableType: string;
    recordIds: string[];
    entityType: string;
  }): Promise<ZorpResponse | undefined> {
    return HttpUtil.entityInstance
      .post(`/table/getListRenderConfig`, { recordIds, entityType, tableType })
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((err) => {
        throw err;
      });
  }

  public static getTempListngConfigForTaskAndUser({
    tableType,
    recordIds,
    entityType,
  }: {
    tableType: string;
    recordIds: string[];
    entityType: string;
  }): Promise<ZorpResponse | undefined> {
    return HttpUtil.entityInstance
      .post(`/table/getListRenderConfigForTaskAndUser`, {
        recordIds,
        entityType,
        tableType,
      })
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((err) => {
        throw err;
      });
  }

  public static getUsersForAccount(
    page: number,
    size: number,
    searchText?: string
  ): Promise<ZorpResponse | undefined> {
    return HttpUtil.entityInstance
      .get(`/users?page=${page}&size=${size}&searchText=${searchText}`)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }

  public static getEntityPermissions(
    tableType: string
  ): Promise<ZorpResponse | undefined> {
    return HttpUtil.entityInstance
      .get(`/table/${tableType}/table-acl`)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }

  public static updateEntityPermissions(
    payload: any
  ): Promise<ZorpResponse | undefined> {
    return HttpUtil.entityInstance
      .put(`/table/table-acl`, payload)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }

  // update table name
  /**
   * 
   * @param tableType {"tableType":"jegb0e",
                    "tableDisplayName":"Ordersss"}
   * @param newTableName 
   * @returns 
   */
  public static updateTableName = ({
    tableType,
    tableDisplayName,
    tableDescription,
    defaultView,
    recordTypeLabel,
  }: {
    tableType: string;
    tableDisplayName: string;
    tableDescription: string;
    defaultView: string;
    recordTypeLabel?: string;
  }) => {
    return HttpUtil.entityInstance
      .post(`/table/rename`, {
        tableType: tableType,
        tableDisplayName: tableDisplayName,
        tableDescription: tableDescription,
        defaultView: defaultView,
        recordTypeLabel: recordTypeLabel,
      })
      .then((response: AxiosResponse) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  public static addComment = ({
    tableType,
    data,
    recordId,
  }: {
    tableType: string;
    recordId: string;
    data: {
      comments: any[];
    };
  }) => {
    return HttpUtil.entityInstance
      .patch(`/table/${tableType}/record/${recordId}/comment`, {
        tableType,
        data,
      })
      .then((response: AxiosResponse) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  public static createView = (
    tableType: string,
    payload: {
      isDeleted: boolean;
      name: string;
      description: string;
      viewPermissions: viewPermissionsPayload;
      columnOrdering: string[];
      sorting: any[];
      columnVisibility: any;
      columnFilters: { id: string; value: unknown }[];
      columnFilterFns: { [id: string]: string };
      globalFilter: string;
      pagination: { pageSize: number; pageIndex: number };
      columnPinning: { left: string[]; right: string[] };
    }
  ) => {
    return HttpUtil.entityInstance
      .post(`/table/${tableType}/views`, { ...payload })
      .then((response: AxiosResponse) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  public static updateView = (
    tableType: string,
    viewId: string,
    payload: {
      name: string;
      description: string;
      viewPermissions: viewPermissionsPayload;
      isDeleted: boolean;
    }
  ) => {
    return HttpUtil.entityInstance
      .patch(`/table/${tableType}/views/${viewId}`, { ...payload })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  public static saveView = (
    tableType: string,
    viewId: string,
    payload: {
      name: string;
      description: string;
      columnOrdering: string[];
      columnVisibility: {};
      columnPinning: { left: []; right: [] };
      globalFilter: string;
      columnFilterFns: {};
      pagination: { pageSize: number; pageIndex: number };
      columnFilter: any[];
      sorting: any[];
      isDeleted: boolean;
    }
  ) => {
    return HttpUtil.entityInstance
      .put(`/table/${tableType}/views/${viewId}`, { ...payload })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  /**
   * curl --location 'https://api.zorp.one/zorp-tables-service/v2/table/records' \
--header 'Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhaGZrMDgiLCJhY2NvdW50SWQiOiJtMTRmbXoiLCJyb2xlIjoiYWRtaW4iLCJpc3MiOiJ6b3JwIiwibmFtZSI6IkFCIFRlc3QiLCJjaGFubmVsIjoiQ0MiLCJleHAiOjE3MjIzMjk5MzcsImlhdCI6MTcxOTczNzkzNywidXNlcklkIjoiYWhmazA4IiwidGVhbUlkcyI6WyJlZnl2a3MiXX0.9QH4Ss2XRKwNjeDfIR-mpoW6G9A5izgsAq6RTwNgneg' \
--header 'Connection: keep-alive' \
--header 'Content-Type: application/json' \
--data-raw '{
    "tableType": "pwpkf7",
    "email":"laxmikanth@zorp.one",
    "records": [
        {
            "recordId":"he6a49",
            "Questions": "Who is the PM of India?",
            "Response_Type": ["TEXT"],
            "Score": 2,
            "placeholder": "",
            "Mandatory": true,
            "Options":"Modi,Rahul",
            "Option_Scores": "Modi-1,Rahul-0",
            "Is_image_required": false,
            "Number_of_Images_Required": 0,
            "priority": ["Low"]
        }
    ]
}'
   */

  public static recordUpsert = (
    {
      tableType,
      email,
      records,
    
    }:{tableType: string,
    email: string,
    records: any[]}
  ): Promise<ZorpResponse | undefined> => {
    return HttpUtil.entityInstance
      .post(`/table/records`, { tableType, email, records })
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((error) => {
        throw error;
      });
  };
}
