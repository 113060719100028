import { PreviewIcon } from "assests";
import notification from "notifications/notifications";
import { BuilderService } from "providers/data/services/BuilderService";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { recordRSEvent } from "utils/CommonUtils";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import { setLoading } from "views/reducer";
import {
  setShowWorkflowConfigModal,
  setShowPreviewModal,
} from "../reducers/workflowAction";
import useWorkFlow from "../hooks/useWorkFlow";
import useOnboarding from "views/onboarding/hooks/useOnboarding";
import useDeviceType from "utils/useDeviceType";
import { InfoTooltip } from "../Tootltips/InfoTooltip";

type Props = {};

const PreviewPublishButton = (props: Props) => {
  const dispatch = useDispatch();
  const { taskTypeId, taskTypeName, isBeingSaved, selectedNode } =
    useWorkFlow();

  const { isBeingOnboarded } = useOnboarding();
  const deviceType = useDeviceType();
  //@ts-ignore
  const { loading } = useSelector((state) => state.loading);
  //@ts-ignore
  const { errors: invalidWorkflow } = useSelector(
    //@ts-ignore
    (state) => state?.validWorkflow
  );

  const publishAsPreview = () => {
    dispatch(setLoading(true));
    recordRSEvent("publish_button_click", {
      context: "builder_general",
      workflow_id: taskTypeId,
      workflow_name: taskTypeName,
    });
    dispatch(setShowWorkflowConfigModal(false));
    BuilderService.publishAsPreview(taskTypeId)
      .then((res) => {
        dispatch(setLoading(false));
        const at = localStorage.getItem("at");
        // window.open(`https://zorp-dev.web.app/#/?at=${at}&preview=true`, "_blank");
        dispatch(setShowPreviewModal(true));
        //@ts-ignore
        localStorage.setItem("isPreview", true);
        localStorage.setItem("previewWorkflowId", taskTypeId);
      })
      .catch((err) => {
        console.error("Error", err);
        recordRSEvent("publish_event_fail", {
          context: "builder_general",
          workflow_id: taskTypeId,
          workflow_name: taskTypeName,
        });
        dispatch(setLoading(false));
        notification("error", "Error while publishing as preview");
      });
  };

  return (
    <>
      {isBeingOnboarded && deviceType !== "mobile" ? (
        <InfoTooltip
          placement="left"
          arrow
          open={true && !selectedNode}
          title="See Preview of the workflow before publishing it."
        >
          <span>
            <ZButton
              disabled={isBeingSaved || invalidWorkflow || loading}
              startIcon={PreviewIcon}
              onClick={publishAsPreview}
              secondary
              variant="contained"
            >
              {" "}
              App Preview{" "}
            </ZButton>
          </span>
        </InfoTooltip>
      ) : (
        <InfoTooltip
          arrow
          title={"See Preview of the workflow before publishing it."}
        >
          <span>
            <ZButton
              disabled={isBeingSaved || invalidWorkflow || loading}
              startIcon={PreviewIcon}
              onClick={publishAsPreview}
              secondary
              variant="contained"
            >
              {" "}
              App Preview{" "}
            </ZButton>
          </span>
        </InfoTooltip>
      )}
    </>
  );
};

export default PreviewPublishButton;
