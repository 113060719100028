import React from "react";

type Props = {};

const ZNewReports = ({
  color,
  width,
  height,
}: {
  color?: string;
  width?: string;
  height?: string;
}) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.75 4.25H2.45C2.02996 4.25 1.81994 4.25 1.65951 4.33174C1.51839 4.40365 1.40365 4.51839 1.33175 4.65951C1.25 4.81994 1.25 5.02996 1.25 5.45V13.55C1.25 13.97 1.25 14.1801 1.33175 14.3405C1.40365 14.4816 1.51839 14.5963 1.65951 14.6683C1.81994 14.75 2.02996 14.75 2.45 14.75H5.75M5.75 14.75H10.25M5.75 14.75L5.75 2.45C5.75 2.02996 5.75 1.81994 5.83174 1.65951C5.90365 1.51839 6.01839 1.40365 6.15951 1.33175C6.31994 1.25 6.52996 1.25 6.95 1.25L9.05 1.25C9.47004 1.25 9.68006 1.25 9.84049 1.33175C9.98161 1.40365 10.0963 1.51839 10.1683 1.65951C10.25 1.81994 10.25 2.02996 10.25 2.45V14.75M10.25 7.25H13.55C13.97 7.25 14.1801 7.25 14.3405 7.33174C14.4816 7.40365 14.5963 7.51838 14.6683 7.65951C14.75 7.81994 14.75 8.02996 14.75 8.45V13.55C14.75 13.97 14.75 14.1801 14.6683 14.3405C14.5963 14.4816 14.4816 14.5963 14.3405 14.6683C14.1801 14.75 13.97 14.75 13.55 14.75H10.25" stroke="#344054" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

export default ZNewReports;