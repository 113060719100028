import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import iconNew from "../../views/maps/task_new.png";
import L from "leaflet";


export const TaskMap = ({ mapCenter, locations, locationDisplay=false }) => {
    const markers = [];
    locations?.forEach((loc, index) => {

        const taskNewIcon = L.divIcon({
            html: `<img class=${locationDisplay ? "map-marker" : ""}  width="40" height: "50" src='${iconNew}'> <span style="display: inline-block; width: 100px !important; color: blue; font-size: small">${locations?.length > 1 ?  `${index+1}.` : ""} ${loc.address} </span> </img>`
        });

        markers.push(<Marker
            position={[
                loc.latitude ?? 0,
                loc.longitude ?? 0,
            ]}
            icon={taskNewIcon}
            key={1000 * Math.random()}
        >
            <Popup onClose={() => {}}>{(locations?.length > 1 ?  `${index+1}.` : "") + loc.address}</Popup>
        </Marker>);
    })

    return (
        <div>
            {mapCenter && <MapContainer
                id="task-map-details"
                center={[
                    mapCenter[0],
                    mapCenter[1],
                ]}
                zoom={1}
                style={{
                    height: "70px",
                    width: "70px",
                }}
                scrollWheelZoom={false}
            >
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {markers}

            </MapContainer>}
        </div>
    );
}