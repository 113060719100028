import { Box, FormControl, Grid } from '@mui/material';
import { PageCategoryEnum, recordPageVisit } from 'config/Rudderstack';
import { DocsLink } from 'constants/CommonConstants';
import notification from 'notifications/notifications';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { TaskFormBody } from 'render-engine/render.styles';
import { ZButton, ZLabel } from 'views/commonComponents/commonComponents.styles';
import { ZInfo } from 'views/commonComponents/ZInfo';
import ZSelect from 'views/commonComponents/ZSelect';
import ZInput from 'views/commonComponents/ZTextInput';
import Callout from 'views/onboarding/components/Callout';
import { getTeamsList } from 'views/users/reducers/Users.actions';
import { clearTeamsPageData, createTeam, getTeamById, updateTeam } from './reducers/Teams.actions';

const TeamCreate = (props: any) => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const { teamsListOptions = [] } = useSelector((state: any) => state.usersPage);
  const { teamDetails = {}, status = '' } = useSelector((state: any) => state.teamsPage);

  const initForm = (data: any) => {
    const { name = '', description = '', parentTeamId = '' } = data;
    setValue('teamName', name);
    setValue('description', description);
    setValue('teamId', { value: parentTeamId, label: parentTeamId });
  };

  useEffect(() => {
    recordPageVisit(PageCategoryEnum.team, 'team_create');
  }, [props]);

  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(getTeamsList());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (id) dispatch(getTeamById(id));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (teamDetails) initForm(teamDetails);
  }, [teamDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (status.match(/200|201/)) {
      Promise.resolve(clearTeamsPageData()).then(() => {
        history.push('/settings/teams');
      });
    }
  }, [status]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      dispatch(clearTeamsPageData());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const cancelCreate = () => {
    notification('info', 'Team creation cancelled!');
    history.push('/settings/teams');
  };

  const onSubmit = (data: any) => {
    const teamCreatePayload = {
      name: data?.teamName,
      description: data?.description,
      parentTeamId: data?.teamId?.value,
    };
    if (id) {
      dispatch(updateTeam({ id, teamCreatePayload }));
      notification('success', `${id}`, "Team Updated");
    } else {
      dispatch(createTeam(teamCreatePayload));
      notification('success', 'Team created successfully!');
    }
  };

  return (
   
      <form onSubmit={handleSubmit(onSubmit)} style={{ padding: '2rem' }}>
        <TaskFormBody>
          <div className='contentWrapper'>

        <Callout
          text={
            <p>
              {" "}
              You can create your teams here.{" "}
              <a
                style={{ color: "blue" }}
                href={DocsLink.TEAMS}
                target="_black"
                rel="noreferrer"
              >
                {" "}
                Know more about Team{" "}
              </a>{" "}
            </p>
          }
        />
            <Grid container spacing={2} mt={3} mb={4}>
              <Grid item xs={12} md={6}>
                <FormControl variant='outlined' fullWidth>
                  <ZLabel>Team Name <span className="text-red-500">*</span></ZLabel>
                  <ZInput register={register} name='teamName' validations={{ required: true }} />
                  {errors && errors['teamName'] && (
                    <ZInfo
                      className='mt-1'
                      errors={errors}
                      name={'teamName'}
                      requiredMessage={'Team Name is required'}
                      isError={true}
                    />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant='outlined' fullWidth>
                  <ZLabel>Description <span className="text-red-500">*</span> </ZLabel>
                  <ZInput register={register} name='description' validations={{ required: true }} />
                  {errors && errors['description'] && (
                    <ZInfo
                      className='mt-1'
                      errors={errors}
                      name={'description'}
                      requiredMessage={'Description is required'}
                      isError={true}
                    />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant='outlined' fullWidth>
                  <ZLabel sx={{marginBottom: "-8px"}}>Parent Team <span className="text-red-500">*</span></ZLabel>
                  <ZSelect name='teamId' control={control} options={teamsListOptions} />
                  {errors && errors['teamId'] && (
                    <ZInfo
                      className='mt-1'
                      errors={errors}
                      name={'teamId'}
                      requiredMessage={'Parent Team is required'}
                      isError={true}
                    />
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <hr />
            <Box mt={16} display='flex' alignItems='center' justifyContent='start'>
              <ZButton type='submit' variant='contained'>
                {!id ? `Create Team` : `Update Team`}
              </ZButton>
              <ZButton variant='contained' onClick={cancelCreate} secondary className='ml-2'>
                Cancel
              </ZButton>
            </Box>
            </div>
        </TaskFormBody>
      </form>
    
  );
};

export default TeamCreate;
