// SingleComment.tsx
import React, { useState, FC } from "react"
import NewComment from "./NewComment"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import styled from "styled-components"
import { DateTime } from "luxon"
import { BiRightArrow } from "react-icons/bi"
import { ReplyRIcon } from "assests"
import Reply from "assests/message-chat-square.svg"
import { formatDate } from "utils/CommonUtils"

const CommentContainer = styled(Card)`
  margin-bottom: 10px !important;
  border: none !important;
  margin: 0 !important;
  height: auto !important;
`

const ReplyContainer = styled.div`
  margin-left: 20px;
  margin-right: 8px;
  margin-top: 11px;
`

const Text = styled.p`
  // get fontSize from props
  font-size: ${(props: {
    fontSize: string
    fontWeight: string
    color: string
  }) => props.fontSize};
  font-weight: ${(props: {
    fontSize: string
    fontWeight: string
    color: string
  }) => props.fontWeight};
  color: ${(props: { fontSize: string; fontWeight: string; color: string }) =>
    props.color};
`

const AuthorDetail = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const CommentText = styled(Card)<{ level?: number }>`
  && {
    background-color: ${(props) =>
      props.level && props.level > 0 ? "#F9FAFB" : "#f2f4f7"};
    margin: 6px 0 0 0;
    padding: 8px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid var(--gray-200, #eaecf0);
    display: flex;
    height: auto !important;
    justify-content: space-between;
  }
`

const CommentSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export type CommentType = {
  id: string
  text: string
  author: {
    id: string
    username: string
  }
  createdAt: string
  replies: CommentType[]
  taggedUsers: string[]
}

type SingleCommentProps = {
  comment: CommentType
  entityId: string
  onNewReply: (data: any, parentId: string, callback: Function) => void
  users: any[]
  level?: number
  showUserName?: boolean
}

const SingleComment: FC<SingleCommentProps> = ({
  comment,
  entityId,
  onNewReply,
  users,
  level = 0,
  showUserName = true,
}) => {
  const [showReplyBox, setShowReplyBox] = useState(false)

  const handleReply = () => {
    setShowReplyBox(true)
  }

  const handleNewReply = (data: any, parentId: string, callback: Function) => {
    onNewReply(data, parentId, () => {
      setShowReplyBox(false)
      callback()
    })
  }

  function getTaggedUsers(comment: string) {
    const possibleMentions = comment.match(/@([\w\s]+)(?=\s|$)/g) || []
    const mentions = possibleMentions.map((mention) =>
      mention.replace("@", "").trim()
    )
    const taggedUsers = []

    for (let mention of mentions) {
      const user = users.find((user) => mention.includes(user.name))
      if (user) {
        taggedUsers.push(user)
      }
    }

    return taggedUsers
  }

  const taggedUsers = getTaggedUsers(comment.text)

  const renderCommentText = (text: string) => {
    let newText = text
    taggedUsers.forEach((user) => {
      const regex = new RegExp(`@${user.name}\\b`, "g")
      newText = newText.replace(
        regex,
        `<a href="/#/users/${user.userId}/show" target="_blank" rel="noopener noreferrer" style="color: blue">@${user.name}</a>`
      )
    })

    // Replace \n with <br />
    newText = newText.replace(/\n/g, "<br />")

    return <span dangerouslySetInnerHTML={{ __html: newText }} />
  }

  return (
    <CommentContainer sx={{marginTop:"8px"}} variant="outlined">
      <CardContent sx={{ padding: "0px" }}>
        {showUserName && (
          <AuthorDetail>
            <div>
              <Text fontSize="11px" fontWeight="500" color="#344054">
                {comment?.author?.username}
              </Text>
            </div>

            <div>
              <Text fontSize="11px" fontWeight="400" color="#475467">
                {formatDate(comment.createdAt)}
              </Text>
            </div>
          </AuthorDetail>
        )}

        <CommentSection>
          <CommentText level={level}>
            <Text fontSize="11px" fontWeight="400" color="#101828">
              {renderCommentText(comment.text)}
            </Text>
            <div>
              {level < 1 && (
                <img src={Reply} onClick={handleReply} height={18} width={18} />
              )}
            </div>
          </CommentText>
        </CommentSection>
      </CardContent>
      <ReplyContainer>
        {comment.replies?.map((reply, index) => (
          <SingleComment
            key={reply.id}
            comment={reply}
            onNewReply={onNewReply}
            entityId={entityId}
            users={users}
            level={level + 1}
            showUserName={index === 0 || comment.author.id !== reply.author.id}
          />
        ))}
      </ReplyContainer>

      {showReplyBox && (
        <NewComment
          parentId={comment.id}
          entityId={entityId}
          onNewComment={handleNewReply}
          users={users}
          onCancel={() => setShowReplyBox(false)}
        />
      )}
    </CommentContainer>
  )
}

export default SingleComment
