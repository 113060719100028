import React from 'react'
import { TaskFormInput, TaskFormLabelForShow } from 'render-engine/render.styles'

type Props = {
    label: string,
    value: string
}

const UserDisplayBlock = ({label,value}: Props) => {
  return (
    <div >
          <TaskFormLabelForShow>{label}</TaskFormLabelForShow>
          <div className='flex justify-between space-x-4 pr-2 pb-4'>
            <TaskFormInput>  {value} </TaskFormInput>
          </div>
        </div>
  )
}

export default UserDisplayBlock