import BlueBeeIcon from "assests/chat_bluebee.svg";
import monitor_template_alert_icon from "assests/monitor_template_alert_icon.svg";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { timeStampToReadableFormat } from "utils/CommonUtils";
import { generateId } from "utils/Utils";
import useAccount from "../../layouts/reducer/useAccount";
import { IConversationItem, IConversationType, IShopifyTemplate } from "../ShopifyMonitor";
import { setConfirmMonitorModalData, setIsConfirmMonitorModalOpen, setOnEditClassicBuilderTemplateData } from "../reducer/MonitorReducer";
import useMonitor from "../reducer/useMonitor";
import MonitorCardFooter from "./MonitorCardFooter";
const UserConvoText = styled.p`
  color: #101828;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
`;

const UserConvoProfileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
  margin-bottom: 6px;
  align-items: center;
  justify-content: ${(props: { type: IConversationType }) =>
    props.type === IConversationType.User ? "flex-end" : "flex-start"};
  };
`;

const MonitorCardInnerWrapper = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 0px 0px 4px 4px;
  border-right: 1px solid #eaecf0;
  border-bottom: 1px solid #eaecf0;
  border-left: 1px solid #eaecf0;
  background-color: #fff;
`;

const MonitorCardMailContentWrapper = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: #f2f4f7;
`;

const MonitorCardHeaderTextWrapper = styled.div`
  display: flex;
  padding: 10px 14px;
  align-items: center;
  font-size:12px;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid #eaecf0;
  background: #f2f4f7;
`;

const MonitorCardConversatiionWrapper = styled.div`
  margin-bottom: 24px;
  margin-right: 28px;
`;

const MonitorCardDescText = styled.p`
color: #475467;
/* Text xs/Regular */
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 18px; /* 150% */

`

const MonitorCardEmailTitleText = styled.p`
color: #101828;
/* Text xs/Medium */
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 18px; /* 150% */
display: flex;
flex-direction: row;
align-items: start;
`

const MonitorCardEmailBodyText = styled.p`
color: #475467;
/* Text xs/Regular */
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 18px; /* 150% */
`

interface IMonitorConversationAICard {
  messageData: IConversationItem;
}


const MonitorConversationAICard = ({ messageData }: IMonitorConversationAICard) => {
  const dispatch = useDispatch();
  const { account } = useAccount();
  const {selectedDatasource} = useMonitor();

  const templateData:IShopifyTemplate = {
    templateId: generateId(6),
    title:messageData?.data?.monitorName as string,
    description:messageData?.data?.monitorDescription as string,
    tags:[],
    sqlQuery:messageData?.data?.sqlQuery as string,
    category:"",
    emailSubject:messageData?.data?.emailBody  as string,
    emailBody:messageData?.data?.emailBody  as string,
    accountEmail:account?.primaryContactEmail,
    selectedDataSource:selectedDatasource
  }
  return (
    <>
      <MonitorCardConversatiionWrapper>
        <div style={{
          display:'flex',
          width:'100%',
          justifyContent:'space-between'
        }}>
        <UserConvoProfileWrapper type={IConversationType.AI_TEMPLATE}>
          <img
            src={BlueBeeIcon}
            alt="bluebee"
            style={{ width: "23px", height: "23px" }}
          />
          <UserConvoText>{"BlueBee"}</UserConvoText>
        </UserConvoProfileWrapper>
        <UserConvoProfileWrapper type={IConversationType.AI_TEMPLATE}>
          <UserConvoText>{ timeStampToReadableFormat(messageData.time) }</UserConvoText>
        </UserConvoProfileWrapper>
        </div>
        <div className="">
          <MonitorCardHeaderTextWrapper>
            <p>{messageData?.data?.message}</p>
          </MonitorCardHeaderTextWrapper>

          <MonitorCardInnerWrapper>
            <MonitorCardEmailTitleText>
              <span style={{ marginRight: "8px" }}>
                <img width={"18px"} style={{marginTop:'2px'}} src={monitor_template_alert_icon} />
              </span>{" "}
              {messageData?.data?.monitorName}
            </MonitorCardEmailTitleText>

            <MonitorCardDescText>{messageData?.data?.monitorDescription}</MonitorCardDescText>

            {
              messageData?.data?.emailSubject && messageData?.data?.emailBody &&
              <MonitorCardMailContentWrapper>
                <MonitorCardEmailBodyText>{messageData?.data?.queryDescription}</MonitorCardEmailBodyText>
              </MonitorCardMailContentWrapper>
            }
            <MonitorCardFooter 
              isCreateMonitorLoading={false}
              isMonitorExecuteLoading={false}
              onCreateClick={() => {
                dispatch(setConfirmMonitorModalData(templateData));
                dispatch(setIsConfirmMonitorModalOpen(true));
              }}
            />
          </MonitorCardInnerWrapper>
        </div>
      </MonitorCardConversatiionWrapper>
    </>
  );
};

// export default MonitorConversationAICard;
export  default React.memo(MonitorConversationAICard);
