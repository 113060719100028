import NoCronImage from "assests/no_cron_image.svg";
import { AiOutlinePlus } from "react-icons/ai";
import styled from "styled-components";
import { ZButton } from "views/commonComponents/commonComponents.styles";

const PlaceholderIcon = styled.img`
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
`;
const NoCronImageIcon = styled.img`
  position: relative;
  width: 152px;
  height: 120px;
`;
const Text8 = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 24px;
`;
const SupportingText2 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  display: none;
`;
const NoCronText = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`;
const Content1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`;
const Text9 = styled.div`
  position: relative;
  line-height: 24px;
  font-weight: 600;
`;
const Button6 = styled.div`
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border: 1px solid #d0d5dd;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 18px;
  gap: 8px;
`;
const NoCronButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  text-align: left;
  color: #344154;
`;
const EmptyState = styled.div`
  width: 352px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
`;
const NoCronContainer = styled.div`
  width: 512px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const NoCronDiv = styled.div`
  align-self: stretch;
  flex: 1;
  height: 100%;
  border-radius: 16px;
  background-color: #f2f4f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  text-align: center;
  font-size: 16px;
  color: #101828;
`;

interface INoCronContent {
  onCreateAutomation: (e: any) => void;
  hideCreateButton: boolean;
  btnName:string;
  title:string;
  label:string;
}

const NoCronContent = ({
  onCreateAutomation,
  hideCreateButton,
  btnName,
  title,
  label
}: INoCronContent) => {
  return (
    <NoCronDiv>
      <NoCronContainer>
        <EmptyState>
          <Content1>
            <NoCronImageIcon alt="" src={NoCronImage} />
            <NoCronText>
              <Text8>{title}</Text8>
              <SupportingText2>
                {label}
              </SupportingText2>
            </NoCronText>
          </Content1>
          <NoCronButton>
            <Button6>
              <PlaceholderIcon alt="" src="/placeholder1.svg" />
              <Text9>Clear search</Text9>
              <PlaceholderIcon alt="" src="/placeholder1.svg" />
            </Button6>
            {!hideCreateButton && (
              <ZButton
                startIcon={<AiOutlinePlus />}
                variant="contained"
                onClick={(e: any) => {
                  onCreateAutomation(e);
                }}>
                {btnName}
              </ZButton>
            )}
          </NoCronButton>
        </EmptyState>
      </NoCronContainer>
    </NoCronDiv>
  );
};

export default NoCronContent;
