import React, { useEffect } from 'react';
import { Card, CardHeader, CardContent } from '@material-ui/core';


import { TaskService } from '../../providers/data/services/TaskService';
import { PageCategoryEnum, recordPageVisit } from '../../config/Rudderstack';
import useMenu from 'views/layouts/reducer/useMenu';

export default function DashBoard(props) {
  const [dashboardUrl, setDashboardUrl] = React.useState('');
  const {selectedEmbedLink} = useMenu()

  useEffect(() => {
    recordPageVisit(PageCategoryEnum.dashboard, 'dashboard');
  }, [props]);

  useEffect(() => {
    setDashboardUrl(selectedEmbedLink);
  }, [selectedEmbedLink]);

  if (dashboardUrl && dashboardUrl.length > 0) {
    // console.log (teams, '********<><><>', `${dashboardUrl}?${teams}`)
    return (
      <Card>
        <div>
          <iframe
            title='iframe'
            src={dashboardUrl}
            frameborder='0'
            width='100%'
            height={window.screen.height * 2}
            allowtransparency
          ></iframe>
        </div>
      </Card>
    );
  }
  return (
    <Card>
      <CardHeader title='No Reports!' />
      <CardContent></CardContent>
    </Card>
  );
}
