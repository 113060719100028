import def from "ajv/dist/vocabularies/discriminator"
import { isEmpty } from "lodash"
import { TeamService } from "providers/data/services/TeamService"
import React, { useEffect } from "react"
import { Controller } from "react-hook-form"
import Select from "react-select"

type Props = {
  name: string
  defaultValue?: any
  teams: any[]
  control: any
}

const SelectTeamDropdown = ({
  name,
  defaultValue = [],
  teams,
  control,
}: Props) => {
  const options = teams.map((team) => ({
    value: team.teamId,
    label: team.name,
  }))

  const defaultOptions = teams
    .filter((team) => defaultValue.includes(team.teamId))
    .map((team) => ({
      value: team.teamId,
      label: team.name,
    }))

  return !isEmpty(teams) ? (
    <Controller
      name={name}
      control={control}
      defaultValue={
        // [{value: 'uh34q0', label: "India Team"}]
        defaultOptions
      }
      render={({ field }) => (
        <Select
          {...field}
          options={options}
          isMulti
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder="All Teams"
          styles={{ container: (provided) => ({ ...provided, width: "100%" }),
          placeholder: (defaultStyles) => {
            return {
              ...defaultStyles,
              color: '#101828',
            }
          },
          multiValue: (styles) => {
            return {
              ...styles,
              border: "1px solid #D0D5DD",
              borderRadius: "6px",
              backgroundColor: 'none', // change background color of the pill
            };
          },
          multiValueLabel: (styles) => {
            return {
              ...styles,
              color: '#101828', // change text color inside the pill
            };
          },
         }}
        />
      )}
    />
  ) : (
    <div>No Teams</div>
  )
}

export default SelectTeamDropdown
