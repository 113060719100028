// create a custom hook that listens to screen width change event and returns width

// src/hooks/useWidth.tsx

import { useState, useEffect } from "react";

const useWidth = (breakpoint?:number) => {

    const [width, setWidth] = useState(window.innerWidth);
    breakpoint = breakpoint || 500;
    useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
        // subscribe to window resize event "onComponentDidMount"
        window.addEventListener("resize", handleResizeWindow);
        return () => {
        // unsubscribe "onComponentDestroy"
        window.removeEventListener("resize", handleResizeWindow);
        };
    }, []);

    return width > breakpoint;

}

export default useWidth;

