import 'leaflet/dist/leaflet.css';
import { CreateTask } from '../../render-engine/create/CreateTask';
import EditTask from '../../render-engine/edit/EditTask';
import { ShowTask } from '../../render-engine/show/ShowTask';
import { TaskShow } from '../tasks/screens/task/details/TaskDetails';
import TaskEdit from '../tasks/screens/task/edit/TaskEdit';
import CreateTaskOld from '../tasks/TaskCreation';

export const TaskCreate = (props) => {
  const queryParams = Object.fromEntries(new URLSearchParams(props.location.search.substring(1)));
  console.log(">>>>> queryParams", queryParams)
  if (queryParams['legacy'])
    return <CreateTaskOld key={Date.now()} record={props} taskType={queryParams['taskType']} />;
  else return <CreateTask key={Date.now()} taskId={queryParams["taskId"] || ""} />;
};

export const TaskShowSection = (props) => {
  const queryParams = Object.fromEntries(new URLSearchParams(props.location.search.substring(1)));
  if (queryParams['legacy'] !== undefined) {
    return <TaskShow {...props} />;
  } else return <ShowTask {...props} />;
};

export const TaskEditSection = (props) => {
  const preview = localStorage.getItem('isPreview') || false;
  const queryParams = Object.fromEntries(new URLSearchParams(props.location.search.substring(1)));
  if (queryParams['legacy']) return <TaskEdit record={props} />;
  else return <EditTask record={props} preview={preview} />;
};
