import { Box } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import styled from "styled-components";

interface IMonitorITable {
  tableData: any;
  headerTopic: string;
  executionTime?: string;
  showTotalCount?: boolean;
}

const ExecutionTimeText = styled.p`
  color: black;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

const TotalCountlabel = styled.p`
  color: black;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

const TotalCountValue = styled.p`
  color: black;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
`;

const MonitorText = styled.p`
  color: black;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

const MonitorTable = ({ tableData, headerTopic, executionTime, showTotalCount = false }: IMonitorITable) => {
  const columnData = Object.keys(tableData?.[0] || {});
  const isResultTable = executionTime && showTotalCount;
  return (
    <>
      <Box display={'flex'} flexDirection={'column'}>
        <div style={{ marginTop: '24px', border: '1px solid #D0D5DD', width: '900px', borderBottom: '0px solid black', borderTopRightRadius: '4px', borderTopLeftRadius: '4px', backgroundColor: '#F9FAFB' }}>
          <p style={{ margin: '12px 24px 12px 24px', color: 'black', fontSize: '12px', fontWeight:500, fontFamily:'Inter' }}>{headerTopic}</p>
        </div>

        <TableContainer component={Paper} sx={{ border: '1px solid #D0D5DD', borderRadius: '0px' }}
          style={{
            border: '1px solid #D0D5DD', borderRadius: '0px', width: '900px',
            borderBottomRightRadius: isResultTable ? '0px' : '4px',
            borderBottomLeftRadius: isResultTable ? '0px' : '4px',
          }}>
          {

            tableData?.length > 0 ?

              <Table stickyHeader style={{ minWidth: '900px', maxWidth: '900px', width: '900px' }} sx={{ minWidth: '900px', maxWidth: '900px', width: '900px' }} aria-label="simple table">
                <TableHead style={{ backgroundColor: '#F9FAFB', width: '900px' }}>
                  <TableRow style={{ backgroundColor: '#F9FAFB', width: '900px' }}>

                    {
                      columnData.map((column, index) => {
                        return (
                          <>
                            {
                              index == 0 ?
                                <TableCell style={{ backgroundColor: '#F9FAFB', borderRight: '1px solid #EAECF0', color: '#475467', fontWeight: 500, fontSize: '12px', paddingLeft: '24px',fontFamily:'Inter' }} align="left">{column}</TableCell> :
                                <TableCell key={index} style={{ backgroundColor: '#F9FAFB', borderRight: '1px solid #EAECF0', color: '#475467', fontWeight: 500, fontSize: '12px', paddingLeft: '24px',fontFamily:'Inter' }} align="left">{column}</TableCell>
                            }
                          </>
                        )
                      })

                    }

                  </TableRow>
                </TableHead>
                <TableBody
                  style={{ width: '900px' }}
                  sx={{ width: '900px' }}
                >
                  {tableData.map((row: any, index: number) => (
                    <TableRow
                      key={index}
                      style={{ width: '900px' }}
                      sx={{
                        // '&:last-child td, &:last-child th': { border: 0 }, 
                        width: '900px'
                      }}>
                      {
                        Object.keys(row ?? {}).map((key, index) => {
                          const cellValue = typeof row?.[key] == 'object' ? JSON.stringify(row?.[key]) : row?.[key];
                          return (
                            <>
                              {
                                index == 0 ?
                                  <TableCell style={{ borderRight: '1px solid #EAECF0', paddingLeft: '24px', fontFamily:'Inter',fontSize:'12px' }} align="left" component="th" scope="row">{cellValue}</TableCell> :
                                  <TableCell key={index} style={{ borderRight: '1px solid #EAECF0',fontFamily:'Inter',fontSize:'12px' }} align="left">{cellValue}</TableCell>
                              }
                            </>
                          )
                        })
                      }

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              :
              <Box width={'100%'} display={'flex'} justifyContent={'center'} height={'100%'} alignItems={'center'}>
                <MonitorText>No Data</MonitorText>
              </Box>

          }

        </TableContainer>

        {
          isResultTable &&
          <div style={{
            border: '1px solid #D0D5DD',
            width: '900px',
            borderTop: '0px solid black',
            backgroundColor: '#F9FAFB',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '12px 24px',
            borderBottomRightRadius: '4px',
            borderBottomLeftRadius: '4px',
          }}>
            <div style={{
              display: 'flex',
            }}>
              <TotalCountlabel>Total Records Found:&nbsp;</TotalCountlabel>
              <TotalCountValue>{tableData?.length || 0}</TotalCountValue>

{
tableData?.length > 25 && <TotalCountlabel>&nbsp;|&nbsp;Only single alert will be triggered if the total count is greater than 25 </TotalCountlabel>
}

            </div>
            <ExecutionTimeText>{executionTime}</ExecutionTimeText>
          </div>
        }

      </Box>
    </>
  );
}

export const MonitorITable = React.memo(MonitorTable);