import * as React from "react";
import { Button } from "views/DataField/DataField.styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { BiCross, BiErrorAlt } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import styled from "styled-components";
import { Paper } from "@mui/material";
import { ZButton } from "views/commonComponents/commonComponents.styles";

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
`;

export const IconWrapper = styled.div`
  padding: 8px;
  background-color: #fee4e2;
  border-radius: 8px;
`;

export default function DeletionConfirmationDialog({
  id,
  deletionTitle,
  deletionText,
  isOpen,
  onConfirmDelete,
  onClose,
  variant = "danger",
  primaryButtonName = "Yes",
  secondaryButtonName = "Cancel",
}) {
  const [open, setOpen] = React.useState(isOpen);

  React.useEffect(() => {
    setOpen(isOpen);
  });

  return (
    <Dialog
      maxWidth={false}
      open={open}
      onClose={onClose}
      className='no__nodeClick'
      aria-labelledby="title"
      PaperProps={{
        className: "no__nodeClick",
        style: {
          borderRadius: 16,
        },
      }}
    >
      <Paper
        className="no__nodeClick"
        style={{
          width: 400,
          padding:16
        }}
      >
        {variant === "danger" && (
          <Flex className="no__nodeClick">
            <IconWrapper className="no__nodeClick">
              <BiErrorAlt
                size={"2rem"}
                color="#D92D20"
                className="dialog__icon no__nodeClick"
                onClick={onClose}
              />
            </IconWrapper>
            {/* <AiOutlineClose className="no__nodeClick" size={"1.8rem"} /> */}
          </Flex>
        )}

        <DialogTitle className="no__nodeClick" style={{ padding: '10px 12px' }} id="title">
          <p className="dialog__title no__nodeClick"> {deletionTitle} </p>
        </DialogTitle>
        <DialogContent className="no__nodeClick" style={{ padding: '12px 12px' }}>
          <DialogContentText>
            <p className="dialog__content no__nodeClick"> {deletionText} </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="no__nodeClick">
          <ZButton variant="contained" style={{ display: 'inline-block', width: '50%',marginRight:"0px" }} className="no__nodeClick" secondary autoFocus onClick={onClose}>
            {secondaryButtonName}
          </ZButton>
          <ZButton
            style={{ display: 'inline-block', width: '50%',marginRight:"0px"  }}
            className="no__nodeClick"
            variant="contained"
            danger={variant === "danger"}
            onClick={(e) => {
              onConfirmDelete(id);
              onClose();
              e.stopPropagation();
            }}
            autoFocus
          >
            {primaryButtonName}
          </ZButton>
        </DialogActions>
      </Paper>
    </Dialog>
  );
}
