// taskId, taskType, taskTypeDisplayName, taskStatus, taskStatusName, accountId, accountName
export const staticFilterData = {
  coreDataFields:
  [
    {
      "name": "taskId",
      "value": "taskId"
    },
    {
      "name": "taskType",
      "value": "taskType"
    },
    {
      "name": "taskTypeDisplayName",
      "value": "taskTypeDisplayName"
    },
    {
      "name": "taskStatus",
      "value": "taskStatus"
    },
    {
      "name": "taskStatusName",
      "value": "taskStatusName"
    },
    {
      "name": "accountId",
      "value": "accountId"
    },
    {
      "name": "accountName",
      "value": "accountName"
    },
    {
      "name": "createdOn",
      "value": "createdOn",
    },
    {
      "name": "updatedOn",
      "value": "updatedOn"
    },
    {
      "name": "isDeleted",
      "value": "isDeleted"
    },
    {
      "name": "userId",
      "value": "userId"
    },
    {
      "name": "userName",
      "value": "userName"
    },
    {
      "name": "userPhoneNumber",
      "value": "userPhoneNumber"
    },
    {
      "name": "teamIds",
      "value": "teamIds"
    },
    {
      "name": "teamsInfo",
      "value": "teamsInfo"
    },
    {
      "name": "slot",
      "value": "slot"
    }
  ]}