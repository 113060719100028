import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BffService } from 'providers/data/services/BffService';
import { IScriptEditorConfig } from './types/types';
import { EntityService } from 'providers/data/services/EntityService';
import { ABMetadataTypeEnum } from 'views/automationBuilder/types/ABType';
import { CronService } from 'providers/data/services/CronService';
import { BuilderService } from 'providers/data/services/BuilderService';
import { MonitorService } from "providers/data/services/MonitorService";
import { AccountService } from 'providers/data/services/AccountService';
import { getAccountId } from 'utils/CommonUtils';
import { teamUserAutomationDefaultMockData } from 'constants/CommonConstants';

const initialState = {
};

export const initEditor = createAsyncThunk('scriptEditor/fetchScriptVariable', async (taskTypeId: string, prevMockData:any={}) => {
  if (!taskTypeId) {
    return await new Promise((resolve) => {
      resolve({
        variables: [],
        mockData: [],
      });
    });
  } else {
    const scriptVariables = await BffService.getScriptVariables(taskTypeId)
      .then((res) => {
        if (res?.code && res?.code.indexOf('200') < 0) {
          if (res?.code === '401') {
          } else {
          }
        } else {
          return res.data;
        }
      })
      .catch((err) => {
        console.log({ err });
      });


    return { scriptVariables };
  }
});

// TODO: Shift the mockData schema logic here as well if needed
export const initMockData = createAsyncThunk("scriptEditor/fetchMockData", async ({taskTypeId, type="workflow"}:{taskTypeId:string, type?:string}) => {

    if (type === "workflow" && taskTypeId) {
      const mockData = await BuilderService.getMockData(taskTypeId)
      .then((res) => {
        
          return res?.data?.mockData;
        
      })
      .catch((err) => {
        console.log({ err });
      });
    return { mockData:mockData || {} };
    } else if (type === ABMetadataTypeEnum.CRON) {
      const mockData = await CronService.fetchCronMockData(taskTypeId)
      .then((res) => {
          return res?.data?.mockData;
      })
      .catch((err) => {
        console.log({ err });
      });
    return { mockData:mockData || {} };
    } else if (type === ABMetadataTypeEnum.WEBHOOK) {
      const mockData = await BffService.fetchWebhook(taskTypeId)
      .then((res)=>{
        return res?.data?.mockData;
      }).catch((err) => {
        console.log({ err });
      });

      return { mockData:mockData || {} };
    } else if (type == ABMetadataTypeEnum.MONITOR){
      const mockData = await MonitorService.fetchMonitorMockData(taskTypeId)
      .then((res) => {
          return res?.data?.mockData;
      })
      .catch((err) => {
        console.log({ err });
      });
      return { mockData:mockData || {} };
    } else if (type == ABMetadataTypeEnum.TEAM || type == ABMetadataTypeEnum.USER) {
      const accountId = getAccountId()
      const mockData = await AccountService.getAccount(accountId)
      .then((res) => {
          return res?.data?.accountConfig?.mockData || teamUserAutomationDefaultMockData;
      })
      .catch((err) => {
        console.log({ err });
      });
      return { mockData:mockData || {} };
    } else {
      const mockData = await EntityService.getMockData(taskTypeId)
      .then((res) => {
          return res?.data?.mockData;
        
      })
      .catch((err) => {
        console.log({ err });
      });
    return { mockData:mockData || {} };
    }
      
    

})

export const scriptEditorSlice = createSlice({
  name: 'scriptEditor',
  initialState,
  reducers: {
    initScriptEditor: (state: any, action: PayloadAction<IScriptEditorConfig>) => {
      state.script = action.payload.script;
      state.returnParams = action.payload.returnParams;
    },
    updateMockData: (state: any, action: PayloadAction<string>) => {
      state.mockData = action.payload;
    },
    copiedPreDefined: (state: any, action: any) => {
      state.copiedPreDefined = action.payload;
    },
    setMockDataContext: (state: any, action: any) =>{
      // this is used to filter Test data
      // we are showing Test data based on context, we are setting script Variable data.
      state.mockDataContext = action.payload;
    }
  },
  extraReducers: (scriptEditor: any) => {
    scriptEditor
      .addCase(initEditor.pending, (state: any) => {
        state.variables = {};
      })
      .addCase(initEditor.fulfilled, (state: any, action: any) => {
        state.variables = action.payload.scriptVariables;
      })
      .addCase(initMockData.pending, (state:any) => {
        state.mockData = {};
      })
      .addCase(initMockData.fulfilled, (state:any, action:any) => {
          state.mockData = action.payload.mockData;
        
      })

  },
});

export const {
  updateMockData,
  copiedPreDefined,
  initScriptEditor,
  setMockDataContext,
} = scriptEditorSlice.actions;

export default scriptEditorSlice.reducer;
