import Box from '@mui/material/Box';
import FilterSelect from "assests/filter_dropdown_arrow.svg";
import MonitorThisIcon from 'assests/moitor_this.svg';
import { IQueryBuilderFetchColumnsPayload, IRunQueryPayload, MonitorService } from 'providers/data/services/MonitorService';
import React, { useEffect, useState } from 'react';
import { Control, FieldErrors, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { useDispatch } from "react-redux";
import Select, { components } from "react-select";
import { IMonitorInterfaceFormValues } from '../MonitorInterface';
import CollapsableComponent from '../MonitorInterfaceComponents/CollapsableComponent';
import { MonitorITable } from '../MonitorInterfaceComponents/MonitorITable';
import { IDatasourceCategory, IScreenContext, setMonitorInterfaceContext } from '../reducer/MonitorReducer';
import useMonitor from '../reducer/useMonitor';
import MonitorInterfaceSelect from './MonitorInterfaceSelect';
import { TaskService } from 'views/tasks/services/TaskService';
import { getAccountId } from "utils/CommonUtils";
import { EntityService } from 'providers/data/services/EntityService';
import { ITable } from 'views/entities/types';
import notification from 'notifications/notifications';

interface IMonitorThisDatasourceSection {
  control: Control<IMonitorInterfaceFormValues>;
  setValue: UseFormSetValue<IMonitorInterfaceFormValues>;
  errors: FieldErrors<IMonitorInterfaceFormValues>;
  watch: UseFormWatch<IMonitorInterfaceFormValues>;
  onTableValueChange: (queryBuilderFetchColumnsPayload: IQueryBuilderFetchColumnsPayload) => void;
  onDataSourceSelect: () => void;
  clearErrors: any;
}

const MonitorThisDatasourceSection = ({ control, setValue, errors, watch, onTableValueChange, onDataSourceSelect, clearErrors }: IMonitorThisDatasourceSection) => {
  const dispatch = useDispatch();
  const accountId = getAccountId();
  const { datasourceListData, monitorInterfaceContext, selectedMonitor, clearMonitorState } = useMonitor();
  const isMonitorInEditMode = monitorInterfaceContext?.monitorScreenContext == IScreenContext.EDIT;
  const [isTableListLoading, setIsTableListLoading] = useState<boolean>(false);
  const [tableOptionData, setTableOptionData] = useState<any[]>([]);
  const [sampleTableDataLoading, setSampleTableDataLoading] = useState<boolean>(false);
  const [sampleQueryResultOutput, setSampleQueryResultOutput] = useState<any[]>([]);
  const [tableNames, setTableNames] = useState<string>(watch()?.tableName?.label || watch()?.tableName as string || '');
  const DropdownIndicator = () => (
    <img
      src={FilterSelect}
      style={{ paddingRight: "14px" }}
      className="cursor-pointer"
    />
  );

  const SingleValue = ({ children, ...props }: any) => (
    <>
      <components.SingleValue {...props} style={{ display: 'flex' }} >
        <div className=""> {props.data.label}</div>
      </components.SingleValue>
    </>
  )

  useEffect(() => {
    const datasourceId = watch()?.datasourceId?.value as string;
    if (isMonitorInEditMode && datasourceId) {
      fetchTableInfo(datasourceId);
    }
  }, [isMonitorInEditMode])

  const fetchTableInfo = async (datasourceId: string) => {
    const tableListData:any =  await fetchSchemaDetails(datasourceId);
    const selectedTableValue = tableListData?.find((data:any) => data.value == monitorInterfaceContext?.monitorData?.tableName);
    setValue('tableName', selectedTableValue);
    
    if (monitorInterfaceContext?.monitorData?.tableName) {
      setSampleTableDataLoading(true);

      const runQueryPayload: IRunQueryPayload = {
        datasourceId: (datasourceId) as string,
        query: 'SELECT * FROM ' + monitorInterfaceContext?.monitorData?.tableName + ' limit 5',
        type: 'SELECT',
      }

      const sampleQueryResultOutput = await MonitorService.runQuery(runQueryPayload)
      setSampleTableDataLoading(false);

      setSampleQueryResultOutput(sampleQueryResultOutput?.data?.queryResponse || [])

      const queryBuilderFetchColumnsPayload: IQueryBuilderFetchColumnsPayload = {
        datasourceId: (watch()?.datasourceId?.value) as string,
        tableName: monitorInterfaceContext?.monitorData?.tableName?.split?.('.')?.[1],
        schema: monitorInterfaceContext?.monitorData?.tableName?.split('.')[0],
        datasourceType: datasourceListData.find((data) => data.datasourceId == (datasourceId))?.datasourceType as string
        // datasourceType: (watch()?.datasourceId?.datasourceType) as string
      }

      onTableValueChange(queryBuilderFetchColumnsPayload)
    } else {
      setSampleQueryResultOutput([]);
      setValue('tableName', undefined);
    }
  }


  const fetchSchemaDetails = async (datasourceId:string, datasourceCategory?:IDatasourceCategory) =>{
    setIsTableListLoading(true);
    const fetchSchemaResponse = await MonitorService.fetchSchema(datasourceId)
    if (fetchSchemaResponse){

    let tableListData:any = Object.keys(fetchSchemaResponse.data).flatMap(schemaName => Object.keys(fetchSchemaResponse.data[schemaName]).map(tableName => {
      return { 
        value: schemaName + '.' + tableName, 
        label: schemaName + '.' + tableName, 
        tableName: tableName }
    }
      ))

    if (datasourceCategory === IDatasourceCategory.ZORP_DEFAULT_CLICKHOUSE) {
      const fetchWorkflowNames:any = await TaskService.getWorkflowList(accountId);

      const fetchTableNames:ITable[] | any = await EntityService.getEntities()

      if (fetchWorkflowNames?.data.length > 0 || fetchTableNames?.length > 0) {

        tableListData = tableListData.map((data: any) => {
          const Id = data.label.replaceAll('all_records_history', '').replaceAll('all_records', '').replaceAll('zorp.', '').replaceAll('all_task_history_', '').replaceAll('all_tasks_', '').replaceAll('all_records_', '').replaceAll('_comments', '').replaceAll('tasks_', '').replaceAll('zorp.', '').replaceAll('tickets_', '').replaceAll('_', '').replaceAll('records_', '').replaceAll('records', '').replaceAll('tasks', '').replaceAll('tickets', '').replaceAll('comments', '').replaceAll('history', '');
          let workflowName;
          let entityName;
          if (Id) {
            workflowName = fetchWorkflowNames?.data.find((data: any) => data.taskType == Id)?.taskTypeDisplayName

            entityName = fetchTableNames?.find((data: any) => data.tableType == Id)?.tableDisplayName
          }
          const description = workflowName || entityName;
          return {
            label: data.label + (description ? ' - ' + description : ''),
            value: data.value,
            tableName: data.tableName,
            description: description
          }
        }).filter((data: any) => data.description)
      }
    }
    setIsTableListLoading(false);
    setTableOptionData(tableListData);

    return tableListData;
  } else {
    notification('error', 'Error fetching schema details')
    return []
  }

  }

  return (
    <>
      <CollapsableComponent
        imgIcon={<img style={{ paddingRight: '8px' }} src={MonitorThisIcon} alt='Icon' />}
        color='#027A48'
        title='Monitor this datasource'
        collapseState={!monitorInterfaceContext?.monitorComponentsData?.isdDatasourceSectionHidden} onClick={() => {
          dispatch(setMonitorInterfaceContext({
            ...monitorInterfaceContext, // Spread the previous state to retain other values
            monitorComponentsData: {
              ...monitorInterfaceContext?.monitorComponentsData, // Spread the previous monitorComponentsData
              isdDatasourceSectionHidden: !monitorInterfaceContext?.monitorComponentsData?.isdDatasourceSectionHidden // Set your desired property
            }
          }));
        }} />

      {
        monitorInterfaceContext?.monitorComponentsData?.isdDatasourceSectionHidden &&
        <Box display={'flex'} marginTop={'24px'} width={'100%'} flexDirection={'column'} justifyContent={'center'}>

          <Box display={'flex'} flexDirection={'row'} alignItems={'flex-start'} gap={'12px'} width={'100%'} justifyContent={'center'} height={'36px'}>

            <Box width={'190px'} height={'36px'}>
              <MonitorInterfaceSelect
                control={control}
                name={"datasourceId"}
                errors={errors}
                options={datasourceListData.map((data) => ({ label: data.displayName, value: data.datasourceId, datasourceCategory:data?.datasourceCategory }))}
                isLoading={false}
                placeholder='Select Datasource'
                isDisabled={false}
                rules={{ required: "Data source type is required" }}
                onChange={async (datasourceValue: any) => {
                  setValue('datasourceId', datasourceValue)
                  await fetchSchemaDetails(datasourceValue.value, datasourceValue.datasourceCategory);

                  dispatch(setMonitorInterfaceContext({
                    ...monitorInterfaceContext, // Spread the previous state to retain other values
                    monitorComponentsData: {
                      ...monitorInterfaceContext?.monitorComponentsData, // Spread the previous monitorComponentsData
                      isConditionSectionHidden: true
                    }
                  }));
                  onDataSourceSelect();
                }}
              />
            </Box>

            <Box width={'300px'} height={'36px'}>
              <MonitorInterfaceSelect
                control={control}
                name={"tableName"}
                errors={errors}
                options={tableOptionData}
                placeholder='Select Table'
                isLoading={isTableListLoading}
                isDisabled={isTableListLoading}
                onChange={async (datasourceValue: any) => {
                  if (datasourceValue?.value) {
                    clearErrors('tableName')
                    setSampleTableDataLoading(true);
                    setTableNames(datasourceValue?.label);
                    setValue('tableName', datasourceValue);

                    const runQueryPayload: IRunQueryPayload = {
                      datasourceId: (watch()?.datasourceId.value) as string,
                      query: 'SELECT * FROM ' + datasourceValue?.value + ' limit 5',
                      type: 'SELECT',
                    }

                    const sampleQueryResultOutput = await MonitorService.runQuery(runQueryPayload)
                    setSampleTableDataLoading(false);

                    setSampleQueryResultOutput(sampleQueryResultOutput?.data?.queryResponse || [])

                    const queryBuilderFetchColumnsPayload: IQueryBuilderFetchColumnsPayload = {
                      datasourceId: (watch()?.datasourceId?.value) as string,
                      tableName: datasourceValue?.value?.split('.')?.[1],
                      schema: datasourceValue?.value.split('.')[0],
                      datasourceType: datasourceListData.find((data) => data.datasourceId == (watch()?.datasourceId.value))?.datasourceType as string
                      // datasourceType: (watch()?.datasourceId?.datasourceType) as string
                    }

                    onTableValueChange(queryBuilderFetchColumnsPayload)

                    dispatch(setMonitorInterfaceContext({
                      ...monitorInterfaceContext, // Spread the previous state to retain other values
                      monitorComponentsData: {
                        ...monitorInterfaceContext?.monitorComponentsData, // Spread the previous monitorComponentsData
                        selectedTableName: datasourceValue?.value
                      }
                    }));
                  } else {
                    setSampleQueryResultOutput([]);
                    setValue('tableName', undefined);
                  }
                }}
              />
            </Box>

{false &&
            <Box display={'flex'} width={'242px'} gap={'12px'}>
              <Box height={'32px'} alignItems={'center'} display={'flex'}>
                <p>or</p>
              </Box>

              <Select
                components={{ IndicatorSeparator: () => null, DropdownIndicator, SingleValue }}
                options={[{ label: 'Cron Expression', value: 'Cron Expression' }]}
                placeholder='Choose Sample'
                isClearable
                onChange={() => {
                }}
                styles={{
                  groupHeading: (base) => ({
                    ...base,
                    flex: "1 1",
                    color: "white",
                    margin: 0,
                  }),
                  control: (base: any, state: any) => ({
                    ...base,
                    width: "176px",
                    "min-height": "32px",
                    minHeight: "32px",
                    alignContent: "center",
                    alignItems: "center",
                  }),
                  option: (provided: any, state: { isSelected: any }) => ({
                    ...provided,
                    paddingLeft: "24px",
                    paddingTop: "6px",
                    paddingBottom: "6px",
                    fontWeight: 400,
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                    fontSize: " 14px",
                  }),
                  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                    color: "#7E7E7F",
                  }),
                }}
              />
            </Box>
            }
          </Box>

          {
            sampleQueryResultOutput.length > 0 &&
            <Box display={'flex'} justifyContent={"center"} height={'220px'}>
              <MonitorITable 
              tableData={sampleQueryResultOutput} 
              headerTopic={`Sample Data for ${tableNames}`} />
            </Box>
          }

        </Box>
      }
    </>
  )
}

// export default MonitorThisDatasourceSection

export  default React.memo(MonitorThisDatasourceSection);