import LoadingButton from '@mui/lab/LoadingButton';
import { Box } from '@mui/material';
import monitorFineTuneBlack from "assests/monitor_fine_tune_white.svg";
import React from 'react';
import { HeadingText } from '../Monitor.styles';
import CreateFromScratchBtn, { IMonitorCreateBtnContext } from './CreateFromScratchBtn';

interface IMonitorListHeader {
    openTemplatePage: () => void;
}

const MonitorListHeader = ({ openTemplatePage }: IMonitorListHeader) => {
    
    return (
        <>
            <Box padding={'24px'} width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <HeadingText>All My Alerts</HeadingText>

                <Box display={'flex'} gap={'8px'}>
                    <LoadingButton
                        color='secondary'
                        loadingPosition='start'
                        startIcon={<img style={{ paddingRight: '5px' }} src={monitorFineTuneBlack} alt='Icon' />}
                        onClick={async () => {
                            openTemplatePage()
                        }}
                        style={{
                            borderRadius: '4px',
                            height: '36px',
                            color: '#fff',
                            fontSize: '12px',
                            fontWeight: 'normal',
                            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                            backgroundColor: '#3C69E7',
                            border: '1px solid var(--Gray-300, #D0D5DD)',
                            padding: '8px 14px',
                            textTransform: 'none',
                            fontFamily:'Inter',
                            fontStyle:'normal'
                        }}
                        sx={{ height: '36px' }} variant="contained">
                        AI Templates
                    </LoadingButton>
                    <CreateFromScratchBtn
                    context={IMonitorCreateBtnContext.MONITOR}
                    />
                </Box>
            </Box>
        </>
    )
}

export default React.memo(MonitorListHeader);