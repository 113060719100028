import { CronService } from "providers/data/services/CronService";
import { Dispatch } from "react";
import { setSelectedCron } from "views/automationBuilder/reducer/automationReducer";
import { IAutomation } from "views/automationBuilder/types/ABType";
import notification from "notifications/notifications";
export const accountAutomationSaveHandler = async ({
    allAutomations,
    dispatch,
    store,
    callback,
}: {
    allAutomations: IAutomation[];
    dispatch: Dispatch<any>;
    store?: any;
    callback: () => void;
}) => {
    const automationData = allAutomations[0];
    const selectedCron = store?.getState()?.automation?.selectedCron;

    const createAutomationPayload = {
        name : automationData.name,
        schedule : automationData.triggers[0].event.trigger,
        payload: automationData,
        isInitiated:true,
        timeZone:automationData.triggers[0].event.timezone.name,
        isActive:selectedCron?.isInitiated == false ? true : selectedCron?.isActive
    }
    const response = await CronService.updateCronData(selectedCron.cronId, createAutomationPayload);
    
    if (response?.code == '200') {
        dispatch(setSelectedCron({}));
        notification("success", "Cron Updated")
        callback()
    } else {
        notification("error", response?.message || `Cron Update Failed`)
    }
};