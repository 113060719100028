import { useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { DecodedAuthToken } from 'providers/data/models/UserType';

const useLoggedInUser = () => {
  const [authToken, setAuthToken] = useState<DecodedAuthToken|null>(null);

  useEffect(() => {
    const authTokenFromStorage = localStorage.getItem('at');

    if (authTokenFromStorage) {
      try {
        const decodedAuthToken:DecodedAuthToken = jwt_decode(authTokenFromStorage);
        setAuthToken(decodedAuthToken);
      } catch (error:any) {
        console.error(`Error decoding auth token: ${error.message}`);
      }
    }
  }, []);

  return authToken;
};

export default useLoggedInUser;
