import React, { FunctionComponent, useState, useEffect } from "react";

export const ZEmpty: FunctionComponent<{}> = () => {
    return (
        <>
            <div className="col-span-1 font-semibold"></div>
            <div className="col-span-3"></div>
        </>
    );

}