import React from "react";
import styled from "styled-components";
import { MdArrowUpward } from "react-icons/md";
import { recordEvent } from "config/Rudderstack";
import { getEnv } from "utils/CommonUtils";
import jwt_decode from "jwt-decode";

const WidgetContainer = styled.div`
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-height: 100%;
  height: 100%;
  overflow: auto;
  transition: transform 0.2s ease;
  &:hover {
    transform: translateY(-5px);
  }
`;

const Header = styled.div`
  font-size: 14px;
  color: #333;
  font-weight: 600;
  margin-bottom: 8px;
`;

const Description = styled.div`
  font-size: 12px;
  color: #666;
  margin-bottom: 12px;
  text-align: center;
  font-weight: 500;
`;

const Count = styled.div`
  font-size: 28px;
  color: #333;
  font-weight: bold;
  margin-bottom: 8px;
`;

const Change = styled.div`
  font-size: 14px;
  color: ${({ increase }) => (increase ? "#00cc00" : "#ff0000")};
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  svg {
    margin-right: 8px;
    font-size: 18px;
  }
`;

const ViewButton = styled.button`
  padding: 8px 24px;
  border-radius: 4px;
  background-color: ${(props) =>
    `${props.theme.color.primary} !important`}; !important; // Dictates the active selected color for metric card

  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

interface NumberMetricProps {
  title: string;
  description: string;
  number: number;
  change: number;
  isPositiveChange: boolean;
  link: any;
}

const NumberMetricComponent: React.FC<NumberMetricProps> = ({
  title,
  description,
  number,
  change,
  isPositiveChange,
  link,
}) => {
  let jwtDecoded: any = jwt_decode(localStorage.getItem("at"));

  const handleClick = (event: any) => {
    recordEvent("Number Widget Clicked", {
      env: getEnv(),
      user: `${jwtDecoded?.name} (${jwtDecoded?.userId})`,
      accountId: jwtDecoded?.accountId,
      timestamp: new Date().toISOString(),
      componentTitle: title,
    });

    event.stopPropagation();
    window.location.href = link?.url;
  };

  const stopPropagation = (event: any) => {
    event.stopPropagation();
  };

  return (
    <WidgetContainer
      onMouseDown={stopPropagation}
      onTouchStart={stopPropagation}
    >
      <Header>{title}</Header>
      <Count>{number}</Count>
      {change !== null && change !== undefined && (
        <Change increase={isPositiveChange}>
          <MdArrowUpward />
          {isPositiveChange ? `↑ ${change}%` : `↓ ${Math.abs(change)}%`} vs last
          week
        </Change>
      )}
      {description && <Description>{description}</Description>}
      <ViewButton onClick={handleClick}>{link?.label} →</ViewButton>
    </WidgetContainer>
  );
};

export default NumberMetricComponent;