import React from "react";
import styled from "styled-components";

export interface IMonitorPromptData {
  title: string;
  description: string;
}

interface IMonitorPromptExamples {
  promptExamples: IMonitorPromptData[];
  onTemplateClick: (promptText: string) => void;
}

const ExampleTitleText = styled.p`
  color: #475467;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  margin-bottom: 12px;
`;

const PromptExampleContainer = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  background: #F9FAFB;
  border: 1px solid #EAECF0;
  align-self: stretch;
  margin-bottom: 12px;
  cursor: pointer;
`;

const PromptExampleTitle = styled.p`
  color: black;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
`;

const PromptExampleDesc = styled.p`
  color: black;
  /* Text xs/Regular */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
`;

const MonitorPromptExamples = ({
  promptExamples,
  onTemplateClick,
}: IMonitorPromptExamples) => {
  return (
    <>
      <ExampleTitleText>Examples:</ExampleTitleText>
      {promptExamples.map((promptExample, index) => {
        return (
          <>
            <PromptExampleContainer
            key={index}
              onClick={() => {
                onTemplateClick(promptExample.title);
              }}
            >
              <PromptExampleTitle>{promptExample.title}</PromptExampleTitle>
              <PromptExampleDesc>{promptExample.description}</PromptExampleDesc>
            </PromptExampleContainer>
          </>
        );
      })}
    </>
  );
};

// export default MonitorPromptExamples;
export  default React.memo(MonitorPromptExamples);