import { Grid, Modal } from "@mui/material";
import VariableTreeDataFieldListAndSelector from "lowcode/VariableTreeDataFieldListAndSelector";
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { HelperText } from "render-engine/render.styles";
import styled from "styled-components";
import { BffService } from 'providers/data/services/BffService';
import { ZButton } from "views/commonComponents/commonComponents.styles";
import useWorkFlow from "../hooks/useWorkFlow";
import {
  setDataPickerOpen,
  setSelectedAction,
} from "../reducers/workflowAction";
import { ButtonContainer, Container } from "./ModalContent.styles";
import notification from "notifications/notifications";
import usePayload from "views/automationBuilder/hooks/usePayload";
import { FilterComponentType } from "filter/components/FilterDynamicComponent";
import { getUpdateDataFieldIndex } from "utils/Utils";
import { cloneDeep, get, set } from "lodash";
import { EditorScriptLocation } from "lowcode/state/types/types";
import useAutomation from "views/automationBuilder/hooks/useAutomation";
import useEntity from 'views/entities/hooks/useEntity';
import { getAccountId } from "utils/CommonUtils";
const ModalTopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 10px 0px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
`;

const TreeContainer = styled.div`
  height: 79%;
  border: 2px solid #e5e5e5;
  padding: 15px !important;
  margin-top: 15px;
  border-radius: 8px;
`;

const WLCircularLoader = styled(CircularProgress)`
  height: 30px !important;
  width: 30px !important;
`;

type Props = {
  setValue: UseFormSetValue<any>;
  updateDataField?: boolean;
  getValues?: Function;
  selectDataField?: () => void;
};

const DatafieldSelector = ({
  setValue,
  updateDataField,
  getValues,
  selectDataField,
}: Props) => {
  const { dataPicker, selectedAction, taskTypeId } = useWorkFlow();
  const { selectedEntity } = useEntity();
  const { isEntityAutomation, isCronAutomation, isMonitorAutomation, selectedMonitorId, selectedCron, isTeamAutomation, isUserAutomation, isWebHookAutomation, selectedWebhook } = useAutomation();
  const dispatch = useDispatch();
  const accountId = getAccountId();
  const { actionConditionPayload } = usePayload();
  const [dataFieldsList, setDataFieldsList] = useState();
  const [selectedDatafieldTypes, setSelectedDatafieldTypes] = useState<string>("");
  const [loadingVariableTree, setLoadingVariableTree] = useState(false);


  const addDatafield = () => {
    // For updated datafield we use react hook forms.
    if (updateDataField && dataPicker.name != EditorScriptLocation.ERROR_MESSAGE) {
      // Adding space if something is already there else don't add space.
      const current =
        getValues && getValues(dataPicker.name)
          ? getValues(dataPicker.name) + " "
          : "";
      setValue(
        dataPicker.name,
        `${current}` + "" + "${" + selectedDatafieldTypes + "}"
      );

      const UpdateDataFieldIndex = getUpdateDataFieldIndex(dataPicker.name);

      dispatch(setDataPickerOpen({ isOpened: false, name: "" }));

      if (UpdateDataFieldIndex >= 0){
        const updateDataFieldData = cloneDeep(selectedAction.updateDataFieldData);
        updateDataFieldData[UpdateDataFieldIndex][Object.keys(updateDataFieldData[UpdateDataFieldIndex])[0]].value += "$"+`{${selectedDatafieldTypes}}` 

        const newActionData = {...selectedAction,updateDataFieldData };

        dispatch(setSelectedAction(newActionData));
      }

    } else {
      const currSelectedAction = { ...selectedAction };

      // for LIST_OF_TEXT dataType, remove the previous value and add datatype
      if (dataPicker.dataType === "LIST_OF_TEXT") {
        // Adding space if something is already there else don't add space.
        setTimeout(() => {
          dispatch(
            setSelectedAction({
              ...currSelectedAction,
              hasChanged: true,
              [dataPicker.name]: {
                ...currSelectedAction[dataPicker.name],
                type: "literal",
                value:
                  "${" +
                  selectedDatafieldTypes +
                  "}",
                dataType: dataPicker.dataType,
              },
            })
          );
        }, 1);
        // dispatch(setSelectedAction(null))
      } else {
        // Adding space if something is already there else don't add space.
        const prevValue =  get(currSelectedAction, dataPicker.name)
       
        let fullValue = '';

        // when the textfield is empty then the type will be empty
        if(prevValue?.type == 'script'){
          fullValue = "${" + selectedDatafieldTypes + "}";
        }else{
          if (prevValue?.dataType == FilterComponentType.BOOLEAN) {
            fullValue = "${" + selectedDatafieldTypes + "}";
          }else {
            fullValue = (prevValue?.value || '') + " ${" + selectedDatafieldTypes + "}";

          }
        }

        let tempData = cloneDeep(selectedAction);
        tempData.hasChanged = true;
        set(tempData, dataPicker.name, {
          type: "literal",
          value: fullValue,
          dataType: dataPicker.dataType,
        })

        setTimeout(() => {
          dispatch(setSelectedAction(tempData));
        }, 1);
      }

      dispatch(setDataPickerOpen({ isOpened: false, name: "" }));
    }
    selectDataField?.();
  };


  useEffect(() => {
    setLoadingVariableTree(true);
    let variableValueResponse: any;

    if (isCronAutomation && selectedCron?.cronId) {
        variableValueResponse = BffService.getScriptVariableWithResponse(selectedCron?.cronId,actionConditionPayload)
    } else if (isEntityAutomation && selectedEntity?.tableType) {
        variableValueResponse = BffService.getScriptVariableWithResponse(selectedEntity?.tableType,actionConditionPayload);
    } else if (isTeamAutomation || isUserAutomation) {
        variableValueResponse = BffService.getScriptVariableWithResponse(accountId, actionConditionPayload)
    } else if (isMonitorAutomation) {
        variableValueResponse = BffService.getScriptVariableWithResponse(selectedMonitorId, actionConditionPayload);
    } else if (isWebHookAutomation) {
        variableValueResponse = BffService.getScriptVariableWithResponse(selectedWebhook?.webhookId, actionConditionPayload);
    } else if (taskTypeId) {
        variableValueResponse = BffService.getScriptVariableWithResponse(taskTypeId, actionConditionPayload);
    }
    variableValueResponse.then((res) => {
      if (res?.code == '200') {
        setLoadingVariableTree(false);
        setDataFieldsList(res.data);
      } else {
        setLoadingVariableTree(false);
        notification('error', 'Error while fetching Variables');
      }
    })
      .catch((err) => {
        setLoadingVariableTree(false);
        notification('error', 'Error while fetching API Documentation');
      })
  }, [])


  return (
    <Modal
      open={dataPicker?.isOpened}
      onClose={() => dispatch(setDataPickerOpen(false))}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-datapicker"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Container>
        <ModalTopBar>
          <div>
            <p> Select the Datafield </p>
          </div>
          <AiOutlineClose className="pointer" onClick={() => dispatch(setDataPickerOpen(false))} />
        </ModalTopBar>
        <hr
          style={{
            marginTop: "4px",
            border: "none",
            marginBottom: "4px",
            height: "4px",
          }} />
        <TreeContainer>
          {
            (dataFieldsList && !loadingVariableTree) &&
            <>
              <VariableTreeDataFieldListAndSelector
                setValue={setValue}
                updateDataField={dataFieldsList}
                getValues={getValues}
                setSelectedDatafieldType={setSelectedDatafieldTypes}
              />
            </>
          }

          {loadingVariableTree &&
            <Grid style={{ height: '100%', display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <WLCircularLoader color="primary" sx={{ height: 0, width: 0 }} />
            </Grid>
          }

        </TreeContainer>
        <HelperText>
          You are selecting value for <b>{dataPicker.name}</b> of type{" "}
          <b>{dataPicker.dataType}</b>
        </HelperText>
        {selectedDatafieldTypes === "" && (
          <p className="text-red-500 text-sm"> * Please select a datafield </p>
        )}
        <ButtonContainer>
          <ZButton
            onClick={addDatafield}
            disabled={selectedDatafieldTypes === ""}
            className="mr-2"
            variant="contained"
          >
            {" "}
            Save{" "}
          </ZButton>
          <ZButton
            variant="contained"
            secondary
            onClick={() => dispatch(setDataPickerOpen(false))}
          >
            {" "}
            Cancel{" "}
          </ZButton>
        </ButtonContainer>
      </Container>
    </Modal>
  );
};

export default DatafieldSelector;
