import { Modal, ModalWrapper, ModalContent, ModalFooter, EditorModalContent } from './commonComponents.styles';
import { IZEditorModal } from './interfaces/IZModal';

const ZEditorModal = (props: IZEditorModal) => {
  const {
    isOpen,
    isSecondary,
    isNotFullScreen,
    handleClose,
    childComponent,
    integration = false
  } = props;

  return (
    <Modal
      aria-labelledby='keep-mounted-modal-title'
      aria-describedby='keep-mounted-modal-description'
      open={isOpen}
      onClose={handleClose}
      keepMounted
      secondary={isSecondary}
      codeEditor={isNotFullScreen}
      integration={integration}>
      <ModalWrapper>
      <EditorModalContent codeEditor={isNotFullScreen}>{childComponent}</EditorModalContent>
      </ModalWrapper>
    </Modal>
  );
};

export default ZEditorModal;