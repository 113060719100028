import { useSelector } from 'react-redux';

type Props = {
  name ?: string
}

const useTaskEdit = ({name=""}:Props) => { 
  const {taskEdit} = useSelector((state:any) => state)
  const editViewAlreadyCreatedItems = useSelector((state: any) => taskEdit[name])
  const taskId = taskEdit.taskId



  return {
    editViewAlreadyCreatedItems,
    taskId
  };
};

export default useTaskEdit;
