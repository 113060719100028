import React, { useState } from "react";
import styled from "styled-components";
import { ZButton } from "views/commonComponents/commonComponents.styles";

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  /* padding: 40px; */
  @media (max-width: 768px) {
    width: 90%;
    padding-top: 30px;
    overflow: hidden;
  }
`;

const Card = styled.div`
  border: 1px solid #ccc;
  margin: auto;
  border-radius: 4px;
  padding: 20px;
  height: 60%;
  width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  /* box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); */
`;

const CardTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin-bottom: 4px;
`;

const CardDescription = styled.p`
  font-size: 14px;
  color: #333;
  font-weight: 400;
`;

const CardButton = styled.button`
  border: 1px solid #ccc;
  padding: 10px 20px;
  color: #333;
  background-color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #eee;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
`;

const YoutubeIframe = styled.iframe`
  width: 100%;
  height: 100%;
  @media (max-width: 768px) {
    height: auto;
    max-height: 300px;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const CheckboxLabel = styled.label`
  margin-left: 10px;
`;

const GPTIntroComponent = (props: any) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    if (isChecked) localStorage.setItem("gptIntroShown", "false");
    props.onContinue();
  };

  return (
    <Container>
      <Card>
        <CardTitle>Welcome to ZorpGPT</CardTitle>
        <CardDescription>
          Here is a quick video to get you up and running quickly
        </CardDescription>
        <YoutubeIframe
          src="https://www.youtube.com/embed/dnTvuYQCHoI"
          title="Zorp GPT Introduction"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></YoutubeIframe>
        <CheckboxContainer>
          <input
            type="checkbox"
            id="nextTime"
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
          />
          <CheckboxLabel htmlFor="nextTime">
            Do not show me next time
          </CheckboxLabel>
        </CheckboxContainer>
        <ButtonContainer>
          <ZButton fullWidth secondary variant="contained">
            {" "}
            Read Documentation
          </ZButton>
          <ZButton fullWidth onClick={handleCheckboxChange} variant="contained">
            Continue
          </ZButton>
        </ButtonContainer>
      </Card>
    </Container>
  );
};

export default GPTIntroComponent;
