import styled from 'styled-components';
import PermissionsComponent from './components/PermissionsComponent';
import { IoIosClose as CloseIcon } from "react-icons/io";
import ConfiguredPermissions from './components/ConfiguredPermissions';
import { ResourceTypeList, PermissionConfiguration } from './types';
import useEntity from '../hooks/useEntity';
import { setEntityPermissions } from '../reducers/entityReducers';
import { useDispatch } from 'react-redux';
import ManageAccessTabs from './components/ManageAccessTab';
import { useState } from 'react';
import notification from 'notifications/notifications';
import { TablePermissionRsEvents } from '../types';
import { recordRSEvent } from 'utils/CommonUtils';

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    width: 70%;
    min-height: 70vh;
    max-width: 900px;
    border-radius: 4px;
    z-index: 1001;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 24px 24px 20px 24px;
`;

const Content = styled.div`
    display: grid;
    grid-template-columns: 2fr 3.5fr;
    padding: 0px 24px 24px 24px;
    gap: 24px;
`;

const ConfiguredPermissionsSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

const PermissionsSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

const ButtonWrapper = styled.div`
    display: flex;
    gap: 8px;
`

const Text = styled.p<{ $color?: string; $fontSize?: string; $fontWeight?: string }>`
    color: ${props => props.$color ? props.$color : "black"};
    font-size: ${props => props.$fontSize ? props.$fontSize : "14px"};
    font-weight: ${props => props.$fontWeight ? props.$fontWeight : "400"};
`

const Tabs = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
`

const Tab = styled.button<{ $selected?: boolean; }>`
    display: flex;
    padding: 8px 12px;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: ${props => props.$selected ? "#3054B9" : "#667085"};
    background: ${props => props.$selected ? "#F5F8FF" : "transparent"};
`

interface ModalProps {
    resourceType: ResourceTypeList;
    onClose: () => void;
}

const ManagePermissionsModal = ({ resourceType, onClose }: ModalProps) => {

    const { finalEntityPermissions, permissionConfiguration } = useEntity();
    const [selectedManageTab, setSelectedManageTab] = useState<boolean>(true);
    const [selectedPermissionConfig, setSelectedPermissionConfig] = useState<PermissionConfiguration>(permissionConfiguration[0]);
    const [isEdited, setIsEdited] = useState<boolean>(false);
    const [isPermissionEdited, setIsPermissionEdited] = useState<boolean>(false);

    const dispatch = useDispatch();
    const handleManageTabChange = () => {
        if (isEdited) {
            notification("error", "You have unsaved changes. Please save before changing permissions.")
            return;
        }
        setSelectedManageTab(false);
        recordRSEvent(TablePermissionRsEvents.OPEN_MANAGE_PERMISSIONS_TAB_BUTTON_CLICKED)
    }

    const handlePermissionTabChange = () => {
        if (isPermissionEdited) {
            notification("error", "You have unsaved changes. Please save before changing permissions.")
            return;
        }
        setSelectedManageTab(true)
        recordRSEvent(TablePermissionRsEvents.OPEN_MANAGE_ACCESS_TAB_BUTTON_CLICKED)
    }

    const onCloseHandler = () => {
        if (!isEdited && !isPermissionEdited) {
            dispatch(setEntityPermissions(finalEntityPermissions));
            onClose();
            recordRSEvent(TablePermissionRsEvents.MANAGE_PERMISSIONS_CLOSE_BUTTON_CLICKED, {
                resourceType: resourceType,
                permissionsAfterClose: finalEntityPermissions
            })
        } else {
            notification('warning', 'You have unsaved changes!')
        }
    }

    return (
        <Overlay onClick={onCloseHandler}>
            <ModalContainer onClick={e => e.stopPropagation()} className="modal">
                <Header>
                    <Tabs>
                        <Tab $selected={selectedManageTab} onClick={handlePermissionTabChange}>Manage Access</Tab>
                        <Tab $selected={!selectedManageTab} onClick={handleManageTabChange}>Permissions</Tab>
                    </Tabs>
                    <ButtonWrapper>
                        <CloseIcon onClick={onCloseHandler} size="24" color='#667085' />
                    </ButtonWrapper>
                </Header>
                {selectedManageTab ?
                    <ManageAccessTabs isEdited={isEdited} setIsEdited={setIsEdited} resourceType={resourceType} />
                    : (
                        <Content>
                            <ConfiguredPermissionsSection>
                                <Text $color="#475467" $fontSize="14px">Configured Permissions</Text>
                                {selectedPermissionConfig && <ConfiguredPermissions selectedPermissionConfig={selectedPermissionConfig} setSelectedPermissionConfig={setSelectedPermissionConfig} onPermissionClick={setSelectedPermissionConfig} resourceType={resourceType} />}
                            </ConfiguredPermissionsSection>
                            <PermissionsSection>
                                <PermissionsComponent isPermissionEdited={isPermissionEdited} setIsPermissionEdited={setIsPermissionEdited} setSelectedPermissionList={setSelectedPermissionConfig} selectedPermissionList={selectedPermissionConfig} resourceType={resourceType} />
                            </PermissionsSection>
                        </Content>
                    )}
            </ModalContainer>
        </Overlay>
    );
};

export default ManagePermissionsModal;
