import React from 'react'
import styled from "styled-components"
import Lottie from "lottie-react";
import ZEmpty from "./ZEmpty.json" 
import {Button} from "../DataField/DataField.styles"

const Quote = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;  
    margin-top:-2rem;
    
    p {
        font-size:16px;
        font-weight:500;
        color:rgba(0, 0, 0, 0.8);
        width:25%;
        text-align:center;
        margin-top:1rem;
       
    }
`

const Image = styled.div`
    height: inherit;
    width: inherit;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top:4rem;

    div > svg {
        height:40vh !important;
        width:90% !important;
    }
`
const ButtonContainer = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  margin-top:1.5rem;
`

type EmptyDataFieldProps = {
    text : string
}

const ZEmptyDataField = ({text}:EmptyDataFieldProps) => {
  return ( 
        <div>
            <Image>
                <Lottie 
                style={{display:"flex",justifyContent:"center"}} 
                loop={true} 
                autoplay={true} 
                animationData={ZEmpty}  
                />
            </Image>
            <Quote>
                <p>
                    {text}
                    {/* <ButtonContainer> 
                        <Button  onClick={() => handleClose()} variant="primary"> Create a new Data Field </Button>
                    </ButtonContainer>  */}
                </p>
            </Quote> 
        </div>
  )
}

export default ZEmptyDataField 