import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  MonitorTicketIcon, ReportsMenuIcon, TableIcon
} from "assests";
import ZMonitorIcon from "assests/icons/ZMonitorIcon";
import ZReportIcon from "assests/icons/ZReportIcon";
import { BffService } from "providers/data/services/BffService";
import { AiFillCar } from "react-icons/ai";
import { BsBuilding } from "react-icons/bs";
import { CiCircleAlert } from "react-icons/ci";
import { FaStore, FaTicketAlt, FaTruck } from "react-icons/fa";
import { GiAutoRepair } from "react-icons/gi";
import { HiArchive, HiChartPie } from "react-icons/hi";
import { MdDashboard, MdMap } from "react-icons/md";
import { SiSimpleanalytics } from "react-icons/si";
import { TbReportAnalytics } from "react-icons/tb";
import { initColor } from "views/settings/GeneralSetting/ThemeColorSelector";
import ZWorkOrder from "assests/icons/ZWorkOrder";
import ZInventory from "assests/icons/ZInventory";
import ZLocations from "assests/icons/ZLocations";
import ZAssets from "assests/icons/ZAssets";
import ZPurchaseOrder from "assests/icons/ZPurchaseOrder";
import ZChecklists from "assests/icons/ZCheckLists";
import ZPreventiveMaintenance from "assests/icons/ZPreventiveMaintenance";
import ZNewReports from "assests/icons/ZNewReports";
import ZTickets from "assests/icons/ZTickets";

export interface IEmbedLink {
  to: string;
  key: string;
  icon: any;
  menuId: string;
  isSupportType: boolean;
  type : IEmbedLinkType;
  isEmbed: boolean;
  embedUrl: string;
  isInternal: boolean;
  data ?: IEmbedLinkData;
  dropdownItems ?: IEmbedLink[];
  links ?: IEmbedLink[];
}

export enum IEmbedLinkType  {
  "GENERIC" = "GENERIC",
  "DROPDOWN" = "DROPDOWN"
}
export interface IEmbedLinkData {
  type: string;
  data: any;
  label: string;
  tableType ?: string;
  tableTypeDisplayName ?: string;
}

const initialState = {
  selectedEmbedLink: "",
  embedLinks: [],
  theme: initColor
};

const getIcon = (icon: string) => {
  switch (icon) {
    case "icon 1":
      return MdDashboard;
    case "icon 2":
      return HiChartPie;
    case "icon 3":
      return TbReportAnalytics;
    case "icon 4":
      return HiArchive;
    case "icon 4":
      return BsBuilding;
    case "repairs":
      return GiAutoRepair;
    case "assets":
      return AiFillCar;
    case "incidents":
      return CiCircleAlert;
    case "truck":
      return FaTruck;
    case "ticket":
      return ZMonitorIcon;
    case "reports":
      return ZReportIcon;
    case "map":
      return MdMap;
    case "ticket":
      return FaTicketAlt;
    case "store":
      return FaStore;
    case "table":
      return TableIcon;
    case "workflowOrders":
      return ZWorkOrder;
    case "inventory":
      return ZInventory;
    case "locations":
      return ZLocations;
    case "assets":
      return ZAssets;
    case "purchaseOrders":
      return ZPurchaseOrder;
    case "checklists":
      return ZChecklists;
    case "preventiveMaintenance":
      return ZPreventiveMaintenance;
    case "newReports":
      return ZNewReports;
    case "tickets":
      return ZTickets;

    default:
      return ZReportIcon;
  }
}

const fetchEmbedLinks = async () => {
  try {
    const res = await BffService.getEmbedItem();
    let embedLinks: IEmbedLink[] = [];
    res?.data?.links?.forEach((link: any) => {
      let linkTo = "";
      if (!link.isInternal) {
        if (link.type == "METABASE") {
          linkTo = `/reports/${link.label}`;
        } else if (link.type == "ZORP_BI") {
          linkTo = `/bireports/${link.label}`;
        } else {
          linkTo = `/reports/${link.label}`;
        }
      } else {
        linkTo = `${link.url}`;
      }

      const icon = getIcon(link.icon);

      embedLinks.push(
        {
          to: linkTo,
          key: link.label,
          icon: icon,
          menuId: link.url,
          isSupportType: false,
          type: link.type === IEmbedLinkType.DROPDOWN ? IEmbedLinkType.DROPDOWN : IEmbedLinkType.GENERIC,
          isEmbed: true,
          embedUrl: link.url,
          isInternal: link.isInternal,
          data: { type: link?.type, data: link?.data, label: link?.label },
          links: link?.links?.map((link: any) => {
            return {
              to: link.url,
              key: link.label,
              icon: getIcon(link.icon),
              menuId: link.url,
              isSupportType: false,
              type: link.type === IEmbedLinkType.DROPDOWN ? IEmbedLinkType.DROPDOWN : IEmbedLinkType.GENERIC,
              isEmbed: link.type === "TABLE" ? false : true,
              embedUrl: "",
              isInternal: link.isInternal,
              data: link?.data ? { ...link?.data } : {},
            };
          })
        });
    });
    return embedLinks;
  } catch (error) {
    console.log("error", error);
    return [];
  }
};

export const initMenu = createAsyncThunk("menu/init", async (taskTypeId) => {
  try {
    const res = await fetchEmbedLinks();
    return res;
  } catch (error) {
    return [];
  }
});

export const MenuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setSelectedEmbedLink: (state, action) => {
      state.selectedEmbedLink = action.payload;
    },
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    resetMenu: (state) => initialState,
  },
  extraReducers: (menu) => {
    menu
      .addCase(initMenu.pending, (state) => {
        if (state.selectedEmbedLink) {
          state.embedLinks = [];
        }
      })
      .addCase(initMenu.fulfilled, (state, action) => {
        state.embedLinks = action.payload;
      });
  },
});

export const { setSelectedEmbedLink, resetMenu,setTheme } = MenuSlice.actions;

export default MenuSlice.reducer;