import { Table, TableBody, TableHead, TableRow } from "@material-ui/core";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { cloneDeep } from "lodash";
import { initMockData } from "lowcode/state/scriptEditorState";
import notification from "notifications/notifications";
import { ZorpResponse } from "providers/data/models/ZorpResponse";
import { AccountService } from "providers/data/services/AccountService";
import { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { SingleTableCell } from "render-engine/render.styles";
import styled from "styled-components";
import { generateId } from "utils/Utils";
import useAutomation from "views/automationBuilder/hooks/useAutomation";
import { closeAutomation, setSelectedWebHook } from "views/automationBuilder/reducer/automationReducer";
import { ABMetadataTypeEnum, IActionBlock, IAutomation } from 'views/automationBuilder/types/ABType';
import { ZButton } from "views/commonComponents/commonComponents.styles";
import { Label } from "views/settings/GeneralSetting/GeneralSettingEdit";
import ConfigureAutomation from "views/workflows/ConfigureAutomation";
import { setSelectedAction } from "views/workflows/reducers/workflowAction";
import { FlexDiv, SubHeading } from "../settings/settings.style";
import NoCronContent from "./NoCronContent";
import NoSecretKeyConditionKeyCron from "./NoSecretKeyConditionKeyCron";
import { BffService } from "providers/data/services/BffService";
import WebhookAutomationBuilderNameModal, { modalFormValue } from "views/automationBuilder/components/WebhookAutomationBuilderNameModal";
import { webhookAutomationSaveHandler } from "./WebhookAutomationSaveHandler";

export const Wrapper = styled.div`
  padding: 20px 0px;
  height:100%;
`;


const WebhookAutomation = () => {
  const [webhookList, setWebhookList] = useState<any>([]);
  const [prevAutomation, setPrevAutomation] = useState<IAutomation[]>([]);
  const [selectedWebhookId, setSelectedWebhookId] = useState<string>("");
  const [openAB, setOpenAB] = useState(false);
  const { clearRightSide, metadataType } = useAutomation();
  const [openWebhookAutomationModal, setOpenWebhookAutomationModal] = useState<boolean>(false);
  const [accountDetails, setAccountDetails] = useState<any>(null);
  const dispatch = useDispatch();

  const getAllWebhooks = async () => {
    const data: any = await BffService.fetchAllWebhooks();
    if (data) {
      setWebhookList([...data.data]);
    }
  }

  const handleEditClick = (webhookData: any) => {
    dispatch(setSelectedWebHook(webhookData));
    setPrevAutomation(webhookData.uiFormat);
    setSelectedWebhookId(webhookData.uiFormat.id);
    dispatch(initMockData({ taskTypeId: webhookData?.webhookId, type: ABMetadataTypeEnum.WEBHOOK }));
    setOpenAB(true);
    dispatch(setSelectedAction(null));
  };

  useEffect(() => {
    getAllWebhooks();
  }, []);

  const onClickAutomation = async (webhookFormData: modalFormValue) => {

    const automationId = generateId(6);
    const actionBlockData: IActionBlock = {
      actions: [],
      conditions: [],
      actionBlockId: generateId(8)
    }

    let newAutomationName = webhookFormData.webhookName;

    const triggerId = generateId(6);
    const newAutomationData: IAutomation =
    {
      triggers: [{
        triggerId,
        event: {
          label: "webhook",
          description: "Cron Trigger",
          webhookUrl:"",
          webhookCurl:""
        },
        metadataType,
      }],
      guards: [],
      actionBlocks: [
        ...([actionBlockData]) // we won't create Action block, in this case : Example screen transition before decision node
      ],
      id: automationId,
      name: newAutomationName,
      errors: []
    }

    setSelectedWebhookId(automationId);
    setPrevAutomation([newAutomationData]);
    dispatch(setSelectedAction(null));

    const createCronPayload = {
      isActive: false,
      isInitiated: false,
      webhookSlug:webhookFormData.webhookSlug,
      webhookName:webhookFormData.webhookName
    }

    const createResponse: ZorpResponse | undefined = await BffService.createWebhook(createCronPayload);
    
    if (createResponse?.code == '201') {
      setOpenAB(true);
      dispatch(setSelectedWebHook({ webhookId: createResponse?.data.webhookId, webhookSlug:webhookFormData.webhookSlug, isInitiated:false }));
      dispatch(initMockData({ taskTypeId: createResponse?.data.webhookId, type: ABMetadataTypeEnum.WEBHOOK }));
    } else {
      notification("error", "Issue while opening Automation");
    }
  }

  const updateIsActive = (webhookId: string, newValue: boolean) => {
    // Create a copy of the current cronDetails array
    const updatedWebhookDetails = cloneDeep(webhookList);

    // Find the index of the element with the matching webhookId
    const index = updatedWebhookDetails.findIndex(
      (item: any) => item.webhookId === webhookId
    );

    // If the element with webhookId is found, update its isActive value
    if (index !== -1) {
      updatedWebhookDetails[index].isActive = newValue;
      setWebhookList(updatedWebhookDetails); // Update the state with the new array
    }
  };

  const getAccountDetails = async () => {
    const data: any = await AccountService.getSecretKeys();
    if (data) {
      console.log(data.data);
      setAccountDetails([...data.data]);
    }
  };

  useEffect(() => {
    getAccountDetails();
  }, []);

  const getZorpKey = () => {
    if (!accountDetails) return undefined;
    const zorpKey = accountDetails?.find((key: any) => key.keyType === "zorp");
    return zorpKey;
  };

  return (
    <>
      <Wrapper>
        <div className="" style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <div>
            <SubHeading>Webhook Automation</SubHeading>
            <Label>
              {" "}
              You can create and manage all your Webhook Automation here{" "}
            </Label>
          </div>

          {/* If there is no cron or the secret key is ot generated, then we don't show create button */}
          {(getZorpKey()?.keyMasked && webhookList.length > 0) && (
            <div>
              <FlexDiv>
                <ZButton
                  startIcon={<AiOutlinePlus />}
                  variant="contained"
                  onClick={async (e) => {
                    // await onClickAutomation(e);
                    setOpenWebhookAutomationModal(true);
                  }}
                >
                  Webhook Automation
                </ZButton>
              </FlexDiv>
            </div>
          )}
        </div>

        {!getZorpKey()?.keyMasked && (

          <NoSecretKeyConditionKeyCron onTabChange={() => {
          }} />
        )}
      </Wrapper>

      {
        webhookList.length > 0 &&
        <div
          style={{
            width: "100%",
            overflowX: "auto",
          }}
        >
          <Table
            style={{
              boxShadow:
                "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
            }}
            className="mt-6"
          >
            <TableHead>
              <TableRow>
                <SingleTableCell headerCell align="left">
                  <p>ID</p>
                </SingleTableCell>
                <SingleTableCell headerCell align="left">
                  <p>Name</p>
                </SingleTableCell>
                <SingleTableCell headerCell align="left">
                  <p>Active</p>
                </SingleTableCell>
                <SingleTableCell headerCell align="left">
                  <p>Action</p>
                </SingleTableCell>
              </TableRow>
            </TableHead>
            {
              // loop from first index of secretKeys
              webhookList?.map((webhookData: any, index: number) => {
                return (
                  <>
                    <TableBody key={webhookData.webhookId}>
                      <TableRow
                        style={{
                          borderLeft: "1px solid #EAECF0",
                          borderRight: "1px solid #EAECF0",
                          borderTop: "1px solid #EAECF0",
                        }}
                      >
                        <SingleTableCell align="left" scope="row">
                          <p>{webhookData.webhookId}</p>
                        </SingleTableCell>{" "}
                        <SingleTableCell align="left" scope="row">
                          <p>{webhookData.webhookName}</p>
                        </SingleTableCell>
                        <SingleTableCell align="left" scope="row">
                          <div
                            style={{
                              display: "flex",
                              gap: "12px",
                            }}
                          >
                            <Switch
                              checked={webhookData.isActive}
                              onChange={async (e) => {
                                const newValue = e.target.checked;
                                const responseData =
                                  await BffService.updateWebhook(
                                    webhookData.webhookId,
                                    { isActive: newValue }
                                  );
                                if (responseData?.code == "200") {
                                  notification("success", "Webhook Status Changed");
                                  updateIsActive(webhookData.webhookId, newValue);
                                } else {
                                  notification(
                                    "success",
                                    "Changing Webhook Status Failed"
                                  );
                                }
                              }}
                            />
                          </div>
                        </SingleTableCell>{" "}
                        <SingleTableCell align="left" scope="row">
                          <div
                            style={{
                              display: "flex",
                              gap: "24px",
                            }}
                          >

                            <Tooltip
                              title={
                                <Typography>
                                  Edit
                                </Typography>
                              }
                            >
                              <span>
                                <FiEdit2
                                  onClick={() => {
                                    handleEditClick(webhookData);
                                  }}
                                  size={"20px"}
                                  color={
                                    "#344054"
                                  }
                                  cursor={"pointer"}
                                />
                              </span>
                            </Tooltip>
                          </div>
                        </SingleTableCell>
                      </TableRow>
                    </TableBody>
                  </>
                );
              })
            }
          </Table>
        </div>
      }

      {
        webhookList.length == 0 &&
        <div style={{
          maxHeight: '600px',
          height: '600px',
        }}>
          <NoCronContent
            title="No Webhook Automations Found"
            label="Your search “Landing page design” did not match any projects.
  Please try again."
            btnName="Webhook Automation"
            hideCreateButton={!getZorpKey()?.keyMasked} onCreateAutomation={async (e: any) => {
              // await onClickAutomation(e);
              setOpenWebhookAutomationModal(true)
            }} />
        </div>
      }

      <ConfigureAutomation
        metaDataType={ABMetadataTypeEnum.WEBHOOK}
        prevAutomations={prevAutomation}
        automationSelected={selectedWebhookId}
        onSaveHandler={webhookAutomationSaveHandler}
        onAutomationClose={() => {
          clearRightSide();
          dispatch(closeAutomation());
          setOpenAB(false);
          getAllWebhooks();
        }}
        otherConfigs={{
          allowOnlySingleAutomation: true,
          hideGuardBlock: false,
          hideTriggerConfigureButton: true,
          triggerConfigureBtnName:"View Details"
        }}
        openAutomationBuilder={openAB}
      />

      <WebhookAutomationBuilderNameModal
      isOpen={openWebhookAutomationModal}
      onClose={() =>{
        setOpenWebhookAutomationModal(false);
      }}
      onSave={({webhookName, webhookSlug}:modalFormValue)=>{
        setOpenWebhookAutomationModal(false);
        onClickAutomation({webhookName, webhookSlug})
      }}
      />
    </>
  );
};

export default WebhookAutomation;
