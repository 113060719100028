import { TextField } from "@mui/material";
import React, { FunctionComponent, useState } from "react";
import {
  UseFormRegister,
  FieldErrors,
  Controller,
  Control,
  UseFormSetValue,
} from "react-hook-form";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { ComponentType } from "../ComponentTypeEnum";
import { IZDateTimeInterface } from "./IZDateTimeInterface";
import { useEffect } from "react";
import { TaskFormLabelForCreateAndEdit } from "render-engine/render.styles";



export const ZDateTime: FunctionComponent<{
  config: IZDateTimeInterface;
  onChange: Function;
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
  control: Control;
  setValue: UseFormSetValue<any>;
}> = ({ config, control, onChange, register, errors, setValue }) => {
  const [dtValue, setDtValue] = React.useState<Date | null>(
    config.value ? new Date(config.value) : new Date()
  );


  // Construct validation
  const validation: any = {};
  if (config.isRequired) validation.required = `required`;

  // If there is an incoming value, store it in redux store (this is akin to default value)
  useEffect(() => {
    setValue(config.key, config.value ? new Date(config.value) : null);
  }, []);

  const handleChange = (newDate: any) => {
    setDtValue(newDate?.toDate());
  };


  const isVisible = config?.isVisible ?? true;


  return (
    <>
      {isVisible && (
        <div className="col-span-4 float-left">
          {
            <TaskFormLabelForCreateAndEdit
              className="col-span-1 font-medium"
              fontSize={config?.label?.fontSize ? config.label.fontSize : 14}
              color={config?.label?.color ? config.label.color : "black"}
            >
              {config?.label?.isVisible ? config.label.val : ""}{config?.isRequired && <span className="text-red-500">*</span>}
            </TaskFormLabelForCreateAndEdit>
          }
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {config.shape.toLowerCase() === "date" && (
              <Controller
                control={control}
                name={config.key}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DesktopDatePicker
                    inputFormat="MM/dd/yyyy"
                    value={value}
                    onChange={onChange}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                )}
              />
            )}

            {config.shape.toLowerCase() === "time" && (
              <Controller
                control={control}
                name={config.key}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TimePicker
                    value={value}
                    onChange={onChange}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                )}
              />
            )}
            {config.shape.toLowerCase() === "datetime" && (
              <Controller
                control={control}
                name={config.key}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DateTimePicker
                    sx={{ width: "100%", backgroundColor: "white",
                    
                  }}
                    value={value}
                    onChange={onChange}
                    timeSteps={{ hours: 1, minutes: 1 }}
                    
                    // renderInput={(params) => (
                    //   <TextField fullWidth {...params} />
                    // )}
                  />
                )}
              />
            )}
          </LocalizationProvider>
          {errors[config.key]?.message?.length > 0 && (
            <div className="font-semibold text-xs text-red-500">
              {errors[config.key]?.message}
            </div>
          )}
        </div>
      )}
    </>
  );
};