export const ERROR_FETCHING_WT = 'Error fetching workflow templates';
export const ERROR_PUBLISHING_WF_TMP = 'Error publishing workflow as template';
export const ERROR_PUBLISHING_TMP_WF = 'Error publishing template as workflow';

//constants for recording rudderstack events
export const CreateFromScratch = {
  name: 'create_workflow_from_scratch_button_click',
  context: 'create_workflow_from_scratch',
};

export const CreateFromTemplate = {
  name: 'create_workflow_from_template_button_click',
  context: 'create_workflow_from_template',
  template_id: '',
};

export const ViewVideoTutorials = {
  name: 'view_video_tutorials_button_click',
  context: 'view_video_tutorials',
};

export const SuggestATemplate = {
  name: 'suggest_a_template_button_click',
  context: 'suggest_a_template',
};

export const SidePaneTemplateClick = {
  name: 'side_pane_template_click',
  context: 'side_pane_template_click',
  template_id: '',
};

export const BookASessionWithZorpExpert = {
  name: 'book_a_session_with_zorp_expert_button_click',
  context: 'book_a_session_with_zorp_expert',
};

export const PublishAsTemplate = {
  name: 'publish_as_template_button_click',
  context: 'publish_as_template',
  workflow_id: '',
};
