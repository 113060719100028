import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IChoiceConfirmation } from './types/IChoiceConfirmation';

const ChoiceConfirmation = (props: IChoiceConfirmation) => {
  const { isOpen, onClose, onConfirm, confirmationTitle, confirmationMessage } = props;

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby='title'>
      <DialogTitle id='title'>{confirmationTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{confirmationMessage}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            onConfirm(e);
            onClose();
            e.stopPropagation();
          }}
          autoFocus
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChoiceConfirmation;
