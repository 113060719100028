import AlertsSVG from "./alerts.svg"
import AppSVG from "./app.svg"
import AutomationsSVG from "./automations.svg"
import bellSVG from "./bell.svg"
import CalendarSVG from "./calendar.svg"
import ChronosSVG from "./chronos.svg"
import CopySVG from "./copy.svg"
import DatamodelSVG from "./datamodel.svg"
import DocsSVG from "./docs.svg"
import DropdownSVG from "./dropdown.svg"
import EditSVG from "./editIcon.svg"
import FeedbackSVG from "./feedback.svg"
import FilterSVG from "./filter-new.svg"
import HashSVG from "./hash.svg"
import HomeSVG from "./home.svg"
import HomeWhiteSVG from "./homeWhite.svg"
import ImageSVG from "./imageIco.svg"
import AutomationSVG from "./lightning.svg"
import LogoutSVG from "./logout.svg"
import MapSVG from "./map.svg"
import MonitorTicketSvg from "./monitor_ticket.svg"
import PreviewSVG from "./preview.svg"
import ProjectsSVG from "./projects.svg"
import RefreshSVG from "./refresh.svg"
import RelationSVG from "./relation.svg"
import ReportsSVG from "./reports.svg"
import SchedulerSVG from "./scheduler.svg"
import SettingsSVG from "./settings.svg"
import SignatureSVG from "./signature.svg"
import WorkflowSettingsSVG from "./settings3.svg"
import TableSVG from "./table.svg"
import SaveIcon from "./save.svg"
import TaskSVG from "./tasks.svg"
import TerminalSVG from "./terminal.svg"
import TextSVG from "./text.svg"
import TicketsSVG from "./tickets.svg"
import TrashSVG from "./trash.svg"
import WorkflowsSVG from "./workflows.svg"
import ToggleSVG from "./toggle.svg"
import IntegerSVG from "./integer.svg"
import ComputedSVG from "./computed.svg"
import LocationSVG from "./location.svg"
import UserSVG from "./user.svg"
import FileSVG from "./file.svg"
import ReplyIcon from "./message-chat-square.svg"
import ClockIcon from "./clock-check.svg"
import DelIcon from "./del.svg"
import DragIcon from "./drag.svg"
import TickIcon from "./check-square.svg"
import CrossIcon from "./x-square.svg"
import CreateTask from "./create_task.svg"
import DeleteTask from "./delete_task.svg"
import SearchTask from "./search_task.svg"
import TransitTask from "./transit_task.svg"
import UpdateRecord from "./update_record.svg"
import UpdateSlot from "./update_slot.svg"
import UpdateTask from "./update_task.svg"
import UpdateTeam from "./update_team.svg"
import Plus from "./plus.svg"
import Code from "./code.svg"
import PushNotification from "./push_notification.svg"
import RestApi from "./rest_api.svg"
import AiFile from "./aifile.svg"



// import { useLogout } from "react-admin"
import { ReactSVG } from "react-svg"


const TerminalIcon = <ReactSVG src={TerminalSVG} />
const CopyIcon = <ReactSVG src={CopySVG} />
const EditIcon = <ReactSVG src={EditSVG} />
const TrashIcon = <ReactSVG src={TrashSVG} />
const HomeIcon = <ReactSVG src={HomeSVG} />
const AlertsIcon = <ReactSVG src={AlertsSVG} />
const ReportsIcon = <ReactSVG src={ReportsSVG} />
const TaskBlackIcon = <ReactSVG color="currentColor" src={TaskSVG} />
const TableBlackIcon = <ReactSVG color="currentColor" src={TableSVG} />
const TicketsIcon = <ReactSVG src={TicketsSVG} />
const MonitorTicket = <ReactSVG color="white" src={MonitorTicketSvg} />
const TaskIcon = <ReactSVG src={TaskSVG} />
const CreateTaskIcon = <ReactSVG src={CreateTask} />
const DeleteTaskIcon = <ReactSVG src={DeleteTask} />
const SearchTaskIcon = <ReactSVG src={SearchTask} />
const TransitTaskIcon = <ReactSVG src={TransitTask} />
const UpdateRecordIcon = <ReactSVG src={UpdateRecord} />
const UpdateSlotIcon = <ReactSVG src={UpdateSlot} />
const UpdateTaskIcon = <ReactSVG src={UpdateTask} />
const UpdateTeamIcon = <ReactSVG src={UpdateTeam} />
const PlusIcon = <ReactSVG src={Plus} />
const CodeIcon = <ReactSVG src={Code} />
const PushNotificationIcon = <ReactSVG src={PushNotification} />
const RestApiIcon = <ReactSVG src={RestApi} />


//For new CMM account
const NewAssestsIcon = ({ size = 16 }: any) => (
  <img src={newAssets} alt="newAssestsIcon" style={{ width: size, height: size }} />
)
const NewChecklistsIcon = ({ size = 16 }: any) => (
  <img src={newChecklists} alt="newChecklistsIcon" style={{ width: size, height: size }} />
)
const NewInventoryIcon = ({ size = 16 }: any) => (
  <img src={newInventory} alt="newInventoryIcon" style={{ width: size, height: size }} />
)
const NewLocationsIcon = ({ size = 16 }: any) => (
  <img src={newLocations} alt="newLocationsIcon" style={{ width: size, height: size }} />
  )
const NewPreventiveMaintenanceIcon = ({ size = 16 }: any) => (
  <img src={newPreventiveMaintenance} alt="newPreventiveMaintenanceIcon" style={{ width: size, height: size }} />
)
const NewPurchaseOrdersIcon = ({ size = 16 }: any) => (
  <img src={newPurchaseOrders} alt="newPurchaseOrdersIcon" style={{ width: size, height: size }} />
)
const NewReportsIcon = ({ size = 16 }: any) => (
  <img src={newReports} alt="newReportsIcon" style={{ width: size, height: size }} />
  )
const NewTicketsIcon = ({ size = 16 }: any) => (
  <img src={newTickets} alt="newTicketsIcon" style={{ width: size, height: size }} />
  )
const NewWorkOrderIcon = ({ size = 16 }: any) => (
  <img src={newWorkOrder} alt="newWorkOrderIcon" style={{ width: size, height: size }} />
  )

const HomeWhiteIcon = ({ size = 20 }: any) => (
  <img src={HomeWhiteSVG} alt="Home Icon" style={{ width: size, height: size }} />
);
const BellIcon = ({ size = 20 }: any) => (
  <img src={bellSVG} alt="Bell Icon" style={{ width: size, height: size }} />
)
const ChronosIcon = ({ size = 20 }: any) => (
  <img src={ChronosSVG} alt="Chronos Icon" style={{ width: size, height: size }} />
)
const WorkflowsIcon = ({ size = 20 }: any) => (
  <img src={WorkflowsSVG} alt="Workflows Icon" style={{ width: size, height: size }} />
)
const SchedulerIcon = ({ size = 20 }: any) => (
  <img src={SchedulerSVG} alt="Scheduler Icon" style={{ width: size, height: size }} />
)
const MapIcon = ({ size = 20 }: any) => (
  <img src={MapSVG} alt="Map Icon" style={{ width: size, height: size }} />
)
const ProjectsIcon = ({ size = 20 }: any) => (
  <img src={ProjectsSVG} alt="Projects Icon" style={{ width: size, height: size }} />
)
const FeedbackIcon = ({ size = 20 }: any) => (
  <img src={FeedbackSVG} alt="Feedback Icon" style={{ width: size, height: size }} />
)
const AppIcon = ({ size = 20 }: any) => (
  <img src={AppSVG} alt="App Icon" style={{ width: size, height: size }} />
)
const SettingsIcon = ({ size = 20 }: any) => (
  <img src={SettingsSVG} alt="Settings Icon" style={{ width: size, height: size }} />
)
const DocsIcon = ({ size = 20 }: any) => (
  <img src={DocsSVG} alt="Docs Icon" style={{ width: size, height: size }} />
)
const TableIcon = ({ size = 16 }: any) => (
  <img src={TableSVG} alt="Table Icon" style={{ width: size, height: size }} />
)
const ReportsMenuIcon = () => (
  <img src={ReportsSVG} alt="Reports Icon" style={{ width: "16px", height: "16px" }} />
)
const MonitorTicketIcon = () => (
  <img src={MonitorTicketSvg} alt="Alert Ticket Icon" style={{ width: "16px", height: "16px" }} />
)
export const ClockRIcon = () => {
  return <ReactSVG src={ClockIcon} />
}
const LogoutIcon = ({ size = 20 }: any) => {
  return <img src={LogoutSVG} alt="Logout Icon" style={{ width: size, height: size }} />
}
const HomeRIcon = (color: any) => {
  return <ReactSVG src={HomeSVG} color={color} />
}

const Trash = (color: any) => {
  return <ReactSVG src={DelIcon} color={color} />
}

const Drag = (color: any) => {
  return <ReactSVG src={DragIcon} color={color} />
}

const Tick = (color: any) => {
  return <ReactSVG src={TickIcon} color={color} />
}

const Cross = (color: any) => {
  return <ReactSVG src={CrossIcon} color={color} />
}

const Save = (color: any) => {
  return <ReactSVG src={SaveIcon} color={color} />
}

const TableRIcon = (color: any) => {
  return <ReactSVG src={TableSVG} color={color} />
}

const RefreshRIcon = (color: any) => {
  return <ReactSVG src={RefreshSVG} color={color} />
}

const CalendarRIcon = (color: any) => {
  return <ReactSVG src={CalendarSVG} color={color} />
}

export const SettingRIcon = (color: any) => {
  return <ReactSVG src={SettingsSVG} color={color} />
}

const DropdownRIcon = (color: any) => {
  return <ReactSVG src={DropdownSVG} color={color} />
}

const FilterRIcon = (color: any) => {
  return <ReactSVG src={FilterSVG} color={color} />
}

const TextRIcon = (color: any) => {
  return <ReactSVG src={TextSVG} color={color} />
}

const RelationRIcon = (color: any) => {
  return <ReactSVG fontSize={"12px"} src={RelationSVG} color={color} />
}

const AutomationRIcon = (color: any) => {
  return <ReactSVG fontSize={"12px"} src={AutomationSVG} color={color} />
}

export const HashRIcon = (color: any) => {
  return <ReactSVG src={HashSVG} color={color} />
}

export const SignatureRIcon = (color: any) => {
  return <ReactSVG src={SignatureSVG} color={color} />
}

export const ImageRIcon = (color: any) => {
  return <ReactSVG src={ImageSVG} color={color} />
}

export const IntegerRIcon = (color: any) => {
  return <ReactSVG src={IntegerSVG} color={color} />
}

export const ComputedRIcon = (color: any) => {
  return <ReactSVG src={ComputedSVG} color={color} />
}

export const LocationRIcon = (color: any) => {
  return <ReactSVG src={LocationSVG} color={color} />
}

export const ToggleRIcon = (color: any) => {
  return <ReactSVG src={ToggleSVG} color={color} />
}

export const TrashRIcon = (color: any) => {
  return <ReactSVG src={TrashSVG} color={color} />
}

export const ReplyRIcon = (color: any) => {
  return <ReactSVG src={ReplyIcon} color={color} />
}


export const FileRIcon = (color: any) => {
  return <ReactSVG src={FileSVG} color={color} />
}

export const UserRIcon = (color: any) => {
  return <ReactSVG src={UserSVG} color={color} />
}

export const AiFileRIcon = (color: any) => {
  return <ReactSVG src={AiFile} color={color} />
}

const AutomationsIcon = <ReactSVG src={AutomationsSVG} />
const PreviewIcon = <ReactSVG src={PreviewSVG} />
const WorkflowSettingsIcon = <ReactSVG src={WorkflowSettingsSVG} />
const DataModelIcon = <ReactSVG src={DatamodelSVG} />

export {
  AlertsIcon,
  AppIcon,
  AutomationRIcon,
  AutomationsIcon,
  CalendarRIcon,
  ChronosIcon,
  CopyIcon,
  DataModelIcon,
  DocsIcon,
  DropdownRIcon,
  EditIcon,
  FeedbackIcon,
  FilterRIcon,
  HomeIcon,
  HomeRIcon,
  HomeWhiteIcon,
  LogoutIcon,
  MapIcon,
  PreviewIcon,
  ProjectsIcon,
  RefreshRIcon,
  RefreshSVG,
  RelationRIcon,
  ReportsIcon,
  ReportsMenuIcon,
  SchedulerIcon,
  SettingsIcon,
  TableBlackIcon,
  TableIcon,
  TableRIcon,
  TaskBlackIcon,
  TaskIcon,
  TerminalIcon,
  TextRIcon,
  TicketsIcon,
  TrashIcon,
  WorkflowSettingsIcon,
  WorkflowsIcon,
  BellIcon,
  MonitorTicket,
  MonitorTicketIcon,
  Trash,
  Drag,
  Tick,
  Cross,
  Save,
  CreateTaskIcon,
  DeleteTaskIcon,
  SearchTaskIcon,
  TransitTaskIcon,
  UpdateRecordIcon,
  UpdateSlotIcon,
  UpdateTaskIcon,
  UpdateTeamIcon,
  PlusIcon,
  PushNotificationIcon,
  RestApiIcon,
  CodeIcon,

  // For CMM account
  NewAssestsIcon,
  NewChecklistsIcon,
  NewInventoryIcon,
  NewLocationsIcon,
  NewPreventiveMaintenanceIcon,
  NewPurchaseOrdersIcon,
  NewReportsIcon,
  NewTicketsIcon,
  NewWorkOrderIcon
}
