import { makeStyles } from '@material-ui/core/styles';
import { TabContext } from '@mui/lab';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { ResourceContextProvider } from 'react-admin';
import { useHistory, useParams } from 'react-router-dom';
import { ZFormHeader, ZTabList } from 'views/commonComponents/commonComponents.styles';
import { TeamsList } from 'views/teams/TeamsList';
import { UserList } from 'views/users/UsersList';
import { PageCategoryEnum, recordPageVisit } from '../../config/Rudderstack';
import { hasPermission } from '../../providers/auth/authUtils';
import RoleList from '../roles/RoleList';
import GeneralSettingEdit from './GeneralSetting/GeneralSettingEdit';
import { tabValues } from './settings.constants';
import { getPathOfGivenValue, getValueOfGivenPath } from './settings.helpers';
import { Heading } from './settings.style';
import SecretManagement from './secret-management/SecretManagement';
import PaymentManagement from './payment/PaymentManagement';
import CronAutomation from 'views/AccountAutomation/CronAutomation';
import AccountAutomationMainPage from 'views/AccountAutomation/AccountAutomationMainPage';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  useEffect(() => {
    recordPageVisit(PageCategoryEnum.settings, 'settings');
  }, [props]);

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding:"32px"
  },
}));

const style = {
  fontFamily: 'Inter',
  fontWeight: 600,
  fontSize: '14px',
};

export default function Settings(props) {
  const classes = useStyles();
  const { page } = useParams();
  const history = useHistory();

  const [value, setValue] = useState(parseInt(getValueOfGivenPath(page, tabValues)));

  const permissions = JSON.parse(localStorage.getItem('permissions'));

  const isAdminAndApiRole = permissions && (permissions.role === 'admin' || permissions.role === "api_role" );

  const handleChange = (event, newValue) => {
    history.push(`/settings/${getPathOfGivenValue(newValue, tabValues)}`);
    setValue(newValue);
  };


  return (
    <div className={classes.root}>
      <Heading> Settings </Heading>
      <TabContext value={value}>
        <ZTabList sx={{ overflow: "auto" }} onChange={handleChange}>
          <Tabs
            value={value}
            aria-label="basic tabs example"
            TabIndicatorProps={{ style: { background: "black" } }}
          >
            <Tab sx={style} disableRipple label="General" />
            <Tab sx={style} disableRipple label="Teams" />
            <Tab sx={style} disableRipple label="Users" />
            <Tab sx={style} disableRipple label="Roles" />
            {isAdminAndApiRole && (
              <Tab sx={style} disableRipple label="Secret Keys" />
            )}

            {isAdminAndApiRole && (
              <Tab sx={style} disableRipple label="Automation" />
            )}

            {isAdminAndApiRole && (
              <Tab sx={style} disableRipple label="Payment" />
            )}

            {/* <Tab sx={style} disableRipple label='White Labelling' /> */}
          </Tabs>
        </ZTabList>
        <hr />
        <TabPanel value={value} index={3}>
          {hasPermission("role", "read") ||
          hasPermission("role", "view") ||
          hasPermission("role", "create") ? (
            <ResourceContextProvider value="roles">
              <RoleList
                basePath="/roles"
                hasCreate={true}
                hasEdit={true}
                hasList={true}
                hasShow={true}
                permissions={permissions}
              />
            </ResourceContextProvider>
          ) : (
            <ZFormHeader> You don't have permissions to view. </ZFormHeader>
          )}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {hasPermission("user", "create") ||
          hasPermission("user", "edit") ||
          hasPermission("user", "view") ? (
            <ResourceContextProvider value="users">
              <UserList
                basePath="/users"
                hasCreate={true}
                hasEdit={true}
                hasList={true}
                hasShow={true}
                permissions={permissions}
              />
            </ResourceContextProvider>
          ) : (
            <ZFormHeader> You don't have permission to view.</ZFormHeader>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {hasPermission("team", "create") ||
          hasPermission("team", "edit") ||
          hasPermission("team", "view") ? (
            <ResourceContextProvider value="teams">
              <TeamsList
                basePath="/teams"
                hasCreate={true}
                hasEdit={true}
                hasList={true}
                hasShow={true}
                permissions={permissions}
              />
            </ResourceContextProvider>
          ) : (
            <ZFormHeader> You don't have permission to view. </ZFormHeader>
          )}
        </TabPanel>
        <TabPanel value={value} index={0}>
          <GeneralSettingEdit />
        </TabPanel>

        {isAdminAndApiRole && (
          <TabPanel value={value} index={4}>
            <SecretManagement />
          </TabPanel>
        )}

        {isAdminAndApiRole && (
          <TabPanel value={value} index={5}>
            <AccountAutomationMainPage
            handleTabChange={()=>{
              handleChange('',4);
            }}
            />

          </TabPanel>
        )}

{isAdminAndApiRole && (
        <TabPanel value={value} index={6}>
          <PaymentManagement />
        </TabPanel>
        )}

        {/* <TabPanel value={value} index={4}>
          <WhiteLabelling/>
        </TabPanel> */}
      </TabContext>
    </div>
  );
}