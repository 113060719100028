import { Dispatch } from "react";
import {setCreatedItems} from "render-engine/reducers/listInfo"
import {setCreatedItemsForTaskCreate, editCreatedItemsForTaskCreate, deleteCreatedItemsForTaskCreate} from "render-engine/reducers/create"
import {setCreatedItemsForTaskEdit, editCreatedItemsForTaskEdit,deleteCreatedItemsForTaskEdit} from "render-engine/reducers/edit"
import {loadListData,removeListDataAndUIElements} from "render-engine/reducers/listInfo"

interface  SaveDataFieldArgsType {
    name: string,
    data: any, 
    location: string,
    dispatch: Dispatch<any>
}

interface IDynamicVariables {
    name:string;
    type:string;
  }

interface EditDataFieldArgsType extends SaveDataFieldArgsType {
    id: string
}

interface  DeleteDataFieldArgsType {
    name: string,
    id: string,
    location: string,
    dispatch: Dispatch<any>
}

interface ISetFieldValue {
    name:string,
    alreadyCreatedItems:any,
    pathname: "create" | "show" | "edit",
    dispatch: Dispatch<any>
}

interface IRecursiveHelperSmartComponents {
  obj:any,
  secondLevel?: boolean,
  key?: string,
  subChild?: string,
  deStructureObj?:boolean
}



/**
 * This function calls the reducer function for  creating new subfield in either taskCreation or taskEdit depending on 
 * the location.
 */
export const saveSubfield = ({name, data, location, dispatch}:SaveDataFieldArgsType) => {
    if(location.includes("create")){
        // Sending data as [data] to maintain homogeneity in the reducer function.
        // while preloading the already created data, we get response as array of objects
        // So here also, we are trying to do the same.
        dispatch(setCreatedItemsForTaskCreate({name, data:[data]}))
    } else{
        // Reason is same as above.
        dispatch(setCreatedItemsForTaskEdit({name, data:[data]}))
    }
}

/**
 * This function calls the reducer function for editing new subfield in either taskCreation or taskEdit depending on 
 * the location.
 */ 

export const editSubfield = ({id, name, data, location, dispatch}: EditDataFieldArgsType) => {
    if(location.includes("create")) {
        dispatch(editCreatedItemsForTaskCreate({name,id,editedData:data}))
    } else {
        dispatch(editCreatedItemsForTaskEdit({name,id,editedData:data}))
    }
    
}

export const deleteSubfield = ({id, name, location, dispatch}: DeleteDataFieldArgsType) => {
    dispatch(removeListDataAndUIElements({name, idx: id}))
}

// Load the already created items for List element in the redux depending upon the
// mode.
export const setCreatedItemsInStore = ({dispatch, name, alreadyCreatedItems,pathname}:ISetFieldValue) => {
    dispatch(loadListData({name, val:alreadyCreatedItems}))
}

// we will call this to spread all value in the object and push to array, during smart automation open
export const recursiveHelperSmartComponents = ({obj, secondLevel, key, subChild, deStructureObj = false}: IRecursiveHelperSmartComponents) => {
    if (obj == null) return [];
  
    let dynamicVariables:IDynamicVariables[] = [];
    if (!secondLevel) {
      Object.keys(obj).forEach((key) => {
        obj[key]?.map((variable:any) => {
          if (variable.dataType === 'OBJECT' || variable.dataType === 'LIST' || variable?.struct?.length > 0) {
            dynamicVariables = [...dynamicVariables, ...recursiveHelperSmartComponents({obj:variable.struct, secondLevel:true, key, subChild:variable.name,deStructureObj})];
          } else {
            if (!dynamicVariables.find((data) => data.name === `${key}.${variable?.name}`)) {
              dynamicVariables.push({ name: `${key}.${variable?.name}`, type: variable?.dataType || 'LIST' });
            }
          }
        });
      });
    } else {
      obj.forEach((variable:any) => {
        if ((variable.dataType === 'OBJECT' || variable.dataType === 'LIST' || variable?.struct?.length > 0) && deStructureObj) {
          dynamicVariables = [...dynamicVariables, ...recursiveHelperSmartComponents({obj:variable.struct, secondLevel:true, key, subChild:`${subChild}.${variable.id}`,deStructureObj})];
        } else {
          if (!dynamicVariables.find((data) => data.name === `${key}.${subChild}.${variable.id}`)) {
            dynamicVariables.push({ name: `${key}.${subChild}.${variable.id}`, type: variable.dataType || 'LIST'  });
          }
        }
      });
    }
    return dynamicVariables;
  }
