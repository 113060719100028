//@ts-nocheck
import { setDataPickerOpen } from 'views/workflows/reducers/workflowAction';
import { ActionToPerform } from 'constants/CommonConstants';
import { Dispatch } from 'redux';
import { addActionInAutomation, addConditionInAutomation, addGuardInAutomation, updateActionInAutomation, updateConditionInAutomation, updateGuardInAutomation } from 'views/automationBuilder/reducer/automationReducer';
import { IActionBlock, IAutomation } from 'views/automationBuilder/types/ABType';
import {ZorpTaskActionIds, ZorpTableActionIds} from "@zorp/common-libs-js/dist/common/types/types"

export enum IIsTempIdUniqueContext {
  ACTION = 'action',
  GUARD = 'guard',
  CONDITION = 'condition'
}

export enum ValidLocation {
  AUTOMATION = 'automation'
}

export const getResourceFromActionType = (actionType: string) => {
  const isTaskAction = Object.values(ZorpTaskActionIds).includes(actionType);
  if (isTaskAction) {
    return "TASK";
  }
  const isTableAction = Object.values(ZorpTableActionIds).includes(actionType);
  if (isTableAction) {
    return "TABLE";
  }
  return null;
}

type saveActionType = {
  payload: any;
  location: ValidLocation | "";
  dispatch: any;
  selectedTransition?: any;
  selectedDecisionNode?: any;
  selectedAction?: any;
  selectedSystemTransition?: any;
  selectedAutomation?: IAutomation;
  selectedActionBlock?: IActionBlock;
};

export const saveAction = ({
  payload,
  location,
  dispatch,
  selectedAction,
  selectedAutomation,
  selectedActionBlock
}: saveActionType) => {
  switch (location) {
    case ValidLocation.AUTOMATION:
      if (selectedAction.actionToPerform === ActionToPerform.CREATE) {
        dispatch(
          addActionInAutomation({
            automationId: selectedAutomation?.id || "",
            action: payload,
            actionBlockId: selectedActionBlock?.actionBlockId || ""
          })
        );
      } else if (selectedAction.actionToPerform === ActionToPerform.EDIT) {
        dispatch(updateActionInAutomation({
          automationId: selectedAutomation?.id || "",
          actionBlockId: selectedActionBlock?.actionBlockId || "",
          actionId: selectedAction?.actionId || "",
          data: payload
        }))
      }
    default:
      console.log('Invalid Location');
  }
};

export const pasteAction = ({ dispatch, payload, selectedAutomationId, actionBlockId }) => {
  dispatch(
    addActionInAutomation({
      automationId: selectedAutomationId || "",
      action: payload,
      actionBlockId: actionBlockId || ""
    })
  );
}

export const saveGuard = ({
  payload,
  location,
  dispatch,
  selectedAction,
}: saveActionType) => {
  switch (location) {
    case ValidLocation.AUTOMATION:

      if (selectedAction.actionToPerform === ActionToPerform.CREATE) {

        dispatch(
          addGuardInAutomation({
            guard: payload
          })
        );
      } else if (selectedAction.actionToPerform === ActionToPerform.EDIT) {

        dispatch(
          updateGuardInAutomation({
            guardId: selectedAction.guardId || "",
            guardData: payload
          })
        );

      }
      break;

    default:
      console.log('Invalid Location');
  }
};

export const pasteGuard = ({ dispatch, payload }) => {
  dispatch(
    addGuardInAutomation({
      guard: payload
    })
  );
}

export const saveCondition = ({
  payload,
  location,
  dispatch,
  selectedAction,
  selectedActionBlock,
  selectedAutomation
}: saveActionType) => {
  switch (location) {
    case ValidLocation.AUTOMATION:

      if (selectedAction.actionToPerform === ActionToPerform.CREATE) {

        dispatch(
          addConditionInAutomation({
            condition: payload,
            actionBlockId: selectedActionBlock?.actionBlockId
          })
        );
      } else if (selectedAction.actionToPerform === ActionToPerform.EDIT) {

        dispatch(
          updateConditionInAutomation({
            conditionId: selectedAction.conditionId || "",
            condition: payload,
            automationId: selectedAutomation?.id,
            actionBlockId: selectedActionBlock?.actionBlockId
          })
        );

      }
      break;

    default:
      console.log('Invalid Location');
  }
};

export const pasteCondition = ({ dispatch, payload, actionBlockId }) => {
  dispatch(
    addConditionInAutomation({
      condition: payload,
      actionBlockId: actionBlockId
    })
  );
}


/**
 * 
 * @param param0 
 * callback function will be called after performing the action
 */
export const dataFieldPickerUtil = ({ dispatch, name, dataType, prevValue, callback }: { dispatch: Dispatch, name: string, dataType: string, prevValue?: any, callback?: Function }) => {
  dispatch(setDataPickerOpen({ name, isOpened: true, dataType, prevValue }))
  if (callback) {
    callback()
  }
}

//add type for generateTempId function

export const generateTempId = (
  selectedAction: any,
  selectedAutomation: IIAutomation,
  name: string,
  type: 'action' | 'guard' | 'condition') => {

  const getIdProperty = () => {
    switch (type) {
      case 'action': return 'actionId';
      case 'guard': return 'guardId';
      case 'condition': return 'conditionId';
      default: return null;
    }
  };

  const hasId = selectedAction?.[getIdProperty()];

  const isIdUsed = (tempId: string) => {
    const isIdInActionBlocks = selectedAutomation?.actionBlocks?.some(actionBlock =>
      actionBlock.actions.some(action => action[getIdProperty()] === tempId) ||
      actionBlock.conditions.some(condition => condition[getIdProperty()] === tempId)
    );
    const isIdInGuards = selectedAutomation?.guards?.some(item => item[getIdProperty()] === tempId);

    return isIdInActionBlocks || isIdInGuards;
  };

  if (hasId && (isIdUsed(hasId))) {
    return selectedAction[getIdProperty()];
  } else {
    let tempId = name.toLowerCase().replace(/[^a-z0-9]/g, '_');
    let suffix = 2;

    while (isIdUsed(tempId)) {
      tempId = `${name.toLowerCase().replace(/[^a-z0-9]/g, '_')}_${suffix}`;
      suffix++;
    }

    return tempId;
  }
};


export const isTempIdUnique = (id: string, selectedAutomation: IAutomation): boolean => {

  console.log({id, selectedAutomation})

  const isUniqueAcrossActions = !selectedAutomation.actionBlocks.some((actionBlock) =>
    actionBlock.actions.some((action) => action.actionId === id)
  );

  const isUniqueAcrossGuards = !selectedAutomation.guards.some((guard) => guard.guardId === id);

  const isUniqueAcrossConditions = !selectedAutomation.actionBlocks.some((actionBlock) =>
    actionBlock.conditions.some((condition) => condition.conditionId === id)
  );

  return isUniqueAcrossActions && isUniqueAcrossGuards && isUniqueAcrossConditions;
};

export const getActionNameForTempId = (actionDetails: any) => {
  if (actionDetails?.hasOwnProperty('guardType')) {
    return actionDetails.guardName.toLowerCase();
  } else if (actionDetails?.hasOwnProperty('conditionType')) {
    return actionDetails.conditionName.toLowerCase();
  } else if (actionDetails?.hasOwnProperty('actionType')) {
    if (actionDetails?.actionType == "TASK_UPDATE_TEAM_ACTION") {
      return "assign user";
    } else if (actionDetails?.actionType == "SCREEN_COMPONENT") {
      return "app component"
    } else {
      return actionDetails.actionName.toLowerCase();
    }
  }
}