import React, { useEffect } from "react";
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import defaultImage from "../../../../layouts/MissingImage.png";
import { isNonInventoryTaskType } from "../../../../../utils/TasktypeUtils";
import {getTaskTypeFields} from '../../../components/TaskTypeFlatFileFields';

const OrderDetails = ({ id, record, resource }) => {

    const [failOpen, setFailOpen] = React.useState(false);
    const [infoOpen, setInfoOpen] = React.useState(false);
    const [info, setInfo] = React.useState(null);
    const [fail, setFail] = React.useState(null);
    const [taskTypeFields, setTaskTypeFields] = React.useState([]);

    const handleFailOpen = (e) => {
        console.log("Fail Info", e);
        setFail(e);
        setFailOpen(true);
    };

    const handleFailClose = () => {
        setFail(null);
        setFailOpen(false);
    };

    const handleInfoOpen = (e) => {
        setInfo(e);
        setInfoOpen(true);
    };

    const handleInfoClose = () => {
        setInfo(null);
        setInfoOpen(false);
    };

    useEffect(() => {

        setTaskTypeFields([]);
        let fields = [];
        getTaskTypeFields(record?.taskType).forEach((f) => {
            fields.push(
                <div key={f.key} className="flex rounded-md bg-gray-100  pt-3 pb-3 justify-between space-x-4 pl-2 pr-2">
                    <p className="font-bold text-left pr-8">{f.label} : </p>
                    <p className="text-right">
                    {record?.data[0] ? record?.data[0].info[f.key] : record?.data.orderInfo[0][f.key]}
                    </p>
                </div>
                // <p className="pt-4 pb-4">
                //     <strong>{f.label} - </strong>
                //     {record?.data[0] ? record?.data[0].info[f.key] : record?.data.orderInfo[0][f.key]}
                // </p>   
            )
        })
        setTaskTypeFields(fields);
    }, [record?.taskType]);

    return (
        <>
            {/* <div className="my-5 ml-3"> */}
            <div className="grid grid-cols-1 sm:grid-cols-2 pt-2 gap-y-4 gap-x-8">
                <div className="flex rounded-md bg-gray-100  pt-3 pb-3 justify-between space-x-4 pl-2 pr-2">
                    <p className="font-bold text-left pr-8">Order Id(s) : </p>
                    <p className="text-right">
                    {record?.orderId}
                    </p>
                </div>
                {/* <p>
                    <strong>Order Id(s) - </strong>
                    {record?.orderId}
                </p> */}
                {/* <p className="pt-4 pb-4">
                    <strong>Delivery Instructions - </strong>
                    {record?.data[0] ? record?.data[0]?.info?.delInstructions : record?.data.orderInfo[0]?.delInstructions}
                </p> */}
                {taskTypeFields}
            </div>
            {!isNonInventoryTaskType(record?.taskType) && (<Table
                size="small"
                stickyHeader
                aria-label="sticky table"
                style={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    marginLeft: "10px",
                    paddingRight: "20px",
                }}
            // className="mx-3 my-5 w-full"
            >
                <TableHead>
                    <TableRow>
                        <TableCell
                            align="left"
                            style={{ color: "#fff", backgroundColor: "#141411" }}
                        >
                            <b></b>
                        </TableCell>
                        <TableCell
                            align="left"
                            style={{ color: "#fff", backgroundColor: "#141411" }}
                        >
                            <b>Category</b>
                        </TableCell>
                        <TableCell
                            align="left"
                            style={{ color: "#fff", backgroundColor: "#141411" }}
                        >
                            <b>SKU</b>
                        </TableCell>
                        <TableCell
                            align="left"
                            style={{ color: "#fff", backgroundColor: "#141411" }}
                        >
                            <b>Id</b>
                        </TableCell>
                        <TableCell
                            align="left"
                            style={{ color: "#fff", backgroundColor: "#141411" }}
                        >
                            <b>Name</b>
                        </TableCell>
                        <TableCell
                            align="left"
                            style={{ color: "#fff", backgroundColor: "#141411" }}
                        >
                            <b>Type</b>
                        </TableCell>
                        <TableCell
                            align="left"
                            style={{ color: "#fff", backgroundColor: "#141411" }}
                        >
                            <b>Qty</b>
                        </TableCell>
                        <TableCell
                            align="left"
                            style={{ color: "#fff", backgroundColor: "#141411" }}
                        >
                            <b>Fulfilled Qty</b>
                        </TableCell>
                        <TableCell
                            align="left"
                            style={{ color: "#fff", backgroundColor: "#141411" }}
                        >
                            <b>Status</b>
                        </TableCell>
                        <TableCell
                            align="left"
                            style={{ color: "#fff", backgroundColor: "#141411" }}
                        >
                            <b>Failure Reason</b>
                        </TableCell>
                        <TableCell
                            align="left"
                            style={{ color: "#fff", backgroundColor: "#141411" }}
                        >
                            <b>Additional Info</b>
                        </TableCell>
                        <TableCell
                            align="left"
                            style={{ color: "#fff", backgroundColor: "#141411" }}
                        >
                            <b>Images</b>
                        </TableCell>
                    </TableRow>
                </TableHead>
                {record?.data?.products?.map((innerItem, index) => (
                    <TableBody>
                        <TableRow
                            style={
                                index % 2 ? { background: "#fff" } : { background: "#F5F5F5" }
                            }
                            key={Math.random()}
                        >
                            <TableCell align="left">
                                {innerItem?.productImageUrl ? (
                                    <a
                                        href={innerItem.productImageUrl}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            height="30px"
                                            width="30px"
                                            alt="img"
                                            src={innerItem.productImageUrl}
                                        ></img>
                                    </a>
                                ) : (
                                    <img
                                        height="30px"
                                        width="30px"
                                        alt="img"
                                        src={defaultImage}
                                    ></img>
                                )}
                            </TableCell>
                            <TableCell align="left" scope="row">{innerItem.productCategory}</TableCell>
                            <TableCell align="left" scope="row">{innerItem.sku}</TableCell>
                            <TableCell align="left">{innerItem.productId}</TableCell>
                            <TableCell align="left">{innerItem.productName}</TableCell>
                            <TableCell align="left">{innerItem.productType}</TableCell>
                            <TableCell align="left">{innerItem.qty}</TableCell>
                            <TableCell align="left">{innerItem.fulfilledQty}</TableCell>
                            <TableCell align="left">{innerItem.productStatus}</TableCell>
                            {innerItem?.failureReason ? (
                                <>
                                    <TableCell
                                        onClick={() => handleFailOpen(innerItem.failureReason)}
                                        style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                                        align="left"
                                    >
                                        {innerItem.failureReason}
                                    </TableCell>
                                    <Dialog
                                        open={failOpen}
                                        onClose={handleFailClose}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">
                                            {"Failure Reasons"}
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                {fail}
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleFailClose} color="primary">
                                                Close
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </>
                            ) : (
                                <TableCell
                                    style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                                    align="left"
                                >
                                    {innerItem.failureReason}
                                </TableCell>
                            )}
                            {innerItem?.additionalInfo ? (
                                <>
                                    <TableCell
                                        onClick={() => handleInfoOpen(innerItem.additionalInfo)}
                                        style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                                        align="left"
                                    >
                                        {innerItem.additionalInfo}
                                    </TableCell>
                                    <Dialog
                                        open={infoOpen}
                                        onClose={handleInfoClose}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">
                                            {"Additional Info"}
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                {info}
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleInfoClose} color="primary">
                                                Close
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </>
                            ) : (
                                <TableCell
                                    style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                                    align="left"
                                >
                                    {innerItem.additionalInfo}
                                </TableCell>
                            )}
                            <TableCell align="left">
                                <div class="container mx-auto text-indigo-400">
                                    {innerItem?.failureImageUrls?.map((image, index) => {
                                        return <a href={image} target="_blank">
                                            {/* <img alt="img" src={image} width="30" height="30"></img> */}
                                            {index+1} &nbsp;
                                        </a>
                                    })}
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ))}
            </Table>)}
        </>
    );
};

export default OrderDetails;