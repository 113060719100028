import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import monitorFineTuneBlack from "assests/monitor_fine_tune_black.svg";
import zorpPlayBtn from "assests/zorp_play_btn.svg";
import React from "react";
import styled from "styled-components";
import { ZButton } from 'views/commonComponents/commonComponents.styles';
import useMonitor from "../reducer/useMonitor";

interface ITextTitleProps {
  color?: string;
}

const TextTitle = styled.p<ITextTitleProps>`
  color: ${(props) => props.color || "#3054b9"};
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
`;

const IconTextWrapper = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

const BottomContainerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const LeftIconWrapper = styled.div`
  display: flex;
  gap: 16px;
`;


interface IMonitorTemplateFooter {
  isCreateMonitorLoading: boolean;
  onExecuteClick: () => void;
  onFineTuneClick: () => void;
  onOpenMonitorPage: () => void;
  onAlertMeClick: () => void;
  showCustomizeBlueBeeBtn?: boolean;
}

const MonitorTemplateFooter = ({
  isCreateMonitorLoading,
  onFineTuneClick,
  onAlertMeClick,
  showCustomizeBlueBeeBtn = false
}: IMonitorTemplateFooter
) => {
  const { isTemplateGeneratingFromPrompt } = useMonitor();

  return (
    <>
      <BottomContainerWrapper>
        <LeftIconWrapper>

          {
            showCustomizeBlueBeeBtn &&
            <IconTextWrapper className="pointer" onClick={!isTemplateGeneratingFromPrompt ? onFineTuneClick : undefined}>
              <img width="18px" src={monitorFineTuneBlack} />
              {isTemplateGeneratingFromPrompt ? (
                <Tooltip title={<Typography>Fine Tune is Disabled, because Some Template are getting generated</Typography>}>
                  <TextTitle color="#475467">Customize with Bluebee</TextTitle>
                </Tooltip>
              ) : (
                <TextTitle color="#475467">Customize with Bluebee</TextTitle>
              )}
            </IconTextWrapper>
          }

        </LeftIconWrapper>

        {isCreateMonitorLoading ? (
          <CircularProgress />
        ) : (
          <ZButton
            secondary
            style={{
              color: '#3054B9',
              fontSize: '12px',
              fontWeight: 'normal',
              backgroundColor: '#F5F8FF',
              border: '1px solid #3054B9',
              height: '32px'
            }}
            variant="contained"
            onClick={onAlertMeClick}
            type="button">
            <img style={{ marginRight: '6px' }} width={"12px"} src={zorpPlayBtn} />
            Alert Me
          </ZButton>
        )}
      </BottomContainerWrapper>
    </>
  );
}

export default React.memo(MonitorTemplateFooter);