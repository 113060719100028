import moment from "moment";
import {
  DataFieldType,
  EntityTypeField,
} from "../views/DataField/DataField.types";

export const pickupLocationTasks = [
  "porter_order_pickup_task",
  "porter_order_return_task",
  "towing_service",
];
const filteredTasks = ["inventory_pickup_task", "smoor_order_pickup_task"];
export const filterTasks = (tasks: any) => {
  let fTasks: any[] = [];

  tasks.forEach((task: any) => {
    if (
      (task?.data[0]?.info && !filteredTasks.includes(task.taskType)) ||
      builderTaskLocation.has(task.taskType)
    ) {
      fTasks.push(task);
    }
  });

  return fTasks;
};

// This will go as config later
export const builderTaskLocation = new Map();
builderTaskLocation.set("wgpaq1", "Customer_Location");
builderTaskLocation.set("rstd7r", "Customer_Location");
builderTaskLocation.set("ix8pcu", "Customer_Location");
builderTaskLocation.set("o3nw6u", "Customer_Location");
builderTaskLocation.set("9srv0x", "pickupLocation");
builderTaskLocation.set("79vcey", "pickupLocation");

export const doesTaskTypehavePickUpLocation = (taskType: string) => {
  const pickupLocationTasks = [
    "porter_order_pickup_task",
    "porter_order_return_task",
    "hero_bike_delivery",
    "hero_bike_pickup",
    "hero_pickup_from_customer_for_repairs",
    "hero_delivery_to_customer_after_repair",
    "towing_service",
  ];
  return pickupLocationTasks.includes(taskType);
};

export const isNonInventoryTaskType = (taskType: string) => {
  const nonInventoryTaskTypes = [
    "hero_general_purchase_task",
    "hero_general_warehouse_work_task",
    "hero_general_gotolocation_work_task",
    "hero_general_pickupdrop_task",
    "hero_general_minor_repair_task",
    "hero_attendance_checkin_task",
    "hero_attendance_checkout_task",
    "demo_kwikfresh_grocery_delivery",
    "hero_general_purchase_task",
    "kwikfresh_grocery_delivery",
    "pickup_and_drop_task",
    "field_visit_task",
    "suprdaily_address_verification",
    "furritas_dog_walk",
    "road_side_service",
    "towing_service",
    "fintech_document_collection",
    "krunchy_kyc",
    "krunchy_produce_details",
    "krunchy_distribution",
    "krunchy_production",
    "krunchy_sourcing",
    "field_sales_task",
    "packing_task",
    "stickering_task",
    "qc2_task",
    "hygiene_shield_audit",
  ];

  return nonInventoryTaskTypes.includes(taskType);
};

export const getTaskTypeCustomFields = (
  taskType: string,
  taskTypeDisplayName: string,
  taskTypeCustomDataFieldsList: [] | undefined
): [] => {
  let fields: any[] = [];

  taskTypeCustomDataFieldsList?.forEach((item: any) => {
    if (item?.taskType === taskType) {
      // @ts-ignore
      item?.customFields?.forEach((singleField: any) => {
        fields.push(
          Object.assign(singleField, { taskType }, { taskTypeDisplayName })
        );
      });
    }
  });
  // @ts-ignore
  return fields;
};

export const getTaskTypeCustomFieldsForBulkTask = (
  taskTypeCustomDataFields: any
): [] => {
  let fields: any[] = [];

  // @ts-ignore
  taskTypeCustomDataFields?.customFields?.forEach((cField: any) => {
    // Skip if its not a create time field
    if (cField.type === EntityTypeField.COMPUTED) {
      return;
    }
    if (cField?.isCreateTimeField === false) {
      return;
    }

    if (cField.type === DataFieldType.LIST_OF_TEXT) {
      let thisOptions: any = [];
      // check if cField has properties
      if (cField?.properties) {
        // check if cField has options
        if (cField?.properties?.options) {
          // check if cField has options
          if (cField?.properties?.options?.length > 0) {
            let thisObj = cField?.properties?.options?.map((val: any) => {
              return { label: val, value: val };
            });
            thisOptions = [...thisObj];
          }
        }
      } else {
        // TODO: Make changes in option keys
        cField?.options?.filter((item: any) => {
          let thisObj = { label: item?.displayValue, value: item?.value };
          thisOptions.push(thisObj);
        });
      }
      fields.push({
        label: cField.name,
        key: cField.name,
        fieldType: {
          type: "select",
          options: thisOptions,
        },
        validations: cField.mandatory
          ? [
              {
                rule: "required",
                errorMessage: `${cField.displayName} is required`,
                level: "error",
              }
            ]
          : [],
      });
    } else if (cField.type === DataFieldType.LOCATION) {
      fields.push({
        label: `${cField.displayName} Address`,
        key: `${cField.name}$address`,
        fieldType: {
          // There are 3 types - "input" / "checkbox" / "select".
          type: "input",
        },
        // validations: cField.mandatory ? {
        //   rules:"required",
        //   errorMessage: `${cField.displayName} Address is required`,
        //   level: "error",
        // } : undefined,
        example: cField.type,
      });
      // TODO: REGEX for checking it.
      fields.push({
        label: `${cField.name} Latitude`,
        key: `${cField.name}$latitude`,
        // validations: cField.mandatory ? {
        //   rules:"required",
        //   errorMessage: `${cField.displayName} Latitude is required`,
        //   level: "error",
        // } : undefined,
        fieldType: {
          // There are 3 types - "input" / "checkbox" / "select".
          type: "input",
        },
        example: cField.type,
      });
      fields.push({
        label: `${cField.name} Longitude`,
        key: `${cField.name}$longitude`,
        example: cField.type,
        // validations: cField.mandatory ? {
        //   rules:"required",
        //   errorMessage: `${cField.displayName} Longitude is required`,
        //   level: "error",
        // } : undefined,
        fieldType: {
          // There are 3 types - "input" / "checkbox" / "select".
          type: "input",
        },
      });
    } else if (cField.type === DataFieldType.COLLECTION) {
      cField?.children?.forEach((subField: any) => {
        fields.push({
          example: cField.type,
          label: `${cField.displayName} ${subField.name}`,
          key: `${cField.name}$${subField.name}`,
          validations: subField.mandatory
            ? [
                {
                  rule: "required",
                  errorMessage: `${cField.displayName} ${subField.name} is required`,
                  level: "error",
                },
              ]
            : [],
          fieldType: {
            // There are 3 types - "input" / "checkbox" / "select".
            type: "input",
          },
        });
      });
    } else {
      const validationArr = []

      if(cField.mandatory) {
        validationArr.push({
          rule: "required",
          errorMessage: `${cField.displayName} is required`,
          level: "error",
        })
      }

      if((cField.type === DataFieldType.NUMBER || cField.type === DataFieldType.TEXT) && cField?.isUnique) {
        validationArr.push({
          rule: "unique",
          errorMessage: `${cField.displayName} should be unique`,
          level: "error",
        })
      }
      fields.push({
        example: cField.type,
        label: cField.displayName,
        key: cField.name,
        validations: validationArr,
        fieldType: {
          // There are 3 types - "input" / "checkbox" / "select".
          type: "input",
        },
      });
    }
  });

  // @ts-ignore
  return fields;
};

export const getSpecificDataTypeFields = (
  taskTypeCustomDataFields: {} | undefined,
  dataType: DataFieldType
): {} => {
  let locationFields: {} = {};

  // @ts-ignore
  taskTypeCustomDataFields?.customFields?.forEach((cField: any) => {
    // Skip if its not a create time field
    if (!cField.isCreateTimeField) {
      return;
    }
    if (cField.type === dataType) {
      //@ts-ignore
      locationFields[cField.name] = cField.mandatory || false;
    }
  });

  // @ts-ignore
  return locationFields;
};

/**
 * return {required: boolean, dataType: DataType}
 */
export const getListDataTypeSubfieldInfo = (
  taskTypeCustomDataFields: {} | undefined
) => {
  let listDataFields: any = {};

  // @ts-ignore
  taskTypeCustomDataFields?.customFields?.forEach((cField: any) => {
    // Skip if its not a create time field
    if (!cField.isCreateTimeField) {
      return;
    }
    if (cField.type === DataFieldType.COLLECTION) {
      //@ts-ignore
      cField?.children?.forEach((child) => {
        listDataFields[`${cField.name}$${child.name}`] = {
          required: child.mandatory || false,
          dataType: child.type,
        };
      });
    }
  });

  // @ts-ignore
  return listDataFields;
};

function validateScheduleSlot(date: string, from: string, to: string) {
  if (date && from && to) {
    const scheduledFrom = moment(date + " " + from);
    const scheduledTo = moment(date + " " + to);

    const diff = scheduledTo.diff(scheduledFrom, "second");
    if (diff <= 0)
      return "Scheduled End Time should be greater than Scheduled Start Time";
  }

  return undefined;
}

function validateLatLang(val: any) {
  if (val !== "" && isNaN(val) === true) {
    return true;
  } else {
    return false;
  }
}

function validateScheduleDateTime(date: string, time: string) {
  if (
    date &&
    time &&
    !moment(date + " " + time, "M/D/YYYY h:m:s A", true).isValid()
  )
    return "Scheduled Time is not valid - Required format h:m:s A";
  return undefined;
}

function validateOffset(offset: string) {
  if (offset && !offset.match(/^[G][M][T][+-](?:2[0-3]|[01][0-9]):[0-5][0-9]$/))
    return "Offset is not valid - Required format e.g GMT+05:30";
  return undefined;
}

function validationStartDateFormat(date: string) {
  if (date && !moment(date, "M/D/YYYY", true).isValid()) {
    return "Start Date is not valid - Required format MM/DD/YYYY";
  }
  return undefined;
}

export const validateLocationFields = (
  taskTypeCustomDataFields: any,
  records: any
) => {
  // const get all record keys
  const recordKeys = Object.keys(records);
  const allLocationFields = getSpecificDataTypeFields(
    taskTypeCustomDataFields,
    DataFieldType.LOCATION
  );
  const allBooleanFields = getSpecificDataTypeFields(
    taskTypeCustomDataFields,
    DataFieldType.BOOLEAN
  );
  const allNumberFields = getSpecificDataTypeFields(
    taskTypeCustomDataFields,
    DataFieldType.NUMBER
  );
  const allDateFields = getSpecificDataTypeFields(
    taskTypeCustomDataFields,
    DataFieldType.DATETIME
  );

  // get location type fields from records
  let locationFields = new Set();
  recordKeys.forEach((record: any) => {
    if (
      record.includes("$address") ||
      record.includes("$latitude") ||
      record.includes("$longitude")
    ) {
      locationFields.add(record.split("$")[0]);
    }
  });
  const locationErrors: any[] = [];
  locationFields.forEach((locationField: any) => {
    //@ts-ignore
    let locationFieldKeyMandatory = allLocationFields[locationField];
    if (locationFieldKeyMandatory) {
      if (
        !records[`${locationField}$address`] &&
        !records[`${locationField}$latitude`] &&
        !records[`${locationField}$longitude`]
      ) {
        locationErrors.push({
          fieldName: `${locationField}$address`,
          message: "This field is required",
        });
        locationErrors.push({
          fieldName: `${locationField}$latitude`,
          message: "This field is required",
        });
        locationErrors.push({
          fieldName: `${locationField}$longitude`,
          message: "This field is required",
        });
      } else if (
        !records[`${locationField}$address`] &&
        !records[`${locationField}$latitude`] &&
        records[`${locationField}$longitude`]
      ) {
        locationErrors.push({
          fieldName: `${locationField}$latitude`,
          message: "This field is required",
        });
        if (validateLatLang(records[`${locationField}$longitude`])) {
          locationErrors.push({
            fieldName: `${locationField}$longitude`,
            message: "Please enter valid longitude",
          });
        }
      } else if (
        !records[`${locationField}$address`] &&
        records[`${locationField}$latitude`] &&
        !records[`${locationField}$longitude`]
      ) {
        locationErrors.push({
          fieldName: `${locationField}$longitude`,
          message: "This field is required",
        });
        if (validateLatLang(records[`${locationField}$latitude`])) {
          locationErrors.push({
            fieldName: `${locationField}$latitude`,
            message: "Please enter valid latitude",
          });
        }
      } else if (
        records[`${locationField}$latitude`] &&
        !records[`${locationField}$longitude`]
      ) {
        locationErrors.push({
          fieldName: `${locationField}$longitude`,
          message: "This field is required",
        });
        if (validateLatLang(records[`${locationField}$latitude`])) {
          locationErrors.push({
            fieldName: `${locationField}$latitude`,
            message: "Please enter valid latitude",
          });
        }
      } else if (
        !records[`${locationField}$latitude`] &&
        records[`${locationField}$longitude`]
      ) {
        locationErrors.push({
          fieldName: `${locationField}$latitude`,
          message: "This field is required",
        });
        if (validateLatLang(records[`${locationField}$longitude`])) {
          locationErrors.push({
            fieldName: `${locationField}$longitude`,
            message: "Please enter valid longitude",
          });
        }
      }
    } else {
      if (
        records[`${locationField}$latitude`] &&
        !records[`${locationField}$longitude`]
      ) {
        locationErrors.push({
          fieldName: `${locationField}$longitude`,
          message: "This field is required",
        });
        if (validateLatLang(records[`${locationField}$latitude`])) {
          locationErrors.push({
            fieldName: `${locationField}$latitude`,
            message: "Please enter valid latitude",
          });
        }
      } else if (
        !records[`${locationField}$latitude`] &&
        records[`${locationField}$longitude`]
      ) {
        locationErrors.push({
          fieldName: `${locationField}$latitude`,
          message: "This field is required",
        });
        if (validateLatLang(records[`${locationField}$longitude`])) {
          locationErrors.push({
            fieldName: `${locationField}$longitude`,
            message: "Please enter valid longitude",
          });
        }
      }
    }
  });

  let allBooleanFieldName = Object.keys(allBooleanFields);
  let allNumberFieldName = Object.keys(allNumberFields);
  let allDateFieldName = Object.keys(allDateFields);

  // check for slot
  if (
    records["scheduledStartTime"] ||
    records["scheduledEndTime"] ||
    records["offset"] ||
    records["scheduledDate"]
  ) {
    // Check for scheduled date
    if (!records["scheduledDate"]) {
      locationErrors.push({
        fieldName: `scheduledDate`,
        message:
          "This field is required when any of the following fields are filled: Scheduled Start Time, Scheduled End Time, Offset",
      });
    }

    // Check for scheduled start time
    if (!records["scheduledStartTime"]) {
      locationErrors.push({
        fieldName: `scheduledStartTime`,
        message:
          "This field is required when any of the following fields are filled: Scheduled Date, Scheduled End Time, Offset",
      });
    }

    if (!records["scheduledEndTime"]) {
      locationErrors.push({
        fieldName: `scheduledEndTime`,
        message:
          "This field is required when any of the following fields are filled: Scheduled Date, Scheduled Start Time, Offset",
      });
    }

    if (!records["offset"]) {
      locationErrors.push({
        fieldName: `offset`,
        message:
          "This field is required when any of the following fields are filled: Scheduled Date, Scheduled End Time, Scheduled End Time",
      });
    }
  }

  // validate slot
  const isScheduleSlotFromGreaterThanTo = validateScheduleSlot(
    records["scheduledDate"],
    records["scheduledStartTime"],
    records["scheduledEndTime"]
  );
  if (isScheduleSlotFromGreaterThanTo) {
    locationErrors.push({
      fieldName: `scheduledStartTime`,
      message: "Scheduled End Time should be greater than Scheduled Start Time",
    });
    locationErrors.push({
      fieldName: `scheduledEndTime`,
      message: "Scheduled End Time should be greater than Scheduled Start Time",
    });
  }

  // validate start date
  const isValidStartDate = validationStartDateFormat(records["scheduledDate"]);
  if (isValidStartDate) {
    locationErrors.push({
      fieldName: `scheduledDate`,
      message: isValidStartDate,
    });
  }

  // validate time
  const isValidStartTime = validateScheduleDateTime(
    records["scheduledDate"],
    records["scheduledStartTime"]
  );
  if (isValidStartTime) {
    locationErrors.push({
      fieldName: `scheduledStartTime`,
      message: isValidStartTime,
    });
  }

  const isValidEndTime = validateScheduleDateTime(
    records["scheduledDate"],
    records["scheduledEndTime"]
  );
  if (isValidEndTime) {
    locationErrors.push({
      fieldName: `scheduledEndTime`,
      message: isValidEndTime,
    });
  }

  const isValidOffset = validateOffset(records["offset"]);
  if (isValidOffset) {
    locationErrors.push({ fieldName: `offset`, message: isValidOffset });
  }

  // Valid boolean check
  recordKeys.forEach((key) => {
    if (records[key]) {
      if (allBooleanFieldName.includes(key)) {
        if (
          records[key]?.toLowerCase() === "true" ||
          records[key]?.toLowerCase() === "false"
        ) {
        } else {
          locationErrors.push({
            fieldName: `${key}`,
            message: "Please enter valid boolean value",
          });
        }
      }
    }
  });

  // value check for date

  recordKeys.forEach((key) => {
      if (records[key]) {
        if (allDateFieldName.includes(key)) {
          if (
            // check if records[key] is valid ISO DATE
            !moment(records[key], moment.ISO_8601, true).isValid()
            
          ) {
            locationErrors.push({
              fieldName: `${key}`,
              message: "Please enter valid date. Format: YYYY-MM-DDTHH:mm:ssZ"
            });
          }
        }
      }
    });

  // Valid number check
  recordKeys.forEach((key) => {
    if (records[key]) {
      if (allNumberFieldName.includes(key)) {
        if (isNaN(records[key])) {
          locationErrors.push({
            fieldName: `${key}`,
            message: "Please enter valid number",
          });
        }
      }
    }
  });

  // Valid List
  const allListSubfields = getListDataTypeSubfieldInfo(
    taskTypeCustomDataFields
  );

  recordKeys.forEach((key) => {
    if (records[key]) {
      if (allListSubfields[key]) {
        if (allListSubfields[key].dataType === DataFieldType.NUMBER) {
          if (isNaN(records[key])) {
            locationErrors.push({
              fieldName: `${key}`,
              message: "Please enter valid number",
            });
          }
        }

        if (allListSubfields[key].dataType === DataFieldType.BOOLEAN) {
          if (
            records[key]?.toLowerCase() === "true" ||
            records[key]?.toLowerCase() === "false"
          ) {
          } else {
            locationErrors.push({
              fieldName: `${key}`,
              message: "Please enter valid boolean value",
            });
          }
        }
      }
    }
  });

  return locationErrors;
};

// written for task export

export const getListFromTaskType = (inputList: any, taskType: string) => {
  const columnList = inputList[taskType].map((data: any, index: any) => {
    return data.name;
  });

  return columnList;
};
