import { Modal, ModalContent, ModalFooter, ModalHeader, ModalWrapper } from './commonComponents.styles';
import { IZModalHeader } from './interfaces/IZModal';

const ZModalHeader = (props: IZModalHeader) => {
  const {
    showFooter = false,
    isOpen,
    isSecondary,
    isNotFullScreen,
    handleClose,
    headerComponent,
    childComponent,
    footerComponent,
    integration = false,
    showCloseIcon = true
  } = props;

  return (
    <Modal
      aria-labelledby='keep-mounted-modal-title'
      aria-describedby='keep-mounted-modal-description'
      open={isOpen}
      onClose={handleClose}
      keepMounted
      secondary={isSecondary}
      codeEditor={isNotFullScreen}
      integration={integration}
    >
      <ModalWrapper>
        <ModalHeader secondary={isSecondary}>
          {headerComponent}
        </ModalHeader>
        <ModalContent codeEditor={isNotFullScreen}>{childComponent}</ModalContent>
        {showFooter && <ModalFooter>{footerComponent}</ModalFooter>}
      </ModalWrapper>
    </Modal>
  );
};

export default ZModalHeader;
