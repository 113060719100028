import React, { useEffect, useState } from "react";
import { Stepper, Step, StepLabel, StepContent } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Box from "@mui/material/Box";
import { formatDate } from "utils/CommonUtils"
import { fetchRecordLogs } from "./handlers/TransitionHandler";
import moment from 'moment';
import { InfoTooltip } from "views/workflows/Tootltips/InfoTooltip"
import { StyledTypography, StyledBox } from "./Components/Styles/ZRecordLogger.style";
import { returnIcon } from "./ZStepper";

import { RecordHistory } from "./types/types";
import LogEntry from "./Components/RecordLoggercomponents/LogEntry";
import { LogChange } from "./types/types";
import useEntity from "views/entities/hooks/useEntity";

interface RecordLogStepperProps {
    recordId: string;
}

const formatActualDate = (date: string): string => {
    return date ? moment(date).format('DD MMM YYYY hh:mm A') : '';
};

const getChannelDisplayName = (channel: string) => {
    switch (channel.toLowerCase()) {
        case "cc":
            return "Control Center";
        case "api":
            return "API";
        case "ma":
            return "Mobile App";
        default:
            return channel;
    }
};

const RecordLogStepper: React.FC<RecordLogStepperProps> = ({ recordId }) => {
    const [logs, setLogs] = useState<RecordHistory[]>([]);
    const { selectedEntity, selectedEntityMetadata, recordTypeLabel } = useEntity();


    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await fetchRecordLogs(recordId, selectedEntity?.tableType || "");
                setLogs(res);
            } catch (error) {
                console.error("Error fetching logs:", error);
            }
        };

        fetchData();
    }, [recordId]);

    const shouldRenderLog = (changes: LogChange[]) => {
        return !(changes.length === 1 && changes[0].path.toLowerCase() === "comments");
    };

    const renderableLogs = logs.filter(log => log.updatedBy && shouldRenderLog(log.changes));

    return (
        <Box>
            <Stepper orientation="vertical">
                {renderableLogs.length > 0 ?
                    (logs.map((log, index) => (
                        log.updatedBy && shouldRenderLog(log.changes) && (
                            <Step key={index} active>
                                <StepLabel style={{ padding: 0 }} icon={<AccessTimeIcon style={{ height: '18px' }} />}>
                                    <div style={{
                                        display: "flex", alignItems: "center", gap: "8px", color: "#475467",
                                        fontSize: "12px",
                                        lineHeight: "1",
                                        marginLeft: "-8px",
                                        marginTop: "1.5px"
                                    }}>
                                        <span>{log.updatedBy.name}</span>
                                        <InfoTooltip
                                            placement="bottom"
                                            title={
                                                <p>{formatActualDate(log.updatedOn)}</p>
                                            }
                                        >
                                            {formatDate(log.updatedOn)}
                                        </InfoTooltip>
                                        <InfoTooltip
                                            title={<p>{getChannelDisplayName(log?.updatedBy?.channel || "api")}</p>}>
                                            <span>{returnIcon(log.updatedBy.channel)}</span>
                                        </InfoTooltip>
                                    </div>
                                </StepLabel>
                                <Box style={{ paddingLeft: "-2px" }}>
                                    <StepContent style={{ width: "100%", marginLeft: "10px", paddingTop: "5px" }}>
                                        {log?.changes && log?.changes.length > 0 ? (
                                            <LogEntry changes={log.changes} />
                                        ) : <StyledTypography><StyledBox color="#475467">New {recordTypeLabel} Created</StyledBox></StyledTypography>}
                                    </StepContent>
                                </Box>
                            </Step>
                        )
                    ))) : <StyledTypography>No {recordTypeLabel.toLowerCase()} to show</StyledTypography>}
            </Stepper>
        </Box >
    );
};

export default RecordLogStepper;
