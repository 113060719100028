import styled from "styled-components"
import CreatedAutomations from 'views/automationBuilder/CreatedAutomations'
import RightPane from 'views/automationBuilder/RightPane'
import AutomationPlayground from 'views/automationBuilder/AutomationPlayground'

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 20% 45% 35%;
`

const ABEntry = () => {
  return (
    <Wrapper>
      <CreatedAutomations />
      <AutomationPlayground />
      <RightPane />
    </Wrapper>
  )
}

export default ABEntry