import { Modal, Tabs } from "@mui/material";
import { useCallback, useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import styled from "styled-components";
import {
  ZButton,
  ZTabList,
} from "views/commonComponents/commonComponents.styles";
import useWorkFlow from "views/workflows/hooks/useWorkFlow";
import { useDispatch } from "react-redux";
import { setShowPreviewModal } from "views/workflows/reducers/workflowAction";
import { TaskRenderService } from "providers/data/services/TaskRenderService";
import notification from "notifications/notifications";
import { Panel, PanelGroup } from "react-resizable-panels";
import { getBaseUrl } from "utils/CommonUtils";
import LoadingScreen from "views/LoadingScreen/LoadingScreen";
import AppIframe from "./AppIframe";
import { TabContext, TabPanel } from "@mui/lab";
import Tab from "@mui/material/Tab";
import Logs from "logs";
import ZEmptyDataField from "views/EmptyDataField/EmptyDataField";
import { BsLayoutSidebarReverse } from "react-icons/bs";
import usePreview from "./reducer/usePreview";
import { setPreviewTaskId } from "./reducer";

const Wrapper = styled(PanelGroup)`
  display: grid !important;
  grid-template-columns: ${(props: { showTaskDetails?: boolean }) =>
    props?.showTaskDetails ? "40% 60%" : "100%"};
  height: 92% !important;
  /* width: 90% !important; */
  background-color: white;
  width: 100%;
`;
//@ts-ignore
const ZTab = styled(Tab)`
  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  padding: 0px 8px;
`;

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  border-bottom: 1px solid #eaecf0;
`;

const Text = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: #000000;
`;

type Props = {};

const Preview = () => {
  const basePath = window.location.origin;
  const [changed, setChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState<string>("1");
  const { showPreviewModal, taskTypeName, taskTypeId } = useWorkFlow();
  const [renderConfig, setRenderConfig] = useState<any>([]);
  const [iframeUrl, setIframeUrl] = useState<string>(
    `${basePath}/#/tasks?preview=true`
  );
  const dispatch = useDispatch();
  const { taskId } = usePreview();

  const [showTaskDetails, setShowTaskDetails] = useState<boolean>(false);

  const getRenderConfigForShow = async () => {
    TaskRenderService.fetchRenderConfigForTaskId(taskId, "show", undefined)
      .then((res) => {
        if (res?.code && res?.code.indexOf("200") < 0) {
          if (res?.code === "401") {
            notification("error", res.message);
          } else {
            notification("error", res.message);
          }
        } else if (res?.data == null) {
          /* history.push(`/tasks/${props.id}/show?legacy=true`); */
          notification("error", "Can't fetch the render config");
        } else {
          setRenderConfig(res?.data);

          setIframeUrl(`${basePath}/#/task/${taskTypeId}/show/${taskId}?preview=true`);
          /* dispatch(setTaskRenderDetails(res?.data)); */
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const onMessageReceivedFromIframe = useCallback((event) => {
    if (
      event?.data?.source === "zorp_web_preview" &&
      event?.data?.name === "delete_task"
    ) {
      dispatch(setPreviewTaskId(""));
    }
    if (
      event?.data?.source === "zorp_web_preview" &&
      event?.data?.name === "task_row_click"
    ) {
      dispatch(setPreviewTaskId(event?.data?.payload?.taskId));
    }
    if (
      event?.data?.source === "zorp_web_preview" &&
      [
        "update_team",
        "transit_task",
        "edit_task",
        "update_team",
        "delete_task",
        "schedule_task",
      ].includes(event?.data?.name)
    ) {
      //@ts-ignore
      document
        .getElementById("app_preview_flutter")
        ?.contentWindow?.postMessage(
          {
            type: "update_screen",
            payload: {
              taskId,
            },
          },
          "*"
        );
    }
    if (
      event?.data?.source === "zorp_preview" &&
      event?.data?.name === "preview_task"
    ) {
      const { payload } = event.data;
      dispatch(setPreviewTaskId(payload.taskId));
      setChanged((prev) => !!prev);
    } else if (
      event?.data?.source === "zorp_preview" &&
      event?.data?.name === "preview_transition"
    ) {
      const { payload } = event.data;
      setLoading(true);
      dispatch(setPreviewTaskId(""));
      setTimeout(() => {
        dispatch(setPreviewTaskId(payload.taskId));
        setLoading(false);
      }, 1000);

      setChanged((prev) => !!prev);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleModalClose = () => {
    dispatch(setShowPreviewModal(false));
    dispatch(setPreviewTaskId(null));
    localStorage.removeItem("isPreview");
    localStorage.removeItem("previewWorkflowId");
  };

  useEffect(() => {
    window.addEventListener("message", onMessageReceivedFromIframe);
  }, [onMessageReceivedFromIframe]);

  useEffect(() => {
    if (taskId) {
      getRenderConfigForShow();
    }
  }, [taskId]);

  return (
    /**
     * Render A mODAL with 80% height and width of the screen and background white
     */
    <Modal
      open={showPreviewModal}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      id="preview-modal"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        // height:"768px"
      }}
    >
      <div
        style={{
          background: "white",
          width: "100%",
          height: "100%",
          // borderRadius: "8px",
        }}
      >
        <Heading>
          <div>
            <Text> Preview: {taskTypeName} </Text>
          </div>
          <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <ZButton
              variant="contained"
              secondary
              onClick={() => setShowTaskDetails((prev) => !prev)}
              startIcon={
                <BsLayoutSidebarReverse size={"1.3rem"} strokeWidth={1} />
              }
            >
              {showTaskDetails ? "Hide Task Details" : "Show Task Details"}
            </ZButton>
            <ZButton
              variant="contained"
              secondary
              onClick={handleModalClose}
              startIcon={<AiOutlineClose size={"1.3rem"} strokeWidth={1} />}
            >
              Close
            </ZButton>
          </div>
        </Heading>
        <Wrapper showTaskDetails={showTaskDetails} direction="horizontal">
          <Panel collapsible={true}>
            <div style={{ height: "100%" }}>
              <AppIframe />
            </div>
            {/* <p> cascascacasccas cas cascasc as cas c</p> */}
          </Panel>
          {showTaskDetails && (
            <>
              <Panel
                // minSize={70}
                collapsible={true}
                style={{
                  height: "100%",
                }}
              >
                <TabContext value={value}>
                  <ZTabList onChange={handleChange}>
                    <Tabs
                      value={value}
                      aria-label="basic tabs example"
                      TabIndicatorProps={{ style: { background: "black" } }}
                    >
                      <ZTab label="Task Details" value="1" />
                      {taskId && <ZTab label="Task Logs" value="2" />}

                      {/* <ZTab label="Item Three" value="3" /> */}
                    </Tabs>
                  </ZTabList>

                  <TabPanel sx={{ padding: "0px", height: "100%" }} value="1">
                    {loading ? (
                      <LoadingScreen />
                    ) : (
                      <div style={{ height: "100%" }}>
                        <iframe
                          src={iframeUrl}
                          title="YouTube video player"
                          frameBorder="0"
                          allowFullScreen
                          style={{
                            height: "95%",
                            width: "100%",
                          }}
                        ></iframe>
                      </div>
                    )}
                  </TabPanel>
                  <TabPanel
                    style={{
                      height: "84%",
                      padding: "12px 8px",
                      overflow: "auto",
                    }}
                    value="2"
                  >
                    {taskId ? (
                      <div>
                        <Logs type="task" taskId={taskId} />
                      </div>
                    ) : (
                      <ZEmptyDataField text="Select a task or create a new one!" />
                    )}
                  </TabPanel>
                </TabContext>
              </Panel>
            </>
          )}
        </Wrapper>
      </div>
    </Modal>
  );
};

export default Preview;
