import jwt_decode from 'jwt-decode';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { identifyUser, initializeRudderstack } from './config/Rudderstack';
import './index.scss';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(<App />, document.getElementById('root'));

const at = localStorage.getItem('at');
const decoded = !!at?.length ? jwt_decode(at) : null;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

initializeRudderstack();

if (at && process.env['REACT_APP_ENVIRONMENT'] === 'prod') {
  if (decoded) identifyUser(decoded.userId);
}
