import { EntityService } from "providers/data/services/EntityService";
import { memo, useCallback, useEffect, useState } from "react";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import MEntityTable from "views/entities/tables/MEntityTable";
import ZRelationCreateRender from "./ZRelationCreateRender";
import notification from "notifications/notifications";
import useEntity from "views/entities/hooks/useEntity";
import { useParams } from "react-router-dom";
import { IRelationReferringEntityType } from "views/entities/types";
import ZTable from "views/entities/tables/ZTable";
import { useHistory } from "react-router-dom";
import { TaskRenderService } from "providers/data/services/TaskRenderService";
import usePorterPermissions from "hooks/usePorterPermissions";
import AddRecordToRelationModal from "./AddRecordToRelationModal";
import useTaskShow from "views/workflows/hooks/useTaskShow";
import { isArray, isEmpty, set } from "lodash";
import { useDispatch } from "react-redux";
import {
  setAddedRecords,
  setRowsToDelete,
  setSubRecordCreateMode,
  setTaskData,
} from "render-engine/reducers/show/TaskShowReducer";
import styled from "styled-components";
import { Tick as TickIcon, Cross as CrossIcon } from "assests";
import { BlockLabel, ZActionButton } from "render-engine/render.styles";
import { BiEdit } from "react-icons/bi";
import { AiOutlinePlus } from "react-icons/ai";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { IoMdArrowDown, IoMdArrowUp } from "react-icons/io";
import { InfoTooltip } from "views/workflows/Tootltips/InfoTooltip";
import useAccessPermissions from "hooks/useAccessPermissions";
import { ResourceTypeConstant } from "views/entities/permissions/types";

type Props = any;

const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3px;
  padding: 12px 16px;
  background: ${(props: { backgroundColor?: string }) => {
    const primaryColor = props?.theme.color.primary || "";
    const rgb = hexToRgb(primaryColor);
    return rgb ? `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.1)` : "";
  }};
  color: ${(props: { backgroundColor?: string }) => props?.theme.color.primary};
`;

const SubText = styled.p`
  font-size: 12px;
  color: ${(props: { theme: { color: { primary: string } } }) => {
    const primaryColor = props.theme.color.primary;
    const rgb = hexToRgb(primaryColor);
    return rgb ? `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.6)` : "";
  }};
`;

const IconText = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: #344054;
`;

const IconWrapper = styled.div`
  padding: 4px;
  border-radius: 4px;
  // on hover
  &:hover {
    background: #f2f4f7;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  overflow-x: auto;
  justify-content: flex-start;
  align-items: center;
`;

const StyledButton = styled.div`
  display: flex;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border-radius: 6px;
  border: 1px solid #d0d5dd;
  background: #fff;
  margin-right: 3px;
`;

const ZRelationShow = (prop: Props) => {
  // const object = {
  //   ...prop?.config?.value,
  //   tableType: prop.config.relationDetails.referringEntityType,
  // }
  const [openModal, setOpenModal] = useState(false);
  const [openAddRecordModal, setOpenAddRecordModal] = useState(false);
  const [renderConfigs, setRenderConfigs] = useState<any>(null);
  const [isTableEmpty, setIsTableEmpty] = useState<boolean>(false);
  const [isEntityTableEmpty, setIsEntityTableEmpty] = useState<boolean>(false);
  const { selectedEntity } = useEntity();

  //adding record type label from show API passed as prop here, it has to be changed everytime we make a change in recordTypeLabel in useEntity hook.
  const recordTypeLabel = prop?.config?.recordTypeLabel || prop.config.section.label || "record"; 

  const { recordId, tableType } = useParams();
  const history = useHistory();
  // const [addedRecords, setAddedRecords] = useState<any>([]);
  const dispatch = useDispatch();
  const porterPermissions = usePorterPermissions();
  const accessPermissions = useAccessPermissions({
    resourceType: ResourceTypeConstant.TABLE,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRecordAfterSelection, setSelectedRecordAfterSelection] =
    useState<null | any[]>(null);
  const [alreadyCreatedRecordIds, setAlreadyCreatedRecordIds] = useState<
    string[]
  >([]);
  const [listingConfig, setListingConfig] = useState<any>(() => {
    return {
      tableType: prop.config.relationDetails.referringEntityType,
      ...prop?.config?.value,
    };
  });
  const {
    convertDataTypeToString,
    initTaskData,
    rowsToDelete,
    taskData,
    addedRecordsToRelation,
    subRecordData
  } = useTaskShow();
  const addedRecords = addedRecordsToRelation?.[prop.config.key] || [];
  useEffect(() => {
    if (prop.config.value) {
      const displayName =
        prop.config.relationDetails.referringEntity ===
        IRelationReferringEntityType.TASK
          ? "taskId"
          : prop.config.relationDetails.referringEntity ===
            IRelationReferringEntityType.USER
          ? "userId"
          : "recordId";
      setAlreadyCreatedRecordIds(
        prop.config.value?.rowDef.map((item: any) => item[displayName])
      );
    }
  }, [prop.config.value]);
  const getCreateViewForRecords = async () => {
    try {
      const res = await EntityService.getListingView(
        prop.config.relationDetails.referringEntityType,
        "create"
      );
      return res;
    } catch (error) {
      notification("error", "Cannot fetch create view");
    }
  };
  const getCreateViewForTasks = async () => {
    try {
      const res = await TaskRenderService.fetchRenderConfig(
        prop.config.relationDetails.referringEntityType,
        "create",
        undefined
      );
      return res.data;
    } catch (error) {
      notification("error", "Cannot fetch create view");
    }
  };
  const getCreateView = async () => {
    if (
      prop.config.relationDetails.referringEntity ===
      IRelationReferringEntityType.TASK
    ) {
      return await getCreateViewForTasks();
    } else {
      return await getCreateViewForRecords();
    }
  };
  const handleButtonClick = async () => {
    if (
      prop.config.relationDetails.cardinality === "ONE" &&
      currentActualValue.length >= 1
    ) {
      return;
    }
    const config = await getCreateView();
    setRenderConfigs(config);
    // setOpenModal(true);
    setOpenAddRecordModal(true);
  };
  const rowDoubleClick = (row: any) => {
    const primaryKey =
      prop.config.relationDetails.referringEntity ===
      IRelationReferringEntityType.TASK
        ? "taskId"
        : "userId";
    const url =
      prop.config.relationDetails.referringEntity ===
      IRelationReferringEntityType.TASK
        ? `/#/task/${prop.config.relationDetails.referringEntityType}/show/${row.row.original[primaryKey]}`
        : `/#/users/${row.row.original[primaryKey]}/show`;
    window.open(url, "_blank");
  };

  const allValues = convertDataTypeToString(prop.config.key, false, []);

  const getAddedRecords = () => {
    /**
     * Get the value using convertDataTypeToString and let it called addValues
     * get the value from initTaskData
     * show the values that are in addValues but not in initTaskData
     */
    const allValues = convertDataTypeToString(prop.config.key, false, []);
    if (!isArray(allValues)) return;
    // get values that are in allValues but not in initTaskData
    const addedValues = allValues?.filter(
      (value: any) => !initTaskData?.[prop.config.key]?.includes(value)
    );
    const recordsToBeShownOnListing = [...allValues];
    // setAddedRecords(addedValues);
    dispatch(setAddedRecords({ key: prop.config.key, value: addedValues }));
    getTemporaryListingConfig(recordsToBeShownOnListing);
  };

  const getTemporaryListingConfig = useCallback(async (addedValues: any) => {
    setLoading(true);
    if (
      prop.config.relationDetails.referringEntity ===
        IRelationReferringEntityType.USER ||
      prop.config.relationDetails.referringEntity ===
        IRelationReferringEntityType.TASK
    ) {
      const res = await EntityService.getTempListngConfigForTaskAndUser({
        tableType: prop.config.relationDetails.referringEntityType,
        entityType: prop.config.relationDetails.referringEntity,
        recordIds: addedValues,
      });
      const obj = {
        tableType: prop.config.relationDetails.referringEntityType,
        ...res?.data?.body,
      };
      if (res?.data?.body?.rowDef?.length !== 0) {
        setIsTableEmpty(false);
        setIsEntityTableEmpty(false);
      }
      setListingConfig(obj);
      setLoading(false);
    } else {
      try {
        const res = await EntityService.getTempListngConfig({
          tableType: prop.config.relationDetails.referringEntityType,
          entityType: prop.config.relationDetails.referringEntity,
          recordIds: addedValues,
        });
        const obj = {
          tableType: prop.config.relationDetails.referringEntityType,
          ...res?.data?.body,
        };
        if (res?.data?.body?.rowDef?.length !== 0) {
          setIsTableEmpty(false);
          setIsEntityTableEmpty(false);
        }
        setListingConfig(obj);
        setLoading(false);
      } catch (error) {
        console.log("error: ", error);
        setIsEntityTableEmpty(true);
        setIsEntityTableEmpty(false);
        setLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    // get current value
    const currentReduxValue = convertDataTypeToString(
      prop.config.key,
      false,
      []
    );
    // get deleted values
    const deletedValues = rowsToDelete?.[prop.config.key] || [];
    // get values that are in currentReduxValue but not in deletedValues
    const filteredValues = currentReduxValue?.filter(
      (value: any) => !deletedValues.includes(value)
    );
    // set the value
    // dispatch(setTaskData({ nodeId: prop.config.key, data: filteredValues }));
  }, [rowsToDelete]);

  useEffect(() => {
    const value = subRecordData?.[prop.config.key] || taskData?.[prop.config.key] || [];
    if (value) {
      // console.log("openAddRecordModal: ", openAddRecordModal);
      // console.log("taskData: ", taskData?.[prop.config.key]);
      getAddedRecords();
    }
  }, [openAddRecordModal, taskData?.[prop.config.key]]);

  useEffect(() => {
    const allValues = convertDataTypeToString(prop.config.key, false, []);
    const object = {
      ...prop?.config?.value,
      tableType: prop.config.relationDetails.referringEntityType,
    };
    if (!isArray(allValues)) {
      setListingConfig(object);
    } else if (allValues.length === 0) {
      setListingConfig(object);
    } else {
      getTemporaryListingConfig(allValues);
    }
  }, []);

  const currentActualValue = isArray(allValues)
    ? allValues.filter(
        (value: any) => !rowsToDelete?.[prop.config.key]?.includes(value)
      )
    : [];

  return !prop.config.value ? (
    <></>
  ) : (
    <div style={{ width: "100%", padding: "0px 0px 8px 0px" }}>
      {prop.config.relationDetails.referringEntity !==
        IRelationReferringEntityType.USER && (
        <Wrapper>
          <div>
            <BlockLabel>
              {prop.config.section.label}
              {accessPermissions?.manage_section && (
                <BiEdit
                  style={{
                    display: "inline",
                    marginLeft: "8px",
                  }}
                  size="14px"
                  onClick={() => prop.config.setShowCreateSectionModal(true)}
                />
              )}
            </BlockLabel>
            <SubText>
              {" "}
              {isTableEmpty || isEntityTableEmpty
                ? ""
                : "Select records to remove "}
            </SubText>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <div>
              {(!isEmpty(addedRecords) ||
                !isEmpty(rowsToDelete?.[prop.config.key])) && (
                <p
                  style={{
                    fontSize: "12px",
                    color: "#98A2B3",
                  }}
                >
                  {" "}
                  Save to update table{" "}
                </p>
              )}
            </div>
            <InnerWrapper>
              {accessPermissions?.create_record &&
                porterPermissions.checkPermissionsForPorter(
                  prop?.config?.relationDetails?.referringEntityType,
                  "create_record"
                ) && (
                  <InfoTooltip
                    title={
                      prop.config.relationDetails.cardinality === "ONE" &&
                      currentActualValue.length >= 1 &&
                      `Only one ${
                        prop.config.relationDetails.referringEntity === "TASK"
                          ? "task"
                          : `${recordTypeLabel}`
                      } can be attached. Remove existing to add a new one.`
                    }
                  >
                    <ZActionButton
                      onClick={handleButtonClick}
                      style={{ marginLeft: "8px" }}
                      disabled={
                        currentActualValue.length >= 1 &&
                        prop.config.relationDetails.cardinality === "ONE"
                      }
                    >
                      {" "}
                      + Attach{" "}
                      {prop.config.relationDetails.referringEntity ===
                      IRelationReferringEntityType.TASK
                        ? "Task"
                        : `${recordTypeLabel}`}
                    </ZActionButton>
                  </InfoTooltip>
                )}
              {accessPermissions.manage_section && (
                <Menu
                  menuClassName="my-menu"
                  active
                  direction="bottom"
                  menuButton={
                    <IconWrapper>
                      <BsThreeDotsVertical size="12px" strokeWidth={1} />
                    </IconWrapper>
                  }
                  transition
                  // direction="left"
                  gap={12}
                  align="start"
                >
                  <MenuItem
                    onClick={() => prop.config.onSectionMove("UP")}
                    className="menuItem-s"
                    disabled={prop.config.isFirst}
                  >
                    <IconWrapper>
                      <IoMdArrowUp size="14px" />
                    </IconWrapper>
                    <IconText> Move Up </IconText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => prop.config.onSectionMove("DOWN")}
                    className="menuItem-s"
                    disabled={prop.config.isLast}
                  >
                    <IconWrapper>
                      <IoMdArrowDown size="14px" />
                    </IconWrapper>
                    <IconText> Move Down </IconText>
                  </MenuItem>
                </Menu>
              )}
            </InnerWrapper>
          </div>
        </Wrapper>
      )}
      {loading && listingConfig ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p style={{ fontSize: "12px", fontWeight: 500 }}> Loading... </p>
        </div>
      ) : (
        <>
          {prop.config.relationDetails.referringEntity ===
            IRelationReferringEntityType.TASK ||
          prop.config.relationDetails.referringEntity ===
            IRelationReferringEntityType.USER
            ? !isTableEmpty && (
                <ZTable
                  tableType=""
                  initTableConfig={listingConfig}
                  onRowDoubleClick={rowDoubleClick}
                  allowColumnEdit={false}
                  enableGlobalFilter={false}
                  hideToolbar={true}
                  setIsTableEmpty={setIsTableEmpty}
                  addedRecords={addedRecords}
                  nameOfTheTableInRelatedTable={prop.config.key}
                  enableRowSelection={true}
                  rowsToDelete={rowsToDelete?.[prop.config.key] || []}
                  fromShowPage={true}
                />
              )
            : !isEntityTableEmpty && (
                <MEntityTable
                  initTableConfig={listingConfig}
                  fromShowPage={true}
                  enableRowSelection={!prop.config.isReadOnly}
                  addedRecords={addedRecords}
                  nameOfTheTableInRelatedTable={prop.config.key}
                  setIsEntityTableEmpty={setIsEntityTableEmpty}
                  rowsToDelete={rowsToDelete?.[prop.config.key] || []}
                />
              )}
          {openModal && (
            <ZRelationCreateRender
              referringEntity={prop.config.relationDetails.referringEntity}
              recordId={recordId}
              alreadyCreatedRecordIds={alreadyCreatedRecordIds}
              parentTableType={tableType}
              columnId={prop.config.key}
              recordTypeLabel={recordTypeLabel}
              tableType={prop.config.relationDetails.referringEntityType}
              name={prop.config.section.label}
              showModal={openModal}
              setShowModal={setOpenModal}
              mode="create"
              renderConfig={renderConfigs}
              setSelectedRecordAfterSelection={setSelectedRecordAfterSelection}
            />
          )}
          {openAddRecordModal && (
            <AddRecordToRelationModal
              showModal={openAddRecordModal}
              setShowModal={setOpenAddRecordModal}
              mode="create"
              name={prop.config.section.label}
              addedRecords={addedRecords}
              setShowCreateNewRecordModal={() => {
                dispatch(setSubRecordCreateMode(true));
                setOpenModal(true);
              }}
              setValue={prop.setValue}
              recordTypeLabel={recordTypeLabel}
              selectedRecordAfterSelection={selectedRecordAfterSelection}
              setSelectedRecordAfterSelection={setSelectedRecordAfterSelection}
              {...prop}
            />
          )}
        </>
      )}
    </div>
  );
};

export default memo(ZRelationShow);
