export enum DataFieldType {
  DATE = 'DATE',
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
  LOCATION = 'LOCATION',
  BOOLEAN = 'BOOLEAN',
  DATETIME = 'DATE',
  LIST_OF_TEXT = 'LIST_OF_TEXT',
  IMAGE = 'IMAGE',
  FILE = 'FILE',
  SIGNATURE = 'SIGNATURE',
  COLLECTION = 'LIST',
  LIST = 'LIST',
  OBJECT = 'OBJECT',
  URL='URL',
  RELATION = 'RELATION',
  ID = 'ID',
  COMMENT = 'COMMENT',
  LINKS = 'LINKS',
  ONLY_DATE = 'ONLY_DATE',
  TIME = 'TIME',
  DATE_TIME = 'DATE_TIME'
}

export enum EntityTypeField {
  BUTTON = 'BUTTON',
  COMPUTED = 'COMPUTED',
  RELATION = 'RELATION',
  USER = 'USER',
  TEAM = 'TEAM',
}

export enum ActionTypeEntity  {
  BUTTON = 'BUTTON',
}

export enum IComponentType { 
  DROPDOWN = "DROPDOWN"
}

export type CollectionType = {
  type: string;
  name: string;
  displayName: string;
  defaultValue?: any;
  mandatory?: boolean;
  isCreateTimeField?: boolean;
  address ?: string;
  latitude ?: number;
  longitude ?: number
};

export type ListOfValueType = {
  default: boolean | undefined;
  value: string;
  displayValue: string;
};

export type ListOfValuesTypeProp = {
  register: any;
  watch: any;
  control: any;
  errors: any;
  getValues?: any;
  clearErrors: any;
  setValue?: any;
  reset?: any;
  isCreateView?: boolean,
  isEditable?: boolean
};

export type CreateDataFieldModalProps = {
  handleClose: () => void;
  open: boolean;
};

export type ListOfValueOptionsType = {
  value: string;
  displayValue: string;
};

export type ButtonProps = {
  variant: string;
  width?: string
};

export type ZTableRowProps = {
  activeField: string;
};

export type CreatedDataFieldProp = {
  setShowEditOption: (bool: boolean) => void;
  showEditOption: boolean;
  handleClose: () => void;
};

export type SingleCellProps = {
  type: 'header' | 'normal';
};
