import { useState } from "react";


const useSessionStorage = (keyName:string, defaultValue:string) => {
    const [storedValue, setStoredValue] = useState(() => {
      try {
        const value = window.localStorage.getItem(keyName);
  
        if (value) {
            // check if the key has a ttl and it's not expired
            const ttl = window.localStorage.getItem(`${keyName}-ttl`);
            if (ttl) {
                if (new Date().getTime() > parseInt(ttl)) {
                    // if it's expired, remove the key and the ttl
                    window.localStorage.removeItem(keyName);
                    window.localStorage.removeItem(`${keyName}-ttl`);
                    return defaultValue;
                } else {
                    return value
                }
            } else {
                return value;
            }
        } else {
            
          window.localStorage.setItem(keyName, defaultValue);
          // set ttl for the above key, `${keyName}-ttl` as the key and the current time + 1 hour in milliseconds as the value
          if(defaultValue) {
            window.localStorage.setItem(`${keyName}-ttl`, (new Date().getTime() + 3600000).toString());
          }
          return defaultValue;
        }
      } catch (err) {
        return defaultValue;
      }
    });
  
    const setValue = (newValue:string,clearValue=false) => {
      try {
        if(clearValue) {
            window.localStorage.removeItem(keyName);
            window.localStorage.removeItem(`${keyName}-ttl`);
            return;
        }
        // check if that value already exists in localStorage
        const value = window.localStorage.getItem(keyName);
        if (value) {
            // if it does, check if it's the same as the new value
            if (value === newValue) {
                // if it is, do nothing
                return;
            } else {
                // if it's not, update it
                window.localStorage.setItem(keyName, newValue)
                // upate the ttl for the above key, `${keyName}-ttl` as the key and the current time + 1 hour in milliseconds as the value
                window.localStorage.setItem(`${keyName}-ttl`, (new Date().getTime() + 3600000).toString());
            }
           
        }
        window.localStorage.setItem(keyName, newValue);
        // upate the ttl for the above key, `${keyName}-ttl` as the key and the current time + 1 hour in milliseconds as the value
        window.localStorage.setItem(`${keyName}-ttl`, (new Date().getTime() + 3600000).toString());
      
    } catch (err) {}
      setStoredValue(newValue);
    };
  
    return [storedValue, setValue];
  };

export default useSessionStorage