import { LoadingButton } from "@mui/lab";
import MonitorImg from "assests/alerts_monitors.png";
import DescOne from "assests/monitorDescOne.svg";
import DescThree from "assests/monitorDescThree.svg";
import DescTwo from "assests/monitorDescTwo.svg";
import { BiPlus } from "react-icons/bi";
import styled from "styled-components";
const Container = styled.div`
  display: flex;
  flex-direction: column; /* Default: Vertical stack on mobile */

  @media (min-width: 768px) {
    flex-direction: row; /* Horizontal arrangement on laptops */
  }
`;

const Box = styled.div`
  flex: 1;
  color: #fff;
  padding: 20px;
  margin: 10px;
  text-align: center;
`;

const MonitorOnboardingScreenContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
`;

const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  width: 100%;
`;

const MiddleContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 400px;
  margin: 30px 0px;
`;

const BottomContent = styled.div``;

const HeaderText = styled.p`
  color: var(--gray-900, #101828);
  text-align: center;
  /* Display md/Semibold */
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.72px;
`;

const HeaderContent = styled.p`
  color: var(--gray-600, #475467);
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  max-width: 780px;
  padding: 20px 0px;
`;

const FooterDivWrapper = styled.div`
  display: flex;
  justify-content: center;
  justify-items: center;
`;

const FooterContentBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const FooterDivHeader = styled.p`
  color: var(--gray-900, #101828);
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
`;

const FooterDivBody = styled.p`
  color: var(--gray-600, #475467);
  text-align: center;
  /* Text md/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

interface IMonitorOnboardingScreen {
  onClick: () => void;
  buttonLoading:boolean;
}

const MonitorOnboardingScreen = ({ onClick,buttonLoading }: IMonitorOnboardingScreen) => {
  return (
    <MonitorOnboardingScreenContainer>
      <TopContent>
        <HeaderText>Introducing Zorp Alerts</HeaderText>
        <HeaderContent>
          Zorp Alert turns raw data into real-time intelligence, delivering
          alerts the instant anomalies are detected. No more sifting through
          data manually. No more missing critical changes. Welcome to the future
          of automated data surveillance.
        </HeaderContent>

        <LoadingButton
        loadingPosition="start"
          onClick={onClick}
          loading={buttonLoading}
          style={{
            textTransform: "none",
            color: "white",
            backgroundColor: "#3C69E7",
          }}
          variant="contained"
          startIcon={<BiPlus />}
        >
          Create your First Monitor
        </LoadingButton>
      </TopContent>
      <MiddleContent>
        <img src={MonitorImg} width={644} />
      </MiddleContent>
      <BottomContent>
        {" "}
        <Container>
          <Box>
            <FooterContentBox>
              <FooterDivWrapper>
                <img src={DescOne} width={48} />
              </FooterDivWrapper>
              <FooterDivHeader>Initialise & Forget</FooterDivHeader>
              <FooterDivBody>
                Engineered to work ceaselessly in the background, keeping an
                unwavering eye on your data.{" "}
              </FooterDivBody>
            </FooterContentBox>
          </Box>
          <Box>
            {" "}
            <FooterContentBox>
              <FooterDivWrapper>
                <img src={DescTwo} width={48} />
              </FooterDivWrapper>
              <FooterDivHeader>Generate Automated Alerts</FooterDivHeader>
              <FooterDivBody>
                Automatically generate alerts and dispatch them across various
                platforms like Slack, Email, and WhatsApp.{" "}
              </FooterDivBody>
            </FooterContentBox>
          </Box>
          <Box>
            {" "}
            <FooterContentBox>
              <FooterDivWrapper>
                <img src={DescThree} width={48} />
              </FooterDivWrapper>
              <FooterDivHeader>
                Create Tickets & Escalation Policies
              </FooterDivHeader>
              <FooterDivBody>
                Facilitate swift issue resolution with automated ticket
                generation and customisable escalation policies.{" "}
              </FooterDivBody>
            </FooterContentBox>
          </Box>
        </Container>
      </BottomContent>
    </MonitorOnboardingScreenContainer>
  );
};

export default MonitorOnboardingScreen;
