import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ShowTaskActionWrapper,
  ZActionButton,
} from "render-engine/render.styles";
import { makeStyles } from "@material-ui/core/styles";
import { AiOutlineArrowLeft, AiOutlineWarning } from "react-icons/ai";
import { FiClock, FiTrash2 } from "react-icons/fi";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import DeletionConfirmationDialog from "utils/DeletionConfirmationDialog";
import { BiEdit } from "react-icons/bi";
import notification from "notifications/notifications";
import { EntityService } from "providers/data/services/EntityService";
import { RiDeleteBin2Line, RiDeleteBin6Line } from "react-icons/ri";
import {
  formatDate,
  getTeamByTeamId,
  getUserByUserId,
  recordRSEvent,
} from "utils/CommonUtils";
import { TableRSEvents, TaskRSEvents } from "views/entities/types";
import usePorterPermissions from "hooks/usePorterPermissions";
import { ReplyRIcon, SettingRIcon } from "assests";
import { isPlainObject } from "lodash";
import { setLoading } from "views/reducer";
import { useDispatch, useSelector } from "react-redux";
import useTaskShow from "views/workflows/hooks/useTaskShow";
import useEntity from "views/entities/hooks/useEntity";
import { Menu, MenuItem } from "@szhsin/react-menu";
import styled from "styled-components";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import "../../styles/zorpTable.css";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  clearInEditState,
  clearTaskData,
  initRecordShowMetaData,
  initTaskShowMetaData,
  revertToInitialState,
} from "render-engine/reducers/show/TaskShowReducer";
import { UseFormReturn } from "react-hook-form";
import { InfoTooltip } from "views/workflows/Tootltips/InfoTooltip";
import { hasPermission } from "providers/auth/authUtils";
import { Task } from "@mui/icons-material";
import { TaskService } from "views/tasks/services/TaskService";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Box,
} from "@mui/material";
import UserSelection from "views/tasks/UserSelection";
import authProvider from "providers/auth/authProvider";
import { AllowedOperations as AO } from "render-engine/show/STConstants";
import Select from "react-select";
import { ModifyScheduleSlot } from "views/tasks/screens/task/edit/ModifyScheduleSlot";
import moment from "moment";

type Props = {
  taskTypeDisplayName: string;
  taskId: string;
  taskType: string;
  team?: string;
  createdOn?: string;
  updatedOn?: string;
  toggleComments: () => void;
  showTaskForm: UseFormReturn<any>;
  resource: "task" | "table";
};

const Button = styled.button`
  padding: 8px !important;
  border: 1px solid #d0d5dd !important;
  border-radius: 4px;
`;

const TextButton = styled.button`
  border-radius: 4px;
  background-color: ${(props) => {
    return props.theme.color.tertiary;
  }};
  color: ${(props) => {
    return props.theme.color.primary;
  }};
  font-size: 12px;
  font-weight: 600;
  padding: 8px;
`;

//@ts-ignore
const useStyles = makeStyles((theme) => ({
  list: {
    padding: "5px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  icon: {
    left: 0,
  },
  iconOpen: {
    transform: "none",
  },
  formControlLabel: {
    left: 24,
  },
  selectSelect: {
    paddingLeft: "24px",
  },
  text: {
    color: "#616569",
    fontWeight: 600,
    fontSize: "14px",
  },
  heading: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#101828",
  },
  badge: {
    background: "#ECFDF3",
    padding: "4px 12px",
    borderRadius: "16px",
    fontWeight: "500",
    color: "#027A48",
  },
  backButton: {
    padding: "7px",
    border: "1px solid #D0D5DD",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "4px",
  },
  subHeading: {
    color: "#667085",
    fontWeight: 500,
    fontSize: "14px",
  },
  subText: {
    color: "#344054",
    fontWeight: 500,
    fontSize: "14px",
    marginLeft: "8px",
  },
}));

const IconWrapper = styled.div`
  padding: 8px !important;
`;

const IconText = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: #344054;
`;

const ShowTaskAction = ({
  taskTypeDisplayName,
  taskType,
  taskId,
  createdOn,
  updatedOn,
  team,
  toggleComments,
  taskStatusName,
  showTaskForm,
}: Props) => {
  const history = useHistory();
  const classes = useStyles();
  const {
    taskData: data,
    isDataEdited,
    rowsToDelete,
    initTaskData,
    addedRecords,
    taskRenderDetails,
    recordDetails,
  } = useTaskShow();
  const { getDataTypeOfKey } = useEntity();
  const [loading, setLocalLoading] = useState(false);
  const [allowedOperations, setAllowedOperations] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [changeOpen, setChangeOpen] = React.useState(false);
  const [driver, setDriver] = React.useState(recordDetails?.task?.userId);
  const [teamIds, setTeamIds] = React.useState(recordDetails?.task?.teamIds);
  const [toolbarToShow, setToolbarToShow] = React.useState(null);
  const [scheduleSlotModalOpen, setScheduleSlotModalOpen] =
    React.useState(false);
  const [userName, setUserName] = React.useState("");
  const [teamName, setTeamName] = React.useState("");

  const { body: { toolbarActions = {} } = {} } = useSelector(
    (state) => state.taskShow.taskRenderDetails.toolbarConfig
  );

  const dataEdited = isDataEdited();

  const [showDeletionConfirmationDialog, setShowDeletionConfirmationDialog] =
    useState(false);

  const preview = localStorage.getItem("isPreview") || false;
  const dispatch = useDispatch();

  const handleEditClick = () => {
    // history.push(`/table/${taskType}/edit/${taskId}`)
    /**
     * Removing location dataType key if the address, latitude and longitude are undefined
     */
    // loop through all the keys of data
    const payload = { ...data };
    setLocalLoading(true);
    const dataKeys = Object.keys(payload);
    // loop through dataKeys and check if the value is an object
    dataKeys.forEach((key) => {
      if (isPlainObject(payload[key])) {
        // get all the keys of the object
        const objectKeys = Object.keys(payload[key]);
        // check if ojectKey has a value called address, latitude and longitude
        if (
          objectKeys.includes("address") &&
          objectKeys.includes("latitude") &&
          objectKeys.includes("longitude")
        ) {
          // check if the value of address, latitude and longitude is undefined
          if (
            (payload[key].address === undefined ||
              payload[key].address === "") &&
            payload[key].latitude === undefined &&
            payload[key].longitude === undefined
          ) {
            // if yes, then delete the key from payload
            delete payload[key];
          }
        }
      }
    });

    const keysFromRowsToDelete = Object.keys(rowsToDelete);
    // loop through the keys of rowsToDelete
    keysFromRowsToDelete.forEach((key) => {
      // check of the value of key is an array and it has length greater than 0
      if (Array.isArray(rowsToDelete[key]) && rowsToDelete[key].length > 0) {
        // check if this key is in payload
        if (dataKeys.includes(key)) {
          // if yes, then remove the rowsToDelete[key] from payload[key]
          const modifiedData = payload[key].filter(
            (item: any) => !rowsToDelete[key].includes(item)
          );
          payload[key] = modifiedData;
        } else {
          // check if the key is in initTaskData
          if (initTaskData && initTaskData[key]) {
            // if no, then remove the rowsToDelete[key] from initTaskData[key]
            const modifiedData = initTaskData[key].filter(
              (item: any) => !rowsToDelete[key].includes(item)
            );
            payload[key] = modifiedData;
          }
        }
      }
    });

    dispatch(setLoading(true));
    // const teamIds = data?.teamIds || []
    // delete data?.teamIds
    const taskEditPayload: any = {
      taskId,
      taskType,
      teamIds: recordDetails?.task?.teamIds || [],
      scheduledSlot: recordDetails?.task?.scheduledSlot,
      data: payload,
      mode: "single",
      channel: "CC",
    };

    TaskService.updateTask(taskEditPayload)
      .then((res: any) => {
        if (res?.code) {
          if (res?.code.indexOf("200") < 0) {
            dispatch(setLoading(false));
            // Error
            recordRSEvent("save_event_fail", {
              context: "task_edit",
              taskId,
              taskType,
            });
            notification("error", res.message);
            setLocalLoading(false);
          } else {
            dispatch(clearTaskData());
            dispatch(clearInEditState());
            
            // push to previous page
            dispatch(
              initTaskShowMetaData({
                taskId,
              })
            );

            // localStorage.removeItem("isPreview");

            dispatch(setLoading(false));
            setLocalLoading(false);
            const preview = localStorage.getItem("isPreview");
            if (preview) {
              //@ts-ignore
              window.parent.postMessage(
                {
                  source: "zorp_web_preview",
                  name: "edit_task",
                  taskId,
                },
                "*"
              );
              history.push(`/task/${taskType}/show/${taskId}/?preview=true`);
            } else history.push(`/task/${taskType}/show/${taskId}`);
            // localStorage.removeItem("isPreview");
          }
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        setLocalLoading(false);
        recordRSEvent("save_event_fail", {
          context: "task_edit",
          taskId,
          taskType,
        });
        notification("error", "Task cannot be edited!");
      });
  };

  const porterPermissions = usePorterPermissions();

  const handleDelete = () => {
    TaskService.deleteTask(taskId)
      .then((res) => {
        if (res?.code && res?.code.indexOf("200") < 0) {
          if (res?.code === "401") {
            notification("error", res.message);
          } else {
            notification("error", res.message);
          }
        } else {
          notification("success", "Task Deleted Successfully");
          history.push(`/tasks`);
          recordRSEvent(TableRSEvents.DELETE_SINGLE_RECORD_SUCCESS, {
            taskId: taskId,
            taskType: taskType,
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
        notification(
          "error",
          err?.response?.data?.message || "Something went wrong"
        );
        recordRSEvent(TableRSEvents.DELETE_SINGLE_RECORD_FAILURE, {
          taskId: taskId,
          taskType: taskType,
        });
      });
  };

  const fetchUserInfo = async () => {
    try {
      const user = await getUserByUserId(recordDetails?.task?.userId);
      setUserName(user?.name);
      return user;
    } catch (error) {
      notification("error", "Error fetching user info");
      return null;
    }
  };

  const fetchTeamInfo = async () => {
    try {
      const team = await getTeamByTeamId(recordDetails?.task?.teamIds[0]);
      setTeamName(team?.[0]?.name);
      return team;
    } catch (error) {
      notification("error", "Error fetching team info");
      return null;
    }
  };

  useEffect(() => {
    // fetch user info.
    fetchUserInfo();

    // fetch team info.
    fetchTeamInfo();
  }, []);

  const canShowChangeTeamUser = () => {
    return allowedOperations?.system?.includes(AO.UPDATE_TEAM);
  };

  const canShowDelete = () => {
    return hasPermission("task", "delete");
  };

  // Show this action only if task is not already deleted and this workflow is allowed to change slot for this state
  const canShowChangeSlot = () => {
    return allowedOperations?.system?.includes(AO.UPDATE_SLOT);
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      opacity: 1,
      zIndex: 10000000,
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      opacity: 1,
      zIndex: 10000000,
      padding: 0,
    }),
  };

  const handleTransition = (value) => {
    if (preview) {
      history.push({
        pathname: `/task/${taskType}/transition/${taskId}/${value}?preview=true`,
      });
    } else {
      history.push({
        pathname: `/task/${taskType}/transition/${taskId}/${value}`,
      });
    }
  };

  const handleTransitionForFlutterWeb = (event) => {
    event.preventDefault();
    history.push({
      pathname: `/task/${taskId}/webf`,
    });
    // if (preview) {
    //   history.push({
    //     pathname: `/task/transition/${taskId}/${value}?preview=true`,
    //   });
    // } else {
    //   history.push({
    //     pathname: `/task/transition/${taskId}/${value}`,
    //   });
    // }
  };

  const teamHasChanged = () => {
    return (
      JSON.stringify(recordDetails?.task?.teamIds?.sort()) !==
      JSON.stringify(teamIds?.sort())
    );
  };

  const handleTransitionError = (err) => {
    notification("error", "Task Transition Failed");
    setChangeOpen(false);
    recordRSEvent("assign_event_fail", {
      context: "task_details",
      taskId,
      error: err,
    });
    // history.push("/tasks");
    console.log(err);
  };

  const driverHasChanged = () => {
    // Return true if the driver has changed, false otherwise
    // compare recordDetails?.task?.userId and driver and return true if they are different
    return recordDetails?.task?.userId !== driver;
  };

  const callbackUserTeamChange = (res) => {
    if (res.code !== "200") {
      // Error
      if (res.code === "401") {
        recordRSEvent("assign_event_fail", {
          context: "task_details",
          taskId,
          error: res.message,
        });
        notification("error", res.message);
        authProvider.logout();
      } else {
        recordRSEvent("assign_event_fail", {
          context: "task_details",
          taskId,
          error: res.message,
        });
        notification("error", res.message);
      }
    } else {
      notification("success", res.message);
      window.parent.postMessage(
        {
          source: "zorp_web_preview",
          name: "update_team",
          taskId,
        },
        "*"
      );
      // history.push(`/tasks`);
      if (!preview) {
        window.location.reload();
      }
    }
    setChangeOpen(false);
  };

  const driverIsSelected = () => {
    // Return true if a driver is selected, false otherwise
    // compare driver with null and return true if they are different
    return driver !== null && driver !== undefined;
  };

  const handleUserChangeNew = () => {
    if (
      !teamIds ||
      !teamIds[0] ||
      teamIds.length === 0 ||
      teamIds[0].trim()?.length === 0
    ) {
      return notification("error", "Team has to be selected");
    }

    if (teamHasChanged() && !driverIsSelected()) {
      // assignTeam();
      TaskService.updateTeamUserEvent(taskId, driver, teamIds, "UPDATE_TEAM")
        .then(callbackUserTeamChange)
        .catch(handleTransitionError);
    } else if (teamHasChanged() && driverHasChanged() && driverIsSelected()) {
      TaskService.updateTeamUserEvent(taskId, driver, teamIds, "UPDATE_TEAM")
        .then(callbackUserTeamChange)
        .catch(handleTransitionError);
    } else if (!teamHasChanged() && driverHasChanged() && driverIsSelected()) {
      // assignUser();
      if (recordDetails?.task?.userId)
        TaskService.updateTeamUserEvent(
          taskId,
          recordDetails?.task?.userId,
          teamIds,
          "UNASSIGN"
        ).then((res) => {
          TaskService.updateTeamUserEvent(taskId, driver, teamIds, "ASSIGN")
            .then(callbackUserTeamChange)
            .catch(handleTransitionError);
        });
      else
        TaskService.updateTeamUserEvent(taskId, driver, teamIds, "ASSIGN")
          .then(callbackUserTeamChange)
          .catch(handleTransitionError);
    } else if (!teamHasChanged() && !driverIsSelected()) {
      // unassignUser();
      TaskService.updateTeamUserEvent(taskId, driver, teamIds, "UNASSIGN")
        .then(callbackUserTeamChange)
        .catch(handleTransitionError);
    }
    // This can happen when the user is in multiple teams, and a different team but the same user is selected
    else if (teamHasChanged() && driverIsSelected()) {
      // unassignUser();
      TaskService.updateTeamUserEvent(taskId, driver, teamIds, "UPDATE_TEAM")
        .then(callbackUserTeamChange)
        .catch(handleTransitionError);
    }
  };
  const handleChangeClose = () => {
    setChangeOpen(false);
  };

  const handleTeamDriverChange = (teamId, driverId) => {
    setTeamIds([teamId]);
    setDriver(driverId);
  };

  useEffect(() => {
    setDriver(recordDetails?.task?.userId);
    setTeamIds(recordDetails?.task?.teamIds);
    setAllowedOperations(toolbarActions[recordDetails?.task?.taskStatus]);
    setToolbarToShow(taskRenderDetails?.toolbarConfig?.toolbarToShow);
  }, [toolbarActions]);

  return (
    <div
      style={{
        borderBottom: "solid 1px #EAECF0",
        paddingBottom: "8px",
      }}
    >
      <ShowTaskActionWrapper>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
            // padding: "10px",
          }}
        >
          <div
            onClick={() => history.push(`/tasks`)}
            className={classes.backButton}
          >
            <AiOutlineArrowLeft
              style={{ fontSize: "20px", color: "#101828" }}
            />
          </div>

          <p className={classes.heading}>
            {"Task"}
            <span
              style={{ fontSize: "16px", color: "#101828" }}
              className={classes.text}
            >
              {" "}
              {taskId}{" "}
            </span>{" "}
          </p>
          <div className={classes.badge}> {taskTypeDisplayName} </div>
          <div className={classes.badge}> {taskStatusName} </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <div>
            {/* <p>
              {" "}
              <span
                style={{
                  color: "#667085",
                  fontSize: "12px",
                }}
              >
                Created on{" "}
              </span>
              :{" "}
              <span style={{ color: "#667085", fontSize: "12px" }}>
                {formatDate(createdOn)}
              </span>{" "}
            </p> */}
            {isDataEdited() ? (
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: 500,
                  color: "#d92d20",
                }}
              >
                <AiOutlineWarning size="1.2rem" style={{ display: "inline" }} />{" "}
                You have unsaved changes{" "}
              </p>
            ) : (
              <InfoTooltip
                placement="top"
                title={
                  <>
                    <p>Created on: {createdOn}</p>
                    <p>Updated on: {updatedOn}</p>
                  </>
                }
              >
                <div>
                  <p>
                    <span
                      style={{
                        color: "#667085",
                        fontSize: "12px",
                      }}
                    >
                      {" "}
                      Updated{" "}
                    </span>{" "}
                    <span style={{ color: "#667085", fontSize: "12px" }}>
                      {formatDate(updatedOn)}
                    </span>{" "}
                  </p>
                </div>
              </InfoTooltip>
            )}
          </div>
          {dataEdited && (
            // <ZButton
            //   secondary
            //   variant="contained"
            //   onClick={() => {
            //     dispatch(revertToInitialState())
            //     showTaskForm.reset()
            //   }}
            // >
            //   Discard Changes
            // </ZButton>
            <ZActionButton
              onClick={() => {
                dispatch(revertToInitialState());
                showTaskForm.reset();
              }}
            >
              Discard Changes
            </ZActionButton>
          )}
          {porterPermissions.checkPermissionsForPorter(
            taskType,
            "update_record"
          ) && (
            <ZButton
              disabled={!isDataEdited() || loading}
              variant="contained"
              onClick={handleEditClick}
            >
              {loading ? "Saving.." : "Save"}
            </ZButton>
          )}
          <Menu
            menuClassName="my-menu"
            menuButton={
              <Button>
                <BsThreeDotsVertical color="#344054" size={"1.5rem"} />
              </Button>
            }
            transition
            // direction="left"
            gap={12}
            align="start"
          >
            {porterPermissions.checkPermissionsForPorter(
              taskType,
              "delete_record"
            ) && (
              <MenuItem
                className="menuItem-delete"
                disabled={!canShowDelete()}
                onClick={() => {
                  setShowDeletionConfirmationDialog(true);
                  recordRSEvent(
                    TaskRSEvents.DELETE_SINGLE_TASK_BUTTON_CLICKED,
                    {
                      taskId,
                      taskType,
                    }
                  );
                }}
              >
                <IconWrapper>
                  <RiDeleteBin6Line
                    color="#B42318"
                    size={"1.5rem"}
                    stroke="2"
                  />
                </IconWrapper>
                <IconText
                  style={{
                    color: "#B42318",
                  }}
                >
                  {" "}
                  Delete Task{" "}
                </IconText>
              </MenuItem>
            )}
          </Menu>
        </div>

        <DeletionConfirmationDialog
          id={"recordDelete"}
          deletionTitle={"Delete this task?"}
          deletionText={"Do you want to delete this task?"}
          isOpen={showDeletionConfirmationDialog}
          onConfirmDelete={() => handleDelete()}
          onClose={() => setShowDeletionConfirmationDialog(false)}
        />
        <Dialog
          open={changeOpen}
          // fullWidth
          maxWidth="xs"
          onClose={handleChangeClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Change Team/User</DialogTitle>
          <DialogContent style={{ marginTop: "0px", paddingTop: "0px" }}>
            <UserSelection
              id={taskId}
              driver={driver}
              teamIds={teamIds}
              onChange={handleTeamDriverChange}
            />
          </DialogContent>
          <DialogActions>
            <Grid style={{ marginRight: "14px", marginTop: "10px" }}>
              <ZButton
                secondary
                variant="contained"
                onClick={handleChangeClose}
                type="button"
                className="mr-2"
              >
                Cancel
              </ZButton>

              <ZButton variant="contained" onClick={handleUserChangeNew}>
                Change
              </ZButton>
            </Grid>
          </DialogActions>
        </Dialog>
        {scheduleSlotModalOpen && (
          <ModifyScheduleSlot
            taskId={taskId}
            scheduledFrom={recordDetails?.task?.scheduledSlot?.from}
            scheduledTo={recordDetails?.task?.scheduledSlot?.to}
            onClose={() => setScheduleSlotModalOpen(false)}
          />
        )}
      </ShowTaskActionWrapper>
      {/* <ShowTaskActionWrapper>
        <div className={classes.subHeading}>
          Team <span className={classes.subText}>{team || "N/A"}</span>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
            // padding: "10px",
          }}
        >
          <div className={classes.subHeading}>
            Created <span className={classes.subText}>{createdOn}</span>
          </div>
          <div className={classes.subHeading}>
            Updated <span className={classes.subText}>{updatedOn}</span>
          </div>
        </div>
      </ShowTaskActionWrapper> */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "16px",
          padding: "0px 32px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            cursor: "pointer",
          }}
        >
          {canShowChangeTeamUser() && (
            <TextButton onClick={() => setChangeOpen(true)}>
              {`${userName || "No user assigned"} - ${
                teamName || recordDetails?.task?.teamIds?.[0]
              }
              `}
            </TextButton>
          )}
          {canShowChangeSlot() && (
            <TextButton onClick={() => setScheduleSlotModalOpen(true)}>
              {recordDetails?.task?.scheduledSlot?.from
                ? `${moment(recordDetails?.task?.scheduledSlot?.from).format(
                    "DD MMM YYYY HH:MM"
                  )} - ${moment(recordDetails?.task?.scheduledSlot?.to).format(
                    "DD MMM YYYY HH:MM"
                  )}`
                : "No slot assigned"}
            </TextButton>
          )}
        </div>
        <div>
          {!!allowedOperations?.user?.length &&
            taskRenderDetails?.toolbarConfig?.toolbarToShow !== "flutter" && (
              <>
                <Box width={"200px"}>
                  <Select
                    options={allowedOperations?.user?.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    placeholder={"Change Status"}
                    loadingMessage={() => "Loading..."}
                    styles={customStyles}
                    onChange={(e) => handleTransition(e.value)}
                    menuPortalTarget={document.body}
                  />
                </Box>
              </>
            )}
        </div>
      </div>
    </div>
  );
};

export default ShowTaskAction;
