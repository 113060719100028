import MonitorMascot from "assests/monitor_mascot.svg";
import React from "react";
import styled from "styled-components";
import { HeadingText } from "../Monitor.styles";

const ConversationText = styled.p`
color: black;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
`

const MonitorChatContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 16px;
margin: 16px;
margin-top: 0px;
`

const GoBackText = styled.p`
color: #3054B9;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
margin-left: 16px;
cursor: pointer;
margin-bottom:10px;
`

interface IMonitorHeaderDetails {
  onClickGoBack: () => void;
  hideBackBtn?: boolean;
}

const MonitorHeaderDetails = ({ onClickGoBack, hideBackBtn }: IMonitorHeaderDetails) => {
  return (
    <div style={{
      marginTop: "24px"
    }}>
      {!hideBackBtn && <GoBackText onClick={onClickGoBack}>
        {/* {'<-'} &nbsp;  */}
      Clear Conversation</GoBackText>}
      <MonitorChatContainer>
        <img src={MonitorMascot} />
        <div>
          <HeadingText>Personalized Alert</HeadingText>
          <ConversationText>Bluebee can help you craft a personalized Monitor to look out for incidents.</ConversationText>
        </div>
      </MonitorChatContainer>
    </div>
  )
}

export  default React.memo(MonitorHeaderDetails);