//@ts-nocheck
import { useRef } from 'react';
import { Grid } from '@mui/material';
import { FiFilter } from "react-icons/fi";
import Button from '@mui/material/Button';
import { Controller } from "react-hook-form";
import Hidden from '@material-ui/core/Hidden';
import Checkbox from "@material-ui/core/Checkbox";
import FilterAdd from "assests/FilterAdd.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FormControlLabel from '@mui/material/FormControlLabel';
import { ZButton } from 'views/commonComponents/commonComponents.styles';
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { FilterCheckBoxText, FilterClearText } from './FilterStyledComponents';
import {useTheme as useStyledTheme} from "styled-components";

interface IFilterCTA {
    addNewFilter: (position) => void;
    control: any;
    nameList: string[];
    disableSubmitButton: boolean;
    showFilterIcon: boolean;
    showIncludeDeleted?: boolean;
    handleClose: () => void;
    clearFilter: () => void;
}

const CustomColorCheckbox = withStyles({
    root: {
        color: "#606569",
        "&$checked": {
            // color: props => props.zTheme.color.primary
        }
    },
    checked: {}
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
    tickSize: {
        transform: "scale(1.1)"
    },
}));

const FilterCTA = ({ addNewFilter, control, nameList, disableSubmitButton = false, showFilterIcon, showIncludeDeleted = false, handleClose, clearFilter }: IFilterCTA) => {
    const theme = useTheme();
    const filterCTAPositionRef = useRef();
    const classes = useStyles();

    const mobile = useMediaQuery(theme.breakpoints.up("xs"));
    const medium = useMediaQuery(theme.breakpoints.up("sm"));

    return (
        <>
            <Grid
                container
                rowGap={2}
                className='mt-2'
                style={{ 
                width: '100%', 
                display: 'flex',
                justifyContent: 'space-between',
             }}
                sx={{
                    paddingRight: {
                        xs: '20px',
                        sm: '0px'
                    }
                }}
            >

                <Grid style={{ display: 'flex', alignItems: 'center' }} xs={12} sm={6} md={6} lg={6} gap={2}>
                    {
                        showFilterIcon &&
                        <FiFilter style={{ fontSize: '15px' }} />
                    }

                    <Button
                        ref={filterCTAPositionRef}
                        disableElevation fullWidth={mobile && !medium}
                        sx={{
                            height: {
                                xs: '40px',
                                sm: '32px'
                            }
                        }}
                        onClick={() => {
                            addNewFilter(filterCTAPositionRef);
                        }} variant="contained" style={{
                            backgroundColor: '#F0F1F3',
                            color: 'rgba(0, 0, 0, 0.8)',
                            marginLeft: '10px',
                            borderRadius: '8px',
                            textTransform: 'none',
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: 400
                        }} startIcon={<img style={{ paddingRight: '6px' }} src={FilterAdd} />}>
                        Filter
                    </Button>


                    <Hidden xsDown>
                        <FilterClearText onClick={() => {
                            clearFilter();
                        }} >Clear all Filters</FilterClearText>
                    </Hidden>

                </Grid>

                <Grid container xs={12} sm={6} md={6} lg={6} style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row' }}
                sx={{
                    paddingLeft:{
                        xs:'10px',
                        sm:''
                    }
                }}
                >
                    <Grid xs={12} sm={6} md={6} lg={6}>
                        {
                            showIncludeDeleted &&
                            <FormControlLabel
                                label={<FilterCheckBoxText>Include Deleted Tasks</FilterCheckBoxText>}
                                control={
                                    <Controller
                                        name={nameList[0]}
                                        control={control}
                                        render={({ field: props }) =>
                                            <CustomColorCheckbox
                                                checked={props.value}
                                                {...props}
                                                className={classes.tickSize}
                                                onChange={(e: any) => props.onChange(e.target.checked)}
                                            />
                                        }
                                    />

                                }
                            />
                        }
                    </Grid>


                    <Grid className="" style={{ display: 'flex', alignContent: 'center', alignItems: 'center' }} sx={{
                        width: {
                            xs: '100%',
                            sm: 'fit-content',
                            md: 'fit-content'
                        },
                        justifyContent: {
                            xs: 'space-between',
                            sm: '',
                            md: ''
                        }
                    }}>
                        <Hidden smUp>
                            <FilterClearText onClick={() => {
                                clearFilter();
                            }} >Clear all Filters</FilterClearText>
                        </Hidden>
                        <Grid style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {
                                (mobile && !medium) &&
                                <ZButton
                                    secondary
                                    style={{ backgroundColor: '#F0F1F3', marginRight: '10px', fontWeight: 'normal', color: '#303030', borderRadius: '8px', height: '32px', border: '0.8px solid #B3B3B4', width: '107px' }}
                                    variant="contained"
                                    onClick={() => {
                                        handleClose();
                                    }}
                                    type="button">
                                    Close
                                </ZButton>
                            }

                            <Button disableElevation style={{ textTransform: 'none', color:  "#3C69E7", border: `1px solid  #3C69E7`, borderRadius: '8px', height: '32px', width: '107px' }} type="submit" disabled={disableSubmitButton} variant="outlined">Apply Filter</Button>
                        </Grid>
                    </Grid>

                </Grid>

            </Grid>
        </>
    )
}

export default FilterCTA