import { AccountService } from "providers/data/services/AccountService";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineClose } from "react-icons/ai";
import { HiOutlineKey } from "react-icons/hi";
import ZInput from "views/commonComponents/ZTextInput";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import {
  CurlModal,
  CurlModalContainer,
  CurlModalFooter,
  CurlFooterLeftWrapper,
  CurlBodyWrapper,
  CurlBodyText,
  CurlFooterRightWrapper,
  CurlModalHeader,
  HeaderContentWrapper,
  ImportCurlHeaderInfoWrapper,
  ImportCurlHeading,
  ImportCurlSentence,
  ImportIconWrapper,
  CurlDocumentationTxt,
  CurlModalBody,
} from "views/workflows/workflowactions/api/APIAction/ImportCurl/CurlStyledComponents";
import notification from "notifications/notifications";
import ErrorMessage from "views/DataField/ErrorMessage";
import {
  displayNameErrorSchema,
  keyNameErrorSchema,
} from "views/DataField/DataFieldErrorSchema";
import { dataFieldNameErrorSchema } from "views/DataField/DataFieldErrorSchema";

type Props = {
  open: boolean;
  handleClose: () => void;
  mode: "create" | "edit";
  data?: any;
  getAccountDetails: () => void;
};

const SecretKeyModal = ({
  open,
  getAccountDetails,
  handleClose,
  mode,
  data,
}: Props) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);

  const regenerateToken = async (data: any) => {
    setLoading(true);
    try {
      const token: any = await AccountService.generateUnmaskedToken(data);
      console.log(token);
      if (token) {
        if (token?.data?.isNew) {
          notification("success", "Secret key added");
        } else {
          notification("success", "Edited the existing token");
        }

        await getAccountDetails();
      } else {
        notification("error", "Something went wrong, please try again");
      }
    } catch (error) {
      console.log(error);
      notification("error", "Something went wrong, please try again");
    }
    setLoading(false);
  };

  const onFormSubmit = async (data: any) => {
    try {
      if (mode === "edit") {
        data = { ...data, key: data.editKey, editKey: undefined };
      }
      await regenerateToken(data);
      handleClose();
    } catch (error) {
      console.log(error);
      notification("error", "Something went wrong, please try again");
    }
  };

  useEffect(() => {
    if (mode === "edit") {
      setValue("name", data?.name);
      setValue("keyType", data?.keyType);
      setValue("key", data?.keyMasked);
    }
  }, []);

  return (
    <CurlModal open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <CurlModalContainer>
          <CurlModalHeader>
            <HeaderContentWrapper>
              <ImportIconWrapper>
                <HiOutlineKey size={"2rem"} strokeWidth={1.5} />
              </ImportIconWrapper>
              <div>
                <AiOutlineClose size={"1.5rem"} onClick={handleClose} />
              </div>
            </HeaderContentWrapper>

            <ImportCurlHeaderInfoWrapper>
              <ImportCurlHeading>
                {mode === "edit" ? "Modify Secret Key" : "Add Secret Key"}
              </ImportCurlHeading>
              <ImportCurlSentence>Configure Secret Key</ImportCurlSentence>
            </ImportCurlHeaderInfoWrapper>
          </CurlModalHeader>

          <CurlModalBody>
            <CurlBodyWrapper>
              <CurlBodyText>Name</CurlBodyText>
              <ZInput
                type="text"
                register={register}
                validations={keyNameErrorSchema}
                placeholder="Enter Secret Key Name"
                name="name"
              />
              <ErrorMessage errors={errors} fieldName="name" />
              <CurlBodyText className="mt-2">Identifier</CurlBodyText>
              <ZInput
                type="text"
                register={register}
                placeholder="Enter ID to use within Zorp"
                validations={displayNameErrorSchema}
                isReadOnly={mode === "edit"}
                name="keyType"
              />
              <ErrorMessage errors={errors} fieldName="keyType" />
              <ImportCurlSentence className="mt-2">
                This is the id you would use within zorp to refer to this secret
                key. Please do not include spaces. Please use only alphabets,
                numbers and underscores.
              </ImportCurlSentence>

              <CurlBodyText className="mt-2">
                {mode === "edit" ? "Current Secret Key" : "Key"}
              </CurlBodyText>
              <ZInput
                type="text"
                register={register}
                placeholder="Enter Secret Key"
                validations={keyNameErrorSchema}
                name="key"
                isReadOnly={mode === "edit"}
              />
              {mode === "edit" && (
                <>
                  <CurlBodyText className="mt-2">Replace Key</CurlBodyText>
                  <ZInput
                    type="text"
                    register={register}
                    placeholder="Enter Secret Key"
                    validations={keyNameErrorSchema}
                    name="editKey"
                  />
                  <ErrorMessage errors={errors} fieldName="key" />
                </>
              )}
            </CurlBodyWrapper>
          </CurlModalBody>

          <CurlModalFooter>
            <CurlFooterLeftWrapper>
              {/* <div className="pr-2" ><img src={CurlDocumentation} /></div> */}
              <CurlDocumentationTxt
                onClick={() => {
                  window.open(
                    "https://docs.zorp.one/product-docs/workflow-builder/automations/actions/rest-api-action#import-curl",
                    "_blank" // <- This is what makes it open in a new window.
                  );
                }}
              >
                {" "}
                Read Documentation
              </CurlDocumentationTxt>
            </CurlFooterLeftWrapper>

            <CurlFooterRightWrapper>
              <ZButton
                type="button"
                secondary
                variant="contained"
                onClick={handleClose}
              >
                {" "}
                Cancel{" "}
              </ZButton>

              <ZButton type="submit" variant="contained">
                {mode === "create" ? "Add Key" : "Update Key"}
              </ZButton>
            </CurlFooterRightWrapper>
          </CurlModalFooter>
        </CurlModalContainer>
      </form>
    </CurlModal>
  );
};

export default SecretKeyModal;
