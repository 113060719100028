import { useCallback, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { DecodedAuthToken } from "providers/data/models/UserType";
import useLoggedInUser from "./useLoggedInUser";

const usePorterPermissions = () => {
  const user = useLoggedInUser();

  // // check if accountId is porter
  // if (user?.accountId !== 'porter') {
  //   return true;
  // }

  // useEffect(() => {
  //   const user = localStorage.getItem('at');

  //   if (authTokenFromStorage) {
  //     try {
  //       const decodedAuthToken:DecodedAuthToken = jwt_decode(authTokenFromStorage);
  //       setAuthToken(decodedAuthToken);
  //     } catch (error:any) {
  //       console.error(`Error decoding auth token: ${error.message}`);
  //     }
  //   }
  // }, []);

  // if the user's account id is porter and role is KAM,
  // check if the user has access to the table's operation
  const checkPermissionsForPorter = useCallback(
    (tableType?: string, operation?: string): Boolean => {
      // if (["codxjv", "efhp85", "j2lu57"].includes(user?.accountId || "") || !tableType) {
      //   if (user?.accountId === "codxjv") {
      //     if (user?.role !== "ymy5cz") {
      //       return true;
      //     }
      //     else if (user?.role === "ymy5cz" && tableType === "cxu2ct") {
      //       return true;
      //     }
      //     return false;
      //   }
      //   // else if (user?.accountId === "efhp85") {
      //   //   if (tableType !== "hi55vv" || user?.userId === "dlzhwp") {
      //   //     return true;
      //   //   }
      //   //   return true;
      //   // }
      //   else if (user?.accountId === "j2lu57") {
      //     if (user?.role === "pvtdnu") {
      //       if (tableType === "o9qv4i")
      //         return true;
      //       else  
      //         return false;
      //     }
      //     return true;
      //   }        
      //   else
      //     return true;
      // }
      // else {
      //   return true;
      // }
      // check for specific tableType and operation
      // Franchisee table
      // if (tableType === "03uqu8") {
      //   switch (operation) {
      //     case "create_record":
      //       return true;
      //     case "update_record":
      //       return true;
      //     default:
      //       return false;
      //   }
      // }
      // // Orders table
      // else if (tableType === "idqhjo") {
      //   switch (operation) {
      //     default:
      //       return false;
      //   }
      // }
      // // Courier Partner connection
      // else if (tableType === "f0z7jn") {
      //   switch (operation) {
      //     default:
      //       return false;
      //   }
      // }
      // // Missing
      // else if (tableType === "lbv66e") {
      //   switch (operation) {
      //     default:
      //       return false;
      //   }
      // }
      // // Courier Partner List
      // else if (tableType === "vmpaed") {
      //   switch (operation) {
      //     case "create_record":
      //       return true;
      //     case "update_record":
      //       return true;
      //     default:
      //       return false;
      //   }
      // } else if (tableType === "hi55vv") {
      //   return false;
      // }
      // return false;
      return true
    },
    [user]
  );

  return {
    checkPermissionsForPorter,
  };
};

export default usePorterPermissions;
