import { ThemeProvider } from '@material-ui/core/styles';
import {
  Box,
  FormControl,
  Link,
  FormLabel, TextField
} from '@mui/material';
import { UserService } from 'providers/data/services/UserService';
import { useState, FunctionComponent, useEffect } from 'react';
import notification from '../../../notifications/notifications';
import { useHistory, Redirect } from 'react-router-dom';
import theme from 'styles/theme';
import { isEmpty } from 'lodash';
import { ZButton } from '../../../views/commonComponents/commonComponents.styles';
import LoadingScreen from 'views/LoadingScreen/LoadingScreen';
import { PageCategoryEnum, recordPageVisit } from 'config/Rudderstack';
import { recordRSEvent } from 'utils/CommonUtils';
import { userDecodedData } from 'utils/UserAuth.utils';
const logo: string = require("../../../assests/darklogo.svg").default;

const FPRequestScreen: FunctionComponent = () => {
  const history = useHistory();
  const [userId, setUserId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    recordPageVisit(PageCategoryEnum.resetPassword, 'reset_password');
  }, []);


  const submit = (e: React.SyntheticEvent) => {
    recordRSEvent('request_verification_click', {
      context: 'forgot_password',
      userId: userId,
    });
    setLoading(true);
    e.preventDefault();
    UserService.generateOTP(userId).then((res) => {
      setLoading(false);
      recordRSEvent('request_verification_success', {
        context: 'forgot_password',
        userId: userId,
      });
      if (res?.code == '202') {
        notification('success', 'OTP sent to your E-mail and/or Phone Number');
        history.push({ pathname: `/verify-otp/${userId}`});
      } else {
        notification('error', res?.message);
      }
    }).catch((err) => {
      recordRSEvent('request_verification_fail', {
        context: 'forgot_password',
        userId: userId,
      });
      setLoading(false);
      notification('error', 'Error while generating OTP');
    })
  };

  if (!isEmpty(userDecodedData())) {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    );
  }


  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <ThemeProvider theme={theme}>
            <div className='login'>
              <div className='loginInner'>
                <div className='loginImg'>
                  <img src={logo} alt='' />
                </div>
                <h2 className='loginTitle'>Reset Password</h2>
                <form className='loginForm' onSubmit={submit}>
                <Box sx={{ mt: 5,width:'320px' }}>
                    <FormControl variant='standard' fullWidth>
                      <FormLabel
                        sx={{
                          fontFamily: "'Inter', sans-serif",
                          mb: 1,
                          color: 'rgba(0,0,0,0.8)',
                          fontWeight: 'bold',
                          fontSize: 'small',
                        }}
                        htmlFor='my-input'
                      >
                        E-Mail Address / Phone Number / Username
                      </FormLabel>
                      <TextField
                        id='username-input'
                        aria-describedby='Enter your Credentials to Continue'
                        size='small'
                        required
                        onChange={(e) => setUserId(e.target.value)}
                      />
                    </FormControl>
                  </Box>
                  <ZButton variant='contained' fullWidth type='submit' sx={{ mt: 4, mb: 4 }}>
                    Request Verification Code
                  </ZButton>
                </form>
                <Link style={{ fontWeight: '600', color: '#3e66fb' }} href='/#/login' underline='hover'>
                Go Back to Login
              </Link>
              </div>
            </div>
          </ThemeProvider>
        </>
      )}
    </>
  );
};

export default FPRequestScreen;
