import React, { useEffect } from "react";
import notification from "../../../notifications/notifications";
import authProvider from "../../../providers/auth/authProvider";
import { TaskService } from "../services/TaskService";

import { Button, Grid } from "@material-ui/core";
import BackupRoundedIcon from "@material-ui/icons/BackupRounded";
import moment from "moment";
import jwt_decode from "jwt-decode";
import {
  PageCategoryEnum,
  recordPageVisit,
  recordEvent,
} from "../../../config/Rudderstack";
import TaskType from "./Tasktype";
import {
  doesTaskTypehavePickUpLocation,
  validateLocationFields,
} from "../../../utils/TasktypeUtils";
import { getTaskTypeCustomFieldsForBulkTask } from "../../../utils/TasktypeUtils";

import { DataFieldType } from "../../DataField/DataField.types";
import { recordRSEvent } from "utils/CommonUtils";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { setFilter } from "react-admin";
import DownloadTemplateCSV from "./DownloadTemplateCSV";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import { bulkUploadCustomTheme } from "utils/BulkUploadTheme";
import { TaskFormBody } from "render-engine/render.styles";
import Callout from "views/onboarding/components/Callout";
import { Link } from "react-admin";

export default function TaskBulkCreate() {
  recordPageVisit(PageCategoryEnum.task, "bulk_task_create");

  const [selectedTaskType, setSelectedTaskType] = React.useState();
  const [createdTasks, setCreatedTasks] = React.useState([]);
  const [allFields, setAllFields] = React.useState([]);
  const [showUploader, setShowUploader] = React.useState(false);
  const [taskTypeCustomDataFields, setTaskTypeCustomDataFields] =
    React.useState({});

  let items = [];
  createdTasks.forEach((task) => {
    items.push(
      <Grid container style={{ "padding-bottom": "10px" }}>
        {task}
      </Grid>
    );
  });

  let text = "";
  if (createdTasks.length > 0) text = <h5>Task(s) Creation Status</h5>;

  const handleTaskTypeChange = (t) => {
    if (!t) {
      setSelectedTaskType("");
    } else {
      setSelectedTaskType(t);
    }
    setCreatedTasks([]);
  };

  let timeoutId;
  const handleCreateTask = async (results) => {
    let taskPayloads = getTaskPayloads(
      results.validData,
      taskTypeCustomDataFields
    );
    let taskResponseMap = [];


    for (let i = 0; i < taskPayloads.length; i++) {
      let taskPayload = taskPayloads[i];
      taskPayload = {
        ...taskPayload,
        mode: "bulk",
        channel: "Dashboard"
      }

      let responseInfo = {};
      try {
        const res = await TaskService.createTask(taskPayload);
        if (res?.code !== "200") {
          // Error
          if (res?.code === "401") {
            recordRSEvent("create_event_fail", {
              context: "bulk_task_creation",
              taskType: taskPayload.taskType,
            });
            notification("error", res.message);
            authProvider.logout();
          } else {
            responseInfo.success = false;
            recordRSEvent("create_event_fail", {
              context: "bulk_task_creation",
              taskType: taskPayload.taskType,
            });
            responseInfo.message = res.message;
            taskResponseMap.push(responseInfo);
          }
        } else {
          responseInfo.success = true;
          recordRSEvent("create_event_success", {
            context: "bulk_task_creation",
            taskType: taskPayload.taskType,
            taskId: res.data.taskId,
          });
          responseInfo.message = res.message;
          taskResponseMap.push(responseInfo);
        }
      } catch (err) {
        console.log("///// Error", err);
        responseInfo.success = false;
        responseInfo.message = "Task could not be created";
        taskResponseMap.push(responseInfo);
      }
    }

    if (taskPayloads.length === 0) {
      return false;
    }

    let finalMessage = "";
    setCreatedTasks([]);
    let cTasks = [];
    for (let i = 0; i < taskResponseMap.length; i++) {
      let responseInfo = taskResponseMap[i];
      finalMessage = finalMessage + responseInfo.message + "\n";
      cTasks.push(`${responseInfo.message}`);
    }

    notification("info", finalMessage);
    setCreatedTasks(cTasks);

    // clearTimeout(timeoutId);
    // timeoutId = setTimeout(async () => {

    // }, 2000);
  };

  useEffect(() => {
    if (!selectedTaskType) {
      return;
    }

    // Fetch custom data fields for selectec taskType
    async function fetchTaskTypeDataFields() {
      const response = await TaskService.getTaskTypeDataFields(
        selectedTaskType.taskType,
      );
      const taskTypeCustomDataFields = response?.data ? response?.data[0] : [];

      setTaskTypeCustomDataFields({ ...taskTypeCustomDataFields });

      let jwtDecoded = jwt_decode(localStorage.getItem("at"));
      let fields = getFields(taskTypeCustomDataFields);

      setAllFields([...fields]);
    }

    fetchTaskTypeDataFields();
  }, [selectedTaskType]);

  useEffect(() => {
    const previewWorkflowId = localStorage.getItem("previewWorkflowId");
    if (!previewWorkflowId) {
      return;
    }

    setSelectedTaskType({
      taskType: previewWorkflowId,
    })
  }, []);

  let legacyFields = [
    {
      label: "Order Id/Number",
      key: "orderId",
      validators: [{ validate: "required" }],
    },

    {
      label: "Customer Name",
      key: "customerName",
      validators: [{ validate: "required" }],
    },
    {
      label: "Customer Phone Country Code",
      key: "customerCountryCode",
      validators: [{ validate: "required" }],
    },
    {
      label: "Customer Phone Number",
      key: "customerPhoneNumber",
      validators: [{ validate: "required" }],
    },
    {
      label: "Customer Address",
      key: "customerAddress",
      // , validators:
      //   [
      //     {
      //       validate: "required_without_all",
      //       fields: ["customerLatitude", "customerLongitude"]
      //     }
      //   ]
    },
    {
      label: "Customer Latitude",
      key: "customerLatitude",
      validators: [
        {
          validate: "required_with",
          fields: ["customerLongitude"],
        },
        // ,
        // {
        //   validate: "required_without",
        //   fields: ["customerAddress"]
        // }
      ],
    },
    {
      label: "Customer Longitude",
      key: "customerLongitude",
      validators: [
        {
          validate: "required_with",
          fields: ["customerLatitude"],
        },
        // ,
        // {
        //   validate: "required_without",
        //   fields: ["customerAddress"]
        // }
      ],
    },
  ];

  let commonFields = [
    {
      label: "Zorp Group Id",
      key: "zorpGroupId",
      alternateMatches: ["Zorp Group Id"],
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // validators: [{ validate: "required" }],
    },
    {
      label: "Workflow Id",
      key: "taskType",
      alternateMatches: ["Task Type", "Workflow Id"],
      validations: [
        {
          rule: "required",
          errorMessage: "Task Type is required",
          level: "error",
        },
      ],
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
    },
    {
      label: "Team Id(s)",
      key: "teamIds",
      alternateMatches: ["Team Id(s)", "Team Id"],
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      }
    },
    {
      label: "User Id",
      key: "userId",
      alternateMatches: ["User Id"],
      fieldType: {
        type: "input",
      }
    },
    {
      label: "Scheduled Date",
      key: "scheduledDate",
      alternateMatches: ["Scheduled Date"],
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
    },
    {
      label: "Scheduled Start Time",
      key: "scheduledStartTime",
      alternateMatches: ["Scheduled Start Time"],
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
    },
    {
      label: "Scheduled End Time",
      key: "scheduledEndTime",
      alternateMatches: ["Scheduled End Time"],
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
    },
    {
      label: "Offset",
      key: "offset",
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
    },
  ];

  // inventoryBased:true
  let inventoryBasedFields = [
    {
      label: "Product Category",
      key: "productCategory",
      validators: [{ validate: "required" }],
    },
    {
      label: "SKU/Model",
      key: "sku",
    },
    { label: "Product Id/Asset Id", key: "productId" },
    {
      label: "Product Name",
      key: "productName",
    },
    {
      label: "Product Type",
      key: "productType",
      validators: [{ validate: "required" }],
    },
    { label: "Product Image", key: "productImageUrl" },
    {
      label: "Quantity",
      key: "qty",
      validators: [
        {
          validate: "required_with_all_values",
          fieldValues: { productCategory: "NON_SERIALISED" },
        },
      ],
    },
    // { label: "Ship First Name", key : "shipFirstName"},
    // { label: "Ship Last Name", key : "shipLastName"},
    // { label: "Ship Address1", key : "shipAddress1"},
    // { label: "Ship Address2", key : "shipAddress2"},
    // { label: "Ship City", key : "shipCity"},
    // { label: "Ship State", key : "shipState"},
    // { label: "Ship Zip", key : "shipZip"},
    // { label: "Phone1", key : "phone1"},
  ];

  function getFields(taskTypeCustomDataFields) {
    let fields = [];

    // Add all common fields taskType
    fields = fields.concat(commonFields);

    // Add task type specific fields
    fields = fields.concat(
      getTaskTypeCustomFieldsForBulkTask(taskTypeCustomDataFields)
    );
    return fields;
  }

  // Check if date, from and to are valid format
  // Check if date-from < date-to
  function validateValues(record) {
    const validateTaskTypeValue = validateTaskType(record.taskType);
    let validLatLang = validateLatLang(record);
    if (validLatLang) {
      return {
        [validLatLang.field]: {
          info: [{ message: validLatLang.message, level: "error" }],
        },
      };
    }

    if (validateTaskTypeValue)
      return {
        taskType: {
          info: [{ message: validateTaskTypeValue, level: "error" }],
        },
      };

    const validateScheduleDt = validateScheduleDate(record.scheduledDate);
    if (validateScheduleDt)
      return {
        scheduledDate: {
          info: [{ message: validateScheduleDt, level: "error" }],
        },
      };

    const validateScheduleFrom = validateScheduleDateTime(
      record.scheduledDate,
      record.scheduledStartTime
    );
    if (validateScheduleFrom)
      return {
        scheduledStartTime: {
          info: [{ message: validateScheduleFrom, level: "error" }],
        },
      };

    const validateScheduleTo = validateScheduleDateTime(
      record.scheduledDate,
      record.scheduledEndTime
    );
    if (validateScheduleTo)
      return {
        scheduledEndTime: {
          info: [{ message: validateScheduleTo, level: "error" }],
        },
      };

    const validationScheduledSlotResult = validateScheduleSlot(
      record.scheduledDate,
      record.scheduledStartTime,
      record.scheduledEndTime
    );
    if (validationScheduledSlotResult)
      return {
        scheduledStartTime: {
          info: [{ message: validationScheduledSlotResult, level: "error" }],
        },
      };

    const validationOffsetResult = validateOffset(record.offset);
    if (validationOffsetResult)
      return {
        offset: {
          info: [{ message: validationOffsetResult, level: "error" }],
        },
      };

    const validateQuantityResult = validateQuantity(record.qty);
    if (validateQuantityResult)
      return {
        qty: {
          info: [{ message: validateQuantityResult, level: "error" }],
        },
      };
  }

  function validateLatLang(records) {
    const keyArray = Object.keys(records);
    for (let i = 0; i < keyArray.length; i++) {
      let recordKey = keyArray[i];
      if (recordKey.includes("$latitude") || recordKey.includes("$longitude")) {
        if (
          records[recordKey] !== "" &&
          isNaN(records[recordKey].trim()) === true
        ) {
          return {
            field: recordKey,
            message: "Latitude and Longitude must be valid digit.",
          };
          
        }
      }
    }
    return false;
  }

  function validateScheduleSlot(date, from, to) {
    if (date && from && to) {
      const scheduledFrom = moment(date + " " + from);
      const scheduledTo = moment(date + " " + to);

      const diff = scheduledTo.diff(scheduledFrom, "second");
      if (diff <= 0)
        return "Scheduled End Time should be greater than Scheduled Start Time";
    }

    return undefined;
  }

  function validateTaskType(taskType) {
    if (taskType && taskType !== selectedTaskType?.taskType)
      return (
        "Task Type specifed in csv is - '" +
        taskType +
        "' and it is not same as the one selected - '" +
        selectedTaskType?.taskType +
        "'"
      );
    return undefined;
  }

  function validateScheduleDate(date) {
    if (date && !moment(date, "M/D/YYYY", true).isValid())
      return "Scheduled Date is not valid - Required format M/D/YYYY";
    return undefined;
  }

  function validateScheduleDateTime(date, time) {
    if (
      date &&
      time &&
      !moment(date + " " + time, "M/D/YYYY h:m:s A", true).isValid()
    )
      return "Scheduled Time is not valid - Required format h:m:s A";
    return undefined;
  }

  function validateOffset(offset) {
    if (
      offset &&
      !offset.match(/^[G][M][T][+-](?:2[0-3]|[01][0-9]):[0-5][0-9]$/)
    )
      return "Offset is not valid - Required format e.g GMT+05:30";
    return undefined;
  }

  function validateQuantity(qty) {
    if (qty && (isNaN(qty.trim()) === true || parseInt(qty.trim()) < 1))
      return "Quantity should be an integer value equal or greater than 1";
    return undefined;
  }
  //TODO:
  function getTaskPayloads(results, taskTypeCustomDataFields) {
    let taskPayloads = [];
    let jwtDecoded = jwt_decode(localStorage.getItem("at"));
    var defaultTeamIds = jwtDecoded.teamIds;

    recordEvent("BulkTask_getTaskPayloads", {
      recordLength: results?.$data?.length,
      userId: jwtDecoded.userId,
      accountId: jwtDecoded.accountId,
      timestamp: new Date().toISOString(),
    });

    if (results) {
      const bulkTaskList = new Map();
      for (let i = 0; i < results?.length; i++) {
        let taskInfo = { ...results[i] };
        taskInfo.inventoryBased = selectedTaskType?.inventoryBased;
        // add if logic for inventory based
        let key;
        if (selectedTaskType?.inventoryBased) {
          key =
            taskInfo.taskType?.trim() +
            "-" +
            taskInfo.teamIds?.trim() +
            "-" +
            taskInfo.orderId?.trim() +
            "-" +
            (taskInfo.scheduledSlotFromDateTime
              ? taskInfo.scheduledSlotFromDateTime?.trim()
              : taskInfo.scheduledDate?.trim() +
                "-" +
                taskInfo.scheduledStartTime?.trim()) +
            "-" +
            (taskInfo.scheduledSlotToDateTime
              ? taskInfo.scheduledSlotToDateTime?.trim()
              : taskInfo.scheduledEndTime?.trim()) +
            "-" +
            taskInfo.customerName?.trim() +
            (taskInfo.customerLatitude
              ? "-" + taskInfo.customerLatitude?.trim()
              : "") +
            (taskInfo.customerLongitude
              ? "-" + taskInfo.customerLongitude?.trim()
              : "") +
            "-" +
            taskInfo.customerAddress?.trim() +
            "-" +
            taskInfo.customerCountryCode?.trim() +
            "-" +
            taskInfo.customerPhoneNumber?.trim();
        } else {
          key = taskInfo.zorpGroupId ? taskInfo.zorpGroupId : i;
        }

        let infoList = bulkTaskList.get(key);
        if (infoList === undefined) {
          infoList = [];
        }
        infoList.push(taskInfo);
        bulkTaskList.set(key, infoList);
      }

      let continueWithoutOffset = false;
      for (let [key, infoList] of bulkTaskList.entries()) {
        // just take the first record
        let taskInfo = infoList[0];

        const taskPayload = {
          taskType: taskInfo.taskType?.trim(),
          teamIds: taskInfo.teamIds
            ? taskInfo.teamIds.trim().split(",")
            : taskInfo.userId
            ? undefined
            : defaultTeamIds,
          userId: taskInfo.userId ? taskInfo.userId.trim() : undefined,
          scheduledSlot: getScheduleSlot(taskInfo, continueWithoutOffset),
          // Would be different for Legacy and Non-legacy
          data: getData(taskInfo, infoList, taskTypeCustomDataFields),
        };

        taskPayloads.push(taskPayload);
      }
    }

    return taskPayloads;
  }

  function getData(taskInfo, infoList, taskTypeCustomDataFields) {
    let data;
    // For legacy tasks
    if (selectedTaskType?.isBuilderEditable === false) {
      data = [
        {
          orderId: taskInfo.orderId?.trim(),
          products: getProducts(infoList),
          info: getOrderInfo(taskInfo),
        },
      ];
      addTaskTypeFields(data[0], taskInfo, taskTypeCustomDataFields);
    } else {
      // For new task types
      data = {};
      addTaskTypeFields(data, infoList, taskTypeCustomDataFields);
      let compositeTypeFields = {};

      Object.keys(data).forEach((item) => {
        if (item.includes("$")) {
          let separation = item.split("$");
          let fieldName = separation[0];
          let subFieldKey = separation[1];
          let subFieldValue = data[item];

          if (subFieldValue) {
            let fName = fieldName.includes(".")
              ? fieldName.split(".")[0]
              : fieldName;
            const curFieldType = taskTypeCustomDataFields?.customFields?.filter(
              (single) => single.name === fName
            );
            const subFieldType = curFieldType[0]?.children?.filter(
              (single) => single.name === subFieldKey
            );
            
            if (
              subFieldType[0]?.type === DataFieldType.NUMBER &&
              !isNaN(subFieldValue)
            ) {
              subFieldValue = Number.isInteger(subFieldValue)
                ? parseInt(subFieldValue)
                : parseFloat(subFieldValue);
            }
            if (
              subFieldType[0]?.type === DataFieldType.IMAGE ||
              subFieldType[0]?.type === DataFieldType.LIST_OF_TEXT
            ) {
              subFieldValue = subFieldValue
                ? subFieldValue.split(",")
                : undefined;
            }
            if(subFieldType[0]?.type === DataFieldType.BOOLEAN) {
              subFieldValue = subFieldValue?.toLowerCase() === "true" ? true : false;
            }

            if (compositeTypeFields[fieldName]) {
              compositeTypeFields[fieldName] = {
                ...compositeTypeFields[fieldName],
                [subFieldKey]: subFieldValue,
              };
            } else {
              compositeTypeFields[fieldName] = { [subFieldKey]: subFieldValue };
            }
          }

          delete data[item];
        } else {
          const curFieldType = taskTypeCustomDataFields?.customFields?.filter(
            (single) => single.name === item
          );

          if (
            curFieldType[0]?.type === DataFieldType.NUMBER &&
            !isNaN(data[item])
          ) {
            data[item] = Number.isInteger(data[item])
              ? parseInt(data[item])
              : parseFloat(data[item]);
          }
          if (
            curFieldType[0]?.type === DataFieldType.IMAGE ||
            curFieldType[0]?.type === DataFieldType.LIST_OF_TEXT
          ) {
            data[item] = data[item] ? data[item].split(",") : undefined;
          }

          // if type is boolean, cast string to boolean
          if (curFieldType[0]?.type === DataFieldType.BOOLEAN) {
            data[item] = data[item]?.toLowerCase() === "true";
          }
        }
      });

      let parsedCompositeTypeFields = Object.keys(compositeTypeFields);
      let listTypeFields = {};
      parsedCompositeTypeFields.forEach((item) => {
        if (item.includes(".")) {
          let separation = item.split(".");
          let fieldName = separation[0];
          let fieldIndex = separation[1];
          let fieldValue = compositeTypeFields[item];
          if (listTypeFields[fieldName]) {
            listTypeFields[fieldName] = [
              ...listTypeFields[fieldName],
              fieldValue,
            ];
          } else {
            listTypeFields[fieldName] = [fieldValue];
          }
        } else {
          data = { ...data, [item]: compositeTypeFields[item] };
        }
      });

      if (listTypeFields) {
        data = { ...data, ...listTypeFields };
      }
    }

    return data;
  }

  function addTaskTypeFields(taskData, taskInfo, taskTypeCustomDataFields) {
    let taskTypeFields = getTaskTypeCustomFieldsForBulkTask(
      taskTypeCustomDataFields
    );

    for (let fieldName of taskTypeFields.values()) {
      let fName = fieldName.key;
      let subFieldName;
      if (fName.includes("$")) {
        let separation = fName.split("$");
        fName = separation[0];
        subFieldName = separation[1];
      }

      const curFieldType = taskTypeCustomDataFields?.customFields?.filter(
        (single) => single.name === fName
      );

      if (
        taskInfo instanceof Array &&
        curFieldType[0].type === DataFieldType.COLLECTION
      ) {
        for (let i = 0; i < taskInfo.length; i++) {
          taskData[fName + "." + i + "$" + subFieldName] = taskInfo[i][
            fieldName.key
          ]
            ? taskInfo[i][fieldName.key].trim()
            : undefined;
        }
      } else {
        let tInfo = taskInfo instanceof Array ? taskInfo[0] : taskInfo;
        taskData[fieldName.key] = tInfo[fieldName.key]
          ? tInfo[fieldName.key].trim()
          : undefined;
      }
    }
  }

  function getScheduleSlot(taskInfo, continueWithoutOffset) {
    let scheduledSlotValueJson = undefined;
    let sTime = taskInfo.scheduledSlotFromDateTime?.trim();
    let eTime = taskInfo.scheduledSlotToDateTime?.trim();
    let offset = taskInfo.offset?.trim();
    
    if (
      !sTime &&
      taskInfo.scheduledDate &&
      taskInfo.scheduledStartTime &&
      offset
    ) {
      sTime =
        taskInfo.scheduledDate?.trim() +
        " " +
        taskInfo.scheduledStartTime?.trim() +
        (offset ? " " + offset : "");
      sTime = new Date(sTime).toISOString();
    }

    if (
      !eTime &&
      taskInfo.scheduledDate &&
      taskInfo.scheduledEndTime &&
      offset
    ) {
      eTime =
        taskInfo.scheduledDate?.trim() +
        " " +
        taskInfo.scheduledEndTime?.trim() +
        (offset ? " " + offset : "");
      eTime = new Date(eTime).toISOString();
    }

    if (sTime && eTime) {
      scheduledSlotValueJson = {
        from: sTime,
        to: eTime,
      };
    }

    return scheduledSlotValueJson;
  }

  function getProducts(infoList) {
    let productsValueJson = undefined;

    if (infoList.length > 0 && infoList[0].inventoryBased === true) {
      let deliveryProductList = [];
      let pickupProductList = [];
      let repairProductList = [];
      let pMap;

      for (let i = 0; i < infoList.length; i++) {
        let tInfo = infoList[i];
        pMap = {};
        pMap.orderId = tInfo.orderId?.trim();
        pMap.productId = tInfo.productId?.trim();
        pMap.productName = tInfo.productName?.trim();
        pMap.productType = tInfo.productType?.trim();
        pMap.productCategory = tInfo.productCategory?.trim();
        pMap.productImageUrl = tInfo.productImageUrl?.trim();
        pMap.sku = tInfo.sku?.trim();

        if ("NON_SERIALISED" === pMap.productCategory) {
          pMap.qty = parseInt(tInfo.qty?.trim());
        }

        if ("DELIVERY" === tInfo.productType?.trim()) {
          deliveryProductList.push(pMap);
        } else if ("PICKUP" === tInfo.productType?.trim()) {
          pickupProductList.push(pMap);
        } else if ("REPAIR" === tInfo.productType?.trim()) {
          repairProductList.push(pMap);
        }
      }

      if (
        deliveryProductList.length > 0 ||
        pickupProductList.length > 0 ||
        repairProductList.length > 0
      ) {
        productsValueJson = {
          DELIVERY: deliveryProductList,
          PICKUP: pickupProductList,
          REPAIR: repairProductList,
        };
      }
    }

    return productsValueJson;
  }

  function getOrderInfo(taskInfo) {
    let infoValueJson = {
      orderId: taskInfo.orderId?.trim(),
      customer: {
        name: taskInfo.customerName?.trim(),
        location: {
          latitude:
            taskInfo.customerLatitude?.trim() === ""
              ? null
              : parseFloat(taskInfo.customerLatitude?.trim()),
          longitude:
            taskInfo.customerLongitude?.trim() === ""
              ? null
              : parseFloat(taskInfo.customerLongitude?.trim()),
          address: taskInfo.customerAddress?.trim(),
        },
        phone: {
          countryCode: "+" + taskInfo.customerCountryCode?.trim(),
          num: taskInfo.customerPhoneNumber?.trim(),
        },
      },
      pickup: getPickupInfo(taskInfo),
    };

    return infoValueJson;
  }

  function getPickupInfo(taskInfo) {
    let pickupInfo = undefined;
    if (doesTaskTypehavePickUpLocation(selectedTaskType?.taskType)) {
      pickupInfo = {
        name: taskInfo.pickupName?.trim(),
        location: {
          latitude:
            taskInfo.pickupLatitude?.trim() === ""
              ? null
              : parseFloat(taskInfo.pickupLatitude?.trim()),
          longitude:
            taskInfo.pickupLongitude?.trim() === ""
              ? null
              : parseFloat(taskInfo.pickupLongitude?.trim()),
          address: taskInfo.pickupAddress?.trim(),
        },
        phone: {
          countryCode: "+" + taskInfo.pickupCountryCode?.trim(),
          num: taskInfo.pickupPhoneNumber?.trim(),
        },
      };
    }

    return pickupInfo;
  }

  return (
    <TaskFormBody
      style={{
        padding: "20px"
      }}
    >
      <Callout
        text={
          <p>
            Task can be created only for already published workflows. Create{" "}
            <Link className="linkTitle" to="/workflows/create">
              new workflow
            </Link>
          </p>
        }
      />
      <br />
      <div>
        <div className="bt-col-md-6">
          <div>
            <div className="font-semibold">
              <p className="mb-2">Task Type / Workflow Id </p>
              <TaskType
                onChange={handleTaskTypeChange}
                size="full"
                disabled={false}
                type={selectedTaskType?.taskType}
                displayName={selectedTaskType?.taskTypeDisplayName}
              />
            </div>
            
          </div>
        </div>
      </div>
      <br />

      <div>
        {/* {flatFileButton} */}
        {selectedTaskType && (
          <>
            <DownloadTemplateCSV
              fields={allFields}
              selectedTaskType={selectedTaskType?.taskTypeDisplayName}
            />
            <div className="mt-2">
              <ZButton variant="contained" onClick={() => setShowUploader(true)}>
                {" "}
                Upload CSV{" "}
              </ZButton>
            </div>
            
          </>
        )}
        
        <Grid container style={{ "padding-top": "20px" }}>
          {text}
          {items}
        </Grid>
        {showUploader && (
          <ReactSpreadsheetImport
            rowHook={(data, addError) => {
              const locationError = validateLocationFields(
                taskTypeCustomDataFields,
                data
              );
              locationError.forEach((err) =>
                addError(err.fieldName, {
                  message: err.message,
                  level: "error",
                })
              );
              return { ...data };
            }}
            isOpen={showUploader}
            onClose={() => setShowUploader(false)}
            onSubmit={(data) => handleCreateTask(data)}
            fields={allFields}
            customTheme={bulkUploadCustomTheme}
          />
        )}
      </div>
    </TaskFormBody>
  );
}
