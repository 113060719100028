import { PageCategoryEnum, recordPageVisit } from 'config/Rudderstack';
import { useEffect } from 'react';
import AutomationBuilder from 'views/automationBuilder';
import useAutomation from 'views/automationBuilder/hooks/useAutomation';
import { ABMetadataTypeEnum, IAutomation, IAutomationBuilderOtherConfigs, OnSaveHandlerFunction } from 'views/automationBuilder/types/ABType';

type IConfigureAutomation = {
  metaDataType: ABMetadataTypeEnum;
  prevAutomations: IAutomation[];
  onSaveHandler: OnSaveHandlerFunction;
  otherConfigs: IAutomationBuilderOtherConfigs | {};
  openAutomationBuilder: boolean
  onAutomationClose: () => void;
  stateId?: string,
  automationSelected?: string
}

const ConfigureAutomation = ({ metaDataType, stateId, prevAutomations, onSaveHandler, otherConfigs, openAutomationBuilder, onAutomationClose, automationSelected }: IConfigureAutomation) => {
  const {metadataType} = useAutomation();

  useEffect(() => {
    if(metadataType){
      recordPageVisit(PageCategoryEnum.automationBuilder, metadataType);
    }
  }, [metadataType]);

  return (
    <>
      {
        openAutomationBuilder && (
          <AutomationBuilder
            prevAutomations={prevAutomations || []}
            payload={metaDataType}
            onAutomationClose={onAutomationClose}
            onSaveHandler={onSaveHandler}
            otherConfigs={otherConfigs}
            openAutomationBuilder={openAutomationBuilder}
            stateId={stateId}
            automationSelected={automationSelected}
          />
        )
      }
    </>
  )

}

export default ConfigureAutomation