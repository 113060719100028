import { useState } from "react";
import styled from "styled-components";
import { useDispatch, useStore } from "react-redux";
import {
  deleteTransition,
  setSelectedTransition,
  setDeleteIconOnTransition,
} from 'views/workflows/reducers/workflowAction';
import useWorkflow from 'views/workflows/hooks/useWorkFlow';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { BiEdit } from 'react-icons/bi';
import { InfoTooltip } from 'views/workflows/Tootltips/InfoTooltip';
import Typography from '@mui/material/Typography';
import { DetectHomeNode } from './DetectHomeUtils';
import DeletionConfirmationDialog from 'utils/DeletionConfirmationDialog';

const Strip = styled.div`
  background: white;
  width: max-content;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  padding: 6px;
  justify-content: center;
  align-items: center;
  margin-left: ${(props) => `${props.posX}px`};
  margin-top: ${(props) => `${props.posY}px`};
  display: flex;
  z-index: 1000;
`;

const HoverStrip = () => {
  const { deleteIconOnTransition } = useWorkflow();
  const dispatch = useDispatch();
  const [openDeletionConfirmation, setOpenDeletionConfirmation] =
    useState(false);
  const store = useStore();

  return (
    <Strip
      posX={deleteIconOnTransition.posX}
      posY={deleteIconOnTransition.posY}
      onMouseLeave={() =>
        dispatch(
          setDeleteIconOnTransition({
            posX: null,
            posY: null,
            transition: {},
            onStrip: false,
          })
        )
      }
    >
      {" "}
      <InfoTooltip
        title="Delete Transition"
      >
        <div style={{ display: "inline", zIndex: "1000" }}>
          <RiDeleteBin6Fill
            size={"1.4rem"}
            color={"#757575"}
            style={{
              zIndex: "100",
            }}
            onClick={() => setOpenDeletionConfirmation(true)}
          />{" "}
        </div>
      </InfoTooltip>
      <InfoTooltip
        title="Update Transition"
      >
        <div style={{ display: "inline", zIndex: "1000" }}>
          <BiEdit
            size={"1.4rem"}
            color={"#757575"}
            style={{
              zIndex: "100",
              marginLeft: "10px",
            }}
            onClick={() => {
              dispatch(setSelectedTransition(deleteIconOnTransition.transition))
              dispatch(
                setDeleteIconOnTransition({
                  xPos: null,
                  yPos: null,
                  transition: {},
                  onStrip: false,
                })
              );
            }
              
            }
          />{" "}
        </div>
      </InfoTooltip>
      <DeletionConfirmationDialog
        id={"flowTransitionDetail"}
        deletionTitle={"Delete Transition"}
        deletionText={"Do you want to delete this Transition?"}
        isOpen={openDeletionConfirmation}
        onConfirmDelete={() => {
          dispatch(deleteTransition(deleteIconOnTransition.transition));
          DetectHomeNode.setHomeAndTerminalNodes(
            store.getState()?.builder?.workflow?.canvas?.elements || [],
            dispatch
          );
          DetectHomeNode.markAllReachableNode(store.getState()?.builder?.workflow?.canvas?.elements || [],
      dispatch)
        }}
        onClose={() => {
          setOpenDeletionConfirmation(false);
        }}
      />
    </Strip>
  );
};

export default HoverStrip;
