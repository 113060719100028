import _, { isObject } from 'lodash';
import { EditorScriptLocation } from 'lowcode/state/types/types';
import { DataFieldType } from 'views/DataField/DataField.types';
const moment = require('moment');

export const generateId = (length: number) => {
  var result = '';
  var alphaNumeric = 'abcdefghijklmnopqrstuvwxyz0123456789';
  var alphabets = 'abcdefghijklmnopqrstuvwxyz';
  var charactersLength = alphaNumeric.length;

  result += alphaNumeric.charAt(Math.floor(Math.random() * alphabets.length));
  for (var i = 1; i < length; i++) {
    result += alphaNumeric.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const convertToTitleCase = (str: string) => {
  return str
      .replace(/([A-Z])/g, ' $1')
      .trim()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
}

//compare two json objects and return if all keys are equal or not
export const compareJsonObjects = (obj1: any, obj2: any) => {
  if (obj1 === obj2) {
    return true;
  }
  if (obj1 == null || obj2 == null) {
    return false;
  }
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return false;
  }
  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  }
  for (var key in obj1) {
    if (!compareJsonObjects(obj1[key], obj2[key])) {
      return false;
    }
  }
  return true;
};

export const matchReturnDataType = (dataType: any, returnDataType: any) => {
  // returnDataType = dataType === OBJECT ? JSON.parse(returnDataType) : returnDataType;
  if (dataType === DataFieldType.BOOLEAN && typeof returnDataType === 'boolean') {
    return true;
  } else if ((dataType === DataFieldType.TEXT || dataType === 'DATE') && typeof returnDataType === 'string') {
    return true;
  } else if (dataType === DataFieldType.NUMBER && typeof returnDataType === 'number') {
    return true;
  } else if ((dataType === 'ARRAY' || dataType === DataFieldType.LIST || dataType === DataFieldType.LIST_OF_TEXT) && Array.isArray(returnDataType)) {
    return true;
  } else if (dataType === DataFieldType.OBJECT && isObject(returnDataType)) {
    return true;
  } else if (dataType === DataFieldType.LOCATION && isObject(returnDataType)) {
    return true;
  }
};

export const getLowCodeEditorDefaultReturnText = (dataType: DataFieldType) => {
  let lowCodeEditorDefaultReturnText: string | number = '';
  switch (dataType) {
    case DataFieldType.TIME:
      lowCodeEditorDefaultReturnText = `'23:00'`;
      break;
    case DataFieldType.TEXT:
      lowCodeEditorDefaultReturnText = `"Hello World"`;
      break;
    case DataFieldType.BOOLEAN:
      lowCodeEditorDefaultReturnText = 'true';
      break;
    case DataFieldType.LIST_OF_TEXT:
      lowCodeEditorDefaultReturnText = '["one","two"]';
      break;
    case DataFieldType.LIST:
      lowCodeEditorDefaultReturnText = '[]';
      break;
    case DataFieldType.OBJECT:
      lowCodeEditorDefaultReturnText = '{}';
      break;
    case DataFieldType.NUMBER:
      lowCodeEditorDefaultReturnText = 123;
      break;
    case DataFieldType.DATETIME:
      lowCodeEditorDefaultReturnText = 'DateTime.now().toISO()';
      break;
    case DataFieldType.SIGNATURE:
      lowCodeEditorDefaultReturnText = `"https://images.pexels.com/photos/1697912/pexels-photo-1697912.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"`;
      break;
    case DataFieldType.IMAGE:
    case DataFieldType.FILE:
      lowCodeEditorDefaultReturnText = '["https://images.pexels.com/photos/1697912/pexels-photo-1697912.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", "https://images.pexels.com/photos/1697912/pexels-photo-1697912.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"]';
      break;
    case DataFieldType.URL:
      lowCodeEditorDefaultReturnText = '"https://jsonplaceholder.typicode.com/todos/1"';
      break;
    default:
      lowCodeEditorDefaultReturnText = `"Hello World"`;
      break;
  }
  return lowCodeEditorDefaultReturnText;
}

// string = 'app_text_box';
//check if given string starts with 'app_' and return text after 'app_'
export const getAppText = (str: string) => {
  if (str.startsWith('app_')) {
    return str.substring(4);
  }
};

// group array by key
export const groupArrayByKey = (array: Array<any>, key: string) => {
  // Return the end result
  const groupedObject = array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    if (!result[currentValue[key]]) {
      result[currentValue[key]] = [];
    }
    result[currentValue[key]].push(currentValue);
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
  return groupedObject;
};

export const getArrayfromObject = (customFieldObject: any) => {
  const selectedFields: Array<string> = [];
  Object.keys(customFieldObject).forEach((taskType) => {
    customFieldObject[taskType].forEach((field: string) => {
      selectedFields.push(field);
    })
  })

  return selectedFields;
}

export const getLowCodeEditorText = (fieldName: string, fieldDataType: DataFieldType, returnText: string): string => {
  if(fieldName === EditorScriptLocation.API_ACTION_SHOULD_RETRY) {
    return `// Depending on the function where you are configuring this retry condition, you have access to the object that is returned from the function. \n// If the function returns true the function would be retried. \n// You can use this to determine if you want to retry the action or not. \n\n ${returnText};`;
  }
  return `${returnText};`;
}


export const convertCamelCase = (str: string) => {
  return str
    // Add space before every capital letter that follows a lowercase letter
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    // Capitalize the first letter
    .replace(/^./, function (str: string) { return str.toUpperCase(); });
}

export const getTimeDifference = (inputTime:string) => {

  // Create a moment object for the input time
  const inputMoment = moment(inputTime);

  // curent time
  const currentMoment = moment();

  // Calculate the difference between the input time and the current time
  const diffDuration =  moment.duration(currentMoment.diff(inputMoment));

  // Calculate the difference in days
  const diffDays = diffDuration.asDays();

  // Calculate the difference in hours
  const diffHours = diffDuration.asHours();

  // Calculate the difference in minutes
  const diffMinutes = diffDuration.asMinutes();

  // Calculate the difference in seconds
  const diffSeconds = diffDuration.asSeconds();

  // Determine the appropriate unit for the time difference
  if (Math.floor(diffDays) > 0) {
    return Math.floor(diffDays) + ' days';
  } else if (Math.floor(diffHours) > 0) {
    return Math.floor(diffHours) + ' hours';
  } else if (Math.floor(diffMinutes) > 0) {
    return Math.floor(diffMinutes) + ' minutes';
  } else {
    return Math.floor(diffSeconds) + ' seconds';
  }
}

// convet ISO date to local date in format 'DD MMM YYYY'
export const convertISODateToLocalDate = (date: string) => {
  return moment(date).format('DD MMM YYYY');
}

export const getUpdateDataFieldIndex = (expressionStr:string) =>{
  const regex = /\[(\d+)\]/; // regular expression to match the number inside square brackets
  const matches = expressionStr.match(regex); // matches will be an array containing the entire match and the captured group
  
  if (matches && matches.length > 1) {
    const number = parseInt(matches[1]); // the captured group will be at index 1
    return number;
  }

  return -1;
}

export const getValueFromJson = (jsonData: any, path: string) => {
  const value = _.get(jsonData, path);
  const type = getType(value);
  return {value:value, type};
}

function getType(value:any) {
  if (value === null) {
    return 'null';
  } else if (Array.isArray(value)) {
    return 'array';
  } else if (typeof value === 'object') {
    if (value instanceof Date) {
      return 'date';
    } else if (value instanceof RegExp) {
      return 'regexp';
    }
  }
  return typeof value;
}

const flattenStructure = (struct:any, parentKey = '', sep = '.') => {
  let items:any = [];
  struct.forEach((field:any) => {
      if (parentKey.includes(' ')) {
          if (field.dataType === 'OBJECT' && field.struct) {
              items.push(field.id);
          }
          return;
      }

      let newKey = parentKey ? `${parentKey}${sep}${field.name}` : field.name;

      if (field.dataType === 'OBJECT' && field.struct) {
          if (field.struct.length > 0) {
              items = items.concat(flattenStructure(field.struct, newKey));
          } else {
              items.push(field.id); // Push the id if the struct is empty
          }
      } else if (field.dataType === 'LIST' && field.struct) {
          items = items.concat(flattenStructure(field.struct, `${newKey}[0]`));
      } else {
          items.push(newKey);
      }
  });
  return items;
};

const flattenResponseStructure = (struct:any, parentKey = '', sep = '.') => {
  let items:any = [];
  struct.forEach((field:any) => {
      if (parentKey.includes(' ')) {
          if (field.dataType === 'OBJECT' && field.struct) {
              items.push(field.id);
          }
          return;
      }

      let newKey = parentKey ? `${parentKey}${sep}${field.name}` : field.name;

      if (field.dataType === 'OBJECT' && field.struct) {
          if (field.struct.length > 0) {
              items = items.concat(flattenResponseStructure(field.struct, newKey));
          } else {
              items.push(parentKey); // Push the parentKey if the struct is empty
          }
      } else if (field.dataType === 'LIST' && field.struct) {
          items = items.concat(flattenResponseStructure(field.struct, `${newKey}[0]`));
      } else {
          items.push(newKey);
      }
  });
  return items;
};

export const processResponseStructure = (structureObj:any) => {
  let result:any = [];
  for (const key in structureObj) {
      if (key.includes(' ')) {
          if (Array.isArray(structureObj[key]) && structureObj[key].length > 0) {
              structureObj[key].forEach(item => {
                  if (item.id) result.push(item.id);
              });
          }
      } else if (Array.isArray(structureObj[key])) {
          if (key.includes('response_')) {
              result = result.concat(flattenResponseStructure(structureObj[key], key));
          } else {
              result = result.concat(flattenStructure(structureObj[key], key));
          }
      }
  }
  return result;
};

export const getOperatingSystem = () => {
  const userAgent = window.navigator.userAgent;
  let os = 'Unknown OS';

  if (userAgent.indexOf('Win') !== -1) os = 'Windows';
  if (userAgent.indexOf('Mac') !== -1) os = 'MacOS';
  if (userAgent.indexOf('X11') !== -1) os = 'UNIX';
  if (userAgent.indexOf('Linux') !== -1) os = 'Linux';
  if (/Android/.test(userAgent)) os = 'Android';
  if (/iPhone|iPad|iPod/.test(userAgent)) os = 'iOS';

  return os;
};