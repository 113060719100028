import { useState, useEffect, useCallback } from "react";
import { TableRSEvents } from "../types";
import debounce from 'lodash/debounce';
import { MdOutlineSearch as SearchIcon } from "react-icons/md";
import { ZButtonWrapper } from "./CustomToolbar";
import { HiOutlineFilter } from "react-icons/hi"
import { LuArrowUpDown as SortingIcon } from "react-icons/lu";
import { IoMdCloseCircleOutline as CloseIcon } from "react-icons/io";
import {
  recordRSEvent,
} from "utils/CommonUtils";
import {
  setShowCreateEntityModal,
} from "../reducers/entityReducers";
import { useDispatch } from "react-redux";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import styled from "styled-components";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { StylesConfig, components } from "react-select";
import {
  RefreshTableButton,
} from "./actions/Actions";

const InputContainer = styled.div`
display: flex;
width: 100%;
max-width: 200px;
justify-content: center;
align-items: center;
gap: 8px;
padding: 6px 8px;
border: 1px solid #EAECF0;
border-radius: 4px;
`

const Input = styled.input`
width: 100%;
margin-right: 10px;
font-size: 11px;
outline: none;
border: none;
`

type Props = {
  table: any;
  setLoading: any;
  columnFilterFns: any;
  showColumnFilters: boolean;
  setRefresh: any;
  fromShowPage?: boolean;
  setSelectedRows?: any;
  viewId?: string;
  tableType: string;
  onNewRecordRecordClick?: any;
};

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: ${(props: any) => (props?.isAdd ? "#F5F8FF" : "")};
  color: ${(props: any) =>
    props?.isAdd ? `${props.theme.color.primary}` : "black"};
`;

const { Option } = components;
const IconOption = (props: any) => {
  return (
    <>
      <Option {...props}>
        <OptionWrapper isAdd={props.data.value === "newEntity"}>
          <div>
            <p style={{ fontSize: "12px", fontWeight: 500 }}>
              {props.data.data.icon}
            </p>
          </div>
          <div>{props.data.label}</div>
        </OptionWrapper>
      </Option>
    </>
  );
};

const CustomToolbarForAddRecordToRelationModal = ({
  table,
  showColumnFilters,
  setRefresh,
}: Props) => {
  const selectedRows = table.getSelectedRowModel().rows.map((row: any) => {
    return row.original;
  });

  const isFilterApplied = table.getState()?.columnFilters?.length;

  const isSortingApplied = table.getState()?.sorting?.length;

  const isGlobalSearchApplied = table.getState()?.globalFilter?.length;

  const allRows = table.getPrePaginationRowModel().rows.map((row: any) => {
    return row.original;
  });

  const [searchInput, setSearchInput] = useState('');

  const removeColumnFilters = () => {
    table.resetColumnFilters();
  };

  const removeSorting = () => {
    table.resetSorting();
    table.setSorting([]);
  };

  const dispatch = useDispatch();

const debouncedSetGlobalFilter = useCallback(
  debounce((value) => {
    table.setGlobalFilter(value);
  }, 500),
  []
);

useEffect(() => {
  debouncedSetGlobalFilter(searchInput);
}, [searchInput, debouncedSetGlobalFilter]);

  const handleNewCreateClick = () => {
    dispatch(setShowCreateEntityModal(true));
    recordRSEvent(TableRSEvents.ADD_NEW_TABLE_DROPDOWN_OPTION_CLICKED);
  };

  const colourStyles: StylesConfig<any> = {
    control: (styles: any) => ({ ...styles, backgroundColor: "white" }),
    menu: (styles: any) => ({ ...styles, zIndex: 9999 }),
    option: (
      styles: { [x: string]: any },
      { data, isDisabled, isFocused, isSelected }: any
    ) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#F5F8FF" : "white",
        color: "black",
        padding: "10px",
        ":active": {
          ...styles[":active"],
          backgroundColor: "#F5F8FF",
        },
      };
    },
    input: (styles: any) => ({ ...styles }),
    placeholder: (styles: any) => ({ ...styles }),
    singleValue: (styles: any, { data }: any) => ({ ...styles }),
  };

  const resetChanges = () => {
    table.resetColumnFilters();
    table.setColumnFilters([]);

    table.resetSorting();
    table.setSorting([]);

    // table.setGlobal
    table.resetGlobalFilter();
    table.setGlobalFilter("");

    setSearchInput("");
  };

  return (
    <>
      <div
        style={{
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "8px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "8px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {isFilterApplied > 0 && (
              <ZButtonWrapper $isFilter={true} onClick={() => table.setShowColumnFilters(!showColumnFilters)}
                startIcon={<HiOutlineFilter size={"12px"} color="#B54708" />}
                endIcon={<AiOutlineClose color="#B54708" onClick={removeColumnFilters} size={"12px"} strokeWidth={1} />}
              >
                <span style={{ marginBottom: '1px' }}>{isFilterApplied} filter(s) applied</span>
              </ZButtonWrapper>
            )}
            {isSortingApplied > 0 && (
              <ZButtonWrapper $isSorting
                startIcon={<SortingIcon color={'#027A48'} size={'12px'} />}
                endIcon={<AiOutlineClose color={'#027A48'} onClick={removeSorting} size={"12px"} strokeWidth={1} />}>
                <span style={{ marginBottom: '1px' }}>Sorted by {isSortingApplied} field</span>
              </ZButtonWrapper>
            )}
            {
              (isGlobalSearchApplied > 0) && (
                <ZButtonWrapper $isGlobalFilter
                  endIcon={<AiOutlineClose
                    color={'#B54708'}
                    onClick={() => table.setGlobalFilter("")}
                    size={"12px"}
                  />}>
                  <span style={{ marginBottom: '1px' }}> Global search applied </span>
                </ZButtonWrapper>
              )
            }
            {(isFilterApplied > 0 ||
              isSortingApplied > 0 ||
              isGlobalSearchApplied > 0) && (
                <ZButtonWrapper onClick={resetChanges} $isReset startIcon={<CloseIcon color={'#DC6803'} size={"12px"} />}>
                <div>
                  <p> Reset Changes </p>
                </div>
              </ZButtonWrapper>
            )}
          </div>
          <div
            style={{
              display: "flex",
              gap: "8px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* <ShowHideColumnAction table={table} /> */}
            <RefreshTableButton setRefresh={setRefresh} table={table} />
            <InputContainer>
              <SearchIcon color={'#C2C9D1'} fontSize={20} />
              <Input
                type="text"
                placeholder="Search..."
                value={searchInput}
                onChange={(e) => {
                  setSearchInput(e.target.value);
                }}
              />
            </InputContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomToolbarForAddRecordToRelationModal;