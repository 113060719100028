import Editor, { useMonaco } from "@monaco-editor/react";
import { useState } from "react";
import useWorkFlow from "views/workflows/hooks/useWorkFlow";


type Props = {
    json : {}
};

const ApiTest = ({json}: Props) => {
  const monaco = useMonaco();

  const [fontSize,setFontSize] = useState(16)
  const [theme, setTheme]  = useState("vs-dark")
  const {apiResponse} = useWorkFlow()
  



  const settings = {
    minimap: false,
    wordWrap: false,
    fontSize: fontSize,
    vimMode: false,
    theme: "vs-dark",
  };

  return (
    <div>
        {/* <button type="button" onClick={performCall}> Button </button> */}
      <Editor
        language="json"
        value={JSON.stringify(json,null, 2)}
        theme={theme}
        height={apiResponse?.data ? "28vh" : "30vh"}
        options={{
          minimap: { enabled: settings.minimap },
          wordWrap: settings.wordWrap ? "on" : "off",
          fontSize,
          automaticLayout: true,
          readOnly: true,
        }}
      />
    </div>
  );
};

export default ApiTest;
