import axios from "axios";
import { getUserInfo } from "./CommonUtils";

export const sendCCErrorAlertToSlack = async ({
  endpoint,
  code,
  message,
  type="API"
}: {
  endpoint: string;
  code?: number;
  message: string;
  type ?: "API" | "ERROR"
}) => {
  const userInfo = getUserInfo();
  const slackMessage = {
    text: type === "API" ? "API Call failed in CC" : "Error while rendering CC",
    attachments: [
      {
        title: "Details",
        color: "#00ff51",
        fields: [
          {
            title: "Endpoint",
            value: endpoint,
            short: true,
          },
          {
            title: "Error Code",
            value: code,
            short: true,
          },
          {
            title: "User Info",
            value: JSON.stringify(userInfo),
            short: true,
          },
          {
            title: "Error Message",
            value: message,
            short: true,
          },
        ],
      },
    ],
  };
  if (process.env["REACT_APP_ENVIRONMENT"] === "prod") {
    try {
      await axios.post(
        "https://hooks.slack.com/services/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",
        JSON.stringify(slackMessage)
      );
    } catch (error) {
      console.log("error", error);
    }
  }
};
