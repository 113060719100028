export const getTaskTypeFields = (taskType : string ) => {

    let fields = [];
    if (taskType === "order_management_task") {
        fields.push({ label: "Delivery Instructions", key: "delInstructions" });

    } else if (taskType === "pickup_and_drop_task" || taskType === "field_visit_task") {
        fields.push({ label: "Complaint", key: "complaint" });

    } else if (taskType === "furritas_dog_walk") {
        fields.push({ label: "Dog's Name", key: "dogsName" });
        fields.push({ label: "Dog's Breed", key: "dogsBreed" });
        fields.push({ label: "Dog's Parent name", key: "dogsParentName" });
        fields.push({ label: "Type Of Walk (Trial or Subscription)", key: "typeOfWalk" });
        fields.push({ label: "Additional Info", key: "additionalInfo" });
        fields.push({ label: "Walk Duration", key: "walkDuration" });

    } else if (taskType === "road_side_service" || taskType === "towing_service") {
        fields.push({ label: "Vehicle Number", key: "vehicleNumber" });
        fields.push({ label: "Vehicle Issue", key: "vehicleIssue" });
        fields.push({ label: "Vehicle Model", key: "vehicleModel" });
        fields.push({ label: "Vehicle Make", key: "vehicleMake" });
        fields.push({ label: "Payment Amount", key: "paymentAmount" });
        fields.push({ label: "Client Type", key: "clientType" });
        fields.push({ label: "Client Tracking Id", key: "clientTrackingId" });
        fields.push({ label: "Client Service Id", key: "clientServiceId" });
        fields.push({ label: "Toll Reimbursable", key: "tollReimbursable" });
        fields.push({ label: "Fuel Delivery Reimbursable", key: "fuelDeliveryReimbursable" });
        fields.push({ label: "Other Charges Reimbursable", key: "otherChargesReimbursable" });
        fields.push({ label: "Spare Reimbursable", key: "spareReimbursable" });

    } else if (taskType === "fintech_document_collection") {
        fields.push({ label: "Remarks", key: "remarks" });
        fields.push({ label: "Documents To Be Collected", key: "documentsToBeCollected" });
        fields.push({ label: "Pickup Location", key: "pickupLocation", type : "select", options : [ {"label": "Home", "value" : "home"}, {"label": "Office", "value" : "office"}]});
        fields.push({ label: "Home Location", key: "homeAddress" });
        fields.push({ label: "Office Location", key: "officeAddress" });

    } else if (taskType === "porter_order_pickup_task" || taskType === "porter_order_delivery_task" || taskType === "porter_order_return_task") {
        fields.push({ label: "Geo Name", key: "geoName" });
        
        if (taskType === "porter_order_pickup_task") {
            fields.push({ label: "Indent Date", key: "indentDate" });
            fields.push({ label: "Pickup Date", key: "pickupDate" });
            fields.push({ label: "Scheduled Pickup Date", key: "scheduledPickupDate" });
            fields.push({ label: "Trip Id", key: "tripId" });
            fields.push({ label: "Recommended Vehicle Type", key: "recommendedVehicleType" });
            fields.push({ label: "Vehicle Type", key: "vehicleType", type : "select", options :
                    [
                        {"label": "None", "value" : ""},
                        {"label": "APE/Zip", "value" : "APE/Zip"}, {"label": "Tata Ace", "value" : "Tata Ace"},
                        {"label": "8ft", "value" : "8ft"}, {"label": "Tata 407", "value" : "Tata 407"},
                        {"label": "14ft Canter", "value" : "14ft Canter"}, {"label": "17ft Canter", "value" : "17ft Canter"},
                        {"label": "20ft Canter", "value" : "20ft Canter"}, {"label": "24ft Canter", "value" : "24ft Canter"},
                        {"label": "32ft (Single Axle)", "value" : "32ft (Single Axle)"}, {"label": "32ft (Multi Axle)", "value" : "32ft (Multi Axle)"}
                    ]});
        }
        //hero_bike_delivery

        if (taskType === "porter_order_return_task") {
            fields.push({ label: "Number Of Return Pieces", key: "noOfReturnPieces" });
        }

    } else if (["hero_bike_delivery", "hero_miscellaneous_task", "hero_bike_pickup", "hero_pickup_from_customer_for_repairs", "hero_delivery_to_customer_after_repair", "hero_miscellaneous_task", "hero_attendance_checkin_task", "hero_attendance_checkout_task", "hero_general_purchase_task", "hero_general_warehouse_work_task", "hero_general_gotolocation_work_task", "hero_general_pickupdrop_task", "hero_general_minor_repair_task"].includes(taskType)) {
        fields.push({ label: "Instructions", key: "instructions" });       

        // Show Delivery mode only for bike delivery and pickup
        if (["hero_bike_delivery", "hero_delivery_to_customer_after_repair"].includes(taskType)) 
            fields.push({ label: "Delivery Mode", key: "deliveryMode", type : "select", options : [ {"label": "Customer Self Pickup", "value" : "customer_pickup"}, {"label": "Delivery by Hero", "value" : "delivery_by_hero"}]});  
        
        if (["hero_bike_pickup", "hero_pickup_from_customer_for_repairs"].includes(taskType)) 
            fields.push({ label: "Pickup Mode", key: "pickupMode", type : "select", options : [ {"label": "Customer Self Drop", "value" : "customer_drop"}, {"label": "Pickup by Hero", "value" : "pickup_by_hero"}]});  

        if (["hero_general_purchase_task"].includes(taskType))
            fields.push({ label: "Activity", key: "activity", type : "select", options : [ {"label": "Battery", "value" : "Battery"}, {"label": "Tyre", "value" : "Tyre"}, {"label": "Tyre", "value" : "tyre"}, {"label": "Accessories", "value" : "Accessories"}, {"label": "Tools", "value" : "Tools"}, {"label": "Others", "value" : "Others"}]});
        if (["hero_general_warehouse_work_task"].includes(taskType))
            fields.push({ label: "Activity", key: "activity", type : "select", options : [ {"label": "Vehicle Inspection", "value" : "Vehicle Inspection"}, {"label": "Inventory Audit", "value" : "Inventory Audit"}, {"label": "Others", "value" : "others"}]});
        if (["hero_general_pickupdrop_task"].includes(taskType))
            fields.push({ label: "Activity", key: "activity", type : "select", options : [ {"label": "Documents", "value" : "Documents"}, {"label": "Accessories", "value" : "Accessories"}, {"label": "Office Inventories", "value" : "Office Inventories"}, {"label": "Others", "value" : "others"}]});            
        if (["hero_general_minor_repair_task"].includes(taskType))
            fields.push({ label: "Activity", key: "activity", type : "select", options : [ {"label": "Jump Start", "value" : "Jump Start"}, {"label": "Puncture", "value" : "Puncture"}, {"label": "Fuel Support", "value" : "Fuel Support"}, {"label": "Battery Replacement", "value" : "Battery Replacement"}, {"label": "Others", "value" : "others"}]});            
    } else if (taskType === "quickaf_order_delivery") {
        fields.push({ label: "Amount to be collected", key: "amountToBeCollected" });

    } else if (taskType === "packing_task") { // suprdaily - Packing Task
        fields.push({ label: "SKU Id", key: "skuId" });
        fields.push({ label: "Product Name", key: "productName" });
        fields.push({ label: "Quantity", key: "qty" });

    } else if (taskType === "stickering_task") { // suprdaily - Stickering Task
        fields.push({ label: "SKU Id", key: "skuId" });
        fields.push({ label: "Product Name", key: "productName" });
        fields.push({ label: "Quantity", key: "qty" });
        fields.push({ label: "Stickering Space Id", key: "stickeringSpaceId" });

    } else if (taskType === "qc2_task") { // suprdaily - QC2 Task
        fields.push({ label: "SKU Id", key: "skuId" });
        fields.push({ label: "Product Name", key: "productName" });
        fields.push({ label: "Quantity", key: "qty" });
        fields.push({ label: "QC2 Space Id", key: "qc2SpaceId" });

    } else if (taskType === "picking_bulk" || taskType === "picking_loose" || taskType === "picking_high_value") {
        fields.push({ label: "Route Number", key: "routeNumber" });
        fields.push({ label: "Drop Location", key: "dropLocation" });
        
    } else if (taskType === "hygiene_shield_audit") { 
        fields.push({ label: "Region", key: "region" });
        fields.push({ label: "City", key: "city" });
        fields.push({ label: "Cluster", key: "cluster" });
        fields.push({ label: "ROH/CH", key: "rohCh" });
        fields.push({ label: "DY RBH", key: "dyRbh" });
        fields.push({ label: "RBH", key: "rbh" });
    }

    return fields;
}
