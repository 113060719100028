import { useState, useEffect } from 'react';
import useQuery from 'hooks/useQuery';
import { getDynamicPath, getFilter, getFilterCount, getFilterListValue, isDynamicPath, storeFilterValue } from 'filter/filterUtils';
import { IFilterContext } from 'filter/constants/Filter.types';
import { userDecodedData } from 'utils/UserAuth.utils';

interface IUseApplyFilter {
    filterContext: string;
    setFilters: any;
}

type IUserState = {userId:string}
const useApplyFilter = (filterContext:any, setFilters:any) => {
    const query = useQuery();
    const { userId = "" } = userDecodedData() as IUserState;
    const [filterCount, setFilterCount] = useState(0);

    let keyForLocalStorage:string = filterContext

    if(isDynamicPath()){
        keyForLocalStorage = getDynamicPath();
    }

    // if(typeof filterContext === 'function'){
    //     filterContext = filterContext();
    // }


    const getFilterParams = () => {
        const getQueryParamFromUrl: string | null = query?.get('search');
        if (getQueryParamFromUrl) {
            try {
                const JSONParam = JSON.parse(getQueryParamFromUrl || '{}');
                const filterResultData = getFilterListValue(JSONParam, keyForLocalStorage, [], userId);
                storeFilterValue(keyForLocalStorage, JSONParam, userId);

                if (keyForLocalStorage == IFilterContext.TASK) {
                    if (JSONParam.includeDeleted) {
                        setFilterCount(JSONParam.filterArray?.length + 1);
                    } else {
                        setFilterCount(JSONParam.filterArray?.length);
                    }
                } else {
                    setFilterCount(JSONParam.filterArray?.length);
                }
                
                return filterResultData;
            } catch (error) {

            }
        } else {

            const JSONParam: any = getFilter(filterContext, userId);
            if (Object.keys(JSONParam).length > 0 ) {
                const filterResultData = getFilterListValue(JSONParam, filterContext, [], userId);


                if (filterContext == IFilterContext.TASK) {
                    if (JSONParam.includeDeleted) {
                        setFilterCount(JSONParam.filterArray?.length + 1);
                    } else {
                        setFilterCount(JSONParam.filterArray?.length);
                    }
                } else {
                    setFilterCount(JSONParam.filterArray?.length);
                }


                return filterResultData;
            }
        }
    }


    useEffect(() => {
        if(filterContext){
            const filterResultData:any = getFilterParams();
            if (filterContext == IFilterContext.TASK && filterResultData) {
                setFilters(filterResultData.fullFilter);
            } else if (filterContext != IFilterContext.TASK && filterResultData) {
                setFilters({ ...filterResultData.systemFilters, data: filterResultData.systemFilters });
            }
        }
    }, [filterContext]);

    return { filterCount };
}

export default useApplyFilter;