import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { IActionType } from '@zorp/common-libs-js/dist/monitor';
import ActionDeleteIcon from 'assests/action_delete_icon.svg';
import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { Controller } from "react-hook-form";
import styled from "styled-components";
import { ZButton } from 'views/commonComponents/commonComponents.styles';
import { LabelText, MonitorTextField, VariableHelperText } from '../Monitor.styles';
import ColumnsListCapsure from '../MonitorInterfaceComponents/ColumnsListCapsure';
import useMonitor from '../reducer/useMonitor';
import MonitorInterfaceSelect from './MonitorInterfaceSelect';
import { recordRSEvent } from 'utils/CommonUtils';
import { RudderStackAutomationBuilderEvents } from 'constants/RudderStackConstants';

interface IAlertTicket {
  errors: any;
  control: any;
  register: any;
  setValue: any;
  watch: any;
  onRemove: (index: number) => void;
  fields: any;
  userListArray: any;
}

const AlertTicket = ({ errors, control, register, setValue, watch, fields, onRemove, userListArray }: IAlertTicket) => {
  const { monitorInterfaceContext } = useMonitor();
  const [showAutoResolveCountField, setShowAutoResolveCountField] = useState(watch()?.autoResolve);
  const columnsListArray = monitorInterfaceContext.monitorComponentsData.tablesColumnsList || [];
  const [showPrimaryColumnField, setShowPrimaryColumnField] = useState<boolean>(watch()?.avoidDuplicate);
  const isCreateBulkAlertEnabled = monitorInterfaceContext?.monitorComponentsData?.isDisableCreateBulkAlert;
  // const fieldsIndex = fields.findIndex((data: any) => (data.actionId == monitorInterfaceContext?.monitorComponentsData?.selectedActionDetails?.actionId && data.actionType == IActionType.TICKET));
  const fieldsIndex = fields.findIndex((data: any) => (data.actionType == IActionType.TICKET));

  const ticketPriorityOptions = [
    {
      value: 'Low Priority',
      label: 'Low Priority'
    },
    {
      value: 'Medium Priority',
      label: 'Medium Priority'
    },
    {
      value: 'High Priority',
      label: 'High Priority'
    }
  ]

  return (
    <Box width={'100%'} padding={'24px'} borderRadius={'4px'} border={'1px solid var(--Gray-200, #EAECF0)'} boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)'}>
      <LabelText>Assign to User</LabelText>
      <Box width={'100%'}>
        <MonitorInterfaceSelect
          control={control}
          name={`actionData[${fieldsIndex}].payload.userEmail`}
          errors={errors}
          options={userListArray}
          isLoading={false}
          placeholder='Assign to User'
          isDisabled={false}
          onChange={(userEmailvalue: any) => {
            setValue(`actionData[${fieldsIndex}].payload.userEmail`, userEmailvalue);
          }}
        />
      </Box>

      <LabelText>Incident Priority</LabelText>
      <Box width={'100%'}>
        <MonitorInterfaceSelect
          control={control}
          name={`actionData[${fieldsIndex}].payload.priority`}
          errors={errors}
          options={ticketPriorityOptions}
          isLoading={false}
          placeholder='Priority Level'
          isDisabled={false}
          onChange={(priorityValue: any) => {
            setValue(`actionData[${fieldsIndex}].payload.priority`, priorityValue);
          }}
        />
      </Box>

      <LabelText style={{
        marginTop: '16px'
      }}>Incident Summary</LabelText>
      <Controller
        name={`actionData[${fieldsIndex}].payload.ticketNotes`}
        control={control}
        render={({ field }) => (
          <>
            <TextareaAutosize
              {...field} // destructuring field state provided by Controller
              maxRows={5}
              {...register(`actionData[${fieldsIndex}].payload.ticketNotes`)}
              minRows={5}
              placeholder={`Hey Team, \nI would like to bring to you notice that...`}
              style={{
                width: "100%",
                border: "1px solid #c2c9d1",
                borderRadius: "4px",
                padding: "6px 8px",
                marginTop: '6px'
              }}
            />

            {
              columnsListArray.length > 0 &&
              <VariableHelperText>Add Variables to your Ticket Body</VariableHelperText>
            }
          </>
        )}
      />
      {
        columnsListArray.length > 0 &&
        <Box width={'100%'} marginTop={'8px'}>
          <ColumnsListCapsure
            onCardClick={(columnName: string) => {
              setValue(`actionData[${fieldsIndex}].payload.ticketNotes`, `${watch()?.actionData?.[fieldsIndex]?.payload?.ticketNotes} \${${columnName}}`)
            }}
            columnsList={columnsListArray} />
        </Box>
      }

      <Divider style={{ marginTop: '24px', color: '#475467', fontSize: '12px', fontWeight: 500 }} textAlign="left">Smart Ticket Management</Divider>

      <Box ml={'11px'} width={'100%'} display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
        <Controller
          name={`autoResolve`}
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <input
                  {...field}
                  onChange={(e) => {
                    setValue('autoResolve', e.target.checked);
                    setShowAutoResolveCountField(e.target.checked)
                    if (!e.target.checked) {
                      setValue('autoResolveCount', undefined);

                      if (watch()?.avoidDuplicate == false) {
                        setShowPrimaryColumnField(e.target.checked)
                      } 
                    } else {
                      setShowPrimaryColumnField(e.target.checked)
                      recordRSEvent(RudderStackAutomationBuilderEvents.ticketAutoClose, {
                        context: RudderStackAutomationBuilderEvents.monitorContext
                      });
                    }
                      }}
                  type="checkbox"
                  style={{ marginRight: "10px", marginTop: "5px", marginBottom: "5px", height: '16px', width: '16px' }}
                  checked={field.value}
                />
              }
              label={
                <Typography
                  variant="body1"
                  style={{
                    color: '#344054',
                    fontSize: '14px',
                  }}
                >
                Auto Close Tickets
                </Typography>
              }
              style={{ marginTop: '20px' }}
            />
          )}
        />
      </Box>

  <>
            <Box ml={'11px'} width={'100%'} display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
              <Controller
                name={`avoidDuplicate`}
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <input
                        {...field} 
                        onChange={(e) => {
                      setValue('avoidDuplicate', e.target.checked);
                      
                      if (e.target.checked == false) {
                        setValue('primaryColumnField', undefined);

                        if (watch()?.autoResolve == false) {
                          setShowPrimaryColumnField(e.target.checked)
                        } 

                      } else {
                        setShowPrimaryColumnField(e.target.checked)

                        recordRSEvent(RudderStackAutomationBuilderEvents.ticketAvoidDuplicate, {
                          context: RudderStackAutomationBuilderEvents.monitorContext
                        });

                      }
                        }}
                        type="checkbox"
                        style={{ marginRight: "10px", marginTop: "5px", marginBottom: "5px", height: '16px', width: '16px' }}
                        checked={field.value}
                      />
                    }
                    label={
                      <Typography
                        variant="body1"
                        style={{
                          color: '#344054',
                          fontSize: '14px',
                        }}
                      >
                        Prevent Duplicate Tickets
                      </Typography>
                    }
                    style={{ marginTop: '20px' }}
                  />
                )}
              />
            </Box>

            {
              showPrimaryColumnField &&
              <>
                <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={'10px'} mt={'20px'}>
                  <LabelText style={{width:'40%'}}>Primary Key Column</LabelText>
                  <Box width={'60%'}>
                    <MonitorInterfaceSelect
                       control={control}
                      name={`primaryColumnField`}
                      errors={errors}
                      options={columnsListArray.map((column: any) => {
                        return { 'value': column, 'label': column }
                      })}
                      isLoading={false}
                      placeholder={''}
                      isDisabled={false}
                      onChange={(primaryColumnFieldValue: any) => {
                        setValue(`primaryColumnField`, primaryColumnFieldValue);
                      }}
                    />
                  </Box>
                </Box>
                <p
                  style={{
                    color: 'rgba(0, 0, 0, 0.6)',
                    fontSize: '12px',
                    fontWeight: 400,
                    marginTop: '2px'
                  }}
                >Choose the column that uniquely identifies each row.</p>
              </>
            } 
</>
{
  showAutoResolveCountField &&

      <Box width={'100%'} display={'flex'} alignItems={'center'} mt={'15px'}>
        <LabelText style={{ width: 'auto', marginRight:'15px' }}>Auto Close Ticket after</LabelText>

        <Box width={'20%'}>
          <Controller
            name={'autoResolveCount'}
            control={control}
            rules={{ required: "Display Name field is requried" }}
            render={({
              fieldState: { error },
            }) => (
              <MonitorTextField
                type='number'
                placeholder='3'
                error={!!error}
                variant="outlined"
                {...register("autoResolveCount")}
              />
            )}
          />
        </Box>

        <Box ml={'15px'}>
          <LabelText>No Occurences</LabelText>
        </Box>
      </Box>
      }
<p
                  style={{
                    color: 'rgba(0, 0, 0, 0.6)',
                    fontSize: '12px',
                    fontWeight: 400,
                    marginTop: '4px'
                  }}
                >Auto close tickets that no longer match the condition</p>
      <Box width={'100%'} display={'flex'} justifyItems={'center'} flexDirection={'column'}>
        <ZButton
          onClick={() => {
            onRemove(fieldsIndex);
            recordRSEvent(RudderStackAutomationBuilderEvents.ticketActionDelete, {
              context: RudderStackAutomationBuilderEvents.monitorContext
            });
          }}
          style={{ padding: '0px', fontFamily: 'Inter', marginTop: '16px', borderRadius: '4px', height: '40px', color: '#B42318', fontSize: '12px', fontWeight: 500, backgroundColor: '#fff', textTransform: 'none' }}
          sx={{ height: '40px' }}
          variant="text">
          <img style={{ paddingRight: '8px' }} src={ActionDeleteIcon} alt='Icon' />
          Delete</ZButton>
      </Box>

    </Box>
  )
}

// export default AlertTicket
export default React.memo(AlertTicket);