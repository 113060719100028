import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material"
import Backdrop from "@mui/material/Backdrop"
import PostPublishModal from "components/post-publish-modal/PostPublishModal"
import { IFilterContext } from "filter/constants/Filter.types"
import FilterModal from "filter/FilterModal"
import { clearFilterStorage, getFilterCount } from "filter/filterUtils"
import useApplyFilter from "filter/hooks/useApplyFilter"
import useWidth from "hooks/useWidth"
import notification from "notifications/notifications"
import { TaskFlowService } from "providers/data/services/TaskFlowService"
import { useEffect, useState } from "react"
import { List, TopToolbar, useRefresh } from "react-admin"
import { BsChevronDown, BsChevronUp, BsStars } from "react-icons/bs"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import styled, { useTheme } from "styled-components"
import { useStyles } from "styles/tableStyles"
import { recordRSEvent } from "utils/CommonUtils"
import { userDecodedData } from "utils/UserAuth.utils"
import { FiBook, FiCloud, FiStar } from "react-icons/fi"
import { FaWpforms } from "react-icons/fa"
import {
  WLCircularLoader,
  ZButton,
  ZIconButton,
} from "views/commonComponents/commonComponents.styles"
import FilterButton from "views/users/components/FilterButton"
import EmptyScreen from "views/workflows/emptyScreen/EmptyScreen"
import useWorkFlow from "views/workflows/hooks/useWorkFlow"
import { resetWorkflow } from "views/workflows/reducers/workflowAction"
import DeletionConfirmationDialog from "../../utils/DeletionConfirmationDialog.js"
import CustomWorkflowList from "./CustomWorkflowList"
import { AiOutlinePlus, AiOutlineRobot } from "react-icons/ai"
import GoogleFormModal from "./automateWorkflow/Gforms/GoogleFormModal"
import { FcGoogle } from "react-icons/fc"
import ZorpGPTNew from "./automateWorkflow/ZorpGPTNew"
import { GiArtificialHive } from "react-icons/gi"
import { ZIconButton2 } from "../../views/entities/styles"

const SwitchWrapper = styled.div``

const Heading = styled.p`
  font-size: 24px;
  font-weight: 600;
  color: #101828;
`

const Text = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: "#475467";
`

const WorkflowListActions = ({
  resource,
  filters,
  displayedFilters,
  hideFilter,
  setFilters,
  filterValues,
  basePath,
  showFilter,
  refresh,
  modalOpened,
  setShowColumnSelector,
  setSortKey,
  setShowGoogleModal,
  setShowGPTModal,
  ...rest
}) => {
  const history = useHistory()
  const largeScreen = useWidth()
  const { userId = "" } = userDecodedData()
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const getFilterValue = useApplyFilter(IFilterContext.WORKFLOW, setFilters)
  const [filterCount, setFilterCount] = useState(
    getFilterValue.filterCount || 0
  )

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const [modalPosition, setModalPosition] = useState([0, 0, 0]) //[X,Y]

  useEffect(() => {
    setFilterCount(getFilterCount(IFilterContext.WORKFLOW, userId))
  }, [])

  useEffect(() => {
    if (getFilterValue?.filterCount >= 0) {
      setFilterCount(getFilterValue.filterCount)
    }
  }, [getFilterValue.filterCount])

  const menuItems = [
    {
      label: "Updated on ASC",
      icon: <BsChevronUp size={"1.5rem"} />,
      visibility: true,
    },
    {
      label: "Updated on DESC",
      icon: <BsChevronDown size={"1.5rem"} />,
      visibility: true,
    },
    {
      label: "Created on ASC",
      icon: <BsChevronUp size={"1.5rem"} />,
      visibility: true,
    },
    {
      label: "Created on DESC",
      icon: <BsChevronDown size={"1.5rem"} />,
      visibility: true,
    },
  ]

  return (
    <>
      <Heading> Workflows </Heading>
      <Text> You can create and manage all your workflows here </Text>

      <TopToolbar className="workflow-toolbar">
        <div>
          <FilterButton
            filterCount={filterCount}
            onRemoveFilterClick={() => {
              setFilters({}, [])
              setFilterCount(0)
              clearFilterStorage(IFilterContext.WORKFLOW)
            }}
            onOpenFilterClick={(pos) => {
              const x =
                pos.current.offsetParent.offsetLeft + pos.current.offsetLeft
              const y =
                pos.current.offsetParent.offsetHeight +
                pos.current.offsetHeight +
                50 +
                pos.current.offsetHeight
              const buttonHeight = pos.current.offsetHeight
              const buttonWidth = pos.current.offsetWidth
              setModalPosition([x, y, buttonHeight, buttonWidth])
              setOpenFilterModal(true)
              showFilter("main")
            }}
          />
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {menuItems.map((option, index) => (
              <div>
                {
                  <MenuItem
                    disabled={!option.visibility}
                    className="node__menuItem"
                    key={option.label}
                    // onClick={(e) =>
                    //   handleMenuItemClick(e, () => option.onClick(data[id]))
                    // }
                    sx={{
                      marginBottom:
                        index !== menuItems.length - 1 ? "16px" : "",
                    }}
                  >
                    <>
                      <ListItemIcon> {option.icon} </ListItemIcon>
                      <ListItemText>
                        <Text size="16px" weight={"500"}>
                          {" "}
                          {option.label}{" "}
                        </Text>
                      </ListItemText>
                    </>
                  </MenuItem>
                }
              </div>
            ))}
          </Menu>
        </div>

        {displayedFilters?.main && openFilterModal && (
          <FilterModal
            position={modalPosition}
            filterTitle={"Search Workflows"}
            urlEndpoint={"/workflows"}
            context={IFilterContext.WORKFLOW}
            openModal={openFilterModal}
            handleClose={(appliedFilterCount) => {
              setOpenFilterModal(false)
              hideFilter("main")
              if (appliedFilterCount >= 0) {
                setFilterCount(appliedFilterCount)
              }
            }}
          />
        )}
        <div style={{ display: "flex", gap: "8px", paddingBottom: "12px" }}>
          {largeScreen ? (
            <ZButton
              variant="contained"
              secondary
              startIcon={
                <FiBook
                  size={"1rem"}
                  style={{ background: "none" }}
                  className="svg_icons_l"
                />
              }
              onClick={() => {
                history.push("/templates")
              }}
            >
              Show Templates
            </ZButton>
          ) : (
            <ZIconButton2
              onClick={() => {
                history.push("/templates")
              }}
            >
              <AddCircleOutlineIcon
                style={{ background: "transparent" }}
                className="svg_icons_l"
              />
            </ZIconButton2>
          )}
          {largeScreen ? (
            <ZButton
              variant="contained"
              style={{
                borderRadius: "4px",
                border: "1px solid var(--green-500, #16B364)",
                background: "#16B364",
                boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              }}
              startIcon={
                <BsStars
                  size={"1rem"}
                  style={{ background: "none" }}
                  className="svg_icons_l"
                />
              }
              onClick={() => setShowGPTModal(true)}
            >
              Zorp GPT
            </ZButton>
          ) : (
            <ZIconButton2
              onClick={() => {
                document.body.style.overflowX = "hidden"
                document.body.style.overflowY = "hidden"
                document.body.style.position = "fixed"
                setShowGPTModal(true)
              }}
            >
              <GiArtificialHive
                style={{ background: "transparent" }}
                className="svg_icons_l"
              />
            </ZIconButton2>
          )}
          {largeScreen ? (
            <ZButton
              variant="contained"
              special
              startIcon={
                <FcGoogle
                  size={"1.2rem"}
                  style={{ background: "none" }}
                  className="svg_icons_l"
                />
              }
              endIcon={
                <BsStars
                  size={"1rem"}
                  style={{ background: "none" }}
                  className="svg_icons_l"
                />
              }
              onClick={setShowGoogleModal}
            >
              Google Forms to App
            </ZButton>
          ) : (
            <ZIconButton2
              onClick={() => {
                setShowGoogleModal(true)
              }}
            >
              <BsStars
                size={"1rem"}
                style={{ background: "none" }}
                className="svg_icons_l"
              />
            </ZIconButton2>
          )}
          {largeScreen ? (
            <ZButton
              variant="contained"
              startIcon={
                <AiOutlinePlus
                  className="svg_icons"
                  style={{
                    backgroundColor: "transparent",
                    color: "#fff",
                    marginRight: 0,
                  }}
                />
              }
              onClick={() => {
                history.push("/templates")
              }}
            >
              New Workflow
            </ZButton>
          ) : (
            <ZIconButton2
              onClick={() => {
                history.push("/templates")
              }}
            >
              <AiOutlinePlus />
            </ZIconButton2>
          )}
        </div>
      </TopToolbar>
    </>
  )
}

const WorkflowList = (props) => {
  const history = useHistory()
  const theme = useTheme()
  const classes = useStyles({ theme })
  const refresh = useRefresh()
  const [taskStatus, setTaskStatus] = useState("")
  const [deleteTaskTypeId, setDeleteTaskTypeId] = useState(null)
  const [openDeletionConfirmation, setOpenDeletionConfirmation] =
    useState(false)
  const [openCopyConfirmation, setOpenCopyConfirmation] = useState(false)
  const dispatch = useDispatch()
  const { postPublish } = useWorkFlow()
  const [showBackdrop, setShowBackdrop] = useState(false)
  const [sortKey, setSortKey] = useState("")
  const [showGPTModal, setShowGPTModal] = useState(false)

  useEffect(() => {
    recordRSEvent("builder_listing", {
      context: "builder_listing",
    })
    dispatch(resetWorkflow())
    refresh()
  }, [])

  const handleDuplicateWorkflow = (taskTypeId) => {
    TaskFlowService.duplicateWorkflow(taskTypeId)
      .then((res) => {
        if (res?.code && res?.code.indexOf("200") < 0) {
          if (res?.code === "401") {
            notification("error", res.message)
          } else {
            notification("error", res.message)
          }
        } else {
          notification("success", `Workflow duplicated`)
          refresh()
          setShowBackdrop(false)
        }
        setShowBackdrop(false)
      })
      .catch((err) => {
        console.log("Error", err)
      })
  }

  const handleDeleteWorkflow = (taskTypeId) => {
    setShowBackdrop(true)
    recordRSEvent("workflowlistdelete_event_success", {
      context: "builder_listing",
      workflow_id: taskTypeId,
    })
    const updatedStatus = {
      taskType: taskTypeId,
      isActive: ["Active", "Draft"].includes(taskStatus) ? false : true,
    }
    switch (taskStatus) {
      case "Active":
      case "Inactive":
        TaskFlowService.changeWorkflowStatus(taskTypeId, updatedStatus.isActive)
          .then((res) => {
            if (res?.code && res?.code.indexOf("200") < 0) {
              if (res?.code === "401") {
                notification("error", res.message)
              } else {
                notification("error", res.message)
              }
            } else {
              notification("success", `Workflow disabled`)
              refresh()
              setShowBackdrop(false)
            }
            setShowBackdrop(false)
          })
          .catch((err) => {
            console.log("Error", err)
          })
        break
      case "Draft":
        TaskFlowService.deleteTaskFlow(taskTypeId)
          .then((res) => {
            if (res?.code && res?.code.indexOf("200") < 0) {
              if (res?.code === "401") {
                notification("error", res.message)
              } else {
                notification("error", res.message)
              }
              setShowBackdrop(false)
            } else {
              notification("success", "Workflow deleted")
              refresh()
              setShowBackdrop(false)
            }
          })
          .catch((err) => {
            console.log("Error", err)
          })
        break
      default:
        break
    }
  }

  const deleteWorkflow = (e, record) => {
    recordRSEvent("workflowlist_delete_button_clicked", {
      context: "builder_listing",
      workflow_id: record.id,
      workflow_name: record.name,
    })
    e.preventDefault()
    e.stopPropagation()
    setTaskStatus(record.status)
    setDeleteTaskTypeId(record.id)
    setOpenDeletionConfirmation(true)
  }

  const copyWorkflow = (e, record) => {
    recordRSEvent("workflowlist_copy_button_clicked", {
      context: "builder_listing",
      workflow_id: record.id,
      workflow_name: record.name,
    })
    e.preventDefault()
    e.stopPropagation()
    setTaskStatus(record.status)
    setDeleteTaskTypeId(record.id)
    setOpenCopyConfirmation(true)
  }

  const onSwitchChange = (e, record) => {
    e.preventDefault()
    e.stopPropagation()
    setTaskStatus(record.status)
    setDeleteTaskTypeId(record.id)
    setOpenDeletionConfirmation(true)
  }

  const handleAPIClick = (e, record) => {
    e.preventDefault()
    e.stopPropagation()
    recordRSEvent("get_api_documentation", {
      context: "workflow_api_documentation",
      taskType: record.taskType,
    })
    history.push({
      pathname: `/document/${record.taskType}`,
    })
  }

  const [showGoogleModal, setShowGoogleModal] = useState(false)

  return (
    <div style={{ padding: "32px" }}>
      <List
        {...props}
        bulkActionButtons={false}
        actions={
          <WorkflowListActions
            setShowGPTModal={() => history.push("/zorp-gpt")}
            setShowGoogleModal={() => setShowGoogleModal(true)}
            setSortKey={setSortKey}
            {...props}
          />
        }
        classes={{ main: classes.main }}
        pagination={false}
        empty={<EmptyScreen />}
      >
        <div style={{ overflowX: "auto" }}>
          <CustomWorkflowList
            sortable={false}
            textAlign="left"
            label="Id"
            source=""
            sortKey={sortKey}
            copyWorkflow={copyWorkflow}
            deleteWorkflow={deleteWorkflow}
            handleAPIClick={handleAPIClick}
            onSwitchChange={onSwitchChange}
          />
          {/* <p>{JSON.stringify(record)}</p> */}
        </div>
      </List>
      {postPublish && <PostPublishModal />}
      {openDeletionConfirmation && (
        <DeletionConfirmationDialog
          deletionTitle={
            ["Draft"].includes(taskStatus)
              ? "Delete Workflow?"
              : ["Active"].includes(taskStatus)
              ? "Disable Workflow"
              : "Enable Workflow"
          }
          deletionText={
            ["Draft"].includes(taskStatus)
              ? "Do you want to delete the unsaved changes ? You will lose all your changes!"
              : ["Active"].includes(taskStatus)
              ? "Are you sure you want to disable this workflow?"
              : "Are you sure you want to enable this workflow?"
          }
          id={deleteTaskTypeId}
          isOpen={openDeletionConfirmation}
          onConfirmDelete={handleDeleteWorkflow}
          onClose={() => {
            setOpenDeletionConfirmation(false)
            recordRSEvent("workflowlist_delete_event_fail", {
              context: "builder_listing",
              workflow_id: deleteTaskTypeId,
            })
          }}
        ></DeletionConfirmationDialog>
      )}
      {showGoogleModal && (
        <GoogleFormModal
          handleClose={() => setShowGoogleModal(false)}
          openModal={showGoogleModal}
        />
      )}
      {/* {showGPTModal && (
        <ZorpGPT
          handleClose={() => setShowGPTModal(false)}
          openModal={showGPTModal}
        />
      )} */}

      {showGPTModal && (
        <ZorpGPTNew
          handleClose={() => setShowGPTModal(false)}
          openModal={showGPTModal}
        />
      )}

      {openCopyConfirmation && (
        <DeletionConfirmationDialog
          deletionTitle="Duplicate Workflow"
          deletionText="Are you sure you want to duplicate this workflow?"
          id={deleteTaskTypeId}
          isOpen={openCopyConfirmation}
          onConfirmDelete={handleDuplicateWorkflow}
          onClose={() => {
            setOpenCopyConfirmation(false)
          }}
        ></DeletionConfirmationDialog>
      )}
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={showBackdrop}
        >
          <WLCircularLoader color="primary" sx={{ height: 200, width: 200 }} />
        </Backdrop>
      </div>
    </div>
  )
}

export default WorkflowList
