import { Table, TableBody, TableHead, TableRow } from "@material-ui/core";
import CircularProgress from "@mui/material/CircularProgress";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { cloneDeep } from "lodash";
import notification from "notifications/notifications";
import { AccountService } from "providers/data/services/AccountService";
import { CronService, ICronExecutionType } from "providers/data/services/CronService";
import { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import { LuPlay } from "react-icons/lu";
import { useDispatch } from "react-redux";
import { SingleTableCell } from "render-engine/render.styles";
import styled from "styled-components";
import DeletionConfirmationDialog from "utils/DeletionConfirmationDialog";
import { generateId } from "utils/Utils";
import useAutomation from "views/automationBuilder/hooks/useAutomation";
import { closeAutomation, setSelectedCron } from "views/automationBuilder/reducer/automationReducer";
import { ABMetadataTypeEnum, IActionBlock, IAutomation } from 'views/automationBuilder/types/ABType';
import { ZButton } from "views/commonComponents/commonComponents.styles";
import { Label } from "views/settings/GeneralSetting/GeneralSettingEdit";
import ConfigureAutomation from "views/workflows/ConfigureAutomation";
import { setSelectedAction } from "views/workflows/reducers/workflowAction";
import { FlexDiv, SubHeading } from "../settings/settings.style";
import { accountAutomationSaveHandler } from "./AccountAutomationSaveHandler";
import NoCronContent from "./NoCronContent";
import NoSecretKeyConditionKeyCron from "./NoSecretKeyConditionKeyCron";
import ConfirmationDialog from "views/automationBuilder/components/ConfirmationDialog";
import { initMockData } from "lowcode/state/scriptEditorState"
import { ZorpResponse } from "providers/data/models/ZorpResponse";

interface ICronAutomation  {
  onTabChange: () => void;
}


export const Wrapper = styled.div`
  padding: 20px 0px;
  height:100%;
`;


const CronAutomation = ({onTabChange}:ICronAutomation) => {
  const [cronDetails, setCronDetails] = useState<any>([]);
  const [prevAutomation, setPrevAutomation] = useState<IAutomation[]>([]);
  const [selectedCronId, setSelectedCronId] = useState<string>("");
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [executeAutomationId, setExecuteAutomationId] = useState<string | null>();
  const [executeAutomationName, setExecuteAutomationName] = useState<string | null>();
  const [openAB, setOpenAB] = useState(false);
  const { clearRightSide } = useAutomation();
  const [accountDetails, setAccountDetails] = useState<any>(null);
  const [executeCronLoading, setExecuteCronLoading] = useState<boolean>(false);
  const [executeCronId, setExecuteCronId] = useState<string>("");
  const dispatch = useDispatch();
  const getAllCronDetails = async () => {
    const data: any = await CronService.getAllCron();
    if (data) {
      console.log(data.data);
      setCronDetails([...data.data]);
    }
  };

  const handleEditClick = (cronData: any) => {
    dispatch(setSelectedCron(cronData));
    setPrevAutomation([cronData.payload.uiFormat]);
    setSelectedCronId(cronData.payload.uiFormat.id);
    dispatch(initMockData({taskTypeId: cronData?.cronId, type:ABMetadataTypeEnum.CRON}));
    setOpenAB(true);
    dispatch(setSelectedAction(null));
  };

  useEffect(() => {
    getAllCronDetails();
  }, []);

  const onClickAutomation = async (e: any) => {

    const automationId = generateId(6);
    const actionBlockData: IActionBlock = {
      actions: [],
      conditions: [],
      actionBlockId: generateId(8)
    }

    let newAutomationName = "New Automation";
    
    const newAutomationData: IAutomation =
    {
      triggers: [],
      guards: [],
      actionBlocks: [
        ...([actionBlockData]) // we won't create Action block, in this case : Example screen transition before decision node
      ],
      id: automationId,
      name: newAutomationName,
      errors: []
    }


    e.preventDefault();
    
    setSelectedCronId(automationId);
    setPrevAutomation([newAutomationData]);
    dispatch(setSelectedAction(null));

    const createCronPayload = {
      name: "New Automation",
      timesRan: 0,
      isActive: false,
      cronType: "AUTOMATION",
      timeZone: "Asia/Kolkata",
      isInitiated: false,
    };

    const createResponse:ZorpResponse | undefined = await CronService.CreateCron(createCronPayload);
    if (createResponse?.code == '201') {
      setOpenAB(true);
      dispatch(setSelectedCron({ cronId: createResponse?.data.cronId, isInitiated:false }));
      dispatch(initMockData({taskTypeId: createResponse?.data.cronId, type:ABMetadataTypeEnum.CRON}));
    } else {
      notification("error", "Issue while opening Automation");
    }
  };

  const executeCron = async (cronId: string) => {
    setExecuteCronLoading(true);
    setExecuteCronId(cronId);
    const executeStatus: any = await CronService.executeCron({
      cronId,
      cronExecutionType: ICronExecutionType.CRON,
    });
    setExecuteCronLoading(false);
    setExecuteCronId("");
    if (executeStatus?.status == "200") {
      notification("success", executeStatus?.data?.message);
    } else {
      notification(
        "error",
        executeStatus?.data?.message || "Cron Execution Failed"
      );
    }
  };

  const updateIsActive = (cronId: string, newValue: boolean) => {
    // Create a copy of the current cronDetails array
    const updatedCronDetails = cloneDeep(cronDetails);

    // Find the index of the element with the matching cronId
    const index = updatedCronDetails.findIndex(
      (item: any) => item.cronId === cronId
    );

    // If the element with cronId is found, update its isActive value
    if (index !== -1) {
      updatedCronDetails[index].isActive = newValue;
      setCronDetails(updatedCronDetails); // Update the state with the new array
    }
  };

  const getAccountDetails = async () => {
    const data: any = await AccountService.getSecretKeys();
    if (data) {
      console.log(data.data);
      setAccountDetails([...data.data]);
    }
  };

  useEffect(() => {
    getAccountDetails();
  }, []);

  const getZorpKey = () => {
    if (!accountDetails) return undefined;
    const zorpKey = accountDetails?.find((key: any) => key.keyType === "zorp");
    return zorpKey;
  };

  return (
    <>
      <Wrapper>
        <div className="" style={{display:'flex', width:'100%', justifyContent:'space-between'}}>
          <div>
            <SubHeading>Scheduled Automation</SubHeading>
            <Label>
              {" "}
              You can create and manage all your Scheduled Automation here{" "}
            </Label>
          </div>

          {/* If there is no cron or the secret key is ot generated, then we don't show create button */}
          {(getZorpKey()?.keyMasked && cronDetails.length > 0) && (
          <div>
            <FlexDiv>
              <ZButton
                startIcon={<AiOutlinePlus />}
                variant="contained"
                onClick={async (e) => {
                  await onClickAutomation(e);
                }}
                >
                Scheduled Automation
              </ZButton>
            </FlexDiv>
                </div>
          )}
          </div>

        {!getZorpKey()?.keyMasked && (
    
    <NoSecretKeyConditionKeyCron onTabChange={()=>{
onTabChange();
    }}/>
        )}
      </Wrapper>

{
  cronDetails.length > 0 && 
      <div
        style={{
          width: "100%",
          overflowX: "auto",
        }}
      >
        <Table
          style={{
            boxShadow:
              "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
          }}
          className="mt-6"
        >
          <TableHead>
            <TableRow>
              <SingleTableCell headerCell align="left">
                <p>ID</p>
              </SingleTableCell>
              <SingleTableCell headerCell align="left">
                <p>Name</p>
              </SingleTableCell> 
              <SingleTableCell headerCell align="left">
                <p>Schedule</p>
              </SingleTableCell>
              <SingleTableCell headerCell align="left">
                <p>Active</p>
              </SingleTableCell>
              <SingleTableCell headerCell align="left">
                <p>Action</p>
              </SingleTableCell>
            </TableRow>
          </TableHead>
          {
            // loop from first index of secretKeys
            cronDetails?.map((cronData: any, index: number) => {
              return (
                <>
                  <TableBody key={cronData.cronId}>
                    <TableRow
                      style={{
                        borderLeft: "1px solid #EAECF0",
                        borderRight: "1px solid #EAECF0",
                        borderTop: "1px solid #EAECF0",
                      }}
                    >
                      <SingleTableCell align="left" scope="row">
                        <p>{cronData.cronId}</p>
                      </SingleTableCell>{" "}
                      <SingleTableCell align="left" scope="row">
                        <p>{cronData.name}</p>
                      </SingleTableCell>
                      <SingleTableCell align="left" scope="row">
                        <p>{cronData.schedule}</p>
                      </SingleTableCell>
                      <SingleTableCell align="left" scope="row">
                        <div
                          style={{
                            display: "flex",
                            gap: "12px",
                          }}
                        >
                          <Switch
                            checked={cronData.isActive}
                            onChange={async (e) => {
                              const newValue = e.target.checked;
                              const responseData =
                                await CronService.updateCronData(
                                  cronData.cronId,
                                  { isActive: newValue }
                                );
                              if (responseData?.code == "200") {
                                notification("success", "Cron Status Changed");
                                updateIsActive(cronData.cronId, newValue);
                              } else {
                                notification(
                                  "success",
                                  "Changing Cron Status Failed"
                                );
                              }
                            }}
                          />
                        </div>
                      </SingleTableCell>{" "}
                      <SingleTableCell align="left" scope="row">
                        <div
                          style={{
                            display: "flex",
                            gap: "24px",
                          }}
                        >
                          {cronData.cronId == executeCronId &&
                          executeCronLoading ? (
                            <CircularProgress />
                          ) : (
                            <Tooltip
                              title={
                                <Typography>
                                  {cronData.isActive
                                    ? "Run Now"
                                    : "Run Disabled, when Cron is Not Active"}
                                </Typography>
                              }
                            >
                              <span>
                                <LuPlay
                                  cursor={cronData.isActive ? "pointer" : ""}
                                  size={"20px"}
                                  color={cronData.isActive ? "" : "#344054"}
                                  onClick={async () => {
                                    if (cronData.isActive) {
                                      setExecuteAutomationId(cronData.cronId)
                                      setExecuteAutomationName(cronData.name)
                                    }
                                  }}
                                />
                              </span>
                            </Tooltip>
                          )}
                          <Tooltip
                            title={
                              <Typography>
                                  Edit
                              </Typography>
                            }
                          >
                            <span>
                              <FiEdit2
                                onClick={() => {
                                    handleEditClick(cronData);
                                }}
                                size={"20px"}
                                color={
                                   "#344054"
                                }
                                cursor={"pointer"}
                              />
                            </span>
                          </Tooltip>
                        </div>
                      </SingleTableCell>
                    </TableRow>
                  </TableBody>
                </>
              );
            })
          }
        </Table>
      </div>
}

{
  cronDetails.length == 0 &&
  <div style={{
    maxHeight:'600px',
    height:'600px',
  }}>

  <NoCronContent 
  title="No Scheduled Automations Found"
  label="Your search “Landing page design” did not match any projects.
  Please try again."
  btnName="Scheduled Automation"
  hideCreateButton={!getZorpKey()?.keyMasked} 
  onCreateAutomation={async (e:any)=>{
    await onClickAutomation(e);
  }}/>
  </div>
}

      <ConfigureAutomation
        metaDataType={ABMetadataTypeEnum.CRON}
        prevAutomations={prevAutomation}
        automationSelected={selectedCronId}
        onSaveHandler={accountAutomationSaveHandler}
        onAutomationClose={() => {
          clearRightSide();
          dispatch(closeAutomation());
          setOpenAB(false);
          getAllCronDetails();
        }}
        otherConfigs={{
          allowOnlySingleAutomation: true,
          hideGuardBlock: false,
          hideTriggerConfigureButton: false,
          showRunAutomation:true
        }}
        openAutomationBuilder={openAB}
      />

      <DeletionConfirmationDialog
        id={"scriptEditor"}
        deletionTitle={"Regenerate Token"}
        deletionText={"Are you sure you want to regenerate your token?"}
        isOpen={showDialog}
        onConfirmDelete={(e: any) => {}}
        onClose={() => setShowDialog(false)}
      />

      <ConfirmationDialog
        id={'automationBuilder'}
        deletionTitle={'Execute Automation'}
        deletionText={`Do you want to Execute ${executeAutomationName} Automation ?`}
        isOpen={!!executeAutomationId}
        primaryButtonName="Yes"
        variant="primary"
        secondaryButtonName="No"
        onPrimaryBtnClick={() => {
          executeCron(executeAutomationId as string);
          setExecuteAutomationId(null)
          setExecuteAutomationName(null);
        }}
        onSecondaryClick={() => {
          setExecuteAutomationId(null)
          setExecuteAutomationName(null);
        }}
        onClose={() => {
          setExecuteAutomationId(null)
          setExecuteAutomationName(null);
        }}
      />
    </>
  );
};

export default CronAutomation;
