import React from 'react';
import { BsInfoCircleFill } from 'react-icons/bs';
import Typography from '@mui/material/Typography';
import { ErrorTooltip } from 'views/workflows/Tootltips/ErrorTooltip';
import { InfoTooltip } from 'views/workflows/Tootltips/InfoTooltip';
import {useTheme} from "styled-components"

type InfoIconPropTypes = {
  item: string[];
  type: 'error' | 'info';
  isCollectLocation?: boolean;
};

const InfoIcon = ({ item, type, isCollectLocation = false }: InfoIconPropTypes) => {
  const theme = useTheme()
  return (
    <>
      {type === 'error' ? (
        <ErrorTooltip
          title={
            <>
              <Typography color='white'>
                {item?.map((error: any) => (
                  <li key={error} style={{color:"white"}}> {error} </li>
                ))}
              </Typography>
            </>
          }
        >
          <div style={{ display: 'inline' }}>
            <BsInfoCircleFill
              size={'1.4rem'}
              color={theme.color.primary}
              style={{
                display: 'inline',
              }}
            />
          </div>
        </ErrorTooltip>
      ) : (
        <InfoTooltip
          title={
            <>
              <Typography color={theme.color.primary}>
                {item.length > 1 ? item?.map((error: any) => <li key={error} style={{color:theme.color.primary}}> {error} </li>) : <p color='white'> {item[0]} </p>}
              </Typography>
            </>
          }
        >
          <div style={{ display: 'inline' }}>
            <BsInfoCircleFill
              size={'1.4rem'}
              color={theme.color.primary}
              style={{
                display: 'inline',
                marginLeft: isCollectLocation ? '0px' : '8px',
              }}
            />
          </div>
        </InfoTooltip>
      )}
    </>
  );
};

export default InfoIcon;
