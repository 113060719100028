import { createAsyncThunk, createSlice,PayloadAction } from '@reduxjs/toolkit';
import notification from '../../../notifications/notifications';
import { TaskService } from '../../../views/tasks/services/TaskService';
import {isArray} from "lodash"

const initialState = {};

interface IRemoveFilePayload {
  link: string[],
  key: string
}

export const initEditTask = createAsyncThunk('editTask', async (taskId: string) => {
  const response = await TaskService.getTask(taskId)
    .then((res) => {
      if (res?.code && res?.code.indexOf('200') < 0) {
        if (res?.code === '401') {
          notification('error', res.message);
        } else {
          notification('error', res.message);
        }
      } else {
        // setTaskTypeName(res.data.taskTypeDisplayName);
        // setTaskTypeDescription(res.data.taskTypeDescription);
        // setElements(res.data.canvas.elements || []);
        return res.data;
      }
    })
    .catch((err) => {
      console.error('Error', err);
    });
  return response;
});

export const TaskEditSlice = createSlice({
  name: 'taskEdit',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setValue: (state: any, action: any) => {
      if (action.payload && action.payload.key) {
        // if key is not present
        if(!state[action.payload.key])
          state[action.payload.key] = []

        // if the payload is array
        if(isArray(action.payload.value)) {
          state[action.payload.key] = [...state[action.payload.key],...action.payload.value]
        } else {
          state[action.payload.key] = action.payload.value;
        }
          
      }
    },
    cancelTaskEdit: () => {
      return {};
    },
    setCreatedItemsForTaskEdit : (state,action) => {
      const {name, data} = action.payload

      //@ts-ignore
      if(!state[name]) {
         //@ts-ignore
        state[name] = []
      }
       //@ts-ignore
       data.forEach((singleData:any) => {
        //@ts-ignore
        state[name].push(singleData)
       })
      
    },
    editCreatedItemsForTaskEdit : (state,action) => {
      const {name,id,editedData} = action.payload
      //@ts-ignore
      const idx:number = state[name]?.findIndex((item) => item.id === id)
      if(idx !== -1) {
        //@ts-ignore
        state[name][idx] = {...editedData}
      }
      
    },
    deleteCreatedItemsForTaskEdit : (state,action) => {
      const {name, id} = action.payload
      //@ts-ignore
      const idx:number = state[name]?.findIndex((item:any) => item.id === id)
      if(idx !== -1) {
        //@ts-ignore
        state[name]?.splice(idx, 1); 
      }
    },
    removeFiles: (state:any,action:PayloadAction<IRemoveFilePayload>) => {
      const {link, key} = action.payload
      if(state[key]) {
        const filteredArray = state[key].filter((item:string) => !link.includes(item))
        state[key] = [...filteredArray]
      }
      
    } 
  },
  extraReducers: (builder) => {
    builder
      .addCase(initEditTask.pending, (state) => {})
      .addCase(initEditTask.fulfilled, (state: any, action: any) => {
        return action.payload.data;
      });
  },
});

export const {
  // setTaskType,
  setValue,
  cancelTaskEdit,
  setCreatedItemsForTaskEdit,
  editCreatedItemsForTaskEdit,
  deleteCreatedItemsForTaskEdit,
  removeFiles,
} = TaskEditSlice.actions;

export default TaskEditSlice.reducer;
