import React from "react";
import styled from "styled-components";

const StyledPills = styled.div`
  border-radius: 16px;
  background: var(--Gray-100, #f2f4f7);
  mix-blend-mode: multiply;
  color: #344054;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 8px 2px 6px;
`;

type Props = {
    children: React.ReactNode;
};

const Pills = ({children, ...props}: Props) => {
  return (
    <StyledPills {...props}>
      {children}
    </StyledPills>
  )
};

export default Pills;
