import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IActionReducer {
    copiedAction: any;
}

const initialState: IActionReducer = {
    copiedAction: null
}

export const ActionSlice = createSlice({
    name: 'action',
    initialState,
    reducers: {
        setCopiedAction: (state, action) => {
            state.copiedAction = action.payload;
        },
    }
})

export const {
    setCopiedAction
} = ActionSlice.actions;

export default ActionSlice.reducer;
