// given array of objects, find path of given string and return value of given path
export const getValueOfGivenPath = (path: string, data: any) => {
  const result = data.find((item: any) => {
    return item.path === path;
  });
  return result.value;
};

// given array of objects, find value of given path and return value of given value
export const getPathOfGivenValue = (value: string, data: any) => {
  const result = data.find((item: any) => {
    return item.value === value;
  });
  return result.path;
};
