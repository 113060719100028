import { useMemo } from "react";
import useEntity from "views/entities/hooks/useEntity";
import { ResourceTypeList, ResourceTypeConstant, TablePermissionsList, accessPermissions } from "views/entities/permissions/types";
import { defaultTablePermissions } from "constants/CommonConstants";

type Props = {
    resourceType: ResourceTypeList;
}

const useAccessPermissions = ({ resourceType }: Props) => {
    const { selectedEntityMetadata } = useEntity();
    /**
     * @Ashutosh temporary fix till we get the correct resource type
     */
    const isTask = /#\/task\//.test(window.location.href);
    const accessPermission = useMemo(() => {
        
        const newPermissionsObject: accessPermissions = { ...defaultTablePermissions };
        if(isTask) {
            Object.keys(newPermissionsObject).forEach((p:any) => {
                newPermissionsObject[p as keyof accessPermissions] = true;
            }
            );
            return newPermissionsObject;
        }
        else if (resourceType === ResourceTypeConstant.TABLE) {
            const permissions: TablePermissionsList[] = selectedEntityMetadata?.permissions as unknown as TablePermissionsList[];
            permissions?.forEach((p) => {
                if (p in newPermissionsObject) {
                    newPermissionsObject[p] = true;
                }
            });
            return newPermissionsObject;
        } else {
            Object.keys(newPermissionsObject).forEach((p:any) => {
                newPermissionsObject[p as keyof accessPermissions] = true;
            }
            );
            return newPermissionsObject;
            
        }
    }, [selectedEntityMetadata, resourceType]);

    return accessPermission;
}

export default useAccessPermissions;
