import { TextField } from "@mui/material";

import IconButton from "@mui/material/IconButton";
import {
  Control, FieldErrors, useFieldArray, useForm, UseFormClearErrors, UseFormGetValues,
  UseFormSetError
} from "react-hook-form";
import { RiAddBoxLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { DataFieldType } from "views/DataField/DataField.types";
import useWorkFlow from "views/workflows/hooks/useWorkFlow";
import { setSelectedAction } from "views/workflows/reducers/workflowAction";
import SingleChip from "./SingleChip";

type Props = {
  name: string;
  validation: any;
  isRequired: boolean | Function;
  getValues: UseFormGetValues<any>;
  setError: UseFormSetError<any>;
  clearErrors: UseFormClearErrors<any>;
  errors: FieldErrors<any>;
  control: Control<any>;
};

const ZListOfTextInputComponent = ({
  errors,
  validation,
  name,
  isRequired,
  getValues,
  setError,
  clearErrors,
  control,
}: Props) => { 
  const {
    register: localRegister,
    setValue: localSetValue,
    getValues: localGetValues,
    handleSubmit: localHandleSubmit,
  } = useForm();
  const {selectedAction} = useWorkFlow()

  const dispatch = useDispatch();


  const addItem = () => {
    const optionToAdd = localGetValues()[name];
    if (optionToAdd) {
      append(optionToAdd)
      dispatch(setSelectedAction({
        ...selectedAction,
        hasChanged: true,
        value: {
          value: getValues()[name] || [],
          dataType: DataFieldType.LIST_OF_TEXT,
          type: "literal"
        }
      }))
    };
    localSetValue(name, "");
    
  };

  const deleteItem = (index: number) => {
    remove(index);
    dispatch(setSelectedAction({
      ...selectedAction,
      hasChanged: true,
      value: {
        value: getValues()[name] || [],
        dataType: DataFieldType.LIST_OF_TEXT,
        type: "literal"
      }
    }))
    setTimeout(() => {
      validateField();
    }, 1000);
  };

  const handleSubmitWithoutPropagation = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    localHandleSubmit(addItem)();
    /**
     * We are using timoeout to update the error message in realtime
     * firstly, React Hook Form state will get filled with the value and then the error message will be updated
     */
    setTimeout(() => {
      validateField();
    }, 1000);
  };

  const validateField = () => {
    if (isRequired && !getValues()[name]?.length) {
      setError(name, { type: "manual", message: "This is a required field" });
    } else {
      clearErrors(name);
    }
  };

  const { append, remove } = useFieldArray({
    control,
    name: name,
  });

  return (
<>
        <TextField
          className="w-full"
          style={{height:'31px',borderRadius:'0px'}}
          type={"text"}
          {...localRegister(`${name}`, validation)}
          id={name}
          variant="outlined"
          placeholder="Type your option and press enter"
          onBlur={validateField}
          inputProps={{
            step: "any",
          }}
          InputProps={{
            endAdornment: (
              <IconButton
                sx={{ padding: "0px" }}
                type="submit"
                onClick={(e) => handleSubmitWithoutPropagation(e)}
                className="list_of_text_add_button"
              >
                <RiAddBoxLine
                  // type="submit"
                  // variant="contained"
                  size={"2rem"}
                  color="#cccccc"
                />
              </IconButton>
            ),
          }}
        />

      <div className="mt-2">
        {getValues()[name]?.map((item: string, index: number) => (
          <SingleChip key={index} index={index} handleDelete={deleteItem} label={item} />
        ))}
      </div>
      </>
  );
};

export default ZListOfTextInputComponent;
