import {
  Control,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form"
import {
  BooleanField,
  DateField,
  FileField,
  ImageField,
  ListOfValues,
  Location,
  Number,
  SignatureField,
  TextField,
} from "views/DataField/Components"
import ButtonAutomation from "views/DataField/Components/ButtonAutomation"
import ComputedColumn from "views/DataField/Components/Computed"
import RelationColumn from "views/DataField/Components/RelationColumn"
import { DataFieldType, EntityTypeField } from "views/DataField/DataField.types"
import ZCheckbox from "views/commonComponents/ZCheckbox"
import ErrorMessage from "views/DataField/ErrorMessage"
import useEntity from "../hooks/useEntity"
import { useEffect } from "react"
import { IRelationReferringEntityType } from "../types"

type Props = {
  type: any
  control: Control
  register: UseFormRegister<any>
  errors: any
  getValues: UseFormGetValues<any>
  setValue: UseFormSetValue<any>
}

const ColumnProps = ({
  type,
  errors,
  register,
  getValues,
  control,
  setValue,
}: Props) => {
  const { activeEditColumn } = useEntity()

  useEffect(() => {
    if (type === DataFieldType.TEXT) {
      setValue("defaultValue", "");
    }
  }, [type, setValue]);
  return (
    <>
      {type === DataFieldType.NUMBER ? (
        <Number errors={errors} register={register} />
      ) : type === DataFieldType.LOCATION ? (
        <Location
          register={register}
          show={true}
          fieldName={"location"}
          address={activeEditColumn?.children?.[0]?.defaultValue || ""}
          latitude={activeEditColumn?.children?.[1]?.defaultValue || ""}
          longitude={activeEditColumn?.children?.[2]?.defaultValue || ""}
        />
      ) : type === DataFieldType.BOOLEAN ? (
        <BooleanField control={control} />
      ) : type === DataFieldType.TEXT ? (
        <TextField
          errors={errors}
          register={register}
          defaultValueName={"defaultValue"}
        />
      ) : type === DataFieldType.DATETIME ? (
        <DateField register={register} />
      ) : type === DataFieldType.LIST_OF_TEXT ? (
        <ListOfValues
          errors={errors}
          isEntity={true}
          control={control}
          getValues={getValues}
        />
      ) : type === DataFieldType.SIGNATURE ? (
        <SignatureField register={register} />
      ) : type === DataFieldType.FILE ? (
        <FileField register={register} />
      ) : type === DataFieldType.IMAGE ? (
        <ImageField register={register} />
      ) : type === EntityTypeField.BUTTON ? (
        <ButtonAutomation
          register={register}
          control={control}
          errors={errors}
        />
      ) : type === EntityTypeField.COMPUTED ? (
        <ComputedColumn />
      ) : type === EntityTypeField.RELATION &&
        (activeEditColumn?.relationDetails?.referringEntity ===
        IRelationReferringEntityType.USER ||activeEditColumn?.relationDetails?.referringEntity ===
        IRelationReferringEntityType.TEAM )  ? (
        <></>
      ) : type === EntityTypeField.RELATION ? (
        <RelationColumn
          setValue={setValue}
          control={control}
          errors={errors}
          register={register}
        />
      ) : null}
      <div className="mt-2"></div>
      {(type === EntityTypeField.RELATION || type === EntityTypeField.USER || type === EntityTypeField.TEAM) && (
        <>
          <ZCheckbox
            name="relation"
            label="Allow linking to multiple records"
            control={control}
          />
          <div>
            <ErrorMessage errors={errors} fieldName={"relation"} />
          </div>
          {type !== EntityTypeField.RELATION &&
            type !== EntityTypeField.USER && 
            type !== EntityTypeField.TEAM &&
            (
              <>
                <div className="mt-2"></div>
                <ZCheckbox
                  name="isDisplayColumn"
                  label="Make this display column"
                  control={control}
                />
                <div>
                  <ErrorMessage errors={errors} fieldName={"isDisplayColumn"} />
                </div>
              </>
            )}
        </>
      )}
      <div className="mt-2"></div>
      {type !== EntityTypeField.BUTTON && type !== EntityTypeField.COMPUTED && (
        <>
          <ZCheckbox
            name="mandatory"
            label="Mandatory during record creation"
            control={control}
          // disabled={activeEditColumn?.type}
          />
          <div>
            <ErrorMessage errors={errors} fieldName={"mandatory"} />
          </div>
          {type !== EntityTypeField.RELATION &&
            type !== EntityTypeField.USER && 
            type !== EntityTypeField.TEAM &&
            (
              <>
                <div className="mt-2"></div>
                <ZCheckbox
                  name="isDisplayColumn"
                  label="Make this display column"
                  control={control}
                />
                <div>
                  <ErrorMessage errors={errors} fieldName={"isDisplayColumn"} />
                </div>
              </>
            )}
        </>
      )}
      <div className="mt-2"></div>
      {(type === DataFieldType.TEXT || type === DataFieldType.NUMBER) && (
        <>
          <ZCheckbox
            name="isUnique"
            label="Column value should be unique"
            control={control}

          // disabled={activeEditColumn?.type}
          />
          <div>
            <ErrorMessage errors={errors} fieldName={"isUnique"} />
          </div>
        </>
      )}
    </>
  )
}

export default ColumnProps
