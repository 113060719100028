import styled from 'styled-components';

export const FrameIcon = styled.img`
  position: relative;
  width: 50.5px;
  height: 50px;
  flex-shrink: 0;
`;

export const SorryOurBuilder = styled.div`
  display: flex;
  line-height: 2rem;
  color: #ffffff;
  font-weight: 600;
  justify-content: center;
  font-size: 1.2rem;
  width: auto;
`;

export const ViewThisScreenOnYourDesk = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  justify-content: center;
  color: #ffffffb2;
`;

export const FrameDiv1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${(props: { extra?: boolean }) => (props.extra ? '40px' : '10px')};
  width: ${(props: { extra?: boolean }) => (props.extra ? '50%' : 'auto')};
`;

export const TextDiv = styled.div`
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
`;

export const BaseDiv = styled.div`
  border-radius: 4px;
  background-color: ${(props) => props.theme.color.primary};
  overflow: hidden;
  padding: 10px 16px;
`;

export const ButtonsDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export const FrameDiv = styled.div`
  position: absolute;
  left: 50%;
  top: 75%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 90%;
  height: 100vh;
`;

export const NotSupportedDiv = styled.div`
  position: relative;
  background-color: #333;
  width: 100%;
  height: 896px;
  overflow: hidden;
  text-align: center;
  font-size: 14px;
  color: #fff;
  font-family: Inter;
`;
