import { Dispatch } from "react"
import { NodeTypes } from "./nodes/Nodes.types"
import { setNodesThatCanBeVisited, setPossibleHomeNode, setPossibleTerminalNodes } from "./reducers/workflowAction"



export class DetectHomeNode {

    public static setHomeAndTerminalNodes = (elements: [],dispatch: Dispatch<any>,isInititalLoad=false) => {
        this.setHomeNode(elements,dispatch,isInititalLoad)
        this.setTerminaNodes(elements,dispatch,isInititalLoad)
    }

    /**
     * 
     * @param elements 
     * elements: [
     *      id: string,
     *      name: string
     *      position: {x:number,y:number}
     *      type: "zorpState" | "zorpDecisionNode" | "zorpEdge"
     *      data:{
     *          id:string,
     *          name: string,
     *          isHomeNode:boolean,
     *          type: "zorpState" | "zorpDecisionNode" | "zorpEdge"
     *      }
     *     ]
     */

    /**
     * 1. Loop all edges and mark each state node which is at the target of these edge.
     * these are the node which have an incoming edges and hence cannot be a home node.
     * 
     * 2. Mark all the node which are not state node. These are decision node and hence
     * cannot be the home node
     * 
     * 3. Get all node which dont have any outgoing transition. These cannot be home node
     * 4. If there is exactly one node after above two filters, mark that home node.
     * 5. Else, mark all node node as home node false and let the user the home.
     * 
     */
    public static setHomeNode = (elements:[], dispatch:Dispatch<any>,isInititalLoad:boolean) => {
        
        const invalidHomeNodeCandidate:string[] = []
        // filter all the transition
        const allTranisitions = elements?.filter((item: any) => item.type === NodeTypes.TRANSITION)
       
        // Store all the node which have an incoming edge
        allTranisitions.forEach((transition:any) => {
            invalidHomeNodeCandidate.push(transition.target)
        })

        // Add decision node to invalidHomeNodeCandidate
        const allDecisionNodes = elements?.filter((item:any) => item.type === NodeTypes.DECISION_NODE)
        allDecisionNodes.forEach((item:any) => {
            invalidHomeNodeCandidate.push(item.data.id)
        })

        // Get all node which are source of any transition
        const nodesWhichAreSourceOfAnyTransition = allTranisitions.map((item:any) => item.source)
        
        // get all state node
        const allStateNode = elements?.filter((item:any) => item.type === NodeTypes.STATE_NODE)
                
        const possibleHomeNode = allStateNode.filter((node:any) => !invalidHomeNodeCandidate.includes(node.id) && nodesWhichAreSourceOfAnyTransition.includes(node.id)).map((singleNode:any) => singleNode.id)
        
        dispatch(setPossibleHomeNode(possibleHomeNode))

        // this.markAllReachableNode(elements, dispatch)

        return possibleHomeNode

    }

    private static getAllReachableNodes = (graph:any, currNode:string, visitedNodes:string[]) => {
        if(visitedNodes.includes(currNode)){
            return
        } 

        visitedNodes.push(currNode)

        const allNodesThanCanBeVisitedFromThisNode = graph[currNode] || []
        allNodesThanCanBeVisitedFromThisNode.forEach((id:string) => {
            this.getAllReachableNodes(graph, id, visitedNodes)
        })

        return
    }

    /**
     * Get the currently selected Home Node
     * Perform a DFS from the home node and get the stepId of all the step node that could be visited
     */

    public static markAllReachableNode = (elements:any[], dispatch: Dispatch<any>) => {
        
        const homeNode:any = elements.find((ele:any) => ele.data?.isHomeNode === true)
        const allStepNodes = elements.filter((ele:any) => ele.type === NodeTypes.STATE_NODE)

        // prepare a graph with key as nodeId and value as array of nodeIds which are reachable from that node
        let graph:any[] = []
        elements.forEach((ele:any) => {
            if(ele.type === NodeTypes.TRANSITION) {
                if(!graph[ele.source]) {
                    graph[ele.source] = []
                }
                graph[ele.source].push(ele.target)
            }
        })

        // DFS
        let visitedNodes:string[] = []
        if(homeNode){
            this.getAllReachableNodes(graph, homeNode.data.id, visitedNodes)
        }
        dispatch(setNodesThatCanBeVisited(visitedNodes))
        
        return true
    }

    /**
     * A node is a terminal node if it is a state node and it does not have any outgoing transition
     * a workflow can have many terminal nodes, but only one home node.
     * so if a workflow has 0 terminal node, we show them the option to mark any node as terminal node.
     *  
     */
    public static setTerminaNodes = (elements:any[], dispatch: Dispatch<any>,isInititalLoad:boolean) => {

        // Get all transition
        const allTranisitions = elements?.filter((item: any) => item.type === NodeTypes.TRANSITION)

        // Get all state node which are source of any transition.
        const nodesWhichAreSourceOfAnyTransition = allTranisitions.map((item:any) => item.source)
        
        // get all state node
        const allStateNode = elements?.filter((item:any) => item.type === NodeTypes.STATE_NODE)
        
        // get all possible termial nodes.
        const possibleTerminalNodes = allStateNode.filter((node:any) => !nodesWhichAreSourceOfAnyTransition.includes(node.id)).map((singleNode:any) => singleNode.id)
        dispatch(setPossibleTerminalNodes({payload:possibleTerminalNodes, allowUnset:!isInititalLoad}))
    }
    

}