import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import notification from 'notifications/notifications';
import { TeamService } from 'providers/data/services/TeamService';

const initialState = {
  status: '',
  teamsList: [],
  teamDetails: {},
};

export const getTeamById = createAsyncThunk('builder/getTeamById', async (id: string) => {
  return await TeamService.getTeam(id);
});

export const createTeam = createAsyncThunk('builder/createTeam', async (teamCreatePayload: any) => {
  return await TeamService.createTeam(teamCreatePayload);
});

export const updateTeam = createAsyncThunk('builder/updateTeam', async (data: any) => {
  const { id = '', teamCreatePayload = {} } = data;
  return await TeamService.updateTeam(id, teamCreatePayload);
});

export const teamsPageSlice: any = createSlice({
  name: 'teamsPage',
  initialState,
  reducers: {
    clearTeamsPageData: (state: any) => {
      state.teamsList = [];
      state.teamDetails = {};
      state.status = '';
    },
  },
  extraReducers(builder): void {
    builder.addCase(getTeamById.pending, (state: any) => {
      state.loadingTeam = true;
    });
    builder.addCase(getTeamById.fulfilled, (state: any, action: any) => {
      state.loadingTeam = false;
      state.teamDetails = action.payload.data?.[0];
    });
    builder.addCase(getTeamById.rejected, (state: any, action: any) => {
      state.loadingTeam = false;
      if (action?.payload?.code?.indexOf('200') < 0) {
        notification('error', action?.payload?.message);
      }
    });
    builder.addCase(createTeam.pending, (state: any) => {
      state.loadingTeam = true;
    });
    builder.addCase(createTeam.fulfilled, (state: any, action: any) => {
      state.loadingTeam = false;
      state.status = action.payload.code;
    });
    builder.addCase(createTeam.rejected, (state: any, action: any) => {
      state.loadingTeam = false;
      if (action?.payload?.code?.indexOf('200') < 0) {
        notification('error', action?.payload?.message);
      }
    });
    builder.addCase(updateTeam.pending, (state: any) => {
      state.loadingTeam = true;
    });
    builder.addCase(updateTeam.fulfilled, (state: any, action: any) => {
      state.loadingTeam = false;
      state.status = action.payload.code;
    });
    builder.addCase(updateTeam.rejected, (state: any, action: any) => {
      state.loadingTeam = false;
      if (action?.payload?.code?.indexOf('200') < 0) {
        notification('error', action?.payload?.message);
      }
    });
  },
});

export const { clearTeamsPageData } = teamsPageSlice.actions;

export default teamsPageSlice.reducer;
