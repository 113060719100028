/* Throughout this folder WT - Stands for WorkFlowTemplate */
import CloseIcon from '@mui/icons-material/Close';
import { Grid, useMediaQuery } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { fetchWorkflowTemplates } from '../reducers/WorkflowTemplate.actions';
import { ContentLayout, WTDialogTitle, WTModal } from '../workflow-template.styles';
import WTContent from './sub-components/WTContent'; /*  */
import WTLHSidebar from './sub-components/WTLHSidebar';
import WTMobileTabs from './sub-components/WTMobileTabs';

const WTMainModal = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = useState(location.pathname === '/templates');

  useEffect(() => {
    dispatch(fetchWorkflowTemplates());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => {
    setOpen(false);
    history.push('/workflows');
  };

  const handleCloseMobile = (redirect: boolean) => {
    setOpen(false);
    redirect && history.push('/workflows');
  };

  return isMobile ? (
    <WTMobileTabs open={open} handleClose={handleCloseMobile} />
  ) : (
    <WTModal open={open} onClose={handleClose} fullWidth>
      <WTDialogTitle id='scroll-dialog-title'>
        Template Library <CloseIcon style={{ cursor: 'pointer' }} fontSize='large' onClick={handleClose} />
      </WTDialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText id='scroll-dialog-description' tabIndex={-1}>
          <ContentLayout>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <WTLHSidebar />
              </Grid>
              <Grid item xs={9}>
                <WTContent />
              </Grid>
            </Grid>
          </ContentLayout>
        </DialogContentText>
      </DialogContent>
    </WTModal>
  );
};

export default WTMainModal;
