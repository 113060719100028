//@ts-nocheck
import { useEffect, useState } from "react";
import Select from 'react-select';
import { LabelWrapper } from "views/automationBuilder/automationBuilder.styles";
import { IComponent } from "views/automationBuilder/types/ABType";
import { Label } from "views/DataField/DataField.styles";
import ErrorMessage from "views/DataField/ErrorMessage";
type ErrorMessageProps = {
  errors: any;
  fieldName: string
  display?: string
}

type ISelectComponent = {
  options: IComponent[];
  defaultValue: {};
  optionLabel: any;
  optionId: any;
  label: any;
  placeHolder: string;
  isClearable?:boolean;
  onClickFunction: (component: any) => void;
  disabled?: Boolean;
  errorMessageName?: string;
  errors?: ErrorMessageProps;
};

const ABSelect = ({ options, defaultValue, onClickFunction, optionLabel, optionId, disabled = false, placeHolder, label,errorMessageName, errors,isClearable=true }: ISelectComponent) => {
  const [selectedId, setSelectedId] = useState(defaultValue || {});

  const handleClick = (component: any) => {
    onClickFunction(component);
  };
  useEffect(() => {
    setSelectedId(defaultValue)
  }, [defaultValue])

  return (
    <>
      <LabelWrapper>
        <Label>{placeHolder}</Label>
      </LabelWrapper>

      {
        (selectedId || options) &&

        <Select
          value={selectedId}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
          isSearchable
          getOptionLabel={optionLabel}
          getOptionValue={optionId}
          isClearable={isClearable}
          disabled={disabled}
          onChange={(optionValue) => {
            setSelectedId(optionValue);
            handleClick(optionValue);
          }}
          placeholder={placeHolder}
          name={placeHolder}
          menuPosition={"fixed"}
          menuPlacement={"bottom"}
          options={options}
        />

        
      }


{
        errors &&
        <ErrorMessage errors={errors} fieldName={errorMessageName} />
      }
    </>
  );
};

export default ABSelect;
