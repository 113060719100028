import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import notification from 'notifications/notifications';
import { RoleService } from 'providers/data/services/RoleService';

const initialState = {
  status: '',
  rolesList: [],
  roleDetails: {},
};

export const getRoleById = createAsyncThunk('builder/getRoleById', async (id: string) => {
  return await RoleService.getRole(id);
});

export const createRole = createAsyncThunk('builder/createUser', async (roleCreatePayload: any) => {
  return await RoleService.createRole(roleCreatePayload);
});

export const updateRole = createAsyncThunk('builder/updateUser', async (data: any) => {
  const { id = '', roleCreatePayload = {} } = data;
  return await RoleService.updateRole(id, roleCreatePayload);
});

export const updateRoleAcl = createAsyncThunk('builder/updateRoleAcl', async (roleAclPayload: any) => {
  return await RoleService.updateRoleAcl(roleAclPayload);
});

export const rolesPageSlice: any = createSlice({
  name: 'rolesPage',
  initialState,
  reducers: {
    clearRolesPageData: (state: any) => {
      state.rolesList = [];
      state.roleDetails = {};
      state.status = '';
    },
  },
  extraReducers: {
    // @ts-ignore
    [getRoleById.pending]: (state: any) => {
      state.loadingRole = true;
    },
    // @ts-ignore
    [getRoleById.fulfilled]: (state: any, action: any) => {
      state.loadingRole = false;
      state.roleDetails = action.payload.data;
    },
    // @ts-ignore
    [getRoleById.rejected]: (state: any, action: any) => {
      state.loadingRole = false;
      if (action?.payload?.code?.indexOf('200') < 0) {
        notification('error', action?.payload?.message);
      }
    },
    // @ts-ignore
    [createRole.pending]: (state: any) => {
      state.loadingRole = true;
    },
    // @ts-ignore
    [createRole.fulfilled]: (state: any, action: any) => {
      state.loadingRole = false;
      state.status = action.payload.code;
    },
    // @ts-ignore
    [createRole.rejected]: (state: any, action: any) => {
      state.loadingRole = false;
      if (action?.payload?.code?.indexOf('200') < 0) {
        notification('error', action?.payload?.message);
      }
    },
    // @ts-ignore
    [updateRole.pending]: (state: any) => {
      state.loadingRole = true;
    },
    // @ts-ignore
    [updateRole.fulfilled]: (state: any, action: any) => {
      state.loadingRole = false;
      state.status = action?.payload?.code;
    },
    // @ts-ignore
    [updateRole.rejected]: (state: any, action: any) => {
      state.loadingRole = false;
      if (action?.payload?.code?.indexOf('200') < 0) {
        notification('error', action?.payload?.message);
      }
    },
  },
});

export const { clearRolesPageData } = rolesPageSlice.actions;

export default rolesPageSlice.reducer;
