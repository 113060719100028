import { makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { IActionType } from '@zorp/common-libs-js/dist/monitor';
import ActionDeleteIcon from 'assests/action_delete_icon.svg';
import { RudderStackAutomationBuilderEvents } from "constants/RudderStackConstants";
import FilterErrorMessage from 'filter/components/FilterErrorMessage';
import React from 'react';
import { Controller } from "react-hook-form";
import styled from "styled-components";
import { recordRSEvent } from "utils/CommonUtils";
import { ZButton } from 'views/commonComponents/commonComponents.styles';
import useAccount from "views/layouts/reducer/useAccount";
import { LabelText, MonitorTextField, VariableHelperText } from "../Monitor.styles";
import ColumnsListCapsure from '../MonitorInterfaceComponents/ColumnsListCapsure';
import useMonitor from '../reducer/useMonitor';

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

interface IAlertEmail {
  errors: any;
  control: any;
  register: any;
  setValue: any;
  watch: any;
  fields: any;
  onRemove:(index:number) => void;
}

const AlertEmail = ({ errors, control, register, setValue, watch, fields, onRemove }: IAlertEmail) => {
  const { monitorInterfaceContext } = useMonitor();
  const { account } = useAccount();
  // const fieldsIndex = fields.findIndex((data: any) => (data.actionId == monitorInterfaceContext?.monitorComponentsData?.selectedActionDetails?.actionId && data.actionType == IActionType.EMAIL));
  const fieldsIndex = fields.findIndex((data: any) => (data.actionType == IActionType.EMAIL));
  const columnsListArray = monitorInterfaceContext.monitorComponentsData.tablesColumnsList || [];
  const useHelperTextStyles = makeStyles(() => ({
    root: {
      paddingLeft: "0px",
      marginLeft: "0px !important",
    },
  }));
  
  const helperTextStyles = useHelperTextStyles();

  return (
    <Box width={'100%'} padding={'24px'} borderRadius={'4px'} border={'1px solid var(--Gray-200, #EAECF0)'} boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)'}>
      <TextWrapper>
        <LabelText>Email Id</LabelText>
        <Controller
        name={`actionData[${fieldsIndex}].payload.userEmail`}
        control={control}
        render={({ field: { onChange, ...field } }) => (
          <MonitorTextField
          helperText={`Recipients are separated by ","`}
            {...field}
          placeholder={`${account.primaryContactEmail || ''}`}
          defaultValue={`${account.primaryContactEmail || ''}`}
            {...register(`actionData[${fieldsIndex}].payload.userEmail`)}
            FormHelperTextProps={{
              classes: {
                root: helperTextStyles.root,
              }
            }}
            inputProps={{
              "aria-label": "none",
            }}
            onChange={onChange}
          />
        )}
      />
        <FilterErrorMessage errors={errors} name={'monitorName'} />
      </TextWrapper>

      <TextWrapper>
        <LabelText>Email Subject</LabelText>
        <Controller
          name={`actionData[${fieldsIndex}].payload.mailSubject`}
          control={control}
          rules={{ required: "Email Subject is Required" }}
          render={({
            field: { onChange, value },
            fieldState: { error },
            formState,
          }) => (
            <MonitorTextField
              error={!!error}
              placeholder="Urgent Attention Required"
              variant="outlined"
              {...register(`actionData[${fieldsIndex}].payload.mailSubject`)}
              onChange={onChange}
            />
          )}
        />
        <FilterErrorMessage errors={errors} name={'monitorName'} />
      </TextWrapper>

      <LabelText style={{marginTop:'16px'}}>Email Body</LabelText>
      <Controller
        name={`actionData[${fieldsIndex}].payload.mailBody`}
        control={control}
        render={({ field }) => (
          <>
          <TextareaAutosize
            {...field} // destructuring field state provided by Controller
            maxRows={5}
            placeholder={`Hey Team, \nI would like to bring to you notice that...`}
            {...register(`actionData[${fieldsIndex}].payload.mailBody`)}
            minRows={5}
            style={{
              width: "100%",
              border: "1px solid #c2c9d1",
              borderRadius: "4px",
              padding: "6px 8px",
              marginTop: '6px'
            }}
          />
          {
columnsListArray?.length > 0 &&
            <VariableHelperText>Add Variables to your Email Body</VariableHelperText>
          }
          </>
        )}
      />

      <Box width={'100%'} marginTop={'8px'}>
      {columnsListArray?.length > 0 &&
        <ColumnsListCapsure
          onCardClick={(columnName: string) => {
            setValue(`actionData[${fieldsIndex}].payload.mailBody`, `${watch()?.actionData?.[fieldsIndex]?.payload?.mailBody} \${${columnName}}`)
          }}
          columnsList={columnsListArray} />}

        <Box display={'flex'} width={'100%'} justifyContent={'space-between'}>
          <Box>
            
          <Box ml={'11px'}>
          <Controller
        name={`actionData[${fieldsIndex}].payload.attachCSV`}
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={
              <input
              {...field}
              type="checkbox"
              style={{ marginRight: "10px", marginTop: "5px", marginBottom: "5px", height:'16px', width:'16px' }}
              checked={field.value}
          />
            }
            label={
              <LabelText style={{
                marginBottom:'0px'
              }}>Attach matching results as CSV</LabelText>
            }
            style={{ marginTop: '20px' }}
          />
        )}
      />
      </Box>
          </Box>
          <Box>
            <ZButton
            onClick={()=>{
              onRemove(fieldsIndex)
              recordRSEvent(RudderStackAutomationBuilderEvents.emailActionDelete, {
                context: RudderStackAutomationBuilderEvents.monitorContext
              });
            }}
            style={{ padding: '0px', fontFamily: 'Inter', marginTop: '16px', borderRadius: '4px', height: '40px', color: '#B42318', fontSize: '12px', fontWeight: 500, backgroundColor: '#fff', textTransform: 'none' }}
              sx={{ height: '40px' }}
              variant="text">
              <img style={{ paddingRight: '8px' }} src={ActionDeleteIcon} alt='Icon' />
              Delete</ZButton>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

// export default AlertEmail
export  default React.memo(AlertEmail);