import { FunctionComponent, memo } from "react"
import { useDispatch } from "react-redux"

import { IZChoiceInterface } from "../choice/IZChoiceInterface"
import { ZChoice } from "../choice/ZChoice"
import { ComponentType } from "../ComponentTypeEnum"
import { IZDateTimeInterface } from "../datetime/IZDateTimeInterface"
import { ZDateTime } from "../datetime/ZDateTime"
import { IZInputInterface } from "../input/IZInputIInterface"
import { ZInput } from "../input/ZInput"
import { IZImageViewerInterface } from "../imageviewer/IZImageViewerInterface"
import { ZTeamList } from "../team/ZTeamList"
import { IZTeamInterface } from "../team/IZTeamInterface"
import { IZTextAreaInterface } from "../textArea/IZTextAreaInterface"
import { ZTextArea } from "../textArea/ZTextArea"
import { ZUserList } from "../user/ZUserList"
import { IZUserListInterface } from "../user/IZUserListInterface"
import { IZTextInterface } from "../text/IZTextInterface"
import { ZText } from "../text/ZText"
import { ZSlot } from "../slot/ZSlot"
import { IZSlotInterface } from "../slot/IZSlotInterface"
import { IZOrderConfigInterface } from "../order/IZOrderConfigInterface"
import { ZOrder } from "../order/ZOrder"
import { ZEmpty } from "../empty/ZEmpty"
import { ZLocationInput } from "../location-input/ZLocationInput"
import { IZLocationInputInterface } from "../location-input/IZLocationInputInterface"
import { ZLocationDisplay } from "../location-display/ZLocationDisplay"
import { IZLocationDisplayInterface } from "../location-display/IZLocationDisplayInterface"
import { ZListOfText } from "../listOfText/ZListOfText"
import ZList from "../listDatafield/ZList"
import { IZListInterface } from "../listDatafield/IZListInterface"
import { ZUploader } from "components/uploader/ZUploader"
import { ZUploaderSign } from "components/uploader/ZUploaderSign"
import { IZUploader } from "components/uploader/IZUploader"
import ZBoolean from "components/boolean/ZBoolean"
import { IZBoolean } from "components/boolean/IZBoolean"
import { IZComponentPropType } from "render-engine/models/ITaskTypeWebBodyRenderModel"
import { ZImageViewer } from "components/ZImageViewer/ZImageViewer"
import ZRelation from "components/relation/ZRelation"
import ZRelationShow from "components/relation/ZRelationShow"
import ZListOfTextDropdown from "components/listOfText/ZListOfTextDropdown"
import { IZListOfTextInterface } from "components/listOfText/IZListOfText"
import { ZComment } from "components/comment/ZComment"
import { IZCommentInterface } from "components/comment/IZCommentInterface"

const ZComponentComponent: FunctionComponent<IZComponentPropType> = ({
  config,
  onChange,
  register,
  errors,
  control,
  isOpenedAsSubfield,
  setValue,
  getValues,
  setError,
  clearErrors,
}) => {
  const dispatch = useDispatch()
  if (!config) return <></>

  if (typeof config.isVisible === "function" && config.isVisible())
    config.isVisible = config.isVisible()
  else if (typeof config.isVisible === "boolean" && config.isVisible) {
    // do nothing
  } else if (
    typeof config.isVisible === "string" &&
    new Function(config.isVisible)()
  )
    config.isVisible = new Function(config.isVisible)()

  // const handleChange = (key: string, val: any) => {
  //     dispatch(setValue({ key: key, value: val }));
  // }

  const otherComponentParams: any = {
    onChange: onChange,
    register: register,
    errors: errors,
    control: control,
    isOpenedAsSubfield,
    setValue: setValue,
    getValues: getValues,
    setError: setError,
    clearErrors: clearErrors,
  }

  const displayNode = (node: any) => {
    switch (node?.type) {
      case ComponentType.INPUT:
        return (
          <ZInput
            config={node as IZInputInterface}
            {...otherComponentParams}
          ></ZInput>
        )
      case ComponentType.CHOICE:
        return (
          <ZChoice
            config={node as IZChoiceInterface}
            {...otherComponentParams}
          ></ZChoice>
        )
      case ComponentType.DATETIME:
        return (
          <ZDateTime
            config={node as IZDateTimeInterface}
            {...otherComponentParams}
          ></ZDateTime>
        )
      case ComponentType.IMAGEVIEWER:
        return (
          <ZImageViewer
            config={node as IZImageViewerInterface}
            {...otherComponentParams}
          ></ZImageViewer>
        )
      case ComponentType.LIST:
        return (
          <ZList
            config={node as IZListInterface}
            {...otherComponentParams}
          ></ZList>
        )
      // case ComponentType.SIGNATURE:
      //   return (
      //     <ZLink
      //       config={node as IZImageViewerInterface}
      //       {...otherComponentParams}
      //     ></ZLink>
      //   );
      case ComponentType.TEAMLIST:
        return (
          <ZTeamList
            config={node as IZTeamInterface}
            {...otherComponentParams}
          ></ZTeamList>
        )
      case ComponentType.USERLIST:
        return (
          <ZUserList
            config={node as IZUserListInterface}
            {...otherComponentParams}
          ></ZUserList>
        )
      case ComponentType.ORDER:
        return (
          <ZOrder
            config={node as IZOrderConfigInterface}
            {...otherComponentParams}
          ></ZOrder>
        )
      case ComponentType.TEXTAREA:
        return (
          <ZTextArea
            config={node as IZTextAreaInterface}
            {...otherComponentParams}
          ></ZTextArea>
        )
      case ComponentType.TEXT:
        return (
          <ZText
            config={node as IZTextInterface}
            {...otherComponentParams}
          ></ZText>
        )
      case ComponentType.SLOT:
        return (
          <ZSlot
            config={node as IZSlotInterface}
            {...otherComponentParams}
          ></ZSlot>
        )
      case ComponentType.EMPTY:
        return <ZEmpty></ZEmpty>
      case ComponentType["LOCATION-INPUT"]:
        return (
          <ZLocationInput
            config={node as IZLocationInputInterface}
            {...otherComponentParams}
          ></ZLocationInput>
        )
      case ComponentType["LOCATION-DISPLAY"]:
        return (
          <ZLocationDisplay
            config={node as IZLocationDisplayInterface}
            {...otherComponentParams}
          ></ZLocationDisplay>
        )
      case ComponentType.IMAGE:
        return (
          <ZUploader
            config={node as IZUploader}
            {...otherComponentParams}
          ></ZUploader>
        )
      case ComponentType.FILE:
        return (
          <ZUploader
            config={node as IZUploader}
            {...otherComponentParams}
          ></ZUploader>
        )
      case ComponentType.SIGNATURE:
        return (
          <ZUploaderSign
            config={node as IZUploader}
            {...otherComponentParams}
          ></ZUploaderSign>
        )
      case ComponentType.BOOLEAN:
        return (
          <ZBoolean
            config={node as IZBoolean}
            {...otherComponentParams}
          ></ZBoolean>
        )
      case ComponentType.RELATION:
        return (
          <ZRelation
            config={node as IZBoolean}
            {...otherComponentParams}
          ></ZRelation>
        )
      case ComponentType.RELATION_SHOW:
        return <ZRelationShow config={node as IZBoolean} {...otherComponentParams}></ZRelationShow>
      case ComponentType["LIST_OF_TEXT"]:
        if (node?.shape === "DROPDOWN") {
          return (
            <ZListOfTextDropdown
              config={node as IZListOfTextInterface}
              {...otherComponentParams}
            ></ZListOfTextDropdown>
          )
        }
        if (node?.shape === "") {
          return (
            <ZListOfText
              config={node as IZInputInterface}
              {...otherComponentParams}
            ></ZListOfText>
          )
        }
        break
      case ComponentType.COMMENT:
        //return <ZComment config={node as IZCommentInterface} {...otherComponentParams}></ZComment>;
        break
      default:
        console.error("invalid component")
        return
    }
  }

  return <>{displayNode(config)}</>
}

export const ZComponent = memo(ZComponentComponent)
