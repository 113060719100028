import { BOOLEAN, NUMBER, TEXT } from "constants/CommonConstants";
import { useJSEditor } from "lowcode/hooks/useJSEditor";
import getInputType from "lowcode/util/getInputType";
import { ZJSEditorLayout } from "lowcode/ZJSEditorLayout";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { recordRSEvent, removeBraces, stopPropagate } from "utils/CommonUtils";
import useWorkflow from "views/workflows/hooks/useWorkFlow";
import { setSelectedAction } from "views/workflows/reducers/workflowAction";
import ModalSmartInputTextField from "views/workflows/workflowactions/api/components/ModalSmartInputTextField";
import {
  getActionNameForTempId,
  IIsTempIdUniqueContext,
  saveGuard,
  ValidLocation
} from "views/workflows/workflowactions/api/utils/utils";
import {
  CoordinatesWrapper, FlexDiv,
  Heading,
  HeadingWrapper,
  Label
} from "views/workflows/workflowactions/ModalContent.styles";
import "../PushNotification.css";
import { PageCategoryEnum, recordPageVisit } from "config/Rudderstack";
import { RudderStackAutomationBuilderContext, RudderStackAutomationBuilderEvents } from "constants/RudderStackConstants";
import { setScriptEditorPayload } from "lowcode/state/scriptOpenState";
import { EditorScriptLocation } from "lowcode/state/types/types";
import { useEffect, useState } from "react";
import { getLowCodeEditorDefaultReturnText, getLowCodeEditorText } from "utils/Utils";
import useAutomation from "views/automationBuilder/hooks/useAutomation";
import usePayload from "views/automationBuilder/hooks/usePayload";
import { DataFieldType } from "views/DataField/DataField.types";
import ErrorMessageInput from "views/workflows/workflowactions/api/components/ErrorMessageInput";
import ExecuteCondition from "views/workflows/workflowactions/api/components/ExecuteCondition";
import IsActive from "views/workflows/workflowactions/api/components/IsActive";
import DatafieldSelector from "../../DatafieldSelector";
import LocationDivider from "./LocationDivider";
import { generateTempId, isTempIdUnique } from "../utils/utils";
import InputTempId from "views/automationBuilder/components/InputTempId";

const LocationCheck = () => {
  const dispatch = useDispatch();
  const { openJSEditorLayout, scriptEditorOpen } = useJSEditor();
  const [isEditing, setIsEditing] = useState(false);
  const [tempIdChanged, setTempIdChanged] = useState(true);

  const {
    selectedAction,
    selectedTransition,
    dataPicker,
    getScriptValue,
  } = useWorkflow();

  const [currTempIdVal, setCurrTempIdVal] = useState(selectedAction?.tempId || "");

  const { clearRightSide, selectedAutomation, decisionSwitchAutomation } = useAutomation()
  const { actionConditionPayload } = usePayload();

  const locationCheckScriptConfig = {
    UserLatitudeConfig: {
      script: getScriptValue(EditorScriptLocation.LOCATION_CHECK_USER_LATITUDE) ||
        getLowCodeEditorText(EditorScriptLocation.LOCATION_CHECK_USER_LATITUDE, DataFieldType.NUMBER, `return ${getLowCodeEditorDefaultReturnText(DataFieldType.NUMBER)}`),
      dataType: NUMBER,
    },
    UserLongitudeConfig: {
      script: getScriptValue(
        EditorScriptLocation.LOCATION_CHECK_USER_LONGITUDE
      ) || getLowCodeEditorText(EditorScriptLocation.LOCATION_CHECK_USER_LONGITUDE, DataFieldType.NUMBER, `return ${getLowCodeEditorDefaultReturnText(DataFieldType.NUMBER)}`),
      dataType: NUMBER,
    },
    CustomerLatitudeConfig: {
      script: getScriptValue(
        EditorScriptLocation.LOCATION_CHECK_CUSTOMER_LATITUDE
      ) || getLowCodeEditorText(EditorScriptLocation.LOCATION_CHECK_CUSTOMER_LATITUDE, DataFieldType.NUMBER, `return ${getLowCodeEditorDefaultReturnText(DataFieldType.NUMBER)}`),
      dataType: NUMBER,
    },
    CustomerLongitudeConfig: {
      script: getScriptValue(
        EditorScriptLocation.LOCATION_CHECK_CUSTOMER_LONGITUDE
      ) || getLowCodeEditorText(EditorScriptLocation.LOCATION_CHECK_CUSTOMER_LONGITUDE, DataFieldType.NUMBER, `return ${getLowCodeEditorDefaultReturnText(DataFieldType.NUMBER)}`),
      dataType: NUMBER,
    },
    ExpectedDistanceConfig: {
      script: getScriptValue(
        EditorScriptLocation.LOCATION_CHECK_EXPECTED_DISTANCE
      ) || getLowCodeEditorText(EditorScriptLocation.LOCATION_CHECK_EXPECTED_DISTANCE, DataFieldType.NUMBER, `return ${getLowCodeEditorDefaultReturnText(DataFieldType.NUMBER)}`),
      dataType: NUMBER,
    },
    LocationGuardCondition: {
      script:
        getScriptValue(EditorScriptLocation.LOCATION_GUARD_CONDITION) ||
        getLowCodeEditorText(EditorScriptLocation.LOCATION_GUARD_CONDITION, DataFieldType.BOOLEAN, `return ${getLowCodeEditorDefaultReturnText(DataFieldType.BOOLEAN)}`),
      dataType: BOOLEAN,
    },
    ErrorMessageConfig: {
      script: getScriptValue(EditorScriptLocation.ERROR_MESSAGE) || getLowCodeEditorText(EditorScriptLocation.ERROR_MESSAGE, DataFieldType.TEXT, `return ${getLowCodeEditorDefaultReturnText(DataFieldType.TEXT)}`),
      dataType: TEXT,
    },
  };


  const {
    register,
    handleSubmit,
    setValue,
    setError,
    control,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      guardName: selectedAction?.guardName,
      tempId: generateTempId(selectedAction, selectedAutomation, getActionNameForTempId(selectedAction), "guard"),
      validationType: selectedAction?.validationType,
      latitude:
        selectedAction?.latitude?.type === "script"
          ? "Smart Script"
          : selectedAction?.latitude?.value,
      longitude:
        selectedAction?.longitude?.type === "script"
          ? "Smart Script"
          : selectedAction?.longitude?.value,
      customerLatitude:
        selectedAction?.customerLatitude?.type === "script"
          ? "Smart Script"
          : selectedAction?.customerLatitude?.value,
      customerLongitude:
        selectedAction?.customerLongitude?.type === "script"
          ? "Smart Script"
          : selectedAction?.customerLongitude?.value,
      expectedDistance:
        selectedAction?.expectedDistance?.type === "script"
          ? "Smart Script"
          : selectedAction?.expectedDistance?.value,
      isActive: selectedAction?.isActive ?? true,
      errorMessage:
        selectedAction?.errorMessage?.type === "script"
          ? "Smart Script"
          : selectedAction?.errorMessage?.value,
    },
  });

  useEffect(() => {
    recordPageVisit(PageCategoryEnum.automationBuilder, RudderStackAutomationBuilderEvents.locationConditionOnLoad);
  }, [])

  // This function gets called when the user click on save button in Modal.
  // If the user is setting switch in choice node, updateConditionSwitch is called
  // else if user is setting up normal guard, updateGuard will be called.
  const onFormSubmit = (data: any) => {
    const updatedLatitude = selectedAction?.latitude;
    const updatedLongitude = selectedAction?.longitude;
    const updatedCustomerLatitude = selectedAction?.customerLatitude;
    const updatedCustomerLongitude = selectedAction?.customerLongitude;
    const updatedExpectedDistance = selectedAction?.expectedDistance;
    const updatedErrorMessage = selectedAction?.errorMessage;
    const updatedGuardName = selectedAction?.guardName;
    let tempActionId: string = selectedAction?.tempId || data?.tempId;
    if (!isEditing) {
      tempActionId = currTempIdVal.length === 0 ? (selectedAction?.actionId || selectedAction?.guardId || selectedAction?.conditionId) : currTempIdVal;
      dispatch(setSelectedAction({ ...selectedAction, tempId: currTempIdVal }));
    }


    let isTempIdChanged = tempIdChanged;
    if (tempActionId === (selectedAction?.actionId || selectedAction?.guardId || selectedAction?.conditionId)) {
      setTempIdChanged(false);
      isTempIdChanged = false;
    }

    if (isTempIdChanged && !isTempIdUnique(tempActionId, selectedAutomation)) {
      setError("tempId", {
        type: "manual",
        message: "This ID already exists. Please choose a different one.",
      });
      return;
    }

    if (!updatedGuardName) {
      setError("guardName", {
        type: "manual",
        message: "This is a required field",
      });
      return;
    }

    // error check
    if (!updatedLatitude?.value) {
      setError("latitude", {
        type: "manual",
        message: "This is a required field",
      });
      return;
    }

    if (!updatedLongitude?.value) {
      setError("longitude", {
        type: "manual",
        message: "This is a required field",
      });
      return;
    }

    if (!updatedCustomerLatitude?.value) {
      setError("customerLatitude", {
        type: "manual",
        message: "This is a required field",
      });
      return;
    }

    if (!updatedCustomerLongitude?.value) {
      setError("customerLongitude", {
        type: "manual",
        message: "This is a required field",
      });
      return;
    }

    if (!updatedExpectedDistance?.value) {
      setError("expectedDistance", {
        type: "manual",
        message: "This is a required field",
      });
      return;
    }

    if (!decisionSwitchAutomation) {
      if (!updatedErrorMessage?.value) {
        setError("errorMessage", {
          type: "manual",
          message: "This is a required field",
        });
        return;
      }

      if (updatedErrorMessage?.value?.length < 3) {
        setError("errorMessage", {
          type: "manual",
          message: "Error message must have more than 3 characters",
        });
        return;
      }
    }

    let payload = {
      ...data,
      latitude: updatedLatitude,
      longitude: updatedLongitude,
      customerLatitude: updatedCustomerLatitude,
      customerLongitude: updatedCustomerLongitude,
      expectedDistance: updatedExpectedDistance,
      guardType: selectedAction.guardType,
      guardId: tempActionId,
      tempId: tempActionId,
      transitionId: selectedTransition?.id,
      errorMessage: updatedErrorMessage,
      isActive: selectedAction?.isActive ?? true,
      [EditorScriptLocation.LOCATION_GUARD_CONDITION]:
        selectedAction[EditorScriptLocation.LOCATION_GUARD_CONDITION] ||
        undefined,
    };

    recordRSEvent(RudderStackAutomationBuilderEvents.locationConditionSaveClick, {
      context: RudderStackAutomationBuilderContext.automationBuilderCondition,
      automationId: selectedAutomation.id,
      guardType: selectedAction.guardType,
      guardId: selectedAction.guardId
    })

    saveGuard({
      payload,
      location: ValidLocation.AUTOMATION,
      dispatch,
      selectedAction
    });

    dispatch(setSelectedAction(null));
  };

  const handleLatitudeChange = (e: any) => {
    dispatch(
      setSelectedAction({
        ...selectedAction,
        hasChanged: true,
        latitude: {
          type: getInputType(e.target.value),
          value: removeBraces(e.target.value),
          dataType: NUMBER,
        },
      })
    );
  };

  const handleLongitudeChange = (e: any) => {
    dispatch(
      setSelectedAction({
        ...selectedAction,
        hasChanged: true,
        longitude: {
          type: getInputType(e.target.value),
          value: removeBraces(e.target.value),
          dataType: NUMBER,
        },
      })
    );
  };


  const handleCustomerLatitudeChange = (e: any) => {
    dispatch(
      setSelectedAction({
        ...selectedAction,
        hasChanged: true,
        customerLatitude: {
          type: getInputType(e.target.value),
          value: removeBraces(e.target.value),
          dataType: NUMBER,
        },
      })
    );
  };

  const handleCustomerLongitudeChange = (e: any) => {
    dispatch(
      setSelectedAction({
        ...selectedAction,
        hasChanged: true,
        customerLongitude: {
          type: getInputType(e.target.value),
          value: removeBraces(e.target.value),
          dataType: NUMBER,
        },
      })
    );
  };

  const handleExpectedDistanceChange = (e: any) => {
    dispatch(
      setSelectedAction({
        ...selectedAction,
        hasChanged: true,
        expectedDistance: {
          type: getInputType(e.target.value),
          value: removeBraces(e.target.value),
          dataType: NUMBER,
        },
      })
    );
  };

  const handleErrorMessageChange = (e: any) => {
    dispatch(
      setSelectedAction({
        ...selectedAction,
        hasChanged: true,
        [EditorScriptLocation.ERROR_MESSAGE]: {
          type: getInputType(e.target.value),
          value: removeBraces(e.target.value),
          dataType: TEXT,
        },
      })
    );
  };

  const handleTempActionValChange = (name, value) => {
    setCurrTempIdVal(value);
  }

  const handleEditClick = () => {
    setIsEditing(true);
    setTempIdChanged(true);
  };

  const handleTickClick = () => {
    setIsEditing(false);
  };

  const setTempIdError = (error) => {
    if (error) {
      setError("tempId", { type: "manual", message: error });
    } else {
      clearErrors("tempId");
    }
  };



  return (
    <>
      {/* Name textfield */}
      <form onSubmit={stopPropagate(handleSubmit(onFormSubmit))} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div>
          <HeadingWrapper>
            <Heading> Location Check </Heading>

            <IsActive control={control} closeOnClick={clearRightSide} />

          </HeadingWrapper>
          <Label> Name </Label>
          <ModalSmartInputTextField
            value={selectedAction?.guardName}
            placeholder="Name this Location Check"
            dataType={DataFieldType.TEXT}
            register={register}
            control={control}
            name="guardName"
            errors={errors}
          />

          <InputTempId
            label="Validation Id"
            onChange={handleTempActionValChange}
            register={register}
            requiredErrorMessage="Validation Id is required"
            errors={errors}
            isReadOnly={!isEditing}
            handleTickClick={handleTickClick}
            handleEditClick={handleEditClick}
            getValues={getValues}
            setTempIdError={setTempIdError}
            isEditing={isEditing}
            name="tempId"
          />

          <ExecuteCondition
            showExpressionActive={selectedAction[EditorScriptLocation.LOCATION_GUARD_CONDITION]?.value ? true : false}
            conditionLabel="Write Conditional Expression (Optional)"
            handleClick={() => {
              dispatch(setScriptEditorPayload(actionConditionPayload));
              openJSEditorLayout({
                config: locationCheckScriptConfig.LocationGuardCondition,
                editorLocation: EditorScriptLocation.LOCATION_GUARD_CONDITION,
              })
            }
            }
          />


          <Label className='mt-2'> Maximum Allowed Distance Difference (in meters)</Label>
          <ModalSmartInputTextField
            type={selectedAction?.expectedDistance?.type || "number"}
            dataType={DataFieldType.NUMBER}
            control={control}
            step="any"
            errors={errors}
            onChangeFunc={handleExpectedDistanceChange}
            isSmartField={true}
            apiBodyPayload={actionConditionPayload}
            value={selectedAction?.expectedDistance?.value}
            register={register}
            openJSEditor={openJSEditorLayout}
            name="expectedDistance"
            config={locationCheckScriptConfig.ExpectedDistanceConfig}
            editorLocation={EditorScriptLocation.LOCATION_CHECK_EXPECTED_DISTANCE}
            placeholder="Enter the maximum difference in Location (in Meters)"
          />

          {!decisionSwitchAutomation &&
            <ErrorMessageInput
              label={'Error Message to be shown be if the condition fails'}
              onChangeFunc={handleErrorMessageChange}
              register={register}
              errors={errors}
              apiBodyPayload={actionConditionPayload}
              openJSEditorLayout={openJSEditorLayout}
              config={locationCheckScriptConfig.ErrorMessageConfig}
            />
          }


          <CoordinatesWrapper >

            <LocationDivider label={'Location 1'} />

            <FlexDiv style={{ marginTop: '8px' }}>
              <div>
                <Label> Latitude </Label>
                <ModalSmartInputTextField
                  type={selectedAction?.latitude?.type || "number"}
                  dataType={DataFieldType.NUMBER}
                  isSmartField={true}
                  control={control}
                  step="any"
                  value={selectedAction?.latitude?.value}
                  placeholder="Enter the Latitude"
                  register={register}
                  avoidOverflow={true}
                  name="latitude"
                  errors={errors}
                  apiBodyPayload={actionConditionPayload}
                  onChangeFunc={handleLatitudeChange}
                  config={locationCheckScriptConfig.UserLatitudeConfig}
                  editorLocation={EditorScriptLocation.LOCATION_CHECK_USER_LATITUDE}
                  openJSEditor={openJSEditorLayout}
                />
              </div>
              <div>
                <Label> Longitude </Label>
                <ModalSmartInputTextField
                  dataType={DataFieldType.NUMBER}
                  control={control}
                  type={selectedAction?.longitude?.type || "number"}
                  isSmartField={true}
                  onChangeFunc={handleLongitudeChange}
                  step="any"
                  value={selectedAction?.longitude?.value}
                  placeholder="Enter the Longitude"
                  avoidOverflow={true}
                  errors={errors}
                  apiBodyPayload={actionConditionPayload}
                  register={register}
                  config={locationCheckScriptConfig.UserLongitudeConfig}
                  editorLocation={
                    EditorScriptLocation.LOCATION_CHECK_USER_LONGITUDE
                  }
                  openJSEditor={openJSEditorLayout}
                  name="longitude"
                />
              </div>
            </FlexDiv>
          </CoordinatesWrapper>

          <CoordinatesWrapper >

            <LocationDivider label={'Location 2'} />


            <FlexDiv style={{ marginTop: '8px' }}>
              <div>
                <Label> Latitude </Label>
                <ModalSmartInputTextField
                  dataType={DataFieldType.NUMBER}
                  isSmartField={true}
                  control={control}
                  onChangeFunc={handleCustomerLatitudeChange}
                  type={selectedAction?.customerLatitude?.type || "number"}
                  step="any"
                  errors={errors}
                  value={selectedAction?.customerLatitude?.value}
                  placeholder="Enter the Latitude"
                  openJSEditor={openJSEditorLayout}
                  apiBodyPayload={actionConditionPayload}
                  config={locationCheckScriptConfig.CustomerLatitudeConfig}
                  editorLocation={
                    EditorScriptLocation.LOCATION_CHECK_CUSTOMER_LATITUDE
                  }
                  avoidOverflow={true}
                  register={register}
                  name="customerLatitude"
                />

              </div>

              <div>
                <Label> Longitude </Label>
                <ModalSmartInputTextField
                  dataType={DataFieldType.NUMBER}
                  type={selectedAction?.customerLongitude?.type || "number"}
                  isSmartField={true}
                  onChangeFunc={handleCustomerLongitudeChange}
                  step="any"
                  control={control}
                  value={selectedAction?.customerLongitude?.value}
                  register={register}
                  errors={errors}
                  config={locationCheckScriptConfig.CustomerLongitudeConfig}
                  name="customerLongitude"
                  apiBodyPayload={actionConditionPayload}
                  avoidOverflow={true}
                  openJSEditor={openJSEditorLayout}
                  placeholder="Enter the Longitude"
                  editorLocation={
                    EditorScriptLocation.LOCATION_CHECK_CUSTOMER_LONGITUDE
                  }
                />

              </div>
            </FlexDiv>
          </CoordinatesWrapper>

        </div>


      </form>
      {scriptEditorOpen && <ZJSEditorLayout />}
      {dataPicker?.isOpened && <DatafieldSelector setValue={setValue} />}
    </>
  );
};

export default LocationCheck;
