import { Button, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import notification from '../../../../../notifications/notifications';
import { TaskService } from '../../../services/TaskService';
import UserSelection from '../../../UserSelection';


function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    borderRadius: '8px',
    border: 'none',
    width: '30%',
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: 450,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export const ModifyTeamUser = ({ taskId, userId, teamId, onClose, callBack }) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [selectedUser, setSelectedUser] = React.useState(userId);
  const [selectedTeam, setSelectedTeam] = React.useState(teamId);

  const handleTeamUserChange = (team, user) => {
    setSelectedUser(user);
    setSelectedTeam(team);
  };

  useEffect(() => {
    setSelectedUser(userId);
    setSelectedTeam(teamId);
  }, [taskId, userId, teamId, onClose]);

  function getMessageContent(msgList) {
    return (
      <div>
        <ul>
          {Object.values(msgList).map((msg) => {
            return <li>{msg}</li>;
          })}
        </ul>
      </div>
    );
  }

  const handleSubmit = (e) => {
    let taskIds = [];
    if (taskId instanceof Array) {
      taskIds = taskId;
    } else {
      taskIds.push(taskId);
    }

    async function callTeamUpdate(taskIds) {
      let successList = [];
      let failureList = [];
      for (let i = 0; i < taskIds.length; i++) {
        let tId = taskIds[i];
        try {
          const res = await TaskService.updateTeamUser(tId, selectedUser, [selectedTeam]);
          if (res && res.code !== '200') {
            failureList.push('Task Id : ' + tId + ' - ' + res.message);
          } else if (res) {
            successList.push('Task Id : ' + tId + ' - ' + res.message);
          }
        } catch (err) {
          failureList.push('Task Id : ' + tId + ' - Error updating task');
          console.error(err);
        }
      }

      if (successList.length > 0 && failureList.length > 0) {
        notification('warning', getMessageContent(successList.concat(failureList)));
      } else if (successList.length > 0) {
        notification('success', getMessageContent(successList));
      } else if (failureList.length > 0) {
        notification('error', getMessageContent(failureList));
      }

      if (callBack) {
        callBack();
      }
    }
    onClose();
    callTeamUpdate(taskIds);
  };

  return (
    <Modal open={true} onClose={() => onClose(true)}>
      <ValidatorForm onSubmit={(e) => handleSubmit(e)} onError={(errors) => console.log(errors)}>
        <div style={modalStyle} className={classes.paper}>
          <div className='flex flex-col justify-start	px-1 md:px-2 pt-4'>
            <UserSelection id={taskId} driver={selectedUser} teamIds={[selectedTeam]} onChange={handleTeamUserChange} />
          </div>
          <div className='pt-4 text-right'>
            <Button type='submit' variant='contained' color='primary' style={{ margin: 8 }}>
              Save
            </Button>
            <Button variant='contained' onClick={() => onClose(true)} color='secondary' style={{ margin: 8 }}>
              Cancel
            </Button>
          </div>
        </div>
      </ValidatorForm>
    </Modal>
  );
};
