import { ZButton } from "views/commonComponents/commonComponents.styles";
import { Label, Input } from "../DataField.styles";
import { numberDefaultValueErrorSchema } from "../DataFieldErrorSchema";
import ErrorMessage from "../ErrorMessage";
import useEntity from "views/entities/hooks/useEntity";
import { setScriptEditorPayload } from "lowcode/state/scriptOpenState";
import usePayload from "views/automationBuilder/hooks/usePayload";
import { useJSEditor } from "lowcode/hooks/useJSEditor";
import { EditorScriptLocation } from "lowcode/state/types/types";
import { useDispatch } from "react-redux";
import { ZJSEditorLayout } from "lowcode/ZJSEditorLayout";
import useWorkFlow from "views/workflows/hooks/useWorkFlow";
import {
  getLowCodeEditorDefaultReturnText,
  getLowCodeEditorText,
} from "utils/Utils";
import { TEXT } from "constants/CommonConstants";

type NumberTypeProp = {
  register: any;
  errors?: any;
  defaultValueName?: string;
};

const ComputedColumn = () => {
  const { actionConditionPayload } = usePayload();
  const dispatch = useDispatch();
  const { getScriptValueForEntity, activeEditColumn } = useEntity();

  const editorConfig = {
    script:
      getScriptValueForEntity(EditorScriptLocation.ENTITY_COMPUTED_COLUMN) ||
      getLowCodeEditorText(
        EditorScriptLocation.PUSH_NOTIFICATION_MESSAGE,
        "TEXT",
        `return ${getLowCodeEditorDefaultReturnText("TEXT")}`
      ),
    dataType: TEXT,
  };

  const { openJSEditorLayout, scriptEditorOpen, dataType } = useJSEditor();

  return (
    <>
      {activeEditColumn?.type && (
        <>
          <ZButton
            secondary
            fullWidth
            variant="contained"
            className="mt-2"
            onClick={(e) => {
              dispatch(setScriptEditorPayload(actionConditionPayload));
              openJSEditorLayout({
                config: editorConfig,
                editorLocation: EditorScriptLocation.ENTITY_COMPUTED_COLUMN,
              });
            }}
          >
            {" "}
            Write Script{" "}
          </ZButton>

          {scriptEditorOpen && <ZJSEditorLayout />}
        </>
      )}
    </>
  );
};

export default ComputedColumn;
