import { useEffect, useState } from "react";
import styled from "styled-components";
import chronosImg from "assests/chronos.svg";
import actionableInsights from "assests/actionableInsights.svg";
import analyzeData from "assests/analyzeData.svg";
import buildDashboards from "assests/buildDashboards.svg"; 
import createChart from "assests/createChart.svg";
import Typography from '@mui/material/Typography';
import { Tooltip } from "@mui/material";
import { AccountService } from "providers/data/services/AccountService";
import jwt_decode from "jwt-decode";
import { CronService } from "providers/data/services/CronService";
import ChronosInfoSection, { IDirection } from "./ChronosInfoSection";
import { getConfig as Config } from "../../config/envConfig/Config";
import { isUserHasAdminRole } from "providers/auth/authUtils";


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Introducing = styled.div`
  padding-top: 24px;
  color: var(--primary-700, #3054b9);
  text-align: center;
  /* Text md/Semibold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
`;

const PageTitle = styled.h1`
  font-size: 32px;
  font-weight: 700;
  padding-top: 4px;
  padding-bottom: 8px;
`;

const PageDescription = styled.p`
  color: var(--gray-600, #475467);
  text-align: center;
  /* Text xl/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding-bottom: 24px;
  line-height: 30px; /* 150% */
`;

const Grid = styled.div`
  display: grid;
  grid-template-areas:
    "section1 section3"
    "section4 section2";
  gap: 1em;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    grid-template-areas:
      "section1"
      
      "section3"
      "section4"
      "section2";
  }
`;



const CardButton = styled.button`
  border: 1px solid #ccc; // thin gray border
  padding: 10px 20px;
  color: #ffffff; // adjust text color as needed
  background-color: ${(props) => props.theme.color.primary};
  border-radius: 5px;
  cursor: pointer;
`;

const Chronos = () => {
  const [isAnalyticsEnabled, setIssAnalyticsEnabled] = useState(false);
  const [isSyncRunning, setIsSyncRunning] = useState(false);
  const [isUserHasAdminPermission, setIsUserHasAdminPermission] = useState<boolean>();
  const chronosDomainURL = `${Config(
    "services.zorpBiSvc.dashboardUrl"
  )}`;

  useEffect(() => {
    const at = localStorage.getItem("at");
    const decoded: any = !!at?.length ? jwt_decode(at) : null;
    AccountService.getAccount(decoded.accountId).then((res) => {
      setIssAnalyticsEnabled(res?.data?.accountConfig?.analytics?.enabled);
    });
  }, []);

  useEffect(()=>{
    const checkAdminRole:boolean| any = isUserHasAdminRole();
    setIsUserHasAdminPermission(checkAdminRole);
  },[])

  const activateAnalytics = () => {
    const at = localStorage.getItem("at");
    const decoded: any = !!at?.length ? jwt_decode(at) : null;
    AccountService.enableAnalytics(decoded.accountId).then((res) => {
      setIsSyncRunning(true);
      const payload = {
        "cronId": "metadata_sync"
      };
      CronService.executeCron(payload).then((res) => {
        setIsSyncRunning(false);
        setIssAnalyticsEnabled(true);
        window.open(`${chronosDomainURL}/login/?username=${at}`, "_blank")
      });
    });
  };

  return (
    <Container>
      <Introducing>Introducing</Introducing>
      <PageTitle>Chronos</PageTitle>
      <PageDescription>
        Powerful, self-serve analytics toolkit to help you analyse and gather
        actionable <br /> insights from your business data{" "}
      </PageDescription>
      {isSyncRunning ? "Syncing your data. Please wait..." : ""}
      {!isAnalyticsEnabled && !isSyncRunning ? (
        <Tooltip disableHoverListener={isUserHasAdminPermission} title={<Typography>This feature is only accessible to users with admin privileges. If you require admin access, please contact your administrator</Typography>}>
        <CardButton  
        disabled={!isUserHasAdminPermission}
        onClick={activateAnalytics}>
          {" "}
          Activate Chronos &#8594;{" "}
        </CardButton>
        </Tooltip>
      ) : (
        <>
        <Tooltip disableHoverListener={isUserHasAdminPermission} title={<Typography>This feature is only accessible to users with admin privileges. If you require admin access, please contact your administrator</Typography>}>
        <CardButton
        disabled={!isUserHasAdminPermission}
        onClick={() => {
            const at = localStorage.getItem("at");
            window.open(`${chronosDomainURL}/login/?username=${at}`, "_blank")
          }}
        >
          {" "}
          Go to Chronos &#8594;{" "}
        </CardButton>
        </Tooltip>
        </>
      )}
      <Grid style={{marginTop:'62px'}}>

        <ChronosInfoSection icon={analyzeData} title='Analyze your data' content=' Visualize your data that is easier to understand and interpret. You
            can create charts and dashboards to track your progress, identify
            trends and make better decisions' section="section1" direction={IDirection.LEFT}/>

        <ChronosInfoSection icon={createChart} title='Create Charts' content='Choose from a variety of charts to visualize your data. Whether you
            need a simple bar chart or a complex heat map, we have the right
            tools and visualizations for you. You can also customize the colors,
            fonts, and other aspects of your charts to make them look exactly
            how you want them to look.' section="section2" direction={IDirection.RIGHT}/>

        <ChronosInfoSection icon={buildDashboards} title='Build Dashboards' content=' Build dashboards that are tailored to your specific needs. You can
            add charts, tables, and other visualizations to your dashboard to
            track the most important metrics for your business. These dashboards
            can be shared with your team members can be visually appealing and
            informative.' section="section3" direction={IDirection.LEFT}/>
     
        <ChronosInfoSection icon={actionableInsights} title='Gather Actionable Insights' content='  Drill down into your data to find answers to your questions. Find
            hidden patterns and trends in your data that can help you make
            better decisions. You can use these insights to improve your
            business processes and increase your revenue.' section="section4" direction={IDirection.RIGHT}/>

      </Grid>
    </Container>
  );
}

export default Chronos;