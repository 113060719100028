import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import jwt_decode from 'jwt-decode';
import "./payment.css";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import SetupForm from "./SetupForm";
import { Box, Card, CardActions, CardContent, Typography } from "@mui/material";
import SetupStatus from "./SetupStatus";
import { useHistory } from 'react-router-dom';
import { getAccountId, getEnv } from 'utils/CommonUtils';
import { getConfig as Config } from '../../../config/envConfig/Config';
import { BffService } from "providers/data/services/BffService";

import {
  recordEvent,
} from "../../../config/Rudderstack";
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(Config('stripePublishableKey'));

const PaymentManagement = () => {
  const [clientSecret, setClientSecret] = useState("");
  const [customerId, setCustomerId] = useState(null);
  const [paymentEnabled, setPaymentEnabled] = useState(false);
  const [paymentMethodAdded, setPaymentMethodAdded] = useState(false);
  const [paymentMethodCards, setPaymentMethodCards] = useState(null);
  const history = useHistory();
  const PaymentMethodsDisplay = () => {
    return (
      <section>
        {paymentMethodCards}
      </section>
    );
  };

  const notifyPaymentSetupActivity = () => {

    let jwtDecoded = jwt_decode(localStorage.getItem("at"));
    recordEvent("Payment Method Detach", {
      env : getEnv(),
      user: `${jwtDecoded.name} (${jwtDecoded.userId})`,
      accountId: jwtDecoded.accountId,
      timestamp: new Date().toISOString(),
    });
  }

  useEffect(() => {
    
    const accountId = getAccountId();
    const decoded = jwt_decode(localStorage.getItem('at'));

    if (getEnv() === 'dev' && decoded.userId !== 'b1vdwp') { // user of test account id (x7tnsa) in dev
      return;
    }   

    const togaiCustomerId = decoded.teamIds[0]; // sending team id for now. once they have single invocing neeed to send zorp account id
    
    BffService.getStripeCustomerIdFromTogai(togaiCustomerId)
        .then(axiosResponse => {
            console.log('Stripe customer id from togai fetch succesfull');
            
            if (axiosResponse?.data?.value) {
                let custId = axiosResponse?.data?.value;
                setPaymentEnabled(true);
                setCustomerId(custId);

                BffService.getPaymentMethods(custId)
                    .then(axiosResponse => {
                        console.log('Payment methods fetch succesfull');
                        if (axiosResponse?.data?.data) {
                            let validPaymentMethods = axiosResponse?.data?.data?.filter(entry => entry.card.country === 'US');
                            let paymentMethodsCards = [];
                            validPaymentMethods.forEach(paymentMethod => {  
                                paymentMethodsCards.push(<ZCard paymentMethod={paymentMethod}/>)
                            });
                            setPaymentMethodCards(paymentMethodsCards);
                            setPaymentMethodAdded(validPaymentMethods && validPaymentMethods.length > 0);
                        }
                    }).catch(error => {
                    });
                
            } else {
                console.log('Stripe customer id from togai is empty!!!');
                setPaymentEnabled(false);
                return;
            }
        }).catch(error => {
            console.log(error);
        });

  }, []);

  const ZCard = ({paymentMethod}) => {
    return (
        <div>
        <Box sx={{ minWidth: 275 }}>
        <Card variant="outlined" sx={{ width: 275 }}>
            <React.Fragment>
            <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            **** {paymentMethod.card.last4} {paymentMethod.card.brand}
            <br/> Country {paymentMethod.card.country}
            </Typography>
            </CardContent>
            <CardActions>
            <ZButton variant="contained" size="small" onClick={() => {handleDetachPaymentMethodClick(paymentMethod)}}>Detach</ZButton>
            </CardActions>
            </React.Fragment>
        </Card>
        </Box>
        <br/>
        </div>
    );
};


  const handleManageClick = async (customerId:string) => {
    // let account = await getAccountDetails();
    // let customerId = account?.accountConfig?.paymentConfig?.stripe_customer_id;

     // Call the function to create the customer portal session
    // StripePaymentService.createPaymentIntent(customerId)
    //   .then(axiosResponse => {
    //       console.log('Payment intent succesfull');
    //       let secret = axiosResponse?.data?.client_secret;
    //       setClientSecret(secret);
    //   }).catch(error => {
    //   });

    BffService.setupIntent(customerId)
      .then(axiosResponse => {
          console.log('Setup intent succesfull');
          let secret = axiosResponse?.data?.client_secret;
          setClientSecret(secret);
      }).catch(error => {
    });
  }

  const handleDetachPaymentMethodClick = async (paymentMethod) => {

    BffService.detachPaymentMethod(paymentMethod.id)
      .then(axiosResponse => {
          notifyPaymentSetupActivity();
          console.log('Detach payment method succesfull');
          history.go(0);
      }).catch(error => {
    });
  }

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="App">
      {paymentEnabled ? (
      paymentMethodAdded ?
        (
            <div>
                 <Elements stripe={stripePromise}>
                    <SetupStatus/>
                </Elements>
                <br/>
                <label>Available Payment Methods</label>
                <PaymentMethodsDisplay/>
            </div>
        )
        :  clientSecret ?
            (
                <Elements options={options} stripe={stripePromise}>
                {/* <CheckoutForm /> */}
                    <SetupForm />
                </Elements>
            )
            :
            (
                <div>
                  <label>Currently we support only US origin credit card payment method</label>
                <br/><br/>
                <ZButton variant="contained" onClick={() => {handleManageClick(customerId)}}>
                    Add Payment Method
                </ZButton>
                </div>
            )
      ) : "Payment configuration not enabled for your account, Please contact support@zorp.one"
      
    }
    </div>
  );
}

export default PaymentManagement;