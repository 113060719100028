import React, { useEffect, useState } from "react";
import { Show, ShowController, useListContext } from "react-admin";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  initTaskShowMetaData,
  setTaskRenderDetails,
} from "render-engine/reducers/show/TaskShowReducer";
import { PageCategoryEnum, recordPageVisit } from "../../config/Rudderstack";
import notification from "../../notifications/notifications";
import { TaskRenderService } from "../../providers/data/services/TaskRenderService";
import { ITaskTypeWebRenderModel } from "../models/ITaskTypeWebRenderModel";
import { Render } from "../Render";
import { getComponentParams } from "../RenderUtils";
import ShowTaskActions from "./ShowTaskActions";
import { resetListInfo } from "render-engine/reducers/listInfo";
import { cancelTaskEdit } from "render-engine/reducers/edit";
import { cleanTaskCreation } from "render-engine/reducers/create";
import { TaskFormBody } from "render-engine/render.styles";
import TransitionStepper from "logs/ZStepper";
import ZStepper from "logs/ZStepper";
import Logs from "logs";
import { get, isEmpty } from "lodash";
import LoadingScreen from "views/LoadingScreen/LoadingScreen";
import { RenderV2 } from "render-engine/RenderV2";
import { convertDateToReadableFormat } from "utils/CommonUtils";
import useTaskShow from "views/workflows/hooks/useTaskShow";
import ShowRecordActions from "./ShowRecordActions";
import { useParams } from "react-router-dom";

export const ShowTask = (props: any) => {
  const { taskType, taskId } = useParams();
  const [renderConfig, setRenderConfig] = useState<ITaskTypeWebRenderModel>();

  const history = useHistory();
  const dispatch = useDispatch();
  const showTaskForm = useForm();
  const [showLogs, setShowLogs] = useState(false);
  const [loading, setLoading] = useState(true);
  const { taskRenderDetails, recordDetails } = useTaskShow();
  const [showRecordLogs, setShowRecordLogs] = useState(true);
  const [hideInfoPanel, setHideInfoPanel] = useState(false);
  const [showSummary, setShowSummary] = useState(false);

  useEffect(() => {
    const preview = localStorage.getItem("isPreview") ?? false;
    if (preview) {
      // Get the parent element with class name "layout"
      const layout = document.querySelector(".layout");

      // Get all elements with class name "js2" that are descendants of the parent element
      const js2Elements = layout?.querySelectorAll(".jss2") || [];
      const raLayout = layout?.querySelectorAll(".RaLayout-appFrame-2") || [];

      // Do something with the "js2Elements" array, for example:
      js2Elements?.forEach((element) => {
        element?.classList.add("preview-styles");
      });

      raLayout?.forEach((element) => {
        element?.classList.add("preview-styles");
      });
    }
  }, []);

  useEffect(() => {
    recordPageVisit(PageCategoryEnum.task, "task_details");
    // dispatch(resetListInfo())
    // Clear edit state if a user comes back to show page
    //TODO: ADD for create task as well
    dispatch(cancelTaskEdit());
    dispatch(cleanTaskCreation());
    setLoading(true);
    // TaskRenderService.fetchRenderConfigForTaskId(taskId, "show", undefined)
    //   .then((res) => {
    //     console.log(res?.data, "renderConfig")
    //     if (res?.code && res?.code.indexOf("200") < 0) {
    //       if (res?.code === "401") {
    //         notification("error", res.message)
    //       } else {
    //         notification("error", res.message)
    //       }
    //     } else if (res?.data == null) {
    //       history.push(`/tasks/${taskId}/show?legacy=true`)
    //     } else {
    //       setRenderConfig(res?.data)
    //       dispatch(setTaskRenderDetails(res?.data))
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("Error", err)
    //   })
    dispatch(initTaskShowMetaData({ taskId }));
    setLoading(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="showTaskParentDiv">
      {loading || isEmpty(taskRenderDetails) ? (
        <LoadingScreen />
      ) : (
        <TaskFormBody>
          <ShowTaskActions
            createdOn={convertDateToReadableFormat(recordDetails?.createdOn)}
            updatedOn={convertDateToReadableFormat(recordDetails?.updatedOn)}
            team={get(
              renderConfig,
              "body.blocks[0].rows[2].columns[0].nodes[0].value.val"
            )}
            toggleComments={() => {}}
            taskTypeDisplayName={recordDetails?.tableTypeName}
            taskType={taskType}
            taskId={taskId}
            showTaskForm={showTaskForm}
            taskStatusName={recordDetails?.taskStatusName}
            resource="task"
          />
          <div
            className={
              !hideInfoPanel && (showRecordLogs || showSummary)
                ? "contentWrapper-show-entity-new"
                : "contentWrapperShowMode-entity"
            }
            style={{ height: "90vh !important" }}
          >
            <RenderV2
              componentParams={getComponentParams(dispatch, showTaskForm)}
              entityCategory={"TASK"}
              entityType={taskType}
              entityId={taskId}
              mode={renderConfig?.mode}
              showRecordLogs={showRecordLogs}
              setShowRecordLogs={setShowRecordLogs}
              hideInfoPanel={hideInfoPanel}
              setHideInfoPanel={setHideInfoPanel}
              showSummary={showSummary}
              setShowSummary={setShowSummary}
            />
          </div>
        </TaskFormBody>
      )}
    </div>
  );
};