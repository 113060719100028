const invalidEventName:string[] = [
    "UPDATE",
    "ASSIGN",
    "UNASSIGN",
    "UPDATE_TEAM",
    "UPDATE_SLOT",
    "SCHEDULE",
    "UNSCHEDULE"
]

export default invalidEventName