// This function here will take the response from APT and
// convert it into an object that can be consumed by the stepper component

/**
 * On each screen where we want to show the logs,
 * Stepper component would be placed and api call for fetching the logs will be made
 * on the parent component
 */

import { IPoint, ISingleTaskLog, IStepper, ITaskLogs, LogType } from "logs/types/types";
import moment from "moment";
import { generateId } from "utils/Utils";
import { TaskService } from "views/tasks/services/TaskService";
import { EntityService } from "providers/data/services/EntityService";
import _ from "lodash";
import RestApiInfo from "logs/RestApiInfo"

export const fetchLogs = async (taskId: string) => {
  try {
    const res = await TaskService.getTaskLogs(taskId);
    if (res) {
      const groupedState = groupSameState(res.data.taskLogs);
      const info = formatData(groupedState);
      // const keys = Object.keys(groupedState)
      // const detailedMap:any = {}
      // keys.forEach((key:string) => {
      //     //@ts-ignore
      //     const stateEventInfo:ISingleTaskLog[] = groupedState[key]
      //     const updatedInfo = convertTaskTransitionToStepperFormat(stateEventInfo)
      //     const newUpdatedInfo = updatedInfo.map((info:IStepper) => info.points )
      //     detailedMap[key] = updatedInfo
      // })
      // console.log(">>>> detailed info",detailedMap)
      // const info = convertTaskTransitionToStepperFormat(res.data.taskLogs)
      return info;
    }
  } catch (error) {
    console.log(error);
  }
};

export const fetchRecordLogs = async (RecordId: string, tabletype: string) => {
  try {
    const res = await EntityService.getRecordLogs(RecordId, tabletype);
    if (res) {
      return res?.data.recordHistory;
    } else {
      return []
    }
  } catch (error) {
    console.log(error);
    return []
  }
};

const getEventName = (data: ISingleTaskLog) => {
  if (data.logType === LogType.REST_API) {
    return "ACTION"
  }
  else if (data.logType === LogType.REST_API_GUARDS) {
    if (data.guardStatus) {
      return "GUARD_PASSED"
    } else {
      return "GUARD_FAILED"
    }
  } else {
    return data.event
  }
}

/**
 *
 * @param response
 * @returns this will be used for linear stepper in which no grouping is needed
 * for ex: user, team etc
 */
export const convertTaskTransitionToStepperFormat = (
  response: ISingleTaskLog[]
): IStepper[] => {
  console.log(response);
  const detailedInfo: IStepper[] = [];
  response.forEach((taskLog) => {
    const {
      sourceStateName,
      targetStateName,
      channel,
      mode,
      updatedBy,
      createdOn,
      event,
    } = taskLog;
    const { name, phone, teams, role, location } = updatedBy;

    let points: IPoint[] = [];
    points.push({
      label: `<span class="special-word"> ${event} </span> trigger occured`,
      createdOn: createdOn,
      channel: channel,
      event: event,
    });
    points.push({
      label: `Task moved from ${sourceStateName} to ${targetStateName}`,
      createdOn: createdOn,
      channel: channel,
      event: event,
    });

    points.push({
      label: `Task was updated by ${name}`,
      createdOn: createdOn,
      channel: channel,
      event: event,
    });

    const detailed: any = {};
    detailed["Updated By"] = name;
    detailed["Created On"] = formatDate(createdOn);
    detailed["Channel"] = channel;
    detailed["Mode"] = mode;
    detailed["Event"] = event;
    detailed["Source State"] = sourceStateName;
    detailed["Target State"] = targetStateName;

    const thisInfo = {
      label: `${event}`,
      description: `${sourceStateName}`,
      detailedInfo: detailed,
      createdOn: createdOn,
      subDescription: `${name}`,
      points: points,
    };

    detailedInfo.push(thisInfo);
  });

  return detailedInfo;
};

export const convertTaskTransitionGroupToStepperFormat = (response: {
  string: ISingleTaskLog[];
}): IStepper[] => {
  console.log(response);
  const finalInfo: IStepper[] = [];
  const keys = Object.keys(response);
  keys.forEach((key: string) => {
    const detailedInfo: IStepper[] = [];
    let thisInfo: any = {};
    let points: string[] = [];
    thisInfo["label"] = key;
    const detailed: any = {};
    //@ts-ignore
    response[key].forEach((taskLog) => {
      const {
        sourceStateName,
        targetStateName,
        channel,
        mode,
        updatedBy,
        createdOn,
        event,
      } = taskLog;
      const { name, phone, teams, role, location } = updatedBy;

      points.push(
        `<span class="special-word"> ${event} </span> trigger occured`
      );
      // points.push(`Task moved from ${sourceStateName} to ${targetStateName}`)
      // points.push(`Task was updated by ${name}`)

      detailed["Updated By"] = name;
      detailed["Created On"] = formatDate(createdOn);
      detailed["Channel"] = channel;
      detailed["Mode"] = mode;
      detailed["Event"] = event;
      detailed["Source State"] = sourceStateName;
      detailed["Target State"] = targetStateName;

      thisInfo["description"] = `${sourceStateName}`;
      thisInfo["subDescription"] = `${name}`;
      thisInfo["createdOn"] = createdOn;
      thisInfo["points"] = points;
      thisInfo["detailedInfo"] = detailed;

      detailedInfo.push(thisInfo);
    });

    finalInfo.push(...detailedInfo);
  });

  return finalInfo;
};

export const formatDate = (isoString: string) => {
  return moment(isoString).format("Do MMM YYYY HH:mm");
};

const groupSameState = (
  taskLogs: ISingleTaskLog[]
): { string: ISingleTaskLog[] } => {
  let id = generateId(10);
  let currState = "";
  let newMap: any = {};
  newMap["CREATE"] = []
  // find element from taskLogs for which event name is "CREATE"
  taskLogs.forEach(
    (elements: ISingleTaskLog) => {
      return elements.allLogs.forEach((element: ISingleTaskLog) => {
        if (element.event === "CREATE") {
          newMap["CREATE"].push(element)
        }
      })

    }
  );

  // remove the create event from taskLogs
  taskLogs = taskLogs.filter(
    (element: ISingleTaskLog) => element.event !== "CREATE"
  );

  const deleteEvent = taskLogs.find(
    (element: ISingleTaskLog) => element.event === "DELETE"
  );

  // remove the delete event from taskLogs
  taskLogs = taskLogs.filter(
    (element: ISingleTaskLog) => element.event !== "DELETE"
  );

  // remove the UPDATE_TEAM event from taskLogs
  taskLogs = taskLogs.filter(
    (element: ISingleTaskLog) => element.event !== "UPDATE_TEAM"
  );

  taskLogs.forEach((elements: ISingleTaskLog) => {
    elements.allLogs.forEach((element: ISingleTaskLog) => {
      if (currState === "") {
        // this is the start of all the events.
        // get a unique state name.
        let thisStateName = element.sourceStateName + "-" + id;
        // assign this state to currState.
        currState = thisStateName;
        //add this entry in the newMap
        if (newMap[currState]) {
          newMap[currState].push({ ...element });
        } else {
          newMap[currState] = [{ ...element }];
        }
      } else if (element.sourceStateName + "-" + id === currState) {
        newMap[currState].push({ ...element });
      } else {
        // sourceStateName is different from previous state
        // this means we have moved to new state.

        // change id
        id = generateId(10);
        // change currState
        currState = element.sourceStateName + "-" + id;
        // make a new entry in map
        newMap[currState] = [{ ...element }];
      }
    })

  });

  // find element from taskLogs for which event name is "DELETE"

  if (deleteEvent) {
    newMap["DELETE"] = [{ ...deleteEvent }];
  }

  return newMap;
};

const formatData = (groupedData: any) => {
  const result: any = [];
  const keys = Object.keys(groupedData);
  keys.forEach((key) => {
    const info: any = {};
    info["label"] = key.split("-")[0];
    info["points"] = [];
    groupedData[key].forEach((data: ISingleTaskLog) => {
      info["description"] = `${data.event}`;
      info["points"].push({
        label: getMessage(data),
        createdOn: `${data.createdOn}`,
        channel: `${data.channel}`,
        event: getEventName(data),
        showMore: data.logType === LogType.TASK_LOGS ?
          <> </>
          : <RestApiInfo
            actionName={data.actionDisplayName}
            actionType={data.actionName}
            description={data.actionDescription}
            headers={data.body}
            errorMessage={data.errorMessage}
            url={data.url}
            guardStatus={data.guardStatus}
            response={data.response}
            request={
              {
                body: data?.body || "",
                headers: data?.headers || "",
                url: data?.url || "",
              }
            }
          />
      });
      info["createdOn"] = `${data.createdOn}`;
      info["subDescription"] = `${data.updatedBy.name}`;
      info["icon"] = `${data.channel}`;
    });
    result.push(info);
  });

  return result;
};

const getMessage = (data: ISingleTaskLog) => {
  if (data.logType === LogType.TASK_LOGS) {
    switch (data.event) {
      case "CREATE":
        return `Task was <span class="special-word"> CREATED </span> by <span class="special-word-highlight">  ${data.updatedBy.name
          } </span> through ${_.upperCase(data.channel)}`;
      case "ASSIGN":
        return `Task was <span class="special-word"> ASSIGNED </span> to user with userId <span class="special-word-highlight"> ${data.formData.userId
          } </span>  by <span class="special-word-highlight"> ${data.updatedBy.name
          } </span> through ${_.upperCase(data.channel)}`;
      case "UNASSIGN":
        return `Task was <span class="special-word"> UNASSIGNED </span> by <span class="special-word-highlight"> ${data.updatedBy.name
          } </span> through ${_.upperCase(data.channel)}`;
      case "UPDATE_TEAM":
        return ``;
      case "UPDATE":
        return `Task was <span class="special-word"> UPDATED </span> by <span class="special-word-highlight"> ${data.updatedBy.name
          }</span> </span> through ${_.upperCase(data.channel)}`;
      case "SCHEDULE":
        return `Task was <span class="special-word"> SCHEDULED </span> for ${formatDate(
          data.formData.scheduledSlot.from
        )} to ${formatDate(
          data.formData.scheduledSlot.to
        )} by <span class="special-word-highlight"> ${data.updatedBy.name
          }</span> through ${_.upperCase(data.channel)}`;
      case "UNSCHEDULE":
        return `Task was <span class="special-word"> UNSCHEDULED </span> by ${data.updatedBy.name
          } through ${_.upperCase(data.channel)}`;
      case "UPDATE_SLOT":
        return `Task was <span class="special-word"> RESCHEDULED  </span> for ${formatDate(
          data.formData.scheduledSlot.from
        )} to ${formatDate(
          data.formData.scheduledSlot.to
        )} by <span class="special-word-highlight"> ${data.updatedBy.name
          }</span> through ${_.upperCase(data.channel)}`;
      case "DELETE":
        return `Task was <span class="special-word-delete"> DELETED </span> by <span class="special-word-highlight">${data.updatedBy.name
          }</span> through ${_.upperCase(data.channel)}`;
      default:
        return `Task was moved to <span class="special-word"> ${data.targetStateName
          } </span> by <span class="special-word-highlight"> ${data.updatedBy.name
          } </span> using <span class="block-word">${data.event
          }</span>  through ${_.upperCase(data.channel)}`;
    }
  } else {
    return `Action <span class="special-word"> ${data.actionDisplayName
      } </span> by <span class="special-word-highlight">${data.updatedBy.name
      }</span>  was performed through ${_.upperCase(data.channel)}  `
  }

};