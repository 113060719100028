import { ISelectValueType } from "views/monitor/DatasourceModal";

export const defaultDatasourceEdit = '********';

export const cronDescriptionArray: ISelectValueType[] = [
    {
      label: "Every 5 minutes",
      value: "00:05:00.000",
    },
    {
      label: "Every 10 minutes",
      value: "00:10:00.000",
    },
    {
      label: "Every 15 minutes",
      value: "00:15:00.000",
    },
    {
      label: "Every 30 minutes",
      value: "00:30:00.000",
    },
    {
      label: "Every 1 hour",
      value: "01:00:00.000",
    },
    {
      label: "Every 6 hour",
      value: "06:00:00.000",
    },
    {
      label: "Every 12 hour",
      value: "12:00:00.000",
    },
    {
      label: "Every 24 hour",
      value: "24:00:00.000",
    }
  ];

  export const MonitorPageTabValues = [
    { value: 0, path: 'Monitor' },
    { value: 1, path: 'template' }
  ]