import React from "react";
import PropTypes from "prop-types";
import ControlledAccordions from "./components/ControlledAccordions";
import "./styles.css"

const GridItem = React.forwardRef(({
  title,
  data,
  type,
  className,
  style,
  dispatch,
  root,
  children,
  ...rest
}, ref) => {
  const width = parseInt(style.width, 10);
  const height = parseInt(style.height, 10) - 50;
  
  return (
    
    <div ref={ref} className={`grid-item ${className}`} style={style} {...rest}>
      <div className="grid-wrapper-item"><div className="grid-item__title">{children}</div>
      <div className="grid-children"><ControlledAccordions/></div>
      </div>
    </div>
  );
});

GridItem.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  root: PropTypes.string.isRequired,
  children: PropTypes.array,
};

export default GridItem;
