import { RoleService } from "providers/data/services/RoleService";
import React, { useEffect, useState } from "react";
import { UseFormRegister } from "react-hook-form";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import ZInput from "views/commonComponents/ZTextInput";
import { Label } from "views/DataField/DataField.styles";
import useWorkFlow from "../hooks/useWorkFlow";
import { IShowTaskCardArgs } from "./ConfigureWorkflowModal";
import styled from "styled-components"
import {  CircularProgress } from "@mui/material";
import {useDispatch} from "react-redux";
import { setAppTaskCreateConfig } from "../reducers/workflowAction";


type Props = {
  openSettings: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    args: IShowTaskCardArgs
  ) => void;
  register: UseFormRegister<any>;
};

export const RoleContainer = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    max-height: 20vh;
    overflow-y: auto;
    margin-top: 15px;
`

const InputField = styled.input`
    border: 1px solid #e5e5e5;
    padding: 8px;
    border-radius: 4px;
    width: 100%;
`

const SingleRole = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    gap:4px;
`

const AppTaskCreate = ({ openSettings, register }: Props) => {
  const [roles, setRoles] = useState<any[]>([]);
  const { appTaskCreateConfig } = useWorkFlow();
  const dispatch = useDispatch();

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let currValue = [...appTaskCreateConfig.visibleForRoles]
    if(e.target.checked) {
      currValue.push(e.target.value)
    } else {
      currValue = currValue.filter(role => role !== e.target.value)
    }

    dispatch(setAppTaskCreateConfig({
      ...appTaskCreateConfig,
      visibleForRoles: currValue
    }));
  }

  const handleDisplayNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setAppTaskCreateConfig({
      ...appTaskCreateConfig,
      displayName: e.target.value
    }));
  }

  const getAllRoles = async () => {
    try {
      const allRoles = await RoleService.listRoles();
      if (allRoles) {
        setRoles(allRoles.data);
      }
    } catch (error) {
      setRoles([]);
    }
  };

  useEffect(() => {
    getAllRoles();
  }, []);

  return (
    <>
      <Label className="mb-1"> Available for Roles </Label>
      <RoleContainer>
        {
          roles.length === 0 && (
            <>
              <CircularProgress/>
              
            </>
          )
        }
        {roles.map((role, index) => (
          <div key={role.roleId}>
            <SingleRole>
                
                <input
                type="checkbox"
                value={role.roleId}
                key={role.roleId}
                // {...register("appTaskCreateConfig.visibleForRoles")}
                onChange={handleCheckboxChange}
                defaultChecked={appTaskCreateConfig.visibleForRoles.includes(role.roleId)}
                />
                <label> {role.name} </label>
            </SingleRole>
          </div>
        ))}
      </RoleContainer>
      <div className="mb-4"></div>
      {/* <Label className="mb-1"> Display Name </Label>
      <InputField
        name="appTaskCreateConfig.displayName"
        // register={register}
        //@ts-ignore
        onChange={handleDisplayNameChange}
        defaultValue={appTaskCreateConfig.displayName}
        placeholder="Display Name"
      /> */}

      <ZButton
        secondary
        fullWidth
        variant="contained"
        onClick={(e) =>
          openSettings(e, {
            isHistory: true,
            isTaskCreate: true,
            isTaskHistoryScreen: false,
          })
        }
        sx={{ marginTop: "10px" }}
        type="button"
      >
        Task Create Config
      </ZButton>
    </>
  );
};

export default AppTaskCreate;
