const useDeviceType = () => {

    const getDeviceType = () => {
        try {
          const ua = navigator.userAgent;
          if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            return "tablet";
          }
          if (
            /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
              ua
            )
          ) {
            return "mobile";
          }
          return "desktop";
        } catch (error) {
          return "mobile"
        }
      
      };

  return getDeviceType()
}

export default useDeviceType