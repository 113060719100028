import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  metaData: {},
  createdItems: {},
  uiElements: {},
  listData: {}
};

export const ListInfoSlice = createSlice({
  name: "listInfo",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    loadMetaData: (state, action) => {
      const { name, val } = action.payload;
      //@ts-ignore
      state.metaData[name] = val;
    },
    setCreatedItems: (state, action) => {
    //   @ts-ignore
      const { name, data } = action.payload;

      //@ts-ignore
      if (!state.createdItems[name]) {
        //@ts-ignore
        state.createdItems[name] = [];
      }

      // It would be array for already created data and object for newly created one
      if (Array.isArray(data)) {
        data.forEach((singleData: any) => {
            //@ts-ignore
          state.createdItems[name].push(singleData);
        });
      } else {
        //@ts-ignore
        state.createdItems[name].push(data);
        }
    },
    resetListInfo : () => initialState,
    loadUIElements: (state, action) => {
      const { name, val } = action.payload;
      //@ts-ignore
      state.uiElements[name] = val
    },
    loadListData: (state, action) => {
      const { name, val } = action.payload;
      //@ts-ignore
    const valWIthId = val?.map((item,idx) => ({...item, id: idx})) 
      //@ts-ignore
      state.listData[name] = valWIthId
    },
    removeListDataAndUIElements: (state:any, action) => {
      const {name, idx} = action.payload

      // Removing item at particular index
      const newListData = state.listData[name].filter((item:any) => item.id !== idx)
      const newUIElements = state.uiElements[name].filter((item:any, index:any) => index !== idx)
      
      // setting id as ines for the newList and newUiElements
      const listDataWithUpdatedId = newListData.map((item:any, index:number) => ({...item, id:index}))
      // const uiElementsWithUpdatedId = newUIElements.map((item:any, index:number) => ({...item, id:index}))

      state.listData[name] = listDataWithUpdatedId
      state.uiElements[name] = newUIElements
    }
  },
  extraReducers: (builder) => {},
});

export const {
  // setTaskType,
  loadMetaData,
  setCreatedItems,
  resetListInfo,
  loadUIElements,
  loadListData,
  removeListDataAndUIElements
} = ListInfoSlice.actions;

export default ListInfoSlice.reducer;
