import {
  CONTEXT_SWITCHING,
  CONTEXT_SWITCHING_CONFIRMATION,
  DELETE_GUARD,
  DELETE_GUARD_CONFIRMATION,
  DELETE_TRIGGER,
  DELETE_TRIGGER_CONFIRMATION
} from "constants/CommonMessages";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useWorkFlow from "views/workflows/hooks/useWorkFlow";
import DeletionConfirmationDialog from "utils/DeletionConfirmationDialog";
import { generateId } from "utils/Utils";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import { setSelectedAction } from "views/workflows/reducers/workflowAction";
import { ABBlockButton, ABTopTextComponent, ActionContentBlock, ActionParent, ActionTrigger, VerticalAlignWrapper } from "./automationBuilder.styles";
import BlockContent from "./components/BlockContent";
import useAutomation from "./hooks/useAutomation";
import useAction from "views/actions/useAction";
import {
  addTriggerInAutomation,
  deleteGuardInAutomation,
  deleteTriggerInAutomation,
  rearrangeValidationInAutomation,
  setActionPayload,
  setSelectedConfiguration,
  setSelectedTrigger
} from "./reducer/automationReducer";
import { ABMetadataTypeEnum } from "./types/ABType";
import { recordRSEvent } from "utils/CommonUtils";
import { RudderStackAutomationBuilderContext, RudderStackAutomationBuilderEvents } from "constants/RudderStackConstants";
import ActionPaste from "./components/ActionPaste";
import { setCopiedAction } from "views/actions/Action";
import { pasteGuard } from "views/workflows/workflowactions/api/utils/utils";

export enum IChangeContext {
  CHANGE_TRIGGER = 'CHANGE_TRIGGER',
  CHANGE_VALIDATION = 'CHANGE_VALIDATION'
}

const TriggerBlock = () => {
  const { selectedAutomation, otherConfigs, selectedTrigger, trigger, metadataType, clearRightSide } = useAutomation();
  const dispatch = useDispatch();

  const {
    selectedAction
  } = useWorkFlow();
  const { copiedAction } = useAction();

  const [triggerId, setTriggerId] = useState<string>("");
  const [guardId, setGuardId] = useState<string>("");
  const [guardType, setGuardType] = useState<string>("");
  const [openModal, setOpenModal] = useState<IChangeContext | null>();

  // Hide Add Trigger Button, Delete Button, Configure Button for Screen Transition : Pass false in to component to hide
  const hideTriggerButton = otherConfigs?.hideTriggerConfigureButton ?? true;

  // Hide Guard Block in Else switch in Decision Node
  const hideGuardBlockInElseSwitchDecisionNode = !(otherConfigs?.hideGuardBlock);

  const hideTrigger = !otherConfigs?.hideTrigger;

  // prefill trigger data during screen transition and decision node
  const preFillTrigger = otherConfigs?.preFillTrigger;

  useEffect(() => {
    if (preFillTrigger && selectedAutomation?.triggers.length == 0) {
      const newTriggerData = {
        triggerId: generateId(6),
        errors: [],
        event: trigger?.events[0]
      }

      dispatch(
        addTriggerInAutomation({
          automationId: selectedAutomation.id,
          action: newTriggerData,
        })
      );
    }
  }, [preFillTrigger])

  const deleteTrigger = () => {

    if (metadataType == ABMetadataTypeEnum.WORKFLOW_SCREEN_SMART_COMPONENT && selectedTrigger?.triggerId == triggerId) {
      clearRightSide();
    }

    setTriggerId("");
    dispatch(
      deleteTriggerInAutomation({
        triggerId,
      })
    );
  }

  const deleteGuard = () => {

    // if the guard that is opened on right side, and deleting that, this will clear the right side window
    if (selectedAction?.guardId == guardId && selectedAction.guardType == guardType) {
      clearRightSide();
    }

    setGuardId("");
    setGuardType("");

    dispatch(
      deleteGuardInAutomation({
        guardId
      })
    );
  }

  const addAnotherTrigger = () => {
    recordRSEvent(RudderStackAutomationBuilderEvents.addAnotherTriggerBtnClick, {
      context: RudderStackAutomationBuilderContext.automationBuilderAction,
      automationId: selectedAutomation.id,
    })

    // clear the right side if anything is present
    dispatch(setSelectedAction(null));

    // while opening smart component in right side, this will clear the actionDropdown Array, which responsible for recursive dropdown component
    dispatch(setActionPayload([]));

    dispatch(setSelectedTrigger(null));

    dispatch(setSelectedConfiguration({ type: null }));

    setTimeout(() => {
      dispatch(setSelectedConfiguration({ type: "trigger" }));
    }, 10)
  }

  const addNewValidation = () => {
    recordRSEvent(RudderStackAutomationBuilderEvents.addAnotherGuardBtnClick, {
      context: RudderStackAutomationBuilderContext.automationBuilderAction,
      automationId: selectedAutomation.id,
    })

    // clear the right side if anything is present
    dispatch(setSelectedAction(null));

    dispatch(setSelectedTrigger(null));
    dispatch(setSelectedConfiguration({ type: "guard" }))
  }

  return (
    <>
      <ActionParent hoverState={false}>
        {
          hideTrigger &&
          <>
            <ActionTrigger>
              <ABTopTextComponent>Trigger</ABTopTextComponent>
              <ActionPaste context='trigger' label="Paste Trigger" onClick={() => {
                dispatch(addTriggerInAutomation({
                  automationId: selectedAutomation.id,
                  action: { ...copiedAction, triggerId: generateId(6) },
                }));
                // dispatch(setCopiedAction(null));
              }} />
            </ActionTrigger>
            <ActionContentBlock>
              {
                selectedAutomation?.triggers.length == 0 &&
                <BlockContent
                  onDelete={() => {
                  }}
                  payload={{}}
                  rowIndex={0}
                  showArrow={false}
                  showConfigure={true}
                  copyAction={() => {
                  }}
                  addNewBlock={() => {
                    dispatch(setSelectedAction(null));
                    dispatch(setActionPayload([]));
                    dispatch(setSelectedConfiguration({ type: "trigger" }))
                  }}
                  showDelete={true}
                  listLength={0}
                  type="trigger"
                  valueSelected={false}
                  buttonTitle={otherConfigs?.hideTriggerConfigureButton || "Configure Trigger"}
                  placeHolderText="Add a Trigger"
                  name={"one"}
                  errors={[]}
                />
              }

              {selectedAutomation?.triggers.map((trigger: any, index: number) => (
                <VerticalAlignWrapper applyMargin={index == 0 ? false : true} key={index}>
                  {index !== 0 && <p className="pl-4 pr-4 mt-1">OR</p>}
                  <BlockContent
                    onDelete={() => {
                      setTriggerId(trigger.triggerId);
                    }}
                    copyAction={() => {
                      const triggerPayloadCopy = _.cloneDeep(trigger)
                      triggerPayloadCopy.originalId = triggerPayloadCopy.triggerId;
                      triggerPayloadCopy.triggerId = generateId(6);
                      triggerPayloadCopy.event.label = triggerPayloadCopy.event.label + "_Copy"
                      dispatch(setCopiedAction(triggerPayloadCopy))
                      localStorage.setItem("copiedAction", JSON.stringify(triggerPayloadCopy));
                    }}
                    payload={trigger}
                    rowIndex={index}
                    showArrow={false}
                    showConfigure={hideTriggerButton}
                    showDelete={hideTriggerButton}
                    listLength={selectedAutomation?.triggers.length}
                    type="trigger"
                    valueSelected={trigger?.event?.label ? true : false}
                    buttonTitle={otherConfigs?.triggerConfigureBtnName || "Configure Trigger"}
                    placeHolderText="Add a Trigger"
                    name={trigger?.event?.label}
                    errors={trigger?.errors}
                  />
                </VerticalAlignWrapper>
              ))}


              {
                (hideTriggerButton && selectedAutomation?.triggers.length > 0) &&
                <ABBlockButton tertiary={true} variant="contained"
                  style={{ marginTop: '10px' }}
                  onClick={() => {
                    if (selectedAction?.hasChanged == true) {
                      setOpenModal(IChangeContext.CHANGE_TRIGGER);
                    } else {
                      addAnotherTrigger();
                    }
                  }}>
                  Add another Trigger
                </ABBlockButton>
              }

            </ActionContentBlock>
          </>
        }


        {
          hideGuardBlockInElseSwitchDecisionNode &&
          <>
            <ActionTrigger>
              <ABTopTextComponent>Perform These Checks</ABTopTextComponent>
              <ActionPaste context='guard' label="Paste Validations" onClick={() => {
                pasteGuard({ dispatch, payload: { ...copiedAction } });
                // dispatch(setCopiedAction(null));
              }} />
            </ActionTrigger>
            <ActionContentBlock>

              {
                selectedAutomation.guards.length == 0 &&
                <BlockContent
                  onDelete={() => {
                  }}
                  payload={{}}
                  rowIndex={0}
                  copyAction={() => {
                  }}
                  addNewBlock={() => {
                    dispatch(setSelectedAction(null));
                    dispatch(setSelectedTrigger(null));
                    dispatch(setActionPayload([]));
                    dispatch(setSelectedConfiguration({ type: "guard" }))
                  }}
                  showArrow={false}
                  showConfigure={true}
                  showDelete={true}
                  listLength={0}
                  type="guard"
                  valueSelected={false}
                  buttonTitle="Configure Validation"
                  placeHolderText="Add a Validation"
                  name={"one"}
                  errors={[]}
                />
              }

              {selectedAutomation.guards.map((guard: any, index: number) => {
                return (
                  <VerticalAlignWrapper applyMargin={index == 0 ? false : true} key={index}>
                    {index !== 0 && <p className="pl-4 pr-4 mt-1">AND</p>}
                    <BlockContent
                      onDelete={() => {
                        setGuardId(guard.guardId);
                        setGuardType(guard.guardType);
                      }}
                      copyAction={() => {
                        const id = generateId(6);
                        const guardPayloadCopy = {
                          ...guard,
                          originalId: guard.guardId,
                          guardId: id,
                          tempId: id,
                          guardName: guard.guardName + "_Copy"
                        }
                        dispatch(setCopiedAction(guardPayloadCopy))
                        localStorage.setItem("copiedAction", JSON.stringify(guardPayloadCopy));
                      }}
                      payload={guard}
                      rowIndex={index}
                      showArrow={true}
                      rearrangeDown={() => {
                        dispatch(rearrangeValidationInAutomation({
                          guardId: guard.guardId,
                          step: 1,
                          index
                        }))
                      }}
                      rearrangeUp={() => {
                        dispatch(rearrangeValidationInAutomation({
                          guardId: guard.guardId,
                          step: -1,
                          index
                        }))
                      }}
                      listLength={selectedAutomation.guards.length}
                      valueSelected={guard?.guardName ? true : false}
                      buttonTitle="Configure Validation"
                      placeHolderText="Add a Validation"
                      name={guard.guardName}
                      type="guard"
                      errors={guard?.errors}
                    />
                  </VerticalAlignWrapper>
                );
              })}

              {
                selectedAutomation.guards.length > 0 &&
                <ZButton tertiary variant="contained"
                  style={{ marginTop: '10px' }}
                  onClick={() => {
                    if (selectedAction?.hasChanged == true) {
                      setOpenModal(IChangeContext.CHANGE_VALIDATION);
                    } else {
                      addNewValidation();
                    }
                  }}>
                  Add another Validation
                </ZButton>
              }
            </ActionContentBlock>
          </>
        }

      </ActionParent>

      <DeletionConfirmationDialog
        id={"triggerDelete"}
        deletionTitle={DELETE_TRIGGER}
        deletionText={DELETE_TRIGGER_CONFIRMATION}
        isOpen={triggerId ? true : false}
        onConfirmDelete={deleteTrigger}
        onClose={() => {
          setTriggerId("");
        }}
      />

      <DeletionConfirmationDialog
        id={"validationDelete"}
        deletionTitle={DELETE_GUARD}
        deletionText={DELETE_GUARD_CONFIRMATION}
        isOpen={guardId ? true : false}
        onConfirmDelete={deleteGuard}
        onClose={() => {
          setGuardId("");
          setGuardType("");
        }}
      />

      <DeletionConfirmationDialog
        id={"context_switching"}
        deletionTitle={CONTEXT_SWITCHING}
        deletionText={CONTEXT_SWITCHING_CONFIRMATION}
        isOpen={openModal}
        onConfirmDelete={() => {
          if (openModal == IChangeContext.CHANGE_TRIGGER) {
            addAnotherTrigger();
          } else if (openModal == IChangeContext.CHANGE_VALIDATION) {
            addNewValidation();
          }

          setOpenModal(null);
        }}
        onClose={() => {
          setOpenModal(null);
        }}
      />

    </>
  );
};

export default TriggerBlock;
