import styled from "styled-components";
import { BsChevronDown } from "react-icons/bs";
import useWorkflow from "views/workflows/hooks/useWorkFlow";

const Button = styled.button`
  border-radius: 4px;
  border: ${(props) => `1px solid ${props.theme.color.secondary}`};
  background: ${(props) => props.theme.color.tertiary};
  display: flex;
  color: ${(props) => props.theme.color.secondary};
`;

const BaseButton = ({ onClick }) => {
  const { showNodeDropdown } = useWorkflow();

  return (
    <Button isOpened={showNodeDropdown} onClick={onClick}>
      <span style={{ padding: "6px 16px" }}> Create Node </span>
      <div
        style={{ height: "36px", width: "1px", backgroundColor: "white" }}
        className="ajscnajs"
      ></div>
      <span
        style={{ marginLeft: "8px", paddingTop: "9px", paddingRight: "11px" }}
      >
        {" "}
        <BsChevronDown size={"1.4rem"} />{" "}
      </span>
    </Button>
  );
};

export default BaseButton;
