export const DefaultJSON = "{\n\n}\n";

export const DefaultJSONDisabled =
  "{ /* JSON editor is currently read-only, go to script editor to change the Script */. }\n";

export const sdkSuggestions = [
  {
    label: "sdk: createRecord",
    insertText: `zorp.createRecord({
  tableType: "",
  data: {
  },
  })`,
    documentation: "Create a new record in a specified table",
  },
  {
    label: "sdk: updateRecord",
    insertText: `zorp.updateRecord({
  tableType: "",
  recordId: "",
  data: {
  }
  })`,
    documentation: "Update an existing record in a specified table",
  },
  {
    label: "sdk: deleteRecord",
    insertText: `zorp.deleteRecord({
  tableType: "",
  recordId: ""
  })`,
    documentation: "Delete a specific record from a table.",
  }, {
    label: "sdk: searchOneAndUpdateRecord",
    insertText: `
    // Parameters:
   //• searchPayload: Criteria for finding the record.
  //• updatePayload: Data to update in the found record.

const searchPayload = {
  recordIds: [""],
  tableTypes: [""],
};
const updatePayload = {
  Name: "BFramework"
};
zorp.searchOneAndUpdateRecord({
  searchPayload,
  updatePayload,
})`,
    documentation: "Search for a record and update it in a single operation.",
  }, {
    label: "sdk: searchRecord",
    insertText: `// Parameters:
// • payload: The search criteria object.
// – page: (Optional) Page number for pagination.
// – size: (Optional) Number of records per page.
// – tableTypes: Array of table type identifiers.
// – recordIds: (Optional) Array of record identifiers.
// – searchParams: (Optional) Additional search parameters as key-value
// pairs.
// – sorting: (Optional) Sorting criteria as key-value pairs.
// – filters: (Optional) Array of filter objects with id, value, and
// filterType.


const searchPayload = {
  recordIds: [""],
  tableTypes: [""],
};

zorp.searchRecord({ payload: searchPayload })
`,
    documentation: "Search for records based on provided criteria.",
  },
  {
    label: "sdk: createTask",
    insertText: `// Parameters:
// • taskType: The type identifier for the task.
// • userId: Optional user identifier associated with the task.
// • teamIds: Array of team identifiers associated with the task.
// • scheduledSlot: The time slot for the task.
// • data: Additional data related to the task.


zorp.createTask({
  taskType: "",
  userId: "",
  teamIds: [""],
  scheduledSlot: {
  from: "2024-04-26T04:23:30.650Z",
  to: "2024-04-26T05:23:30.651Z",
  },
  data: { details: "Task details" },
});
`,
    documentation: "Create a new task with specified details.",
  }, {
    label: "sdk: updateTask",
    insertText: `// Parameters:
// • taskId: The identifier of the task to update.
// • data: Data to update in the task.


zorp.updateTask({
  taskId: "",
  data: { details: "" },
  })
`,
    documentation: "Update properties of an existing task.",
  }, {
    label: "sdk: updateTeam",
    insertText: `// Parameters:
// • taskId: The identifier of the task.
// • formData: Data related to the team update.

zorp.updateTeam({
  taskId: "",
  formData: {
    userId: null,
    teamIds: [""],
  }
});
`,
    documentation: "Update team details associated with a task.",
  }, {
    label: "sdk: deleteTask",
    insertText: `zorp.deleteTask({ taskId: "jeckxx" });
`,
    documentation: "Delete a specific task by ID.",
  }, {
    label: "sdk: updateTaskSlot",
    insertText: `zorp.updateTaskSlot({
  taskId: "",
  formData: {
  scheduledSlot: {
    from: "2024-04-27T05:53:59.591Z",
    to: "2024-04-28T06:53:59.592Z",
   }
  }
})`,
    documentation: "Update the time slot of a specific task.",
  }, {
    label: "sdk: transitTask",
    insertText: `
// Parameters:
// • taskId: The identifier of the task.
// • event: The event type for the transition.
// • formData: Additional data related to the transition.

 zorp.transitTask({
    taskId: "bey7h8",
    event: "Complete",
    formData: {
      ame: "kaush",
      stateTransitionInfo: {
      kkp607: {
        timestamp: "2024-04-26T05:55:20.685+00:00",
       },
    },
    ssss: "ssss",
    wwww: "kaush",
    pnwww: "kaushakendra",
    },
    channel: "Dashboard",
    mode: "single",
})`,
    documentation: "Transition a task to a new state.",
  }, {
    label: "sdk: searchTask",
    insertText: `zorp.searchTask({ taskId: "bey7h8" })`,
    documentation: "Search for a task by ID.",
  }, {
    label: "sdk: searchOneAndUpdateTask",
    insertText: `
// Parameters:
//  • searchPayload: Criteria for finding the task.
//  • updatePayload: Data to update in the found task.

zorp.searchOneAndUpdateTask({
  searchPayload: { taskId: "bey7h8" },
  updatePayload: { name: "searchoneandupdate" },
})`,
    documentation: "Search for a task and update it based on provided criteria.",
  }, {
    label: "sdk: sendEmail",
    insertText: `
// Parameters:
//  • searchPayload: Criteria for finding the task.
//  • updatePayload: Data to update in the found task.

const payload = {
  to: "kaushalendra@zorp.one",
  from: "notifications@zorp.one", // Replace with your email
  subject: "Shipping label print - ${new Date().toLocaleDateString()}", // Use the Current Dtext: "Shipping Label Link",
  html: "<p>Shipping Label Link</p>",
};

  zorp.sendEmail(payload)`,
    documentation: "Send an email to a specified recipient using the Zorp platform. This function allows for both plain text and HTML content.",
  }
];