import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography } from '@mui/material';
import { PageCategoryEnum, recordPageVisit } from "config/Rudderstack";
import { TEXT } from "constants/CommonConstants";
import { RudderStackAutomationBuilderContext, RudderStackAutomationBuilderEvents } from "constants/RudderStackConstants";
import { useJSEditor } from "lowcode/hooks/useJSEditor";
import { EditorScriptLocation } from "lowcode/state/types/types";
import getInputType from "lowcode/util/getInputType";
import { ZJSEditorLayout } from "lowcode/ZJSEditorLayout";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { recordRSEvent, removeBraces } from "utils/CommonUtils";
import { getLowCodeEditorDefaultReturnText, getLowCodeEditorText } from "utils/Utils";
import {generateTempId, getActionNameForTempId, isTempIdUnique} from "../utils/utils";
import useAutomation from "views/automationBuilder/hooks/useAutomation";
import usePayload from 'views/automationBuilder/hooks/usePayload';
import useWorkFlow from "views/workflows/hooks/useWorkFlow";
import IsActive from "views/workflows/workflowactions/api/components/IsActive";
import ModalSmartInputTextField from "views/workflows/workflowactions/api/components/ModalSmartInputTextField";
import {
  saveAction,
  ValidLocation
} from "views/workflows/workflowactions/api/utils/utils";
import {
  Heading, HeadingWrapper, Label
} from "views/workflows/workflowactions/ModalContent.styles";
import {
  setSelectedAction
} from "../../../reducers/workflowAction";
import DatafieldSelector from "../../DatafieldSelector";
import ErrorMessageInput from "../components/ErrorMessageInput";
import InputTempId from "views/automationBuilder/components/InputTempId";

const PushNotification = () => {
  const dispatch = useDispatch();
  // Default value for exitOnError is true
  const [exitOnError, setExitOnError] = useState(false);
  const {
    selectedAction,
    getScriptValue,
    dataPicker
  } = useWorkFlow();

  const { selectedActionBlock, selectedAutomation, clearRightSide } = useAutomation();
  const { actionConditionPayload } = usePayload();
  const [isEditing, setIsEditing] = useState(false);
  const [tempIdChanged, setTempIdChanged] = useState(true);
  const [currTempIdVal, setCurrTempIdVal] = useState(selectedAction?.tempId || "");


  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    setValue,
    control,
    clearErrors,
    getValues,
  } = useForm({
    defaultValues: {
      isActive: selectedAction?.isActive ?? true,
      tempId: generateTempId(selectedAction, selectedAutomation, getActionNameForTempId(selectedAction), "action"),
    }
  });


  const { openJSEditorLayout, scriptEditorOpen } = useJSEditor();

  const handleMessageChange = (e) => {
    dispatch(
      setSelectedAction({
        ...selectedAction,
        hasChanged: true,
        message: {
          type: getInputType(e.target.value),
          value: removeBraces(e.target.value),
          dataType: TEXT,
        },
      })
    );
  };

  const handleErrorMessageChange = (e) => {
    dispatch(
      setSelectedAction({
        ...selectedAction,
        hasChanged: true,
        [EditorScriptLocation.ERROR_MESSAGE]: {
          type: getInputType(e.target.value),
          value: removeBraces(e.target.value),
          dataType: TEXT,
        },
      })
    );
  };

  const handleTitleChange = (e) => {
    dispatch(
      setSelectedAction({
        ...selectedAction,
        hasChanged: true,
        title: {
          type: getInputType(e.target.value),
          value: removeBraces(e.target.value),
          dataType: TEXT,
        },
      })
    );
  };


  const onFormSubmit = (data) => {

    let tempActionId = selectedAction?.tempId || data?.tempId;
    if(!isEditing) {
      tempActionId = currTempIdVal.length === 0 ? (selectedAction?.actionId || selectedAction?.guardId || selectedAction?.conditionId) : currTempIdVal;
      dispatch(setSelectedAction({ ...selectedAction, tempId: currTempIdVal }));
    }

    const updatedTitle = selectedAction?.title;
    const updatedMessage = selectedAction?.message;
    const updatedName = selectedAction?.actionName;

    let isTempIdChanged = tempIdChanged;
    if (tempActionId === (selectedAction?.actionId || selectedAction?.guardId || selectedAction?.conditionId)) {
      setTempIdChanged(false);
      isTempIdChanged = false;
    }

    if (isTempIdChanged && !isTempIdUnique(tempActionId, selectedAutomation)) {
    setError("tempId", {
      type: "manual",
      message: "This ID already exists. Please choose a different one.",
    });
    return;
  }

    if (!updatedName) {
      setError("actionName", {
        type: "manual",
        message: "This is a required field",
      });
      return;
    }

    if (!updatedTitle?.value) {
      setError("title", {
        type: "manual",
        message: "This is a required field",
      });
      return;
    }

    if (!updatedMessage?.value) {
      setError("message", {
        type: "manual",
        message: "This is a required field",
      });
      return;
    }

    const payload = {
      ...data,
      message: updatedMessage,
      title: updatedTitle,
      actionType: selectedAction.actionType,
      actionId: tempActionId,
      tempId: tempActionId,
      isActive: selectedAction?.isActive ?? true,
      [EditorScriptLocation.ERROR_MESSAGE]: selectedAction[EditorScriptLocation.ERROR_MESSAGE],
      exitOnError: exitOnError,
      errors: []
    };

    recordRSEvent(RudderStackAutomationBuilderEvents.pushNotificationActionSaveClick, {
      context: RudderStackAutomationBuilderContext.automationBuilderAction,
      automationId: selectedAutomation.id,
      actionType: selectedAction.actionType,
      actionId: selectedAction.actionId
    })

    saveAction({
      payload,
      location: ValidLocation.AUTOMATION,
      dispatch,
      selectedAction,
      selectedAutomation,
      selectedActionBlock
    });

    clearRightSide();
  };

  useEffect(() => {
    recordPageVisit(PageCategoryEnum.automationBuilder, RudderStackAutomationBuilderEvents.pushNotificationActionOnLoad);
  }, [])

  useEffect(() => {
    setExitOnError(selectedAction?.exitOnError ?? false)
  }, [selectedAction?.exitOnError]);

  const pushNotificationScriptConfig = {
    MessageScriptConfig: {
      script: getScriptValue(EditorScriptLocation.PUSH_NOTIFICATION_MESSAGE) || getLowCodeEditorText(EditorScriptLocation.PUSH_NOTIFICATION_MESSAGE, TEXT, `return ${getLowCodeEditorDefaultReturnText(TEXT)}`),
      dataType: TEXT,
    },
    TitleScriptConfig: {
      script: getScriptValue(EditorScriptLocation.PUSH_NOTIFICATION_TITLE) || getLowCodeEditorText(EditorScriptLocation.PUSH_NOTIFICATION_TITLE, TEXT, `return ${getLowCodeEditorDefaultReturnText(TEXT)}`),
      dataType: TEXT,
    },
    errorMessageConfig: {
      script: getScriptValue(EditorScriptLocation.ERROR_MESSAGE) || getLowCodeEditorText(EditorScriptLocation.ERROR_MESSAGE, TEXT, `return ${getLowCodeEditorDefaultReturnText(TEXT)}`),
      dataType: TEXT,
    }
  };

  const handleTempActionValChange = (name, value) => {
      setCurrTempIdVal(value);
  }

  const handleEditClick = () => {
    setIsEditing(true);
    setTempIdChanged(true);
  };

  const handleTickClick = () => {
    setIsEditing(false);
  };


  const setTempIdError = (error) => {
    if (error) {
      setError("tempId", { type: "manual", message: error });
    } else {
      clearErrors("tempId");
    }
  };


  return (
    <form onSubmit={handleSubmit(onFormSubmit)} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <HeadingWrapper>
        <Heading> Push Notification </Heading>
        <IsActive control={control} closeOnClick={clearRightSide} />
      </HeadingWrapper>
      <Label> Name </Label>
      <ModalSmartInputTextField
        value={selectedAction?.actionName}
        control={control}
        placeholder="Describe the push notification here"
        register={register}
        errors={errors}
        name="actionName"
      />
      <InputTempId
            label="Action Id"
            onChange={handleTempActionValChange}
            register={register}
            requiredErrorMessage = "Action Id is required"
            errors = {errors}
            getValues = {getValues}
            isReadOnly={!isEditing}
            handleEditClick={handleEditClick}
            handleTickClick={handleTickClick}
            setTempIdError={setTempIdError}
            isEditing={isEditing}
            name="tempId"
          />

      <Label className='mt-2'> Title </Label>
      <ModalSmartInputTextField
        type={selectedAction?.title?.type || "literal"}
        dataType={TEXT}
        control={control}
        isSmartField={true}
        step="any"
        value={selectedAction?.title?.value}
        placeholder="Enter ${ to Select Dynamic value for Notification Title"
        register={register}
        name="title"
        errors={errors}
        getValues={getValues}
        onChangeFunc={handleTitleChange}
        apiBodyPayload={actionConditionPayload}
        config={pushNotificationScriptConfig.TitleScriptConfig}
        editorLocation={EditorScriptLocation.PUSH_NOTIFICATION_TITLE}
        openJSEditor={openJSEditorLayout}
      />

      <Label className='mt-2'> Message</Label>
      <ModalSmartInputTextField
        dataType={TEXT}
        type={selectedAction?.message?.type || "literal"}
        isSmartField={true}
        control={control}
        step="any"
        value={selectedAction?.message?.value}
        placeholder="Enter ${ to Select Dynamic value for Notification Message"
        register={register}
        name="message"
        errors={errors}
        onChangeFunc={handleMessageChange}
        config={pushNotificationScriptConfig.MessageScriptConfig}
        editorLocation={EditorScriptLocation.PUSH_NOTIFICATION_MESSAGE}
        openJSEditor={openJSEditorLayout}
        apiBodyPayload={actionConditionPayload}
      />


      <ErrorMessageInput
        label={`Error Message to be shown if the Push Notification fails`}
        onChangeFunc={handleErrorMessageChange}
        register={register}
        errors={errors}
        apiBodyPayload={actionConditionPayload}
        openJSEditorLayout={openJSEditorLayout}
        config={pushNotificationScriptConfig.errorMessageConfig}
      />

      <div style={{ marginTop: '10px', padding: '11px' }}>
        <FormControlLabel
          control={
            <input
              type="checkbox"
              style={{ height: '13px', width: '13px' }}
              checked={exitOnError}
              onChange={(value) => {
                setExitOnError((currentValue) => !currentValue)
              }}
              value="one"
            />
          }
          label={<Typography variant='h6' style={{ color: '#808080', fontSize: '14px', fontWeight: 'bold', marginLeft: '10px' }}>Exit Transition On Error</Typography>}
        />
      </div>
      {dataPicker?.isOpened && <DatafieldSelector setValue={setValue} />}
      {scriptEditorOpen && <ZJSEditorLayout/>}


    </form>
  );
};

export default PushNotification;