import React from 'react'
import styled from "styled-components"
import CreateView from "views/DataField/CreateView";
import EditView from "views/DataField/EditView";
import SubFieldView from "views/DataField/SubFieldView";
import useWorkFlow from './hooks/useWorkFlow';


const Container = styled.div` 
    display: grid;
    grid-template-columns:${(props:any) => props.variant === "divide" ? "45% 31%" : ""};
    height:  83vh;
    width: ${(props:any) => (props.type === "EDIT" && props.variant === "") ? "40%" :  ""};
    margin-left: ${(props:any) => (props.type === "EDIT" && props.variant === "") ? "30%" : (props.type === "EDIT" && props.variant === "divide") ? "22%" : ""};
    `

type ModalForFlutterProps = {
  type: "EDIT" | "CREATE"
}


const ModalForFlutter = ({type}:ModalForFlutterProps) => {

  const {activeSubField} = useWorkFlow()
  return (
    <>
      {/* @ts-ignore */}
      <Container type={type} variant={activeSubField ? "divide" : ""}>
        {
          type === "CREATE" ? (
            /* @ts-ignore */
            <CreateView/>
          ) : (
            /* @ts-ignore */
            <EditView/>
          )
        }
        {activeSubField && <SubFieldView/>}
      </Container>
    </>
  )

  
}

export default ModalForFlutter