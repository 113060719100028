import { Button, ButtonGroup } from "@material-ui/core";
import { IconButton, TextField, Tooltip } from "@mui/material";
import { PageCategoryEnum, recordPageVisit } from "config/Rudderstack";
import React, { useEffect } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useHistory } from "react-router-dom";
import { TaskFormBody } from "render-engine/render.styles";
import TopBar from "render-engine/TopBar";
import { recordRSEvent } from "utils/CommonUtils";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import InfoIcon from "@mui/icons-material/Info";
import notification from "../../notifications/notifications";
import authProvider from "../../providers/auth/authProvider";
import { UserService } from "../../providers/data/services/UserService";
import { PhoneValidator, TeamValidator } from "../tasks/components/Validator";
import UserRole from "./components/UserRole";
import UserDisplayBlock from "./UserDisplayBlock";
import DeleteIcon from '@material-ui/icons/Delete';

const UserEditForm = (props) => {
  const [selectedRole, setSelectedRole] = React.useState("");
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [phoneCode, setPhoneCode] = React.useState("");
  const [teamIds, setTeamIds] = React.useState([]);
  const history = useHistory();
  const [email, setEmail] = React.useState("");
  const [externalId, setExternalId] = React.useState("");

  useEffect(() => {
    ValidatorForm.addValidationRule("teamIdCheck", (value) => {
      if (teamIds && teamIds.length > 0) {
        return true;
      } else {
        return false;
      }
    });
  });

  useEffect(() => {
    ValidatorForm.addValidationRule("phoneNumberCheck", (value) => {
      if (phoneCode && phoneCode.length > 0 && phone && phone.length > 0) {
        return true;
      } else {
        return false;
      }
    });
  });

  useEffect(() => {
    ValidatorForm.addValidationRule("checkEmail", (value) => {
      var mailformat =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if ((email.length > 0 && email.match(mailformat)) || email.length === 0) {
        return true;
      } else {
        return false;
      }
    });
  });

  const handleTeamIdChange = (id, index) => {
    // Make a copy of the teamIds array to avoid direct mutation
    const updatedTeamIds = [...teamIds];

    // Update the value at the specified index, or add it if it doesn't exist
    if (index >= updatedTeamIds.length) {
      updatedTeamIds.push(id);
    } else {
      updatedTeamIds[index] = id;
    }

    // Update the state with the new array
    setTeamIds(updatedTeamIds);
  };

  const handleTeamDelete = (index) => {
    // Ensure at least one team remains associated
    if (teamIds.length > 1) {
      const updatedTeamIds = teamIds.filter((_, i) => i !== index);
      setTeamIds(updatedTeamIds);
    } else {
      notification("error", "At least one team must be associated to an user");
    }
  };

  const handleRoleChange = (val) => {
    if (val === "") setSelectedRole("");
    else setSelectedRole(val);
  };

  const editUser = () => {
    recordRSEvent("edituser_button_click", {
      context: "user_edit",
      entity_id: props.id,
      entity_status: props.status,
      team_id: props.teamIds,
      team_name: props.teams,
      role_id: teamIds,
      role_name: props.role,
    });
    const taskPayLoad = {
      userId: props.id,
      name: name,
      phone: {
        num: phone,
        countryCode: phoneCode,
      },
      role: selectedRole,
      teamIds: [...new Set(teamIds)],
      email: email,
      externalId: externalId
    };

    UserService.userUpdate(taskPayLoad)
      .then((res) => {
        if (res?.code && res?.code.indexOf("200") < 0) {
          // Error
          if (res?.code === "401") {
            notification("error", res.message);
            authProvider.logout();
          } else {
            notification("error", res.message);
            recordRSEvent("edituser_event_fail", {
              context: "user_edit",
              entity_id: props.id,
              entity_status: props.status,
              team_id: props.teamIds,
              team_name: props.teams,
              role_id: teamIds,
              role_name: props.role,
              external_id: props.externalId
            });
          }
        } else {
          notification("success", res.message);
          recordRSEvent("edituser_event_success", {
            context: "user_edit",
            entity_id: props.id,
            entity_status: props.status,
            team_id: props.teamIds,
            team_name: props.teams,
            role_id: teamIds,
            role_name: props.role,
          });
          history.push(`/users/${props.id}/show`);
        }
      })
      .catch((err) => {
        console.log("Error", err);
        notification("error", "User cannot be edited!");
        recordRSEvent("edituser_event_fail", {
          context: "user_edit",
          entity_id: props.id,
          entity_status: props.status,
          team_id: props.teamIds,
          team_name: props.teams,
          role_id: teamIds,
          role_name: props.role,
        });
        history.push("/users");
      });
  };

  const handleNameChange = (val) => {
    setName(val);
  };

  const handlePhoneChange = (value, data, event, formattedValue) => {
    setPhone(value.slice(data.dialCode.length));
    setPhoneCode(data.dialCode);
  };

  const handleEmailChange = (val) => {
    if (val == null || val.length == 0) setEmail("");
    else setEmail(val);
  };

  const cancelEdit = () => {
    notification("info", "User edit cancelled!");
    recordRSEvent("edituser_cancel_button_click", {
      context: "user_edit",
      entity_id: props.id,
      entity_status: props.status,
      team_id: props.teamIds,
      team_name: props.teams,
      role_id: teamIds,
      role_name: props.role,
    });
    history.push(`/users/${props.id}/show`);
  };

  useEffect(() => {
    recordPageVisit(PageCategoryEnum.userEdit, "page_load", {
      entity_id: props.userId,
      entity_status: props.status,
      team_id: props.teamIds,
      team_name: props.teams,
      role_id: props.roleId,
      role_name: props.role,
    });
    var id = props.id;
    const data = UserService.getUser(id);
    data
      .then((res) => {
        const user = res?.data;
        setSelectedRole(user.role);
        setName(user?.name);
        setPhone(user?.phone?.num);
        if (user?.phone?.countryCode?.length > 0)
          setPhoneCode(user?.phone?.countryCode);
        else setPhoneCode("");
        setTeamIds(user?.teamIds || []);
        setEmail(user?.email == null ? "" : user?.email);
        setExternalId(user?.externalId);
      })
      .catch((err) => {
        notification("error", "Error while fetching user details");
        console.log(err);
      });
  }, [props.id]);

  return (
    <ValidatorForm
      onSubmit={editUser}
      onError={(errors) => console.log(errors)}
    >
      <TaskFormBody>
        <TopBar
          heading="Edit User"
          onCancelText={"Cancel"}
          onSaveText="Edit"
          onSave={() => { }}
          onCancel={cancelEdit}
        />
        <div className="contentWrapper">
          <div className="grid grid-cols-1 sm:grid-cols-8 gap-x-8">
            <div className="col-span-4">
              <div
                className="col-span-1 font-medium"
                style={{ padding: "20px 0px 4px 0px" }}
              >
                Name <span className="text-red-500">*</span>
              </div>
              <TextValidator
                autoComplete={false}
                value={name}
                variant="outlined"
                size="large"
                className="inc-t0 oid-wd w-full"
                onChange={(event) => handleNameChange(event.target.value)}
                validators={["required"]}
                errorMessages={["This field is required"]}
              />
            </div>

            <div className="col-span-4">
              <div
                className="col-span-1 font-medium"
                style={{ padding: "20px 0px 4px 0px" }}
              >
                Role <span className="text-red-500">*</span>
              </div>

              <UserRole
                onChange={handleRoleChange}
                disabled={false}
                role={selectedRole}
              />
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-8 gap-x-8">
            <div className="col-span-4">
              <div
                className="col-span-1 font-medium"
                style={{ padding: "20px 0px 4px 0px" }}
              >
                Phone Number
              </div>
              <PhoneValidator
                enableSearch={true}
                country={"us"}
                value={phoneCode + phone}
                onChange={handlePhoneChange}
                className="contactNumClass"
                // validators={['phoneNumberCheck']}
                validators={[]}
                errorMessages={["This field is required"]}
              />
            </div>

            <div className="col-span-4">
              <div
                className="col-span-1 font-medium"
                style={{ padding: "20px 0px 4px 0px" }}
              >
                Team <span className="text-red-500">*</span>
              </div>

              {teamIds?.length > 0 && teamIds.map((teamId, index) => (
                <div key={index} style={{ paddingTop: "5px", display: "flex", alignItems: "center", width: "100%" }}>
                  <div style={{ flex: 1 }}>
                    <TeamValidator
                      id={`teamField-${index}`}
                      onChange={(e) => {
                        handleTeamIdChange(e, index)
                      }}
                      disabled={false}
                      team={teamId}
                      style={{ width: "100%" }}
                      validators={["teamIdCheck"]}
                      errorMessages={["This field is required"]}
                    />
                  </div>
                  <IconButton size="small" aria-label="delete" color="primary" onClick={() => handleTeamDelete(index)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              ))}

              <div style={{ textAlign: "right", color: "blue", fontSize: "14px", paddingRight: "10px", paddingTop: "5px" }}>
                <a href="#" onClick={(e) => {
                  e.preventDefault();
                  // e.stopPropagation();
                  setTeamIds([...teamIds, ""]);
                }}>+ Add Team</a>
              </div>

            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-8 gap-x-8">
            <div className="col-span-4">
              <div
                className="col-span-1 font-medium"
                style={{ padding: "20px 0px 4px 0px" }}
              >
                Email
              </div>
              <TextValidator
                onChange={(event) => handleEmailChange(event.target.value)}
                variant="outlined"
                size="medium"
                validators={["checkEmail"]}
                className="inc-t0 oid-wd w-full"
                errorMessages={["Enter a valid email address"]}
                value={email}
              />
            </div>
            <div className="col-span-4">
              <div
                className="col-span-1 font-medium"
                style={{ padding: "20px 0px 4px 0px" }}
              >
                External Id
                <Tooltip title="This is the user id used within your ogranization">
                  <IconButton size="medium">
                    <InfoIcon fontSize="medium" />
                  </IconButton>
                </Tooltip>
              </div>
              <TextValidator
                autoComplete={false}
                value={externalId}
                variant="outlined"
                size="large"
                className="inc-t0 oid-wd w-full"
                onChange={(event) => setExternalId(event.target.value)}
                validators={[]}
              />
            </div>
            <div></div>
          </div>
        </div>
      </TaskFormBody>
    </ValidatorForm>
  );
};

export default UserEditForm;
