import { TablePermissionDependency, TaskPermissionDependency, Permission, PermissionObject, TaskPermission } from "views/entities/permissions/types";
import { extractDependencies, generateDefaultPermissions } from "utils/CommonUtils";

export const RUN: string = 'run';
export const SAVE: string = 'save';
export const TEXT: string = 'TEXT';
export const JSON: string = 'JSON';
export const OBJECT: string = 'OBJECT';
export const BOOLEAN: string = 'BOOLEAN';
export const FLUTTER_WEB: string = 'FLUTTER_WEB';
export const VARIABLE_TREE: string = 'VARIABLE_TREE';
export const FUNCTION_SNIPPETS: string = 'FUNCTION_SNIPPETS';
export const CLOSE_WITHOUT_SAVE: string = 'CLOSE_WITHOUT_SAVE';
export const NUMBER: string = 'NUMBER';
export const FORCE_SAVE: string = 'FORCE_SAVE';
export const DOC_LINK: string = 'https://docs.zorp.one/product-docs/low-code-scripting';

export enum SystemTransitionType {
  UPDATE_TEAM = 'updateTeam',
  UPDATE_SLOT = 'updateSlot',
  UPDATE_TASK = 'updateTask',
  CREATE_TASK = 'createTask',
  DELETE_TASK = 'deleteTask',
}

export enum ActionToPerform {
  CREATE = 'create',
  EDIT = 'edit',
}

export const DocsLink = {
  ROLES: "https://docs.zorp.one/product-docs/roles",
  USERS: "https://docs.zorp.one/product-docs/users",
  TEAMS: "https://docs.zorp.one/product-docs/teams"
}

export const TaskPermissionDependencies: TaskPermissionDependency = {
  view_task: [],
  edit_task: ['view_task'],
  create_task: ['view_task', 'edit_task'],
  delete_task: ['view_task'],
}

export const defaultTablePermissionsList: { [key: string]: { [key: string]: PermissionObject } } = {
  Table: {
    view_table: {
      id: 'view_table',
      description: 'Allows Viewing of a table',
      displayName: 'View Table',
      dependencies: []
    },
    duplicate_table: {
      id: 'duplicate_table',
      description: 'Allows duplication of a table',
      displayName: 'Duplicate a Table',
      dependencies: ['view_table']
    },
    delete_table: {
      id: 'delete_table',
      description: 'Allows deletion of a table',
      displayName: 'Delete a Table',
      dependencies: ['view_table']
    },
    rename_table: {
      id: 'rename_table',
      description: 'Allows renaming of a table',
      displayName: 'Rename a Table',
      dependencies: ['view_table']
    },
    manage_table_column: {
      id: 'manage_table_column',
      description: 'Allows management of table columns',
      displayName: 'Manage Table Columns',
      dependencies: ['view_table']
    }, manage_table_view: {
      id: 'manage_table_view',
      description: 'Allows management of table views',
      displayName: 'Manage Table Views',
      dependencies: ['view_table', 'view_record']
    },
  },
  Records: {
    view_record: {
      id: 'view_record',
      description: 'Allows viewing of table records',
      displayName: 'View Table Records',
      dependencies: ["view_table"]
    },
    create_record: {
      id: 'create_record',
      description: 'Allows creation of a new table record',
      displayName: 'Create Table Record',
      dependencies: ['view_table', 'view_record']
    },
    edit_record: {
      id: 'edit_record',
      description: 'Allows editing of table records',
      displayName: 'Edit Table Record',
      dependencies: ['view_table', 'view_record']
    },
    delete_record: {
      id: 'delete_record',
      description: 'Allows deleting records',
      displayName: 'Delete Table Record',
      dependencies: ['view_table', 'view_record']
    },
    manage_section: {
      id: 'manage_section',
      description: 'Allows managing record layout',
      displayName: 'Manage Layout',
      dependencies: ['view_table', 'view_record']
    },
    add_comment: {
      id: 'add_comment',
      description: 'Allows adding comments to records',
      displayName: 'Comment',
      dependencies: ['view_table', 'view_record']
    },
  },
  Misc: {
    manage_table_automation: {
      id: 'manage_table_automation',
      description: 'Allows management of table automations',
      displayName: 'Manage Table Automations',
      dependencies: ['manage_table_column', 'view_table']
    },
    update_table_acl: {
      id: 'update_table_acl',
      description: 'Allows Sharing Tables with Others',
      displayName: 'Manage Sharing',
      dependencies: ['view_table']
    },
  },
};


export const defaultTablePermissions = generateDefaultPermissions(defaultTablePermissionsList);
export const TablePermissionDependencies: TablePermissionDependency = extractDependencies(defaultTablePermissionsList);

export const defaultTaskPermissionsList: TaskPermission = {
  Task: {
    view_task: {
      id: 'view_task',
      description: 'Allows viewing of tasks',
      displayName: 'View Task',
      dependencies: []
    },
    edit_task: {
      id: 'edit_task',
      description: 'Allows editing of tasks',
      displayName: 'Edit Task',
      dependencies: ['view_task']
    },
    create_task: {
      id: 'create_task',
      description: 'Allows creation of tasks',
      displayName: 'Create Task',
      dependencies: ['view_task', 'edit_task']
    },

  },
  Misc: {
    delete_task: {
      id: 'delete_task',
      description: 'Allows deletion of tasks',
      displayName: 'Delete Task',
      dependencies: ['view_task']
    }
  }
}

export const defaultPermission: Permission = {
  permissionId: '',
  permissionSetName: '',
  isEditable: true,
  permissions: [],
  or: {
    roleIds: [],
    teamIds: [],
    userIds: [],
  },
  and: {
    roleIds: [],
    teamIds: [],
    userIds: [],
  },
}

export const permissionLevels = [
  { value: "editor", label: "Editor" },
  { value: "commenter", label: "Commenter" },
  { value: "creator", label: "Creator" },
  { value: "viewer", label: "Viewer" },
];

export const teamUserAutomationDefaultMockData = {
  "loggedInUser": {
    "userId": "twxdko",
    "name": "zorp",
    "phone": {
      "countryCode": "ab5b9h",
      "num": " "
    },
    "teams": [
      {
        "teamId": "podcxf",
        "name": "pcn7b1",
        "parentTeamId": "q43r03",
        "description": "c8jfgf"
      },
      {
        "teamId": "ff9bmn",
        "name": "pcn7b1",
        "parentTeamId": "bxu4dc",
        "description": "c8jfgf"
      },
      {
        "teamId": "dze2nx",
        "name": "pcn7b1",
        "parentTeamId": "r4pacx",
        "description": "c8jfgf"
      }
    ],
    "role": {
      "roleId": "ud5fin",
      "name": "admin",
      "description": "jmncpb"
    },
    "userLocation": {
      "latitude": 11781,
      "longitude": 88563,
      "address": "b1f1x9"
    }
  },
  "company": {
    "companyId": "dfulis",
    "name": " ",
    "timezone": "Asia/Kolkata",
    "secretKeys": {
      "zorp": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIfdfd.SldUIFJ1bGVzISIsImlhdCI6MTQ1OTQ0ODExOSwiZXhwIjoxNDU5NDU0NTE5fQ.yIVBD5b73C75osbmwwshQNRC7frWUYrqaTjTpza2y4"
    }
  },
  "team": {
    "teamId": "y94ne0",
    "accountId": "c6hltz",
    "createdOn": "2024-05-08T10:37:59.625+00:00",
    "updatedOn": "2024-05-08T10:37:59.625+00:00",
    "name": "new",
    "description": "hi all",
    "parentTeamId": "d6c155",
    "isActive": true,
    "parentTeam": null,
    "ancestorTeamIds": []
  },
  "teamBeforeUpdate": {
    "teamId": "y94ne0",
    "accountId": "c6hltz",
    "createdOn": "2024-05-08T10:37:59.625+00:00",
    "updatedOn": "2024-05-08T10:37:59.625+00:00",
    "name": "new",
    "description": "hi all",
    "parentTeamId": "d6c155",
    "isActive": true,
    "parentTeam": null,
    "ancestorTeamIds": []
  },
  "user": {
    "userId": "uhj86g",
    "createdOn": "2024-04-05T07:26:29.663+00:00",
    "updatedOn": "2024-05-09T17:49:59.664+00:00",
    "name": "Usaf khan",
    "accountId": "onpq8f",
    "phone": {
      "countryCode": "91",
      "num": "9878675687"
    },
    "email": "",
    "teamIds": [
      "lokmng"
    ],
    "teams": [
    ],
    "role": "oij8uh",
    "isActive": true,
    "status": "ONLINE",
    "externalId": ""
  },
  "userBeforeUpdate": {
    "userId": "uhj86g",
    "createdOn": "2024-04-05T07:26:29.663+00:00",
    "updatedOn": "2024-05-09T17:49:59.664+00:00",
    "name": "Usaf khan",
    "accountId": "onpq8f",
    "phone": {
      "countryCode": "91",
      "num": "9878675687"
    },
    "email": "",
    "teamIds": [
      "lokmng"
    ],
    "teams": [
    ],
    "role": "oij8uh",
    "isActive": true,
    "status": "ONLINE",
    "externalId": ""
  }
}

export const markdownText = `
  ### No summary available
`;