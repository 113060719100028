import { useRecordContext } from 'react-admin';
import React from 'react';
import { get } from 'lodash';
import moment from 'moment';

const CustomDateField = ( props ) =>
{
    const { source } = props;
    const record = useRecordContext(props);
    const value = get(record, source)
    let reqDate;

    if(value) {
        const date = new Date(value)
        // get day from date
        reqDate = moment(date).format('Do MMM YYYY , h:mm A');
    }
    

   

    
    return (
        <div>
            {value && reqDate}
        </div>
    );
};


 
CustomDateField.defaultProps = {
    addLabel: true,
};

export default CustomDateField;
