import Modal from "@mui/material/Modal";
import {
  Backdrop,
  Box,
  Fade,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useState } from "react";
import { Control, Controller } from "react-hook-form";
import styled from "styled-components";
import {
  APIFlexDiv,
  Heading,
  HeadingWrapper,
  InputContainer,
  Label,
  URLInfoWrapper,
} from "views/workflows/workflowactions/ModalContent.styles";
import ZInput from "views/commonComponents/ZTextInput";
import ExecuteCondition from "../components/ExecuteCondition";
import { EditorScriptLocation } from "lowcode/state/types/types";
import _ from "lodash";
import { useJSEditor } from "lowcode/hooks/useJSEditor.tsx";
import { useDispatch } from "react-redux";
import { setSelectedAction } from "views/workflows/reducers/workflowAction";
import { OBJECT } from "constants/CommonConstants";
import { setScriptEditorPayload } from "lowcode/state/scriptOpenState";
import { ZButton } from "views/commonComponents/commonComponents.styles";

type Props = {};

const ModalDesc = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
`;

const ModalHeaderText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
`;

const FooterRightWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
`;

const FooterLeftWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const DocumentationTxt = styled.p`
  color: #3054b9;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
  cursor: pointer;
`;

const ModalFooter = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
  justify-content: space-between;
  display: flex;
  width: 100%;
  height: 100px;
`;

const LabelText = styled.p`
  color: #667085;
  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  margin-bottom: 8px;
`;

const TemplateModal = styled(Modal)`
  display: flex;
  /* height: 80vh; */
  overflow: auto;
  // z-index: ${(props: any) => (props.isScriptEditorOpen ? 2 : 10000000)} ;
`;

const ModalContainer = styled(Box)`
  display: flex;
  z-index: 100000;
  flex-direction: column;
  padding: 0px;
  width: ${(props: { height?: string; width?: string }) =>
    props?.width ? props.width : "640px"};
  height: ${(props: { height?: string; width?: string }) =>
    props?.height ? props.height : "540px"};
  background: #ffffff;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  position: absolute;
  top: 15%;
  left: 30%;
  border-radius: 12px;
  border: 1px solid #667085;
`;

const HeaderInfoWrapper = styled.div`
  // margin-top: 16px;
  padding-bottom: 8px;
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  // height: 161px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: #ffffff;
  width: 100%;
  padding: 24px 24px 0px 24px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 24px 24px 24px;
  width: 100%;
`;

const MonitorNameWrapper = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #d0d5dd;
  background: #f9fafb;
`;

const MainHeading = styled.p`
  color: #101828;
  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
`;

const ModalContent = styled.div`
  padding: 0px 24px 0px 24px;
`;

const MainDiscription = styled.p`
  color: #475467;
  /* Text xs/Regular */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
`;

const FlexContainer = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
`;

const ErrorHandlingModal = ({
  openErrorHandlingModal,
  handleModalClose,
  control,
  register,
  handleValChange,
  actionExecutionScriptConfig,
  selectedAction,
  ShowCurrentActionPayload,
  getValues,
  watch
}: {
  openErrorHandlingModal: boolean;
  handleModalClose: () => void;
  handleValChange: (e: any) => void;
  control: Control;
  register: any;
  actionExecutionScriptConfig: any;
  selectedAction: any;
  ShowCurrentActionPayload: any;
  getValues: any;
  watch: any;
}) => {
  const {
    openJSEditorLayout,
    scriptEditorOpen,
    DefaultJSON,
    openJSEditor,
    userClearedScript,
  } = useJSEditor();
  const dispatch = useDispatch();
  const [apiBody, setApiBody] = useState(
    selectedAction?.api_action_json_body?.value
  );

  const onSave = () => {
    console.log(getValues());
    handleModalClose();
  };

  const isExponential = watch("exponential");

  return (
    <TemplateModal
      open={openErrorHandlingModal}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContainer>
        <ModalHeader>
          <HeaderInfoWrapper>
            <ModalHeaderText> Error Handling </ModalHeaderText>
          </HeaderInfoWrapper>
        </ModalHeader>
        <ModalContent>
          <Controller
            rules={{ required: true }}
            control={control}
            name="isRetryEnabled"
            render={({ field }) => (
              <RadioGroup row {...field}>
                <FormControlLabel
                  value="retryEnabled"
                  control={<Radio />}
                  label={<MainDiscription> Enabled </MainDiscription>}
                />
                <FormControlLabel
                  value="retryDisabled"
                  control={<Radio />}
                  label={<MainDiscription> Disabled </MainDiscription>}
                />
              </RadioGroup>
            )}
          />

          <Box mb={2} />
          <LabelText> Retries </LabelText>
          <InputContainer>
            <ZInput
              onChange={handleValChange}
              type="number"
              register={register}
              validations={{
                required: "API Name is required",
              }}
              name="retries"
              placeholder="Number of retries"
            />
          </InputContainer>
          <Box mb={2} />
          <LabelText> Retry Strategy </LabelText>
          <Controller
            rules={{ required: true }}
            control={control}
            name="exponential"
            render={({ field }) => (
              <RadioGroup row {...field}>
                <FormControlLabel
                  value="uniform"
                  control={<Radio />}
                  label={<MainDiscription> Uniform </MainDiscription>}
                />
                <FormControlLabel
                  value="exponential"
                  control={<Radio />}
                  label={<MainDiscription> Exponential </MainDiscription>}
                />
              </RadioGroup>
            )}
          />

          <Box mb={2} />
          <FlexContainer>
            <Box>
              <LabelText> Delay (in ms) </LabelText>
              <InputContainer>
                <ZInput
                  onChange={handleValChange}
                  type="number"
                  register={register}
                  validations={{
                    required: "API Name is required",
                  }}
                  name="delay"
                  placeholder="Delay in ms"
                />
              </InputContainer>
            </Box>
            {
              // show this only if exponential is selected
             isExponential === "exponential" && (
                <Box>
                  <LabelText> Exponential Factor </LabelText>
                  <InputContainer>
                    <ZInput
                      onChange={handleValChange}
                      type="number"
                      register={register}
                      validations={{
                        required: "API Name is required",
                      }}
                      name="exponentialFactor"
                      placeholder="Delay in ms"
                    />
                  </InputContainer>
                </Box>
              )
            }
          </FlexContainer>
          <Box mb={2} />
          <LabelText> Retry Condition </LabelText>
          <ExecuteCondition
            showExpressionActive={
              selectedAction[EditorScriptLocation.API_ACTION_SHOULD_RETRY]
                ?.value
                ? true
                : false
            }
            conditionLabel="Write Expression to Execute"
            handleClick={() => {
              if (
                selectedAction?.api_action_json_body?.type == "literal" ||
                selectedAction?.api_action_json_body?.type == undefined
              ) {
                const apiBodyData = {
                  api_action_json_body: {
                    type: "literal",
                    value: apiBody,
                    dataType: OBJECT,
                  },
                };

                const tmpSelectedAction = _.cloneDeep(selectedAction);
                Object.assign(tmpSelectedAction, apiBodyData);
                dispatch(setSelectedAction(tmpSelectedAction));
              }

              dispatch(setScriptEditorPayload(ShowCurrentActionPayload));
              openJSEditorLayout({
                config: actionExecutionScriptConfig.ShouldRetry,
                editorLocation: EditorScriptLocation.API_ACTION_SHOULD_RETRY,
              });
            }}
          />
        </ModalContent>
        <ModalFooter>
          <FooterLeftWrapper>
            {/* <DocumentationTxt> Learn More </DocumentationTxt> */}
          </FooterLeftWrapper>
          <FooterRightWrapper>
            {/* <DocumentationTxt onClick={handleModalClose}>
              {" "}
              Close{" "}
            </DocumentationTxt> */}
            <ZButton onClick={handleModalClose} secondary variant="contained">
              {" "}
              Close{" "}
            </ZButton>
            <ZButton type="button" onClick={onSave} variant="contained">
              {" "}
              Submit{" "}
            </ZButton>
          </FooterRightWrapper>
        </ModalFooter>
      </ModalContainer>
    </TemplateModal>
  );
};

export default ErrorHandlingModal;
