import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import MonitorMascot from "assests/monitor_mascot.svg";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { setShowInterfaceUI } from "./reducer/MonitorReducer";

const MonitorCreateConfirmDescription = styled.p`
color: #475467;
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
`;

const MonitorCreateConfirmHeading = styled.p`
color: #101828;
text-align: center;
/* Text lg/Semibold */
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
`;

const CurlFooterRightWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

const ConfirmModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

const ImportCurlHeaderInfoWrapper = styled.div`
  margin-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CurlModalFooter = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  justify-content: space-between;
  display: flex;
  width: 100%;
  height: 100px;
`;

const CurlModalContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: ${(props: { height?: string; width?: string }) =>
    props?.width ? props.width : "420px"};
  // height: ${(props: { height?: string; width?: string }) => props?.height ? props.height : "638px"};
  background: #ffffff; 
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  position: absolute;
  // top: 15%;
  // left: 30%;
  border-radius: 4px;
`;

const CurlModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: #ffffff;
  width: 100%;
  padding: 24px 24px 0px 24px;
`

interface IMonitorCreateConfirmModal {
  openModal: boolean;
  handleClose: () => void;
  onManageMonitor: () => void;
}

const MonitorCreateConfirmModal = ({
  openModal,
  handleClose,
  onManageMonitor
}: IMonitorCreateConfirmModal) => {
  const dispatch = useDispatch();

  return (
    <ConfirmModal open={openModal} onClose={handleClose}>
      <CurlModalContainer>
        <CurlModalHeader>
          <ImportCurlHeaderInfoWrapper>
          <div style={{width:'100%', textAlign:'center', display:'flex', justifyContent:'center'}}>

          <img style={{width:'51px', height:'72px'}} src={MonitorMascot} />
          </div>
            <MonitorCreateConfirmHeading>Hoorah! You're free. BlueBee is now on the lookout</MonitorCreateConfirmHeading>
            <MonitorCreateConfirmDescription>
            Need to adjust your monitoring setup? <br></br> No problem! Head over to My Monitors page
            </MonitorCreateConfirmDescription>
          </ImportCurlHeaderInfoWrapper>
        </CurlModalHeader>


        <CurlModalFooter>
        
          <CurlFooterRightWrapper>
            <Button
              style={{
                color: "#344054",
                fontFamily: "inter",
                padding: "8px 14px",
                fontStyle: "normal",
                fontWeight: 600,
                border: " 1px solid #D0D5DD",
                height: "34px",
                width: "186px",
                textTransform: "none",
              }}
              onClick={()=>{
                onManageMonitor();
                // dispatch(setShowInterfaceUI(true));
              }}
            >
              My Monitors
            </Button>

            <Button
              disableElevation
              variant="contained"
              style={{
                color: "#fff",
                fontFamily: "inter",
                padding: "8px 14px",
                fontStyle: "normal",
                fontWeight: 600,
                height: "34px",
                background: "#3C69E7",
                width: "186px",
                textTransform: "none",
              }}
              onClick={handleClose}
            >
              Alright, Thanks!
            </Button>
          </CurlFooterRightWrapper>
        </CurlModalFooter>
      </CurlModalContainer>
    </ConfirmModal>
  );
};

export default MonitorCreateConfirmModal;
