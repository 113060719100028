import { Dispatch } from "react";
import { IAutomation } from "views/automationBuilder/types/ABType";
import { addAutomationInSmartComponent } from "views/workflows/reducers/workflowAction";

export const saveSmartComponentAutomation = ({ allAutomations, dispatch, stateId,callback }: { allAutomations: IAutomation[], dispatch: Dispatch<any>, stateId: string,callback:() => void }) => {
    const eventDetails: any = {
        key: "screen_automation_details",
        stateId: stateId,
        data: allAutomations
    };

    // this is the place we need to set 
    // @ts-ignore
    document.getElementById('app-builder')?.contentWindow?.postMessage(eventDetails, '*');
    callback()
}  