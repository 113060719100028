import { Function } from "lodash";
import { useEffect, useState } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { FiChevronsLeft, FiDownload } from "react-icons/fi";
import styled from "styled-components"
import { device, getFileExtension } from "utils/CommonUtils";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import { FileIcon, defaultStyles } from 'react-file-icon';
import { ZIconButton2 } from "views/entities/styles";

const slideStyles = {
//   width: "70%",
  height: "100%",
  borderRadius: "10px",
  backgroundSize: "cover",
  backgroundPosition: "center",
  margin:"auto"
};

// const rightArrowStyles = {
//   top: "50%",
//   transform: "translate(0, -50%)",
//   right: "32px",
//   fontSize: "45px",
//   color: "red",
//   zIndex: 1,
//   cursor: "pointer",
// };

// const leftArrowStyles = {
//   top: "50%",
//   transform: "translate(0, -50%)",
//   left: "32px",
//   fontSize: "45px",
//   color: "red",
//   zIndex: 1,
//   cursor: "pointer",
// };

const sliderStyles = {
  height: "100%",
};

const DotContainer = styled.div`
    display: flex;
  justify-content:center;

  margin: 20px auto;
  padding: 10px;
  overflow : auto;
  background-color: rgba(0,0,0,0.5);

  @media ${device.mobileM} {
        width: 90%;
  }
`

// const dotStyle = {
//   margin: "0 3px",
//   cursor: "pointer",
//   fontSize: "20px",
// };


// const SmallImage = styled.image`
//     object-fit: fill;
//     height:100px;
//     width: 100px;

// `

type Props = {
    slides: {url:string, title:string}[],
    startIndex: number,
    onClose: () => void,
}

const NavigationButton = styled.div`
    background : rgb(0,0,0,0.5);
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    gap: 15px;
    padding: 10px;
`

const ImageSlider = ({ slides,startIndex,onClose }:Props) => {
  const [currentIndex, setCurrentIndex] = useState(() => startIndex || 0);
  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  const goToSlide = (slideIndex:number) => {
    setCurrentIndex(slideIndex);
  };
  const slideStylesWidthBackground = {
    ...slideStyles,
    // backgroundImage: `url(${slides[currentIndex].url})`,
  };

  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 500;
  useEffect(() => {
   const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            onClose();
        }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener on component unmount
    return () => {
        window.removeEventListener('keydown', handleKeyDown);
    };
}, [onClose]);

  return (
    <div style={{  position: "relative",...sliderStyles}}>
        <NavigationButton>
            <div>
              {
                width > breakpoint ? (
                  //@ts-ignore
                  <ZButton secondary variant="contained" onClick={onClose} startIcon={width > breakpoint ? <FiChevronsLeft/> : <></>} >
                    { width > breakpoint ? "Go Back" : <FiChevronsLeft/> } 
                </ZButton>
                ) : (
                  //@ts-ignore
                  <ZIconButton2 onClick={onClose} > 
                      <FiChevronsLeft/>
                  </ZIconButton2>
                )
              }
                
            </div>
            <div>
                {
                  width > breakpoint ? (
                    <ZButton className="mr-2" onClick={goToPrevious} startIcon={width > breakpoint ? <AiOutlineArrowLeft/> : <></>} secondary variant="contained"> 
                      { width > breakpoint ? "Previous Image " : <AiOutlineArrowLeft/> } 
                    </ZButton>
                  ) : (
                    <ZIconButton2 className="mr-2" onClick={goToPrevious}>
                        <AiOutlineArrowLeft/>
                    </ZIconButton2>
                  )
                }
                {
                  width > breakpoint ? (
                    <ZButton onClick={goToNext} endIcon={width > breakpoint ? <AiOutlineArrowRight /> : <></>} secondary variant="contained"> 
                      { width > breakpoint ? "Next Image " : <AiOutlineArrowRight /> } 
                    </ZButton>
                  ) : (
                    <ZIconButton2 onClick={goToNext}>
                        <AiOutlineArrowRight />
                    </ZIconButton2>
                  )
                }
                
            </div>
            {/* <div>
                
            </div> */}
            <div>
              {
                width > breakpoint ? (
                  <ZButton startIcon={width > breakpoint ? <FiDownload/> : <></>} secondary variant="contained">
                    <a href={slides[currentIndex].url}
                        download={`Image ${currentIndex}`}
                        rel="noreferrer"
                        target="_blank">
                        { width > breakpoint ? "Download Image " : <FiDownload/>  } 
                    </a>  </ZButton>
                ) : (
                  <ZIconButton2 >
                      <a href={slides[currentIndex].url}
                        download={`Image ${currentIndex}`}
                        rel="noreferrer"
                        target="_blank">
                        { width > breakpoint ? "Download Image " : <FiDownload/>  } 
                    </a>  
                  </ZIconButton2>
                )
              }
            
            </div>
        </NavigationButton>
      {/* <div>
        <div onClick={goToPrevious} style={{position: 'absolute',...leftArrowStyles}}>
          ❰
        </div>
        <div onClick={goToNext} style={{position: 'absolute',...rightArrowStyles}}>
          ❱
        </div>
      </div> */}
      <div style={slideStylesWidthBackground} onClick={onClose}>
        {
            slides[currentIndex].url ? (
                <img 
                    alt="main" 
                    style={{height:"100%",margin:"0 auto", objectFit:"fill", borderRadius:"10px"}} 
                    src={slides[currentIndex].url} 
                    onClick={(e) => e.stopPropagation()}
                /> 
            ) : (
                <div onClick={(e) => e.stopPropagation()}>
                    <FileIcon 
                        extension={getFileExtension(slides[currentIndex].url)} 
                        {...defaultStyles.docx} 
                    />
                </div>
            )
        }
    </div>

      <DotContainer>
        {slides.map((slide, slideIndex) => {
          return (
            <div
            style={{ height:"100px", borderRadius:"10px", minWidth:"100px",maxWidth:"100px"}}
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
            
          >
            {
                slide.url ? (
                    <img alt="sub" style={{height:"80px", width:"80px", objectFit:"fill",borderRadius:"10px", border:slideIndex === currentIndex ? "3px solid #3E66FB" : "" }} src={slide.url}/>
                ) : (
                    // <img alt="sub" style={{height:"80px", width:"80px",borderRadius:"10px", objectFit:"fill"}} src={"https://zorp-assets.s3.ap-south-1.amazonaws.com/Zorp_file_icon.png"}/>
                    <FileIcon extension={getFileExtension(slide.url)} {...defaultStyles.docx}/>
                )
            }
            
          </div>
          )
          
})}
      </DotContainer>
    </div>
  );
};

export default ImageSlider;