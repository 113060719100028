import { Button, ButtonGroup, Grid, makeStyles, Paper, CircularProgress } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Location from './components/Location'
// import { useGetIdentity } from 'react-admin';
// import InventoryStoreLocation from './components/InventoryStoreLocation';

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { UserService } from "../../providers/data/services/UserService";
import Order from './components/Order';
import TaskType from './components/Tasktype';
import TimeSlotSelection from './components/TimeSlotSelection';
import { TaskService } from './services/TaskService';
import notification from '../../notifications/notifications';
import authProvider from '../../providers/auth/authProvider';
import { ValidatorForm } from "react-material-ui-form-validator";
import { TeamValidator } from './components/Validator';
import { doesTaskTypehavePickUpLocation, isNonInventoryTaskType } from '../../utils/TasktypeUtils';
import { recordPageVisit, PageCategoryEnum } from '../../config/Rudderstack';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 2,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
        boxShadow: "none",
        backgroundColor: 'transparent'
    },
}));

const CreateTaskOld = (props) => {
    const [selectedTaskType, setSelectedTaskType] = React.useState(props.taskType || '');
    const [isInventoryTaskType, setisInventoryTaskType] = React.useState(false);
    const [drivers, setDrivers] = React.useState([]);
    const [driversAll, setDriversAll] = React.useState([]);
    const [driver, setDriver] = React.useState(null);
    const [customerInfo, setCustomerInfo] = React.useState({
        'phone': '',
        'phoneCode': '',
        'name': '',
        'address': '',
        'lat': null,
        'lon': null
    });

    const [pickupInfo, setPickupInfo] = React.useState({
        'phone': '',
        'phoneCode': '',
        'name': '',
        'address': '',
        'lat': null,
        'lon': null
    });

    const [order, setOrder] = React.useState({
        'orderId': "",
        'delInstructions': "",
        'products': {
            'DELIVERY': [],
            'REPAIR': [],
            'PICKUP': []
        }
    });
    const [deliverySlot, setDeliverySlot] = React.useState({
        'fromDateTime': null,
        'toDateTime': null
    });

    // const [warehouseLocation, setWarehouseLocation] = React.useState({});
    const [deliveryError, setDeliveryError] = React.useState(false);
    const [taskCreateProgress, setTaskCreateProgress] = React.useState(false);
    const [teamId, setTeamId] = React.useState("");
    const [disableCreateButton, setDisableCreateButton] = React.useState(false);

    const history = useHistory();
    const classes = useStyles();

    useEffect(() => {
        recordPageVisit(PageCategoryEnum.task, "single_task_create");
        setSelectedTaskType(props.taskType)
        handleTaskTypeChange(props.taskType)
    }, [props])


    //   useEffect(() => {      
    ValidatorForm.addValidationRule("teamIdCheck", (value) => {
        if (teamId && teamId.length > 0) {
            return true;
        } else {
            return false;
        }
    });
    //   }, [props]);

    useEffect(() => {
        selectUsers(teamId);
    }, [driversAll]);

    //   useEffect(() => {
    //     if (driver === "1") setDriver(null);
    //     UserService.userSelect(teamId)
    //       .then((res) => {
    //         var i = 0;
    //         const drivers_array = [];
    //         for (i = 0; i < res?.data?.users?.length; i++) {
    //           const item = res?.data?.users[i];
    //           const driver_single = {
    //             id: item.userId,
    //             name: item.name,
    //             teamIds: item.teamIds
    //           };
    //           drivers_array.push(driver_single);
    //         }

    //         // setDrivers(drivers_array);      
    //         setDriversAll(drivers_array);
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   }, []);
    useEffect(() => {
        if (driver === null) setDriver(null);
        UserService.userSelect(teamId)
            .then((res) => {
                var i = 0;
                const drivers_array = [];
                for (i = 0; i < res?.data?.users?.length; i++) {
                    const item = res?.data?.users[i];
                    const driver_single = {
                        id: item.userId,
                        name: item.name,
                        teamIds: item.teamIds
                    };
                    drivers_array.push(driver_single);
                }

                // setDrivers(drivers_array);      
                setDriversAll(drivers_array);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [teamId]);

    const handleTeamIdChange = (tId) => {
        setTeamId(tId);
        setDriver(null);

        selectUsers(tId);
    }

    const selectUsers = (tId) => {
        // filter the users based on selected team
        if (tId) {
            let users = [];
            driversAll.filter(d => d.teamIds.includes(tId)).map(d => users.push(d));
            setDrivers(users);
        }
        else
            setDrivers(null);
    }


    const handleUserChange = (e) => {
        // console.log(e.target.value);
        setDriver(e.target.value);
    };
    const handleTaskTypeChange = (t) => {
        if (t === '') setSelectedTaskType('');
        else setSelectedTaskType(t?.taskType || t);

        if (!isNonInventoryTaskType(t))
            setisInventoryTaskType(true);
        else setisInventoryTaskType(false);
    }
    const handleCustomerInfoChange = (t) => { setCustomerInfo(t); }

    const handlePickupInfoChange = (t) => { setPickupInfo(t); }


    const handleDeliverySlotChange = (t) => { setDeliverySlot(t); }
    // const handleWarehousePickupLocationChange = (t) => {setWarehouseLocation(t);}
    const handleOrderChange = (t) => {
        setOrder(t);
    }

    useEffect(() => {
        if (deliverySlot?.fromDateTime !== null && deliverySlot?.toDateTime !== null) {
            if (deliverySlot?.fromDateTime < deliverySlot?.toDateTime)
                setDeliveryError(true);
            else
                setDeliveryError(false);
        }
        else
            setDeliveryError(false);
    }, [deliverySlot.fromDateTime, deliverySlot.toDateTime])

    // useEffect(() => {
    //     ValidatorForm.addValidationRule('addressCheck', (value) => {
    //         var flag1 = false;
    //         var flag2 = false;
    //         if(customerInfo.customerAddress !== '') flag1 = true;
    //         if(customerInfo.customerLat != null && customerInfo.customerLong != null) flag2 = true

    //         if(flag1 === true || flag2 === true)
    //             return true;
    //         else
    //             return false;
    //     });
    // }, [customerInfo.customerAddress, customerInfo.customerLat, customerInfo.customerLong])

    // useEffect(() => {
    //     if(selectedTaskType === "field_visit_task" || selectedTaskType === "pickup_and_drop_task")
    //         setisInventoryTaskType(true);
    //     else
    //         setisInventoryTaskType(false);
    //     console.log("Show Flag", isInventoryTaskType);
    // }, [selectedTaskType])


    const cancelTask = () => {
        notification('info', 'Task creation cancelled!');
        history.push("/tasks");
    }

    const createTask = () => {
        setDisableCreateButton(true);

        //TODO: This has to be moved to order component as a validation
        // Check if either of delivery, pickup or repair have atleast one element
        if (order != null && order.products != null && !isNonInventoryTaskType(selectedTaskType)) {
            const hasAtleastOneOrder = order.products.DELIVERY.length > 0 || order.products.REPAIR.length > 0 || order.products.PICKUP.length > 0;
            if (!hasAtleastOneOrder) {
                notification('error', "Task needs to have atleast one product");
                setDisableCreateButton(false);
                return;
            }
        }

        const orderInfo = {
            "orderId": order.orderId,
            "customer": {
                "name": customerInfo['name'],
                "location": {
                    "latitude": customerInfo?.lat === '' ? null : parseFloat(customerInfo?.lat),
                    "longitude": customerInfo?.lon === '' ? null : parseFloat(customerInfo?.lon),
                    "address": customerInfo?.address
                },
                "phone": {
                    "countryCode": "+" + customerInfo['phoneCode'],
                    "num": customerInfo['phone']
                },
            }
        }

        if (doesTaskTypehavePickUpLocation(selectedTaskType)) {
            orderInfo['pickup'] = {
                "name": pickupInfo['name'],
                "location": {
                    "latitude": pickupInfo?.lat === '' ? null : parseFloat(pickupInfo?.lat),
                    "longitude": pickupInfo?.lon === '' ? null : parseFloat(pickupInfo?.lon),
                    "address": pickupInfo?.address
                },
                "phone": {
                    "countryCode": "+" + pickupInfo['phoneCode'],
                    "num": pickupInfo['phone']
                },
            }
        }

        const taskPayload = {
            "taskType": selectedTaskType,
            "teamIds": [teamId],
            "scheduledSlot": {
                "from": new Date(deliverySlot.fromDateTime).toISOString(),
                "to": new Date(deliverySlot.toDateTime).toISOString()
            },
            "data": [
                {
                    ...order,
                    "info": orderInfo,
                }
            ],
            "userId": driver
        };
        setTaskCreateProgress(true);

        const resultData = TaskService.createTask(taskPayload);
        resultData.then((res) => {
            // console.log(res);
            if (res?.code && res?.code.indexOf("200") < 0) {
                setDisableCreateButton(false);
                // Error
                if (res?.code === "401") {
                    notification('error', res.message);
                    authProvider.logout();
                }
                else {
                    notification('error', res.message);
                }
            }
            else {
                setDisableCreateButton(false);
                notification('success', res.message);
                history.push(`/tasks/${res.data.taskId}/show?legacy=true`);
            }
        })
            .catch((err) => {
                setDisableCreateButton(false);
                console.log("Error", err);
                notification('error', 'Task cannot be created!');
            })
    }

    return (
        <div className="flex flex-col justify-start	px-1 md:px-2">
            <h2 className="custom-lf-2 font-bold text-2xl text-md-center">Create Task</h2>
            <br />
            <div className="zorp-card">
                {/* <Grid container className="custom-lf">
                    <Grid item >
                        <h3 className="mt-7 font-bold">Task Type : </h3>
                    </Grid>
                    <Grid item>
                        <Paper className={classes.paper}>
                            <TaskType onChange={handleTaskTypeChange} disabled={false} type={selectedTaskType} />
                        </Paper>
                    </Grid>
                </Grid> */}
                <Grid container className="pl-6">
                    <div className="bt-row">
                        <div className="bt-col-md-6">
                            <div className="bt-row">
                                <div className="bt-col-md-4">
                                    <strong> Workflow </strong>
                                </div>
                                <div className="bt-col-md-8">
                                    <TaskType
                                        onChange={handleTaskTypeChange}
                                        size="full"
                                        disabled={false}
                                        type={selectedTaskType}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </div>
            {
                selectedTaskType !== '' &&
                (
                    <ValidatorForm
                        onSubmit={createTask}
                        onError={errors => console.log(errors)}
                    >

                        <div className="zorp-card">
                            <Grid container className="pl-6">
                                <div className="bt-row">
                                    <div className="bt-col-md-6">
                                        <div className="bt-row">
                                            <div className="bt-col-md-4">
                                                <strong>Team </strong>
                                            </div>
                                            <div className="bt-col-md-6">
                                                <TeamValidator
                                                    id="teamField"
                                                    onChange={handleTeamIdChange}
                                                    disabled={false}
                                                    team={teamId}
                                                    size="full"
                                                    // style={{ minWidth: "300px", maxWidth: "300px" }}
                                                    validators={["teamIdCheck"]}
                                                    errorMessages={["Team field is required"]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </div>
                        <div className="zorp-card">
                            <Grid container className="pl-6">
                                <div className="bt-row">
                                    <div className="bt-col-md-6">
                                        <div className="bt-row">
                                            <div className="bt-col-md-4">
                                                <strong>User</strong>
                                            </div>
                                            <div className="bt-col-md-6">
                                                <Select
                                                    labelId="demo-controlled-open-select-label"
                                                    id="demo-controlled-open-select"
                                                    value={driver}
                                                    className="form-control"
                                                    onChange={(e) => handleUserChange(e)}
                                                    style={{ minWidth: "300px" }}
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    {drivers?.map((item) => (
                                                        <MenuItem value={item.id}>{item.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </div>
                        {/* <Grid container item justify="flex-start">
                            <div style={{ width: "100%" }} className="zorp-card mt-2">
                                <h3 className="custom-lf"><strong>Team</strong></h3>
                                <div className="pt-4 ml-6">
                                    <TeamValidator
                                        id="teamField"
                                        onChange={handleTeamIdChange}
                                        disabled={false}
                                        team={teamId}
                                        size="small"
                                        style={{ minWidth: "300px", maxWidth: "300px" }}
                                        validators={["teamIdCheck"]}
                                        errorMessages={["This field is required"]}
                                    />
                                </div>
                            </div>
                        </Grid> */}

                        <Grid container item justify="flex-start">
                            <div style={{ width: "100%" }} className="zorp-card mt-2">
                                <h3 className="custom-lf"><strong>Scheduled Slot</strong></h3>
                                <TimeSlotSelection slots={deliverySlot} onChange={handleDeliverySlotChange}></TimeSlotSelection>
                            </div>
                        </Grid>

                        {doesTaskTypehavePickUpLocation(selectedTaskType) && <Grid container item xs={12} justify="flex-start">
                            <div className="zorp-card mt-2" style={{ width: "100%" }}>
                                <Location info={pickupInfo} onChange={handlePickupInfoChange} label="Pickup"></Location>
                            </div>
                        </Grid>}


                        <Grid container item xs={12} justify="flex-start">
                            <div className="zorp-card mt-2" style={{ width: "100%" }}>
                                <Location info={customerInfo} onChange={handleCustomerInfoChange} label={doesTaskTypehavePickUpLocation(selectedTaskType) ? "Drop" : "Customer"}></Location>
                            </div>
                        </Grid>

                        {/* <Grid container item xs={12} justify="flex-start">
                            <h4>Warehouse Location (for pickup)</h4>
                            <InventoryStoreLocation info={warehouseLocation.name} onChange={handleWarehousePickupLocationChange}></InventoryStoreLocation>
                        </Grid> */}

                        <Grid container item xs={12} justify="flex-start">
                            <div className="zorp-card mt-2">
                                {(selectedTaskType === "field_visit_task" || selectedTaskType === "pickup_and_drop_task") ?
                                    <h3 className="custom-lf"><strong>Task Details</strong></h3> :
                                    <h3 className="custom-lf"><strong>Order Details</strong></h3>
                                }
                                <Order taskType={selectedTaskType} isInventoryTask={isInventoryTaskType} order={order} onChange={handleOrderChange}></Order>
                            </div>
                        </Grid>

                        <Grid container item xs={12} justify="flex-start">
                            <ButtonGroup size="small" variant='outlined'>
                                <Button type="submit" disabled={disableCreateButton} variant="contained" color="primary" style={{ marginRight: "10px", height: "50px", width: "120px", marginTop: "10px" }}>
                                    Create
                                </Button>
                                <Button variant="contained" onClick={cancelTask} style={{ height: "50px", width: "120px", marginTop: "10px" }} color="secondary">
                                    Cancel
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </ValidatorForm>
                )}
        </div>
    )
}

export default CreateTaskOld;