import { UserService } from "providers/data/services/UserService";

export function hasPermission(resource, action) {
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const isPermitted = permissions && permissions?.resourceActions?.filter (ra => {        
        if (ra.resource === resource){
            for (let i=0; i < ra.actions.length; i++){
                if (ra.actions[i].id === action)
                    return true;
            }
            return false;
        }
        else
            return false; 
    }).length > 0;
    return isPermitted;
}

export function isUserHasAdminRole ()   {
    const value = localStorage.getItem("permissions");
    if (value){
        const permissions = JSON.parse(value || '');
        return permissions.role === 'admin';
    }else {
        return false;
    }
}

export function basicAuthLogin (userName, password) {
    const body = { userId: userName, password: password };
    const headers = {
      'Content-Type': 'application/json',
    };
    return UserService.login(userName, body, headers);
}

export function googleOAuthLogin (email, token) {
    return UserService.loginWithGoogleOAuth(email, token);
}


export function validateLoginOTP (userName, otpCode) {
    return UserService.validateLoginOTP(userName, otpCode);
}