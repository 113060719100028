import React from "react";
import {Label,SelectType} from "../DataField.styles"
import MenuItem from '@mui/material/MenuItem';
import {Controller} from "react-hook-form"

type BooleanFieldTypeProp = {
  control:any;
  defaultValueName?:string
}

const BooleanField = ({control,defaultValueName="defaultValue"}:BooleanFieldTypeProp) => {
  
  return ( 
    <>
        <Label> Default Value </Label>
        <Controller  
              render={({ field }) => (
               
                <SelectType defaultValue={"true"} {...field}>
                  {/* @ts-ignore */}  
                  <option value={"true"}>True</option>
                  {/* @ts-ignore */}  
                  <option value={"false"}>False</option> 
                </SelectType>
              )}  
              name={defaultValueName}
              control={control}
              
            />
             
    </>
  ) 
}

export default BooleanField