export type IScriptReturnParams = {
  returnType: any;
  sample: any;
};

/**
 * action condition check old is to accomodate those configurations which has old key
 * to store action conditions
 */

export enum EditorScriptLocation {
  BODY = 'body',
  API_ACTION_JSON_BODY = 'api_action_json_body',
  PUSH_NOTIFICATION_TITLE = 'title',
  PUSH_NOTIFICATION_MESSAGE = 'message',
  LOCATION_CHECK_USER_LATITUDE = 'latitude',
  LOCATION_CHECK_USER_LONGITUDE = 'longitude',
  LOCATION_CHECK_CUSTOMER_LATITUDE = 'customerLatitude',
  LOCATION_CHECK_CUSTOMER_LONGITUDE = 'customerLongitude',
  LOCATION_CHECK_EXPECTED_DISTANCE = 'expectedDistance',
  EXPRESSION_CONDITION_EXPRESSION_TO_VALIDATE = 'expression',
  CONDITION_CHECK = 'condition',
  UPDATE_DATA_FIELD = 'UPDATE_DATA_FIELD',
  UPDATE_DATA_FIELD_CONDITION_CHECK = 'condition',
  LOCATION_GUARD_CONDITION = 'condition',
  EXPRESSION_GUARD_CONDITION = "condition",
  ERROR_MESSAGE = "errorMessage",
  AB_CONDITION = 'ab_condition',
  AB_SMART_CONDITION_VALUE = 'value',
  AB_SMART_ACTION_VALUE = 'value',
  AB_SMART_ACTION_JS_FUNCTION = 'ab_action',
  AB_API_ACTION_URL = 'url',
  UPDATE_TEAM_TEAMIDS = 'value',
  UPDATE_TEAM_USERID = 'userId',
  HEADERS = 'headers',
  ENTITY_COMPUTED_COLUMN = 'computedColumn',
  BUTTON_AUTOMATION_URL = 'buttonAutomationUrl',
  API_ACTION_SHOULD_RETRY = 'shouldRetry',
}

export type IScriptEditorConfig = {
  variables: Array<any>;
  mockData: any;
  script: string;
  returnParams: IScriptReturnParams;
};

export type IScriptEditorUpdateScript = {
  script: string;
};

export enum LowCodeInvocation {
  SCREEN_TRANSITION = 'SCREEN_TRANSITION',
  SYSTEM_TRANSITION = 'SYSTEM_TRANSITION',
  DECISION_NODE = 'DECISION_NODE'
}