import CircularProgress from "@mui/material/CircularProgress";
import monitorFineTuneBlue from "assests/monitor_fine_tune_blue.svg";
import { RudderStackAutomationBuilderEvents } from "constants/RudderStackConstants";
import React from "react";
import styled from "styled-components";
import { recordRSEvent } from "utils/CommonUtils";
interface ITextTitleProps {
    color?: string;
  }
  
  const TextTitle = styled.p<ITextTitleProps>`
    color: ${props => props.color || '#475467'};
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 150% */
  `;
const IconTextWrapper = styled.div`
  display: flex;
  gap: 6px;
`;

const BottomContainerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const LeftIconWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

interface IMonitorTemplateFooter {
    isCreateMonitorLoading: boolean;
    isMonitorExecuteLoading: boolean;
    onCreateClick: () => void;
}

const MonitorCardFooter = ({
    isCreateMonitorLoading,
    onCreateClick
}: IMonitorTemplateFooter) => {
    return (
        <BottomContainerWrapper>

            {isCreateMonitorLoading ? (
                <CircularProgress />
            ) : (
                <IconTextWrapper
                    className="pointer"
                    onClick={() => {
                        recordRSEvent(RudderStackAutomationBuilderEvents.aiModalMonitorThis, {
                            context: RudderStackAutomationBuilderEvents.monitorContext
                          });
                        onCreateClick();
                    }}
                >
                    <img width={"18px"} src={monitorFineTuneBlue} />
                    <TextTitle color="#3054B9">Alert Me</TextTitle>
                </IconTextWrapper>
            )}
        </BottomContainerWrapper>
    );
};

// export default MonitorCardFooter;
export default React.memo(MonitorCardFooter);