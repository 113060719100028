import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import styled from "styled-components";
import { AiOutlineClose } from "react-icons/ai";
import { SpecialText } from "./logs.styled";

const Wrapper = styled.div`
  background-color: white;
  height: 90%;
  width: 80%;
  padding: 16px 32px;
  border-radius: 8px;
`;

const Heading = styled.p`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #000000;
`;

const Text = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #000000;
`;

const SubHeading = styled.p`
  text-align: center;
  font-size: 16px;
  font-weight: 500;
`

type Props = {
  actionName?: string;
  actionType?: string;
  description?: string;
  headers?: string;
  url?: string;
  errorMessage?: string;
  guardStatus?: boolean;
  response?: {
    body: string;
    headers: string;
    status: string;
  };
  request?: {
    body: string;
    headers: string;
    url: string;
  };
};

const RestApiInfo = ({
  actionName,
  actionType,
  description,
  headers,
  url,
  response,
  errorMessage,
  guardStatus,
  request,
}: Props) => {
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const [bodyIsAValidJson, setBodyIsAValidJson] = useState(true);
  const [requestBodyIsAValidJson, setRequestBodyIsAValidJson] = useState(true);
  const [requestHeadersIsAValidJson, setRequestHeadersIsAValidJson] =
    useState(true);
  const [headersIsAValidJson, setHeadersIsAValidJson] = useState(true);

  useEffect(() => {
    try {
      JSON.parse(response?.body || "");
    } catch (e) {
      setBodyIsAValidJson(false);
    }
    try {
      JSON.parse(response?.headers || "");
    } catch (e) {
      setHeadersIsAValidJson(false);
    }
    try {
      JSON.parse(request?.body || "");
    } catch (e) {
      setRequestBodyIsAValidJson(false);
    }
    try {
      JSON.parse(request?.headers || "");
    } catch (e) {
      setRequestHeadersIsAValidJson(false);
    }
  }, []);

  return (
    <>
      <SpecialText onClick={showModal}>
        Show More
      </SpecialText>
      {open && (
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <>
            <Wrapper>
              <div className="">
                <Heading>
                  {" "}
                  Action Details
                  <AiOutlineClose
                    onClick={() => setOpen(false)}
                    style={{ float: "right", cursor: "pointer" }}
                  />
                </Heading>
                {/* <div> */}

                {/* </div> */}
              </div>

              <p className="mb-2">
                {" "}
                <Text>Action Name : </Text> {actionName}{" "}
              </p>
              <p className="mb-2">
                {" "}
                <Text> Description : </Text> {description}{" "}
              </p>
              <p className="mb-2">
                {" "}
                <Text> State : </Text> {response?.status}{" "}
              </p>
              <p> </p>

              {request?.url && (
                <p className="mb-2">
                  {" "}
                  <Text> Request URL : </Text> {request?.url}{" "}
                </p>
              )}
              {guardStatus === false && errorMessage && (
                <p className="mb-2">
                  {" "}
                  <Text> Error Message : </Text> {errorMessage}{" "}
                </p>
              )}
              
              <div
                style={{
                  height: "80%",
                  overflowY: "auto",
                  // display: "grid",
                  // gridTemplateColumns: "50% 50%",
                }}
              >
                {/* Request */}
                <SubHeading> API Request </SubHeading>
                <div>
                  <div className="mb-4">
                    <Text> Request Body </Text>
                    {requestBodyIsAValidJson ? (
                      <ReactJson
                        // collapsed={true}
                        displayDataTypes={false}
                        //   style={{ overflow: "auto", maxHeight: "400px" }}
                        name={false}
                        src={JSON.parse(request?.body || "") || {}}
                      />
                    ) : (
                      <p> {request?.body} </p>
                    )}
                  </div>
                  {/* <div className="mb-4">
                    <Text> Request Header </Text>
                    {requestHeadersIsAValidJson ? (
                      <ReactJson
                        // collapsed={true}
                        displayDataTypes={false}
                        //   style={{ overflow: "auto", maxHeight: "400px" }}
                        name={false}
                        src={JSON.parse(request?.headers || "") || {}}
                      />
                    ) : (
                      <p> {request?.headers} </p>
                    )}
                  </div> */}
                </div>
                {/* Response */}
                <SubHeading> API Response </SubHeading>
                <div>
                  <div className="mb-4">
                    <Text> Response Body </Text>
                    {bodyIsAValidJson ? (
                      <ReactJson
                        // collapsed={true}
                        displayDataTypes={false}
                        //   style={{ overflow: "auto", maxHeight: "400px" }}
                        name={false}
                        src={JSON.parse(response?.body || "") || {}}
                      />
                    ) : (
                      <p> {response?.body} </p>
                    )}
                  </div>
                  <div className="mb-4">
                    <Text> Response Headers </Text>
                    {headersIsAValidJson ? (
                      <ReactJson
                        // collapsed={true}
                        displayDataTypes={false}
                        //   style={{ overflow: "auto", maxHeight: "400px" }}
                        name={false}
                        src={JSON.parse(response?.headers || "") || {}}
                      />
                    ) : (
                      <p> {response?.headers} </p>
                    )}
                  </div>
                </div>
              </div>
              
            </Wrapper>
          </>
        </Modal>
      )}
    </>
  );
};

export default RestApiInfo;
