import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditMonitorMenu from 'assests/edit_monitor.svg';
import MonitorThreeDotMenu from 'assests/monitor_three_dot_menu.svg';
import PauseMenu from 'assests/pause_monitor.svg';
import { RudderStackAutomationBuilderEvents } from 'constants/RudderStackConstants';
import * as React from 'react';
import styled from "styled-components";
import { recordRSEvent } from 'utils/CommonUtils';

const MenuText = styled.p`
color: var(--Gray-900, #101828);
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 18px; /* 150% */
`
export enum IMonitorContentMenu {
    EDIT_MONITOR="Edit Alert",
    PAUSE_MONITOR = "Pause Alert"
}

interface IMonitorListDetailPageContextMenu {
    onClickEdit : (menuContext:IMonitorContentMenu) =>void;
    monitorStatus:boolean;
}

const MonitorListDetailPageContextMenu = ({onClickEdit, monitorStatus}:IMonitorListDetailPageContextMenu) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                style={{
                    height: '36px',
                    width: '36px',
                    padding: '0px',
                    margin: '0px',
                    border: '1px solid #D0D5DD'
                }}
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <img src={MonitorThreeDotMenu} alt='Icon' />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: '184px',
                        width: '248px',
                        paddingLeft: '8px'
                    },
                    elevation: 0
                }}
            >


                <MenuItem style={{ height: '44px' }} onClick={()=>{
                    recordRSEvent(RudderStackAutomationBuilderEvents.click_edit_monitor, {
                        context: RudderStackAutomationBuilderEvents.monitorContext
                      });
                    handleClose();
                    onClickEdit(IMonitorContentMenu.EDIT_MONITOR);
                }}>
                    <img src={EditMonitorMenu} alt='Icon' style={{ marginRight: '8px' }} />
                    <MenuText>{IMonitorContentMenu.EDIT_MONITOR}</MenuText>
                </MenuItem>



                <MenuItem style={{ height: '44px' }} onClick={()=>{

                    if (monitorStatus) {
                        recordRSEvent(RudderStackAutomationBuilderEvents.deActivateMonitor, {
                            context: RudderStackAutomationBuilderEvents.monitorContext
                        });
                    } else {
                        recordRSEvent(RudderStackAutomationBuilderEvents.enableMonitor, {
                            context: RudderStackAutomationBuilderEvents.monitorContext
                        });
                    }
                    handleClose();
                    onClickEdit(IMonitorContentMenu.PAUSE_MONITOR);
                }}>
                    <img src={PauseMenu} alt='Icon' style={{ marginRight: '8px' }} />
                    <MenuText> {monitorStatus ? 'Disable Alert' : 'Activate Alert'} </MenuText>
                </MenuItem>



            </Menu>
        </div>
    )
}

// export default MonitorListDetailPageContextMenu
export default React.memo(MonitorListDetailPageContextMenu);