import React, {memo} from "react"
import styled from "styled-components"
import { Wrapper } from "./ListOfValueBadges"
import { FaExternalLinkAlt as ExternalLink } from "react-icons/fa";

type Props = {
  value: string
  color: string
  onClick?: () => void
  width?: string
}

const NoWrapSpan = styled.span`
  white-space: nowrap;
  font-size: 11px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
`

const RelationCellBadge = ({ value, color, onClick, width }: Props) => {
  return (
    <span onClick = {onClick} style={{cursor: "pointer", display: 'inline-flex', marginLeft: '8px', width: width ? width : 'max-content'}}>
    <Wrapper value={value} color={color} underline={true}>
        <NoWrapSpan> {value} </NoWrapSpan>
        <ExternalLink style={{minWidth: "10px", minHeight: "10px"}} />
    </Wrapper>
    </span>
  )
}

export default memo(RelationCellBadge);
