import React, { useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import { Button, ButtonGroup, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextValidator, ValidatorForm, SelectValidator } from "react-material-ui-form-validator";
import notification from "../../../notifications/notifications";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 450,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  addButton: {
    margin: "10px 10px 10px 10px",
    width: "70px",
    float: "right",
  },
}));

export default function ProductEditModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [productCategory, setProductCategory] = React.useState(props?.editProduct?.productCategory ? props.editProduct.productCategory : null);
  const [qty, setQty] = React.useState(props?.editProduct?.qty ? props.editProduct.qty : null);
  const [sku, setSku] = React.useState(
    props?.editProduct?.sku ? props.editProduct.sku : ""
  );
  const [productId, setProductId] = React.useState(
    props?.editProduct?.productId ? props.editProduct.productId : ""
  );
  const [productName, setProductName] = React.useState(
    props?.editProduct?.productName ? props.editProduct.productName : ""
  );
  const [productImageUrl, setProductImageUrl] = React.useState(
    props?.editProduct?.productImageUrl ? props.editProduct.productImageUrl : ""
  );

  const closeModal = () => {
    props.onClose();
    setOpen(false);
  };

  console.log ("***********2183838")

  // useEffect(() => {
  //     ValidatorForm.addValidationRule('specialCharCheck', (value) => {
  //         return !/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/.test(value);
  //     })

  //     ValidatorForm.addValidationRule('requiredCheck', (value) => {
  //         console.log("Check check");
  //         if(value !== '')
  //         {
  //             console.log("TRUE");
  //             return true;
  //         }
  //         else
  //         {
  //             console.log("FALSE");
  //             return false;
  //         }
  //     })
  // }, [sku, productName])

  // const editProduct = (e) => {
  //   e.preventDefault();
  //   if (!sku) {
  //     notification("error", "Empty fields not allowed");
  //     setOpen(false);
  //     props.onClose();
  //   } else {
  //     const addedProduct = {
  //       sku: sku,
  //       productId: productId,
  //       productName: productName,
  //       productImageUrl: productImageUrl,
  //     };
  //     props.onEdit(addedProduct);
  //     setOpen(false);
  //     props.onClose();
  //   }
  // };


  const editProduct = (e) => {
    e.preventDefault();
    if (!productCategory) {
      notification("error", "Product Category cannot be empty");
      // setOpen(false);
      // props.onClose();
    } else if (productCategory === "SERIALISED" && (!productId || productId.trim().length === 0)){
      notification("error", "Product Id cannot be empty");
    }
    else if (productCategory === "NON_SERIALISED" && (!qty || isNaN(parseInt(qty)) || parseInt(qty) <= 0)){
      notification("error", "Quantity needs to be a number, and greater than 0");
    }
    else {
      const editedProduct = {
        ...props.editProduct,
        productCategory: productCategory,
        sku: sku,
        productId: productId,
        productName: productName,
        qty: qty,
        productImageUrl: productImageUrl,
      };
      if (productCategory === "SERIALISED")
        delete editedProduct.qty;
      else if (productCategory === "NON_SERIALISED"){
        editedProduct.qty = parseInt(editedProduct.qty);
        delete editedProduct.productId;
      }         
      props.onEdit(editedProduct);
      setOpen(false);
      props.onClose();
    }
  };

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={closeModal}
    >
      <div style={modalStyle} className={classes.paper}>
        <h3>Edit Product</h3>
        <SelectValidator
            style={{ height: "35px", marginTop: "10px", marginBottom: "20px" }}
            label="Category"
            autoFocus={true}
            onChange={(event) => setProductCategory(event.target.value)}
            name="Category"
            value={productCategory}
            validators={["requiredCheck"]}
            errorMessages={[
              "This field is required"
            ]}
            variant="outlined"
            fullWidth
          >
            <MenuItem value="SERIALISED">Serialised</MenuItem>
            <MenuItem value="NON_SERIALISED">Non-Serialised</MenuItem>

          </SelectValidator>
        <TextValidator
          style={{ height: "35px", marginTop: "10px", marginBottom: "20px" }}
          label="SKU/Model"
          onChange={(event) => setSku(event.target.value)}
          name="SKU/Model"
          value={sku}
          // validators={['requiredCheck', 'specialCharCheck']}
          // errorMessages={['This field is required', 'Special Characters not allowed']}
          variant="outlined"
          fullWidth
        />
        {(productCategory != null && productCategory === "SERIALISED") && <TextValidator
          style={{ height: "35px", marginTop: "10px", marginBottom: "20px" }}
          id="productId"
          label="Product Id"
          autoComplete={false}
          value={productId}
          onChange={(event) => setProductId(event.target.value)}
          name="productId"
          variant="outlined"
          fullWidth
        />}
        <TextValidator
          style={{ height: "35px", marginTop: "10px", marginBottom: "20px" }}
          id="productName"
          label="Product Name"
          autoComplete={false}
          onChange={(event) => setProductName(event.target.value)}
          name="productName"
          value={productName}
          // validators={['requiredCheck', 'specialCharCheck']}
          // errorMessages={['This field is required']}
          variant="outlined"
          fullWidth
        />
        {(productCategory != null && productCategory === "NON_SERIALISED") && <TextValidator
            style={{ height: "35px", marginTop: "10px", marginBottom: "20px" }}
            id="qty"
            type="number"
            label="Quantity"
            autoComplete={false}
            onChange={(event) => setQty(event.target.value)}
            name="Quantity"
            value={qty}
            validators={['minNumber:1', 'maxNumber:5000', 'matchRegexp:^[0-9]$']}
            errorMessages={['Invalid quantity value']}
            variant="outlined"
            fullWidth
          />}
        <TextValidator
          style={{ height: "35px", marginTop: "10px", marginBottom: "20px" }}
          id="productImageUrl"
          type="url"
          label="Image Url"
          value={productImageUrl}
          autoComplete={false}
          onChange={(event) => setProductImageUrl(event.target.value)}
          name="productImageUrl"
          variant="outlined"
          fullWidth
        />
        <ButtonGroup size="small" variant="outlined">
          <Button
            onClick={(e) => editProduct(e)}
            style={{
              height: "40px",
              marginRight: "10px",
              width: "90px",
              marginTop: "10px",
            }}
            variant="contained"
            color="primary"
          >
            Edit
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            variant="contained"
            onClick={closeModal}
            style={{ height: "40px", width: "90px", marginTop: "10px" }}
            color="secondary"
          >
            Cancel
          </Button>
        </ButtonGroup>
      </div>
    </Modal>
  );
}
