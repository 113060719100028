import { DateTime } from 'luxon';

export const getFullDateHour = (date: string) => {
  return DateTime.fromISO(date).toFormat('dd/MM/yyyy h:mm:ss a');
};

export const getFullDate = (date: string, insertSymbol: string = '') => {
  return DateTime.fromISO(date).toFormat(`DD ${insertSymbol} hh:mm a`);
};

export const getDateFirstFormat = (date: string) => {
  return DateTime.fromISO(date).toFormat('DD');
};

export const getLocalizedTime = (date: string) => {
  return DateTime.fromISO(date).toFormat('hh:mm a');
};

export const convertToISO = (date: string) => {
  return DateTime.fromISO(date).toISO();
};

export const convertToISOEndOf = (date: string) => {
  return DateTime.fromISO(date).endOf('day').toISO();
};

export const isBeforeCheck = (fromDate: string, toDate: string) => {
  // const isValidTime  = DateTime.fromISO(fromDate) < DateTime.fromISO(toDate);
  const isValidTime  = new Date(fromDate) < new Date(toDate);
  return isValidTime;
};
