export const filterTitle:string = 'Filters';

export const defaultFilterOptions = [
    {
        "label": "equals",
        "value": "equals"
    }
]

export const ListOfTextFilterOptions = [
    {
        "label": "contains",
        "value": "contains"
    }
]

export const timeFields = [
    {
        "label": "scheduledFromTime",
        "toCheck": "scheduledToTime"
    },
    {
        "label": "scheduledToTime",
        "toCheck": "scheduledFromTime"
    },
    {
        "label": "fromTime",
        "toCheck": "toTime"
    },
    {
        "label": "toTime",
        "toCheck": "fromTime"
    }
]