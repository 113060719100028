import { PageCategoryEnum, recordPageVisit } from "config/Rudderstack";
import { useEffect } from "react";
import {
  CreateButton,
  Datagrid,
  ExportButton,
  List,
  TextField,
  TopToolbar,
  downloadCSV,
  Pagination,
} from "react-admin";
import { useStyles } from "styles/tableStyles";
import { recordRSEvent } from "utils/CommonUtils";
import { userDecodedData } from "utils/UserAuth.utils";
import jsonExport from "jsonexport/dist";
import ListPagination from "views/commonComponents/ListPagination";
import { SubHeading } from "views/settings/settings.style";
import { Label } from "views/settings/GeneralSetting/GeneralSettingEdit";
import { useTheme } from "styled-components";
import { hasPermission } from "providers/auth/authUtils";

const RoleListActions = ({ basePath, data, resource }) => {
  const theme = useTheme()
  const classes = useStyles({theme});

  return (
    <>
      <TopToolbar className="workflow-toolbar setting-bar">
      <div>
          <SubHeading>
            Roles Management
          </SubHeading>
          <Label
            
            >
              {" "}
              Manage your roles and permissions{" "}
            </Label>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <ExportButton
            style={{
              border: "1px solid #c2c9d1",
              color: "#344054",
              fontSize: "1rem",
              fontWeight: "600",
              backgroundColor: "#FFF",
              // marginRight: "8px",
              padding: "6px 16px",
            }}
          />
          {hasPermission("role", "create") && <CreateButton
            style={{
              color: "white",
              fontSize: "1rem",
              fontWeight: "600",

              marginRight: "8px",
              padding: "6px 16px",
            }}
            className={classes.createButton}
            basePath="/roles"
            variant="contained"
            label="Add Role"
          />}
        </div>
      </TopToolbar>
    </>
  );
};

const RoleList = (props) => {
  const theme = useTheme()
  const classes = useStyles({theme});
  const { userId = "" } = userDecodedData();

  useEffect(() => {
    recordPageVisit(PageCategoryEnum.role, "role_list");
  }, [props]);

  const customizeRoleExportField = (roleData) => {
    recordRSEvent("export_grid", {
      context: "role_export_grid",
      userId,
    });

    const rolesForExport = roleData.map((role) => {
      const { id, ...roleForData } = role;
      return roleForData;
    });
    jsonExport(
      rolesForExport,
      {
        headers: [
          "roleId",
          "name",
          "description",
          "accountId",
          "isInternal",
          "createdOn",
          "updatedOn",
        ],
      },
      (err, csv) => {
        downloadCSV(csv, "Roles"); // download user_Data.csv file
      }
    );
  };

  return (
    <List
      {...props}
      bulkActionButtons={false}
      pagination={<ListPagination />}
      exporter={customizeRoleExportField}
      actions={<RoleListActions />}
    >
      <Datagrid
        classes={{
          headerCell: classes.headerCell,
          headerRow: classes.headerRow,
          rowEven: classes.rowEven,
          rowOdd: classes.rowOdd,
          rowCell: classes.rowCell,
          table : classes.table
        }}
        rowClick="show"
      >
        <TextField
          sortable={false}
          textAlign="left"
          label="Role Id"
          source="id"
        />
        <TextField sortable={false} textAlign="left" source="name" />
        <TextField
          sortable={false}
          textAlign="left"
          label="Description"
          source="description"
        />
      </Datagrid>
    </List>
  );
};

export default RoleList;
