import Modal from "@mui/material/Modal"
import styled from "styled-components"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import notification from "notifications/notifications"
import "react-datetime/css/react-datetime.css"
import { Render } from "render-engine/Render"
import TopBar from "render-engine/TopBar"
import { EntityService } from "providers/data/services/EntityService"
import { modifyFormDataForBackend } from "utils/CommonUtils"
import { TaskService } from "providers/data/services/TaskService"
import { IRelationReferringEntityType } from "views/entities/types"
import { setValue } from "render-engine/reducers/create"
import { resetSubRecordData, setSubRecordCreateMode, setTaskData, setTaskDataUpdated } from "render-engine/reducers/show/TaskShowReducer"
import useTaskShow from "views/workflows/hooks/useTaskShow"
import { useTabsList } from "@mui/base"
import { RenderV2 } from "render-engine/RenderV2"
import useEntity from "views/entities/hooks/useEntity"

const Wrapper = styled.form`
  margin-right: 11rem;
  max-height: 70vh;
  /* overflow-y: auto; */
  overflow: hidden;
  background-color: white;
  margin-left: 10vw;
  margin-top: 10vh;
  /* padding: 15px; */
  /* border-radius: 4px; */
`

const ButtonContainer = styled.div`
  display: flex;
  /* gap: 8px; */
  justify-content: flex-end;
  margin-top: 10px;
`

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  p {
    font-weight: 500;
    font-size: 14px;
  }

  svg {
    &:hover {
      cursor: pointer;
    }
  }
`

type Props = {
  referringEntity: IRelationReferringEntityType
  showModal: boolean
  setShowModal: any
  name: string
  mode: "create" | "edit"
  recordTypeLabel?: string
  renderConfig: any
  tableType: string
  parentTableType: string
  columnId: string
  alreadyCreatedRecordIds: string[]
  recordId: string
  setSelectedRecordAfterSelection: (setSelectedRecordAfterSelection:any) => void
}

const ZRelationCreateRender = ({
  referringEntity,
  showModal,
  setShowModal,
  name,
  mode,
  renderConfig,
  tableType,
  recordTypeLabel,
  parentTableType,
  columnId,
  alreadyCreatedRecordIds,
  recordId,
  setSelectedRecordAfterSelection
}: Props) => {
  const {
    register: modalRegister,
    control: modalControl,
    handleSubmit,
    formState,
    setValue: modalSetValue,
    getValues: modalGetValues,
    setError: modalSetError,
    clearErrors: modalClearErrors,
  } = useForm()

  const { convertDataTypeToString, subRecordData : data } = useTaskShow()

  const val = convertDataTypeToString(`${columnId}` || "", false,"", "parentRecord")

  const dispatch = useDispatch()

  const onClose = () => {
    setShowModal(false)
    dispatch(setSubRecordCreateMode(false))
    dispatch(resetSubRecordData())
  }

  const saveData = async () => {
    let createdEntityId;
    if (referringEntity === IRelationReferringEntityType.TASK) {
      createdEntityId = await saveDataForTask(data)
    } else if (referringEntity === IRelationReferringEntityType.CUSTOM) {
      createdEntityId = await saveDataForRecord(data)
    } else {
      notification("error", "Function not implemeneted for USER")
    }
    onClose()
    notification("success", `New ${
      referringEntity === IRelationReferringEntityType.TASK ? `task created with id ${createdEntityId} and attached to ${columnId}` : `${recordTypeLabel} created with recordId ${createdEntityId}`
    } `)
    }

  const saveDataForRecord = async (data: any) => {
    try {
      // create a new record in the related table
      const createdRecord = await EntityService.createEntityRecord({
        data: data,
        tableType: tableType,
      })
      // add the newly create in the payload
      const payload = {
        tableType: parentTableType,
        data: {
          [columnId]: [
            ...alreadyCreatedRecordIds,
            createdRecord?.data.recordId,
          ],
        },
      }

      // update the parent table
      // const updatedRecord = await EntityService.updateEntityRecord(
      //   parentTableType,
      //   recordId,
      //   payload
      // )
      // window.location.reload()
      const modifiedData = [...val, createdRecord?.data.recordId]
      modalSetValue(columnId, modifiedData)
      // set it to false so that the taskData is saved at the correct position.
      dispatch(setSubRecordCreateMode(false))
      // dispatch(
      //   setTaskData({
      //     nodeId: columnId,
      //     data: modifiedData,
      //   })
      // )
      setSelectedRecordAfterSelection(modifiedData)
      dispatch(setTaskDataUpdated(true))
      return createdRecord?.data.recordId
    } catch (error) {
      console.log(error)
      notification("error", "Cannot create subfield")
      return
    }
  }

  const saveDataForTask = async (data: any) => {
    try {
      data = modifyFormDataForBackend(data)
      data.taskType = tableType
      console.error("****", data)
      const taskCreationPayload: any = {
        // taskType: store.getState().taskCreation.taskType,
        // userId: store.getState().taskCreation.userId,
        // teamIds: [store.getState().taskCreation.teamIds],
        taskType: data.taskType,
        userId: data.userId,
        teamIds: [data.teamIds],
        scheduledSlot: data.scheduledSlot,
        mode: "single",
        channel: "CC",
        data: {
          ...data,
          taskType: undefined,
          userId: undefined,
          teamIds: undefined,
          scheduledSlot: undefined,
        },
      }
      // const omitFieldsInData: Array<string> = ['scheduledSlot', 'taskType', 'teamIds', "userId"];
      // for (let key of Object.keys(store.getState().taskCreation)) {
      //   // Skip the omit fields for data
      //   if (!omitFieldsInData.includes(key)) taskCreationPayload.data[key] = store.getState().taskCreation[key];
      // }

      TaskService.createTask(taskCreationPayload)
        .then(async (res: any) => {
          if (res?.code && res?.code.indexOf("200") < 0) {
            // createTaskForm.reset()
            // Error
            if (res?.code === "401") {
              notification("error", res?.message || "Something went wrong!")
            } else if (res.code === "600") {
              notification("error", res?.message || "Something went wrong!")
            } else {
              notification("error", res?.message || "Something went wrong!")
            }
          } else {
            notification("success", res.message)
            const payload = {
              tableType: parentTableType,
              data: {
                [columnId]: [...alreadyCreatedRecordIds, res?.data.taskId],
              },
            }

            const modifiedData = [...val, res?.data.taskId]

            // update the parent table
            // const updatedRecord = await EntityService.updateEntityRecord(
            //   parentTableType,
            //   recordId,
            //   payload
            // )
            dispatch(setTaskData({ nodeId: columnId, data: modifiedData }))
            return res?.data.taskId
            // window.location.reload()
          }
        })
        .catch((err) => {
          console.error("Error", err)
          notification("error", "Task cannot be created!")
        })
    } catch (error) {
      console.log(error)
      notification("error", "Cannot create subfield")
      return
    }
  }

  const handleSubmitWithoutPropagation = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    handleSubmit(saveData)(e)
  }

  const handleChange = (key: string, value: string) => {
    if (referringEntity === IRelationReferringEntityType.TASK) {
      dispatch(setValue({ key, value }))
    }
  }

  // Get the Correct render config
  const subRenderConfig = renderConfig
  return (
    <Modal
      open={showModal}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Wrapper onSubmit={handleSubmitWithoutPropagation}>
        {/* <Heading> */}
        {/* <div>
            <p>
              {mode === "create"
                ? `${name}: Create`
                : `${name}: Edit`}
            </p>
          </div>
          <div>
            <AiOutlineClose size="1.2rem" onClick={() => setShowModal(false) } />
          </div> */}
        <TopBar
          heading={mode === "create" ? `${name}: Create` : `${name}: Edit`}
          onSaveText={mode === "create" ? "Create" : "Edit"}
          onCancelText={"Cancel"}
          onSave={() => {}}
          onCancel={onClose}
        />
        {/* </Heading> */}
        <hr />
        <div style={{ padding: "15px", maxHeight: "60vh", overflow: "auto" }}>
          {referringEntity === IRelationReferringEntityType.TASK ? (
            <RenderV2
             initRenderConfig={subRenderConfig}
              componentParams={{
                register: modalRegister,
                onChange: handleChange,
                errors: formState.errors,
                control: modalControl,
                setValue: modalSetValue,
                isOpenedAsSubfield: true,
                getValues: modalGetValues,
                setError: modalSetError,
                clearErrors: modalClearErrors,
              }}
            ></RenderV2>
          ) : (
            <RenderV2
              initRenderConfig={subRenderConfig}
              componentParams={{
                register: modalRegister,
                onChange: handleChange,
                errors: formState.errors,
                control: modalControl,
                setValue: modalSetValue,
                isOpenedAsSubfield: true,
                getValues: modalGetValues,
                setError: modalSetError,
                clearErrors: modalClearErrors,
              }}
            ></RenderV2>
          )}
        </div>
      </Wrapper>
    </Modal>
  )
}

export default ZRelationCreateRender