import React from "react";
import { Handle, useStoreState } from "react-flow-renderer";
import "../css/flow.css";
import styled, { useTheme } from "styled-components";
import { IoIosHome } from "react-icons/io";
import { BiEdit } from "react-icons/bi";
import { BsFillInfoCircleFill, BsStopCircleFill } from "react-icons/bs";
import { ErrorTooltip } from "views/workflows/Tootltips/ErrorTooltip";
import Typography from "@mui/material/Typography";
import useWorkFlow from "views/workflows/hooks/useWorkFlow";
import LongMenu from "views/workflows/nodes/ContextMenu";
import { useDispatch } from "react-redux";
import { WarningTooltip } from "../Tootltips/WarningTooltip";
import NodeHoverStrip from "./NodeHoverStrip";
import {HiOutlineStop} from "react-icons/hi"
import {AiOutlineHome} from "react-icons/ai"

export const ZNodeContainer = styled.div`
  border-radius: 7px;
  border: ${(props) =>
    props.error
      ? `1.5px solid #F03738`
      : props.warning
      ? `1px solid #F3901C`
      : `1px solid #c2c9d1`};
  background-color: #e5e5e5;
  color: black;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 19rem;
  width: 10rem;
  padding: 7px 5px 7px 5px;
  &:hover {
    cursor: pointer;
  }
`;

export const NotReachableNodeError = styled.div`
  position: absolute;
  left: 19px;
  top: 25px;
`;

export const OuterContainer = styled.div`
  border: ${(props) =>
    props.error
      ? `1.5px solid #F03738`
      : props.warning
      ? `1px solid #F3901C`
      : `1px solid #c2c9d1`};
  padding: 7px;
  border-radius: 12px;
  background-color: white;
`;

export const DetailContainer = styled.div`
  margin-right: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background-color: ${(props) => (props.color === "color" ? "#d0d1d2" : "")};
  border-radius: 4px;
  max-width: 3rem;
  margin: auto;
  &:hover {
    cursor: pointer;
  }
`;
export const IconContainer = styled.div`
  margin-left: 0.8rem;
  &:hover {
    cursor: pointer;
  }
`;

const EditIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
`;

const Notch = styled.div`
  height: 10px;
  width: 54px;
  background-color: white;
  position: absolute;
  top: 7px;
  border-radius: 2px;
  border-bottom: ${(props) =>
    props.error
      ? `1.5px solid #F03738`
      : props.warning
      ? `1px solid #F3901C`
      : `1px solid #c2c9d1`};
  border-left: ${(props) =>
    props.error
      ? `1.5px solid #F03738`
      : props.warning
      ? `1px solid #F3901C`
      : `1px solid #c2c9d1`};
  border-right: ${(props) =>
    props.error
      ? `1.5px solid #F03738`
      : props.warning
      ? `1px solid #F3901C`
      : `1px solid #c2c9d1`};
`;

export const TopContainer = styled.div`
  display: flex;
  height: fit-content;
  margin-left: 3px;
  margin-bottom: 1rem;
`;

export const BottomContainer = styled.div`
  background: white;
  margin-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ClickContainer = styled.div``;

const Heading = styled.p`
  color: #808080;
  font-size: 12px;
  text-align: center;
  font-weight: 500;
  margin-top: 1rem;
  text-align: center;
`;

export const NameContainer = styled.div`
  display: flex;
  word-wrap: break-word;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  font-size: 12px;
  margin-left: 4px;
`;

const DeleteButton = styled.div`
  position: absolute;
  bottom: 10px;
  &:hover {
    color: black;
  }
`;


const errorPresent = (elements, nodeId) => {
  let targetNode = elements?.filter((item) => item.id === nodeId);
  return targetNode && targetNode[0] ? targetNode[0]?.errors : [];
};

const ZorpStateNode = ({ data }) => {
  const {
    selectedNode,
    allElements,
    possibleHomeNodes,
    nodesThatCanBeVisited,
    possibleTerminalNodes,
  } = useWorkFlow();

  const [showStrip, setShowStrip] = React.useState(false);
  const bottomRef = React.useRef(null);
  const topRef = React.useRef(null);

  const edges = useStoreState((state) => state.edges);

  // To ensure that there is no repeated edge.
  const isValid = (info) => {
    const invalidEdge = edges.filter(
      (edge) => edge.source === info.source && edge.target === info.target
      //   (edge?.sourceHandle || "a") === info.sourceHandle &&
      //  edge.targetHandle === info.targetHandle
    );

    return invalidEdge.length === 0 ? true : false;
  };

  const allErrors = React.useMemo(() => {
    return errorPresent(allElements, data.id);
  }, [allElements, data.id]);

  const handleMouseLeaveNew = (e) => {
    // const bottomDivRect = e?.currentTarget?.getBoundingClientRect?.();
    const topDivRect = document
      .querySelector("#topDiv")
      ?.getBoundingClientRect();
    if (
      // e.clientY > topDivRect.bottom ||
      e.clientX < topDivRect?.left ||
      e.clientX > topDivRect?.right ||
      e.clientY - 100 > topDivRect?.bottom
    ) {
      setShowStrip(false);
    }
  };

  const theme = useTheme();


  const dispatch = useDispatch();

  return (
    <div>
      {showStrip && (
        <NodeHoverStrip
          func = {() => setShowStrip(false)}
          // onMouseEnter={() => setShowStrip(true)}
          nodeId={data.id}
          data={data}
          ref={topRef}
        />
      )}
      <OuterContainer
        onMouseLeave={handleMouseLeaveNew}
        onMouseEnter={() => setShowStrip(true)}
        error={false}
        warning={false}
        ref={bottomRef}
      >
        <ZNodeContainer error={false} warning={false}>
          <div>
            {data?.isHomeNode && (
              <DetailContainer color={"color"}>
                <AiOutlineHome size={"1.5rem"} color="#494949" />
              </DetailContainer>
            )}

            {data?.isTerminalNode && (
              <DetailContainer color={"color"}>
                <HiOutlineStop size={"1.5rem"} color="#494949" />
              </DetailContainer>
            )}

            <p
              style={{
                marginTop: "1.4rem",
                fontSize: "12px",
                textAlign: "center",
                wordBreak: "break-all",
              }}
            >
              {data.name}
            </p>

            <Heading>
              <BiEdit
                style={{ marginRight: "4px", display: "inline" }}
                display={"inline"}
                size={"1.4rem"}
                color="#616569"
                cursor={"pointer"}
              />
              Click to Edit
            </Heading>
          </div>
          <Notch showStrip={showStrip} error={false} warning={false} />
          <LongMenu
            showStrip={showStrip}
            nodeId={data.id}
            data={data}
            showTerminalNodes={possibleTerminalNodes.length === 0}
            showSetHomeNode={possibleHomeNodes.length !== 1}
          />
          <NotReachableNodeError showStrip={showStrip}>
            {allErrors?.length > 0 ? (
              <ErrorTooltip
                color="red"
                title={
                  <>
                    <Typography color="inherit">
                      {allErrors?.map((item) => (
                        <li> {item} </li>
                      ))}
                    </Typography>
                  </>
                }
              >
                <DetailContainer>
                  <BsFillInfoCircleFill size={"1.5rem"} color="#FF2D55" />
                </DetailContainer>
              </ErrorTooltip>
            ) : !nodesThatCanBeVisited.includes(data.id) ? (
              <WarningTooltip
                color={"yellow"}
                title={
                  <>
                    <Typography color="inherit">
                      <p> This node cannot be visited. </p>
                    </Typography>
                  </>
                }
              >
                <DetailContainer>
                  <BsFillInfoCircleFill size={"1.5rem"} color="#F3901C" />
                </DetailContainer>
              </WarningTooltip>
            ) : (
              <></>
            )}
          </NotReachableNodeError>

          {/* <Handle type="target" position="left" style={{ border:"none", borderRadius: "3px",background:"#34C759",width:"10px",height:"15px" }} /> */}
        </ZNodeContainer>
        <Handle
          type="source"
          position="right"
          isValidConnection={isValid}
          id="a"
          style={{
            height: "41px",
            width: "16px",
            top: "84%",
            // marginLeft: "-12px",
            left: "93%",
            backgroundColor: theme.color.primary,
            borderRadius: "4px",
            border: `1px solid ${theme.color.primary}`
          }}
        />
        <Handle
          type="target"
          position="left"
          isValidConnection={isValid}
          id="b"
          style={{
            height: "41px",
            width: "16px",
            top: "44px",
            marginLeft: "-2px",
            backgroundColor: theme.color.primary,
            borderRadius: "4px",
            border: `1px solid ${theme.color.primary}`
          }}
        />
      </OuterContainer>
    </div>
  );
};

export default React.memo(ZorpStateNode);
