import {Label,Input} from "../DataField.styles"

type DateFieldTypeProp = {
    register:any;
}


const DateField = ({register}:DateFieldTypeProp) => {
  return (
    <>
        {/* <Label>
            Default Value
        </Label>
        <Input type="text" {...register("defaultValue")} /> */}
    </> 
  )
}

export default DateField