import React, { FunctionComponent, useEffect, useState } from "react"
import { UseFormRegister, FieldErrors, useForm } from "react-hook-form"
import { useDispatch, useStore } from "react-redux"
import { ZComponent } from "../common/ZComponent"
import { ZEmpty } from "../empty/ZEmpty"
import { IZComponentPropType } from "render-engine/models/ITaskTypeWebBodyRenderModel"
import { IZLocationInputInterface } from "./IZLocationInputInterface"
import {
  setInEditState,
  setTaskData,
} from "render-engine/reducers/show/TaskShowReducer"
import useTaskShow from "views/workflows/hooks/useTaskShow"
import ZInput from "views/commonComponents/ZTextInput"
import { TextField, TextareaAutosize } from "@mui/material"
import { ZButton } from "views/commonComponents/commonComponents.styles"
import styled from 'styled-components';



const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px 24px;
  gap: 16px;
`;

const LocationInputContainer = styled.div`
  width: 100%;
  gap:4px;
  display: flex;
  flex-direction: column;
`

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 8px;
  padding: 16px;
`;

const Heading = styled.div`
  color: var(--gray-900, #101828);
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
`;

const Label = styled.div`
  color: var(--gray-700, #344054);
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;

export const ZLocationInputV2: FunctionComponent<IZComponentPropType> = ({
  config,
  onChange,
  register,
  errors,
  setValue,
  control,
  isOpenedAsSubfield,
  getValues,
}) => {
  // Construct validation
  const { convertDataTypeToString } = useTaskShow()
  const value = convertDataTypeToString(`${config.key}` || "", false)
  const {
    register: localRegister,
    handleSubmit: localHandleSubmit,
    getValues: localGetValues,
  } = useForm({
    defaultValues: {
      address: value?.address || "",
      latitude: value?.latitude || null,
      longitude: value?.longitude || null,
    },
  })
  const validation: any = {}
  const dispatch = useDispatch()

  if (config.isRequired) validation.required = `required`
  // address input ref
  const [address, setAddress] = useState(() => {
    return value?.address
  })
  // latitude input ref
  const [latitude, setLatitude] = useState(() => {
    return value?.latitude
  })
  // longitude input ref
  const [longitude, setLongitude] = useState(() => {
    return value?.longitude
  })

  let isVisible = true
  if (config.hasOwnProperty("isVisible")) {
    if (
      (typeof config.isVisible === "string" ||
        config.isVisible instanceof String) &&
      config.isVisible.toLowerCase().trim() === "false"
    )
      isVisible = false
    else if (typeof config.isVisible === "boolean") isVisible = config.isVisible
    else if (typeof config.isVisible === "function")
      isVisible =
        config.isVisible() === "true" || config.isVisible() === true
          ? true
          : false
  }

  const componentParams = {
    onChange: onChange,
    register: register,
    errors: errors,
    setValue: setValue,
    control,
    isOpenedAsSubfield,
    getValues: getValues,
  }

  // Create a custome validate function for address, latitude and longitude.
  // For all the validate calls, if config.required is true, then we need to validate the field.
  // Else, we don't need to validate the field.
  // For address:
  /**
   * if config.required is true, and lat and long both are not present, then address is required.
   */

  const addressConfig = { ...config.value.address }
  const latConfig = { ...config.value.latitude }
  const longConfig = { ...config.value.longitude }

  addressConfig.validate = () => {
    if (!config.required) {
      return true
    }
    if (
      !localGetValues()?.address &&
      !localGetValues()?.latitude &&
      !localGetValues()?.longitude
    )
      return "required"
    else return true
  }

  // For latitude:
  /**
   * if longitude is there: latitude is required.
   * if address is there and longitude is not there, its fine.
   * if address is not there and longitude is not there, its required.
   */

  latConfig.validate = () => {
    if (!config.required) {
      return true
    }
    if (localGetValues()?.latitude) {
      return true
    }
    if (localGetValues()?.longitude && !localGetValues()?.latitude) {
      return "required"
    }
    if (localGetValues()?.address && !localGetValues()?.longitude) {
      return true
    }
    if (!localGetValues()?.address && !localGetValues()?.longitude) {
      return "required"
    }
    return true
  }

  // For longitude
  /**
   * if latitude is there: longitude is required.
   * if address is there and latitude is not there, its fine.
   * if address is not there and latitude is not there, its required.
   */
  longConfig.validate = () => {
    if (!config.required) {
      return true
    }
    if (localGetValues()?.latitude && !localGetValues()?.longitude) {
      return "required"
    }
    if (localGetValues()?.address && !localGetValues()?.latitude) {
      return true
    }
    if (!localGetValues()?.address && !localGetValues()?.latitude) {
      return "required"
    }
    return true
  }

  // const handleBlur = () => {
  //   console.log("handleBlur", getValues())
  //   // const curValue = {
  //   //   ...getValues(),
  //   //   [`${config.key}`]: {
  //   //     address,
  //   //     latitude,
  //   //     longitude,
  //   //   },
  //   // }
  //   // dispatch(setInEditState({ nodeId: config.key, inEditState: false }))
  //   // // KEY CAN BE location.address or location.latitude or location.longitude
  //   // // GET THE MAIN KEY AND SET THE VALUE
  //   // const mainKey = config.key.split(".")[0]
  //   // dispatch(setTaskData({ nodeId: mainKey, data: curValue[`${mainKey}`] }))
  //   // dispatch(
  //   //   setTaskData({ nodeId: config.key, data: curValue[`${config.key}`] })
  //   // )
  // }

  const handleSubmit = (data: any) => {
    console.log("data", data)
    const curValue = {
      ...getValues(),
      [`${config.key}`]: {
        ...data,
      },
    }
    dispatch(setInEditState({ nodeId: config.key, inEditState: false }))
    const mainKey = config.key.split(".")[0]
    dispatch(setTaskData({ nodeId: mainKey, data: curValue[`${mainKey}`] }))
    config.onClose()
  }

  if (isVisible)
    return (
      <>
        {/* @ts-ignore */}
        {/* <ZComponent config={addressConfig} {...componentParams}></ZComponent> */}
        {/* @ts-ignore */}
        {/* <ZComponent config={latConfig} {...componentParams}></ZComponent> */}
        {/* @ts-ignore */}
        {/* <ZComponent config={longConfig} {...componentParams}></ZComponent> */}
        <Container>
          <Heading>Change Location</Heading>
          <LocationInputContainer>
            <Label>Address</Label>
            <TextareaAutosize
              className="w-full textarea"
              // multiline={config.shape.toLowerCase() !== "number"}
              minRows={1}
              defaultValue={value?.address}
              maxRows={config.maxLines}
              id={config?.key}
              
              style={{
                width: "100%",
                border: "1px solid #c2c9d1",
                borderRadius: "4px",
                padding: "8px 12px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                outline: "none",
                resize: "none",
              }}
              {...localRegister(`address`, addressConfig)}
            />
          </LocationInputContainer>
          <LocationInputContainer>
            <Label>Latitude</Label>
            <TextField
              className="w-full"
              // multiline={config.shape.toLowerCase() !== "number"}
              type={config.shape.toLowerCase()}
              maxRows={config.maxLines}
              {...register(`${config.key}.latitude`)}
              id={config?.key}
              variant="outlined"
              {...localRegister(`latitude`, { ...latConfig, valueAsNumber: true })}
              InputProps={{
                style: {
                  width: "100%",
                  padding: "5px 4px",
                  fontFamily: "Inter",
                },
              }}
              inputProps={{
                step: "any",
              }}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                outline: "none",
              }}
            />
          </LocationInputContainer>
          <LocationInputContainer>
            <Label>Longitude</Label>
            <TextField
              className="w-full"
              defaultValue={value?.longitude}
              // multiline={config.shape.toLowerCase() !== "number"}
              type={config.shape.toLowerCase()}
              maxRows={config.maxLines}
              id={config?.key}
              variant="outlined"
              {...localRegister(`longitude`, {
                ...longConfig,
                valueAsNumber: true,
              })}
              InputProps={{
                style: {
                  width: "100%",
                  padding: "5px 4px",
                  fontFamily: "Inter",
                },
              }}
              inputProps={{
                step: "any",
              }}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                outline: "none",
              }}
            />
          </LocationInputContainer>
        </Container>

        <ActionsWrapper>
          <ZButton
            variant="contained"
            onClick={() => {
              console.log("submitting")
              localHandleSubmit(handleSubmit)()
            }}
            style={{
              background: "#3C69E7",
              border: "#3C69E7",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              padding: "8px 14px",
              cursor: "pointer",
            }}
            type="button"
          >
            Save
          </ZButton>
        </ActionsWrapper>
      </>
    )
  else return <div></div>
}
