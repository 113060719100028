//@ts-nocheck
import Box from '@mui/material/Box';
import { FiX } from "react-icons/fi";
import Typography from '@mui/material/Typography';
import FilterModalClose from "assests/FilterModalClose.svg";

import { FilterCloseHeaderRightWrapper, FilterDocumentationText, FilterHeading } from './FilterStyledComponents';

interface IFilterHeader {
    filterTitle: string;
    onClose: () => void;
}

const FilterHeader = ({ filterTitle, onClose }: IFilterHeader) => {
    return (
        <>
            <Box style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginBottom: '5px' }}>
                <FilterHeading>
                    {filterTitle}
                </FilterHeading>

                <FilterCloseHeaderRightWrapper>
                    <FilterDocumentationText>
                        {/* Read the Documentation */}
                    </FilterDocumentationText>


                    <img src={FilterModalClose} className='cursor-pointer'
                        onClick={() => {
                            onClose();
                        }}
                    />
                </FilterCloseHeaderRightWrapper>

            </Box>
        </>
    )
}

export default FilterHeader