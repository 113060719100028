import { AxiosResponse } from 'axios';
import HttpUtil from 'config/AxiosConfig';
import { ZorpResponse } from 'providers/data/models/ZorpResponse';

export class RoleService {
  public static listRoles(fetchInternalRoles? : boolean): Promise<ZorpResponse | undefined> {
    const queryCriteria = (fetchInternalRoles !== null && fetchInternalRoles !== undefined) ? `fetchInternalRoles=${fetchInternalRoles}` : ''
    return HttpUtil.userInstance.get(`/roles?${queryCriteria}`).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static getRole(roleId: any): Promise<ZorpResponse | undefined> {
    return HttpUtil.userInstance.get(`/role/${roleId}`).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static getAclForRole(roleId: any): Promise<ZorpResponse | undefined> {
    return HttpUtil.userInstance.get(`/role/${roleId}/acl`).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static getResourceActions(): Promise<ZorpResponse | undefined> {
    return HttpUtil.userInstance.get('/resourceAction').then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static updateRole(roleId: any, roleDetails: any): Promise<ZorpResponse | undefined> {
    return HttpUtil.userInstance.put(`/role/${roleId}`, roleDetails).then((response: AxiosResponse) => {
      console.log (response, '*******')
      return response?.data as ZorpResponse;
    });
  }

  public static createRole(roleDetails: any): Promise<ZorpResponse | undefined> {
    return HttpUtil.userInstance.post(`/role`, roleDetails).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static updateRoleAcl(aclDetails: any): Promise<ZorpResponse | undefined> {
    return HttpUtil.userInstance.post('/acl', aclDetails).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static findRoleFromLocalStorage(roleId: String) {
    // Roles
    let role = roleId;
    if (roleId && localStorage.getItem('roles')) {
      const localStorageRoles: any = JSON.parse(localStorage.getItem('roles') || '[]');
      localStorageRoles
        .filter((role: any) => {
          return role.roleId === roleId;
        })
        .map((filteredRole: any) => (role = filteredRole?.name + ' (' + filteredRole?.roleId + ')'));
    }
    return role;
  }
}
