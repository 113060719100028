import { Grid, Paper, makeStyles } from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import React, { useEffect } from "react";
import { PhoneValidator } from "./Validator";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    boxShadow: "none",
    backgroundColor: "transparent",
  },
}));

export default function Location({ submit, info, onChange, label }) {
  const [phone, setPhone] = React.useState(info.phone);
  const [name, setName] = React.useState(info.name);
  const [address, setAddress] = React.useState(info.address);
  const [lat, setLat] = React.useState(info.lat);
  const [lon, setLon] = React.useState(info.lon);
  const [phoneCode, setPhoneCode] = React.useState(info.phoneCode);
  const [latLon, setLatLon] = React.useState("");

  const classes = useStyles();

  useEffect(() => {
    setPhone(info.phone);
    setAddress(info.address);
    setLat(info.lat);
    setLon(info.lon);
    if (info.lat !== null && info.lat !== '' && info.lon !== null && info.lon !== '') {
      setLatLon(info.lat + "," + info.lon);
    } else {
      setLatLon("");
    }
    setName(info.name);
    setPhoneCode(info.phoneCode);
  }, [info]);

  useEffect(() => {
    ValidatorForm.addValidationRule("addressCheck", (value) => {
      var flag1 = false;
      var flag2 = false;
      if (address !== "") flag1 = true;
      if (lat != null && lon != null) flag2 = true;

      if (flag1 === true || flag2 === true) return true;
      else return false;
    });
  }, [address, lat, lon]);

  const handleNameChange = (val) => {
    setName(val);
    onChange({
      phone: phone,
      phoneCode: phoneCode,
      name: val,
      address: address,
      lat: lat,
      lon: lon,
    });
  };

  const handleAddressChange = (val) => {
    setAddress(val);
    if (val != null && val.length > 0 && lat == null) {
      setLat("");
    } else {
      setLat(null);
    }

    if (val != null && val.length > 0 && lon == null) {
      setLon("");
    } else {
      setLon(null);
    }
    onChange({
      phone: phone,
      phoneCode: phoneCode,
      name: name,
      address: val,
      lat: lat,
      lon: lon
    });
  };

  const handlePhoneChange = (value, data, event, formattedValue) => {
    setPhone(value.slice(data.dialCode.length));
    setPhoneCode(data.dialCode);
    onChange({
      phone: value.slice(data.dialCode.length),
      phoneCode: data.dialCode,
      name: name,
      address: address,
      lat: lat,
      lon: lon
    });
  };

  const handleLatChange = (val) => {
    if (val === "") val = null;

    setLat(val);
    onChange({
      phone: phone,
      phoneCode: phoneCode,
      name: name,
      address: address,
      lat: val,
      lon: lon
    });
  };

  const handleLonChange = (val) => {
    if (val === "") val = null;
    setLon(val);
    onChange({
      phone: phone,
      phoneCode: phoneCode,
      name: name,
      address: address,
      lat: lat,
      lon: val
    });
  };

  const handleLatLonChange = (val) => {
    setLatLon(val);
  };

  const handleLatLonBlur = (val) => {
    let latVal = "";
    let lonVal = "";
    if (val && val !== null &&val !== "") {
     const latLon = val.split(",");
     if (latLon && latLon.length === 2) {
        latVal = latLon[0];
        lonVal = latLon[1];
      }
    }
    setLat(latVal);
    setLon(lonVal);
    setLatLon(val);
    onChange({
      phone: phone,
      phoneCode: phoneCode,
      name: name,
      address: address,
      lat: latVal,
      lon: lonVal
    });
  };

  return (
    <div className="">
      <Grid container xs={12} justify="flex-start">
        <Grid item xs={12}>
          <h3 className="custom-lf">
            <strong>{label}</strong>
          </h3>
        </Grid>
        <Grid item xs={12} justify="flex-start">
          <Paper className={classes.paper}>
            <div className="bt-row">
              <div className="bt-col-md-6">
                <Grid container className="mt-2">
                  <Grid item className="bt-col-md-4">
                    <strong>Name: </strong>
                  </Grid>
                  <Grid item className="bt-col-md-8">
                    <TextValidator
                      id="name"
                      value={name}
                      autoComplete={false}
                      variant="outlined"
                      size="small"
                      onChange={(event) =>
                        handleNameChange(event.target.value)
                      }
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid container className="mt-2">
                  <Grid item className="bt-col-md-4">
                    <strong>Number: </strong>
                  </Grid>
                  <Grid item className="bt-col-md-8">
                    <PhoneValidator
                      enableSearch={true}
                      country={"in"}
                      value={phoneCode + phone}
                      onChange={handlePhoneChange}
                      style={{ width: "100%"}}
                      className="contactNumClass"
                      validators={['required']}
                      errorMessages={['This field is required']}
                    />
                  </Grid>
                </Grid>
                <Grid container className="mt-2">
                  <Grid item className="bt-col-md-4">
                    <strong>Address: </strong>
                  </Grid>
                  <Grid item className="bt-col-md-8">
                    <TextValidator
                      id="address"
                      value={address}
                      autoComplete={false}
                      variant="outlined"
                      size="small"
                      onChange={(event) =>
                        handleAddressChange(event.target.value)
                      }
                      validators={['addressCheck']}
                      errorMessages={['Either address or latitude/longitude needs to be provided']}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className="bt-col-md-6">
                <Grid container className="mt-2">
                  <Grid item className="bt-col-md-4">
                    <strong>Location (Latitude,Longitude): </strong>
                  </Grid>
                  <Grid item className="bt-col-md-8">
                    <TextValidator
                      validators={['matchRegexp:^(-?)(0|([1-9][0-9]*))(\\.[0-9]+)?,(-?)(0|([1-9][0-9]*))(\\.[0-9]+)?$']}
                      id="latlon"
                      value={latLon}
                      autoComplete={false}
                      variant="outlined"
                      size="small"
                      onBlur={(event) =>
                        handleLatLonBlur(event.target.value)
                      }
                      onChange={(event) =>
                        handleLatLonChange(event.target.value)
                      }
                      errorMessages={['Location value should be comma separated numbers in the format Latitude,Longitude - e.g 12.77,70.1']}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid container className="mt-2">
                  <Grid item className="bt-col-md-4">
                    <strong>Latitude: </strong>
                  </Grid>
                  <Grid item className="bt-col-md-8">
                    <TextValidator
                      validators={['matchRegexp:^(-?)(0|([1-9][0-9]*))(\\.[0-9]+)?$']}
                      id="lat"
                      value={lat}
                      autoComplete={false}
                      variant="outlined"
                      size="small"
                      onChange={(event) =>
                        handleLatChange(event.target.value)
                      }
                      errorMessages={['Latitude value should be a number - e.g 12.77']}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid container className="mt-2">
                  <Grid item className="bt-col-md-4">
                    <strong>Longitude: </strong>
                  </Grid>
                  <Grid item className="bt-col-md-8">
                    <TextValidator
                      validators={['matchRegexp:^(-?)(0|([1-9][0-9]*))(\\.[0-9]+)?$']}
                      id="lon"
                      value={lon}
                      autoComplete={false}
                      variant="outlined"
                      size="small"
                      onChange={(event) =>
                        handleLonChange(event.target.value)
                      }
                      errorMessages={['Longitude value should be a number - e.g 77.1']}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

//     {/* {lat && <MapContainer center={[lat, lon]} zoom={15} scrollWheelZoom={false}>
//             <ChangeView center={[lat, lon]} zoom={15} />
//             <TileLayer
//                 attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
//                 url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//             />
//             <Marker position={[lat, lon]} icon={taskNewIcon}>
//                 <Popup>
//                     {address}
//                 </Popup>
//             </Marker>
//         </MapContainer>
//     } */}

//     {

//         <div className="mapouter">
//             <div className="gmap_canvas">
//                 <iframe width="100%" height="500" id="gmap_canvas"
//                     src={mapAddress} frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
//                 </iframe>
//             </div>
//         </div>
// }
