import React from "react";
import { LogChange, NestedArrayChange } from "../../types/types";
import { StyledBox, StyledTypography } from "../Styles/ZRecordLogger.style";
import { truncateString } from "utils/CommonUtils";
import { InfoTooltip } from "views/workflows/Tootltips/InfoTooltip";
import useEntity from "views/entities/hooks/useEntity";

interface ChangeDetailsProps extends LogChange {
    nestedArrayChanges?: NestedArrayChange[];
}

interface ArrayChangeProps {
    nestedChange: NestedArrayChange;
    nestedIndex: number;
    path: string;
    truncateLength: number;
    renderValueWithTooltip: (value: string | number, fullValue: string | any, color: string) => JSX.Element;
}

interface ChangeProps {
    newValue?: string | number | object;
    oldValue?: string | number | object;
    path: string;
    truncateLength: number;
    recordTypeLabel: string;
    renderValueWithTooltip: (value: string | number, fullValue: string | any, color: string) => JSX.Element;
}

const ChangeDetails: React.FC<ChangeDetailsProps> = ({
    path,
    changeType,
    newValue,
    oldValue,
    nestedArrayChanges,
}) => {
    const truncateLength = 7;
    const {selectedEntityMetadata, selectedEntity, recordTypeLabel} = useEntity();

    if (path.toLowerCase().includes("comments")) {
        return null;
    }

    const renderValueWithTooltip = (value: string | number | object, fullValue: string | number | object, color: string) => {
        if (typeof fullValue === 'object' || typeof value === 'object') {
            return <StyledBox color={color} style={{ cursor: "pointer" }}>table</StyledBox>
        }
        let stringValue = String(fullValue);

        return stringValue.length > truncateLength ? (
            <InfoTooltip placement="bottom" title={<pre>{stringValue}</pre>}>
                <StyledBox color={color} style={{ cursor: "pointer" }}>{value}</StyledBox>
            </InfoTooltip>
        ) : (
            <StyledBox color={color}>{stringValue}</StyledBox>
        );
    };

    return (
        <StyledTypography>
            {changeType === "A" && Array.isArray(nestedArrayChanges) && nestedArrayChanges.length > 0 &&
                nestedArrayChanges.map((nestedChange, nestedIndex) => (
                    <ArrayChange
                        nestedChange={nestedChange}
                        nestedIndex={nestedIndex}
                        path={path}
                        truncateLength={truncateLength}
                        renderValueWithTooltip={renderValueWithTooltip}
                    />
                ))
            }

            {changeType === "E" && (
                <EditChange
                    newValue={newValue}
                    oldValue={oldValue}
                    path={path}
                    truncateLength={truncateLength}
                    recordTypeLabel={recordTypeLabel}
                    renderValueWithTooltip={renderValueWithTooltip}
                />
            )}

            {changeType === "N" && (
                <NewChange
                    newValue={newValue}
                    path={path}
                    truncateLength={truncateLength}
                    recordTypeLabel={recordTypeLabel}
                    renderValueWithTooltip={renderValueWithTooltip}
                />
            )}

            {changeType === "D" && (
                <DeleteChange
                    oldValue={oldValue}
                    path={path}
                    truncateLength={truncateLength}
                    recordTypeLabel={recordTypeLabel}
                    renderValueWithTooltip={renderValueWithTooltip}
                />
            )}
        </StyledTypography>
    );
};

const ArrayChange: React.FC<ArrayChangeProps> = ({
    nestedChange,
    nestedIndex,
    path,
    truncateLength,
    renderValueWithTooltip }) => {
    const truncatedPath = truncateString(path, truncateLength);
    return (
        <React.Fragment key={nestedIndex}>
            {nestedChange.changeType === "N" && (
                <div>
                    Added array data {renderValueWithTooltip(`+${truncateString(nestedChange.newValue, truncateLength)}`, nestedChange.newValue, "#039855")}{" "}
                    to {renderValueWithTooltip(truncatedPath, path, "#3054B9")}
                </div>
            )}
            {nestedChange.changeType === "E" && (
                <div>
                    Modified array data from {renderValueWithTooltip(`-${truncateString(nestedChange.oldValue, truncateLength)}`, nestedChange.oldValue, "#D92D20")}{" "}
                    to {renderValueWithTooltip(`+${truncateString(nestedChange.newValue, truncateLength)}`, nestedChange.newValue, "#039855")}{" "}
                    in {renderValueWithTooltip(truncatedPath, path, "#3054B9")}
                </div>
            )}
            {nestedChange.changeType === "D" && (
                <div>
                    Deleted array data {renderValueWithTooltip(`-${truncateString(nestedChange.oldValue, truncateLength)}`, nestedChange.oldValue, "#D92D20")}{" "}
                    from {renderValueWithTooltip(truncatedPath, path, "#3054B9")}
                </div>
            )}
        </React.Fragment>
    );
};

const EditChange: React.FC<ChangeProps> = ({
    newValue,
    oldValue,
    path,
    truncateLength,
    recordTypeLabel,
    renderValueWithTooltip }) => {
    const truncatedPath = truncateString(path, truncateLength);
    return (
        <div>
            Modified {recordTypeLabel} from {oldValue !== null && oldValue !== "" ? renderValueWithTooltip(truncateString(oldValue, truncateLength), oldValue, "#039855") : <StyledBox color="#039855">null</StyledBox>}{" "}
            to {newValue !== null && newValue !== "" ? renderValueWithTooltip(truncateString(newValue, truncateLength), newValue, "#039855") : <StyledBox color="#039855">null</StyledBox>}{" "}
            in {renderValueWithTooltip(truncatedPath, path, "#3054B9")}
        </div>
    );
};

const NewChange: React.FC<ChangeProps> = ({
    newValue,
    path,
    truncateLength,
    renderValueWithTooltip }) => {
    const truncatedPath = truncateString(path, truncateLength);
    return (
        <div>
            Entered {newValue !== null && newValue !== "" && typeof newValue === "string" ? renderValueWithTooltip(truncateString(newValue, truncateLength), newValue, "#039855") : Array.isArray(newValue) && newValue.length > 0 ? renderValueWithTooltip("first entity", "first entity", "#039855") : <StyledBox color="#039855">null</StyledBox>}{" "}
            in {renderValueWithTooltip(truncatedPath, path, "#3054B9")}
        </div>
    );
};

const DeleteChange: React.FC<ChangeProps> = ({
    oldValue,
    path,
    truncateLength,
    renderValueWithTooltip }) => {
    const truncatedPath = truncateString(path, truncateLength);
    return (
        <div>
            Deleted {oldValue !== null && oldValue !== "" ? renderValueWithTooltip(truncateString(oldValue, truncateLength), oldValue, "#D92D20") : <StyledBox color="#D92D20">null</StyledBox>}{" "}
            from {renderValueWithTooltip(truncatedPath, path, "#3054B9")}
        </div>
    );
};

export default ChangeDetails;