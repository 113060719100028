import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { ZorpResponse } from 'providers/data/models/ZorpResponse';
import { TaskFlowService } from 'providers/data/services/TaskFlowService';
import { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactSelect from 'react-select';
import { RedocStandalone } from 'redoc';
import { recordRSEvent } from 'utils/CommonUtils';
import { ZLabel } from 'views/commonComponents/commonComponents.styles';
import notification from '../../notifications/notifications';

interface workFlowDropdownValueType {
  value: string;
  label: string;
}

export const Document: FunctionComponent = () => {
  const { taskType } = useParams();
  const [openAPISchemaJson, setOpenAPISchemaJson] = useState();
  const [taskTypes, setTaskTypes] = useState([]);
  const [selectedTaskType, setSelectedTaskType] = useState<workFlowDropdownValueType | undefined>({ value: "", label: "" });

  const handleChange = (value: workFlowDropdownValueType) => {
    recordRSEvent('get_api_documentation', {
      context: 'workflow_api_documentation',
      taskType: value.value,
    });
    setSelectedTaskType(value);
  };
  useEffect(() => {
    TaskFlowService.fetchActiveWorkflows().then((res: ZorpResponse | undefined) => {
      if (res?.code == '200') {
        const taskTypeList = res.data.map((taskType: any) => {
          return {
            label: taskType.taskTypeDisplayName,
            value: taskType.taskType
          }
        })
        setTaskTypes(taskTypeList);
      } else {
        notification('error', 'Error while fetching workflow list');
      }
    })
      .catch((error) => {
        notification('error', 'Error while fetching workflow list');
      });

  }, [])

  useEffect(() => {
    if (!taskType) {
      setSelectedTaskType(taskTypes[0]);
    } else {
      const workflowType = taskTypes.find((value: workFlowDropdownValueType) => value.value == taskType);
      setSelectedTaskType(workflowType);
    }
  }, [taskTypes])

  useEffect(() => {
    if (selectedTaskType?.value) {
      const taskOpenApiResponse = TaskFlowService.getDocApiByTaskType(selectedTaskType.value);
      taskOpenApiResponse.then((res) => {
        if (res?.code == '200') {
          setOpenAPISchemaJson(res?.data?.staticJson)
        } else {
          notification('error', 'Error while fetching API Documentation');
        }
      })
        .catch((err) => {
          notification('error', 'Error while fetching API Documentation');
        });
    }
  }, [selectedTaskType?.value])

  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
    }),
    menu: (base: any) => ({
      ...base,
      borderRadius: 0,
      opacity: 1,
      zIndex: 100,
      marginTop: 0
    }),
    menuList: (base: any) => ({
      ...base,
      opacity: 1,
      zIndex: 100,
      padding: 0
    })
  };

  return (
    <>
      <Grid className='doc-left-margin' style={{ display: 'flex', alignContent: 'center', alignItems: 'center', marginTop: 20, marginBottom: 20}}>
        <Grid style={{ width: 250 }}>
          <Box sx={{ minWidth: 120, marginBottom:1 }}>
            <ZLabel sx={{ mb: 0.5 }}>Workflow</ZLabel>
          </Box>
          {
            selectedTaskType &&
            <ReactSelect defaultValue={selectedTaskType} onChange={(value: any) => { handleChange(value) }} styles={customStyles} options={taskTypes} />
          }
        </Grid>
      </Grid>
      <RedocStandalone
        specUrl={openAPISchemaJson}
        options={{
          downloadFileName:'Zorp_api_collection',
          unstable_ignoreMimeParameters: false,
          hideDownloadButton: false,
          nativeScrollbars: false,
          theme: { colors: { primary: { main: '#dd5522' } } }, labels: {
            download: "Download Postman Collection",
            downloadSpecification: "Postman Collection"
          },
        }}
      />
    </>
  )
}