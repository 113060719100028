import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import * as React from 'react';

export const InfoTooltip = styled(({ className, ...props }) => <Tooltip placement='top' {...props} classes={{ popper: className }} />)(
 
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor:'#fff',
      color: '#667085',
      maxWidth: 250,
      fontSize: "12px",
      borderRadius: '4px',
      fontWeight: 400,
      border: "1px solid var(--gray-300, #D0D5DD)",
      boxShadow: "box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
      padding:"8px"
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: '#fff', // Change the color of the arrow to match the tooltip background
      '&:before': {
        border: '1px solid var(--gray-300, #D0D5DD)', // Border for the arrow
      },
    },
  })
);
