import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import createList from "assests/createList.svg";
import exportList from "assests/exportList.svg";
import ListColumn from "assests/listBox.svg";
import { IFilterContext } from "filter/constants/Filter.types";
import { filterTitle } from "filter/constants/FilterConstants";
import FilterModal from "filter/FilterModal";
import { clearFilterStorage, getFilterCount } from "filter/filterUtils";
import useApplyFilter from "filter/hooks/useApplyFilter";
import jsonExport from "jsonexport/dist";
import { forOwn, isEmpty, sortBy, uniq, uniqBy } from "lodash";
import { Fragment, useEffect, useState } from "react";
import {
  ChipField,
  downloadCSV,
  ExportButton,
  List,
  TextField,
  TopToolbar,
  useRecordContext,
} from "react-admin";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { resetListInfo } from "render-engine/reducers/listInfo";
import { recordRSEvent } from "utils/CommonUtils";
import { userDecodedData } from "utils/UserAuth.utils";
import { groupArrayByKey } from "utils/Utils";
import {
  ZButton,
  ZIconButton,
} from "views/commonComponents/commonComponents.styles";
import ListPagination from "views/commonComponents/ListPagination";
import CustomDateField from "views/components/CustomDatefFieldComponent";
import CustomListField from "views/components/CustomListField";
import { DataFieldType } from "views/DataField/DataField.types";
import GridExportModal from "views/TaskExport/GridExportModal";
import BulkDeleteActionButton from "views/tasks/components/BulkDeleteActionButton";
import BulkUpadateTeamActionButton from "views/tasks/components/BulkUpdateTeamActionButton";
import { staticFilterData } from "views/tasks/TaskConstants";
import FilterButton from "views/users/components/FilterButton";
import EmptyScreen from "views/workflows/emptyScreen/EmptyScreen";
import {
  PageCategoryEnum,
  recordPageVisit,
} from "../../../../../config/Rudderstack";
import { hasPermission } from "../../../../../providers/auth/authUtils";
import { initTaskCreation } from "../../../../../render-engine/reducers/create";
import { useStyles } from "../../../../../styles/tableStyles";
import LocalUserSettings from "../../../../../utils/LocalUserSettings";
import { getTaskTypeCustomFields } from "../../../../../utils/TasktypeUtils";
import CustomBulkActionButton from "../../../../components/CustomBulkActionButton";
import CustomizableDatagrid from "../../../../components/CustomizableDatagrid";
import LoadingScreen from "../../../../LoadingScreen/LoadingScreen";
import { setLoading } from "../../../../reducer/index";
import { TaskService } from "../../../services/TaskService";
import { useLocation } from "react-router-dom";
import { ZIconButton2 } from "views/entities/styles";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import ZModal from "views/commonComponents/ZModal";
import { CurlModal } from "views/workflows/workflowactions/api/APIAction/ImportCurl/CurlStyledComponents";
import SelectTableTypeModal from "./SelectTableTypeModal";
import { TaskRenderService } from "providers/data/services/TaskRenderService";
import notification from "notifications/notifications";


const TaskListActions = ({
  resource,
  filters,
  displayedFilters,
  hideFilter,
  filterValues,
  setFilters,
  basePath,
  showFilter,
  refresh,
  modalOpened,
  setShowColumnSelector,
  showTaskTypeSelector,
  setShowTaskTypeSelector,
  ...rest
}) => {
  const getRootPath = () => {
    // get the url
    const url = window.location.href;
    // split on the basis of "/"
    const urlArray = url.split("/");
    // find the index of #
    const index = urlArray.indexOf("#");
    let rootPath = "/tasks";
    if (urlArray.length > index + 1) {
      rootPath = "/" + urlArray[index + 1] + "/tasks" || "/tasks";
    }
    return rootPath;
  };

  const getFilterContext = () => {
    // get the url
    const url = window.location.href;
    // split on the basis of "/"
    const urlArray = url.split("/");
    // find the index of #
    const index = urlArray.indexOf("#");
    let context = IFilterContext.TASK;
    if (urlArray.length > index + 1) {
      const basePath = urlArray[index + 1];
      if (basePath !== "tasks") {
        context = urlArray[index + 1] || IFilterContext.TASK;
      }
    }
    return context;
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [showFlag, setShowFlag] = useState(false);
  const [filterModal, setOpenFilterModal] = useState(false);
  const getFilterValue = useApplyFilter(IFilterContext.TASK, setFilters);
  const [filterCount, setFilterCount] = useState(
    getFilterValue.filterCount || 0
  );
  const dispatch = useDispatch();
  const [modalPosition, setModalPosition] = useState([0, 0, 0]); //[X,Y]
  const { userId = "", accountId = "" } = userDecodedData();
  const [context, _] = useState(() => getFilterContext());
  const history = useHistory();
  

  useEffect(() => {
    recordPageVisit(PageCategoryEnum.task, "task_list");
    dispatch(resetListInfo());
  }, []);

  useEffect(() => {
    if (getFilterValue?.filterCount >= 0) {
      setFilterCount(getFilterValue.filterCount);
    }
  }, [getFilterValue.filterCount]);

  useEffect(() => {
    for (const [key, value] of Object.entries(rest?.data)) {
      if (
        value?.taskType === "field_visit_task" ||
        value?.taskType === "pickup_and_drop_task"
      )
        setShowFlag(true);
      break;
    }
    dispatch(initTaskCreation({}));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFilterCount(getFilterCount(IFilterContext.TASK, userId));
  }, []);

  const handleClick = (event) => {
    recordRSEvent("taskcreate_button_click", {
      context: "navigation",
    });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 500;
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  return (
    <TopToolbar className="tool-bar">
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          gap: "10px",
        }}
      >
        {width > breakpoint ? (
          <ZButton
            startIcon={
              width > breakpoint ? (
                <img style={{ paddingRight: "6px" }} src={ListColumn} />
              ) : (
                <></>
              )
            }
            onClick={() => setShowColumnSelector(true)}
            secondary
            style={{
              fontWeight: 600,
              fontSize: "16px",
              color: "#616569",
              marginRight: "12px",
            }}
            variant="contained"
          >
            {width > breakpoint ? (
              "Edit Table Columns"
            ) : (
              <img style={{ paddingRight: "6px" }} src={ListColumn} />
            )}
          </ZButton>
        ) : (
          <ZIconButton2 onClick={() => setShowColumnSelector(true)}>
            <img style={{ paddingRight: "6px" }} src={ListColumn} />
          </ZIconButton2>
        )}        
        {accountId !== 'dxzpm5' ? <FilterButton
          filterCount={filterCount}
          onRemoveFilterClick={() => {
            setFilters({}, []);
            setFilterCount(0);
            clearFilterStorage(IFilterContext.TASK, userId);
          }}
          onOpenFilterClick={(pos) => {
            const x =
              pos.current.offsetParent.offsetLeft + pos.current.offsetLeft;
            const y =
              pos.current.offsetParent.offsetParent.offsetTop +
              pos.current.offsetTop +
              pos.current.offsetHeight;
            const buttonHeight = pos.current.offsetHeight;
            const buttonWidth = pos.current.offsetWidth;
            setModalPosition([x, y, buttonHeight, buttonWidth]);
            setOpenFilterModal(true);
            showFilter("main");
          }}
        /> : <></> }

        {displayedFilters?.main && filterModal && (
          <FilterModal
            position={modalPosition}
            filterTitle={filterTitle}
            urlEndpoint={getRootPath}
            context={IFilterContext.TASK}
            openModal={displayedFilters?.main}
            handleClose={(appliedFilterCount) => {
              setOpenFilterModal(false);
              hideFilter("main");
              if (appliedFilterCount >= 0) {
                setFilterCount(appliedFilterCount);
              }
            }}
          />
        )}

        <ExportButton
          variant="contained"
          secondary
          icon={<img src={exportList} />}
          style={{
            border: "1px solid #c2c9d1",
            color: "#344054",
            fontSize: "1rem",
            // fontWeight: "600",
            backgroundColor: "#ffffff",
            marginRight: "0px",
            padding: "6px 16px",
            width: "87px",
            borderRadius: "4px",
          }}
          onClick={() =>
            recordRSEvent("export_icon_click", {
              context: "navigation",
            })
          }
        />
        {hasPermission("task", "create") &&
          (width > breakpoint ? (
            <>
              {
                <ZButton
                  className="ml-2"
                  style={{ marginRight: "12px", marginLeft: "0px" }}
                  aria-controls="simple-menu"
                  onClick={handleClick}
                  startIcon={
                    width > breakpoint ? <img src={createList} /> : <></>
                  }
                  variant="contained"
                >
                  {width > breakpoint ? "Add Task" : <img src={createList} />}
                </ZButton>
              }
            </>
          ) : (
            <ZIconButton2 onClick={handleClick}>
              <img src={createList} />
            </ZIconButton2>
          ))}
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            className="svg_icons_m"
            onClick={() => {
              recordRSEvent("singletask_create_button", {
                context: "signle_task_creation",
              });
              recordRSEvent("singletaskcreate_button_click", {
                context: "navigation",
              });
              // history.push("/tasks/create");
              setShowTaskTypeSelector(true);
            }}
            // containerelement={<Link to="/create" />}
          >
            Single Task
          </MenuItem>

          <MenuItem
            className="svg_icons_m"
            onClick={() => {
              recordRSEvent("bulktask_create_button", {
                context: "bulk_task_creation",
              });
              recordRSEvent("bulktaskcreate_button_click", {
                context: "navigation",
              });
              history.push("/bulktask");
            }}
            containerelement={<Link to="/bulktask" />}
            primarytext="Bulk Tasks"
          >
            Bulk Tasks
          </MenuItem>

          <MenuItem
            className="svg_icons_m"
            containerelement={<Link to="/document" />}
            primarytext="API"
            onClick={() => {
              history.push("/document");
            }}
            style={{
              padding:"8px 4px !important"
            }}
          >
            API
          </MenuItem>
        </Menu>
        
        
      </div>
    </TopToolbar>
  );
};

const ConditionalChipField = (props) => {
  const theme = useTheme()
  const classes = useStyles({theme});
  const record = useRecordContext(props);
  if (record && record.taskInInitialState === true)
    return (
      <ChipField
        className="svg_icons_m"
        label="Task Status"
        style={{
          backgroundColor: record.isDeleted ? "#D3D3D3" : "#F7CF60",
          margin: "-9px 0px -6px -12px",
          color: "black",
          fontSize: "14px",
          fontWeight: "400",
        }}
        source="taskStatusName"
        classes={{ chip: classes.chip }}
      />
    );
  if (record && record.taskInTerminalState === true)
    return (
      <ChipField
        className="svg_icons_m"
        label="Task Status"
        style={{
          backgroundColor: record.isDeleted ? "#D3D3D3" : "#8ADA89",
          margin: "-9px 0px -6px -12px",
          color: "black",
          fontSize: "14px",
          fontWeight: "400",
        }}
        source="taskStatusName"
        classes={{ chip: classes.chip }}
      />
    );

  return (
    <ChipField
      className="svg_icons_m"
      label="Task Status"
      style={{
        backgroundColor: record.isDeleted ? "#D3D3D3" : "#F7CF60",
        margin: "-9px 0px -6px -12px",
        color: "black",
        fontSize: "14px",
        fontWeight: "400",
      }}
      source="taskStatusName"
      classes={{ chip: classes.chip }}
    />
  );
};

const TaskList = (props) => {
  const theme = useTheme()
  const classes = useStyles({theme});
  const dispatch = useDispatch();
  const [taskFields, setTaskFields] = useState([]);
  const { userId = "", accountId = "" } = userDecodedData();
  const { loading } = useSelector((state) => state.loading);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [taskTypeFields, setTaskTypeFields] = useState({});
  const [allRowsFill, setAllRowsFill] = useState(true);
  const [taskExportData, setTaskExportData] = useState([]);
  const [preview, setPreview] = useState(() =>
    localStorage.getItem("isPreview")
  );
  const [taskTypeCustomDataFieldsList, setTaskTypeCustomDataFieldsList] =
    useState([]);
  const [showColumnSelector, setShowColumnSelector] = useState(false);
  const { search } = useLocation();
  const [showTaskTypeSelector, setShowTaskTypeSelector] = useState(false)

  const [taskTypes, setTaskTypes] = useState([]);

  useEffect(() => {
    TaskService.getTaskTypes(false, true).then((res) => {
      let tt = {};
      res?.data?.forEach((t) => {
        tt[t.taskType] = {
          label: t.taskTypeDisplayName,
          value: t.taskType,
          legacy: true,
        };
      });
      TaskRenderService.listRenderConfigs(true)
        .then((res) => {
          if (res?.code && res?.code.indexOf("200") < 0) {
            if (res?.code === "401") {
              notification("error", "Session expired");
            } else {
              notification("error", res.message);
            }
          } else {
            res?.data?.forEach((t) => {
              tt[t.taskType] = {
                label: t.taskTypeDisplayName,
                value: t.taskType,
                legacy: false,
              };
            });
          }
          let ttNew = [];
          forOwn(tt, function (value, key) {
            ttNew.push(value);
          });
          ttNew = uniqBy(sortBy(tt, ["label"]), "value");
          setTaskTypes(ttNew);
        })
        .catch((err) => {
          console.error("Error", err);
        });
    });
  }, []);

  const fetchTaskTypeCustomDataFieldsList = async () => {
    try {
      const response = await TaskService.getTaskTypeDataFields();
      const customDataFieldsList = response?.data;
      setTaskTypeCustomDataFieldsList(customDataFieldsList);
    } catch (error) {
      console.error("** res something went wrong", error);
    }
  };

  useEffect(() => {
    fetchTaskTypeCustomDataFieldsList();
    recordRSEvent("page_load", {
      context: "task",
      userId,
    });
    if (!isEmpty(userDecodedData())) {
      fetchTaskTypeCustomDataFieldsList();
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(userDecodedData())) {
      dispatch(setLoading(true));
      const taskTypesRes = TaskService.getTaskTypes(true, true);
      taskTypesRes
        .then((res) => {
          setTaskTypeFields([]);
          const isTaskTypeNotPresent =
            res?.data?.filter((tt) => tt.taskType === props.type).length === 0;

          if (isTaskTypeNotPresent) {
            res?.data.push({
              taskType: props.type,
              taskTypeDisplayName: props.type,
            });
          }

          let tFileds = [];
          let columnSourcesString = [];
          let taskTypeFieldsArray = [];
          res?.data?.forEach((taskTypeObj) => {
            if (taskTypeCustomDataFieldsList.length > 0) {
              const taskTypeCustomFields = getTaskTypeCustomFields(
                taskTypeObj.taskType,
                taskTypeObj.taskTypeDisplayName,
                taskTypeCustomDataFieldsList
              );
              taskTypeFieldsArray =
                taskTypeFieldsArray.concat(taskTypeCustomFields);

              if (taskTypeCustomFields.length > 0) {
                taskTypeCustomFields.forEach((f) => {
                  if (!columnSourcesString.includes(`${f.name}+${f.type}`)) {
                    columnSourcesString.push(`${f.name}+${f.type}`);

                    let lKey =
                      taskTypeObj.isBuilderEditable === false
                        ? `data[0].info["${f.name}"]`
                        : `data[${f.name}]`;
                    let sortByKey =
                      f.isBuilderEditable === false
                        ? `data[0].orderInfo["${f.name}"]`
                        : `data.${f.name}`;

                    if (f.type === DataFieldType.COLLECTION) {
                      tFileds.push(
                        <CustomListField
                          textAlign="left"
                          label={f.displayName}
                          source={"data." + f.name}
                        />
                      );
                    } else {
                      if (f.type === DataFieldType.DATETIME) {
                        tFileds.push(
                          <CustomDateField
                            showTime
                            sortable={true}
                            textAlign="left"
                            label={f.displayName}
                            source={lKey}
                            sortBy={sortByKey}
                            options={{
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                            }}
                          />
                        );
                      } else {
                        tFileds.push(
                          <TextField
                            sortable={true}
                            textAlign="left"
                            label={f.displayName}
                            source={lKey}
                            sortBy={sortByKey}
                          />
                        );
                      }
                    }
                  }
                });
              }
            }
          });
          staticFilterData.customDataFields = groupArrayByKey(
            taskTypeFieldsArray,
            "taskType"
          );
          setTaskTypeFields(staticFilterData);
          setTaskFields(tFileds);
          dispatch(setLoading(false));
        })
        .catch((e) => {
          console.error(e);
          dispatch(setLoading(false));
        });
    }
  }, [taskTypeCustomDataFieldsList]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isEmpty(userDecodedData())) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }

  const CustomStorage = {
    get: (resourceName) => {
      if (isEmpty(userDecodedData())) {
        window.location.href = "/#/login";
        window.location.reload();
      }
      resourceName = userId;
      return LocalUserSettings.get(resourceName);
    },
    set: (resourceName, selectedColumns) => {
      resourceName = userId;
      LocalUserSettings.set(resourceName, selectedColumns);
    },
  };

  const PostBulkActionButtons = (props) => (
    <Fragment>
      {accountId === "porter" && <CustomBulkActionButton {...props} />}
      <BulkUpadateTeamActionButton {...props} />
      {hasPermission("task", "delete") && <BulkDeleteActionButton {...props} />}
    </Fragment>
  );

  const postRowStyle = (record, index) => ({
    backgroundColor: record?.isDeleted ? "#C0C0C0" : undefined,
  });

  const customizeTaskExportField = (tasks) => {
    recordRSEvent("export_grid", {
      context: "task_export_grid",
    });
    setTaskExportData(tasks);
    setFilterModalOpen(true);
  };

  const exportData = (fieldsToIncludeInExport, inputData) => {
    const tasksForExport = JSON.parse(inputData).map((task) => {
      const {
        id,
        teams,
        user,
        taskInInitialState,
        taskInTerminalState,
        progress,
        scheduledSlot,
        pickupDetails,
        customerDetails,
        data,
        formData,
        taskTypeDisplayName,
        taskId,
        taskType,
        taskStatus,
        createdOn,
        updatedOn,
        taskStatusName,
        accountId,
        accountName,
        isDeleted,
        userId,
        teamIds,
        teamsInfo,
        slot,
        ...taskForExport
      } = task; // omit backLinks and author
      if (task.taskType === '4jbhs4'){
        staticFilterData.coreDataFields = staticFilterData.coreDataFields.filter((field) => field.name !== 'userName' && field.name !== 'userId' && field.name !== 'userPhoneNumber');
      } 
      fieldsToIncludeInExport.forEach((individualObj) => {
        taskForExport[individualObj] = task.data[individualObj];
        if (
          staticFilterData.coreDataFields.find(
            (data) => data.name == individualObj
          )
        ) {
          if (
            individualObj == "userId" ||
            individualObj == "userName" ||
            individualObj == "userPhoneNumber"
          ) {
            if (individualObj == "userPhoneNumber") {
              taskForExport[individualObj] = task?.user?.phone?.num
                ? task?.user?.phone?.countryCode + "-" + task?.user?.phone?.num
                : "";
            } else {
              taskForExport[individualObj] =
                task?.user?.[
                  individualObj == "userName" ? "name" : individualObj
                ];
            }
          } else {
            taskForExport[individualObj] = task?.[individualObj];
          }
        }
      });
      return taskForExport;
    });

    const columnOrder = [];
    staticFilterData.coreDataFields.forEach((data) => {
      if (fieldsToIncludeInExport.includes(data.name)) {
        columnOrder.push(data.name);
      }
    });

    /**
     * tasksForExport - Task Export Array data
     * allRowsFill - If true, then fill all additional rows of list with the values, instead of empty
     */

    const taskExportFillData = (tasksForExport, allRowsFill) => {
      if (allRowsFill) {
        try {
          // Fill the list fields with previous values
          const finalArray = [];
          tasksForExport.forEach((exportData) => {
            // array of property name in task data
            const exportDataKeys = Object.keys(exportData);

            // array of list of List Property
            const arrDataTypeLst = [];

            exportDataKeys.forEach((key) => {
              // checking the property type is array and the type of the first element in array is object
              if (
                Array.isArray(exportData[key]) &&
                typeof exportData[key][0] == "object"
              ) {
                arrDataTypeLst.push({
                  key,
                  length: exportData[key].length,
                  value: exportData[key],
                });
              }
            });

            // sorting the
            const sortedArrayBasedOnLength =
              arrDataTypeLst.sort((a, b) => b.length - a.length) || [];

            if (sortedArrayBasedOnLength.length > 0) {
              Array(sortedArrayBasedOnLength?.[0].length)
                .fill()
                .forEach((undefinedValue, sortedIndex) => {
                  let obj = {};
                  arrDataTypeLst.map((data) => {
                    const newObject = Object.keys(
                      data.value[sortedIndex] || {}
                    ).reduce((result, key) => {
                      result[`${data.key}.${key}`] =
                        data.value[sortedIndex][key];
                      return result;
                    }, {});
                    obj = { ...obj, ...newObject };
                    delete exportData[data.key];
                  });
                  finalArray.push({ ...exportData, ...obj });
                });
            }

            if (sortedArrayBasedOnLength.length == 0) {
              finalArray.push(exportData);
            }
          });
          return finalArray;
        } catch (error) {
          return tasksForExport;
        }
      } else {
        return tasksForExport;
      }
    };

    jsonExport(
      taskExportFillData(tasksForExport, allRowsFill),
      {
        headers: columnOrder, // order fields in the export
      },
      (err, csv) => {
        downloadCSV(csv, "Tasks"); // download as 'task_data.csv` file
      }
    );
  };

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <GridExportModal
            allRowsFill={allRowsFill}
            onAllRowsFillClick={() => {
              setAllRowsFill(!allRowsFill);
            }}
            exportData={(fieldsToIncludeInExport) => {
              exportData(
                fieldsToIncludeInExport,
                JSON.stringify(taskExportData)
              );
            }}
            modalTitle="Export Task Data"
            inputListData={taskTypeFields}
            isOpen={filterModalOpen}
            setShowModal={setFilterModalOpen}
          />
          <List
            {...props}
            className="taskclasscust"
            perPage={preview ? 10 : 25}
            classes={{
              headerCell: classes.headerCell,
              headerRow: classes.headerRow,
              rowEven: classes.rowEven,
              rowOdd: classes.rowOdd,
              rowCell: classes.rowCell,
              table: classes.table,
            }}
            sort={{ field: "updatedOn", order: "DESC" }}
            actions={
              <TaskListActions
                modalOpened={showColumnSelector}
                setShowColumnSelector={setShowColumnSelector}
                showTaskTypeSelector={showTaskTypeSelector}
                setShowTaskTypeSelector={setShowTaskTypeSelector}
                {...props}
              />
            }
            bulkActionButtons={<PostBulkActionButtons />}
            pagination={<ListPagination />}
            exporter={customizeTaskExportField}
            empty={<EmptyScreen callback={() => setShowTaskTypeSelector(true)} />}
          >
            <CustomizableDatagrid
              modalOpened={showColumnSelector}
              handleOpen={() => setShowColumnSelector(true)}
              handleClose={() => setShowColumnSelector(false)}
              defaultColumns={[
                "taskId",
                "taskTypeDisplayName",
                "createdOn",
                "updatedOn",
                "user.name",
                "slot",
                "teamsInfo",
              ]}
              storage={CustomStorage}
              classes={{
                headerCell: classes.headerRow,
                rowEven: classes.rowEven,
                rowOdd: classes.rowOdd,
                rowCell: classes.row,
              }}
              rowClick={(id, basePath, record) => {
                recordRSEvent("taskrow_clicked", {
                  context: "task_listing",
                  task_id: record.taskId,
                  task_type: record.taskType,
                });
                if (preview) {
                  window.parent.postMessage(
                    {
                      source: "zorp_web_preview",
                      name: "task_row_click",
                      payload: {
                        taskId: record.taskId,
                      },
                    },
                    "*"
                  );
                }
                // return "show";
                return `/task/${record.taskType}/show/${record.taskId}`;
              }}
              key={1000 * Math.random()}
              rowStyle={postRowStyle}
              isRowSelectable={(record) => !record?.isDeleted}
            >
              <TextField
                sortable={true}
                textAlign="left"
                label="Task Id"
                source="taskId"
              />
              <TextField
                sortable={true}
                textAlign="left"
                label="Work Flow/Task Type"
                source="taskTypeDisplayName"
              />
              <TextField
                sortable={true}
                textAlign="left"
                label="Created On"
                source="createdOn"
              />
              <TextField
                sortable={true}
                textAlign="left"
                label="Updated On"
                source="updatedOn"
              />
              <TextField
                sortable={true}
                textAlign="left"
                label="Scheduled Slot"
                source="slot"
                sortBy="scheduledSlot.from"
              />
              <TextField
                sortable={true}
                textAlign="left"
                label="User"
                source="user.name"
                sortBy="user.name"
              />
              <TextField
                key={8}
                sortable={false}
                textAlign="left"
                label="Team"
                source="teamsInfo"
              />
              {taskFields}
              <ConditionalChipField
                sortable={true}
                label="Task Status"
                sortBy="taskStatusName"
              />
            </CustomizableDatagrid>
          </List>
          {/* {
               <CurlModal
              open={showTaskTypeSelector}
              onClose={() => {
                  setShowTaskTypeSelector(false)
              }}
            >
              <SelectTableTypeModal taskTypes={taskTypes}/>
            </CurlModal>
            } */}
            {
              showTaskTypeSelector && <SelectTableTypeModal showTaskTypeSelector={showTaskTypeSelector} taskTypes={taskTypes} setShowTaskTypeSelector={setShowTaskTypeSelector}/>
            }
        </>
      )}
    </>
  );
};

export default TaskList;
