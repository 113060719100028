//@ts-nocheck
import { TEXT } from "constants/CommonConstants";
import { EditorScriptLocation } from "lowcode/state/types/types";
import { UseFormRegister } from "react-hook-form";
import usePayload from "views/automationBuilder/hooks/usePayload";
import useWorkflow from "views/workflows/hooks/useWorkFlow";
import ModalSmartInputTextField from "views/workflows/workflowactions/api/components/ModalSmartInputTextField";
import { Label } from "views/workflows/workflowactions/ModalContent.styles";

type Props = {
  onChangeFunc: Function;
  register: UseFormRegister<any>;
  openJSEditorLayout: any;
  errors: any;
  config: any;
  width?: string;
  label: string;
  scriptButtonOnClick?: () => void;
  apiBodyPayload: any;
};

const ErrorMessageInput = ({
  onChangeFunc,
  register,
  openJSEditorLayout,
  config,
  errors,
  label,
  scriptButtonOnClick,
  apiBodyPayload,
  width,
}: Props) => {
  const { selectedAction } = useWorkflow();

  return (
    <>
      <Label style={{ marginTop: "20px", marginBottom: "8px" }}>
        {" "}
        {label}{" "}
      </Label>
      <ModalSmartInputTextField
        type={selectedAction?.errorMessage?.type}
        dataType={TEXT}
        onChangeFunc={onChangeFunc}
        placeholder="Enter the error message"
        register={register}
        minWidth={width}
        scriptButtonOnClick={scriptButtonOnClick}
        value={selectedAction?.errorMessage?.value}
        name={EditorScriptLocation.ERROR_MESSAGE}
        isSmartField={true}
        config={config}
        errors={errors}
        editorLocation={EditorScriptLocation.ERROR_MESSAGE}
        openJSEditor={openJSEditorLayout}
        apiBodyPayload={apiBodyPayload}
        width="100%"
      />
    </>
  );
};

export default ErrorMessageInput;
