import { UseFormReturn } from 'react-hook-form';
import { Dispatch } from 'redux';
import { setValue } from './reducers/create';
import { setValue as setValueEdit } from './reducers/edit';

export const getComponentParams = (dispatch: Dispatch, form: UseFormReturn, onChangeHandler?: Function): any => {
  const handleChange = (key: string, val: any) => {
    // Split the key with dots. This ensures that object values are being considered
    // for ex, `customerLocation.address` should have `address` attribute going into `customerLocation` object
    dispatch(setValue({ key: key, value: val }));
  };

  return {
    onChange: onChangeHandler || handleChange,
    register: form.register,
    errors: form.formState.errors,
    control: form.control,
    setValue: form.setValue,
    getValues: form.getValues,
    isOpenedAsSubfield: false,
    setError: form.setError,
    clearErrors: form.clearErrors,
  };
};

export const getComponentParamsEdit = (dispatch: Dispatch, form: UseFormReturn, onChangeHandler?: Function): any => {
  const handleChange = (key: string, val: any) => {
    // Split the key with dots. This ensures that object values are being considered
    // for ex, `customerLocation.address` should have `address` attribute going into `customerLocation` object
    dispatch(setValueEdit({ key: key, value: val }));
  };

  return {
    onChange: onChangeHandler || handleChange,
    register: form.register,
    errors: form.formState.errors,
    control: form.control,
    setValue: form.setValue,
    getValues: form.getValues,
    isOpenedAsSubfield: false,
    setError: form.setError,
    clearErrors: form.clearErrors,
  };
};
