import { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useDispatch, useSelector, useStore } from "react-redux"
import { useHistory } from "react-router-dom"
import { ITaskTypeWebRenderModel } from "render-engine/models/ITaskTypeWebRenderModel"
import { recordRSEvent } from "utils/CommonUtils"
import notification from "../../notifications/notifications"
import LoadingScreen from "../../views/LoadingScreen/LoadingScreen"
import { setLoading } from "../../views/reducer/index"
import { Render } from "render-engine/Render"
import { getComponentParamsEdit } from "render-engine/RenderUtils"
import { cancelTaskEdit } from "render-engine/reducers/edit"
import { TaskFormBody } from "render-engine/render.styles"
import TopBar from "render-engine/TopBar"
import { useParams } from "react-router-dom"
import { EntityService } from "providers/data/services/EntityService"
import useEntity from "./hooks/useEntity"
import { AiOutlineArrowLeft } from "react-icons/ai"
import { BiEdit } from "react-icons/bi"
import { ZButton } from "views/commonComponents/commonComponents.styles"
import { ViewModeHeader } from "./styles"
import { error } from "ajv/dist/vocabularies/applicator/dependencies"
import { TableRSEvents } from "./types"
import usePorterPermissions from "hooks/usePorterPermissions"
import { isPlainObject } from "lodash"
import { clearTaskData, setTaskData } from "render-engine/reducers/show/TaskShowReducer"

const EntityEdit = (props: any) => {
  const store = useStore()
  const history = useHistory()
  const dispatch = useDispatch()
  const editTaskForm = useForm()
  const { recordId, tableType } = useParams()
  const { selectedEntity } = useEntity()

  const { loading } = useSelector((store: any) => store.loading)

  const [disableEditButton, setDisableEditButton] = useState(false)
  const [renderConfig, setRenderConfig] = useState<ITaskTypeWebRenderModel>()

  const porterPermissions = usePorterPermissions()

  // Fetch the config for CC
  useEffect(() => {
    EntityService.getEntityShowView(tableType, recordId, "edit")
      .then((res) => {
        if (res?.code && res?.code.indexOf("200") < 0) {
          if (res?.code === "401") {
            notification("error", res.message)
          } else {
            notification("error", res.message)
          }
        } else {
          setRenderConfig(res?.data)
        }
      })
      .catch((err) => {
        console.error("Error", err)
        notification(
          "error",
          err?.response?.data?.message ||
            "Something went wrong. Please try again."
        )
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // Task Edit Final Function
  const submitTaskEdit = (data: any) => {
    /**
     * Removing location dataType key if the address, latitude and longitude are undefined
     */
    // loop through all the keys of data
    const dataKeys = Object.keys(data)
    // loop through dataKeys and check if the value is an object
    dataKeys.forEach((key) => {
      if (isPlainObject(data[key])) {
        // get all the keys of the object
        const objectKeys = Object.keys(data[key])
        // check if ojectKey has a value called address, latitude and longitude
        if (
          objectKeys.includes("address") &&
          objectKeys.includes("latitude") &&
          objectKeys.includes("longitude")
        ) {
          // check if the value of address, latitude and longitude is undefined
          if (
            (data[key].address === undefined || data[key].address === "") &&
            data[key].latitude === undefined &&
            data[key].longitude === undefined
          ) {
            // if yes, then delete the key from data
            delete data[key]
          }
        }
      }
    })

    dispatch(setLoading(true))
    const teamIds = data?.teamIds || []
    delete data?.teamIds
    const taskEditPayload: any = {
      tableType: tableType,
      data: data,
      teamIds,
    }

    // const omitFieldsInData: Array<string> = ['scheduledSlot', 'taskType', 'teamIds'];
    // for (let key of Object.keys(store.getState().taskEdit)) {
    //   // Skip the omit fields for data
    //   if (!omitFieldsInData.includes(key)) taskEditPayload.data[key] = store.getState().taskEdit[key];
    // }

    EntityService.updateEntityRecord(tableType, recordId, taskEditPayload)
      .then((res: any) => {
        if (res?.code) {
          if (res?.code.indexOf("200") < 0) {
            if (res?.code === "304") {
              notification("warning", "No change", res.message)
            } else {
              setDisableEditButton(false)
              editTaskForm.reset()
              notification("error", res.message)
            }
          } else {
            notification("success", "Record Edited successfully")
            setDisableEditButton(false)
            recordRSEvent(TableRSEvents.EDIT_SINGLE_RECORD_SUCCESS, {
              context: "record_edit",
              recordId,
              tableType: taskEditPayload.taskType,
            })
            dispatch(clearTaskData())
            // push to previous page
            history.goBack()

            // localStorage.removeItem("isPreview");
          }
        }
        dispatch(setLoading(false))
      })
      .catch((err) => {
        setDisableEditButton(false)
        editTaskForm.reset()
        console.error("Error", err)
        dispatch(setLoading(false))
        recordRSEvent(TableRSEvents.EDIT_SINGLE_RECORD_FAILURE, {
          context: "record_edit",
          recordId,
          tableType: taskEditPayload.taskType,
        })
        notification(
          "error",
          err?.response?.data?.message ||
            "Something went wrong. Please try again."
        )
      })
  }

  const cancelEdit = () => {
    history.goBack()
  }

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <FormProvider {...editTaskForm}>
          <form
            style={{ height: "94vh", overflow: "hidden" }}
            onSubmit={editTaskForm.handleSubmit(submitTaskEdit)}
          >
            <TopBar
              heading="Edit a Record"
              onCancelText={"Cancel"}
              onSaveText={
                porterPermissions.checkPermissionsForPorter(
                  selectedEntity?.tableType,
                  "update_record"
                )
                  ? "Update"
                  : ""
              }
              onSave={() => {}}
              onCancel={cancelEdit}
            />
            <TaskFormBody key="taskCreate" className={`grid`}>
              {/* <ViewModeHeader>
            <ZButton
              secondary
              variant="contained"
              onClick={() => {
                history.goBack();
              }}
              startIcon={<AiOutlineArrowLeft style={{ fontSize: "1.5rem" }} />}
            >
              {" "}
              Back{" "}
            </ZButton>

            <ZButton
              secondary
              variant="contained"
              onClick={() => {handleEditClick()}}
              startIcon={<BiEdit style={{ fontSize: "1.5rem" }} />}
            >
              {" "}
              Edit{" "}
            </ZButton>
          </ViewModeHeader> */}
              <div className="contentWrapper">
                <Render
                  renderConfig={renderConfig?.body?.blocks}
                  componentParams={getComponentParamsEdit(
                    dispatch,
                    editTaskForm
                  )}
                />

                <div className="pt-16">
                  {/* <ButtonGroup size='small' variant='outlined'> */}
                  {/* <ZButton
                    type="submit"
                    disabled={disableEditButton}
                    variant="contained"
                    style={{ marginRight: "10px" }}
                  >
                    Save
                  </ZButton>
                  <ZButton variant="contained" onClick={cancelEdit} secondary>
                    Cancel
                  </ZButton> */}
                  {/* </ButtonGroup> */}
                </div>
              </div>
            </TaskFormBody>
          </form>
        </FormProvider>
      )}
    </>
  )
}

export default EntityEdit
