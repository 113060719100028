import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setValue } from "render-engine/reducers/create";
import useWorkFlow from "views/workflows/hooks/useWorkFlow";
import {
  setPostPublish,
  setShowPublishModal,
} from "views/workflows/reducers/workflowAction";
import QRCode from "qrcode";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import styled from "styled-components";
//@ts-ignore
import { Image } from "./postPublishStyles";
//@ts-ignore
import useDeviceType from "utils/useDeviceType";
import { Checkbox, Divider, FormControlLabel } from "@mui/material";
import { FiEdit, FiInfo } from "react-icons/fi";
//@ts-ignore
import AppStore from "assests/appstore.svg";
//@ts-ignore
import PlayStore from "assests/playstore.svg";
//@ts-ignore
import UnpublishedWorkflow from "assests/unpublishwf.svg";
import { AiOutlineWarning, AiFillWarning } from "react-icons/ai";
//@ts-ignore
import shortUrl from "node-url-shortener";
import Lottie from "lottie-react";
import ZLoading from "assests/97443-loading-gray.json";
import { BffService } from "providers/data/services/BffService";

//@ts-ignore

const Heading = styled.div`
  /* font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px; */
  text-align: ${(props: { danger?: boolean; center?: boolean }) =>
    props.center ? "center" : "left"};
  background: ${(props: { danger?: boolean }) =>
    props.danger
      ? "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F03738"
      : ""};
  padding: ${(props: { danger?: boolean }) => (props.danger ? "10px" : "")};
  border-radius: 8px;
`;

const Text = styled.p`
  font-size: 12px;
  font-weight: ${(props: { bold?: boolean; center?: boolean }) =>
    props.bold ? 600 : 400};
  color: ${(props: { danger?: boolean; bold?: boolean; center?: boolean }) =>
    props.danger ? "#F03738" : ""};
  margin-top: 10px;
  text-align: ${(props: { center?: boolean }) =>
    props.center ? "center" : "left"};

  svg {
    display: inline !important;
  }
`;

const ZDivider = styled(Divider)`
  font-size: 12px !important;
  color: rgba(0, 0, 0, 0.5);
`;

const Container = styled.div`
  background: white;
  height: 100%;
  padding: 16px;
`;

const ImageDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
`;

const CheckboxDiv = styled.div`
  display: flex;
  justify-content: center;
  .MuiFormControlLabel-label {
    font-size: 12px;
    font-weight: 600;
    color: #808080;
  }

  .MuiSvgIcon-root {
    color: "#808080";
  }
`;

const PostPublishModal = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [qrcode, setQrcode] = useState<string | null>(null);
  const [showPostPublishModal, setShowPostPublishModal] = useState(() =>
    localStorage.getItem("showPostPublishModal") === "true" ? true : false
  );
  const deviceType = useDeviceType();
  const [loading, setLoading] = useState(false);

  const generateQRCode = async () => {
    try {
      setLoading(true);
      const data:any = await BffService.getFirbaseDynamicLink(`https://zorp.page.link/?apn=one.zorp&isi=1581396842&ibi=one.zorp&link=https://zorp.page.link%3Fat%3D${localStorage.getItem("at")}%26rt%3D''`,);
      const qr = await QRCode.toDataURL(data.data.shortLink, {
        errorCorrectionLevel: "H",
      });
      setQrcode(qr);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setQrcode(null);
    }
  };

  useEffect(() => {
    generateQRCode();
  }, []);

  const {
    taskTypeId,
    isWorkflowPublishedBefore,
    postedWorkflowId = "",
    showPublishModal,
    isSaveWorkflowCalledAtleastOnce,
  } = useWorkFlow();

  const getAppDownload = () => {
    /**
     * download the app using the link
     */
    window.open(
      `https://zorp.page.link/?apn=one.zorp&isi=1581396842&ibi=one.zorp&link=https://zorp.page.link%3Fat%3D${localStorage.getItem(
        "at"
      )}%26rt%3D${localStorage.getItem("rt")}`,
      "_blank"
    );

    // window.open(`https://www.zorp.one/app-download`, "_blank");
  };

  const handleClose = () => {
    dispatch(
      setPostPublish({
        postedWorkflow: "",
        setPostPublish: false,
        postedWorkflowId: "",
      })
    );
  };

  const handleClick = () => {
    handleClose();
    dispatch(setValue({ key: "taskType", value: taskTypeId }));
    history.push(`/task/${taskTypeId}/create`);
  };

  const drop = useRef(null);

  function handleAwayClick(e: any) {
    // @ts-ignore
    const varia = !!e.target.closest(`.${drop?.current?.className}`);
    // @ts-ignore
    if (varia && showPublishModal) {
    } else {
      dispatch(setShowPublishModal(false));
    }
  }
  useEffect(() => {
    document.addEventListener("click", handleAwayClick);
    return () => {
      document.removeEventListener("click", handleAwayClick);
    };
  });

  const handleChange = (e: any) => {
    localStorage.setItem("showPostPublishModal", e.target.checked);
    setShowPostPublishModal(e.target.checked);
  };

  return (
    // <Modal open={true}  onClose={handleClose}>
    <div className="h-auto w-96 absolute z-10 right-0 top-24">
      <div
        className="dropDown"
        ref={drop}
        style={{
          background: "white",
          height: "100%",
          padding: "8px 16px 4px 16px",
          position: "relative",
          borderRadius: "8px",
          boxShadow: "0px 3px 6px rgba(140, 152, 164, 0.25)",
        }}
      >
        {!isWorkflowPublishedBefore ? (
          <Heading>
            <Text style={{ fontSize: "27px" }} center>
              <AiFillWarning size={"2rem"} />
            </Text>
            <Text bold center>
              Publish your Workflow first.
            </Text>
            <Text center>
              In order to test your workflow, please publish it first.
            </Text>
          </Heading>
        ) : !isSaveWorkflowCalledAtleastOnce ? (
          <Heading>
            <Text style={{ fontSize: "32px", marginTop:"0px" }} center>
              {" "}
              🚀{" "}
            </Text>
            <Text bold center>
              {" "}
              Your Workflow is Published{" "}
            </Text>
            <Text center>
              {" "}
              Let's create the task using the workflow you have just created.{" "}
            </Text>
          </Heading>
        ) : (
          <>
            <Heading danger>
              <Text center>
                <AiOutlineWarning size={"1.5rem"} color="#F03738" />
              </Text>
              <Text danger center>
                Your workflow has changes that isn’t published yet. To test out
                your changes, please publish it first.
              </Text>
            </Heading>
            <Text center bold>
              You can access the previously published workflow by scanning
              below.
            </Text>
          </>
        )}

        {!isWorkflowPublishedBefore ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={UnpublishedWorkflow} />
          </div>
        ) : (
          <>
            <div className="mt-3">
              <ZDivider> Download the App </ZDivider>
            </div>
            {/* <Image style={{margin:"20px auto 0px auto", width:"95%"}} alt="publish successfull" src={PostPublishTopImage}/> */}
            <div>
              {loading && (
                <ImageDiv>
                  <Lottie
                    animationData={ZLoading}
                    loop={true}
                    autoplay={true}
                    style={{ display: "flex", justifyContent: "center" }}
                    width={"80%"}
                  />
                </ImageDiv>
              )}
              {qrcode && (
                <>
                  <ImageDiv>
                    <Image width={"60%"} src={qrcode} />
                  </ImageDiv>
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <a
                      href="https://apps.apple.com/th/app/zorp-user/id1581396842"
                      
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img src={AppStore} />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=one.zorp"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img src={PlayStore} />
                    </a>
                  </div>
                </>
              )}
              {/* <Image src={CreateTaskUsingZorpApp}></Image> */}
            </div>

            <div className="mt-3 mb-3">
              <ZDivider> Create Task Using Dashboard </ZDivider>
            </div>
            <div>
              {deviceType === "mobile" && (
                <ZButton
                  className="mb-2"
                  onClick={getAppDownload}
                  variant="contained"
                  fullWidth
                >
                  Download the App
                </ZButton>
              )}
              <ZButton
                variant="contained"
                onClick={handleClick}
                secondary
                type="button"
                style={{ marginBottom: "10px" }}
                fullWidth
                startIcon={<FiEdit size={"1rem"} />}
              >
                Create Task Using Dashboard
              </ZButton>
              {/* <ZButton
                fullWidth
                variant="contained"
                onClick={handleClick}
                secondary
                type="button"
                startIcon={<FiInfo size={"1rem"} />}
              >
                How to create task on App?
              </ZButton> */}
              <CheckboxDiv>
                <FormControlLabel
                  sx={{
                    marginRight: "0px",
                    marginLeft: "0px",
                  }}
                  label="Do not show this again"
                  control={
                    <Checkbox
                      sx={{
                        color: "#808080",
                      }}
                      checked={showPostPublishModal}
                      onChange={handleChange}
                    />
                  }
                />
              </CheckboxDiv>
            </div>
          </>
        )}
      </div>
    </div>

    // </Modal>
  );
};

export default PostPublishModal;
