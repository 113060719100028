import React, { useState, useRef, useEffect, memo } from 'react';
import { InfoTooltip as Tooltip } from "views/workflows/Tootltips/InfoTooltip";
import LinkCellBadge from './LinkCellBadge';

type RelationCellWithTooltipProps = {
    values: any[];
};

const LinkCellWithTooltip: React.FC<RelationCellWithTooltipProps> = ({ values }) => {
    const [isOverflowing, setIsOverflowing] = useState(false);
    const cellRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (cellRef.current) {
            setIsOverflowing(cellRef.current.scrollWidth > cellRef.current.clientWidth);
        }
    }, []);

    const relationContainer = (
        <div ref={cellRef} style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: '100%' }}>
            {values?.map((value: string, index: number) => (
                <LinkCellBadge
                    key={index}
                    width='max-content'
                    value={value}
                    backgroundColor={"#F2F4F7"}
                    color={"#3054B9"}
                    onClick={() => {
                        window.open(value, '_blank');
                    }}
                />
            ))}
        </div>
    );

    return (
        isOverflowing ? (
            <Tooltip title={values.join(', ')} arrow>
                {relationContainer}
            </Tooltip>
        ) : (
            relationContainer
        )
    );
};

export default memo(LinkCellWithTooltip);