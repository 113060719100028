import React, { useState, FC, useEffect, useCallback, useRef } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { AiOutlineClose, AiOutlineSend } from "react-icons/ai";
import { ZIconButton2 } from "views/entities/styles";
import { UserService } from "providers/data/services/UserService";

const NewCommentContainer = styled(Grid)`
  margin-top: 8px !important;
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

interface TextProps {
  fontSize: string;
  fontWeight: string;
  color: string;
}

const Text = styled.p<TextProps>`
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  color: ${(props) => props.color};
`;

interface NewCommentProps {
  entityId: string;
  parentId: string;
  onNewComment: (data: any, parentId: string, callback: Function) => void;
  onCancel: () => void;
  users: any[];
  newMessage?: boolean;
  setUsersData: any;
}

const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const NewComment: FC<NewCommentProps> = ({
  entityId,
  parentId,
  onNewComment,
  onCancel,
  users,
  newMessage,
  setUsersData,
}) => {
  const [text, setText] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>("");
  const [filteredUsers, setFilteredUsers] = useState<any[]>(users);
  const [cachedUsers, setCachedUsers] = useState<any[]>([]);

  const debouncedFilter = useDebounce(filter, 300);
  const filterInputRef = useRef<HTMLInputElement>(null);

  const fetchUsers = useCallback(async (searchString: string) => {
    if (searchString === "" && cachedUsers.length > 0) {
      setFilteredUsers(cachedUsers);
    } else {
      const response = await UserService.allAcctUsers(searchString);
      if (response && response.data && response.data.users) {
        setFilteredUsers(response.data.users);
        if (searchString === "") {
          setCachedUsers(response.data.users); // Cache the default user list
        }
      }
    }
  }, [cachedUsers]);

  useEffect(() => {
    if (debouncedFilter) {
      fetchUsers(debouncedFilter);
    } else {
      setFilteredUsers(cachedUsers.length > 0 ? cachedUsers : users);
    }
  }, [debouncedFilter, fetchUsers, users, cachedUsers]);

  useEffect(() => {
    if (anchorEl) {
      setFilter(""); // Clear the filter when the menu is reopened
      if (cachedUsers.length === 0) {
        fetchUsers(""); // Fetch the default list of users if not cached
      } else {
        setFilteredUsers(cachedUsers);
      }
      if (filterInputRef.current) {
        filterInputRef.current.focus();
      }
    }
  }, [anchorEl, fetchUsers, cachedUsers]);

  const handleUserClick = (username: string) => {
    const parts = text.split("@");
    parts[parts.length - 1] = `${username} `;
    setText(parts.join("@"));
    setAnchorEl(null);
  };

  const handleSubmit = () => {
    if (text.trim() === "") {
      setError("Comment cannot be empty");
      return;
    }
    setError(null);
    setLoading(true);
    onNewComment(text, parentId, () => {
      setText("");
      setLoading(false);
    });
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilter(newValue);
    if (newValue === "") {
      setFilteredUsers(cachedUsers.length > 0 ? cachedUsers : users);
    }
  };

  return (
    <>
      {loading && (
        <LoadingOverlay>
          <CircularProgress />
        </LoadingOverlay>
      )}
      <NewCommentContainer container direction="column" spacing={1}>
        <Text style={{ marginLeft: "1rem" }} fontWeight="500" fontSize="11px" color="#344054">
          Replying to All
        </Text>
        <Grid className="zorpComment" item xs={12}>
          <Box px={newMessage ? 0 : { xs: 1, sm: 2, md: 2, lg: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              value={text}
              onChange={(e) => setText(e.target.value)}
              error={!!error}
              helperText={error}
              placeholder="Add a comment..."
              multiline
              minRows={1}
              maxRows={3}
              onFocus={async () => {
                if (users.length === 0 && cachedUsers.length === 0) {
                  fetchUsers("");
                }
              }}
              sx={{ padding: "0px !important" }}
              disabled={loading}
              onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if ((e.target as HTMLInputElement).value.endsWith("@")) {
                  setAnchorEl(e.currentTarget);
                }
              }}
            />
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{ autoFocus: false }} // Prevent Menu from auto-focusing
            >
              <Box px={1}>
                <TextField
                  fullWidth
                  placeholder="Filter users"
                  value={filter}
                  onChange={handleFilterChange}
                  inputRef={filterInputRef}
                  onKeyDown={(e) => e.stopPropagation()} // Prevent menu items from being selected
                />
              </Box>
              {filteredUsers.length > 0 ? (
                filteredUsers.map((user: any) => (
                  <MenuItem key={user.id} onClick={() => handleUserClick(user.name)}>
                    {user.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No users found</MenuItem>
              )}
            </Menu>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            textAlign: "right",
            paddingBottom: "10px",
            marginRight: parentId?.length > 0 ? "1.5rem" : undefined,
          }}
        >
          {parentId?.length > 0 && (
            <ZIconButton2 onClick={onCancel} style={{ marginRight: "8px" }}>
              <AiOutlineClose />
            </ZIconButton2>
          )}
          <Button
            variant="outlined"
            color="primary"
            onClick={handleSubmit}
            size="small"
            startIcon={<AiOutlineSend style={{ marginRight: "0px" }} />}
            disabled={loading}
            style={{
              backgroundColor: "white",
              color: "black",
              border: "1px solid var(--gray-300, #D0D5DD)",
              fontWeight: "400",
              textTransform: "none",
              fontSize: "11px",
            }}
          >
            Send Message
          </Button>
        </Grid>
      </NewCommentContainer>
    </>
  );
};

export default NewComment;