import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MonitorSend from "assests/monitor_convo_send.svg";
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { BsInfoCircleFill } from 'react-icons/bs';

interface IMonitorConversationFooterProps {
  onSendClick: (promptText: string) => void;
  disableSendButton: boolean;
}

// Define an interface for the prop types
interface FooterWrapperProps {
  isLoading: boolean;
}

// Use the interface in your styled component
const FooterWrapper = styled.div<FooterWrapperProps>`
  display: flex;
  gap: 12px;
  flex-direction: row;
  justify-content: center;
  margin: ${({ isLoading }) => isLoading ? '12px 16px 34px 16px' : '12px 16px 24px 16px'};
`;

const MonitorConversationFooter = ({ onSendClick, disableSendButton }: IMonitorConversationFooterProps) => {
  const [promptValue, setPromptValue] = useState<string>('');

  return (
    <FooterWrapper isLoading={disableSendButton}>
      <TextField
        value={promptValue}
        InputProps={{
          style: {
            borderRadius: "8px",
          }
        }}
        onChange={(e) => {
          setPromptValue(e.target.value)
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onSendClick(promptValue);
            setPromptValue('');
          }
        }}
        sx={{ 
          '& .MuiInputBase-root': {
            height: '34px', 
          } }}
        style={{
          width: '95%',
          backgroundColor: '#FFFFFF',
          height: '36px',
          minHeight: '36px',
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '20px',
          color: 'rgba(0, 0, 0, 0.8)'
        }}
        placeholder="What do you have in mind?"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Tooltip disableHoverListener={!disableSendButton || !promptValue} title={<Typography>{disableSendButton ? 'Template is Generating' : !promptValue ? 'Enter Prompt for Monitor' : ''}</Typography>}>
        <span>
          <Button
            disabled={!promptValue || disableSendButton}
            onClick={() => {
              onSendClick(promptValue);
              setPromptValue('');
            }}
            variant="outlined" style={{
              margin: '0px',
              borderRadius: '8px',
              padding: '8px 14px 8px 14px',
              height: '34px',
              width:'46px',
              maxWidth: '46px',
              minWidth: '46px',
            }}>
            <img style={{padding:'0px', margin:'0px'}} src={MonitorSend} />
          </Button>
        </span>
      </Tooltip>
    </FooterWrapper>
  )
}

// export default MonitorConversationFooter
export  default React.memo(MonitorConversationFooter);