import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { Grid, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { convertCamelCase } from 'utils/Utils';

type DataFieldsType = {
    name: string;
}


type CoreDataFieldsListType = {
    inputListData: Array<DataFieldsType>;
    selectedList: string[];
    setSelectedList: Dispatch<SetStateAction<string[]>>;
}


const CoreDataFieldsList = ({ inputListData, selectedList, setSelectedList }: CoreDataFieldsListType) => {

    return (
        <FormGroup>
            {inputListData &&
                inputListData.map((inputs: any, index: any) => (
                    <>
                        <Grid style={{ marginLeft: '40px' }}>
                            <FormControlLabel
                                key={index}
                                control={
                                    <input
                                        type="checkbox"
                                        style={{ marginRight: "10px", marginTop: "5px", marginBottom: "5px" }}
                                        checked={selectedList.includes(inputs.name)}
                                        onChange={(value) => {
                                            if (selectedList.includes(inputs.name)) {
                                                setSelectedList(current => current.filter((obj) => obj != inputs.name));
                                            } else {
                                                setSelectedList(prev => [...prev, inputs.name])
                                            }
                                        }}
                                        value="one"
                                    />
                                }
                                label={<Typography variant='h6'> {convertCamelCase(inputs.name)}</Typography>}
                            />
                        </Grid>
                    </>
                ))}
        </FormGroup>
    )
}

export default CoreDataFieldsList;
