import React, { useState } from 'react';
import {
  Container,
  TopDiv,
  Button,
  Label,
  Input,
  BottomDiv,
  EditContainer,
  
  HorizontalLine,
  SelectType,
} from './DataField.styles';
import useWorkflow from 'views/workflows/hooks/useWorkFlow';
import { DataFieldType } from './DataField.types';
import { useDispatch } from 'react-redux';
import { setActiveSubField, deleteSubField } from 'views/workflows/reducers/workflowAction';
import { useForm } from 'react-hook-form';
import {
  TextField,
  Number,
  BooleanField,
  FileField,
  ImageField,
  SignatureField,
  ListOfValues,
  Location,
  DateField,
} from './Components/index';

const SubFieldView = () => {
  const { activeSubField, activeEditField } = useWorkflow();
  const dispatch = useDispatch();

  const { name, type, register, fieldName, setValue, getValues, remove, index, isDraft, watch, children } =
    activeSubField;

  const [showMandatory, setShowMandatory] = useState(getValues()?.subFields[index]?.isCreateTimeField || false);

  const onSave = () => {
  };

  const onClose = () => {
    dispatch(setActiveSubField(null));
  };

  const onDelete = () => {
    remove(index);
    dispatch(setActiveSubField(null));
  };

  const getUIComponent = (type: string, name: string) => {
    switch (type) {
      case DataFieldType.TEXT:
        return (
          <>
            <TextField register={register} defaultValueName={fieldName + '.defaultValue'} />
          </>
        );

      case DataFieldType.NUMBER:
        return (
          <>
            <Number register={register} defaultValueName={fieldName + '.defaultValue'} />
          </>
        );

      case DataFieldType.FILE:
        return (
          <>
            <FileField register={register} />
          </>
        );

      case DataFieldType.SIGNATURE:
        return (
          <>
            <SignatureField register={register} />
          </>
        );
      /**
       * children will have info of address, latitude and longitude
       * 0th Idx => address
       * 1st => latitude
       * 2nd => longitude
       *
       * children will not be available for all the datatypes. Locations has children.
       * Other will have an empty array i.e. []
       */
      case DataFieldType.LOCATION:
        return (
          <>
            <Location
              register={register}
              show={true}
              fieldName={fieldName}
              address={children ? children[0]?.defaultValue : ''}
              latitude={children ? children[1]?.defaultValue : null}
              longitude={children ? children[2]?.defaultValue : null}
            />
          </>
        );

      case DataFieldType.IMAGE:
        return (
          <>
            <ImageField register={register} />
          </>
        );

      case DataFieldType.LIST_OF_TEXT:
        return (
          <>
            {/* <ListOfValues getValues={getValues} /> */}
          </>
        );

      case DataFieldType.DATETIME:
        return (
          <>
            <DateField register={register} />
          </>
        );

      case DataFieldType.BOOLEAN:
        return (
          <>
            {/* <BooleanField
              defaultValueName={fieldName + ".defaultValue"}
              control={control}
            /> */}
            <Label> Default Value </Label>
            <SelectType
              defaultValue={getValues(fieldName + '.defaultValue') ?? true}
              onChange={(e) => setValue(fieldName + '.defaultValue', e.target.value)}
            >
              {/* @ts-ignore */}
              <option value={true}>True</option>
              {/* @ts-ignore */}
              <option value={false}>False</option>
            </SelectType>
          </>
        );
      default:
        return (
          <>
            <p> Invalid Component </p>
          </>
        );
    }
  };

  return (
    // @ts-ignore
    <EditContainer type='subfield' as={'div'}>
      <TopDiv>
        <p>
          {' '}
          Configure <b> {`${name}`} </b> Sub Field Settings{' '}
        </p>
        <HorizontalLine />
      </TopDiv>
      <BottomDiv>
        {getUIComponent(type, name)}
        {/* @ts-ignore */}

        {activeEditField.type !== DataFieldType.LOCATION && type !== DataFieldType.LOCATION && (
          <div style={{ marginTop: '1.4rem' }}>
            <div>
              <Input
                type='checkbox'
                {...register(fieldName + '.isCreateTimeField')}
                style={{ width: '5%' }}
                onChange={() => setShowMandatory((prev: boolean | undefined) => !prev)}
              />
              <Label style={{ display: 'inline' }}> Collect this data field as an input when a task is created</Label>
            </div>
            {showMandatory && (
              <div>
                <Input type='checkbox' {...register(fieldName + '.mandatory')} style={{ width: '5%' }} />
                <Label style={{ display: 'inline' }}> Make this input mandatory </Label>
              </div>
            )}
          </div>
        )}

        {/* @ts-ignore */}
        <Button width='full' className='mt-2' variant='secondary' type='button' onClick={onClose}>
          {' '}
          Close{' '}
        </Button>
        {activeEditField.type !== DataFieldType.LOCATION && isDraft && (
          <>
            {/* @ts-ignore */}
            <Button width='full' className='mt-2' variant='danger' type='button' onClick={onDelete}>
              {' '}
              Delete{' '}
            </Button>
          </>
        )}
      </BottomDiv>
    </EditContainer>
  );
};

export default SubFieldView;
