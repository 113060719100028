import { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { recordRSEvent } from "utils/CommonUtils";
import DeletionConfirmationDialog from "utils/DeletionConfirmationDialog.js";
import { getDefaultValue } from "views/DataField/HelperFunction/getDefaultValue";
import useWorkFlow from "views/workflows/hooks/useWorkFlow";
import notifications from "../../notifications/notifications";
import {
  deleteDraftDataField,
  editDataField,
  setActiveEditField,
  setActiveSubField,
  setDataFieldType,
  setShowDataFieldEditModal,
  setShowDataFieldEditModalSingle,
  setShowEditField,
} from "../workflows/reducers/workflowAction";
import { CollectionField } from "./Components/index";
import {
  BottomDiv,
  Button,
  EditContainer,
  HorizontalLine,
  Input,
  Label,
  SelectType,
  SubFieldContainer,
  TopDiv,
} from "./DataField.styles";
import { DataFieldType } from "./DataField.types";
import {
  displayNameErrorSchema,
  idErrorSchema,
  numberDefaultValueErrorSchema,
  textDefaultValueErrorSchema,
} from "./DataFieldErrorSchema";
import ErrorMessage from "./ErrorMessage";

type EditConfigProps = {
  setShowEditOption: (bool: boolean) => void;
};

type SingleOptionType = {
  value?: string;
  displayValue?: string;
  default?: boolean;
};

type FormInputs = {
  name: string;
  type: string;
  displayName: string;
  defaultValue?: string | number | undefined | null;
  mandatory: boolean;
  isCreateTimeField: boolean;
  subFields?: [SingleOptionType];
  id: string;
};

const EditView = ({ setShowEditOption }: EditConfigProps) => {
  const dispatch = useDispatch();
  //@ts-ignore
  const {
    activeEditField,
    dataFields: alreadyCreatedDataFields,
    taskTypeId,
    taskTypeName,
    selectedNode,
    showTaskCardConfig,
    dataFieldType,
  } = useWorkFlow();
  const {
    register,
    clearErrors,
    handleSubmit,
    setError,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: {
      subFields: activeEditField?.children || [],
    },
  });

  const [selectedType, setSelectedType] = useState("singleSelect");
  //@ts-ignore
  const [openDeletionConfirmation, setOpenDeletionConfirmation] =
    useState(false);

  const { append } = useFieldArray({
    control,
    name: "subFields",
  });

  const onFormSubmit = (data: any) => {
    // We are checking for duplicate field name.. Also id check here ensures that
    // we dont check the field with itself as this will make changing name mandatory
    // every time user wants to change something else.

    for (let i = 0; i < alreadyCreatedDataFields?.length; i++) {
      if (
        alreadyCreatedDataFields[i].name === data.name &&
        alreadyCreatedDataFields[i]?.id !== data?.id
      ) {
        setError("name", {
          type: "manual",
          message: "Data field with this name already exist",
        });
        return;
      }
    }

    if (activeEditField?.type === DataFieldType.COLLECTION) {
      let prevSubfieldName: string[] = [];
      for (let i = 0; i < data?.subFields?.length; i++) {
        if (prevSubfieldName.includes(data.subFields[i]?.name)) {
          //@ts-ignore
          setError(`subFields.${i}.name`, {
            type: "manual",
            message: "Data field with this name already exist",
          });
          return;
        }
        prevSubfieldName.push(data.subFields[i]?.name);
      }
    }

    //TODO: KAUSH: Refactor the whole function as LIST_OF_VALUES is no longer an exceptional case.
    let newDefaultValue: any = data.defaultValue;
    if (activeEditField?.type === DataFieldType.BOOLEAN) {
      newDefaultValue = data.defaultValue === "false" ? false : true;
    }
    if (activeEditField?.type === DataFieldType.COLLECTION) {
      let isDataFieldMandatory = false;
      data?.subFields?.forEach((item: any) => {
        if (item.type === DataFieldType.LOCATION) {
          item.children = [
            {
              name: "address",
              displayName: "Address",
              description: "Address",
              type: "TEXT",
              defaultValue: item?.address || "",
            },
            {
              name: "latitude",
              displayName: "Latitude",
              description: "Latitude",
              type: "NUMBER",
              defaultValue: item?.latitude,
            },
            {
              name: "longitude",
              displayName: "Longitude",
              description: "Longitude",
              type: "NUMBER",
              defaultValue: item?.longitude,
            },
          ];
        }

        if (item.type === DataFieldType.BOOLEAN) {
          item.defaultValue =
            item?.defaultValue === "false" || item?.defaultValue === false
              ? false
              : true;
        }

        item.defaultValue =
          item?.defaultValue === null || item?.defaultValue === undefined
            ? getDefaultValue(item?.type)
            : item?.defaultValue;

        if (!isDataFieldMandatory && item?.isCreateTimeField) {
          isDataFieldMandatory = item?.mandatory || false;
        }

        item.mandatory = item?.isCreateTimeField ? item?.mandatory : false;
      });
      data.mandatory = data?.isCreateTimeField && isDataFieldMandatory;
    }
    dispatch(
      editDataField({
        ...data,
        displayName: data?.displayName,
        id: activeEditField?.id,
        defaultValue: newDefaultValue,
        type: activeEditField?.type,
        isDraft: activeEditField?.isDraft,
        children: data?.subFields || activeEditField?.children || [],
        subFields: undefined,
      })
    );
    if (selectedNode || showTaskCardConfig?.show) {
      const dataForFlutter = {
        data: {
          ...data,
          name:
            data?.name +
            "." +
            data?.subFields[data?.subFields.length - 1]?.name,
          id: activeEditField?.id,
          defaultValue: newDefaultValue,
          type: activeEditField?.type,
          isDraft: activeEditField?.isDraft,
          children: data?.subFields || activeEditField?.children || [],
          subFields: undefined,
        },
        key: dataFieldType.key,
      };
      // @ts-ignore
      document
        .getElementById("app-builder")
        ?.contentWindow?.postMessage(dataForFlutter, "*");
      //dispatch(setShowDataFieldModal(false));
    }
    notifications("success", "Successfully edited the data field");
    recordRSEvent("editdatafield_button_click", {
      context_data: "datafield_edit",
      workflow_id: taskTypeId,
      workflow_name: taskTypeName,
      dataField_name: data.name,
      dataField_type: activeEditField?.type,
    });
    // setShowEditOption(false)
    // dispatch(setShowEditField(false));
    dispatch(setShowDataFieldEditModal(false));
    dispatch(setActiveSubField(null));
    dispatch(setActiveEditField({}));
    dispatch(setShowDataFieldEditModalSingle(false));
    dispatch(
      setDataFieldType({
        dataType: DataFieldType.TEXT,
        key: "",
        subFieldDataType: null,
        listName: null,
      })
    );
  };

  useEffect(() => {
    recordRSEvent("editdatafield_modal_opened", {
      context_data: "datafield_edit",
      workflow_id: taskTypeId,
      workflow_name: taskTypeName,
      dataField_name: activeEditField?.name,
      dataField_type: activeEditField?.type,
    });
  }, []);

  // TODO: hANDLE DELETE CASE AS WELL.
  const closeModal = () => {
    if (selectedNode || showTaskCardConfig?.show) {
      dispatch(setShowDataFieldEditModalSingle(false));
      dispatch(setActiveEditField({}));
      dispatch(
        setDataFieldType({
          dataType: DataFieldType.TEXT,
          key: "",
          subFieldDataType: null,
          listName: null,
        })
      );
    } else {
      dispatch(setActiveEditField({}));
      dispatch(setShowEditField(false));
      dispatch(setShowDataFieldEditModal(false));
    }
    dispatch(setActiveSubField(null));
  };

  const deleteDataField = (dataField: any) => {
    dispatch(deleteDraftDataField(dataField));
    dispatch(setShowEditField(false));
    dispatch(setShowDataFieldEditModal(false));
    recordRSEvent("editdatafield_deleteconfirmationbox_deletebutton_clicked", {
      context: "datafield_edit",
      workflow_id: taskTypeId,
      workflow_name: taskTypeName,
      datafield_name: activeEditField?.name,
      datafield_type: activeEditField?.type,
    });
    notifications("success", "Successfully deleted the datafield");
  };

  useEffect(() => {
    // Will only exectue if type is List of Value
    setValue("name", activeEditField?.name);
    setValue("displayName", activeEditField.displayName)
    setValue("defaultValue", activeEditField?.defaultValue);
    setValue("isCreateTimeField", activeEditField?.isCreateTimeField);
    setValue("mandatory", activeEditField?.mandatory);
    setValue("id", activeEditField?.id);
    // activeEditField?.subFields?.map((item: any) => {
    //   append(item);
    // });
  }, [activeEditField]); // eslint-disable-line react-hooks/exhaustive-deps

  const isCreateTimeField = watch("isCreateTimeField");

  return (
    <EditContainer onSubmit={handleSubmit(onFormSubmit)}>
      <TopDiv>
        <p> Edit Datafield</p>
        <HorizontalLine />
      </TopDiv>
      <BottomDiv>
        <Label>Name</Label>
        <Input
          // value={activeEditField.displayName}
          {...register("displayName", displayNameErrorSchema)}
          type="text"
        />
        <ErrorMessage errors={errors} fieldName="displayName" />
        <Label> Id </Label>
        <Input
          value={activeEditField.name}
          disabled={!activeEditField?.isDraft}
          {...register("name", idErrorSchema)}
          type="text"
        />
        <ErrorMessage errors={errors} fieldName={"name"} />
        <Label> Data Field Type </Label>
        {/* @ts-ignore */}
        <Input value={activeEditField?.type} disabled={true} />
        {activeEditField?.defaultValue !== undefined && (
          <>
            <Label>
              {" "}
              {activeEditField?.type !== DataFieldType.LIST_OF_TEXT &&
              activeEditField?.type !== DataFieldType.COLLECTION &&
              activeEditField?.type !== DataFieldType.LOCATION
                ? "Default Value"
                : ""}{" "}
            </Label>
            {
              <>
                {activeEditField?.type === DataFieldType.NUMBER && (
                  <>
                    <Input
                      // defaultValue={activeEditField.defaultValue}
                      type="number"
                      {...register(
                        "defaultValue",
                        numberDefaultValueErrorSchema
                      )}
                    />
                    <ErrorMessage errors={errors} fieldName={"defaultValue"} />
                  </>
                )}

                {activeEditField?.type === DataFieldType.TEXT && (
                  <>
                    <Input
                      type="text"
                      {...register("defaultValue", textDefaultValueErrorSchema)}
                      // value={activeEditField.defaultValue}
                    />
                    <ErrorMessage errors={errors} fieldName={"defaultValue"} />
                  </>
                )}

                {activeEditField?.type === DataFieldType.BOOLEAN && (
                  <Controller
                    render={({ field }) => (
                      //@ts-ignore
                      <SelectType
                        // defaultValue={activeEditField.defaultValue === "true" ? true : false}
                        {...field}
                      >
                        {/* @ts-ignore */}
                        <option value={true}>True</option>
                        {/* @ts-ignore */}
                        <option value={false}>False</option>
                      </SelectType>
                    )}
                    name="defaultValue"
                    control={control}
                    // defaultValue={getValues("defaultValue")}
                  />
                )}
              </>
            }
          </>
        )}

        {activeEditField?.type === DataFieldType.COLLECTION && (
          <div style={{ marginTop: "8px" }}>
            <Input
              type="checkbox"
              {...register("isCreateTimeField")}
              // checked={activeEditField.isCreateTimeField}
              defaultChecked={getValues("isCreateTimeField")}
              //@ts-ignore
              // onChange={(e) => createTimeChange("isCreateTimeField",e)}
              style={{ width: "5%" }}
            />
            <Label style={{ display: "inline" }}>
              {" "}
              Collect this data field as an input when a task is created
            </Label>
          </div>
        )}

        {activeEditField?.children &&
          (activeEditField?.type === DataFieldType.COLLECTION ||
            activeEditField?.type === DataFieldType.LOCATION) && (
            <>
              <Label style={{ marginTop: "8px" }}> Subfields </Label>
              <SubFieldContainer>
                <CollectionField
                  register={register}
                  watch={watch}
                  control={control}
                  errors={errors}
                  getValues={getValues}
                  clearErrors={clearErrors}
                  setValue={setValue}
                  isEditable={
                    activeEditField?.type === DataFieldType.LOCATION
                      ? false
                      : true
                  }
                />
              </SubFieldContainer>
            </>
          )}

        {activeEditField?.type !== DataFieldType.COLLECTION && (
          <div style={{ marginTop: "1.4rem" }}>
            <div>
              <Input
                type="checkbox"
                {...register("isCreateTimeField")}
                // checked={activeEditField.isCreateTimeField}
                defaultChecked={getValues("isCreateTimeField")}
                //@ts-ignore
                // onChange={(e) => createTimeChange("isCreateTimeField",e)}
                style={{ width: "5%" }}
              />
              <Label style={{ display: "inline" }}>
                {" "}
                Collect this data field as an input when a task is created
              </Label>
            </div>
            {isCreateTimeField && (
              <div>
                <Input
                  type="checkbox"
                  {...register("mandatory")}
                  // checked={activeEditField.mandatory}
                  defaultChecked={getValues("mandatory")}
                  style={{ width: "5%" }}
                />
                <Label style={{ display: "inline" }}>
                  {" "}
                  Make this input mandatory{" "}
                </Label>
              </div>
            )}
          </div>
        )}
      </BottomDiv>
      <div
        style={{
          position: "absolute",
          bottom: "1.4rem",
          right: "1.4rem",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {/* TODO: Vivek
          Enable this button only if there is any actual Edit to the form*/}
        <Button
          type="button"
          onClick={() => {
            recordRSEvent("editdatafield_closebutton_clicked", {
              context: "datafield_create",
              workflow_id: taskTypeId,
              workflow_name: taskTypeName,
              datafield_name: activeEditField?.name,
              datafield_type: activeEditField?.type,
            });
            closeModal();
          }}
          variant="secondary"
        >
          Close
        </Button>
        {activeEditField?.isDraft && (
          <Button
            type="button"
            onClick={() => {
              setOpenDeletionConfirmation(true);
            }}
            variant="danger"
          >
            Delete
          </Button>
        )}
        {openDeletionConfirmation && (
          <DeletionConfirmationDialog
            id={activeEditField}
            deletionTitle={"Delete Field"}
            deletionText={"Do you want to delete the field?"}
            isOpen={openDeletionConfirmation}
            onConfirmDelete={deleteDataField}
            onClose={() => {
              setOpenDeletionConfirmation(false);
              recordRSEvent(
                "editdatafield_deleteconfirmationbox_cancelbutton_clicked",
                {
                  context: "datafield_edit",
                  workflow_id: taskTypeId,
                  workflow_name: taskTypeName,
                  datafield_name: activeEditField?.name,
                  datafield_type: activeEditField?.type,
                }
              );
            }}
          ></DeletionConfirmationDialog>
        )}

        <Button type="submit" variant="primary">
          {" "}
          Save{" "}
        </Button>
      </div>
    </EditContainer>
  );
};

export default EditView;
