import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import PersonIcon from "@material-ui/icons/Person";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import jwt_decode from "jwt-decode";
import { TaskFlowService } from "providers/data/services/TaskFlowService";
import * as React from "react";
import { forwardRef, useEffect } from "react";
import { AppBar, useLogout, UserMenu } from "react-admin";
import { BiEdit } from "react-icons/bi";
import { BsArrowLeftShort, BsPlay, BsSave } from "react-icons/bs";
import { IoIosSave } from "react-icons/io";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { isWorkflowPath, recordRSEvent } from "utils/CommonUtils";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import { setLoading } from "views/reducer/index";
import WTInfoModal from "views/workflow-templates/components/sub-components/WTInfoModal";
import {
  checkWorkflowTemplate,
  resetWATData,
} from "views/workflow-templates/reducers/WorkflowTemplate.actions";
import useWorkFlow from "views/workflows/hooks/useWorkFlow";
import { NodeTypes } from "views/workflows/nodes/Nodes.types";
import {
  setPostPublish,
  setShowWorkflowConfigModal,
  setIsSaveWorkflowCalledAtleastOnce,
  setShowPublishModal,
  setShowPreviewModal,
} from "views/workflows/reducers/workflowAction";
import notification from "../../notifications/notifications";
import { UserService } from "../../providers/data/services/UserService";
import ZLogo from "./Logo.svg";
import PageTitle from "./PageTitle";
import { getConfig } from "config/envConfig/Config";
import { InfoTooltip } from "views/workflows/Tootltips/InfoTooltip";
import useOnboarding from "views/onboarding/hooks/useOnboarding";
import { OnboardingUtil } from "views/onboarding/OnboardingUtil";
import { OnboardingStep } from "views/onboarding/types/OnboardingTypes";
import { setShowOnboardingModal } from "views/onboarding/reducer/onboardingAction";
import { OnboardingTooltip } from "views/workflows/Tootltips/OnboardingTooltip";
import { hasPermission } from "providers/auth/authUtils";
import useDeviceType from "utils/useDeviceType";
import UserIcon from "assests/user-01.svg";
import ChangePasswordIcon from "assests/edit-05.svg";
import LogoutIcon from "assests/log-out-01.svg";
import authProvider from "providers/auth/authProvider";
import styled from "styled-components";
import _ from "lodash";
import { WorkflowUtils } from "views/workflows/WorkflowUtils";
import { BuilderService } from "providers/data/services/BuilderService";
import useAccount from "./reducer/useAccount";
import PublishButton from "components/post-publish-modal/PublishButton";
import PostPublishModal from "components/post-publish-modal/PostPublishModal";
import { resetEntity } from "views/entities/reducers/entityReducers";

const WrapperDiv = styled.div`
  padding: 0px 10px;
  li {
    padding: 10px;
    border-radius: 4px;
  }
`;

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontSize: "14px",
    marginLeft: "34vw",
    fontWeight: "500",
  },
  spacer: {
    flex: 1,
  },
  paper: {
    backgroundColor: "red",
  },
  userMenuDiv: {
    border: "1px solid #EAECF0",
    borderRadius: "8px",
    backgroundColor: "red",
    boxShadow:
      "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);",
  },
});

const ConfigurationMenu = forwardRef((_props, _ref) => {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [passwd, setPasswd] = React.useState("");
  const [rePasswd, setRePasswd] = React.useState("");
  const [user, setUser] = React.useState("");
  const [userName, setUserName] = React.useState("");
  const logout = useLogout();
  const handleClick = () => logout();
  const dispatch = useDispatch();

  useEffect(() => {
    const at = localStorage.getItem("at");
    const decoded = jwt_decode(at);
    setUser(decoded.userId);
    setUserName(decoded?.name);
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setRePasswd("");
    setPasswd("");
  };
  const handleLogout = async () => {
    try {
      dispatch(resetEntity());
      localStorage.removeItem("tableType");
      logout();
    } catch (error) {
      console.log(error);
    }
  };

  const redirect = () => {
    // history.push(`/users/${user}/show`);
    window.open(`/#/users/${user}/show`, "_blank");
  };

  const handleFinalChange = () => {
    if (passwd && passwd === rePasswd) {
      const taskPayLoad = {
        password: passwd,
      };
      const change = UserService.changePasswd(taskPayLoad);
      change
        .then((res) => {
          if (res.code === "200") {
            notification("success", res?.message);
            history.push("/");
          } else if (res?.data) {
            res?.data?.[0]?.split(",")?.map((errMsg) => {
              notification("error", errMsg);
            });

            recordRSEvent("changepassword_event_failed", {
              context: "user_password_change",
            });
          }
          setOpen(false);
          setPasswd("");
          setRePasswd("");
        })
        .catch((_err) => {
          notification("error", _err?.data);
          setOpen(false);
          setPasswd("");
          setRePasswd("");
        });
    }
  };

  return (
    <WrapperDiv>
      <MenuItem onClick={redirect}>
        <ListItemIcon>
          <img src={UserIcon} />
        </ListItemIcon>
        <div
          style={{ color: "#3C69E7", fontSize: "16px", marginLeft: "-15px" }}
        >
          User - {userName}{" "}
          <span style={{ color: "black", fontSize: "16px" }}> {user} </span>
        </div>
      </MenuItem>
      <MenuItem onClick={handleClickOpen}>
        <ListItemIcon>
          <img src={ChangePasswordIcon} />
        </ListItemIcon>
        <div
          style={{ color: "#3C69E7", fontSize: "16px", marginLeft: "-15px" }}
        >
          Change Password
        </div>
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <img src={LogoutIcon} />
        </ListItemIcon>
        <div
          style={{ color: "#3C69E7", fontSize: "16px", marginLeft: "-15px" }}
        >
          Logout
        </div>
      </MenuItem>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
        <DialogContent className="flex flex-col px-6">
          <TextField
            autoFocus
            variant="outlined"
            value={passwd}
            margin="dense"
            id="name"
            type="password"
            label="New Password"
            onChange={(e) => setPasswd(e.target.value)}
          />
          <TextField
            error={passwd !== rePasswd}
            variant="outlined"
            value={rePasswd}
            margin="dense"
            type="password"
            id="name"
            helperText={`The password must be between 8 and 30 characters in length.\n
              The password must not contain the username.\n
              The password must not include any whitespace characters.
              The password must contain at least one uppercase letter.
              The password must contain at least one lowercase letter.
              The password must include at least one digit.
              The password must have at least one special character (e.g., !, @, #).
              The password must not contain sequences of three or more consecutive alphabetical characters.
              The password must not contain sequences of three or more consecutive numerical characters.
              The password must not include sequences of three or more consecutive characters that appear in a line on a QWERTY keyboard.`}
            label="Re-Type Password"
            onChange={(e) => setRePasswd(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleFinalChange} color="primary">
            Change Password
          </Button>
        </DialogActions>
      </Dialog>
    </WrapperDiv>
  );
});

const CustomUserMenu = (props) => {
  const classes = useStyles();

  return (
    <div>
      <UserMenu classes={{ root: "my-user-menu" }}>
        <ConfigurationMenu />
      </UserMenu>
    </div>
  );
};

const ZorpAppBar = (props) => {
  const isPreview = localStorage.getItem("isPreview");

  return (
    <div
      style={{
        marginTop: isPreview ? "0px" : "-48px",
      }}
    ></div>
  );
};

export default ZorpAppBar;
