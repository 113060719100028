import { NUMBER, OBJECT, TEXT } from "constants/CommonConstants";
import { useJSEditor } from "lowcode/hooks/useJSEditor";
import getInputType from "lowcode/util/getInputType";
import { BuilderService } from "providers/data/services/BuilderService";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { removeBraces } from "utils/CommonUtils";
import {
  DataFieldType,
  FilterOptions,
  IComponentType,
} from "views/DataField/DataField.types";
import usePayload from "views/automationBuilder/hooks/usePayload";
import useWorkFlow from "views/workflows/hooks/useWorkFlow";
import { setSelectedAction } from "views/workflows/reducers/workflowAction";
import ModalSmartInputTextField from "views/workflows/workflowactions/api/components/ModalSmartInputTextField";
import {
  getLowCodeEditorDefaultReturnText,
  getLowCodeEditorText,
} from "utils/Utils";
import Select from "react-select";
import { get, isArray } from "lodash";
import _ from "lodash";
import { RiDeleteBin2Line } from "react-icons/ri";
import styled from "styled-components";
import DatafieldSelector from "views/workflows/workflowactions/DatafieldSelector";
import { PlusIcon } from "assests";
import { AiOutlinePlus } from "react-icons/ai";
import { ZJSEditorLayout } from "lowcode/ZJSEditorLayout";
import notification from "notifications/notifications";

const FilterWrapper = styled.div`
  display: grid;
  grid-template-columns: 5% 30% 20% 40%;
  gap: 10px;
  margin-bottom: 12px;
`;

const customStyles = {
  control: (provided: Record<string, unknown>, state: any) => ({
    ...provided,
    background: "#fff",
    borderColor: "#D0D5DD",
    minHeight: "40px",
    height: "40px",
    borderRadius: "8px",
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  }),
  valueContainer: (provided: Record<string, unknown>, state: any) => ({
    ...provided,
    height: "31px",
    padding: "0 6px",
  }),
  input: (provided: Record<string, unknown>, state: any) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state: any) => ({
    display: "none",
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
  menu: (provided: Record<string, unknown>, state: any) => ({
    ...provided,
    zIndex: 9999,
  }),
  option: (provided: Record<string, unknown>, state: any) => ({
    ...provided,
    zIndex: "9999 !important",
  }),
};

const Label = styled.div`
  color: var(--Gray-700, #344054);

  /* Text sm/Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

const Container = styled.div`
  display: ${(props: { type: string }) =>
    props.type === "FILTER" ? "" : "grid"};
  grid-template-columns: 1fr 2fr;
  gap: 10px;
  align-items: center;
  margin-bottom: 16px;
`;

const AddNewLink = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 10px;
  color: #3054b9;
  cursor: pointer;
`;

const DynamicForm = ({
  actionType,
  control,
  errors,
  register,
  clearErrors,
  setValue,
  getValues,
}: {
  actionType: string;
  control: any;
  errors: any;
  register: any;
  clearErrors: any;
  setValue: any;
  getValues: any;
}) => {
  const [fields, setFields] = useState<any[]>([]);
  const [selections, setSelections] = useState<{ [key: string]: any }>({});
  const [changedStep, setChangedStep] = useState<string | undefined>(undefined);
  const { selectedAction, getScriptValue, getLiteralValue, dataPicker } =
    useWorkFlow();
  const [callNextStep, setCallNextStep] = useState<boolean>(false);

  const [currentStep, setCurrentStep] = useState<{
    actionType: string;
    currentSelections: any;
  }>(() => {
    const copySelectedAction: any = { ...selectedAction?.parameters };
    // if any key of copySelectedAction is an object with keys dataType, type, value then take value else take the whole key value
    Object.keys(copySelectedAction).forEach((key) => {
      if (
        copySelectedAction[key] &&
        copySelectedAction[key].hasOwnProperty("dataType") &&
        copySelectedAction[key].hasOwnProperty("type") &&
        copySelectedAction[key].hasOwnProperty("value")
      ) {
        copySelectedAction[key] = copySelectedAction[key].value;
      }
    });
    return { actionType, currentSelections: { ...copySelectedAction } };
  });

  const [allFieldsDataType, setAllFieldsDataType] = useState<any>({});

  const { actionConditionPayload } = usePayload();
  const { openJSEditorLayout, scriptEditorOpen } = useJSEditor();
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      BuilderService.fetchNextStep(currentStep).then((response: any) => {
        const newFields = response.data.fields.map((field: any) => ({
          ...field,
          step: currentStep, // Tagging each field with its step for possible future needs
        }));
        // only change fields after the field with key changedStep
        const changedStepIndex = fields.findIndex(
          (field: any) => field.name === changedStep
        );

        // get an rray of field.name after the changedStepIndex
        const fieldsAfterChangedStep = fields
          .slice(changedStepIndex + 1)
          .map((field) => field.name);

        // if changedStepIndex is 0, change fields array from 1st index
        // also merge the field with same field.name
        // so if we have prev has field1, field2, field3 and new has field1, field2, field4
        // then we should have field1, field2,field3, field4 and not field1, field2, field1, field2, field3, field4

        const fieldsNames = fields.map((field) => field.name);

        const newFieldsWithoutDuplicates = newFields.filter(
          (field: any) => !fieldsNames.includes(field.name)
        );

        if (changedStepIndex >= 0) {
          setFields((prev) => [...newFields]);
        } else {
          setFields((prev) => [...prev, ...newFields]);
        }
        //   setFields(prev => [...prev, ...newFields]); // Append new fields to all existing fields
        // Initialize selections for new fields only if they don't already exist
        const newSelections = newFields.reduce(
          (acc: any, field: any) => {
            if (acc[field.name] === undefined) {
              acc[field.name] = "";
            }
            return acc;
          },
          { ...selections }
        );
        const fieldsWithDataTypes = fields.map((field) => ({
          [field.name]: field.dataType,
        }));
        setAllFieldsDataType((prev: any) => ({
          ...fieldsWithDataTypes,
        }));
        setSelections(newSelections);
        const resetValues = fieldsAfterChangedStep.reduce((obj, key) => {
          return { ...obj, [key]: undefined };
        }, {});
        dispatch(
          setSelectedAction({
            ...selectedAction,
            parameters: {
              ...selectedAction.parameters,
              // get keys from fieldsAfterChangedStep and set them to undefined in selectedAction.parameters. the keys might not exist in selectedAction.parameters
              ...resetValues,
            },
            hasChanged: true,
          })
        );
      });
    } catch (error) {
      console.error("Error fetching next step", error);
      notification(
        "error",
        "Error fetching next step",
        "Error fetching next step"
      );
    }
  }, [callNextStep]);

  const handleSelectionChange = (
    key: string,
    value: any,
    nextStep?: boolean
  ) => {
    clearErrors();
    setSelections((prev) => ({ ...prev, [key]: value }));

    setChangedStep(key);

    setCurrentStep({
      actionType,
      currentSelections: { ...selections, [key]: value },
    });
    if (nextStep) {
      setCallNextStep((prev: boolean) => !prev);
    }
    dispatch(
      setSelectedAction({
        ...selectedAction,
        parameters: {
          ...selectedAction.parameters,
          [key]: value,
        },
        hasChanged: true,
      })
    );
    // if (response.data.nextStep) {
    //   setCurrentStep('next');
    // }
  };

  const handleRemoveFilter = (index: number) => {
    const updatedSelectedActions = _.cloneDeep(selectedAction);
    updatedSelectedActions.parameters.filters.splice(index, 1);
    dispatch(setSelectedAction(updatedSelectedActions));
  };

  const handleFilterValChange = ({
    key,
    index,
    value,
  }: {
    key: string;
    index: number;
    value: string;
  }) => {
    if (selectedAction?.parameters?.filters) {
      const updatedSelectedActions = _.cloneDeep(selectedAction);
      const pathKey = `parameters.filters[${index}].${key}`;
      _.set(updatedSelectedActions, pathKey, value);
      dispatch(setSelectedAction(updatedSelectedActions));
    } else {
      dispatch(
        setSelectedAction({
          ...selectedAction,
          hasChanged: true,
          parameters: {
            ...selectedAction.parameters,
            filters: [
              {
                [key]: value,
              },
            ],
          },
        })
      );
    }
  };

  const actionExecutionScriptConfig = ({
    type,
    key,
    childrens,
  }: {
    type: string;
    key: string;
    childrens?: any[];
  }) => {
    switch (type) {
      case "TEXT":
        let isObject = false;
        let addedInfo:any=""
        if (key.includes("searchParams")) {
          addedInfo = `/**
                  * This function should return an object that represents filter criteria for a data set.
                  * The object should be in the form { key1:value1, key2:value2, ... },
                  * where key1, key2, etc. are the IDs of the columns to be filtered,
                  * and value1, value2, etc. are the corresponding values to filter for in those columns.
                  */`;
              isObject = true;
        }
        if (key.includes("sorting")) {
          addedInfo = `/**
          * This is a schema for a filter configuration object.
          * 
          * The object has the following properties:
          * 
          * id: A string that represents the field name to be filtered.
          * 
          * desc: A boolean that indicates whether the filter should be in descending order. 
          *       If true, the filter will be in descending order. If false or not provided, the filter will be in ascending order.
          * 
          * keyword: Set this to true if the field is a TEXT field. 
          *          This ensures the field is sorted using the un-analyzed .keyword sub-field, providing accurate sorting for text fields.
          *          Do not set this to true for non-text fields (e.g., numeric or date fields).
          * 
          * Example:
          * 
          * {
          *   "id": "FieldName",
          *   "desc": false,
          *   "keyword": false
          * }
          */`;
            isObject = true;
        }
        return {
          script:
            getScriptValue(key) ||
            getScriptValue(key) ||
            getLowCodeEditorText(
              key,
              DataFieldType.TEXT,
              `${addedInfo} \n return ${getLowCodeEditorDefaultReturnText(
                DataFieldType.TEXT
              )}`
            ),
          // script: 'return "Hello World";',
          dataType: isObject ? "OBJECT" : "TEXT",
        };
      case "NUMBER":
        return {
          script:
            getScriptValue(key) ||
            getScriptValue(key) ||
            getLowCodeEditorText(
              key,
              DataFieldType.NUMBER,
              `return ${getLowCodeEditorDefaultReturnText(
                DataFieldType.NUMBER
              )}`
            ),
          dataType: DataFieldType.NUMBER,
        };
      case "DATE":
      case "ONLY_DATE":
      case "DATETIME":
        return {
          script:
            getScriptValue(key) ||
            getScriptValue(key) ||
            getLowCodeEditorText(
              key,
              DataFieldType.DATE,
              `return ${getLowCodeEditorDefaultReturnText(DataFieldType.DATE)}`
            ),
          dataType: DataFieldType.DATE,
        };
      case "BOOLEAN":
        return {
          script:
            getScriptValue(key) ||
            getScriptValue(key) ||
            getLowCodeEditorText(
              key,
              DataFieldType.BOOLEAN,
              `return ${getLowCodeEditorDefaultReturnText(
                DataFieldType.BOOLEAN
              )}`
            ),
          dataType: DataFieldType.BOOLEAN,
        };
      case "LIST_OF_TEXT":
      case "RELATION":
      case "LIST":
        let scriptString = `/** structure of ${key} \n  { \n`;
        childrens?.forEach((child: any) => {
          scriptString += `${child.name}: ${child.type},\n`;
        });
        scriptString += "} **/ \n";
        return {
          script:
            getScriptValue(key) ||
            getScriptValue(key) ||
            getLowCodeEditorText(
              key,
              DataFieldType.LIST,
              `${scriptString} \n return ${getLowCodeEditorDefaultReturnText(
                DataFieldType.LIST
              )}`
            ),
          dataType: DataFieldType.LIST_OF_TEXT,
        };
      case "LOCATION":
        return {
          script:
            getScriptValue(key) ||
            getScriptValue(key) ||
            getLowCodeEditorText(
              key,
              DataFieldType.LOCATION,
              `return ${getLowCodeEditorDefaultReturnText(
                DataFieldType.LOCATION
              )}`
            ),
          dataType: DataFieldType.OBJECT,
        };
      case "TIME":
        return {
          script:
            getScriptValue(key) ||
            getScriptValue(key) ||
            getLowCodeEditorText(
              key,
              DataFieldType.TEXT,
              `\n // Time should be in HH:MM format \n return ${getLowCodeEditorDefaultReturnText(
                DataFieldType.TIME
              )} `
            ),
          dataType: DataFieldType.TEXT,
        };
      default:
        // handle other cases or do nothing
        break;
    }
  };

  const handleValueChange = (e: any, key: any) => {
    /**
     *         parameters: {
          ...selectedAction.parameters,
          [key]: {
            type: getInputType(e.target.value),
            value: removeBraces(e.target.value),
            dataType: TEXT,
          },
        },

     */
    const updatedSelectedAction = _.cloneDeep(selectedAction);
    const value = {
      type: getInputType(e.target.value),
      value: removeBraces(e.target.value),
      dataType: TEXT,
    };
    const pathKey = `parameters.${key}`;
    _.set(updatedSelectedAction, pathKey, value);
    dispatch(
      setSelectedAction({
        ...updatedSelectedAction,
        hasChanged: true,
      })
    );
  };

  const handleLocationChange = (e: any, key: any) => {
    dispatch(
      setSelectedAction({
        ...selectedAction,
        hasChanged: true,
        parameters: {
          ...selectedAction.parameters,
          [key]: {
            ...selectedAction.parameters[key],
            address: {
              type: getInputType(e.target.value),
              value: removeBraces(e.target.value),
              dataType: TEXT,
            },
            latitude: {
              type: getInputType(e.target.value),
              value: 0,
              dataType: NUMBER,
            },
            longitude: {
              type: getInputType(e.target.value),
              value: 0,
              dataType: NUMBER,
            },
          },
        },
      })
    );
  };

  const handleNumValueChange = (e: any, key: any) => {
    const updatedSelectedAction = _.cloneDeep(selectedAction);
    const value = {
      type: getInputType(e.target.value),
      value: removeBraces(e.target.value),
      dataType: NUMBER,
    };
    const pathKey = `parameters.${key}`;
    _.set(updatedSelectedAction, pathKey, value);
    dispatch(
      setSelectedAction({
        ...updatedSelectedAction,
        hasChanged: true,
      })
    );
  };

  const handleDateValueChange = (e: any, key: any) => {
    const updatedSelectedAction = _.cloneDeep(selectedAction);
    const value = {
      type: getInputType(e.target.value),
      value: removeBraces(e.target.value),
      dataType: DataFieldType.DATE,
    };
    const pathKey = `parameters.${key}`;
    _.set(updatedSelectedAction, pathKey, value);
    dispatch(
      setSelectedAction({
        ...updatedSelectedAction,
        hasChanged: true,
      })
    );
  };

  const handleValueChangeForDropdown = (
    e: any,
    key: any,
    nextStep?: boolean
  ) => {
    let values: any;
    if (e === null || e === undefined || e === "") {
      values = [];
    } else if (isArray(e)) {
      values = e.map((val: any) => val.value);
    } else {
      if (e.value) {
        values = [e.value];
      } else {
        values = [];
      }
    }

    setSelections((prev) => ({ ...prev, [key]: values }));

    setChangedStep(key);

    const copySelectedAction: any = { ...selectedAction?.parameters };
    // if any key of copySelectedAction is an object with keys dataType, type, value then take value else take the whole key value
    Object.keys(copySelectedAction).forEach((key) => {
      if (
        copySelectedAction[key] &&
        copySelectedAction[key].hasOwnProperty("dataType") &&
        copySelectedAction[key].hasOwnProperty("type") &&
        copySelectedAction[key].hasOwnProperty("value")
      ) {
        copySelectedAction[key] = copySelectedAction[key].value;
      }
    });

    setCurrentStep({
      actionType,
      currentSelections: { ...(copySelectedAction || {}), [key]: values },
    });
    if (nextStep) {
      setCallNextStep((prev: boolean) => !prev);
    }

    const updatedSelectedAction = _.cloneDeep(selectedAction);
    const value = {
      type: "literal",
      value: values,
      dataType: DataFieldType.LIST_OF_TEXT,
    };
    const pathKey = `parameters.${key}`;
    _.set(updatedSelectedAction, pathKey, value);

    dispatch(
      setSelectedAction({
        ...updatedSelectedAction,
        hasChanged: true,
      })
    );
  };

  const handleFileOrImageValueChange = (e: any, key: any) => {
    /**
     * e.target.value is an string with , separated values
     * if e.target.value is empty, then set it to empty array
     * else split the string by , and set it as array
     * if there is an , and after that there is no value, then it should be ignored
     * if there is an , and before that there is no value, then it should be ignored
     * trim the values before setting it
     */
    let values;
    if (!e.target.value) {
      values = [];
    } else {
      values = e.target.value.split(",").map((val: string) => val.trim());
      // remove empty values
      values = values.filter((val: string) => val !== "");
    }
    const updatedSelectedAction = _.cloneDeep(selectedAction);
    const value = {
      type: "literal",
      value: values,
      dataType: DataFieldType.LIST_OF_TEXT,
    };
    const pathKey = `parameters.${key}`;
    _.set(updatedSelectedAction, pathKey, value);
    dispatch(
      setSelectedAction({
        ...updatedSelectedAction,
        hasChanged: true,
      })
    );
  };

  const handleRelationValueChange = (e: any, key: any) => {
    let values;
    if (
      e.target.value === null ||
      e.target.value === undefined ||
      e.target.value === ""
    ) {
      values = [];
    } else if (e.target.value === "") {
      values = [];
    } else {
      values = e.target.value.split(",").map((val: string) => val.trim());
      // remove empty values
      values = values.filter((val: string) => val !== "");
    }
    dispatch(
      setSelectedAction({
        ...selectedAction,
        hasChanged: true,
        parameters: {
          ...selectedAction.parameters,
          [key]: {
            type: "literal",
            value: values,
            dataType: DataFieldType.LIST_OF_TEXT,
          },
        },
      })
    );
  };

  const handleBooleanValueChange = (e: any, key: any) => {
    const updatedSelectedAction = _.cloneDeep(selectedAction);
    const value = {
      type: "literal",
      value: e ? e.value : undefined,
      dataType: DataFieldType.BOOLEAN,
    };
    const pathKey = `parameters.${key}`;
    _.set(updatedSelectedAction, pathKey, value);
    dispatch(
      setSelectedAction({
        ...updatedSelectedAction,
        hasChanged: true,
      })
    );
  };

  const handleListOfTextForFilter = (e: any, key: any) => {
    let values;
    if (e === null || e === undefined || e === "") {
      values = [];
    } else if (isArray(e)) {
      values = e.map((val: any) => val.value);
    } else {
      if (e.value) {
        values = [e.value];
      } else {
        values = [];
      }
    }
    const updatedSelectedAction = _.cloneDeep(selectedAction);
    const value = {
      type: "literal",
      value: values?.[0] || "",
      dataType: DataFieldType.TEXT,
    };
    const pathKey = `parameters.${key}`;
    _.set(updatedSelectedAction, pathKey, value);
    dispatch(
      setSelectedAction({
        ...updatedSelectedAction,
        hasChanged: true,
      })
    );
  };

  const handleAddFilter = () => {
    const isFilterPresent = selectedAction.parameters?.filters;
    dispatch(
      setSelectedAction({
        ...selectedAction,
        hasChanged: true,
        parameters: {
          ...selectedAction.parameters,
          filters: [
            ...(isFilterPresent || []),
            {
              id: "",
              filterType: "",
              value: {
                type: "literal",
                value: "",
                dataType: TEXT,
              },
            },
          ],
        },
      })
    );
  };

  return (
    <div>
      {fields.map((field, index) => {
        return (
          <>
            <Container type={field.type}>
              {field.type === "DROPDOWN" ||
              field.type === "TEXT" ||
              field.type === "NUMBER" ||
              field.type === "DATE" ||
              field.type === "DATETIME" ||
              field.type === "LIST_OF_TEXT" ||
              field.type === "FILTER" ||
              field.type === "FILE" ||
              field.type === "IMAGE" ||
              field.type === "LOCATION" ||
              field.type === "ONLY_DATE" ||
              field.type === "BOOLEAN" ||
              field.type === "RELATION" ||
              field.type === "LIST" ||
              field.type === "TIME" ? (
                <Label>
                  {field.displayName}
                  {field.isRequired ? (
                    <span style={{ color: "red" }}>*</span>
                  ) : null}
                </Label>
              ) : null}

              {field.type === "DROPDOWN" ? (
                <>
                  <Select
                    // key={field.name}
                    styles={customStyles}
                    value={
                      field.options
                        .filter(
                          (option: any) =>
                            option.key ===
                            selectedAction?.parameters?.[field.name]
                        )
                        .map((option: any) => ({
                          value: option.key,
                          label: option.value,
                        }))[0] || null
                    }
                    // isRequired={field.isRequired}
                    onChange={(selectedOption: any) =>
                      handleSelectionChange(
                        field.name,
                        selectedOption ? selectedOption.value : "",
                        field.nextStep
                      )
                    }
                    options={field.options.map((option: any) => ({
                      value: option.key,
                      label: option.value,
                    }))}
                    name={`parameters.${field.name}`}
                  />
                  {errors &&
                    get(errors, `parameters.${field.name}.message`) && (
                      <p style={{ color: "red", fontSize: "12px" }}>
                        {get(errors, `parameters.${field.name}.message`)}
                      </p>
                    )}
                </>
              ) : field.type === "TEXT" ? (
                <>
                  <ModalSmartInputTextField
                    type={
                      selectedAction?.parameters?.[field.name]?.type ||
                      "literal"
                    }
                    dataType={DataFieldType.TEXT}
                    isSmartField={true}
                    control={control}
                    step="any"
                    avoidOverflow
                    minWidth="100%"
                    errors={errors}
                    onChangeFunc={(e: any) => handleValueChange(e, field.name)}
                    apiBodyPayload={actionConditionPayload}
                    value={
                      selectedAction?.parameters?.[field.name]?.value ||
                      (typeof selectedAction?.parameters?.[field.name] ==
                      "string"
                        ? selectedAction?.parameters?.[field.name]
                        : "")
                    }
                    placeholder={`Enter ${field.displayName}`}
                    register={register}
                    openJSEditor={openJSEditorLayout}
                    name={`parameters.${field.name}`}
                    config={actionExecutionScriptConfig({
                      type: "TEXT",
                      key: `parameters.${field.name}`,
                    })}
                    editorLocation={`parameters.${field.name}`}
                  />
                </>
              ) : field.type === "NUMBER" ? (
                <>
                  <ModalSmartInputTextField
                    type={
                      selectedAction?.parameters?.[field.name]?.type ||
                      "literal"
                    }
                    dataType={DataFieldType.NUMBER}
                    control={control}
                    step="any"
                    avoidOverflow
                    minWidth="100%"
                    errors={errors}
                    onChangeFunc={(e: any) =>
                      handleNumValueChange(e, field.name)
                    }
                    isSmartField={true}
                    apiBodyPayload={actionConditionPayload}
                    value={
                      selectedAction?.parameters?.[field.name]?.value ||
                      (typeof selectedAction?.parameters?.[field.name] ==
                      "string"
                        ? selectedAction?.parameters?.[field.name]
                        : "")
                    }
                    placeholder={`Enter ${field.displayName}`}
                    register={register}
                    openJSEditor={openJSEditorLayout}
                    name={`parameters.${field.name}`}
                    config={actionExecutionScriptConfig({
                      type: "NUMBER",
                      key: `parameters.${field.name}`,
                    })}
                    editorLocation={`parameters.${field.name}`}
                  />
                </>
              ) : field.type === "LIST_OF_TEXT" ? (
                <>
                  <ModalSmartInputTextField
                    type={
                      selectedAction?.parameters?.[field.name]?.type ||
                      "literal"
                    }
                    dataType={
                      (selectedAction?.[field.name]?.value?.includes("${") &&
                        selectedAction?.[field.name].value?.endsWith("}")) ||
                      selectedAction?.[field.name]?.type == "script"
                        ? DataFieldType.TEXT
                        : IComponentType.DROPDOWN
                    }
                    control={control}
                    step="any"
                    avoidOverflow
                    minWidth="100%"
                    errors={errors}
                    onChangeFunc={(e: any) =>
                      handleValueChangeForDropdown(
                        e,
                        field.name,
                        field.nextStep
                      )
                    }
                    isSmartField={true}
                    apiBodyPayload={actionConditionPayload}
                    value={
                      selectedAction?.parameters?.[field.name]?.value || []
                    }
                    placeholder={`Enter ${field.displayName}`}
                    register={register}
                    openJSEditor={openJSEditorLayout}
                    options={
                      field.properties?.options
                        ? field.properties?.options.map((option: any) => ({
                            value: option.key ? option.key : option,
                            label: option.value ? option.value : option,
                          }))
                        : []
                    }
                    name={`parameters.${field.name}`}
                    config={actionExecutionScriptConfig({
                      type: "LIST_OF_TEXT",
                      key: `parameters.${field.name}`,
                    })}
                    editorLocation={`parameters.${field.name}`}
                    isMulti={field.otherDetails?.isMultiselect}
                  />
                </>
              ) : field.type === "LIST" ? (
                <>
                  <ModalSmartInputTextField
                    type={
                      selectedAction?.parameters?.[field.name]?.type ||
                      "literal"
                    }
                    dataType={
                      (selectedAction?.[field.name]?.value?.includes("${") &&
                        selectedAction?.[field.name].value?.endsWith("}")) ||
                      selectedAction?.[field.name]?.type == "script"
                        ? DataFieldType.TEXT
                        : DataFieldType.TEXT
                    }
                    control={control}
                    step="any"
                    avoidOverflow
                    minWidth="100%"
                    errors={errors}
                    onChangeFunc={(e: any) =>
                      handleValueChangeForDropdown(
                        e,
                        field.name,
                        field.nextStep
                      )
                    }
                    isSmartField={true}
                    apiBodyPayload={actionConditionPayload}
                    value={
                      selectedAction?.parameters?.[field.name]?.value || []
                    }
                    disabled={true}
                    placeholder={`${field.displayName} is a LIST. Open script editor to add values`}
                    register={register}
                    openJSEditor={openJSEditorLayout}
                    options={
                      field.properties?.options
                        ? field.properties?.options.map((option: any) => ({
                            value: option.key ? option.key : option,
                            label: option.value ? option.value : option,
                          }))
                        : []
                    }
                    name={`parameters.${field.name}`}
                    config={actionExecutionScriptConfig({
                      type: "LIST",
                      key: `parameters.${field.name}`,
                      childrens: field?.children || [],
                    })}
                    editorLocation={`parameters.${field.name}`}
                    isMulti={field.otherDetails?.isMultiselect}
                  />
                </>
              ) : field.type === "IMAGE" || field.type === "FILE" ? (
                <>
                  <ModalSmartInputTextField
                    type={
                      selectedAction?.parameters?.[field.name]?.type ||
                      "literal"
                    }
                    dataType={
                      (selectedAction?.[field.name]?.value?.includes("${") &&
                        selectedAction?.[field.name].value?.endsWith("}")) ||
                      selectedAction?.[field.name]?.type == "script"
                        ? DataFieldType.TEXT
                        : DataFieldType.TEXT
                    }
                    control={control}
                    step="any"
                    avoidOverflow
                    minWidth="100%"
                    errors={errors}
                    onChangeFunc={(e: any) =>
                      handleFileOrImageValueChange(e, field.name)
                    }
                    isSmartField={true}
                    apiBodyPayload={actionConditionPayload}
                    value={
                      selectedAction?.parameters?.[field.name]?.value &&
                      isArray(selectedAction?.parameters?.[field.name]?.value)
                        ? selectedAction?.parameters?.[field.name]?.value?.join(
                            ","
                          )
                        : selectedAction?.parameters?.[field.name]?.value
                    }
                    placeholder={`Enter ${field.displayName}`}
                    register={register}
                    openJSEditor={openJSEditorLayout}
                    options={
                      field.properties?.options
                        ? field.properties?.options.map((option: any) => ({
                            value: option.key ? option.key : option,
                            label: option.label ? option.label : option,
                          }))
                        : []
                    }
                    name={`parameters.${field.name}`}
                    config={actionExecutionScriptConfig({
                      type: "LIST_OF_TEXT",
                      key: `parameters.${field.name}`,
                    })}
                    editorLocation={`parameters.${field.name}`}
                    isMulti={field.otherDetails?.isMultiselect}
                  />
                </>
              ) : field.type === "DATE" || field.type === "DATETIME" ? (
                <>
                  <ModalSmartInputTextField
                    type={
                      selectedAction?.parameters?.[field.name]?.type ||
                      "literal"
                    }
                    dataType={
                      (selectedAction?.[field.name]?.value?.includes("${") &&
                        selectedAction?.[field.name].value?.endsWith("}")) ||
                      selectedAction?.[field.name]?.type == "script"
                        ? DataFieldType.TEXT
                        : DataFieldType.DATETIME
                    }
                    control={control}
                    step="any"
                    avoidOverflow
                    minWidth="100%"
                    errors={errors}
                    onChangeFunc={(e: any) =>
                      handleDateValueChange(e, field.name)
                    }
                    isSmartField={true}
                    apiBodyPayload={actionConditionPayload}
                    value={selectedAction?.parameters?.[field.name]?.value}
                    placeholder={`Enter ${field.displayName}`}
                    register={register}
                    openJSEditor={openJSEditorLayout}
                    name={`parameters.${field.name}`}
                    config={actionExecutionScriptConfig({
                      type: "DATE",
                      key: `parameters.${field.name}`,
                    })}
                    editorLocation={`parameters.${field.name}`}
                  />
                </>
              ) : field.type === "ONLY_DATE" ? (
                <>
                  <ModalSmartInputTextField
                    type={
                      selectedAction?.parameters?.[field.name]?.type ||
                      "literal"
                    }
                    dataType={
                      (selectedAction?.[field.name]?.value?.includes("${") &&
                        selectedAction?.[field.name].value?.endsWith("}")) ||
                      selectedAction?.[field.name]?.type == "script"
                        ? DataFieldType.TEXT
                        : DataFieldType.ONLY_DATE
                    }
                    control={control}
                    step="any"
                    avoidOverflow
                    minWidth="100%"
                    errors={errors}
                    onChangeFunc={(e: any) =>
                      handleDateValueChange(e, field.name)
                    }
                    isSmartField={true}
                    apiBodyPayload={actionConditionPayload}
                    value={selectedAction?.parameters?.[field.name]?.value}
                    placeholder={`Enter ${field.displayName}`}
                    register={register}
                    openJSEditor={openJSEditorLayout}
                    name={`parameters.${field.name}`}
                    config={actionExecutionScriptConfig({
                      type: "DATE",
                      key: `parameters.${field.name}`,
                    })}
                    editorLocation={`parameters.${field.name}`}
                  />
                </>
              ) : field.type === "TIME" ? (
                <>
                  <ModalSmartInputTextField
                    type={
                      selectedAction?.parameters?.[field.name]?.type ||
                      "literal"
                    }
                    dataType={
                      (selectedAction?.[field.name]?.value?.includes("${") &&
                        selectedAction?.[field.name].value?.endsWith("}")) ||
                      selectedAction?.[field.name]?.type == "script"
                        ? DataFieldType.TEXT
                        : DataFieldType.TIME
                    }
                    control={control}
                    step="any"
                    avoidOverflow
                    minWidth="100%"
                    errors={errors}
                    onChangeFunc={(e: any) =>
                      handleDateValueChange(e, field.name)
                    }
                    isSmartField={true}
                    apiBodyPayload={actionConditionPayload}
                    value={selectedAction?.parameters?.[field.name]?.value}
                    placeholder={`Enter ${field.displayName}`}
                    register={register}
                    openJSEditor={openJSEditorLayout}
                    name={`parameters.${field.name}`}
                    config={actionExecutionScriptConfig({
                      type: "TIME",
                      key: `parameters.${field.name}`,
                    })}
                    editorLocation={`parameters.${field.name}`}
                  />
                </>
              ) : field.type === "BOOLEAN" ? (
                <>
                  <ModalSmartInputTextField
                    type={
                      selectedAction?.parameters?.[field.name]?.type ||
                      "literal"
                    }
                    dataType={
                      (selectedAction?.[field.name]?.value?.includes("${") &&
                        selectedAction?.[field.name].value?.endsWith("}")) ||
                      selectedAction?.[field.name]?.type == "script"
                        ? DataFieldType.TEXT
                        : IComponentType.DROPDOWN
                    }
                    control={control}
                    step="any"
                    avoidOverflow
                    minWidth="100%"
                    errors={errors}
                    onChangeFunc={(e: any) =>
                      handleBooleanValueChange(e, field.name)
                    }
                    isSmartField={true}
                    apiBodyPayload={actionConditionPayload}
                    value={
                      [selectedAction?.parameters?.[field.name]?.value] || []
                    }
                    placeholder={`Enter ${field.displayName}`}
                    register={register}
                    openJSEditor={openJSEditorLayout}
                    options={[
                      {
                        label: "True",
                        value: true,
                      },
                      {
                        label: "False",
                        value: false,
                      },
                    ]}
                    name={`parameters.${field.name}`}
                    config={actionExecutionScriptConfig({
                      type: "BOOLEAN",
                      key: `parameters.${field.name}`,
                    })}
                    editorLocation={`parameters.${field.name}`}
                    isMulti={field.otherDetails?.isMultiselect}
                  />
                </>
              ) : field.type === "LOCATION" ? (
                <>
                  <ModalSmartInputTextField
                    type={
                      selectedAction?.parameters?.[field.name]?.type ||
                      "literal"
                    }
                    dataType={
                      (selectedAction?.[field.name]?.value?.includes("${") &&
                        selectedAction?.[field.name].value?.endsWith("}")) ||
                      selectedAction?.[field.name]?.type == "script"
                        ? DataFieldType.TEXT
                        : DataFieldType.TEXT
                    }
                    control={control}
                    step="any"
                    avoidOverflow
                    minWidth="100%"
                    errors={errors}
                    onChangeFunc={(e: any) =>
                      handleLocationChange(e, field.name)
                    }
                    isSmartField={true}
                    apiBodyPayload={actionConditionPayload}
                    value={
                      selectedAction?.parameters?.[field.name]?.adress || ""
                    }
                    placeholder={`Enter ${field.displayName}`}
                    register={register}
                    openJSEditor={openJSEditorLayout}
                    options={[
                      {
                        label: "True",
                        value: true,
                      },
                      {
                        label: "False",
                        value: false,
                      },
                    ]}
                    name={`parameters.${field.name}`}
                    config={actionExecutionScriptConfig({
                      type: "LOCATION",
                      key: `parameters.${field.name}`,
                    })}
                    editorLocation={`parameters.${field.name}`}
                    isMulti={field.otherDetails?.isMultiselect}
                  />
                </>
              ) : field.type === "RELATION" ? (
                <>
                  <ModalSmartInputTextField
                    type={
                      selectedAction?.parameters?.[field.name]?.type ||
                      "literal"
                    }
                    dataType={
                      (selectedAction?.[field.name]?.value?.includes("${") &&
                        selectedAction?.[field.name].value?.endsWith("}")) ||
                      selectedAction?.[field.name]?.type == "script"
                        ? DataFieldType.TEXT
                        : DataFieldType.TEXT
                    }
                    control={control}
                    step="any"
                    avoidOverflow
                    minWidth="100%"
                    errors={errors}
                    onChangeFunc={(e: any) =>
                      handleRelationValueChange(e, field.name)
                    }
                    isSmartField={true}
                    apiBodyPayload={actionConditionPayload}
                    value={
                      selectedAction?.parameters?.[field.name]?.value &&
                      isArray(selectedAction?.parameters?.[field.name]?.value)
                        ? selectedAction?.parameters?.[field.name]?.value?.join(
                            ","
                          )
                        : selectedAction?.parameters?.[field.name]?.value
                    }
                    placeholder={`Enter ${field.displayName}`}
                    register={register}
                    openJSEditor={openJSEditorLayout}
                    name={`parameters.${field.name}`}
                    config={actionExecutionScriptConfig({
                      type: "LIST_OF_TEXT",
                      key: `parameters.${field.name}`,
                    })}
                    editorLocation={`parameters.${field.name}`}
                  />
                </>
              ) : field.type === "FILTER" ? (
                selectedAction?.parameters?.[field.name]?.length === 0 ||
                selectedAction?.parameters?.[field.name] === undefined ? (
                  <>
                    <div style={{ marginTop: "16px" }}></div>
                    <AddNewLink onClick={handleAddFilter}>
                      <AiOutlinePlus />
                      Add Filter
                    </AddNewLink>
                  </>
                ) : (
                  <>
                    <div style={{ marginTop: "16px" }}></div>
                    {selectedAction?.parameters?.[field.name]?.map(
                      (item: string, index: number) => (
                        <FilterWrapper>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <RiDeleteBin2Line
                              onClick={() => handleRemoveFilter(index)}
                            />
                          </div>
                          <Select
                            value={
                              field.options
                                .filter(
                                  (option: any) =>
                                    option.key ===
                                    selectedAction?.parameters?.filters[index]
                                      ?.id
                                )
                                .map((option: any) => ({
                                  value: option.key,
                                  label: option.value,
                                }))[0] || null
                            }
                            onChange={(selectedOption: any) =>
                              handleFilterValChange({
                                key: `id`,
                                value: selectedOption
                                  ? selectedOption.value
                                  : "",
                                index,
                              })
                            }
                            options={field.options.map((option: any) => ({
                              value: option.key,
                              label: option.value,
                            }))}
                          />

                          {errors &&
                            get(
                              errors,
                              `parameters.filters[${index}].id.message`
                            ) && (
                              <p style={{ color: "red", fontSize: "12px" }}>
                                {get(
                                  errors,
                                  `parameters.filters[${index}].id.message`
                                )}
                              </p>
                            )}
                          <Select
                            onChange={(selectedOption: any) =>
                              handleFilterValChange({
                                key: `filterType`,
                                value: selectedOption
                                  ? selectedOption.value
                                  : "",
                                index,
                              })
                            }
                            value={
                              field.filterOptions[
                                selectedAction?.parameters?.filters[index]?.id
                              ]
                                ?.filter(
                                  (option: any) =>
                                    option.key ===
                                    selectedAction?.parameters?.filters[index]
                                      ?.filterType
                                )
                                .map((option: any) => ({
                                  value: option.value,
                                  label: option.value,
                                }))[0] || null
                            }
                            options={field.filterOptions[
                              selectedAction?.parameters?.filters[index]?.id
                            ]?.map((option: any) => ({
                              value: option.key,
                              label: option.value,
                            }))}
                          />
                          {errors &&
                            get(
                              errors,
                              `parameters.filters[${index}].filterType.message`
                            ) && (
                              <p style={{ color: "red", fontSize: "12px" }}>
                                {get(
                                  errors,
                                  `parameters.filters[${index}].filterType.message`
                                )}
                              </p>
                            )}

                          {field.fieldType[
                            selectedAction?.parameters?.filters[index]?.id
                          ] === "DROPDOWN" ? (
                            <Select
                              key={field.name}
                              value={
                                field.options
                                  .filter(
                                    (option: any) =>
                                      option.key ===
                                      selectedAction?.parameters?.[field.name]
                                  )
                                  .map((option: any) => ({
                                    value: option.key,
                                    label: option.value,
                                  }))[0] || null
                              }
                              isRequired={field.isRequired}
                              onChange={(selectedOption: any) =>
                                handleSelectionChange(
                                  field.name,
                                  selectedOption ? selectedOption.value : "",
                                  field.nextStep
                                )
                              }
                              options={field.options.map((option: any) => ({
                                value: option.key,
                                label: option.value,
                              }))}
                            />
                          ) : field.fieldType[
                              selectedAction?.parameters?.filters[index]?.id
                            ] === "TEXT" ? (
                            <>
                              <ModalSmartInputTextField
                                type={
                                  selectedAction?.parameters?.[field.name]?.[
                                    index
                                  ]?.value?.type || "literal"
                                }
                                dataType={DataFieldType.TEXT}
                                control={control}
                                step="any"
                                avoidOverflow
                                minWidth="100%"
                                errors={errors}
                                onChangeFunc={(e: any) =>
                                  handleValueChange(
                                    e,
                                    `filters[${index}].value`
                                  )
                                }
                                isSmartField={true}
                                apiBodyPayload={actionConditionPayload}
                                value={
                                  selectedAction?.parameters?.[field.name]?.[
                                    index
                                  ]?.value?.value ||
                                  (typeof selectedAction?.parameters?.[
                                    field.name
                                  ]?.[index]?.value?.value == "string"
                                    ? selectedAction?.parameters?.[
                                        field.name
                                      ]?.[index]?.value?.value
                                    : "")
                                }
                                placeholder={`Enter ${field.displayName}`}
                                register={register}
                                openJSEditor={openJSEditorLayout}
                                name={`parameters.${field.name}.[${index}]`}
                                config={actionExecutionScriptConfig({
                                  type: "TEXT",
                                  key: `parameters.${field.name}.[${index}].value`,
                                })}
                                editorLocation={`parameters.${field.name}.[${index}].value`}
                              />
                            </>
                          ) : field.fieldType[
                              selectedAction?.parameters?.filters[index]?.id
                            ] === "NUMBER" ? (
                            <>
                              <ModalSmartInputTextField
                                type={
                                  selectedAction?.parameters?.[field.name]?.[
                                    index
                                  ]?.value?.type || "literal"
                                }
                                dataType={DataFieldType.NUMBER}
                                control={control}
                                step="any"
                                avoidOverflow
                                minWidth="100%"
                                errors={errors}
                                onChangeFunc={(e: any) =>
                                  handleNumValueChange(
                                    e,
                                    `filters[${index}].value`
                                  )
                                }
                                isSmartField={true}
                                apiBodyPayload={actionConditionPayload}
                                value={
                                  selectedAction?.parameters?.[field.name]?.[
                                    index
                                  ]?.value?.value ||
                                  (typeof selectedAction?.parameters?.[
                                    field.name
                                  ]?.[index]?.value?.value == "string"
                                    ? selectedAction?.parameters?.[
                                        field.name
                                      ]?.[index]?.value?.value
                                    : "")
                                }
                                placeholder={`Enter ${field.displayName}`}
                                register={register}
                                openJSEditor={openJSEditorLayout}
                                name={`parameters.${field.name}.[${index}]`}
                                config={actionExecutionScriptConfig({
                                  type: "NUMBER",
                                  key: `parameters.${field.name}.${index}.value`,
                                })}
                                editorLocation={`parameters.${field.name}.${index}.value`}
                              />
                            </>
                          ) : field.fieldType[
                              selectedAction?.parameters?.filters[index]?.id
                            ] === "LIST_OF_TEXT" ? (
                            <>
                              <ModalSmartInputTextField
                                type={
                                  selectedAction?.parameters?.filters[index]
                                    ?.value?.type || "literal"
                                }
                                dataType={
                                  (selectedAction?.[field.name]?.[
                                    index
                                  ]?.value?.value?.includes("${") &&
                                    selectedAction?.[field.name]?.[
                                      index
                                    ]?.value?.value?.endsWith("}")) ||
                                  selectedAction?.[field.name]?.[index]?.value
                                    ?.type == "script"
                                    ? DataFieldType.TEXT
                                    : IComponentType.DROPDOWN
                                }
                                control={control}
                                step="any"
                                avoidOverflow
                                minWidth="100%"
                                errors={errors}
                                onChangeFunc={(e: any) =>
                                  handleListOfTextForFilter(
                                    e,
                                    `filters[${index}].value`
                                  )
                                }
                                isSmartField={true}
                                apiBodyPayload={actionConditionPayload}
                                value={
                                  // join the array by , and set it as string
                                  selectedAction?.parameters?.[field.name]?.[
                                    index
                                  ]?.value?.value?.split(",") || []
                                }
                                placeholder={`Enter ${field.displayName}`}
                                register={register}
                                openJSEditor={openJSEditorLayout}
                                options={
                                  field.fieldMetadata[
                                    selectedAction?.parameters?.filters[index]
                                      ?.id
                                  ].properties?.options
                                    ? field.fieldMetadata[
                                        selectedAction?.parameters?.filters[
                                          index
                                        ]?.id
                                      ].properties?.options.map(
                                        (option: any) => ({
                                          value: option.key
                                            ? option.key
                                            : option,
                                          label: option.label
                                            ? option.label
                                            : option,
                                        })
                                      )
                                    : []
                                }
                                name={`parameters.${field.name}.[${index}]`}
                                config={actionExecutionScriptConfig({
                                  type: "TEXT",
                                  key: `parameters.${field.name}.${index}.value`,
                                })}
                                editorLocation={`parameters.${field.name}.${index}.value`}
                                isMulti={field.otherDetails?.isMultiselect}
                              />
                            </>
                          ) : field.fieldType[
                              selectedAction?.parameters?.filters[index]?.id
                            ] === "FILE" ||
                            field.fieldType[
                              selectedAction?.parameters?.filters[index]?.id
                            ] === "IMAGE" ? (
                            <>
                              <ModalSmartInputTextField
                                type={
                                  selectedAction?.parameters?.[field.name]?.[
                                    index
                                  ]?.value?.type || "literal"
                                }
                                dataType={
                                  (selectedAction?.parameters?.[field.name]?.[
                                    index
                                  ]?.value?.value?.includes("${") &&
                                    selectedAction?.parameters?.[field.name]?.[
                                      index
                                    ]?.value?.value?.endsWith("}")) ||
                                  selectedAction?.parameters?.[field.name]?.[
                                    index
                                  ]?.value?.type == "script"
                                    ? DataFieldType.TEXT
                                    : DataFieldType.TEXT
                                }
                                control={control}
                                step="any"
                                avoidOverflow
                                minWidth="100%"
                                errors={errors}
                                onChangeFunc={(e: any) =>
                                  handleFileOrImageValueChange(
                                    e,
                                    `filters[${index}].value`
                                  )
                                }
                                isSmartField={true}
                                apiBodyPayload={actionConditionPayload}
                                value={
                                  // join the array by , and set it as string
                                  selectedAction?.parameters?.[field.name]?.[
                                    index
                                  ]?.value?.value?.join(",") ||
                                  selectedAction?.parameters?.[field.name]
                                    ?.value?.value
                                }
                                placeholder={`Enter ${field.displayName}`}
                                register={register}
                                openJSEditor={openJSEditorLayout}
                                options={
                                  field.properties?.options
                                    ? field.properties?.options.map(
                                        (option: any) => ({
                                          value: option.key
                                            ? option.key
                                            : option,
                                          label: option.label
                                            ? option.label
                                            : option,
                                        })
                                      )
                                    : []
                                }
                                name={`parameters.${field.name}.[${index}]`}
                                config={actionExecutionScriptConfig({
                                  type: "LIST_OF_TEXT",
                                  key: `parameters.${field.name}.${index}.value`,
                                })}
                                editorLocation={`parameters.${field.name}.${index}.value`}
                                isMulti={field.otherDetails?.isMultiselect}
                              />
                            </>
                          ) : field.fieldType[
                              selectedAction?.parameters?.filters[index]?.id
                            ] === "DATE" ||
                            field.fieldType[
                              selectedAction?.parameters?.filters[index]?.id
                            ] === "DATETIME" ? (
                            <>
                              <ModalSmartInputTextField
                                type={
                                  selectedAction?.parameters?.[field.name]?.[
                                    index
                                  ]?.value?.type || "literal"
                                }
                                dataType={
                                  (typeof selectedAction?.parameters?.[
                                    field.name
                                  ]?.[index]?.value?.value === "string" &&
                                    selectedAction?.parameters?.[field.name]?.[
                                      index
                                    ]?.value?.value?.includes("${") &&
                                    selectedAction?.parameters?.[field.name]?.[
                                      index
                                    ]?.value?.value?.endsWith("}")) ||
                                  selectedAction?.parameters?.[field.name]?.[
                                    index
                                  ]?.value?.type == "script"
                                    ? DataFieldType.TEXT
                                    : DataFieldType.DATETIME
                                }
                                control={control}
                                step="any"
                                avoidOverflow
                                minWidth="100%"
                                errors={errors}
                                onChangeFunc={(e: any) =>
                                  handleDateValueChange(
                                    e,
                                    `filters[${index}].value`
                                  )
                                }
                                isSmartField={true}
                                apiBodyPayload={actionConditionPayload}
                                value={
                                  selectedAction?.parameters?.[field.name]?.[
                                    index
                                  ]?.value?.value
                                }
                                placeholder={`Enter ${field.displayName}`}
                                register={register}
                                openJSEditor={openJSEditorLayout}
                                name={`parameters.${field.name}.[${index}].value`}
                                config={actionExecutionScriptConfig({
                                  type: "DATE",
                                  key: `parameters.${field.name}.${index}.value`,
                                })}
                                editorLocation={`parameters.${field.name}.${index}.value`}
                              />
                            </>
                          ) : field.fieldType[
                              selectedAction?.parameters?.filters[index]?.id
                            ] === "TIME" ? (
                            <>
                              <ModalSmartInputTextField
                                type={
                                  selectedAction?.parameters?.[field.name]?.[
                                    index
                                  ]?.value?.type || "literal"
                                }
                                dataType={
                                  (selectedAction?.[
                                    field.name
                                  ]?.value?.value?.includes("${") &&
                                    selectedAction?.[
                                      field.name
                                    ].value?.value?.endsWith("}")) ||
                                  selectedAction?.[field.name]?.value?.type ==
                                    "script"
                                    ? DataFieldType.TEXT
                                    : DataFieldType.TIME
                                }
                                control={control}
                                step="any"
                                avoidOverflow
                                minWidth="100%"
                                errors={errors}
                                onChangeFunc={(e: any) =>
                                  handleDateValueChange(e, field.name)
                                }
                                isSmartField={true}
                                apiBodyPayload={actionConditionPayload}
                                value={
                                  selectedAction?.parameters?.[field.name]?.[
                                    index
                                  ]?.value?.value
                                }
                                placeholder={`Enter ${field.displayName}`}
                                register={register}
                                openJSEditor={openJSEditorLayout}
                                name={`parameters.${field.name}.[${index}].value`}
                                config={actionExecutionScriptConfig({
                                  type: "DATE",
                                  key: `parameters.${field.name}`,
                                })}
                                editorLocation={`parameters.${field.name}`}
                              />
                            </>
                          ) : field.fieldType[
                              selectedAction?.parameters?.filters[index]?.id
                            ] === "BOOLEAN" ? (
                            <>
                              <ModalSmartInputTextField
                                type={
                                  selectedAction?.parameters?.[field.name]?.[
                                    index
                                  ]?.value?.type || "literal"
                                }
                                dataType={
                                  (selectedAction?.[
                                    field.name
                                  ]?.value?.value?.includes("${") &&
                                    selectedAction?.[
                                      field.name
                                    ].value?.value?.endsWith("}")) ||
                                  (selectedAction?.[
                                    field.name
                                  ]?.value?.value?.includes("${") &&
                                    selectedAction?.[field.name].value?.type ==
                                      "script")
                                    ? DataFieldType.TEXT
                                    : IComponentType.DROPDOWN
                                }
                                control={control}
                                step="any"
                                avoidOverflow
                                minWidth="100%"
                                errors={errors}
                                onChangeFunc={(e: any) =>
                                  handleBooleanValueChange(
                                    e,
                                    `${field.name}.[${index}].value`
                                  )
                                }
                                isSmartField={true}
                                apiBodyPayload={actionConditionPayload}
                                value={
                                  [
                                    selectedAction?.parameters?.[field.name]?.[
                                      `${index}`
                                    ]?.value?.value,
                                  ] || []
                                }
                                placeholder={`Enter ${field.displayName}`}
                                register={register}
                                openJSEditor={openJSEditorLayout}
                                options={[
                                  {
                                    label: "True",
                                    value: true,
                                  },
                                  {
                                    label: "False",
                                    value: false,
                                  },
                                ]}
                                name={`parameters.${field.name}.[${index}]`}
                                config={actionExecutionScriptConfig({
                                  type: "BOOLEAN",
                                  key: `parameters.${field.name}`,
                                })}
                                editorLocation={`parameters.${field.name}`}
                                isMulti={field.otherDetails?.isMultiselect}
                              />
                            </>
                          ) : field.fieldType[
                              selectedAction?.parameters?.filters[index]?.id
                            ] === "ONLY_DATE" ? (
                            <>
                              {console.log(
                                ">>>>",
                                typeof selectedAction?.parameters?.[
                                  field.name
                                ]?.[index]?.value?.value
                              )}
                              <ModalSmartInputTextField
                                type={
                                  selectedAction?.parameters?.[field.name]?.[
                                    index
                                  ]?.value?.type || "literal"
                                }
                                dataType={
                                  (typeof selectedAction?.parameters?.[
                                    field.name
                                  ]?.[index]?.value?.value === "string" &&
                                    selectedAction?.parameters?.[field.name]?.[
                                      index
                                    ]?.value?.value?.includes("${") &&
                                    selectedAction?.parameters?.[field.name]?.[
                                      index
                                    ]?.value?.value?.endsWith("}")) ||
                                  selectedAction?.parameters?.[field.name]?.[
                                    index
                                  ]?.value?.type == "script"
                                    ? DataFieldType.TEXT
                                    : DataFieldType.ONLY_DATE
                                }
                                control={control}
                                step="any"
                                avoidOverflow
                                minWidth="100%"
                                errors={errors}
                                onChangeFunc={(e: any) =>
                                  handleDateValueChange(
                                    e,
                                    `filters[${index}].value`
                                  )
                                }
                                isSmartField={true}
                                apiBodyPayload={actionConditionPayload}
                                value={
                                  selectedAction?.parameters?.[field.name]?.[
                                    index
                                  ]?.value?.value
                                }
                                placeholder={`Enter ${field.displayName}`}
                                register={register}
                                openJSEditor={openJSEditorLayout}
                                name={`parameters.${field.name}.[${index}].value`}
                                config={actionExecutionScriptConfig({
                                  type: "DATE",
                                  key: `parameters.${field.name}.${index}.value`,
                                })}
                                editorLocation={`parameters.${field.name}.${index}.value`}
                              />
                            </>
                          ) : null}
                        </FilterWrapper>
                      )
                    )}

                    <AddNewLink onClick={handleAddFilter}>
                      <AiOutlinePlus />
                      Add Filter
                    </AddNewLink>
                  </>
                )
              ) : null}
            </Container>
          </>
        );
      })}
      {dataPicker?.isOpened && <DatafieldSelector setValue={setValue} />}
      {scriptEditorOpen && <ZJSEditorLayout />}
    </div>
  );
};

export default DynamicForm;
