import { AxiosResponse } from 'axios';
import HttpUtil from 'config/AxiosConfig';
import { ZorpResponse } from 'providers/data/models/ZorpResponse';

export class TaskFlowService {
  public static listActiveWorkflows(): Promise<ZorpResponse | undefined> {
    return HttpUtil.taskInstance
      .post('/task/type/list', {
        showInternalCreationAlso: true,
      })
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }

  public static listDraftWorkflows(): Promise<ZorpResponse | undefined> {
    return HttpUtil.builderInstance.get('/workflows').then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static getWorkflow(workflowId: string): Promise<ZorpResponse | undefined> {
    return HttpUtil.builderInstance.get(`/workflow/${workflowId}`).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static saveTaskFlow(taskFlow: any, taskType: string): Promise<ZorpResponse | undefined> {
    return HttpUtil.builderInstance.put(`/workflow/${taskType}`, taskFlow).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static deleteTaskFlow(taskType: string): Promise<ZorpResponse | undefined> {
    return HttpUtil.builderInstance.delete(`/workflow/${taskType}`).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static fetchActiveWorkflows(): Promise<ZorpResponse | undefined> {
    return HttpUtil.taskInstance
      .post('/task/type/list', {
        showInternalCreationAlso: true,
        isActive:true,
        preview: false
      })
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }

  public static saveTaskState(
    accountId: string,
    taskType: string,
    taskTypeName: string,
    stateId: string,
    stateName: string
  ): Promise<ZorpResponse | undefined> {
    const header: any = {
      visibility: true,
      nodes: [
        {
          nodeId: 'header_node',
          key: 'task_header_info',
          nodePosition: 'initial',
          cluster: 'c1',
          type: 'HEADER',
          component: {
            version: '1.0.0',
            shape: 'HEADER',
            params: [
              {
                key: 'taskId',
                label: {
                  color: '#ffffff',
                  fontSize: 12,
                  isVisible: false,
                  val: 'Task Id',
                },
                value: {
                  color: '#ffffff',
                  fontSize: 12,
                  isVisible: false,
                  val: '${task.taskId}',
                },
              },
              {
                key: 'taskType',
                label: {
                  color: '#ffffff',
                  fontSize: 12,
                  isVisible: false,
                  val: 'Task Type',
                },
                value: {
                  color: '#ffffff',
                  fontSize: 10,
                  isVisible: true,
                  val: '${task.taskTypeDisplayName}',
                },
              },
              {
                key: 'status',
                label: {
                  color: '#ffffff',
                  fontSize: 13,
                  isVisible: false,
                  val: 'Status',
                },
                value: {
                  color: '#ffffff',
                  fontSize: 10,
                  isVisible: true,
                  val: '${task.taskStatusName}',
                },
              },
            ],
          },
        },
      ],
    };

    const payload = {
      version: '1.0.0',
      taskType: taskType,
      taskTypeDisplayName: taskTypeName,
      accountId: accountId,
      status: stateId,
      statusName: stateName,
      taskId: 'sample',
      header: header,
      footer: { visibility: false, actions: [] },
      body: [],
    };
    return HttpUtil.builderInstance
      .put(`/workflow/${taskType}/state/${stateId}`, payload)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }

  public static publishWorkflow(taskType: string): Promise<ZorpResponse | undefined> {
    return HttpUtil.builderInstance.post(`/workflow/${taskType}/publish`, {}).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static getDocApiByTaskType(taskType: string): Promise<ZorpResponse | undefined> {
    return HttpUtil.builderInstance.get(`/tasktype/${taskType}/apidocs`).then((response:AxiosResponse)=>{
      return response.data as ZorpResponse;
    })
  }
  
  public static changeWorkflowStatus(workflowId: string, status: boolean): Promise<ZorpResponse | undefined> {
    // return HttpUtil.taskInstance.patch(`/task/type/flow/save`, updatedStatus).then((response: AxiosResponse) => {
    //   return response.data as ZorpResponse;
    // });

    return HttpUtil.builderInstance.patch(`/workflow/${workflowId}/status`, {status}).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static duplicateWorkflow(workflowId: string): Promise<ZorpResponse | undefined> {
    return HttpUtil.builderInstance.post(`/workflow/${workflowId}/duplicate`, {}).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static getWorkflowTemplates(): Promise<ZorpResponse | undefined> {
    return HttpUtil.builderInstance.get('/template/home').then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static publishWorkflowAsTemplate(templateId: string, body: any): Promise<ZorpResponse | undefined> {
    return HttpUtil.builderInstance.post(`/template/${templateId}/publish`, body).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static publishTemplateAsWorkflow(templateId: string): Promise<ZorpResponse | undefined> {
    return HttpUtil.builderInstance.post(`/template/${templateId}/workflow`, {}).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static checkWorkflowTemplate(workflowId: string): Promise<ZorpResponse | undefined> {
    return HttpUtil.builderInstance.get(`/template/workflow/${workflowId}`).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }
}
