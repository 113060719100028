import { ZTextField } from './commonComponents.styles';
import { HookIPFieldPropType } from './interfaces/hookComponents.types';

const ZInput = ({ register,onChange=() => {}, name, minHeight, type, validations, isReadOnly,placeholder,maxLength,autoFocus=false }: HookIPFieldPropType) => {
  return (
    <ZTextField
      sx={{minHeight: minHeight || 'auto'}}
      fullWidth
      autoFocus={autoFocus}
      inputProps={{
        autocomplete: 'new-password',
        form: {
          autocomplete: 'off',
        },
        maxLength: maxLength ?? undefined
      }}
      
      type={type}
      disabled={isReadOnly}
      {...register(name, {
        ...validations,
        onChange: (e) => onChange(name,e.target.value)
      })}
      placeholder={placeholder}
    />
  );
};

export default ZInput;
