import DropdownItem from "./DropdownItem";
import { BsPhone } from "react-icons/bs";
import { TiArrowShuffle } from "react-icons/ti";

const liCls =
  "p-3 border text-gray-700 hover:text-white hover:bg-indigo-700 cursor-pointer";

const nodes = [
  {
    icon: <BsPhone size={"2rem"} color={"#C2C9D1"} />,
    heading: "Screen Node",
    info: "Create a Step required for your workflow that is to be completed by your user.",
    nodeType: "zorpState",
  },
  {
    icon: <TiArrowShuffle size={"2rem"} color={"#C2C9D1"} />,
    heading: "Decision Node",
    info: "Create a decision step to control the flow of your workflow based on certain conditions.",
    nodeType: "zorpDecisionNode",
  },
];

const SelectNodeDropdown = ({addNodeOnClick}) => {
  return (
    <div className="h-auto w-96 absolute z-10">
      <ul
        className="text-left"
        style={{ marginLeft: "-13.5rem", marginTop: "10px",width:"26rem" }}
      >
        {nodes.map((node) => (
          <DropdownItem
            icon={node.icon}
            heading={node.heading}
            info={node.info}
            key={Math.random()}
            nodeType={node.nodeType}
            addNodeOnClick={addNodeOnClick}
          />
        ))}
      </ul>
    </div>
  );
};

export default SelectNodeDropdown;
