import React, { useRef } from "react";
import GridLayout from "./GridLayout";
import "./styles.css";
import Draggable from "react-draggable";

const DashboardGrid = ({children}) => {
 
  return (
    <div className="dashboard">
      <GridLayout children = {children} />
    </div>
  );
};

export default DashboardGrid;
