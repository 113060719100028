import ZInput from "views/commonComponents/ZTextInput"
import { Label } from "../DataField.styles"
import { textDefaultValueErrorSchema } from "../DataFieldErrorSchema"

type TextFieldTypeProp = {
  register:any;
  errors?:any;
  defaultValueName: string,
  disabled?: boolean | undefined
}


const TextField = ({register,defaultValueName,errors}:TextFieldTypeProp) => {
  return (
    <>
      <Label style={{marginBottom:"6px"}}>
        Default Value
      </Label>
      <ZInput autoFocus type="text" name={defaultValueName} register={register} validations={textDefaultValueErrorSchema} />
      {/* <ErrorMessage errors={errors} fieldName={defaultValueName}/> */}
    </>
  )
}

export default TextField