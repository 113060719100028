import { cloneDeep } from "lodash";
import { AiFillWarning } from "react-icons/ai";

type IFilterErrorMessage = {
  errors: any;
  name: string;
}

const FilterErrorMessage = ({ errors, name }: IFilterErrorMessage) => {

  // this function get value from error object using the name key
  // when we pass index and child property name in the name key, we can't access message from the error, 
  // so we are using this function

  const getDescendantProp = (obj: any, path: any) => {
    const splitedValue = path.split(/[.[]['"]?/);
    let duplicateObject = cloneDeep(obj);
    while (splitedValue.length && duplicateObject) {
      duplicateObject = duplicateObject[splitedValue.shift().replace(/['"]?]$/, '')];
    }
    return duplicateObject;
  };

  const getErrorObj = getDescendantProp(errors, name);
  const errorMessage = getErrorObj?.message;
  return (
    <div className="  mt-1 flex">
      {getDescendantProp(errors, name)?.message &&
      <>
      <AiFillWarning
        size={"1rem"}
        fill='red'
        stroke="red"
        color="red"
        style={{ display: "inline", marginRight: "4px" }}
      />
      <p className="text-xs text-[#000000] opacity-50 ">{errorMessage}</p>
      </>
  }
    </div>
  )
}

export default FilterErrorMessage