import { useState } from 'react';
import { ABListWrapper, ABNameText, IconWrapper, VerticalAlignWrapper } from 'views/automationBuilder/automationBuilder.styles';
import { DELETE_AUTOMATION_BLOCK, DELETE_AUTOMATION_CONFIRMATION } from 'constants/CommonMessages';
import { BiCopy, BiEdit, BiCopyAlt } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch } from 'react-redux';
import DeletionConfirmationDialog from 'utils/DeletionConfirmationDialog';
import useAutomation from 'views/automationBuilder/hooks/useAutomation';
import { deleteAutomation, setSelectedAutomation } from 'views/automationBuilder/reducer/automationReducer';
import { IAutomation } from 'views/automationBuilder/types/ABType';
import InfoIcon from 'views/workflows/Info/InfoIcon';
import { setCopiedAction } from 'views/actions/Action';
import { generateId } from 'utils/Utils';
import useAction from 'views/actions/useAction';
import { cloneDeep } from 'lodash';


type IAutomationList = {
    automationDetails: IAutomation,
    selected: boolean,
    editAutomation: (automationName: string) => void;
    automationClick: () => void;
}

interface ICopiedAutomation extends IAutomation {
    originalId?:string,
}

const AutomationList = ({ automationDetails, selected, editAutomation, automationClick }: IAutomationList) => {
    const [confirmDeleteAutomation, setConfirmDeleteAutomation] = useState<boolean>(false);
    const { selectedAutomation, clearRightSide } = useAutomation();
    const dispatch = useDispatch();
    const { copiedAction } = useAction();
    const automationDelete = () => {
        dispatch(deleteAutomation({ automationId: automationDetails.id }));

        // Clear AB Part in Middle and Right (trigger, guards, condition and action)
        // clears when we are deleting the selected Automation
        if (selectedAutomation?.id == automationDetails.id) {
            dispatch(setSelectedAutomation(null));
            clearRightSide();
        }
    }

    const copyAutomation = (e:any) =>{
        e.stopPropagation();
        const automationData:ICopiedAutomation = cloneDeep(automationDetails);
        automationData.originalId = automationData.id;
        automationData.id = generateId(6);
        automationData.name = automationData.name + '_copy';
        dispatch(setCopiedAction(automationData))
        localStorage.setItem("copiedAction", JSON.stringify(automationData));
    }

    return (
        <>
            <ABListWrapper selected={selected} onClick={automationClick}>
            <VerticalAlignWrapper applyMargin={false}>
                    <ABNameText selected={selected}>
                        {automationDetails.name}
                    </ABNameText>
                    {automationDetails?.errors?.length > 0 && (
                        <InfoIcon type="error" item={automationDetails?.errors} />
                    )}
                </VerticalAlignWrapper>
                <VerticalAlignWrapper applyMargin={false}>
                    <IconWrapper>
                        {
                            copiedAction?.originalId == automationDetails.id
                                ?
                                <BiCopyAlt
                                    color={selected ? '#fff' : '#616569'}
                                    className="pointer mr-1 ml-1" onClick={(e) => {
                                        copyAutomation(e);
                                    }}
                                />
                                :
                                <BiCopy
                                    color={selected ? '#fff' : '#616569'}
                                    className="pointer mr-1 ml-1" onClick={(e) => {
                                        copyAutomation(e);
                                    }} />
                        }


                    </IconWrapper>
                    <IconWrapper>
                        <BiEdit onClick={(e) => {
                            e.stopPropagation();
                            editAutomation(automationDetails.name);
                        }} color={selected ? '#fff' : '#616569'} />
                    </IconWrapper>

                    <IconWrapper>
                        <RiDeleteBin6Line
                            color={selected ? '#fff' : '#616569'}
                            onClick={(e) => {
                                e.stopPropagation();
                                setConfirmDeleteAutomation(true);
                            }} />

                    </IconWrapper>
                </VerticalAlignWrapper>

            </ABListWrapper>

            <DeletionConfirmationDialog
                id={'automationDelete'}
                deletionTitle={DELETE_AUTOMATION_BLOCK}
                deletionText={DELETE_AUTOMATION_CONFIRMATION}
                isOpen={confirmDeleteAutomation}
                onConfirmDelete={() => {
                    setConfirmDeleteAutomation(false);
                    automationDelete();
                }}
                onClose={() => {
                    setConfirmDeleteAutomation(false);
                }}
            />
        </>
    );
}

export default AutomationList;
