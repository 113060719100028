export const displayNameErrorSchema = {
    required:"Name is Required", 
    minLength:{value:3,message:"Name must be greater than 3 characters"},
    pattern: {value:/^[a-zA-Z0-9_-\s]+$/g,message:"You can only use alphabets, numbers, underscore(_) and hyphen(-) and white space"}
}   

export const idErrorSchema = {
    required:"Id is Required", 
    minLength:{value:3,message:"Id must be greater than 3 characters"},
    pattern: {value:/^[a-zA-Z0-9_-]+$/g,message:"You can only use alphabets, numbers, underscore(_) and hyphen(-)"}

}         

export const columnNameErrorSchema = {
    required:"Column Name is Required", 
    minLength:{value:3,message:"Column name must be greater than 3 characters"},
    // value can only have alphabets, numbers, underscore(_) and hyphen(-) and space
    pattern: {value: /^[a-zA-Z0-9_-\s]+$/g, message: "You can only use alphabets, numbers, underscore(_), hyphen(-), and space"}

}

export const dataFieldNameErrorSchema = {
    required:"Data field Name is Required", 
    minLength:{value:3,message:"Data Field name must be greater than 3 characters"}
}
export const keyNameErrorSchema = {
    required:"Value is Required", 
    minLength:{value:3,message:"Value must be greater than 3 characters"}
}

export const numberDefaultValueErrorSchema = {
    // required:"Default value is required", 
    valueAsNumber:true
}

export const textDefaultValueErrorSchema = {
    // required:"Default value is required", 
}