import { useEffect, useState } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { setActiveSubField } from 'views/workflows/reducers/workflowAction';
import { AddSubfieldText, CollectionContainer, Input, Label, SelectType } from '../DataField.styles';
import { DataFieldType, ListOfValuesTypeProp } from '../DataField.types';
import useWorkflow from 'views/workflows/hooks/useWorkFlow';
import { dataFieldNameErrorSchema, displayNameErrorSchema, idErrorSchema } from '../DataFieldErrorSchema';

const Collection = ({
  register,
  watch,
  control,
  errors,
  getValues,
  clearErrors,
  setValue,
  isCreateView = false,
  isEditable = true,
}: ListOfValuesTypeProp) => {
  const [selectedType, setSelectedType] = useState('singleSelect');
  const dispatch = useDispatch();
  const { activeSubField, selectedNode, dataFieldType, activeEditField, showTaskCardConfig} = useWorkflow();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'subFields',
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedType((event.target as HTMLInputElement).value);
    clearErrors();
  };

  // This to make sure that the subfield of correct dataType is appended.
  // Currently it happens only for Create View as the creat modal can only be opened
  /// from Flutter. If the creatView is true, we append a subField of datatype specified
  // from flutter or if it opened from builder, we set it to TEXT

  useEffect(() => {
    // If create is opened in flutter web , we dont want any sub field to be appened.
    if(isCreateView && !selectedNode && !showTaskCardConfig.show) {
      append({
        type: dataFieldType.subFieldDataType || DataFieldType.TEXT,
        name: '',
        isCreateTimeField: true
      });
    } else {
      if ((selectedNode || showTaskCardConfig.show) && dataFieldType.subFieldDataType) {
        append({
          type: dataFieldType.subFieldDataType || DataFieldType.TEXT,
          name: '',
          isCreateTimeField: true
        });
      }
    }
  }, [dataFieldType]);

  // TODO: Make arguments as Object with {name, type, fieldName } as keys
  // children is explicity of LOCATION dataType to get the default value.
  const selectSubfield = (name: string, type: string, fieldName: string, index: number, isDraft: boolean,children?:[]) => {
    dispatch(setActiveSubField(null));
    setTimeout(() => {
      dispatch(
        setActiveSubField({
          name: getValues(name),
          type: getValues(type),
          register: register,
          fieldName,
          setValue: setValue,
          getValues: getValues,
          remove: remove,
          index,
          isDraft,
          watch,
          children
        })
      );
    }, 100);
  };

  return (
    <>
      {fields.map((item: any, index:number) => {
        return ( 
          <CollectionContainer
            // @ts-ignore
            variant={activeSubField?.index === index ? 'selected' : ''}
            onClick={() => {
              selectSubfield(
                `subFields.${index}.name`,
                `subFields.${index}.type`,
                `subFields.${index}`,
                index,
                (item?.isDraft === undefined || item?.isDraft === true) ? true : false,
                item.type === DataFieldType.LOCATION ? item?.children : []
              );
            }}
          >
            <div style={{ marginRight: '5px' }}>
              {/* disabled flag will depend on following:
              1. If the field is editable.
              2. If the modal is opened in flutter view.
              3. If it is already published.
              */}
              <Label> Datafield Type </Label>
              <Controller
                render={({ field: { value, onChange } }) => (
                  <SelectType
                    value={getValues(`subFields.${index}.type`)}
                    onChange={onChange}
                    placeholder='Select Data Field Type'
                    onClick={(e: any) => e.stopPropagation()}
                    disabled={!isEditable ? true : selectedNode ? true : (item?.isDraft === undefined || item?.isDraft === true) ? false : true}
                  >
                    <option value={DataFieldType.NUMBER}>Number</option>
                    <option value={DataFieldType.TEXT}>Text</option>
                    <option value={DataFieldType.LOCATION}>Location</option>
                    <option value={DataFieldType.BOOLEAN}>Boolean</option>
                    <option value={DataFieldType.DATE}>Datetime</option>
                    {/* <option value={DataFieldType.ONLY_DATE}>Date</option>
                    <option value={DataFieldType.TIME}>Time</option>
                    <option value={DataFieldType.LINKS}>Links</option> */}
                    <option value={DataFieldType.LIST_OF_TEXT}>List of Text</option>
                    <option value={DataFieldType.IMAGE}>Image</option>
                    <option value={DataFieldType.SIGNATURE}>Signature</option>
                    <option value={DataFieldType.FILE}>File</option>
                  </SelectType>
                )}
                name={`subFields.${index}.type`}
                control={control}
                defaultValue={getValues(`subFields.${index}.type`)}
              />
              {errors?.subFields && <span className='text-red-500'> {errors?.subFields[index]?.type?.message} </span>}
            </div>
            <div style={{ marginRight: '5px' }}>
              <Label> Name </Label>
              <Input
                placeholder='Data field Name'
                {...register(`subFields.${index}.displayName`, displayNameErrorSchema)}
                onClick={(e) => e.stopPropagation()}                
              />
              {errors?.subFields && (
                <span className='text-red-500 text-xs'> {errors?.subFields[index]?.name?.message} </span>
              )}
            </div>
            <div style={{ marginRight: '5px' }}>
              <Label> Id </Label>
              <Input
                placeholder='Data field Id'
                {...register(`subFields.${index}.name`, idErrorSchema)}
                onClick={(e) => e.stopPropagation()}
                disabled={!isEditable ? true : (item?.isDraft === undefined || item?.isDraft === true) ? false : true}
              />
              {errors?.subFields && (
                <span className='text-red-500 text-xs'> {errors?.subFields[index]?.name?.message} </span>
              )}
            </div>
          </CollectionContainer>
        );
      })}
      {errors.subFields && <p className='text-red-500 text-xs'>{errors.subFields.message}</p>}

      {/* We dont want uset tot add subfield in case he is in a flutter view or he has Location Datatype selected. */}

      {!selectedNode && !showTaskCardConfig.show && activeEditField?.type !== DataFieldType.LOCATION && (
        <AddSubfieldText
          onClick={() => {
            append({ type: 'TEXT', name: '', isCreateTimeField:true });
          }}
        >
          Add Sub Field
        </AddSubfieldText>
      )}
    </>
  );
};

export default Collection;
