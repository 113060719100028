import React, { useState } from "react";
import { useListContext } from "react-admin";
import styled from "styled-components";
import ZSwitch from "views/commonComponents/ZSwitch";
import { CiShare1 } from "react-icons/ci";
import { convertISODateToLocalDate, getTimeDifference } from "utils/Utils";
import { useHistory } from "react-router-dom";
import {
  FormControlLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { InfoTooltip } from "./Tootltips/InfoTooltip";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiCode, FiCopy, FiTrash2 } from "react-icons/fi";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin5Line } from "react-icons/ri";
import { BsCodeSlash } from "react-icons/bs";
import { recordRSEvent } from "utils/CommonUtils";
import LoadingScreen from "views/LoadingScreen/LoadingScreen";
import _ from "lodash";
import { AiOutlineArrowRight } from "react-icons/ai";
import { hasPermission } from "providers/auth/authUtils";
import {FiEdit} from "react-icons/fi"


type Props = {};

interface IBadge {
  status: string;
}

interface IText {
  weight: any;
  size: any;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  border: 1px solid #eaecf0;
  border-radius: 4px;
  margin-bottom: 16px;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Heading = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: "#1D2939";
`;

const Text = styled.span<IText>`
  font-weight: ${(props) => props.weight};
  font-size: ${(props) => props.size};
`;

const User = styled.a<any>`
  font-weight: 600;
  font-size: 14px;
  color: ${(props:{hasLink ?: boolean}) => props?.hasLink ? props.theme.color.primary : ""};
  text-decoration: none;
  margin-right: 8px;

  svg {
    display: inline;
    color: ${(props) => props.theme.color.primary}
  }
`;

// create a component called badge that'll render a <span> tag with some styles and take "status" as props
const Badge = styled.span<IBadge>`
  background-color: ${(props) =>
    props.status === "Active" ? "#ECFDF3" : (props.status === "Draft" ?"#F5F5DC;" : "#FFF4ED;")};
  border-radius: 16px;
  color: ${(props) => (props.status === "Active" ? "#027A48" : "#B93815;")};
  padding: 4px 12px;
  font-weight: 500;
`;

const SwitchWrapper = styled.div``;

interface IMenuItems {
  label: string;
  onClick: Function;
  visibility: boolean;
  icon: React.ReactElement;
}

const CustomWorkflowContent = (props: any) => {
  const { data, id } = props;

  const [anchorEl, setAnchorEl] = useState<null | SVGElement>(null);
  const history = useHistory();

  const onOpenWorkflowClick = (e: any, record: any) => {
    console.log(">>>>>>>", record);
    recordRSEvent("workflowlist_row_click", {
      context: "builder_listing",
      workflow_id: record.id,
      workflow_name: record.name,
    });
    if (record?.isBuilderEditable === false) {
      alert("Editing is disabled for this workflow");
      return "";
    } else history.push(`/workflows/${record.id}/show`);
  };

  const menuItems: IMenuItems[] = [
    {
      label: "Open Workflow",
      onClick: (e: any, record: any) => onOpenWorkflowClick(e, record),
      visibility: true,
      icon: <FiEdit size={"2rem"} />,
    },
    {
      label: "API Docs",
      onClick: (e: any, record: any) => props.handleAPIClick(e, record),
      visibility: true,
      icon: <FiCode size={"2rem"} />,
    },
    {
      label: "Duplicate Workflow",
      onClick: (e: any, record: any) => props.copyWorkflow(e, record),
      visibility: hasPermission("workflow", "create"),
      icon: <FiCopy size={"2rem"} />,
    },
    {
      label: "Delete Unsaved Changes",
      onClick: (e: any, record: any) => props.deleteWorkflow(e, record),
      visibility: data[id].status === "Draft" && hasPermission("workflow", "remove"),
      icon: <FiTrash2 size={"2rem"} />,
    },
  ];

  const handleMenuItemClick = (e: any, callback: Function) => {
    e.preventDefault();
    callback();
    handleClose(e);
  };

  const handleClick = (event: React.MouseEvent<SVGElement>) => {
    console.log("called");
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e:any) => {
    e.stopPropagation()
    setAnchorEl(null);
  };

  const isEnabled = (data[id].isActive === true) || data[id].isActive === undefined || data[id].isActive === null;
  const isDraft = data[id].status === "Draft";

  const open = Boolean(anchorEl);
  return (
    <>
      <div>
        <FlexDiv>
          <Heading>
            {data[id].taskTypeDisplayName} ({data[id].taskType})
          </Heading>
          {isEnabled && <Badge status={data[id].status}>
            {/* {data[id].isActive === true ? (data[id].status === "Draft" ? "Draft" : "Published") : "Inactive"} */}
            {isDraft === true ? "Draft" : "Published"}
          </Badge>}
          {!isEnabled && <Badge status={data[id].status}>
            {/* {data[id].isActive === true ? (data[id].status === "Draft" ? "Draft" : "Published") : "Inactive"} */}
            Disabled
          </Badge>}
        </FlexDiv>

        <div style={{ marginTop: "8px" }}>
          <Text weight={400} size={"14px"}>
            {" "}
            {
              data[id].updatedOn  ? (
                <span> Updated {getTimeDifference(data[id].updatedOn) || "unknown"} ago by{" "} </span>
              ) : (
                <span> Updated unknown seconds ago by{" "} </span>
              )
            }
            
          </Text>
          <User
            href={`/#/users/${data[id].updatedBy?.userId}/show`}
            target="_blank"
            hasLink = {data[id].updatedBy?.name ? true : false}
            as={data[id].updatedBy?.name ? "a" : "span"}
          >
            {" "}
            {data[id].updatedBy?.name || "Unknown"}{" "}
            {
              data[id].updatedBy?.name && (
                <span>
                {" "}
                <CiShare1 strokeWidth={2} />{" "}
                </span>
              )
             
            }
            
          </User>
          {data[id].createdOn && (
            <>
              <Text weight={400} size={"14px"}>
                Published on {convertISODateToLocalDate(data[id].createdOn)} by{" "}
              </Text>
              <User
                href={`/#/users/${data[id].publishedBy?.userId}/show`}
                target="_blank"
                hasLink = {data[id].publishedBy?.name ? true : false}
                as={data[id].publishedBy?.name ? "a" : "span"}
              >
                {data[id].publishedBy?.name || "Unknown"}
                <span>
                  {" "}
                  <CiShare1 strokeWidth={2} />{" "}
                </span>{" "}
              </User>
            </>
          )}
        </div>
      </div>
      <div>
        <FlexDiv>
          <FormControlLabel
            label={
              <p
                style={{
                  color: "#3054B9",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                {["Active", "Draft"].includes(data[id].status)
                  ? "Enabled"
                  : "Disabled"}
              </p>
            }
            control={
              <InfoTooltip
                title={data[id].status === "Draft" ? "Not Allowed" : ""}
              >
                <SwitchWrapper
                  onClick={(e: any) => {
                    e.stopPropagation();
                  }}
                  as="button"
                >
                  <ZSwitch
                    checked={["Active", "Draft"].includes(data[id].status)}
                    onChange={(e: any) => props.onSwitchChange(e, data[id])}
                    disabled={data[id].status === "Draft"}
                  />
                </SwitchWrapper>
              </InfoTooltip>
            }
          />
          <div
            style={{
              right: "18px",
              background: "#F2F4F7",
              padding: "2px",
              borderRadius: "4px",
            }}
            className="node__contextMenu"
          >
            <BsThreeDotsVertical
              size="1.5rem"
              color="#494949"
              className="node__contextMenu"
              onClick={(e: any) => {
                e.stopPropagation();
                handleClick(e);
              }}
            />

            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={(e) => handleClose(e)}
            >
              
                {menuItems.map((option: IMenuItems, index: number) => (
                  <div key={option.label}>
                    {
                      <MenuItem
                        disabled={!option.visibility}
                        className="node__menuItem"
                        key={option.label}
                        onClick={(e) => {
                          handleMenuItemClick(e, () =>
                            option.onClick(e, data[id])
                          );
                        }}
                        sx={{
                          marginBottom:
                            index !== menuItems.length - 1 ? "16px" : "",
                        }}
                      >
                        <>
                          <ListItemIcon> {option.icon} </ListItemIcon>
                          <ListItemText>
                            <Text size="16px" weight={"500"}>
                              {" "}
                              {option.label}{" "}
                            </Text>
                          </ListItemText>
                        </>
                      </MenuItem>
                    }
                  </div>
                ))}
              
            </Menu>
          </div>
        </FlexDiv>
        <div style={{ marginTop: "10px", display: "flex", float: "right" }}>
          <User onClick={(e:any) => onOpenWorkflowClick(e, data[id])}>
            {" "}
            Open Workflow{" "}
            <span>
              {" "}
              <AiOutlineArrowRight strokeWidth={2} />{" "}
            </span>{" "}
          </User>
        </div>
      </div>
    </>
  );
};

export default CustomWorkflowContent;
