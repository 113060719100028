import React, { FunctionComponent, useState, useEffect } from "react";
import NewComment from "./NewComment";
import SingleComment, { CommentType } from "./SingleComment";
import { BffService } from "providers/data/services/BffService";
import { generateId } from "utils/Utils";
import useLoggedInUser from "hooks/useLoggedInUser";
import { UserService } from "providers/data/services/UserService";
import { AxiosResponse } from "axios";
import { EntityService } from "providers/data/services/EntityService";
import _ from "lodash";
import notification from "notifications/notifications";

export const ZComment: FunctionComponent<any> = ({ config }) => {
  const [comments, setComments] = useState<CommentType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const user = useLoggedInUser();
  const [users, setUsers] = useState<any[]>([]);

  useEffect(() => {
    // Parse the comments from the config value, or initialize as an empty array if it's not present
    let comments = JSON.parse(config?.value?.val || "[]");

    // Check if any of the comments have mentions using a regular expression
    let hasMentions = false;
    for (const comment of comments) {
      if (comment?.text?.match(/@([\w\s]+)(?=\s|$)/g)){
        hasMentions = true;
        break;
      }
    }

    // If there are mentions, fetch all account users using the UserService
    if (hasMentions) {
      UserService.allAcctUsers().then((u: any) => {
        setUsers(u?.data?.users);
      });
    }

    // Set the comments and users state variables
    setComments(comments);
  }, [config]);

  const setUsersData = (users: any) => {
    setUsers(users);
  }

  let isVisible = config?.isVisible ?? true;

  const handleNewComment = (
    text: any,
    parentId: string,
    callback: Function
  ) => {
    // Add code to handle new comment
    if (text.trim() === "") {
      setError("Comment cannot be empty");
      return;
    }

    function getTaggedUsers(comment: string) {
      // Extract all possible @mentions from the comment text.
      const possibleMentions = comment.match(/@([\w\s]+)(?=\s|$)/g) || [];

      // Remove @ and extra spaces from the mentions.
      const mentions = possibleMentions.map((mention) =>
        mention.replace("@", "").trim()
      );

      const taggedUsers = [];

      for (let mention of mentions) {
        // Check if the mention matches any username in the users array.
        const user = users?.find((user) => mention.includes(user.name));
        if (user) {
          taggedUsers.push(_.pick(user, ['userId', 'name', 'email', 'phone', 'fcmToken']));
        }
      }

      return taggedUsers;
    }

    const taggedUsers = getTaggedUsers(text);

    // check if the parent id is empty
    // if not, find the parent comment and add the new comment to the replies
    // if empty, add the new comment to the comments array
    let updatedComments: Array<CommentType>;
    if (parentId === "") {
      const newComment: CommentType = {
        id: generateId(6),
        text: text,
        author: {
          id: user?.sub as string,
          username: user?.name as string,
        },
        createdAt: new Date().toISOString(),
        replies: [],
        taggedUsers: taggedUsers,
      };

      updatedComments = [...comments, newComment];
    } else {
      // find the comment which matches the parent id
      // add the new comment to the replies array
      const parentComment = comments.find((comment) => comment.id === parentId);
      if (parentComment) {
        parentComment.replies = parentComment.replies || [];
        const newComment: CommentType = {
          id: generateId(6),
          text: text,
          author: {
            id: user?.sub as string,
            username: user?.name as string,
          },
          createdAt: new Date().toISOString(),
          replies: [],
          taggedUsers: taggedUsers,
        };
        parentComment.replies.push(newComment);
      }
      updatedComments = [...comments];
    }

    if (config.entityCategory === "TASK") {
      const updateTask = {
        taskId: config?.entityId,
        data: {
          comments: updatedComments,
        },
      };
      BffService.updateTask(updateTask).then((response) => {
        setComments(updatedComments);
        callback();
      });
    }
    else if (config.entityCategory === "TABLE") {
      const updateRecord = {
        tableType: config.entityType,
        recordId: config?.entityId,
        data: {
          comments: updatedComments,
        },
      };
      EntityService.addComment({ ...updateRecord }).then((response) => {
        setComments(updatedComments);
        callback();
      }).catch((error) => {
        console.log(error);
        notification("error", "Error", `${error?.response?.data?.message}` || `${error?.message}` || 'Comment could not be added')
        callback();
      })
    }
  };

  if (isVisible)
    return (
      <div className="col-span-4">
        {comments?.map((comment: CommentType, index: number) => (
          <div className={(index > 0 && comments[index - 1].replies.length > 0) ? "mt-2" : "0"}>
            <SingleComment
              comment={comment}
              onNewReply={handleNewComment}
              entityId={config.entityId}
              users={users}
              /**
               * check comment.author.id === prevComment.author.id. if they are same, then showUserName = false
               * if its is the first comment, then showUserName = true
               * also, if there is any reply to the prev comment, then showUserName = true
               * 
               */
              showUserName={
                index === 0 ||
                comment.author.id !== comments[index - 1].author.id ||
                comments[index - 1].replies.length > 0
              }
            />
          </div>
        ))}
        <div className={comments.length > 0 ? "0" : "0"}></div>
        <NewComment
          entityId={config.entityId}
          parentId=""
          onNewComment={handleNewComment}
          users={users}
          newMessage={true}
          onCancel={() => ""}
          setUsersData={setUsersData}
        />
      </div>
    );
  else return <div></div>;
};