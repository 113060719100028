import Box from '@mui/material/Box';
import MonitorDown from 'assests/monitor_down_arrow.svg';
import MonitorSideArrow from 'assests/side_arrow.svg';
import React, { ReactElement } from 'react';
import styled from "styled-components";

const MonitorText = styled.p`
  color: ${props => props.color || 'var(--Success-700, #027A48)'};
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

// Updated interface with ReactElement
interface ICollapsableComponent {
  imgIcon: ReactElement; // Change this line
  collapseState: boolean;
  onClick: () => void;
  title: string;
  color: string;
  hideArrow?: boolean;
}

const CollapsableComponent = ({collapseState, onClick, title, imgIcon, color, hideArrow=false}:ICollapsableComponent) => {
  return (
    <Box 
    onClick={onClick} marginTop={'24px'} display={'flex'} flexDirection={'row'}  className='pointer'>
{
!hideArrow &&
    <img style={{ paddingRight: '8px' }} src={!collapseState ? MonitorDown: MonitorSideArrow} alt='Icon' />
}

        <Box display={'flex'} height={'32px'}  borderRadius={'4px'} justifyContent={'center'} alignItems={'center'} border={' 1px solid var(--Gray-300, #D0D5DD)'} padding={'12px 6px'}>
        {imgIcon}
            {/* <img style={{ paddingRight: '8px' }} src={MonitorThisIcon} alt='Icon' /> */}
            <MonitorText color={color}>
                {title}
            </MonitorText>
        </Box>
    </Box>
  )
}

// export default CollapsableComponent
export  default React.memo(CollapsableComponent);