import { Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { TreeView as SimpleTreeView, TreeItem, TreeItemProps } from "@mui/x-tree-view";
import booleanDataType from "assests/datatype_boolean.svg";
import fileDataType from "assests/datatype_file.svg";
import imageDataType from "assests/datatype_image.svg";
import locationDataType from "assests/datatype_location.svg";
import numberDataType from "assests/datatype_number.svg";
import objectDataType from "assests/datatype_object.svg";
import signatureDataType from "assests/datatype_signature.svg";
import expandIcon from "assests/expand_icon.svg";
import collapseIcon from "assests/collapse_icon.svg";
import textDataType from "assests/datatype_text.svg";
import onlyTime from "assests/only_time.svg";
import onlyDate from "assests/ony_dte.svg";
import listDataType from "assests/datafield_list.svg";
import datafieldLot from "assests/datafield_lot.svg";
import objRelation from "assests/obj_relation.svg";
import jsCondition from "assests/js_condition.svg";
import onlyComment from "assests/datafield_comment.svg";
import testDataInvoke from "assests/test_data_invoke.svg";
import mockDataValueIcon from "assests/value_mockdata.svg";
import linkDataType from "assests/datafield_link.svg";
import { VARIABLE_TREE } from 'constants/CommonConstants';
import MockDataEditor from 'lowcode/MockDataEditor';
import notification from 'notifications/notifications';
import React, { useEffect, useState } from 'react';
import { MdOutlineSearch as SearchIcon } from "react-icons/md";
import styled from 'styled-components';
import { getTextBeforeSpacing } from 'utils/CommonUtils';
import useAutomation from 'views/automationBuilder/hooks/useAutomation';
import { copiedPreDefined } from '../state/scriptEditorState';
import Tooltip from '@mui/material/Tooltip';
import { useDispatch, useSelector } from "react-redux";
import _, { cloneDeep, isEmpty } from 'lodash';
import SingleMockDataUpdateModal from './SingleMockDataUpdateModal';
import { getValueFromJson } from 'utils/Utils';
import { DataFieldType } from 'views/DataField/DataField.types';
import { makeStyles } from '@mui/styles';
import { ComponentType } from 'components/ComponentTypeEnum';

type SingleVariable = {
  id: string;
  name: string,
  dataType?: string
};

type IStyledTreeItemProps = TreeItemProps & {
  labelInfo?: string;
  labelText: string;
  valuePath: string;
  isParentArray?: boolean;
  onMockDataClick: (value: any) => void;
};

export const WLCircularLoader = styled(CircularProgress)`
  height: 40px !important;
  width: 40px !important;
`;

const useStyles = makeStyles({
  selected: {
    backgroundColor: '#fff !important',
  },
  hover: {
    '&:hover': {
      backgroundColor: 'lightgray',
    },
  },
  '&:hover': {
    backgroundColor: 'lightgray',
  }
});


const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  border: 1px solid #eaecf0;
  border-radius: 4px;
  margin: 12px 16px;
`;

const Input = styled.input`
  width: 100%;
  font-size: 11px;
  outline: none;
  border: none;
`;

const WholeTextWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
interface LeftWholeWrapperProps {
  fullWidth?: boolean;
}

const LeftWrapper = styled.div<LeftWholeWrapperProps>`
  display: flex;
  gap: 12px;
  align-items: center;
  border: 1px solid #eaecf0;
  padding: 3px 6px;
  border-radius: 4px;
  height: 32px;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  max-width: 95%;
  margin-right: 8px;
`;

const LeftWholeWrapper = styled.div<LeftWholeWrapperProps>`
  display: flex;
  align-items: center;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '')};
`;

const StyledTreeItemRoot = styled(TreeItem)`
  margin: 0px !important;
  margin-top: 4px !important;
  margin-bottom: 4px !important;
`;

const getLogo = (dataType: string) => {
  switch (dataType) {
    case 'TEXT':
      return textDataType;
      break;

    case 'DATETIME':
      return textDataType;
      break;

    case 'OBJECT':
      return objectDataType;
      break;

    case 'LIST_OF_TEXT':
      return datafieldLot;
      break;

    case 'LIST':
      return listDataType;
      break;

    case 'NUMBER':
      return numberDataType;
      break;

    case 'RELATION':
      return objRelation;
      break;

    case 'COMMENT':
      return onlyComment;
      break;

    case 'ONLY_DATE':
      return onlyDate;
      break;

    case 'FILE':
      return fileDataType;
      break;

    case 'IMAGE':
      return imageDataType;
      break;

    case 'LINKS':
      return linkDataType;
      break;

    case 'LOCATION':
      return locationDataType;
      break;

    case 'SIGNATURE':
      return signatureDataType;
      break;

    case 'TIME':
      return onlyTime;
      break;

    case 'BOOLEAN':
      return booleanDataType;
      break;

    case 'baseObject':
      return jsCondition;
      break;

    default:
      return objectDataType;
      break;
  }
}

const StyledTreeItem = ({ labelInfo, labelText, onMockDataClick, isParentArray, valuePath, ...other }: IStyledTreeItemProps) => {
  const classes = useStyles();
  const { mockData } = useSelector((state) => state.scriptEditor);
  const mockDataValue = getValueFromJson(mockData, valuePath);
  const [disableToolTip] = useState<boolean>(isEmpty(mockDataValue?.value) || mockDataValue?.type === 'object' || mockDataValue?.type === 'array');
  const isNameStarts = labelText?.includes('- (');
  const showType = !labelText?.includes('(response') && !isParentArray;
  return (
    <>
      <StyledTreeItemRoot
        classes={{
          selected: classes.selected,
        }}
        label={
          <>
            <WholeTextWrapper>
              <LeftWholeWrapper fullWidth={isNameStarts}>
                <LeftWrapper fullWidth={isNameStarts}>
                  <img width={15} height={15} src={getLogo(labelInfo as string)} />
                  <Tooltip placement='right-start' title={<Typography color={'white'}>{labelText}</Typography>}>
                    <span style={{
                      width:'100%',
                    }}>
                      <Typography width={!isNameStarts ? 'fit-content' : '200px'} style={{
                        color: '#475467',
                        fontSize: '12px',
                        fontWeight: 400,
                        maxWidth: `${!isNameStarts ? '110px' : '200px'}`,
                      }}
                        noWrap>
                        {labelText}
                      </Typography>
                    </span>
                  </Tooltip>
                </LeftWrapper>

                {showType &&
                  <Tooltip disableHoverListener={disableToolTip} placement='right-start' title={<Typography color={'white'}>{(mockDataValue?.type !== 'object' && mockDataValue?.type !== 'array') ? mockDataValue?.value : ''}</Typography>}>
                    <span>
                      <img onClick={(e) => {
                        e.stopPropagation();
                        onMockDataClick(mockDataValue);
                      }} width={15} height={15} src={isEmpty(typeof mockDataValue?.value == 'string' ? mockDataValue?.value : mockDataValue?.value?.toString()) ? testDataInvoke : mockDataValueIcon} />
                    </span>
                  </Tooltip>
                }
              </LeftWholeWrapper>

              {
                labelInfo &&
                <p style={{
                  fontSize: '12px',
                  fontWeight: 400,
                  color: '#667085'
                }}>{labelInfo?.toLowerCase()}</p>
              }

            </WholeTextWrapper>
          </>
        }
        {...other}
      />
    </>
  )
}

type variableTreeProps = {
  variableTreeData: any;
  loading: boolean;
}

interface IRenderTree {
  parentString: string;
  nodes: any;
  isParentArray?: boolean;
}

const checkThisIsRelation = (path:String, VariableData:any) =>{
  const getTableName =   path.replaceAll('record.','').replaceAll(`data`,'').replaceAll(`.`,'').split('.')[0];

  const value = VariableData?.record?.find((data:any)=> data.id == 'data')?.struct;

  const dat = value?.find((data:any)=> data.id == getTableName)?.dataType;

  if (dat == ComponentType.RELATION){
    return true;
  } else {
    return false;
  }
  }

  const collectObjectIds = (parentKey:string, item:any, collectedIds:any[] = []) => {
    if (item.dataType === "OBJECT") {
        // if (parentKey.includes('.')) {
            collectedIds.push(`${parentKey}${item.id}`);
        // } else {
            collectedIds.push(`${parentKey}.${item.id}`);
        // }
    }
    if (item.struct) {
        // if (parentKey.includes('.')) {
            collectedIds.push(`${parentKey}${item.id}`);
        // } else {
            collectedIds.push(`${parentKey}.${item.id}`);
        // }
        item.struct.forEach((subItem:any) => {
            collectObjectIds(`${parentKey}.${item.id}`, subItem, collectedIds);
        });
    }
    return collectedIds;
}

const collectIdsFromTask = (data:any) =>{
    let collectedIds:any[] = [];
    Object.keys(data).forEach(key => {
        (data?.[key] || []).forEach((item:any) => {
          collectedIds = collectObjectIds(key, item, collectedIds);
        });
    });
    return collectedIds;
}


const VariableTreeLowCode = ({ variableTreeData, loading }: variableTreeProps) => {
  const { selectedAutomation } = useAutomation();
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState<string>();
  const [allApiFilteredVariables, setAllApiFilteredVariables] = useState<string[]>([]);
  const [VariableData, setVariableData] = useState<any>([]);
  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
  const [openMockData, setOpenMockData] = useState<string>('');
  const [openSingleMockDataUpdateModal, setOpenSingleMockDataUpdateModal] = useState<string>('');

  useEffect(() => {
    setVariableData(variableTreeData);
    let variableData = [...Object.keys(variableTreeData)];

    const checkTriggerHasTableUpdate = selectedAutomation?.triggers?.find((data: any) => data?.event?.label == 'When a record is updated');
    const checkTriggerHasTeamUpdate = selectedAutomation?.triggers?.find((data: any) => data?.event?.label == 'On Team Update');
    const checkTriggerHasUserUpdate = selectedAutomation?.triggers?.find((data: any) => data?.event?.label == 'On User Update');

    if (!checkTriggerHasTableUpdate) variableData = variableData?.filter((item) => item !== "recordBeforeUpdate");
    if (!checkTriggerHasTeamUpdate) variableData = variableData?.filter((item) => item !== "teamBeforeUpdate");
    if (!checkTriggerHasUserUpdate) variableData = variableData?.filter((item) => item !== "userBeforeUpdate");

    setAllApiFilteredVariables(variableData);
  }, [`${JSON.stringify(variableTreeData)}`]);

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpandedNodes(nodeIds);
  };

  const getTextToCopy = (copyMe: string) => {
    const selectedContextArr = ["record", "task", "loggedInUser", "appComponents", "company", "recordBeforeUpdate", "user", "userBeforeUpdate", "team", "teamBeforeUpdate", "requestBody"];
    let copiedTextArray: any = copyMe.split('.');
    let selectedContext = copiedTextArray.shift();
    let copyText: string;
    if (selectedContextArr.includes(selectedContext)) {
      copyText = getTextBeforeSpacing(copyMe);
    } else {
      copyText = copiedTextArray.join(".");
    }

    return copyText;
  }

  const copyToClipBoard = async (copyText: string, parentString: string) => {
    dispatch(copiedPreDefined({ data: copyText, type: VARIABLE_TREE, dataName: parentString }));
    try {
      await navigator.clipboard.writeText(copyText);
    } catch (err) {
      notification('error', 'Unable to copy data field to clipboard, Try again');
    }
  }

  const RenderTree = ({ parentString, nodes, isParentArray }: IRenderTree) => {
    const nodeArray = nodes?.struct;
    const isArray = Array.isArray(nodeArray) || nodeArray?.length > 0;
    const valuePath = getTextToCopy(`${parentString}.${nodes?.id}`);

    return (
      <StyledTreeItem
      isParentArray={isParentArray}
        onMockDataClick={(mockDataValue: any) => {
          const isUpdateSingleMockDataValue = mockDataValue?.type !== 'object' && mockDataValue?.type !== 'array' && nodes.dataType !== DataFieldType.LIST && nodes.dataType !== DataFieldType.OBJECT;
          if (isUpdateSingleMockDataValue) {
            setOpenSingleMockDataUpdateModal(valuePath);
          } else {
            setOpenMockData(valuePath);
          }
        }}
        valuePath={valuePath}
        onClick={() => {
          if (!Array.isArray(nodeArray) || nodeArray.length == 0) {
            const parentStringRelationCheck = checkThisIsRelation(parentString, VariableData);
            if (parentStringRelationCheck) {
              copyToClipBoard(`${parentString}[0].${nodes?.id}`, `${nodes?.id}`);
              } else {
                let firstName = '';
                if (parentString.includes(' - ')) {
                if (parentString.includes('.')) {
                  firstName = parentString.substring(parentString.indexOf('.') + 1) + '.';
                } else {
                  firstName = '';
                }
              } else {
                  firstName = parentString + '.';
                }
                copyToClipBoard(`${firstName}${nodes?.id}`, `${nodes?.id}`);
              }
          }
        }}
        nodeId={parentString + nodes?.id}
        labelText={nodes?.id}
        labelInfo={nodes?.dataType}>
        {isArray
          ? nodeArray?.map((node: any) => {
            const parentStringValue = `${parentString}.${nodes?.id}`;
            return (
              <RenderTree parentString={parentStringValue} nodes={node} isParentArray={nodes.dataType == DataFieldType.LIST} />
            );
          })
          : null}
      </StyledTreeItem>
    );
  };

  const searchJsonByKeyword = (json: any, keyword: any) => {
    // Helper function to search recursively
    function searchInStruct(struct: any) {
      return struct.filter((item: any) => {
        const name = item.name.toLowerCase();
        const id = item.id.toLowerCase();
        if (item.dataType === "OBJECT" && item.struct) {
          // Recursively filter the children
          const val = searchInStruct(item.struct);
          item.struct = cloneDeep(val);

          return val.length > 0 || name.includes(keyword) || id.includes(keyword);
        } else {
          // Check for direct matches on non-object items
          return name.includes(keyword) || id.includes(keyword);
        }
      });
    }

    // Function to filter the main keys (e.g., "task", "company") based on the struct content
    const filterTopLevel = (item: any) => {
      if (Array.isArray(item)) {
        return item.filter((entry) => {
          const name = entry.name.toLowerCase();
          const id = entry.id.toLowerCase();
          if (entry.dataType === "OBJECT" && entry.struct) {
            // Recursively filter the struct
            const val = searchInStruct(entry.struct);
            entry.struct = cloneDeep(val);
            // Keep this object if it still contains relevant children or if it matches directly
            return val.length > 0 || name.includes(keyword) || id.includes(keyword);
          } else {
            // Check for direct matches
            return name.includes(keyword) || id.includes(keyword);
          }
        });
      }
      return [];
    }

    // Start filtering each of the main sections in the JSON
    return Object.fromEntries(Object.entries(json).map(([key, value]) => {
      const temp = cloneDeep(value);
      const outputValue = filterTopLevel(temp)
      return [key, outputValue]
    }));
  }

  const removeEmptyArrays = (obj: any) => {
    // Iterate through the object keys
    Object.keys(obj).forEach((key) => {
      // Check if the value is an array and if it's empty
      if (Array.isArray(obj[key]) && obj[key].length === 0) {
        // Delete the property if the array is empty
        delete obj[key];
      }
    });

    return obj;
  }

  return (
    <>
      <div style={{
        height: '85%',
        overflow: 'none'
      }}>
        <InputContainer>
          <SearchIcon color={'#C2C9D1'} fontSize={20} />
          <Input
            type="text"
            placeholder="Search..."
            value={searchInput}
            style={{
              width: '100%'
            }}
            onChange={(e) => {
              const searchKeyword = e.target.value;

              const filteredJson = searchJsonByKeyword(variableTreeData, searchKeyword);
              setVariableData(filteredJson);
              let variableData = [...Object.keys(removeEmptyArrays(filteredJson))];
              setAllApiFilteredVariables(variableData);

              setSearchInput(e.target.value);

              const expandedItems = collectIdsFromTask(filteredJson).concat(variableData);
              setExpandedNodes(expandedItems)
            }}
          />
        </InputContainer>

        <div style={{
          marginLeft: '12px',
          marginRight: '12px'
        }}>
          <WholeTextWrapper>
            <LeftWholeWrapper className='pointer' onClick={() => {
              setOpenMockData('wholeData');
            }} >
              <LeftWrapper>
                <img style={{
                }} width={15} height={15} src={jsCondition} />

                <Typography width={'fit-content'} style={{
                  color: '#475467',
                  fontSize: '12px',
                  fontWeight: 400
                }}
                  noWrap>
                  {'Test Data'}
                </Typography>

              </LeftWrapper>

              <img className='pointer' onClick={() => {
                setOpenMockData('wholeData');
              }} width={15} height={15} src={mockDataValueIcon} />

            </LeftWholeWrapper>

            <p style={{
              fontSize: '12px',
              fontWeight: 400,
              color: '#667085'
            }}>baseObject</p>

          </WholeTextWrapper>
        </div>

        <SimpleTreeView
          aria-label='multi-select'
          multiSelect
          onClick={(e)=>{
            e.stopPropagation();
            console.log('clicked');
          }}
          defaultCollapseIcon={<img src={collapseIcon} />}
          defaultExpandIcon={<img src={expandIcon} />}
          expanded={expandedNodes}
          onNodeToggle={handleToggle}
          style={{marginTop:'0px !important'}}
          sx={{ height: '100%', flexGrow: 1, maxWidth: 400, overflowY: 'auto', marginTop:'0px !important' }}
          className='mt-2 mb-2'>
          {loading &&
            <Grid style={{ height: '100%', display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <WLCircularLoader color="primary" sx={{ height: 0, width: 0 }} />
            </Grid>
          }

          {!loading && allApiFilteredVariables.map((variable: any) => {
            const arrValue = VariableData?.[variable];
            const isArray = Array.isArray(arrValue);
            const valuePath = variable;

            return (
              <StyledTreeItem
                onMockDataClick={(mockDataValue: any) => {
                  if (mockDataValue?.type !== 'object' && mockDataValue?.type !== 'array' && (mockDataValue?.type != 'undefined' || mockDataValue?.value)) {
                    setOpenSingleMockDataUpdateModal(valuePath);
                  } else if((isArray == true && arrValue[0]?.dataType === DataFieldType.OBJECT)){
                    setOpenMockData(valuePath);
                  } else {
                    setOpenMockData(valuePath);
                  }
                }}
                valuePath={variable}
                nodeId={variable}
                labelText={variable}>
                {isArray &&
                  arrValue?.map((singleVariable: SingleVariable) => {
                    const isListOrObject = singleVariable?.dataType === 'OBJECT' || singleVariable?.dataType === 'LIST';
                    const valuePath = getTextToCopy(`${variable}.${singleVariable?.id}`);

                    return (
                      <>
                        {isListOrObject ?
                          <RenderTree
                          parentString={variable} nodes={singleVariable} isParentArray={false}/>
                          :
                          <StyledTreeItem
                            valuePath={valuePath}
                            onMockDataClick={(mockDataValue: any) => {
                              if (mockDataValue?.type !== 'object' && mockDataValue?.type !== 'array') {
                                setOpenSingleMockDataUpdateModal(valuePath);
                              } else {
                                setOpenMockData(valuePath);
                              }
                            }}
                            onClick={() => {
                              const relationCheckTest = checkThisIsRelation(variable, VariableData);
                              if(relationCheckTest){
                                copyToClipBoard(`${variable}[0].${singleVariable?.id}`, `${variable}`)
                              }else {
                                copyToClipBoard(`${variable}.${singleVariable?.id}`, `${variable}`)
                                }
                            }}
                            nodeId={variable + singleVariable.id} labelText={singleVariable.id} labelInfo={singleVariable?.dataType} />
                        }
                      </>
                    );
                  })}
              </StyledTreeItem>
            );
          })}
        </SimpleTreeView>
      </div>

      {
        !!openMockData &&
        <MockDataEditor isOpenPath={openMockData} onClose={() => {
          setOpenMockData('');
        }} />
      }

      {
        !!openSingleMockDataUpdateModal &&
        <SingleMockDataUpdateModal openModal={openSingleMockDataUpdateModal} handleClose={() => {
          setOpenSingleMockDataUpdateModal('');
        }} />
      }

    </>
  );
};

export const VariableTreeLowCodeEditor = React.memo(VariableTreeLowCode);