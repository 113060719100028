import dotProp from 'dot-prop';
import configDev from './config.dev.json';
import configLocal from './config.local.json';
import configProd from './config.prod.json';

export const getConfig = (key: string): any => {
  const config = getEnvConfig();
  return dotProp.get(config, key);
};

function getEnvConfig(): any {
  if (process.env['REACT_APP_ENVIRONMENT'] === 'local') {
    return configLocal as any;
  } else if (process.env['REACT_APP_ENVIRONMENT'] === 'dev') {
    return configDev as any;
  } else if (process.env['REACT_APP_ENVIRONMENT'] === 'prod') {
    return configProd as any;
  } else {
    return configDev as any;
  }
}
