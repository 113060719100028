import { Menu, MenuItem } from "@szhsin/react-menu"
import "@szhsin/react-menu/dist/index.css"
import "@szhsin/react-menu/dist/transitions/slide.css"
// import {
//   FilterRIcon,
//   RefreshRIcon,
//   SettingRIcon
// } from "assests"
import usePorterPermissions from "hooks/usePorterPermissions"
import useAccessPermissions from "hooks/useAccessPermissions"
import { HiOutlineRefresh as RefreshIcon } from "react-icons/hi";
import { ResourceTypeConstant } from "views/entities/permissions/types"
import jsonExport from "jsonexport/dist"
import { BsThreeDotsVertical as ThreeDots } from "react-icons/bs";
import {
  MRT_ShowHideColumnsButton as MrtShowHideColumnsButton,
  MRT_ToggleGlobalFilterButton as MrtToggleGlobalFilterButton
} from "material-react-table"
import { Drag } from "assests"
import { Popover, Typography, FormControlLabel } from '@mui/material';
import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { CiViewColumn as ColumnVisibilityIcon } from "react-icons/ci";
import { initEditor, initMockData } from "lowcode/state/scriptEditorState";
import notification from "notifications/notifications"
import useAutomation from "views/automationBuilder/hooks/useAutomation";
import { EntityService } from "providers/data/services/EntityService"
import { useEffect, useState } from "react"
import { downloadCSV } from "react-admin"
import { BiCard, BiCodeAlt, BiCopy, BiEdit, BiExport, BiImport } from "react-icons/bi"
import { BsLightningCharge } from "react-icons/bs"
import { ZJSEditorLayout } from "lowcode/ZJSEditorLayout";
import { RiDeleteBin6Line } from "react-icons/ri"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { ReactSpreadsheetImport } from "react-spreadsheet-import"
import styled, { useTheme } from "styled-components"
import { styled as muiStyled } from '@mui/material/styles';
import { bulkUploadCustomTheme } from "utils/BulkUploadTheme"
import {
  OnboardingIdsForComponent,
  recordRSEvent,
} from "utils/CommonUtils"
import {
  getTaskTypeCustomFieldsForBulkTask,
  validateLocationFields,
} from "utils/TasktypeUtils"
import {
  ActionTypeEntity,
  DataFieldType,
  EntityTypeField,
} from "views/DataField/DataField.types"
import ConfirmationDialog from "views/automationBuilder/components/ConfirmationDialog"
import { closeAutomation } from "views/automationBuilder/reducer/automationReducer"
import { ABMetadataTypeEnum } from "views/automationBuilder/types/ABType"
import { ZButton } from "views/commonComponents/commonComponents.styles"
import { saveHandler } from "views/entities/entityAutomation/saveHandler"
import useEntity from "views/entities/hooks/useEntity"
import { useJSEditor } from "lowcode/hooks/useJSEditor";
// import useWorkFlow from "views/workflows/hooks/useWorkFlow";
import {
  getEntityMetadata,
  setSelectEntity,
  setShowEntityEditModal,
  setShowTableAppConfigCard,
} from "views/entities/reducers/entityReducers"
import { ZIconButton2 } from "views/entities/styles"
import { IColDefs, TableRSEvents } from "views/entities/types"
import { initMenu } from "views/layouts/reducer/menuSlice"
import ConfigureAutomation from "views/workflows/ConfigureAutomation"
import CollectEmailModal from "./CollectEmailModal"
import { HiOutlineFilter } from "react-icons/hi"
// import { LuSettings } from "react-icons/lu"
// import { getConfig } from "config/envConfig/Config"
// import axios from "axios"

const IconWrapper = styled.div`
  padding: 8px !important;
`

const IconText = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: #344054;
`

const Button = styled.button`
  padding: 8px !important;
  border: 1px solid #d0d5dd !important;
  border-radius: 4px;
`

const ZButtonWrapper = styled(ZButton)`
  background-color: #F0F6FC !important;
  padding: 2px 8px !important;
  border-radius: 4px !important;
  align-items: center !important;
  border: none !important;
  text-transform: none !important;
  font-size: 12px !important;
  font-family: Inter !important;
  font-weight: 500 !important;
  line-height: 18px !important;
  color: #344054 !important;
`

const IOSSwitch = muiStyled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 20,
  marginLeft: '0px !important',
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: '2.5px 0 0 0',
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#3C69E7 !important',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 15,
    height: 15,
  },
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2, // Adjust for the new height to maintain rounded edges
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA !important' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const MenuHeading = styled.div`
    color: var(--Gray-700, #344054);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  `

const MenuPopover = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 4px;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
`

export const FilterAction = ({
  table,
  showColumnFilters,
}: {
  table: any
  showColumnFilters: boolean
}) => {
  const toggle = () => {
    console.log("showColumnFilters", showColumnFilters)
    table.setShowColumnFilters(!showColumnFilters)
  }
  return (
    <>
      {/* <ZIconButton2 style={{ margin: "0px 5px" }}>
        <MRT_ToggleFiltersButton
          sx={{
            color: "#344054",
            padding: "0px",
          }}
          table={table}
        />
      </ZIconButton2> */}
      <ZButtonWrapper
        startIcon={<HiOutlineFilter size="12px" color="#667085" />}
        secondary
        variant="contained"
        onClick={toggle}
      >
        {" "}
        Filter{" "}
      </ZButtonWrapper>
    </>
  )
}

export const GlobalSearch = ({ table }: { table: any }) => {
  return (
    <>
      <ZIconButton2 style={{ margin: "0px 5px" }}>
        <MrtToggleGlobalFilterButton
          sx={{
            color: "#344054",
            padding: "0px",
          }}
          table={table}
        />
      </ZIconButton2>
    </>
  )
}

export const RefreshTableButton = ({
  setRefresh,
  table,
}: {
  setRefresh: any
  table: any
}) => {
  return (
    <>
      <Button onClick={setRefresh}>
        <div>
          <RefreshIcon color="#344054" fontSize="16px" />
        </div>
      </Button>
    </>
  )
}

export const ShowHideColumnAction = ({ table }: { table: any }) => {

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleShowHideColumnsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const columns = table.getAllColumns();
    const newOrder = Array.from(table.getState().columnOrder);
    const [removed] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, removed);

    const orderedColumns = newOrder
      .map(columnId => columns.find(column => column.id === columnId))
      .filter(column => column !== undefined);
    table.setColumnOrder(orderedColumns.map(column => column.id));
  };

  const open = Boolean(anchorEl);
  const id = open ? 'show-hide-popover' : undefined;

  const updateColumns = () => {
    const allColumns = table.getAllColumns();
    const columnOrder = table.getState().columnOrder;
    if (allColumns.length > 0 && columnOrder.length > 0) {
      const sortedColumns = columnOrder.map(columnId => allColumns.find(column => column.id === columnId)).filter(Boolean);
      return sortedColumns;
    }
    return null;
  };

  useEffect(() => {
    updateColumns();
  }, [table]);

  return (
    <>
      <ZButtonWrapper
        startIcon={<ColumnVisibilityIcon strokeWidth={1} size="12px" color="#667085" />}
        onClick={handleShowHideColumnsClick}
      >
        Columns
      </ZButtonWrapper>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuPopover>
          <MenuHeading>Manage Columns</MenuHeading>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="columns">
              {(provided) => (
                <div style={{ display: "flex", flexDirection: "column", gap: "8px" }} {...provided.droppableProps} ref={provided.innerRef}>
                  {updateColumns().map((column, index) => (
                    <Draggable key={column.id} draggableId={column.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={{
                            ...provided.draggableProps.style,
                            display: column.id === 'mrt-row-select' ? 'none' : 'flex',
                          }}
                        >
                          <div style={{ display: "flex", alignItems: "center", padding: "4px 8px", justifyContent: 'flex-start' }}>
                            <div {...(column.id !== 'mrt-row-select' ? provided.dragHandleProps : {})}>
                              <span style={{marginRight: '16px', display: 'flex', alignItems: 'center'}}><Drag color={'#98A2B3'} /></span>
                            </div>
                            <FormControlLabel
                            style={{marginLeft: '0px'}}
                              control={
                                <IOSSwitch
                                  checked={column.getIsVisible()}
                                  onChange={() => column.toggleVisibility()}
                                  disabled={column.id === 'mrt-row-select'}
                                />
                              }
                              label={""}
                            />
                            <span style={{ color: '#344054', fontWeight: '500', lineHeight: '20px', fontSize: '12px' }}>{column.columnDef.header}</span>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </MenuPopover>
      </Popover>
    </>
  );
};


export const Actions = ({
  table,
  setLoading,
  columnFilterFns,
}: {
  table: any
  setLoading: any
  columnFilterFns: any
}) => {
  // const selectedRows = table.getSelectedRowModel().rows.map((row: any) => {
  //   return row.original
  // })

  // const allRows = table.getPrePaginationRowModel().rows.map((row: any) => {
  //   return row.original
  // })

  const [showCollectEmail, setShowCollectEmail] = useState(false)
  const [showImportModal, setShowImportModal] = useState(false)
  const [showBulkUploader, setShowBulkUploader] = useState(false)
  const [openAB, setOpenAB] = useState(false)
  // const [bulkUploadData, setBulkUploadData] = useState<any>([])
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [allFields, setAllFields] = useState<any>([])
  // const [showTableCardConfig, setShowTableCardConfig] = useState(false);
  // const url = getConfig("appDesigner");
  const {
    allEntities,
    selectedEntityData,
    selectedEntity,
    selectedEntityMetadata,
    recordTypeLabel,
  } = useEntity()

  // const {
  //   openJSEditorLayout,
  //   DefaultJSON,
  //   openJSEditor,
  //   userClearedScript
  // } = useJSEditor();

  const {
    scriptEditorOpen
  } = useJSEditor();

  // const {
  //   selectedTransition,
  //   selectedAction,
  //   getScriptValue,
  //   getLiteralValue,
  //   apiResponse,
  //   dataPicker,
  //   taskTypeId,
  // } = useWorkFlow();

  const porterPermissions = usePorterPermissions()
  const accessPermissions = useAccessPermissions({ resourceType: ResourceTypeConstant.TABLE })


  const handleTableCardConfig = () => {
    dispatch(setShowTableAppConfigCard(true))
  }

  const history = useHistory();
  const { clearRightSide } = useAutomation();
  useEffect(() => {
    // remove isDeleted from selectedEntityMetadata.customFields
    const activeColumns = selectedEntityMetadata?.customFields?.filter(
      (val: any) => val?.isDeleted !== true
    )
    const fields = getTaskTypeCustomFieldsForBulkTask({
      customFields: activeColumns,
    })
    setAllFields(fields)
  }, [selectedEntityMetadata])

  const dispatch = useDispatch()

  const handleBulkCreate = async (
    data: any,
    email?: string,
    showLoader = true
  ) => {
    if (showLoader) setLoading(true)
    data.validData.forEach((record: any) => {
      Object.keys(record).forEach((item) => {
        if (item.includes("$")) {
          let separation = item.split("$")
          let fieldName = separation[0]
          let subFieldKey = separation[1]
          let subFieldValue = record[item]

          if (subFieldValue) {
            let fName = fieldName.includes(".")
              ? fieldName.split(".")[0]
              : fieldName
            const curFieldType = selectedEntityMetadata?.customFields?.filter(
              (single) => single.name === fName
            )
            //@ts-ignore
            const subFieldType = curFieldType[0]?.children?.filter(
              (single: any) => single.name === subFieldKey
            )

            if (
              subFieldType[0]?.type === DataFieldType.NUMBER &&
              !isNaN(subFieldValue)
            ) {
              subFieldValue = Number.isInteger(subFieldValue)
                ? parseInt(subFieldValue)
                : parseFloat(subFieldValue)
            }
            if (
              subFieldType[0]?.type === DataFieldType.IMAGE ||
              subFieldType[0]?.type === DataFieldType.LIST_OF_TEXT
            ) {
              subFieldValue = subFieldValue
                ? subFieldValue.split(",")
                : undefined
            }
            if (subFieldType[0]?.type === DataFieldType.BOOLEAN) {
              subFieldValue =
                subFieldValue?.toLowerCase() === "true" ? true : false
            }

            if (record[fieldName]) {
              record[fieldName] = {
                ...record[fieldName],
                [subFieldKey]: subFieldValue,
              }
            } else {
              record[fieldName] = { [subFieldKey]: subFieldValue }
            }
          }

          delete record[item]
        } else {
          const curFieldType =
            selectedEntityMetadata?.customFields.filter(
              (val) => val.name === item
            ) || []

          if (
            curFieldType[0]?.type === DataFieldType.NUMBER &&
            !isNaN(record[item])
          ) {
            record[item] = Number.isInteger(record[item])
              ? parseInt(record[item])
              : parseFloat(record[item])
          }
          if (curFieldType[0]?.type === DataFieldType.DATETIME) {
            if (record[item]) {
              record[item] = new Date(record[item]).toISOString()
            } else {
              record[item] = undefined
            }
          }
          if (curFieldType[0]?.type === DataFieldType.ONLY_DATE) {
            if (record[item]) {
              const selectedDate = new Date(record[item]);
              const utcDate = new Date(
                Date.UTC(
                  selectedDate.getFullYear(),
                  selectedDate.getMonth(),
                  selectedDate.getDate()
                )
              ).toISOString();
              record[item] = utcDate;
            } else {
              record[item] = undefined;
            }
          }
          if (
            curFieldType[0]?.type === DataFieldType.IMAGE ||
            curFieldType[0]?.type === DataFieldType.LIST_OF_TEXT ||
            curFieldType[0]?.type === DataFieldType.FILE ||
            curFieldType[0]?.type === DataFieldType.RELATION
          ) {
            record[item] = record[item] ? record[item].split(",") : undefined
          }

          // if type is boolean, cast string to boolean
          if (curFieldType[0]?.type === DataFieldType.BOOLEAN) {
            record[item] = record[item]?.toLowerCase() === "true"
          }
        }
      })
    })

    const payload = {
      tableType: selectedEntity?.tableType || "",
      records: data.validData,
      email: email,
    }

    try {
      notification("info", "Importing data...")
      const res = await EntityService.recordBulkImport(payload)

      setShowCollectEmail(false)
      dispatch(getEntityMetadata(selectedEntity?.tableType || ""))

      recordRSEvent(TableRSEvents.BULK_IMPORT_RECORDS_SUCCESS, {
        tableType: selectedEntity?.tableType || "",
        res,
        count: data.validData.length,
      })
      notification("success", "Data imported successfully")
    } catch (error: any) {
      console.log(error)
      notification(
        "error",
        error?.response?.data?.message ||
        "Something went wrong. Please try again"
      )
      recordRSEvent(TableRSEvents.BULK_IMPORT_RECORDS_FAILURE, {
        tableType: selectedEntity?.tableType || "",
      })
    }
    if (showLoader) setLoading(false)
  }

  const handleImportClick = () => {
    setShowImportModal(true)
    recordRSEvent(TableRSEvents.BULK_IMPORT_BUTTON_CLICKED, {
      tableType: selectedEntity?.tableType || "",
    })
  }

  const handleTableDelete = async () => {
    try {
      // select the next tableType that can be selectedEntity once this table is deleted
      if (!allEntities) return;
      const entities: any = allEntities?.filter(
        (val: any) => val?.tableType !== selectedEntity?.tableType
      )
      const nextSelectedEntity = entities.length ? entities[0].tableType : null
      const nextSelectedEntityDefaultView = entities.length ? entities[0].defaultView : null
      if (nextSelectedEntity) {
        localStorage.setItem("tableType", nextSelectedEntity)
        if(nextSelectedEntityDefaultView) {
          window.location.href = `/#/table/${nextSelectedEntity}/view/${nextSelectedEntityDefaultView}`
        }
        else {
          window.location.href = `/#/table/${nextSelectedEntity}`
        }
      } else {
        localStorage.removeItem("tableType")
      }

      const res = await EntityService.deleteTable(
        selectedEntity?.tableType || ""
      )
      console.log(res)
      notification("success", "Table deleted successfully")
      dispatch(setSelectEntity(null))
      dispatch(initMenu())
      if (nextSelectedEntity === null) {
        history.push("/table")
      }
    } catch (error) {
      notification("error", "Something went wrong. Please try again")
    }
  }

  const handleCopyClick = async () => {
    try {
      const res = await EntityService.copyTable(selectedEntity?.tableType || "")
      notification("success", "New table has been created!")
      localStorage.setItem("tableType", res.data.tableType)
      dispatch(setSelectEntity(null))
      if(res?.data?.defaultViewId) {
        window.location.href = `/#/table/${res.data.tableType}/view/${res?.data?.defaultViewId}`
      }
      else {
        window.location.href = `/#/table/${res.data.tableType}`
      }
      dispatch(initMenu())
    } catch (error) {
      console.log(error)
      notification("error", "Something went wrong. Please try again")
    }
  }

  const handleExportClick = async () => {
    if (!accessPermissions?.view_record) return;
    const headers =
      selectedEntityData?.colDefs
        .filter(
          (val: IColDefs) =>
            val?.data?.type !== ActionTypeEntity.BUTTON &&
            val?.field !== "addRow" &&
            val?.data?.type === "COMMENT"
        )
        .map((col: any) => col.field)
        .filter((val: string) => val !== undefined) || []

    const filters = table.getState().columnFilters
    filters.forEach((filterObj: any) => {
      if (["createdOn", "updatedOn"].includes(filterObj.id)) {
        filterObj.filterType = columnFilterFns[filterObj.id] || "lessThan"
      } else {
        filterObj.filterType = columnFilterFns[filterObj.id] || "contains"
      }
    })

    const allowedDirectSortFields = ["recordId", "createdOn", "updatedOn"]
    const sorting = table.getState().sorting
    sorting.forEach((sortObj: any) => {
      if (!allowedDirectSortFields.includes(sortObj.id)) {
        sortObj.keyword = true
      }
    })

    const searchText = table.getState().globalFilter

    const payload = {
      tableTypes: [selectedEntity?.tableType || ""],
      filters,
      sorting,
      searchText,
      page: 0,
      size: 10000,
    }

    const allData: any = await EntityService.exportRecords(payload)
    const data = allData.data.records.map((val: any) => {
      return {
        ...val.data,
        comments: undefined,
        recordId: val.recordId,
        createdOn: val.createdOn,
        updatedOn: val.updatedOn,
      }
    })

    jsonExport(
      data,
      {
        headers,
      },
      (err, csv) => {
        downloadCSV(
          csv,
          `${selectedEntity?.tableDisplayName}-${selectedEntity?.tableType}`
        )
      }
    )
  }

  const handleRenameClick = () => {
    dispatch(setShowEntityEditModal(true))
  }

  const handleTemplateDownload = () => {
    // location headers
    let locHeaders: any = []
    selectedEntityMetadata?.customFields
      ?.filter((val: any) => val?.isDeleted !== true)
      .forEach((val) => {
        if (val?.type === DataFieldType.LOCATION) {
          locHeaders.push(`${val.name} Address`)
          locHeaders.push(`${val.name} Latitude`)
          locHeaders.push(`${val.name} Longitude`)
        }
      })

    let headers =
      selectedEntityMetadata?.customFields
        .filter(
          (val: any) =>
            val?.type !== "COMMENT" &&
            val?.type !== EntityTypeField.COMPUTED &&
            val?.type !== EntityTypeField.BUTTON &&
            val?.type !== DataFieldType.LOCATION &&
            val?.isDeleted !== true
        )
        .map((col: any) => col.name)
        .filter((val: string) => val !== undefined) || []

    headers = [...headers, ...locHeaders]
    const data: any = []
    // const csvContent = convertJson2CSV({ data, headers })
    jsonExport(
      data,
      {
        headers,
      },
      (err, csv) => {
        downloadCSV(
          csv,
          `${selectedEntity?.tableDisplayName}-${selectedEntity?.tableType}`
        )
      }
    )
    recordRSEvent(TableRSEvents.DOWNLOAD_TEMPLATE_CSV_BUTTON_CLICKED, {
      tableType: selectedEntity?.tableType || "",
    })
  }

  const handleBulkUploadWithEmail = async (data: any) => {
    handleBulkCreate(data, undefined, false)
  }

  const handleAPIClick = () => {
    if (
      porterPermissions.checkPermissionsForPorter(
        selectedEntity?.tableType,
        "table_documentation"
      )
    ) {
      history.push(`/table/document/${selectedEntity?.tableType}`)
    }
  };



  const theme: any = useTheme()

  return (
    <>
      <Menu
        menuClassName="my-menu"
        menuButton={
          <Button>
            <ThreeDots color="#344054" size={"16px"} />
          </Button>
        }
        transition
        gap={12}
        align="center"
      >
        <MenuItem
          onClick={() => {
            if (selectedEntity) navigator.clipboard.writeText(selectedEntity?.tableType)
            notification("success", "Table Id copied to clipboard")
          }}
          className="menuItem-copy"
        >
          <IconText
            style={{
              color: "#667085",
              fontWeight: "500",
              padding: "8px",
            }}
          >
            {" "}
            Table Id: {selectedEntity?.tableType}{" "}
          </IconText>
          <IconWrapper>
            <BiCopy color={theme.color.primary} size={"1.5rem"} stroke="2" />
          </IconWrapper>
        </MenuItem>
        {/* <MenuItem className="menuItem-s">
          <ShowHideColumnAction table={table} />
        </MenuItem> */}
        {porterPermissions.checkPermissionsForPorter(
          selectedEntity?.tableType,
          "automations"
        ) && accessPermissions.manage_table_automation && (
            <MenuItem
              className="menuItem-s"
              onClick={() => {
                dispatch(initMockData({taskTypeId: selectedEntity?.tableType as string, type:"entity"}));
                dispatch(initEditor({taskTypeId: selectedEntity?.tableType as string, type:"entity"}));
                setOpenAB(true)
                recordRSEvent(TableRSEvents.TABLE_AUTOMATION_BUTTON_CLICKED, {
                  tableType: selectedEntity?.tableType || "",
                })
              }}
              id={OnboardingIdsForComponent.CONFIGURE_TABLE_AUTOMATION_BUTTON}
            >
              <IconWrapper>
                <BsLightningCharge color="#344054" size={"1.5rem"} stroke="2" />
              </IconWrapper>
              <IconText> Automations </IconText>
            </MenuItem>
          )}
        {accessPermissions.view_record && porterPermissions.checkPermissionsForPorter(
          selectedEntity?.tableType,
          "import_records"
        ) && (
            <MenuItem onClick={handleImportClick} className="menuItem-s">
              <IconWrapper>
                <BiImport color="#344054" size={"1.5rem"} stroke="2" />
              </IconWrapper>
              <IconText> Import </IconText>
            </MenuItem>
          )}
        {accessPermissions.view_record && (<MenuItem onClick={handleExportClick} className="menuItem-s">
          <IconWrapper>
            <BiExport color="#344054" size={"1.5rem"} stroke="2" />
          </IconWrapper>
          <IconText> Export </IconText>
        </MenuItem>)}
        {porterPermissions.checkPermissionsForPorter(
          selectedEntity?.tableType,
          "rename_table"
        ) && accessPermissions.rename_table && (
            <MenuItem onClick={handleRenameClick} className="menuItem-s">
              <IconWrapper>
                <BiEdit color="#344054" size={"1.5rem"} stroke="2" />
              </IconWrapper>
              <IconText> Rename Table </IconText>
            </MenuItem>
          )}
        {porterPermissions.checkPermissionsForPorter(
          selectedEntity?.tableType,
          "duplicate_table"
        ) && accessPermissions.duplicate_table && (
            <MenuItem onClick={handleCopyClick} className="menuItem-s">
              <IconWrapper>
                <BiCopy color="#344054" size={"1.5rem"} stroke="2" />
              </IconWrapper>
              <IconText> Duplicate Table </IconText>
            </MenuItem>
          )}
        {porterPermissions.checkPermissionsForPorter(
          selectedEntity?.tableType,
          "table_documentation"
        ) && (
            <MenuItem onClick={handleAPIClick} className="menuItem-s">
              <IconWrapper>
                <BiCodeAlt color="#344054" size={"1.5rem"} stroke="2" />
              </IconWrapper>
              <IconText> API Documentation </IconText>
            </MenuItem>
          )}
        <MenuItem onClick={handleTableCardConfig} className="menuItem-s">
          <IconWrapper>
            <BiCard color="#344054" size={"1.5rem"} stroke="2" />
          </IconWrapper>
          <IconText> Mobile App Settings </IconText>
        </MenuItem>
        {accessPermissions.delete_table && porterPermissions.checkPermissionsForPorter(
          selectedEntity?.tableType,
          "delete_table"
        ) && (
            <MenuItem
              onClick={() => setShowDeleteConfirmation(true)}
              className="menuItem-delete"
            >
              <IconWrapper>
                <RiDeleteBin6Line color="#B42318" size={"1.5rem"} stroke="2" />
              </IconWrapper>
              <IconText
                style={{
                  color: "#B42318",
                }}
              >
                {" "}
                Delete Table{" "}
              </IconText>
            </MenuItem>
          )}

      </Menu>

      <ConfigureAutomation
        metaDataType={ABMetadataTypeEnum.ENTITY_AUTOMATION}
        prevAutomations={
          selectedEntityMetadata?.tableMetadata?.automations?.systemAutomation
            ?.uiAutomations || []
        }
        onSaveHandler={saveHandler}
        onAutomationClose={() => {
          clearRightSide();
          dispatch(closeAutomation())
          setOpenAB(false)
        }}
        otherConfigs={{}}
        openAutomationBuilder={openAB}
      />
      <ConfirmationDialog
        id="importRecord"
        deletionTitle="Import Data"
        deletionText="Click on Download Template to get a sample CSV file. If you already have a CSV file, click on Import."
        isOpen={showImportModal}
        onClose={() => {
          setShowImportModal(false)
          recordRSEvent(TableRSEvents.BULK_IMPORT_RECORDS_CANCEL, {
            tableType: selectedEntity?.tableType || "",
          })
        }}
        onPrimaryBtnClick={() => {
          setShowBulkUploader(true)
          setShowImportModal(false)
        }}
        onSecondaryClick={() => {
          handleTemplateDownload()
        }}
        variant="primary"
        primaryButtonName="Import"
        secondaryButtonName="Download Template"
      />

      <ConfirmationDialog
        id="deleteTable"
        deletionTitle="Delete Table"
        deletionText="Are you sure you want to delete this table? This action cannot be undone."
        isOpen={showDeleteConfirmation}
        onClose={() => {
          setShowDeleteConfirmation(false)
        }}
        onPrimaryBtnClick={() => {
          handleTableDelete()
          setShowDeleteConfirmation(false)
        }}
        onSecondaryClick={() => {
          setShowDeleteConfirmation(false)
        }}
        variant="danger"
        primaryButtonName="Delete"
        secondaryButtonName="Cancel"
      />
      {showCollectEmail && <CollectEmailModal
        showModal={showCollectEmail}
        setShowModal={setShowCollectEmail}
        callback={(email: string) => {
          handleBulkCreate([], email, false)
        }}
      />}
      {scriptEditorOpen && <ZJSEditorLayout />}

      {showBulkUploader && (
        <ReactSpreadsheetImport
          rowHook={(data, addError) => {
            const locationError = validateLocationFields(
              selectedEntityMetadata,
              data
            )
            locationError.forEach((err) =>
              addError(err.fieldName, {
                message: err.message,
                level: "error",
              })
            )
            return { ...data }
          }}
          isOpen={showBulkUploader}
          onClose={() => setShowBulkUploader(false)}
          onSubmit={(data) => handleBulkUploadWithEmail(data)}
          fields={allFields}
          customTheme={bulkUploadCustomTheme}
        />
      )}
    </>
  )
}