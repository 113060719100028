import { AxiosResponse } from 'axios';
import HttpUtil from 'config/AxiosConfig';
import { ZorpResponse } from 'providers/data/models/ZorpResponse';

export class CommunicationService {
  public static listAlerts(params: any): Promise<ZorpResponse | undefined> {
    const filterData = params.filter;

    let alertsSearchPayload: any = {};

    if (filterData.name) alertsSearchPayload.alertId = filterData.alertId;
    if (filterData.statuses) alertsSearchPayload.statuses = filterData.statuses;
    if (filterData.teamIds) alertsSearchPayload.teamIds = filterData.teamIds;
    if (filterData.searchString) alertsSearchPayload.searchString = filterData.searchString;

    const { page, perPage } = params.pagination;
    // const num = (page - 1) * perPage;
    // const size = perPage;
    alertsSearchPayload.page = page - 1;
    alertsSearchPayload.size = perPage;

    return HttpUtil.communicationInstance.post('/alert/list', alertsSearchPayload).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static getAlertCount(statuses: any): Promise<ZorpResponse | undefined> {
    return HttpUtil.communicationInstance.get(`/alert/count?statuses=${statuses}`).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static getAlert(alertId: string): Promise<ZorpResponse | undefined> {
    return HttpUtil.communicationInstance.get(`/alert/find/${alertId}`).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static updateStatus(alert: any): Promise<ZorpResponse | undefined> {
    return HttpUtil.communicationInstance
      .put(`/alert/${alert.id}/update/${alert.status}`)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }
}
