import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import notification from 'notifications/notifications';
import { TeamService } from 'providers/data/services/TeamService';
import { UserService } from 'providers/data/services/UserService';
import { recordRSEvent } from 'utils/CommonUtils';

const initialState = {
  usersList: [],
  rolesList: [],
  teamsList: [],
  teamsListOptions: [],
  userDetails: {},
};

export const getUsersList = createAsyncThunk('builder/getUsersList', async () => {
  return await UserService.getRoles(false);
});

export const getUserById = createAsyncThunk('builder/getUserById', async (id: string) => {
  return await UserService.getUser(id);
});

export const getTeamsList = createAsyncThunk('builder/getTeamsList', async () => {
  return await TeamService.listTeams({ filter: {}, pagination: { page: 1, perPage: 3000 } });
});

export const createUserAction = createAsyncThunk('builder/createUser', async (body: any) => {
  const res = await UserService.createUser(body);
  if (res?.code === '400') {
    if (res?.data?.[0])
      res?.data?.[0]?.split(",")?.forEach((errMsg: string) => {
        notification("error", errMsg);
      });
    else if (res?.message)
      notification("error", res?.message);
  }
  else if (res?.code === '200')
    recordRSEvent("create_user_button_click", {
      context: "user_create",
      user_name: body?.name,
      user_phone: body?.phone,
      user_role: body.role,
      user_team: body?.teamIds,
    });
  return res;
});

export const updateUserAction = createAsyncThunk('builder/createUser', async (body: any) => {
  return await UserService.userUpdate(body);
});

export const usersPageSlice: any = createSlice({
  name: 'usersPage',
  initialState,
  reducers: {
    clearUsersPageData: (state: any) => {
      state.usersList = [];
      state.rolesList = [];
      state.teamsList = [];
      state.teamsListOptions = [];
      state.userDetails = {};
    },
    clearUserCreationData: (state: any) => {
      state.isCreatedUserStatus = '';
      state.isUpdatedUserStatus = '';
    },
  },
  extraReducers: {
    // @ts-ignore
    [getUsersList?.pending]: (state: any) => {
      state.isFetchingUsers = true;
    },
    // @ts-ignore
    [getUsersList?.fulfilled]: (state: any, action: any) => {
      state.isFetchingUsers = false;
      state.usersList = action?.payload?.data;
      state.rolesList = action?.payload?.data.map((data: any) => {
        return { label: data.name, value: data.roleId };
      });
    },
    // @ts-ignore
    [getUsersList?.rejected]: (state: any, action: any) => {
      state.isFetchingUsers = false;
      if (action?.payload?.code?.indexOf('200') < 0) {
        notification('error', action?.payload?.message);
      }
    },
    // @ts-ignore
    [getTeamsList?.pending]: (state: any) => {
      state.isFetchingTeams = true;
    },
    // @ts-ignore
    [getTeamsList?.fulfilled]: (state: any, action: any) => {
      state.isFetchingTeams = false;
      state.teamsList = action?.payload?.data;
      state.teamsListOptions = action?.payload?.data?.teams?.map((team: any) => {
        return {
          value: team?.teamId,
          label: `${team?.name}  (${team?.teamId})`,
        };
      });
    },
    // @ts-ignore
    [getTeamsList?.rejected]: (state: any, action: any) => {
      state.isFetchingTeams = false;
      if (action?.payload?.code?.indexOf('200') < 0) {
        notification('error', action?.payload?.message);
      }
    },
    // @ts-ignore
    [getUserById?.pending]: (state: any) => {
      state.isFetchingUserDetails = true;
    },
    // @ts-ignore
    [getUserById?.fulfilled]: (state: any, action: any) => {
      state.isFetchingUserDetails = false;
      state.userDetails = action?.payload?.data;
    },
    // @ts-ignore
    [getUserById?.rejected]: (state: any, action: any) => {
      state.isFetchingUserDetails = false;
      if (action?.payload?.code?.indexOf('200') < 0) {
        notification('error', action?.payload?.message);
      }
    },
    // @ts-ignore
    [createUserAction?.pending]: (state: any) => {
      state.isCreatingUser = true;
    },
    // @ts-ignore
    [createUserAction?.fulfilled]: (state: any, action: any) => {
      state.isCreatingUser = false;
      state.isCreatedUserStatus = action?.payload?.code;
    },
    // @ts-ignore
    [createUserAction?.rejected]: (state: any, action: any) => {
      state.isCreatingUser = false;
      if (action?.payload?.code?.indexOf('200') < 0) {
        notification('error', action?.payload?.message);
      }
    },
    // @ts-ignore
    [updateUserAction?.pending]: (state: any) => {
      state.isUpdatingUser = true;
    },
    // @ts-ignore
    [updateUserAction?.fulfilled]: (state: any, action: any) => {
      state.isUpdatingUser = false;
      state.isUpdatedUserStatus = action?.payload?.code;
    },
    // @ts-ignore
    [updateUserAction?.rejected]: (state: any, action: any) => {
      state.isUpdatingUser = false;
      if (action?.payload?.code?.indexOf('200') < 0) {
        notification('error', action?.payload?.message);
      }
    },
  },
});

export const { clearUsersPageData, clearUserCreationData } = usersPageSlice.actions;

export default usersPageSlice.reducer;
