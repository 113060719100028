import React from "react";

type Props = {};

const ZInventory = ({
  color,
  width,
  height,
}: {
  color?: string;
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.375 5V11.15C15.375 12.4101 15.375 13.0402 15.1298 13.5215C14.914 13.9448 14.5698 14.289 14.1465 14.5048C13.6652 14.75 13.0351 14.75 11.775 14.75H6.225C4.96488 14.75 4.33482 14.75 3.85352 14.5048C3.43016 14.289 3.08595 13.9448 2.87024 13.5215C2.625 13.0402 2.625 12.4101 2.625 11.15V5M2.7 1.25H15.3C15.72 1.25 15.9301 1.25 16.0905 1.33175C16.2316 1.40365 16.3463 1.51839 16.4183 1.65951C16.5 1.81994 16.5 2.02996 16.5 2.45V3.8C16.5 4.22004 16.5 4.43006 16.4183 4.59049C16.3463 4.73161 16.2316 4.84635 16.0905 4.91825C15.9301 5 15.72 5 15.3 5H2.7C2.27996 5 2.06994 5 1.90951 4.91825C1.76839 4.84635 1.65365 4.73161 1.58175 4.59049C1.5 4.43006 1.5 4.22004 1.5 3.8V2.45C1.5 2.02996 1.5 1.81994 1.58175 1.65951C1.65365 1.51839 1.76839 1.40365 1.90951 1.33175C2.06994 1.25 2.27996 1.25 2.7 1.25ZM7.2 7.625H10.8C11.22 7.625 11.4301 7.625 11.5905 7.70674C11.7316 7.77865 11.8463 7.89338 11.9183 8.03451C12 8.19494 12 8.40496 12 8.825V9.425C12 9.84504 12 10.0551 11.9183 10.2155C11.8463 10.3566 11.7316 10.4713 11.5905 10.5433C11.4301 10.625 11.22 10.625 10.8 10.625H7.2C6.77996 10.625 6.56994 10.625 6.40951 10.5433C6.26839 10.4713 6.15365 10.3566 6.08174 10.2155C6 10.0551 6 9.84504 6 9.425V8.825C6 8.40496 6 8.19494 6.08174 8.03451C6.15365 7.89338 6.26839 7.77865 6.40951 7.70674C6.56994 7.625 6.77996 7.625 7.2 7.625Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ZInventory;
