import {Label,Input} from "../DataField.styles"

type SignatureFieldProps = {
    register:any;
}


const SignatureField = ({register}:SignatureFieldProps) => {
  return (
    <>
        {/* <Label>
            Default Value
        </Label>
        <Input type="text" {...register("defaultValue")} /> */}
    </> 
  )
}

export default SignatureField