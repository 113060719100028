import React from "react";
import PropTypes from "prop-types";

import GridItem from "./GridItem";
import "./styles.css";

const GridItemContainer = React.forwardRef(
  ({ title, type, data, children, key, item, ...props }, ref) => {
    return (
      <GridItem
        ref={ref}
        title={title}
        type={type}
        data={data}
        root={key}
        {...props}
      >
        {children}
      </GridItem>
    );
  }
);

export default GridItemContainer;
