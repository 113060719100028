import styled from "styled-components";
import {CSSProperties} from "react"
import { IconButton } from "@mui/material";

interface IText {
    fontSize: string;
    fontWeight: string;
    color: string;
}

interface IFlex {
    justifyContent ?: CSSProperties['justifyContent'];
    alignItems ?: CSSProperties['alignItems'];
    gap ?: string;
}

export const Text = styled.p<IText>`
    font-size: ${({ fontSize }) => fontSize};
    font-weight: ${({ fontWeight }) => fontWeight};
    color : ${({ color }) => color};
`

export const Flex = styled.div<IFlex>`
    display: flex;
    justify-content: ${({ justifyContent }) => justifyContent};
    align-items: ${({ alignItems }) => alignItems};
    gap: ${({ gap }) => gap};
`

export const ZIconButton2 = styled(IconButton)`
    border: 1px solid #D0D5DD !important;
    border-radius: 4px !important;
`

export const ColorPalets = {
    gray700 : "#344054",
    gray500 : "#667085",
    gray600 : "#475467",
    gray300 : "#D0D5DD",
    gray200 : "#EAECF0",
}

export const ViewModeHeader = styled.div`
     display: flex;
     justify-content: space-between;
     padding: 20px 40px;
`