import { Button as bt } from "@mui/material";
import styled from "styled-components";

interface IABListWrapper {
  selected: boolean;
}

export const ActionTypeHeading = styled.div`
  color: var(--Gray-500, #667085);

  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  margin-top: 24px;
  margin-bottom: 12px;
`;

interface IABNameText {
  selected: boolean;
}

interface IVerticalAlignWrapper {
  applyMargin: boolean;
}

export const Wrapper = styled.div`
  border-right: 1px solid #eaecf0;
  height: 100vh;
  overflow-y: auto;
`;
export const ABNameListWrapper = styled.div`
  border-right: 1px solid #eaecf0;
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 40%;
`;

export const AutomationPlaygroundWrapper = styled.div`
  border-right: 1px solid #eaecf0;
  height: 100vh;
  background-color: #f9fafb;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 90px;
  overflow-y: auto;
`;

export const ActionBlockIconPack = styled.div`
  display: flex;
  background-color: ${(props: { hoverState: Boolean }) =>
    props.hoverState === true ? "#3054B9" : "#FCFCFD"};
  border: 1px solid #eaecf0;
  border-bottom: 0px solid #eaecf0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  width: 90px;
  justify-content: space-around;
  flex-wrap: wrap;
`;

export const ActionParent = styled.div`
  background-color: #fff;
  border: ${(props: { hoverState: Boolean }) =>
    props.hoverState === true ? "1px solid #3054B9" : "1px solid #EAECF0"};
  border-radius: 4px;
  width: 100%;
  height: fit-content;
`;

export const ABSelectWrapper = styled.div`
  margin-top: 1rem;
`;

export const ActionContentBlock = styled.div`
  padding: 0px 10px 10px 10px;
  font-size: 12px;
`;

export const VerticalAlignWrapper = styled.div<IVerticalAlignWrapper>`
  display: flex;
  align-items: center;
  margin-top: ${(props: any) => (props.applyMargin == true ? "8px" : "0px")};
`;

export const ABNameText = styled.p<IABNameText>`
  color: ${(props: any) =>
    props.selected === true ? "#fff" : "rgba(0,0,0,0.5)"};
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
  margin-right: 5px;
`;

export const ABListWrapper = styled.div<IABListWrapper>`
  display: flex;
  justify-content: space-between;
  background-color: ${(props: any) =>
    props.selected === true ? props.theme.color.primary : ""};
  padding-top: 10px;
  padding-bottom: 10px;

  // cursor to pointer on hover
  &:hover {
    cursor: pointer;
    background-color: ${(props: any) =>
      props.selected !== true ? "#EBEFFF" : ""};
  }
`;

export const TriggerBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  overflow-x: auto;
  border: 1px solid #eaecf0;
  font-size: 12px;
  background-color: ${(props: {
    filledState: Boolean;
    errors: Boolean;
    selected: Boolean;
    NotSelected: Boolean;
  }) => (props.filledState == true ? "#FCFCFD" : "#f6f7fe")};
  border: ${(props: {
    filledState: Boolean;
    errors: Boolean;
    selected: Boolean;
    NotSelected: Boolean;
  }) =>
    props.errors === true
      ? "1px solid #F20C0C"
      : props?.selected
      ? `1px solid ${props.theme.color.primary} `
      : !props.filledState
      ? "1px dotted #9eb2fc"
      : ""};
  &:hover {
    background-color: ${(props: {
      filledState: Boolean;
      errors: Boolean;
      selected: Boolean;
      NotSelected: Boolean;
    }) => (props.NotSelected === false ? "#CFD9FE" : " #E0E4E8")};
  }
`;

export const UnSelectedText = styled.div`
  color: ${(props) => props.theme.color.primary};
  font-size: 12px;
  font-weight: 600;
`;

export const ActionInnerContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ActionTrigger = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CreateNewAutomationWrapper = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #eaecf0;
  padding-top: 12px;
  padding-bottom: 12px;
`;

export const AutomationEmptyText = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
  margin-left: 10px;
  margin-top: 1rem;
`;

export const Text = styled.p`
  font-size: 12px;
  font-weight: 400;
`;

export const ABText = styled.p`
  color: #000;

  /* Text xs/Regular */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
`;

export const TriggerCard = styled.div`
  padding: 10px;
  background-color: #fcfcfd;
  border: 1px solid #eaecf0;
  margin-bottom: 10px;
  border-radius: 4px;
  display: flex;
  gap:8px;
  align-items: flex-start;
  &:hover {
    cursor: pointer;
    background-color: #e0e4e8;
  }
`;

export const TriggerContainer = styled.div`
  align-items: center;
`;

export const TriggerImage = styled.img`
  height: 12px;
  margin-right: 8px;
`;

export const IconWrapper = styled.div`
  cursor: pointer !important;
  margin-left: 10px;
  margin-right: 10px;
`;

export const EditABNameButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

export const LabelWrapper = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const ABEntryListSectionWrapper = styled.div`
  background-color: #f2f2f2;
  margin-top: 20px;
  padding: 10px;
`;

export const ABEntryListWrapperText = styled.div`
  font-size: 12px;
  font-weight: 400;
`;

export const ABEntryListWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
`;

export const ABListText = styled.p`
  color: ${(props) => props.theme.color.primary};
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  margin-left: 10px;
`;

export const ABEntryButtonText = styled.span`
  margin-left: 8px;
  margin-top: -3px;
`;

export const ABDisabledText = styled.p`
  font-family: "Inter";
  font-style: normal;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
`;

export const SearchInput = styled.input`
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  width: 100%;
  padding: 8px;
  color: var(--Gray-500, #667085);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const ActionDescription = styled.p`
  color: var(--Gray-700, #344054);

  /* Text xs/Regular */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
`;

export const ActionLabel = styled.p`
  color: var(--Gray-700, #344054);

  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
`;

export const ABDisabledTextWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 12px;
  margin-left: 6px;
  margin-right: 16px;
  gap: 10px;
  width: 83px;
  height: 28px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    #c2c9d1;
  border: 1px solid #eaecf0;
  border-radius: 4px;
`;

export const ABTopTextComponent = styled.p`
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
`;

export const ABBlockButton = styled(bt)`
  &.MuiButton-contained {̦
    padding: 2px;
    font-size: ${(props: {
      secondary?: boolean;
      tertiary?: boolean;
      danger?: boolean;
      fontSize?: number;
    }) => (props?.fontSize ? `${props.fontSize}px` : "1rem")};
    line-height: 1.8rem;
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 4px;
    border: 1px solid;
    box-shadow: none !important;
    max-height: 4rem;
    min-width: auto;
    justify-content: center;
    background-color: ${(props: {
      special?: boolean;
      secondary?: boolean;
      tertiary?: boolean;
      danger?: boolean;
    }) => {
      return props.special
        ? ""
        : props.secondary
        ? "#FFFFFF"
        : props.tertiary
        ? "transparent"
        : props.danger
        ? "#F03738"
        : props.theme.color.primary;
    }};
    background: ${(props: {
      special?: boolean;
      secondary?: boolean;
      tertiary?: boolean;
      danger?: boolean;
    }) => {
      return props.special
        ? "linear-gradient(45deg, #6941C6 0%, #7F56D9 100%);"
        : props.secondary
        ? ""
        : props.tertiary
        ? ""
        : props.danger
        ? ""
        : "";
    }};
    border-color: ${(props: {
      special?: boolean;
      secondary?: boolean;
      tertiary?: boolean;
      danger?: boolean;
    }) => {
      return props?.special
        ? ""
        : props.secondary
        ? "#D0D5DD"
        : props.tertiary
        ? "transparent"
        : props.danger
        ? "#F03738"
        : props.theme.color.primary;
    }};
    color: ${(props: {
      secondary?: boolean;
      tertiary?: boolean;
      danger?: boolean;
    }) => {
      return props.secondary
        ? "#344054;"
        : props.tertiary
        ? props.theme.color.primary
        : props.danger
        ? "#fff"
        : "#fff";
    }};
    .MuiSvgIcon-root {
      font-size: 1.4rem !important;
    }
    &:hover,
    &:focus {
      background-color: ${(props: {
        secondary?: boolean;
        tertiary?: boolean;
        danger?: boolean;
      }) => {
        return props.secondary
          ? "#F9FAFA"
          : props.tertiary
          ? "#F5F7FF"
          : props.danger
          ? "#B4292A"
          : props.theme.color.secondary;
      }};
      border-color: ${(props: {
        secondary?: boolean;
        tertiary?: boolean;
        danger?: boolean;
      }) => {
        return props.secondary
          ? "#92979D"
          : props.tertiary
          ? "transparent"
          : props.danger
          ? "#B4292A"
          : props.theme.color.secondary;
      }};
    }
    &:active {
      background-color: ${(props: {
        secondary?: boolean;
        tertiary?: boolean;
        danger?: boolean;
      }) => {
        return props.secondary
          ? "#F0F2F3"
          : props.tertiary
          ? "#ECF0FF"
          : props.danger
          ? "#781C1C"
          : props.theme.color.secondary;
      }};
      border-color: ${(props: {
        secondary?: boolean;
        tertiary?: boolean;
        danger?: boolean;
      }) => {
        return props.secondary
          ? "#92979D"
          : props.tertiary
          ? "transparent"
          : props.danger
          ? "#781C1C"
          : props.theme.color.secondary;
      }};
    }
    &.Mui-disabled {
      background-color: ${(props: {
        secondary?: boolean;
        tertiary?: boolean;
        danger?: boolean;
      }) => {
        return props.secondary
          ? "#fff"
          : props.tertiary
          ? "transparent"
          : props.danger
          ? "#FDEBEB"
          : "#F0F2F3";
      }};
      border-color: ${(props: {
        secondary?: boolean;
        tertiary?: boolean;
        danger?: boolean;
      }) => {
        return props.secondary
          ? "#E1E4E8"
          : props.tertiary
          ? "transparent"
          : props.danger
          ? "#FDEBEB"
          : "#F0F2F3";
      }};
      color: ${(props: {
        secondary?: boolean;
        tertiary?: boolean;
        danger?: boolean;
      }) => {
        return props.secondary
          ? "#C2C9D1"
          : props.tertiary
          ? "#C2C9D1"
          : props.danger
          ? "#F79B9B"
          : "#92979D";
      }};
    }
    &.MuiButton-sizeSmall {
      font-size: 12px;
      font-weight: 600;
      padding: 0.8rem 1.6rem;
      line-height: 1.3rem;
      max-height: 3.2rem;
    }
`;
