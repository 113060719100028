import React, {useState, useRef, useEffect, memo} from "react";
import { BiUser } from "react-icons/bi";
import { CiImageOn, CiMap } from "react-icons/ci";
import { GoCode } from "react-icons/go";
import { RxText, RxSwitch, RxFileText } from "react-icons/rx";
import {
  DataFieldType,
  EntityTypeField,
} from "views/DataField/DataField.types";
import { TiSortNumerically } from "react-icons/ti";
import { Tooltip } from "@mui/material";
import { AutomationRIcon, CalendarRIcon, DropdownRIcon, HashRIcon, RelationRIcon, TextRIcon } from "assests"

type Props = {
  dataType:string
  params: any;
   name: string;
};

const getDataTypeIcon = (type: DataFieldType | EntityTypeField) => {
  switch (type) {
    case DataFieldType.TEXT:
      return <TextRIcon color="#667085" size="1.2rem" />;
    case DataFieldType.NUMBER:
      return <TiSortNumerically size="1.2rem" />;
    case DataFieldType.BOOLEAN:
      return <RxSwitch size="1.2rem" />;
    case DataFieldType.DATE:
      return <CalendarRIcon color="#667085" size="1.2rem" />;
    case DataFieldType.IMAGE:
      return <CiImageOn size="1.2rem" />;
    case DataFieldType.LIST_OF_TEXT:
      return <DropdownRIcon color="#667085" size="1.2rem" />;
    case DataFieldType.FILE:
      return <RxFileText size="1.2rem" />;
    case DataFieldType.LOCATION:
      return <CiMap size="1.2rem" />;
    case EntityTypeField.COMPUTED:
      return <GoCode size="1.2rem" />;
    case EntityTypeField.RELATION:
      return <RelationRIcon color="#667085" size="1.2rem" />;
    case EntityTypeField.USER:
      return <BiUser size="1.2rem" />;
    case EntityTypeField.BUTTON:
      return <AutomationRIcon color="#667085" />;
    case DataFieldType.ID:
      return <HashRIcon color="#667085" />;
    default:
      return <RxText size="1.2rem" />;
  }
};

const isTextOverflowing = (element: HTMLElement | null) => {
  return element ? element.scrollWidth > element.clientWidth : false;
};

const SystemFieldHeader = ({
  dataType,
  params,
  name
}: Props) => {
  const [isOverflow, setIsOverflow] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    if (textRef.current) {
      setIsOverflow(isTextOverflowing(textRef.current));
    }
  }, [params]);

  return (
    <div
      style={{
        display: "flex",
        width: "95%",
        gap: "8px",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "6px 0px",
      }}
      
    >
      <div
        style={{
          display: "flex",
          gap: "4px",
          alignItems: "center",
          width: "80%",
        }}
      >
        <Tooltip
          title={
            dataType === DataFieldType.LIST_OF_TEXT
              ? "Dropdown"
              : dataType
          }
        >
          <div>
            {getDataTypeIcon(
              dataType
            )}
          </div>
        </Tooltip>

        <Tooltip title={isOverflow ? name : ""}>
          <div
            ref={textRef}
            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: "100%" }}
          >
            {name}
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default memo(SystemFieldHeader);
