import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import ProgressBar from "./ProgressBar";
import uploadImageInstance from "./UploadImageInstance";
import { IZUploader } from "components/uploader/IZUploader";
import { useDispatch, useSelector } from "react-redux";
import { AiFillFolderAdd, AiFillFolderOpen } from "react-icons/ai";
import { RiDeleteBinLine } from "react-icons/ri";
import notification from "notifications/notifications";
import {
  Wrapper,
  IconContainer,
  InputComponent,
  UploadedFile,
  FileInfo,
  Name,
  FileGrid,
} from "./FileUpload.styles";
import { ComponentType } from "components/ComponentTypeEnum";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import HttpUtil from "config/AxiosConfig";

type Props = {
  config: IZUploader;
  onChange: Function;
  alreadyUploadedImages: Array<any>;
  isOpenedAsSubfield: boolean;
  removeFiles: Function;
};

const FileUpload = ({
  config,
  onChange,
  alreadyUploadedImages,
  isOpenedAsSubfield,
  removeFiles,
}: Props) => {
  const [progress, setProgress] = useState(0);

  const uploadFile = async (files: any[]) => {
    files.forEach(async (file) => {
      let formData = new FormData();
      formData.append("media", file);
      try {
        const response = await HttpUtil.bffInstance.post(
          "/task/3123123/media",
          formData,
          {
            onUploadProgress: (progressEvent) => {
              if (progressEvent) {
                let curProgress =
                  (progressEvent.loaded / (progressEvent?.total || 1)) * 100;
                setProgress(curProgress);
              }
            },
          }
        );
        onChange(config?.key, [response?.data?.data[0].path]);
        setProgress(0);
      } catch (e) {
        setProgress(0);
        notification("error", "Cannot upload image. Something went wrong");
        console.error(e);
      }
    });
  };

  const onDropAccepted = useCallback((acceptedFiles) => {
    uploadFile(acceptedFiles);
  }, []);

  const onDropRejected = useCallback((rejectedFile) => {
    rejectedFile.forEach((file: any) => {
      file.errors.forEach((error: any) => {
        notification("error", error.message);
      });
    });
  }, []);

  function maxFileUploadedValidator(file: any) {
    // Value is hard-coded to one as it only handles Signature now. In futute,
    // we have to replace it with value coming from config.
    let maxFiles = config?.maxNumber || 1;
    if (alreadyUploadedImages.length >= maxFiles) {
      return {
        code: "too-many-files",
        message: `Can't upload more than ${config.maxNumber || 1} files/images`,
      };
    }

    return null;
  }

  const getAcceptedFormat = (type: string): any => {
    if (type === ComponentType.IMAGE || type === ComponentType.SIGNATURE) {
      return {
        "image/*": [".png", ".gif", ".jpeg", ".jpg"],
      };
    } else {
      return {};
    }
  };

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    isDragActive,
  } = useDropzone({
    onDropAccepted,
    onDropRejected,
    multiple: config?.type === ComponentType.SIGNATURE ? false : true,
    maxFiles:
      config?.type === ComponentType.SIGNATURE ? 1 : config?.maxNumber || 100,
    validator: maxFileUploadedValidator,
    accept: getAcceptedFormat(config?.type),
  });

  return (
    <>
      <div {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
        <ZButton secondary variant="contained">
          {" "}
          Upload {config.type === ComponentType.FILE ? "Files" : "Images"}{" "}
        </ZButton>
      </div>
    </>
  );
};

export default FileUpload;
