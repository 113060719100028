import { Dispatch } from "redux";
import { TaskFlowService } from "../../providers/data/services/TaskFlowService";
import { generateId } from "../../utils/Utils";
import {
  setAllWorkflowError,
  setSelectedDecisionNode,
  setSelectedTransition,
  setWorkflowErrors,
  updateElements,
  initWorkflow,
  setIsWorkflowPublishedBefore,
} from "views/workflows/reducers/workflowAction";
import { initEditor, initMockData } from "lowcode/state/scriptEditorState";
import { NodeTypes } from "./nodes/Nodes.types";
import { getConfig } from "config/envConfig/Config";

import jwt_decode from "jwt-decode";
import { WorkflowValidationUtils } from "./WorkflowValidationUtils";
import { initializeSystemTransitions } from "./nodes/NodeUtils";
import { setError } from "views/reducer/validWorkflowErrorState";
import { TaskService } from "views/tasks/services/TaskService";

const getId = () => generateId(6);

export class WorkflowUtils {
  public static addNewStep = (
    taskTypeId: string,
    taskTypeName: string,
    dispatch: Dispatch,
    event: any,
    reactFlowWrapper: any,
    reactFlowInstance: any,
    elements: any,
    saveWorkflow: Function,
    nodeType: string
  ) => {
    const reactFlowBounds = reactFlowWrapper.getBoundingClientRect();
    // const type = event.dataTransfer.getData('application/reactflow');
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: Math.floor(Math.random() * 30) + 50 + reactFlowBounds.top,
    });
    const newNodeId = getId();

    let newNode: any = {};

    if (nodeType === NodeTypes.STATE_NODE) {
      newNode = {
        id: newNodeId,
        name: "UNNAMED_STEP",
        type: nodeType,
        position,
        data: {
          name: "UNNAMED_STEP",
          id: newNodeId,
          type: NodeTypes.STATE_NODE,
          isHomeNode: false,
          isTerminalNode: false,
        },
      };
    } else if (nodeType === NodeTypes.DECISION_NODE) {
      newNode = {
        id: newNodeId,
        name: "DECISION_NODE",
        type: nodeType,
        position,
        data: {
          name: "DECISION_NODE",
          id: newNodeId,
          type: "node",
          description: "",
          expression: "",
          handleInfo: [
            {
              id: "a",
              data: {
                name: "First Switch",
                conditions: [],
                actions: [],
              },
              type: "IF",
            },
            {
              id: "b",
              data: {
                name: "Else Switch",
                conditions: [],
                actions: [],
              },
              type: "ELSE",
            },
          ],
        },
      };
    }

    const updatedElements = elements.concat(newNode);
    dispatch(updateElements(updatedElements));
    //setElements((es) => es.concat(newNode));
    // dispatch(setSelectedNode(newNode));
    // setSelectedStateNode(newNode);

    const at: string = localStorage.getItem("at") as string;
    var decoded: any = jwt_decode(at);
    let accountId = decoded.accountId;

    WorkflowUtils.saveNewStep(
      accountId,
      taskTypeId,
      taskTypeName,
      newNode.id,
      newNode.name,
      saveWorkflow
    );
  };

  public static saveNewStep = (
    accountId: string,
    taskTypeId: string,
    taskTypeName: string,
    newNodeId: string,
    newNodeName: string,
    saveWorkflow: Function
  ) => {
    TaskFlowService.saveTaskState(
      accountId,
      taskTypeId,
      taskTypeName,
      newNodeId,
      newNodeName
    )
      .then((res) => {
        if (res?.code && res?.code.indexOf("200") < 0) {
        } else {
          saveWorkflow();
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  public static getTaskCardConfigSrc = (
    taskTypeId: string,
    showTaskCardConfig: any,
    accountId: string
  ) => {
    const url = getConfig("appDesigner");
    const accessToken = localStorage.getItem("at");
    if (showTaskCardConfig.isTaskCreate) {
      return `${url}/#/app-builder?at=${accessToken}&accountId=${accountId}&taskType=${taskTypeId}&isTaskCreate=true`;
    } else if (showTaskCardConfig.isTaskHistoryScreen) {
      return `${url}/#/app-builder?at=${accessToken}&accountId=${accountId}&taskType=${taskTypeId}&isTaskHistoryConfig=true`;
    } else if(showTaskCardConfig.isTableCardConfig) {
      return `${url}/#/tableCardConfig?at=${accessToken}&tableType=${taskTypeId}`;
    }
    return `${url}/#/taskCardConfig?at=${accessToken}&taskType=${taskTypeId}&isHistory=${showTaskCardConfig.isHistory}`;
  };

  public static validateWorkflow = async (
    dispatch: Dispatch,
    taskTypeId: string,
    selectedTransition: any,
    selectedDecisionNode: any,
    prevErrorState: boolean,
    store: any
  ) => {
    const { workflowData, isErrorPresent, allWorkflowErrors } =
      await WorkflowValidationUtils.validateWorkflow(taskTypeId, store);

    if (prevErrorState !== isErrorPresent) {
      dispatch(updateElements(workflowData.canvas.elements));
      initializeSystemTransitions(dispatch, workflowData);
      dispatch(setWorkflowErrors(workflowData.errors));
      dispatch(setError(isErrorPresent));
      dispatch(setAllWorkflowError(allWorkflowErrors));

      // This is done for real time update of error status in actions.
      // Time complexity is high and not preferable.
      workflowData.canvas?.elements?.forEach((element: any) => {
        if (element.id === selectedTransition?.id) {
          dispatch(setSelectedTransition(element));
        }
      });

      workflowData.canvas?.elements?.forEach((element: any) => {
        if (element.type === "zorpDecisionNode") {
          element.data.handleInfo.forEach((handle: any) => {
            if (handle?.id === selectedDecisionNode?.id) {
              dispatch(
                setSelectedDecisionNode({
                  ...selectedDecisionNode,
                  ...handle,
                })
              );
            }
          });
        }
      });
    }
  };

  public static checkElementTargetIsDecisionNode = (
    transitionTargetId: string,
    allScreenElements: any
  ) => {
    const checkTargetType = allScreenElements.find(
      (data: any) => data.id === transitionTargetId
    );

    const isTargetDecisionNode =
      checkTargetType?.type === NodeTypes.DECISION_NODE;

    return isTargetDecisionNode;
  };

  public static checkValidElementClick = (event: any) => {
    const blackListClassNames = [
      "node__contextMenu",
      "MuiDialog-scrollPaper",
      "no__nodeClick",
      "node__menuItem",
      "edit__nodeName",
    ];

    let validElement = true;
    blackListClassNames.forEach((className: string) => {
      if (event?.target?.classList.contains(className)) {
        validElement = false;
      }
    });

    return validElement;
  };

  public static initBuilder = (dispatch: Dispatch<any>, id: string) => {
    //@ts-ignore
    dispatch(initWorkflow(id));
    dispatch(initEditor(id));
    dispatch(initMockData({taskTypeId:id}));
    this.checkIfWorkflowHasBeenPublished(dispatch, id);
  };

  public static checkIfWorkflowHasBeenPublished = async (
    dispatch: Dispatch<any>,
    taskType: string
  ) => {
    try {
      const data: any = await TaskService.checkWorkflowStatus(taskType);
      if (data.code === "200") {
        dispatch(setIsWorkflowPublishedBefore(true));
      } else {
        dispatch(setIsWorkflowPublishedBefore(false));
      }
    } catch (error) {}
  };

  public static addNewNodeWithKey = (
    nodeId: string,
    nodeType: string,
    nodeName: string,
    dispatch: Dispatch,
    elements: any[]
  ) => {
    const type = nodeType;
    const position = { x: 350, y: 150 };
    const newNode = {
      id: nodeId,
      name: nodeName,
      description: "Please describe this event!",
      type,
      position,
      data: {
        name: nodeName,
        id: nodeId,
        type: "node",
        onChange: () => {},
        isHomeNode: false,
      },
    };

    const updatedElements = elements.concat(newNode);
    dispatch(updateElements(updatedElements));
    //setElements((es) => es.concat(newNode));
    // dispatch(setSelectedNode(newNode));
    // setSelectedStateNode(newNode);
  };
}
