import { Dialog } from '@material-ui/core';
import { Button, CircularProgress } from '@material-ui/core';
import notification from 'notifications/notifications';
import * as React from 'react';
import { Fragment, useState } from 'react';
import {
    Confirm,
    useDUpdateMany,
    useRefresh,
    useNotify,
    useUnselectAll,
} from 'react-admin';
import { TaskService } from '../services/TaskService';

const BulkDeleteActionButton = ({  resource, basePath, filterValues,  selectedIds  }) => {
    const [open, setOpen] = useState(false);
    const [executing, setExecuting] = React.useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    // const [updateMany, { loading }] = useUpdateMany(
    //     'posts',
    //     selectedIds,
    //     { views: 0 },
    //     {
    //         onSuccess: () => {
    //             refresh();
    //             notify('Posts updated');
    //             unselectAll('posts');
    //         },
    //         onFailure: error => notify('Error: posts not updated', { type: 'warning' }),
    //     }
    // );

    // React.useEffect(() => {
    //     alert("S :" + selectedIds);
    //     setSelIds(selectedIds);

    // }, [selectedIds]);

    const deleteTasks = async() => {
        // alert("Sel Rows : "+  selectedIds);
        const response = await TaskService.deleteBulkTask(Object.values(selectedIds));

        if (response && response.code === '200') {
          notification('success', response?.message);
        } else {
            notification('error', response?.message);
        }
        unselectAll(resource);
        refresh();
        setExecuting(false);
    };

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        setExecuting(true);
        deleteTasks();
        setOpen(false);
    };

    return (
        <Fragment>
            <Button label="Delete" disabled={executing} onClick={handleClick}>
                {executing ? <CircularProgress size={14} /> : 'Delete'}
            </Button>
 
            <Confirm
                isOpen={open}
                title="Delete Tasks"
                content="Are you sure you want to delete these tasks?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
}

export default BulkDeleteActionButton;