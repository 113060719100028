import React from 'react'
import Markdown from 'react-markdown'
import { useTheme } from 'styled-components'

type Props = {
    markdown: string
}

const SummaryComponent = ({markdown}: Props) => {
    const theme = useTheme()
  return (
    <Markdown
        components={
            {
                p: ({node, ...props}) => <p style={{fontSize: '14px', color:"#354055"}} {...props} />,
                h1: ({node, ...props}) => <h1 style={{fontSize: '14px', fontWeight:600, marginBottom:"8px", color:theme.color.primary}} {...props} />,
                h2: ({node, ...props}) => <h2 style={{fontSize: '14px',fontWeight:600,marginBottom:"8px", color:theme.color.primary}} {...props} />,
                h3: ({node, ...props}) => <h3 style={{fontSize: '14px',fontWeight:600,marginBottom:"8px"}} {...props} />,
                hr: ({node, ...props}) => <hr style={{border: '1px solid #E0E0E0', margin:"8px 0px"}} {...props} />,
                ul: ({node, ...props}) => <ul style={{fontSize: '14px', marginBottom:"8px"}} {...props} />,
                strong: ({node, ...props}) => <strong style={{fontWeight:600}} {...props} />,
                a: ({node, ...props}) => <a style={{color: '#3054B9', fontWeight:"500"}} target="_blank" rel="noopener noreferrer" {...props} />,
                li: ({node, ...props}) => <li style={{fontSize: '14px',color:"#354055", marginBottom:"8px"}} {...props} />,
            }
        }
    >{markdown}</Markdown>
  )
}

export default SummaryComponent