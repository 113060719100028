import notification from "notifications/notifications";
import { ZorpResponse } from "providers/data/models/ZorpResponse";
import { BffService } from "providers/data/services/BffService";
import { Dispatch } from "redux";
import { DataFieldType } from "views/DataField/DataField.types";
import { setApiResponse, setFetchingTestAPIResponse } from "views/workflows/reducers/workflowAction";

export interface IUrl {
    type: string;
    value: string;
    dataType: string;
}

interface IPerformCallArgs {
    url: IUrl;
    method: string;
    body?: any;
    headers?: any;
    query?: any;
    dispatch: Dispatch,
    mockData?: any

}

interface scriptValue {
    type : string;
    value:string;
    dataType:DataFieldType
}

interface IHeader {
    key: string;
    value: scriptValue;
}

export const performCall = async ({ url, headers, method, body, dispatch, mockData }: IPerformCallArgs) => {

    try {
        dispatch(setFetchingTestAPIResponse(true))
        const response: ZorpResponse | undefined = await BffService.testApi(url, headers, method, body, mockData)
        return response
    } catch (e) {
        console.log(e)
        return {
            data: {
                status: 400,
                statusText: "Something went wrong"
            }

        }
    }

}

// return an object with headers.key as object's key and headers.value as object's value
const modifyHeadersForApiActionTesting = (headers: [{ key: string, value: scriptValue }]) => {
    const modifiedHeaders: any = {}
    headers.forEach((header: IHeader) => {
        let headerValue:any;
        if (headers.length === 1) {
            if (header.key === "" && (header.value.value === "" || header.value?.value?.length === 0)) {
                return {}
            }
        }

        if (!header.value.value) {
            headerValue = []
        }else {
            headerValue = [header.value]
        }
        // if (typeof header.value === "string") {
        //     headerValue = [header.value]
        // } else {
            // headerValue = [...header.value]
        // }

        modifiedHeaders[header.key] = headerValue;
    })
    return modifiedHeaders
}

export const testAPI = async ({ url, method, headers, body, dispatch, mockData }: IPerformCallArgs) => {
    try {
        const modifiedHeaders = modifyHeadersForApiActionTesting(headers)
        if (!url) {
            notification("error", "Please enter a valid URL")
            return
        }
        const apiResponse = await performCall({ url, method, headers: modifiedHeaders, mockData, body, dispatch });
        dispatch(setApiResponse(apiResponse?.data))
        dispatch(setFetchingTestAPIResponse(false))

    } catch (error) {
        console.log(error)
        dispatch(setApiResponse({
            statusText: "The API call failed, please check your headers and body"
        }))
        return {
            "internal_message":"The API call failed, please check your headers"
        }
    }
}
