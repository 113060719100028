import React, { useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import { Button, ButtonGroup, Select, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextValidator, SelectValidator, ValidatorForm } from "react-material-ui-form-validator";
import notification from "../../../notifications/notifications";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 450,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  addButton: {
    margin: "10px 10px 10px 10px",
    width: "70px",
    float: "right",
  },
}));

export default function AddProductModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [sku, setSku] = React.useState("");
  const [productCategory, setProductCategory] = React.useState(null);
  const [qty, setQty] = React.useState(null);
  const [productId, setProductId] = React.useState(null);
  const [productName, setProductName] = React.useState("");
  const [productImageUrl, setProductImageUrl] = React.useState("");

  const closeModal = () => {
    props.onClose();
    setOpen(false);
  };

  useEffect(() => {
    ValidatorForm.addValidationRule("specialCharCheck", (value) => {
      return !/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/.test(value);
    });

    ValidatorForm.addValidationRule("requiredCheck", (value) => {
      if (value !== "") {
        return true;
      } else {
        return false;
      }
    });
  }, [sku, productName]);

  const addProduct = (e) => {
    e.preventDefault();
    if (!productCategory) {
      notification("error", "Product Category cannot be empty");
      // setOpen(false);
      // props.onClose();
    } else if (productCategory === "SERIALISED" && (!productId || productId.trim().length === 0)){
      notification("error", "Product Id cannot be empty");
    }
    else if (productCategory === "NON_SERIALISED" && (!qty || isNaN(parseInt(qty)) || parseInt(qty) <= 0)){
      notification("error", "Quantity needs to be a number, and greater than 0");
    }
    else {
      const addedProduct = {
        productCategory: productCategory,
        sku: sku,
        productId: productId,
        productName: productName,
        qty: qty,
        productImageUrl: productImageUrl,
      };
      if (productCategory === "SERIALISED")
        delete addedProduct.qty;
      else if (productCategory === "NON_SERIALISED"){
        addedProduct.qty = parseInt(addedProduct.qty);
        delete addedProduct.productId;
      }         
      props.onAdd(addedProduct);
      setOpen(false);
      props.onClose();
    }
  };

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={closeModal}
    >
      {/* <ValidatorForm useRef="form" onSubmit={addProduct}> */}
        <div style={modalStyle} className={classes.paper}>
          <h3>Add Product</h3>
          <SelectValidator
            style={{ height: "35px", marginTop: "10px", marginBottom: "20px" }}
            label="Category"
            autoFocus={true}
            onChange={(event) => setProductCategory(event.target.value)}
            name="Category"
            // value={sku}
            validators={["requiredCheck"]}
            errorMessages={[
              "This field is required"
            ]}
            variant="outlined"
            fullWidth
          >
            <MenuItem value="SERIALISED">Serialised</MenuItem>
            <MenuItem value="NON_SERIALISED">Non-Serialised</MenuItem>

          </SelectValidator>
          <TextValidator
            style={{ height: "35px", marginTop: "10px", marginBottom: "20px" }}
            label="SKU/Model"
            // autoFocus={true}
            onChange={(event) => setSku(event.target.value)}
            name="SKU"
            // value={sku}
            validators={["requiredCheck", "specialCharCheck"]}
            errorMessages={[
              "This field is required",
              "Special Characters not allowed",
            ]}
            variant="outlined"
            fullWidth
          />
          {(productCategory != null && productCategory === "SERIALISED") && <TextValidator
            style={{ height: "35px", marginTop: "10px", marginBottom: "20px" }}
            id="productId"
            label="Product Id"
            autoComplete={false}
            // value={productId}
            onChange={(event) => setProductId(event.target.value)}
            name="productId"
            variant="outlined"
            fullWidth
          />}
          <TextValidator
            style={{ height: "35px", marginTop: "10px", marginBottom: "20px" }}
            id="productName"
            label="Product Name"
            autoComplete={false}
            onChange={(event) => setProductName(event.target.value)}
            name="productName"
            // value={productName}
            validators={["requiredCheck", "specialCharCheck"]}
            errorMessages={["This field is required"]}
            variant="outlined"
            fullWidth
          />
          {(productCategory != null && productCategory === "NON_SERIALISED") && <TextValidator
            style={{ height: "35px", marginTop: "10px", marginBottom: "20px" }}
            id="qty"
            type="number"
            label="Quantity"
            autoComplete={false}
            onChange={(event) => setQty(event.target.value)}
            name="Quantity"
            // value={productName}
            validators={['minNumber:0', 'maxNumber:255', 'matchRegexp:^[0-9]$']}
            variant="outlined"
            fullWidth
          />}
          <TextValidator
            style={{ height: "35px", marginTop: "10px", marginBottom: "20px" }}
            id="productImageUrl"
            type="url"
            label="Image Url"
            // value={productImageUrl}
            autoComplete={false}
            onChange={(event) => setProductImageUrl(event.target.value)}
            name="productImageUrl"
            variant="outlined"
            fullWidth
          />
          <ButtonGroup size="small" variant="outlined">
            <Button
              onClick={(e) => addProduct(e)}
              // type="submit"
              style={{
                height: "40px",
                marginRight: "10px",
                width: "90px",
                marginTop: "10px",
              }}
              variant="contained"
              color="primary"
            >
              Add
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              variant="contained"
              onClick={closeModal}
              style={{ height: "40px", width: "90px", marginTop: "10px" }}
              color="secondary"
            >
              Cancel
            </Button>
          </ButtonGroup>
        </div>
      {/* </ValidatorForm> */}
    </Modal>

  );
}
