//@ts-nocheck
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isEqual } from "lodash";
import { BuilderService } from "providers/data/services/BuilderService";
import {
  IAutomation, IActionBlock,
  IAutomationReducerInitialState,
  IAutomationBuilderOtherConfigs
} from "views/automationBuilder/types/ABType";

const initialState: IAutomationReducerInitialState = {
  isOpen: false,
  payload: {},
  trigger: {},
  actions: [],
  conditions: [],
  guards: [],
  selectedTrigger: null, // This is similar to selectedAction in the workflow
  metadataType: null,
  otherConfigs: null,
  prevAutomations: null,
  saveAutomationHandler: null,
  isTaskCreateScreenAutomation:false,
  isTaskHistoryScreenAutomation:false,
  allAutomations: [],
  selectedAutomation: null,
  selectedConfiguration: null,
  selectedActionBlock: null,
  createAutomationDefault:null,
  errors: {},
  selectedCron:{}, // while editing the Scheduled automation, while clicking the automation edit button, data will be stored here
  selectedWebhook:{}, // while editing the webhook automation, while clicking the automation edit button, data will be stored
  selectedMonitorId:"",


  // temp array for dropdown
  actionDropdown: []
};

export const initAutomationBuilder = createAsyncThunk(
  "automationBuilder/initAutomationBuilder",
  async ({ workflowId, stateId, accountId, metadataType, otherConfigs, isTaskCreateScreenAutomation, isTaskHistoryScreenAutomation,
  }: {
    workflowId: string;
    accountId: string;
    stateId: string;
    metadataType: string;
    otherConfigs: IAutomationBuilderOtherConfigs;
    isTaskCreateScreenAutomation:boolean;
    isTaskHistoryScreenAutomation:boolean;
  }) => {
    try {
      const res = await BuilderService.getAutomationConfig({
        workflowId,
        stateId,
        accountId,
        metadataType,
        isTaskCreateScreenAutomation,
        isTaskHistoryScreenAutomation
      })
      return { ...res, otherConfigs };
    } catch (error) {
      console.log(">>", error);
      return {};
    }
  }
);

export const AutomationSlice = createSlice({
  name: "automation",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAutomationBuilderOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
      if (action.payload == false) {
        state.selectedAutomation = null;
      }
    },
    setTriggers: (state, action: PayloadAction<any>) => {
      state.trigger = action.payload;
    },
    setSelectedTrigger: (state, action: PayloadAction<any>) => {
      state.selectedTrigger = action.payload;
    },
    addAutomation: (state, action: PayloadAction<IAutomation>) => {
      state.allAutomations.push(action.payload);
    },
    updateAutomationName: (state, action: PayloadAction<{ automationName: string; automationId: string }>) => {
      const { automationName, automationId } = action.payload;

      const automationIdx = state.allAutomations.findIndex(
        (automation) => automation.id === automationId
      );
      state.allAutomations[automationIdx].name = automationName
    },
    deleteAutomation: (
      state,
      action: PayloadAction<{ automationId: string }>
    ) => {
      const { automationId } = action.payload;
      const getAutomationIndex = state.allAutomations.findIndex(
        (automation) => automation.id == automationId
      );
      state.allAutomations.splice(getAutomationIndex, 1);
    },
    setSelectedAutomation: (state, action: PayloadAction<IAutomation | null>) => {
      if (!isEqual(state.selectedAutomation, action.payload) && action.payload != undefined) {
        state.selectedAutomation = action.payload;
      }

      if(action.payload == null){
        state.selectedAutomation = null;
      }


    },
    addTriggerInAutomation: (
      state,
      action: PayloadAction<{ automationId: string; action: any }>
    ) => {
      // insert trigger in selected automation
      state.selectedAutomation?.triggers.push(action.payload.action);
    },
    deleteTriggerInAutomation: (
      state,
      action: PayloadAction<{ triggerId: string }>
    ) => {
      const { triggerId } = action.payload;
      // Delete Trigger in Selected Automation
      const getTriggerIndex = state.selectedAutomation?.triggers.findIndex(
        (trigger) => trigger.triggerId == triggerId
      );
      state.selectedAutomation?.triggers.splice(getTriggerIndex, 1);
      state.selectedTrigger = null;
    },
    addActionBlockInAutomation: (
      state,
      action: PayloadAction<{ automationId: string; action: any }>
    ) => {
      // insert action block in selected automation
      state.selectedAutomation?.actionBlocks.push(action.payload.action);
    },
    removeActionBlockInAutomation: (
      state,
      action: PayloadAction<{ actionBlockId: string }>
    ) => {
      const { actionBlockId } = action.payload;

      // remove action block from selected automation
      const getAutomationBlockIndex =
        state.selectedAutomation?.actionBlocks.findIndex(
          (automationBlock) => automationBlock.actionBlockId === actionBlockId
        );
      if (getAutomationBlockIndex !== undefined && getAutomationBlockIndex !== -1) {
        state.selectedAutomation?.actionBlocks.splice(
          getAutomationBlockIndex,
          1
        );
      }
    },
    rearrangeValidationInAutomation:(state, action: PayloadAction<{step: number, index: number, guardId:string}>)=>{
      const {step, index, guardId} = action.payload;
      state.selectedAutomation?.guards?.forEach((guards:any)=>{
        if(guards.guardId == guardId){
          const tempValue = state.selectedAutomation?.guards[index];
          state.selectedAutomation.guards[index] = state.selectedAutomation?.guards[index + step];
          state.selectedAutomation.guards[index  + step] = tempValue;
        }
      })
    },
    rearrangeActionBlockInAutomation: (state, action: PayloadAction<{ automationId: string; actionBlockId: string, index: number, step: number }>) => {
      const { index, step } = action.payload;

      // re-arrange in selected automation
      const saTempValue = state.selectedAutomation?.actionBlocks[index];
      state.selectedAutomation.actionBlocks[index] = state.selectedAutomation?.actionBlocks[index + step];
      state.selectedAutomation.actionBlocks[index + step] = saTempValue;

    },
    rearrangeActionOrConditionInActionBlock: (state, action: PayloadAction<{ automationId: string; actionBlockId: string, id: number, index: number, step: number, type: string }>) => {
      // id is actionId or conditionId
      const { actionBlockId, index, step, type } = action.payload;

      // rearrange in selected automation
      state.selectedAutomation?.actionBlocks.forEach((actionBlock: IActionBlock) => {
        if (actionBlock.actionBlockId === actionBlockId) {
          const tempValue = actionBlock[type][index];
          actionBlock[type][index] = actionBlock[type][index + step];
          actionBlock[type][index + step] = tempValue;
        }
      })
    },
    moveActionToAnotherActionBlock: (state,action: PayloadAction<{step:number,currentActionBlockIndex:number, currentActionIndex:number, type:string}>)=>{
      const {currentActionBlockIndex, step, currentActionIndex, type} = action.payload;

      // Logic is to Move the Action to the above block
      const tempValue = state.selectedAutomation?.actionBlocks[currentActionBlockIndex][type][currentActionIndex];

      const destinationActionBlockLength = state.selectedAutomation?.actionBlocks[currentActionBlockIndex + step][type].length;

      state.selectedAutomation.actionBlocks[currentActionBlockIndex + step][type][destinationActionBlockLength] = tempValue;

      // delete current Action
      state.selectedAutomation?.actionBlocks[currentActionBlockIndex][type].splice(currentActionIndex, 1);

    },
    addActionInAutomation: (
      state,
      action: PayloadAction<{
        automationId: string;
        actionBlockId: string;
        action: any;
      }>
    ) => {
      // update the info in allAutomations array

      // update the info in selectedAutomation
      state.selectedAutomation?.actionBlocks
        .filter(
          (actionBlock) =>
            actionBlock.actionBlockId === action.payload.actionBlockId
        )
        .forEach((actionBlock) =>
          actionBlock.actions.push(action.payload.action)
        );
    },
    updateActionInAutomation: (
      state,
      action: PayloadAction<{
        automationId: string;
        actionBlockId: string;
        actionId: string;
        data: any;
      }>
    ) => {
      // update action in allAutomation
      const automationIdx = state.allAutomations.findIndex(
        (automation) => automation.id === action.payload.automationId
      );
      if (automationIdx !== undefined) {
        const actionBlockIdx = state.allAutomations[
          automationIdx
        ].actionBlocks.findIndex(
          (actionBlock) =>
            actionBlock.actionBlockId === action.payload.actionBlockId
        );
        if (actionBlockIdx !== undefined) {
          const actionIdx = state.allAutomations[automationIdx].actionBlocks[
            actionBlockIdx
          ].actions.findIndex(
            (thisAction: any) => thisAction.actionId === action.payload.actionId
          );
          if (actionIdx !== undefined) {
            state.allAutomations[automationIdx].actionBlocks[
              actionBlockIdx
            ].actions[actionIdx] = action.payload.data;
          }
        }
      }

      // update action in selectedAutomation
      const idxToUpdate = state.selectedAutomation?.actionBlocks.findIndex(
        (actionBlock) =>
          actionBlock.actionBlockId === action.payload.actionBlockId
      );
      if (idxToUpdate !== undefined) {
        const actionBlockToUpdate = state.selectedAutomation?.actionBlocks[
          idxToUpdate
        ].actions.findIndex(
          (thisAction: any) => thisAction.actionId === action.payload.actionId
        );
        if (actionBlockToUpdate !== undefined) {
          state.selectedAutomation.actionBlocks[idxToUpdate].actions[
            actionBlockToUpdate
          ] = action.payload.data;
        }
      }
    },
    deleteActionInAutomation: (
      state,
      action: PayloadAction<{
        actionBlockId: string;
        actionId: string;
      }>
    ) => {
      const { actionId, actionBlockId } = action.payload;

      // Delete the Action in selectedAutomation
      state.selectedAutomation?.actionBlocks
        .filter((actionBlock) => actionBlock.actionBlockId == actionBlockId)
        .forEach((actionBlock) => {
          const getActionIndex = actionBlock.actions.findIndex(
            (action) => action.actionId === actionId
          );
          actionBlock.actions.splice(getActionIndex, 1);
        });
    },
    addConditionInAutomation: (
      state,
      action: PayloadAction<{
        actionBlockId: string;
        condition: any;
      }>
    ) => {
      // add new condition to selectedAutomation
      state.selectedAutomation?.actionBlocks
        .filter(
          (actionBlock) =>
            actionBlock.actionBlockId === action.payload.actionBlockId
        )
        .forEach((actionBlock) =>
          actionBlock.conditions.push(action.payload.condition)
        );
    },
    updateConditionInAutomation: (
      state,
      action: PayloadAction<{ automationId: string; actionBlockId: string; conditionId: string, condition: any }>
    ) => {
      const automationIdx = state.allAutomations.findIndex((automation) => automation.id === action.payload.automationId);
      if (automationIdx !== undefined) {
        const actionBlockIdx = state.allAutomations[automationIdx].actionBlocks.findIndex((actionBlock) => actionBlock.actionBlockId === action.payload.actionBlockId);
        if (actionBlockIdx !== undefined) {
          const actionIdx = state.allAutomations[automationIdx].actionBlocks[actionBlockIdx].conditions.findIndex((thisAction: any) => thisAction.conditionId === action.payload.conditionId)
          if (actionIdx !== undefined) {
            state.allAutomations[automationIdx].actionBlocks[actionBlockIdx].conditions[actionIdx] = action.payload.condition;
          }
        }
      }

      // update action in selectedAutomation
      const idxToUpdate = state.selectedAutomation?.actionBlocks.findIndex((actionBlock) => actionBlock.actionBlockId === action.payload.actionBlockId)
      if (idxToUpdate !== undefined) {
        const actionBlockToUpdate = state.selectedAutomation?.actionBlocks[idxToUpdate].conditions.findIndex((thisAction: any) => thisAction.conditionId === action.payload.conditionId)
        if (actionBlockToUpdate !== undefined) {
          state.selectedAutomation.actionBlocks[idxToUpdate].conditions[actionBlockToUpdate] = action.payload.condition
        }
      }
    },
    deleteConditionInAutomation: (
      state,
      action: PayloadAction<{
        actionBlockId: string;
        conditionId: string;
      }>
    ) => {
      const { conditionId, actionBlockId } = action.payload;

      // Delete the Condition in selectedAutomation
      state.selectedAutomation?.actionBlocks
        .filter((actionBlock) => actionBlock.actionBlockId == actionBlockId)
        .forEach((actionBlock) => {
          const getConditionIndex = actionBlock.conditions.findIndex(
            (condition) => condition.conditionId == conditionId
          );
          actionBlock.conditions.splice(getConditionIndex, 1);
        });
    },
    addGuardInAutomation: (
      state,
      action: PayloadAction<{
        guard: any;
      }>
    ) => {
      // add new Guard to selectedAutomation
      state.selectedAutomation?.guards.push(action.payload.guard);
    },
    updateGuardInAutomation: (state, action: PayloadAction<{ guardId: string, guardData: any }>) => {
      const { guardData, guardId } = action.payload;

      if (state.selectedAutomation) {

        let toBeUpdatedIndex = state?.selectedAutomation?.guards?.findIndex(
          (item) => item.guardId === guardId
        );

        if (toBeUpdatedIndex !== -1) {
          state.selectedAutomation.guards[toBeUpdatedIndex] = {
            ...guardData,
          };
        }

      }
    },
    deleteGuardInAutomation: (state, action: PayloadAction<{ guardId: string }>) => {
      const { guardId } = action.payload;

      if (state.selectedAutomation) {
        let toBeUpdatedIndex = state?.selectedAutomation?.guards?.findIndex(
          (item) => item.guardId === guardId
        );

        if (toBeUpdatedIndex !== -1) {
          state?.selectedAutomation?.guards.splice(toBeUpdatedIndex, 1);
        }

      }
    },
    updateAllAutomations: (state) => {
      const selectedAutomationId = state.selectedAutomation?.id;
      let toBeUpdatedIndex = state?.allAutomations?.findIndex(
        (item) => item.id === selectedAutomationId
      );

      if (toBeUpdatedIndex !== -1) {
        state.allAutomations[toBeUpdatedIndex] = {
          ...state.selectedAutomation,
        };
      }
    },
    updateTriggerInSelectedAutomation: (
      state,
      action: PayloadAction<any>
    ) => {
      if (state.selectedAutomation) {
        let toBeUpdatedIndex = state?.selectedAutomation?.triggers?.findIndex(
          (item) => item.triggerId === state.selectedTrigger.triggerId
        );

        if (toBeUpdatedIndex !== -1) {
          state.selectedAutomation.triggers[toBeUpdatedIndex] = {
            ...action.payload,
          };
        }
      }
    },
    deleteTrigger: (state, action: PayloadAction<{ triggerId: string }>) => {
      if (state.selectedAutomation) {
        let toBeUpdatedIndex = state?.selectedAutomation?.triggers?.findIndex(
          (item) => item.triggerId === action.payload.triggerId
        );

        if (toBeUpdatedIndex !== -1) {
          state.selectedAutomation.triggers.splice(toBeUpdatedIndex, 1);
        }
      }

      state.selectedTrigger = null;
    },
    closeAutomation: (state) => {
      state.selectedActionBlock = null;
      state.selectedAutomation = null;
      state.selectedTrigger = null;
      state.trigger = [];
      state.actions = [];
      state.conditions = [];
      state.guards = [];
      state.metadataType = null;
      state.selectedConfiguration = null;
      state.otherConfigs = null;
      state.allAutomations = [];
      state.isOpen = false;
    },
    // selectedConfiguration is used to determine what to show in right side
    setSelectedConfiguration: (
      state,
      action: PayloadAction<{ type: "action" | "condition" | "guard" | "trigger" | null }>
    ) => {
      state.selectedConfiguration = action.payload.type;
    },
    setSelectedActionBlock: (
      state,
      action: PayloadAction<{ actionBlock: any }>
    ) => {
      state.selectedActionBlock = action.payload.actionBlock;
    },
    resetAutomation: (state, action: PayloadAction<any>) => {
      state.allAutomations = action.payload;
    },
    setPrevAutomations: (state, action) => {
      state.prevAutomations = action.payload;
    },
    setCreateAutomationDefault: (state, action) => {
      // this is to create new automation automatically , when on load automation is opened from flutter
      state.createAutomationDefault = action.payload;
    },
    setAutomationHandler: (state, action) => {
      state.saveAutomationHandler = action.payload;
    },
    setAllErrors: (state, action) => {
      state.errors = action.payload;
    },
    setActionPayload: (state, action) => {
      state.actionDropdown = action.payload;
    },
    setTaskCreateScreenAutomation : (state,action) =>{
      state.isTaskCreateScreenAutomation = action.payload;
    },
    setTaskHistoryScreenAutomation : (state,action) =>{
      state.isTaskHistoryScreenAutomation = action.payload;
    },  
    setSelectedCron : (state,action) =>{
      state.selectedCron = action.payload;
    },   
    setSelectedWebHook : (state,action) =>{
      state.selectedWebhook = action.payload;
    },
    setSelectedMonitorId : (state,action) =>{
      state.selectedMonitorId = action.payload;
    }
  },
  extraReducers: (automationBuilder) => {
    automationBuilder
      .addCase(initAutomationBuilder.fulfilled, (state, action) => {
        state.trigger = action?.payload?.data?.trigger || {};
        state.actions = action?.payload?.data?.actions || [];
        state.conditions = action?.payload?.data?.conditions || [];
        state.guards = action?.payload?.data?.guards || [];
        state.metadataType = action?.payload?.data?.metadataType || null;
        state.otherConfigs = action?.payload?.otherConfigs || null;
      })
      .addCase(initAutomationBuilder.rejected, (state, action) => {
      })
      .addCase(initAutomationBuilder.pending, (state, action) => {
      });
  },
});

export const {
  updateTriggerInSelectedAutomation,
  updateAllAutomations,
  setAutomationBuilderOpen,
  addConditionInAutomation,
  setSelectedTrigger,
  addAutomation,
  deleteAutomation,
  setSelectedAutomation,
  addActionInAutomation,
  rearrangeActionOrConditionInActionBlock,
  deleteConditionInAutomation,
  deleteActionInAutomation,
  addActionBlockInAutomation,
  removeActionBlockInAutomation,
  addTriggerInAutomation,
  rearrangeActionBlockInAutomation,
  setSelectedCron,
  setSelectedWebHook,
  setTaskCreateScreenAutomation,
  setTaskHistoryScreenAutomation,
  deleteTriggerInAutomation,
  rearrangeValidationInAutomation,
  setSelectedConfiguration,
  setSelectedActionBlock,
  updateActionInAutomation,
  addGuardInAutomation,
  updateGuardInAutomation,
  deleteGuardInAutomation,
  updateConditionInAutomation,
  setPrevAutomations,
  setCreateAutomationDefault,
  setAutomationHandler,
  moveActionToAnotherActionBlock,
  closeAutomation,
  resetAutomation,
  updateAutomationName,
  setAllErrors,
  setActionPayload,
  setSelectedMonitorId
} = AutomationSlice.actions;

export default AutomationSlice.reducer;
