//@ts-nocheck
import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import FilterLoading from "assests/filter_loading.gif";
import SearchFilter from "assests/search_filter.svg";
import { IFilterContext } from 'filter/constants/Filter.types';
import { useEffect, useState } from 'react';
import { FilterAddModalHeading, FilterAddModalListName, FilterAddModalListNameDisabled, FilterAddModalSubHeading, FilterGroupListWrapper } from './FilterStyledComponents';


interface IFilterAddModal {
    openModal: boolean;
    handleClose: () => void;
    onSelect: (data: any) => void;
    filterMeta: any;
    position: number[];
    appliedWorkFlowFilter: any;
    context: IFilterContext;
    invokeIndex: number;
    loading: boolean;
}

const FilterAddModal = ({ openModal, handleClose, filterMeta, onSelect, position, appliedWorkFlowFilter = [], context, invokeIndex, loading }: IFilterAddModal) => {
    const [searchData, setSearchData] = useState([]);

    useEffect(() => {
        setSearchData(getReactFilterData());
    }, [filterMeta.length, appliedWorkFlowFilter?.length])


    const getReactFilterData = () => {
        // adding disbled for the applied filter
        const defaultFilterMeta =  filterMeta.map((filterData)=>{
            filterData.options?.map((data)=>{
                const findAppliedFilter = appliedWorkFlowFilter.find((appliedData) => appliedData.filterKey === data.value)
                    if(findAppliedFilter){
                        data.isDisabled = true;
                    }else {
                        data.isDisabled = false;
                    }

            })

            return filterData;
        })

        if (context != IFilterContext.TASK) {
            return defaultFilterMeta;
        } else {
            const filterAppliedIndex = appliedWorkFlowFilter.findIndex((data) => data.filterKey === 'taskTypes');
            const checkWorkFlowFilterApplied = appliedWorkFlowFilter.find((data) => data.filterKey === 'taskTypes');
            if (checkWorkFlowFilterApplied && filterAppliedIndex >= 0 && invokeIndex > filterAppliedIndex) {
                let selectedWorkFlowData = appliedWorkFlowFilter.find((data) => data.filterKey === 'taskTypes')?.filterValue?.map((data) => data.value);

                if(!selectedWorkFlowData?.length || selectedWorkFlowData?.length === 0){
                    return defaultFilterMeta;
                }

                selectedWorkFlowData.unshift('Universal_Filters');

                const filteredData = defaultFilterMeta.filter((data) => selectedWorkFlowData?.includes(data.workFlowId))
                return filteredData;
            } else {
                return defaultFilterMeta;
            }
        }
    }


    function searchABTests(json: ABTest[], searchString: string): ABTest[] {
        const searchResults: ABTest[] = [];
        const resultMap: Map<string, ABTest> = new Map();

        // Search in the label first
        for (const abTest of json) {
            if (abTest.label.toLowerCase().includes(searchString.toLowerCase())) {
                searchResults.push(abTest);
            } else {
                // If not found in the label, search in the options

                if (abTest?.options?.length > 0) {

                for (const option of abTest.options) {
                    if (option.value.toLowerCase().includes(searchString.toLowerCase()) ||
                        option.label.toLowerCase().includes(searchString.toLowerCase())) {
                        // Create or update the parent object in the Map with the matching option
                        const existingParent: ABTest | undefined = resultMap.get(abTest.workFlowId);
                        if (existingParent) {
                            existingParent.options.push(option);
                        } else {
                            const parentCopy: ABTest = {
                                label: abTest.label,
                                options: [option],
                                workFlowId: abTest.workFlowId
                            };
                            resultMap.set(abTest.workFlowId, parentCopy);
                        }
                    }
                }
            }


            }
        }

        // Add the results from the Map to the searchResults array
        for (const result of resultMap.values()) {
            searchResults.push(result);
        }

        return searchResults;
    }

    return (
        <Modal
            open={openModal}
            // hideBackdrop
            BackdropProps={{ style: { backgroundColor: 'transparent' } }}
            onClose={() => {
                handleClose()
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={
                {
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    position: 'absolute' as 'absolute',
                    boxShadow: '0px 3px 6px rgba(140, 152, 164, 0.25)',
                    bgcolor: '#FCFCFD',
                    border: '1px solid #F2F2F2',
                    borderRadius: '8px',
                    left: {
                        xs: '0%',
                        sm: `${position[0]}px`
                    },
                    right: {
                        xs: '0%',
                        sm: '0%',
                        md: '10%'
                    },
                    top: {
                        xs: '0%',
                        sm: position[1] <= 450 ? `${position[1] + position[2]}px` : '30%',
                        md: position[1] <= 450 ? `${position[1] + 100 + position[2]}px` : '30%'
                    },
                    width: '450px',
                    height: filterMeta.length <= 5 ? 'fit-content' : '450px',
                }
            }>

                <div className="" style={{ position: 'sticky', top: '0%', width: '100%', backgroundColor: '#FCFCFD' }}>

                    <FilterAddModalHeading>Filters</FilterAddModalHeading>

                    <TextField style={{ width: '100%', backgroundColor: '#F0F1F3', height: '32px', paddingTop: '4px', paddingBottom: '6px', marginTop: '0px' }} placeholder='Search Filters'
                        variant="standard" // <== changed this
                        margin="normal"
                        required
                        fullWidth
                        autoFocus
                        onChange={(e) => {
                            const filterData = searchABTests(getReactFilterData(), e.target.value);
                            setSearchData(filterData)
                        }}
                        InputProps={{
                            startAdornment: <img style={{ paddingLeft: '18.5px', paddingRight: '10.5px' }} src={SearchFilter} />
                            , // <== adjusted this
                            disableUnderline: true, // <== added this
                        }}
                    />
                </div>

                {
                    !loading &&
                    <div className='filter-list-datafields' 
                    style={{
                        height: '356px',
                        overflowY: 'scroll'
                    }}>
                        {
                            searchData.map((data: any) => {
                                return (
                                    <>

                                    {
                                        (data.options?.length > 0) &&
                                        <FilterGroupListWrapper>

                                            <FilterAddModalSubHeading>{data.label}</FilterAddModalSubHeading>

                                            {
                                                data.options.map((optionData) => {
                                                    return (
                                                        <>
                                                        {
                                                            (optionData?.isDisabled == false || optionData?.isDisabled == undefined || !optionData) &&
                                                            <FilterAddModalListName className='filter-hover' onClick={() => {
                                                                setSearchData(getReactFilterData());
                                                                onSelect(optionData)
                                                            }}>{optionData.label}</FilterAddModalListName>
                                                        }

                                                        {
                                                            optionData.isDisabled == true &&
                                                            <FilterAddModalListNameDisabled>{optionData.label}</FilterAddModalListNameDisabled>
                                                        }
                                                        </>
                                                    )
                                                })
                                            }
                                        </FilterGroupListWrapper>
                                    }


                                    {(!data?.options) &&
                                        <div className='filter-hover' style={{
                                            color: "#7E7E7F",
                                            paddingLeft: '24px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px'
                                        }}
                                            onClick={() => {
                                                setSearchData(getReactFilterData());
                                                onSelect(data)
                                            }}
                                        >
                                            <p>{data.label}</p>
                                        </div>
                                    }



                                    </>
                                )
                            })
                        }
                    </div>
                }

                {
                    loading &&
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img style={{ height: '40px', width: '40px' }} src={FilterLoading} alt="animated gif" />
                    </div>
                }
            </Box>
        </Modal>
    )
}

export default FilterAddModal