import {Label,Input} from "../DataField.styles"

type ImageFieldProps = {
    register:any;
}


const ImageField = ({register}:ImageFieldProps) => {
  return (
    <>
        {/* <Label>
            Default Value
        </Label>
        <Input type="text" {...register("defaultValue")} /> */}
    </> 
  )
}

export default ImageField