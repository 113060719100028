import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";
import { forOwn, isEqual } from "lodash";
import notification from "notifications/notifications";
import { RoleService } from "providers/data/services/RoleService";
import { TaskFlowService } from "providers/data/services/TaskFlowService";
import { removeElements } from "react-flow-renderer";
import { generateId } from "utils/Utils";
import { DataFieldType } from "views/DataField/DataField.types";
import { NodeTypes } from "../nodes/Nodes.types";

const getId = () => generateId(6);

const initialState = {
  showNodeDropdown: false,
  showPublishModal: false,
  isContextMenuVisible: false,
  showDataFieldModal: false,
  showDataFieldCreateModal: false,
  showDataFieldEditModal: false,
  showUpdateDecisionNodeModal: false,
  showUpdateNodeNameModal: false,
  showCreateDatafieldModalSingle: false,
  showEditDatFieldModalSingle: false,
  showWorkflowConfigModal: false,
  allWorkflowErrors: [],
  isWorkflowPublishedBefore: false,
  showErrorTooltip: {
    choiceNode: {},
  },
  showTaskCardConfig: {
    show: false,
    isHistory: false,
    isTaskCreate:false,
    isTaskHistoryScreen:false
  },
  flutterTaskCardConfig: {
    isHistory: false,
    isTaskCreate:false,
    isTaskHistoryScreen:false
  },
  workflow: {
    new: false,
    updateTeam: {
      assignActions: [],
      assignConditions: [],
      unassignActions: [],
      unassignConditions: [],
    },
    updateSlot: {
      actions: [],
      scheduleConditions: [],
      conditions: [],
      unscheduleConditions: [],
    },
    updateTask: {
      actions: [],
      conditions: [],
    },
    createTask: {
      actions: [],
    },
    deleteTask: {
      actions: [],
      conditions: [],
    },
    selectedSystemTransition: {},
    appTaskCreateConfig: {
      isTaskCreateEnable: true,
      displayName: "Create Task",
      visibleForRoles:["admin"]
    },
    selectedNode:{},
    possibleHomeNodes: [],
    possibleTerminalNodes:[],
  },
  dataFieldType: {
    dataType: DataFieldType.TEXT,
    key: "",
    subFieldDataType: null,
    listName: null,
  },
  postedWorkflowInfo:{
    setPostPublish: false,
    postedWorkflow: "",
    postedWorkflowId: ""
  },
  deleteIconOnTransition: {
    posX: null,
    posY: null,
    transition: {},
    onStrip: false,
  },
  workflowNotFound: false,
  showWorkflowErrors: false,
  changesInFlutter: false,
  apiResponse:{},
  fetchingTestAPIResponse: false,
  isSaveWorkflowCalledAtleastOnce: false,
  dataPicker: {
    isOpened: false,
    name:"",
    dataType:""
  },
  systemTransitionAutomation : [],
  screenTransitionAutomation : [],
  showPreviewModal: false,
};

export const initWorkflow = createAsyncThunk(
  "builder/fetchWorkflow",
  async (taskTypeId) => {
    // For new workflow, generate a new id and scaffold the basic params for the new workflow
    
    // Fetch roles to populate the visibleForRoles in appTaskCreateConfig
    
    let roleList = []
    try {
      const allRoles = await RoleService.listRoles()
      roleList = allRoles.data.map((role) => role.roleId)
    } catch (error) {
      console.log(error)
    }
    
    if (!taskTypeId) {
      return await new Promise((resolve) => {
        const newTaskTypeId = getId();
        const at = localStorage.getItem("at");
        const decoded = jwt_decode(at);
        resolve({
          taskType: newTaskTypeId,
          new: true,
          taskTypeDisplayName: `Workflow- ${decoded?.name} ${newTaskTypeId}`,
          taskTypeDescription: "This workflow is meant for ....",
          creationInfo: {
            from : "BUILDER",
            fromId: ""
          },
          isFirstLoad: false,
          updateTeam: {
            assignActions: [],
            assignConditions: [],
            unassignActions: [],
            unassignConditions: [],
          },
          updateSlot: {
            actions: [],
            scheduleConditions: [],
            conditions: [],
            unscheduleConditions: [],
          },
          updateTask: {
            actions: [],
            conditions: [],
          },
          createTask: {
            actions: [],
          },
          deleteTask: {
            actions: [],
            conditions: [],
          },
          workflowStatus: "draft",
          appTaskCreateConfig: {
            isTaskCreateEnable: true,
            displayName: `Workflow- ${decoded?.name} ${newTaskTypeId}`,
            visibleForRoles:roleList
      
          }
        });
      });
    }
    // For an existing workflow, fetch the details of the workflow from the backend API
    else {
        let workflowNotLoaded = false;
        const response = await TaskFlowService.getWorkflow(taskTypeId)
        .then((res) => {
          if (res?.code && res?.code.indexOf("200") < 0) {
            if (res?.code === "401") {
              notification("error", res.message);
            } else {
              notification("error", res.message);
            }
          } else {
            return res.data;
          }
        })
        .catch((err) => {
          console.log("Error", err);
          workflowNotLoaded = true;
        });
        
      return { ...response, isFirstLoad: true, roleList,workflowNotLoaded };
        
      
    }
  }
);

export const workflowSlice = createSlice({
  name: "builder",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setTaskTypeNameDescription: (state, action) => {
      if (state.workflow) {
        state.workflow.taskTypeDisplayName = action.payload.taskTypeName;
        state.workflow.taskTypeDescription = action.payload.taskTypeDescription;
      }
    },
    setWorkflowErrors: (state, action) => {
      if (state.workflow) {
        state.workflow.errors = action.payload;
      }
    },
    updateElements: (state, action) => {
      // Make updates to the state only if the incoming action payload is different
      // This will save the unnecessary updates
      if (!isEqual(state?.workflow?.canvas?.elements, action?.payload)) {
        if (state.workflow && state.workflow.canvas)
          state.workflow.canvas.elements = action.payload;
        else {
          state.workflow.canvas = {
            elements: action.payload,
          };
        }
      }
    },
    setSelectedNode: (state, action) => {
      state.workflow.selectedNode = action.payload;
    },
    setSelectedTransition: (state, action) => {
      state.workflow.selectedTransition = action.payload;
    },
    setSelectedAction: (state, action) => {
      
      // if(state.workflow.selectedAction === null || state.workflow.selectedAction === {}){
      //   // add a key "hasChanged" in the action payload
      //   if(action && action.payload) action.payload.hasChanged = false;
      // } 
      // else {
      //   if(action && action.payload) action.payload.hasChanged = true
      //   // action.payload?.hasChanged === undefined  ? true : !!action.payload?.hasChanged;
      // }
      
      state.workflow.selectedAction = action.payload;
      if(action.payload == null){
        state.workflow.selectedSystemTransition = null;
      }
    },
    setIsBeingSaved: (state, action) => {
      if (state.workflow) state.workflow.isBeingSaved = action.payload;
    },
    updateNodeName: (state, action) => {
      const updatedNode = action.payload;
      if (state.workflow && state.workflow?.canvas)
        state.workflow.canvas?.elements?.forEach((item) => {
          if (item.id === updatedNode.status) {
            item.data.name = updatedNode.statusName;
            item.name = updatedNode.statusName;
          }
        });
    },
    deleteNode: (state, action) => {
      const deletedStateNode = action.payload;
      if (state.workflow && state.workflow?.canvas) {
        state.workflow.canvas.elements = removeElements(
          [deletedStateNode],
          state.workflow?.canvas?.elements
        );
        state.workflow.selectedNode = null;
      }
    },
    deleteTransition: (state, action) => {
      const deletedTransition = action.payload;
      if (state.workflow && state.workflow?.canvas) {
        state.workflow.canvas.elements = removeElements(
          [deletedTransition],
          state.workflow?.canvas?.elements
        );
        state.workflow.selectedTransition = null;
        state.deleteIconOnTransition = {
          posX: null,
          posY: null,
          transition: {},
          strip: false,
        };
      }
    },
    setTransitionEventName: (state, action) => {
      if (state.workflow && state.workflow?.canvas) {
        state.workflow?.canvas.elements
          .filter((ele) => ele.id === action?.payload?.transition.id)
          .forEach((ele) => {
            ele.label = action.payload.eventName;
            ele.data.event = action.payload.eventName;
            // Modify the selected Transition
            state.workflow.selectedTransition =
              state.workflow?.canvas.elements.find(
                (ele) => ele.id === action?.payload?.transition.id
              );
          });
      }
    },
    setTransitionLocationCollection: (state, action) => {
      if (state.workflow && state.workflow?.canvas) {
        state.workflow?.canvas.elements
          .filter((ele) => ele.id === action?.payload?.transition.id)
          .forEach((ele) => {
            ele.data.collectLocation = action?.payload?.collectLocation;
            // Modify the selected Transition
            state.workflow.selectedTransition =
              state.workflow?.canvas.elements.find(
                (ele) => ele.id === action?.payload?.transition.id
              );
          });
      }
    },
    addAction: (state, action) => {
      if (state.workflow && state.workflow?.canvas) {
        state.workflow?.canvas.elements
          .filter((ele) => ele.data?.id === action?.payload?.transition.data.id)
          .forEach((ele) => {
            if (!ele?.data?.actions) ele.data.actions = [];
            ele.data.actions.push(action?.payload?.action);

            // Modify the selected action
            state.workflow.selectedAction = action?.payload?.action;

            // Modify the selected Transition
            state.workflow.selectedTransition =
              state.workflow?.canvas.elements.find(
                (ele) => ele.id === action?.payload?.transition.id
              );
          });
      }
    },
    addGuard: (state, action) => {
      if (state.workflow && state.workflow?.canvas) {
        state.workflow?.canvas.elements
          .filter((ele) => ele.data?.id === action?.payload?.transition.data.id)
          .forEach((ele) => {
            if (!ele?.data?.guards) ele.data.guards = [];
            ele.data.guards.push(action?.payload?.guard);

            // Modify the selected action
            // TODO: Shooul we have selectedGuard and selectedAction seperate?
            // This will mean that a same instance we can have both guard and actins
            // as selected . But this doesn;t make sense as per our current designs.
            state.workflow.selectedAction = action?.payload?.guard;

            // Modify the selected Transition
            state.workflow.selectedTransition =
              state.workflow?.canvas.elements.find(
                (ele) => ele.id === action?.payload?.transition.id
              );
          });
      }
    },
    updateGuard: (state, action) => {
      if (state.workflow && state.workflow?.canvas) {
        // Modify the action details in the state
        let tempState = JSON.parse(JSON.stringify(state));
        tempState?.workflow?.canvas.elements
          .filter((ele) => ele.id === action?.payload?.transitionId)
          .forEach((ele) => {
            ele?.data?.guards
              ?.filter((a) => a.guardId === action?.payload?.guardId)
              .forEach((a) => {
                forOwn(action.payload, (value, key) => (a[key] = value));

                // Modify the selected action
                tempState.workflow.selectedAction = JSON.parse(
                  JSON.stringify(a)
                );
              });
          });

        // Modify the selected Transition
        tempState.workflow.selectedTransition =
          tempState.workflow?.canvas.elements.find(
            (ele) => ele.id === action?.payload?.transitionId
          );
        return tempState;
      }
    },
    removeGuard: (state, action) => {
      if (state.workflow && state.workflow?.canvas) {
        // Modify the action details in the state
        state.workflow?.canvas.elements
          .filter((ele) => ele.id === action?.payload?.transition.id)
          .forEach((ele) => {
            const actionToBeRemovedIndex = ele?.data?.guards?.findIndex(
              (a) => a.guardId === action?.payload?.guard?.guardId
            );
            if (actionToBeRemovedIndex >= 0) {
              ele?.data?.guards?.splice(actionToBeRemovedIndex, 1);
              // Modify the selected action
              delete state.workflow.selectedAction;
            }

            // ele?.data?.actions?.filter((a) => a.actionId === action?.payload?.actionId)
            //     .forEach((a) => {
            //         a.actionName = action.payload.actionName;
            //         a.headers = action.payload.headers;
            //         a.queryParams = action.payload.queryParams;
            //         a.body = action.payload.body;
            //         a.method = action.payload.method;
            //         a.url = action.payload.url;

            //         // Modify the selected action
            //         state.workflow.selectedAction = null;
            //     })
          });

        // Modify the selected Transition
        state.workflow.selectedTransition =
          state.workflow?.canvas.elements.find(
            (ele) => ele.id === action?.payload?.transition.id
          );
        // state.workflow.selectedAction = null;
      }
    },
    updateHome: (state, action) => {
      const homeNode = action.payload;

      if (state.workflow && state.workflow?.canvas)
        state.workflow?.canvas?.elements.map((ele) => {
          if (ele.id === homeNode) {
            ele.data.isHomeNode = true;
          } else {
            ele.data.isHomeNode = false;
          }
        });
    },
    updateAction: (state, action) => {
      if (state.workflow && state.workflow?.canvas) {
        // Modify the action details in the state
        let tempState = JSON.parse(JSON.stringify(state));
        tempState?.workflow?.canvas.elements
          .filter((ele) => ele.id === action?.payload?.transitionId)
          .forEach((ele) => {
            ele?.data?.actions
              ?.filter((a) => a.actionId === action?.payload?.actionId)
              .forEach((a) => {
                forOwn(action.payload, (value, key) => (a[key] = value));

                // Modify the selected action
                tempState.workflow.selectedAction = JSON.parse(
                  JSON.stringify(a)
                );
              });
          });

        // Modify the selected Transition
        tempState.workflow.selectedTransition =
          tempState.workflow?.canvas.elements.find(
            (ele) => ele.id === action?.payload?.transitionId
          );
        return tempState;
      }
    },
    removeAction: (state, action) => {
      if (state.workflow && state.workflow?.canvas) {
        // Modify the action details in the state
        state.workflow?.canvas.elements
          .filter((ele) => ele.id === action?.payload?.transition.id)
          .forEach((ele) => {
            const actionToBeRemovedIndex = ele?.data?.actions?.findIndex(
              (a) => a.actionId === action?.payload?.action?.actionId
            );
            if (actionToBeRemovedIndex >= 0) {
              ele?.data?.actions?.splice(actionToBeRemovedIndex, 1);
              // Modify the selected action
              delete state.workflow.selectedAction;
            }
          });

        // Modify the selected Transition
        state.workflow.selectedTransition =
          state.workflow?.canvas.elements.find(
            (ele) => ele.id === action?.payload?.transition.id
          );
      }
    },
    setActiveEditField: (state, action) => {
      state.workflow.activeEditField = action.payload;
    },
    setShowEditField: (state, action) => {
      state.workflow.showEditField = action.payload;
    },
    newSetActiveEditField: (state, action) => {
      const { dataFieldId: id } = action.payload;
      let activeEditField = state.workflow?.dataFields?.filter((dataField) => {
        // Before publish the id is random string, but after publish the
        return dataField.id === id || dataField.name === id;
      });
      if (activeEditField.length > 0) {
        state.workflow.activeEditField = activeEditField[0];
        state.showDataFieldEditModal = true;
      } else {
        state.workflow.activeEditField = {};
        state.showDataFieldEditModal = false;
      }
    },
    addDataField: (state, action) => {
      if (state?.workflow?.dataFields) {
        state.workflow.dataFields.push(action.payload);
      } else {
        state.workflow.dataFields = [action.payload];
      }
    },
    setDeleteIconOnTransition: (state, action) => {
      state.deleteIconOnTransition = action.payload;
    },
    editDataField: (state, action) => {
      if (state?.workflow?.dataFields) {
        // use filter nad map
        let editItemIdx = state?.workflow?.dataFields.findIndex(
          (item) => item.id === action.payload.id
        );
        if (editItemIdx !== -1) {
          state.workflow.dataFields[editItemIdx] = {
            ...action.payload,
          };
        }
      }
    },
    updateActionConditionScript: (state, action) => {
      const { actionId, payload, editorLocation } = action.payload;
      state?.workflow?.canvas?.elements?.forEach((ele) => {
        if (ele?.type === "zorpEdge") {
          ele?.data?.actions?.forEach((item) => {
            if (item.actionId === actionId) {
              item[editorLocation] = payload;
            }
          });
        }
      });

      //To update the selectedAction's conditional script
      // TODO: Can we do it better.
      state.workflow.selectedAction[editorLocation] = payload;
    },

    updateActionInConditionScriptForSwitch: (state, action) => {
      const { nodeId, switchId, actionId, editorLocation, data } =
        action.payload;
      state.workflow.canvas?.elements?.forEach((item) => {
        if (item.id === nodeId) {
          item.data.handleInfo.forEach((singleItem) => {
            if (singleItem.id === switchId) {
              singleItem.data?.actions?.forEach((singleAction) => {
                if (singleAction.actionId === actionId) {
                  singleAction[editorLocation] = data;
                  state.workflow.selectedAction = action.payload?.data;
                }
              });
              state.workflow.selectedDecisionNode = {
                ...singleItem,
                nodeId,
                deletePossible:
                  state?.workflow?.selectedDecisionNode?.deletePossible,
              };
            }
          });
        }
      });
    },

    updateConditionsInConditionScriptForSwitch: (state, action) => {
      const { nodeId, switchId, actionId, editorLocation, data } =
        action.payload;
      state.workflow.canvas?.elements?.forEach((item) => {
        if (item.id === nodeId) {
          item.data.handleInfo.forEach((singleItem) => {
            if (singleItem.id === switchId) {
              singleItem.data?.conditions?.forEach((singleCondition) => {
                if (singleCondition.actionId === actionId) {
                  singleCondition[editorLocation] = data;
                  state.workflow.selectedAction[editorLocation] =
                    action.payload?.data;
                }
              });
              state.workflow.selectedDecisionNode = {
                ...singleItem,
                nodeId,
                deletePossible:
                  state?.workflow?.selectedDecisionNode?.deletePossible,
              };
            }
          });
        }
      });
    },

    deleteDraftDataField: (state, action) => {
      if (state?.workflow?.dataFields) {
        // use filter nad map
        let toBeDeletedItemIdx = state?.workflow?.dataFields.findIndex(
          (item) => item.id === action.payload.id
        );
        if (toBeDeletedItemIdx !== -1) {
          state.workflow.dataFields.splice(toBeDeletedItemIdx, 1);
        }
        state.workflow.activeEditField = {};
      }
    },
    setShowNodeDropdown: (state, action) => {
      state.showNodeDropdown = action.payload;
    },
    setShowDataFieldModal: (state, action) => {
      state.showDataFieldModal = action.payload;
    },
    setShowDataFieldCreateModal: (state, action) => {
      state.showDataFieldCreateModal = action.payload;
    },
    setShowDataFieldEditModal: (state, action) => {
      state.showDataFieldEditModal = action.payload;
    },
    setPostPublish: (state, action) => {
      state.postedWorkflowInfo = {
        postedWorkflow: action.payload.postedWorkflow,
        postedWorkflowId: action.payload.postedWorkflowId,
        setPostPublish: action.payload.setPostPublish,
        
      }
    },
    setDataFieldType: (state, action) => {
      state.dataFieldType = action.payload;
    },
    setShowDataFieldEditModal: (state, action) => {
      state.showDataFieldEditModal = action.payload;
    },

    updateDecisionNode: (state, action) => {
      let updatedInfo = action.payload;
      state.workflow.canvas?.elements?.forEach((item) => {
        if (item.id === updatedInfo.id) {
          item.data.name = updatedInfo.name;
          item.data.description = updatedInfo.description;
          item.data.expression = updatedInfo.expression;
        }
      });
      // if (state.workflow && state.workflow?.canvas
    },
    updateHandlerCount: (state, action) => {
      state.workflow.canvas?.elements?.forEach((item) => {
        if (item.id === action.payload) {
          // get the id of last
          let lastIdx = item.data.handleInfo.length;
          const id = generateId(3);
          let tempArray = [
            ...item.data.handleInfo.slice(0, lastIdx - 1),
            {
              id: id,
              data: {
                name: `New Switch - ${id}`,
                conditions: [],
                actions: [],
              },
              type: "IF",
            },
            ...item.data.handleInfo.slice(lastIdx - 1),
          ];
          item.data.handleInfo = [...tempArray];
        }
      });
    },
    removeHandleFromDecisionNode: (state, action) => {
      const { id, nodeId } = action.payload;
      state?.workflow?.canvas?.elements?.forEach((item) => {
        if (item.id === nodeId) {
          let indexToBeRemoved = item.data?.handleInfo?.findIndex(
            (handle) => handle.id === id
          );
          if (indexToBeRemoved >= 0) {
            item.data?.handleInfo?.splice(indexToBeRemoved, 1);
            delete state?.workflow?.selectedDecisionNode;
          }
        }
      });
    },

    rearrangeHandles: (state, action) => {
      const { step, nodeId, idx } = action.payload;
      state.workflow.canvas?.elements?.forEach((item) => {
        if (item.id === nodeId) {
          let temporaryVariable = item.data.handleInfo[idx];
          // TODO: Check for corner cases:
          // If idx is last or if idx is first
          item.data.handleInfo[idx] = item.data.handleInfo[idx + step];
          item.data.handleInfo[idx + step] = temporaryVariable;
        }
      });
    },

    setSelectedDecisionNode: (state, action) => {
      state.workflow.selectedDecisionNode = action.payload;
    },
    addConditionInSwitch: (state, action) => {
      const { nodeId, switchId } = action.payload;
      state.workflow.canvas?.elements?.forEach((item) => {
        if (item.id === nodeId) {
          item.data.handleInfo.forEach((singleItem) => {
            if (singleItem.id === switchId) {
              singleItem.data.conditions.push(action.payload.data);
              state.workflow.selectedDecisionNode = {
                ...singleItem,
                nodeId,
                deletePossible:
                  state?.workflow?.selectedDecisionNode?.deletePossible,
              };
              state.workflow.selectedAction = action.payload?.data;
            }
          });
        }
      });
    },
    removeConditionFromSwitch: (state, action) => {
      const { nodeId, switchId, guardId } = action.payload;
      state.workflow.canvas?.elements?.forEach((item) => {
        if (item.id === nodeId) {
          item.data.handleInfo.forEach((singleItem) => {
            if (singleItem.id === switchId) {
              //   const actionToBeRemovedIndex = ele?.data?.guards?.findIndex(
              //     (a) => a.guardId === action?.payload?.guard?.guardId
              // );
              // if (actionToBeRemovedIndex >= 0) {
              //     ele?.data?.guards?.splice(actionToBeRemovedIndex, 1);
              //     // Modify the selected action
              //     delete state.workflow.selectedAction;
              // }
              const indexToBeRemoved = singleItem?.data?.conditions?.findIndex(
                (condition) => condition.guardId === guardId
              );
              if (indexToBeRemoved >= 0) {
                singleItem?.data?.conditions?.splice(indexToBeRemoved, 1);
                delete state.workflow.selectedAction;
                state.workflow.selectedDecisionNode = {
                  ...singleItem,
                  nodeId,
                  deletePossible:
                    state?.workflow?.selectedDecisionNode?.deletePossible,
                };
              }
            }
          });
        }
      });
    },
    updateConditionInSwitch: (state, action) => {
      const { nodeId, switchId, guardId } = action.payload;
      state.workflow.canvas?.elements?.forEach((item) => {
        if (item.id === nodeId) {
          item.data.handleInfo.forEach((singleItem) => {
            if (singleItem.id === switchId) {
              singleItem.data?.conditions?.forEach((condition) => {
                if (condition.guardId === guardId) {
                  forOwn(
                    action.payload.data,
                    (value, key) => (condition[key] = value)
                  );
                  state.workflow.selectedAction = action.payload?.data;
                }
              });
              state.workflow.selectedDecisionNode = {
                ...singleItem,
                nodeId,
                deletePossible:
                  state?.workflow?.selectedDecisionNode?.deletePossible,
              };
            }
          });
        }
      });
    },
    addActionInSwitch: (state, action) => {
      const { nodeId, switchId } = action.payload;
      state.workflow.canvas?.elements?.forEach((item) => {
        if (item.id === nodeId) {
          item.data.handleInfo.forEach((singleItem) => {
            if (singleItem.id === switchId) {
              singleItem.data.actions.push(action.payload.data);
              state.workflow.selectedDecisionNode = {
                ...singleItem,
                nodeId,
                deletePossible:
                  state?.workflow?.selectedDecisionNode?.deletePossible,
              };
              state.workflow.selectedAction = action.payload?.data;
            }
          });
        }
      });
    },
    updateActionInSwitch: (state, action) => {
      const { nodeId, switchId, actionId } = action.payload;
      state.workflow.canvas?.elements?.forEach((item) => {
        if (item.id === nodeId) {
          item.data.handleInfo.forEach((singleItem) => {
            if (singleItem.id === switchId) {
              singleItem.data?.actions?.forEach((singleAction) => {
                if (singleAction.actionId === actionId) {
                  forOwn(
                    action.payload.data,
                    (value, key) => (singleAction[key] = value)
                  );
                  state.workflow.selectedAction = action.payload?.data;
                }
              });
              state.workflow.selectedDecisionNode = {
                ...singleItem,
                nodeId,
                deletePossible:
                  state?.workflow?.selectedDecisionNode?.deletePossible,
              };
            }
          });
        }
      });
    },
    removeActionFromDecisionNode: (state, action) => {
      const { nodeId, switchId, actionId } = action.payload;
      state.workflow.canvas?.elements?.forEach((item) => {
        if (item.id === nodeId) {
          item.data.handleInfo.forEach((singleItem) => {
            if (singleItem.id === switchId) {
              const indexToBeRemoved = singleItem?.data?.actions?.findIndex(
                (singleAction) => singleAction.actionId === actionId
              );
              if (indexToBeRemoved >= 0) {
                singleItem?.data?.actions?.splice(indexToBeRemoved, 1);
                delete state.workflow.selectedAction;
                state.workflow.selectedDecisionNode = {
                  ...singleItem,
                  nodeId,
                  deletePossible:
                    state?.workflow?.selectedDecisionNode?.deletePossible,
                };
              }
            }
          });
        }
      });
    },
    updateSwitchName: (state, action) => {
      const { nodeId, switchId, newName } = action.payload;
      state.workflow.canvas?.elements?.forEach((item) => {
        if (item.id === nodeId) {
          item.data.handleInfo.forEach((singleItem) => {
            if (singleItem.id === switchId) {
              singleItem.data.name = newName;
              state.workflow.selectedDecisionNode = {
                ...singleItem,
                nodeId,
                deletePossible:
                  state?.workflow?.selectedDecisionNode?.deletePossible,
              };
            }
          });
        }
      });
    },
    updateDecisionNodeName: (state, action) => {
      const { nodeId, name } = action.payload;
      state?.workflow?.canvas?.elements?.forEach((ele) => {
        if (ele.id === nodeId) {
          ele.data.name = name;
          ele.name = name;
        }
      });
    },
    updateNodeNameFromCC: (state, action) => {
      const { nodeId, name } = action.payload;
      state?.workflow?.canvas?.elements?.forEach((ele) => {
        if (ele.id === nodeId) {
          ele.data.name = name;
        }
      });
    },
    setShowUpdateDecisionNodeNameModal: (state, action) => {
      state.showUpdateDecisionNodeModal = action.payload;
    },
    setShowUpdateNodeNameModal: (state, action) => {
      state.showUpdateNodeNameModal = action.payload;
    },
    rearrangeActionOrCondition: (state, action) => {
      const { step, nodeId, handleId, idx, type } = action.payload;
      state.workflow.canvas?.elements?.forEach((item) => {
        if (item.id === nodeId) {
          item?.data?.handleInfo?.forEach((handle) => {
            if (handle.id === handleId) {
              if (type === "conditions") {
                let temporaryVariable = handle.data.conditions[idx];
                // TODO: Check for corner cases:
                // If idx is last or if idx is first
                handle.data.conditions[idx] =
                  handle.data.conditions[idx + step];
                handle.data.conditions[idx + step] = temporaryVariable;
              } else if (type === "actions") {
                let temporaryVariable = handle.data.actions[idx];
                // TODO: Check for corner cases:
                // If idx is last or if idx is first
                handle.data.actions[idx] = handle.data.actions[idx + step];
                handle.data.actions[idx + step] = temporaryVariable;
              }
            }
          });
        }
      });
    },
    rearrangeActionOrConditionInTransition: (state, action) => {
      const { step, transitionId, idx, type } = action.payload;
      state.workflow.canvas?.elements?.forEach((item) => {
        if (item.id === transitionId) {
          if (type === "conditions") {
            let temporaryVariable = item.data.conditions[idx];
            // TODO: Check for corner cases:
            // If idx is last or if idx is first
            item.data.conditions[idx] = item.data.conditions[idx + step];
            item.data.conditions[idx + step] = temporaryVariable;
          } else if (type === "actions") {
            let temporaryVariable = item.data.actions[idx];
            // TODO: Check for corner cases:
            // If idx is last or if idx is first
            item.data.actions[idx] = item.data.actions[idx + step];
            item.data.actions[idx + step] = temporaryVariable;
          } else if (type === "transitionConditions") {
            let temporaryVariable = item.data.guards[idx];
            // TODO: Check for corner cases:
            // If idx is last or if idx is first
            item.data.guards[idx] = item.data.guards[idx + step];
            item.data.guards[idx + step] = temporaryVariable;
          }
        }
      });
    },
    setShowWorkflowConfigModal: (state, action) => {
      state.workflow.selectedTransition = null;
      state.workflow.selectedDecisionNode = null;
      state.showWorkflowConfigModal = action.payload;
    },
    setShowCreateDatafieldModalSingle: (state, action) => {
      state.showCreateDatafieldModalSingle = action.payload;
    },
    setShowDataFieldEditModalSingle: (state, action) => {
      const name = action.payload;
      const arr = state.workflow.dataFields.filter(
        (dataField) => dataField.name === name
      );
      if (arr.length > 0) {
        state.workflow.activeEditField = arr[0];
        state.showEditDatFieldModalSingle = true;
      } else {
        state.showEditDatFieldModalSingle = false;
      }
    },
    setAllWorkflowError: (state, action) => {
      state.allWorkflowErrors = action.payload;
    },
    setShowWorkflowErrors: (state, action) => {
      state.showWorkflowErrors = action.payload;
    },
    openTaskCardConfig: (state, action) => {
      state.showTaskCardConfig = action.payload;
    },
    setFlutterTaskCardConfig:(state,action)=>{
      state.flutterTaskCardConfig = action.payload;
    },
    setShowErrorTooltip: (state, action) => {
      const { payload } = action;
      state.showErrorTooltip = {
        ...state.showErrorTooltip,
        ...payload,
      };
    },
    setActiveSubField: (state, action) => {
      state.activeSubField = action.payload;
    },

    // deleteDraftDataField: (state, action) => {
    //   if (state?.workflow?.dataFields) {
    //     // use filter nad map
    //     let toBeDeletedItemIdx = state?.workflow?.dataFields.findIndex((item) => item.id === action.payload.id);
    //     if (toBeDeletedItemIdx !== -1) {
    //       state.workflow.dataFields.splice(toBeDeletedItemIdx, 1);
    //     }
    //     state.workflow.activeEditField = {};
    //   }
    // },
    deleteSubField: (state, action) => {
      const { dataFieldId, subFieldId } = action.payload;
      if (state?.workflow?.dataFields) {
        const activeDataFieldIdx = state?.workflow?.dataFields.findIndex(
          (item) => item.id === dataFieldId
        );
        const activeDataField = state?.workflow?.dataFields[activeDataFieldIdx];

        const toBeDeletedItemIdx = activeDataField?.subFields.findIndex(
          (item) => item.id === subFieldId
        );
        if (toBeDeletedItemIdx !== -1) {
          activeDataField?.subFields.splice(toBeDeletedItemIdx, 1);
        }
        state.workflow.dataFields[activeDataFieldIdx] = activeDataField;
      }
    },

    setSelectedSystemTransition: (state, action) => {
      state.workflow.selectedSystemTransition = action.payload;
    },

    addSystemTransition: (state, action) => {
      const { transitionType, type, payload } = action.payload;
      if (!state.workflow[transitionType][type])
        state.workflow[transitionType][type] = [];
      state.workflow[transitionType][type].push(payload);
      state.workflow.selectedAction = payload;
      state.workflow.selectedSystemTransition = { transitionType };
    },

    editSystemTransition: (state, action) => {
      const { transitionType, type, id, payload } = action.payload;
      const editIdx = state.workflow[transitionType][type].findIndex(
        (item) => item.guardId === id || item.actionId === id
      );
      if (editIdx !== -1) {
        state.workflow[transitionType][type][editIdx] = {
          ...payload,
        };
      }
    },

    initSystemTransition: (state, action) => {
      const { transitionType, payload } = action.payload;
      if (!isEqual(state?.workflow?.[transitionType], payload)) {
        state.workflow[transitionType] = payload;
      }
    },
    deleteSystemTransition: (state, action) => {
      const { transitionType, type, id } = action.payload;

      let toBeDeletedItemIdx = state?.workflow[transitionType][type].findIndex(
        (item) => item.guardId === id || item.actionId === id
      );
      if (toBeDeletedItemIdx !== -1) {
        state.workflow[transitionType][type].splice(toBeDeletedItemIdx, 1);
        delete state.workflow.selectedAction;
      }
    },
    rearrangeSystemTransition: (state, action) => {
      const { transitionType, type, idx, step } = action.payload;
      if (idx === 0 && step === -1) {
        return;
      }

      let temporaryVariable = state.workflow[transitionType][type][idx];
      state.workflow[transitionType][type][idx] =
        state.workflow[transitionType][type][idx + step];
      state.workflow[transitionType][type][idx + step] = temporaryVariable;
    },
    setFirstLoad: (state, action) => {
      state.workflow.isFirstLoad = action.payload;
    },
    setChangeInFlutter: (state, action) => {
      state.changesInFlutter = action.payload;
    },
    setPossibleHomeNode: (state, action) => {
      state.workflow.possibleHomeNodes = [...action.payload];
      if (action.payload.length === 1) {
        state.workflow?.canvas?.elements.forEach((ele, index) => {
          if(ele.type === NodeTypes.STATE_NODE){
            if (action.payload.includes(ele.data.id)) {
              state.workflow.canvas.elements[index].data.isHomeNode = true;
            } else {
              state.workflow.canvas.elements[index].data.isHomeNode = false;
            }
          }
          
        });
      }
    },
    setHomeNode: (state, action) => {
      const { nodeId } = action.payload;
      state.workflow?.canvas?.elements.forEach((ele, index) => {
        if(ele.type === NodeTypes.STATE_NODE)
          {
            if (ele.data.id === nodeId) {
              state.workflow.canvas.elements[index].data.isHomeNode = true;
            } else {
              state.workflow.canvas.elements[index].data.isHomeNode = false;
            }
          }
          
      });
    },
    // If there is only one valid terminal node, we mark that node as home node and
    // remove all other nodes from being terminal
    // allowUnset key determines if the already marked/unmarked node should be affected or not.
    setPossibleTerminalNodes: (state, action) => {
      const {payload, allowUnset} = action.payload
      state.workflow.possibleTerminalNodes = [...payload];

      state.workflow?.canvas?.elements.forEach((ele, index) => {
        if(ele.type === NodeTypes.STATE_NODE){
          if (payload.includes(ele.data.id)) {
            state.workflow.canvas.elements[index].data.isTerminalNode = true;
          } else {
            if(allowUnset){
              state.workflow.canvas.elements[index].data.isTerminalNode = false;
            }
          }
        }
        
      });
    },
    setTerminalNode: (state, action) => {
      const { nodeId } = action.payload;
      state.workflow?.canvas?.elements.forEach((ele, index) => {
        if(ele.type === NodeTypes.STATE_NODE){
          if (ele.data.id === nodeId) {
            state.workflow.canvas.elements[index].data.isTerminalNode = true;
          }
        }     
      });
    },
    unsetTerminalNode: (state, action) => {
      const { nodeId } = action.payload;
      state.workflow?.canvas?.elements.forEach((ele, index) => {
        if(ele.type === NodeTypes.STATE_NODE){
          if (ele.data.id === nodeId) {
            state.workflow.canvas.elements[index].data.isTerminalNode = false;
          }
        }
        
      });
    },
    setContextMenuVisibility: (state, action) => {
      state.isContextMenuVisible = action.payload;
    },
    setNodesThatCanBeVisited: (state, action) => {
      state.nodesThatCanBeVisited = [...action.payload];
    },
    setIsSaveWorkflowCalledAtleastOnce: (state, action) => {
      state.isSaveWorkflowCalledAtleastOnce = action.payload
    },
    setAppTaskCreateConfig : (state, action) => {
      state.workflow.appTaskCreateConfig = {...state.workflow.appTaskCreateConfig, ...action.payload}
    },

    resetWorkflow: () => initialState,
    setApiResponse: (state, action) => {
      state.apiResponse = action.payload
    },
    setFetchingTestAPIResponse: (state, action) => {
      state.fetchingTestAPIResponse = action.payload
    },
    setDataPickerOpen: (state, action) => {
      state.dataPicker = action.payload
    },
    setSystemTransitionAutomation: (state, action) => {
      state.workflow.systemTransitionAutomations = action.payload
    },
    setScreenTransitionAutomation: (state, action) => {
      state.screenTransitionAutomation = action.payload
    },

    addAutomationInTransition: (state, action) => {
      // state.selectedTransition
      /**
       * TODO: Elements should be added in the canvas elemets
       */
       if (state.workflow && state.workflow?.canvas) {
          state.workflow?.canvas.elements
            .filter((ele) => ele.data?.id === state.workflow.selectedTransition.data.id)
            .forEach((ele) => {
              ele.data = {...ele.data, ...action.payload}
            });
        }
      state.workflow.selectedTransition.data = {...action.payload}
      
    },
    addAutomationInSmartComponent:(state, action)=>{
      if (state.workflow && state.workflow?.canvas) {
        state.workflow?.canvas.elements
          .filter((ele) => ele.data?.id === state.workflow.selectedTransition.data.id)
          .forEach((ele) => {
            ele.data = {...ele.data, ...action.payload}
          });
      }
    },
    addAutomationInDecisionNode: (state, action) => {
      const { nodeId, id:switchId } = state.workflow.selectedDecisionNode
      state.workflow.canvas?.elements?.forEach((item) => {
        if (item.id === nodeId) {
          item.data.handleInfo.forEach((singleItem) => {
            if (singleItem.id === switchId) {
              singleItem.data ={...singleItem.data, automations:action.payload.automations};
              state.workflow.selectedDecisionNode = {
                ...singleItem,
                nodeId,
                deletePossible:
                  state?.workflow?.selectedDecisionNode?.deletePossible,
              };
              // state.workflow.selectedAction = action.payload?.data;
            }
          });
        }
      });
    },
    setShowPublishModal: (state, action) => {
      state.showPublishModal = action.payload
    },
    setIsWorkflowPublishedBefore: (state, action) => {
      state.isWorkflowPublishedBefore = action.payload
    },
    setShowPreviewModal: (state, action) => {
      state.showPreviewModal = action.payload
    },
    addSubfieldToListDataField: (state, action) => {
      const {listName, subfield} = action.payload
      // find datafield with name listName
      const dataField = state.workflow?.dataFields?.find((ele) => ele?.name === listName && ele?.type === DataFieldType.LIST)

      // findIndex of subfield with name subfield.name
      const dataFieldIndex = state.workflow?.dataFields?.findIndex((ele) => ele?.name === listName && ele?.type === DataFieldType.LIST)

      if(dataField){
        dataField.children.push({...subfield})
      }

      if(dataFieldIndex !== -1){
        state.workflow.dataFields[dataFieldIndex] = {...dataField}
      }


    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(initWorkflow.pending, (state) => {
        if (state.workflow) {
          state.workflow = {};
          state.workflow.isBeingSaved = true;
          state.workflow.new = false;
        }
      })
      .addCase(initWorkflow.fulfilled, (state, action) => {
        state.workflow = action.payload;

        //TODO: REMOVE once BE changes are done
        //TODO: ALSO THE roleList should not go in state.workflow
        if(state.workflow?.appTaskCreateConfig?.visibleForRoles?.length === 0){
          state.workflow.appTaskCreateConfig = {
            isTaskCreateEnable: state.workflow?.appTaskCreateConfig.isTaskCreateEnable, 
            displayName: action?.payload?.taskTypeDisplayName || "Create Task",
            visibleForRoles:[...action.payload?.roleList] || []
      
          }
        }
        if (state.workflow) {
          state.workflow.isBeingSaved = false;
          // state.showWorkflowConfigModal = false
        }
      });
  },
});

export const {
  setTaskTypeNameDescription,
  setWorkflowErrors,
  updateElements,
  setSelectedNode,
  setSelectedTransition,
  setSelectedAction,
  setIsBeingSaved,
  updateNodeName,
  deleteNode,
  deleteTransition,
  setTransitionEventName,
  addAction,
  addGuard,
  updateGuard,
  removeGuard,
  updateAction,
  removeAction,
  addDataField,
  setActiveEditField,
  editDataField,
  deleteDraftDataField,
  setShowEditField,
  updateHome,
  setTransitionLocationCollection,
  updateActionConditionScript,
  setShowNodeDropdown,
  setShowDataFieldModal,
  setShowDataFieldCreateModal,
  setShowDataFieldEditModal,
  setShowDataFieldEditModalSingle,
  newSetActiveEditField,
  setShowWorkflowErrors,
  updateDecisionNode,
  updateHandlerCount,
  rearrangeHandles,
  setSelectedDecisionNode,
  addConditionInSwitch,
  updateConditionInSwitch,
  removeConditionFromSwitch,
  addActionInSwitch,
  updateActionInSwitch,
  removeActionFromDecisionNode,
  removeHandleFromDecisionNode,
  updateSwitchName,
  updateDecisionNodeName,
  addSystemTransition,
  setShowUpdateDecisionNodeNameModal,
  rearrangeActionOrCondition,
  setPostPublish,
  updateActionInConditionScriptForSwitch,
  updateConditionsInConditionScriptForSwitch,
  setDataFieldType,
  setDeleteIconOnTransition,
  setShowCreateDatafieldModalSingle,
  setAllWorkflowError,
  openTaskCardConfig,
  setFlutterTaskCardConfig,
  rearrangeActionOrConditionInTransition,
  setShowWorkflowConfigModal,
  setShowErrorTooltip,
  setActiveSubField,
  deleteSubField,
  editSystemTransition,
  setSelectedSystemTransition,
  deleteSystemTransition,
  rearrangeSystemTransition,
  initSystemTransition,
  setFirstLoad,
  resetWorkflow,
  setPossibleHomeNode,
  setChangeInFlutter,
  setAppTaskCreateConfig,
  setHomeNode,
  setContextMenuVisibility,
  setNodesThatCanBeVisited,
  setPossibleTerminalNodes,
  setTerminalNode,
  unsetTerminalNode,
  setApiResponse,
  setFetchingTestAPIResponse,
  setIsSaveWorkflowCalledAtleastOnce,
  setDataPickerOpen,
  setSystemTransitionAutomation,
  setScreenTransitionAutomation,
  addAutomationInTransition,
  addAutomationInSmartComponent,
  addAutomationInDecisionNode,
  setShowPublishModal,
  setShowUpdateNodeNameModal,
  setIsWorkflowPublishedBefore,
  updateNodeNameFromCC,
  setShowPreviewModal,
  addSubfieldToListDataField
} = workflowSlice.actions;

export default workflowSlice.reducer;
