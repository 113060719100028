import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import TextareaAutosize from "@mui/material/TextareaAutosize";
import ClassicBuilderIcon from "assests/classic_builder_icon.svg";
import ConfirmMonitorDatasource from "assests/confirm_monitor_datasource.svg";
import clockCronIcon from "assests/cron_clock_icon.svg";
import FilterSelect from "assests/filter_dropdown_arrow.svg";
import MonitorEmail from "assests/monitor_email.svg";
import { useState } from "react";
import Select, { SingleValue, components } from "react-select";
import styled from "styled-components";
import { ISelectValueType } from "./DatasourceModal";
import { VariableHelperText } from "./Monitor.styles";
import { IShopifyTemplate } from "./ShopifyMonitor";
import useMonitor from "./reducer/useMonitor";
import { IRunQueryPayload, MonitorService } from "providers/data/services/MonitorService";
import { IDatasourceCategory } from "./reducer/MonitorReducer";
import notification from "notifications/notifications";

const cronDescriptionArray: ISelectValueType[] = [
    {
      label: "Every 5 minutes",
      value: "*/5 * * * *",
    },
    {
      label: "Every 10 minutes",
      value: "*/10 * * * *",
    },
    {
      label: "Every 15 minutes",
      value: "*/15 * * * *",
    },
    {
      label: "Every 30 minutes",
      value: "*/30 * * * *",
    },
    {
      label: "Every 1 hour",
      value: "0 * * * *",
    },
    {
      label: "Every 6 hour",
      value: "0 */6 * * *",
    },
    {
      label: "Every 12 hour",
      value: "0 */12 * * *",
    },
    {
      label: "Every 24 hour",
      value: "0 0 * * *",
    }
  ];
  
  const defaultValue = cronDescriptionArray[4]
  
  

const ModalContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: ${(props: { height?: string; width?: string }) =>
    props?.width ? props.width : "560px"};
  height: ${(props: { height?: string; width?: string }) =>
    props?.height ? props.height : "auto"};
  max-height: 86vh;
  background: #ffffff;
  box-shadow: 0px 10px 14px -2px rgba(16, 24, 40, 0.01),
    0px 4px 4px -2px rgba(16, 24, 40, 0.01);
  position: absolute;
  border-radius: 4px;
  overflow:scroll;
`;

const HeaderInfoWrapper = styled.div`
  // margin-top: 16px;
  padding-bottom: 16px;
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  // height: 161px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: #ffffff;
  width: 100%;
  padding: 24px 24px 0px 24px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 24px 24px 24px;
  width: 100%;
`;

const MonitorNameWrapper = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #d0d5dd;
  background: #f9fafb;
`;

const MainHeading = styled.p`
  color: #101828;
  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
`;

const MainDiscription = styled.p`
  color: #475467;
  /* Text xs/Regular */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
`;


const ModalDesc = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
`;

const ModalHeaderText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
`;

const FooterRightWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
`;

const FooterLeftWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const DocumentationTxt = styled.p`
  color: #3054b9;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
  cursor: pointer;
`;

const ModalFooter = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
  justify-content: space-between;
  display: flex;
  width: 100%;
  height: 100px;
`;

const LabelText = styled.p`
  color: #667085;
  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  margin-bottom: 8px;
`;

interface ITemplateEditContent {
  handleModalClose : () => void;
  onAcivateAlerts : (editedTemplateData:IShopifyTemplate) => void;
  onTemplateEdit : (editedTemplateData:IShopifyTemplate) => void;
}

const TemplateEditContent = ({handleModalClose, onAcivateAlerts, onTemplateEdit} : ITemplateEditContent) => {
  const checkForPlaceHoldervalue = (query: string) => {
    // Regular expression to match content within { }
    const regex = /{([^}]+)}/g;
    let matches: any = query?.match(regex);

    // Removing the curly braces and storing the values in an array
    const values = matches?.map((match: any) => match.replace(/[{}]/g, ''));

    // return values;
    if (values?.length > 0) {
      // Convert array to string and format it as required
      const formattedValues = `${values.join(", ")}`;
      return `Replace placeholder values: ${formattedValues}`;
    } else {
      return ``;
    }
  }

  const { confirmMonitorModalData: template, datasourceListData } = useMonitor();
  const [datasourceName] = useState<string>(template.selectedDataSource?.displayName as string);
  const [monitorName] = useState<string>(template.title);
  const [monitorDescription] = useState<string>(template.description);
  const [moniorCron, setMonitorCron] = useState<ISelectValueType>(defaultValue);
  const [emailSubject] = useState<string>(template.emailSubject);
  const [emailBody] = useState<string>(template.emailBody);
  const [sqlQuery, setSqlQuery] = useState<string>(template.sqlQuery as string);
  const [emailTo, setEmailTo] = useState<string>(template?.accountEmail as string);
  const [error, setError] = useState(checkForPlaceHoldervalue(template.sqlQuery));

 

  const SingleValue = ({ children, ...props }: any) => (
    <>
      <components.SingleValue {...props} style={{ display: 'flex' }} >
        <div style={{ display: 'flex' }}>
          <img src={clockCronIcon} style={{ marginRight: '4px' }} /><div className=""> {props.data.label}</div>
        </div>
      </components.SingleValue>
    </>
  )

  const getTemplateEditedPayload = ():IShopifyTemplate => {
    const templatePayloadToCreateMonitor: IShopifyTemplate = {
      ...template,
      title: monitorName,
      description: monitorDescription,
      emailSubject: emailSubject,
      emailBody: emailBody,
      accountEmail: emailTo,
      cronExpression: moniorCron?.value,
      datasourceId: template?.selectedDataSource?.datasourceId as string,
      sqlQuery: sqlQuery
    }

    return templatePayloadToCreateMonitor;
  }

  const DropdownIndicator = () => (
    <img
      src={FilterSelect}
      style={{ paddingRight: "14px" }}
      className="cursor-pointer"
    />
  );

  const executeQuery = async () => {
    const datasourceId = datasourceListData.find((data) => data.datasourceCategory == IDatasourceCategory.ZORP_DEFAULT_CLICKHOUSE)?.datasourceId as string;
    const runQueryPayload: IRunQueryPayload = {
      datasourceId,
      query: sqlQuery,
      type: 'SELECT',
    }
    const queryOutput = await MonitorService.runQuery(runQueryPayload)

    if (queryOutput.code != '200') {
      setError(queryOutput.message)
    } else {
      const templatePayload = getTemplateEditedPayload();
      onAcivateAlerts(templatePayload)
    }
  }

  return (
    <>
    {/* <ModalContainer> */}
    <ModalHeader>
      <HeaderInfoWrapper>
        <ModalHeaderText>Finalize your Alerts setup</ModalHeaderText>
        <ModalDesc>
        Here’s what BlueBee looks for and where he notifies you
        </ModalDesc>
      </HeaderInfoWrapper>
    </ModalHeader>


    {/*  db details */}
    <TextWrapper>
      <LabelText>Monitor this datasource</LabelText>
      <TextField
        disabled
        variant="outlined"
        sx={{
          backgroundColor: '#F9FAFB',
          width: '100%',
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000",
            fontWeight: 500,
            fontSize: "12px",
          },
          '& .MuiInputBase-root': {
            height: '42px',
            padding: '12px'
          }
        }}
        value={datasourceName}
        InputProps={{
          startAdornment: (
            // <InputAdornment style={{margin:'0px', padding:'0px'}} position="start">
            <img src={ConfirmMonitorDatasource} />
            // {/* </InputAdornment> */}
          ),
        }}
      />
    </TextWrapper>

    {/* monitor meta data */}

    <TextWrapper>
      <LabelText>Look out for these anomalies</LabelText>
      <MonitorNameWrapper>
        <Box display="flex" flexDirection="row" m={0} p={0} gap={'8px'} alignItems={'start'}>
          <img src={ConfirmMonitorDatasource} />
          <MainHeading>{monitorName}</MainHeading>
        </Box>
        <MainDiscription>{monitorDescription}</MainDiscription>
      </MonitorNameWrapper>
    </TextWrapper>

    {/* email aciton info */}
    <TextWrapper>
      <LabelText>SQL Query</LabelText>
      <TextareaAutosize
        maxRows={5}
        value={sqlQuery}
        onChange={(e) => {
          setSqlQuery(e.target.value);
        }}
        minRows={5}
        style={{
          width: "100%",
          border: "1px solid #c2c9d1",
          borderRadius: "4px",
          padding: "6px 8px",
          marginTop: '6px'
        }}
      />
      <div style={{
        maxHeight:'50px',
        overflow:'auto',
        cursor:'pointer'
      }}>

      <VariableHelperText 
      onClick={()=>{
        navigator.clipboard.writeText(error);
      notification('success', 'Error messge copied to clipboard'); 
      }}    
      style={{ 
        marginTop: '6px',
        fontSize: '12px',
        color: '#F22D3D',
      }}>{error}</VariableHelperText>
      </div>
    </TextWrapper>

    {/* change email field */}

    <TextWrapper>
      <LabelText>Alert me through</LabelText>
      <TextField
        variant="outlined"
        sx={{
          backgroundColor: '#fff',
          width: '100%',
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000",
            fontWeight: 500,
            fontSize: "12px",
          },
          '& .MuiInputBase-root': {
            height: '42px',
            padding: '12px'
          }
        }}
        value={emailTo}
        onChange={(e) => {
          setEmailTo(e.target.value)
        }}
        InputProps={{
          startAdornment: (
            // <InputAdornment style={{margin:'0px', padding:'0px'}} position="start">
            <img src={MonitorEmail} />
            // {/* </InputAdornment> */}
          ),
        }}
      />
    </TextWrapper>

    {/* Change cron expression here */}
    <TextWrapper style={{ paddingBottom: '0px' }}>
      <LabelText>Monitoring schedule</LabelText>
      <div
        style={{
          width: "100%",
        }}>
        <Select
          components={{ IndicatorSeparator: () => null, DropdownIndicator, SingleValue }}
          options={cronDescriptionArray}
          defaultValue={defaultValue}
          value={moniorCron || defaultValue}
          onChange={(dropdownValue: SingleValue<ISelectValueType>) => {
            setMonitorCron(dropdownValue as ISelectValueType)
          }}
          styles={{
            groupHeading: (base) => ({
              ...base,
              flex: "1 1",
              color: "white",
              margin: 0,
            }),
            control: (base: any, state: any) => ({
              ...base,
              width: "100%",
              "min-height": "32px",
              minHeight: "32px",
              alignContent: "center",
              alignItems: "center",
            }),
            option: (provided: any, state: { isSelected: any }) => ({
              ...provided,
              paddingLeft: "24px",
              paddingTop: "6px",
              paddingBottom: "6px",
              fontWeight: 400,
              wordWrap: "break-word",
              whiteSpace: "normal",
              fontSize: " 14px",
            }),
            menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
            menu: (provided) => ({
              ...provided,
              zIndex: 9999,
              color: "#7E7E7F",
            }),
          }}
        />
      </div>
    </TextWrapper>

    <ModalFooter>
      <FooterLeftWrapper>
        <div className="pr-2">
          <img src={ClassicBuilderIcon} />
        </div>
        <DocumentationTxt
          onClick={async () => {
            const templatePayload = getTemplateEditedPayload();
            onTemplateEdit(templatePayload)
          }}>
          Customize further
        </DocumentationTxt>
      </FooterLeftWrapper>

      <FooterRightWrapper>
        <Button
          style={{
            color: "#344054",
            fontFamily: "inter",
            padding: "8px 14px",
            fontStyle: "normal",
            fontSize: '12px',
            fontWeight: 'normal',
            border: " 1px solid #D0D5DD",
            height: "34px",
            width: "62px",
            textTransform: "none",
          }}
          onClick={handleModalClose}
        >
          Close
        </Button>

        <Button
          disableElevation
          variant="contained"
          style={{
            color: "#fff",
            fontFamily: "inter",
            padding: "8px 14px",
            fontStyle: "normal",
            fontWeight: 'normal',
            fontSize: '12px',
            height: "34px",
            background: "#3C69E7",
            textTransform: "none",
          }}
          onClick={async ()=>{
            await executeQuery();
          }}
        >
          Activate Alerts
        </Button>
      </FooterRightWrapper>
    </ModalFooter>
  {/* </ModalContainer> */}
  </>
  )
}

export default TemplateEditContent