import notification from "notifications/notifications";
import { BffService } from "providers/data/services/BffService";
import { Dispatch } from "react";
import { IAutomation } from "views/automationBuilder/types/ABType";

export const webhookAutomationSaveHandler = async ({
    allAutomations,
    dispatch,
    store,
    callback,
}: {
    allAutomations: IAutomation[];
    dispatch: Dispatch<any>;
    store?: any;
    callback: () => void;
}) => {
    const selectedWebhook = store.getState()?.automation?.selectedWebhook;

    const webhookPayload = {
        payload: allAutomations,
        isInitiated: true,
        isActive: (selectedWebhook?.isInitiated == false) ? true : selectedWebhook?.isActive
    }
    
    const response = await BffService.updateWebhook(selectedWebhook?.webhookId, webhookPayload);
    
    if (response?.code == '200') {
        notification("success", "webhook Automation Saved");
        callback();
    } else {
        notification("error", response?.message || `webhook Automation Failed`);
    }
}