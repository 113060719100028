import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@material-ui/core';
import React, { useEffect } from 'react';
import { RoleService } from '../../providers/data/services/RoleService';


const RoleAcl = (props) => {
  const [aclResourceActionsMerged, setAclResourceActionsMerged] = React.useState(null);
  const [masterResourceActions, setMasterResourceActions] = React.useState(null);

  const transformToDB = (acl) => {
    // construct for updating role acl in database
    let aclDB = JSON.parse(JSON.stringify(acl));
    let resourceActions = [];
    Object.entries(aclDB).map(([res, actions], i) => {
      let actionkeys = [];
      Object.entries(actions).map(([act, actionPermission], j) => {
        if (actionPermission) {
          masterResourceActions
            .filter((mRa) => mRa.resource === res)
            .filter((mRa) =>
              mRa.actions.filter((mRaAction) => {
                if (mRaAction.id === act) {
                  actionkeys.push({ id: act, description: mRaAction.description, groupId: mRaAction.groupId });
                }
                return mRaAction.id === act;
              })
            );
        }
      });

      // delete aclDB[res];
      resourceActions.push({
        resource: res,
        actions: actionkeys,
      });
    });
    return resourceActions;
  };

  const handleChange = (event, resource, action) => {
    let acl = JSON.parse(JSON.stringify(aclResourceActionsMerged));

    Object.entries(acl).map(([res, actions], i) => {
      // console.log(actions);
      if (resource === res) {
        Object.entries(actions).map(([act, actionPermission], j) => {
          if (act === action) {
            actions[act] = !actionPermission;
            // act[actionPermission] = !act[actionPermission];
          }
        });
      }
    });
    setAclResourceActionsMerged(acl);
    props.onChange(transformToDB(acl));
  };

  useEffect(() => {
    RoleService.getAclForRole(props.roleId)
      .then((response) => {
        let roleAcls = response?.data;
        if (roleAcls === undefined) {
          roleAcls = {};
          roleAcls.resourceActions = [];
        }

        RoleService.getResourceActions()
          .then((response) => {
            const resourceActions = response?.data;
            setMasterResourceActions(resourceActions);
            const mergedAclActions = {};
            resourceActions.forEach((ra) => {
              let actions = {};
              ra.actions.forEach((action) => {
                actions[action.id] = false;
                roleAcls.resourceActions.map((roleAclResourceAction) => {
                  if (roleAclResourceAction.resource === ra.resource) {
                    actions[action.id] =
                      roleAclResourceAction.actions.filter((raAction) => {
                        return raAction.id === action.id;
                      }).length > 0;
                  }
                  // actions[action.id] = true;
                });
              });
              mergedAclActions[ra.resource] = actions;
            });
            setAclResourceActionsMerged(mergedAclActions);
            props.onChange(transformToDB(mergedAclActions));
          })
          .catch((error) => {
            // notification("error", "Error while fetching users for team");
          });
      })
      .catch((error) => {
        // notification("error", "Error while fetching users for team");
      });
  }, [props.roleId]);

  return (
    <div>
      <h3 className="font-medium" style={{ padding: "10px 0px" }}>
        Role Permissions
      </h3>
      <hr/>
      {aclResourceActionsMerged &&
        Object.entries(aclResourceActionsMerged).map(([resource, actions], i) => (
          <div className='flex flex-row mt-6'>
            <FormControl component='fieldset'>
              <FormLabel component='legend' class='text-lg font-semibold'>
                {resource}
              </FormLabel>
              <FormGroup aria-label='position' row>
                {Object.entries(actions).map(([action, actionPermission], j) => (
                  <div class='flex flex-row items-center role__acl'>
                    <FormControlLabel
                      value={`${actionPermission}`}
                      control={
                        <Checkbox
                          checked={actionPermission}
                          onChange={(event) => handleChange(event, resource, action)}
                          color='primary'
                        />
                      }
                      label={action}
                      labelPlacement='start'
                      disabled={props.readOnly}
                    />
                    <br />
                  </div>
                ))}
              </FormGroup>
            </FormControl>
          </div>
        ))}
    </div>
  );
};

export default RoleAcl;
