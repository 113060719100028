import { LuShield as ConfiguredPermissionIcon } from "react-icons/lu";
import { useState } from 'react';
import styled from 'styled-components';
import { IoIosAdd as AddPermissionIcon } from "react-icons/io";
import useEntity from 'views/entities/hooks/useEntity';
import { useDispatch } from 'react-redux';
import { setEntityPermissions, setpermissionConfiguration, setFinalEntityPermissions, getEntityMetadata } from 'views/entities/reducers/entityReducers';
import { ResourceTypeList, PermissionConfiguration } from '../types';
import { ResourceTypeConstant } from '../types';
import { isUniquePermissionName } from 'utils/CommonUtils';
import { generateId } from "utils/Utils";
import ModalComponent from "./ModalComponent";
import jwtDecode from "jwt-decode";
import { EntityService } from "providers/data/services/EntityService";
import notification from 'notifications/notifications';
import { TablePermissionRsEvents } from 'views/entities/types';
import { recordRSEvent } from 'utils/CommonUtils';

interface ListContainerProps {
    selected: boolean;
}

const DisplayContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
    border-radius: 4px;
`;

const ConfiguredPermissionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    cursor: pointer;
    margin-bottom: 16px;
    justify-content: flex-start;
    align-items: flex-start;
    max-height: 45vh;
    overflow: auto;
`

const ListContainer = styled.div`
    display: flex;
    gap: 12px;
    width: 100%;
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 4px;
    justify-content: flex-start;
    align-items: center;
    background-color: ${({ selected }: ListContainerProps) => (selected ? '#ECF3FF' : 'transparent')};
    color: ${({ selected }: ListContainerProps) => (selected ? '#3054B9' : '#475467')};
`;

const StyledUserIcon = styled(ConfiguredPermissionIcon)`
    font-size: 20px;
    color: #3054B9;
`;

const RoleName = styled.span`
    font-size: 13px;
    color: #475467;
`;

type ConfiguredPermissionsProps = {
    resourceType: ResourceTypeList;
    onPermissionClick: (permission: PermissionConfiguration) => void;
    selectedPermissionConfig: PermissionConfiguration;
    setSelectedPermissionConfig: (permission: PermissionConfiguration) => void;
}

const ConfiguredPermissions = ({ resourceType, onPermissionClick, selectedPermissionConfig, setSelectedPermissionConfig }: ConfiguredPermissionsProps) => {

    const { permissionConfiguration, entityPermissions } = useEntity();
    const dispatch = useDispatch();
    const [newPermissionName, setNewPermissionName] = useState<string>("");
    const [showAddPermissionModal, setShowAddPermissionModal] = useState<boolean>(false);
    const {selectedEntity} = useEntity();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isUnique, setIsunique] = useState<boolean>(true);

    const onAddPermissionClick = () => {
        recordRSEvent(TablePermissionRsEvents.ADD_NEW_PERMISSION_BUTTON_CLICKED, {
            resourceType: resourceType,
            currPermissions: entityPermissions,
        })
        setShowAddPermissionModal(true);
    }

    const onSavePermissionHandler = async() => {
        const at = localStorage.getItem('at');
        const decoded: any = !!at?.length ? jwtDecode(at) : null;
        switch (resourceType) {
            case ResourceTypeConstant.TABLE: {
                if(!isUniquePermissionName(newPermissionName, permissionConfiguration)) {
                    setIsunique(false);
                    return;
                }
                const UniquePermissionName = newPermissionName
                const newPermissionId: string = generateId(6);

                const newTablePermissionsConfig = [...permissionConfiguration, {
                    permissionId: newPermissionId,
                    permissionSetName: UniquePermissionName,
                    permissions: [],
                }];

                recordRSEvent(TablePermissionRsEvents.ADD_NEW_PERMISSION_NAME_SAVE_BUTTON_CLICKED, {
                    newPermissionId: newPermissionId,
                    newPermissionName: UniquePermissionName,
                    newPermissionConfig: newTablePermissionsConfig
                })

                dispatch(setpermissionConfiguration(newTablePermissionsConfig));
                const newEntityPermissions = [...entityPermissions, {
                    permissionId: newPermissionId,
                    permissionSetName: UniquePermissionName,
                    isEditable: true,
                    permissions: [],
                    or: {
                        roleIds: [], teamIds: [], userIds: [],
                    },
                    and: {
                        roleIds: [],
                        teamIds: [],
                        userIds: [],
                    },
                }]
                dispatch(setEntityPermissions(newEntityPermissions))
                dispatch(setFinalEntityPermissions(newEntityPermissions))
                const newSelectedPermission = newTablePermissionsConfig.find((permission) => permission.permissionSetName === newPermissionName);

                if (newSelectedPermission) {
                    setSelectedPermissionConfig(newSelectedPermission);
                    onPermissionClick(newSelectedPermission);
                } else {
                    setSelectedPermissionConfig(newTablePermissionsConfig[0]);
                    onPermissionClick(newTablePermissionsConfig[0]);
                }

                recordRSEvent(TablePermissionRsEvents.SELECTED_PERMISSION_CONFIG_CHANGED, {
                    newSelectedConfig: newSelectedPermission,
                })

                const payloadPermissions = Array.from(newEntityPermissions.values()).map(permission => ({
                    permissionId: permission.permissionId,
                    permissionSetName: permission.permissionSetName,
                    permissions: permission.permissions,
                    or: {
                        roleIds: Array.from(permission.or.roleIds).map(role => (typeof role === 'object' && role !== null) ? role.id || '' : ''),
                        teamIds: Array.from(permission.or.teamIds).map(team => (typeof team === 'object' && team !== null) ? team.id || '' : ''),
                        userIds: Array.from(permission.or.userIds).map(user => (typeof user === 'object' && user !== null) ? user.id || '' : ''),
                    },
                    and: {
                        roleIds: Array.from(permission.and.roleIds).map(role => (typeof role === 'object' && role !== null) ? role.id || '' : ''),
                        teamIds: Array.from(permission.and.teamIds).map(team => (typeof team === 'object' && team !== null) ? team.id || '' : ''),
                        userIds: Array.from(permission.and.userIds).map(user => (typeof user === 'object' && user !== null) ? user.id || '' : ''),
                    },
                    isEditable: permission.isEditable,
                }));

                const payload = {
                    tablePermissions: payloadPermissions,
                    tableType: selectedEntity?.tableType,
                    accountId: decoded?.accountId,
                    notifyUsers: false,
                };

                try {
                    setIsLoading(true);
                    const response = await EntityService.updateEntityPermissions(payload);
                    if (response) {
                        dispatch(setFinalEntityPermissions(newEntityPermissions));
                        dispatch(getEntityMetadata(selectedEntity?.tableType || ""));
                        recordRSEvent(TablePermissionRsEvents.ADD_NEW_PERMISSION_SUCCESSFUL, {
                            payload: payload,
                            response: response,
                        })
                        notification("success", "Permission added successfully");
                    } else {
                        notification("error", "Error while updating permissions");
                    }
                } catch (error) {
                    console.error(error);
                    recordRSEvent(TablePermissionRsEvents.ADD_NEW_PERMISSION_FAILURE, {
                        payload: payload,
                        error: error
                    })
                    notification("error", "Error while adding permissions");
                } finally {
                    setIsLoading(false);
                    setNewPermissionName("");
                    setShowAddPermissionModal(false);
                }
            }
        }
    };

    const onCancelHandler = () => { 
        setIsunique(true); 
        setNewPermissionName(''); 
        setShowAddPermissionModal(false) 
        recordRSEvent(TablePermissionRsEvents.ADD_NEW_PERMISSION_NAME_CANCEL_BUTTON_CLICKED, {
            newPermissionName: newPermissionName,
            newPermissionConfig: permissionConfiguration
        })
    }

    return (
        <>
            <DisplayContainer>
                <ConfiguredPermissionsContainer>
                    {permissionConfiguration.map((permission) => (
                        <ListContainer key={permission.permissionSetName} onClick={() => {
                            setSelectedPermissionConfig(permission);
                            onPermissionClick(permission)
                            recordRSEvent(TablePermissionRsEvents.MANAGE_PERMISSIONS_SELECTED_PERMISSION_CHANGED, {
                                newSelectedPermission: permission,
                            })
                        }}
                            selected={selectedPermissionConfig?.permissionSetName === permission.permissionSetName}>
                            <StyledUserIcon />
                            <RoleName>{permission.permissionSetName.charAt(0).toUpperCase() + permission.permissionSetName.slice(1)}</RoleName>
                        </ListContainer>
                    ))}
                </ConfiguredPermissionsContainer>
                <div onClick={() => onAddPermissionClick()} style={{ display: 'flex', alignItems: 'center', gap: '12px', fontWeight: '600', fontSize: '12px', color: "#667085", padding: '8px 12px', borderRadius: '4px', cursor: 'pointer' }}>
                    <AddPermissionIcon style={{ cursor: 'pointer', fontSize: '16px' }} onClick={() => onAddPermissionClick()} /> Add New
                </div>
            </DisplayContainer>
            {showAddPermissionModal && (
                <ModalComponent
                    isActive={!isLoading}
                    isUnique={isUnique}
                    setIsUnique={setIsunique}
                    setIsActive={setIsLoading}
                    onClose={onCancelHandler}
                    heading="Add Permission"
                    label="Permission Name"
                    placeholder="Enter Permission Name"
                    inputText={newPermissionName}
                    setInputText={setNewPermissionName}
                    onSave={() => onSavePermissionHandler()}
                />
            )}
        </>
    );
};

export default ConfiguredPermissions;