import moment from "moment";
import { BiShieldQuarter as PermissionsIcon } from "react-icons/bi";
import styled from 'styled-components'
import { InfoTooltip as Tooltip } from "views/workflows/Tootltips/InfoTooltip";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AiOutlinePlus } from "react-icons/ai";
import ZTableButton from "./ZTableButton";
import OverflowCell from "../components/OverflowCell";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { SignatureRIcon } from "assests";
import ImageIco from "assests/imageIcoBlue.svg";
import { isArray, isObject, memoize } from "lodash";
import {
    complimentColor,
    OnboardingIdsForComponent,
    recordRSEvent
  } from "utils/CommonUtils";
import {
    ActionTypeEntity,
    EntityTypeField,
    DataFieldType,
  } from "views/DataField/DataField.types";
  import {
    IColDefs,
    IRelationReferringEntityType,
    IRowDefs,
    ITable,
    IselectedMetadata,
    TableRSEvents,
  } from "../types";
import SystemFieldHeader from "./SystemFieldHeader";
import UserRelationRender from "./UserTeamRelationRender";
import RelationCellWithTooltip from "./RelationCellWithTooltip";
import ButtonAutomationHeader from "./ButtonAutomationHeader";
import { convertToTitleCase } from "utils/Utils";
import { setShowCreationModal } from "../reducers/entityReducers";
import notification from "notifications/notifications";
import ListOfText from "./ListOfText";
import { PermissionsListType } from "../permissions/types";
import UserTeamRelationRender from "./UserTeamRelationRender";
import React from "react";
import LinkCellWithTooltip from "./LinkCellWithTooltip";

const StyledSpan = styled.span`
  padding: 4px 6px;
  margin-top: 2px;
  cursor: pointer; 
  display: flex;
  align-items: center; 
  color: #344054;
  font-weight: 500;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`

export interface ColumnFilter {
  id: string;
  value: string | undefined;
  filterType?: string;
}

type ColumnMenuProps = {
  column : any,
  internalColumnMenuItems :any,
  col : any,
  setSelectedColumnName : (selectedColumnName: string) => void,
  setShowColumnPermissionsModal : (showPermissionsModal: boolean) => void,
  handleActionMenuClose : () => void
}

const renderColumnActionsMenuItems = memoize (({
  column,
  internalColumnMenuItems,
  col,
  setSelectedColumnName,
  setShowColumnPermissionsModal,
  handleActionMenuClose
}: ColumnMenuProps) => {
  if (!column || !internalColumnMenuItems) return [];
  const styledInternalColumnMenuItems = internalColumnMenuItems.map((menuItem:any) => {
    const newMenuItem = { ...menuItem };
    newMenuItem.props = {
      ...newMenuItem.props,
      sx: {
        ...newMenuItem.props?.sx,
        color: '#344054',
        fontFamily: 'Inter',
        padding: '4px 6px',
        fontSize: '14px',
        margin: '2px',
        fontWeight: '500',
        lineHeight: '20px',
        border: 'none',
        '&:hover': {
          backgroundColor: '#e0e0e0',
        },
      },
    };
    return newMenuItem;
  });
  return [
    <StyledSpan key={'column-permissions'} onClick={() => { setSelectedColumnName(col.colDef.name); setShowColumnPermissionsModal(true); handleActionMenuClose() }}>
      <span style={{ marginRight: '18px', marginLeft: '0px', display: 'inline-flex', justifyContent: 'flex-start' }}>
        <PermissionsIcon size={'1.5rem'} color={'#344054'} fontWeight={600} />
      </span>
      Column Permissions
    </StyledSpan>,
    ...styledInternalColumnMenuItems,
  ];
});

const renderCellWithTooltip = memoize((params: any, type: any, options = {}) => {
  const { dateFormat = "Do MMM YYYY , h:mm A" } = options;
  let value;

  switch (type) {
    case DataFieldType.BOOLEAN:
      value = params?.cell?.renderValue() === undefined ? "" : params?.cell?.renderValue() ? "True" : "False";
      break;
    case DataFieldType.SIGNATURE:
    case DataFieldType.IMAGE:
    case DataFieldType.FILE:
      value = params?.cell?.renderValue()?.length > 0
        ? (
          <span style={{ color: "#3067c4", padding: "4px 12px" }}>
            {type === DataFieldType.SIGNATURE
              ? <SignatureRIcon style={{ display: "inline" }} color="#667085" size="1.2rem" />
              : <img height={18} width={18} src={ImageIco} style={{ display: "inline", marginRight: "4px", color: "#3067c4" }} />
            }
            View Uploaded Images/Files
          </span>
        )
        : " ";
      break;
    case DataFieldType.LOCATION:
      value = params?.cell?.renderValue() &&
        (params?.cell?.renderValue()?.address ||
          (params?.cell?.renderValue()?.latitude && params?.cell?.renderValue()?.longitude))
        ? params?.cell?.renderValue?.()?.address || `${params?.cell?.renderValue()?.latitude || ""}, ${params?.cell?.renderValue()?.longitude || ""}`
        : "";
      break;
    case DataFieldType.TEXT || DataFieldType.NUMBER:
      value = isArray(params?.cell?.renderValue?.()) || isObject(params?.cell?.renderValue?.()) ? "" : params?.cell?.renderValue();
      break;
    default:
      value = params?.cell?.renderValue() ? moment(params.cell.getValue()).format(dateFormat) : "";
      break;
  }

  return (
    <Tooltip
      title={
        params.column.getIsFiltered()
          ? "Filter Applied"
          : params.column.getIsSorted()
            ? "Column is Sorted"
            : ""
      }
    >
      <div>
        <p
          style={{
            padding: "4px 6px",
            background: params.column.getIsFiltered()
              ? "#fef3ee"
              : params.column.getIsSorted()
                ? "#ecfbe7"
                : "",
          }}
        >
          {value}
        </p>
      </div>
    </Tooltip>
  );
});

type Props = {
  data: any;
  view: any;
  fromShowPage: boolean;
  setSelectedColumnName: (selectedColumnName: string) => void;
  setShowColumnPermissionsModal: (showColumnPermissionModal: boolean) => void;
  handleActionMenuClose: () => void;
  columnFilterFns: { [key: string]: string }
  setLinksArray: (linksArray: any[]) => void;
  setShowImage: (showImage: boolean) => void;
  refresh: boolean
  setRefresh: (refresh: boolean) => void;
  initTableConfig: any;
  accessPermissions: any;
  account: any;
  porterPermissions: any;
  dispatch: any;
  selectedEntity: any;
  savedViewChanges: any;
}

export const formatListingConfig = memoize((props: Props) => {
  const {
    data,
    view,
    fromShowPage,
    setSelectedColumnName,
    setShowColumnPermissionsModal,
    handleActionMenuClose,
    columnFilterFns,
    setLinksArray,
    setShowImage,
    refresh,
    setRefresh,
    initTableConfig,
    accessPermissions,
    account,
    porterPermissions,
    dispatch,
    selectedEntity,
    savedViewChanges,
  } = props;

  const viewId = view?.viewId;
  const isSavedViewChangesPresent = viewId && savedViewChanges && savedViewChanges[viewId];

  if (!isSavedViewChangesPresent && !fromShowPage) {
    return { colDefs: [], rowDefs: [] };
  }

  if ((!savedViewChanges[view?.viewId] || !data?.colDef?.headers) && !fromShowPage) {
    return { colDefs: [], rowDefs: [] };
  }

  const start = performance.now();

  const colDefs = createColDefs({
    data,
    fromShowPage,
    savedViewChanges,
    setSelectedColumnName,
    setShowColumnPermissionsModal,
    handleActionMenuClose,
    columnFilterFns,
    setLinksArray,
    setShowImage,
    refresh,
    setRefresh,
    initTableConfig,
    accessPermissions,
    account,
    dispatch,
    view,
    porterPermissions,
    selectedEntity,
  });


  const rowDefs = createRowDefs(data?.rowDef);

  return { colDefs, rowDefs };
});

const createColDefs = memoize(({
  data,
  fromShowPage,
  porterPermissions,
  selectedEntity,
  savedViewChanges,
  setSelectedColumnName,
  setShowColumnPermissionsModal,
  handleActionMenuClose,
  columnFilterFns,
  setLinksArray,
  setShowImage,
  refresh,
  setRefresh,
  initTableConfig,
  accessPermissions,
  account,
  dispatch,
  view
}: any) => {
  const colDefs = (data?.colDef?.headers || []).map((col: IColDefs) => {
    const filterForColumnExists = !fromShowPage ? savedViewChanges[view?.viewId]?.columnFilters?.some((filter: {  id: string, value: unknown }) => filter.id === col.colDef.name) : true;
    const enableFilter = accessPermissions?.manage_table_view || !filterForColumnExists;
    const extraProps: any = {};

    if (col.colDef.dataType === DataFieldType.TEXT) {
      extraProps.enableColumnFilter = enableFilter;
      extraProps.columnFilterModeOptions = [
        "contains",
        "empty",
        "endsWith",
        "equals",
        "startsWith",
        "empty",
        "notEmpty",
        "notEquals",
      ];
      extraProps.renderColumnActionsMenuItems = (params: any) => renderColumnActionsMenuItems({
        ...params,
        col,
        setSelectedColumnName,
        setShowColumnPermissionsModal,
        handleActionMenuClose
      });
      extraProps.Cell = (params: any) => {
        if (
          isArray(params?.cell?.renderValue?.()) ||
          isObject(params?.cell?.renderValue?.())
        ) {
          return <></>;
        }
        return <OverflowCell value={params?.cell?.renderValue()} />;
      };
    }
    if (col.colDef.dataType === DataFieldType.LINKS) {
      extraProps.enableColumnFilter = enableFilter;
      extraProps.columnFilterModeOptions = [
        "contains",
        "empty",
        "endsWith",
        "equals",
        "startsWith",
        "empty",
        "notEmpty",
        "notEquals",
      ];
      extraProps.renderColumnActionsMenuItems = (params: any) => renderColumnActionsMenuItems({
        ...params,
        col,
        setSelectedColumnName,
        setShowColumnPermissionsModal,
        handleActionMenuClose
      });
      extraProps.Cell = (params: any) => {
        if (
          isArray(params?.cell?.renderValue?.()) ||
          isObject(params?.cell?.renderValue?.())
        ) {
          return <></>;
        }
        return <LinkCellWithTooltip
        values={
          isArray(params?.cell?.renderValue()) ? params?.cell?.renderValue() : params?.cell?.renderValue()?.split(",")
        }
        />;
      };
    }

    if (col.colDef.dataType === DataFieldType.TIME) {
      extraProps.enableColumnFilter = enableFilter;
      extraProps.columnFilterModeOptions = [
        "contains",
        "empty",
        "endsWith",
        "equals",
        "startsWith",
        "empty",
        "notEmpty",
        "notEquals",
      ];
      extraProps.renderColumnActionsMenuItems = (params: any) => renderColumnActionsMenuItems({
        ...params,
        col,
        setSelectedColumnName,
        setShowColumnPermissionsModal,
        handleActionMenuClose
      });
      extraProps.Cell = (params: any) => {
        if (
          isArray(params?.cell?.renderValue?.()) ||
          isObject(params?.cell?.renderValue?.())
        ) {
          return <></>;
        }
        return <OverflowCell value={params?.cell?.renderValue()} />;
      };
    }

    if (col.colDef.dataType === DataFieldType.NUMBER) {
      extraProps.enableColumnFilter = enableFilter;
      extraProps.columnFilterModeOptions = [
        "equals",
        "greaterThan",
        "greaterThanOrEqualTo",
        "lessThan",
        "lessThanOrEqualTo",
        "between",
        "notEquals",
        "empty",
        "notEmpty",
      ];
      extraProps.renderColumnActionsMenuItems = (params: any) => renderColumnActionsMenuItems({
        ...params,
        col,
        setSelectedColumnName,
        setShowColumnPermissionsModal,
        handleActionMenuClose
      });
      extraProps.Cell = (params: any) => renderCellWithTooltip(params, DataFieldType.TEXT);
    }

    if (col.colDef.dataType === DataFieldType.BOOLEAN) {
      extraProps.enableColumnFilter = enableFilter;
      extraProps.enableSorting = false;
      extraProps.columnFilterModeOptions = ["equals"];
      extraProps.renderColumnActionsMenuItems = (params: any) => renderColumnActionsMenuItems({
        ...params,
        col,
        setSelectedColumnName,
        setShowColumnPermissionsModal,
        handleActionMenuClose
      });

      extraProps.Cell = (params: any) => renderCellWithTooltip(params, DataFieldType.BOOLEAN);
    }

    if (col.colDef.dataType === DataFieldType.ONLY_DATE) {
      extraProps.enableColumnFilter = enableFilter;
      extraProps.enableSorting = true;
      extraProps.sortingFn = "datetime";
      extraProps.columnFilterModeOptions = [
        "greaterThanOrEqualTo",
        "lessThanOrEqualTo",
        "equals",
        "empty",
        "notEmpty",
        "lessThan",
        "greaterThan",
      ]; //only allow these filter modes
      extraProps.renderColumnActionsMenuItems = (params: any) => renderColumnActionsMenuItems({
        ...params,
        col,
        setSelectedColumnName,
        setShowColumnPermissionsModal,
        handleActionMenuClose
      });
      extraProps.filterFn = "equals";
      extraProps.Cell = (params: any) => renderCellWithTooltip(params, DataFieldType.ONLY_DATE, { dateFormat: "Do MMM YYYY" });
      extraProps.Filter = ({ column }: { column: any }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            onChange={(newValue) => {
              column.setFilterValue(newValue);
            }}
            slotProps={{
              textField: {
                helperText: `Filter Mode: ${columnFilterFns[column.id] ? columnFilterFns[column.id] === 'contains' ? 'Less Than Or Equal To' : convertToTitleCase(columnFilterFns[column.id]) : "Less Than Or Equal To"}`,
                sx: { minWidth: "120px" },
                variant: "standard",
              },
              field: {
                clearable: true,
                onClear: () => column.setFilterValue(null),
              },
            }}
            value={
              column.getFilterValue()
                ? new Date(column.getFilterValue())
                : null
            }
          />
        </LocalizationProvider>
      );
    }

    if (col.colDef.dataType === DataFieldType.DATE || col.colDef.dataType === DataFieldType.DATE_TIME) {
      extraProps.enableColumnFilter = enableFilter;
      extraProps.enableSorting = true;
      extraProps.sortingFn = "datetime";
      extraProps.columnFilterModeOptions = [
        "greaterThanOrEqualTo",
        "lessThanOrEqualTo",
        "equals",
        "empty",
        "notEmpty",
        "lessThan",
        "greaterThan",
      ]; //only allow these filter modes
      extraProps.renderColumnActionsMenuItems = (params: any) => renderColumnActionsMenuItems({
        ...params,
        col,
        setSelectedColumnName,
        setShowColumnPermissionsModal,
        handleActionMenuClose
      });
      extraProps.filterFn = "lessThan";
      extraProps.Cell = (params: any) => renderCellWithTooltip(params, DataFieldType.DATE);
      extraProps.Filter = ({ column }: { column: any }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            onChange={(newValue) => {
              column.setFilterValue(newValue);
            }}
            slotProps={{
              textField: {
                helperText: `Filter Mode: ${columnFilterFns[column.id] ? columnFilterFns[column.id] === 'contains' ? 'Less Than Or Equal To' : convertToTitleCase(columnFilterFns[column.id]) : "Less Than Or Equal To"}`,
                sx: { minWidth: "120px" },
                variant: "standard",
              },
              field: {
                clearable: true,
                onClear: () => {
                  column.setFilterValue(null);
                },
              },
            }}
            value={
              column.getFilterValue()
                ? new Date(column.getFilterValue())
                : null
            }
          />
        </LocalizationProvider>
      );
    }

    if (col.colDef.dataType === DataFieldType.SIGNATURE || col.colDef.dataType === DataFieldType.IMAGE || col.colDef.dataType === DataFieldType.FILE) {
      extraProps.enableColumnFilter = false;
      extraProps.enableSorting = false;
      extraProps.size = '220';
      extraProps.renderColumnActionsMenuItems = (params: any) => renderColumnActionsMenuItems({
        ...params,
        col,
        setSelectedColumnName,
        setShowColumnPermissionsModal,
        handleActionMenuClose
      });
      extraProps.Cell = (params: any) => renderCellWithTooltip(params, col.colDef.dataType);
      extraProps.muiTableBodyCellProps = (params: any) => ({
        onClick: () => {
          const uploadedFiles = params?.cell?.renderValue();
          if (!uploadedFiles) return;
          if (uploadedFiles?.length === 0) return;

          if (typeof uploadedFiles === "string") {
            setLinksArray([{ url: uploadedFiles, title: "Image" }]);
          } else {
            const links = uploadedFiles.map((link: any, idx: any) => {
              return { url: link, title: `Image ${idx}` };
            });
            if (!links) {
              setLinksArray(undefined);
            }
            setLinksArray(links);
            setShowImage(true);
          }
        },
      });

      extraProps.cellType = col.colDef.dataType;
      extraProps.enableColumnFilter = false;
      extraProps.enableSorting = false;
    }

    if (col.colDef.dataType === ActionTypeEntity.BUTTON) {
      extraProps.Cell = (params: any) => {
        return (
          <div
            style={{
              display: "flex",
              gap: "8px",
              padding: "0px 6px",
              justifyContent: "center",
              alignItems: "center",
              width: '100%',
            }}
          >
            <ZTableButton
              params={params}
              col={col}
              refresh={refresh}
              setRefresh={setRefresh}
              fromShowPage={fromShowPage}
              tableType={initTableConfig?.tableType}
            />
          </div>
        );
      };

      extraProps.disableColumnMenu = true;
      extraProps.enableColumnActions = true;
      extraProps.onClick = () => {};
      extraProps.Header = (params: any) => {
        return (
            <ButtonAutomationHeader
              allowColumnEdit={accessPermissions?.manage_table_column}
              col={col}
              params={params}
            />
        );
      };
      extraProps.enableColumnFilter = false;
      extraProps.enableSorting = false;
      extraProps.renderColumnActionsMenuItems = (params: any) => renderColumnActionsMenuItems({
        ...params,
        col,
        setSelectedColumnName,
        setShowColumnPermissionsModal,
        handleActionMenuClose
      });
    }

    if (col.colDef.dataType === DataFieldType.LOCATION) {
      extraProps.Cell = (params: any) => {
        const val = params?.cell?.renderValue?.()?.address;
        return <OverflowCell color="#3067c4" value={params?.cell?.renderValue() &&
          (params?.cell?.renderValue()?.address ||
            (params?.cell?.renderValue()?.latitude &&
              params?.cell?.renderValue()?.longitude))
          ? params?.cell?.renderValue?.()?.address ||
          `${params?.cell?.renderValue()?.latitude || ""}, ${params?.cell?.renderValue()?.longitude || ""}`
          : ""} />;
      };
      extraProps.enableColumnFilter = enableFilter;
      extraProps.enableSorting = false;
      extraProps.disableColumnMenu = true;
      extraProps.columnFilterModeOptions = [
        "contains",
        "empty",
        "endsWith",
        "equals",
        "startsWith",
        "empty",
        "notEmpty",
        "notEquals",
      ];
      extraProps.renderColumnActionsMenuItems = (params: any) => renderColumnActionsMenuItems({
        ...params,
        col,
        setSelectedColumnName,
        setShowColumnPermissionsModal,
        handleActionMenuClose
      });
    }

    if (col.colDef.dataType === DataFieldType.LIST_OF_TEXT) {
      extraProps.enableColumnFilter = enableFilter;
      extraProps.Cell = (params: any) => {
        return (
          <ListOfText params={params} col={col} complimentColor={complimentColor} />
        );
      };
      extraProps.filterSelectOptions = col.colDef?.properties?.options?.map(
        (val: any) => {
          return {
            text: val,
            value: val,
          };
        }
      );
      extraProps.filterVariant = "select";
      extraProps.enableSorting = false;
      extraProps.columnFilterModeOptions = [
        "contains",
        "empty",
        "notEmpty",
      ];
      extraProps.renderColumnActionsMenuItems = (params: any) => renderColumnActionsMenuItems({
        ...params,
        col,
        setSelectedColumnName,
        setShowColumnPermissionsModal,
        handleActionMenuClose
      });
    }

    if (col.colDef.dataType === DataFieldType.RELATION) {
      let len = 0;
      extraProps.Cell = (params: any) => {
          const val = params.cell.renderValue?.();
          if (!Array.isArray(val)) {
              return <></>;
          }
          const displayColumn = col.colDef?.displayColumn;
          const referringEntity = col.colDef?.relationDetails?.referringEntity;
          const defaultDisplayColumn = ["recordId", "taskId"];
          len = val?.length;
          const colId = params?.cell?.column?.id;
          const values = val?.map((v: any) => {
              if(displayColumn) return defaultDisplayColumn.includes(displayColumn)
                  ? v?.[displayColumn]
                  : referringEntity === IRelationReferringEntityType.USER
                  ? v?.[displayColumn]
                  : v?.data?.[displayColumn]
              else return v?.[defaultDisplayColumn[0]];
            });
          return (
              referringEntity === IRelationReferringEntityType.USER ? (
                <UserTeamRelationRender relationContent={params?.cell?.row?.original?.[colId]} isUser={true}/>
              ) :  referringEntity === IRelationReferringEntityType.TEAM ? 
                <UserTeamRelationRender relationContent={params?.cell?.row?.original?.[colId]}/>
              : (
                <RelationCellWithTooltip relationContent={params?.cell?.row?.original?.[colId]} values={values}/>
              )
          );
      }};

    if (col.colDef.dataType === EntityTypeField.COMPUTED) {
      extraProps.enableColumnFilter = false;
      extraProps.enableSorting = false;
      extraProps.disableColumnMenu = true;
      extraProps.renderColumnActionsMenuItems = (params: any) => renderColumnActionsMenuItems({
        ...params,
        col,
        setSelectedColumnName,
        setShowColumnPermissionsModal,
        handleActionMenuClose
      });
      extraProps.Cell = (params: any) => {
        if (
          isArray(params?.cell?.renderValue?.()) ||
          isObject(params?.cell?.renderValue?.())
        ) {
          return <></>;
        }
        return <OverflowCell value={params?.cell?.renderValue()} />;
      };
    }

    return {
      accessorKey: col.id || col.type,
      header: col.label,
      filterFn: "contains",
      data: {
        ...col.colDef,
        type: col.colDef.dataType,
      },
      Header: (params: any) => {
        return (
            <ButtonAutomationHeader
              allowColumnEdit={accessPermissions?.manage_table_column}
              col={col}
              params={params}
            />
        );
      },
      Cell: (params: any) => renderCellWithTooltip(params, col.colDef.dataType),
      ...extraProps,
    };
  });

  if (initTableConfig === undefined) {
    colDefs?.splice(0, 0, {
      accessorKey: "recordId",
      header: "Record Id",
      enableClickToCopy: true,
      enableSorting: true,
      pinned: "left",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      cellStyle: { fontWeight: "500" },
      props: {
        isSystemField: true,
      },
      size: 150,
      columnFilterModeOptions: [
        "contains",
        "empty",
        "endsWith",
        "equals",
        "startsWith",
        "empty",
        "notEmpty",
        "notEquals",
      ],
      Cell: (params: any) => renderCellWithTooltip(params, DataFieldType.TEXT),
      Header: (params: any) => {
        return (
            <SystemFieldHeader
              dataType={DataFieldType.ID}
              name="ID"
              params={params}
            />
        );
      },
    });
  }

  colDefs?.splice(1, 0, {
    accessorKey: "createdOn",
    header: 'Created On',
    size: 200,
    accessorFn: (row: any) =>
      moment(row.createdOn).tz(account?.accountConfig?.timezone),
    Header: (params: any) => {
      return (
          <SystemFieldHeader
            dataType={DataFieldType.DATE}
            name="Created On"
            params={params}
          />
      );
    },
    enableColumnFilter: true,
    enableSorting: true,
    pinned: "left",
    columnFilterModeOptions: [
      "greaterThanOrEqualTo",
      "lessThanOrEqualTo",
      "equals",
      "empty",
      "notEmpty",
      "lessThan",
      "greaterThan",
    ], //only allow these filter modes
    filterFn: "equals",
    sortingFn: "datetime",
    lockPinned: true,
    cellStyle: { fontWeight: "500" },
    props: {
      isSystemField: true,
    },
    Cell: (params: any) => renderCellWithTooltip(params, DataFieldType.DATE),
    Filter: ({ column }: { column: any }) => (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          onChange={(newValue) => {
            column.setFilterValue(newValue);
          }}
          slotProps={{
            textField: {
              helperText: `Filter Mode: ${columnFilterFns[column.id] ? columnFilterFns[column.id] === 'contains' ? 'Less Than Or Equal To' : convertToTitleCase(columnFilterFns[column.id]) : "Less Than Or Equal To"}`,
              sx: { minWidth: "120px" },
              variant: "standard",
            },
            field: {
              clearable: true,
              onClear: () => column.setFilterValue(null),
            },
          }}
          value={
            column.getFilterValue()
              ? new Date(column.getFilterValue())
              : null
          }
        />
      </LocalizationProvider>
    ),
  });

  colDefs?.splice(2, 0, {
    accessorKey: "updatedOn",
    size: 220,
    accessorFn: (row:any) =>
      moment(row.updatedOn).tz(account?.accountConfig?.timezone),
    header: 'Updated On',
    enableColumnFilter: true,
    enableSorting: true,
    columnFilterModeOptions: [
      "greaterThanOrEqualTo",
      "lessThanOrEqualTo",
      "equals",
      "empty",
      "notEmpty",
      "lessThan",
      "greaterThan",
    ], //only allow these filter modes
    sortingFn: "datetime",
    filterFn: "equals",
    pinned: "left",
    lockPinned: true,
    cellStyle: { fontWeight: "500" },
    props: {
      isSystemField: true,
    },
    Cell: (params: any) => renderCellWithTooltip(params, DataFieldType.DATE),
    Filter: ({ column }: { column: any }) => (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          onChange={(newValue) => {
            column.setFilterValue(newValue);
          }}
          slotProps={{
            textField: {
              helperText: `Filter Mode: ${columnFilterFns[column.id] ? columnFilterFns[column.id] === 'contains' ? 'Less Than Or Equal To' : convertToTitleCase(columnFilterFns[column.id]) : "Less Than Or Equal To"}`,
              sx: { minWidth: "120px" },
              variant: "standard",
            },
            field: {
              clearable: true,
              onClear: () => column.setFilterValue(null),
            },
          }}
          value={
            column.getFilterValue()
              ? new Date(column.getFilterValue())
              : null
          }
        />
      </LocalizationProvider>
    ),
    Header: (params: any) => {
      return (
          <SystemFieldHeader
            dataType={DataFieldType.DATE}
            name="Updated On"
            params={params}
          />
      );
    },
  });

  if (initTableConfig === undefined && porterPermissions.checkPermissionsForPorter(selectedEntity?.tableType, "add_column")) {
    colDefs.push({
      accessorKey: "addRow",
      header: 'Add Row',
      size: 50,
      pinned: "right",
      lockPinned: true,
      enableSorting: false,
      enableColumnFilter: false,
      enableColumnActions: false,
      enableColumnOrdering: false,
      enableHiding: false,
      enableResizing: false,
      disableColumnMenu: true,
      Header: () => {
        return (
          <>
            {accessPermissions?.manage_table_column && <Tooltip title={"Add New Column"}>
              <div
                style={{ width: "100%", padding: '4px 6px' }}
                onClick={() => {
                  if (accessPermissions?.manage_table_column) dispatch(setShowCreationModal(true))
                  else {
                    notification("error", "Permission Denied");
                    return;
                  }
                }}
                id={OnboardingIdsForComponent.CREATE_NEW_COLUMN_BUTTON}
                className="zorpTable-addRow"
              >
                <AiOutlinePlus size="1.5rem" strokeWidth={"2px"} />
              </div>
            </Tooltip>}
          </>
        );
      },
    });
  }

  return colDefs;
});

const createRowDefs = memoize((rowDef: IRowDefs[]) => {
  return rowDef?.map((row) => ({
    ...row.data,
    recordId: row?.recordId,
    createdOn: row?.createdOn,
    updatedOn: row?.updatedOn,
  }));
});

export const arraysAreEqual = memoize((arr1: string[], arr2: string[], selectedEntityMetadata: IselectedMetadata | null): boolean => {
    if (!Array.isArray(arr1) || !Array.isArray(arr2) || !selectedEntityMetadata) {
        return false;
    }
  
    const customFields = selectedEntityMetadata.customFields || [];
  
    const deletedColumns = customFields.filter((el) => el.hasOwnProperty('isDeleted') && el.isDeleted);
  
    if (deletedColumns.length > 0) {
        const deletedColumnNames = deletedColumns.map((el:any) => el.name);
        arr1 = arr1.filter((item) => !deletedColumnNames.includes(item));
        arr2 = arr2.filter((item) => !deletedColumnNames.includes(item));
    }
  
    if (arr1.length !== arr2.length) return false;
  
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false;
    }
      return true;
})
  
  export const checkColumnVisibility = (prev: any, next: any) => {
    if(!prev && !next) return true;
    else if (!prev || !next) return false;
    if (typeof prev === 'object' && typeof next === 'object') {
      const prevKeys = Object.keys(prev);
      const nextKeys = Object.keys(next);
      if (prevKeys.length !== nextKeys.length) return false;
      for (const key of prevKeys) {
        if (!nextKeys.includes(key)) return false;
        if (prev[key] !== next[key]) return false;
      }
      return true;
    }
    return prev === next;
  }
  
  export const deepCompare = memoize((obj1: any, obj2: any, path: string[] = []) => {
  
    const keys1 = Object.keys(obj1 || {});
    const keys2 = Object.keys(obj2 || {});
    let sharedKeys = keys1.filter(key => key !== 'showGlobalFilter');
    sharedKeys = keys1.filter(key => keys2.includes(key));
    if(keys1.includes('columnVisibility') && obj1.columnVisibility && !keys2.includes('columnVisibility')) {
      sharedKeys.push('columnVisibility');
    }
  
    for (const key of sharedKeys) {
      const newPath = path.concat([key]);
      const val1 = obj1[key];
      const val2 = obj2[key];
      const keysThatShouldNotBeCompared = [ 'showColumnFilters', 'showGlobalFilter', 'columnPinning', 'columnOrdering', 'pageIndex'];
      if (keysThatShouldNotBeCompared.includes(key)) continue;
  
      if(key === 'columnVisibility') {
        if(!checkColumnVisibility(val1, val2 || {})) return {isEqual: false, valChanged: newPath.join('.')};
      }
  
      if (Array.isArray(val1) && Array.isArray(val2)) {
        if (val1.length !== val2.length) {
          return { isEqual: false, valChanged: newPath.join('.') };
        }
        if (val1.every(item => typeof item !== 'object') && val2.every(item => typeof item !== 'object')) {
          const sortedVal1 = [...val1];
          const sortedVal2 = [...val2];
          if (!sortedVal1.every((item, index) => item === sortedVal2[index])) {
            return { isEqual: false, valChanged: newPath.join('.') };
          }
        } else {
          const arrayComparisonSet1 = new Set(val1.map(item => JSON.stringify(item)));
          const arrayComparisonSet2 = new Set(val2.map(item => JSON.stringify(item)));
          if (arrayComparisonSet1.size !== arrayComparisonSet2.size || ![...arrayComparisonSet1].every(item => arrayComparisonSet2.has(item))) {
            return { isEqual: false, valChanged: newPath.join('.') };
          }
        }
      } else if (typeof val1 === 'object' && val1 !== null && typeof val2 === 'object' && val2 !== null) {
        const res:any = deepCompare(val1, val2, newPath);
        if (res.isEqual === false) return res;
      } else if (val1 !== val2) {
        return { isEqual: false, valChanged: newPath.join('.') };
      }
    }
  
    return { isEqual: true, valChanged: undefined };
  });
  
  export const handleActionMenuClose = () => {
    const backdrop = document.querySelector('.MuiBackdrop-root') as HTMLElement | null;
    const backDropModal = document.querySelector('.MuiModal-backdrop') as HTMLElement | null;
    
    if (backdrop) {
      backdrop.click();
    }
    
    if (backDropModal) {
      backDropModal.click();
    }
  }