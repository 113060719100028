import { AxiosResponse } from 'axios';
import HttpUtil from 'config/AxiosConfig';
import { ZorpResponse } from 'providers/data/models/ZorpResponse';
import { convertToISO, convertToISOEndOf, isBeforeCheck } from 'utils/DateFunctions.utils';

export class TaskService {
  public static listTasks(params: any): Promise<ZorpResponse | undefined> {
    const preview = localStorage.getItem('isPreview') ?? false

    let queryParams:any = {
      preview: preview,
    };
    const filterData = params.filter;
    if (filterData.data?.orderInfo || filterData.data) {
      let searchFieldValueMap: any = {};

      if (filterData.data?.orderInfo) {
        for (let key of Object.keys(filterData.data.orderInfo)) {
          let k = 'data.orderInfo.' + key;
          searchFieldValueMap[k] = filterData.data.orderInfo[key];
        }
      }

      if (filterData.data) {
        for (let key of Object.keys(filterData.data)) {
          if (key !== 'orderInfo') {
            let k = 'data.' + key;
            searchFieldValueMap[k] = filterData.data[key];
          }
        }
      }

      queryParams = {
        ...queryParams,
        searchFieldValuePair: searchFieldValueMap,
      };
    }

    if (filterData.taskTypes)
      queryParams = {
        ...queryParams,
        taskTypes: filterData.taskTypes,
      };
    if (filterData.search)
      queryParams = {
        ...queryParams,
        searchStates: filterData.search,
      };

    if (filterData.taskStatuses)
      queryParams = {
        ...queryParams,
        taskStatuses: filterData.taskStatuses,
      };

    if (filterData.teamIds)
      queryParams = {
        ...queryParams,
        teamIds: filterData.teamIds,
      };
    if (filterData.userId)
      queryParams = {
        ...queryParams,
        userId: filterData.userId,
      };
    if (filterData.searchString)
      queryParams = {
        ...queryParams,
        searchString: filterData.searchString,
      };

    if (filterData.scheduledFromTime && filterData.scheduledToTime) {
      const SchFromTime = convertToISO(filterData.scheduledFromTime);
      const SchToTime = convertToISOEndOf(filterData.scheduledToTime);
      // if (isBeforeCheck(SchFromTime, SchToTime)) {
        queryParams = {
          ...queryParams,
          scheduledFromTime: SchFromTime,
          scheduledToTime: SchToTime,
        };
      // }
    }

    if (filterData.fromTime && filterData.toTime) {
      const from = convertToISO(filterData.fromTime);
      const to = convertToISOEndOf(filterData.toTime);
      // if (isBeforeCheck(from, to)) {
        queryParams = {
          ...queryParams,
          fromTime: from,
          toTime: to,
        };
      // }
    }

    // Task id
    if (filterData.taskId && filterData.taskId.length > 0) {
      queryParams = {
        ...queryParams,
        taskId: filterData.taskId,
      };
    }

     // Exclude deleted
    queryParams = {
      ...queryParams,
      excludeDeleted: !filterData.includeDeleted,
    };
    

    const { page, perPage } = params.pagination;

    queryParams = {
      ...queryParams,
      page: page - 1,
      size: perPage,
    };

    const st = params.sort;
    var sortField = st.field;
    var sortOrder = st.order;

    queryParams = {
      ...queryParams,
      sortField: sortField,
      sortOrder: sortOrder
    };
    
    // Filter Task API
    return HttpUtil.bffInstance.post('/tasks', queryParams).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static getTask(taskId: string): Promise<ZorpResponse | undefined> {
    return HttpUtil.taskInstance.get(`/task/find?taskId=${taskId}`).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static createTask(taskPayload: any): Promise<ZorpResponse | undefined> {
    return HttpUtil.taskInstance.post(`/task/create`, taskPayload).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  // public static deleteTask(taskId?: string): Promise<ZorpResponse | undefined> {
  //     let taskPayload = {
  //         "taskId": taskId,
  //         "event": "DELETE",
  //     }

  //     return HttpUtil.taskInstance.post(`/task/transit`, taskPayload)
  //     .then((response: AxiosResponse) => {
  //         return (response.data as ZorpResponse);
  //     }).catch((err) => {
  //         return err;
  //     });
  // }

  public static getMetabaseUrl(teams: string): Promise<ZorpResponse | undefined> {
    return HttpUtil.bffInstance.get('/metabase/dashboard?' + teams).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }
}
