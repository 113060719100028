// It takes the input entered by the user in the SmartInput Field and returns
// whether it is an expression or literal. The type would be script if the user
// enters the script from the JSEditor.
const getInputType = (input:string):any => {
    if(input && typeof input === "string" && input?.includes("{{") && input?.includes("}}")) {
        return "expression"
    } else {
        return "literal"
    }
} 
export default getInputType