import HttpUtil from "config/AxiosConfig";
import notification from "notifications/notifications";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import {  DNDContainer, Divider, HelperText, IconContainer, IconP, Info, Label, ResponsiveWrapper, Wrapper } from "../settings.style";
import { Block } from "../GeneralSetting/GeneralSettingEdit";
import { AiOutlineCloudUpload } from "react-icons/ai";



type Props = {
  logo: string;
  setLogo: (logo: string) => void;
};

const WhiteLabelling = ({logo, setLogo}: Props) => {

    const [loading, setLoading] = useState<boolean>(false);

    const uploadFile = async (files: any[]) => {
      setLoading(true);
      notification("info", "Uploading logo...");
        files.forEach(async (file) => {
          let formData = new FormData();
          formData.append("media", file);
          try {
            const response = await HttpUtil.bffInstance.post(
              "/task/3123123/media",
              formData,
            );
            setLogo(response?.data?.data[0].path)
            notification("success", "Logo uploaded successfully. Click on save and refresh the page to see the changes");
          } catch (e) {
           
            notification("error", "Cannot upload image. Something went wrong");
            console.error(e);
          }
          setLoading(false);
        });
    };

    const onDropAccepted = useCallback((acceptedFiles) => {
        uploadFile(acceptedFiles);
      }, []);
    
      const onDropRejected = useCallback((rejectedFile) => {
        rejectedFile.forEach((file:any) => {
          file.errors.forEach((error:any) => {
            notification("error", error.message)
          })
        })
        
      },[]) 

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        isDragActive,
      } = useDropzone({
        onDropAccepted,
        onDropRejected,
        multiple: false,
        maxFiles: 1,
        accept:{ "image/*": [".png", ".gif", ".jpeg", ".jpg"]}
      });

  return (
    <>
      <Block>
        <div>
          <Label style={{marginTop:"30px"}} large bold > Company Logo </Label>
        </div>
        <ResponsiveWrapper style={{width:"100%"}}> 
          <Block>
            
            {logo ? <div><img alt="Company logo" src={logo} style={{width:"100px", height:"100px"}}/></div> : <div></div>}
            <div  {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
              {/* <ZButton sx={{marginTop:"10px"}} secondary variant="contained" > Upload Logo </ZButton> */}
              <DNDContainer>
                <IconContainer>
                  <IconP>
                    <AiOutlineCloudUpload size={"2rem"}/>
                  </IconP>
                </IconContainer>
                <HelperText> Click to Upload or drag and drop the image here! </HelperText>
                <HelperText> SVG, PNG, JPG or GIF (max. 800x400px) </HelperText>
              </DNDContainer>
          </div>
        </Block>
        </ResponsiveWrapper>
      </Block>
    </>
  );
};

export default WhiteLabelling;
