import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Link,
} from "@mui/material";
import styled from "styled-components";
import useTaskShow from "views/workflows/hooks/useTaskShow";
import { UserService } from "providers/data/services/UserService";
import moment from "moment";

interface DataEntry {
  Task_Status?: string[];
  Completed_at?: string;
  Task_Owner?: string[];
  Task_Owner_Team?: string[];
}

interface Record {
  data: DataEntry;
  createdOn: string;
  recordId?: string;
  taskId?: string;
  taskType?: string;
  taskStatusName?: string;
  tableType?: string;
  user?: any;
  tableName?: string;
  taskTypeDisplayName?: string;
  Completed_at?: string;
}

interface EnrichedData {
  [key: string]: Record[] | undefined;
}

export const BlockHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e1e4e8;
  padding: 8px 16px;
  background: ${(props: { backgroundColor?: string }) => {
    const primaryColor = props?.theme.color.primary || "";
    const rgb = hexToRgb(primaryColor);
    return rgb ? `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.1)` : "";
  }};
  font-size: 12px;
  border-radius: 4px 4px 0 0;
  color: #3054b9;
  font-weight: 500;
`;
export const BlockContainer = styled.div`
  border: 1px solid #eaeaef;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-bottom: 16px;
  border-radius: 4px;
  max-height: 440px;
  overflow: auto;
`;

const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

const getTaskOwnerDetail = async (TaskOwners: string[]) => {
  let taskOwnernames = [];
  let taskOwnerTeam = [];
  for (let i = 0; i < TaskOwners.length; i++) {
    const user = await UserService.getUser(TaskOwners[i]);
    if (user) {
      taskOwnernames.push(user?.data?.name);
      if (user.data.teams) {
        taskOwnerTeam.push(...user.data.teams.map((team: any) => team.name));
      }
    }
  }
  return { taskOwnernames, taskOwnerTeam };
};

async function fetchTableData(enrichedData: EnrichedData) {
  const fieldsToProcess = [
    "VoC_to_Bug_Linking",
    "Field_Visit_Log",
    "Field_Rework_Log",
    "Loaner_Vehicle_Allocation",
    "Loaner_Vehicle_Issue",
    "Factory_Rework",
    "Factory_Rework_Log",
    "Loaner_Vehicle_Return",
    "Loaner_Pack_Allocation",
    "Loaner_Pack_Issue",
    "Loaner_Pack_Return",
    "New_Bug_Addition",
    "Pump_Field_Visit_Log",
  ];

  const allTasks = fieldsToProcess.flatMap(
    (field) => enrichedData[field] || []
  );
  const tasksWithDetails = await Promise.all(
    allTasks.map(async (record) => {
      const {
        data,
        createdOn,
        recordId,
        taskId,
        tableType,
        taskType,
        user,
        tableName,
        taskTypeDisplayName,
        taskStatusName,
        Completed_at,
      } = record;
      const taskStatus = data.Task_Status
        ? data.Task_Status.join(", ")
        : taskStatusName
        ? taskStatusName
        : "";
      const completedAtLocal = data.Completed_at || Completed_at || "";
      const isTaskRelation = taskId ? true : false;
      const createdOnRaw = isValidDate(createdOn) ? new Date(createdOn) : new Date(0);
      const fieldName = tableName || taskTypeDisplayName || "";
      const createdOnFormatted = isValidDate(createdOn)
        ? moment(createdOn).format("Do MMM YYYY , h:mm A")
        : "-";
      const completedAtFormatted = isValidDate(completedAtLocal)
        ? moment(completedAtLocal).format("Do MMM YYYY , h:mm A")
        : "-";

      let taskOwner = [];
      let taskOwnerTeam = [];

      if (!isTaskRelation && data.Task_Owner) {
        const res = await getTaskOwnerDetail(data.Task_Owner);
        taskOwner = res.taskOwnernames;
        taskOwnerTeam = res.taskOwnerTeam;
      } else {
        taskOwner = [user?.name || ""];
        if (user?.teams) {
          taskOwnerTeam.push(...user?.teams.map((team: any) => team.name));
        }
      }

      return {
        recordId: recordId || taskId,
        fieldName,
        createdOnRaw: createdOnRaw,
        createdOn: createdOnFormatted,
        taskStatus,
        taskOwner: taskOwner.join(", "),
        taskOwnerTeam: taskOwnerTeam.join(", "),
        completedAt: completedAtFormatted || "-",
        entityType: taskType || tableType,
        isTaskRelation,
      };
    })
  );

  tasksWithDetails.sort((a, b) => a.createdOnRaw.getTime() - b.createdOnRaw.getTime());

  return {
    tableHeader: [
      "Item/Task_Id",
      "Item/Task Name",
      "Created Date",
      "Current Status",
      "Task Owner",
      "Team",
      "Completed at",
    ],
    rows: tasksWithDetails,
    isEmpty: false
  };
}

// Helper function to validate date
function isValidDate(dateString) {
  return moment(dateString, moment.ISO_8601, true).isValid();
}

const defaultRow = async (enrichedData: any, recordDetails: any) => {
  let taskOwner = ""
  let taskOwnerTeam = ""
  if(enrichedData?.Owner) {
    const userIds = enrichedData?.Owner?.map((owner:any) => owner.userId);
    const ownerUser = await getTaskOwnerDetail(userIds);
    if(ownerUser) {
      taskOwner = ownerUser?.taskOwnernames?.join(", ") || ""
      taskOwnerTeam = ownerUser?.taskOwnerTeam?.join(", ") || ""
    } 
    
  }
  
  
  return {
    recordId: recordDetails?.recordId || "",
    fieldName: "Job Cards",
    createdOnRaw: recordDetails?.createdOn || "",
    createdOn: isValidDate(recordDetails?.createdOn)
      ? moment(recordDetails?.createdOn).format("Do MMM YYYY , h:mm A")
      : "-",
    taskStatus: enrichedData?.Status || "",
    taskOwner: taskOwner || "-",
    taskOwnerTeam: taskOwnerTeam || "-",
    completedAt: isValidDate(enrichedData?.Completed_at)
    ? moment(enrichedData?.createdOn).format("Do MMM YYYY , h:mm A")
    : "-",
    entityType: "yccpj1",
    isTaskRelation: false,
  };
};

function DynamicTable() {
  const [tableData, setTableData] = useState({
    tableHeader: [],
    rows: [],
    isEmpty: true,
  });
  const { enrichedData, recordDetails } = useTaskShow();
  const [loading, setLoading] = useState(false);


  const getTableData = async (enrichedData:any) => {
    try {
      fetchTableData(enrichedData || {}).then(async (data) => {
        const defaultRowData = await defaultRow(enrichedData, recordDetails);
        setTableData({ ...data, rows: [defaultRowData, ...data.rows] });
        setLoading(false);
      }).catch((error) => {
        console.error("Error while fetching table data", error);
        setLoading(false);
      })
    } catch (error) {
      setLoading(false);
    }

  }

  useEffect(() => {
    if(!enrichedData) return
    getTableData(enrichedData);
    setLoading(true);
    
  }, [enrichedData]);

  return (
    <div style={{ width: "100%", marginBottom: "16px" }}>
      <BlockHeader>Summary</BlockHeader>
      <BlockContainer>
        <TableContainer
          component={Paper}
          style={{ maxHeight: 440, maxWidth: "100%", overflow: "auto" }}
        >
          <Table stickyHeader aria-label="dynamic table">
            <TableHead>
              <TableRow>
                {tableData.tableHeader &&
                  !tableData.isEmpty &&
                  tableData.tableHeader.map((header) => (
                    <TableCell
                      key={header}
                      sx={{ fontWeight: 500 }}
                    >
                      <p style={{ fontWeight: "500" }}>{header}</p>
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!tableData.isEmpty ? (
                tableData.rows.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {Object.entries(row).map(([key, value], cellIndex) => {
                      if (key === "isTaskRelation" || key === "createdOnRaw" || key === "entityType") {
                        return null;
                      }
                      if ((key === "recordId" || key === "taskId") && value) {
                        const url = row?.isTaskRelation
                          ? `https://app.zorp.one/#/task/${row.entityType}/show/${value}`
                          : `https://app.zorp.one/#/table/${row.entityType}/show/${value}`;
                        return (
                          <TableCell sx={{fontSize:"12px"}} key={cellIndex}>
                            <Link
                              href={url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {value || "Undefined ID"}
                            </Link>
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell sx={{fontSize:"12px"}} key={cellIndex}>{value || "-"}</TableCell>
                        );
                      }
                    })}
                  </TableRow>
                ))):(
                  <div
                    style={{padding:"8px"}}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "13px",
                        fontWeight: 500
                      }}
                    >{
                      loading ? "Loading..." : "No data found"
                    } </p>
                  </div>
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </BlockContainer>
    </div>
  );
}

export default DynamicTable;
