// Automation Name Validation
export const ABNameErrorMessage = 'Automation names must be unique';

// Trigger Validation for System Validation - checking same trigger is repeating
export const ABTriggerNameErrorMessage = 'Avoid duplication in Triggers';

// Trigger Name Validation for Smart Component
// export const ABTriggerNameErrorMessageSC = 'Looks like you have added same kind of triggers.Avoid duplication';
export const ABTriggerNameErrorMessageSC = 'Trigger with "${triggerName}" name repeated. Avoid duplication';

// Location Guard Name Validation Message
export const ABLocationGuardNameMessage = 'Location Validation names must be unique';

// Expression Guard Name Validation Message
export const ABExpressionGuardErrorMessage = 'Expression Validation names must be unique';

// Expression Guard Expression Validation Message
export const ABExpressionGuardExpressionErrorMessage = 'No Expression found in "${guardName}" Validation, Add an expression';

// Expression Condition Name Unique Validation Error Message
export const ABExpressionConditionNameErrorMessage = 'Condition names should be unique';

// Expression Condition Name Validation Error Message
export const ABExpressionConditionErrorMessage = 'Please name your Condition';

// Expression Condition - Expression Error Message
export const ABExpressionConditionExpressionErrorMessage = 'No Expression found in "${conditionName}" Condition, Add an expression';

// Action Name Unique Validation Error Message
export const ABActionNameUniqueErrorMessage = 'Action names should be unique';

// Expression Action - Expression Error Message
export const ABExpressionActionExpressionErrorMessage = 'No Expression found in "${actionName}" Action, Add an expression';
