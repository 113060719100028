import { ActionToPerform } from "constants/CommonConstants";
import { Dispatch } from "react";
import {
  setActionPayload,
  setAutomationBuilderOpen,
  setSelectedConfiguration,
  setSelectedTrigger,
} from "views/automationBuilder/reducer/automationReducer";
import {
  ABMetadataTypeEnum,
  IConfiguration,
  actionType,
} from "../types/ABType";
import { setSelectedAction } from "views/workflows/reducers/workflowAction";
import { EntityService } from "providers/data/services/EntityService";
import { CronService } from "providers/data/services/CronService";
import { MonitorService } from "providers/data/services/MonitorService";
import { BuilderService } from "providers/data/services/BuilderService";
import { AccountService } from "providers/data/services/AccountService";
import { updateMockData } from "../../../lowcode/state/scriptEditorState";
import { getAccountId } from "utils/CommonUtils";
import { EntityTypeField } from "views/DataField/DataField.types";
import {
  CreateTaskIcon,
  DeleteTaskIcon,
  SearchTaskIcon,
  TransitTaskIcon,
  UpdateRecordIcon,
  UpdateSlotIcon,
  UpdateTaskIcon,
  PlusIcon,
  TrashRIcon,
  UpdateTeamIcon,
  TrashIcon,
  RestApiIcon,
  PushNotificationIcon,
  CodeIcon,
} from "assests/index";
import {
  ZorpTableActionIds,
  ZorpTaskActionIds,
} from "@zorp/common-libs-js/dist/common/types/types";
import { BffService } from "providers/data/services/BffService";
import { ActionType } from "@zorp/common-libs-js/dist/common/enums/ZorpActionType";

export const getActionIcon = (action: any) => {
  switch (action) {
    case ZorpTaskActionIds.CREATE_ZORP_TASK:
      return CreateTaskIcon;
    case ZorpTaskActionIds.DELETE_ZORP_TASK:
      return DeleteTaskIcon;
    case ZorpTaskActionIds.SEARCH_ZORP_TASK:
      return SearchTaskIcon;
    case ZorpTaskActionIds.TRANSIT_ZORP_TASK:
      return TransitTaskIcon;
    case ZorpTaskActionIds.UPDATE_ZORP_TASK:
      return UpdateRecordIcon;
    case ZorpTaskActionIds.UPDATE_SLOT:
      return UpdateSlotIcon;
    case ZorpTaskActionIds.UPDATE_TEAM:
    case actionType.TASK_UPDATE_TEAM_ACTION:
      return UpdateTeamIcon;
    case ZorpTableActionIds.CREATE_ZORP_RECORD:
      return PlusIcon;
    case ZorpTableActionIds.DELETE_ZORP_RECORD:
      return TrashIcon;
    case ZorpTableActionIds.UPDATE_ZORP_RECORD:
      return UpdateRecordIcon;
    case ZorpTableActionIds.SEARCH_ZORP_RECORD:
      return SearchTaskIcon;
    case actionType.API_ACTION:
      return RestApiIcon;
    case actionType.PUSH_NOTIFICATION:
      return PushNotificationIcon;
    case actionType.JS_FUNCTION_ACTION:
    case actionType.EXPRESSION:
      return CodeIcon;
    default:
      return PlusIcon;
  }
};

export const showAutomationBuilder = ({
  dispatch,
  visibility,
}: {
  dispatch: Dispatch<any>;
  visibility: boolean;
}) => {
  dispatch(setAutomationBuilderOpen(visibility));
};

/**
 *
 * @param param0
 * This function will be called to set any active/selected trigger/action/condition
 */
export const setSelectedFunction = ({
  dispatch,
  type,
  payload,
}: {
  dispatch: Dispatch<any>;
  type: IConfiguration;
  payload: any;
}) => {
  if (type === "trigger") {
    const tempTrigger = { ...payload, actionToPerform: ActionToPerform.EDIT };

    dispatch(setSelectedTrigger(null));
    dispatch(setSelectedAction(null));
    dispatch(setSelectedConfiguration({ type: null }));
    dispatch(setActionPayload([]));

    setTimeout(() => {
      dispatch(setSelectedTrigger(tempTrigger));
      dispatch(setSelectedConfiguration({ type }));
    }, 10);
  } else if (type === "action" || type === "guard" || type === "condition") {
    /**
     * For action, we first have to show a dropdown to select the action type
     * Then depending upon the action type, we will show the action configuration
     * Here we are configuring whether to show action dropdown or condition dropdown
     */
    const tempAction = {
      ...payload,
      actionToPerform: ActionToPerform.EDIT,
      hasChanged: false,
    };

    dispatch(setSelectedTrigger(null));
    dispatch(setSelectedAction(null));
    dispatch(setActionPayload([]));

    setTimeout(() => {
      dispatch(setSelectedAction({ ...tempAction }));
      dispatch(setSelectedConfiguration({ type }));
    }, 10);
  }
};

export const updateMockDataValue = async (
  store: any,
  dispatch: any,
  mockDataPayload: any
) => {
  const accountId = getAccountId();
  const metadataType = store.getState()?.automation?.metadataType;
  const activeEditColumn = store.getState()?.entity?.activeEditColumn;
  const isEntityAutomation =
    metadataType === ABMetadataTypeEnum.ENTITY_AUTOMATION ||
    metadataType === ABMetadataTypeEnum.BUTTON_AUTOMATION ||
    activeEditColumn?.type === EntityTypeField.COMPUTED ||
    activeEditColumn?.type === EntityTypeField.BUTTON ||
    store.getState()?.entity?.showTableAppConfigCard;
  const isCronAutomation = metadataType === ABMetadataTypeEnum.CRON;
  const isMonitorAutomation = metadataType === ABMetadataTypeEnum.MONITOR;
  const isTeamAutomation = metadataType === ABMetadataTypeEnum.TEAM;
  const isUserAutomation = metadataType === ABMetadataTypeEnum.USER;
  const isWebhookAutomation = metadataType === ABMetadataTypeEnum.WEBHOOK;
  const selectedTableType = store.getState()?.entity?.selectedEntity?.tableType;
  const selectedCronId = store.getState()?.automation.selectedCron?.cronId;
  const selectedMonitorIdValue = store.getState()?.automation.selectedMonitorId;
  const accountIdValue = accountId;
  const taskTypeId = store.getState()?.builder?.workflow?.taskType;

  dispatch(updateMockData(mockDataPayload));
  if (isEntityAutomation) {
    await EntityService.updateMockData(
      selectedTableType as string,
      mockDataPayload
    );
  } else if (isCronAutomation) {
    await CronService.updateCronMockData(selectedCronId, mockDataPayload);
  } else if (isMonitorAutomation) {
    await MonitorService.updateMonitorMockData(
      selectedMonitorIdValue,
      mockDataPayload
    );
  } else if (isTeamAutomation || isUserAutomation) {
    await AccountService.updateAccountMockdata(accountIdValue, mockDataPayload);
  } else if (isWebhookAutomation) {
    const webhookId = store.getState()?.automation?.selectedWebhook?.webhookId;
    const webhookAutomationUpdatePayload = {
      mockData: mockDataPayload,
    };

    await BffService.updateWebhook(webhookId, webhookAutomationUpdatePayload);
  } else if (taskTypeId) {
    await BuilderService.putMockData(taskTypeId, mockDataPayload);
  }
};