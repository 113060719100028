import React from 'react'
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { Dispatch } from 'redux';
import ZSwitch from 'views/commonComponents/ZSwitch';
import { SystemTransitionLabel } from 'views/DataField/DataField.styles';
import { setAppTaskCreateConfig } from '../reducers/workflowAction';
import AppTaskCreate from './AppTaskCreate';
import { AiOutlineDown, AiOutlineLeft } from "react-icons/ai";

type Props = {
    setValue: UseFormSetValue<any>,
    appTaskCreateConfig: any,
    dispatch: Dispatch,
    openSettings: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, args: any) => void,
    register: UseFormRegister<any>
}

const AppTaskCreateLabel = ({setValue,openSettings,register, appTaskCreateConfig, dispatch}: Props) => {
    
    const [showSubMenu, setShowSubMenu] = React.useState(false);

    return (
    <>
        <SystemTransitionLabel onClick={() => setShowSubMenu((prev) => !prev)}>
            <div>Configure Task creation for Zorp App</div>
            <div>
            {showSubMenu ? (
                <AiOutlineDown />
            ) : (
                <AiOutlineLeft />
            )}
            </div>
        </SystemTransitionLabel>
        <div style={{ display: showSubMenu ? "" : "none",marginTop:"15px" }}>
            <ZSwitch
                onChange={() => {
                    setValue("appTaskCreateConfig.isTaskCreateEnable", !appTaskCreateConfig.isTaskCreateEnable);
                    dispatch(setAppTaskCreateConfig(
                        {isTaskCreateEnable: !appTaskCreateConfig.isTaskCreateEnable}
                    ))
                }}
                checked={appTaskCreateConfig.isTaskCreateEnable}
                label="Enable Task Creation on Zorp App"
                
            />
            <div className='mb-4'></div>
            {
                appTaskCreateConfig.isTaskCreateEnable && (
                <AppTaskCreate  openSettings={openSettings} register={register}/>
                )
            }
        </div>
    </>
  )
}

export default AppTaskCreateLabel