import { ABMetadataTypeEnum } from "views/automationBuilder/types/ABType";
import getInputType from "./getInputType";

export const returnUpdatedObject = (val: string | {}, dataType: string): {} => {
  if (typeof val === "string") {
    return {
      type: getInputType(val || ""),
      value: val || "",
      dataType: dataType
    }
  } else {
    return { ...val }
  }
}

export const getLowCodeEditorTitle = (metaDatatype: ABMetadataTypeEnum, selectedTransition: any, selectedDecisionNode: any, selectedAutomation: any, selectedAction: any, editorLocation: any, selectedNode: any, showWorkflowConfigModal: any, showTaskCardConfig: any,selectedEntity:any, isEntityAutomation: boolean, activeEditColumn:any, selectedCron:any) => {
  let actionName = '';

  if (selectedAction?.actionName) {
    actionName = selectedAction?.actionName;
  } else if (selectedAction?.conditionName) {
    actionName = selectedAction?.conditionName;
  } else if (selectedAction?.guardName) {
    actionName = selectedAction?.guardName;
  }

  if(metaDatatype === ABMetadataTypeEnum.BUTTON_AUTOMATION) {
    return `Table - ${selectedEntity.tableDisplayName} > ${activeEditColumn.name} > Automation`;
  }

  // Screen Transition
  if(isEntityAutomation) {
    if(activeEditColumn) {
      return `Table - ${selectedEntity.tableDisplayName} > Column > ${activeEditColumn.displayName} > ${activeEditColumn?.action?.label || ""}`;
    }
    return `Table - ${selectedEntity.tableDisplayName} Automation`;
  }
  else if (selectedTransition) {
    return ` ${selectedTransition.data.event} > ${getAutomationContextTitle(metaDatatype)} > ${selectedAutomation?.name} > ${actionName} >  ${formatString(editorLocation)}`;
  } else if (selectedDecisionNode) {
    return ` ${selectedDecisionNode.data.name} > ${getAutomationContextTitle(metaDatatype)} > ${selectedAutomation?.name} > ${actionName} >  ${formatString(editorLocation)}`;
  } else if (showTaskCardConfig.isTaskCreate && metaDatatype) {
    return ` Task Create Screen > ${getAutomationContextTitle(metaDatatype)} > ${selectedAutomation?.name} > ${actionName} >  ${formatString(editorLocation)}`;
  } else if (showTaskCardConfig.isTaskCreate) {
    return ` Task Create Screen > ${formatString(editorLocation)}`;
  } else if (showTaskCardConfig.isTaskHistoryScreen && metaDatatype) {
    return ` Task History Screen > ${getAutomationContextTitle(metaDatatype)} > ${selectedAutomation?.name} > ${actionName} >  ${formatString(editorLocation)}`;
  } else if (showTaskCardConfig.isTaskHistoryScreen) {
    return ` Task History Screen > ${formatString(editorLocation)}`;
  } else if (showTaskCardConfig.isHistory) {
    return ` Task History Card Settings > ${formatString(editorLocation)}`;
  } else if (Object.keys(selectedNode || {}).length > 0 && metaDatatype) {
    return ` ${selectedNode.data.name} > ${getAutomationContextTitle(metaDatatype)} > ${selectedAutomation?.name} > ${actionName} >  ${formatString(editorLocation)}`;
  } else if (Object.keys(selectedCron || {}).length > 0 && metaDatatype === ABMetadataTypeEnum.CRON) {
    return ` ${getAutomationContextTitle(metaDatatype)} > ${selectedAutomation?.name} > ${actionName} >  ${formatString(editorLocation)}`;
  } else if (Object.keys(selectedCron || {}).length > 0 && metaDatatype === ABMetadataTypeEnum.WEBHOOK) {
    return ` ${getAutomationContextTitle(metaDatatype)} > ${selectedAutomation?.name} > ${actionName} >  ${formatString(editorLocation)}`;
  } else if (Object.keys(selectedNode || {}).length) {
    return ` ${selectedNode?.data?.name}  >  ${formatString(editorLocation)}`;
  } else if (metaDatatype === ABMetadataTypeEnum.TEAM) {
    return ` ${getAutomationContextTitle(metaDatatype)} > ${selectedAutomation?.name} > ${actionName} >  ${formatString(editorLocation)}`;
  } else if (metaDatatype === ABMetadataTypeEnum.USER) {
    return ` ${getAutomationContextTitle(metaDatatype)} > ${selectedAutomation?.name} > ${actionName} >  ${formatString(editorLocation)}`;
  } else {

    // Workflow Events
    if (metaDatatype == ABMetadataTypeEnum.WORKFLOW_SYSTEM_TRANSITION) {
      return `${getAutomationContextTitle(metaDatatype)} > ${selectedAutomation?.name} > ${actionName} >  ${formatString(editorLocation)}`;
    }

    if (showTaskCardConfig.show && !showTaskCardConfig.isHistory && !showTaskCardConfig.isTaskHistoryScreen && !showTaskCardConfig.isTaskCreate) {
      return ` Task Card Settings > ${formatString(editorLocation)}`;
    }

  }
}

const getAutomationContextTitle = (metaDatatype: ABMetadataTypeEnum) => {
  if (metaDatatype == ABMetadataTypeEnum.WORKFLOW_SYSTEM_TRANSITION) {
    return 'System Transition Automation';
  } else if (metaDatatype == ABMetadataTypeEnum.WORKFLOW_SCREEN_TRANSITION) {
    return 'Screen Transition Automation';
  } else if (metaDatatype == ABMetadataTypeEnum.WORKFLOW_DECISION_NODE_TRANSITION_IF_SWITCH) {
    return 'Decision Node Switch Automation';
  } else if (metaDatatype == ABMetadataTypeEnum.WORKFLOW_DECISION_NODE_TRANSITION_ELSE_SWITCH) {
    return 'Decision Node Else Switch Automation';
  } else if (metaDatatype == ABMetadataTypeEnum.WORKFLOW_SCREEN_ONLOAD) {
    return 'On Load Screen Automation';
  } else if (metaDatatype == ABMetadataTypeEnum.WORKFLOW_SCREEN_SMART_COMPONENT) {
    return 'Smart Component Automation';
  } else if (metaDatatype == ABMetadataTypeEnum.USER) {
    return 'User Automation';
  } else if (metaDatatype == ABMetadataTypeEnum.TEAM) {
    return 'Team Automation';
  } else if (metaDatatype == ABMetadataTypeEnum.CRON) {
    return 'Scheduled Automation';
  } else if (metaDatatype == ABMetadataTypeEnum.WEBHOOK) {
    return 'Webhook Automation';
  } else if (metaDatatype == ABMetadataTypeEnum.MONITOR) {
    return 'Alert Auto-Resolution Automation';
  } else {
    return '';
  }
}

const formatString = (str: string) => {
  if (str.toLowerCase().includes('update_data_field')) {
    str = str.split(' ')[0];
  } else {
    // Add space between capital letters
    str = str.replace(/([A-Z])/g, ' $1').replace('_', ' ').replace('-', ' ');

    // Capitalize the first letter
    str = str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str;
}