import {
  CalendarRIcon,
  ClockRIcon,
  DropdownRIcon,
  FileRIcon,
  IntegerRIcon,
  Save,
  SignatureRIcon,
  TextRIcon,
  ToggleRIcon,
} from "assests";
import { TEXT } from "constants/CommonConstants";
import React, { useRef, useState } from "react";
import { useForm, useFieldArray, Control, Controller } from "react-hook-form";
import {
  AiOutlineArrowLeft,
  AiOutlinePlus,
  AiOutlineQuestionCircle,
} from "react-icons/ai";
import { BsSave } from "react-icons/bs";
import styled from "styled-components";
import { stopPropagate } from "utils/CommonUtils";
import { DataFieldType } from "views/DataField/DataField.types";
import ZCheckbox from "views/commonComponents/ZCheckbox";
import ZSelect from "views/commonComponents/ZSelect";
import ZInput from "views/commonComponents/ZTextInput";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import ZSwitch from "views/commonComponents/ZSwitch";
import Select from "react-select";
import { ZIconButton2 } from "views/entities/styles";
import { RiDeleteBinLine } from "react-icons/ri";
import { EntityService } from "providers/data/services/EntityService";
import notification from "notifications/notifications";
import { InfoTooltip } from "views/workflows/Tootltips/InfoTooltip";
import { useHistory } from "react-router-dom";
import useEntity from "views/entities/hooks/useEntity";
import useTaskShow from "views/workflows/hooks/useTaskShow";
import { isEmpty } from "lodash";
import { LuClock } from "react-icons/lu";
import { components } from "react-select";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

type Props = {};

const typeMapping: any = {
  TEXT: {
    label: "Text",
    value: "TEXT",
    icon: <TextRIcon color="#667085" />,
  },
  NUMBER: {
    label: "Number",
    value: "NUMBER",

    icon: <IntegerRIcon color="#667085" />,
  },
  "SINGLE SELECT": {
    label: "Single Select",
    value: "SINGLE SELECT",

    icon: <DropdownRIcon color="#667085" />,
  },
  "MULTI SELECT": {
    label: "Multi Select",
    value: "MULTI SELECT",

    icon: <DropdownRIcon color="#667085" />,
  },
  FILE: {
    label: "File",
    value: "FILE",

    icon: <FileRIcon color="#667085" />,
  },
  SIGNATURE: {
    label: "Signature",
    value: "SIGNATURE",

    icon: <SignatureRIcon color="#667085" />,
  },
  DATE: {
    label: "Date",
    value: "DATE",

    icon: <CalendarRIcon color="#667085" size="1.2rem" />,
  },
  TIME: {
    label: "Time",
    value: "TIME",

    icon: <LuClock size="1.8rem" color="#334053" />,
  },
  BOOLEAN: {
    label: "Boolean",
    value: "BOOLEAN",

    icon: <ToggleRIcon color="#667085" />,
  },
};

const priorityMapping: any = {
  LOW: {
    label: "Low",
    value: "LOW",
    icon: (
      <FiberManualRecordIcon
        style={{ color: "#4CAF50", width: "12px", height: "12px" }}
      />
    ),
  },
  MEDIUM: {
    label: "Medium",
    value: "MEDIUM",
    icon: (
      <FiberManualRecordIcon
        style={{ color: "#FFD700", width: "12px", height: "12px" }}
      />
    ),
  },
  HIGH: {
    label: "High",
    value: "HIGH",
    icon: (
      <FiberManualRecordIcon
        style={{ color: "#FF4C4C", width: "12px", height: "12px" }}
      />
    ),
  },
};

const { Option, SingleValue } = components;
const IconOption = (props: any) => {
  return (
    <>
      <Option {...props}>
        <OptionWrapper isAdd={props.data.value === "newEntity"}>
          <div>
            <p style={{ fontSize: "12px", fontWeight: 500 }}>
              {props.data.icon}
            </p>
          </div>
          <div>{props.data.label}</div>
        </OptionWrapper>
      </Option>
    </>
  );
};

const SingleValueDiv = ({ children, ...props }: any) => (
  <SingleValue {...props}>
    {props.data.icon}
    {children}
  </SingleValue>
);

export const ActionText = styled.p`
  color: var(--Primary-700, #3054b9);

  /* Text xs/Semibold */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
  // on hover
  &:hover {
    cursor: pointer;
  }
`;

export const Wrapper = styled.div`
  padding: 20px;
`;

export const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Heading = styled.p`
  color: var(--Gray-500, #667085);

  font-size: 18px;
  font-weight: 600;
`;

export const Text = styled.p`
  color: var(--Gray-700, #344054);

  /* Text xs/Medium */
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

export const TextInput = styled.input`
  border-radius: 4px;
  padding: 7px 12px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  width: 100%;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  // style for disabled input
  &:disabled {
    background: var(--Gray-100, #f5f7fa);
  }
`;

export const TextArea = styled.textarea`
  border-radius: 4px;
  padding: 8px 12px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  width: 100%;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
`;

export const QuestionWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: #fff;
  padding: 24px;
  margin-bottom: 16px;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
`;

export const ChecklistProperties = styled.div`
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--Gray-200, #eaecf0);
  background: #fff;

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 16px;
`;

export const Label = styled.p`
  color: var(--Gray-700, #344054);

  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  margin-bottom: 8px;
`;

export const ErrorMessage = styled.p`
  color: var(--Red-500, #e3342f);
  font-size: 12px;
  font-weight: 500;
  margin-top: 4px;
`;

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: ${(props: any) => (props?.isAdd ? "#F5F8FF" : "")};
  color: ${(props: any) =>
    props?.isAdd ? `${props.theme.color.primary}` : "black"};
`;

const ContainerDiv = styled.div<{ $wrap?: string }>`
  display: flex;
  flex-wrap: ${(props) => props.$wrap || ""};
  gap: 8px;
  width: 100%;
`;

const Container = styled.div`
    width: 100%;
    padding: 4px;
    display; flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
`;

const FlexContainer = styled(FlexDiv)<{ $gap?: string; $width?: string }>`
  width: ${(props) => props.$width || ""};
  gap: ${(props) => props.$gap || "8px"};
`;

const DropdownOptions = [
  {
    label: "Text",
    value: "TEXT",
    data: {
      icon: <TextRIcon color="#667085" />,
    },
  },
  {
    label: "Number",
    value: "NUMBER",
    data: {
      icon: <IntegerRIcon color="#667085" />,
    },
  },
  {
    label: "Single Select",
    value: "SINGLE SELECT",
    data: {
      icon: <DropdownRIcon color="#667085" />,
    },
  },
  {
    label: "Multi Select",
    value: "MULTI SELECT",
    data: {
      icon: <DropdownRIcon color="#667085" />,
    },
  },
  {
    label: "File",
    value: "FILE",
    data: {
      icon: <FileRIcon color="#667085" />,
    },
  },
  {
    label: "Signature",
    value: "SIGNATURE",
    data: {
      icon: <SignatureRIcon color="#667085" />,
    },
  },
  {
    label: "Date",
    value: "DATE",
    data: {
      icon: <CalendarRIcon color="#667085" size="1.2rem" />,
    },
  },
  {
    label: "Time",
    value: "TIME",
    data: {
      icon: <LuClock size="1.8rem" color="#334053" />,
    },
  },
  {
    label: "Boolean",
    value: "BOOLEAN",
    data: {
      icon: <ToggleRIcon color="#667085" />,
    },
  },
];

const PriorityOptions = [
  {
    label: "Low",
    value: "LOW",
    icon: (
      <FiberManualRecordIcon
        style={{ color: "#4CAF50", width: "12px", height: "12px" }}
      />
    ),
  },
  {
    label: "Medium",
    value: "MEDIUM",
    icon: (
      <FiberManualRecordIcon
        style={{ color: "#FFD700", width: "12px", height: "12px" }}
      />
    ),
  },
  {
    label: "High",
    value: "HIGH",
    icon: (
      <FiberManualRecordIcon
        style={{ color: "#FF4C4C", width: "12px", height: "12px" }}
      />
    ),
  },
];

function convertChecklistToPayload(checklist: any, checklistTabletype: string) {
  // get the tableType from the url
  return checklist.questions.map((question: any) => {
    let options = "";
    let optionScores = "";

    if (["SINGLE SELECT", "MULTI SELECT"].includes(question.type.value)) {
      options = question.options
        .filter((opt: any) => opt.option)
        .map((opt: any) => opt.option)
        .join(",");
      optionScores = question.options
        .filter((opt: any) => opt.option)
        .map((opt: any) => `${opt.option}-${opt.score}`)
        .join(",");
    }

    // check for BOOLEAN type
    if (question.type.value === "BOOLEAN") {
      options = "True,False";
      // index 0 is for True and index 1 is for False. Read the
      optionScores = question.options
        .map((opt: any, index: number) =>
          index === 0 ? `True-${opt.score || 0}` : `False-${opt.score || 0}`
        )
        .join(",");
    }

    return {
      recordId: question?.recordId || undefined,
      Questions: question.question,
      Response_Type: [question.type.value],
      Score: parseInt(question.score, 10),
      placeholder: question.placeholder,
      Mandatory: question.Mandatory,
      Options: options,
      Option_Scores: optionScores,
      Is_image_required: question.Is_image_required,
      Number_of_Images_Required: parseInt(
        question.Number_of_Images_Required,
        10
      ),
      priority: question.priority?.value ? [question.priority.value] : ["LOW"],
      Correct_Answer: question.correctAnswer,
      // Checklist:[checklistTabletype]
    };
  });
}

function getReferringEntityType(data: any) {
  const tabs = data.tabs;

  for (const tab of tabs) {
    for (const section of tab.sections) {
      if (section.sectionType === "RELATION" && section.rows.length > 0) {
        for (const row of section.rows) {
          for (const item of row) {
            if (
              (item.key === "Questions" || item.key === "Tasks") &&
              item.relationDetails
            ) {
              return item.relationDetails.referringEntityType;
            }
          }
        }
      }
    }
  }

  return "Error: referringEntityType for key 'Questions' not found.";
}

const TypeSelect = ({ control, name }: any) => {
  return (
    <Controller
      render={({ field }) => (
        <Select
          {...field}
          options={DropdownOptions.map((option) => ({
            label: option.label,
            value: option.value,
            icon: option.data.icon,
          }))}
          components={{
            Option: IconOption,
            SingleValue: SingleValueDiv,
          }}
          value={field.value}
          styles={{
            control: (styles) => ({
              ...styles,
              backgroundColor: "white",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            }),
            container: (styles) => ({
              ...styles,
              flex: 2,
            }),
            singleValue: (base: any) => ({
              ...base,
              display: "flex",
              color: "rgb(51, 51, 51)",
              gap: "8px",
              alignItems: "center",
            }),
            menu: (base) => ({
              ...base,
              borderRadius: 0,
              opacity: 1,
              color: "rgb(51, 51, 51)",
              zIndex: 10000000,
              marginTop: 0,
            }),
          }}
        />
      )}
      name={name}
      rules={{ required: "Type is required" }}
      control={control}
    />
  );
};

const PrioritySelect = ({ control, name }: any) => {
  return (
    <Controller
      render={({ field }) => (
        <Select
          {...field}
          options={PriorityOptions.map((option) => ({
            label: option.label,
            value: option.value,
            icon: option.icon,
          }))}
          value={field.value}
          placeholder="Priority"
          components={{
            Option: IconOption,
            SingleValue: SingleValueDiv,
          }}
          styles={{
            control: (styles) => ({
              ...styles,
              backgroundColor: "white",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            }),
            container: (styles) => ({
              ...styles,
              flex: 2,
            }),
            singleValue: (base: any) => ({
              ...base,
              display: "flex",
              color: "rgb(51, 51, 51)",
              gap: "8px",
              alignItems: "center",
            }),
            menu: (base) => ({
              ...base,
              borderRadius: 0,
              opacity: 1,
              color: "rgb(51, 51, 51)",
              zIndex: 10000000,
              marginTop: 0,
            }),
          }}
        />
      )}
      name={name}
      control={control}
      rules={{ required: "Priority is required" }}
    />
  );
};

const ChecklistSidebar = ({
  register,
  control,
  append,
  addQuestionRef,
  errors,
}: any) => {
  return (
    <div>
      <ZButton
        secondary
        variant="contained"
        fullWidth
        onClick={() => {
          append({
            question: "",
            type: "",
            score: 0,
            placeholder: "",
            Mandatory: false,
            options: [],
            Is_image_required: false,
            Number_of_Images_Required: 0,
            priority: "LOW",
          });
          addQuestionRef.current?.scrollIntoView({ behavior: "smooth" });
        }}
        startIcon={<AiOutlinePlus />}
      >
        {" "}
        Add Question{" "}
      </ZButton>
      <div className="mt-4"></div>
      {/* Collect Checklist Name */}
      <Label>Checklist Name</Label>
      <TextInput
        label="Checklist Name"
        placeholder="Checklist Name"
        {...register("name", { required: "Checklist Name is required" })}
      />
      {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
      <div className="mt-4"></div>
      {/* Collect Description */}
      <Label>Description</Label>
      <TextArea
        label="Description"
        placeholder="Description"
        {...register("description")}
      />
      <div className="mt-2"></div>
      <ChecklistProperties>
        <Label> Checklist Properties </Label>
        <Container>
          <ZCheckbox
            name="enableScore"
            control={control}
            label="Enable Scoring"
          />
          <div className="mt-2"></div>
          <ZCheckbox
            name="enablePriority"
            control={control}
            label="Enable Priority"
          />
        </Container>
      </ChecklistProperties>
    </div>
  );
};

function ChecklistHeader({
  mode,
  checklistTabletype,
  loading,
}: {
  mode: "create" | "edit";
  checklistTabletype: string;
  loading: boolean;
}) {
  const history = useHistory();
  const { selectedViewId } = useEntity();
  return (
    <FlexDiv className="mb-3">
      <FlexContainer $gap="8px">
        <ZButton
          startIcon={<AiOutlineArrowLeft />}
          secondary
          variant="contained"
          onClick={() => {
            if (selectedViewId) {
              history.push(
                `/table/${checklistTabletype}/view/${selectedViewId}/?allowRedirect=false`
              );
            } else {
              history.push(`/table/${checklistTabletype}/?allowRedirect=false`);
            }
          }}
        >
          Back
        </ZButton>
        <Heading className="ml-2">
          {mode === "create" ? "Create" : "Edit"} Checklist{" "}
        </Heading>
      </FlexContainer>
      <FlexContainer $gap="8px">
        <ZButton
          disabled={loading}
          type="submit"
          startIcon={<Save />}
          variant="contained"
        >
          {loading ? "Saving..." : "Save Checklist"}
        </ZButton>
      </FlexContainer>
    </FlexDiv>
  );
}

const SingleQuestion = ({
  control,
  register,
  questionIndex,
  watchEnableScore,
  watch,
  watchEnablePriority,
  remove,
  errors,
}: any) => {
  const watchType = watch(`questions.${questionIndex}.type`);
  return (
    <QuestionWrapper>
      <FlexContainer $gap="8px">
        <div style={{ flex: 3 }}>
          <Label>Question</Label>
          <TextInput
            {...register(`questions.${questionIndex}.question`, {
              required: "Question is required",
            })}
            placeholder="Question"
            style={{ flex: 3 }}
          />
          {errors?.questions?.[`${questionIndex}`]?.[`question`] && (
            <ErrorMessage>
              {errors?.questions?.[`${questionIndex}`]?.[`question`]?.message}
            </ErrorMessage>
          )}
        </div>
        <div style={{ flex: 2 }}>
          <Label>Type</Label>
          <TypeSelect
            name={`questions.${questionIndex}.type`}
            control={control}
          />
          {errors?.questions?.[`${questionIndex}`]?.[`type`] && (
            <ErrorMessage>
              {errors?.questions?.[`${questionIndex}`]?.[`type`]?.message}
            </ErrorMessage>
          )}
        </div>
      </FlexContainer>
      <div className="mt-2"></div>
      <ContainerDiv
        $wrap={
          watchEnableScore &&
          watch(`questions.${questionIndex}.Is_image_required`)
            ? "wrap"
            : ""
        }
      >
        <FlexContainer $gap="8px" $width="100%">
          <div
            style={{ flex: watchEnableScore && watchEnablePriority ? 1 : 3 }}
          >
            <Label>Placeholder</Label>
            <TextInput
              {...register(`questions.${questionIndex}.placeholder`)}
              placeholder="Placeholder"
            />
          </div>
          {watchEnableScore &&
            watchType.value !== "SINGLE SELECT" &&
            watchType.value !== "MULTI SELECT" && (
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                  flex: 1,
                }}
              >
                <Label>Score</Label>
                <TextInput
                  {...register(`questions.${questionIndex}.score`)}
                  placeholder="Score"
                  type="number"
                  style={{ width: "100%" }}
                />
                <div
                  style={{
                    position: "absolute",
                    right: "5px",
                    top: "69%",
                    transform: "translateY(-50%)",
                  }}
                >
                  <InfoTooltip
                    placement="top"
                    title="Score is used to evaluate the response to this question."
                  >
                    <span>
                      <AiOutlineQuestionCircle
                        size={"1.5rem"}
                        color="#98A2B3"
                      />
                    </span>
                  </InfoTooltip>
                </div>
              </div>
            )}
          {watch(`questions.${questionIndex}.Is_image_required`) && (
            <div style={{ flex: 1 }}>
              <Label style={{ whiteSpace: "nowrap", width: "100%" }}>
                {" "}
                Number of Images{" "}
              </Label>
              <TextInput
                {...register(
                  `questions.${questionIndex}.Number_of_Images_Required`
                )}
                placeholder="Number of Images"
                type="number"
              />
            </div>
          )}
        </FlexContainer>
        {watchEnableScore &&
          (watchType.value === "TEXT" ? (
            <FlexContainer $gap="10px" $width="100%">
              <div style={{ flex: 1 }}>
                <Label>Correct Answer</Label>
                <TextInput
                  {...register(`questions.${questionIndex}.correctAnswer`)}
                  placeholder="Correct Answer"
                />
              </div>
            </FlexContainer>
          ) : watchType.value === "NUMBER" ? (
            <FlexContainer $gap="10px" $width="100%">
              <div style={{ flex: 2 }}>
                <Label>Correct Answer</Label>
                <TextInput
                  {...register(`questions.${questionIndex}.correctAnswer`)}
                  placeholder="Correct Answer"
                  type="number"
                />
              </div>
            </FlexContainer>
          ) : null)}
        {watchEnablePriority && (
          <div style={{ width: "100%" }}>
            <Label> Priority </Label>
            <PrioritySelect
              name={`questions.${questionIndex}.priority`}
              control={control}
            />
          </div>
        )}
      </ContainerDiv>

      <div className="mt-2"></div>
      {(watchType.value === "SINGLE SELECT" ||
        watchType.value === "MULTI SELECT") && (
        // Show a input field to collect options
        <OptionList
          control={control}
          register={register}
          questionIndex={questionIndex}
          errors={errors}
        />
      )}
      {watchType.value === "BOOLEAN" && (
        <div>
          <FlexDiv style={{ gap: "10px" }}>
            <div style={{ flex: 2 }}>
              <Label>Option 1</Label>
              <TextInput
                {...register(`questions.${questionIndex}.options.0.option`)}
                value="True"
                placeholder="Option 1"
                disabled
              />
            </div>
            <div style={{ flex: 1 }}>
              <Label> Score </Label>
              <TextInput
                {...register(`questions.${questionIndex}.options.0.score`)}
                placeholder="Score"
                type="number"
              />
            </div>
          </FlexDiv>
          <div className="mt-2"></div>
          <FlexDiv style={{ gap: "10px" }}>
            <div style={{ flex: 2 }}>
              <Label>Option 2</Label>
              <TextInput
                {...register(`questions.${questionIndex}.options.1.option`)}
                placeholder="Option 2"
                value="False"
                disabled
              />
            </div>
            <div style={{ flex: 1 }}>
              <Label> Score </Label>
              <TextInput
                {...register(`questions.${questionIndex}.options.1.score`)}
                placeholder="Score"
                type="number"
              />
            </div>
          </FlexDiv>
        </div>
      )}

      <div className="mt-2"></div>
      <FlexDiv style={{ gap: "10px", justifyContent: "flex-end" }}>
        <div onClick={() => remove(questionIndex)}>
          <RiDeleteBinLine color="#667085" size="1.5rem" />
        </div>
        <Controller
          name={`questions.${questionIndex}.Mandatory`}
          control={control}
          render={({ field: { onChange, value } }) => (
            <ZSwitch
              labelDirection="left"
              switchStyle={{ width: "36px", height: "20px" }}
              checked={value as boolean}
              onChange={onChange}
              inputProps={{
                "aria-label": "controlled",
                width: "14px",
                height: "14px",
                left: "-10px",
              }}
              label="Mandatory"
            />
          )}
        />
        <div className="mt-2"></div>
        <ZCheckbox
          name={`questions.${questionIndex}.Is_image_required`}
          control={control}
          label="Image Required"
        />
      </FlexDiv>
    </QuestionWrapper>
  );
};

function OptionList({ control, register, questionIndex, errors }: any) {
  const {
    fields: optionFields,
    append: appendOption,
    remove: removeOption,
  } = useFieldArray({
    control,
    name: `questions.${questionIndex}.options`,
  });

  return (
    <div>
      {optionFields.map((optionField, optionIndex) => (
        <FlexDiv
          style={{ gap: "16px", marginBottom: "10px" }}
          key={optionField.id}
        >
          <div style={{ flex: 2 }}>
            <Label>Option {optionIndex + 1}</Label>
            <TextInput
              {...register(
                `questions.${questionIndex}.options.${optionIndex}.option`,
                { required: "Option is required" }
              )}
              placeholder={`Option ${optionIndex + 1}`}
              style={{ flex: 2 }}
            />
            {errors?.questions?.[`${questionIndex}`]?.[`options`] && (
              <ErrorMessage>
                {errors?.questions?.[`${questionIndex}`]?.[`options`]?.message}
              </ErrorMessage>
            )}
          </div>
          <div style={{ flex: 1 }}>
            <Label> Score </Label>
            <TextInput
              {...register(
                `questions.${questionIndex}.options.${optionIndex}.score`
              )}
              placeholder="Score"
              type="number"
            />
          </div>
          <div
            style={{ marginTop: "23px" }}
            onClick={() => removeOption(optionIndex)}
          >
            <RiDeleteBinLine color="#667085" size="1.5rem" />
          </div>
        </FlexDiv>
      ))}
      <div className="mt-2"></div>
      <ActionText
        onClick={() =>
          appendOption({
            option: "",
            score: 0,
          })
        }
      >
        Add Option
      </ActionText>
    </div>
  );
}

const Checklist = ({
  control,
  register,
  watch,
  fields,
  append,
  remove,
  addQuestionRef,
  errors,
}: any) => {
  // watch enableScore
  const watchEnableScore = watch("enableScore");
  const watchEnablePriority = watch("enablePriority");

  return (
    <div style={{ flex: 2 }}>
      {fields.map((field: any, index: any) => {
        return (
          <SingleQuestion
            key={field.id}
            control={control}
            register={register}
            questionIndex={index}
            watchEnableScore={watchEnableScore}
            watchEnablePriority={watchEnablePriority}
            watch={watch}
            remove={remove}
            addQuestionRef={addQuestionRef}
            errors={errors}
          />
        );
      })}
      <ActionText
        onClick={() =>
          append({
            question: "",
            type: "",
            score: 0,
            placeholder: "",
            Mandatory: false,
            options: [],
            Is_image_required: false,
            Number_of_Images_Required: 0,
            priority: "LOW",
          })
        }
      >
        {" "}
        <AiOutlinePlus
          strokeWidth={2}
          style={{ display: "inline-block", marginRight: "6px" }}
        />
        Add Question{" "}
      </ActionText>
    </div>
  );
};

const ChecklistBuilder = ({
  taskRenderDetails,
  checklistTabletype,
  mode = "show" || "create",
  recordId,
}: any) => {
  const { selectedViewId } = useEntity();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  //   const {
  //     control,
  //     register,
  //     watch,
  //     handleSubmit,
  //     formState: { errors },
  //   } = useForm({
  //     defaultValues: defaultValues || {
  //       name: "",
  //       enableScore: false,
  //       description: "",
  //       enablePriority: false,
  //       questions: [
  //         {
  //           question: "",
  //           type: "",
  //           score: 0,
  //           placeholder: "",
  //           Mandatory: false,
  //           options: [
  //             {
  //               option: "",
  //               score: 0,
  //             },
  //           ],
  //           Is_image_required: false,
  //           Number_of_Images_Required: 0,
  //           priority: "LOW",
  //         },
  //       ],
  //     },
  //   });

  const enrichedData = taskRenderDetails?.recordData?.enrichedData || {};
  let defaultFormValues = {
    name: "",
    enableScore: false,
    description: "",
    enablePriority: false,
    questions: [
      {
        question: "",
        type: "",
        score: 0,
        placeholder: "",
        Mandatory: false,
        options: [],
        Is_image_required: false,
        Number_of_Images_Required: 0,
        priority: "LOW",
      },
    ],
  };

  if (!isEmpty(enrichedData)) {
    defaultFormValues = {
      name: enrichedData.Name,
      enableScore: enrichedData.Enable_Scoring,
      description: enrichedData.Description,
      enablePriority: enrichedData.Enable_Priority ?? false,
      questions: enrichedData.Questions.map((question: any) => ({
        question: question.data.Questions,
        type: typeMapping[question.data.Response_Type[0]],
        score: question.data.Score,
        placeholder: question.data.placeholder,
        Mandatory: question.data.Mandatory,
        options: question.data.Options.split(",").map(
          (option: any, index: any) => ({
            option: option,
            score: question.data.Option_Scores.split(",")[index].split("-")[1],
          })
        ),
        Is_image_required: question.data.Is_image_required,
        Number_of_Images_Required: question.data.Number_of_Images_Required,
        priority: question?.data?.priority?.[0]
          ? priorityMapping[question.data.priority[0]]
          : priorityMapping["LOW"],
        recordId: question.recordId,
        correctAnswer: question.data.Correct_Answer,
      })),
    };
  }

  const {
    control,
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultFormValues,
  });

  const addQuestionRef = useRef(null);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "questions",
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const questionsTableType = getReferringEntityType(taskRenderDetails);
      const payload = convertChecklistToPayload(data, checklistTabletype);
      let promises = payload.map((record: any) => {
        if (record?.recordId) {
          const newRecord = { ...record, recordId: undefined };
          return EntityService.updateEntityRecord(
            questionsTableType,
            record.recordId,
            {
              tableType: questionsTableType,
              data: newRecord,
              teamIds: [],
            }
          );
        }
        return EntityService.createEntityRecord({
          tableType: questionsTableType,
          data: record,
          teamIds: [],
        });
      });

      Promise.all(promises)
        .then(async (results) => {
          const createdQuestions = results.map(
            (res: any) => res.data?.recordId
          );
          try {
            let createdChecklist: any = undefined;
            if (mode === "show") {
              createdChecklist = await EntityService.updateEntityRecord(
                checklistTabletype,
                recordId,
                {
                  tableType: checklistTabletype,
                  data: {
                    Questions: createdQuestions,
                    Name: data.name,
                    Description: data.description,
                    Enable_Scoring: data.enableScore,
                    Enable_Priority: data.enablePriority,
                  },
                  teamIds: [],
                }
              );
            } else {
              createdChecklist = await EntityService.createEntityRecord({
                tableType: checklistTabletype,
                data: {
                  Questions: createdQuestions,
                  Name: data.name,
                  Description: data.description,
                  Enable_Scoring: data.enableScore,
                  Enable_Priority: data.enablePriority,
                },
                teamIds: [],
              });
            }

            let updatePromises = createdQuestions.map((recordId: any) => {
              return EntityService.updateEntityRecord(
                questionsTableType,
                recordId,
                {
                  tableType: questionsTableType,
                  data: { Checklist: [createdChecklist.data?.recordId] },
                  teamIds: [],
                }
              );
            });

            Promise.all(updatePromises)
              .then(() => {
                notification(
                  "success",
                  "Success",
                  "Checklist created successfully"
                );
                setLoading(false);
                if (selectedViewId) {
                  history.push(
                    `/table/${checklistTabletype}/view/${selectedViewId}/?allowRedirect=false`
                  );
                } else {
                  history.push(
                    `/table/${checklistTabletype}/?allowRedirect=false`
                  );
                }
              })
              .catch((error) => {
                setLoading(false);
                console.error(error);
                notification(
                  "error",
                  "Error",
                  "Failed to update questions with checklist"
                );
              });
          } catch (error) {
            setLoading(false);
            console.error(error);
            notification("error", "Error", "Failed to generate checklist");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
          notification("error", "Error", "Failed to save questions");
        });
    } catch (error) {
      setLoading(false);
      console.error(error);
      notification("error", "Error", "Failed to save questions");
    }
  };

  return (
    <Wrapper as="form" onSubmit={handleSubmit(onSubmit)}>
      <ChecklistHeader
        checklistTabletype={checklistTabletype}
        mode={mode}
        loading={loading}
      />
      <FlexDiv
        style={{
          display: "flex",
          height: "90vh",
          alignItems: "flex-start",
          gap: "20px",
        }}
      >
        <div style={{ flex: 2, overflowY: "scroll", height: "100%" }}>
          <Checklist
            control={control}
            register={register}
            watch={watch}
            handleSubmit={handleSubmit}
            fields={fields}
            append={append}
            remove={remove}
            addQuestionRef={addQuestionRef}
            errors={errors}
          />
        </div>
        <div
          style={{ flex: 1, position: "sticky", top: 0, padding: "0px 10px" }}
        >
          <ChecklistSidebar
            append={append}
            control={control}
            register={register}
            addQuestionRef={addQuestionRef}
            errors={errors}
          />
        </div>
      </FlexDiv>
    </Wrapper>
  );
};

export default ChecklistBuilder;
