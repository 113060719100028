import React,{useEffect} from 'react'
import styled from "styled-components"
import { FiCopy } from "react-icons/fi"
import { BiEdit } from "react-icons/bi"
import { RiDeleteBin5Line } from "react-icons/ri"
import { InfoTooltip } from '../Tootltips/InfoTooltip'
import { BuilderService } from 'providers/data/services/BuilderService'
import { WorkflowUtils } from '../WorkflowUtils'
import { NodeTypes } from './Nodes.types'
import useWorkFlow from '../hooks/useWorkFlow'
import { useDispatch, useStore } from 'react-redux'
import { deleteNode, setShowUpdateNodeNameModal } from '../reducers/workflowAction'
import notification from 'notifications/notifications'
import DeletionConfirmationDialog from "utils/DeletionConfirmationDialog.js";
import { DetectHomeNode } from '../DetectHomeUtils'

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    gap: 10px;
    background-color: white;
    border-radius: 4px;
    /* margin-bottom: 10px; */
    cursor: pointer;
    border: 1px solid #E5E5E5;
    position: absolute;
    top: -43px;
    left: 6px;
`   


const IconContainer = styled.div`
    padding: 4px;
    &:hover {
        background-color: #ECF3FF;
        svg {
            color: ${(props) => props.theme.color.primary}
        }
    }
`

type Props = {
    nodeId: string,
    data:any
    func: Function
    ref: any
}

type INodeHoverStrip = {
    icon: React.ReactElement,
    onClick: Function
    tooltip: string
}


const NodeHoverStrip = ({nodeId,data,func,ref}: Props) => {

    const {getHomeNode,getTerminalNodes,taskTypeId} = useWorkFlow()
    const store = useStore()
    const [showDeleteNode, setShowDeleteNode] = React.useState(false)



    const dispatch = useDispatch()

    const handleCopy = async (nodeId:string) => {
        try {
          // make call to Builder service and save node in the redis
          const res:any = await BuilderService.copyNode(taskTypeId, nodeId)
          // add the node to canvas.elements
          WorkflowUtils.addNewNodeWithKey(
            res.data.status,
            NodeTypes.STATE_NODE,
            res.data.statusName,
            dispatch,
            store.getState()?.builder?.workflow?.canvas?.elements
          )

          notification("success", "Node Copied Successfully")
          
        } catch (error) {
         console.log("error", error) 
         notification("error", "Something went wrong")
        }
    }
  
    const handleDelete = async () => {
    try {
        const res:any = await BuilderService.deleteNode(taskTypeId, nodeId)
        console.log("res", res)
        // delete the node from canvas.elements
        dispatch(deleteNode(data))
        setShowDeleteNode(false)
        DetectHomeNode.setHomeAndTerminalNodes(
            store.getState()?.builder?.workflow?.canvas?.elements || [],
            dispatch
          );
          DetectHomeNode.markAllReachableNode(store.getState()?.builder?.workflow?.canvas?.elements || [],
            dispatch)
        notification("success", "Node Deleted Successfully")
    } catch (error) {
        console.log(error)
        notification("error", "Something went wrong")
    }
    }

    const info: INodeHoverStrip[] = [
        {
            icon: <FiCopy size={'1.5rem'} color="#9DA6CF" />,
            onClick: () => handleCopy(nodeId),
            tooltip: "Duplicate Node"
        },
        {
            icon: <BiEdit size={'1.8rem'} color="#9DA6CF"/>,
            onClick: () => dispatch(setShowUpdateNodeNameModal(data)),
            tooltip: "Rename Node"
        },
        // {
        //     icon: <FiHome size={"1.5rem"} color="#9DA6CF"/>,
        //     onClick: () => {},
        //     tooltip: "Set as Home Node"
        // },
        // {
        //     icon: <AiOutlineStop size={"1.5rem"} color="#9DA6CF"/>,
        //     onClick: () => {},
        //     tooltip: "Set as Terminal Node"
        // },
        {
            icon: <RiDeleteBin5Line size={'1.8rem'} color="#9DA6CF"/>,
            onClick: () => setShowDeleteNode(true),
            tooltip: "Delete Node"
        }
    ]

    
    

  return (
    //@ts-ignore
    <Wrapper id="topDiv" ref={ref} onMouseLeave={func} className='nodrag'>
        {
            info.map((item, index) => {
                return (
                    <InfoTooltip title={item.tooltip} key={index}>
                        <IconContainer key={index} onClick={() => item.onClick()}>
                            {item.icon}
                        </IconContainer>
                    </InfoTooltip>
                )
            })
        }
        <DeletionConfirmationDialog
        id={"deleteNode"}
        deletionTitle={"Delete this Node"}
        deletionText={"All the transition which starts from this node will be deleted. This can't be undone"}
        isOpen={showDeleteNode}
        onConfirmDelete={() => handleDelete()}
        onClose={() => setShowDeleteNode(false)}

      />
    </Wrapper>
  )
}

export default NodeHoverStrip