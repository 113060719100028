class LocalStorageUtil {
    static setItem(key: string, value: any) {
        try {
            if (typeof value === 'string') {
                window.localStorage.setItem(key, value);
            } else {
                window.localStorage.setItem(key, JSON.stringify(value));
            }
        } catch (e) {
            console.error("Error setting localStorage item:", e);
        }
    }

    static getItem(key: string, parse: boolean = true) {
        const item = window.localStorage.getItem(key);
        return parse && item ? this.safeParse(item) : item;
    }

    private static safeParse(item: string): any {
        try {
            return JSON.parse(item);
        } catch (e) {
            return item;
        }
    }
}

export default LocalStorageUtil;
