// FlowNodeUI.js will render if value in selectedNode(Redux)
import { cloneDeep } from "lodash";
import { useJSEditor } from "lowcode/hooks/useJSEditor.tsx";
import { setScriptEditorPayload } from "lowcode/state/scriptOpenState";
import { ZJSEditorLayout } from "lowcode/ZJSEditorLayout.tsx";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import {
  getLowCodeEditorDefaultReturnText,
  getLowCodeEditorText,
} from "utils/Utils";
import useAutomation from "views/automationBuilder/hooks/useAutomation";
import usePayload from "views/automationBuilder/hooks/usePayload";
import { closeAutomation, setCreateAutomationDefault } from "views/automationBuilder/reducer/automationReducer";
import { saveOnLoadAutomation } from "views/automationBuilder/saveHandlers/saveOnLoadAutomation";
import { saveSmartComponentAutomation } from "views/automationBuilder/saveHandlers/saveSmartComponentAutomation";
import { ABMetadataTypeEnum } from "views/automationBuilder/types/ABType";
import { DataFieldType } from "views/DataField/DataField.types";
import { v4 as uuidv4 } from "uuid";
import {
  addDataField,
  addSubfieldToListDataField,
  deleteNode,
  setChangeInFlutter,
  setDataFieldType,
  setSelectedAction,
  setSelectedNode,
  setSelectedSystemTransition,
  setSelectedTransition,
  setShowCreateDatafieldModalSingle,
  setShowDataFieldEditModalSingle,
  updateHome,
  updateNodeName,
} from "views/workflows/reducers/workflowAction";
import { getConfig } from "../../config/envConfig/Config";
import ConfigureAutomation from "./ConfigureAutomation";
import { DetectHomeNode } from "./DetectHomeUtils";
import useWorkFlow from "./hooks/useWorkFlow";
import notification from "notifications/notifications";
import { getDefaultValue } from "views/DataField/HelperFunction/getDefaultValue";

const FlowNodeUI = ({ saveWorkflow }) => {
  let isHomeNode = false;
  let canBeHomeNode = false;

  const dispatch = useDispatch();
  const store = useStore();
  const url = getConfig("appDesigner");
  const accessToken = localStorage.getItem("at");

  const { openJSEditorLayout, scriptEditorOpen } = useJSEditor();
  const { clearRightSide } = useAutomation();

  const {
    elements: allNodes,
    accountId,
    selectedNode,
    taskTypeId,
    changesInFlutter,
  } = useWorkFlow();

  const [iframeUrl, setIframeUrl] = useState(
    `${url}/#/app-builder?taskStatus=${selectedNode?.id}&at=${accessToken}&accountId=${accountId}&taskType=${taskTypeId}&canBeHomeNode=${canBeHomeNode}&isHomeNode=${isHomeNode}`
  );

  const [openABForSmartComponentScreen, setOpenABForSmartComponentScreen] =
    useState(false);
  const [openABForOnLoadScreen, setOpenABForOnLoadScreen] = useState(false);
  const [smartComponentStateId, setSmartComponentStateId] = useState("");
  const [prevAutomationSCData, setPrevAutomationSCData] = useState([]);
  const [metaDataType, setMetaDataType] = useState("");
  const [automationId, setAutomationId] = useState("");
  const { actionConditionPayload } = usePayload();

  const onMessageReceivedFromIframe = useCallback((event) => {
    if (
      event?.data?.source === "zorp_web" &&
      event?.data?.name === "save_state"
    ) {
      console.log("save_state", event.data.payload);
      dispatch(updateNodeName(event.data.payload));
    } else if (
      event?.data?.source === "zorp_web" &&
      (event?.data?.name === "delete_state") &
        (event?.data?.stateId === selectedNode?.id)
    ) {
      dispatch(deleteNode(selectedNode));
      DetectHomeNode.setHomeAndTerminalNodes(
        store.getState()?.builder?.workflow?.canvas?.elements || [],
        dispatch
      );
      DetectHomeNode.markAllReachableNode(
        store.getState()?.builder?.workflow?.canvas?.elements || [],
        dispatch
      );
    } else if (
      event?.data?.source === "zorp_web" &&
      event?.data?.name === "home_node"
    ) {
      dispatch(updateHome(event.data.stateId));
    } else if (
      event?.data?.source === "zorp_web" &&
      event?.data?.name === "open_jsScriptEditor"
    ) {
      if (
        store.getState()?.builder?.workflow?.selectedNode &&
        !store.getState()?.builder?.showWorkflowConfigModal
      ) {
        // This will Execute When Low Code Editor is Opened from App Builder Screen.
        const editorConfig = {
          script:
            event?.data.payload?.["script"] ||
            getLowCodeEditorText(
              event?.data.payload?.["editorLocation"],
              event?.data.payload?.["dataType"],
              `return ${getLowCodeEditorDefaultReturnText(
                event?.data.payload?.["dataType"]
              )}`
            ),
          dataType: event?.data.payload?.["dataType"],
          key: event?.data.payload?.["key"],
        }; 

        const apiPayLoad = cloneDeep(actionConditionPayload);
        apiPayLoad.payload.inclusions = [];
        apiPayLoad.payload.inclusions.push('task');
        apiPayLoad.payload.inclusions.push({
          type: "appComponents",
          config: {
            stateId:
              store.getState()?.builder?.workflow?.selectedNode?.id || "",
          },
        });
        apiPayLoad.payload.exclusions = [];
        apiPayLoad.payload.exclusions.push("RECORD");

        dispatch(setScriptEditorPayload(apiPayLoad));
        openJSEditorLayout({
          config: editorConfig,
          editorLocation: event?.data.payload?.["editorLocation"],
        });
      }
    } else if (
      event?.data?.source === "zorp_web" &&
      event?.data?.name === "exit_fullscreen"
    ) {
      if (event.data.updateWorkflow)
        dispatch(setChangeInFlutter(!changesInFlutter));
      dispatch(
        setDataFieldType({
          dataType: DataFieldType.TEXT,
          key: "",
          subFieldDataType: null,
          listName: null,
        })
      );
      onCanvasClick();
    } else if (
      event?.data?.source === "zorp_web" &&
      event?.data?.name === "create_datafield"
    ) {
      if (event.data.payload.listName === null)
        dispatch(setShowCreateDatafieldModalSingle(true));
      else
        dispatch(setShowDataFieldEditModalSingle(event.data.payload.listName));
      dispatch(setDataFieldType(event.data.payload));
    }
    // Launch automation Builder
    else if (
      event?.data?.source === "zorp_web" &&
      event?.data?.name === "launch_automation_builder"
    ) {
      setSmartComponentStateId(event.data?.data?.stateId);
      setMetaDataType(ABMetadataTypeEnum.WORKFLOW_SCREEN_SMART_COMPONENT);
      // when user click Automation list, we need to pass selectedAutomationId
      setAutomationId(event.data?.data?.selectedAutomationId || "");
      // set prev automation data
      setPrevAutomationSCData(
        event.data?.data?.automations?.length > 0
          ? event.data?.data?.automations
          : []
      );

      // TODO: Open automation builder for smart components
      setOpenABForSmartComponentScreen(true);
    } else if (
      event?.data?.source === "zorp_web" &&
      event?.data?.name === "launch_onload_builder"
    ) {
      setSmartComponentStateId(event.data?.data?.stateId);
      setMetaDataType(ABMetadataTypeEnum.WORKFLOW_SCREEN_ONLOAD);
      // when user click Automation list, we need to pass selectedAutomationId
      setAutomationId(event.data?.data?.selectedAutomationId || "");

      // set prev automation data
      setPrevAutomationSCData(
        event.data?.data?.automations?.length > 0
          ? event.data?.data?.automations
          : []
      );

      if (event.data?.data?.automations?.length == 0 || event.data?.data?.automations?.length == null || event.data?.data?.automations?.length == undefined) {
        dispatch(setCreateAutomationDefault(true));
      }

      // TODO: Open automation builder for smart components 
      setOpenABForOnLoadScreen(true);
    } else if (
      event?.data?.source === "zorp_web" &&
      event?.data?.name === "create_inline_datafield"
    ) {
      console.log("event.data.payload", event.data.payload);
      const { dataType, dataFieldName, listName, subFieldDataType,key} =
        event.data.payload;
      // if (!dataFieldName || !dataType) {
      //   notification("error", "Please enter data field name and data type");
      //   return;
      // }
      const alreadyCreatedDataFields = store.getState()?.builder?.workflow?.dataFields
      const prevDataFieldsName =
        alreadyCreatedDataFields?.map((item) => item.name) || [];

      if (listName) {
        if (!listName) {
          notification("error", "Please enter list name");
          return;
        }
        if (!subFieldDataType) {
          notification("error", "Please select data type for list subfield");
          return;
        }

        // check if list name already exists
        if (!prevDataFieldsName.includes(listName)) {
          notification("error", "Please enter valid list name");
          return;
        }

        // check if any data field with same subfield name already exists
        if (prevDataFieldsName.includes(dataFieldName)) {

          //have to be handled
          // notification("error", "Data field name already exists");
          return;
        }

        // get the listName field from alreadyCreatedDataFields and check if any subfield with same name already exists
        const listField = alreadyCreatedDataFields.find(
          (item) => item.name === listName
        );

        if(!listField) {
          notification("error", "Please enter valid list name");
          return;
        }

        const subFields = listField?.children || [];

        if (subFields?.length > 0) {
          const subFieldNames = subFields.map((item) => item.name);
          if (subFieldNames.includes(dataFieldName)) {
             //have to be handled
            // notification("error", "Subfield name already exists");
            return;
          }
        }

        let item = {
          name : dataFieldName,
          type: subFieldDataType,
        }
        if (subFieldDataType === DataFieldType.LOCATION) {
          item.children = [
            {
              name: "address",
              displayName: "Address",
              description: "Address",
              type: "TEXT",
              defaultValue: item?.address || "",
            },
            {
              name: "latitude",
              displayName: "Latitude",
              description: "Latitude",
              type: "NUMBER",
              defaultValue: item?.latitude,
            },
            {
              name: "longitude",
              displayName: "Longitude",
              description: "Longitude",
              type: "NUMBER",
              defaultValue: item?.longitude,
            },
          ];
        }

        if (subFieldDataType === DataFieldType.BOOLEAN) {
          item.defaultValue = item?.defaultValue === "false" ? false : true;
        }

        item.defaultValue = item?.defaultValue ?? getDefaultValue(subFieldDataType);

        // if (!isDataFieldMandatory && item?.isCreateTimeField) {
        //   isDataFieldMandatory = item?.mandatory || false;
        // }
        item.isCreateTimeField = false

        item.mandatory = item?.isCreateTimeField ? item?.mandatory : false;

        try {
          dispatch(addSubfieldToListDataField({
            listName,
            subfield: item
          }))
          console.log("List datafield",{ key: key, data: {
            ...listField,
            name : `${listName}.${dataFieldName}`,
            key
          } });
          notification("success", "Subfield added successfully");
          document
          .getElementById("app-builder")
          ?.contentWindow?.postMessage(
            { key: key, data: {
              ...listField,
              name : `${listName}.${dataFieldName}`,
              key
            } },
            "*"
          );
        } catch (error) {
          console.log("error", error);
          notification("error", "Something went wrong");
        }
        return

      }


      for (let i = 0; i < prevDataFieldsName?.length; i++) {
        if (prevDataFieldsName[i] === dataFieldName) {
           //have to be handled
          // notification("error", "Data field already exists");
          return;
        }
      }
      // name: data?.dataFieldName,
      let dataFieldRequiredValue = {
        // name: data?.dataFieldName,
        name: event.data.payload.dataFieldName,
        displayName: event.data.payload.dataFieldName,
        type: event.data.payload.dataType,
        mandatory: false,
        isCreateTimeField: false,
        isDraft: true, // Marking the field as isDraft = true because this data field is created newly
      };

      if (dataType === DataFieldType.LOCATION) {
        let children = [
          {
            name: "address",
            displayName: "Address",
            description: "Address",
            type: "TEXT",
            defaultValue: "",
          },
          {
            name: "latitude",
            displayName: "Latitude",
            description: "Latitude",
            type: "NUMBER",
            defaultValue: "",
          },
          {
            name: "longitude",
            displayName: "Longitude",
            description: "Longitude",
            type: "NUMBER",
            defaultValue: "",
          },
        ];

        dataFieldRequiredValue = {
          ...dataFieldRequiredValue,
          children,
        };
      }

      if (dataType === DataFieldType.LIST) {
        let children = [];
        dataFieldRequiredValue = {
          ...dataFieldRequiredValue,
          children,
        };
      }

      if (dataType === DataFieldType.BOOLEAN) {
        dataFieldRequiredValue = {
          ...dataFieldRequiredValue,
          defaultValue: false,
        };
      }
      try {
        dispatch(
          addDataField({
            id: uuidv4(),
            ...dataFieldRequiredValue,
          })
        );
        console.log(">>>>>> sending data to iframe", { key, data: dataFieldRequiredValue });
        // console.log("Normal datafield",{ key: key, data: { key, data: dataFieldRequiredValue } });
        document
          .getElementById("app-builder")
          ?.contentWindow?.postMessage(
            { key, data: dataFieldRequiredValue },
            "*"
          );
      } catch (error) {
        console.log("erroro", error);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.addEventListener("message", onMessageReceivedFromIframe);
  }, [onMessageReceivedFromIframe]);

  useEffect(() => {
    checkStale();
    setIframeUrl(
      `${url}/#/app-builder?taskStatus=${selectedNode?.id}&at=${accessToken}&accountId=${accountId}&taskType=${taskTypeId}&canBeHomeNode=${canBeHomeNode}&isHomeNode=${isHomeNode}`
    );
  }, [selectedNode]); // eslint-disable-line react-hooks/exhaustive-deps

  function checkStale() {
    var listOfEdges = [];
    var selectedNodeId = selectedNode?.id;
    isHomeNode = selectedNode.data?.isHomeNode ?? false;
    allNodes.forEach((element) =>
      element["type"] === "zorpEdge" ? listOfEdges.push(element) : null
    );
    listOfEdges.forEach((element) =>
      element["source"] === selectedNodeId ? (canBeHomeNode = true) : null
    );
  }

  const onCanvasClick = () => {
    dispatch(setSelectedNode(null));
    dispatch(setSelectedTransition(null));
    dispatch(setSelectedAction(null));
    dispatch(setSelectedSystemTransition(null));
  };

  return (
    <div>
      {selectedNode && (
        <iframe
          id="app-builder"
          key={iframeUrl}
          title="Zorp Designer"
          src={iframeUrl}
          frameBorder="0"
          width={window.screen.width * 0.96}
          allowtransparency="true"
          allow={"camera " + iframeUrl + "; microphone " + iframeUrl}
          style={{
            display: "block",
            height: "100%",
            width: "100vw",
            position: "absolute",
            top: "0",
            left: "0",
            zIndex: "300",
          }}
        />
      )}

      {scriptEditorOpen && <ZJSEditorLayout />}

      {/* Smart Components AB for Screen Automation */}
      {/* Task Create AB is handled in ConfigureWorkflowModal */}
      <ConfigureAutomation
        metaDataType={metaDataType}
        prevAutomations={prevAutomationSCData}
        stateId={smartComponentStateId}
        onSaveHandler={(data) => {
          saveSmartComponentAutomation({ ...data, stateId: selectedNode?.id });
        }}
        onAutomationClose={() => {
          clearRightSide();
          dispatch(closeAutomation());
          setOpenABForSmartComponentScreen(false);
        }}
        automationSelected={automationId}
        otherConfigs={{
          hideGuardBlock: false,
        }}
        openAutomationBuilder={openABForSmartComponentScreen}
      />

      {/* OnLoad AB for Screen Automation */}
      {/* Task Create AB is handled in ConfigureWorkflowModal */}
      <ConfigureAutomation
        metaDataType={metaDataType}
        prevAutomations={prevAutomationSCData}
        stateId={smartComponentStateId}
        onSaveHandler={(data) => {
          saveOnLoadAutomation({ ...data, stateId: selectedNode?.id });
        }}
        onAutomationClose={() => {
          clearRightSide();
          dispatch(closeAutomation());
          setOpenABForOnLoadScreen(false);
        }}
        automationSelected={automationId}
        otherConfigs={{
          hideGuardBlock: true,
          allowOnlySingleAutomation: true,
          hideTriggerConfigureButton: false,
          preFillTrigger: true,
        }}
        openAutomationBuilder={openABForOnLoadScreen}
      />
    </div>
  );
};

export default FlowNodeUI;
