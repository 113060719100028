import React from "react";

type Props = {};

const ZAppIcon = ({color, width, height}: {color ?:string, width?:string, height?:string}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0001 14.5846H10.0084M6.83341 18.3346H13.1667C14.1002 18.3346 14.5669 18.3346 14.9234 18.153C15.237 17.9932 15.492 17.7382 15.6518 17.4246C15.8334 17.0681 15.8334 16.6014 15.8334 15.668V4.33464C15.8334 3.40121 15.8334 2.9345 15.6518 2.57798C15.492 2.26438 15.237 2.00941 14.9234 1.84962C14.5669 1.66797 14.1002 1.66797 13.1667 1.66797H6.83341C5.89999 1.66797 5.43328 1.66797 5.07676 1.84962C4.76316 2.00941 4.50819 2.26438 4.3484 2.57798C4.16675 2.9345 4.16675 3.40121 4.16675 4.33464V15.668C4.16675 16.6014 4.16675 17.0681 4.3484 17.4246C4.50819 17.7382 4.76316 17.9932 5.07676 18.153C5.43328 18.3346 5.89999 18.3346 6.83341 18.3346ZM10.4167 14.5846C10.4167 14.8148 10.2302 15.0013 10.0001 15.0013C9.76996 15.0013 9.58341 14.8148 9.58341 14.5846C9.58341 14.3545 9.76996 14.168 10.0001 14.168C10.2302 14.168 10.4167 14.3545 10.4167 14.5846Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ZAppIcon;