import { Button, ButtonGroup } from '@material-ui/core';
import React, { useEffect } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory } from 'react-router-dom';
import { TaskFormBody, TaskFormInput } from 'render-engine/render.styles';
import { recordRSEvent } from 'utils/CommonUtils';
import { getFullDateHour } from 'utils/DateFunctions.utils';
import { PageCategoryEnum, recordPageVisit } from '../../config/Rudderstack';
import notification from '../../notifications/notifications';
import authProvider from '../../providers/auth/authProvider';
import { RoleService } from '../../providers/data/services/RoleService';
import RoleAcl from './RoleAcl';
import Callout from 'views/onboarding/components/Callout';
import { Link } from 'react-admin';
import { ZButton } from 'views/commonComponents/commonComponents.styles';
import TopBar from 'render-engine/TopBar';

const RoleEdit = (props) => {
  const [name, setName] = React.useState('');
  const [roleId, setRoleId] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [createdOn, setCreatedOn] = React.useState('');

  const [acl, setAcl] = React.useState(null);
  const history = useHistory();

  useEffect(() => {
    recordPageVisit(PageCategoryEnum.role, 'role_edit');
  }, [props]);

  useEffect(() => {
    var id = props.id;
    const data = RoleService.getRole(id);
    data
      .then((res) => {
        const role = res?.data;
        setName(role?.name);
        setRoleId(role?.roleId);
        setDescription(role?.description);
        setCreatedOn(getFullDateHour(role?.createdOn));
      })
      .catch((err) => {
        notification('error', 'Error while fetching role details');
        console.log(err);
      });
  }, [props.id]);

  const handleNameChange = (val) => setName(val);

  const handleDescriptionChange = (val) => setDescription(val);

  const handleAclChange = (val) => setAcl(val);

  const cancelEdit = () => {
    notification('info', 'Role edit cancelled!');
    history.push(`/roles/${props.id}/show`);
  };

  const constructRoleAclPayload = () => {
    return {
      role: roleId,
      resourceActions: acl,
    };
  };

  const editRole = () => {
    const rolePayLoad = {
      name: name,
      description: description,
    };

    RoleService.updateRole(roleId, rolePayLoad)
      .then((res) => {
        // console.log(res);
        if (res?.code && res?.code.indexOf('200') < 0) {
          // Error
          if (res?.code === '401') {
            notification('error', res.message);
            recordRSEvent('editrole_event_fail', {
              context: 'role_listing',
              role_id: roleId,
              role_name: rolePayLoad.name,
              error: res.message,
            });
            authProvider.logout();
          } else {
            recordRSEvent('editrole_event_fail', {
              context: 'role_listing',
              role_id: roleId,
              role_name: rolePayLoad.name,
              error: res.message,
            });
            notification('error', res.message);
          }
        } else {
          const roleAclDetails = constructRoleAclPayload();
          RoleService.updateRoleAcl(roleAclDetails)
            .then((res) => {
              // console.log(res);
              if (res?.code && res?.code.indexOf('200') < 0) {
                // Error
                if (res?.code === '401') {
                  notification('error', res.message);
                  recordRSEvent('editrole_event_fail', {
                    context: 'role_listing',
                    role_id: roleId,
                    role_name: rolePayLoad.name,
                    error: res.message,
                  });
                  authProvider.logout();
                } else {
                  notification('error', res.message);
                  recordRSEvent('editrole_event_fail', {
                    context: 'role_listing',
                    role_id: roleId,
                    role_name: rolePayLoad.name,
                    error: res.message,
                  });
                }
              } else {
                notification('success', res.message);
                recordRSEvent('editrole_event_sucess', {
                  context: 'role_listing',
                  role_id: roleId,
                  role_name: rolePayLoad.name,
                });
                history.push('/settings/roles');
              }
            })
            .catch((err) => {
              console.log('Error', err);
              // notification("error", "Role could not be saved!");
            });
        }
      })
      .catch((err) => {
        console.log('Error', err);
        // notification("error", "Role could not be saved!");
      });
  };

  return (
    
      <ValidatorForm onSubmit={editRole} onError={(errors) => console.log(errors)}>
        
          <TaskFormBody>
          {/* {hasPermission("role", "edit") && <TopBar title="Edit Role" onCancelText={"Cancel"} onSaveText="Edit" onSave={() => {}} onCancel={cancelEdit}  />} */}
            <TopBar title="Edit Role" onCancelText={"Cancel"} onSaveText="Edit" onSave={() => {}} onCancel={cancelEdit}  />
            <div className='contentWrapper'>
              <div className="grid grid-cols-1 sm:grid-cols-8 gap-x-8">
                <div className="col-span-4">
                  <div
                    className="col-span-1 font-medium"
                    style={{ padding: "20px 0px 4px 0px" }}
                  >
                    Role
                  </div>
                  <TaskFormInput>{roleId}</TaskFormInput>
                </div>
                <div className="col-span-4">
                  <div
                      className="col-span-1 font-medium"
                      style={{ padding: "20px 0px 4px 0px" }}
                    >
                      Name <span className="text-red-500">*</span>
                  </div>
                  <TextValidator
                    autoComplete={false}
                    value={name}
                    variant='outlined'
                    style={{ minWidth: '300px', maxWidth: '300px' }}
                    size='medium'
                    className='inc-t0 oid-wd'
                    onChange={(event) => handleNameChange(event.target.value)}
                    validators={['required']}
                    errorMessages={['This field is required']}
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-8 gap-x-8">
                <div className="col-span-4">
                  <div
                      className="col-span-1 font-medium"
                      style={{ padding: "20px 0px 4px 0px" }}
                    >
                      Description
                  </div>
                  <TextValidator
                    autoComplete={false}
                    value={description}
                    variant='outlined'
                    style={{ minWidth: '300px', maxWidth: '300px' }}
                    size='medium'
                    className='inc-t0 oid-wd'
                    onChange={(event) => handleDescriptionChange(event.target.value)}
                    // validators={['required']}
                    // errorMessages={['This field is required']}
                  />
                </div>
                <div className="col-span-4">
                    <div
                        className="col-span-1 font-medium"
                        style={{ padding: "20px 0px 4px 0px" }}
                      >
                        Created On
                    </div>
                    <TaskFormInput>{createdOn}</TaskFormInput>
                </div>
              </div>
              

              <div class='flex flex-col justify-start	px-1 md:px-2'>
                <div class='flex flex-col rounded-xl'>
                  
                  <div class='pt-10 mb-4'>
                    <RoleAcl roleId={props.id} readOnly={false} onChange={handleAclChange} />
                  </div>
                  
                 
                </div>
              </div>
            </div>
          </TaskFormBody>
        
      </ValidatorForm>
    
  );
};

export default RoleEdit;
