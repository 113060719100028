import triggerIcon from "assests/trigger.svg";
import {
  TriggerCard,
  TriggerContainer,
  TriggerImage,
  ActionLabel,
  ActionDescription,
} from "../automationBuilder.styles";
import styled from "styled-components";
import { getActionIcon } from "../utils/util";

type IListCard = {
  event: any;
  clickList: () => void;
};

const ListCard = ({ event, clickList }: IListCard) => {
  console.log(event);
  return (
    <TriggerCard onClick={clickList}>
      {event?.data?.actionType ? (
        <div style={{ marginTop: "4px" }}>
          {getActionIcon(event.data.actionType)}
        </div>
      ) : (
        <TriggerImage src={triggerIcon} alt="fnIcon" />
      )}

      <TriggerContainer>
        <ActionLabel> {event.label} </ActionLabel>
        {event?.data?.description && (
          <ActionDescription> {event.data.description} </ActionDescription>
        )}
      </TriggerContainer>
    </TriggerCard>
  );
};

export default ListCard;
