import styled from "styled-components";

export const StyledBox = styled.span<{ color: string }>`
  border: ${({ color }) => (color ? `1.7px solid ${color}` : "none")};
  color: ${({ color }) => (color ? `${color}` : "#475467")};
  border-radius: 4px;
  padding: 2px 8px;
  font-weight: 600;
  white-space: nowrap;
`;

export const StyledTypography = styled.p`
  color: #475467;
  font-size: 12px;
  line-height: 250%;
  font-weight: 400;
  margin-bottom: 0px;
`;
