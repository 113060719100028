import React, { useState,useEffect } from "react";
import { themeColors } from "utils/CommonUtils";
import { Input, Label } from "views/DataField/DataField.styles";
import { Color, initColor } from "views/settings/GeneralSetting/ThemeColorSelector";

export interface ColorButtonProps {
  color: Color;
  onSelect: (color: Color) => void;
  isActive: boolean;
}

const ColorButton: React.FC<ColorButtonProps> = ({
  color,
  onSelect,
  isActive,
}) => {
  const handleClick = () => {
    onSelect(color);
  };

  return (
    <button type="button"
      style={{
        backgroundColor: color.primary,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "36px",
        height: "36px",
        border: "none",
        outline: "none",
        borderRadius: "4px",
        cursor: "pointer",
        marginRight: "10px",
        opacity: isActive ? 1 : 1, // Remove the opacity styling
      }}
      onClick={handleClick}
    >
      {isActive && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="white"
          width="18px"
          height="18px"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M9 16.17L5.53 12.7a.996.996 0 1 1 1.41-1.41L9 13.17l7.89-7.88a.996.996 0 1 1 1.41 1.41L9 16.17z" />
        </svg>
      )}
    </button>
  );
};

interface ColorListProps {
  defaultColor?: Color;
  defaultUsePrimaryColor?: boolean;
  label?: string;
  onSelect: (color: Color, usePrimaryColor: boolean) => void;
  isSettings ?: boolean;
  colorList ?: Color[];
}

const ColorList: React.FC<ColorListProps> = ({ defaultColor,isSettings=true, defaultUsePrimaryColor, label, onSelect,colorList=themeColors }) => {
  const colors: Color[] = colorList;
  const [selectedColor, setSelectedColor] = useState<Color | null>(
    defaultColor || null
  );
  const [usePrimaryColor, setUsePrimaryColor] = useState(false);


  const handleColorSelect = (color: Color) => {
    setSelectedColor(color);
    onSelect(color, usePrimaryColor);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsePrimaryColor(event.target.checked);
    onSelect(selectedColor ?? initColor , event.target.checked);
  };

  useEffect(() => {
      setSelectedColor(defaultColor || null);
      setUsePrimaryColor(defaultUsePrimaryColor?? false);

  }, [defaultColor, defaultUsePrimaryColor]);

  return (
    <div>

      <div style={{ display: "flex" }}>
        {colors.map((color, index) => (
          <ColorButton
            key={index}
            color={color}
            onSelect={handleColorSelect}
            isActive={selectedColor?.primary === color.primary}
          />
        ))}
      </div>
      <div style={{ display: isSettings ? "flex" : "none", alignItems: "center", marginTop: "8px", marginLeft: "-5px" }}>
        <Input type="checkbox" style={{ width: "25px" }} checked={usePrimaryColor} onChange={handleCheckboxChange} />
        <Label style={{ display: "inline", fontWeight: "500" }}> Use Primary Color for Mobile App bar</Label>
      </div>
    </div>
  );
};

export default ColorList;