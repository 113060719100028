import AlertAutomation from "./AlertAutomation";
import AlertEmail from "./AlertEmail";
import AlertTicket from "./AlertTicket";
import SlackActionConfiguration from "./SlackActionConfiguration";
import useMonitor from '../reducer/useMonitor';
import { useDispatch } from "react-redux";
import { IActionType } from "@zorp/common-libs-js/dist/monitor";
import { setMonitorInterfaceContext } from "../reducer/MonitorReducer";
import React, { useEffect, useState } from "react";
import { BffService } from "providers/data/services/BffService";
import { UserService } from "providers/data/services/UserService";
import useAccount from "views/layouts/reducer/useAccount";
import ConfirmationDialog from 'views/automationBuilder/components/ConfirmationDialog';
import { ISelectValueType } from "../DatasourceModal";
import notification from "notifications/notifications";
interface ISelectedRightSideAction {
  control: any;
  setValue: any;
  watch: any;
  errors: any;
  register: any;
  remove: any;
  fields: any;
}

const SelectedRightSideAction = ({
  control,
  setValue,
  watch,
  errors,
  register,
  remove,
  fields,
}: ISelectedRightSideAction) => {
  const { monitorInterfaceContext } = useMonitor();
  const dispatch = useDispatch();
  const { account } = useAccount();
  const actiontype = monitorInterfaceContext?.monitorComponentsData?.selectedActionDetails?.actionType;
  const [slackMemberArray, setSlackMemberArray] = useState();
  const [userListArray, setuserListArray] = useState<ISelectValueType[]>([]);
  const [actionDeleteConfirmation, setActionDeleteConfirmation] = useState<number | undefined>();

  const removeAction = (index: number) => {
    remove(index)

    dispatch(setMonitorInterfaceContext({
      ...monitorInterfaceContext, // Spread the previous state to retain other values
      monitorComponentsData: {
        ...monitorInterfaceContext?.monitorComponentsData, // Spread the previous monitorComponentsData
        selectedActionDetails: undefined
      }
    }));
  }

  useEffect(() => {
    if (account?.accountConfig?.slackVerificationToken) {
      fetchSlackDetails();
    }
  }, [])

  const fetchSlackDetails = async () => {
    let slackUserArray = [];
    let slackChannelArray = [];

    const fetchSlackChannels = await BffService.fetchSlackChannels();

    if (fetchSlackChannels?.code == '200') {
      slackUserArray = fetchSlackChannels.data.map((data: any) => {
        return { label: data.name, value: data.id, type: 'CHANNEL', id: data.id, name: data.name}
      })
    }

    const fetchSlackUser = await BffService.fetchSlackUsers();

    if (fetchSlackUser?.code == '200') {
      slackChannelArray = fetchSlackUser.data.map((data: any) => {
        return { label: data.name, value: data.id, type: 'USER', id: data.id, name: data.name}
      })
    }

    setSlackMemberArray(slackUserArray.concat(slackChannelArray));
  }

  const fetchTicketUserList = async () => {
    const usersList = await UserService.userSelect(undefined);
    if (usersList?.code == '200') {

      const userListData = usersList?.data?.users?.map((data: any) => {
        return {
          label: data.name + `${data.email ? ' - ' + data.email : ''}`, 
          value: data.email || data.userId
        }
      })
      setuserListArray(userListData || []);
    } else {
      notification('error', 'Error fetching user list');
      setuserListArray([]);
    }
  }

  useEffect(()=>{
    fetchTicketUserList();
  },[])

  return (
    <>
      {monitorInterfaceContext?.monitorComponentsData?.selectedActionDetails?.actionType == IActionType.SLACK ? (
        <SlackActionConfiguration
          control={control}
          slackMember={slackMemberArray}
          fields={fields}
          onRemove={(fieldsIndex: number) => {
            setActionDeleteConfirmation(fieldsIndex);
          }}
          errors={errors}
          setValue={setValue}
          watch={watch}
        />
      ) : actiontype == IActionType.TICKET ? (
        <>
          <AlertTicket
            control={control}
            fields={fields}
            userListArray={userListArray}
            register={register}
            onRemove={(fieldsIndex: number) => {
              setActionDeleteConfirmation(fieldsIndex);
            }}
            errors={errors}
            setValue={setValue}
            watch={watch}
          />
        </>
      ) : actiontype == IActionType.EMAIL ? (
        <>
          <AlertEmail
            errors={errors}
            fields={fields}
            control={control}
            register={register}
            onRemove={(fieldsIndex: number) => {
              setActionDeleteConfirmation(fieldsIndex);
            }}
            setValue={setValue}
            watch={watch}
          />
        </>
      ) : actiontype == IActionType.AUTOMATION ? (
        <AlertAutomation
          fields={fields}
          onRemove={(fieldsIndex: number) => {
            setActionDeleteConfirmation(fieldsIndex);
          }}
        />
      ) : (
        <></>
      )}

      <ConfirmationDialog
        id={'monitor_action_discard_modal'}
        deletionTitle={'Are you sure you want to delete this action?'}
        deletionText={`You will lose all the changes you have done to this action.`}
        isOpen={actionDeleteConfirmation as number >= 0}
        primaryButtonName="Yes"
        variant="danger"
        secondaryButtonName="No"
        onPrimaryBtnClick={() => {
          removeAction(actionDeleteConfirmation as number);
          setActionDeleteConfirmation(undefined);
        }}
        onSecondaryClick={() => {
          setActionDeleteConfirmation(undefined);
        }}
        onClose={() => {
          setActionDeleteConfirmation(undefined);
        }}
      />
    </>
  );
};

// export default SelectedRightSideAction;
export  default React.memo(SelectedRightSideAction);