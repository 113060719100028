import { useEffect, useState } from "react";
import FileUpload from "components/dropzone/FileUpload";
import { FunctionComponent } from "react";
import {
  UseFormRegister,
  FieldErrors,
  UseFormSetValue,
  UseFormGetValues,
  useFieldArray,
  Control,
} from "react-hook-form";

import { IZUploader } from "./IZUploader";
import { AiOutlineWarning } from "react-icons/ai";
import styled from "styled-components";
import {  ZButton } from "views/commonComponents/commonComponents.styles";
import ZShowImage from "components/ZImageViewer/ZShowImage";
import _ from "lodash";
import { Modal } from "@mui/material";

const HeadingContainer = styled.div`
  display:flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

export const ZUploaderSign: FunctionComponent<{
  config: IZUploader;
  onChange: Function;
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  isOpenedAsSubfield: boolean;
  control: Control;
}> = ({
  config,
  getValues,
  onChange,
  register,
  errors,
  isOpenedAsSubfield,
  control,
  setValue,
}) => {
  const [alreadyUploadedSign, setAlreadyUploadedSign] = useState<string[]>([]);
  // this function will be called if the field is opened as a subfile for any list.
  // we are appending the link to the form arr

  const updateLocalStateWhenOpenedAsSubfield = (
    key: string,
    value: string
  ): void => {
    // setUploadedImage((prev: string[]) => [...prev, value]);
    setValue(config.key, value[0]);
    setAlreadyUploadedSign((prev) => [...prev, value[0]]);
  };

  const removeFiles = (idx: number) => {
    setAlreadyUploadedSign([]);
    setValue(config.key, "");
  };

  const [showUploadModal,setShowUploadModal] = useState<boolean>(false)

  const handleChange = updateLocalStateWhenOpenedAsSubfield;

  // ADD ONcHANGE for loading already created image in the array.
  useEffect(() => {
    //TODO: REMOVE THIS ONCE SIGNATURE RESPONSE IS FIXED FROM BFF
    //@ts-ignore
    if(config.value === [] || config.value === ""){
        setValue(config.key, "")
    } else{
        setValue(config.key, "")
        if(Array.isArray(config.value)){
            //@ts-ignore
            setAlreadyUploadedSign((prev) => [...prev, ...config.value])
            setValue(config.key, config.value[0])
        } else {
            //@ts-ignore
            setAlreadyUploadedSign((prev) => [...prev, config.value])
            setValue(config.key, config.value)
        }
        
    } 
   
  }, []);

  const linksArray = _.cloneDeep([getValues()[config.key]])
  linksArray?.forEach((val:string,index:number) => {
    linksArray[index] = {
      url: val,
      title: `Image ${index}`
    }})

  //   const alreadyUploadedImages:any[]  = getValues()[config.key] ? [getValues()[config.key]] : []

  return (
    <>
    {
        errors[config.key]?.message && (
          <div className="font-semibold text-xs text-red-500 mt-1">
            <AiOutlineWarning
              size={"1rem"}
              color="red"
              style={{ display: "inline", marginRight: "4px" }}
            />{" "}
            {errors[config.key]?.message}
          </div>
        )
      }
      <HeadingContainer>
        <div>
          <p style={{fontSize:"12px", color:"gray"}}> Uploaded Images</p>
        </div>
        <div>
          {/* <ZButton variant="contained" onClick={() => setShowUploadModal(true)} secondary > Upload Images </ZButton> */}
          <FileUpload
              config={config}
              onChange={handleChange}
              alreadyUploadedImages={alreadyUploadedSign}
              isOpenedAsSubfield={isOpenedAsSubfield}
              removeFiles={removeFiles}
            />
        </div>
      </HeadingContainer>
      <div style={{overflow:"auto"}}>
      <ZShowImage deleteFunc={removeFiles} linksArray={linksArray || []}/>
      </div>
      <Modal
        open={showUploadModal}
        onClose={() => setShowUploadModal(false)}
      >
        <div className="newwwwww">
          <FileUpload
              config={config}
              onChange={handleChange}
              alreadyUploadedImages={alreadyUploadedSign}
              isOpenedAsSubfield={isOpenedAsSubfield}
              removeFiles={removeFiles}
            />
        </div>
      </Modal>
      {/* <FileUpload
        config={config}
        onChange={handleChange}
        alreadyUploadedImages={alreadyUploadedSign}
        isOpenedAsSubfield={isOpenedAsSubfield}
        removeFiles={removeFiles}
      /> */}
    </>
  );
};
