import React, { useEffect,useMemo,useState } from "react";
import { DriveFile } from "./googleForm.types";
import { Heading } from "views/workflows/workflowactions/api/APIAction/ImportCurl/CurlStyledComponents";

import styled from "styled-components";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import { debounce } from "lodash";
//@ts-ignore
import GForm from "assests/gform.svg"


const FormDiv = styled.div`
  border: 1px solid #eaecf0;
  padding: 8px 16px;
  border-radius: 4px;
  margin-bottom: 8px;
  display: flex;
  justify-content : space-between;
  align-items: center;
`;

const Wrapper = styled.div`
    height: 75%;
    overflow-y: scroll;
`

const Text = styled.p`
   font-weight: 500;
    font-size: 16px;
    margin-bottom: 8px;
`

const Input = styled.input`
  border: 1px solid #eaecf0;
  padding: 8px 16px;
  border-radius: 4px;
  margin-bottom: 8px;
  width: 100%;
`

type Props = {
  forms: DriveFile[];
  token: string;
  createWorkflow: (formId: string, token: string) => void;
};

const FormsContainers = ({ forms, token, createWorkflow }: Props) => {

  const [query, setQuery] = useState("");
  const [filteredForms, setFilteredForms] = useState(forms);

  const filterForms = (query: string) => {
    if(!query) return setFilteredForms(forms)
    const filtered = forms.filter((form) => {
      return form.name.toLowerCase().includes(query.toLowerCase());
    });
    setFilteredForms(filtered);
  }

  const handleChange = (e:any) => {
    setQuery(e.target.value);
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleChange, 300);
  }, []);

  useEffect(() => {
    filterForms(query);
  },[query])

  return (
    <>
      <Text> Search a form to get started </Text>
      <Input placeholder={"Search Forms"} type="text" onChange={debouncedResults} />
      <div className="mb-4"></div>
      <Wrapper>
        {filteredForms.map((form) => (
          <FormDiv
            onClick={() => {
              createWorkflow(form.id, token);
            }}
            key={form.id}
          >
            <div style={{
              display: "flex",
              gap: "12px",
              alignItems: "center"
            }}>
              <div>
                <img src={GForm} alt="gform" />
              </div>
              <div style={{fontWeight:"500"}}>{form.name}</div>
            </div>
            
            <div>
              <ZButton variant="contained" secondary>
                Convert to Workflow
              </ZButton>
            </div>
          </FormDiv>
        ))}
      </Wrapper>
    </>
  );
};

export default FormsContainers;
