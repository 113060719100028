import React, { ReactChildren, ReactElement } from 'react'
import Modal from '@mui/material/Modal'; 
import {GrClose} from "react-icons/gr"
import {useSelector,useDispatch} from "react-redux"
import { setSelectedAction } from 'views/workflows/reducers/workflowAction';
import useOnboarding from '../hooks/useOnboarding';
import { setShowOnboardingModal } from '../reducer/onboardingAction';
import { OnboardingUtil } from '../OnboardingUtil';
  
 
const OnboardingModal = ({children}:{children:ReactElement}) => {

  const dispatch = useDispatch()
  const {showOnboardingModal} = useOnboarding()  
  return (
    <Modal
    open={showOnboardingModal}
    onClose={() => OnboardingUtil.stop(dispatch)}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    >
    
    <div style={{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        height:"100%"
    }}>
        {children}
    </div>
      
    
    </Modal>
  )
}

export default OnboardingModal