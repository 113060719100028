import React, { FunctionComponent, useState, useEffect } from "react"
import { UseFormRegister, FieldErrors } from "react-hook-form"
import {
  BlockLabel,
  LinkContainer,
  TaskFormInput,
  TaskFormLabelForShow,
  ZActionButton,
} from "render-engine/render.styles"

import { IZlocationValueInterface } from "../location-input/IZLocationValueInterface"
import { IZLocationDisplayInterface } from "./IZLocationDisplayInterface"
import { GrLocation } from "react-icons/gr"
import { TaskMap } from "./TaskMap"
import styled from "styled-components"
import { device } from "utils/CommonUtils"
import useTaskShow from "views/workflows/hooks/useTaskShow"
import { ZComponent } from "components/common/ZComponent"
import { BiEdit } from "react-icons/bi"
import { useDispatch } from "react-redux"
import { setInEditState } from "render-engine/reducers/show/TaskShowReducer"
import {
  CurlModal,
  GPTDiv,
} from "views/workflows/workflowactions/api/APIAction/ImportCurl/CurlStyledComponents"
import { ZComponentV2 } from "components/common/ZComponentV2"
import useAccessPermissions from "hooks/useAccessPermissions"
import { ResourceTypeConstant } from "views/entities/permissions/types"

const Container = styled.div`
  display: flex;
  @media ${device.mobileL} {
    flex-direction: column;
  }
`

export const ZLocationDisplayV2: FunctionComponent<{
  config: IZLocationDisplayInterface
  onChange: Function
  register: UseFormRegister<any>
  errors: FieldErrors<any>
}> = (props) => {
  const { config, onChange, register, errors } = props
  let isVisible = true
  const [showEdit, setShowEdit] = useState(false)
  const dispatch = useDispatch()
  const { convertDataTypeToString } = useTaskShow()
  if (config.hasOwnProperty("isVisible")) {
    if (
      (typeof config.isVisible === "string" ||
        config.isVisible instanceof String) &&
      config.isVisible.toLowerCase().trim() === "false"
    )
      isVisible = false
    else if (typeof config.isVisible === "boolean") isVisible = config.isVisible
    else if (typeof config.isVisible === "function")
      isVisible =
        config.isVisible() === "true" || config.isVisible() === true
          ? true
          : false
  }

  const { inEditState } = useTaskShow()
  const accessPermissions = useAccessPermissions({ resourceType: ResourceTypeConstant.TABLE })

  // Find the map center
  // Map center is set to the first location value element
  let mapCenter: any
  let locations: Array<IZlocationValueInterface> = []
  const value = convertDataTypeToString(config.key, false)
  if (value) {
    if (Array.isArray(value)) {
      if (value[0]) mapCenter = value[0]
      else mapCenter = { latitude: 0, longitude: 0 }

      locations = value
    } else {
      // check if value.latitude and value.longitude exists
      if (value?.latitude && value?.longitude) mapCenter = value
      else
        mapCenter = {
          latitude: 0,
          longitude: 0,
        }
      mapCenter = mapCenter
      locations = [value]
    }
  }

  const locationsDisplay =
    locations?.length > 0 ? (
      locations?.map((location: IZlocationValueInterface, index: number) => {
        return (
          <div key={index} className="">
            <LinkContainer>
              <a
                href={
                  "https://maps.google.com?q=" +
                  location?.latitude +
                  "," +
                  location?.longitude
                }
                target="_blank"
              >
                {location?.address}
              </a>
            </LinkContainer>
            {accessPermissions.edit_record && !props?.config?.isReadOnly && <LinkContainer>
              <a
                href={
                  "https://maps.google.com?q=" +
                  location?.latitude +
                  "," +
                  location?.longitude
                }
                target="_blank"
              >
                {location?.latitude}
              </a>
              {
                location?.latitude && location?.longitude ? ", " : null
              }
              <a
                href={
                  "https://maps.google.com?q=" +
                  location?.latitude +
                  "," +
                  location?.longitude
                }
                target="_blank"
              >
                {location?.longitude}
              </a>
            </LinkContainer>}
            <LinkContainer></LinkContainer>
          </div>
        )
      })
    ) : accessPermissions.edit_record && !props?.config?.isReadOnly ? (
      <ZActionButton
        className=""
        onClick={() => { handleEditClick() }}
      >
        + Add Location
      </ZActionButton>
    ) : null

  const handleEditClick = () => {
    setShowEdit(true)
    dispatch(setInEditState({ nodeId: config.key, inEditState: true }))
  }

  // Markers
  // const markers = config.values?.map((loc: IZlocationValueInterface) => return {latitude})

  if (isVisible)
    return (
      <>
        <div className="col-span-4">
          <TaskFormLabelForShow
            fontSize={config.label?.fontSize}
            color={config?.label?.color}
          >
            {config?.label?.val}
            {accessPermissions.edit_record && !props?.config?.isReadOnly && <BiEdit
              style={{
                display: "inline",
                marginLeft: "5px",
              }}
              onClick={handleEditClick}
            />}
          </TaskFormLabelForShow>
          <Container>
            {/* <div>
              <TaskMap
                locationDisplay={true}
                mapCenter={[mapCenter?.latitude ?? 0, mapCenter?.longitude ?? 0]}
                locations={locations}
              ></TaskMap>
            </div> */}
            <div>{locationsDisplay}</div>
          </Container>
        </div>
        <CurlModal open={showEdit} onClose={() => setShowEdit(false)}>
          <GPTDiv
            style={{
              height: "auto",
              width: "40vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "4px",
            }}
          >
            <ZComponentV2
              {...props}
              config={{
                ...config.editConfig,
                onClose: () => setShowEdit(false),
              }}
            />
          </GPTDiv>
        </CurlModal>
      </>
    )
  else return <div></div>
}
