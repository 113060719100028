// @ts-ignore
import ZLogo from 'views/layouts/Logo.svg';
// @ts-ignore
import Rocket from 'assests/rocket.png';
// @ts-ignore
import DefaultImg from './placeholder.svg';

export const images = {
  Rocket: Rocket,
  ZLogo: ZLogo,
  DefaultImg: DefaultImg,
};
