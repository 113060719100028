import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { getConfig as Config } from "./envConfig/Config";
import notification from "../notifications/notifications";
import { recordRSEvent } from "utils/CommonUtils";
import { generateId } from "utils/Utils";
import { sendCCErrorAlertToSlack } from "utils/SlackController";

const httpRequestorInterceptor = (config: AxiosRequestConfig) => {
  config.headers = {
    Authorization: "Bearer " + localStorage.getItem("at"),
    "x-zorp-channel": "CC",
  };
  return config;
};

const httpResponseInterceptor = (response: AxiosResponse) => {
  // if the url is not test api url, then only check for 401
  const checkNotTestAPIUrl = response.config.url != "/web/api/test";
  if (
    (response.status === 401 ||
      (response.data && response.data.code === "401")) &&
    !window.location.href.includes("signup") &&
    !window.location.href.includes("login") &&
    checkNotTestAPIUrl
  ) {
    // console.log("current window url", window.location.href);
    // localStorage.setItem("templateId",window.location.href);
    // const url = window.location.href;
    // // get search param templateId
    // const tempArr = url.split("templateId=");
    // const templateId = tempArr.length > 1 ? tempArr[1] : "";
    localStorage.removeItem("at");
    localStorage.removeItem("rt");
    const templateId = localStorage.getItem("templateId");
    const solutionId = localStorage.getItem("solutionId");
    notification("warning", "Your session has expired. Please Relogin!");
    window.location.href = templateId
      ? `/login?templateId=${templateId}&solutionId=${solutionId}`
      : `/login`;
    window.location.reload();
    HttpUtil.communicationInstance({});
    HttpUtil.taskInstance({});
    HttpUtil.bffInstance({});
    HttpUtil.userInstance({});
    HttpUtil.assignmentInstance({});
    HttpUtil.teamInstance({});
    HttpUtil.builderInstance({});
    HttpUtil.cronInstance({});
  }
  if (response.status === 403) {
    return Promise.reject({ response });
  }

  if (response.status === 500) {
    return Promise.reject({ response });
  }

  if (response.status === 600) {
    return Promise.reject({ response });
  }
  return response;
};

const httpResponseErrorInterceptor = (error: AxiosError) => {
  console.error(error, "-------------");

  if (
    error.message &&
    error.message.indexOf("401") >= 0 &&
    !window.location.href.includes("signup")
  ) {
    // notification('error', error.response?.data.message);
    // localStorage.removeItem('at');
    // localStorage.removeItem('rt');
    // notification('warning', 'Your session has expired. Please Relogin!');
    // window.location.href = '/';
    // window.location.reload();
    // HttpUtil.communicationInstance({});
    // HttpUtil.taskInstance({});
    // HttpUtil.bffInstance({});
    // HttpUtil.userInstance({});
    // HttpUtil.assignmentInstance({});
    // HttpUtil.teamInstance({});
  } else if (error.message && error.message.indexOf("403") >= 0) {
    // throw error;
    // fication('error', error.response?.data?.message);
    return;
  }
  notification("error", error?.message || (error.response?.data as any)?.message || "Something went wrong");
  recordRSEvent("API_CALL_FAILED", {
    API: error.config.url,
    STATUS: error.response?.status,
    MESSAGE: error.response?.data?.message,
  });
  if (error?.config.url !== "/alert/count?statuses=NEW") {
    sendCCErrorAlertToSlack({
      endpoint: error?.config.url || "unknown",
      code: error?.response?.status,
      message: error?.message || error?.response?.data?.message || "unknown",
    })
      .then((res: any) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  throw error;
};

const httpResponseEntityErrorInterceptor = (error: AxiosError) => {
  console.error(error, "-------------");

  if (
    error.message &&
    error.message.indexOf("401") >= 0 &&
    !window.location.href.includes("signup")
  ) {
    // notification('error', error.response?.data.message);
    // localStorage.removeItem('at');
    // localStorage.removeItem('rt');
    // notification('warning', 'Your session has expired. Please Relogin!');
    // window.location.href = '/';
    // window.location.reload();
    // HttpUtil.communicationInstance({});
    // HttpUtil.taskInstance({});
    // HttpUtil.bffInstance({});
    // HttpUtil.userInstance({});
    // HttpUtil.assignmentInstance({});
    // HttpUtil.teamInstance({});
  } else if (error.message && error.message.indexOf("403") >= 0) {
    throw error;
    // // fication('error', error.response?.data?.message);
    // return;
  }
  notification("error", (error.response?.data as any)?.message);
  recordRSEvent("API_CALL_FAILED", {
    API: error.config.url,
    STATUS: error.response?.status,
    MESSAGE: error.response?.data?.message,
  });
  if (error?.config.url !== "/alert/count?statuses=NEW") {
    sendCCErrorAlertToSlack({
      endpoint: error?.config.url || "unknown",
      code: error?.response?.status,
      message: error?.message || error?.response?.data?.message || "unknown",
    })
      .then((res: any) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  throw error
};

export default class HttpUtil {
  private static _communicationInstance: AxiosInstance | undefined;
  private static _userInstance: AxiosInstance | undefined;
  private static _taskInstance: AxiosInstance | undefined;
  private static _bffInstance: AxiosInstance | undefined;
  private static _builderInstance: AxiosInstance | undefined;
  private static _assignmentInstance: AxiosInstance | undefined;
  private static _teamInstance: AxiosInstance | undefined;
  private static _accountInstance: AxiosInstance | undefined;
  private static _cronInstance: AxiosInstance | undefined;
  private static _monitorInstance: AxiosInstance | undefined;
  private static _monitorGqlInstance: AxiosInstance | undefined;
  private static _tablesInstance: AxiosInstance | undefined;

  public static get communicationInstance(): AxiosInstance {
    this._communicationInstance = axios.create({
      baseURL: `${Config("services.communicationSvc.host")}${Config("services.communicationSvc.endpoint")}`,
      timeout: Config("services.communicationSvc.timeout"),
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
      },
    });
    this._communicationInstance.interceptors.response.use(
      httpResponseInterceptor,
      httpResponseErrorInterceptor
    );
    this._communicationInstance.interceptors.request.use(
      httpRequestorInterceptor
    );
    return this._communicationInstance;
  }
  public static get monitorInstance(): AxiosInstance {
    this._monitorInstance = axios.create({
      baseURL: `${Config("services.monitorSvc.host")}${Config("services.monitorSvc.endpoint")}`,
      timeout: Config("services.monitorSvc.timeout"),
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
      },
    });
    this._monitorInstance.interceptors.response.use(
      httpResponseInterceptor,
      httpResponseErrorInterceptor
    );
    this._monitorInstance.interceptors.request.use(httpRequestorInterceptor);
    return this._monitorInstance;
  }

  public static set monitorInstance(dummy: any) {
    this._monitorInstance = undefined;
  }

  public static get monitorGqlInstance(): AxiosInstance {
    this._monitorGqlInstance = axios.create({
      baseURL: `${Config("services.monitorGqlSvc.host")}${Config("services.monitorGqlSvc.endpoint")}`,
      timeout: Config("services.monitorGqlSvc.timeout"),
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
      },
    });
    this._monitorGqlInstance.interceptors.response.use(
      httpResponseInterceptor,
      httpResponseErrorInterceptor
    );
    this._monitorGqlInstance.interceptors.request.use(httpRequestorInterceptor);
    return this._monitorGqlInstance;
  }

  public static set monitorGqlInstance(dummy: any) {
    this._monitorGqlInstance = undefined;
  }

  public static set communicationInstance(dummy: any) {
    this._communicationInstance = undefined;
  }

  public static get userInstance(): AxiosInstance {
    this._userInstance = axios.create({
      baseURL: `${Config("services.userSvc.host")}${Config("services.userSvc.endpoint")}`,
      timeout: Config("services.userSvc.timeout"),
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
      },
      validateStatus: function (status) {
        return status >= 200 && status < 500; // default
      },
    });
    this._userInstance.interceptors.response.use(
      httpResponseInterceptor,
      httpResponseErrorInterceptor
    );
    this._userInstance.interceptors.request.use(httpRequestorInterceptor);
    return this._userInstance;
  }

  public static get entityInstance(): AxiosInstance {
    this._tablesInstance = axios.create({
      baseURL: `${Config("services.tablesSvc.host")}${Config("services.tablesSvc.endpoint")}`,
      timeout: Config("services.tablesSvc.timeout"),
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
      },
      validateStatus: function (status) {
        return status >= 200 && status < 399;
      },
    });
    this._tablesInstance.interceptors.response.use(
      httpResponseInterceptor,
      httpResponseEntityErrorInterceptor
    );
    return this._tablesInstance;
  }

  public static get entityInstanceForBulk(): AxiosInstance {
    this._tablesInstance = axios.create({
      baseURL: `${Config("services.tablesSvcForBulkApi.host")}${Config("services.tablesSvcForBulkApi.endpoint")}`,
      timeout: Config("services.tablesSvcForBulkApi.timeout"),
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        "x-zorp-key": generateId(10),
      },
      validateStatus: function (status) {
        return status < 399; // default
      },
    });
    this._tablesInstance.interceptors.response.use(httpResponseInterceptor);
    return this._tablesInstance;
  }

  public static set userInstance(dummy: any) {
    this._userInstance = undefined;
  }

  public static get teamInstance(): AxiosInstance {
    this._teamInstance = axios.create({
      baseURL: `${Config("services.teamSvc.host")}${Config("services.teamSvc.endpoint")}`,
      timeout: Config("services.teamSvc.timeout"),
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
      },
      validateStatus: function (status) {
        return status >= 200 && status < 500; // default
      },
    });
    this._teamInstance.interceptors.response.use(
      httpResponseInterceptor,
      httpResponseErrorInterceptor
    );
    this._teamInstance.interceptors.request.use(httpRequestorInterceptor);
    return this._teamInstance;
  }

  public static set teamInstance(dummy: any) {
    this._teamInstance = undefined;
  }

  public static get taskInstance(): AxiosInstance {
    this._taskInstance = axios.create({
      baseURL: `${Config("services.taskSvc.host")}${Config("services.taskSvc.endpoint")}`,
      timeout: Config("services.taskSvc.timeout"),
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
      },
      validateStatus: function (status) {
        return status >= 200 && status < 500; // default
      },
    });
    this._taskInstance.interceptors.response.use(
      httpResponseInterceptor,
      httpResponseErrorInterceptor
    );
    this._taskInstance.interceptors.request.use(httpRequestorInterceptor);
    return this._taskInstance;
  }

  public static set taskInstance(dummy: any) {
    this._taskInstance = undefined;
  }

  public static get bffInstance(): AxiosInstance {
    this._bffInstance = axios.create({
      baseURL: `${Config("services.bffSvc.host")}${Config("services.bffSvc.endpoint")}`,
      timeout: Config("services.bffSvc.timeout"),
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
      },
      validateStatus: function (status) {
        return status >= 200 && status < 500; // default
      },
    });
    this._bffInstance.interceptors.response.use(
      httpResponseInterceptor,
      httpResponseErrorInterceptor
    );
    this._bffInstance.interceptors.request.use(httpRequestorInterceptor);
    return this._bffInstance;
  }

  public static set bffInstance(dummy: any) {
    this._bffInstance = undefined;
  }

  public static get builderInstance(): AxiosInstance {
    this._builderInstance = axios.create({
      baseURL: `${Config("services.builderSvc.host")}${Config("services.builderSvc.endpoint")}`,
      timeout: Config("services.builderSvc.timeout"),
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
      },
      validateStatus: function (status) {
        return status >= 200 && status < 500; // default
      },
    });
    this._builderInstance.interceptors.response.use(
      httpResponseInterceptor,
      httpResponseErrorInterceptor
    );
    this._builderInstance.interceptors.request.use(httpRequestorInterceptor);
    return this._builderInstance;
  }

  public static set builderInstance(dummy: any) {
    this._bffInstance = undefined;
  }

  public static get assignmentInstance(): AxiosInstance {
    this._assignmentInstance = axios.create({
      baseURL: `${Config("services.assignmentSvc.host")}${Config("services.assignmentSvc.endpoint")}`,
      timeout: Config("services.assignmentSvc.timeout"),
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
      },
      validateStatus: function (status) {
        return status >= 200 && status < 500; // default
      },
    });
    this._assignmentInstance.interceptors.response.use(
      httpResponseInterceptor,
      httpResponseErrorInterceptor
    );
    this._assignmentInstance.interceptors.request.use(httpRequestorInterceptor);
    return this._assignmentInstance;
  }

  public static set assignmentInstance(dummy: any) {
    this._assignmentInstance = undefined;
  }

  public static get accountInstance(): AxiosInstance {
    this._accountInstance = axios.create({
      baseURL: `${Config("services.accountSvc.host")}${Config("services.accountSvc.endpoint")}`,
      timeout: Config("services.accountSvc.timeout"),
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
      },
      validateStatus: function (status) {
        return status >= 200 && status < 500; // default
      },
    });
    this._accountInstance.interceptors.response.use(
      httpResponseInterceptor,
      httpResponseErrorInterceptor
    );
    this._accountInstance.interceptors.request.use(httpRequestorInterceptor);
    return this._accountInstance;
  }

  public static set accountInstance(dummy: any) {
    this._accountInstance = undefined;
  }

  public static get cronInstance(): AxiosInstance {
    this._cronInstance = axios.create({
      baseURL: `${Config("services.cronSvc.host")}${Config("services.cronSvc.endpoint")}`,
      timeout: Config("services.cronSvc.timeout"),
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
      },
      validateStatus: function (status) {
        return status >= 200 && status < 500; // default
      },
    });
    this._cronInstance.interceptors.response.use(
      httpResponseInterceptor,
      httpResponseErrorInterceptor
    );
    this._cronInstance.interceptors.request.use(httpRequestorInterceptor);
    return this._cronInstance;
  }

  public static set cronInstance(dummy: any) {
    this._cronInstance = undefined;
  }
}
