import { IAutomation } from "views/automationBuilder/types/ABType";
import bolt from 'assests/bolt.svg';
import { ABEntryListSectionWrapper, ABEntryListWrapperText, ABEntryListWrapper, ABListText } from '../automationBuilder.styles';

type IABEntryList = {
  automationList: IAutomation[],
  onAutomationClick: (automationId: string) => void;
}

const ABEntryList = ({ automationList, onAutomationClick }: IABEntryList) => {
  return (
    automationList?.length > 0 &&
    <ABEntryListSectionWrapper>
      <ABEntryListWrapperText>
        This transition is used in the following automations
      </ABEntryListWrapperText>
      <ABEntryListWrapper>
        <>
          {
            automationList.map((automationData) => (
              <>
                <img src={bolt} alt='fnIcon' />
                <ABListText
                  onClick={() => {
                    onAutomationClick(automationData.id);
                  }} >{automationData.name}</ABListText>
              </>
            ))
          }
        </>
      </ABEntryListWrapper>
    </ABEntryListSectionWrapper>
  )
}

export default ABEntryList