import { TableCell } from "@mui/material"
import styled from "styled-components"
import { Input } from "views/DataField/DataField.styles"

const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
};

const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
}

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
}

export const HeaderDiv = styled.div`
  display: flex;
  border: 2px solid #eaeaef;
  border-right: none;
  padding: 4px 8px;
  font-weight: 500;
  justify-content: space-between;
  align-items: center;

  button {
    padding: 8px 13px 8px 13px;
    border-radius: 4px;
    color: white;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    background-color: #007aff;
    margin-right: 4px;
  }
`

// export const BlockLabel = styled.h3`
//     font-weight: 500;
//     font-size: 14px;
//     /* padding: 0px 0px 10px 0px; */
//     /* border-bottom: 1px solid #e1e4e8; */
//     margin: 0px 0px 12px 0px;

// `

// export const BlockDiv = styled.div<{isLast: boolean}>`
//     padding: ${(props:{isLast:boolean}) => props?.isLast ? "20px" : "" };
//     background: white;
//     box-shadow: ${(props:{isLast:boolean}) => props ? "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)" : "" };
//     /* background: , rgba(255, 255, 255, 0.9)), #C2C9D1; */
//     /* border-radius: px; */
//     margin-bottom: 32px;
//     border: ${(props:{isLast:boolean}) => props?.isLast ? "1px solid #EAECF0" : "" };

// `

export const BlockLabel = styled.h3`
  font-weight: 500;
  font-size: 12px;
  color: ${(props: { color?: string }) => props?.theme?.color.primary || ""};
`

export const BlockHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e1e4e8;
  padding: 8px 16px;
  background: ${(props: { backgroundColor?: string }) => {
    const primaryColor = props?.theme.color.primary || "";
    const rgb = hexToRgb(primaryColor);
    return rgb ? `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.1)` : "";
  }};
`


export const BlockContainer = styled.div`
  border: 1px solid #eaeaef;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-bottom: 16px;
  border-radius: 4px;
`

interface BlockDivProps {
  padding?: string;
}

export const BlockDiv = styled.div<BlockDivProps>`
    padding: ${(props) => (props.padding ? props.padding : '24px')};
    /* margin-bottom: 20px; */
    /* border: 1px solid var(--gray-200, #EAECF0); */
    /* border-radius: 4px; */
    background: white;
    /* box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05); */
`;

// export const TaskFormInput = styled.div`
//     padding: ${(props:{backgroundColor?:string}) => props?.backgroundColor ? "8px" : "0px"};
//     background-color: ${(props:{backgroundColor?:string}) => props?.backgroundColor || ""};
//     border: 0px;
//     border-radius: 4px;
//     /* width: 100%; */
//     margin-top: 8px;
//     font-size: 14px;
//     font-weight: 500;
//     margin-bottom: 8px;
//     max-height: 12rem;
//     overflow-y: auto;
//     color: ${(props:{backgroundColor?:string}) => props?.backgroundColor ? "white" : "#344054"};

//     a {
//         color: #007AFF;
//     }
// `

export const TaskFormInput = styled.div`
  padding: ${(props: { backgroundColor?: string }) =>
    props?.backgroundColor ? "8px" : "0px 0px 0px 0px"};
  background-color: ${(props: { backgroundColor?: string }) =>
    props?.backgroundColor || ""};
  border: 0px;
  border-radius: 8px;
  /* width: 100%; */
  /* margin-top: 8px; */
  font-size: 12px;
  font-weight: 400;
  /* margin-bottom: 8px; */
  max-height: 12rem;
  overflow-y: auto;
  color: ${(props: { backgroundColor?: string }) =>
    props?.backgroundColor ? "white" : ""};

  a {
    color: #007aff;
  }
`

export const LinkContainer = styled.div`
  /* background-color: #FFF; */
  font-size: 12px;
  overflow-y: auto;
  width: 100%;
  color: "blue"
`

export const HelperText = styled.p`
  margin-top: 8px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
`

export const ZTextValueWrapper = styled.div`
  background-color: ${(props: { isKeyChanged?: boolean }) => {
    return props?.isKeyChanged ? "#ECF3FF" : ""
  }};
  padding: ${(props: { isKeyChanged?: boolean }) => {
    return props?.isKeyChanged ? "4px 8px" : ""
  }};
  border-radius: ${(props: { isKeyChanged?: boolean }) => {
    return props?.isKeyChanged ? "4px" : ""
  }};
  margin-left: ${(props: { isKeyChanged?: boolean }) =>
    props.isKeyChanged ? "0px" : "-7px"};
  margin-top: ${(props: { isKeyChanged?: boolean }) =>
    props.isKeyChanged ? "-4px" : "-8px"};
  padding: 4px 8px;
  // on hover
  &:hover {
    background: #ededed;
    /* padding: 4px 8px; */
    border-radius: 4px;
  }
  color: ${(props: { isKeyChanged?: boolean }) => {
    return props?.isKeyChanged ? "#007aff" : ""
  }};
  border: ${(props: { isKeyChanged?: boolean }) => {
    return props?.isKeyChanged ? "1px solid #007aff" : ""
  }
  };
`

// export const TaskFormLabelForShow = styled.div`
//     font-weight: 500;
//     font-size: ${(props:{fontSize?:number, color?: string }) => `${props?.fontSize}px` || "12px"};
//     color: ${(props:{fontSize?:number, color?: string }) => !props ? "#667085" : props?.color !== "#E74C3C" ? props.color : "#667085" };
// `

export const TaskFormLabelForShow = styled.div`
  font-size: ${(props) => props.fontSize ? `12px` : "12px"};
  color: ${(props) => props.color && props.color !== "#E74C3C" ? props.color : "#667085"};
  margin-bottom: 4px;
`;


export const TaskFormLabelForCreateAndEdit = styled.div`
  padding: 0px 0px 4px 0px !important;
  font-weight: 400 !important;
  font-size: ${(props: { fontSize?: number; color?: string }) =>
    `12px` || "12px"};
  color: ${(props: { fontSize?: number; color?: string }) =>
    !props?.color
      ? "#667085"
      : props?.color !== "#E74C3C"
      ? props.color
      : "#667085"};
`

export const TaskFormBody = styled.div`
  /* padding: 2rem 20rem; */
  /* padding: 10px; */
  @media ${device.tablet} {
    padding: 10px;
  }
`

export const SelectComponentCustomStyles = {
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    padding: "0px 8px",
  }),
  clearIndicator: (provided: any, state: any) => ({
    ...provided,
    padding: "1px",
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    padding: "1px 4px",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: "32px",
  }),
}

export const SingleTableCell = styled(TableCell)`
  background-color: ${(props: { headerCell?: boolean }) =>
    props.headerCell ? "#f0f2f3" : ""};

  p {
    font-size: ${(props: { headerCell?: boolean }) =>
    props.headerCell ? "12px" : "14px"};
    font-weight: ${(props: { headerCell?: boolean }) =>
    props.headerCell ? "500" : "400"};
    color: #475467;
  }
`

export const ZActionButton = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${(props: any) => props.disabled ? '#98A2B3' : props.theme.color.primary};
  cursor: pointer;
`

export const ZTextWrapper = styled.div`
  /* display: grid; */
  grid-template-columns: 40% 60%;

  @media ${device.mobileL} {
    grid-template-columns: 100%;
  }
`

export const ShowTaskActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 10px 32px;
  justify-content: space-between;

  @media ${device.mobileL} {
    padding: 10px;
  }
`
