import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import notification from 'notifications/notifications';
import { TaskFlowService } from 'providers/data/services/TaskFlowService';
import { ERROR_FETCHING_WT, ERROR_PUBLISHING_WF_TMP, ERROR_PUBLISHING_TMP_WF } from '../constants/WTConstants';

const initialState = {
  workflowTemplateHome: {},
  workflowTemplateById: {},
  isPublishingTemplate: false,
  publishedTemplate: {},
  isPublishingWorkflow: false,
  publishedWorkflow: '',
  publishedWATStatus: '',
  publishedWATMessage: '',
  selectedTemplateLHS: '',
  workflowTemplateCheck: null,
};

export const fetchWorkflowTemplates = createAsyncThunk('builder/fetchWorkflowTemplates', async () => {
  return await TaskFlowService.getWorkflowTemplates();
});

export const publishWorkflowAsTemplate = createAsyncThunk('builder/publishWorkflowAsTemplate', async (data: any) => {
  const { taskTypeId: workflowTemplateId, body } = data;
  return await TaskFlowService.publishWorkflowAsTemplate(workflowTemplateId, body);
});

export const publishTemplateAsWorkflow = createAsyncThunk(
  'builder/publishTemplateAsWorkflow',
  async (templateId: string) => {
    return await TaskFlowService.publishTemplateAsWorkflow(templateId);
  }
);

export const checkWorkflowTemplate = createAsyncThunk('builder/checkWorkflowTemplate', async (workflowId: string) => {
  return await TaskFlowService.checkWorkflowTemplate(workflowId);
});

export const workflowTemplateSlice = createSlice({
  name: 'workflowTemplate',
  initialState,
  reducers: {
    setWorkflowTemplateById: (state, action) => {
      state.workflowTemplateById = action.payload;
    },
    clearPublishedWorkflowData: (state) => {
      state.publishedWorkflow = '';
    },
    resetWATData: (state) => {
      state.publishedWATStatus = '';
      state.publishedWATMessage = '';
    },
    setSelectedTemplateLHS: (state, action) => {
      state.selectedTemplateLHS = action.payload;
    },
  },
  extraReducers: {
    // @ts-ignore
    [fetchWorkflowTemplates?.pending]: (state: any) => {
      state.isFetching = true;
    },
    // @ts-ignore
    [fetchWorkflowTemplates?.fulfilled]: (state: any, action: any) => {
      state.isFetching = false;
      state.workflowTemplateHome = action?.payload?.data;
    },
    // @ts-ignore
    [fetchWorkflowTemplates?.rejected]: (state: any, action: any) => {
      state.isFetching = false;
      if (action?.payload?.code?.indexOf('200') < 0) {
        notification('error', action?.payload?.message || ERROR_FETCHING_WT);
      }
    },
    // @ts-ignore
    [publishWorkflowAsTemplate?.pending]: (state: any) => {
      state.isPublishingTemplate = true;
    },
    // @ts-ignore
    [publishWorkflowAsTemplate?.fulfilled]: (state: any, action: any) => {
      state.isPublishingTemplate = false;
      state.publishedWATStatus = action?.payload?.code;
      state.publishedWATMessage = action?.payload?.message;
      state.publishedTemplate = action?.payload?.data;
      state.workflowTemplateCheck = {};
      if (action?.payload?.code?.match(/200|201/)) {
        notification('success', 'Template published successfully');
      }
    },
    // @ts-ignore
    [publishWorkflowAsTemplate?.rejected]: (state: any, action: any) => {
      state.isPublishingTemplate = false;
      if (action?.payload?.code?.indexOf('200') < 0) {
        notification('error', action?.payload?.message || ERROR_PUBLISHING_WF_TMP);
      }
    },
    // @ts-ignore
    [publishTemplateAsWorkflow?.pending]: (state: any) => {
      state.isPublishingWorkflow = true;
    },
    // @ts-ignore
    [publishTemplateAsWorkflow?.fulfilled]: (state: any, action: any) => {
      state.isPublishingWorkflow = false;
      state.publishedWorkflow = action?.payload?.data;
    },
    // @ts-ignore
    [publishTemplateAsWorkflow?.rejected]: (state: any, action: any) => {
      state.isPublishingWorkflow = false;
      if (action?.payload?.code?.indexOf('200') < 0) {
        notification('error', action?.payload?.message || ERROR_PUBLISHING_TMP_WF);
      }
    },
    // @ts-ignore
    [checkWorkflowTemplate?.pending]: (state: any) => {
      state.isCheckingWorkflowTemplate = true;
    },
    // @ts-ignore
    [checkWorkflowTemplate?.fulfilled]: (state: any, action: any) => {
      state.isCheckingWorkflowTemplate = false;
      state.workflowTemplateCheck = action?.payload?.data;
    },
    // @ts-ignore
    [checkWorkflowTemplate?.rejected]: (state: any, action: any) => {
      state.isCheckingWorkflowTemplate = false;
      if (action?.payload?.code?.indexOf('200') < 0) {
        notification('error', action?.payload?.message || ERROR_FETCHING_WT);
      }
    },
  },
});

export const { clearPublishedWorkflowData, resetWATData, setSelectedTemplateLHS } = workflowTemplateSlice.actions;

export default workflowTemplateSlice.reducer;
