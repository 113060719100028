import { Dispatch } from "react";
import { IAutomation } from "views/automationBuilder/types/ABType";
import { addAutomationInSmartComponent } from "views/workflows/reducers/workflowAction";

export const saveOnLoadAutomation = ({ allAutomations, dispatch, stateId,callback }: { allAutomations: IAutomation[], dispatch: Dispatch<any>, stateId: string, callback: () => void }) => {
    const eventDetails: any = {
        key: "onload_automation_details",
        stateId: stateId,
        data: allAutomations
    };

    if(callback) {
        callback()
    }

    // @ts-ignore
    document.getElementById('app-builder')?.contentWindow?.postMessage(eventDetails, '*');
}  