//@ts-nocheck
import bolt from 'assests/bolt.svg';
import { useTheme } from 'styled-components';
import { ExpressionWrapper } from 'views/commonComponents/commonComponents.styles';

type Props = {
  handleClick: Function;
  conditionLabel: string;
  showExpressionActive: boolean;
}

const ExecuteCondition = ({ handleClick, conditionLabel, showExpressionActive }: Props) => {
  const theme = useTheme()
  return (

    <ExpressionWrapper style={{marginTop:'10px'}} onClick={() => {
      handleClick();
    }}>
      <div className="">

        <div style={{ justifyContent: 'center', display: 'flex' }}>
          {conditionLabel}
        </div>
        {showExpressionActive &&
          <div style={{
            justifyContent: 'center', display: 'flex', backgroundColor: '#f6f7fe', border: `1px solid ${theme.color.primary}`,
            marginTop: '10px',
            borderRadius: '4px',
            padding: '0.5rem 1.6rem'
          }}>
            <img style={{ marginRight: '15px' }} src={bolt} alt='fnIcon' />
            There is an Expression Active
          </div>
        }
      </div>
    </ExpressionWrapper>
  )
}

export default ExecuteCondition