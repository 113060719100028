import React, { useState, useRef, useEffect } from "react";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import { IButtonActionType, TableRSEvents } from "../types";
import notification from "notifications/notifications";
import { recordRSEvent } from "utils/CommonUtils";
import useEntity from "../hooks/useEntity";
import { EntityService } from "providers/data/services/EntityService";
import { InfoTooltip as Tooltip } from "views/workflows/Tootltips/InfoTooltip";

type props = {
  params: any;
  col: any;
  refresh: any;
  setRefresh: any;
  fromShowPage?: boolean;
  tableType?: string;
};

const isTextOverflowing = (element: HTMLElement | null) => {
  return element ? element.scrollWidth > element.clientWidth : false;
};

function ZTableButton({
  params,
  col,
  refresh,
  setRefresh,
  fromShowPage,
  tableType,
}: props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { selectedEntity } = useEntity();
  const textRef = useRef<HTMLSpanElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      setIsOverflowing(isTextOverflowing(textRef.current));
    }
  }, [col]);

  const executeButtonClick = async (data: any) => {
    setIsLoading(true);
    try {
      recordRSEvent(TableRSEvents.TABLE_AUTOMATION_BUTTON_CLICKED, {
        ...data,
      });
      await EntityService.executeEntityColumnAutomation(data);
      setIsLoading(false);
      notification(
        "success",
        "Action related to button has been executed successfully!"
      );
      recordRSEvent(TableRSEvents.ROW_LEVEL_AUTOMATION_BUTTON_SUCCESS, {
        ...data,
      });
      setRefresh(!refresh);
    } catch (error) {
      notification(
        "error",
        error?.response?.data?.message || "Something went wrong"
      );
      recordRSEvent(TableRSEvents.ROW_LEVEL_AUTOMATION_BUTTON_FAILURE, {
        ...data,
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      {" "}
      {
        <div
          style={{
            display: "flex",
            gap: "8px",
            padding: "0px 12px",
            width: "100%",
          }}
        >
        <Tooltip title={isOverflowing ? col.colDef?.buttonLabel : ""} placement="right">
          <ZButton
            ref={textRef}
            fullWidth
            disabled={isLoading}
            secondary={col.colDef?.button?.value.includes("secondary")}
            primary={col.colDef?.button?.value.includes("primary")}
            style={{
              padding: "0px 6px",
              fontSize: "12px",
              display: "inline-block",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
            variant={
              col.colDef?.properties?.type.includes("Outlined")
                ? "contained"
                : "contained"
            }
            onClick={(e) => {
              if (
                params.column.columnDef.data?.action?.value ===
                IButtonActionType.OPEN_URL
              ) {
                if (params?.cell?.renderValue()) {
                  window.open(params?.cell?.renderValue(), "_blank");
                } else {
                  e.stopPropagation();
                  notification("error", "URL is not defined for this button");
                }
              } else {
                e.stopPropagation();
                if (fromShowPage) {
                  if (tableType) {
                    executeButtonClick({
                      recordId: params.row.original.recordId,
                      tableType: tableType,
                      buttonId: col.colDef?.name,
                    });
                  } else {
                    notification(
                      "error",
                      "Table type is not defined for this button"
                    );
                  }
                } else if (selectedEntity)
                  executeButtonClick({
                    recordId: params.row.original.recordId,
                    tableType: selectedEntity.tableType,
                    buttonId: col.colDef?.name,
                  });
              }
            }}
          >
            <span>
              {isLoading ? "Loading" : col.colDef?.buttonLabel || "Click Me"}
            </span>
          </ZButton>
      </Tooltip>
        </div>
      }{" "}
    </>
  );
}

export default ZTableButton;
