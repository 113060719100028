import React, { FunctionComponent, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import { Button, ButtonGroup, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextValidator, ValidatorForm, SelectValidator } from "react-material-ui-form-validator";
import notification from "../../notifications/notifications";
import { ComponentType } from "../ComponentTypeEnum";
import { ProductCategory } from "./ProductCategoryEnum";
import { IZInputInterface } from "../input/IZInputIInterface";
import { IZChoiceInterface } from "../choice/IZChoiceInterface";
import { ZChoice } from "../choice/ZChoice";
import { getComponentParams } from "../../render-engine/RenderUtils";
import { ZInput } from "../input/ZInput";
import { useDispatch } from "react-redux";
import { IZProductInterface } from "./IZProductInterface";
import { useForm } from "react-hook-form";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 450,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  addButton: {
    margin: "10px 10px 10px 10px",
    width: "70px",
    float: "right",
  },
}));

export const EditProductModal: FunctionComponent<{ product: IZProductInterface, onEdit: (p: any) => void, onClose: () => void }> = ({ product, onEdit, onClose }) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [productCategory, setProductCategory] = React.useState(product?.productCategory);
  const [qty, setQty] = React.useState(product?.qty);
  const [sku, setSku] = React.useState(product?.sku);
  const [productId, setProductId] = React.useState(product?.productId);
  const [productName, setProductName] = React.useState(product?.productName);
  const [productImageUrl, setProductImageUrl] = React.useState(product?.productImageUrl);

  const closeModal = () => {
    onClose();
    setOpen(false);
  };

  const dispatch = useDispatch();
  const editProductForm = useForm({ mode: "onChange" });

  
  const editProduct = async (e: any) => {
    e.preventDefault();
    e.preventDefault();
    e.stopPropagation();
    await editProductForm.trigger();
    if (Object.keys(editProductForm.formState.errors).length > 0)
      return;

    const editedProduct: any = {
      productCategory: productCategory,
      sku: sku,
      productId: productId,
      productName: productName,
      qty: qty,
      productImageUrl: productImageUrl,
    };
    if (productCategory === "SERIALISED")
      delete editedProduct.qty;
    else if (productCategory === "NON_SERIALISED") {
      editedProduct.qty = parseInt(editedProduct.qty);
      delete editedProduct.productId;
    }
    onEdit(editedProduct);
    setOpen(false);
    onClose();
  };

  useEffect(() => {
    setOpen(true);
  }, []);

  const productCategoryConfig: IZChoiceInterface = {
    nodeId: `node_productId`,
    key: 'productId',
    type: ComponentType.CHOICE,
    shape: "DROPDOWN",
    value: product.productCategory,
    label: {
      color: "#E74C3C",
      fontSize: 14,
      isVisible: true,
      val: "Category"
    },
    required: true,
    options: [
      { "label": ProductCategory.SERIALISED, "value": ProductCategory.SERIALISED },
      { "label": ProductCategory.NON_SERIALISED, "value": ProductCategory.NON_SERIALISED }
    ],
    onValidate: () => { },
    onSave: () => { },
  }

  const skuConfig: IZInputInterface = {
    nodeId: `node_sku`,
    key: 'sku',
    type: ComponentType.INPUT,
    shape: "TEXT",
    value: product.sku,
    label: {
      color: "#E74C3C",
      fontSize: 14,
      isVisible: true,
      val: "SKU"
    },
    isRequired: false,
    onValidate: () => { },
    onSave: () => { },
  }

  const productIdConfig: IZInputInterface = {
    nodeId: `node_productId`,
    key: 'productId',
    type: ComponentType.INPUT,
    shape: "TEXT",
    value: product.productId,
    label: {
      color: "#E74C3C",
      fontSize: 14,
      isVisible: true,
      val: "Product Id"
    },
    isVisible: () => { return productCategory === ProductCategory.SERIALISED },
    isRequired: productCategory === ProductCategory.SERIALISED,
    onValidate: () => { },
    onSave: () => { },
  }

  const productNameConfig: IZInputInterface = {
    nodeId: `node_productName`,
    key: 'productName',
    type: ComponentType.INPUT,
    shape: "TEXT",
    value: product.productName,
    label: {
      color: "#E74C3C",
      fontSize: 14,
      isVisible: true,
      val: "Product Name"
    },
    isRequired: false,
    onValidate: () => { },
    onSave: () => { },
  }

  const productQtyConfig: IZInputInterface = {
    nodeId: `node_productQty`,
    key: 'qty',
    type: ComponentType.INPUT,
    shape: "NUMBER",
    value: product.qty,
    label: {
      color: "#E74C3C",
      fontSize: 14,
      isVisible: true,
      val: "Qty"
    },
    isVisible: () => { return productCategory === ProductCategory.NON_SERIALISED },
    isRequired: productCategory === ProductCategory.NON_SERIALISED,
    onValidate: () => { },
    onSave: () => { },
  }

  const productImageUrlConfig: IZInputInterface = {
    nodeId: `node_productImage`,
    key: 'productImageUrl',
    type: ComponentType.INPUT,
    shape: "TEXT",
    value: product.productImageUrl,
    label: {
      color: "#E74C3C",
      fontSize: 14,
      isVisible: true,
      val: "Image"
    },
    isRequired: false,
    onValidate: () => { },
    onSave: () => { },
  }


  const changeHandler = async (setFn: Function, value: any) => {
    setFn(value);
    // await addProductForm.trigger();
  }

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={closeModal}
    >
      <div style={modalStyle} className={classes.paper}>
        <h3>Edit Product</h3>
        <ZChoice
          config={productCategoryConfig}
          {...getComponentParams(dispatch, editProductForm, (k: string, v: any) => changeHandler(setProductCategory, v))}
        />
        <ZInput config={skuConfig}
          {...getComponentParams(dispatch, editProductForm, (k: string, v: any) => changeHandler(setSku, v))}></ZInput>
        <ZInput config={productIdConfig}
          {...getComponentParams(dispatch, editProductForm, (k: string, v: any) => changeHandler(setProductId, v))}></ZInput>
        <ZInput config={productNameConfig}
          {...getComponentParams(dispatch, editProductForm, (k: string, v: any) => changeHandler(setProductName, v))}></ZInput>
        <ZInput config={productQtyConfig}
          {...getComponentParams(dispatch, editProductForm, (k: string, v: any) => changeHandler(setQty, v))}></ZInput>
        <ZInput config={productImageUrlConfig}
          {...getComponentParams(dispatch, editProductForm, (k: string, v: any) => changeHandler(setProductImageUrl, v))}></ZInput>
        <ButtonGroup size="small" variant="outlined">
          <Button
            onClick={(e) => editProduct(e)}
            style={{
              height: "40px",
              marginRight: "10px",
              width: "90px",
              marginTop: "10px",
            }}
            variant="contained"
            color="primary"
          >
            Edit
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            variant="contained"
            onClick={closeModal}
            style={{ height: "40px", width: "90px", marginTop: "10px" }}
            color="secondary"
          >
            Cancel
          </Button>
        </ButtonGroup>
      </div>
    </Modal>
  );
}
