import Box from '@mui/material/Box';
import { RudderStackAutomationBuilderEvents } from 'constants/RudderStackConstants';
import React, { useState } from 'react';
import styled from "styled-components";
import { recordRSEvent } from 'utils/CommonUtils';
import ConfirmationDialog from 'views/automationBuilder/components/ConfirmationDialog';
import { ZButton } from 'views/commonComponents/commonComponents.styles';
import useMonitor from '../reducer/useMonitor';

const ConfigureMonitorTxt = styled.p`
  color: black;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
`;

const ConfigureMonitorDesc = styled.p`
  color: black;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

interface IMonitorInterfacePageHeader {
    discardMonitor: () => void;
}

const MonitorInterfacePageHeader = ({ discardMonitor }: IMonitorInterfacePageHeader) => {
    const {clearMonitorState} = useMonitor();
    const [monitorDiscardConfirmation, setMonitorDiscardConfirmation] = useState<boolean>(false);
    return (
        <>
            <Box
                display={'flex'}
                justifyContent={'space-between'}
                height={'fit-content'}
                width={'100%'}
                alignItems={'center'}>

                <Box>
                    <ConfigureMonitorTxt>Setup your Alerting rules</ConfigureMonitorTxt>
                    <ConfigureMonitorDesc>Define what to monitor, how to be notified, and the resolution steps to be followed</ConfigureMonitorDesc>
                </Box>

                <Box
                    gap={'12px'}
                    display={'flex'}>

                    <ZButton
                        onClick={() => {
                            recordRSEvent(RudderStackAutomationBuilderEvents.discardChanges, {
                                context: RudderStackAutomationBuilderEvents.monitorContext
                              });
                            setMonitorDiscardConfirmation(true);
                        }}
                        style={{ borderRadius: '4px', 
                        height: '40px', 
                        color: '#B42318', 
                        fontSize: '12px',
                        fontWeight: 'normal',
                        border: '1px solid var(--Gray-300, #FEF3F2)', 
                        backgroundColor: '#FEF3F2'
                     }}
                        sx={{ height: '40px' }} secondary variant="contained">Discard & Exit</ZButton>
                </Box>
            </Box>
            <ConfirmationDialog
                id={'monitor_discard_modal'}
                deletionTitle={'Are you sure you want to discard this monitor?'}
                deletionText={`You will lose all the changes you have made to this monitor.`}
                isOpen={monitorDiscardConfirmation}
                primaryButtonName="Yes"
                variant="danger"
                secondaryButtonName="No"
                onPrimaryBtnClick={() => {
                    setMonitorDiscardConfirmation(false);
                    clearMonitorState();
                    discardMonitor();
                }}
                onSecondaryClick={() => {
                    setMonitorDiscardConfirmation(false);
                }}
                onClose={() => {
                    setMonitorDiscardConfirmation(false);
                }}
            />
        </>
    )
}

// export default MonitorInterfacePageHeader
export default React.memo(MonitorInterfacePageHeader);