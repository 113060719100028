import { useDispatch, useSelector, useStore } from "react-redux";
import { EntityTypeField } from "views/DataField/DataField.types";
import {
  resetAutomation,
  setAllErrors,
  setSelectedAutomation,
} from "views/automationBuilder/reducer/automationReducer";
import {
  ABMetadataTypeEnum,
  IAutomation,
} from "views/automationBuilder/types/ABType";
import { validateAutomation } from "views/automationBuilder/utils/automationValidation";
import useEntity from "views/entities/hooks/useEntity";
import useWorkFlow from "views/workflows/hooks/useWorkFlow";
import { setSelectedAction } from "views/workflows/reducers/workflowAction";
import {
  setActionPayload,
  setSelectedConfiguration,
  setSelectedTrigger,
} from "../reducer/automationReducer";

const useAutomation = () => {
  const dispatch = useDispatch();
  const { selectedAction } = useWorkFlow();
  const store = useStore();
  const {activeEditColumn, showTableAppConfigCard} = useEntity();

  const automation = useSelector((state: any) => state.automation);
  const trigger = useSelector((state: any) => state.automation.trigger);
  const selectedWebhook = useSelector((state: any) => state.automation.selectedWebhook);
  const guards = useSelector((state: any) => state.automation.guards);
  const actions = useSelector((state: any) => state.automation.actions);
  const conditions = useSelector((state: any) => state.automation.conditions);
  const metaDataType = useSelector( 
    (state: any) => state.automation.metaDataType
  );
  const selectedTrigger = useSelector(
    (state: any) => state.automation.selectedTrigger
  );
  const allAutomations = useSelector(
    (state: any) => state.automation.allAutomations
  );
  const selectedAutomation = useSelector(
    (state: any) => state.automation.selectedAutomation
  );
  const selectedConfiguration = useSelector(
    (state: any) => state.automation.selectedConfiguration
  );
  const selectedActionBlock = useSelector(
    (state: any) => state.automation.selectedActionBlock
  );
  const metadataType = useSelector(
    (state: any) => state.automation.metadataType
  );
  const prevAutomations = useSelector(
    (state: any) => state.automation.prevAutomations
  );
  const otherConfigs = useSelector(
    (state: any) => state.automation.otherConfigs
  );
  const saveAutomationHandler = useSelector(
    (state: any) => state.automation.saveAutomationHandler
  );
  const errors = useSelector((state: any) => state.automation.errors);
  const selectedMonitorId = useSelector((state:any)=>state.automation.selectedMonitorId);
  const actionDropdown = useSelector(
    (state: any) => state.automation.actionDropdown
  );
  const createAutomationDefault = useSelector(
    (state: any) => state.automation.createAutomationDefault
  );
  const isTaskCreateScreenAutomation = useSelector(
    (state: any) => state.automation.isTaskCreateScreenAutomation
  );
  const isTaskHistoryScreenAutomation = useSelector(
    (state: any) => state.automation.isTaskHistoryScreenAutomation
  );
  const selectedCron = useSelector(
    (state: any) => state.automation.selectedCron
  );
  //  Teams List and users List
  const getUpdateTeamActionData = actions.find(
    (actionData: any) => actionData.actionType == "TASK_UPDATE_TEAM_ACTION"
  )?.options;
  const teamsList: any[] = getUpdateTeamActionData?.teams;
  const usersList: any[] = getUpdateTeamActionData?.users;

  // clears the right side (trigger, guard, condition, action) in the AB
  const clearRightSide = () => {
    dispatch(setSelectedTrigger(null));
    dispatch(setSelectedAction(null));
    dispatch(setSelectedConfiguration({ type: null }));
    dispatch(setActionPayload([]));
  };


  const handleABValidation = () => {
    const { updatedAutomations, allAutomationErrors } = validateAutomation(store);
    dispatch(resetAutomation(updatedAutomations));
    dispatch(setAllErrors(allAutomationErrors));

    const updatedSelectedAutomation = updatedAutomations.find(
      (automation: IAutomation) => automation.id === selectedAutomation?.id
    );
    dispatch(setSelectedAutomation(updatedSelectedAutomation));
  };
  // this will return boolean based on anything opened in right side or not.
  const checkConfigureOpenInRight = !!selectedAction || !!selectedTrigger;

  // this will return true, if metadata type is Smart Component or onLoad
  const isSmartAutomation =
    metadataType === ABMetadataTypeEnum.WORKFLOW_SCREEN_SMART_COMPONENT ||
    metadataType === ABMetadataTypeEnum.WORKFLOW_SCREEN_ONLOAD;

  const isEntityAutomation = (metadataType === ABMetadataTypeEnum.ENTITY_AUTOMATION || 
    metadataType === ABMetadataTypeEnum.BUTTON_AUTOMATION || activeEditColumn?.type === EntityTypeField.COMPUTED || activeEditColumn?.type === EntityTypeField.BUTTON || showTableAppConfigCard
    )

  const isTeamAutomation = metadataType === ABMetadataTypeEnum.TEAM;

  const isUserAutomation = metadataType === ABMetadataTypeEnum.USER;
  
  const isCronAutomation = metadataType === ABMetadataTypeEnum.CRON;

  const isWebHookAutomation = metadataType == ABMetadataTypeEnum.WEBHOOK;

  const isMonitorAutomation = metadataType === ABMetadataTypeEnum.MONITOR;

  // don't show error messages field in all decision node switches

  const decisionSwitchAutomation =
    metadataType ===
      ABMetadataTypeEnum.WORKFLOW_DECISION_NODE_TRANSITION_IF_SWITCH ||
    metadataType ===
      ABMetadataTypeEnum.WORKFLOW_DECISION_NODE_TRANSITION_ELSE_SWITCH;

    
  const isTransitionAutomation = (metadataType === ABMetadataTypeEnum.WORKFLOW_SYSTEM_TRANSITION || metadataType === ABMetadataTypeEnum.WORKFLOW_SCREEN_TRANSITION);

    
  const isWorkFlowAutomation = isSmartAutomation || decisionSwitchAutomation || isTransitionAutomation;

    

  return {
    clearRightSide,
    handleABValidation,
    checkConfigureOpenInRight,
    isOpen: automation.isOpen,
    trigger,
    guards,
    actions,
    teamsList,
    usersList,
    conditions,
    metaDataType,
    selectedTrigger,
    otherConfigs,
    isSmartAutomation,
    decisionSwitchAutomation,
    allAutomations,
    selectedAutomation,
    selectedConfiguration,
    selectedActionBlock,
    metadataType,
    prevAutomations,
    saveAutomationHandler,
    isTaskCreateScreenAutomation,
    isTaskHistoryScreenAutomation,
    selectedCron,
    errors,
    actionDropdown,
    createAutomationDefault,
    isEntityAutomation,
    isTeamAutomation,
    isUserAutomation,
    isWebHookAutomation,
    isCronAutomation,
    isMonitorAutomation,
    selectedMonitorId,
    selectedWebhook,
    isWorkFlowAutomation
  };
};

export default useAutomation;
