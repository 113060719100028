import axios, { AxiosResponse } from "axios";
import HttpUtil from "config/AxiosConfig";
import { ZorpResponse } from "providers/data/models/ZorpResponse";
import { IUpdateSubfieldsArgs } from "components/listDatafield/IZListInterface";
import { IUrl } from "views/workflows/workflowactions/api/APIAction/ApiActionTestUtils";
import { getConfig as Config } from '../../../config/envConfig/Config';

export type IScriptEditorInclusion = {
  type: string;
  subType?: string;
  config?: IScriptEditorApiConfig | any;
}

type IScriptEditorApiConfig = {
  transitionId: string;
  taskType: string;
  actionId: string;
}

export enum IFilterContext {
  TASK='TASK',
  USER='USER',
  TEAM='TEAM',
  ROLE='ROLE',
  WORKFLOW='WORKFLOW'
}

export type IGuestTokenPayload = {
  dashBoardId: string;
}

export interface IFilterPayload {
  context:IFilterContext
}

export type IScriptEditorVariableApi = {
  inclusions: Array<IScriptEditorInclusion | string>,
  exclusions: Array<string>;
}

export type IScriptEditorVariableApiPayload = {
  payload: IScriptEditorVariableApi
}

export class BffService {
  public static getTeamDescendants(): Promise<ZorpResponse | undefined> {
    return HttpUtil.bffInstance
      .get("/team/descendants")
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }

  public static updateTask(
    taskUpdatePayload: any
  ): Promise<ZorpResponse | undefined> {
    return HttpUtil.bffInstance
      .put(`/task`, taskUpdatePayload)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }

  public static getUserSearchResult(
    payload: any
  ): Promise<ZorpResponse | undefined> {
    return HttpUtil.bffInstance
      .post(`/users-teams-roles`, payload)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }


  public static taskTransition(
    taskEditPayload: any
  ): Promise<ZorpResponse | undefined> {
    return HttpUtil.bffInstance
      .post(`/task/${taskEditPayload?.taskId}/transit`, taskEditPayload)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }

  public static getActionsConfig(): Promise<ZorpResponse | undefined> {
    return HttpUtil.bffInstance
      .get("/actions/config")
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }

  public static getScriptVariables(
    taskId: string
  ): Promise<ZorpResponse | undefined> {
    return HttpUtil.bffInstance
      .get(`/tasktype/${taskId}/scriptvariables`)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }

  public static getFilterMeta(payload: any): Promise<ZorpResponse | undefined> {
    return HttpUtil.bffInstance
      .post(`/filter`, payload)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }

  public static getScriptVariableWithResponse(taskId: string, payload: IScriptEditorVariableApiPayload): Promise<ZorpResponse | undefined> {
    return HttpUtil.bffInstance
      .post(`/tasktype/${taskId}/scriptvariables`, payload)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }


  public static initateMonitor(accountId: string): Promise<ZorpResponse | undefined> {
    return HttpUtil.bffInstance
      .post(`/initiate-monitor`,{accountId})
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }


  public static updateSubfields = ({
    taskId,
    type,
    index,
    listKey,
    modifyMetadata,
    item,
    listData
  }: IUpdateSubfieldsArgs): Promise<ZorpResponse | undefined> => {
    return HttpUtil.bffInstance
      .post(`/web/task/guardsactions/execute`, {
        guards: null,
        actions: [
          {
            id: "action1",
            name: "LIST_RELOAD",
            type: "LIST_RELOAD",
            condition: {
              expression: {
                type: "script",
                dataType: "BOOLEAN",
                value: "return true",
              },
              message: "Unable to execute",
              exitOnError: true,
            },
            parameters: {
              listData,
              type,
              index,
              listKey,
              modifyMetadata,
              item,
            },
          },
        ],
        taskId

      })
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  };

  public static testApi = (url: IUrl, headers: any, method: string, body: any, mockData: any): Promise<ZorpResponse | undefined> => {
    return HttpUtil.bffInstance.post("/web/api/test", {
      url,
      headers,
      method,
      body,
      mockData
    }).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    }).catch((error) => {
      return error;
    })
  }


  public static getEmbedItem = (): Promise<ZorpResponse | undefined> => {
    return HttpUtil.bffInstance.get(`account/embed/items`).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    }).catch((error) => {
      return undefined
    })
  }

  public static getBiGuestToken = (guestTokenPayload: any): Promise<ZorpResponse | undefined> => {
    return HttpUtil.bffInstance.post(Config("services.bffSvc.api.getBiGuestToken"), guestTokenPayload).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    }).catch((error) => {
      return undefined
    })
  }

  public static getFirbaseDynamicLink = (url: string): Promise<ZorpResponse | undefined> => {
    return axios.post("https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDr7mSEuftyfrwP-uo3jWpDar-yetTOGpY", {
      longDynamicLink: url
    })
  }

  public static getStripeCustomerIdFromTogai = (togaiCustomerId: string): Promise<ZorpResponse | undefined> => {
    return HttpUtil.bffInstance.get(`/togai/${togaiCustomerId}/stripe`).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    }).catch((error) => {
      return undefined
    })
  }

  public static setupIntent(customerId: string): Promise<ZorpResponse | undefined> {
    return HttpUtil.bffInstance
      .post(`/stripe/${customerId}/intent/setup`, {})
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }

  public static getPaymentMethods(customerId: string): Promise<ZorpResponse | undefined> {
    return HttpUtil.bffInstance
      .get(`/stripe/${customerId}/paymentmethod`)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }

  public static detachPaymentMethod(paymentMethodId: string): Promise<ZorpResponse | undefined> {
    return HttpUtil.bffInstance
      .post(`/stripe/${paymentMethodId}/detachpaymentmethod`, {})
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }

  public static getHomeScreenConfig(): Promise<ZorpResponse | undefined> {
    return HttpUtil.bffInstance
      .get(`/web/homescreen/config`)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
    }
  // fetch slack users list
  public static fetchSlackUsers = (): Promise<ZorpResponse | undefined> => {
    const fetchSlackUsersUrl = Config("services.bffSvc.api.getSlackUsers");
    return HttpUtil.bffInstance.get(fetchSlackUsersUrl).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    }).catch((error) => {
      return undefined
    })
  }

  public static testTeamUserAutomation(testAutomationPayload: any): Promise<ZorpResponse | undefined> {
    const testTeamUserAutomationUrl = Config("services.bffSvc.api.testTeamUserAutomation");
    return HttpUtil.bffInstance.post(testTeamUserAutomationUrl, testAutomationPayload).then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  } 


  public static testAutomation = (payload: any): Promise<ZorpResponse | undefined> => {
    return HttpUtil.bffInstance.post("/automations/actions/test", payload).then((response: AxiosResponse) => {

        return response.data as ZorpResponse;
    }).catch((error) => {
      return undefined
    })
  }
 
  // fetch slack channels list
  public static fetchSlackChannels = (): Promise<ZorpResponse | undefined> => {
    const fetchSlackChannelsUrl = Config("services.bffSvc.api.getSlackChannels");
    return HttpUtil.bffInstance.get(fetchSlackChannelsUrl).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    }).catch((error) => {
      return undefined
    })
  }

  // fetch all webhook api 
  public static fetchAllWebhooks = (): Promise<ZorpResponse | undefined> => {
    const fetchAllWebhookUrl = Config("services.bffSvc.api.getAllWebhook");
    return HttpUtil.bffInstance.get(fetchAllWebhookUrl).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    }).catch((error) => {
      return undefined
    })
  }

  // fetch single webhook api
  public static fetchWebhook = (webhookId:string): Promise<ZorpResponse | undefined> => {
    const fetchWebhookUrl = (Config("services.bffSvc.api.getWebhook") as string).replace('{webhookId}', webhookId);
    return HttpUtil.bffInstance.get(fetchWebhookUrl).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    }).catch((error) => {
      return undefined
    })
  }

  // create webhook api
  public static createWebhook = (webhookPayload:any): Promise<ZorpResponse | undefined> => {
    const createWebhookUrl = Config("services.bffSvc.api.createWebhook");
    return HttpUtil.bffInstance.post(createWebhookUrl, webhookPayload).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    }).catch((error) => {
      return undefined
    })
  }

  // update webhook api
  public static updateWebhook = (webhookId:string, webhookPayload:any): Promise<ZorpResponse | undefined> => {
    const createWebhookUrl = (Config("services.bffSvc.api.updateWebhook") as string).replace('{webhookId}', webhookId);
    return HttpUtil.bffInstance.put(createWebhookUrl, webhookPayload).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    }).catch((error) => {
      return undefined
    })
  }

}
