import React, { useEffect, useState, useRef } from 'react'
import { IoClose as CloseIcon } from "react-icons/io5";
import SearchInputContainer from '../permissions/components/SearchInputContainer';
import { EntityReference, OptionType, readWritePermissions } from '../permissions/types';
import { RiDeleteBinLine as DeleteIcon } from "react-icons/ri";
import { EntityService } from 'providers/data/services/EntityService';
import useEntity from '../hooks/useEntity';
import notification from 'notifications/notifications';
import { useHistory } from 'react-router-dom';
import { getCreateViewPayload } from 'utils/CommonUtils';
import styled from 'styled-components';
import { LuCopy as CopyIcon } from "react-icons/lu";
import LocalStorageUtil from 'utils/LocalStorageUtil';
import { useDispatch } from "react-redux";
import { getViewsObj } from 'utils/CommonUtils';
import { recordRSEvent } from 'utils/CommonUtils';
import { ViewsRSEvents } from '../types';
import {
    CurlModal,
} from "views/workflows/workflowactions/api/APIAction/ImportCurl/CurlStyledComponents";
import DeletionConfirmationDialog from 'utils/DeletionConfirmationDialog';
import { setSavedViewChanges } from '../reducers/entityReducers';

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    cursor: default;
    justify-content: center;
    align-items: center;
    z-index: 999;
`;

const ModalContainer = styled.div`
    background-color: white;
    padding: 24px;
    border-radius: 4px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: flex-start;
    width: 100%;
    z-index: 1001;
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
`

const Heading = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
`

const Text = styled.p<{ $color: string; $fontSize: string; $fontWeight: string }>`
    color: ${(props) => props.$color};
    font-size: ${(props) => props.$fontSize};
    font-weight: ${(props) => props.$fontWeight};
`

const InputContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: flex-start;
`

const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    font-weight: 600;
    gap: 10px;
    align-items: center;
`

const ButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    margin-top: 32px;
    justify-content: space-between;
    align-items: center;
`

const SaveButton = styled.button<{ $active?: boolean; $color: string; }>`
    color: ${props => props.$active ? props.$color : "#fff"};
    background: ${props => props.$active ? props.theme.color.primary : "grey"};
    border: 1px solid ${props => props.$active ? props.theme.color.primary : "grey"};
    cursor: ${props => props.$active ? "pointer" : "not-allowed"};
    font-size: 14px;
    font-weight: 600;
    padding: 8px 14px;
    border-radius: 4px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
`

const Button = styled.button`
    display: flex;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid var(--Gray-300, #D0D5DD);
    background: var(--Base-White, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
`

const StyledInput = styled.input`
    display: flex;
    padding: 8px 12px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    outline: none;
    border-radius: 4px;
    font-weight: 500;
    border: 1px solid var(--Gray-300, #D0D5DD);
    background: var(--Base-White, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: #101828;
    font-size: 14px;
    ::placeholder {
        color: grey;
    }
`

const Error = styled.div<{ $color: string; }>`
    color: ${props => props.$color};
    font-size: 12px;
    font-weight: 600;
    margin-top: 2px;
`

type props = {
    context: 'create' | 'update' | 'copy';
    view?: any;
    views?: [];
    onSave: Function;
    onClose: () => void;
    onDeleteView: (event:React.SyntheticEvent | null, viewId: string) => void;
    setIsLoadingTransition: (isLoading: boolean) => void;
}

function ManageTableViews({ context, view, onSave, views, onClose, onDeleteView, setIsLoadingTransition }: props) {
    const [isLoading, setIsLoading] = useState(false);
    const [viewSelectedOptions, setViewSelectedOptions] = useState<OptionType[]>([]);
    const { savedViewChanges } = useEntity();
    const [editSelectedOptions, setEditSelectedOptions] = useState<OptionType[]>([]);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [viewName, setViewName] = useState<string>(view ? view.name : '');
    const [viewDescription, setViewDescription] = useState<string>(view ? view.description : '');
    const [currentContext, setCurrentContext] = useState<"create" | "update" | "copy">(context);
    const { selectedEntityMetadata } = useEntity();
    const { selectedEntity } = useEntity();
    const history = useHistory();
    const firstInputRef = useRef<HTMLInputElement | null>(null);
    const [isMouseDownOnOverlay, setIsMouseDownOnOverlay] = useState(false);
    const [viewNameError, setViewNameError] = useState('');
    const [viewDescriptionError, setViewDescriptionError] = useState('');
    const dispatch = useDispatch();
    const validateInputs = (context?: "create" | "update" | "copy") => {
        let isValid = true;
        let filteredViews;

        if (viewName.length > 30) {
            setViewNameError('* View name must not exceed 30 characters');
            return false;
        }

        if (viewDescription.length > 200) {
            setViewDescriptionError('* View description must not exceed 200 characters');
            return false;
        }

        if (context === 'update' && view) {
            filteredViews = views?.filter((v: any) => v.viewId !== view.viewId);
        } else {
            filteredViews = views;
        }

        if (filteredViews && filteredViews.find((v: any) => v.name.trim().toLowerCase() === viewName.trim().toLowerCase())) {
            setViewNameError('* View name already exists');
            return false;
        }

        // If all checks pass, clear any existing errors
        setViewNameError('');
        setViewDescriptionError('');

        return isValid;
    };
    


    const handleOverlayMouseDown = (e: React.MouseEvent) => {
        setIsMouseDownOnOverlay(e.currentTarget === e.target);
    }

    const handleOverlayMouseUp = (e: React.MouseEvent) => {
        if (isMouseDownOnOverlay && e.currentTarget === e.target) {
            closeHandler();
        }
    }

    useEffect(() => {
        if (firstInputRef.current) {
            firstInputRef.current.focus();
        }
    }, []);

    const generateOptions = (permissions: readWritePermissions): OptionType[] => {
        const options: OptionType[] = [];
        const permissionTypes: Record<string, 'role' | 'team' | 'user'> = {
            roleIds: 'role',
            teamIds: 'team',
            userIds: 'user',
        };

        (Object.keys(permissions) as Array<keyof readWritePermissions>).forEach((key) => {
            if (permissions[key].length > 0) {
                const type = permissionTypes[key];
                permissions[key].forEach((entityReference: EntityReference) => {
                    if (entityReference.name && entityReference.id) options.push({ label: entityReference.name, value: entityReference.id, type: type });
                })
            }
        });

        return options;
    };

    useEffect(() => {
        if (currentContext === 'update' || currentContext === 'copy') {
            if (view.viewPermissions) {
                const viewOptions: OptionType[] = generateOptions(view.viewPermissions.read.or);
                const editOptions: OptionType[] = generateOptions(view.viewPermissions.write.or);
                setViewSelectedOptions(viewOptions)
                setEditSelectedOptions(editOptions)
            }
            if (view && view.name && view.description) {
                if (currentContext === 'update') {
                    setViewName(view.name);
                    setViewDescription(view.description);
                }
                else {
                    let name = view.name + "-copy";
                    let description = view.description + "-copy";
                    if (view.name.length > 30) {
                        name = view.name.substring(0, 25) + "-copy";
                    }
                    if (description.length > 200) {
                        description = description.substring(0, 195) + "-copy";
                    }
                    setViewName(name);
                    setViewDescription(description);
                }
            }
        }
        if (currentContext === 'create') {
            setViewSelectedOptions([])
            setEditSelectedOptions([])
            setViewName('')
            setViewDescription('')
        }
    }, [view, currentContext])

    const getViewPermissionsPayload = () => {
        let readPermissions: { roleIds: string[], teamIds: string[], userIds: string[] } = { roleIds: [], teamIds: [], userIds: [] };
        viewSelectedOptions.forEach((option) => {
            if (option.type === 'role') readPermissions.roleIds.push(option.value);
            else if (option.type === 'team') readPermissions.teamIds.push(option.value);
            else if (option.type === 'user') readPermissions.userIds.push(option.value);
        },);

        let writePermissions: { roleIds: string[], teamIds: string[], userIds: string[] } = { roleIds: [], teamIds: [], userIds: [] };

        editSelectedOptions.forEach((option) => {
            if (option.type === 'role') writePermissions.roleIds.push(option.value);
            else if (option.type === 'team') writePermissions.teamIds.push(option.value);
            else if (option.type === 'user') writePermissions.userIds.push(option.value);
        });

        if ((readPermissions.userIds.length > 0 || readPermissions.teamIds.length > 0 || readPermissions.roleIds.length > 0) && !readPermissions.roleIds.includes('admin')) {
            readPermissions.roleIds.push('admin')
        }
        if ((writePermissions.userIds.length > 0 || writePermissions.teamIds.length > 0 || writePermissions.roleIds.length > 0) && !writePermissions.roleIds.includes('admin')) {
            writePermissions.roleIds.push('admin')
        }

        const viewPermissionsPayload = {
            read: {
                and: {
                    userIds: [],
                    teamIds: [],
                    roleIds: [],
                },
                or: readPermissions
            },
            write: {
                and: {
                    userIds: [],
                    teamIds: [],
                    roleIds: [],
                },
                or: writePermissions
            }
        }

        return viewPermissionsPayload;
    }

    const handleSave = () => {
        if (viewName.trim().length === 0) {
            notification('error', 'View name cannot be empty');
            return;
        }
        if(currentContext === 'update') {
            recordRSEvent(ViewsRSEvents.UPDATE_VIEW_BUTTON_CLICKED);
            if (!validateInputs(currentContext)) {
                recordRSEvent(ViewsRSEvents.CREATE_OR_UPDATE_VIEW_INVALID_INPUTS);
                return;
            }
        } else if (!validateInputs()) {
            recordRSEvent(ViewsRSEvents.CREATE_OR_UPDATE_VIEW_INVALID_INPUTS);
            return;
        }
        if (selectedEntityMetadata && selectedEntityMetadata.customFields) {
            const payload = getCreateViewPayload(selectedEntityMetadata?.customFields);

            const viewPermissionsPayload = getViewPermissionsPayload()
            setIsLoadingTransition(true);

            if (currentContext === 'create') {
                recordRSEvent(ViewsRSEvents.CREATE_NEW_VIEW_BUTTON_CLICKED);
                try {
                    if (selectedEntity) {
                        EntityService.createView(selectedEntity?.tableType, {
                            name: viewName,
                            description: viewDescription,
                            isDeleted: false,
                            ...payload,
                            viewPermissions: viewPermissionsPayload
                        }).then((res):any => {
                            if (res) {
                                notification('success', 'View created successfully');
                                history.push(`/table/${selectedEntity?.tableType}/view/${res.data.viewId}`);
                                recordRSEvent(ViewsRSEvents.CREATE_VIEW_SUCCESS, {
                                    tableType: selectedEntity?.tableType,
                                    viewId: res.data.viewId,
                                    ...payload,
                                    viewPermissions: viewPermissionsPayload
                                })
                                const viewChanges = localStorage.getItem(`viewChanges_${selectedEntity?.tableType}`) ? JSON.parse(localStorage.getItem(`viewChanges_${selectedEntity?.tableType}`)!) : {};
                                LocalStorageUtil.setItem(`viewChanges_${selectedEntity?.tableType}`, JSON.stringify({ ...viewChanges, [res.data.viewId]: false }));
                                dispatch(setSavedViewChanges({ ...savedViewChanges, [res.data.viewId]: getViewsObj(res.data.params) }));
                                LocalStorageUtil.setItem(`tableState_${selectedEntity?.tableType}_${res.data.viewId}`, JSON.stringify(res.data.params));
                                onSave('create');
                                closeHandler();
                            } else {
                                notification('error', 'Failed to create view');
                            }
                        })
                    }
                }
                catch (err) {
                    console.log(err);
                    recordRSEvent(ViewsRSEvents.CREATE_VIEW_FAILURE, {
                        tableType: selectedEntity?.tableType,
                        ...payload,
                        viewPermissions: viewPermissionsPayload
                    })
                    throw err
                } finally {
                    setIsLoadingTransition(false);
                }
            }
            else if (currentContext === 'update') {
                recordRSEvent(ViewsRSEvents.UPDATE_VIEW_BUTTON_CLICKED);
                setIsLoadingTransition(true);
                try {
                    if (selectedEntity) {
                        EntityService.updateView(selectedEntity?.tableType, view.viewId, {
                            name: viewName,
                            description: viewDescription,
                            isDeleted: false,
                            ...payload,
                            viewPermissions: viewPermissionsPayload
                        }).then((res) => {
                            if (res) {
                                notification('success', 'View updated successfully');
                                history.push(`/table/${selectedEntity?.tableType}/view/${view.viewId}`);
                                recordRSEvent(ViewsRSEvents.UPDATE_VIEW_SUCCESS, {
                                    tableType: selectedEntity?.tableType,
                                    viewId: view.viewId,
                                    ...payload,
                                    viewPermissions: viewPermissionsPayload
                                })
                                onSave('update', view.viewId);
                                closeHandler();
                            } else {
                                notification('error', 'Failed to update view');
                            }
                        })
                    }
                } catch (err) {
                    console.log(err);
                    recordRSEvent(ViewsRSEvents.UPDATE_VIEW_FAILURE, {
                        tableType: selectedEntity?.tableType,
                        ...payload,
                        viewPermissions: viewPermissionsPayload
                    })
                    throw err;
                }
                finally {
                    setIsLoadingTransition(false);
                }
            }
            else {
                notification('error', 'Something went wrong!')
            }
        }
    }

    const handleSaveDuplicate = async () => {
        recordRSEvent(ViewsRSEvents.DUPLICATE_VIEW_BUTTON_CLICKED);
        if (viewName.trim().length === 0) {
            notification('error', 'View name cannot be empty');
            return;
        }
        if (!validateInputs()) {
            return;
        }
        if (!selectedEntityMetadata || !selectedEntityMetadata.customFields) return;
        const payload = getCreateViewPayload(selectedEntityMetadata?.customFields, savedViewChanges[view.viewId]);
        const viewPermissionsPayload = getViewPermissionsPayload();

        if (selectedEntity) {
            try {
                setIsLoading(true);
                const res = await EntityService.createView(selectedEntity?.tableType, {
                    name: `${viewName}`,
                    description: `${viewDescription}`,
                    isDeleted: false,
                    ...payload,
                    viewPermissions: viewPermissionsPayload
                })
                if (res) {
                    notification('success', 'View duplicated successfully');
                    recordRSEvent(ViewsRSEvents.DUPLICATE_VIEW_SUCCESS, {
                        viewId: res.data.viewId,
                        ...payload
                    });
                    onSave('copy', res.data.viewId, view.viewId);
                } else {
                    notification('error', 'Failed to copy view');
                }
            }
            catch (err) {
                console.log(err);
                notification('error', 'Failed to copy view');
                closeHandler();
                recordRSEvent(ViewsRSEvents.DUPLICATE_VIEW_FAILURE, {
                    ...payload
                });
                throw err
            }
            finally {
                setIsLoading(false);
                closeHandler();
            }
        }
        else {
            notification('error', 'Something went wrong!');
        }
    }

    const handleDelete = () => {
        let readPermissions: { roleIds: string[], teamIds: string[], userIds: string[] } = { roleIds: [], teamIds: [], userIds: [] };

        viewSelectedOptions.forEach((option) => {
            if (option.type === 'role') readPermissions.roleIds.push(option.value);
            else if (option.type === 'team') readPermissions.teamIds.push(option.value);
            else if (option.type === 'user') readPermissions.userIds.push(option.value);
        },);

        let writePermissions: { roleIds: string[], teamIds: string[], userIds: string[] } = { roleIds: [], teamIds: [], userIds: [] };

        editSelectedOptions.forEach((option) => {
            if (option.type === 'role') writePermissions.roleIds.push(option.value);
            else if (option.type === 'team') writePermissions.teamIds.push(option.value);
            else if (option.type === 'user') writePermissions.userIds.push(option.value);
        });

        const payload = {
            isDeleted: true,
            name: viewName,
            description: viewDescription,
            viewPermissions: {
                read: {
                    and: {
                        userIds: [],
                        teamIds: [],
                        roleIds: [],
                    },
                    or: readPermissions
                },
                write: {
                    and: {
                        userIds: [],
                        teamIds: [],
                        roleIds: [],
                    },
                    or: writePermissions
                }
            }
        }
        setIsLoadingTransition(true);
        try {
            if (selectedEntity && view.viewId) {
                EntityService.updateView(selectedEntity?.tableType, view.viewId, payload).then((res) => {
                    if (res) {
                        notification('success', 'View deleted successfully');
                        const defaultView:any = views?.find((v:any) => v.isDefault);
                        recordRSEvent(ViewsRSEvents.DELETE_VIEW_SUCCESS, {
                            ...payload,
                            viewId: view.viewId
                        })
                        const viewChangesKey = localStorage.getItem(`viewChanges_${selectedEntity?.tableType}`);
                        if (viewChangesKey) {
                            const parsedViewChanges = JSON.parse(viewChangesKey);
                            delete parsedViewChanges[view.viewId];
                            LocalStorageUtil.setItem(`viewChanges_${selectedEntity?.tableType}`, JSON.stringify(parsedViewChanges));
                        }
                        onDeleteView(null, defaultView?.viewId);
                        closeHandler();
                    }
                })
            }
        }
        catch (err) {
            console.log(err);
            recordRSEvent(ViewsRSEvents.DELETE_VIEW_FAILURE, {
                ...payload,
                viewId: view.viewId
            })
            throw err;
        } finally {
            setIsLoadingTransition(false);
        }
    }

    const closeHandler = () => {
        setIsLoading(false);
        recordRSEvent(ViewsRSEvents.CREATE_UPDATE_VIEW_MODAL_CLOSED);
        onClose();
    }

    return (
        <CurlModal open={true}>
            <ModalOverlay
                onMouseDown={handleOverlayMouseDown}
                onMouseUp={handleOverlayMouseUp}
            >
                <ModalContainer onClick={(e: any) => e.stopPropagation()}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: "12px" }}>
                        <HeaderContainer>
                            <Heading>
                                <Text $color="var(--Gray-900, #101828)" $fontSize="18px" $fontWeight="600">
                                    {currentContext === 'create' ? "Create new view" : currentContext === 'update' ? "Manage view" : "Duplicate view"}
                                </Text>
                            </Heading>
                            <CloseIcon fontSize={24} color={"#667085"} onClick={() => {
                                closeHandler();
                            }} />
                        </HeaderContainer>
                        <InputContainer>
                            <Text $color="#344054" $fontSize="12px" $fontWeight="500">
                                View's Name <span style={{ color: '#B42318' }}>*</span>
                            </Text>
                            <StyledInput ref={firstInputRef} value={viewName} onChange={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if(e.target.value.trim().length <= 30 && viewNameError !== '') {
                                    setViewNameError('')
                                }
                                if (e.target.value.trim().length === 0) {
                                    setIsEmpty(true);
                                } else {
                                    setIsEmpty(false);
                                }
                                setViewName(e.target.value);
                            }} type='text' placeholder='Enter the view name...' />
                            {(viewNameError || isEmpty) && <Error $color="#B42318"> {viewNameError} </Error>}
                        </InputContainer>
                        <InputContainer>
                            <Text $color="#344054" $fontSize="12px" $fontWeight="500">
                                View's Description
                            </Text>
                            <StyledInput value={viewDescription} onChange={(e: any) => { 
                                e.stopPropagation(); 
                                setViewDescription(e.target.value);
                                if(e.target.value.trim().length <= 200 && viewDescriptionError !== '') {
                                    setViewDescriptionError('')
                                }
                                }} type='text' placeholder='Enter the view description...' />
                            {viewDescriptionError && <Error $color="#B42318"> {viewDescriptionError} </Error>}
                        </InputContainer>
                        <InputContainer>
                            <Text $color="#344054" $fontSize="12px" $fontWeight="500">
                                Who can view:
                            </Text>
                            <SearchInputContainer placeholder='All teams, all roles and all users' context={currentContext === 'create' ? 'create' : 'update'} onChangeSelect={setViewSelectedOptions} selectedOptions={viewSelectedOptions} />
                        </InputContainer>
                        {/* <InputContainer>
                            <Text $color="#344054" $fontSize="12px" $fontWeight="500">
                                Who can edit:
                            </Text>
                            <SearchInputContainer placeholder='All teams, all roles and all users' context={currentContext === 'create' ? 'create' : 'update'} onChangeSelect={setEditSelectedOptions} selectedOptions={editSelectedOptions} />
                        </InputContainer> */}
                    </div>
                    <ButtonWrapper>
                        {currentContext === 'update' ?
                            <div style={{ display: 'flex', gap: '16px', fontWeight: '600', width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}>
                                {!view.isDefault && <div onClick={() => {setShowDeleteConfirmation(true); recordRSEvent(ViewsRSEvents.DELETE_VIEW_BUTTON_CLICKED, {
                                    viewId: view.viewId
                                })}}
                                    style={{ display: 'flex', gap: '8px', color: '#B42318', cursor: 'pointer', fontSize: '14px', justifyContent: 'center', alignItems: 'center' }}>
                                    <DeleteIcon fontSize={24} /><span style={{ width: '100%' }}>Delete View</span>
                                </div>}
                                <div onClick={() => {
                                    setCurrentContext('copy');
                                    recordRSEvent(ViewsRSEvents.DUPLICATE_VIEW_BUTTON_CLICKED, {
                                        viewId: view.viewId
                                    })
                                }}
                                    style={{ display: 'flex', gap: '8px', color: '#475467', cursor: 'pointer', fontSize: '14px', justifyContent: 'center', alignItems: 'center' }}>
                                    <CopyIcon strokeWidth={2} fontSize={20} /><span style={{ width: '100%' }}>Duplicate View</span>
                                </div>
                            </div> : <></>}
                        <ButtonContainer>
                            <Button onClick={() => { closeHandler() }}>
                                Cancel
                            </Button>
                            {(currentContext !== 'copy') ? <SaveButton $active={!isLoading && (viewName.trim().length > 0) ? true : false} disabled={isLoading || viewName.trim().length <= 0} onClick={handleSave} $color="#fff">
                                {currentContext === 'create' ? isLoading ? "Creating..." : "Create" : isLoading ? "Updating..." : "Update"}
                            </SaveButton> :
                                <SaveButton $active={!isLoading && (viewName.trim().length > 0) ? true : false} disabled={isLoading || viewName.trim().length <= 0} onClick={handleSaveDuplicate} $color="#fff">
                                    {isLoading ? "Confirming..." : "Confirm"}
                                </SaveButton>}
                        </ButtonContainer>
                    </ButtonWrapper>
                </ModalContainer>
                <DeletionConfirmationDialog
                    id={"delete-confirmation"}
                    deletionTitle={"Delete this view?"}
                    deletionText={"Do you want to delete this view?"}
                    isOpen={showDeleteConfirmation}
                    onConfirmDelete={() => handleDelete()}
                    onClose={() => setShowDeleteConfirmation(false)}
                />
            </ModalOverlay>
        </CurlModal>
    )
}

export default ManageTableViews