import { isEmpty } from "lodash";
import * as React from "react";
import { Redirect, Route } from "react-router-dom";
import HomePage from "views/onboarding/HomePage";
import AccoutSetup from "views/signup/screens/AccountSetup";
import AwaitingVerification from "views/signup/screens/AwaitingVerification";
import TransitionTask from "views/tasks/components/TransitionTask";
import WTMainModal from "views/workflow-templates/components/WTMainModal";
import { userDecodedData } from "../utils/UserAuth.utils";
import DashBoard from "../views/dashboard/dashboard";
import ZorpLoginPage from "../views/layouts/Login";
import FPRequestScreen from "../views/layouts/ForgotPassword/FPRequestScreen";
import FPVerifyOTP from "../views/layouts/ForgotPassword/FPVerifyOTP";
import FPChangePassword from "../views/layouts/ForgotPassword/FPChangePassword";
import ZorpMap from "../views/maps/ZorpMap";
import { Document } from "../views/ApiDocs/Document";
import Settings from "../views/settings/Settings";
import SignUp from "../views/signup/screens/SignUp";
import TaskBulkCreate from "../views/tasks/components/TaskBulkCreate";
import Scheduler from "../views/tasks/screens/scheduler/Scheduler";
import GeneralSettingEdit from "views/settings/GeneralSetting/GeneralSettingEdit";
import PlaceHolder from "views/layouts/PlaceHolder";
import CustomReport from "views/layouts/CustomReport";
import UrbanLadderProjects from "views/layouts/UrbanladderProjects";
import AwfisProjects from "views/layouts/AwfisProjects";
import ContinuityCare from "views/layouts/ContinuityCare";
import CarbonMasters from "views/layouts/CarbonMasters";
import CarbonMasters_Ops from "views/layouts/CarbonMasters_Ops";
import RidyStations from "views/layouts/RidyStations";
import SecretManagement from "views/settings/secret-management/SecretManagement";
import FilteredTaskList from "views/tasks/screens/task/list/FilteredList";
import TaskList from "views/tasks/screens/task/list/TaskList";
import FWebFrame from "render-engine/show/FWebFrame";
import BiDashboard from "views/ZorpBi/BiDashboard";
import Entity from "views/entities/Entity";
import EntityCreate from "views/entities/EntityCreate";
import {EntityShow} from "views/entities/EntityShow";
import EntityEdit from "views/entities/EntityEdit";
import Chronos from "views/chronos/ZChronos";
import ZMap from "../views/zmap/ZMap";
import AppsmithDashboards from "views/layouts/AppsmithDashboards";
import TableLanding from "views/entities/tables/TableLanding";
import TicketTable from 'views/entities/tables/TicketTable';
import Monitor from "views/monitor/Monitor";
import ZorpGPTModal from "views/workflows/automateWorkflow/ZorpGPTModal"
import { TableDocument } from "views/ApiDocs/TableDocument";
import ShopifyMonitor from "views/monitor/ShopifyMonitor";
import DashboardGrid from "views/grids/DashboardGrid";
import { ShowTask } from "render-engine/show/ShowTask";
import { CreateTask } from "render-engine/create/CreateTask";

// @ts-ignore
const routes = [

  // tasks routes
  <PrivateRoute exact path="/task/:taskType/show/:taskId" component={ShowTask} />,
  <PrivateRoute exact path="/task/:taskType/create" component={CreateTask} />,

  <PrivateRoute exact path="/table" component={TableLanding} />,
  <PrivateRoute exact path="/table/:tableType" component={Entity} />,
  <PrivateRoute exact path="/ticket/:tableType" component={TicketTable} />,
  <PrivateRoute exact path="/table/:tableType/view/:viewId" component={Entity} />,
  <PrivateRoute exact path="/table/create/:tableType" component={EntityCreate} />,
  <PrivateRoute exact path="/table/:tableType/edit/:recordId" component={EntityEdit} />,
  <PrivateRoute exact path="/table/:tableType/show/:recordId" component={EntityShow} />,
  <PrivateRoute exact path="/table/document/:tableType" component={TableDocument} />,


  <PrivateRoute exact path="/reports/:id" component={DashBoard} />,
  <PrivateRoute exact path="/bireports/:label" component={BiDashboard} />,
  <PrivateRoute exact path="/schedule" component={Scheduler} />,
  <PrivateRoute exact path="/holder" component={PlaceHolder} />,
  <PrivateRoute exact path="/customreport" component={CustomReport} />,
  <PrivateRoute exact path="/projects-ul-modular">
    <UrbanLadderProjects
      url={
        "https://app.appsmith.com/app/urban-ladder-project-management/project-list-637b5d1654ea811eb25a2c13?embed=true"
      }
    />
  </PrivateRoute>,
  <PrivateRoute exact path="/porter-orders">
    <AppsmithDashboards
      url={
        "https://app.appsmith.com/app/porter-order-management/orders-649bcb099dd4927ca5db6293?embed=true"
      }
    />
  </PrivateRoute>,
  <PrivateRoute exact path="/porter-franchisee">
    <AppsmithDashboards
      url={
        "https://app.appsmith.com/app/porter-franchisee-management/franchisees-64b504213379621654158ed8?embed=true"
      }
    />
  </PrivateRoute>,
  <PrivateRoute exact path="/admin-ul-qc">
    <UrbanLadderProjects
      url={
        "https://app.appsmith.com/app/urbanladder-qc/page1-63e1f7e94fc63a0df6b85ae6?embed=true"
      }
    />
  </PrivateRoute>,
  <PrivateRoute exact path="/projects-a" component={AwfisProjects} />,
  <PrivateRoute exact path="/customers" component={ContinuityCare} />,
  <PrivateRoute exact path="/admin-cm" component={CarbonMasters} />,
  <PrivateRoute exact path="/admin-cm-ops" component={CarbonMasters_Ops} />,
  <PrivateRoute exact path="/stations" component={RidyStations} />,

  <PrivateRoute exact path="/bulktask" component={TaskBulkCreate} />,
  <PrivateRoute
    exact
    path="/task/:taskType/transition/:taskId?/:selectedTransition?"
    component={TransitionTask}
  />,
  <PrivateRoute
    exact
    path="/task/:taskId/webf"
    component={FWebFrame}
  />,
  <PrivateRoute exact path="/map" component={ZorpMap} />,
  <PrivateRoute exact path="/zmap" component={ZMap} />,
  <PrivateRoute exact path="/monitor" component={Monitor} />,
  <PrivateRoute exact path="/monitor/shopify" component={ShopifyMonitor} />,
  <PrivateRoute exact path="/document/:taskType?" component={Document} />,
  <PrivateRoute
    exact
    path="/health"
    component={() => <h3>Hey There!!! The App is Healthy</h3>}
  />,
  <PrivateRoute exact path="/settings/:page" component={Settings} />,
  <PrivateRoute exact path="/chronos" component={Chronos} />,
  <PrivateRoute
    exact
    path="/settings/general/edit"
    component={GeneralSettingEdit}
  />,
  <PrivateRoute exact path="/templates" component={WTMainModal} />,
  <PrivateRoute exact path="/zorp-gpt" component={ZorpGPTModal} />,
  <PrivateRoute exact path="/white-labelling" component={WTMainModal} />,
  <PrivateRoute exact path="/:name/tasks" component={FilteredTaskList} />,
  <PrivateRoute exact path="secret" component={SecretManagement} />,
  <Route exact path="/reset-password" component={FPRequestScreen} noLayout />,
  <Route exact path="/verify-otp/:userId" component={FPVerifyOTP} noLayout />,
  <Route
    exact
    path="/change-password/:userId/:otp"
    component={FPChangePassword}
    noLayout
  />,
  <Route exact path="/account-setup/:email" component={AccoutSetup} noLayout />,
  <Route
    exact
    path="/awaiting-verification/:email"
    component={AwaitingVerification}
    noLayout
  />,
  <Route exact path="/login" component={ZorpLoginPage} />,
  <PrivateRoute exact path="/home" component={HomePage} />,
  <PrivateRoute exact path="/home-2" component={DashboardGrid} />,
  <Route exact path="/signup" component={SignUp} noLayout />,
];

export default routes;

function PrivateRoute({ component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        !isEmpty(userDecodedData()) ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
}
