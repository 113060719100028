import Modal from "@mui/material/Modal";
import trigger from "assests/trigger.svg";
import { cloneDeep } from "lodash";
import { useJSEditor } from "lowcode/hooks/useJSEditor";
import { useState } from "react";
import { useDispatch, useStore } from "react-redux";
import styled from "styled-components";
import { closeAutomation } from "views/automationBuilder/reducer/automationReducer";
import { saveSystemTransition } from "views/automationBuilder/saveHandlers/saveSystemTransitions";
import { ABMetadataTypeEnum } from "views/automationBuilder/types/ABType";
import { DataFieldType } from "views/DataField/DataField.types";
import DataFieldDrawer from "views/DataField/DataFieldDrawer";
import ModalForFlutter from "views/workflows/CreateModalForFlutter";
import {
  setSelectedAction,
  setSelectedTransition,
} from "views/workflows/reducers/workflowAction";
import ShowWorkflowErrorButton from "views/workflows/WorkflowErrors/ShowWorkflowErrorButton";
import ConfigureAutomation from "./ConfigureAutomation";
import ConfigureDataFieldButton from "./ConfigureDataFieldButton";
import ConfigureWorkflowButton from "./ConfigureWorkflow/ConfigureWorkflowButton";
import GetTutorialsButton from "./ConfigureWorkflow/GetTutorialsButton";
import "./css/flow.css";
import useWorkFlow from "./hooks/useWorkFlow";
import {
  setActiveEditField,
  setActiveSubField,
  setDataFieldType,
  setShowCreateDatafieldModalSingle,
  setShowDataFieldCreateModal,
  setShowDataFieldEditModal,
  setShowDataFieldEditModalSingle,
  setShowDataFieldModal,
} from "./reducers/workflowAction";
import SelectNodeButton from "./selectNode/SelectNodeButton";
import useAutomation from "views/automationBuilder/hooks/useAutomation";
import { ZIconButton2 } from "views/entities/styles";
import { AiOutlineArrowLeft, AiOutlineLeft } from "react-icons/ai";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import { AutomationsIcon } from "assests";
import {useHistory} from "react-router-dom"
import PreviewPublishButton from "./ConfigureWorkflow/PreviewPublishButton";
import PublishButton from "components/post-publish-modal/PublishButton";

const Header = ({ addNodeOnClick }) => {
  const {
    taskTypeName,
    taskTypeId,
    showDataFieldModal,
    showCreateDatafieldModalSingle,
    showEditDatFieldModalSingle,
    allWorkflowErrors,
    isBeingSaved
  } = useWorkFlow();


  const [openAB, setOpenAB] = useState(false);
  const dispatch = useDispatch();
  const store = useStore();
  const { mockData } = useJSEditor();
  const { clearRightSide } = useAutomation();
  const history = useHistory()

  const Button = styled.button`
    background-color: #f2f2f2;
    border: 1px solid #c2c9d1;
    color: #616569;
    padding: 8px 16px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    margin-right: 10px;
  `;

  const TopBarWrapper = styled.div`
    padding: 32px 12px 0px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(229, 229, 229);
  `;

  const TopBarHeading = styled.div`
    display: flex;
    align-items: center;
    .backButton {
      padding: 7px;
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 4px;
      margin-right: 8px;
      background-color: #FFFFFF;
      border-color: #D0D5DD;
      color: #344054;
    }
    p {
      span {
        font-weight: 400;
      }
      font-size: 20px;
      font-weight: 600;
    }
  `;

  const closeModal = () => {
    dispatch(setShowDataFieldModal(false));
    dispatch(setShowDataFieldCreateModal(false));
    dispatch(setShowDataFieldEditModal(false));
    dispatch(setActiveSubField(null));
  };

  const closeModalFromBuilder = () => {
    dispatch(setShowCreateDatafieldModalSingle(false));
    dispatch(
      setDataFieldType({
        dataType: DataFieldType.TEXT,
        key: "",
        subFieldDataType: null,
        listName: null,
      })
    );
    dispatch(setActiveSubField(null));
  };

  const closeEditModalFromBuilder = () => {
    dispatch(setShowDataFieldEditModalSingle(false));
    dispatch(setActiveEditField({}));
    dispatch(
      setDataFieldType({
        dataType: DataFieldType.TEXT,
        key: "",
        subFieldDataType: null,
        listName: null,
      })
    );
    dispatch(setActiveSubField(null));
  };

  return (
    <>
      <TopBarWrapper className="bg-gray-50 header">
        <TopBarHeading>
          <div onClick={() => history.push("/workflows")} className="backButton">
            <AiOutlineArrowLeft />
          </div>
          <p>
            <span>Workflow: </span> {taskTypeName} - {taskTypeId}
          </p>
        </TopBarHeading>
        <div className="button_container">
          {isBeingSaved && (
                <span className="pr-4 text-green-600"> Auto Saving.... </span>
          )}

          {allWorkflowErrors.length > 0 && (
            <ShowWorkflowErrorButton allWorkflowErrors={allWorkflowErrors} />
          )}
          <span className="pr-4"></span>
          <PreviewPublishButton/>
          <span className="pr-4"></span>
          {/* <GetTutorialsButton /> */}
          <PublishButton/>
        </div>
      </TopBarWrapper>
      <div
        style={{
          height: "61px",
          display: "flex",
          backgroundColor: "#e5e5e5",
          justifyContent: "space-between",
        }}
        className="flex justify-between bg-gray-50 header"
      >
        <div className="button_container" style={{ marginLeft: "12px",gap:"4px" }}>
          <ConfigureDataFieldButton />
          <ConfigureWorkflowButton />
          <ZButton
            onClick={(e) => {
              e.preventDefault();
              setOpenAB(true);
              dispatch(setSelectedTransition(null));
              dispatch(setSelectedAction(null));
            }}
            startIcon={AutomationsIcon}
            secondary
            variant="contained"
          >
            {" "}
            Automations{" "}
          </ZButton>

          <ConfigureAutomation
            metaDataType={ABMetadataTypeEnum.WORKFLOW_SYSTEM_TRANSITION}
            prevAutomations={
              store.getState()?.builder?.workflow
                ?.systemTransitionAutomations || []
            }
            onSaveHandler={saveSystemTransition}
            onAutomationClose={() => {
              clearRightSide();
              dispatch(closeAutomation());
              setOpenAB(false);
            }}
            otherConfigs={{}}
            openAutomationBuilder={openAB}
          />

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={showDataFieldModal}
            onClose={closeModal}
            closeAfterTransition
            BackdropProps={{ style: { backgroundColor: "transparent" } }}
            sx={{ width: "80vw", left: "76px", top: "110px" }}
          >
            <DataFieldDrawer />
          </Modal>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={showCreateDatafieldModalSingle}
            onClose={closeModalFromBuilder}
            closeAfterTransition
            BackdropProps={{ style: { backgroundColor: "transparent" } }}
            sx={{ width: "80vw", left: "76px", top: "110px" }}
          >
            <>
              <ModalForFlutter type={"CREATE"} />
            </>
          </Modal>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={showEditDatFieldModalSingle}
            onClose={closeEditModalFromBuilder}
            closeAfterTransition
            BackdropProps={{ style: { backgroundColor: "transparent" } }}
            sx={{ width: "80vw", left: "76px", top: "110px" }}
          >
            <>
              <ModalForFlutter type={"EDIT"} />
            </>
          </Modal>
        </div>
        <div className="button_container">
          <SelectNodeButton addNodeOnClick={addNodeOnClick} />
        </div>
      </div>
    </>
  );
};

export default Header;
