import { useSelector } from 'react-redux'

type Props = {}

const usePreview = () => {
    //@ts-ignore
    const preview = useSelector((state) => state.preview)
    
    const {taskId} = preview

    return {
        taskId
    }
  
}

export default usePreview