import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { RudderStackAutomationBuilderEvents } from "constants/RudderStackConstants";
import notification from "notifications/notifications";
import { MonitorService } from "providers/data/services/MonitorService";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { getAccountId, recordRSEvent } from "utils/CommonUtils";
import { generateId } from "utils/Utils";
import { setSelectedMonitorId } from 'views/automationBuilder/reducer/automationReducer';
import useAccount from "views/layouts/reducer/useAccount";
import { IQueryGeneratedType } from "./MonitorInterface";
import { IShopifyTemplate } from "./ShopifyMonitor";
import TemplateEditContent from "./TemplateEditContent";
import { ICronValueType, IScreenContext, setIsConfirmMonitorModalOpen, setMonitorCreateFromTemplatePayload, setMonitorInterfaceContext, setOnEditClassicBuilderTemplateData, setSelectedMonitor, setShowInterfaceUI } from "./reducer/MonitorReducer";
import useMonitor from "./reducer/useMonitor";
const TemplateModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 80vh; */
  overflow: auto;
`;

const ModalContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: ${(props: { height?: string; width?: string }) =>
    props?.width ? props.width : "560px"};
  height: ${(props: { height?: string; width?: string }) =>
    props?.height ? props.height : "auto"};
  max-height: 86vh;
  background: #ffffff;
  box-shadow: 0px 10px 14px -2px rgba(16, 24, 40, 0.01),
    0px 4px 4px -2px rgba(16, 24, 40, 0.01);
  position: absolute;
  //   top: 15%;
  //   left: 30%;
  border-radius: 4px;
  overflow:scroll;
`;

const TemplateEditModal = () => {
  const dispatch = useDispatch();
  const accountId = getAccountId();
  const { account } = useAccount();
  const { isConfirmMonitorModalOpen } = useMonitor();


  const handleModalClose = () => {
    dispatch(setIsConfirmMonitorModalOpen(false));
  }

  return (
    <TemplateModal
      open={isConfirmMonitorModalOpen}
      onClose={handleModalClose}>
      <ModalContainer>
        <TemplateEditContent
        onTemplateEdit={async (templatePayload: IShopifyTemplate)=>{
          dispatch(setOnEditClassicBuilderTemplateData(templatePayload));

          const monitorCreateResponse = await MonitorService.createUnInitiatedMonitor();
          if (monitorCreateResponse?.code == '201') {
            const monitorId = monitorCreateResponse?.data?.monitorId;
          // set your data
          dispatch(setMonitorInterfaceContext({
              monitorScreenContext: IScreenContext.EDIT,
              monitorComponentsData: {
                  isdDatasourceSectionHidden: true,
                  isConditionSectionHidden: true,
                  isChannelSectionHidden: true,
                  isMonitorCustomizationSectionHidden: true,
                  selectedActionDetails: undefined,
                  tablesColumnsList:[],
                  isDisableCreateBulkAlert: false,
              },
              monitorData: {
                "monitorId": monitorId,
                "accountId": accountId,
                "isActive": true,
                "isDeleted": false,
                "avoidDuplicate": false,
                "autoResolve": false,
                "timesRan": 0,
                "createBulkAlert": false,
                "isInitiated": true,                      
                "actionData": [
                  {
                    "actionType": "EMAIL",
                    "actionId": generateId(6),
                    "label": "Email",
                    "isActive": true,
                    "payload": {
                        "userEmail": account?.primaryContactEmail,
                        "mailSubject": templatePayload.emailSubject,
                        "mailBody": templatePayload.emailBody,
                        "attachCSV": true
                    }
                }
                ],
                "autoResolveCount": 0,
                "cronData": {
                    "cronType": ICronValueType.EXPRESSION,
                    "timeZone": "Asia/Kolkata",
                    "cronData": {
                        "expression": templatePayload.cronExpression as string
                    },
                    "isActive": true
                },
                "datasourceId": templatePayload.datasourceId as string,
                "monitorDescription": templatePayload.description,
                "monitorName": templatePayload.title,
                "query": templatePayload.sqlQuery,
                "query_generated_type": IQueryGeneratedType.SQL,
            }
          }));

          dispatch(setSelectedMonitor({ monitorId: monitorId, monitorAutomation: [] }))
          dispatch(setSelectedMonitorId(monitorId));
          
          handleModalClose();
          dispatch(setShowInterfaceUI(true))
          recordRSEvent(RudderStackAutomationBuilderEvents.aiModalEditInClassicBuilder, {
            context: RudderStackAutomationBuilderEvents.monitorContext
          });
          } else {
            notification('error', 'Error creating monitor')
          }
        }}
          onAcivateAlerts={(editedTemplateData: IShopifyTemplate) => {
            recordRSEvent(RudderStackAutomationBuilderEvents.monitorThis, {
              context: RudderStackAutomationBuilderEvents.monitorContext
            });

            dispatch(setMonitorCreateFromTemplatePayload(editedTemplateData));
          }}
          handleModalClose={() => {
            dispatch(setIsConfirmMonitorModalOpen(false));
          }} />
      </ModalContainer>
    </TemplateModal>
  )
}

export default TemplateEditModal