import {
    Label
  } from "views/workflows/workflowactions/ModalContent.styles";

type ILocationDivider ={
    label:string
}
const LocationDivider = ({label}:ILocationDivider) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignContent: 'center', alignItems: 'center', marginTop:'10px' }}>
    <div style={{ width: '15%' }}>
      <Label> {label}</Label>
    </div>
    <div style={{ width: '85%', marginBottom: '8px' }}>
      <hr />
    </div>
  </div>
  )
}

export default LocationDivider