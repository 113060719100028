import React from 'react'
import { Pagination } from 'react-admin';

const ListPagination = (props: any) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Pagination rowsPerPageOptions={[10, 25, 50, 75]} {...props} />
        </div>
    )
};
export default ListPagination;