import FAQComponent from "./components/homepage/FAQComponent";
import InformationComponent from "./components/homepage/InformationComponent";
import NumberMetricComponent from "./components/homepage/NumberMetricComponent";

// Define a type for the component props
export interface HomePageComponentProps {
  type?: HomePageComponentType;
  custom?: any;
}

// Enum to define component types
enum HomePageComponentType {
  INFO = "INFO",
  FAQ = "FAQ",
  METRIC = "METRIC"
}

// Factory class
export function createComponent(
  component: HomePageComponentProps
): JSX.Element {
  switch (component.type) {
    case HomePageComponentType.INFO:
      return <InformationComponent {...component?.custom} />;
    case HomePageComponentType.FAQ:
      return <FAQComponent {...component?.custom} />;
    case HomePageComponentType.METRIC:
        return <NumberMetricComponent {...component?.custom} />;      
    default:
      throw new Error("Invalid component type");
  }
}
