import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { actionChannel } from 'redux-saga/effects';
import { InitialStateType, OnboardingStep } from '../types/OnboardingTypes';
import jwt_decode from 'jwt-decode';
import { UserService } from 'providers/data/services/UserService';
import { isEqual } from 'lodash';

const initialState:InitialStateType = {
  
  showOnboardingModal: false,
  isBeingOnboarded: false,
  onboarding:{
      currentStepId: "",
      isOnboardingDoneForWeb: false,
      isOnboardingDoneForApp: false,
      onboardingCompletedOn: null,
      onboardingStartedOn: null,
      steps: []
  }
    
  
};

export const initOnboarding = createAsyncThunk('onboarding/initOnboarding', async () => {
    
    try {
      const at:any = localStorage.getItem('at') || "";
      const decoded:any = jwt_decode(at);
      const onBoardingData = await UserService.getUserOnboarding({userId:decoded.userId});
      // if(!onBoardingData?.data){
      //   return {
      //     isOnboardingDoneForWeb: false,
      //     isOnboardingDoneForApp: false,
      //     steps:[]
      //   }
      // } else 
        // get current step for onBoardingData.data.steps
        let currentStepId = OnboardingStep.OVERVIEW;
        const stepsCompleted = onBoardingData?.data?.steps?.map((step:any) => step.stepId);
        if(stepsCompleted.includes(OnboardingStep.EXECUTE_TASK)) {
          currentStepId = OnboardingStep.COMPLETED
        } 
        else if(stepsCompleted.includes(OnboardingStep.CREATE_TASK)) {
          currentStepId = OnboardingStep.EXECUTE_TASK
          
        }
        else if(stepsCompleted.includes(OnboardingStep.WORKFLOW)) 
        {
          currentStepId = OnboardingStep.CREATE_TASK
        }
        else if(stepsCompleted.includes(OnboardingStep.OVERVIEW)){
          currentStepId = OnboardingStep.WORKFLOW
        }

        
        // if(onBoardingData?.data?.steps?.length === 4)
        //   currentStepId = OnboardingStep.COMPLETED
        // else if(onBoardingData?.data.steps?.length === 3)
        //   currentStepId = OnboardingStep.EXECUTE_TASK
        // else if(onBoardingData?.data?.steps?.length === 2){
        //   currentStepId = OnboardingStep.CREATE_TASK
        // } else if(onBoardingData?.data?.steps?.length === 1){
        //   currentStepId = OnboardingStep.WORKFLOW
        // } else {
        //   currentStepId = ""
        // }
        
      
        return {
          isOnboardingDoneForWeb: onBoardingData?.data.isOnboardingDoneForWeb,
          isOnboardingDoneForApp: onBoardingData?.data.isOnboardingDoneForApp,
          steps: onBoardingData?.data.steps,
          currentStepId
        }
      // }
      
    } catch (error) {
      console.log("erroor", error) 
      return {
        isOnboardingDoneForWeb: false,
        isOnboardingDoneForApp: false,
        steps:[],
        currentStepId: OnboardingStep.OVERVIEW
      }
    }
    
});

export const UserOnboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    
    setShowOnboardingModal: (state:InitialStateType, action) => {
      state.showOnboardingModal = action.payload
    },

    updateOnboarding: (state:InitialStateType, action) => {
      if(!isEqual(state.onboarding, action.payload)){
        //@ts-ignore
        state.onboarding = {...action.payload}
      }
      
    },
    setIsBeingOnboarded: (state:InitialStateType, action) => {
      state.isBeingOnboarded = action.payload
    },
    
  },
  extraReducers: (builder) => {

    builder.addCase(initOnboarding.fulfilled, (state, action) => {
      if(!isEqual(action.payload, state.onboarding)){
        //@ts-ignore
        state.onboarding = {...state.onboarding, ...action.payload};
      }
      
    })
    .addCase(initOnboarding.rejected, (state, action) => {
      state.onboarding = {
        currentStepId: "",
        isOnboardingDoneForWeb: false,
        isOnboardingDoneForApp: false,
        onboardingCompletedOn: "",
        onboardingStartedOn: "",
        steps: []
    }
    })
  },
});

export const { setShowOnboardingModal,updateOnboarding,setIsBeingOnboarded } = UserOnboardingSlice.actions;

export default UserOnboardingSlice.reducer;
