import { hasPermission } from "providers/auth/authUtils";
import React, { useEffect } from "react";
import { useDispatch, useStore, useSelector } from "react-redux";
import useOnboarding from "views/onboarding/hooks/useOnboarding";
import { resetWATData } from "views/workflow-templates/reducers/WorkflowTemplate.actions";
import useWorkFlow from "views/workflows/hooks/useWorkFlow";
import { useHistory } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { getAccountDetails } from "utils/CommonUtils";
import authProvider from "providers/auth/authProvider";
import useZMenu from "./reducer/useMenu";
import { initColor } from "views/settings/GeneralSetting/ThemeColorSelector";
import LoadingScreen from "views/LoadingScreen/LoadingScreen";

type Props = {
  children: React.ReactNode;
};

const ZThemeProvider = (props: Props) => {
  const dispatch = useDispatch();
  const { getIdentity, checkAuth } = authProvider;

  const { theme } = useZMenu();

  const [loading, setLoading] = React.useState(true);

  const [preview, setPreview] = React.useState(false);
  const [activeTheme, setActiveTheme] = React.useState({color : {
    primary : initColor.primary,
    secondary : initColor.secondary,
    tertiary : initColor.tertiary
  }});

  const [isPermittedToEditWorkflow, setIsPermittedToEditWorkflow] =
    React.useState(false);

  useEffect(() => {
    setIsPermittedToEditWorkflow(hasPermission("workflow", "edit"));
  }, []);

  const { publishedWATStatus, publishedTemplate } = useSelector(
    (state) => state?.workflowTemplates
  );

  const getAccountDetailsFunc = async () => {
    try {
      setLoading(true);
      const accountInfo = await getAccountDetails();
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      link.href = accountInfo?.accountConfig?.logo;
      // check if theme has been selected or not
      if (
        accountInfo?.accountConfig?.theme?.colors.primary &&
        accountInfo?.accountConfig?.theme?.colors.secondary &&
        accountInfo?.accountConfig?.theme?.colors.tertiary
      ) {
        const newTheme = {
          ...accountInfo?.accountConfig?.theme?.colors
        };
        // document.documentElement.style.setProperty('--my-color', color);
        // set theme style.setProperty
        document.documentElement.style.setProperty('--primary', newTheme.primary);
        document.documentElement.style.setProperty('--secondary', newTheme.secondary);
        document.documentElement.style.setProperty('--tertiary', newTheme.tertiary);
        setActiveTheme({ color: newTheme });
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAccountDetailsFunc();
  }, [theme]);

  const handleCloseTemplateOpen = () => {
    setShowTemplateInfo(false);
    document.body.classList.remove("modal-open");
  };

  useEffect(() => {
    if (publishedWATStatus?.match(/200|201/) && publishedTemplate) {
      handleCloseTemplateOpen();
      dispatch(resetWATData());
    }
  }, [publishedWATStatus, publishedTemplate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // collect preview from url
    // http://localhost:3000/#/tasks/${taskId}/show/?preview=true
    const preview = window.location.href.includes("preview=true");
    setPreview(preview);
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <ThemeProvider theme={activeTheme}>{props.children}</ThemeProvider>
      )}
    </>
  );
};

export default ZThemeProvider;
