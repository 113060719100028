import {Label,Input} from "../DataField.styles"

type FileFieldProps = {
    register:any;
}


const FileField = ({register}:FileFieldProps) => {
  return (
    <>
        {/* <Label>
            Default Value
        </Label>
        <Input type="text" {...register("defaultValue")} /> */}
    </> 
  )
}

export default FileField