import React, {memo} from 'react'
import styled from "styled-components";

type Props = {
    value:string
    color: string
    backgroundColor ?: string
    padding?: string
    marginLeft?: string
    underline?: boolean
    width?: string
}

export const Wrapper = styled.p`
    display: inline-flex;
    color: ${({color}: Props) => color};
    background-color: ${({backgroundColor}: Props) => (backgroundColor ? backgroundColor : 'transparent')};
    font-weight: 500;
    gap: 4px;
    font-size: 11px;
    justify-content: flex-start;
    align-items: center;
    border-radius: 4px;
    :hover {
      text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
      cursor: pointer;
      color: #3054B9;
    }
    width: ${({ width }) => (width ? width : '100%')};
    padding: ${({padding}: Props) => padding ? padding : '2px 4px'};
    height: fit-content;
    text-overflow: ellipsis !important;
    white-space: nowrap;
    margin-left: ${({marginLeft}: Props) => marginLeft && marginLeft};
`

const ListOfValueBadges = ({value, color, backgroundColor, padding, marginLeft, underline, width}: Props) => {
  return (
    <Wrapper width={width} value={value} backgroundColor={backgroundColor} color={color} padding={padding} marginLeft={marginLeft} underline={underline}>
        {value}
    </Wrapper>
  )
}

export default memo(ListOfValueBadges)