export type IPhone = {
    countryCode: String,
    num: String
}

export type ITeam = {
    teamId: String,
    name: String,
    parentTeamId?: String,
    description: String
}

export type ITaskAssignedUser = {
    userId: String,
    name: String,
    phone: IPhone,
    teams: Array<ITeam>
}

export type IUserLocation = {
    latitude: number,
    longitude: number,
    accuracy: number,
    collectedAt: Date
}

export enum IJSErrorType {
    REFERENCE_ERROR="ReferenceError",
    TYPE_ERROR = "TypeError",
    SYNTAX_ERROR = "SyntaxError",
    ERROR="Error",
    AXIOS_ERROR="AxiosError",
}