import React, { Component } from 'react';
import T from 'prop-types';
import { FieldTitle } from 'react-admin';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import IconClose from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { debounce } from 'lodash';
import { ZHeader } from 'views/commonComponents/commonComponents.styles';

const InputContainer = styled.div`
  background: var(--Base-White, #FFF);
  z-index: 1000;
  padding-top: 18px;
  margin-bottom: 12px;
  width: 100%;
  position: sticky;
  top: 0;
`

const Input = styled.input`
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  width: 98%;
  font-size: 12px;
  border-radius: 4px;
  margin: 0px;
  margin-left: 6px;
  border: 1px solid var(--Gray-300, #D0D5DD);
  background: var(--Base-White, #FFF);
  z-index: 1000;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
` 

const styles = (theme) => ({
  dialogContent: {
    height: '500px',
    overflowY: 'auto',
    padding: '8px 12px',
    paddingTop: '0px',
    position: 'relative',
  },
  searchInput: {
    margin: theme.spacing(1, 0),
    '& label.Mui-focused': {
      color: theme.palette.primary.main,
    },
    '& .MuiInput-underline:after': { 
      borderBottomColor: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'gray',
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  checkBox: {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: '#3054B9',
    },
  },
  label: {
    fontSize: 'inherit',
  },
  formControlLabel: {
    marginLeft: 0,
    '& .MuiFormControlLabel-label': {
      marginLeft: 0,
      width: '100%',
    },
  },
  dialogPaper: {
    minWidth: '444px',
    maxWidth: '444px',
    overflowX: 'scroll',
  },
});

class TableColumnSelectionDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
    };
    this.debouncedSearch = debounce(this.handleSearch, 300);
  }

  onColumnClicked = ({ target: { value: columnName } }) => {
    this.props.onColumnClicked(columnName);
  };

  handleSearch = (value) => {
    this.setState({ searchTerm: value });
  };

  filterAndSortColumns = () => {
    const { columns } = this.props;
    const { searchTerm } = this.state;

    const filteredColumns = columns
      .filter(({ label }) => label.toLowerCase().includes(searchTerm.toLowerCase()))
      .sort((a, b) => a.label.localeCompare(b.label));

    return filteredColumns;
  };

  handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    this.setState({ searchTerm }, () => {
      this.filterAndSortColumns();
    });
  };

  render() {
    const { classes, selection, onClose, resource } = this.props;
    const filteredAndSortedColumns = this.filterAndSortColumns();

    return (
      <Dialog classes={{ paper: classes.dialogPaper }} aria-labelledby="ra-columns-dialog-title" onClose={onClose} open>
        <Grid>
          <ZHeader>
            <div>
              <DialogTitle id="ra-columns-dialog-title">Table Columns</DialogTitle>
            </div>
            <div>
              <DialogActions>
                <Button onClick={onClose} color="secondary">
                  <IconClose />
                </Button>
              </DialogActions>
            </div>
          </ZHeader>
        </Grid>

        <DialogContent className={classes.dialogContent}>
          <InputContainer>
            <Input autoFocus placeholder='Search Columns' onChange={this.handleSearchChange} />
          </InputContainer>
          <FormGroup>
            {filteredAndSortedColumns.map(({ source, label }) => (
              <FormControlLabel
                key={source}
                classes={{ root: classes.formControlLabel }}
                control={
                  <Checkbox
                    className={classes.checkBox}
                    checked={!!selection[source]}
                    onChange={this.onColumnClicked}
                    value={source}
                  />
                }
                label={<FieldTitle className={classes.label} label={label} source={source} resource={resource} />}
              />
            ))}
          </FormGroup>
        </DialogContent>
      </Dialog>
    );
  }
}

TableColumnSelectionDialog.propTypes = {
  columns: T.arrayOf(
    T.shape({
      label: T.string,
      source: T.string.isRequired,
    }),
  ).isRequired,
  selection: T.object,
  onClose: T.func,
  resource: T.string,
};

TableColumnSelectionDialog.defaultProps = {
  columns: [],
};

export default withStyles(styles)(TableColumnSelectionDialog);
