import { IZorpResponse } from "@zorp/common-libs-js/dist/common/types/types";
import { AxiosResponse } from "axios";
import HttpUtil from "config/AxiosConfig";
import { ZorpResponse } from "providers/data/models/ZorpResponse";
import { getConfig as Config } from "../../../config/envConfig/Config";
import { IConversationType } from "views/monitor/ShopifyMonitor";
import { IDatasourceCategory } from "views/monitor/reducer/MonitorReducer";

export type IMessageData = {
    message?: string;
    monitorName?: string;
    sqlQuery?: string;
    monitorDescription?: string;
    queryDescription?:string;
    emailSubject?: string;
    emailBody?: string;
};

export interface ICreateTemplateFromPromptPayload {
  datasourceId: string,
  datasourceDisplayName: string,
  checkIfLastConversationIsAITemplate: boolean,
  threadId: string,
  isFineTune?: boolean,
  // isLoading: boolean,
  conversationType: IConversationType,
  messageData:IMessageData
}

export interface ICloseConversationPayload {
  threadId: string,
  datasourceId: string,
}

export interface IDataSourcePayload {
  datasourceType: string;
  displayName: string;
  databaseVersion: string;
  name: string;
  host: string;
  port: number;
  username: string;
  password: string | undefined;
  ssl: boolean;
  datasourceCategory: IDatasourceCategory;
}

export interface IRunQueryPayload {
  datasourceId:string;
  query:string;
  type:string;
}

export interface IQueryBuilderFetchColumnsPayload {
  datasourceId:string;
  tableName:string;
  datasourceType:string;
  schema:string;
}

export class MonitorService {
  public static executeGQL = (
    body: any
  ): Promise<ZorpResponse | undefined> => {
    const gqlUrl = Config("services.monitorSvc.api.gql");
    return HttpUtil.monitorGqlInstance
      .post(gqlUrl, { ...body })
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((error) => {
        throw error;
      });
  };

  /**
   * @description Create monitors from templates
   */
  public static createMonitorsFromTemplateIds = (monitorPayload: any) => {
    const createMonitorTemplateUrl = Config("services.monitorSvc.api.createMonitorFromTemplates");

    return HttpUtil.monitorInstance
      .post(createMonitorTemplateUrl, monitorPayload)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((error) => {
        throw error;
      });
  }

  /** 
   * Get All Datasource
   */

  public static getAllDatasource = (): Promise<IZorpResponse> => {
    const getAllDatasourceUrl = Config("services.monitorSvc.api.getAllDatasource");
    return HttpUtil.monitorInstance
      .get(getAllDatasourceUrl)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((error) => {
        throw error;
      });
  }
  
  public static fetchSchema = (datasourceId: string): Promise<IZorpResponse> => {
    const fetchSchemaUrl = (Config("services.monitorSvc.api.fetchTableSchema") as string).replace('{datasourceId}', datasourceId);
    return HttpUtil.monitorInstance
      .get(fetchSchemaUrl)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((error) => {
        throw error;
      });
  }

  public static runQuery = (runQueryPayload: IRunQueryPayload): Promise<IZorpResponse> => {
    const fetchSchemaUrl = Config("services.monitorSvc.api.runQuery");
    return HttpUtil.monitorInstance
      .post(fetchSchemaUrl, runQueryPayload)
      .then((response: AxiosResponse) => {
        // @ts-ignore
        return response?.data || response?.response?.data as ZorpResponse;
      })
      .catch((error) => {
        throw error;
      });
  }
  
  public static getTemplateGenerationProgress = (): Promise<IZorpResponse> => {
    const templateSuggestionProgressData = Config("services.monitorSvc.api.templateGenerationProgress");
    return HttpUtil.monitorInstance
      .get(templateSuggestionProgressData)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((error) => {
        throw error;
      });
  }


  public static fetchMonitorMockData = (monitorId:string): Promise<ZorpResponse | undefined> => {
    const fetchmonitorUrl = (Config("services.monitorSvc.api.fetchMonitorMockData") as string).replace('{monitorId}', monitorId);
    return HttpUtil.monitorInstance.get(fetchmonitorUrl).then((response: AxiosResponse) => {
      return response?.data as ZorpResponse;
    });
  }

  public static updateMonitorMockData = (monitorId:string, payload:any): Promise<ZorpResponse | undefined> => {
    const updateMonitorMockDataPayload = {
      monitorId, payload
    }

    const updateMonitorUrl = (Config("services.monitorSvc.api.updateMonitorMockData") as string).replace('{monitorId}', monitorId)
    return HttpUtil.monitorInstance.put(updateMonitorUrl, updateMonitorMockDataPayload).then((response: AxiosResponse) => {
      return response?.data as ZorpResponse;
    });
  }

  public static executeMonitorAutomation = (payload:any): Promise<ZorpResponse | undefined> => {
    return HttpUtil.monitorInstance
      .post(`/automation/execute/${payload?.monitorId}`, payload)
      .then((response: AxiosResponse) => {
        return response;
      }).catch((error:any)=>{
        return error?.response || 'Something went wrong!';
      })
  }

  public static executeMonitor(payload: any): Promise<ZorpResponse | undefined> {
    return HttpUtil.cronInstance
      .post("/run/cronjob", payload)
      .then((response: AxiosResponse) => {
        return response;
      }).catch((error: any) => {
        return error?.response || 'Something went wrong!';
      })
  }

  public static executeTemplateMonitor = (templateId:string): Promise<ZorpResponse | undefined> => {
    const fetchmonitorUrl = (Config("services.monitorSvc.api.executeTemplateMonitor") as string).replace('{templateId}', templateId);
    return HttpUtil.monitorInstance.get(fetchmonitorUrl).then((response: AxiosResponse) => {
      return response?.data as ZorpResponse;
    });
  }

  // fetch all conversations
  public static fetchAllConversations = (datasourceId:string): Promise<ZorpResponse | undefined> => {
    const fetchAllConversationsUrl = (Config("services.monitorSvc.api.fetchAllConversations") as string).replace('{datasourceId}', datasourceId);
    return HttpUtil.monitorInstance.get(fetchAllConversationsUrl).then((response: AxiosResponse) => {
      return response?.data as ZorpResponse;
    });
  }

  // template from prompt
  public static createTemplateFromPrompt = (payload:ICreateTemplateFromPromptPayload): Promise<ZorpResponse | undefined> => {
    const createTemplateFromPromptUrl = Config("services.monitorSvc.api.templateFromPrompt");
    return HttpUtil.monitorInstance.post(createTemplateFromPromptUrl, payload).then((response: AxiosResponse) => {
      return response?.data as ZorpResponse;
    });
  }

  // close the conversation

  public static closeConversation = (closeConversationPayload : ICloseConversationPayload): Promise<ZorpResponse | undefined> => {
    const closeConversationUrl = Config("services.monitorSvc.api.closeConversation");
    return HttpUtil.monitorInstance.post(closeConversationUrl, closeConversationPayload).then((response: AxiosResponse) => {
      return response?.data as ZorpResponse;
    });
  }

  // add datasource
  public static addDatasource = (payload:IDataSourcePayload): Promise<ZorpResponse | undefined> => {
    const addDatasourceUrl = Config("services.monitorSvc.api.addDatasource");
    return HttpUtil.monitorInstance.post(addDatasourceUrl, payload).then((response: AxiosResponse) => {
      // @ts-ignore
      return response?.data || response?.response?.data as ZorpResponse;
    });
  }

  // update datasource
  public static updateDatasource = (payload:IDataSourcePayload, datasourceId:string): Promise<ZorpResponse | undefined> => {
    const updateDatasourceUrl = (Config("services.monitorSvc.api.updateDatasource") as string).replace('{datasourceId}', datasourceId);
    return HttpUtil.monitorInstance.patch(updateDatasourceUrl, payload).then((response: AxiosResponse) => {
      // @ts-ignore
      return response?.data || response?.response?.data as ZorpResponse;
    });
  }

  // test connection
  public static testDatasourceConnection = (payload:IDataSourcePayload): Promise<ZorpResponse | undefined> => {
    const testDatasourceConnectionUrl = Config("services.monitorSvc.api.testConnection");
    return HttpUtil.monitorInstance.post(testDatasourceConnectionUrl, payload).then((response: AxiosResponse) => {
      // @ts-ignore
      return response?.data || response?.response?.data as ZorpResponse;
    });
  }

  public static testMonitor = (monitorTestPayload:any): Promise<ZorpResponse | undefined> => {
    const testMonitorUrl = Config("services.monitorSvc.api.testMonitor");
    return HttpUtil.monitorInstance.post(testMonitorUrl, monitorTestPayload).then((response: AxiosResponse) => {
      return response?.data || response?.response?.data as ZorpResponse;
    });
  }

  // create Monitor

  public static createMonitor = (monitorTestPayload:any): Promise<ZorpResponse | undefined> => {
    const createMonitorUrl = Config("services.monitorSvc.api.createMonitor");
    return HttpUtil.monitorInstance.post(createMonitorUrl, monitorTestPayload).then((response: AxiosResponse) => {
      return response?.data || response?.response?.data as ZorpResponse;
    });
  }

    // fetch Monitor
    public static getMonitor = (): Promise<ZorpResponse | undefined> => {
      const createMonitorUrl = Config("services.monitorSvc.api.getMonitor");
      return HttpUtil.monitorInstance.get(createMonitorUrl).then((response: AxiosResponse) => {
        return response?.data || response?.response?.data as ZorpResponse;
      });
    }

  // update monitor
  public static updateMonitor = (monitorTestPayload:any, monitorId:string): Promise<ZorpResponse | undefined> => {
    const updateMonitorUrl = (Config("services.monitorSvc.api.updateMonitor") as string).replace('{monitorId}', monitorId);
    return HttpUtil.monitorInstance.put(updateMonitorUrl, monitorTestPayload).then((response: AxiosResponse) => {
      return response?.data || response?.response?.data as ZorpResponse;
    });
  }

  public static queryBuilderFetchColumns = (queryBuilderFetchColumnsPayload:IQueryBuilderFetchColumnsPayload): Promise<ZorpResponse | undefined> => {
    const fetchColumnsUrl = Config("services.monitorSvc.api.queryBuilderFetchColumns")
    return HttpUtil.monitorInstance.post(fetchColumnsUrl, queryBuilderFetchColumnsPayload).then((response: AxiosResponse) => {
      return response?.data as ZorpResponse;
    });
  }

  public static getQueryFromBuilder = (getQueryFromBuilder:any): Promise<ZorpResponse | undefined> => {
    const getQueryFromBuilderUrl = Config("services.monitorSvc.api.get_query_from_builder")
    return HttpUtil.monitorInstance.post(getQueryFromBuilderUrl, getQueryFromBuilder).then((response: AxiosResponse) => {
      return response?.data as ZorpResponse;
    });
  }

  // get cron description and next run
  public static getCronDescription = (cronDescriptionPayload:any): Promise<ZorpResponse | undefined> => {
    const getCronDescriptionUrl = Config("services.monitorSvc.api.cron_description")
    return HttpUtil.monitorInstance.post(getCronDescriptionUrl, cronDescriptionPayload).then((response: AxiosResponse) => {
      return response?.data as ZorpResponse;
    });
  }

  // create uninitiated monitor
  public static createUnInitiatedMonitor = (): Promise<ZorpResponse | undefined> => {
    const createUnInitiatedMonitorUrl = Config("services.monitorSvc.api.create_uninitiated_monitor")
    return HttpUtil.monitorInstance.post(createUnInitiatedMonitorUrl).then((response: AxiosResponse) => {
      return response?.data as ZorpResponse;
    });
  }
}