import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import * as React from 'react';
import { useTheme } from 'styled-components';

export const OnboardingTooltip = styled(({ className, ...props }) => <Tooltip placement='down' {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor:'#3e66fb',
      color: '#ffffff',
      maxWidth: 300,
      fontSize: "14px",
      borderRadius: '4px',
      padding: "20px",

    },
  })
);
