import React from "react";
import { FunctionComponent, memo, useEffect, useState } from "react";
import { ITaskTypeWebRenderModel } from "./models/ITaskTypeWebRenderModel";
import { IRenderComponentParams } from "./models/ITaskTypeWebBodyRenderModel";
import { ComponentType } from "components/ComponentTypeEnum";
import { BlockDiv, BlockLabel } from "./render.styles";
import { ZComment } from "components/comment/ZComment";
import { ShowPageTabs } from "./ShowPageTabs";
import useTaskShow from "views/workflows/hooks/useTaskShow";
import RecordLogStepper from "logs/RecordLogStepper";
import { AiOutlineArrowRight } from "react-icons/ai";
import Logs from "logs";
import SummaryComponent from "./show/SummaryComponent";
import { markdownText } from "constants/CommonConstants";
import { ZButton } from "../views/commonComponents/commonComponents.styles";
import { BffService } from "../providers/data/services/BffService";
import notification from "../notifications/notifications";
import { TaskService } from "../providers/data/services/TaskService";

const RenderComponent: FunctionComponent<{
  componentParams: IRenderComponentParams;
  entityCategory?: string;
  entityId?: string;
  entityType?: string;
  showComments?: boolean;
  setShowComments?: (showComments: boolean) => void;
  initRenderConfig?: ITaskTypeWebRenderModel;
  showRecordLogs?: boolean;
  setShowRecordLogs?: any;
  showSummary?: boolean;
  setShowSummary?: any;
  mode?: string;
  hideInfoPanel?: boolean;
  setHideInfoPanel?: any;
}> = ({
  componentParams,
  entityCategory,
  entityId,
  entityType,
  showComments,
  setShowComments,
  showRecordLogs,
  setShowRecordLogs,
  showSummary,
  setShowSummary,
  initRenderConfig,
  hideInfoPanel = false,
  setHideInfoPanel,
}) => {
  const [summary, setSummary] = useState<any>("");
  const [fetchingSummary, setFetchingSummary] = useState<boolean>(false);
  const [isCommentEnabled, setIsCommentEnabled] = useState(false);
  const [commentNode, setCommentNode] = useState<any>({});
  const { taskShowLayoutMetadata, taskRenderDetails } = useTaskShow();
  const mode = window.location.href.includes("show")
    ? "show"
    : window.location.href.includes("create")
    ? "create"
    : "transit";

  useEffect(() => {
    let renderConfig;
    if (initRenderConfig) {
      renderConfig = [...initRenderConfig?.tabs];
    } else {
      renderConfig = [...taskRenderDetails?.tabs];
    }

    let newRenderConfig = [...(renderConfig || [])]; // creating a new array to avoid mutation
    newRenderConfig?.forEach((tab, tabIndex) => {
      tab?.sections?.forEach((section: any) => {
        section?.rows?.forEach((row: any) => {
          row?.forEach((node: any) => {
            if (node?.type === ComponentType.COMMENT) {
              setIsCommentEnabled(true);
              setCommentNode({
                ...node,
                entityCategory,
                entityType,
                entityId,
              });
              newRenderConfig.splice(tabIndex, 1); // removing the block from the new array
            }
          });
        });
      });
    });
  }, []);

  // const updateSummary = async () => {
  //   try {
  //     setFetchingSummary(true);
  //     const res = await BffService.refereshSummary({
  //       taskId: entityId,
  //       taskType: entityType,
  //       automationId:"custom-auto-id"
  //     })
  //     // replace `summary` with org datafield
  //     setSummary(res?.task?.data?.['ai_summary']);
  //     setFetchingSummary(false);
  //   } catch (error) {
  //     notification("error", error.message);
  //     setFetchingSummary(false);
  //   }
  // }

  const fetchTaskData = async () => {
    try {
      const res = await TaskService.getTask(entityId || "");
      setSummary(res?.data?.data?.['ai_summary'] || res?.data?.data?.['AI_Summary']);
    } catch (error) {
      console.log("Error", error);
    }
  }

  useEffect(() => {
    fetchTaskData();
  },[])
    

  return (
    <>
      {taskRenderDetails && (
        <div style={{ height: "100%" }}>
          {initRenderConfig ? (
            <ShowPageTabs
              renderConfig={initRenderConfig?.tabs}
              componentParams={componentParams}
              setShowComments={setShowComments}
              setShowRecordLogs={setShowRecordLogs}
              setShowSummary={setShowSummary}
              setHideInfoPanel={setHideInfoPanel}
              entityCategory={entityCategory}
              entityId={entityId}
            />
          ) : (
            <ShowPageTabs
              renderConfig={taskRenderDetails?.tabs}
              componentParams={componentParams}
              setShowComments={setShowComments}
              setShowRecordLogs={setShowRecordLogs}
              setShowSummary={setShowSummary}
              setHideInfoPanel={setHideInfoPanel}
              entityCategory={entityCategory}
              entityId={entityId}
            />
          )}
        </div>
      )}

      {showComments && !hideInfoPanel && (
        <div
          style={{
            borderLeft: "1px solid #eaecf0",
            height: "100%",
          }}
          className={`col-span-1 ${
            showComments && !hideInfoPanel ? "visible" : ""
          }`}
        >
          <BlockLabel
            style={{
              padding: "13.5px",
              borderBottom: "1px solid #E0E0E0",
              height: "44px",
            }}
          >
            <div
              style={{
                display: "inline-block",
                marginRight: "8px",
                cursor: "pointer",
              }}
              onClick={() => {
                setHideInfoPanel(true);
              }}
            >
              <AiOutlineArrowRight
                style={{
                  display: "inline-block",
                  color: "#667085",
                }}
                size="1.2rem"
              />{" "}
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: 500,
                  color: "#667085",
                }}
              >
                Collapse
              </span>
            </div>
            Comments
          </BlockLabel>
          <BlockDiv
            style={{
              border: "none",
              boxShadow: "none",
              padding: "16px",
              height: "calc(100vh - 108px)",
              overflow: "auto",
            }}
          >
            <ZComment config={commentNode}></ZComment>
          </BlockDiv>
        </div>
      )}
      {/* {showRecordLogs && !hideInfoPanel && ( */}
      {showRecordLogs && !hideInfoPanel && mode === "show" && (
        <div
          style={{
            borderLeft: "1px solid #eaecf0",
            height: "100%",
          }}
          className={`col-span-1 ${
            showRecordLogs && !hideInfoPanel ? "visible" : ""
          }`}
        >
          <BlockLabel
            style={{
              padding: "13.5px",
              borderBottom: "1px solid #E0E0E0",
              height: "44px",
            }}
          >
            <div
              style={{
                display: "inline-block",
                marginRight: "8px",
                cursor: "pointer",
              }}
              onClick={() => {
                setHideInfoPanel(true);
              }}
            >
              <AiOutlineArrowRight
                style={{
                  display: "inline-block",
                  color: "#667085",
                }}
                size="1.2rem"
              />{" "}
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: 500,
                  color: "#667085",
                }}
              >
                Collapse
              </span>
            </div>
            Logs
          </BlockLabel>
          <BlockDiv
            style={{
              border: "none",
              boxShadow: "none",
              padding: "16px",
              height: "calc(100vh - 108px)",
              overflow: "auto",
            }}
          >
            {entityCategory === "TASK" ? (
              <Logs taskId={entityId} type="task" />
            ) : (
              <RecordLogStepper recordId={entityId || ""} />
            )}
          </BlockDiv>
        </div>
      )}
      {showSummary && !hideInfoPanel && (
        <div
          style={{
            borderLeft: "1px solid #eaecf0",
            height: "100%",
          }}
          className={`col-span-1 ${
            showSummary && !hideInfoPanel ? "visible" : ""
          }`}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "6px 8px",
              borderBottom: "1px solid rgb(224, 224, 224)",
              alignItems: "center"
            }}
          >
            <BlockLabel
            >
              <div
                style={{
                  display: "inline-block",
                  marginRight: "8px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setHideInfoPanel(true);
                }}
              >
                <AiOutlineArrowRight
                  style={{
                    display: "inline-block",
                    color: "#667085",
                  }}
                  size="1.2rem"
                />{" "}
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: 500,
                    color: "#667085",
                  }}
                >
                  Collapse
                </span>
              </div>
              Summary
            </BlockLabel>
            {/* <ZButton
              variant="contained"
              onClick={updateSummary}
            >
              Refresh
            </ZButton> */}
          </div>

          <BlockDiv
            style={{
              border: "none",
              boxShadow: "none",
              padding: "16px",
              height: "calc(100vh - 108px)",
              overflow: "auto",
            }}
          >
            {
              fetchingSummary ? "Loading..." : <SummaryComponent markdown={summary || markdownText} />
            }
          </BlockDiv>
        </div>
      )}

      {/* )} */}
      {/* <p> CAJCNASJCBASCHAS CAS CJHASCBASCAJCBASC</p> */}
    </>
  );
};

export const RenderV2 = memo(RenderComponent);