import { FunctionComponent, useCallback, useState, useRef } from "react";
import { useFieldArray } from "react-hook-form";
import { IZListInterface, HeaderType } from "./IZListInterface";
import { useEffect } from "react";
import _ from "lodash";
import Box from "@mui/material/Box";
import DeletionConfirmationDialog from "utils/DeletionConfirmationDialog.js";
import { deleteSubfield } from "components/listDatafield/utils";
import {
  DataGrid,
  GridRenderCellParams,
  GridCellParams,
  GridRowParams,
  GridCellEditStopParams,
  MuiEvent,
  GridCellEditStopReasons,
} from "@mui/x-data-grid";
import { ComponentType } from "../ComponentTypeEnum";
import { useDispatch, useStore } from "react-redux";
import {
  loadMetaData,
  loadUIElements,
  loadListData,
} from "render-engine/reducers/listInfo/index";
import CreateSubFieldModal from "./CreateSubFieldModal";
import useListInfo from "views/workflows/hooks/useListInfo";
import { useLocation, useParams } from "react-router-dom";
import { IZComponentPropType } from "render-engine/models/ITaskTypeWebBodyRenderModel";
import { isEqual } from "lodash";
import styled from "styled-components";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import moment from "moment";
import { ImageModal } from "components/ZImageViewer/ZShowImage";
import ImageSlider from "components/imageviewer/ImageSlider";
import { DataFieldType } from "views/DataField/DataField.types";
import notification from "notifications/notifications";
import { recordRSEvent } from "utils/CommonUtils";
import { setLoading } from "views/reducer";
import { TaskService } from "views/tasks/services/TaskService";
import useTaskEdit from "views/workflows/hooks/useTaskEdit";
import useTaskShow from "views/workflows/hooks/useTaskShow";
import { setTaskData } from "render-engine/reducers/show/TaskShowReducer";
import { BlockLabel } from "render-engine/render.styles";
import { BiEdit } from "react-icons/bi";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoMdArrowDown, IoMdArrowUp } from "react-icons/io";
import {AiOutlineArrowDown} from "react-icons/ai";
import { Position } from "react-flow-renderer";

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3px;
  padding: 8px 16px;
`;

const IconText = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: #344054;
`;

const IconWrapper = styled.div`
  padding: 4px;
  border-radius: 4px;
  // on hover
  &:hover {
    background: #f2f4f7;
  }
`;

const ScrollIndicator = styled.div<{ isVisible: boolean }>`
  position: absolute;
  bottom: ${(props) => (props.isVisible ? "10px" : "-40px")};
  left: 50%;
  transform: translateX(-50%);
  opacity: ${(props) => (props.isVisible ? "0.5" : "0")};
  transition: opacity 0.3s, bottom 0.3s;
  pointer-events: none;
`;

type SubfieldModalMode = "create" | "edit";

const ZList: FunctionComponent<IZComponentPropType> = ({
  config,
  onChange,
  register,
  errors,
  control,
  setValue,
  getValues,
  ...props
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [showCreateSubFieldModal, setShowCreateSubFieldModal] = useState(false);
  const [activeEditListSubfield, setActiveEditListSubfield] = useState({});
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [mode, setMode] = useState<SubfieldModalMode>("create");
  const { taskId } = useTaskEdit({});

  const store = useStore();

  const { listData } = useListInfo({ name: config?.key });

  const { fields, append, update, remove } = useFieldArray({
    name: config.key,
    control,
  });

  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [showImageModal, setShowImageModal] = useState<boolean>(false);
  const [linkArray, setLinkArray] = useState<any[]>([]);
  const { convertDataTypeToString } = useTaskShow();
  const value = convertDataTypeToString(config.key, false, []);
  const [isBottomVisible, setIsBottomVisible] = useState(true);

  const gridRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    dispatch(
      loadMetaData({ name: config.key, val: config?.value?.modifyMetadata })
    );
    dispatch(
      loadUIElements({ name: config?.key, val: config?.value?.uiElements })
    );
    dispatch(loadListData({ name: config?.key, val: config?.value?.listData }));

    let alreadyCreatedItems: any = [];
    //@ts-ignore
    config.value.items.forEach((item: IZListInterface[], idx: number) => {
      //@ts-ignore
      // let singleRow = { id: generateId(6) };
      let singleRow = {};
      item.forEach(
        (singleItem: IZListInterface) =>
          (singleRow = {
            ...singleRow,
            // TODO: GET this change reverted.
            //@ts-ignore
            [singleItem.key]: singleItem.value.val ?? singleItem.value,
          })
      );
      // append(singleRow);
      //@ts-ignore
      singleRow.id = idx;
      alreadyCreatedItems.push(singleRow);
    });
    remove();
    value?.forEach((item: any, idx: number) => {
      // adding index to make sure the useFormArray function works fine
      let newItem = { ...item, id: idx };
      append(newItem);
    });
  }, []);

  useEffect(() => {
    console.log({gridref: gridRef?.current})
    const handleScroll = () => {
      if (gridRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = gridRef.current;
        setIsBottomVisible(scrollTop + clientHeight < scrollHeight);
      }
    };
  
    if (gridRef.current) {
      gridRef.current.addEventListener('scroll', handleScroll);
      handleScroll();
    }
  
    return () => {
      if (gridRef.current) {
        gridRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [gridRef.current]);  

  //@ts-ignore
  const returnGroupOfDivs = (arr, name: string) => {
    // const rows = [];
    // //@ts-ignore
    // for (let i = 0; i < arr.length; i += 2) {
    //   const temp = [arr[i], arr[i + 1]];
    //   rows.push(formGroupOfDivs(temp, i));
    // }

    // remove all undefined values from arr
    arr = arr.filter((item: any) => item);

    if (arr.length > 0) {
      return [
        <p style={{ color: "blue" }}>
          {" "}
          Click to View {name.toLocaleLowerCase()}s{" "}
        </p>,
      ];
    } else {
      return [<></>];
    }

    // console.log(rows)
  };
  //@ts-ignore
  const formGroupOfDivs = (arr, startIdx) => {
    return (
      <div>
        {/* {arr.map(
          //@ts-ignore
          (item, idx) =>
            item && (
              <a target="_blank" style={{ color: "blue" }} href={item}>
                {" "}
                Image {idx + startIdx + 1},{" "}
              </a>
            )
        )} */}
        <p style={{ color: "blue" }}> Click to View Images </p>
      </div>
    );
  };

  const columns = config.value.headers.map((header: HeaderType) => ({
    field: header.name,
    headerName: header?.label || header.name,
    // width: Math.max(WIDTH / numberOfHeaders, 200),
    // flex: 1,
    minWidth: Math.max(250, header.name?.length * 10 || 0),
    cellClassName: (params: GridCellParams<any>) => {
      if (
        header.type === ComponentType.IMAGEVIEWER ||
        header.type === ComponentType.IMAGE ||
        header.type === ComponentType.FILE ||
        header.type === ComponentType.SIGNATURE
      ) {
        return "image-viewer";
      } else {
        return "";
      }
    },
    renderCell: (params: GridRenderCellParams<any>) => {
      if (
        header.type === ComponentType.IMAGEVIEWER ||
        header.type === ComponentType.IMAGE ||
        header.type === ComponentType.FILE ||
        header.type === ComponentType.SIGNATURE
      ) {
        if (!Array.isArray(params.value)) {
          params.value = [params.value];
        }
        return returnGroupOfDivs(params.value || [], header.type).map(
          (item) => item
        );
        //@ts-ignore
      } else if (header.type === "LOCATION") {
        if (params.value?.address) {
          return <p> {params.value.address} </p>;
        } else {
          return (
            <p>
              {" "}
              {params.value.latitude}, {params.value.longitude}
            </p>
          );
        }
      } else if (header.type === ComponentType.DATE) {
        const date = params.value ? new Date(params.value) : "";
        return <p> {date && moment(date).format("Do MMM YYYY , h:mm A")} </p>;
      } else {
        <p> {params.value} </p>;
      }
    },
    dataType: header.type,
    editable:
      (location.pathname.includes("show") ||
        location.pathname.includes("transit")) &&
      (header.type === ComponentType.TEXT ||
        header.type === ComponentType.NUMBER)
        ? true
        : false,
  }));

  const rows: any[] =
    value.map((val: any, index: number) => {
      return {
        ...val,
        id: index,
      };
    }) || [];

  const deleteData = () => {
    deleteSubfield({
      //@ts-ignore
      id: activeEditListSubfield.id ?? "",
      name: config.key,
      location: location.pathname,
      dispatch: dispatch,
    });

    //@ts-ignore
    remove(activeEditListSubfield.id);
    // update value
    const updatedValue = getValues()?.[config.key] || [];

    dispatch(
      setTaskData({
        nodeId: config.key,
        data: updatedValue,
      })
    );

    setShowDeleteButton(false);
    setActiveEditListSubfield({});
  };

  const handleRowClick = (params: GridRowParams) => {
    if (isEqual(params.row, activeEditListSubfield)) {
      setActiveEditListSubfield({});
      setShowDeleteButton(false);
      setSelectedRows([]);
    } else {
      setActiveEditListSubfield(params.row);
      setShowDeleteButton(true);
      setSelectedRows([params.id]);
    }
  };

  const handleCellClick = (params: any) => {
    if (_.isString(params.value) && params.value.includes("https")) {
      const allLinks = _.cloneDeep([params.value]);
      allLinks.forEach((link: string, index: number) => {
        allLinks[index] = {
          url: link,
          title: `Image ${index}`,
        };
      });
      setShowImageModal(true);
      setLinkArray([...allLinks]);
    } else if (_.isArray(params.value) && params.value.length > 0) {
      if (params.value[0]?.includes("https")) {
        const allLinks = _.cloneDeep(params.value);
        allLinks.forEach((link: string, index: number) => {
          allLinks[index] = {
            url: link,
            title: `Image ${index}`,
          };
        });
        setShowImageModal(true);
        setLinkArray([...allLinks]);
      }
    }
  };

  const style = {
    // height: rows.length === 0 ? 192 : (rows.length * 52) + 136,
    position: "relative",
    width: "100%",
    "& .MuiDataGrid-columnSeparator": {
      display: "none !important",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontSize: "14px !important",
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#f0f2f3",
    },
    "& .image-viewer": {
      display: "block !important",
      fontSize: "14px !important",
    },
  };

  // Task Edit Final Function
  const submitTaskEdit = (data: any) => {
    /**
     * Removing location dataType key if the address, latitude and longitude are undefined
     */
    // loop through all the keys of data
    const dataKeys = Object.keys(data);
    // loop through dataKeys and check if the value is an object
    dataKeys.forEach((key) => {
      if (typeof data[key] === "object") {
        // get all the keys of the object
        const objectKeys = Object.keys(data[key]);
        // check if ojectKey has a value called address, latitude and longitude
        if (
          objectKeys.includes("address") &&
          objectKeys.includes("latitude") &&
          objectKeys.includes("longitude")
        ) {
          // check if the value of address, latitude and longitude is undefined
          if (
            (data[key].address === undefined || data[key].address === "") &&
            data[key].latitude === undefined &&
            data[key].longitude === undefined
          ) {
            // if yes, then delete the key from data
            delete data[key];
          }
        }
      }
    });

    const taskEditPayload: any = {
      taskId,
      taskType: store.getState().taskEdit.taskType,
      teamIds: store.getState().taskEdit.teamIds,
      scheduledSlot: store.getState().taskEdit.scheduledSlot,
      data: data,
      mode: "single",
      channel: "CC",
    };

    // const omitFieldsInData: Array<string> = ['scheduledSlot', 'taskType', 'teamIds'];
    // for (let key of Object.keys(store.getState().taskEdit)) {
    //   // Skip the omit fields for data
    //   if (!omitFieldsInData.includes(key)) taskEditPayload.data[key] = store.getState().taskEdit[key];
    // }

    TaskService.updateTask(taskEditPayload)
      .then((res: any) => {
        if (res?.code) {
          if (res?.code.indexOf("200") < 0) {
            // setDisableEditButton(false);
            // editTaskForm.reset();
            notification("error", res.message);
          } else {
            notification("success", "Task Edited successfully");
            recordRSEvent("save_event_success", {
              context: "task_edit",
              taskId,
              taskType: taskEditPayload.taskType,
            });
          }
          // notification('success', res.message);
          // dispatch(cancelTaskEdit());
          // const preview = localStorage.getItem("isPreview");
          // if(preview){
          //   //@ts-ignore
          //   window.parent.postMessage(
          //     {
          //       source: "zorp_web_preview",
          //       name:"edit_task",
          //       taskId: props?.data?.id,
          //     }, "*");
          //   history.push(`/tasks/${props.record.id}/show/?preview=true`);
          // }

          // else
          //   history.push(`/tasks/${props.record.id}/show`);
          // localStorage.removeItem("isPreview");
          // }
        }
      })
      .catch((err) => {
        // setDisableEditButton(false);
        // editTaskForm.reset();
        console.error("Error", err);
        dispatch(setLoading(false));
        recordRSEvent("save_event_fail", {
          context: "task_edit",
          taskId,
          taskType: taskEditPayload.taskType,
        });
        notification("error", "Task cannot be edited!");
      });
  };

  const handleRowEditCommit = useCallback(async (params) => {
    if (!params.colDef) return;
    const initialRowValueBeforeEdit = { ...params.row };
    const rowId = params.id;

    // get the field being edited
    const fieldBeingEdited = params.field;

    // get the new value of the field
    let newValue = params.value;

    // if colDef.dataType is NUMBER, convert newValue to number
    const dataType = params.colDef.dataType;
    if (dataType === DataFieldType.NUMBER) {
      // if the value cannot be converted, throw error
      if (isNaN(newValue)) {
        notification("error", "Value must be a number");
        return
      } else {
        if (newValue === "") {
          newValue = undefined;
        } else {
          newValue = parseInt(newValue);
        }
        
        // 123 => "123"
      }
    }

    // update the initialRowValueBeforeEdit with the new value
    initialRowValueBeforeEdit[fieldBeingEdited] = newValue;
    //remove id from initialRowValueBeforeEdit
    delete initialRowValueBeforeEdit.id;
    // const formValue = getValues();

    // // update the formValue with the new value
    // formValue[config.key][rowId] = initialRowValueBeforeEdit;

    // setValue(`${config.key}.${rowId}`, initialRowValueBeforeEdit);
    update(rowId, initialRowValueBeforeEdit);

    // try {
    //   const res = await submitTaskEdit(formValue);
    //   // notification("success", "Task Edited successfully");
    // } catch (error) {
    //   console.log(">>>> error", error);
    //   notification("error", "Task cannot be edited!");
    // }
    const updatedValue = getValues()[config.key];
    dispatch(
      setTaskData({
        nodeId: config.key,
        data: updatedValue,
      })
    );
  }, []);

  const actionMode = window.location.href.includes("show")
    ? "show"
    : window.location.href.includes("create")
    ? "create"
    : "transit";

  return (
    <>
      {showCreateSubFieldModal && (
        <CreateSubFieldModal
          mode={mode}
          showModal={showCreateSubFieldModal}
          setShowModal={setShowCreateSubFieldModal}
          name={config.key}
          location={location.pathname}
          activeEditListSubfield={activeEditListSubfield}
          register={register}
          control={control}
          setValue={setValue}
          getValues={getValues}
          append={append}
          update={update}
          remove={remove}
        />
      )}

      <Box sx={style}>
        <>
          <Wrapper>
            <BlockLabel>
              {config.section.label}

              <BiEdit
                style={{
                  display: "inline",
                  marginLeft: "8px",
                }}
                size="14px"
                onClick={() => config.setShowCreateSectionModal(true)}
              />
            </BlockLabel>
            <div
              style={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ButtonContainer>
                {config?.value?.allowElementCreate && (
                  <ZButton
                    onClick={() => {
                      setMode("create");
                      setShowCreateSubFieldModal(true);
                    }}
                    variant="contained"
                    type="button"
                  >
                    Create Item
                  </ZButton>
                )}

                {showDeleteButton && (
                  <>
                    <ZButton
                      type="button"
                      onClick={() => {
                        setMode("edit");
                        setShowCreateSubFieldModal(true);
                      }}
                      secondary
                      variant="contained"
                      className="ml-2"
                    >
                      Edit
                    </ZButton>

                    <ZButton
                      type="button"
                      onClick={() => setShowDelete(true)}
                      danger
                      variant="contained"
                      className="ml-2"
                    >
                      Delete
                    </ZButton>
                  </>
                )}
              </ButtonContainer>
              {actionMode !== "transit" && (
                <Menu
                  menuClassName="my-menu"
                  active
                  direction="bottom"
                  menuButton={
                    <IconWrapper>
                      <BsThreeDotsVertical size="12px" strokeWidth={1} />
                    </IconWrapper>
                  }
                  transition
                  // direction="left"
                  gap={12}
                  align="start"
                >
                  <MenuItem
                    onClick={() => config.onSectionMove("UP")}
                    className="menuItem-s"
                    disabled={config.isFirst}
                  >
                    <IconWrapper>
                      <IoMdArrowUp size="14px" />
                    </IconWrapper>
                    <IconText> Move Up </IconText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => config.onSectionMove("DOWN")}
                    className="menuItem-s"
                    disabled={config.isLast}
                  >
                    <IconWrapper>
                      <IoMdArrowDown size="14px" />
                    </IconWrapper>
                    <IconText> Move Down </IconText>
                  </MenuItem>
                </Menu>
              )}
            </div>
          </Wrapper>
          <DeletionConfirmationDialog
            id={"subfieldRow"}
            deletionTitle={"Delete this row?"}
            deletionText={"Do you want to delete this row?"}
            isOpen={showDelete}
            onConfirmDelete={() => deleteData()}
            onClose={() => setShowDelete(false)}
          />
        </>
        <div style={{ maxHeight: "350px", overflow: "auto" }} ref={gridRef}>
        <DataGrid
          autoHeight
          sx={{
            "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
              py: "8px",
            },
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              py: "15px",
            },
            "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
              py: "22px",
            },
            "& .MuiDataGrid-cellContent": {
              fontSize: "14px",
            },
            "& .MuiDataGrid-footerContainer": {
              display: "none",
            },
          }}
          rows={rows}
          columns={columns}
          getRowId={(row) => row.id}
          rowsPerPageOptions={[]}
          onRowClick={handleRowClick}
          onCellClick={handleCellClick}
          getRowHeight={() => "auto"}
          selectionModel={selectedRows}
          onCellEditCommit={handleRowEditCommit}
          onCellEditStop={(
            params: GridCellEditStopParams,
            event: MuiEvent<React.SyntheticEvent<Element, Event>>,
            details: any
          ) => {
            if (
              params &&
              params.colDef &&
              event.target &&
              event.target.value !== undefined
            ) {
              let newValue = event.target.value;
              if (params.colDef.dataType === DataFieldType.NUMBER) {
                if (isNaN(newValue)) {
                  notification("error", "Value must be a number");
                  throw new Error("Value must be a number");
                } else {
                  newValue = parseInt(newValue);
                }
              }
              setValue(`${config.key}.${params.id}.${params.field}`, newValue);
              dispatch(
                setTaskData({
                  nodeId: config.key,
                  data: fields,
                })
              );
            }
          }}
          onSelectionModelChange={(selectedRows) => setSelectedRows([])}
        />
        </div>
        <ScrollIndicator isVisible={isBottomVisible}>
          <AiOutlineArrowDown size={16} />
        </ScrollIndicator>
      </Box>
      <ImageModal
        open={showImageModal}
        onClose={() => setShowImageModal(false)}
      >
        <ImageSlider
          // @ts-ignore
          onClose={() => setShowImageModal(false)}
          startIndex={0}
          slides={linkArray}
        />
      </ImageModal>
    </>
  );
};

export default ZList;
