import { CONTEXT_SWITCHING, CONTEXT_SWITCHING_CONFIRMATION } from 'constants/CommonMessages';
import { RudderStackAutomationBuilderContext, RudderStackAutomationBuilderEvents } from 'constants/RudderStackConstants';
import { useEffect, useState } from 'react';
import { BiPaste } from "react-icons/bi";
import { useDispatch } from 'react-redux';
import { recordRSEvent } from 'utils/CommonUtils';
import DeletionConfirmationDialog from 'utils/DeletionConfirmationDialog';
import { generateId } from 'utils/Utils';
import useAction from 'views/actions/useAction';
import { ABNameListWrapper, AutomationEmptyText, CreateNewAutomationWrapper } from 'views/automationBuilder/automationBuilder.styles';
import EditAutomationModal from 'views/automationBuilder/components/EditAutomationModal';
import useAutomation from 'views/automationBuilder/hooks/useAutomation';
import { addAutomation, setSelectedAutomation, updateAutomationName } from 'views/automationBuilder/reducer/automationReducer';
import { ABMetadataTypeEnum, IActionBlock, IAutomation } from 'views/automationBuilder/types/ABType';
import { ZButton } from 'views/commonComponents/commonComponents.styles';
import useWorkFlow from 'views/workflows/hooks/useWorkFlow';
import './automationbuilder.css';
import AutomationList from './components/AutomationList';
export enum IChangeContext {
  CREATE_AUTOMATION = 'CREATE_AUTOMATION',
  CHANGE_AUTOMATION = 'CHANGE_AUTOMATION'
}

const CreatedAutomations = () => {
  const dispatch = useDispatch();
  const { copiedAction } = useAction();
  const [selectedAutomationData, setSelectedAutomationData] = useState<IAutomation | undefined>();
  const [updatedAutomationName, setUpdatedAutomationName] = useState<string>(''); // automation name
  const [updateAutomationId, setUpdateAutomationId] = useState<string>(''); // automation Id is for updating automation name
  const { allAutomations, otherConfigs, selectedAutomation, clearRightSide, checkConfigureOpenInRight, handleABValidation, metadataType, createAutomationDefault } = useAutomation();
  const { selectedAction, selectedTransition, selectedDecisionNode, selectedNode } = useWorkFlow()

  const [openModal, setOpenModal] = useState<IChangeContext | null>();
  const addNewAutomation = () => {
    let newAutomationName = "New Automation";
    // Decision Node
    // screen Transition

    if (metadataType == ABMetadataTypeEnum.WORKFLOW_SCREEN_TRANSITION) {
      newAutomationName = `${selectedTransition.data.event} Screen Automation`;
    } else if (metadataType == ABMetadataTypeEnum.WORKFLOW_DECISION_NODE_TRANSITION_IF_SWITCH || metadataType == ABMetadataTypeEnum.WORKFLOW_DECISION_NODE_TRANSITION_ELSE_SWITCH) {
      newAutomationName = `${selectedDecisionNode.data.name} Automation`;
    } else if (metadataType == ABMetadataTypeEnum.WORKFLOW_SCREEN_ONLOAD) {
      newAutomationName = `${selectedNode?.data?.name} Onload Automation`;
    }

    const automationId = generateId(6);
    const actionBlockData: IActionBlock = {
      actions: [],
      conditions: [],
      actionBlockId: generateId(8)
    }

    const newAutomationData: IAutomation =
    {
      triggers: [],
      guards: [],
      actionBlocks: [
        ...(!otherConfigs?.hideActionBlock ? [actionBlockData] : []) // we won't create Action block, in this case : Example screen transition before decision node
      ],
      id: automationId,
      name: newAutomationName,
      errors: []
    }

    recordRSEvent(RudderStackAutomationBuilderEvents.automationCreateNew, {
      context: RudderStackAutomationBuilderContext.automationBuilder,
      automationId,
    })

    dispatch(addAutomation(newAutomationData));
    dispatch(setSelectedAutomation(newAutomationData));

    if (metadataType != ABMetadataTypeEnum.WORKFLOW_SCREEN_TRANSITION && metadataType != ABMetadataTypeEnum.WORKFLOW_DECISION_NODE_TRANSITION_IF_SWITCH && metadataType != ABMetadataTypeEnum.WORKFLOW_DECISION_NODE_TRANSITION_ELSE_SWITCH && metadataType != ABMetadataTypeEnum.WORKFLOW_SCREEN_ONLOAD) {
      setUpdatedAutomationName(newAutomationName);
    }

    setUpdateAutomationId(automationId);

    // Making Right Side Clear While Switching Automation
    clearRightSide();
  }

  const switchAutomation = (data: IAutomation) => {
    recordRudderStackSwitchingEvent(data.id);
    dispatch(setSelectedAutomation(data));
    clearRightSide();
  }

  const recordRudderStackSwitchingEvent = (automationId: string) => {
    recordRSEvent(RudderStackAutomationBuilderEvents.switchDifferentAutomation, {
      context: RudderStackAutomationBuilderContext.automationBuilder,
      automationId,
    })
  }



  const allowOnlySingleAutomation = (otherConfigs?.allowOnlySingleAutomation && allAutomations.length >= 1)

  useEffect(()=>{
    // this is only for onload screen automation, while opening from flutter
    if (createAutomationDefault === true && metadataType ===  ABMetadataTypeEnum.WORKFLOW_SCREEN_ONLOAD && allAutomations.length == 0) {
      addNewAutomation();
    }
  },[createAutomationDefault, metadataType])

  return (
    <ABNameListWrapper>
      <CreateNewAutomationWrapper>
        <ZButton
          style={{ width: '100%', marginLeft: '10px', marginRight: '10px' }}
          secondary
          disabled={allowOnlySingleAutomation}
          variant="contained"
          onClick={() => {
            if (checkConfigureOpenInRight && selectedAction?.hasChanged) {
              setOpenModal(IChangeContext.CREATE_AUTOMATION);
            } else {
              addNewAutomation();
            }
          }}
          type="button">
          Add New Automation
        </ZButton>
        {
          copiedAction?.id &&
          <ZButton
            style={{ marginLeft: '10px', marginRight: '10px' }}
            secondary
            disabled={allowOnlySingleAutomation}
            variant="contained"
            onClick={() => {
              dispatch(addAutomation({...copiedAction, id: generateId(6)}));
              // dispatch(setCopiedAction(null));
            }}
            type="button">
            <BiPaste size={'22px'} className="pointer" />
          </ZButton>
        }

      </CreateNewAutomationWrapper>


      {/* <AutomationEmptyText>System Events</AutomationEmptyText> */}
      {
        allAutomations.length === 0 ? <AutomationEmptyText>No Automations</AutomationEmptyText> :
          allAutomations?.map((data: IAutomation) => {
            return (
              <AutomationList
                automationClick={() => {
                  if (checkConfigureOpenInRight && selectedAction?.hasChanged) {
                    setOpenModal(IChangeContext.CHANGE_AUTOMATION);
                    recordRudderStackSwitchingEvent(data.id);
                    setSelectedAutomationData(data);
                  } else {
                    switchAutomation(data);
                  }
                }}
                editAutomation={(automationName) => {
                  setUpdatedAutomationName(automationName);
                  setUpdateAutomationId(data.id);
                }}
                selected={selectedAutomation?.id == data.id}
                key={data.id}
                automationDetails={data}
              />
            )
          })
      }

      {
        updatedAutomationName &&
        <EditAutomationModal
          isOpen={updatedAutomationName ? true : false}
          onClose={() => {
            setUpdatedAutomationName('');
            setUpdateAutomationId('');
          }}
          onSave={(automationName) => {
            dispatch(updateAutomationName({ automationName, automationId: updateAutomationId }));
            setUpdatedAutomationName('');
            setUpdateAutomationId('');
            handleABValidation();
          }}
          payload={{ automationName: updatedAutomationName }} />
      }

      <DeletionConfirmationDialog
        id={"context_switching"}
        deletionTitle={CONTEXT_SWITCHING}
        deletionText={CONTEXT_SWITCHING_CONFIRMATION}
        isOpen={openModal}
        onConfirmDelete={() => {

          if (openModal == IChangeContext.CREATE_AUTOMATION) {
            addNewAutomation();
          } else if (openModal == IChangeContext.CHANGE_AUTOMATION) {
            switchAutomation(selectedAutomationData);
          }

          setOpenModal(null);
        }}
        onClose={() => {
          setOpenModal(null);
        }}
      />

    </ABNameListWrapper>
  )
}

export default CreatedAutomations