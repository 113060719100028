import { AxiosResponse } from 'axios';
import HttpUtil from 'config/AxiosConfig';
import { ZorpResponse } from 'providers/data/models/ZorpResponse';

export class TaskRenderService {
  public static listComponents(): Promise<ZorpResponse | undefined> {
    return HttpUtil.bffInstance.get('/component/config').then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static fetchRenderConfig(taskType: string, mode?: string, status?: string): Promise<ZorpResponse | undefined> {
    return HttpUtil.bffInstance
      .get(`/web/tasktype/${taskType}/render?mode=${mode}&status=${status}`)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }

  public static fetchRenderConfigForTaskId(
    taskId: string,
    mode?: string,
    status?: string
  ): Promise<ZorpResponse | undefined> {
    return HttpUtil.bffInstance
      .get(`/task/${taskId}/render/web?mode=${mode}&status=${status}`)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }

  public static listRenderConfigs(isCreate?: boolean): Promise<ZorpResponse | undefined> {
    return HttpUtil.bffInstance.get(`/web/tasktypes?isCreate=${isCreate}`).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }
}
