import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  taskId: null,
};

export const PreviewSlice = createSlice({
  name: 'preview',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // setTaskType: (state, action) => {
    //     state.taskType = action.payload;
    // },
    setPreviewTaskId: (state, action) => {
      state.taskId = action.payload;
    }
  },
  extraReducers: (builder) => {},
});

export const { setPreviewTaskId } = PreviewSlice.actions;

export default PreviewSlice.reducer;
