import { PageCategoryEnum, recordPageVisit } from "config/Rudderstack";
import { TEXT } from "constants/CommonConstants";
import {
  RudderStackAutomationBuilderContext,
  RudderStackAutomationBuilderEvents
} from "constants/RudderStackConstants";
import { cloneDeep } from "lodash";
import { useJSEditor } from "lowcode/hooks/useJSEditor";
import { EditorScriptLocation } from "lowcode/state/types/types";
import getInputType from "lowcode/util/getInputType";
import { ZJSEditorLayout } from "lowcode/ZJSEditorLayout";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { recordRSEvent, removeBraces } from "utils/CommonUtils";
import {
  getLowCodeEditorDefaultReturnText,
  getLowCodeEditorText
} from "utils/Utils";
import { DataFieldType } from "views/DataField/DataField.types";
import useWorkflow from "views/workflows/hooks/useWorkFlow";
import { setSelectedAction } from "views/workflows/reducers/workflowAction";
import ErrorMessageInput from "views/workflows/workflowactions/api/components/ErrorMessageInput";
import IsActive from "views/workflows/workflowactions/api/components/IsActive";
import ModalSmartInputTextField from "views/workflows/workflowactions/api/components/ModalSmartInputTextField";
import {
  saveAction,
  ValidLocation,
  generateTempId,
  isTempIdUnique,
  IIsTempIdUniqueContext,
  getActionNameForTempId,
} from "views/workflows/workflowactions/api/utils/utils";
import DatafieldSelector from "views/workflows/workflowactions/DatafieldSelector";
import {
  Heading,
  HeadingWrapper,
  Label
} from "views/workflows/workflowactions/ModalContent.styles";
import {
  ABSelectWrapper,
  LabelWrapper,
  Text
} from "../automationBuilder.styles";
import useAutomation from "../hooks/useAutomation";
import usePayload from "../hooks/usePayload";
import InputTempId from "views/automationBuilder/components/InputTempId";
import { setActionPayload } from "../reducer/automationReducer";
import { actionType, IAction } from "../types/ABType";
import ABSelect from "./AppComponents/ABSelect";
const optionLabel = (option: any) => option.name;
const optionId = (option: any) => option.id;

const ABActionComponent = () => {
  const dispatch = useDispatch();
  const { actionConditionPayload } = usePayload();
  const { openJSEditorLayout, scriptEditorOpen } = useJSEditor();
  const { selectedActionBlock, selectedAutomation } = useAutomation();
  const { actionDropdown, actions, metadataType, clearRightSide } =
    useAutomation();
  const {
    selectedAction,
    getScriptValue,
    dataPicker,
  } = useWorkflow();

  const [valueDataType, setValueDataType] = useState<DataFieldType>(
    DataFieldType.TEXT
  );
  // Hide value textfield while creating new action and show while editing action
  const [showValue, setShowValue] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [tempIdChanged, setTempIdChanged] = useState(true);
  const [currTempIdVal, setCurrTempIdVal] = useState(selectedAction?.tempId || "");

  const valueScriptConfig = {
    MessageScriptConfig: {
      script:
        getScriptValue(EditorScriptLocation.AB_SMART_ACTION_VALUE) ||
        getLowCodeEditorText(
          EditorScriptLocation.AB_SMART_ACTION_VALUE,
          valueDataType,
          `return ${getLowCodeEditorDefaultReturnText(valueDataType)}`
        ),
      dataType: valueDataType,
    },
    errorMessageConfig: {
      script: getScriptValue(EditorScriptLocation.ERROR_MESSAGE) || getLowCodeEditorText(EditorScriptLocation.ERROR_MESSAGE, DataFieldType.TEXT, `return ${getLowCodeEditorDefaultReturnText(DataFieldType.TEXT)}`),
      dataType: TEXT,
    }
  };

  useEffect(() => {
    if (selectedAction?.componentData && actionDropdown?.length > 0) {
      setShowValue(true);
      // setting data Type | NUMBER | TEXT | BOOLEAN | DATE
      const dataType = getDefaultFunction(
        selectedAction?.componentData.length - 1
      )?.dataType;

      if (dataType && Object.keys(DataFieldType).includes(dataType)) {
        setValueDataType(dataType);
      }
    }
  }, [actionDropdown]);

  useEffect(() => {
    if (selectedAction?.componentData) {
      dispatch(setActionPayload(selectedAction?.componentData));
    }
  }, []);

  useEffect(() => {
    recordPageVisit(
      PageCategoryEnum.automationBuilder,
      RudderStackAutomationBuilderEvents.smartComponentConditionOnLoad
    );
  }, []);

  const onFormSubmit = (data: any) => {

    let tempActionId:string = selectedAction?.tempId || data?.tempId;
    if (!isEditing) {
      tempActionId = currTempIdVal.length === 0 ? (selectedAction?.actionId || selectedAction?.guardId || selectedAction?.conditionId) : currTempIdVal;
      dispatch(setSelectedAction({ ...selectedAction, tempId: currTempIdVal }));
    }

    let isTempIdChanged = tempIdChanged;
    if (tempActionId === (selectedAction?.actionId || selectedAction?.guardId || selectedAction?.conditionId)) {
      setTempIdChanged(false);
      isTempIdChanged = false;
    }

    if (isTempIdChanged && !isTempIdUnique(tempActionId, selectedAutomation)) {
      setError("tempId", {
        type: "manual",
        message: "This ID already exists. Please choose a different one.",
      });
      return;
    }

    // validation for Action Name
    if (!data.actionName) {
      setError("actionName", {
        type: "manual",
        message: "This is a required field",
      });
      return;
    }

    if (data.actionName.length <= 3) {
      setError("actionName", {
        type: "manual",
        message: "Name must be greater than 3 characters",
      });
      return;
    }

    if (
      !selectedAction?.value?.value ||
      (valueDataType === DataFieldType.LIST_OF_TEXT &&
        selectedAction?.value?.value.length === 0)
    ) {
      setError("value", {
        type: "manual",
        message: "This is a required field",
      });
      return;
    }

    recordRSEvent(
      RudderStackAutomationBuilderEvents.smartComponentActionSaveClick,
      {
        context: RudderStackAutomationBuilderContext.automationBuilderAction,
        automationId: selectedAutomation.id,
        actionType: selectedAction.actionType,
        actionId: selectedAction.actionId,
      }
    );

    const payload = {
      actionId: tempActionId,
      tempId: tempActionId,
      actionName: data.actionName,
      actionType: selectedAction.actionType,
      isActive: selectedAction?.isActive ?? true,
      componentData: actionDropdown,
      value: selectedAction?.value,
      metadataType,
      errors: [],
      [EditorScriptLocation.ERROR_MESSAGE]: selectedAction[EditorScriptLocation.ERROR_MESSAGE],
    };

    saveAction({
      payload,
      location: ValidLocation.AUTOMATION,
      dispatch,
      selectedAction,
      selectedAutomation,
      selectedActionBlock,
    });

    clearRightSide();
  };

  const getArrayToIterate = (index: number) => {
    const componentsData = actions.find(
      (actionData: IAction) =>
        actionData.actionType == actionType.SCREEN_COMPONENT
    )?.struct;
    if (index == 0) {
      return componentsData;
    } else {
      const dropDownOptionsArray = [componentsData];
      for (let loopIndex = 0; loopIndex < actionDropdown.length; loopIndex++) {
        const dropDownArrayData = dropDownOptionsArray[loopIndex]?.find(
          (data: any) => data.id == actionDropdown?.[loopIndex]
        );
        dropDownOptionsArray.push(dropDownArrayData?.struct);
        if (loopIndex == index) {
          break;
        }
      }
      return dropDownOptionsArray[index];
    }
  };

  const getSelectedValue = (index: number) => {
    return actionDropdown?.[index];
  };

  // this is called when user click cross on smart input (blue baclground input)
  const handleValueChange = (e:any) => {
    if (valueDataType === DataFieldType.LIST_OF_TEXT) {
      dispatch(
        setSelectedAction({
          ...selectedAction,
          hasChanged: true,
          value: {
            type: getInputType(e.target.value),
            value: [],
            dataType: valueDataType,
          },
        })
      );
    } else {
      dispatch(
        setSelectedAction({
          ...selectedAction,
          hasChanged: true,
          value: {
            type: getInputType(e.target.value),
            value: removeBraces(e.target.value),
            dataType: valueDataType,
          },
        })
      );
    }
  };

  const getDefaultFunction = (index: number) => {
    const value = getArrayToIterate(index)?.find(
      (data: any) => data.id == getSelectedValue(index)
    );
    // if the value is undefined, sometime the  value in dropdown is not clearing, so we are sending {} - empty object when value is undefined
    return value || {};
  };

  const handleErrorMessageChange = (e:any) => {
    dispatch(
      setSelectedAction({
        ...selectedAction,
        hasChanged: true,
        [EditorScriptLocation.ERROR_MESSAGE]: {
          type: getInputType(e.target.value),
          value: removeBraces(e.target.value),
          dataType: TEXT,
        },
      })
    );
  };

  const getDefaultValue = (dataType: DataFieldType) => {
    switch (dataType) {
      case DataFieldType.LIST_OF_TEXT:
        return [];
      // case DataFieldType.DATETIME:
      //   return new Date().toISOString();
      // case DataFieldType.BOOLEAN:
      //   return true;
      default:
        return [];
    }
  };

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    setValue,
    control,
    clearErrors,
    getValues,
  } = useForm({
    defaultValues: {
      isActive: selectedAction?.isActive ?? true,
      actionName: selectedAction?.actionName,
      value: selectedAction?.value?.value,
      tempId: generateTempId(selectedAction, selectedAutomation, getActionNameForTempId(selectedAction), "action"),
    },
  });

  const handleTempActionValChange = (name, value) => {
    setCurrTempIdVal(value);
  }

  const handleEditClick = () => {
    setIsEditing(true);
    setTempIdChanged(true);
  };

  const handleTickClick = () => {
    setIsEditing(false);
  };

  const setTempIdError = (error) => {
    if (error) {
      setError("tempId", { type: "manual", message: error });
    } else {
      clearErrors("tempId");
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <HeadingWrapper>
          <Heading> Configure Action </Heading>
          <IsActive control={control} closeOnClick={clearRightSide} />
        </HeadingWrapper>
        <Text>
          {" "}
          Configure the actions that need to be executed once the automation is
          triggered and the condition clears.
        </Text>

        <LabelWrapper>
          <Label> Name the Action</Label>
        </LabelWrapper>
        <ModalSmartInputTextField
          value={selectedAction?.actionName}
          dataType={DataFieldType.TEXT}
          isSmartField={false}
          control={control}
          placeholder="Describe the Action Name Here"
          register={register}
          errors={errors}
          name="actionName"
        />

        <InputTempId
          label="Action Id"
          onChange={handleTempActionValChange}
          register={register}
          requiredErrorMessage="Action Id is required"
          errors={errors}
          isReadOnly={!isEditing}
          handleTickClick={handleTickClick}
          handleEditClick={handleEditClick}
          getValues={getValues}
          setTempIdError={setTempIdError}
          isEditing={isEditing}
          name="tempId"
        />

        <ABSelectWrapper>
          <ABSelect
            options={
              actions.find(
                (actionData: IAction) =>
                  actionData.actionType == actionType.SCREEN_COMPONENT
              )?.struct
            }
            optionLabel={optionLabel}
            optionId={optionId}
            placeHolder={"Select Component"}
            label={"Select Component"}
            errors={Object.keys(getDefaultFunction(0)).length > 0 ? {} : errors}
            errorMessageName={"value"}
            defaultValue={getDefaultFunction(0)}
            onClickFunction={(optionValue) => {
              if (optionValue) dispatch(setActionPayload([optionValue.id]));
              else dispatch(setActionPayload([]));

              setShowValue(false);
              setValueDataType("");
              // clearing value field
              dispatch(
                setSelectedAction({
                  ...selectedAction,
                  hasChanged: true,
                  value: {},
                })
              );
            }}
          />
        </ABSelectWrapper>

        {actionDropdown?.length > 0 &&
          actionDropdown.map((actionDropdownData: any, index: number) => {
            return (
              <>
                {getDefaultFunction(index)?.struct?.length > 0 && (
                  <ABSelectWrapper>
                    <ABSelect
                      options={getDefaultFunction(index)?.struct.filter(
                        (valueData) => valueData.isReadOnly != true
                      )} // this filter logic removes all read only attribute from the list Example: nodeId
                      optionLabel={optionLabel}
                      optionId={optionId}
                      placeHolder={getDefaultFunction(index)?.label}
                      label={getDefaultFunction(index)?.label}
                      errors={Object.keys(getDefaultFunction(index + 1)).length > 0 ? {} : errors}
                      errorMessageName={"value"}
                      defaultValue={getDefaultFunction(index + 1)}
                      onClickFunction={(optionValue: any) => {
                        if (optionValue) {
                          if (actionDropdown.length === index + 1) {
                            // Newly selecting in dropdown value
                            dispatch(
                              setActionPayload([
                                ...actionDropdown,
                                optionValue?.id,
                              ])
                            );
                          } else {
                            // when already selected value is changed
                            // updating the array and remove the balance elements
                            const tmpData = cloneDeep(actionDropdown);
                            tmpData[index + 1] = optionValue?.id;
                            tmpData.splice(index + 2);
                            dispatch(setActionPayload(tmpData));

                            // Hide textfield when value in middle is changed and value is not hidden if the last dropdown value is changed
                            if (optionValue?.struct) {
                              // making value textfield hidden
                              setShowValue(false);
                              setValueDataType(undefined); // setting falsy value
                            }
                          }
                        } else {
                          // When clear button in dropdown is pressed
                          const tmpData = cloneDeep(actionDropdown);
                          tmpData.splice(index + 1);
                          dispatch(setActionPayload(tmpData));
                        }

                        // show value textfield
                        if (
                          !optionValue?.struct ||
                          (optionValue?.struct.length == 0 &&
                            (optionValue?.dataType == DataFieldType.LIST ||
                              optionValue?.dataType == DataFieldType.OBJECT))
                        ) {
                          setValueDataType(optionValue?.dataType || TEXT);
                          setShowValue(true);
                        }

                        setValue(
                          "value",
                          getDefaultValue(optionValue?.dataType)
                        );
                        // clearing value field

                        // depending on the datatype set value on selectedAction
                        if (optionValue?.dataType === DataFieldType.DATETIME) {
                          dispatch(
                            setSelectedAction({
                              ...selectedAction,
                              hasChanged: true,
                              value: {
                                type: "literal",
                                value: getDefaultValue(
                                  optionValue?.dataType || ""
                                ),
                                dataType: optionValue?.dataType,
                              },
                            })
                          );
                        } else {
                          dispatch(
                            setSelectedAction({
                              ...selectedAction,
                              hasChanged: true,
                              value: {},
                            })
                          );
                          setValue(EditorScriptLocation.AB_SMART_ACTION_VALUE, getDefaultValue(optionValue?.dataType));
                        }
                      }}
                    />
                  </ABSelectWrapper>
                )}
              </>
            );
          })}

        {showValue && valueDataType && (
          <>
            <LabelWrapper>
              <Label> Value </Label>
            </LabelWrapper>

            <ModalSmartInputTextField
              dataType={valueDataType}
              control={control}
              type={selectedAction?.value?.type}
              isSmartField={true}
              step="any"
              value={selectedAction?.value?.value}
              placeholder="Enter ${ to Select Dynamic value"
              register={register}
              setValue={setValue}
              getValues={getValues}
              setError={setError}
              clearErrors={clearErrors}
              name="value"
              errors={errors}
              onChangeFunc={handleValueChange}
              config={valueScriptConfig.MessageScriptConfig}
              editorLocation={EditorScriptLocation.AB_SMART_ACTION_VALUE}
              openJSEditor={openJSEditorLayout}
              apiBodyPayload={actionConditionPayload}
            />
          </>
        )}

        {showValue && valueDataType && (
          <ErrorMessageInput
            label={`Error Message to be shown if the App Component fails`}
            onChangeFunc={handleErrorMessageChange}
            register={register}
            apiBodyPayload={actionConditionPayload}
            errors={errors}
            openJSEditorLayout={openJSEditorLayout}
            config={valueScriptConfig.errorMessageConfig}
          />
        )}


        {scriptEditorOpen && <ZJSEditorLayout/>}
      </form>

      {dataPicker?.isOpened && <DatafieldSelector setValue={setValue} />}
    </>
  );
};

export default ABActionComponent;
