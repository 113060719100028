import React, { useEffect } from 'react';
import { Show, ShowController } from 'react-admin';
import { PageCategoryEnum, recordPageVisit } from '../../../../../config/Rudderstack';
import { doesTaskTypehavePickUpLocation } from '../../../../../utils/TasktypeUtils';
import OrderDetails from './OrderDetails';
import { TaskMap } from './TaskMap';
import TaskShowActions from './TaskShowActions';

export const TaskShow = (props) => {
  useEffect(() => {
    recordPageVisit(PageCategoryEnum.task, 'task_details');
  }, [props]);

  return (
    <div className='showTaskParentDiv'>
      <ShowController {...props} component='div'>
        {(controllerProps) => {
          const taskDetails = controllerProps?.record;
          return (
            <Show {...props} actions={<TaskShowActions />} {...controllerProps}>
              <>
                <div className='px-2 pt-2'>
                  <h3 className='bg-zorpBlack py-2 pl-2 rounded-md font-bold text-white text-xl'>Task Details</h3>
                  <div className='grid grid-cols-1 sm:grid-cols-2 pt-2 gap-y-4 gap-x-8'>
                    <div className='flex rounded-md bg-gray-100  pt-3 pb-3 justify-between space-x-4 pl-2 pr-2'>
                      <p className='font-bold text-left pr-8'>Task Id:</p>
                      <p className='text-right'>{taskDetails?.taskId}</p>
                    </div>
                    <div className='flex rounded-md bg-gray-100  pt-3 pb-3 justify-between space-x-4 pl-2 pr-2'>
                      <p className='font-bold text-left pr-8'>Task Type:</p>
                      <p className='text-right'>{taskDetails?.taskTypeDisplayName}</p>
                    </div>

                    {taskDetails?.taskType !== 'field_visit_task' && taskDetails?.taskType !== 'pickup_and_drop_task' && (
                      <div className='flex rounded-md bg-gray-100  pt-3 pb-3 justify-between space-x-4 pl-2 pr-2'>
                        <p className='font-bold text-left pr-8'>Scheduled Slot</p>
                        <p className='text-right'>
                          From - {taskDetails?.start}
                          <br />
                          To - {taskDetails?.end}
                        </p>
                      </div>
                    )}
                    {taskDetails?.taskType === 'field_visit_task' && taskDetails?.taskType === 'pickup_and_drop_task' && (
                      <div className='flex rounded-md bg-gray-100  pt-3 pb-3 justify-between space-x-4 pl-2 pr-2'>
                        <p className='font-bold text-left pr-8'>Order Id:</p>
                        <p className='text-right'>{taskDetails?.orderId}</p>
                      </div>
                    )}
                    {taskDetails?.taskType === 'field_visit_task' && taskDetails?.taskType === 'pickup_and_drop_task' && (
                      <div className='flex rounded-md bg-gray-100  pt-3 pb-3 justify-between space-x-4 pl-2 pr-2'>
                        <p className='font-bold text-left pr-8'>Complaints:</p>
                        <p className='text-right'>{taskDetails?.complaint}</p>
                      </div>
                    )}
                    <div class='flex rounded-md bg-gray-100  pt-3 pb-3 justify-between space-x-4 pl-2 pr-2'>
                      <p class='font-bold text-left pr-8'>Task Status</p>
                      <p class='text-right'>{taskDetails?.taskStatusName}</p>
                    </div>
                    {taskDetails?.user ? (
                      <div className='flex rounded-md bg-gray-100  pt-3 pb-3 justify-between space-x-4 pl-2 pr-2'>
                        <p className='font-bold text-left pr-8'>Task Assigned To</p>
                        <p className='text-right'>
                          {taskDetails?.user?.name} - {taskDetails?.user?.userId}
                        </p>
                      </div>
                    ) : (
                      <div className='flex rounded-md bg-gray-100  pt-3 pb-3 justify-between space-x-4 pl-2 pr-2'>
                        <p className='font-bold text-left pr-8'>Task Assigned To</p>
                        <p className='text-right'>None</p>
                      </div>
                    )}
                    <div className='flex rounded-md bg-gray-100  pt-3 pb-3 justify-between space-x-4 pl-2 pr-2'>
                      <p className='font-bold text-left pr-8'>Team</p>
                      <p className='text-right'>{taskDetails?.teamsInfo}</p>
                    </div>
                  </div>
                </div>
                <div>
                  {taskDetails?.taskType !== 'inventory_pickup_task' && (
                    <div className='m-2'>
                      <div className='my-5'>
                        <h3 className='bg-zorpBlack py-2 pl-2 rounded-md font-bold text-white text-xl'>
                          Location Details
                        </h3>
                      </div>
                      <div className='flex flex-row w-full'>
                        <div className='w-4/6'>
                          <TaskMap
                            mapCenter={taskDetails?.mapCenter}
                            locations={[taskDetails?.pickup, taskDetails?.drop]}
                          ></TaskMap>
                        </div>
                        <div className='flex flex-col ml-3 border rounded-t-md border-black w-2/6'>
                          {doesTaskTypehavePickUpLocation(taskDetails?.taskType) && (
                            <div>
                              <div className='w-full justify-center rounded-t-md py-2 pl-2 bg-zorpBlack'>
                                <p className='font-bold text-white'>
                                  {doesTaskTypehavePickUpLocation(taskDetails?.taskType)
                                    ? 'Pickup Location'
                                    : 'Location'}
                                </p>
                              </div>
                              <br />

                              <div>
                                <div className='px-2 py-2'>
                                  <p className='font-bold text-left pr-8'>Name:</p>
                                  <div className='p-2 bg-gray-100 rounded-md'>{taskDetails?.pickup?.name}</div>
                                  <div className='p-2 bg-gray-100 rounded-md'>
                                    {taskDetails?.pickup?.phone.countryCode}-{taskDetails?.pickup?.phone.num}
                                  </div>
                                </div>
                                <div className='px-2 py-2'>
                                  <p className='font-bold text-left pr-8'>Address:</p>
                                  <div className='p-2 bg-gray-100 rounded-md'>
                                    <a
                                      href={
                                        'https://maps.google.com?q=' +
                                        taskDetails?.pickup?.location?.latitude +
                                        ',' +
                                        taskDetails?.pickup?.location?.longitude
                                      }
                                      target='_blank'
                                    >
                                      {taskDetails?.pickup?.location?.address}
                                    </a>
                                  </div>
                                  <div className='p-2 bg-gray-100 rounded-md text-xs'>
                                    <a
                                      href={
                                        'https://maps.google.com?q=' +
                                        taskDetails?.pickup?.location?.latitude +
                                        ',' +
                                        taskDetails?.pickup?.location?.longitude
                                      }
                                      target='_blank'
                                    >
                                      {taskDetails?.pickup?.location?.latitude},{' '}
                                      {taskDetails?.pickup?.location?.longitude}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          <div className='w-full justify-center rounded-t-md py-2 pl-2 bg-zorpBlack'>
                            <p className='font-bold text-white'>
                              {doesTaskTypehavePickUpLocation(taskDetails?.taskType) ? 'Drop Location' : 'Location'}
                            </p>
                          </div>
                          <br />
                          {taskDetails?.taskType !== 'inventory_pickup_task' && (
                            <div>
                              <div className='px-2 py-2'>
                                <p className='font-bold text-left pr-8'>Name:</p>
                                <div className='p-2 bg-gray-100 rounded-md'>{taskDetails?.drop?.name}</div>
                                <div className='p-2 bg-gray-100 rounded-md'>
                                  {taskDetails?.drop?.phone.countryCode}-{taskDetails?.drop?.phone.num}
                                </div>
                              </div>
                              <div className='px-2 py-2'>
                                <p className='font-bold text-left pr-8'>Address:</p>
                                <div className='p-2 bg-gray-100 rounded-md'>
                                  <a
                                    href={
                                      'https://maps.google.com?q=' +
                                      taskDetails?.drop?.location?.latitude +
                                      ',' +
                                      taskDetails?.drop?.location?.longitude
                                    }
                                    target='_blank'
                                  >
                                    {taskDetails?.drop?.location?.address}
                                  </a>
                                </div>
                                <div className='p-2 bg-gray-100 rounded-md text-xs'>
                                  <a
                                    href={
                                      'https://maps.google.com?q=' +
                                      taskDetails?.drop?.location?.latitude +
                                      ',' +
                                      taskDetails?.drop?.location?.longitude
                                    }
                                    target='_blank'
                                  >
                                    {taskDetails?.drop?.location?.latitude}, {taskDetails?.drop?.location?.longitude}
                                  </a>
                                </div>
                              </div>
                            </div>
                          )}
                          {/* <div className="px-2 py-2">
                                                            <p className="font-bold text-left pr-8">Latitude:</p>
                                                            <div className="p-2 bg-gray-100 rounded-md">
                                                                <p className="text-left"></p>
                                                            </div>
                                                        </div>
                                                        <div className="px-2 py-2">
                                                            <p className="font-bold text-left pr-8">Longitude:</p>
                                                            <div className="p-2 bg-gray-100 rounded-md">
                                                                <p className="text-left"></p>
                                                            </div>
                                                        </div> */}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {controllerProps.record?.taskType !== 'field_visit_task' &&
                  controllerProps.record?.taskType !== 'pickup_and_drop_task' && <OrderDetails {...controllerProps} />}

                <iframe
                  src={`https://app.appsmith.com/applications/615c166205fc5e1d45b5e7a6/pages/615c166205fc5e1d45b5e7a8?taskId=${taskDetails?.taskId}&taskType=${taskDetails?.taskType}&embed=true&isCommentMode=false`}
                  width='100%'
                  height='1000px'
                  scrolling='no'
                ></iframe>
              </>
            </Show>
          );
        }}
      </ShowController>
    </div>
  );
};
