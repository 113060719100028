import { useDispatch, useStore } from "react-redux";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import { setShowOnboardingModal } from "../reducer/onboardingAction";
import {
  Image,
  OnbaordingHeadingWrapper as HeadingWrapper,
  OnboardingButtonContainer as ButtonContainer,
  OnboardingContainer,
  OnboardingHeading as Heading,
  Text,
} from "views/onboarding/styles/OnboardingStyles";
//@ts-ignore
import CreateWorkflowStepImage from "assests/banner3.svg";
//@ts-ignore
import CreateWorkflowMainImage from "assests/s2asset.gif";
import { AiOutlineClose } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import useOnboarding from "../hooks/useOnboarding";
import { TaskFlowService } from "providers/data/services/TaskFlowService";
import notification from "notifications/notifications";

type Props = {};

const ConfigureFlowStep = (props: Props) => {
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  const { onboarding } = useOnboarding();

  const handleClick = async () => {
    try {
      const data = await TaskFlowService.publishTemplateAsWorkflow("xpq8d6");
      history.push(`/workflows/${data?.data}/show`);
    } catch (error) {
      notification(
        "error",
        "Couldn't create a sample workflow for you. Please try again."
      );
    }
  };

  const closeModal = () => {
    // OnboardingUtil.stop(dispatch)
    dispatch(setShowOnboardingModal(false));
  };

  return (
    <>
      <OnboardingContainer>
        <HeadingWrapper>
          <Heading>Zorp Onboarding Tutorial</Heading>
          <div onClick={() => dispatch(setShowOnboardingModal(false))}>
            {" "}
            <AiOutlineClose size="1.5rem" />{" "}
          </div>
        </HeadingWrapper>
        <Image alt="onboarding-overview-step" src={CreateWorkflowStepImage} />
        <Image
          main
          alt="onboarding-overview-step-gif"
          src={CreateWorkflowMainImage}
        />
        <Text banner>
          {" "}
          Welcome to the Workflow Canvas. This is where you configure your
          workflow and mobile application screens. For now, we have already made
          a sample workflow for you to publish. Click on ‘Publish’ to get
          started{" "}
        </Text>
        <ButtonContainer>
          <ZButton onClick={closeModal} variant="contained">
            {" "}
            Try Publishing the workflow we created for you{" "}
          </ZButton>
        </ButtonContainer>
      </OnboardingContainer>
    </>
  );
};

export default ConfigureFlowStep;
