import { useRecordContext } from 'react-admin';
import ScrollDialog from 'components/ScrollDialog';
import { Link } from '@material-ui/core';
import React from 'react';

const CustomListField = ( props ) =>
{
    const { source } = props;
    const record = useRecordContext(props);
    const value = record && record[source.split(".")[0]][source.split(".")[1]];
    const [open, setOpen] = React.useState(false);
    let contentText = getContentText(value);
    let text = getSummaryText(value);

    const handleClose = (e) => {
        setOpen(false);
        e.stopPropagation();
    };

    return (
        <div>
        <Link style={{color: "black"}} onClick = {(e) => {
            setOpen(true);
            e.stopPropagation();
        }}>
           {text}
        </Link>
        
        {open && <ScrollDialog label={source.split(".")[1]} value={contentText} scroll="paper" onClose={handleClose}/>}
        </div>
    );
};

function isIsoDate(str) {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
    var d = new Date(str); 
    return d.toISOString()===str;
  }


  const getSummaryText = (value) => {
    let text = "";
    if (value) {
        if (0 in value) {
            text =  Object.values(value[0]).join(" ").toString().substring(0, 10) + "...";
        }
    }

    return text;
  };

const getContentText = (value) => {
    let displayValue = [];
    if (value == undefined || value == null ||  value == "") {
        return "";
    }

    var tableStyle = {
        border : "1px solid lightgrey",
        padding : "3px"
     };

    
    let headers = [];
    for (let headerIndex = 0; headerIndex < Object.keys(value[0]).length; headerIndex++) {
        headers.push(<th style={tableStyle}>{Object.keys(value[0])[headerIndex]}</th>);
    }
    displayValue.push(<tr>{headers}</tr>);
    
    for (let rowIndex = 0; rowIndex < value.length; rowIndex++) {
        let rowValue = value[rowIndex];
        let row = [];
        for (let colIndex = 0; colIndex < Object.keys(rowValue).length; colIndex++) {
            let val = Object.values(rowValue)[colIndex];
            row.push(<td style={tableStyle}>{isIsoDate(val) ? new Date(val).toLocaleDateString() : val}</td>);
        }
        displayValue.push(<tr>{row}</tr>);  
    }
    
    return (
        <table style={tableStyle}>{displayValue}</table>
    );
};
 
CustomListField.defaultProps = {
    addLabel: true,
};

export default CustomListField;
