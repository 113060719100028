import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((props) => ({

  // font-size: 14px;
  //   border-left: 1px solid #E1E4E8;
  //   font-weight: 400;
  //   padding-top: 2px;
  //   border-right: 1px solid #E1E4E8;
  //   padding-left: 10px;
  //   padding-right: 0px;
  //   padding-bottom: 0px;
  table: {
    boxShadow: "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
    overflow: "auto"
  },
  rowCell: {
    fontSize: "14px",
    padding:"12px 24px",
    color:"#475467",
    fontWeight: "400",
    '&:hover': {
      backgroundColor: '#f0f2f3'
    },
  },
  rowEven: {
    backgroundColor: '#fff',
    borderLeft: "1px solid #EAECF0",
    borderRight: "1px solid #EAECF0",
    borderBottom: "1px solid #EAECF0",
  },
  rowOdd: {
    backgroundColor: '#fff',
    borderLeft: "1px solid #EAECF0",
    borderRight: "1px solid #EAECF0",
    borderBottom: "1px solid #EAECF0",
  },
  headerCell: {
    backgroundColor: '#F9FAFB',
    color: '#475467',
    textAlign: 'left',
    fontWeight: '500',
    fontSize: '12px',
    padding: "12px 24px"
  },
  headerRow: {
    backgroundColor: '#F9FAFB',
    color: '#475467',
    textAlign: 'left',
    fontWeight: '500',
    fontSize: '12px',
    borderRadius: '4px !important',
    border: "1px solid #EAECF0",
    padding: "12px 24px"
  },
  detailsBox: {
    paddingRight: '50px',
    marginRight: '50px',
    justifyContent: 'space-between',
  },
  ellipsis: {
    width: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
  },
  ellipsisAlert: {
    width: '700px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
  },
  chip: {
    borderRadius: '0px !important',
    width: '105%',
  },
  createButton: {
    padding: '6px 16px',
    fontSize: '1rem',
    lineHeight: '1.8rem',
    fontWeight: 500,
    textTransform: 'capitalize',
    borderRadius: '0.4rem',
    border: '1px solid',
    boxShadow: 'none !important',
    maxHeight: ' 4rem',
    minWidth: 'auto',
    justifyContent: 'center',
    backgroundColor: props => `${props.theme.color.primary} !important`,
    borderColor: props => `${props.theme.color.secondary} !important`,
    color: '#fff',
    marginLeft: '1rem',
    marginRight: '1rem',
    '&:hover': {
      backgroundColor: props => `${props.theme.color.secondary} !important`,
      color: '#ffffff',
    },
  },
}));
