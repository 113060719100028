import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';
import datafieldlogo from 'assests/datafieldlogo.svg';
import { useJSEditor } from 'lowcode/hooks/useJSEditor';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { BsPlus } from 'react-icons/bs';
import ZInput from "views/commonComponents/ZTextInput";
import {
  Heading, HeadingWrapper, InputContainer,
  Label
} from 'views/workflows/workflowactions/ModalContent.styles';
import { makeStyles } from '@material-ui/core/styles';
import SmartInputScriptButton from 'components/script-button/SmartInputScriptButton';
import { PageCategoryEnum, recordPageVisit } from 'config/Rudderstack';
import { TEXT } from 'constants/CommonConstants';
import { RudderStackAutomationBuilderContext, RudderStackAutomationBuilderEvents } from 'constants/RudderStackConstants';
import { setScriptEditorPayload } from 'lowcode/state/scriptOpenState';
import { EditorScriptLocation } from 'lowcode/state/types/types';
import { ZJSEditorLayout } from 'lowcode/ZJSEditorLayout';
import { useEffect, useRef, useState } from 'react';
import { RiDeleteBin6Line as DeleteIcon } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { recordRSEvent, removeBraces } from 'utils/CommonUtils';
import { getLowCodeEditorDefaultReturnText, getLowCodeEditorText } from 'utils/Utils';
import useAutomation from "views/automationBuilder/hooks/useAutomation";
import usePayload from 'views/automationBuilder/hooks/usePayload';
import { actionType } from 'views/automationBuilder/types/ABType';
import { DataFieldType } from 'views/DataField/DataField.types';
import ErrorMessage from 'views/DataField/ErrorMessage';
import useWorkFlow from 'views/workflows/hooks/useWorkFlow';
import IsActive from "views/workflows/workflowactions/api/components/IsActive";
import InputTempId from 'views/automationBuilder/components/InputTempId';
import {
  dataFieldPickerUtil, getActionNameForTempId, saveAction,
  ValidLocation
} from "views/workflows/workflowactions/api/utils/utils";
import { setSelectedAction } from "../../../reducers/workflowAction";
import DatafieldSelector from "../../DatafieldSelector";
import ErrorMessageInput from '../components/ErrorMessageInput';
import { FormControlLabel, Typography } from '@mui/material';
import getInputType from 'lowcode/util/getInputType';
import { generateTempId, isTempIdUnique } from "../utils/utils";


const Item = ({ entity: { name, char } }) => (

  <>
    <div style={{ zIndex: 1001 }}>{`${char}`}</div>
  </>
)

const ErrorMessageFields = () => (
  <>
    <span className="font-semibold text-xs text-red-500">
      This is required
    </span>
  </>
)




const useStyles = makeStyles((theme) => ({
  reactSelect: {
    width: "100%",
    fontSize: 14,
    fontWeight: 500,
    height: "40px",
  },
  textArea: {
    width: '100%',
    border: '1px solid #ccc',
    padding: '10px',
    color: 'black',
    height: '40px',
    borderRadius: '2px',
  },
  disabledSpan: {
    display: "flex",
    backgroundColor: "#2E4CBC !important",
    color: "white",
    height: '31px',
    width: '100%',
    padding: "10px 10px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  closeButton: {
    color: "white",
    cursor: "pointer",
    marginLeft: "10px",
  },
}));

const UpdateDatefield = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { selectedActionBlock, selectedAutomation, clearRightSide } = useAutomation();
  const { actionConditionPayload } = usePayload();
  const { intelliSense, openJSEditorLayout, scriptEditorOpen, editorLocation } = useJSEditor();
  const [isEditing, setIsEditing] = useState(false);
  const [tempIdChanged, setTempIdChanged] = useState(true);

  const {
    selectedAction,
    getScriptValue,
    dataPicker
  } = useWorkFlow();

  const [currTempIdVal, setCurrTempIdVal] = useState(selectedAction?.tempId || "");

  // Here react autocomplete needs name and char, where as react select needs label and value as keys;

  const options = intelliSense.map((option) => {
    return {
      name: `\${${option.type}}`,
      char: `\${${option.name}}`,
      label: option.name,
      value: option.type,
    };
  });


  const defaultValues = {
    list: [
      {
        name: "useFieldArray",
      },
    ],
    tempId: generateTempId(selectedAction, selectedAutomation, getActionNameForTempId(selectedAction), "action"),
    isActive: selectedAction?.isActive ?? true,
    updateDataFieldActionName: "Update Datafield Action Name"
  };

  const {
    register,
    formState: { errors },
    reset,
    control,
    watch,
    handleSubmit,
    setValue,
    setError,
    getValues,
    clearErrors,
  } = useForm({ defaultValues });

  const [dataFieldIndex, setDataFieldIndex] = useState<number>(0);
  const [dataFieldId, setDataFieldId] = useState<string[]>([]);
  const [exitOnError, setExitOnError] = useState(true);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "list",
  });


  const insertNewRow = (index: any, dataFieldValue: any) => {
    append({ name: `useFieldArray${index}` });
    const selectedActionTmpData = {
      ...selectedAction,
      hasChanged: true,
      updateDataFieldData: [...selectedAction.updateDataFieldData]
    }

    selectedActionTmpData.updateDataFieldData.push({
      "": {
        value: '',
        type: 'literal',
        dataType: dataFieldValue?.data.type
      }
    })
    dispatch(setSelectedAction(selectedActionTmpData));
  };

  const removeRow = (index: any) => {
    remove(index);
    const selectedActionTmpData = {
      ...selectedAction,
      hasChanged: true,
      updateDataFieldData: [...selectedAction.updateDataFieldData]
    }

    selectedActionTmpData.updateDataFieldData.splice(index, 1);
    dispatch(setSelectedAction(selectedActionTmpData));
  };

  const textAreaSuggestionRef = useRef([]);

  const clearScript = (index: number, dataFieldValue: any) => {
    setValue(`list[${index}].script`, "");
    setValue(`list[${index}].expression`, "");

    const selectedActionTmpData = {
      ...selectedAction,
      hasChanged: true,
      updateDataFieldData: [...selectedAction.updateDataFieldData]
    }

    selectedActionTmpData.updateDataFieldData[index] = {
      [dataFieldValue.label]:
      {
        value: '',
        type: 'literal',
        dataType: dataFieldValue?.data.type
      }
    }

    dispatch(setSelectedAction(selectedActionTmpData));
  };

  const updateDataFieldScriptConfig = {
    errorMessageConfig: {
      script: getScriptValue(EditorScriptLocation.ERROR_MESSAGE) || getLowCodeEditorText(EditorScriptLocation.ERROR_MESSAGE, TEXT, `return ${getLowCodeEditorDefaultReturnText(TEXT)}`),
      dataType: TEXT,
    }
  };

  const initForm = (dataFields: any) => {
    const updatedData =
      Array.isArray(dataFields) &&
      dataFields.map((data: any) => {
        const key = Object.keys(data);
        const primKey = data[key];
        return {
          selectedDataField: { label: key?.[0], value: key?.[0], data: { type: primKey.dataType } },
          script: primKey?.type === "script" ? primKey.value : "",
          expression: primKey?.type === "literal" ? primKey.value : "",
        };
      });

    reset({
      list: updatedData,
      updateDataFieldActionName: selectedAction?.actionName,
      tempId: generateTempId(selectedAction, selectedAutomation, getActionNameForTempId(selectedAction), "action"),
    });
  };

  useEffect(() => {
    if (selectedAction[actionType.UPDATE_DATA_FIELD_DATA]?.length > 0) {
      const dataFields = selectedAction[actionType.UPDATE_DATA_FIELD_DATA];

      dataFields.forEach((dataField: any, indexData: number) => {
        const index = getValues()?.list?.findIndex((item: any) => dataField?.hasOwnProperty(item?.selectedDataField?.label));
        if (index !== -1) {
          if (dataField[getValues().list[indexData].selectedDataField?.label]?.value?.includes("return")) {
            setValue(`list[${indexData}].script`, "");
            setValue(`list[${indexData}].expression`, "");
            setValue(`list[${indexData}].script`, dataField[getValues().list[indexData].selectedDataField.label]?.value);
          }
        } else {
          initForm(dataFields);
        }
      });
    }
  }, [selectedAction]);

  useEffect(() => {
    if (!selectedAction?.updateDataFieldData || selectedAction.updateDataFieldData?.length == 0) {

      const selectedActionTmpData = {
        ...selectedAction,
        hasChanged: true,
        updateDataFieldData: [{
          "": {
            value: '',
            type: 'literal',
            dataType: ""
          }
        }]
      }

      dispatch(setSelectedAction(selectedActionTmpData));
    }
  }, [])

  useEffect(() => {
    recordPageVisit(PageCategoryEnum.automationBuilder, RudderStackAutomationBuilderEvents.updateDataFieldActionOnLoad);
  }, [])

  useEffect(() => {
    setExitOnError(selectedAction?.exitOnError ?? true);
  }, [selectedAction?.exitOnError])

  const getType = (script: any, expression: any) => {
    if (
      (script && script?.includes("return")) ||
      (expression && expression?.includes("return"))
    ) {
      return "script";
    } else {
      return "literal";
    }
  };

  const handleErrorMessageChange = (e) => {
    dispatch(
      setSelectedAction({
        ...selectedAction,
        hasChanged: true,
        [EditorScriptLocation.ERROR_MESSAGE]: {
          type: getInputType(e.target.value),
          value: removeBraces(e.target.value),
          dataType: TEXT,
        },
      })
    );
  };


  const onSubmit = (data: any) => {

    let tempActionId: string = selectedAction?.tempId || data?.tempId;
    if (!isEditing) {
      tempActionId = currTempIdVal.length === 0 ? (selectedAction?.actionId || selectedAction?.guardId || selectedAction?.conditionId) : currTempIdVal;
      dispatch(setSelectedAction({ ...selectedAction, tempId: currTempIdVal }));
    }

    let isTempIdChanged = tempIdChanged;
    if (tempActionId === (selectedAction?.actionId || selectedAction?.guardId || selectedAction?.conditionId)) {
      setTempIdChanged(false);
      isTempIdChanged = false;
    }

    if (isTempIdChanged && !isTempIdUnique(tempActionId, selectedAutomation)) {
      setError("tempId", {
        type: "manual",
        message: "This ID already exists. Please choose a different one.",
      });
      return;
    }

    const updateDataFieldData = data.list.map((item: any) => {
      return {
        [item.selectedDataField.label]: {
          type: getType(item.script, item.expression),
          value: item.expression || item.script,
          dataType: item.selectedDataField.data.type,
        },
      };
    });

    Promise.resolve(
      dispatch(setSelectedAction({ ...selectedAction, hasChanged: true, updateDataFieldData }))
    ).then(() => {
      const payload = {
        updateDataFieldData,
        actionName: data?.updateDataFieldActionName,
        actionType: selectedAction.actionType,
        actionId: tempActionId,
        tempId: tempActionId,
        isActive: selectedAction?.isActive ?? true,
        [EditorScriptLocation.ERROR_MESSAGE]: selectedAction[EditorScriptLocation.ERROR_MESSAGE],
        exitOnError: exitOnError
      };



      recordRSEvent(RudderStackAutomationBuilderEvents.updateDataFieldActionSaveClick, {
        context: RudderStackAutomationBuilderContext.automationBuilderAction,
        automationId: selectedAutomation.id,
        actionType: selectedAction.actionType,
        actionId: selectedAction.actionId
      })

      saveAction({
        payload,
        location: ValidLocation.AUTOMATION,
        dispatch,
        selectedAction,
        selectedAutomation,
        selectedActionBlock
      });

      clearRightSide();
    });
  };



  const getCurrentFieldValue = (path: string) => {
    const value = getValues(path);
    return value;
  };

  const handleValChange = (name, value) => {
    dispatch(setSelectedAction({ ...selectedAction, [name]: value, hasChanged: true }))
  }

  const handleTempActionValChange = (name, value) => {
    setCurrTempIdVal(value);
  }

  const handleEditClick = () => {
    setIsEditing(true);
    setTempIdChanged(true);
  };

  const handleTickClick = () => {
    setIsEditing(false);
  };

  const setTempIdError = (error) => {
    if (error) {
      setError("tempId", { type: "manual", message: error });
    } else {
      clearErrors("tempId");
    }
  };


  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{ overflow: 'auto', height: '100%' }}>
        <HeadingWrapper>
          <Heading>Update Data Fields</Heading>
          <IsActive control={control} closeOnClick={clearRightSide} />
        </HeadingWrapper>
        <Label> Name </Label>
        <InputContainer>
          <ZInput
            onChange={handleValChange}
            register={register}
            validations={{
              required: "Update Data Field Action Name is required",
            }}
            name="updateDataFieldActionName"
            placeholder="Name of the Action"
          />
        </InputContainer>
        <InputTempId
          label="Action Id"
          onChange={handleTempActionValChange}
          register={register}
          requiredErrorMessage="Action Id is required"
          errors={errors}
          isReadOnly={!isEditing}
          handleTickClick={handleTickClick}
          handleEditClick={handleEditClick}
          getValues={getValues}
          setTempIdError={setTempIdError}
          isEditing={isEditing}
          name="tempId"
        />
        <ErrorMessage errors={errors} fieldName={"updateDataFieldActionName"} />


        <ErrorMessageInput
          label={`Error Message to be shown if the Update Datafield fails`}
          onChangeFunc={handleErrorMessageChange}
          register={register}
          errors={errors}
          apiBodyPayload={actionConditionPayload}
          openJSEditorLayout={openJSEditorLayout}
          config={updateDataFieldScriptConfig.errorMessageConfig}
        />


        <div style={{ marginTop: '10px', padding: '11px' }}>
          <FormControlLabel
            control={
              <input
                type="checkbox"
                style={{ height: '18px', width: '18px' }}
                checked={exitOnError}
                onChange={(value) => {
                  setExitOnError((currentValue) => !currentValue)
                }}
                value="one"
              />
            }
            label={<Typography variant='h6' style={{ color: '#808080', fontSize: '14px', fontWeight: 'bold', marginLeft: '10px' }}>Exit Transition On Error</Typography>}
          />
        </div>

        <div className='update__DataField'>
          <div className='update__DataField__headerTableRow '>
            <div className='update__DataField__paramsTableColumn_left'>Data Field</div>
            <div className='update__DataField__paramsTableColumn_right'>Value</div>
          </div>
          <div className='update__DataField__allRows'>
            {fields.map((item, index) => {
              const dataFieldIndexKey = `list[${index}].selectedDataField`;
              const dataFieldValue = getValues(dataFieldIndexKey);
              const watchScript = watch(`list[${index}].script`);
              const expressionIndex = `list[${index}].expression`;
              return (
                <div
                  key={item.id}
                  className="update__DataField__paramsTableRow">
                  <div className="update__DataField__paramsTableColumn_left">
                    <Controller
                      render={({ field }) => (
                        <Select
                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          menuPlacement={"bottom"}
                          className={classes.reactSelect}
                          placeholder='Select'
                          menuPortalTarget={document.body}
                          {...field}
                          // isClearable
                          options={options
                            ?.filter((item) => item.label.includes("task.data"))
                            .map((option) => {
                              return {
                                label: option.label,
                                value: option.label,
                                data: {
                                  type: option.value,
                                },
                              };
                            })}
                          onChange={(e: any) => {
                            setValue(dataFieldIndexKey, e);
                            clearErrors(dataFieldIndexKey);


                            const selectedActionTmpData = {
                              ...selectedAction,
                              hasChanged: true,
                              updateDataFieldData: [...selectedAction.updateDataFieldData]
                            }

                            selectedActionTmpData.updateDataFieldData[index] = {
                              [e.value]: {
                                value: '',
                                type: 'literal',
                                dataType: e.data.type
                              }
                            }

                            dispatch(setSelectedAction(selectedActionTmpData));
                          }}
                        />
                      )}
                      name={dataFieldIndexKey}
                      control={control}
                      rules={{
                        required: true,
                      }}
                    />

                    {errors?.list?.[index]?.selectedDataField &&
                      <ErrorMessageFields />}

                  </div>

                  <div className='update__DataField__paramsTableColumn_right'>
                    <div className="" style={{
                      width: '100%',
                      display: 'flex',
                    }}>
                      {
                        dataFieldValue?.data?.type ?

                          <img className='pointer updateDataFieldImg2' src={datafieldlogo} alt='fnIcon'
                            onClick={() => {
                              dataFieldPickerUtil({
                                name: expressionIndex,
                                dispatch,
                                dataType: dataFieldValue?.data?.type || DataFieldType.TEXT,
                              })

                              setDataFieldIndex(index);
                            }} />
                          :
                          <img className='disabledImg updateDataFieldImg2' src={datafieldlogo} alt='fnIcon' />
                      }

                      <div>
                        {watchScript ? (
                          <>
                            <span className={classes.disabledSpan}>
                              FX Smart Script
                              <span
                                className={classes.closeButton}
                                onClick={() => {
                                  clearScript(index, dataFieldValue);
                                }}>
                                X
                              </span>
                            </span>
                          </>
                        ) : (
                          <>
                            <Controller
                              name={expressionIndex}
                              control={control}
                              rules={{
                                required: true,
                              }}
                              render={({ field }) => (
                                <ReactTextareaAutocomplete
                                  data-max="1"
                                  {...field}
                                  style={{ minHeight: '31px', height: '31px', overflow: 'auto', paddingBottom: '0px', paddingTop: '5px', marginTop: '0px' }}
                                  className={classes.textArea}
                                  id={field?.name}
                                  innerRef={(el) => {
                                    const indexOfDataField = dataFieldId.indexOf(field?.name);
                                    if (!dataFieldId?.includes(field?.name) && el && indexOfDataField) {
                                      setDataFieldId((fieldId) => [...fieldId, field?.name]);
                                      textAreaSuggestionRef.current.push(el);
                                    } else {
                                      textAreaSuggestionRef.current[indexOfDataField] = el;
                                    }
                                  }}
                                  placeholder='Enter value'
                                  onChange={(e: any) => {
                                    setValue(expressionIndex, e.target.value);
                                    clearErrors(expressionIndex);
                                    const selectedActionTmpData = {
                                      ...selectedAction,
                                      hasChanged: true,
                                      updateDataFieldData: [...selectedAction.updateDataFieldData]
                                    }

                                    selectedActionTmpData.updateDataFieldData[index] = {
                                      [Object.keys(selectedAction.updateDataFieldData[index])[0]]: {
                                        value: e.target.value,
                                        type: 'literal',
                                        dataType: TEXT
                                      }
                                    }

                                    dispatch(setSelectedAction(selectedActionTmpData));
                                  }}
                                  loadingComponent={() => <span>Loading</span>}
                                  movePopupAsYouType={true}
                                  trigger={{
                                    "${": {
                                      dataProvider: (token) => {
                                        return options;
                                      },
                                      component: Item,
                                      output: (item, trigger) => item.char,
                                    },
                                  }}
                                />
                              )}
                            />

                            {errors?.list?.[index]?.expression &&
                              <ErrorMessageFields />}
                          </>
                        )}
                      </div>


                      <SmartInputScriptButton
                        handleClick={() => {
                          const updateDataFieldDataType = dataFieldValue?.data.type;
                          dispatch(setScriptEditorPayload(actionConditionPayload));
                          openJSEditorLayout({
                            config: {
                              script: watchScript || getLowCodeEditorText(`${dataFieldValue?.label} in action ${EditorScriptLocation.UPDATE_DATA_FIELD}`, updateDataFieldDataType, `return ${getLowCodeEditorDefaultReturnText(updateDataFieldDataType)}`),
                              dataType: updateDataFieldDataType,
                            },
                            editorLocation: `${dataFieldValue?.label} in action ${EditorScriptLocation.UPDATE_DATA_FIELD} ${index}`,
                          });
                        }}
                        isDisabled={!dataFieldValue?.data?.type}
                        isInline
                        isVisible={true}
                      />

                      {/* Delete Button */}
                      <div className='update__DataField__paramsTableIcons pointer'>

                        <div className='update__DataField__paramsTableIcons'>
                          {(index > 0 || fields.length > 1) && (
                            <DeleteIcon
                              size={"1.4rem"}
                              color={"#f03738"}
                              onClick={() => removeRow(index)}
                            />
                          )}
                        </div>

                        <BsPlus size={'1.4rem'} color={'#9a99a2'} onClick={() => insertNewRow(index, dataFieldValue)} />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {scriptEditorOpen && <ZJSEditorLayout />}
      {dataPicker?.isOpened &&
        <DatafieldSelector selectDataField={() => {
          setTimeout(() => {
            textAreaSuggestionRef.current[dataFieldIndex]?.click();
          }, 0)
        }} setValue={setValue} updateDataField={true} getValues={getCurrentFieldValue} />}
    </form>
  );
};

export default UpdateDatefield;