import React from 'react'
import styled from "styled-components"
import { OnboardingIdsForComponent } from 'utils/CommonUtils'
import { ZButton } from 'views/commonComponents/commonComponents.styles'

type Props = {
    heading:string,
    onSave: Function,
    onCancel: Function,
    onSaveText: string,
    onCancelText: string
}

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #C2C9D1;;
    padding: 10px;
    /* margin-bottom: 20px; */
    align-items: center;
    border-bottom: 1px solid #e1e4e8;
`

const TopBar = ({heading, onSave, onCancel,onSaveText, onCancelText}: Props) => {
  return (
    <Wrapper>
        <div>
            <p style={{fontSize:"16px", fontWeight:"600"}}>{heading}</p>
        </div>
        <div>
        {/* @ts-ignore */}
            <ZButton id={OnboardingIdsForComponent.CREATE_NEW_TABLE_RECORD_CREATE_BUTTON} type='submit' className='mr-2' variant='contained' onClick={onSave}>{onSaveText}</ZButton>
            {/* @ts-ignore */}
            <ZButton secondary variant='contained' onClick={onCancel}>{onCancelText}</ZButton>
        </div>
    </Wrapper>
  )
}

export default TopBar