import * as rudderanalytics from "rudder-sdk-js";
import jwt_decode from "jwt-decode";

export const PageCategoryEnum = Object.freeze({
    "task": "task",
    "user": "user",
    "team": "team",
    "role": "role",
    "signup":"signup",
    "login":"login",
    "dashboard": "dashboard",
    "calendar": "calendar",
    "map": "map",
    "settings": "settings",
    "dataModel":"dataModel",
    "userEdit":"user_edit",
    "resetPassword":"reset_password",
    "verifyOTP":"verify_otp",
    "changePassword":"change_password",
    "automationBuilder":"automationBuilder",
    "monitorList":"monitorList",
    "templateList":"templateList",
});

export const initializeRudderstack = () => {
    if (process.env["REACT_APP_ENVIRONMENT"] === "prod") {
        rudderanalytics.ready(() => { console.log("Rudderstack is all set!!!", "******") })
        rudderanalytics.load("1yBkTZiviVUzk2iAWBYzJQSD6aJ", "https://zorpsubrabhyk.dataplane.rudderstack.com", { logLevel: "WARN", integrations: { All: true } })
    }
    else {
        rudderanalytics.ready(() => { console.log("Rudderstack is all set!!!", "******") })
        rudderanalytics.load("1w7OHJAQmdDUS9P6CPWQv7fneHy", "https://zorpsubrabhyk.dataplane.rudderstack.com", { logLevel: "INFO", integrations: { All: true } })
    }
}

export const identifyUser = (userId) => {
    let accountId = undefined;
    let decoded = undefined;
  
    const at = localStorage.getItem('at');
    if (at) {
      decoded = jwt_decode(at);
    }
    if (decoded) {
      accountId = decoded.accountId;
    }
    rudderanalytics.identify(userId, {accountId: accountId}, {accountId: accountId});
}

export const recordPageVisit = (category, name, properties) => {
    let accountId = undefined;
    let decoded = undefined;
  
    const at = localStorage.getItem('at');
    if (at) {
      decoded = jwt_decode(at);
    }
    if (decoded) {
      accountId = decoded.accountId;
    }
    properties = properties || {};
    properties.accountId = accountId;
    rudderanalytics.page(category, name, properties || {});
}

export const recordEvent = (event, properties, options, callback) => {
    rudderanalytics.track("CC - " + event, properties, options, callback);
}


export const reset = () => {
    rudderanalytics.reset();
}

export { rudderanalytics }