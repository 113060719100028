import { ABActionNameUniqueErrorMessage, ABExpressionActionExpressionErrorMessage, ABExpressionConditionErrorMessage, ABExpressionConditionExpressionErrorMessage, ABExpressionConditionNameErrorMessage, ABExpressionGuardErrorMessage, ABExpressionGuardExpressionErrorMessage, ABLocationGuardNameMessage, ABNameErrorMessage, ABTriggerNameErrorMessage, ABTriggerNameErrorMessageSC } from "constants/ABErrorMessage";
import { cloneDeep } from "lodash";
import { EditorScriptLocation } from "lowcode/state/types/types";
import { guardTypes } from "views/workflows/workflowactions/api/guard.types";
import { ErrorLocations } from "views/workflows/WorkflowErrors/utils";
import { ABMetadataTypeEnum, actionType, conditionTypes } from "../types/ABType";

/**
 *
 * @param {*} allAutomations
 */

export const validateAutomation = (store) => {
  const allAutomations = store.getState()?.automation?.allAutomations;
  const { updatedAutomations, allAutomationErrors } = validate(allAutomations,store);
  return { updatedAutomations, allAutomationErrors }
};

export const validate = (newAutomations,store) => {
  let updatedAutomations = cloneDeep(newAutomations)
  const metadataType = store.getState()?.automation.metadataType;
  //n3
  let allAutomationErrors = [];
  const allActionsAndConditionsNames = {};
  const allGuardsNames = {};

  // for all AB except smart component
  let allTriggersName = {}

  const abNameWithEmptyAction = [];

  updatedAutomations.forEach((automation) => {

    // check automation has empty Trigger
    if (metadataType != ABMetadataTypeEnum.MONITOR && automation.triggers?.length == 0){
      const message = `${automation.name} Should Have atleast one Trigger`;
      const location = ErrorLocations.GENERAL;
      allAutomationErrors.push({ message, location , payload: { message, location } });
    }

    if (metadataType == ABMetadataTypeEnum.WORKFLOW_DECISION_NODE_TRANSITION_IF_SWITCH && automation.guards.length === 0) {
      const message = `${automation.name} Should Have atleast one Validation`;
      const location = ErrorLocations.GENERAL;
      allAutomationErrors.push({ message, location , payload: { message, location } });
    }

    // * * automation name validation * * //
    const currentAutomationName = automation.name;
    automation.errors = [];

    updatedAutomations.forEach((abData) => {

      if (abData.id != automation.id) {
        const prevAutomationName = abData.name;
        if (currentAutomationName === prevAutomationName) {
          automation.errors = [ABNameErrorMessage];
        }
      }
    })

    let errorWithMoreInfo = automation.errors.map((error) => ({
      message: error,
      location:ErrorLocations.GENERAL,
      payload: {
        automation,
        message: error,
      }
    }));

    allAutomationErrors = [...allAutomationErrors, ...errorWithMoreInfo];

    // * * automation trigger validation starts * * //
    automation.triggers.forEach((trigger) => {

      if ((metadataType != ABMetadataTypeEnum.WORKFLOW_SCREEN_SMART_COMPONENT && metadataType != ABMetadataTypeEnum.WORKFLOW_SCREEN_ONLOAD)) {
        // AB trigger validation except smart component

        trigger.errors = []

        // checking same trigger is repeating
        if (!allTriggersName[trigger?.event?.id]) {
          allTriggersName[trigger?.event?.id] = 1
        } else {
          allTriggersName[trigger?.event?.id] = allTriggersName[trigger?.event?.id] + 1
          trigger.errors.push(ABTriggerNameErrorMessage)
        }

        let errorWithMoreInfo = trigger.errors.map((error) => ({
          message: error,
          payload: {
            automation: automation,
            message: error,
          },
          location: ErrorLocations.AUTOMATION_TRIGGER
        }))
        allAutomationErrors = [...allAutomationErrors, ...errorWithMoreInfo];

      } else {
        // AB trigger validation for smart component

        trigger.errors = [];

        // checking trigger name is repeating
        if (allTriggersName[trigger?.event?.label] && allTriggersName[trigger?.event?.label] == automation.id) {
          trigger.errors.push(ABTriggerNameErrorMessageSC.replace('${triggerName}', allTriggersName[trigger?.event?.label]));
        } else {
          allTriggersName[trigger?.event?.label] = automation.id;
        }

        let errorWithMoreInfo = trigger.errors.map((error) => ({
          message: error,
          payload: {
            automation,
            trigger:trigger
          },
          location: ErrorLocations.AUTOMATION_SC_TRIGGER
        }))
        allAutomationErrors = [...allAutomationErrors, ...errorWithMoreInfo];

      }


    })

    // * * automation Guard (which block the transition) validation starts * * //
    automation.guards.forEach((guard, idx) => {
      if (guard.guardType === guardTypes.LOCATION_CHECK) {
        const locationError = validLocationCheck(guard);

        if (allGuardsNames[guard?.guardName] && allGuardsNames[guard?.guardName] == automation.id) {
          locationError.push(ABLocationGuardNameMessage)
        } else {
          allGuardsNames[guard?.guardName] = automation.id;
        }


        let errorWithMoreInfo = locationError.map((error) => ({
          message: error,
          payload: {
            action: guard,
            automation
          },
          location: ErrorLocations.AUTOMATION_LOC_GUARD
        }))
        guard.errors = locationError;
        allAutomationErrors = [...allAutomationErrors, ...errorWithMoreInfo];
      } else if (guard.guardType === guardTypes.EXPRESSION_GUARD) {
        const expressionErrors = validExpressionGuard(guard);

        if (allGuardsNames[guard?.guardName] && allGuardsNames[guard?.guardName] == automation.id) {
          expressionErrors.push(ABExpressionGuardErrorMessage)
        } else {
          allGuardsNames[guard?.guardName] = automation.id;
        }

        let errorWithMoreInfo = expressionErrors.map((error) => ({
          message: error,
          payload: {
            action: guard,
            automation:automation,
            message: error
          },
          location: ErrorLocations.AUTOMATION_GUARD
        }));

        guard.errors = expressionErrors
        allAutomationErrors = [...allAutomationErrors, ...errorWithMoreInfo];
      }
    })

    // * * action block action push notification * * //
    automation.actionBlocks.forEach((actionBlock) => {

      // checking Automation has a action, check : should have atleast one Action
      if ((metadataType != ABMetadataTypeEnum.WORKFLOW_DECISION_NODE_TRANSITION_IF_SWITCH && metadataType != ABMetadataTypeEnum.WORKFLOW_DECISION_NODE_TRANSITION_ELSE_SWITCH) &&  actionBlock.actions.length == 0) {
        const checkAutomationId = abNameWithEmptyAction.find((data) => data.automationId == automation.id);

        if (!checkAutomationId) {
          abNameWithEmptyAction.push({
            actionBlockId: actionBlock.actionBlockId,
            automationId: automation.id,
            automationName: automation.name
          });
        }

      } else {
        const checkAutomationId = abNameWithEmptyAction.findIndex((data) => data.actionBlockId == actionBlock.actionBlockId);
        if (checkAutomationId) {
          abNameWithEmptyAction.splice(checkAutomationId, 1);
        }

      }



      actionBlock.actions.forEach((action, idx) => {

        if (action.actionType === actionType.PUSH_NOTIFICATION) {
          let pushNotificationErrors = validatePushNotification(action);
    
          if (allActionsAndConditionsNames[action.actionName] && allActionsAndConditionsNames[action.actionName] == automation.id) {
            pushNotificationErrors.push(ABActionNameUniqueErrorMessage);
          } else {
            allActionsAndConditionsNames[action.actionName] = automation.id;
          }

          let errorWithMoreInfo = pushNotificationErrors.map((error) => ({
            message: error,
            payload: {
              automation,
              actionBlock,
              action: action,
            },
            location: ErrorLocations.AUTOMATION_PUSH_ACTION
          }))
          // allAutomationErrors holds error shown in the global error box
          allAutomationErrors = [...allAutomationErrors, ...errorWithMoreInfo];
          // action.errors holds error shown in each action contextually
          action.errors = pushNotificationErrors;

          // * * action block action API Action * * //
        } else if (action.actionType === actionType.API_ACTION) {
          const apiErrors = validAPIAction(action);

          if (allActionsAndConditionsNames[action.actionName] && allActionsAndConditionsNames[action.actionName] == automation.id) {
            apiErrors.push(ABActionNameUniqueErrorMessage);
          } else {
            allActionsAndConditionsNames[action.actionName] = automation.id;
          }


          let errorWithMoreInfo = apiErrors.map((error) => ({
            message: error,
            payload: {
              automation,
              actionBlock,
              action: action,
            },
            location: ErrorLocations.AUTOMATION_API_ACTION
          }))

          action.errors = apiErrors;
          allAutomationErrors = [...allAutomationErrors, ...errorWithMoreInfo];

        } else if (action.actionType === actionType.UPDATE_DATA_FIELD) {
          const updateDataFieldError = [];

          if (allActionsAndConditionsNames[action.actionName] && allActionsAndConditionsNames[action.actionName] == automation.id) {
            updateDataFieldError.push(ABActionNameUniqueErrorMessage);
          } else {
            allActionsAndConditionsNames[action.actionName] = automation.id;
          }

          let errorWithUpdateInfo = updateDataFieldError.map((error) => ({
            message: error,
            payload: {
              action: action,
              automation,
              actionBlock,
            },
            location: ErrorLocations.AUTOMATION_UDF_ACTION
          }))

          action.errors = updateDataFieldError;
          allAutomationErrors = [...allAutomationErrors, ...errorWithUpdateInfo];
        } else if (action.actionType === actionType.SCREEN_COMPONENT) {
          const abSCActionError = [];
          if (allActionsAndConditionsNames[action.actionName] && allActionsAndConditionsNames[action.actionName] == automation.id) {
            abSCActionError.push(ABActionNameUniqueErrorMessage);
          } else {
            allActionsAndConditionsNames[action.actionName] = automation.id;
          }

          let errorWithMoreInfo = abSCActionError.map((error) => ({
            message: error,
            payload: {
              automation,
              actionBlock,
              action: action,
            },
            location: ErrorLocations.AUTOMATION_SC_ACTION
          }))
          action.errors = abSCActionError;
          allAutomationErrors = [...allAutomationErrors, ...errorWithMoreInfo];
        } else if (action.actionType === actionType.EXPRESSION) {
          const abSCActionError = [];
          
          if (allActionsAndConditionsNames[action.actionName] && allActionsAndConditionsNames[action.actionName] == automation.id) {
            abSCActionError.push(ABActionNameUniqueErrorMessage);
          } else {
            allActionsAndConditionsNames[action.actionName] = automation.id;
          }

          if(!action[EditorScriptLocation.AB_SMART_ACTION_JS_FUNCTION]){
            abSCActionError.push(ABExpressionActionExpressionErrorMessage.replace('${actionName}', action.actionName));
          }


          let errorWithMoreInfo = abSCActionError.map((error) => ({
            message: error,
            payload: {
              automation,
              actionBlock,
              action: action,
            },
            location: ErrorLocations.AUTOMATION_JF_ACTION
          }))


          action.errors = abSCActionError;
          allAutomationErrors = [...allAutomationErrors, ...errorWithMoreInfo];
        }

      });

      actionBlock.conditions.forEach((condition, idx) => {
        if (condition.conditionType === conditionTypes.EXPRESSION || condition.conditionType === conditionTypes.SCREEN_COMPONENT) {
          const expressionErrors = validExpressionCondition(condition);

          if (allActionsAndConditionsNames[condition.conditionName] && allActionsAndConditionsNames[condition.conditionName] == automation.id) {
            expressionErrors.push(ABExpressionConditionNameErrorMessage);
          } else {
            allActionsAndConditionsNames[condition.conditionName] = automation.id;
          }

          let errorWithMoreInfo = expressionErrors.map((error) => ({
            message: error,
            payload: {
              actionBlock: actionBlock,
              action: condition,
              automation: automation
            },
            location: ErrorLocations.AUTOMATION_EXP_CONDITION
          }))
          
          condition.errors = expressionErrors
          allAutomationErrors = [...allAutomationErrors, ...errorWithMoreInfo];
        }

        if (condition.conditionName in allActionsAndConditionsNames) {
          allActionsAndConditionsNames[condition.conditionName] += 1
        } else {
          allActionsAndConditionsNames[condition.conditionName] = 1
        }
      })
    });

  });


  return { updatedAutomations, allAutomationErrors };
};

const invalidName = (str) => {
  if (!new RegExp(/^[a-z0-9_\s${}.]+$/i).test(str)) {
    return true;
  }

  return false;
};

const invalidURL = (str) => {
  if (
    !new RegExp(
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
    ).test(str)
  ) {
    return true;
  }
  return false;
};

const validatePushNotification = (action) => {
  let errors = [];
  if (!action?.actionName || action?.actionName === "") {
    errors.push("Push Notification must have a name");
    if (invalidName(action?.message)) {
      errors.push(
        "Push Notification Message can only have alphanumeric, underscore, space and hyphen,$,{,} and period(.) as characters"
      );
    }
  }

  if (!action?.message?.value || action?.message?.value === "") {
    errors.push("Push Notification must have a message");
    // element.data.errors.push("Push Notification must have a message")
  }

  if (!action?.message?.value || action?.title?.value === false || action?.title?.value === "") {
    errors.push("Push Notification must have a title");
  }

  return errors;
};

const validAPIAction = (action) => {
  let errors = []
  if (!action?.actionName || action?.actionName === "") {
    errors.push("API action must have a valid name!");
  }

  if (invalidName(action?.actionName)) {
    errors.push("API action must have a valid name!");

  }


  // // Must have a valid URL
  // if (!invalidURL(action?.url?.value) || !invalidURL(action?.url)) {
  //   errors.push("Invalid URL Provided");
  // }

  // valid header check
  if (action?.headers !== "") {
    if (typeof action?.headers === "string") {
      const keyValuePair = action?.headers?.split("###");
      keyValuePair?.forEach((kvPair) => {
        //split based on ":"
        const singleKeyValue = kvPair?.split(":");
        if (singleKeyValue.length !== 2) {
          errors.push("Invalid Headers providers");
        }

        singleKeyValue.forEach((skPair) => {
          if (skPair === "") {
            errors.push("Each Header must have a key value pair");
          }
        });
      });
    } else {
      const allkeys = Object.keys(action?.headers || {});
      allkeys.forEach((key) => {
        if (key.trim().length === 0) {
          errors.push("Each Header must have a key value pair");
        }

        if (action?.headers[key].length === 0) {
          errors.push("Each Header must have a key value pair");
        }
      })
    }
  }

  return errors
}

const validLocationCheck = (guard) => {
  const errors = []
  if (!guard.guardName || guard.guardName === "") {
    errors.push("Guards must have a name");
  }

  // if (!guard.validationType || !guard.validationType) {
  //   errors.push("Location must have a valid alogrithm");
  // }

  if (
    !guard.latitude ||
    (guard.latitude.type === "literal" && !guard.latitude.value)
  ) {
    errors.push("Location must have a valid latitude");
  }

  if (
    !guard.longitude ||
    (guard.longitude.type === "literal" && !guard.longitude.value)
  ) {
    errors.push("Location must have a valid longitude");
  }

  if (
    !guard.customerLatitude ||
    (guard.customerLatitude.type === "literal" &&
      !guard.customerLatitude.value)
  ) {
    errors.push("Location must have a valid customer latitude");
  }

  if (
    !guard.customerLongitude ||
    (guard.customerLongitude.value === "literal" &&
      !guard.customerLongitude.value)
  ) {
    errors.push("Location must have a valid customer longitude");
  }

  if (
    !guard.expectedDistance ||
    (guard.expectedDistance.value === "literal" &&
      !guard.expectedDistance.value &&
      guard.expectedDistance.value < 0)
  ) {
    errors.push("Expected Distance must be a valid number");
  }

  return errors
}

const validExpressionGuard = (guard) => {
  const errors = []
  if (!guard.guardName || guard.guardName === "") {
    errors.push("Guards must have a name");
  }

  if (!guard.expression) {
    errors.push(ABExpressionGuardExpressionErrorMessage.replace('${guardName}', guard.guardName));
  }
  return errors
}


const validExpressionCondition = (condition) => {
  const errors = [];
  
  if (!condition.conditionName || condition.conditionName === "") {
    errors.push(ABExpressionConditionErrorMessage);
  }

  if (condition.conditionType === conditionTypes.EXPRESSION && !condition[EditorScriptLocation.AB_CONDITION]) {
    errors.push(ABExpressionConditionExpressionErrorMessage.replace('${conditionName}', condition.conditionName));
  }

  return errors
}