import { createSlice } from '@reduxjs/toolkit';
import {isArray} from "lodash"

const initialState = {};

export const TaskCreationSlice = createSlice({
  name: 'taskCreation',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // setTaskType: (state, action) => {
    //     state.taskType = action.payload;
    // },
    setValue: (state, action) => {
      if (action.payload && action.payload.key) {
        // if key is not present
        if(!state[action.payload.key])
          state[action.payload.key] = []

        // if the payload is array
        if(isArray(action.payload.value)) {
          state[action.payload.key] = [...state[action.payload.key],...action.payload.value]
        } else {
          state[action.payload.key] = action.payload.value;
        }
          
      }
    },
    cancelTaskCreation: (state, action) => {
      return {};
    },
    initTaskCreation: (state, action) => {
      return {};
    },
    cleanTaskCreation: () => {
      return {};
    },
    loadMetaData : (state, action) => {
      state.metaData = action.payload
    },
    setCreatedItemsForTaskCreate : (state,action) => {
      const {name, data} = action.payload

      if(!state[name]) {
        state[name] = []
      }
      data.forEach((singleData) => {
        state[name].push(singleData)
       })
    },
    editCreatedItemsForTaskCreate : (state,action) => {
      const {name,id,editedData} = action.payload
      //@ts-ignore
      const idx = state[name]?.findIndex((item) => item.id === id)
      if(idx !== -1) {
        //@ts-ignore
        state[name][idx] = {...editedData}
      }
      
    },
    deleteCreatedItemsForTaskCreate : (state,action) => {
      const {name, id} = action.payload
      //@ts-ignore
      const idx = state[name]?.findIndex((item) => item.id === id)
      if(idx !== -1) {
        //@ts-ignore
        state[name]?.splice(idx, 1); 
      }
    }
  },
  extraReducers: (builder) => {},
});

export const {
  // setTaskType,
  setValue,
  cancelTaskCreation,
  initTaskCreation,
  cleanTaskCreation,
  loadMetaData,
  setCreatedItemsForTaskCreate,
  editCreatedItemsForTaskCreate,
  deleteCreatedItemsForTaskCreate
} = TaskCreationSlice.actions;

export default TaskCreationSlice.reducer;
