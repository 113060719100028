import styled from 'styled-components';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

const Button = styled.button`
  background-color: #f2f2f2;
  border: 1px solid #c2c9d1;
  color: #616569;
  padding: 10px 16px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
`;

const GetTutorialsButton = () => {
  const handleOpen = () => {};

  return (
    <>
      <Button id='get_tutorial_and_help' onClick={handleOpen}>
        <span style={{ display: 'inherit' }}>
          <AiOutlineQuestionCircle size={'1.4rem'} />
        </span>
        <span style={{ marginLeft: '8px', marginTop: '1px' }}> View Tutorial or Get Help </span>
      </Button>
    </>
  );
};

export default GetTutorialsButton;
