import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getListFromTaskType } from 'utils/TasktypeUtils';

const CustomDataFieldsList = ({ inputListData, newSelectList, setSelectedList }: any) => {
  const [selectBoxList, setSelectBoxList] = useState<any>();
  const [newSelectListDuplicate, setnewSelectListDuplicate] = useState<any>();

  useEffect(() => {
    setSelectBoxList(JSON.parse(JSON.stringify(newSelectList)));
  }, [`${newSelectList}`,])

  const setIndeterminateState = React.useCallback(el => {
    const taskType = el?.getAttribute("data-taskType")

    if (el && taskType) {
      el.indeterminate = (selectBoxList[0][taskType].length != getListFromTaskType(inputListData, taskType).length && selectBoxList[0][taskType].length > 0);
    }
    
  }, [JSON.stringify(newSelectListDuplicate), selectBoxList]);

  return (
    <FormGroup>
      {selectBoxList &&
        Object.keys(inputListData).map((taskType: any, index: any) => (
          <>
            <FormControlLabel
              style={{ marginLeft: '0px',marginTop:'10px',marginBottom:'6px' }}
              key={index}
              control={
                <>
                  <input
                    data-taskType={taskType}
                    type="checkbox"
                    id='task-export-select-all'
                    ref={setIndeterminateState}
                    checked={selectBoxList[0][taskType].length == getListFromTaskType(inputListData, taskType).length}
                    style={{ marginLeft: '10px', marginRight: "10px" }}
                    onChange={(value) => {
                      if (selectBoxList[0][taskType].length > 0 && selectBoxList[0][taskType].length != getListFromTaskType(inputListData, taskType).length) {
                        const newAr = selectBoxList[0];
                        newAr[taskType] = getListFromTaskType(inputListData, taskType);
                        setSelectedList(newAr);
                      }
                      else if (selectBoxList[0][taskType].length > 0) {
                        const newAr = selectBoxList[0];
                        newAr[taskType] = [];
                        setSelectedList(newAr);
                      }
                      else {
                        const newAr = selectBoxList[0];
                        newAr[taskType] = getListFromTaskType(inputListData, taskType);
                        setSelectedList(newAr);
                      }
                    }}
                  />
                </>
              }
              label={<p style={{fontWeight:'bold',fontSize:'14px',color:'#000'}}>{inputListData[taskType][0].taskTypeDisplayName} ( {taskType} )</p>}
            />
            {selectBoxList &&
              inputListData[taskType].map((inputs: any, index: any) => (
                <>
                  <Grid style={{ marginLeft: '40px' }}>
                    <FormControlLabel
                    style={{ marginTop:'2px',marginBottom:'2px' }}
                      key={index}
                      control={
                        <input
                          type="checkbox"
                          style={{ marginRight: "10px", marginTop: "5px", marginBottom: "5px" }}
                          checked={selectBoxList[0][taskType].includes(inputs.name)}
                          onChange={(value) => {
                            if (selectBoxList[0][taskType].includes(inputs.name)) {
                              const newAr = selectBoxList[0];
                              const findInd = newAr[taskType].findIndex((data:any) => data == inputs.name);
                              newAr[taskType].splice(findInd, 1);
                              setSelectedList(newAr);
                              setnewSelectListDuplicate(newAr);
                            } else {
                              const newAr = selectBoxList[0];
                              newAr[taskType].push(inputs.name);
                              setSelectedList(newAr);
                              setnewSelectListDuplicate(newAr);
                            }
                          }}
                        />
                      }
                      label={<p style={{fontWeight:'400',fontSize:'14px',color:'#000'}}>{inputs.name}</p>}
                    />
                  </Grid>
                </>

              ))
            }
          </>
        ))
      }
    </FormGroup>
  )
}

export default CustomDataFieldsList;
