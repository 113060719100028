import styled from "styled-components"
import { Button } from "@material-ui/core";
import {FiFilter} from "react-icons/fi"

export const ZFilterButton = styled(Button)`
    color:rgba(0, 0, 0, 0.6);   
`

export const ZFilterIcon = styled(FiFilter)`
    color: rgba(0, 0, 0, 0.6);
`