import React, { useEffect } from "react";
import {
  SubHeading,
  InputWithCopy,
  CopyText,
  Wrapper2,
  FlexDiv,
} from "../settings.style";
import { Wrapper } from "../GeneralSetting/GeneralSettingEdit";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import { BiCopy } from "react-icons/bi";
import { AccountService } from "providers/data/services/AccountService";
import notification from "notifications/notifications";
import jwtDecode from "jwt-decode";
import { Label } from "views/settings/GeneralSetting/GeneralSettingEdit";
import DeletionConfirmationDialog from "utils/DeletionConfirmationDialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { SingleTableCell } from "render-engine/render.styles";
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import SecretKeyModal from "./SecretKeyModal";
import { GrPowerCycle, GrRefresh } from "react-icons/gr";
import { MdOutlineRefresh } from "react-icons/md";
import { Divider } from "@mui/material";

type Props = {};

// const regenerateToken = () => {
//   const token =
// }

const SecretManagement = (props: Props) => {
  const [accountDetails, setAccountDetails] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [copyLoading, setCopyLoading] = React.useState<boolean>(false);
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
  const [dataForEdit, setDataForEdit] = React.useState<any>(null);
  const [keyForDelete, setKeyForDelete] = React.useState<any>(null);

  const getAccountDetails = async () => {
    const data: any = await AccountService.getSecretKeys();
    if (data) {
      console.log(data.data);
      setAccountDetails([ ...data.data ]);
    }
  };

  const fetchFullToken = async () => {
    if(!accountDetails){
      notification("error", "No token found.Please generate a new token");
      return;
    }
    try {
      setCopyLoading(true);
      const token: any = accountDetails?.find(
        (key: any) => key.keyType === "zorp"
      )
      if (token) {
        // copy token.authToken to clipboard
        window.navigator.clipboard.writeText(token.key);
        notification("success", "Token copied to clipboard");
      } else {
        notification("error", "Token not Found! Generate a new token");
      }
    } catch (error) {
      console.log(error);
      notification("error", "Something went wrong, please try again");
    }
    setCopyLoading(false);
  };

  const regenerateToken = async () => {
    setLoading(true);
    try {
      const token: any = await AccountService.generateUnmaskedToken({
        keyType: "zorp",
      });
      if (token) {
        notification("success", "Secret key added");
        await getAccountDetails();
      } else {
        notification("error", "Something went wrong, please try again");
      }
    } catch (error) {
      console.log(error);
      notification("error", "Something went wrong, please try again");
    }
    setLoading(false);
  };

  const getZorpKey = () => {
    if (!accountDetails) return undefined;
    const zorpKey = accountDetails?.find((key: any) => key.keyType === "zorp");
    return zorpKey;
  };

  const handleEditClick = (data: any) => {
    setDataForEdit(data);
    setShowModal(false);
    setShowEditModal(true);
  };

  const handleDeleteClick = async (id: string) => {
    try {
      await AccountService.deleteSecretKey(id);
      getAccountDetails();
      notification("success", "Secret key deleted successfully");
    } catch (error) {
      console.log(error);
      notification("error", "Something went wrong, please try again");
    }
  };

  useEffect(() => {
    getAccountDetails();
  }, []);

  return (
    <>
      <Wrapper>
        <SubHeading>Secret Key Management</SubHeading>

        <Label
          style={{
            marginBottom: "20px",
          }}
        >
          Your secret API keys are listed below. <br />
          Do not share your API key with others, or expose it in the browser or
          other client-side code.
        </Label>
      </Wrapper>
      <Wrapper2>
        <InputWithCopy>
          <div
            style={{
              padding: "6px 16px",
            }}
          >
            {getZorpKey()?.keyMasked ||
              "No tokens! Click on Regenerate Token"}
          </div>
          <CopyText onClick={fetchFullToken}>
            <BiCopy size={"1.5rem"} color="#344054" />
            {copyLoading ? "Copying..." : "Copy"}
          </CopyText>
        </InputWithCopy>
        <ZButton
          disabled={loading}
          onClick={() => setShowDialog(true)}
          style={{
            backgroundColor: "#FEF3F2",
            color: "#B42318",
            border: "none",
            padding: "8px 16px",
          }}
          startIcon={<MdOutlineRefresh size="1.5rem" color={"#B42318"} />}
          variant="contained"
        >
          {loading ? "Regenerating..." : "Regenerate Token"}
        </ZButton>
        <ZButton
          danger
          variant="contained"
          onClick={() => setKeyForDelete("zorp")}
        >
          Delete
        </ZButton>
      </Wrapper2>
      <Divider className="mt-4" />
      <Wrapper className="mt-2">
        <FlexDiv>
          <div>
            <SubHeading>External Secret Keys</SubHeading>

            <Label
              style={{
                marginBottom: "20px",
              }}
            >
              Add and manage your external secret keys here so that you can
              refer to them from within Zorp using unique IDs
            </Label>
          </div>

          <ZButton
            startIcon={<AiOutlinePlus />}
            onClick={() => setShowModal(true)}
            variant="contained"
          >
            Add Secret Key
          </ZButton>
        </FlexDiv>
      </Wrapper>
      <div
        style={{
          width: "100%",
          overflowX: "auto",
        }}
      >
        <Table
          style={{
            boxShadow: "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
          }}
         className="mt-6">
          <TableHead>
            <TableRow>
              <SingleTableCell headerCell align="left">
                <p>Id</p>
              </SingleTableCell>
              <SingleTableCell headerCell align="left">
                <p>Name</p>
              </SingleTableCell>
              <SingleTableCell headerCell align="left">
                <p>Key</p>
              </SingleTableCell>
              <SingleTableCell headerCell align="left">
                <p>Actions</p>
              </SingleTableCell>
            </TableRow>
          </TableHead>
          {
            // loop from first index of secretKeys
            accountDetails
              ?.filter((key: any) => key.keyType !== "zorp")
              ?.map((key: any) => {
                return (
                  <>
                    <TableBody>
                      <TableRow
                        style={{
                          borderLeft: "1px solid #EAECF0",
                          borderRight: "1px solid #EAECF0",
                          borderTop: "1px solid #EAECF0",
                        }}
                      >
                        <SingleTableCell align="left">
                          <p>{key.keyType}</p>
                        </SingleTableCell>
                        <SingleTableCell align="left" scope="row">
                          <p>{key.name}</p>
                        </SingleTableCell>
                        {/* @ts-ignore */}
                        <SingleTableCell size="large" align="left" scope="row">
                          <p>{key.keyMasked}</p>
                        </SingleTableCell>
                        <SingleTableCell align="left" scope="row">
                          <div
                            style={{
                              display: "flex",
                              gap: "12px",
                            }}
                          >
                            <RiDeleteBinLine
                              onClick={() => setKeyForDelete(key.keyType)}
                              size={"2rem"}
                              color="#344054"
                              cursor="pointer"
                            />
                            <FiEdit2
                              onClick={() => handleEditClick(key)}
                              size={"2rem"}
                              color="#344054"
                              cursor="pointer"
                            />
                          </div>
                        </SingleTableCell>
                      </TableRow>
                    </TableBody>
                  </>
                );
              })
          }
        </Table>
      </div>

      <DeletionConfirmationDialog
        id={"scriptEditor"}
        deletionTitle={"Regenerate Token"}
        deletionText={"Are you sure you want to regenerate your token?"}
        isOpen={showDialog}
        onConfirmDelete={(e: any) => regenerateToken()}
        onClose={() => setShowDialog(false)}
      />

      <DeletionConfirmationDialog
        id={"scriptEditor"}
        deletionTitle={"Delete Key"}
        deletionText={`Are you sure you want to delete the key ${keyForDelete} ?`}
        isOpen={keyForDelete}
        onConfirmDelete={(e: any) => handleDeleteClick(keyForDelete)}
        onClose={() => setKeyForDelete(null)}
      />

      {showModal && (
        <SecretKeyModal
          open={showModal}
          handleClose={() => setShowModal(false)}
          mode="create"
          getAccountDetails={getAccountDetails}
        />
      )}
      {showEditModal && (
        <SecretKeyModal
          open={showEditModal}
          handleClose={() => setShowEditModal(false)}
          mode="edit"
          data={dataForEdit}
          getAccountDetails={getAccountDetails}
        />
      )}
    </>
  );
};

export default SecretManagement;
