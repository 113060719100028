import React from "react";
import Chip from "@mui/material/Chip";
import { RiDeleteBinLine } from "react-icons/ri";

type Props = {
    label: string,
    index:number, 
    handleDelete: (index:number) => void
};

const SingleChip = ({label, index, handleDelete}: Props) => {
  return (
    <Chip
      size="small"
      label={label}
      onClick={() => {}}
      onDelete={() => handleDelete(index)}
      deleteIcon={<RiDeleteBinLine color="#333333"/>}
      variant="outlined"
      sx={{
        marginRight:"4px",
        marginBottom:"4px",
        padding: "14px 4px",
        backgroundColor: "#e1e4e8",
        color: "#333333",
        fontSize: "14px"
      }}
    />
  );
};

export default SingleChip;
