export enum ComponentType {
    INPUT = "INPUT",
    CHOICE = "CHOICE",
    DATETIME = "DATETIME",
    IMAGEVIEWER = "IMAGEVIEWER",
    TEAMLIST = "TEAMLIST",
    TEXTAREA = "TEXTAREA",
    USERLIST = "USERLIST",
    TEXT = "TEXT",
    SLOT = "SLOT",
    ORDER = "ORDER",
    EMPTY="EMPTY",
    'LOCATION-INPUT'="LOCATION-INPUT",
    'LOCATION-DISPLAY'="LOCATION-DISPLAY",
    LIST ="LIST",
    SIGNATURE="SIGNATURE",
    "LIST_OF_TEXT" = "LIST_OF_TEXT",
    IMAGE = "IMAGE",
    FILE = "FILE",
    BOOLEAN = "BOOLEAN",
    DATE = "DATE",
    RELATION = "RELATION",
    RELATION_SHOW = "RELATION_SHOW",
    NUMBER = "NUMBER",
    COMMENT="COMMENT",
    LINKS = "LINKS",
    ONLY_DATE = "ONLY_DATE",
    TIME = "TIME"
}