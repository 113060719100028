import Button from '@mui/material/Button';
import {
  CONTEXT_SWITCHING,
  CONTEXT_SWITCHING_CONFIRMATION,
  DELETE_ACTION,
  DELETE_ACTION_CONFIRMATION,
  DELETE_CONDITION,
  DELETE_CONDITION_CONFIRMATION
} from "constants/CommonMessages";
import { RudderStackAutomationBuilderContext, RudderStackAutomationBuilderEvents } from "constants/RudderStackConstants";
import _, { cloneDeep } from "lodash";
import { useState } from "react";
import { BiCopy, BiCopyAlt, BiPaste } from "react-icons/bi";
import { HiOutlineArrowSmDown, HiOutlineArrowSmUp } from "react-icons/hi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useParams } from 'react-router-dom';
import { recordRSEvent } from "utils/CommonUtils";
import { generateId } from "utils/Utils";
import { setCopiedAction } from "views/actions/Action";
import useAction from "views/actions/useAction";
import { IActionBlock } from "views/automationBuilder/types/ABType";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import useWorkFlow from 'views/workflows/hooks/useWorkFlow';
import { setSelectedAction } from "views/workflows/reducers/workflowAction";
import { pasteAction, pasteCondition } from "views/workflows/workflowactions/api/utils/utils";
import { ABTopTextComponent, ActionBlockIconPack, ActionContentBlock, ActionParent, ActionTrigger, VerticalAlignWrapper } from "../automationBuilder.styles";
import useAutomation from "../hooks/useAutomation";
import {
  addActionBlockInAutomation, deleteActionInAutomation,
  deleteConditionInAutomation, moveActionToAnotherActionBlock, rearrangeActionOrConditionInActionBlock, setActionPayload, setSelectedActionBlock, setSelectedConfiguration, setSelectedTrigger
} from "../reducer/automationReducer";
import { updateMockDataValue } from '../utils/util';
import ActionPaste from "./ActionPaste";
import BlockContent from "./BlockContent";
import ConfirmationDialog from "./ConfirmationDialog";
import SplitDivider from "./SplitDivider";

export enum IChangeContext {
  CHANGE_ACTION = 'CHANGE_ACTION',
  CHANGE_CONDITION = 'CHANGE_CONDITION'
}


type Props = {
  actionBlock: IActionBlock;
  onDelete: (actionBlockId: string) => void;
  showAddActionBlockBtn: boolean;
  showDeleteActionBlockBtn: boolean;
  rowIndex: number;
  rearrangeUp: () => void;
  rearrangeDown: () => void;
  listLength: number;
};

const ActionBlock = ({
  actionBlock,
  onDelete,
  showAddActionBlockBtn,
  showDeleteActionBlockBtn,
  rowIndex,
  rearrangeUp,
  rearrangeDown,
  listLength
}: Props) => {
  const dispatch = useDispatch();
  const store = useStore();
  const { copiedAction } = useAction();
  const { tableType } = useParams();
  const [onHover, setOnHover] = useState(false);
  const [openModal, setOpenModal] = useState<IChangeContext | null>();
  const { selectedAutomation, clearRightSide, selectedCron,selectedMonitorId } = useAutomation();
  const [actionDeleteConfirmationId, setActionDeleteConfirmationId] = useState<string>("");
  const [conditionDeleteConfirmationId, setConditionDeleteConfirmationId] = useState<string>("");
  const [actionConditionType, setActionConditionType] = useState<string>("");
  const { selectedAction, taskTypeId } = useWorkFlow();
  const { mockData } = useSelector((state) => state.scriptEditor);

  const deleteAction = async () => {
    const mockDataPayload = cloneDeep(mockData);
    // clear right side, when we are deleting Action that is selected
    if (selectedAction?.actionId == actionDeleteConfirmationId && selectedAction.actionType == actionConditionType) {
      clearRightSide();
    }

    dispatch(
      deleteActionInAutomation({
        actionBlockId: actionBlock.actionBlockId,
        actionId: actionDeleteConfirmationId,
      })
    );

    setActionDeleteConfirmationId("");
    setActionConditionType("");

    // delete Action type == API Action, send API Request to Builder service to delete mockData
    if (actionConditionType === 'API') {
      const id = selectedCron.cronId | tableType | taskTypeId | selectedMonitorId;
      delete mockDataPayload[`response_${id}`];

     await updateMockDataValue(store, dispatch, mockDataPayload);

    }


  }

  const deleteCondition = () => {
    // clear right side, when we are deleting Action that is selected
    if (selectedAction?.conditionId == conditionDeleteConfirmationId && selectedAction.conditionType == actionConditionType) {
      clearRightSide();
    }

    dispatch(
      deleteConditionInAutomation({
        actionBlockId: actionBlock.actionBlockId,
        conditionId: conditionDeleteConfirmationId,
      })
    );
    setConditionDeleteConfirmationId("");
    setActionConditionType("");
  }

  const addNewCondition = () => {

    recordRSEvent(RudderStackAutomationBuilderEvents.addAnotherConditionBtnClick, {
      context: RudderStackAutomationBuilderContext.automationBuilderAction,
      automationId: selectedAutomation.id,
    })

    // clear the right side if anything is present
    dispatch(setSelectedAction(null));

    // while opening smart component in right side, this will clear the actionDropdown Array, which responsible for recursive dropdown component
    dispatch(setActionPayload([]));

    dispatch(setSelectedConfiguration({ type: "condition" }));
    dispatch(setSelectedActionBlock({ actionBlock }));
  }

  const addNewAction = () => {
    recordRSEvent(RudderStackAutomationBuilderEvents.addAnotherActionBtnClick, {
      context: RudderStackAutomationBuilderContext.automationBuilderAction,
      automationId: selectedAutomation.id,
    })

    // making selected Action null, makes the right pane to hide the action and show the list based on the selected configuration
    dispatch(setSelectedAction(null));

    // while opening smart component in right side, this will clear the actionDropdown Array, which responsible for recursive dropdown component
    dispatch(setActionPayload([]));

    dispatch(setSelectedTrigger(null));
    dispatch(setSelectedConfiguration({ type: "action" }));
    dispatch(setSelectedActionBlock({ actionBlock }));
  }

  const copyActionBlock = (e:any) => {
    e.stopPropagation();
    const actionBlockData = _.cloneDeep(actionBlock);
    actionBlockData.originalId = actionBlockData.actionBlockId;
    actionBlockData.actionBlockId = generateId(6);

    actionBlockData.actions.forEach((actionData: any) => {
      actionData.actionId = generateId(6);
      actionData.actionName = actionData.actionName + " copy";
    });

    actionBlockData.conditions.forEach((conditionData: any) => {
      conditionData.conditionId = generateId(6);
      conditionData.conditionName = conditionData.conditionName + " copy";
    });

    dispatch(setCopiedAction(actionBlockData));
    localStorage.setItem("copiedAction", JSON.stringify(actionBlockData));
  };

  return (
    <>
      <div style={{ width: '90px' }} className="ml-1" onMouseEnter={() => {
        setOnHover(true);
      }}
        onMouseLeave={() => {
          setOnHover(false);
        }}
      >
        <ActionBlockIconPack hoverState={onHover}>
          {rowIndex !== 0 && (
            <HiOutlineArrowSmUp
              color={onHover ? '#fff' : '#60646A'}
              onClick={(e) => {
                e.stopPropagation();
                rearrangeUp();
              }}
              size={"1.3rem"}
              className="pointer mr-1 ml-1"
            />)}

          {rowIndex !== listLength - 1 && (
            <HiOutlineArrowSmDown
              color={onHover ? '#fff' : '#60646A'}
              size={"1.3rem"}
              onClick={(e) => {
                e.stopPropagation();
                rearrangeDown();
              }}
              className="pointer mr-1 ml-1"
            />
          )}

          {
            copiedAction?.originalId == actionBlock.actionBlockId ?
              <BiCopyAlt
                color={onHover ? '#fff' : '#60646A'}
                className="pointer mr-1 ml-1"
                onClick={(e) => {
                  copyActionBlock(e);
                }}
              />
              :
              <BiCopy
                color={onHover ? '#fff' : '#60646A'}
                className="pointer mr-1 ml-1" onClick={(e) => {
                  copyActionBlock(e);
                }} />

          }






          {showDeleteActionBlockBtn && (
            <RiDeleteBin6Line
              onClick={() => {
                onDelete(actionBlock.actionBlockId);
              }}
              color={onHover ? '#fff' : '#60646A'}
              className="pointer mr-1 ml-1"
            />
          )}
        </ActionBlockIconPack>
      </div>

      <ActionParent hoverState={onHover} style={{ marginBottom: "0px" }}>
        <ActionTrigger>
          <ABTopTextComponent>If these Conditions are met</ABTopTextComponent>
          <ActionPaste context='condition' label="Paste Conditions" onClick={() => {
            pasteCondition({ dispatch, payload: { ...copiedAction }, actionBlockId: actionBlock.actionBlockId });
            // dispatch(setCopiedAction(null));
          }} />
        </ActionTrigger>
        <ActionContentBlock>
          {actionBlock.conditions.length == 0 &&
            <BlockContent
              onDelete={() => {
              }}
              payload={{}}
              rowIndex={0}
              copyAction={() => {
              }}
              showArrow={false}
              showConfigure={true}
              addNewBlock={() => {
                dispatch(setSelectedAction(null));
                dispatch(setSelectedTrigger(null));
                dispatch(setActionPayload([]));
                dispatch(setSelectedConfiguration({ type: "condition" }))
                dispatch(setSelectedActionBlock({ actionBlock }))
              }}
              showDelete={true}
              listLength={0}
              type="condition"
              actionBlock={actionBlock}
              valueSelected={false}
              buttonTitle="Configure Condition"
              placeHolderText="Add a Condition ( Optional )"
              name={""}
              errors={[]}
            />}

          {actionBlock.conditions.map((condition: any, index: number) => {
            return (
              <VerticalAlignWrapper applyMargin={index == 0 ? false : true} key={index}>
                {index !== 0 && <p className="pl-4 pr-4 mt-1">AND</p>}
                <BlockContent
                  onDelete={() => {
                    setConditionDeleteConfirmationId(condition?.conditionId);
                    setActionConditionType(condition?.conditionType);
                  }}
                  copyAction={() => {
                    const id = generateId(6);
                    const conditionPayloadCopy = {
                      ...condition,
                      originalId: condition?.conditionId,
                      conditionId: id,
                      conditionName: condition.conditionName + "_Copy",
                      tempId: id,
                    }
                    dispatch(setCopiedAction(conditionPayloadCopy))
                    localStorage.setItem("copiedAction", JSON.stringify(conditionPayloadCopy));
                  }}
                  payload={condition}
                  showArrow={true}
                  moveToAboveActionBlock={() => {
                    dispatch(moveActionToAnotherActionBlock({
                      currentActionBlockIndex:rowIndex,
                      currentActionIndex:index,
                      step:-1,
                      type: "conditions",
                    }))
                  }}
                  moveToBelowActionBlock={()=>{
                    dispatch(moveActionToAnotherActionBlock({
                      currentActionBlockIndex:rowIndex,
                      currentActionIndex:index,
                      step:1,
                      type: "conditions",
                    }))
                  }}
                  rearrangeDown={() => {
                    dispatch(rearrangeActionOrConditionInActionBlock({
                      automationId: condition.id,
                      actionBlockId: actionBlock.actionBlockId,
                      index: index,
                      step: 1,
                      type: "conditions",
                      id: condition.conditionId
                    }))
                  }}
                  rearrangeUp={() => {
                    dispatch(rearrangeActionOrConditionInActionBlock({
                      automationId: condition.id,
                      actionBlockId: actionBlock.actionBlockId,
                      index: index,
                      step: -1,
                      type: "conditions",
                      id: condition.actionId
                    }))
                  }}
                  rowIndex={index}
                  listLength={actionBlock.conditions.length}
                  actionBlockIndex={rowIndex}
                  actionBlocksCount={listLength}
                  valueSelected={condition?.conditionName ? true : false}
                  buttonTitle="Configure Condition"
                  placeHolderText="Add a Condition ( Optional )"
                  actionBlock={actionBlock}
                  name={condition.conditionName}
                  type="condition"
                  errors={condition.errors}
                />
              </VerticalAlignWrapper>
            );
          })}

          {actionBlock.conditions.length > 0 &&
            <ZButton tertiary variant="contained"
              style={{ marginTop: '10px' }}
              onClick={() => {
                if (selectedAction?.hasChanged == true) {
                  // when something  in right side or unsaved changed  in right side this will execute
                  setOpenModal(IChangeContext.CHANGE_CONDITION);
                } else {
                  // when something not in right side or unsaved changed not in right side this will execute
                  addNewCondition();
                }
              }}>
              Add another Condition
            </ZButton>}


        </ActionContentBlock>
        <ActionTrigger>
          <ABTopTextComponent>Perform These Action</ABTopTextComponent>
          <ActionPaste context='action' label="Paste Actions" onClick={() => {
            pasteAction({ dispatch, payload: { ...copiedAction }, selectedAutomationId: selectedAutomation.id, actionBlockId: actionBlock.actionBlockId });
            // dispatch(setCopiedAction(null));
}} />
        </ActionTrigger>
        <ActionContentBlock>

          {
            actionBlock.actions.length == 0 &&
            <BlockContent
              onDelete={() => {
              }}
              payload={{}}
              rowIndex={0}
              showArrow={false}
              copyAction={() => {
              }}
              addNewBlock={() => {
                dispatch(setSelectedAction(null));
                dispatch(setSelectedTrigger(null));
                dispatch(setActionPayload([]));
                dispatch(setSelectedConfiguration({ type: "action" }))
                dispatch(setSelectedActionBlock({ actionBlock }))
              }}
              showConfigure={true}
              showDelete={true}
              listLength={0}
              actionBlock={actionBlock}
              type="action"
              valueSelected={false}
              buttonTitle="Configure Action"
              placeHolderText="Add a Action"
              name={""}
              errors={[]}
            />
          }

          {actionBlock.actions.map((action: any, index: number) => {
            return (
              <VerticalAlignWrapper applyMargin={index == 0 ? false : true} key={index}>
                <BlockContent
                  showArrow={true}
                  onDelete={() => {
                    setActionDeleteConfirmationId(action.actionId);
                    setActionConditionType(action.actionType);
                  }}
                  copyAction={() => {
                    const id = generateId(6);
                    const actionPayloadCopy = {
                      ...action,
                      originalId: action.actionId,
                      actionId: id,
                      actionName: action.actionName + "_Copy",
                      tempId: id,
                    }
                    dispatch(setCopiedAction(actionPayloadCopy))
                    localStorage.setItem("copiedAction", JSON.stringify(actionPayloadCopy));
                  }}
                  key={action.actionId}
                  actionBlockIndex={rowIndex}
                  actionBlocksCount={listLength}
                  payload={action}
                  moveToAboveActionBlock={() => {
                    dispatch(moveActionToAnotherActionBlock({
                      currentActionBlockIndex:rowIndex,
                      currentActionIndex:index,
                      step:-1,
                      type: "actions",
                    }))
                  }}
                  moveToBelowActionBlock={()=>{
                    dispatch(moveActionToAnotherActionBlock({
                      currentActionBlockIndex:rowIndex,
                      currentActionIndex:index,
                      step:1,
                      type: "actions",
                    }))
                  }}
                  rearrangeUp={() => {
                    dispatch(rearrangeActionOrConditionInActionBlock({
                      automationId: action.id,
                      actionBlockId: actionBlock.actionBlockId,
                      index: index,
                      step: -1,
                      type: "actions",
                      id: action.actionId
                    }))
                  }}
                  rearrangeDown={() => {
                    dispatch(rearrangeActionOrConditionInActionBlock({
                      automationId: action.id,
                      actionBlockId: actionBlock.actionBlockId,
                      index: index,
                      step: 1,
                      type: "actions",
                      id: action.actionId
                    }))
                  }}
                  rowIndex={index}
                  listLength={actionBlock.actions.length}
                  valueSelected={action.actionName ? true : false}
                  buttonTitle="Configure Action"
                  placeHolderText="Add a Action"
                  type="action"
                  actionBlock={actionBlock}
                  name={action.actionName}
                  errors={action.errors}
                />
              </VerticalAlignWrapper>
            );
          })}

          {
            actionBlock.actions.length > 0 &&
            <ZButton tertiary variant="contained"
              style={{ marginTop: '10px' }}
              onClick={() => {
                if (selectedAction?.hasChanged == true) {
                  // when something  in right side or unsaved changed  in right side this will execute
                  setOpenModal(IChangeContext.CHANGE_ACTION);
                } else {
                  // when something not in right side or unsaved changed not in right side this will execute
                  addNewAction();
                }
              }}>
              Add another Action
            </ZButton>
          }

        </ActionContentBlock>
      </ActionParent>

      <SplitDivider />
      {showAddActionBlockBtn && (
        <>
          <ZButton
            className="pl-10"
            onClick={() => {

              recordRSEvent(RudderStackAutomationBuilderEvents.addAnotherActionBlockBtnClick, {
                context: RudderStackAutomationBuilderContext.automationBuilderAction,
                automationId: selectedAutomation.id,
              })

              dispatch(
                addActionBlockInAutomation({
                  automationId: selectedAutomation?.id,
                  action: {
                    actions: [],
                    conditions: [],
                    actionBlockId: generateId(8),
                  },
                })
              );
            }}
            variant="contained">
            Add Action Block
          </ZButton>
          {
            copiedAction?.actionBlockId &&

            <Button style={{ marginLeft: '20px', border: '1.5px solid #254DC3', backgroundColor: '#f6f7fe' }} onClick={(e) => {
              e.stopPropagation();

              const actionBlockData = _.cloneDeep(copiedAction);
              actionBlockData.actionBlockId = generateId(6);

              actionBlockData.actions.forEach((actionData:any) => {
                actionData.actionId = generateId(6);
                actionData.actionName = actionData.actionName + ' copy';
              });

              actionBlockData.conditions.forEach((conditionData:any) => {
                conditionData.conditionId = generateId(6);
                conditionData.conditionName = conditionData.conditionName + ' copy';
              });

              dispatch(
                addActionBlockInAutomation(
                  {
                    automationId: selectedAutomation?.id,
                    action: actionBlockData,
                  }
                )
              );
              // dispatch(setCopiedAction(null));
            }} variant="outlined">
              <BiPaste size={'22px'} className="pointer" />
            </Button>
          }
        </>
      )}

      <ConfirmationDialog
        id={"actionDelete"}
        deletionTitle={DELETE_ACTION}
        deletionText={DELETE_ACTION_CONFIRMATION}
        isOpen={actionDeleteConfirmationId ? true : false}
        primaryButtonName="Yes"
        secondaryButtonName="Cancel"
        onPrimaryBtnClick={deleteAction}
        onSecondaryClick={() => {
          setActionDeleteConfirmationId("");
          setActionConditionType("");
        }}
        onClose={()=>{
          setActionDeleteConfirmationId("");
        }}
      />

      <ConfirmationDialog
        id={"confirmationDelete"}
        deletionTitle={DELETE_CONDITION}
        deletionText={DELETE_CONDITION_CONFIRMATION}
        isOpen={conditionDeleteConfirmationId ? true : false}
        primaryButtonName="Yes"
        secondaryButtonName="Cancel"
        onPrimaryBtnClick={deleteCondition}
        onSecondaryClick={() => {
          setConditionDeleteConfirmationId("");
          setActionConditionType("");
        }}
        onClose={()=>{
          setConditionDeleteConfirmationId("");
        }}
      />


      <ConfirmationDialog
        id={"context_switching"}
        deletionTitle={CONTEXT_SWITCHING}
        deletionText={CONTEXT_SWITCHING_CONFIRMATION}
        isOpen={openModal ? true : false}
        primaryButtonName="Yes"
        secondaryButtonName="Cancel"
        onPrimaryBtnClick={()=>{
          if (openModal == IChangeContext.CHANGE_ACTION) {
            addNewAction();
          } else if (openModal == IChangeContext.CHANGE_CONDITION) {
            addNewCondition();
          }
          setOpenModal(null);
        }}
        onSecondaryClick={() => {
          setOpenModal(null);
        }}
        onClose={() => {
          setOpenModal(null);
        }}
      />
    </>
  );
};

export default ActionBlock;
