import React from "react";

type Props = {};

const ZHomeIcon = ({color, width, height}: {color ?:string, width?:string, height?:string}) => {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 20.9997V13.5997C9 13.0397 9 12.7597 9.10899 12.5457C9.20487 12.3576 9.35785 12.2046 9.54601 12.1087C9.75992 11.9997 10.0399 11.9997 10.6 11.9997H13.4C13.9601 11.9997 14.2401 11.9997 14.454 12.1087C14.6422 12.2046 14.7951 12.3576 14.891 12.5457C15 12.7597 15 13.0397 15 13.5997V20.9997M11.0177 2.76375L4.23539 8.03888C3.78202 8.3915 3.55534 8.56781 3.39203 8.78861C3.24737 8.9842 3.1396 9.20454 3.07403 9.43881C3 9.70327 3 9.99045 3 10.5648V17.7997C3 18.9198 3 19.4799 3.21799 19.9077C3.40973 20.284 3.71569 20.59 4.09202 20.7818C4.51984 20.9997 5.07989 20.9997 6.2 20.9997H17.8C18.9201 20.9997 19.4802 20.9997 19.908 20.7818C20.2843 20.59 20.5903 20.284 20.782 19.9077C21 19.4799 21 18.9198 21 17.7997V10.5648C21 9.99045 21 9.70327 20.926 9.43881C20.8604 9.20454 20.7526 8.9842 20.608 8.78861C20.4447 8.56781 20.218 8.3915 19.7646 8.03888L12.9823 2.76376C12.631 2.4905 12.4553 2.35388 12.2613 2.30136C12.0902 2.25502 11.9098 2.25502 11.7387 2.30136C11.5447 2.35388 11.369 2.4905 11.0177 2.76375Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  );
};

export default ZHomeIcon;