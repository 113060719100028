import React from "react";

type Props = {};

const ZLocations = ({
  color,
  width,
  height,
}: {
  color?: string;
  width?: string;
  height?: string;
}) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.75 13.5L1.5 16.5V4.5L6.75 1.5M6.75 13.5L12 16.5M6.75 13.5V1.5M12 16.5L16.5 13.5V1.5L12 4.5M12 16.5V4.5M12 4.5L6.75 1.5" stroke="#344054" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

export default ZLocations;
