 import React from 'react'
import { Input,Label } from '../DataField.styles'
import { numberDefaultValueErrorSchema } from 'views/DataField/DataFieldErrorSchema'
 
type LocationTypeProp = {
    register:any,
    show?:boolean,
    fieldName?:string
    address?:string,
    latitude?:string | number,
    longitude?:string | number
}

const Location = ({register,show=false,fieldName="",address="",longitude="",latitude=""}:LocationTypeProp) => {
  return (
    
    show ? (
            <>
                <Label>
                    Default Value - Address
                </Label>
                <Input placeholder='Enter default value for Address' defaultValue={address} type="string" {...register(fieldName+".address")}/>
                <Label>
                  Default Value - Latitude
                </Label>
                <Input type="number" step={"any"} placeholder='Enter default value for Latitude' defaultValue={latitude}   {...register(fieldName+".latitude",numberDefaultValueErrorSchema)}/>
                <Label>
                  Default Value - Longitude 
                </Label>
                <Input type="number" step={"any"} placeholder='Enter default value for Longitude'  defaultValue={longitude}  {...register(fieldName+".longitude",numberDefaultValueErrorSchema)}/>
            </>
    ) : null
    
    
  )
}

export default Location