//@ts-nocheck
import { Button } from "@mui/material";
import FilterModalClose from "assests/FilterModalClose.svg";
import CurlDocumentation from "assests/curl_documentation.svg";
import CurlIcon from "assests/import_curl.svg";
import { convert, validate } from "curl-to-postmanv2";
import notification from "notifications/notifications";
import { useState } from "react";
import { DataFieldType } from "views/DataField/DataField.types";
import { CurlBodyText, CurlBodyWrapper, CurlDocumentationTxt, CurlFooterLeftWrapper, CurlFooterRightWrapper, CurlModal, CurlModalBody, CurlModalContainer, CurlModalFooter, CurlModalHeader, CurlTextArea, HeaderContentWrapper, ImportCurlHeaderInfoWrapper, ImportCurlHeading, ImportCurlSentence, ImportIconWrapper } from './CurlStyledComponents';

interface IImportCurlModal {
    openModal: boolean;
    handleClose: () => void;
    onImport: (jsonStructure: any) => void;
}

const ImportCurlModal = ({ openModal, handleClose, onImport }: IImportCurlModal) => {
    const [curl, setCurl] = useState<string>('');

    const convertCurlToJson = async (curlData:string) => {

        // validate curl
        const value = validate(curlData);

        // if the validation success then
        if (value.result) {
            convert({ type: "string", data: curlData }, (err, result) => {
                if (err) {
                    notification("error", 'Enter Valid curl command');
                    return;
                }
                // if the validation is valid
                if (result.result) {
                    try {
                        const jsonOutput = result.output[0].data;
                        const data = {
                            method: jsonOutput.method,
                            url: jsonOutput.url,
                            headers: jsonOutput.header.map((data: { key: string, value: any }) => {
                                const valueData = {
                                        type: 'literal',
                                        value: data.value,
                                        dataType: DataFieldType.TEXT,
                                      }

                                return { key:data.key, value: valueData };
                            }),
                            body: JSON.parse(jsonOutput.body.raw || '{}'),
                        }
                        onImport(data)
                    } catch (e) {
                        notification("error", 'Enter Valid curl command');
                    }
                } else {
                    // if the validation is invalid
                    notification("error", 'Enter Valid curl command');
                }

            }
            );


        } else {
            // if the validation fails then
            notification("error", value.reason || 'Enter Valid curl command');
        }

    }

    return (
        <CurlModal
            open={openModal}
            onClose={handleClose}>
            <CurlModalContainer>
                <CurlModalHeader>

                    <HeaderContentWrapper>
                        <ImportIconWrapper>
                            <img src={CurlIcon} />
                        </ImportIconWrapper>
                        <div><img src={FilterModalClose} className='cursor-pointer' onClick={handleClose} /></div>
                    </HeaderContentWrapper>


                    <ImportCurlHeaderInfoWrapper>
                        <ImportCurlHeading>Import Curl</ImportCurlHeading>
                        <ImportCurlSentence>Paste the Curl String in the input area below</ImportCurlSentence>
                    </ImportCurlHeaderInfoWrapper>

                </CurlModalHeader>



                <CurlModalBody>
                    <CurlBodyWrapper>
                        <CurlBodyText>Enter Curl String</CurlBodyText>
                        <CurlTextArea style={{ overflow: 'auto' }} placeholder="Enter Curl String here" value={curl} onChange={(e) => {
                            setCurl(e.target.value);
                        }} />
                    </CurlBodyWrapper>
                </CurlModalBody>



                <CurlModalFooter>
                    <CurlFooterLeftWrapper>
                        <div className="pr-2" ><img src={CurlDocumentation} /></div>
                        <CurlDocumentationTxt onClick={()=>{
                                window.open(
                                    'https://docs.zorp.one/product-docs/workflow-builder/automations/actions/rest-api-action#import-curl',
                                    '_blank' // <- This is what makes it open in a new window.
                                  );
                                  
                        }}> Read Documentation</CurlDocumentationTxt>
                    </CurlFooterLeftWrapper>

                    <CurlFooterRightWrapper>

                        <Button
                            style={{
                                color: '#344054',
                                fontFamily: 'inter',
                                padding: '2rem',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                border: ' 1px solid #D0D5DD',
                                height: '44px',
                                width: '90px',
                                textTransform: 'none'
                            }}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>

                        <Button
                            disableElevation
                            variant="contained"
                            style={{
                                color: '#fff',
                                fontFamily: 'inter',
                                padding: '2rem',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                height: '44px',
                                background: '#3C69E7',
                                width: '90px',
                                textTransform: 'none'
                            }}
                            onClick={() => {
                                convertCurlToJson(curl);
                            }}>
                            Import
                        </Button>

                    </CurlFooterRightWrapper>
                </CurlModalFooter>
            </CurlModalContainer>
        </CurlModal>
    )
}

export default ImportCurlModal