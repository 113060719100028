import LoadingButton from '@mui/lab/LoadingButton';
import AddMonitorBtn from 'assests/add_new_monitor.svg';
import PlusWhite from 'assests/plus_white.svg';
import { MonitorService } from 'providers/data/services/MonitorService';
import { useState } from 'react';
import { useDispatch } from "react-redux";
import { setSelectedMonitorId } from 'views/automationBuilder/reducer/automationReducer';
import { setMonitorInterfaceContext, setSelectedMonitor, setShowInterfaceUI } from '../reducer/MonitorReducer';
import useMonitor from '../reducer/useMonitor';
import { recordRSEvent } from 'utils/CommonUtils';
import { RudderStackAutomationBuilderEvents } from 'constants/RudderStackConstants';

export enum IMonitorCreateBtnContext {
    TABLE = 'TABLE',
    MONITOR = 'MONITOR'
}

interface ICreateFromScratchBtn {
    context: IMonitorCreateBtnContext;
    onCreateCallBack?: () => void;
}

const CreateFromScratchBtn = ({context, onCreateCallBack}:ICreateFromScratchBtn) => {
    const isTable = (context == IMonitorCreateBtnContext.TABLE);

    const dispatch = useDispatch();
    const { monitorInterfaceContext } = useMonitor();
    const [createMonitorBtnLoading, setCreateMonitorBtnLoading] = useState<boolean>(false);

    const onNewMonitorCreateClick = async () => {
        recordRSEvent(RudderStackAutomationBuilderEvents.monitorDesignFromScratch, {
            context: RudderStackAutomationBuilderEvents.monitorContext
          });

        setCreateMonitorBtnLoading(true);
        const monitorCreateResponse = await MonitorService.createUnInitiatedMonitor();
        setCreateMonitorBtnLoading(false);
        if (monitorCreateResponse?.code == '201') {
            
            dispatch(setMonitorInterfaceContext({
                ...monitorInterfaceContext, // Spread the previous state to retain other values
                monitorComponentsData: {
                    ...monitorInterfaceContext?.monitorComponentsData, // Spread the previous monitorComponentsData
                    isdDatasourceSectionHidden: true
                }
            }));

            dispatch(setSelectedMonitor({ monitorId: monitorCreateResponse.data.monitorId }))
            dispatch(setSelectedMonitorId(monitorCreateResponse.data.monitorId));
            dispatch(setShowInterfaceUI(true));
            onCreateCallBack?.();
        } else {
            // Show error message
            console.log('Error creating monitor');
        }
    }
  return (
    <LoadingButton
    color='secondary'
    loading={createMonitorBtnLoading}
    disabled={createMonitorBtnLoading}
    loadingPosition='start'
    startIcon={<img style={{ paddingRight: '5px' }} src={isTable ? PlusWhite : AddMonitorBtn} alt='Icon' />}
    onClick={()=>{
        isTable ? onCreateCallBack?.() : onNewMonitorCreateClick()
    }}
    style={{
        borderRadius: '4px',
        height: isTable ? '32px' : '36px',
        color: isTable ? '#fff' : '#344054',
        fontSize: '12px',
        fontWeight: isTable ? 600 : 'normal',
        padding: '8px 14px',
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)', 
        border: '1px solid var(--Gray-300, #D0D5DD)', 
        backgroundColor: isTable ? '#3C69E7' : '#fff',
        textTransform: 'none'
    }}
    sx={{ height: isTable ? '32px' : '36px' }} variant="contained">
    Design from Scratch
</LoadingButton>
  )
}

export default CreateFromScratchBtn;