export type ButtonPosition = {
    top: number;
    left: number;
    height: number;
};

export enum ResourceTypeConstant {
    TABLE = "table",
    TASK = "task",
    AUTOMATION = "automation"
}

export type ResourceTypeList = ResourceTypeConstant.TABLE | ResourceTypeConstant.TASK | ResourceTypeConstant.AUTOMATION

export type PermissionDependency = TablePermissionDependency | TaskPermissionDependency;

export type TablePermissionDependency = {
    [key in TablePermissionsList]?: TablePermissionsList[]
}
export type TaskPermissionDependency = {
    [key in TaskPermissionsList]?: TaskPermissionsList[]
}

export type PermissionsListType = (TablePermissionsList | TaskPermissionsList)[];

export type TablePermissionsList = "view_table" | "delete_table" |
    "view_tab" | "edit_tab" | "create_tab" | "delete_tab" | "view_record" | "edit_record" |
    "create_record" | "delete_record" | "duplicate_table" | "manage_table_column" | "manage_table_automation" | "create_view"
    | "view_view" | "edit_view" | "update_table_acl" | "rename_table" | "manage_section" | "add_comment" | "manage_table_view";

export type TableEntities = 'Table' | 'Records' | 'Misc';

export type TablePermission = {
    [key in TableEntities]?: {
        [key in TablePermissionsList]?: {
            id: TablePermissionsList;
            description: string;
            displayName: string;
            dependencies: TablePermissionsList[];
        }
    }
}

export type PermissionObject = {
    id: TablePermissionsList;
    description: string;
    displayName: string;
    dependencies: TablePermissionsList[];
}

export type OptionType = {
    value: string;
    label: string;
    type: "team" | "role" | "user";
};

export type TaskPermission = {
    [key in TaskEntities]?: {
        [key in TaskPermissionsList]?: {
            id: TaskPermissionsList;
            description: string;
            displayName: string;
            dependencies: TaskPermissionsList[];
        }
    }
}

export type TaskPermissionsList = "view_task" | "edit_task" | "create_task" | "delete_task"
export type TaskEntities = 'Task' | 'Misc';

export type EntityReference = {
    id?: string;
    name?: string;
}

export type Permission = {
    permissionId: string;
    permissionSetName: string;
    isEditable: boolean;
    permissions: PermissionsListType;
    or: {
        roleIds: EntityReference[];
        teamIds: EntityReference[];
        userIds: EntityReference[];
    };
    and: {
        roleIds: EntityReference[];
        teamIds: EntityReference[];
        userIds: EntityReference[];
    }
}

export type readWritePermissions = { roleIds: EntityReference[], teamIds: EntityReference[], userIds: EntityReference[] }

export type viewPermissions = {
    "read": {
        "and": readWritePermissions,
        "or": readWritePermissions
    },
    "write": {
        "and": readWritePermissions,
        "or": readWritePermissions
    }
}

export type viewPermissionsPayload = {
    "read" : {
        "and": {
            "roleIds": string[],
            "teamIds": string[],
            "userIds": string[]
        },
        "or": {
            "roleIds": string[],
            "teamIds": string[],
            "userIds": string[]
        }
    },
    "write" : {
        "and": {
            "roleIds": string[],
            "teamIds": string[],
            "userIds": string[]
        },
        "or": {
            "roleIds": string[],
            "teamIds": string[],
            "userIds": string[]
        }
    }
}

export type accessPermissions = {
    [key in TablePermissionsList]?: boolean;
}

export type PermissionConfiguration = {
    permissionId: string;
    permissionSetName: string;
    permissions: PermissionsListType;
}

export type Invite = {
    roles: string[];
    teams: string[];
    users: string[];
}

export type addedUser = {
    index?: number;
    type?: string;
    value?: EntityReference;
}