//@ts-nocheck
import { ButtonBase, tooltipClasses, TooltipProps } from '@mui/material';
import { styled } from "@mui/material/styles";
import Tooltip from '@mui/material/Tooltip';
import FilterSelect from "assests/filter_dropdown_arrow.svg";
import { requiredErrorMessage } from "filter/constants/ErrorMessage";
import { useEffect } from "react";
import { Controller, useWatch } from "react-hook-form";
import Select, { components, GroupHeadingProps } from 'react-select';
import FilterErrorMessage from "./FilterErrorMessage";
import { FilterAddModalSubHeading, FilterGroupListWrapper, ReactFilterSelectedValue } from './FilterStyledComponents';

interface IReactFilterSelect {
    options: any;
    onChange: (selectedValue: any) => void;
    name: string;
    isLoading?: boolean;
    errors: any;
    setValue: any;
    isDisabled?: boolean;
    control: any;
    noOptionsMessage?: string;
    value: any;
    isMulti: boolean;
    styleSelectedValue?: boolean;
    hideDropdownArrow?:boolean;
}


export const GroupHeading = (
    props: GroupHeadingProps<any>
) => (
    <FilterGroupListWrapper>
        <FilterAddModalSubHeading>{props?.data.label}</FilterAddModalSubHeading>
    </FilterGroupListWrapper>
);


const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#E8E8E8",
        color: "rgba(0, 0, 0, 0.8)",
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 6,
        paddingBottom: 6,
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '13px',
        lineHeight: '20px',
        border: '1px solid #C0C9D2',
        boxShadow:'0px 3px 6px rgba(140, 152, 164, 0.25)',
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#E8E8E8",
    },
}))



const SingleValue = ({ children, ...props }: any) => (
    <>
        {
            // with tip
            (!props?.data?.isUniversalFilter && props?.data?.workFlowName) ?
                <components.SingleValue {...props}>
                    <StyledTooltip title={props?.data?.workFlowName + ' > ' + props.data.label} placement="right-end">
                        <ButtonBase style={{ 
                            textDecoration: 'none', 
                            color: 'rgba(0, 0, 0, 0.8)',
                             }}>
                            {"... > " + props.data.label}
                        </ButtonBase>
                    </StyledTooltip>
                </components.SingleValue>
                :
                // without tooltip
                <components.SingleValue {...props}>
                    <ReactFilterSelectedValue>{props.data.label}</ReactFilterSelectedValue>
                </components.SingleValue>
        }
    </>
)


const ReactFilterSelect = ({ options, onChange, name, control, isLoading = false, isDisabled = false, noOptionsMessage = "No Options", value, errors, isMulti = false, setValue, styleSelectedValue = false, hideDropdownArrow = true }: IReactFilterSelect) => {
    const valueField: any = useWatch({ control, name });
    useEffect(() => {
        setValue(name, valueField);
    }, []);

    const filterOption = ({ label, value }: any, string: any) => {
        // default search
        if (label.toLowerCase().includes(string.toLowerCase()) || value.toLowerCase().includes(string.toLowerCase())) return true;

        // check if a group as the filter string as label
        const groupOptions = options.filter((group: any) => {
            return group.label.toLowerCase().includes(string.toLowerCase())
        }
        );

        if (groupOptions) {
            for (const groupOption of groupOptions) {
                // Check if current option is in group
                const option = groupOption?.options?.find((opt: any) => (opt.value) == value);
                if (option) {
                    return true;
                }
            }
        }
        return false;
    };

    const DropdownIndicator = () => (
        hideDropdownArrow ? <img style={{ paddingRight: '7px' }} src={FilterSelect} className='cursor-pointer' /> : null
    );

    return (
        <div>
            {(value || options) &&
                <Controller
                    name={name}
                    control={control}
                    defaultValue={valueField}
                    rules={{ required: requiredErrorMessage }}
                    render={({ field: { ref, ...field } }) => (
                        <Select
                            {...field}
                            value={valueField}
                            isLoading={isLoading}
                            isMulti={isMulti}
                            closeMenuOnSelect={isMulti ? false : true}
                            menuPortalTarget={document.body}
                            filterOption={filterOption}
                            menuPosition={'fixed'}
                            isDisabled={isDisabled}
                            noOptionsMessage={({ inputValue }) => noOptionsMessage}
                            options={options}
                            ref={ref}
                            onChange={(selectedValue) => {
                                onChange(selectedValue)
                            }}
                            components={{ GroupHeading, IndicatorSeparator: () => null, DropdownIndicator, SingleValue }}
                            styles={
                                    {
                                        groupHeading: (base) => ({
                                            ...base,
                                            flex: '1 1',
                                            color: 'white',
                                            margin: 0,
                                        }),
                                        control: (base: any, state: any) => ({
                                            ...base,
                                            width: '95%',
                                            'min-height': '32px',
                                            minHeight:'32px',
                                            alignContent:'center',
                                            alignItems:'center'
                                        }),
                                        option: (provided: any, state: { isSelected: any }) => ({
                                            ...provided,
                                            color: state.data.isDisabled == true ? '#C7C7C7' : state.isSelected ? "white" : "rgba(0, 0, 0, 0.5)",
                                            paddingLeft: '24px',
                                            paddingTop: '6px',
                                            paddingBottom: '6px',
                                            fontWeight: 400,
                                            wordWrap:'break-word',
                                            whiteSpace:'normal',
                                            fontSize: ' 14px',
                                            backgroundColor: state.isSelected ? "#2584FF" : "white",
                                            "&:hover": {
                                                backgroundColor: (state.data.isDisabled && state.isSelected)  ? "#2584FF" : state.data.isDisabled ? "white" : "#DDEBFF"
                                            }
                                        }),
                                        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                                        menu: provided => ({ ...provided, zIndex: 9999, color: '#7E7E7F' }),
                                        singleValue: (styles, { data }) => {
                                            return {
                                                ...styles,
                                                ...(!data?.isUniversalFilter && styleSelectedValue) &&
                                                {
                                                    color: "#2E2E2E",
                                                    borderRadius: '4px',
                                                    backgroundColor: "#E8E8E8",
                                                    width: 'fit-content',
                                                    paddingLeft: '10px',
                                                    paddingRight: '10px'
                                                }

                                            }
                                        }

                                    }
                            }
                        />
                    )}
                />
            }
            <FilterErrorMessage errors={errors} name={name} />
        </div>
    )
}

export default ReactFilterSelect