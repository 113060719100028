import moment from 'moment';
import React from 'react';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { ValidatorComponent } from 'react-form-validator-core';
import PhoneInput from 'react-phone-input-2';
import Teams from '../../teams/Teams';
import UserRole from '../../users/components/UserRole';
import {AiOutlineWarning} from "react-icons/ai"

class DateValidator extends ValidatorComponent {
  renderValidatorComponent() {
    const { errorMessages, validators, requiredError,allowPast=false, validatorListener, ...rest } = this.props;
    function pastValid(current) {
      var prev = moment().subtract(1, 'day');
      return current.isAfter(prev);
    }
    return (
      <div>
        <Datetime
          {...rest}
          isValidDate={!allowPast ? pastValid : () => {return true}}
          
          ref={(r) => {
            this.input = r;
          }}
        />
        {this.errorText()}
      </div>
    );
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return (
      <div className="font-semibold text-xs text-red-500 mt-1">
        <AiOutlineWarning
          size={"1rem"}
          color="red"
          style={{ display: "inline", marginRight: "4px" }}
        />{" "}
        {this.getErrorMessage()}
      </div>
    );
  }
}

class PhoneValidator extends ValidatorComponent {
  renderValidatorComponent() {
    const { errorMessages, validators, requiredError, validatorListener, ...rest } = this.props;

    return (
      <div>
        <PhoneInput
          {...rest}
          ref={(r) => {
            this.input = r;
          }}
        />
        {this.errorText()}
      </div>
    );
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return (
      <div className="font-semibold text-xs text-red-500 mt-1">
        <AiOutlineWarning
          size={"1rem"}
          color="red"
          style={{ display: "inline", marginRight: "4px" }}
        />{" "}
        {this.getErrorMessage()}
      </div>
    );
  }
}

class RoleValidator extends ValidatorComponent {
  renderValidatorComponent() {
    const { errorMessages, validators, requiredError, validatorListener, ...rest } = this.props;

    return (
      <div>
        <UserRole
          {...rest}
          ref={(r) => {
            this.input = r;
          }}
        />
        {this.errorText()}
      </div>
    );
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return (
      <div className="font-semibold text-xs text-red-500 mt-1">
        <AiOutlineWarning
          size={"1rem"}
          color="red"
          style={{ display: "inline", marginRight: "4px" }}
        />{" "}
        {this.getErrorMessage()}
      </div>
    )
}}

class TeamValidator extends ValidatorComponent {
  renderValidatorComponent() {
    const { errorMessages, validators, requiredError, validatorListener, ...rest } = this.props;

    return (
      <div>
        <Teams
          {...rest}
          ref={(r) => {
            this.input = r;
          }}
        />
        {this.errorText()}
      </div>
    );
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return (
      // <div
      //   style={{
      //     color: '#f44336',
      //     fontSize: '0.75rem',
      //     textAlign: 'left',
      //     marginLeft: '14px',
      //     marginRight: '14px',
      //     marginTop: '4px',
      //   }}
      // >
      //   {this.getErrorMessage()}
      // </div>
      <div className="font-semibold text-xs text-red-500 mt-1">
        <AiOutlineWarning
          size={"1rem"}
          color="red"
          style={{ display: "inline", marginRight: "4px" }}
        />{" "}
        {this.getErrorMessage()}
      </div>
    );
  }
}

export { DateValidator, PhoneValidator, RoleValidator, TeamValidator };
