import { Backdrop, Checkbox } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { ImageModal } from "components/ZImageViewer/ZShowImage";
import ImageSlider from "components/imageviewer/ImageSlider";
import usePorterPermissions from "hooks/usePorterPermissions";
import { ResourceTypeConstant } from "../permissions/types";
import { isFunction, union, debounce, isEqual } from "lodash";
import ColumnPermissions from "../components/ColumnPermissions";
import useAccessPermissions from "hooks/useAccessPermissions";
import { deduplicateArray } from "utils/CommonUtils";
import {
  MRT_ColumnFiltersState,
  MRT_DensityState,
  MRT_PaginationState,
  MRT_SortingState,
  MRT_VisibilityState,
  MaterialReactTable,
} from "material-react-table";
import moment from "moment";
import notification from "notifications/notifications";
import { EntityService } from "providers/data/services/EntityService";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getTablePropsLSKey,
  recordRSEvent,
  getViewsObj,
} from "utils/CommonUtils";
import useAccount from "views/layouts/reducer/useAccount";
import useEntity from "../hooks/useEntity";
import { setSavedViewChanges, setShowCreationModal } from "../reducers/entityReducers";
import {
  TableRSEvents,
  ViewState,
  ViewsRSEvents,
} from "../types";
import CustomIcons from "./CustomIcons";
import CustomToolbar from "./CustomToolbar";
import { setRowsToDelete } from "render-engine/reducers/show/TaskShowReducer";
import "../../../styles/zorpTable.css"
import LocalStorageUtil from "utils/LocalStorageUtil";
import { RiDeleteBinLine } from "react-icons/ri";
import { ColumnFilter, formatListingConfig } from "./TableUtils";
import { AiOutlineSearch } from "react-icons/ai";
import { arraysAreEqual, checkColumnVisibility, deepCompare, handleActionMenuClose } from "./TableUtils";

type Props = {
  initTableConfig?: any;
  view?: any;
  enableRowSelection?: boolean;
  fromShowPage?: boolean;
  getTableInstance?: any;
  viewChanges?: { [viewId: string]: boolean };
  setViewChanges?: (viewChanges: { [viewId: string]: boolean }) => void;
  nameOfTheTableInRelatedTable?: string;
  setIsEntityTableEmpty?: React.Dispatch<React.SetStateAction<boolean>>;
  addedRecords?: any[];
  rowsToDelete?: any[];
};

const VirtualizedMEntityTable = ({
  initTableConfig,
  view,
  enableRowSelection,
  fromShowPage = false,
  viewChanges,
  getTableInstance,
  setViewChanges,
  nameOfTheTableInRelatedTable = undefined,
  addedRecords = [],
  setIsEntityTableEmpty,
  rowsToDelete = [],
}: Props) => {
  const [rowData, setRowData] = useState<any>([]);
  const [showImage, setShowImage] = useState<boolean>(false);
  const [linksArray, setLinksArray] = useState<any[]>([]);
  const dispatch = useDispatch();
  const { selectedEntity, selectedEntityMetadata, savedViewChanges } = useEntity();
  const [selectedColumnName, setSelectedColumnName] = useState<string>("");
  // const [gridApi, setGridApi] = useState<any>(null);
  const accessPermissions = useAccessPermissions({ resourceType: ResourceTypeConstant.TABLE });
  // const [openAB, setOpenAB] = useState(false);
  const [columnDefs, setColumnDefs] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const { account } = useAccount();
  const porterPermissions = usePorterPermissions();

  const [columnFilterFns, setColumnFilterFns] = useState<{ [key: string]: string }>(() => {
    if(view && view?.viewId) {
      const lsKey = `tableState_${selectedEntity?.tableType}_${view?.viewId}`;
      const lsValue = localStorage.getItem(lsKey);
    if (lsValue) {
      try {
        const parsedValue = JSON.parse(lsValue);
        return parsedValue.columnFilterFns || {};
      } catch (error) {
        console.error(`Error parsing JSON for localStorage key "${lsKey}":`, error);
      }
    }
    }   
    return {};
  });
  const [showColumnPermissionsModal, setShowColumnPermissionsModal] = useState(false);

  const defaultColumnPinning = JSON.stringify({ left: ['mrt-row-select'], right: ["showRecord", "addRow"] });
  const [columnPinning, setColumnPinning] = useState(() => {
    if(view && view?.viewId) {
      const lsKey = `tableState_${selectedEntity?.tableType}_${view?.viewId}`;
    const lsValue = localStorage.getItem(lsKey);
    if (lsValue) {
      try {
        const parsedValue = JSON.parse(lsValue);
        return parsedValue.columnPinning || JSON.parse(defaultColumnPinning);
      } catch (error) {
        console.error(`Error parsing JSON for localStorage key "${lsKey}":`, error);
      }
    }
    }   
    return JSON.parse(defaultColumnPinning);
  });

  const [pagination, setPagination] = useState<MRT_PaginationState>(() => {
    if (!selectedEntity || !view) {
      return {
        pageIndex: 0,
        pageSize: 10
      }
    }
    const key = `tableState_${selectedEntity?.tableType}_${view?.viewId}`;
    try {
      const lsValue = localStorage.getItem(key);
      if (lsValue) {
        const parsedValue = JSON.parse(lsValue);
        return parsedValue.pagination;
      }
    } catch (error) {
      console.error('Error parsing pagination from localStorage:', error);
    }
    return { pageIndex: 0, pageSize: 10 };
  });
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(() => {
    if (!selectedEntity || !view) {
      return []
    }
    const lsValue = localStorage.getItem(`tableState_${selectedEntity?.tableType}_${view?.viewId}`);
    return (lsValue && JSON.parse(lsValue)?.columnFilters) || [];
  });

  const [refresh, setRefresh] = useState(false);
  const [sorting, setSorting] = useState<MRT_SortingState>(() => {
    if (!selectedEntity || !view) {
      return []
    }
    const lsValue = localStorage.getItem(`tableState_${selectedEntity?.tableType}_${view?.viewId}`);
    return (lsValue && JSON.parse(lsValue).sorting) || [];
  });

  const [selectedRows, setSelectedRows] = useState(() => {
    let selectedRowInitialState: Record<string, boolean> = {};
    initTableConfig?.rowDef?.forEach((row: {recordId: string;}) => {
      if (rowsToDelete?.includes(row.recordId)) {
        selectedRowInitialState[row.recordId] = true;
      }
    });
    return selectedRowInitialState;
  });

  const isFirstRender = useRef(true);

  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(() => {
    if (!selectedEntity || !view) {
      return undefined
    }
    const lsValue = localStorage.getItem(`tableState_${selectedEntity?.tableType}_${view?.viewId}`);
    if(lsValue === null) return undefined;
    const parsedColumnVisibility = JSON.parse(lsValue).columnVisibility;
    return parsedColumnVisibility ? Object.keys(parsedColumnVisibility).length === 0 ? undefined : parsedColumnVisibility : undefined;
  });
  const [density, setDensity] = useState<MRT_DensityState>("comfortable");
  const [globalFilter, setGlobalFilter] = useState<string>(() => {
    if (!selectedEntity || !view) {
      return ""
    }
    const lsValue = localStorage.getItem(`tableState_${selectedEntity?.tableType}_${view?.viewId}`);
    return (lsValue && JSON.parse(lsValue).globalFilter) || "";
  });
  const [showGlobalFilter, setShowGlobalFilter] = useState(true);
  const [showColumnFilters, setShowColumnFilters] = useState(false);
  const [columnOrdering, setColumnOrdering] = useState<string[]>(() => {
    if (!selectedEntity || !view) {
      return []
    }
    const lsValue = localStorage.getItem(`tableState_${selectedEntity?.tableType}_${view?.viewId}`);
    return (lsValue && deduplicateArray(JSON.parse(lsValue).columnOrdering)) || [];
  });

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const showRecordClick = useCallback((recordId: string) => {
    if (!accessPermissions.view_record) return;
    const tableType = initTableConfig?.tableType || selectedEntity?.tableType;
    // open `/table/${tableType}/show/${recordId}` in new tab
    // check if the url has 'show"
    /**
     * this is to open the details page in the new tab if the record details
     * is invoked from the show page itself
     * this happens when a record has a relation and we double click on the
     * row to open the details page
     * For the main table, it will open on the same tab, but for the table
     * in the relation, it will open in the new tab.
     */

    const url = window.location.href;
    if (url.includes("show")) {
      window.open(`/#/table/${tableType}/show/${recordId}`, "_blank");
    } else {
      history.push(`/table/${tableType}/show/${recordId}`);
    }
    // window.open(`/#/table/${tableType}/show/${recordId}`, "_blank")
    // history.push(`/table/${tableType}/show/${recordId}`)
    recordRSEvent(TableRSEvents.TABLE_ROW_CLICK, {
      recordId,
    });
  }, []);

  const handleRowSelectionChange = (updaterFn: (currentSelectedRows: Record<string, boolean>) => Record<string, boolean>) => {
    setSelectedRows((currentSelectedRows) => {
      // Update the state with the new selection
      if (isFunction(updaterFn)) return updaterFn(currentSelectedRows);
      return currentSelectedRows;
    });
  };

  useEffect(() => {
    // if(isFirstRender.current) return;
    // get all the keys of selected rows
    const deletedRecords = Object.keys(selectedRows);
    // loop through the keys:
    // const deletedRecords = selectedRowKeys.map((key: number) => {
    //   // if the value is true, then the row is selected
    //   if (selectedRows[key]) {
    //     //get this index from initConfig.rowData
    //     const val = initTableConfig?.rowDef?.[key];
    //     return val?.recordId;
    //   }
    // });
    // remove undefined values
    const filteredDeletedRecords = deletedRecords.filter(
      (val) => val !== undefined
    );

    if (nameOfTheTableInRelatedTable) {
      // remove value that are in filteredDeletedRecords from t
      // set the state
      dispatch(
        setRowsToDelete({
          nodeId: nameOfTheTableInRelatedTable,
          data: [...filteredDeletedRecords],
        })
      );
    }

    //
    // Additional logic to handle the updated state
  }, [selectedRows]);

  useEffect(() => {
    if (initTableConfig === undefined) {
      if(!savedViewChanges || !savedViewChanges[view?.viewId]) {
        dispatch(setSavedViewChanges({ ...savedViewChanges, [view?.viewId]: getViewsObj(view?.params) } || []));
      }
      let persistedViewChanges = localStorage.getItem(`viewChanges_${selectedEntity?.tableType}`);
      const viewIdExistsLS = persistedViewChanges && JSON.parse(persistedViewChanges)[view?.viewId]
      if (!viewIdExistsLS) {
        const viewParamsObj = getViewsObj(view?.params);
        LocalStorageUtil.setItem(`tableState_${selectedEntity?.tableType}_${view?.viewId}`, JSON.stringify(viewParamsObj));
      }
    }
    // eslint-disable-next-line
  }, [
    selectedEntityMetadata,
  ]);

  useEffect(() => {
    if (initTableConfig) {
      const { colDefs: initColDefs, rowDefs: initRowDefs } =
        formatListingConfig(
          {data: initTableConfig, 
          view,
          fromShowPage, 
          setSelectedColumnName,
          setShowColumnPermissionsModal,
          handleActionMenuClose,
          columnFilterFns,
          setLinksArray,
          setShowImage,
          refresh,
          setRefresh,
          initTableConfig,
          accessPermissions,
          account,
          porterPermissions,
          dispatch,
          selectedEntity,
          savedViewChanges,
        });
      setColumnDefs(initColDefs);
      setRowData(initRowDefs);
      if (initRowDefs && initRowDefs.length === 0) {
        setIsEntityTableEmpty?.(true);
      }
    }
  }, [initTableConfig]);

  useEffect(() => {
    const childDiv = document.querySelector(".ZorpTablePagination-root");
    if (childDiv) {
      const parentDiv = childDiv.parentElement;
      if (parentDiv) {
        parentDiv.classList.add("ZorpTablePaginationParent-root");
      }
    }
  }, []);

  useEffect(() => {
    if (columnDefs.length > 0) {
      // Filter column definitions and map to accessor keys, excluding 'comments' and 'addRow'
      const defaultColumnOrdering = columnDefs
        .filter(col => col.accessorKey !== 'comments' && col.accessorKey !== 'addRow')
        .map(col => col.accessorKey);
  
      // Prepend 'mrt-row-select' to the column ordering
      defaultColumnOrdering.unshift("mrt-row-select");
  
      // Retrieve the current table state from local storage
      const currentTableState = LocalStorageUtil.getItem(`tableState_${selectedEntity?.tableType}_${view?.viewId}`);
  
      // Determine the current column ordering based on view changes or saved view changes
      let currentTableOrdering: any = (viewChanges && viewChanges[view?.viewId] && currentTableState)
        ? currentTableState.columnOrdering.filter((col: string) => col !== 'comments')
        : savedViewChanges[view?.viewId]?.columnOrdering?.filter((col: string) => col !== 'comments');
  
      // Check if the current ordering has the same length as the default ordering
      if (currentTableOrdering && currentTableOrdering.length !== defaultColumnOrdering.length) {
        // Find any missing elements that are not in the current ordering
        const missingElements = defaultColumnOrdering.filter(col => !currentTableOrdering.includes(col));
  
        // Merge and deduplicate the current ordering with the missing elements
        currentTableOrdering = deduplicateArray([...currentTableOrdering, ...missingElements]);
  
        // Update the state with the new column ordering
        setColumnOrdering(currentTableOrdering);
  
        // If saved view changes exist for this view, update them as well
        if (view?.viewId && savedViewChanges[view?.viewId] && savedViewChanges[view?.viewId].columnOrdering) {
          const savedColumnOrdering = deduplicateArray([...savedViewChanges[view?.viewId].columnOrdering || [], ...missingElements]);
          dispatch(setSavedViewChanges({ ...savedViewChanges, [view?.viewId]: { ...savedViewChanges[view?.viewId], columnOrdering: savedColumnOrdering } }))
        }
      } else if (fromShowPage) {
        // If triggered from the show page, set the column ordering to default
        setColumnOrdering(defaultColumnOrdering);
  
        // Update saved view changes for this view
        if (view?.viewId && savedViewChanges[view?.viewId]) {
          dispatch(setSavedViewChanges({ ...savedViewChanges, [view?.viewId]: { ...savedViewChanges[view?.viewId], columnOrdering: defaultColumnOrdering } }));
        }
      }
    }
  }, [columnDefs]);
  

  useEffect(() => {
    if (isFirstRender.current) return; 
    if(selectedEntityMetadata && Object.keys(selectedEntityMetadata).length > 0)
      fetchListingView();
  }, [selectedEntityMetadata, refresh])

  //load state from local storage
  useEffect(() => {

    if (!selectedEntity || !view) return;
    const localStorageValView = localStorage.getItem(`tableState_${selectedEntity?.tableType}_${view?.viewId}`);
    const viewChanges = localStorage.getItem(`viewChanges_${selectedEntity?.tableType}`);
    const isViewChanged = viewChanges && JSON.parse(viewChanges).hasOwnProperty(view?.viewId);
    if (!localStorageValView) {
      const sortingLocal = (view?.params?.sorting || []).map((el:any) => {
        if(el.id !== 'createdOn' && el.id !== 'updatedOn' && el.id !== 'recordId') {
         return {
           ...el,
           keyword: true
         }
        }
        else {
          return el
        }
      })
      let currentTableState: ViewState = {
        sorting: sortingLocal,
        columnFilters: view?.params?.columnFilters || [],
        pagination: view?.params?.pagination || { pageIndex: 0, pageSize: 10 },
        columnFilterFns: view?.params?.columnFilterFns || {},
        columnPinning: { left: ['mrt-row-select'], right: ["showRecord", "addRow"] },
        globalFilter: view?.params?.globalFilter || '',
        showColumnFilters: view?.params?.showColumnFilters || false,
        showGlobalFilter: view?.params?.showGlobalFilter || true,
      };

      if (view?.params?.columnVisibility) {
        currentTableState.columnVisibility = view?.params?.columnVisibility;
      }
      if (view?.params?.columnOrdering) {
        currentTableState.columnOrdering = view?.params?.columnOrdering;
      }

      LocalStorageUtil.setItem(`tableState_${selectedEntity?.tableType}_${view?.viewId}`, currentTableState);
    }
    const densityKey = getTablePropsLSKey({
      type: "densityKey",
      tableId: selectedEntity?.tableType || "",
      viewId: view?.viewId,
    });
    const parsedLocalStorageValView = localStorageValView ? JSON.parse(localStorageValView) : null;
    if (!parsedLocalStorageValView) return;
    const columnFilterFns = parsedLocalStorageValView?.columnFilterFns;
    const columnPinning = parsedLocalStorageValView?.columnPinning;
    const columnFilters = parsedLocalStorageValView?.columnFilters;
    const columnVisibility = parsedLocalStorageValView?.columnVisibility;
    const density: "compact" | "comfortable" | "spacious" = localStorage.getItem(densityKey) as "compact" | "comfortable" | "spacious";
    const globalFilter = parsedLocalStorageValView?.globalFilter;
    const showGlobalFilter = parsedLocalStorageValView?.showGlobalFilter;
    const showColumnFilters = parsedLocalStorageValView?.showColumnFilters;
    const columnOrdering = Array.isArray(parsedLocalStorageValView?.columnOrdering) ? deduplicateArray(parsedLocalStorageValView?.columnOrdering) : undefined;
    const sorting = parsedLocalStorageValView?.sorting;
    const pagination = parsedLocalStorageValView?.pagination;
    if (columnFilterFns) {
      setColumnFilterFns(prevColumnFilterFns => {
        if (!isEqual(prevColumnFilterFns, columnFilterFns)) {
          return columnFilterFns;
        }
        return prevColumnFilterFns;
      });
    } else {
      setColumnFilterFns(prevColumnFilterFns => {
        if (!isEqual(prevColumnFilterFns, {})) {
          return {};
        }
        return prevColumnFilterFns;
      });
    }

    if (columnFilters) {
      setColumnFilters(prevColumnFilters => {
        if (!isEqual(prevColumnFilters, columnFilters)) {
          return columnFilters;
        }
        return prevColumnFilters;
      });
    }

    if (columnOrdering) {
      setColumnOrdering(prevColumnOrdering => {
        if (!isEqual(prevColumnOrdering, columnOrdering)) {
          return columnOrdering;
        }
        return prevColumnOrdering;
      });
    }

    if(columnVisibility) {
      setColumnVisibility(prevColumnVisibility => {
        if (!isEqual(prevColumnVisibility, columnVisibility)) {
          return columnVisibility;
        }
        return prevColumnVisibility;
      });
    }

    if (sorting) {
      setSorting(prevSorting => {
        if (!isEqual(prevSorting, sorting)) {
          return sorting;
        }
        return prevSorting;
      });
    }

    if (globalFilter) {
      setGlobalFilter(globalFilter);
    }

    if (pagination) {
      setPagination(prevPagination => {
        if (prevPagination.pageIndex !== pagination.pageIndex || prevPagination.pageSize !== pagination.pageSize) {
          return pagination;
        }
        return prevPagination;
      });
    }

    if (columnPinning) {
      // always add "showRecords" and "addRow" to right pinning
      const currRight = columnPinning.right;
      const currLeft = columnPinning.left;
      // add "mrt-row-select" to left pinning
      const left = ["mrt-row-select"]
      const right = ["showRecord", "addRow"];
      columnPinning.right = union(currRight, right);
      columnPinning.left = union(currLeft, left);
      setColumnPinning(columnPinning);
    } else {
      setColumnPinning(JSON.parse(defaultColumnPinning))
    }

    if (density) {
      setDensity(density);
    } else {
      setDensity('comfortable');
    }

    if (showGlobalFilter) {
      setShowGlobalFilter(showGlobalFilter);
    }
    if (showColumnFilters) {
      setShowColumnFilters(true);
    } else {
      setShowColumnFilters(false);
    }

    if(isViewChanged && localStorageValView) {
      const savedColumnVisibility = savedViewChanges?.[view.viewId]?.columnVisibility
      const currColumnVisibility = JSON.parse(localStorageValView).columnVisibility
      const columnVisibilityChange = checkColumnVisibility(savedColumnVisibility, currColumnVisibility)
      if(!columnVisibilityChange) {
        fetchListingView();
      }
    }

    isFirstRender.current = false;
  }, []);



  useEffect(() => {
    if (isFirstRender.current || !view) return;
    const densityKey = getTablePropsLSKey({
      type: "densityKey",
      tableId: selectedEntity?.tableType || "",
      viewId: view?.viewId,
    });
    localStorage.setItem(densityKey, density);
  }, [density, selectedEntity?.tableType]);


  const handleColumnFilterFnsChange = (updater: any) => {
    //call the setState as normal, but need to check if using an updater callback with a previous state
    setColumnFilterFns((prev) =>
      updater instanceof Function ? updater(prev) : updater
    );
  };

  const debouncedChangeDetection = debounce((currentTableState) => {
    if (!currentTableState || (view && view?.viewId && !savedViewChanges[view?.viewId])) return;
    const { isEqual, valChanged } = deepCompare(currentTableState, savedViewChanges[view?.viewId]);
    if (!isEqual) {
      if (setViewChanges && viewChanges && Object.keys(viewChanges).length > 0) {
        setViewChanges({ ...viewChanges, [view?.viewId]: true });
      }
      if (selectedEntity?.tableType) LocalStorageUtil.setItem(`viewChanges_${selectedEntity?.tableType}`, JSON.stringify({
        ...viewChanges,
        [view?.viewId]: true,
      }));
      recordRSEvent(ViewsRSEvents.TABLE_STATE_CHANGE_DETECTED, {
        tableType: selectedEntity?.tableType || "",
        viewId: view?.viewId || "",
        changedField: valChanged,
      });

      if (valChanged && !valChanged.startsWith('columnVisibility' || !valChanged.startsWith('columnOrdering'))) {
        fetchListingView();
      }
       
    } else {
      if (setViewChanges && viewChanges && Object.keys(viewChanges).length > 0) {
        setViewChanges({ ...viewChanges, [view?.viewId]: false });
      }
      if (selectedEntity?.tableType && viewChanges && Object.keys(viewChanges).length > 0) LocalStorageUtil.setItem(`viewChanges_${selectedEntity?.tableType}`, JSON.stringify({
        ...viewChanges,
        [view?.viewId]: false,
      }));

      recordRSEvent(ViewsRSEvents.TABLE_STATE_CHANGE_NOT_DETECTED, {
        tableType: selectedEntity?.tableType || "",
        viewId: view?.viewId || "",
      });

      fetchListingView();     
    }
  }, 100);


  useEffect(() => {
   
    if (isFirstRender.current || !view) return;

    let initialColumnFilterFns: { [key: string]: string } = columnFilterFns || {};

    const columnFilterFnsLocal: { [key: string]: string } = columnFilters.reduce((acc, filter: { id: string; value: unknown; }) => {
      acc[filter.id] = initialColumnFilterFns[filter.id] || 'contains';
      return acc;
    }, initialColumnFilterFns);

    const sortingLocal = (sorting || []).map((el:any) => {
        if(el.id !== 'createdOn' && el.id !== 'updatedOn' && el.id !== 'recordId') {
         return {
           ...el,
           keyword: true
         }
        }
        else {
          return el
        }
      })

    const currentTableState: ViewState = {
      sorting: sortingLocal,
      columnFilters: columnFilters,
      columnFilterFns: columnFilterFnsLocal,
      columnPinning: columnPinning || { left: ['mrt-row-select'], right: ['showRecord', 'addRow'] },
      pagination: pagination || {
        pageIndex: 0,
        pageSize: 10,
      },
      globalFilter: globalFilter || '',
      showColumnFilters: showColumnFilters || false,
      showGlobalFilter: showGlobalFilter || true,
      columnVisibility: columnVisibility ? Object.keys(columnVisibility).length > 0 ? columnVisibility : undefined : undefined,
      columnOrdering: columnOrdering
    };

    debouncedChangeDetection(currentTableState);

    LocalStorageUtil.setItem(`tableState_${selectedEntity?.tableType}_${view?.viewId}`, currentTableState);

   
    // eslint-disable-next-line
  }, [
    columnVisibility,
    columnFilterFns,
     columnFilters, 
     pagination, 
     sorting, 
     globalFilter,
    ]);

    useEffect(() => {
      if (isFirstRender.current || !view) return;
  
      let initialColumnFilterFns: { [key: string]: string } = columnFilterFns || {};
  
      const columnFilterFnsLocal: { [key: string]: string } = columnFilters.reduce((acc, filter: { id: string; value: unknown; }) => {
        acc[filter.id] = initialColumnFilterFns[filter.id] || 'contains';
        return acc;
      }, initialColumnFilterFns);
  
      const currentTableState: ViewState = {
        sorting: sorting || [],
        columnFilters: columnFilters,
        columnFilterFns: columnFilterFnsLocal,
        columnPinning: columnPinning || { left: ['mrt-row-select'], right: ['showRecord', 'addRow'] },
        pagination: pagination || {
          pageIndex: 0,
          pageSize: 10,
        },
        globalFilter: globalFilter || '',
        showColumnFilters: showColumnFilters || false,
        showGlobalFilter: showGlobalFilter || true,
        columnOrdering: columnOrdering
      };
  
      if (columnVisibility && Object.keys(columnVisibility).length > 0) {
        currentTableState.columnVisibility = columnVisibility
      }
  
      LocalStorageUtil.setItem(`tableState_${selectedEntity?.tableType}_${view?.viewId}`, currentTableState);
      // eslint-disable-next-line
    },[
      columnPinning, 
      showColumnFilters, 
      showGlobalFilter,
    ]);
  

  useEffect(() => {
    if (isFirstRender.current || !view) return;

    const savedColumnOrdering = savedViewChanges[view?.viewId]?.columnOrdering || [];
    if (savedColumnOrdering.length === 0 || arraysAreEqual(savedColumnOrdering, columnOrdering, selectedEntityMetadata)) return;

    const sortingLocal = (sorting || []).map((el:any) => {
      if(el.id !== 'createdOn' && el.id !== 'updatedOn' && el.id !== 'recordId') {
       return {
         ...el,
         keyword: true
       }
      }
      else {
        return el
      }
    })

    const currentTableState: ViewState = {
      sorting: sortingLocal,
      columnFilters: columnFilters,
      columnFilterFns: columnFilterFns || {},
      columnPinning: columnPinning || { left: ['mrt-row-select'], right: ['showRecord', 'addRow'] },
      pagination: pagination || {
        pageIndex: 0,
        pageSize: 10,
      },
      globalFilter: globalFilter || '',
      showColumnFilters: showColumnFilters || false,
      showGlobalFilter: showGlobalFilter || true,
    };

    if (columnVisibility && Object.keys(columnVisibility).length > 0) {
      currentTableState.columnVisibility = columnVisibility
    }

    if (columnOrdering && columnOrdering.length > 0) {
      currentTableState.columnOrdering = columnOrdering
    }

    LocalStorageUtil.setItem(`tableState_${selectedEntity?.tableType}_${view?.viewId}`, currentTableState);

    if (setViewChanges && viewChanges && Object.keys(viewChanges).length > 0) {
      setViewChanges({ ...viewChanges, [view?.viewId]: true });
    }
    if (selectedEntity?.tableType) LocalStorageUtil.setItem(`viewChanges_${selectedEntity?.tableType}`, JSON.stringify({
      ...viewChanges,
      [view?.viewId]: true,
    }));

  }, [columnOrdering]);

  const fetchListingView = async () => {
    if (!selectedEntity || !view?.viewId) {
      return;
    }
    const localStorageValue = localStorage.getItem(`tableState_${selectedEntity?.tableType}_${view?.viewId}`);
    if (!localStorageValue) {
      return;
    }
    const tableStateLocal = JSON.parse(localStorageValue);

    const allowedDirectSortFields = ["recordId", "createdOn", "updatedOn"];
    // keyword:false to each object in sorting array
    if (tableStateLocal && tableStateLocal?.sorting) tableStateLocal?.sorting.forEach((sortObj: {id: string, keyword: boolean}) => {
      if (!allowedDirectSortFields.includes(sortObj.id)) {
        sortObj.keyword = true;
      }
    });

    // add filterType to each column filter
    if (tableStateLocal && tableStateLocal.columnFilters) {
      (Array.isArray(tableStateLocal.columnFilters) ? tableStateLocal.columnFilters : []).forEach((filterObj:any) => {
          if (!filterObj.filterType) {
            const isDateField = moment(filterObj.value, moment.ISO_8601, true).isValid()
              if ((filterObj.value && isDateField)) {
                  filterObj.filterType = tableStateLocal.columnFilterFns[filterObj.id] === "contains" ? "lessThanOrEqualTo" : tableStateLocal.columnFilterFns[filterObj.id];
              } else {
                  filterObj.filterType = tableStateLocal.columnFilterFns[filterObj.id] || "contains";
              }
          }
      });
  }
    const savedColumnFilters = savedViewChanges[view?.viewId]?.columnFilters || [];

    const finalColumnFiltersMap = new Map<string, ColumnFilter>(
      tableStateLocal.columnFilters.map((el: ColumnFilter) => [el.id, el])
    );
    
    savedColumnFilters.forEach((item) => {
      if (!finalColumnFiltersMap.has(item.id)) {
        finalColumnFiltersMap.set(item.id, { ...item, value: undefined });
      }
    });
    
    const resultedColumnFilters: ColumnFilter[] = Array.from(
      finalColumnFiltersMap.values()
    );


    try {
      if (selectedEntity && selectedEntity.tableType) {
        setLoading(true);
        const data:any = await EntityService.getListingView(
          selectedEntity.tableType,
          "listing",
          pagination.pageIndex || 0,
          pagination.pageSize || 10,
          tableStateLocal?.sorting || [],
          tableStateLocal?.globalFilter || '',
          resultedColumnFilters || [],
          view?.viewId || ""
        );

        setRowCount(data?.count || 0);
        setLoading(false);

        const { colDefs, rowDefs } = formatListingConfig(
          {data: data.body, 
          view,
          fromShowPage, 
          setSelectedColumnName,
          setShowColumnPermissionsModal,
          handleActionMenuClose,
          columnFilterFns,
          setLinksArray,
          setShowImage,
          refresh,
          setRefresh,
          initTableConfig,
          accessPermissions,
          account,
          porterPermissions,
          dispatch,
          selectedEntity,
          savedViewChanges,
        });
        setRowData(() => [...rowDefs]);
        if (rowDefs.length === 0) {
          setIsEntityTableEmpty?.(true);
        }
        setColumnDefs(colDefs)
      }
    } catch (error: any) {
      console.log(">>>> error", error?.message);
      notification("error", error?.message);
      setRowData([]);
      setColumnDefs([]);
    }
  };

  const memoizedRowData = useMemo(() => rowData, [rowData]);
  const memoizedColDefs = useMemo(() => columnDefs, [columnDefs]);

  return (
    <>
      <div
        className={
          initTableConfig === undefined
            ? "zorpTable"
            : "zorpTable zorpTableComponent table-container"
        }
        style={
          initTableConfig === undefined ? { height: "calc(100% - 7px)", overflow: "auto" } : {}
        }
      >
        <MaterialReactTable
          data={memoizedRowData}
          enableColumnResizing
          defaultColumn={{
            minSize: 20, //allow columns to get smaller than default
            maxSize: 400, //allow columns to get larger than default
          }}
          muiTableBodyCellProps={({ cell }) => ({
            onDoubleClick: (event) => {
              event.stopPropagation();
              //@ts-ignore
              showRecordClick(cell.row.original.recordId);
            },
          })}
          enableMultiRowSelection
          muiSelectCheckboxProps={({ row }) => ({
            color: "primary",
            // show "Delete" icon only if fromShowPage is true else show the default checkbox
            icon: fromShowPage ? <RiDeleteBinLine /> : <Checkbox />,
            onClick: () =>
              handleRowSelectionChange((prev) => ({
                ...prev,
                [row.id]: !prev[row.id],
              })),
          })}
          columns={memoizedColDefs}
          enablePinning
          enableStickyHeader
          muiTablePaperProps={{
            style: {
              height: "100%",
              position: "relative",
              zIndex: 0,
              boxShadow: "none",
            },
          }}
          getRowId={(row:any) => row.recordId}
          muiTableHeadCellProps={{
            sx: {
              "& .Mui-TableHeadCell-Content": {
                justifyContent: "space-between",
              },
              "& .MuiButtonBase-root": {
                justifyContent: "start",
              },
              "& .Mui-TableHeadCell-ResizeHandle-Wrapper": {
                "& .MuiDivider-root": {
                  borderWidth: "0px",
                  borderColor: "none !important",
                },
              },
            },
          }}
          muiTableBodyRowProps={({ row }) => {
            return {
              sx: {
                backgroundColor: rowsToDelete?.includes(row?.original.recordId)
                  ? "#FFF4ED !important"
                  : addedRecords?.includes(row?.original?.recordId)
                    ? "#ECF3FF !important"
                    : "white",
                // Apply any other row styles here
              },
            };
          }}
          muiTopToolbarProps={{
            sx: {
              padding: "0px !important",
            },
            className: "zorpTableTopToolbar",
            id: "muitoptoolbar",
          }}
          renderToolbarInternalActions={() => <></>}
          renderTopToolbarCustomActions={(props) => {
            return initTableConfig === undefined || fromShowPage ? (
              <CustomToolbar
                showColumnFilters={showColumnFilters}
                columnFilterFns={columnFilterFns}
                setLoading={setLoading}
                getTableInstance={getTableInstance}
                viewChanges={viewChanges}
                setViewChanges={setViewChanges}
                setRefresh={setRefresh}
                setSelectedRows={setSelectedRows}
                viewId={view ? view?.viewId : ""}
                {...props}
              />
            ) : (
              <></>
            );
          }}
          initialState={{
            columnPinning: {
              // left: ["recordId"],
              right: ["addRow"],
            },
            pagination,
            columnFilters,
            globalFilter,
            sorting,
            rowSelection: { "0": true },
          }}

          rowCount={initTableConfig === undefined ? rowCount : undefined}
          enableRowSelection={
            fromShowPage ?
              (fromShowPage && enableRowSelection) ? true : false
              : accessPermissions?.delete_record ? true : false
          }
          onPaginationChange={setPagination}
          onColumnFiltersChange={setColumnFilters}
          onColumnVisibilityChange={setColumnVisibility}
          onDensityChange={setDensity}
          enableGlobalFilter={initTableConfig === undefined}
          onGlobalFilterChange={setGlobalFilter}
          onColumnOrderChange={setColumnOrdering}
          onShowColumnFiltersChange={setShowColumnFilters}
          onShowGlobalFilterChange={setShowGlobalFilter}
          onSortingChange={setSorting}
          onColumnFilterFnsChange={handleColumnFilterFnsChange}
          onColumnPinningChange={setColumnPinning}
          enableColumnFilterModes
          manualFiltering={initTableConfig === undefined}
          positionGlobalFilter="left"
          muiSearchTextFieldProps={{
            placeholder: `Search ${rowCount} records`,
            inputProps: {
              sx: {
                fontSize: "11px",
                display: "none",
              },
            },
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">
                  <AiOutlineSearch
                    style={{ marginLeft: "4px" }}
                    size={"16px"}
                  />
                </InputAdornment>
              ),
            },
            sx: {
              border: "1px solid #EAECF0",
              padding: "3px",
              borderRadius: "4px",
              width: "100%",
              marginRight: "10px",
            },
          }}
          manualPagination={initTableConfig === undefined}
          manualSorting={initTableConfig === undefined}
          globalFilterFn="contains"
          icons={CustomIcons}
          muiTablePaginationProps={{
            rowsPerPageOptions: [10, 20, 30, 50, 100],
            showFirstButton: false,
            showLastButton: false,
            className: "ZorpTablePagination-root",
          }}
          onRowSelectionChange={handleRowSelectionChange}
          enableColumnVirtualization
          state={{
            columnFilters,
            columnVisibility : columnVisibility || {},
            density,
            globalFilter,
            showColumnFilters,
            showGlobalFilter,
            sorting,
            isLoading: loading,
            pagination,
            columnFilterFns,
            columnOrder: columnOrdering,
            columnPinning: {
              left: union(["mrt-row-select"], columnPinning.left),
              right: union(columnPinning.right, ["showRecord", "addRow"]),
            },
            rowSelection: selectedRows,
          }}
          enableColumnOrdering={initTableConfig === undefined || !fromShowPage}
        />
      </div>
      {linksArray?.length > 0 && (
        <ImageModal
          BackdropComponent={Backdrop}
          BackdropProps={{
            color: "rgba(0,0,0,0.8)",
          }}
          open={showImage}
          onClose={() => setShowImage(false)}
        // aria-labelledby="modal-modal-title"
        // aria-describedby="modal-modal-description"
        >
          <ImageSlider
            onClose={() => setShowImage(false)}
            startIndex={0}
            slides={linksArray}
          />
        </ImageModal>
      )}
      {showColumnPermissionsModal && <ColumnPermissions colName={selectedColumnName} open={showColumnPermissionsModal} setOpen={setShowColumnPermissionsModal} key={"column-permissions"} />}
    </>
  );
};

export default memo(VirtualizedMEntityTable);
