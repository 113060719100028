import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { TopToolbar, ListButton, EditButton } from 'react-admin';

import { Button } from '@material-ui/core';

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';
import DeleteIcon from '@material-ui/icons/Delete';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import notification from '../../../../../notifications/notifications';
import { hasPermission } from '../../../../../providers/auth/authUtils';
import { TaskService } from '../../../services/TaskService';
import UserSelection from '../../../UserSelection';

import authProvider from '../../../../../providers/auth/authProvider';
import { ModifyScheduleSlot } from '../edit/ModifyScheduleSlot';
import DeletionConfirmationDialog from 'utils/DeletionConfirmationDialog'

const TaskShowActions = (props) => {
  const [open, setOpen] = React.useState(false);
  const [changeOpen, setChangeOpen] = React.useState(false);
  const [scheduleSlotModalOpen, setScheduleSlotModalOpen] = React.useState(false);
  const [initialDriver, setInitialDriver] = React.useState(props?.data?.userId);
  const [driver, setDriver] = React.useState(props?.data?.userId);
  const [teamIds, setTeamIds] = React.useState(props?.data?.teamIds);
  const history = useHistory();
  // console.log("Show Props", props);

  useEffect(() => {
    setInitialDriver(props?.data?.userId);
    setDriver(props?.data?.userId);
    setTeamIds(props?.data?.teamIds);
  }, [props?.data]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleChangeOpen = () => {
    setChangeOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChangeClose = () => {
    setChangeOpen(false);
  };
  const handleTeamDriverChange = (teamId, driverId) => {
    setTeamIds([teamId]);
    setDriver(driverId);
  };

  const handleUserChange = () => {
    if (!teamIds || teamIds.length === 0 || teamIds[0].trim().length === 0)
      return notification('error', 'Team has to be selected');
    TaskService.updateTeamUser(props?.data?.id, driver, teamIds)
      .then((res) => {
        // console.log("RES", res);
        if (res.code !== '200') {
          // Error
          if (res.code === '401') {
            notification('error', res.message);
            authProvider.logout();
          } else {
            notification('error', res.message);
          }
        } else {
          notification('success', res.message);
        }
        setChangeOpen(false);
        history.push(`/tasks`);
      })
      .catch((err) => {
        notification('error', 'Task Transition Failed');
        setChangeOpen(false);
        // history.push("/tasks");
        console.log(err);
      });
  };

  const handleDelete = () => {
    const taskId = props?.data?.id;
    // Should got to older version of the task delete
    TaskService.deleteTaskLegacy(taskId)
      .then((res) => {
        // console.log("Deleted", res);
        if (res.code === '200') {
          setOpen(false);
          notification('success', res.message);
          history.push('/tasks');
        } else {
          setOpen(false);
          notification('error', res.message);
        }
      })
      .catch((err) => {
        console.log(err);
        notification('error', 'Task not deleted!');
        setOpen(false);
      });
  };

  return (
    <TopToolbar className='tool-bar'>
      <ListButton basepath={props.basePath} label='Back' icon={<ChevronLeft />} />
      {hasPermission('task', 'edit') &&
        !props?.data?.taskInTerminalState &&
        props?.data &&
        props?.data?.taskType !== 'inventory_pickup_task' && (
          <EditButton basepath={props?.basePath} record={props?.data} />
        )}
      {props?.data && !props?.data?.taskInTerminalState && props?.data?.taskType !== 'inventory_pickup_task' && (
        <Button startIcon={<SwapHorizontalCircleIcon />} size='small' onClick={handleChangeOpen}>
          CHANGE TEAM/USER
        </Button>
      )}
      {props?.data && !props?.data?.taskInTerminalState && props?.data?.taskType !== 'inventory_pickup_task' && (
        <Button startIcon={<SwapHorizontalCircleIcon />} size='small' onClick={() => setScheduleSlotModalOpen(true)}>
          CHANGE SLOT
        </Button>
      )}
      {props?.data && !props?.data?.taskInTerminalState && props?.data?.taskType !== 'inventory_pickup_task' && (
        <Button startIcon={<DeleteIcon />} size='small' onClick={handleClickOpen}>
          DELETE
        </Button>
      )}

      <DeletionConfirmationDialog
          deletionTitle="Delete Task"
          deletionText="Do you want to delete this task? This action cannot be undone."
          id={"deleteSingleTask"}
          isOpen={open}
          onConfirmDelete={handleDelete}
          onClose={{handleClose} }
        ></DeletionConfirmationDialog>
      <Dialog
        open={changeOpen}
        // fullWidth
        maxWidth='xs'
        onClose={handleChangeClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Change</DialogTitle>
        <DialogContent>
          <UserSelection id={props?.data?.id} driver={driver} teamIds={teamIds} onChange={handleTeamDriverChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleChangeClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleUserChange} color='primary'>
            Change
          </Button>
        </DialogActions>
      </Dialog>

      {/* Schedule Slot change Dialog */}
      {scheduleSlotModalOpen && (
        <ModifyScheduleSlot
          taskId={props?.data?.id}
          scheduledFrom={props?.data?.scheduledSlot?.from}
          scheduledTo={props?.data?.scheduledSlot?.to}
          taskType={props?.data?.taskType}
          onClose={() => setScheduleSlotModalOpen(false)}
        ></ModifyScheduleSlot>
      )}
    </TopToolbar>
  );
};

export default TaskShowActions;
