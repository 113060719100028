import {Label,Input} from "../DataField.styles"
import {numberDefaultValueErrorSchema} from "../DataFieldErrorSchema"
import ErrorMessage from "../ErrorMessage"

type NumberTypeProp = {
    register:any;
    errors?:any;
    defaultValueName?:string
}


const Number = ({register,errors,defaultValueName="defaultValue"}:NumberTypeProp) => {
  return (  
    <>
        <Label>
            Default Value
        </Label>
        <Input autoFocus type="number" step={"any"} {...register(defaultValueName,numberDefaultValueErrorSchema)} />
        {/* <ErrorMessage errors={errors} fieldName={"defaultValue"}/> */}
    </>
  )
}

export default Number