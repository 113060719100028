import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { TreeItem, TreeItemProps, TreeView } from "@mui/x-tree-view";
import notification from 'notifications/notifications';
import { UseFormSetValue } from "react-hook-form";
import { AiFillCaretDown, AiFillCaretRight } from 'react-icons/ai';
import { IoIosCube } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import styled, { useTheme } from 'styled-components';
import { generateId } from 'utils/Utils';
import useAutomation from "views/automationBuilder/hooks/useAutomation";
import { ABMetadataTypeEnum } from 'views/automationBuilder/types/ABType';
import useWorkFlow from 'views/workflows/hooks/useWorkFlow';
import {
  newSetActiveEditField,
  setActiveSubField, setShowDataFieldCreateModal
} from 'views/workflows/reducers/workflowAction';


type SingleVariable = {
  name:string,
  dataType?:string
  id?:string
}

type StyledTreeItemProps = TreeItemProps & {
  labelInfo?: string;
  labelText: string;
};

interface IVariableTree {
  setValue?: UseFormSetValue<any>,
  updateDataField?: any,
  getValues?: Function,
  setSelectedDatafieldType?: Function
}

const CustomIcon = () => {
  const theme = useTheme();
  return <IoIosCube color={theme.color.primary} style={{ marginRight: '8px' }} />;
};

const StyledTreeItemRoot = styled(TreeItem)``;

const  StyledTreeItem = (props: StyledTreeItemProps) => {
  const { labelInfo = '', labelText, ...other } = props;

  return (
    <StyledTreeItemRoot
      label={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: 0.5,
            pr: 0,
            fontFamily: 'inter',
            marginBottom: '0px !important',
          }}>
          <Box component={CustomIcon} color='inherit' sx={{ mr: 1, marginBottom: '8px !important' }} />

          <Typography variant='h6' sx={{ fontWeight: 'inherit', flexGrow: 1, marginBottom: '0px !important' }}>
            {labelText}
          </Typography>

          <Typography variant='subtitle1' color='inherit' sx={{ marginBottom: '0px !important' }}>
            {labelInfo || ''}
          </Typography>
        </Box>
      }
      {...other}
    />
  );
}

export const VariableTreeDataFieldListAndSelector = ({ setValue, setSelectedDatafieldType, updateDataField }: IVariableTree) => {
  const variables = useSelector((state: any) => state.scriptEditor.variables);
  const dispatch = useDispatch();
  const { dataPicker } = useWorkFlow();
  const { metadataType } = useAutomation();

  // This component is used in both data field selector and in configure data in builder page
  const conditionArray = [ABMetadataTypeEnum.WORKFLOW_SCREEN_SMART_COMPONENT, ABMetadataTypeEnum.WORKFLOW_SCREEN_ONLOAD, ABMetadataTypeEnum.ENTITY_AUTOMATION, ABMetadataTypeEnum.BUTTON_AUTOMATION, ABMetadataTypeEnum.CRON, ABMetadataTypeEnum.WEBHOOK, ABMetadataTypeEnum.MONITOR, ABMetadataTypeEnum.TEAM, ABMetadataTypeEnum.USER];
  const useUpdateDataFieldVariable = conditionArray.includes(metadataType);

  const handleDoubleClick = (dataFieldId: any) => {
    if (dataPicker?.isOpened) {
      return
    }
    if (!dataFieldId) {
      notification('error', 'This datafield cannot be edited');
      return;
    }
    dispatch(setShowDataFieldCreateModal(false));
    dispatch(newSetActiveEditField({ dataFieldId: null }));
    dispatch(setActiveSubField(null));
    setTimeout(() => {
      dispatch(newSetActiveEditField({ dataFieldId }));
    }, 100);
  };

  const handleClick = (str: string, dataType: string) => {
    if (setValue && (dataType === dataPicker.dataType)) {
      setSelectedDatafieldType && setSelectedDatafieldType(str)
    }
  }

  const renderTree = (parentString: string, nodes: any) => (
    <>
      <StyledTreeItem
        onDoubleClick={(e) => {
          e.stopPropagation();
          if (!nodes?.isReadOnly) {
            handleDoubleClick(nodes?.id);
          } else {
            handleDoubleClick(undefined);
          }
        }}
        onFocusCapture={() => {
          setTimeout(() => {
            handleClick(`${parentString}.${nodes?.id}`, nodes.dataType)
          }, 0)

        }}
        key={generateId(6)}
        nodeId={`${parentString}.${nodes.id}`}
        labelText={nodes.name}
        labelInfo={nodes.dataType}
        sx={{ marginBottom: '0px !important' }}
        // Anything which is array will not be allowed to select. Because we don't have support for referencing index.
        disabled={!dataPicker?.isOpened ? false :
          (Array.isArray(nodes.struct) && nodes.struct.length > 0) ? false : nodes.dataType !== dataPicker?.dataType}
      >
        {Array.isArray(nodes.struct)
          ? nodes.struct.map((node: any) => renderTree(`${parentString}.${nodes.id}`, node))
          : null}
      </StyledTreeItem>
    </>
  );

  return (
    <TreeView
      aria-label='multi-select'
      defaultCollapseIcon={<AiFillCaretDown />}
      defaultExpandIcon={<AiFillCaretRight />}
      defaultExpanded={['task', 'task.data']}
      multiSelect
      sx={{ height: setValue ? "100%" : '75vh', flexGrow: 1, width: 'auto', overflowY: 'auto', marginBottom: '0px !important' }}>

      {(useUpdateDataFieldVariable ? Object.keys(updateDataField) : Object.keys(variables)).map((variable: any) => {
        return (
          <TreeItem
            sx={{ fontFamily: 'inter', fontWeight: '600', color: '#92979d', marginBottom: '0px !important' }}
            nodeId={variable}
            label={variable}>
            {
              (useUpdateDataFieldVariable ? updateDataField[variable] : variables[variable]).map((singleVariable: SingleVariable) => {
                return (
                  <>
                    {singleVariable.dataType === 'OBJECT' || (singleVariable.dataType === 'LIST' && !singleVariable?.id) ? (
                      renderTree(variable, singleVariable)
                    ) : (
                      <StyledTreeItem
                        disabled={(!dataPicker.isOpened || singleVariable.dataType === 'OBJECT') ? false : singleVariable.dataType !== dataPicker?.dataType}
                        sx={{ marginBottom: '0px !important' }}
                        nodeId={generateId(6)}
                        labelInfo={singleVariable.dataType}
                        labelText={singleVariable.name}
                        onDoubleClick={() => handleDoubleClick(singleVariable?.id || undefined)}
                        onFocusCapture={() => handleClick(`${variable}.${singleVariable.id}`,singleVariable?.dataType as string)}
                      />
                    )}
                  </>
                );
              })
            }
          </TreeItem>
        );
      })}
    </TreeView>
  );
};

export default VariableTreeDataFieldListAndSelector;
