import LoadingButton from '@mui/lab/LoadingButton';
import { Box } from '@mui/material';
import TestAlertIcon from 'assests/test_alert_icon.svg';
import ZorpSlacklogo from 'assests/zorp_slack_logo.svg';
import AssignmentIcon from 'assests/assignment_icon.svg';
import styled from "styled-components";
import MonitorListDetailPageContextMenu, { IMonitorContentMenu } from './MonitorListDetailPageContextMenu';
import { MonitorService } from 'providers/data/services/MonitorService';
import { memo, useEffect, useState } from 'react';
import moment from 'moment';
import { IActionType } from '@zorp/common-libs-js/dist/monitor';
import { Theme } from '@mui/material/styles';
import { Divider } from '../Monitor.styles';
import Tooltip from "@mui/material/Tooltip";
import { recordRSEvent } from 'utils/CommonUtils';
import Typography from "@mui/material/Typography";
import notification from 'notifications/notifications';
import { RudderStackAutomationBuilderEvents } from 'constants/RudderStackConstants';

interface IMonitorListDetailPage {
    selectedMonitorData: any;
    onClickEdit: (menuContext: IMonitorContentMenu) => void;
}

const CardBoxWrapper = styled(Box)<{ theme: Theme }>(() => ({
    marginTop: '20px',
    borderRadius: '4px',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
}));

const CardHeaderWrapper = styled(Box)({
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    height: '34px',
    padding: '8px 16px',
    backgroundColor: '#F2F4F7',
});

const MonitorTitle = styled.p`
  color: var(--Gray-900, #101828);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
`;

const UpdatedOnTxtLabel = styled.p`
  color: black;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
`;
const UpdatedOnTxt = styled.p`
  color: var(--Gray-700, #344054);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
`;

const CardTitle = styled.p`
  color: black;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

const MonitorCardLabel = styled.p`
  color: black;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

const MonitorCardTxt = styled.p`
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
`;

const EmailToText = styled.p`
  color: black;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  margin-bottom: 8px;
  font-weight: 400;
`;

const TicketDescriptionText = styled.p`
  color: var(--Gray-900, #101828);
  font-family: Inter;
  font-size: 12px;
  display: flex;
  font-style: normal;
  width: 100%;
  gap: 4px;
  align-items: center;
  flex-wrap: wrap;
  font-weight: normal;
`;

const EmailBodyText = styled.p`
  color: var(--Gray-900, #101828);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  :400 ;
  width: 100%;
  gap: 4px;
  align-items: center;
  flex-wrap: wrap;
`;

const SlackBodyText = styled.p`
  color: var(--Gray-900, #101828);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  gap: 4px;
  align-items: center;
  flex-wrap: wrap;
`;

const EmailBodySubject = styled.p`
  color: var(--Gray-900, #101828);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 8px;
`;

// New styled component for the special text
const HighlightedText = styled.span`
  color: var(--Primary-700, #3054b9);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

const VariableColumnWrapper = styled.span`
  padding: 2px 8px;
  width: fit-content;
  background-color: #f5f8ff;
  border-radius: 16px;
`;

const CriticalValueBox = styled(Box)({
  borderRadius: "4px",
  width: "fit-content",
  backgroundColor: "#D92D20",
  padding: "2px 8px",
});

const AssisgnmentBoxWrapper = styled(Box)({
  borderRadius: "6px",
  width: "fit-content",
  padding: "2px 8px",
  border: "1px solid #D0D5DD",
});

const AssisgnmentBoxText = styled.p`
  color: var(--Base-White, #344054);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

const CriticalValueText = styled.p`
  color: var(--Base-White, #fff);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

const CriticalValueLable = styled.p`
  color: black;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

const ZorpLabelText = styled.p`
  color: var(--Gray-900, #101828);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

const MonitorListDetailPage = ({ selectedMonitorData, onClickEdit }: IMonitorListDetailPage) => {
    const [testMonitorLoading, setTestMonitorLoading] = useState<boolean>(false);
    const [cronDescription, setCronDescription] = useState<string>();
    const [cronNextRun, setCronNextRun] = useState<string>();

    const ticketInformationArray = selectedMonitorData?.actionData?.filter((data: any) => data.actionType == IActionType.TICKET);
    const emailInformationArray = selectedMonitorData?.actionData?.filter((data: any) => data.actionType == IActionType.EMAIL);
    const slackInformationArray = selectedMonitorData?.actionData?.filter((data: any) => data.actionType == IActionType.SLACK);

    const testMonitor = async () => {
        recordRSEvent(RudderStackAutomationBuilderEvents.testAlertInList, {
            context: RudderStackAutomationBuilderEvents.monitorContext
          });
        setTestMonitorLoading(true);
        const monitorPayload = {
            monitorId: selectedMonitorData.monitorId,
            executionType: 'CRON'
        }

        const testMonitor = await MonitorService.testMonitor(monitorPayload);
        setTestMonitorLoading(false);
        if (testMonitor?.code == '200') {
            notification('success', 'Alert Executed');
        } else {
            notification('error', testMonitor?.message || 'Failed to Execute Alert');
        }
    }


    const parseDescData = (text: string) => {
        const regex = /\$\{(.*?)\}/g;
        let result = [];
        let lastIndex = 0;

        text?.replace(regex, (match: any, p1: any, offset: any) => {
            // Push the text before the match, if any
            if (offset > lastIndex) {
                result.push({ text: text.substring(lastIndex, offset), highlighted: false });
            }
            // Push the matched text
            result.push({ text: match, highlighted: true });
            lastIndex = offset + match.length;
        });

        // Push any remaining text after the last match
        if (lastIndex < text?.length) {
            result.push({ text: text?.substring(lastIndex), highlighted: false });
        }

        return result;
    };


    const getCronDescription = async () => {
        let monitorDescriptionPayload;
        const cronData = selectedMonitorData.cronData;
        if (selectedMonitorData.cronData.cronType == 'INTERVAL') {

            monitorDescriptionPayload = {
                timeZone: cronData.timeZone,
                cronData: {
                    hours: cronData.cronData.hours,
                    minutes: cronData.cronData.minutes
                },
                cronType: cronData.cronType,
            }

        } else if (cronData.cronType == 'EXPRESSION') {

            monitorDescriptionPayload = {
                timeZone: cronData.timeZone,
                cronData: {
                    expression: cronData.cronData.expression
                },
                cronType: cronData.cronType
            }
        }
        const monitorDescOutput = await MonitorService.getCronDescription(monitorDescriptionPayload);
        if (monitorDescOutput?.code == '200') {
            setCronDescription(monitorDescOutput.data.cronDescription);
            setCronNextRun(monitorDescOutput.data.cronNextRun.toString());
        } else {
            setCronDescription('');
            setCronNextRun('');
        }

    }

    useEffect(() => {
        getCronDescription();
    }, [selectedMonitorData?.monitorId])

    return (
        <Box padding={'24px'}>

            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <MonitorTitle>{selectedMonitorData.monitorName}</MonitorTitle>

                <Box display={'flex'} gap={'8px'}>


                    <Tooltip
                        disableFocusListener={selectedMonitorData.isActive}
                        disableHoverListener={selectedMonitorData.isActive}
                        disableTouchListener={selectedMonitorData.isActive}
                        disableInteractive={selectedMonitorData.isActive}
                        title={
                            <Typography>
                                {selectedMonitorData.isActive
                                    ? ""
                                    : "Execute Disabled, when Alert is Not Active"}
                            </Typography>
                        }>
                        <span>
                            <LoadingButton
                                color='secondary'
                                loading={testMonitorLoading}
                                disabled={testMonitorLoading || !selectedMonitorData?.isActive}
                                loadingPosition='start'
                                startIcon={<img style={{ paddingRight: '8px' }} src={TestAlertIcon} alt='Icon' />}
                                onClick={async () => {
                                    testMonitor();
                                }}
                                style={{
                                    borderRadius: '4px',
                                    height: '36px',
                                    color: '#344054',
                                    fontSize: '12px',
                                    fontWeight: 'normal',
                                    padding: '8px 14px',
                                    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)', border: '1px solid var(--Gray-300, #D0D5DD)', backgroundColor: '#fff',
                                    textTransform: 'none',
                                    fontFamily:'Inter',
                                    fontStyle:'normal'
                                }}
                                sx={{ height: '36px' }} variant="contained">

                                Test Alert
                            </LoadingButton>
                        </span>
                    </Tooltip>

                    <MonitorListDetailPageContextMenu
                        monitorStatus={selectedMonitorData.isActive}
                        onClickEdit={(editContext: IMonitorContentMenu) => {
                            onClickEdit(editContext)
                        }}
                    />
                </Box>
            </Box>

            {/* Updated On Wrapper */}
            <Box width={'100%'} display={'flex'} justifyContent={'flex-end'} marginTop={'20px'} gap={'8px'}>
                <UpdatedOnTxtLabel>Updated On</UpdatedOnTxtLabel>
                <UpdatedOnTxt>{moment(selectedMonitorData.updatedAt).format('MMM DD YYYY, hh:mm A')}</UpdatedOnTxt>
            </Box>

            {/* Alert Details Page */}

            <Box
                marginTop={'20px'}
                borderRadius={'4px'}
                boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)'}>
                <Box
                    sx={{
                        borderTopLeftRadius: '4px',
                        borderTopRightRadius: '4px',
                    }}
                    height={'34px'} padding={'8px 16px'} bgcolor={'#F2F4F7'}>
                    <CardTitle>Alert Details</CardTitle>
                </Box>
                <Box padding={'16px'}>

                    <Box>
                        <MonitorCardLabel>Description</MonitorCardLabel>
                        <MonitorCardTxt>{selectedMonitorData.monitorDescription}</MonitorCardTxt>
                    </Box>
                    {
                        (cronDescription && cronNextRun) &&
                        <>
                            <Box marginTop={'12px'}>
                                <MonitorCardLabel>Schedule</MonitorCardLabel>
                                <MonitorCardTxt>{cronDescription}</MonitorCardTxt>
                            </Box>

                            <Box marginTop={'12px'}>
                                <MonitorCardLabel>Next Run</MonitorCardLabel>
                                <MonitorCardTxt>{cronNextRun}</MonitorCardTxt>
                            </Box>
                        </>
                    }

                </Box>
            </Box>

            {/* Ticket Information */}
            {
                ticketInformationArray?.map((data: any, index:number) => {
                    const parsedText = parseDescData(data.payload.ticketNotes);
                    return (
                        <CardBoxWrapper key={index}>
                            <CardHeaderWrapper>
                                <CardTitle>Generated Ticket Preview</CardTitle>
                            </CardHeaderWrapper>
                            <Box padding={'16px'}>

                                {/* <TicketDescriptionText>{data.payload.ticketNotes}</TicketDescriptionText> */}
                                <Box display={'flex'} width={'100%'}>
                                    <TicketDescriptionText>
                                        {parsedText.map((part, index) =>
                                            part.highlighted ?
                                                (<>
                                                    <VariableColumnWrapper key={index}>
                                                        <HighlightedText>{part.text.replace('${', '').replace('}', '')}</HighlightedText>
                                                    </VariableColumnWrapper>
                                                </>) :
                                                part.text
                                        )}
                                    </TicketDescriptionText>
                                </Box>

                                <Box display={'flex'} gap={'8px'} marginTop={'16px'} alignItems={'center'}>
                                    <CriticalValueLable>Severity Level</CriticalValueLable>
                                    <CriticalValueBox>
                                        <CriticalValueText>{data.payload.priority?.value || data.payload.priority}</CriticalValueText>
                                    </CriticalValueBox>
                                </Box>

                                <Box display={'flex'} gap={'8px'} marginTop={'16px'} alignItems={'center'}>
                                    <CriticalValueLable>Assign to</CriticalValueLable>
                                    <AssisgnmentBoxWrapper>
                                        <Box display={'flex'} gap={'5px'}>
                                            <img src={AssignmentIcon} alt='Icon' />
                                            <AssisgnmentBoxText>{data.payload.userEmail?.value || data.payload.userEmail}</AssisgnmentBoxText>
                                        </Box>
                                    </AssisgnmentBoxWrapper>
                                </Box>
                            </Box>
                        </CardBoxWrapper>
                    )
                })
            }

            {/* Email Information */}
            {
                emailInformationArray.map((data: any, index:number) => {
                    const parsedText = parseDescData(data.payload.mailBody);
                    return (
                        <CardBoxWrapper key={index}>
                            <CardHeaderWrapper>
                                <CardTitle>Email Preview</CardTitle>
                            </CardHeaderWrapper>

                            <Box padding={'16px'}>
                                <EmailToText>{data.payload.userEmail.join(", ")}</EmailToText>

                                <Divider />

                                <Box display={'flex'} width={'100%'} marginTop={'8px'} flexDirection={'column'}>
                                    <EmailBodySubject>{data.payload.mailSubject}</EmailBodySubject>
                                    <EmailBodyText>
                                        {parsedText.map((part, index) =>
                                            part.highlighted ?
                                                (<>
                                                    <VariableColumnWrapper>
                                                        <HighlightedText key={index}>{part.text.replace('${', '').replace('}', '')}</HighlightedText>
                                                    </VariableColumnWrapper>
                                                </>) :
                                                part.text
                                        )}
                                    </EmailBodyText>
                                </Box>
                            </Box>
                        </CardBoxWrapper>
                    )
                })
            }

            {/* Slack Information */}
            {
                slackInformationArray.map((data: any, index:number) => {
                    const parsedText = parseDescData(data.payload.message);
                    return (
                        <CardBoxWrapper key={index}>
                            <CardHeaderWrapper>
                                <CardTitle>Slack Message Preview</CardTitle>
                            </CardHeaderWrapper>
                            <Box padding={'16px'} display={'flex'} alignItems={'flex-start'} gap={'12px'} >

                                <img src={ZorpSlacklogo} alt='Icon' />


                                <Box display={'flex'} width={'100%'} flexDirection={'column'}>
                                    <ZorpLabelText>Zorp</ZorpLabelText>
                                    <SlackBodyText>
                                        {parsedText.map((part, index) =>
                                            part.highlighted ?
                                                (<>
                                                    <VariableColumnWrapper key={index}>
                                                        <HighlightedText>{part.text.replace('${', '').replace('}', '')}</HighlightedText>
                                                    </VariableColumnWrapper>
                                                </>) :
                                                part.text
                                        )}
                                    </SlackBodyText>
                                </Box>

                            </Box>
                        </CardBoxWrapper>
                    )
                })
            }
        </Box>
    )
}

export default memo(MonitorListDetailPage);