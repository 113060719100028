export const ChoiceNodeCondition:string[] = [
    "Conditions are checks/validations that will be executed during transition.",
    "Conditions will be executed in  sequence. In case you need a rearrange, use the arrow next to them "
]

export const ActionTooltipContent:string[] = [
    "Actions help you run automations when user triggers the transition. For example, send an email when user clicked ‘Next’."
]

export const  CollectLocationContent: string[] = [
   "When enabled, collect location of the app user at this transition. Note: Location needs to be stored to datafields using “Update Datafields” action."
]

export const RelationCreateOrEdit: string[] = [
    "Choose the data you want to display here by setting a Display column. Learn more"
]

export const DataFieldContent: string[] = [
    "Datafields are data variables that can be used to store the information for the Task. You can think of datafields like database for this workflow"
]

export const AssignActionTooltipContent: string[] = [
    `Configure Actions that get triggered when a user is Assigned to a task`
]

export const UnassignActionTooltipContent: string[] = [
    `Configure Actions that get triggered when a user is Unassigned to a task`
]

export const UpdateSlotActionTooltipContent: string[] = [
    `Configure Actions that get triggered when a Time slot of a task is changed from the Dashboard's "CHANGE SLOT" option.`
]

export const CreateTaskActionTooltipContent: string[] = [
    `Configure Actions that get triggered when a Task is created. This can be from the Single Task, Bulk Task or Zorp API task creation.`
]

export const UpdateTaskActionTooltipContent: string[] = [
    `Configure Actions that get triggered when a Task is edited from the Dashboard using the "UPDATE TASK" option.`
]

export const DeleteTaskActionTooltipContent: string[] = [
    `Configure Actions that get triggered when a Task is deleted`
]

export const AssignCondititonTooltipContent: string[] = [
    `This condition is triggered when a user is Assigned to a task. Adding any condition will validate if user can be assigned to task`
]

export const UnassignConditionTooltipContent: string[] = [
    `This condition is triggered when a user is Unassigned to a task. Adding any condition will validate if user can be unassigned to task`
]

export const UpdateSlotConditionTooltipContent: string[] = [
    `Add conditions to restrict when a Time slot of a task is changed from the Dashboard's "CHANGE SLOT" option.`
]

export const CreateTaskConditionTooltipContent: string[] = [
    `Add conditions to restrict when a Task is created. This can be from the Single Task, Bulk Task or Zorp API task creation.`
]

export const UpdateTaskConditionTooltipContent: string[] = [
    `Add conditions to restrict when a Task is updated`
]

export const DeleteTaskConditionTooltipContent: string[] = [
    `Add conditions to restrict when a Task is deleted`
]

export const ConditionsTooltipContent: string[] = [
    "Conditions are rules that need to pass to let the user proceed to the next step. You can use conditions to check if the user has done their task in the correct manner."
]