import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  errors:false
};

export const ValidWorkflowStateSlice = createSlice({
  name: 'loading',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

    setError: (state,action) => {
      state.errors = action.payload
    }
  },
  extraReducers: (builder) => {},
});

export const { setError } = ValidWorkflowStateSlice.actions;

export default ValidWorkflowStateSlice.reducer;
