import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import ZSelect from "views/commonComponents/ZSelect";
import { ZDropdown } from "views/commonComponents/commonComponents.styles";
import {
  CurlModal,
  GPTDiv,
} from "views/workflows/workflowactions/api/APIAction/ImportCurl/CurlStyledComponents";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import { Modal } from "@mui/base";

type Props = {
  taskTypes: string[];
  showTaskTypeSelector: boolean;
  setShowTaskTypeSelector: (show: boolean) => void;
};

const Wrapper = styled(GPTDiv)`
  border-radius: 4px;
`;

const ModalWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 16px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  padding: 8px;
  margin-bottom: 8px;
`;

const MainWrapper = styled.div`
  height: 39vh;
  overflow-y: auto;
`;

const Heading = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  justify-content: flex-start;
  width: 100%;
  gap: 8px;
  border: 1px solid #d0d5dd;
  background: "#FFF";
  padding: 8px 12px;
  position: sticky;
  align-self: stretch;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
`;
const SearchInput = styled.input`
  width: 100%;
  color: #667085;
  font-size: 12px;
  border: none;
  outline: none;
`;

const SelectTableTypeModal = ({
  taskTypes,
  showTaskTypeSelector,
  setShowTaskTypeSelector,
}: Props) => {
  const [options, setOptions] = React.useState<any[]>([]);
  const [value, setValue] = React.useState<any>(null);
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const { control } = useForm();

  useEffect(() => {
    if (taskTypes) {
      const options = taskTypes.map((taskType: any) => {
        return {
          label: taskType.label,
          value: taskType.value,
        };
      });
      setOptions(options);
      setValue(options[0]);
    }
  }, []);

  const filterOptions = (inputValue: string) => {
    const newOptions = taskTypes.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    setOptions(newOptions);
  };

  // debounce filterOptions
  const debouncedFilterOptions = React.useCallback(
    debounce(filterOptions, 500),
    []
  );

  const openWorkflow = (taskType: string) => {
    console.log("open workflow");
    history.push(`/workflows/${taskType}/show`);
  };

  const handleCloseModal = () => {
    setShowTaskTypeSelector(false); // Replace this with your actual code to close the modal
  };

  const handleStopPropagation = (event) => {
    event.stopPropagation();
  };



  return (
    <div
      style={{
        display: showTaskTypeSelector ? "flex" : "none",
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.5)",
        zIndex: 1000,
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={handleCloseModal}
    >
      <Wrapper
        onClick={handleStopPropagation}
        style={{
          height: "50vh",
          width: "50vw",
        }}
      >
        {console.log("SelectTableTypeModal", taskTypes)}
        <ModalWrapper>
          <div
            style={{
              width: "100%",
            }}
          >
            <div>
              <Heading> Select Workflow </Heading>
            </div>
            <SearchContainer>
              <SearchIcon style={{ margin: "0px", color: "#667085" }} />
              {/* <SearchInput placeholder="Search..." 
            // onChange={
            //   (e) => {
            //     setSearchText(e.target.value);
            //     debouncedFilterOptions(e.target.value);
            //   }
            // } 
            /> */}
              <input
                
                autoFocus
                placeholder="Search..."
                onChange={(e) => {
                  setSearchText(e.target.value);
                  debouncedFilterOptions(e.target.value);
                }}
                style={{
                  width: "100%",
                  color: "#667085",
                  fontSize: "12px",
                  border: "none",
                  outline: "none",
                }}
              />
            </SearchContainer>
            <MainWrapper>
              {options?.map((option: any) => {
                return (
                  <Container>
                    <div
                      onClick={() => {
                        history.push(`/task/${option.value}/create`);
                      }}
                    >
                      <p> {option.label} </p>
                      <p>{option.value}</p>
                      <p onClick={() => openWorkflow(option.value)}>
                        {" "}
                        Open Workflow{" "}
                      </p>
                    </div>
                  </Container>
                );
              })}
            </MainWrapper>
          </div>
        </ModalWrapper>
      </Wrapper>
    </div>
  );
};

export default SelectTableTypeModal;
