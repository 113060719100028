import { FunctionComponent, memo } from "react";
import { useDispatch } from "react-redux";

import { IZChoiceInterface } from "../choice/IZChoiceInterface";
import { ZChoice } from "../choice/ZChoice";
import { ComponentType } from "../ComponentTypeEnum";
import { IZDateTimeInterface } from "../datetime/IZDateTimeInterface";
import { ZDateTime } from "../datetime/ZDateTime";
import { IZInputInterface } from "../input/IZInputIInterface";
import { ZInput } from "../input/ZInput";
import { IZImageViewerInterface } from "../imageviewer/IZImageViewerInterface";
import { ZTeamList } from "../team/ZTeamList";
import { IZTeamInterface } from "../team/IZTeamInterface";
import { IZTextAreaInterface } from "../textArea/IZTextAreaInterface";
import { ZTextArea } from "../textArea/ZTextArea";
import { ZUserList } from "../user/ZUserList";
import { IZUserListInterface } from "../user/IZUserListInterface";
import { IZTextInterface } from "../text/IZTextInterface";
import { ZText } from "../text/ZText";
import { ZSlot } from "../slot/ZSlot";
import { IZSlotInterface } from "../slot/IZSlotInterface";
import { IZOrderConfigInterface } from "../order/IZOrderConfigInterface";
import { ZOrder } from "../order/ZOrder";
import { ZEmpty } from "../empty/ZEmpty";
import { ZLocationInput } from "../location-input/ZLocationInput";
import { IZLocationInputInterface } from "../location-input/IZLocationInputInterface";
import { ZLocationDisplay } from "../location-display/ZLocationDisplay";
import { IZLocationDisplayInterface } from "../location-display/IZLocationDisplayInterface";
import { ZListOfText } from "../listOfText/ZListOfText";
import ZList from "../listDatafield/ZList";
import { IZListInterface } from "../listDatafield/IZListInterface";
import { ZUploader } from "components/uploader/ZUploader";
import { ZUploaderSign } from "components/uploader/ZUploaderSign";
import { IZUploader } from "components/uploader/IZUploader";
import ZBoolean from "components/boolean/ZBoolean";
import { IZBoolean } from "components/boolean/IZBoolean";
import { IZComponentPropType } from "render-engine/models/ITaskTypeWebBodyRenderModel";
import { ZImageViewer } from "components/ZImageViewer/ZImageViewer";
import ZRelation from "components/relation/ZRelation";
import ZRelationShow from "components/relation/ZRelationShow";
import ZListOfTextDropdown from "components/listOfText/ZListOfTextDropdown";
import { IZListOfTextInterface } from "components/listOfText/IZListOfText";
import { ZComment } from "components/comment/ZComment";
import { IZCommentInterface } from "components/comment/IZCommentInterface";
import { ZUploaderV2 } from "components/uploader/ZUploaderV2";
import { ZLocationDisplayV2 } from "components/location-display/ZLocationDisplayV2";
import { ZLocationInputV2 } from "components/location-input/ZLocationInputV2";
import { ZInputV2 } from "components/input/ZInputV2";
import { ZDateTimeV2 } from "components/datetime/ZDateTimeV2";
import ZBooleanV2 from "components/boolean/ZBooleanV2";
import { ZTextAreaV2 } from "components/textArea/ZTextAreaV2";
import ZUserRelationShow from "components/relation/ZUserRelationShow";
import { ZTextV2 } from "components/text/ZTextV2";
import { ZUploaderSignV2 } from "components/uploader/ZUploaderSignV2";
import { isEmpty } from "lodash";
import { ZLinks } from "components/links/ZLinks";

const ZComponentComponent: FunctionComponent<IZComponentPropType> = ({
  config,
  onChange,
  register,
  errors,
  control,
  isOpenedAsSubfield,
  setValue,
  getValues,
  setError,
  clearErrors,
}) => {
  const dispatch = useDispatch();
  if (!config) return <></>;

  if (typeof config.isVisible === "function" && config.isVisible())
    config.isVisible = config.isVisible();
  else if (typeof config.isVisible === "boolean" && config.isVisible) {
    // do nothing
  } else if (
    typeof config.isVisible === "string" &&
    new Function(config.isVisible)()
  )
    config.isVisible = new Function(config.isVisible)();

  // const handleChange = (key: string, val: any) => {
  //     dispatch(setValue({ key: key, value: val }));
  // }

  const otherComponentParams: any = {
    onChange: onChange,
    register: register,
    errors: errors,
    control: control,
    isOpenedAsSubfield,
    setValue: setValue,
    getValues: getValues,
    setError: setError,
    clearErrors: clearErrors,
  };

  const displayNode = (node: any) => {
    switch (node?.type) {
      case ComponentType.INPUT:
        return (
          <ZInputV2
            config={node as IZInputInterface}
            {...otherComponentParams}
          ></ZInputV2>
        );
      case ComponentType.CHOICE:
        return (
          <ZChoice
            config={node as IZChoiceInterface}
            {...otherComponentParams}
          ></ZChoice>
        );
      case ComponentType.DATETIME:
        return (
          <ZDateTimeV2
            config={node as IZDateTimeInterface}
            {...otherComponentParams}
          ></ZDateTimeV2>
        );
      case ComponentType.IMAGEVIEWER:
        if (node?.dataType === ComponentType.SIGNATURE) {
          return (
            <ZUploaderSignV2
              config={node as IZImageViewerInterface}
              {...otherComponentParams}
            ></ZUploaderSignV2>
          );
        } else {
          return (
            <ZUploaderV2
              config={node.editConfig as IZImageViewerInterface}
              {...otherComponentParams}
            ></ZUploaderV2>
          );
        }

      case ComponentType.LIST:
        return (
          <ZList
            config={node as IZListInterface}
            {...otherComponentParams}
          ></ZList>
        );
      // case ComponentType.SIGNATURE:
      //   return (
      //     <ZLink
      //       config={node as IZImageViewerInterface}
      //       {...otherComponentParams}
      //     ></ZLink>
      //   );
      case ComponentType.TEAMLIST:
        return (
          <ZTeamList
            config={node as IZTeamInterface}
            {...otherComponentParams}
          ></ZTeamList>
        );
      case ComponentType.USERLIST:
        return (
          <ZUserList
            config={node as IZUserListInterface}
            {...otherComponentParams}
          ></ZUserList>
        );
      case ComponentType.ORDER:
        return (
          <ZOrder
            config={node as IZOrderConfigInterface}
            {...otherComponentParams}
          ></ZOrder>
        );
      case ComponentType.TEXTAREA:
        return (
          <ZTextAreaV2
            config={node as IZTextAreaInterface}
            {...otherComponentParams}
          ></ZTextAreaV2>
        );
      case ComponentType.TEXT:
        return (
          <ZTextV2
            config={node as IZTextInterface}
            {...otherComponentParams}
          ></ZTextV2>
        );
      case ComponentType.SLOT:
        return (
          <ZSlot
            config={node as IZSlotInterface}
            {...otherComponentParams}
          ></ZSlot>
        );
      case ComponentType.EMPTY:
        return <ZEmpty></ZEmpty>;
      case ComponentType["LOCATION-INPUT"]:
        return (
          <ZLocationInputV2
            config={node as IZLocationInputInterface}
            {...otherComponentParams}
          ></ZLocationInputV2>
        );
      case ComponentType["LOCATION-DISPLAY"]:
        return (
          <ZLocationDisplayV2
            config={node as IZLocationDisplayInterface}
            {...otherComponentParams}
          ></ZLocationDisplayV2>
          // <ZLocationInput
          //     config={node.editConfig as IZLocationInputInterface}
          //     {...otherComponentParams}
          //   ></ZLocationInput>
        );
      case ComponentType.IMAGE:
        return (
          <ZUploaderV2
            config={node as IZUploader}
            {...otherComponentParams}
          ></ZUploaderV2>
        );
      case ComponentType.FILE:
        return (
          <ZUploaderV2
            config={node as IZUploader}
            {...otherComponentParams}
          ></ZUploaderV2>
        );
      case ComponentType.SIGNATURE:
        return (
          <ZUploaderSignV2
            config={node as IZUploader}
            {...otherComponentParams}
          ></ZUploaderSignV2>
        );
      case ComponentType.BOOLEAN:
        return (
          <ZBooleanV2
            config={node as IZBoolean}
            {...otherComponentParams}
          ></ZBooleanV2>
        );
      case ComponentType.RELATION:
        // if (config.shape === "USER") {
        //   return (
        //     <ZUserRelationShow
        //       config={node as IZBoolean}
        //       {...otherComponentParams}
        //     />
        //   )
        // } else {
        //   return (
        //     <ZRelationShow
        //       config={node as IZBoolean}
        //       {...otherComponentParams}
        //     ></ZRelationShow>
        //   )
        // }
        return (
          <ZRelation
            config={node as IZBoolean}
            {...otherComponentParams}
          ></ZRelation>
        );
      case ComponentType.RELATION_SHOW:
        if (config.shape === "USER" || config.shape === "TEAM") {
          return (
            <ZUserRelationShow
              config={node as IZBoolean}
              {...otherComponentParams}
            />
          );
        } else {
          if(isEmpty(config?.value?.colDef)) return <></>
          return (
            <ZRelationShow
              config={node as IZBoolean}
              {...otherComponentParams}
            ></ZRelationShow>
          );
        }

      case ComponentType["LIST_OF_TEXT"]:
        if (node?.shape === "DROPDOWN") {
          return (
            <ZListOfTextDropdown
              config={node as IZListOfTextInterface}
              {...otherComponentParams}
            ></ZListOfTextDropdown>
          );
        }
        if (node?.shape === "") {
          return (
            <ZListOfText
              config={node as IZInputInterface}
              {...otherComponentParams}
            ></ZListOfText>
          );
        }
        break;
      case ComponentType.COMMENT:
        //return <ZComment config={node as IZCommentInterface} {...otherComponentParams}></ZComment>;
        break;
      case ComponentType.LINKS:
        return (
          <ZLinks config={node as IZInputInterface} {...otherComponentParams}></ZLinks>
        )
      default:
        console.error("invalid component");
        return;
    }
  };

  return <>{displayNode(config)}</>;
};

export const ZComponentV2 = memo(ZComponentComponent);
