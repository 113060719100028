import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import * as React from 'react';

//@ts-ignore
export const WarningTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
 
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor:'#F3901C',
      color: '#ffffff',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(14),
      border: '1px solid #dadde9',
      borderRadius: '8px',
    },
  })
);
