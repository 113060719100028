import React from 'react'
import styled from "styled-components"
import Lottie from "lottie-react";
import ZNotFound from "./ZNotFound.json"

const Quote = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top:-2rem;
    
    p {

        span {
            font-size:18px;
            font-weight:600;
            color:black;
        }
        font-size:14px;
        font-weight:600;
        color:rgba(0, 0, 0, 0.6);
        width:25%;
        text-align:center;
        margin-top:1rem;
       
    }
`

const Image = styled.div`
    height: inherit;
    width: inherit;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top:4rem;

    div > svg {
        height:35% !important;
        width:70% !important;
    }
`
function randomIntFromInterval(min:number, max:number):number { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

const NotFound = () => {
  return (
        <div>
            <Image>
                <Lottie 
                style={{display:"flex",justifyContent:"center"}} 
                loop={true} 
                autoplay={true} 
                animationData={ZNotFound} 
                />
            </Image>
            <Quote>
                <p>
                    <span> The page you were looking for does not exist. </span> <br/>
                    <br/><br/>
                    Sorry we couldn't find this page.But don't worry, you can find 
                    plenty of other things by navigating our sidebar on the left. 
                    
                </p>
            </Quote> 
        </div>
  )
}

export default NotFound 