import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { IInitState, ITable } from "../types";
import { EntityService } from "providers/data/services/EntityService";
import notification from "notifications/notifications";
const initialState: IInitState = {
  entities: undefined,
  selectedEntity: null,
  showCreateEntityModal: false,
  entityPermissions: [],
  finalEntityPermissions: [],
  permissionConfiguration: [],
  savedViewChanges: {},
  selectedViewId: "",
  showColumnCreationModal: false,
  activeEditColumn: null,
  activeCreateColumn: null,
  selectedRow: [],
  selectedEntityMetadata: null,
  entityAutomations: [],
  selectedActionColumn: null,
  selectedEntityData: null,
  rowsAfterAnyOps: [],
  showEntityEditModal: false,
  showTableAppConfigCard: false
};

export const initEntities = createAsyncThunk("entity/initEntity", async (tableType?: string) => {
  try {
    const entities: any = await EntityService.getEntities();
    return { entities, tableType: tableType };
  } catch (error) {
    console.log(error);
    notification("error", error?.response?.data?.message || "Something went wrong")
    return { entities: [] };
  }
});

const fetchEntityMetadata = async (tableType: string) => {
  try {
    if (tableType) {
      const entityMetadata: any = await EntityService.getEntityMetadata(
        tableType
      );
      return { entityMetadata: entityMetadata?.data };
    }
  } catch (error) {
    console.log(error);
  }
};

export const getEntityMetadata = createAsyncThunk(
  "entity/getEntityMetadata",
  async (tableType: string) => {
    const entityMetadata = await fetchEntityMetadata(tableType);
    return entityMetadata;
  }
);

export const EntityReducers = createSlice({
  name: "entity",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetEntity: (state) => {
      return initialState;
    },
    setSelectEntity: (state, action) => {
      state.selectedEntity = action.payload;
      state.selectedEntityMetadata = null;
      state.selectedActionColumn = null;
      state.selectedEntityData = null;
    },
    setShowCreateEntityModal: (state, action) => {
      state.showCreateEntityModal = action.payload;
    },
    setEntityPermissions: (state, action) => {
      state.entityPermissions = action.payload;
    },
    setFinalEntityPermissions: (state, action) => {
      state.finalEntityPermissions = action.payload;
    },
    setpermissionConfiguration: (state, action) => {
      state.permissionConfiguration = action.payload;
    },
    setSavedViewChanges: (state, action) => {
      state.savedViewChanges = action.payload;
    },
    setSelectedViewId: (state, action) => {
      state.selectedViewId = action.payload;
    },
    setSelectedRow: (state, action) => {
      state.selectedRow = action.payload;
    },
    setShowCreationModal: (state, action) => {
      state.showColumnCreationModal = action.payload;
    },
    setActiveEditColumn: (state, action) => {
      state.activeEditColumn = action.payload;
    },
    setShowTableAppConfigCard: (state, action) => {
      state.showTableAppConfigCard = action.payload;
    },
    setSelectedActionColumn: (state, action) => {
      state.selectedActionColumn = action.payload;
    },
    setEntityAutomations: (state, action) => {
      //@ts-ignore
      state.selectedEntityMetadata.tableMetadata.automations.systemAutomation.uiAutomations =
        action.payload;
    },
    setActiveCreateColumn: (state, action) => {
      state.activeCreateColumn = action.payload;
    },
    setShowEntityEditModal: (state, action) => {
      state.showEntityEditModal = action.payload;
    },
    setColumnActionAutomation: (state, action) => {
      const columnName = action.payload.columnName;
      state.selectedEntityMetadata.tableMetadata.automations.actions[
        columnName
      ].uiAutomations = action.payload.automations;
      state.selectedActionColumn.uiAutomations = action.payload.automations;
    },
    setSelectedEntityData: (state, action) => {
      state.selectedEntityData = action.payload;
    },
    setRowsAfterAnyOps: (state, action) => {
      state.rowsAfterAnyOps = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(initEntities.fulfilled, (state, action) => {
        state.entities = action.payload.entities;
        // tableType from localStorage
        const tableTypeFromLocalStorage = localStorage.getItem("tableType");
        // state.selectedEntity = null;
        if (action.payload.entities && action.payload.entities.length > 0) {

          const currTableType = action.payload.tableType || tableTypeFromLocalStorage;
          const tableTypeInfo = action.payload.entities.find((entity: ITable) => entity.tableType === currTableType);
          if (tableTypeInfo) {
            state.selectedEntity = tableTypeInfo;
          } else {
            state.selectedEntity = action.payload.entities[0];
            localStorage.setItem("tableType", action.payload.entities[0].tableType)
          }
          // if(action.payload.tableType){
          //   state.selectedEntity = action.payload.entities.find((entity:ITable)=>entity.tableType === action.payload.tableType);
          // }else{
          //   state.selectedEntity = action.payload.entities[0];
          // }   
          // change url to /table/${selectedEntity.tableType}
          // const query = useQuery();
          // window.location.href = `/#/table/${state?.selectedEntity.tableType}`;

        }
      })
      .addCase(getEntityMetadata.fulfilled, (state, action) => {
        if (action.payload) state.selectedEntityMetadata = action.payload.entityMetadata;
      });
  },
});

export const {
  resetEntity,
  setSelectedRow,
  setSelectEntity,
  setShowCreateEntityModal,
  setEntityPermissions,
  setFinalEntityPermissions,
  setpermissionConfiguration,
  setSavedViewChanges,
  setSelectedViewId,
  setShowCreationModal,
  setActiveEditColumn,
  setActiveCreateColumn,
  setEntityAutomations,
  setSelectedActionColumn,
  setColumnActionAutomation,
  setSelectedEntityData,
  setRowsAfterAnyOps,
  setShowEntityEditModal,
  setShowTableAppConfigCard
} = EntityReducers.actions;

export default EntityReducers.reducer;