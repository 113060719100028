import { InfoTooltip } from 'views/workflows/Tootltips/InfoTooltip';
import React, {useState, useRef, memo} from 'react'

interface OverflowCellProps {
    value: string;
    color?: string;
  }
  
  const OverflowCell= ({ value, color = '#101828' }: OverflowCellProps) => {
    const [isOverflowing, setIsOverflowing] = useState(false);
    const cellRef = useRef(null);
  
    const handleMouseEnter = () => {
      if (cellRef.current) {
        const { clientWidth, scrollWidth } = cellRef.current;
        setIsOverflowing(scrollWidth > clientWidth);
      }
    };
  
    return (
      <InfoTooltip placement="right" title={isOverflowing ? value : ''} arrow>
        <span
          ref={cellRef}
          onMouseEnter={handleMouseEnter}
          style={{
            padding: "4px 6px",
            display: "inline-block",
            textOverflow: "ellipsis", 
            overflow: "hidden", 
            whiteSpace: "nowrap",
            maxWidth: "100%",
            color: color
          }}
        >
          {value}
        </span>
      </InfoTooltip>
    );
  };

  export default memo(OverflowCell);