import { PanelResizeHandle } from "react-resizable-panels";
import styles from "./style.module.css";
import styled from "styled-components";

const DividerTxt = styled.p`
  color: var(--Gray-500, #667085);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
`;

const ResizeHandleOuter = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  margin: 8px 0;
  background-color: #F9FAFB;
`;

const HandleLine = styled.div`
  background-color: #667085;
  height: 3px;
  width: 86px;
  border-radius: 4px;
`;

export default function ResizeHandle({
  className = "",
  id
}: {
  className?: string;
  id?: string;
}) {
  return (
    <PanelResizeHandle
    style={{ backgroundColor: "#F9FAFB" }}
      className={[styles.ResizeHandleOuter, className].join(" ")}
      id={id}
    >
      <ResizeHandleOuter>
        <HandleLine />
        <DividerTxt>Drag the handle to Resize Code Editor</DividerTxt>
        <HandleLine />
      </ResizeHandleOuter>
    </PanelResizeHandle>
  );
}