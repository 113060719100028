import { array } from "joi";

export const getMiddleXAxisPoint = (
  x: number,
  y: number
): { xCenter: number; yUp: number; yDown: number } => {
  let className = "react-flow__edge-textbg";
  let dummyXright = x;
  let dummyXleft = x;

  // iterate right
  while (true) {
    dummyXright += 1;
    const ele = document.elementFromPoint(dummyXright, y);
    if (!ele?.classList.contains(className)) {
      break;
    }
  }

  // iterate left
  while (true) {
    dummyXleft -= 1;
    const ele = document.elementFromPoint(dummyXleft, y);
    if (!ele?.classList.contains(className)) {
      break;
    }
  }

  let dummyYdown = y;
  let dummyYup = y;

  // move up

  while (true) {
    dummyYdown -= 1;
    const ele = document.elementFromPoint(x, dummyYdown);
    if (!ele?.classList.contains(className)) {
      break;
    }
  }

  // move down

  while (true) {
    dummyYup += 1;
    const ele = document.elementFromPoint(x, dummyYup);
    if (!ele?.classList.contains(className)) {
      break;
    }
  }

  return {
    xCenter: Math.ceil((dummyXright + dummyXleft) / 2),
    yUp: dummyYdown + 10,
    yDown: dummyYup,
  };
};

export const getNextPoint = (
  x: number,
  y: number
): { top: boolean; bottom: boolean , right:boolean, left:boolean} => {
  let className = "react-flow__edge-textbg";
  let onePixelUp = document.elementFromPoint(x, y - 1);
  let onePixelDown = document.elementFromPoint(x, y + 1);
  let onePixelLeft = document.elementFromPoint(x + 1, y);
  let onePixelRight = document.elementFromPoint(x - 2, y);

  return {
    top: onePixelUp?.classList.contains(className) ?? true,
    bottom: onePixelDown?.classList.contains(className) ?? true,
    right: onePixelRight?.classList.contains(className) ?? true,
    left: onePixelLeft?.classList.contains(className) ?? true
  };
};

const breakNameInValidLenght = (part:string):string[] => {
  let arr:string[] = [];
  let startIndex = 0;
  while(startIndex < part.length) {
    arr.push(part.substring(startIndex,startIndex+15))
    startIndex += 15;
  }

  return arr

}


export const getName = (name:string):string => {
  let newName:string = ""
  const parts = name.split(" ")
  parts.forEach((part:string) => {
    if(part.length > 15) {
      let arr = breakNameInValidLenght(part)
      arr.forEach((item,idx) => {
        if(idx !== arr.length-1) {
          newName += item + "- "
        } else {
          newName += item
        }
      })
    } else{
      newName += part
    }
    newName += " "
  })
  newName.trimEnd()

  return newName


}