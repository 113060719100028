import { recursiveHelperSmartComponents } from 'components/listDatafield/utils';
import _, { isEmpty } from 'lodash';
import { addScriptEditorOptions, apiActionJsonBody, setScriptEditorOpen, setScriptEditorPayload} from 'lowcode/state/scriptOpenState';
import { useDispatch, useSelector } from 'react-redux';
import useAutomation from "views/automationBuilder/hooks/useAutomation";

export const useJSEditor = () => {
  const lodashFunctions = _.filter(Object.getOwnPropertyNames(_), (val: any) => {
    return _.isFunction(_[val]);
  });

  const dispatch = useDispatch();
  const { scriptEditorOpen, scriptEditorOptions, userClearedScript,api_json_body,scriptEditorApiPayload } = useSelector(
    (state: any) => state.scriptEditorOpen
  );
  const { isSmartAutomation } = useAutomation();

  const {
    script: initialScript,
    mockData,
    variables,
    copiedPreDefined,
    mockDataContext
  } = useSelector((state: any) => state.scriptEditor);

  const initiateScriptEditor = (action: any) => {
    dispatch(addScriptEditorOptions(action));
  };

  const openJSEditorLayout = (action: any) => {
    initiateScriptEditor(action);
    dispatch(setScriptEditorOpen(true));
  };

  const openJSEditor = (action: any) => {
    dispatch(apiActionJsonBody(action));
  };

  const { task = {}, loggedInUser = {} } = mockData ?? {};
  const { config = {}, editorLocation = '' } = scriptEditorOptions;
  const { script = '', dataType = '' } = config;

  const intelliSense = !isEmpty(variables) ? recursiveHelperSmartComponents({obj:variables, deStructureObj:isSmartAutomation}) : [];

  return {
    initiateScriptEditor,
    scriptEditorOpen,
    setScriptEditorPayload,
    scriptEditorApiPayload,
    initialScript,
    scriptEditorOptions,
    mockData,
    variables,
    task,
    loggedInUser,
    config,
    mockDataContext,
    editorLocation,
    script,
    copiedPreDefined,
    dataType,
    openJSEditorLayout,
    intelliSense,
    lodashFunctions,
    openJSEditor,
    userClearedScript,
    api_json_body
  };
};
