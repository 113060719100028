//@ts-nocheck
import React, { FunctionComponent, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import { Button, ButtonGroup } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ZChoice } from "../choice/ZChoice";
import { IZChoiceInterface } from "../choice/IZChoiceInterface";
import { ComponentType } from "../ComponentTypeEnum";
import { getComponentParams } from "../../render-engine/RenderUtils";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { ProductCategory } from "./ProductCategoryEnum";
import { IZInputInterface } from "../input/IZInputIInterface";
import { ZInput } from "../input/ZInput";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 450,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  addButton: {
    margin: "10px 10px 10px 10px",
    width: "70px",
    float: "right",
  },
}));

const AddProductModal: FunctionComponent<{ onAdd: (p: any) => void, onClose: () => void }> = ({ onAdd, onClose }) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [sku, setSku] = React.useState("");
  const [productCategory, setProductCategory] = React.useState(null);
  const [qty, setQty] = React.useState(null);
  const [productId, setProductId] = React.useState(null);
  const [productName, setProductName] = React.useState("");
  const [productImageUrl, setProductImageUrl] = React.useState("");

  const dispatch = useDispatch();
  const addProductForm = useForm({mode: "onChange"});

  const closeModal = () => {
    onClose();
    setOpen(false);
  };


  const addProduct = async (e: any) => {


    e.preventDefault();
    e.stopPropagation();
    await addProductForm.trigger();
    if (Object.keys(addProductForm.formState.errors).length > 0)
      return;
      const addedProduct: any = {
        productCategory: productCategory,
        sku: sku,
        productId: productId,
        productName: productName,
        qty: qty,
        productImageUrl: productImageUrl,
      };
      if (productCategory === "SERIALISED")
        delete addedProduct.qty;
      else if (productCategory === "NON_SERIALISED") {
        addedProduct.qty = parseInt(addedProduct.qty);
        delete addedProduct.productId;
      }
      onAdd(addedProduct);
      setOpen(false);
      onClose();
    // }
  };

  useEffect(() => {
    setOpen(true);
  }, []);

  const changeHandler = async (setFn: Function, value : any) => {
    setFn(value);
    // await addProductForm.trigger();
  } 

  const productCategoryConfig: IZChoiceInterface = {
    nodeId: `node_productId`,
    key: 'productId',
    type: ComponentType.CHOICE,
    shape: "DROPDOWN",
    label: {
      color: "#E74C3C",
      fontSize: 14,
      isVisible: true,
      val: "Category"
    },
    required: true,
    options: [
      { "label": ProductCategory.SERIALISED, "value": ProductCategory.SERIALISED },
      { "label": ProductCategory.NON_SERIALISED, "value": ProductCategory.NON_SERIALISED }
    ],
    onValidate: () => { },
    onSave: () => { },
  }

  const skuConfig: IZInputInterface = {
    nodeId: `node_sku`,
    key: 'sku',
    type: ComponentType.INPUT,
    shape: "TEXT",
    label: {
      color: "#E74C3C",
      fontSize: 14,
      isVisible: true,
      val: "SKU"
    },
    isRequired: false,
    onValidate: () => { },
    onSave: () => { },
  }

  const productIdConfig: IZInputInterface = {
    nodeId: `node_productId`,
    key: 'productId',
    type: ComponentType.INPUT,
    shape: "TEXT",
    label: {
      color: "#E74C3C",
      fontSize: 14,
      isVisible: true,
      val: "Product Id"
    },
    isVisible : () => { return productCategory === ProductCategory.SERIALISED },
    required: productCategory === ProductCategory.SERIALISED,
    onValidate: () => { },
    onSave: () => { },
  }

  const productNameConfig: IZInputInterface = {
    nodeId: `node_productName`,
    key: 'productName',
    type: ComponentType.INPUT,
    shape: "TEXT",
    label: {
      color: "#E74C3C",
      fontSize: 14,
      isVisible: true,
      val: "Product Name"
    },
    required: false,
    onValidate: () => { },
    onSave: () => { },
  }

  const productQtyConfig: IZInputInterface = {
    nodeId: `node_productQty`,
    key: 'qty',
    type: ComponentType.INPUT,
    shape: "NUMBER",
    label: {
      color: "#E74C3C",
      fontSize: 14,
      isVisible: true,
      val: "Qty"
    },
    isVisible : () => { return productCategory === ProductCategory.NON_SERIALISED },
    required: productCategory === ProductCategory.NON_SERIALISED,
    onValidate: () => { },
    onSave: () => { },
  }

  const productImageUrlConfig: IZInputInterface = {
    nodeId: `node_productImage`,
    key: 'productImageUrl',
    type: ComponentType.INPUT,
    shape: "TEXT",
    label: {
      color: "#E74C3C",
      fontSize: 14,
      isVisible: true,
      val: "Image"
    },
    required: false,
    onValidate: () => { },
    onSave: () => { },
  }


  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={closeModal}
    >
      {/* <ValidatorForm useRef="form" onSubmit={addProduct}> */}
      <div style={modalStyle} className={classes.paper} >
        <form >
          <h3>Add Product</h3>
          <ZChoice
            config={productCategoryConfig}
            {...getComponentParams(dispatch, addProductForm, (k: string,v: any) => changeHandler(setProductCategory, v))}
          />
          <ZInput config={skuConfig}
            {...getComponentParams(dispatch, addProductForm, (k: string,v: any) => changeHandler(setSku, v))}></ZInput>
          <ZInput config={productIdConfig}
            {...getComponentParams(dispatch, addProductForm, (k: string,v: any) => changeHandler(setProductId, v))}></ZInput>
          <ZInput config={productNameConfig}
            {...getComponentParams(dispatch, addProductForm, (k: string,v: any) => changeHandler(setProductName, v))}></ZInput>
          <ZInput config={productQtyConfig}
            {...getComponentParams(dispatch, addProductForm, (k: string,v: any) => changeHandler(setQty, v))}></ZInput>
          <ZInput config={productImageUrlConfig}
            {...getComponentParams(dispatch, addProductForm, (k: string,v: any) => changeHandler(setProductImageUrl, v))}></ZInput>
          
          <ButtonGroup size="small" variant="outlined">
            <Button
              onClick={(e) => addProduct(e)}
              // type="submit"
              style={{
                height: "40px",
                marginRight: "10px",
                width: "90px",
                marginTop: "10px",
              }}
              variant="contained"
              color="primary"
            >
              Add
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              variant="contained"
              onClick={closeModal}
              style={{ height: "40px", width: "90px", marginTop: "10px" }}
              color="secondary"
            >
              Cancel
            </Button>
          </ButtonGroup>
        </form>
      </div>
      {/* </ValidatorForm> */}
    </Modal>

  );
}

export default AddProductModal;
