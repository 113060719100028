import {
  Button,
  ButtonGroup,
  Grid,
  makeStyles,
  Paper,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Location from "../../../components/Location";
// import InventoryStoreLocation from './components/InventoryStoreLocation';
import Order from "../../../components/Order";
import TaskType from "../../../components/Tasktype";
import TimeSlotSelection from "../../../components/TimeSlotSelection";
import { TaskService as tSvc2 } from "../../../services/TaskService";
import { TaskService as tSvc1 } from "../../../../../providers/data/services/TaskService";
import notification from "../../../../../notifications/notifications";
import authProvider from "../../../../../providers/auth/authProvider";
import { ValidatorForm } from "react-material-ui-form-validator";
import moment from "moment";
import { doesTaskTypehavePickUpLocation, isNonInventoryTaskType } from "../../../../../utils/TasktypeUtils";
import { PageCategoryEnum, recordPageVisit } from "../../../../../config/Rudderstack";
import {getTaskTypeFields} from '../../../components/TaskTypeFlatFileFields';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 2,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    boxShadow: "none",
    backgroundColor: "transparent",
  },
}));

const TaskEdit = (props) => {
  const [userId, setUserId] = React.useState(null);
  const [selectedTaskType, setSelectedTaskType] = React.useState("");
  const [isInventoryTask, setIsInventoryTask] = React.useState(false);

  useEffect(() => {
    recordPageVisit(PageCategoryEnum.task, "task_edit");
  }, [props])

  const [customerInfo, setCustomerInfo] = React.useState({
    'phone': '',
    'phoneCode': '',
    'name': '',
    'address': '',
    'lat': null,
    'lon': null
  });

  const [pickupInfo, setPickupInfo] = React.useState({
    'phone': '',
    'phoneCode': '',
    'name': '',
    'address': '',
    'lat': null,
    'lon': null
  });

  const [order, setOrder] = React.useState(
    {
      'orderId': "",
    //   'delInstructions': "",
      'products': {
        'DELIVERY': [],
        'REPAIR': [],
        'PICKUP': []
      }
    }
  );

  function getOrderJson(task) {
    let taskInfo = task?.data[0].info;
    let orderJson = {
      orderId: taskInfo?.orderId,
      products: {
        DELIVERY: task?.data[0].products?.DELIVERY,
        REPAIR: task?.data[0].products?.REPAIR,
        PICKUP: task?.data[0].products?.PICKUP,
      }
    };

    getTaskTypeFields(task.taskType).forEach((f) => {
      orderJson[f.key] = taskInfo? taskInfo[f.key] : "";
    })

    return orderJson;
  }

  // const [orderInput, setOrderInput] = React.useState({
  //   orderId: "",
  //   deliveryProducts: [],
  //   repairProducts: [],
  //   pickupProducts: [],
  // });
  const [deliverySlot, setDeliverySlot] = React.useState({
    fromDateTime: null,
    toDateTime: null,
  });
  const [initialDeliverySlot, setInitialDeliverySlot] = React.useState({
    fromDateTime: null,
    toDateTime: null,
  });

  const [deliveryError, setDeliveryError] = React.useState(false);
  const history = useHistory();
  const classes = useStyles();
  const handleCustomerInfoChange = (customer) => setCustomerInfo(customer);

  const handlePickupInfoChange = (pickup) => setPickupInfo(pickup);

  const handleDeliverySlotChange = (t) => {
    setDeliverySlot(t);
  };
  const handleOrderChange = (t) => {
    setOrder(t);
  };
  const handleTaskTypeChange = (t) => {
    // Do nothing
  };

  useEffect(() => {
    if (
      deliverySlot?.fromDateTime !== null &&
      deliverySlot?.toDateTime !== null
    ) {
      if (deliverySlot?.fromDateTime < deliverySlot?.toDateTime)
        setDeliveryError(true);
      else setDeliveryError(false);
    } else setDeliveryError(false);
  }, [deliverySlot.fromDateTime, deliverySlot.toDateTime]);

  useEffect(() => {
    tSvc2
      .getTask(props?.record?.id)
      .then((res) => {
        const task = res.data.data;
        if (task?.userId) setUserId(task?.userId);
        setSelectedTaskType(task.taskType);
        if (!isNonInventoryTaskType(task.taskType)) setIsInventoryTask(true);
        setDeliverySlot({
          fromDateTime: new Date(task?.scheduledSlot?.from),
          toDateTime: new Date(task?.scheduledSlot?.to),
        });
        setInitialDeliverySlot({
          fromDateTime: new Date(task?.scheduledSlot?.from),
          toDateTime: new Date(task?.scheduledSlot?.to),
        });

        setCustomerInfo({
          phone: task?.data[0].info?.customer?.phone?.num,
          phoneCode: task?.data[0].info?.customer?.phone?.countryCode,
          name: task?.data[0].info?.customer?.name,
          address: task?.data[0].info?.customer?.location?.address
            ? task?.data[0].info?.customer?.location?.address
            : "",
          lat: task?.data[0].info?.customer?.location?.latitude
            ? task?.data[0].info?.customer?.location?.latitude
            : null,
          lon: task?.data[0].info?.customer?.location?.longitude
            ? task?.data[0].info?.customer?.location?.longitude
            : null,
        });

        setPickupInfo({
          phone: task?.data[0].info?.pickup?.phone?.num,
          phoneCode: task?.data[0].info?.pickup?.phone?.countryCode,
          name: task?.data[0].info?.pickup?.name,
          address: task?.data[0].info?.pickup?.location?.address
            ? task?.data[0].info?.pickup?.location?.address
            : "",
          lat: task?.data[0].info?.pickup?.location?.latitude
            ? task?.data[0].info?.pickup?.location?.latitude
            : null,
          lon: task?.data[0].info?.pickup?.location?.longitude
            ? task?.data[0].info?.pickup?.location?.longitude
            : null,
        });


        setOrder(
          getOrderJson(task)
          //{
          // orderId: task?.data[0].info?.orderId,
          // delInstructions: task?.data[0].info?.delInstructions,
          // products: {
          //   DELIVERY: task?.data[0].products?.DELIVERY,
          //   REPAIR: task?.data[0].products?.REPAIR,
          //   PICKUP: task?.data[0].products?.PICKUP,
          // },
        //}
        );
        // setOrderInput({
        //   orderId: task?.data[0].info?.orderId,
        //   delInstructions: task?.data[0].info?.delInstructions,
        //   deliveryProducts: task?.data[0].products?.delivery,
        //   repairProducts: task?.data[0].products?.repair,
        //   pickupProducts: task?.data[0].products?.pickup,
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const closeTaskEdit = () => {
    notification("info", "Task edit cancelled!");
    history.push("/tasks");
  };

  // Task Edit Final Function
  const submitTaskEdit = () => {

    var scheduleFlag = false;
    var unassign = false;
    var assign = false;

    if (userId) {
      unassign = true;
      assign = true;
    }

    if (
      moment(deliverySlot.fromDateTime).toISOString() !==
      moment(initialDeliverySlot.fromDateTime).toISOString() ||
      moment(deliverySlot.toDateTime).toISOString() !== moment(initialDeliverySlot.toDateTime).toISOString()
    )
      scheduleFlag = true;

    const orderInfo = {
      orderId: order.orderId,
      customer: {
        name: customerInfo["name"],
        location: {
          latitude: customerInfo?.lat === "" ? null : parseFloat(customerInfo?.lat),
          longitude: customerInfo?.lon === "" ? null : parseFloat(customerInfo?.lon),
          address: customerInfo?.address,
        },
        phone: {
          countryCode: customerInfo["phoneCode"],
          num: customerInfo["phone"],
        },
      }
    };

    if (doesTaskTypehavePickUpLocation(selectedTaskType)) {
      orderInfo['pickup'] = {
        name: pickupInfo["name"],
        location: {
          latitude: pickupInfo?.lat === "" ? null : parseFloat(pickupInfo?.lat),
          longitude: pickupInfo?.lon === "" ? null : parseFloat(pickupInfo?.lon),
          address: pickupInfo?.address,
        },
        phone: {
          countryCode: pickupInfo["phoneCode"],
          num: pickupInfo["phone"],
        },
      };
    }

    const taskUpdatePayload = {
      scheduleFlag: scheduleFlag,
      unassign: unassign,
      assign: assign,
      userId: userId,
      taskId: props?.record?.id,
      taskType: selectedTaskType,
      scheduledSlot: {
        from: new Date(deliverySlot.fromDateTime).toISOString(),
        to: new Date(deliverySlot.toDateTime).toISOString(),
      },
      data: [
        {
          ...order,
          info: orderInfo,
        },
      ],
    };

    tSvc2
      .updateTask(taskUpdatePayload)
      .then((res) => {
        if (res?.data.code && res?.data.code !== "200") {
          // Error
          if (res?.code === "401") {
            notification("error", res.message);
            authProvider.logout();
          } else {
            notification("error", res.message);
          }
        } else {
          notification("success", res.data.message);
          history.push("/tasks");
        }
      })
      .catch((err) => {
        console.log("Error", err);
        notification("error", "Task cannot be updated!");
      });
  };

  return (
    <Grid
      direction="column"
      justify="flex-start"
      container
      xs={12}
      className="main-container"
    >
      <h2 className="custom-lf-2 font-bold text-2xl text-md-center">
        {"Edit Task - " + props?.record?.id}
      </h2>
      <br />
      <div className="zorp-card">
        <Grid container className="pl-6">
          <div className="bt-row">
            <div className="bt-col-md-6">
              <div className="bt-row">
                <div className="bt-col-md-4">
                  <strong>Task Type </strong>
                </div>
                <div className="bt-col-md-8">
                  <TaskType onChange={handleTaskTypeChange} disabled={true} type={selectedTaskType} />
                </div>
              </div>
            </div>
          </div>
        </Grid>

        {/* <Grid container className="custom-lf"> */}
        {/* <Grid item >
            <h3 className="mt-7 font-bold">Task Type : </h3>
          </Grid>
          <Grid item>
            <Paper className={classes.paper}>
              <TaskType onChange={handleTaskTypeChange} disabled={true} type={selectedTaskType} />
            </Paper>
          </Grid> */}
        {/* </Grid> */}
      </div>
      <ValidatorForm
        // onSubmit={createTask}
        onError={(errors) => console.log(errors)}
      >
        <Grid container item justify="flex-start">
          <div style={{ width: "100%" }} className="zorp-card mt-2">
            <h3 className="custom-lf"><strong>Scheduled Slot</strong></h3>
            <TimeSlotSelection
              slots={deliverySlot}
              onChange={handleDeliverySlotChange}
              disabled={true}
            ></TimeSlotSelection>
          </div>
        </Grid>

        {doesTaskTypehavePickUpLocation(selectedTaskType) && <Grid container item xs={12} justify="flex-start">
          <div className="zorp-card mt-2" style={{ width: "100%" }}>
            <Location
              info={pickupInfo}
              onChange={handlePickupInfoChange}
              label="Pickup Information"
            ></Location>
          </div>
        </Grid>}

        <Grid container item xs={12} justify="flex-start">
          <div className="zorp-card mt-2" style={{ width: "100%" }}>
            <Location
              info={customerInfo}
              onChange={handleCustomerInfoChange}
              label={doesTaskTypehavePickUpLocation(selectedTaskType) ? "Drop Information" : "Customer Information"}
            ></Location>
          </div>
        </Grid>

        {/* <Grid container item xs={12} justify="flex-start">
                            <h4>Warehouse Location (for pickup)</h4>
                            <InventoryStoreLocation info={warehouseLocation.name} onChange={handleWarehousePickupLocationChange}></InventoryStoreLocation>
                        </Grid> */}

        <Grid container item xs={12} justify="flex-start">
          <div className="zorp-card mt-2">
            <h3 className="custom-lf "><strong>Order Details</strong></h3>
            <Order taskType={selectedTaskType} order={order} isInventoryTask={isInventoryTask} onChange={handleOrderChange}></Order>
          </div>
        </Grid>

        <Grid container item xs={12} justify="flex-start">
          <ButtonGroup size="small" variant="outlined">
            <Button
              onClick={submitTaskEdit}
              variant="contained"
              color="primary"
              style={{
                marginRight: "10px",
                height: "50px",
                width: "120px",
                marginTop: "10px",
              }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              onClick={closeTaskEdit}
              style={{ height: "50px", width: "120px", marginTop: "10px" }}
              color="secondary"
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Grid>
      </ValidatorForm>
    </Grid>
  );
};

export default TaskEdit;
