import React, { useEffect, useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Select from 'react-select';
import { UserService } from '../../providers/data/services/UserService';
import { TeamValidator } from '../tasks/components/Validator';

export default function UserSelection(props) {
  const [users, setUsers] = useState([]);
  const [usersAll, setUsersAll] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [user, setUser] = useState(props?.driver);
  const [teamId, setTeamId] = useState(props.teamIds ? props.teamIds[0] : null);

  useEffect(() => {
    ValidatorForm.addValidationRule('TeamIdCheck', (value) => {
      if (teamId && teamId.length > 0) {
        return true;
      } else {
        return false;
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    selectUsers(teamId);
  }, [usersAll]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user === '1') setUser(null);
    UserService.userSelect(props?.id)
      .then((res) => {
        var i = 0;
        const drivers_array = [];
        for (i = 0; i < res?.data?.users?.length; i++) {
          const item = res?.data?.users[i];
          const driver_single = {
            id: item.userId,
            name: item.name,
            teamIds: item.teamIds,
          };
          drivers_array.push(driver_single);
        }

        // setDrivers(drivers_array);
        setUsersAll(drivers_array);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleTeamIdChange = (tId) => {
    setTeamId(tId);
    setUser(null);
    props.onChange(tId, null);

    selectUsers(tId);
  };

  const selectUsers = (tId) => {
    // filter the users based on selected team
    if (tId) {
      let users = [];
      usersAll.filter((d) => d.teamIds.includes(tId)).map((d) => users.push(d));
      setUsers(users);
    } else setUsers(usersAll);
  };

  const handleUserChange = (event) => {
    setUser(event?.value);
    props.onChange(teamId, event?.value);
  };

  const containsText = (text, searchText) => text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;


  const usersList = React.useMemo(
    () => users.filter((option) => containsText(option?.name, searchText)),
    [searchText, users] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const finalUsersList = usersList.map((team) => {
    return {
      value: team?.id,
      label: team?.name,
    };
  });

  return (
    <ValidatorForm
      onError={(errors) => console.log(errors)}
    >
      <div className='flex flex-col justify-start	px-1 md:px-2 w-full'>
        <div className='flex flex-col border rounded-xl'>
          <div className='flex flex-col md:flex-row'>
            <div style={{ width: '100%' }} className='w-96 items-center justify-start md:mx-1 rounded-md py-4 px-4'>
              <div>
                <h1 className='font-bold text-left'>Team</h1>
              </div>
              <div style={{ width: "100%" }} className='justify-end user-selection'>
                <TeamValidator
                  id='teamField'
                  onChange={handleTeamIdChange}
                  disabled={false}
                  team={teamId}
                  size='full'
                />
              </div>
            </div>
          </div>
          <div className='flex flex-col md:flex-row'>
            <div className='w-full items-center justify-start md:mx-1 rounded-md py-4 px-4'>
              <div>
                <h1 className='font-bold text-left'>User</h1>
              </div>
              <div className='w-full justify-end user-selection'>
                {
                  (user || finalUsersList) &&
                  <>
                    <Select
                      defaultValue={finalUsersList.find((c) => c.value === user)}
                      value={finalUsersList.find((c) => c.value === user) ?? null}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      menuPortalTarget={document.body}
                      isSearchable
                      isClearable
                      placeholder='Select User'
                      name="select-user"
                      onChange={handleUserChange}
                      menuPosition={"fixed"}
                      menuPlacement={"bottom"}
                      options={finalUsersList}
                    />
                  </>
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    </ValidatorForm>
  );
}
