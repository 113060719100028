import styled from "styled-components";
import Select from "@mui/material/Select";
import { ButtonProps } from "./DataField.types";
import { SingleCellProps } from "./DataField.types";
import TableCell from "@mui/material/TableCell";

export const CollectionContainer = styled.div`
  display: grid;
  grid-template-columns: 25% 40% 40%;
  padding: 10px; 
  border: ${(props:any) => props.variant === "selected" ? `2px solid ${props.theme.color.primary}`  : "2px solid #E1E4E8"};
  border-radius: 4px;
  margin-bottom: 8px;
  margin-top:8px;
  &:hover {
    cursor: pointer;
  }
  background-color: ${(props: any) =>
    props.variant === "selected" ? "#f0f2f3" : "white"};
`;

export const Container = styled.form`
  background-color: white;
  border: 1px solid #eaeaef;
  overflow-y: auto;
  height: 100%;
  position: relative;
  width: ${(props: any) => (props.flutterView ? "30vw" : "")};
  margin-left: ${(props: any) => (props.flutterView ? "37%" : "")};
`;

export const TopDiv = styled.div`
  /* background-color: #E6E4EA;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem; */
  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: #616569;
    padding: 14px 12px;
  }
`;

export const BottomDiv = styled.div`
  padding: 10px 12px;
`;

export const ModalHeading = styled.p`
  font-size: 14px;
  color: #616569;
  font-weight: 600;
  padding: 4px 6px;
`;

export const Heading = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: black;
  font-size: 14px;
  font-weight: 500;
`;

export const InfoDiv = styled.div`
  background-color: #007aff;
  padding: 1rem;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  display: flex;
`;

export const Input = styled.input`
  border: 2px solid #eaeaef;
  border-radius: 8px;
  width: 100%;
  padding: 10px 8px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
`;

export const SingleCell = styled(TableCell)<SingleCellProps>`
  font-weight: ${(props) => (props.type === "header" ? 600 : "normal")};
  font-size: 16px;
`;

export const Label = styled.div`
  color: #10002e;
  /* margin-top: 1rem; */
  font-size: 12px;
  font-weight: 500;
`;

export const SystemTransitionLabel = styled(Label)`
    background-color: #f2f2f2;
    padding: 10px;
    margin-left: -20px;
    margin-right: -20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dee1e3;

    div {
      &:first-of-type {
        color: rgba(0, 0, 0, 0.7);
      }
    }
`

export const SelectType = styled.select`
  width: 100%;
  border: 2px solid #eaeaef;
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 12px;
`;

export const FormFooter = styled.div`
  display: flex;
  position:absolute ;
  justify-content: flex-end;
  bottom: 1rem;
  right: 1rem;
`;

export const Button = styled.button<ButtonProps>`
  background-color: ${(props) =>
    props.variant === "primary"
      ? `${props.theme.color.primary}`
      : props.variant === "danger"
      ? "#F03738"
      : "#f2f2f2"};
  padding: 10px 8px;
  border-radius: 4px;
  margin-right: ${(props) =>
    props.variant === "secondary" || props.variant === "danger"
      ? "15px"
      : "0px"};
  color: ${(props) =>
    props.variant === "primary" || props.variant === "danger"
      ? "white"
      : "#757575"};
  min-width: ${(props: any) => (props.width === "full" ? "100%" : "5rem")};
  border: ${(props) =>
    props.variant === "secondary" ? "1px solid #c2c9d1" : ""};
  line-height: 16px;
  font-size: 12px;
  font-weight: 600;
`;

export const InfoText = styled.p`
  color: #007aff;
  font-size: 14px;
`;

export const HorizontalLine = styled.hr`
  background-color: #f0f2f3;
  border-top-width: 2px;
`;

export const SubFieldContainer = styled.div`
  height: 39vh;
  overflow: auto;
`;

export const EditContainer = styled.form`
background-color: ${(props:any) => props.type === "subfield" ? "#f0f2f3" : "white"};
border: 1px solid #eaeaef;
position: relative ;
/* height: 70vh; */
/* height: 84.5vh; */
`;

export const AddSubfieldText = styled.p`
    color: ${(props) => props.theme.color.primary};
    margin-top: 11px;
    font-weight: 600;
    font-size: 14px;

    &:hover {
      cursor:pointer;
    }
`

