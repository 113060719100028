import jwt_decode from "jwt-decode";
import { recordRSEvent } from "utils/CommonUtils";
// import { Sprig } from 'utils/Sprig.util';
import HttpUtil from '../../config/AxiosConfig';
import { identifyUser, reset } from '../../config/Rudderstack';
import notification from '../../notifications/notifications';
import { RoleService } from '../data/services/RoleService';
import { TeamService } from '../data/services/TeamService';
import { basicAuthLogin, googleOAuthLogin, validateLoginOTP } from './authUtils';
import { UserService } from 'providers/data/services/UserService';
import { fetchAndStoreAccountName, getAuthToken } from 'utils/UserAuth.utils';
import { validate } from 'uuid';
import { Redirect, useHistory } from "react-router-dom"



const authProvider = {
  login: (loginParams) => {
    const fn =
      loginParams.loginType === "googleOauth"
        ? googleOAuthLogin
        : basicAuthLogin;

    return fn(loginParams.param1, loginParams.param2).then(async (response) => {
      if (!response || response.code !== "200") {
        throw new Error(
          response.message ||
            "Error while verifying the credentials. Pls try again!"
        );
      }

      if(response?.data?.twoFA?.enabled == true){
        return { redirectTo: '/#/reset-password', twoFA: response?.data?.twoFA }
      }

      // Add the Tokens to Axios Config here only!
      // Axios Auth Headers set Bug.
      localStorage.setItem("at", response.data.at);
      localStorage.setItem("rt", response.data.rt);

      var decodedToken = jwt_decode(response.data.at);

      // Store permissions/acl info in local storage
      localStorage.setItem("permissions", JSON.stringify(response.data.acl));

      // fetch account name and store in local storage
      await fetchAndStoreAccountName();

      return authProvider
        .getPermissions()
        .then((permissions) => {
          identifyUser(decodedToken.userId);
          TeamService.listTeams({
            filter: {},
            pagination: { page: 1, perPage: 100 },
          })
            .then((res) => {
              if (res?.data?.teams) {
                localStorage.setItem("teams", JSON.stringify(res?.data?.teams));
              }
            })
            .catch((err) => {
              console.error(err);
            });

          RoleService.listRoles(false)
            .then((response) => {
              if (response?.data) {
                localStorage.setItem("roles", JSON.stringify(response?.data));
              }
            })
            .catch((err) => {
              console.error(err);
            });

          HttpUtil.taskInstance({});
          HttpUtil.bffInstance({});
          HttpUtil.userInstance({});

          return { redirectTo: "/table" };
        })
        .catch(() => {
          notification("error", "You dont have permissions to control center");
          return { redirectTo: false };
          // throw new Error('You dont have permissions to control center')
        });
    });
  },




  validateLoginOTP: (otpParams) => {

    const fn = validateLoginOTP;

    return fn(otpParams.userId, otpParams.otpCode)
      .then(async (response) => {
        if (!response || response.code !== '200') {
          throw new Error(response.message || 'Error while verifying the Code. Pls try again!');
        }

      
        // Add the Tokens to Axios Config here only!
        // Axios Auth Headers set Bug.
        localStorage.setItem('at', response.data.at);
        localStorage.setItem('rt', response.data.rt);

        var decodedToken = jwt_decode(response.data.at);

        // Store permissions/acl info in local storage
        localStorage.setItem('permissions', JSON.stringify(response.data.acl));
          
        // fetch account name and store in local storage
        await fetchAndStoreAccountName();
        
        return authProvider
          .getPermissions()
          .then((permissions) => {
            identifyUser(decodedToken.userId);
            TeamService.listTeams({ filter: {}, pagination: { page: 1, perPage: 100 } })
              .then((res) => {
                if (res?.data?.teams) {
                  localStorage.setItem('teams', JSON.stringify(res?.data?.teams));
                }
              })
              .catch((err) => {
                console.error(err);
              });

            RoleService.listRoles(false)
              .then((response) => {
                if (response?.data) {
                  localStorage.setItem('roles', JSON.stringify(response?.data));
                }
              })
              .catch((err) => {
                console.error(err);
              });

            HttpUtil.taskInstance({});
            HttpUtil.bffInstance({});
            HttpUtil.userInstance({});

            return { redirectTo: '/table', twoFA: response?.data?.twoFA }
          })
          .catch(() => {
            notification('error', 'You dont have permissions to control center');
            return {redirectTo: false}
            // throw new Error('You dont have permissions to control center')
          });
      });
  },




  logout: async () => {
    recordRSEvent("logout_event_success", {
      context: "logout",
    });

    // Call user logout
    const userId = getAuthToken()?.userId;
    await UserService.logoutUser(userId);

    localStorage.removeItem("at");
    localStorage.removeItem("rt");
    localStorage.removeItem("permissions");
    localStorage.removeItem("teams");
    localStorage.removeItem("roles");
    localStorage.removeItem("tableType");

    localStorage.removeItem("redirectUrl");
    localStorage.removeItem("redirectUrl-ttl");

    // remove account Id
    localStorage.removeItem("accountName");
    // remove templateId
    localStorage.removeItem('templateId');
    // remove templateId-ttl
    localStorage.removeItem('templateId-ttl');
    localStorage.removeItem('t'); 
    // HttpUtil.taskInstance({});
    // HttpUtil.bffInstance({});
    // HttpUtil.userInstance({});

    // Sprig.logoutUser();
    // Sprig.removeAttributes(['ROLE', 'FULLNAME']);

    // remove crisp clear session
    try {
      window.$crisp.push(["do", "session:reset"]);
      // clear rudderstack info
      reset();
    } catch (e) {
      console.log("error while clearing crisp session");
    }

    return Promise.resolve();
  },

  checkError: ({ status }) => {
    // if (status === 401 || status === 403) {
    //   localStorage.removeItem('at');
    //   localStorage.removeItem('rt');
    //   localStorage.removeItem('permissions');
    //   localStorage.removeItem('teams');
    //   return Promise.reject();
    // }
    // return Promise.resolve();
  },

  checkAuth: () => {
    // whitelist signup route
    if (
      window.location.href.includes("signup") ||
      window.location.href.includes("account-setup") ||
      window.location.href.includes("/awaiting-verification")
    ) {
      return Promise.resolve();
    } else {
      window.location.href.split("?templateId=");
      return !!localStorage.getItem("at")?.length
        ? authProvider.getPermissions()
        : Promise.reject();
    }
  },

  getPermissions: () => {
    if (
      window.location.href.includes("signup") ||
      window.location.href.includes("account-setup") ||
      window.location.href.includes("/awaiting-verification")
    ) {
      return Promise.resolve();
    } else {
      const role = localStorage.getItem("permissions");
      // Check if the user has access to controlcenter. This is determined by "controlcenter-access" action
      if (role) {
        const permissions = JSON.parse(role);
        if (
          permissions &&
          permissions.resourceActions.filter(
            (p) =>
              (
                p.resource === "others" &&
                p.actions.filter((a) => a.id === "controlcenter-access")
              ).length > 0
          ).length > 0
        ) {
          return Promise.resolve(JSON.parse(role));
        } else return Promise.reject();
      }
      return Promise.resolve([]);
    }
  },
  // GET FIRST NAME
  getIdentity: () => {
    let decodedAt =
      !!localStorage.getItem("at")?.length &&
      jwt_decode(localStorage.getItem("at"));
    return Promise.resolve({
      id: decodedAt.userId,
      fullName: decodedAt.name?.split(" ")[0].substring(0, 10),
    });
  },
};

export default authProvider;
