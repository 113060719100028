import { makeStyles } from '@material-ui/core/styles';
import { TabContext } from '@mui/lab';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { PageCategoryEnum, recordPageVisit } from 'config/Rudderstack';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ZTabList } from 'views/commonComponents/commonComponents.styles';
import CronAutomation from './CronAutomation';
import WebhookAutomation from './WebhookAutomation';


function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    useEffect(() => {
        recordPageVisit(PageCategoryEnum.settings, 'settings');
    }, [props]);

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        // padding: "32px"
    },
}));

const style = {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '14px',
};
interface IAccountAutomationMainPage {
    handleTabChange:()=> void;
}
const AccountAutomationMainPage = ({handleTabChange}:IAccountAutomationMainPage) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event:React.SyntheticEvent, newValue:any) => {
        setValue(newValue)
    };

    return (
        <div className={classes.root}>
            <TabContext value={value}>
                <ZTabList sx={{ overflow: "auto" }} onChange={handleChange}>
                    <Tabs
                        value={value}
                        aria-label="basic tabs example"
                        TabIndicatorProps={{ style: { background: "black" } }}>
                        <Tab sx={style} disableRipple label="Scheduled Automation" />
                        <Tab sx={style} disableRipple label="Webhook Automation" />
                    </Tabs>
                </ZTabList>
                <hr />
                <TabPanel value={value} index={0}>
                       <CronAutomation onTabChange={()=>{
            handleTabChange();
            }}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                <WebhookAutomation/>
                </TabPanel>

            </TabContext>
        </div>
    )
}

export default AccountAutomationMainPage