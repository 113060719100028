import { Typography } from "@mui/material";
import infoIconWebhook from "assests/info_icon_webhook.svg";
import WebhookCopyIcon from "assests/webhook_copy.svg";
import notification from "notifications/notifications";
import styled from "styled-components";
import { getAccountId } from "utils/CommonUtils";
import { getConfig as Config } from "../../../config/envConfig/Config";
import useAutomation from "../hooks/useAutomation";

const Header = styled.p`
  color: rgba(0, 0, 0, 0.8);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`;

const Label = styled.p`
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-top: 4px;
  margin-bottom: 24px;
`;

const TextLabel = styled.p`
  color: var(--Gray-700, #344054);
  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  margin-top: 16px;
`;

const TextFieldWrapper = styled.div`
  border-radius: 4px 0px 0px 4px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Gray-100, #f2f4f7);
  padding: 8px 8px 8px 12px;
  height: 36px;
  display: flex;
  justify-content: space-between;
  width: 75%;
`;

const CopyLinkWrapper = styled.div`
  border-radius: 0px 4px 4px 0px;
  border-left: 0px solid var(--Gray-300, #d0d5dd);
  border-top: 1px solid var(--Gray-300, #d0d5dd);
  border-right: 1px solid var(--Gray-300, #d0d5dd);
  border-bottom: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  height: 36px;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  width: 25%;
  cursor: pointer;
`;

const WholeWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const WebhookTrigger = () => {
  const accountId = getAccountId();
  const { selectedWebhook } = useAutomation();

  const webhookhost = (Config("services.bffSvc.host") as string).replace(
    "/bff-service",
    ""
  );

  const webhookUrl = `${webhookhost}/webhook/${accountId}/${selectedWebhook.webhookSlug}`;

  const webhookCurl = `curl --location '${webhookUrl}' \
  --header 'Content-Type: application/json' \
  --data '{
   
  }'`;
  return (
    <div>
      <Header>Webhook Trigger</Header>
      <Label>Triggers when a webhook is received via the configured link</Label>

      <TextLabel>Webhook URL</TextLabel>

      <WholeWrapper>
        <TextFieldWrapper>
          <Typography
            sx={{
              color: "#667085",
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
            }}
            noWrap
          >
            {webhookUrl}
          </Typography>
          <img src={infoIconWebhook} />
        </TextFieldWrapper>
        <CopyLinkWrapper
          onClick={() => {
            navigator.clipboard.writeText(webhookUrl);
            notification("success", "webhook url copied to clipboard");
          }}
        >
          <img height={"20"} width={"20"} src={WebhookCopyIcon} />
          <Typography
            sx={{
              color: "#344054",
              fontFamily: "Inter",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 600,
              paddingLeft: "8px",
            }}
            noWrap
          >
            Copy Link
          </Typography>
        </CopyLinkWrapper>
      </WholeWrapper>

      <TextLabel>Webhook Curl</TextLabel>

      <WholeWrapper>
        <TextFieldWrapper>
          <Typography
            sx={{
              color: "#667085",
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
            }}
            noWrap
          >
            {`${webhookCurl}`}
          </Typography>
          <img src={infoIconWebhook} />
        </TextFieldWrapper>
        <CopyLinkWrapper
          onClick={() => {
            navigator.clipboard.writeText(webhookCurl);
            notification("success", "webhook curl copied to clipboard");
          }}
        >
          <img height={"20"} width={"20"} src={WebhookCopyIcon} />
          <Typography
            sx={{
              color: "#344054",
              fontFamily: "Inter",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 600,
              paddingLeft: "8px",
            }}
            noWrap
          >
            Copy Link
          </Typography>
        </CopyLinkWrapper>
      </WholeWrapper>
    </div>
  );
};
