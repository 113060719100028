import React from "react";
import styled from "styled-components";
import { LogChange } from "../../types/types";
import ChangeDetails from "./ZRecordLogger";

interface LogEntryProps {
  changes: LogChange[];
}

const LogEntry: React.FC<LogEntryProps> = ({ changes }) => {
  return (
    <>
      {changes.map((change, index) => (
        <div key={index}>
          <ChangeDetails {...change} />
        </div>
      ))}
    </>
  );
};

export default LogEntry;
