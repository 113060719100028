import {
  setSelectedAction,
  setShowWorkflowConfigModal,
  setSelectedTransition,
  setSelectedDecisionNode,
  setSelectedNode,
  setShowErrorTooltip,
  setSelectedSystemTransition
} from "views/workflows/reducers/workflowAction";
import notification from "notifications/notifications";
import { ActionToPerform } from "constants/CommonConstants";
import { setActionPayload, setSelectedActionBlock, setSelectedAutomation, setSelectedConfiguration, setSelectedTrigger } from "views/automationBuilder/reducer/automationReducer";

export enum ErrorLocations {
  CHOICE_NODE = "choiceNode",
  NODE = "node",
  ACTION_TRANSITION = "actionTransition",
  CONDITION_TRANSITION = "conditionTransition",
  ACTION_CHOICE = "actionChoice",
  CONDITION_CHOICE = "conditionChoice",
  WORKFLOW = "workflow",
  TRANSITION = "transition",
  CHOICE_NODE_HANDLE = "choiceNodeHandle",
  SYSTEM_TRANSITIONS = "systemTransitions",
  HOME_NODE = "homeNode",
  TERMINAL_NODE = "terminalNode",
  GENERAL = 'general',
  AUTOMATION_NAME = "automationName",
  AUTOMATION_ACTION = "automationAction",
  AUTOMATION_ACTION_BLOCK = "automationActionBlock",

  AUTOMATION_TRIGGER = "automation_trigger",
  AUTOMATION_SC_TRIGGER = 'automation_sc_trigger',

  AUTOMATION_GUARD = 'expression_guard',
  AUTOMATION_LOC_GUARD = "automation_location_guard",

  AUTOMATION_EXP_CONDITION = 'automation_exp_condition', // Expression Condition
  AUTOMATION_SC_CONDITION = 'automation_sc_condition', // Smart Condition

  AUTOMATION_API_ACTION = 'automation_api_action', // API Action
  AUTOMATION_PUSH_ACTION = 'automation_push_action', // Push Notification Action
  AUTOMATION_UDF_ACTION = 'automation_update_data_field_action', // Update data field Action
  AUTOMATION_SC_ACTION = 'automation_sc_action', // smart component Action
  AUTOMATION_JF_ACTION = 'automation_jf_action' // Javascript Function Action
}

export interface IAutomationData {
  selectedAutomationId: string,
  selectedActionBlockId: string,
  automationMetaData: string
}

export const openView = (location: string, payload: any, dispatch: any, automationData: IAutomationData) => {
  dispatch(setShowWorkflowConfigModal(false));
  dispatch(setSelectedTransition(null));
  dispatch(setSelectedDecisionNode(null));
  switch (location) {
    case ErrorLocations.ACTION_TRANSITION:
      dispatch(setSelectedTransition(payload.transition));
      dispatch(setSelectedAction({...payload.action,hasChanged: false, actionToPerform:ActionToPerform.EDIT}));
      break;
    case ErrorLocations.CHOICE_NODE_HANDLE:
      dispatch(setSelectedDecisionNode(payload));
      break;

    case ErrorLocations.CONDITION_CHOICE:
      dispatch(setSelectedDecisionNode(payload.decisionNode));
      dispatch(setSelectedAction({...payload.condition,hasChanged: false, actionToPerform:ActionToPerform.EDIT}));
      break;

    case ErrorLocations.ACTION_CHOICE:
      dispatch(setSelectedDecisionNode(payload.decisionNode));
      dispatch(setSelectedAction({...payload.action,hasChanged: false, actionToPerform:ActionToPerform.EDIT}));
      break;

    case ErrorLocations.CONDITION_TRANSITION:
      dispatch(setSelectedTransition(payload.transition));
      dispatch(setSelectedAction({...payload.condition,hasChanged: false, actionToPerform:ActionToPerform.EDIT}));
      break;

    case ErrorLocations.WORKFLOW:
      dispatch(setShowWorkflowConfigModal(true));
      break;

    case ErrorLocations.TRANSITION:
      dispatch(setSelectedTransition(payload.transition));
      break;

    case ErrorLocations.NODE:
      dispatch(setSelectedNode(payload.nodeData));
      break;

    case ErrorLocations.CHOICE_NODE:
      dispatch(
        setShowErrorTooltip({
          choiceNode: payload.nodeId,
        })
      );

      setTimeout(() => {
        dispatch(
          setShowErrorTooltip({
            choiceNode: "",
          })
        );
      }, 2000);
      break;

    case ErrorLocations.SYSTEM_TRANSITIONS:
      dispatch(setSelectedSystemTransition(payload.systemTransitionInfo))
      dispatch(setShowWorkflowConfigModal(true))
      dispatch(setSelectedAction({ ...payload.data, actionToPerform: ActionToPerform.EDIT }))
      break

    case ErrorLocations.AUTOMATION_ACTION:
      dispatch(setSelectedAutomation(payload.automation));
      dispatch(setSelectedActionBlock({actionBlock:payload.actionBlock}))
      dispatch(setSelectedAction({...payload.action,hasChanged: false, actionToPerform:ActionToPerform.EDIT}));
      break
      
    
    case ErrorLocations.HOME_NODE: 
      notification("error", "Please mark any node as Home Node")
      break

    case ErrorLocations.TERMINAL_NODE:
      notification("error", "Please mark any node as Terminal Node")
      break

    case ErrorLocations.GENERAL:
      notification("error", payload.message);
      break

    case ErrorLocations.AUTOMATION_TRIGGER:
      notification("error", payload.message);
      break

    case ErrorLocations.AUTOMATION_SC_TRIGGER:
      if (automationData.selectedAutomationId && automationData.selectedAutomationId != payload.automation.id) {
        dispatch(setSelectedAutomation(null));
      }

      dispatch(setSelectedTrigger(null));
      dispatch(setSelectedAction(null));
      dispatch(setSelectedConfiguration({ type: null }));
      dispatch(setActionPayload([]));

      setTimeout(() => {
        // we are only setting data for selectedAutomation, only if the selectedAutomation id and the automation id in the error payload is different
        if (payload.automation && (!automationData.selectedAutomationId || automationData.selectedAutomationId != payload.automation.id)) {
          dispatch(setSelectedAutomation(payload.automation));
        }
        dispatch(setSelectedTrigger(payload.trigger));
        dispatch(setSelectedConfiguration({ type: 'trigger' }));
      }, 10)
      break

    case ErrorLocations.AUTOMATION_GUARD:
      case ErrorLocations.AUTOMATION_LOC_GUARD:
      
      if (automationData.selectedAutomationId && automationData.selectedAutomationId != payload.automation.id) {
        dispatch(setSelectedAutomation(null));
      }
      dispatch(setSelectedAction(null));
      dispatch(setSelectedConfiguration({ type: null }));

      setTimeout(() => {
        // we are only setting data for selectedAutomation, only if the selectedAutomation id and the automation id in the error payload is different
        if (payload.automation && !automationData.selectedAutomationId || automationData.selectedAutomationId != payload.automation.id) {
          dispatch(setSelectedAutomation(payload.automation));
        }

        dispatch(setSelectedConfiguration({ type: "guard" }));
        dispatch(setSelectedAction({ ...payload.action,hasChanged:false, actionToPerform: ActionToPerform.EDIT }));
      }, 10)

      break

    case ErrorLocations.AUTOMATION_EXP_CONDITION:
    case ErrorLocations.AUTOMATION_SC_CONDITION:

      if (automationData.selectedAutomationId && automationData.selectedAutomationId != payload.automation.id) {
        dispatch(setSelectedAutomation(null));
      }

      dispatch(setSelectedActionBlock({ actionBlock: null }));
      dispatch(setSelectedConfiguration({ type: null }));
      dispatch(setSelectedAction(null));
      dispatch(setActionPayload([]));

      setTimeout(() => {
        // we are only setting data for selectedAutomation, only if the selectedAutomation id and the automation id in the error payload is different
        if (payload.automation.id && !automationData.selectedAutomationId || automationData.selectedAutomationId != payload.automation.id) {
          dispatch(setSelectedAutomation(payload.automation));
        }

        dispatch(setSelectedActionBlock({ actionBlock: payload.actionBlock }));
        dispatch(setSelectedConfiguration({ type: "condition" }));
        dispatch(setSelectedAction({ ...payload.action, actionToPerform: ActionToPerform.EDIT }));
      }, 10)
      break

    case ErrorLocations.AUTOMATION_API_ACTION:
    case ErrorLocations.AUTOMATION_PUSH_ACTION:
    case ErrorLocations.AUTOMATION_UDF_ACTION:
    case ErrorLocations.AUTOMATION_SC_ACTION:
    case ErrorLocations.AUTOMATION_JF_ACTION:

      if (automationData.selectedAutomationId && automationData.selectedAutomationId != payload.automation.id) {
        dispatch(setSelectedAutomation(null));
      }

      dispatch(setSelectedActionBlock({ actionBlock: null }));
      dispatch(setSelectedConfiguration({ type: null }));
      dispatch(setSelectedAction(null));
      dispatch(setActionPayload([]));

      setTimeout(() => {
        // we are only setting data for selectedAutomation, only if the selectedAutomation id and the automation id in the error payload is different
        if (payload.automation && !automationData.selectedAutomationId || automationData.selectedAutomationId != payload.automation.id) {
          dispatch(setSelectedAutomation(payload.automation));
        }

        dispatch(setSelectedActionBlock({ actionBlock: payload.actionBlock }));
        dispatch(setSelectedConfiguration({ type: "condition" }));
        dispatch(setSelectedAction({ ...payload.action, actionToPerform: ActionToPerform.EDIT }));
      }, 10)

      break



    default:
      console.log("oops something went wrong");
  }
};
