import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISelectValueType } from "../DatasourceModal";
import { IQueryGeneratedType } from "../MonitorInterface";
import { IActionDetails } from "../MonitorInterfaceComponents/AlertMeSection";
import { IDatasourceListInterface, IShopifyTemplate } from "../ShopifyMonitor";
import { MonitorService } from 'providers/data/services/MonitorService';

export enum IScreenContext {
    CREATE = "CREATE",
    EDIT = "EDIT"
}

export interface IShopifySyncData {
    shopifyOngoingSync?: boolean;
    shopifyLastTs?: string;
}

export interface IConnectionMetadata {
    value: string;
    label: string;
    datasourceId: string;
    datasourceType: string;
    displayName: string;
    name: string;
    host: string;
    port: number;
    username: string;
    password: string;
    ssl: boolean;
    datasourceCategory?: IDatasourceCategory;
}

export enum IMonitorExecutionType {
    CRON = 'CRON',
    NO_MONITOR = 'NO_MONITOR',
}

export interface IDatasourceModalContext {
    datasourceContext: IScreenContext,
    contextData?: IConnectionMetadata
}

export enum IDatasourceCategory {
    SHOPIFY = 'shopify',
    ZORP_DEFAULT_CLICKHOUSE = 'zorp_default_clickhouse',
    ZORP_SAMPLE_DATASOURCE = 'zorp_sample_datasource',
    USER_ADDED_DATASOURCE = 'user_added_datasource'
}

export enum ICronValueType {
    EXPRESSION = 'EXPRESSION',
    INTERVAL = 'INTERVAL',
    SCHEDULE = 'SCHEDULE'
}

export interface ICronExpressionData {
    expression: string;
}

interface ICronIntervalData {
    hours?: number;
    minutes?: number;
}

export interface ICronScheduleData {
    // type: ICronScheduleDataType;
    daysArray: string[];
    timeArray: string[];
}

export interface ICronExpression {
    cronType: ICronValueType,
    timeZone: string;
    cronData: ICronExpressionData | ICronIntervalData | ICronScheduleData;
    isActive?:boolean;
}

interface IMonitorData {
    monitorId?:string;
    accountId?:string;
    isDeleted?:boolean;
    isInitiated?:boolean;
    timesRan?:number;
    datasourceId: string;
    tableName?: string;
    monitorName: string;
    isActive: boolean;
    monitorDescription: string;
    timeZone: string;
    cronType: ICronValueType;
    createBulkAlert: boolean;
    avoidDuplicate: boolean;
    autoResolve: boolean;
    query_generated_type: IQueryGeneratedType;
    query: string;
    autoResolveCount: number;
    cronExpression: string;
    cronInterval: ISelectValueType;
    actionData: any;
    secondaryCriteria: any;
    cronData: ICronExpression;
    primaryColumnField: ISelectValueType;
    automationPayload?:any;
}

interface IMonitorComponentsData {
    selectedTableName?:string;
    isdDatasourceSectionHidden: boolean,
    isConditionSectionHidden: boolean,
    isChannelSectionHidden: boolean,
    isDisableCreateBulkAlert: boolean,
    isMonitorCustomizationSectionHidden: boolean,
    selectedActionDetails: IActionDetails | undefined,
    tablesColumnsList: string[]
}

export interface IMonitorInterfaceContext {
    monitorData?: IMonitorData,
    monitorComponentsData: IMonitorComponentsData,
    monitorScreenContext: IScreenContext,
}

export interface ISelectedMonitor {
    monitorId: string;
    monitorAutomation?: any;
}

interface IMonitorReducerInitialState {
    selectedDatasource?: IDatasourceListInterface,// store value of selected datasource
    datasourceListData: IConnectionMetadata[],
    confirmMonitorModalData?: IShopifyTemplate | null,
    onEditClassicBuilderTemplateData: IShopifyTemplate | undefined,
    isConfirmMonitorModalOpen: boolean,
    isTemplateGeneratingFromPrompt: boolean,
    monitorCreateFromTemplatePayload: IShopifyTemplate | undefined,
    datasourceModalContext: IDatasourceModalContext | undefined,
    shopifySyncData?: IShopifySyncData | undefined,
    monitorInterfaceContext: IMonitorInterfaceContext | undefined,
    selectedMonitor: ISelectedMonitor | undefined,
    showInterfaceUI: boolean,
}

const initialState: IMonitorReducerInitialState = {
    isConfirmMonitorModalOpen: false,
    isTemplateGeneratingFromPrompt: false,
    onEditClassicBuilderTemplateData: undefined,
    monitorCreateFromTemplatePayload: undefined,
    datasourceModalContext: undefined,
    shopifySyncData: {
    },
    monitorInterfaceContext: undefined,
    datasourceListData: [],
    selectedMonitor: undefined,
    showInterfaceUI: false
}

export const intDatasource = createAsyncThunk('init/datasource', async () => {
    const fetchDatasource = await MonitorService.getAllDatasource();
    if (fetchDatasource.code === '200') {
        return fetchDatasource.data;
    } else {
        return [];
    }
})

export const MonitorSlice = createSlice({
    name: "monitor",
    initialState,
    reducers: {
        setSelectedDatasource: (state, action: PayloadAction<IDatasourceListInterface>) => {
            state.selectedDatasource = action.payload;
        },
        setConfirmMonitorModalData: (state, action: PayloadAction<IShopifyTemplate | null>) => {
            state.confirmMonitorModalData = action.payload;
        },
        setOnEditClassicBuilderTemplateData: (state, action: PayloadAction<IShopifyTemplate | undefined>) => {
            state.onEditClassicBuilderTemplateData = action.payload;
        },
        setIsConfirmMonitorModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isConfirmMonitorModalOpen = action.payload;
        },
        setIsTemplateGeneratingFromPrompt: (state, action: PayloadAction<boolean>) => {
            state.isTemplateGeneratingFromPrompt = action.payload;
        },
        setMonitorCreateFromTemplatePayload: (state, action: PayloadAction<IShopifyTemplate | undefined>) => {
            state.monitorCreateFromTemplatePayload = action.payload;
        },
        setDatasourceModalContext: (state, action: PayloadAction<IDatasourceModalContext | undefined>) => {
            state.datasourceModalContext = action.payload;
        },
        setShopifySyncData: (state, action: PayloadAction<IShopifySyncData | undefined>) => {
            state.shopifySyncData = action.payload;
        },
        setDatasourceListData: (state, action: PayloadAction<IConnectionMetadata[]>) => {
            state.datasourceListData = action.payload;
        },
        setMonitorInterfaceContext: (state, action: PayloadAction<IMonitorInterfaceContext | undefined>) => {
            state.monitorInterfaceContext = action.payload;
        },
        setSelectedMonitor: (state, action: PayloadAction<ISelectedMonitor | undefined>) => {
            state.selectedMonitor = action.payload;
        },
        setShowInterfaceUI: (state, action: PayloadAction<boolean>) => {
            state.showInterfaceUI = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(intDatasource.fulfilled, (state, action) => {
                state.datasourceListData = action.payload;
                if (!state.selectedDatasource) {
                    state.selectedDatasource = action.payload[0];
                }
            })
            .addCase(intDatasource.rejected, (state, action) => {
                state.datasourceListData = [];
                state.selectedDatasource = undefined;
            })
            // .addCase(intDatasource.pending, (state, action) => {
            //     state.datasourceListData = [];
            //     state.selectedDatasource = undefined;
            // })
    }
});

export const {
    setSelectedDatasource,
    setConfirmMonitorModalData,
    setIsConfirmMonitorModalOpen,
    setIsTemplateGeneratingFromPrompt,
    setOnEditClassicBuilderTemplateData,
    setMonitorCreateFromTemplatePayload,
    setDatasourceModalContext,
    setShopifySyncData,
    setDatasourceListData,
    setMonitorInterfaceContext,
    setSelectedMonitor,
    setShowInterfaceUI
} = MonitorSlice.actions;

export default MonitorSlice.reducer;