import React from 'react';
import Box from '@mui/material/Box';
import styled from "styled-components";

interface IColumnsListCapsure {
    columnsList: Array<string>;
    onCardClick: (columnData: string) => void;
}

const ConfigureMonitorTxt = styled.p`
color: var(--Primary-700, #3054B9);
text-align: center;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 18px; /* 150% */
`

const ColumnsListCapsure = ({ columnsList, onCardClick }: IColumnsListCapsure) => {
    return (
        <Box display={'flex'} flexDirection={'row'} width={'100%'} flexWrap={'wrap'} gap={'6px'}>
            {
                columnsList.map((data, index) => {
                    return (
                        <>
                            <Box key={index} bgcolor={'#F5F8FF'} padding={'2px 8px'} width={'fit-content'} borderRadius={'16px'} className='pointer' onClick={() => {
                                onCardClick(data)
                            }}>
                                <ConfigureMonitorTxt>{data}</ConfigureMonitorTxt>
                            </Box>
                        </>
                    )
                })
            }
        </Box>
    )
}

// export default ColumnsListCapsure
export  default React.memo(ColumnsListCapsure);