import React from 'react'
import styled from "styled-components"

const Wrapper = styled.div`
    background: #e5e5e5;
    width: 100%;
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
`

const LoadingText = styled.p`
    font-size: 16px;
    font-weight: 600;
    color: #757575
`


type Props = {}

const TestApiLoadingScreen = (props: Props) => {
  return (
    <Wrapper>
        <LoadingText> Sending Request ... </LoadingText>
    </Wrapper>
  )
}

export default TestApiLoadingScreen