import { useState, useRef, useEffect } from "react";
import usePorterPermissions from "hooks/usePorterPermissions";
import useTaskShow from "views/workflows/hooks/useTaskShow";
import { useDispatch } from "react-redux";
import { setInEditState } from "render-engine/reducers/show/TaskShowReducer";
import styled from "styled-components";
import {
  TaskFormInput,
  TaskFormLabelForShow,
  ZActionButton,
  ZTextValueWrapper,
} from "render-engine/render.styles";
import { Avatar, Tooltip } from "@mui/material";
import { initTaskShowMetaData } from "render-engine/reducers/show/TaskShowReducer";
import { stringAvatar } from "utils/CommonUtils";
import { ZComponentV2 } from "components/common/ZComponentV2";
import { FaChevronDown } from "react-icons/fa";
import useOutsideClick from "views/workflows/hooks/useOutsideClick";
import useAccessPermissions from "hooks/useAccessPermissions";
import { ResourceTypeConstant } from "views/entities/permissions/types";

type Props = any;

const ZUserRelationShow = (prop: Props) => {
  const dispatch = useDispatch();
  const porterPermissions = usePorterPermissions();
  const [editConfig, setEditConfig] = useState<any>({});

  const { inEditState, taskData, initTaskData } = useTaskShow();
  const accessPermissions = useAccessPermissions({
    resourceType: ResourceTypeConstant.TABLE,
  });

  const handleClick = () => {
    if (!accessPermissions.edit_record || prop.config.isReadOnly) return
    const nodeId = prop.config.key
    setEditConfig(prop.config.editConfig)
    dispatch(setInEditState({ nodeId: nodeId, inEditState: true }))
  }

  useEffect(() => {
    if (inEditState?.[prop.config.key]) {
      setEditConfig(prop.config.editConfig);
    }
  }, []);

  const UserBadge = styled.span`
    display: flex;
    align-items: center;
    width: max-content;
    gap: 4px;
    padding: 3px 8px;
    border-radius: 4px;
    border: 1px solid #d0d5dd;
    background: #fff;
  `;

  return !prop.config?.value ? (
    <></>
  ) : (
    <div className="col-span-4">
      {inEditState?.[prop.config.key] ? (
        <ZComponentV2 {...prop} config={editConfig} />
      ) : (
        <>
          <TaskFormLabelForShow
            fontSize={prop.config.label?.fontSize}
            color={prop.config?.label?.color}
          >
            {prop.config?.label?.val}
            {prop.config?.isRequired && <span className="text-red-500">*</span>}
          </TaskFormLabelForShow>
          <ZTextValueWrapper onClick={handleClick}>
            {prop.config?.value?.rowDef?.length === 1 ? (
              <div
                style={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <UserBadge>
                  <Avatar
                    style={{
                      width: "16px",
                      height: "16px",
                      fontSize: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: "1.5px",
                    }}
                    {...stringAvatar(prop.config.value.rowDef[0].name)}
                  />
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "12px",
                      color: "#344054",
                    }}
                  >
                    {prop.config.value.rowDef[0].name}
                  </div>
                </UserBadge>
                <FaChevronDown
                  size={"1rem"}
                  color="#3054B9"
                  onClick={handleClick}
                />
              </div>
            ) : prop.config?.value?.rowDef?.length > 0 ? (
              <div style={{ display: "flex", gap: "4px" }}>
                {prop.config?.value?.rowDef?.map((item: any) => (
                  <Tooltip title={item.name}>
                    <Avatar
                      style={{ fontSize: "10px" }}
                      {...stringAvatar(item.name)}
                    />
                  </Tooltip>
                ))}
              </div>
            ) : accessPermissions.edit_record ? (
              <ZActionButton> + Add {prop?.config?.shape === "TEAM" ? "Team" : "User"} </ZActionButton>
            ): null}
          </ZTextValueWrapper>
        </>
      )}
    </div>
  );
};
export default ZUserRelationShow;
