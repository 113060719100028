//@ts-nocheck
import { default as Modal } from '@mui/material/Modal';
import { ActionToPerform } from 'constants/CommonConstants';
import { useJSEditor } from 'lowcode/hooks/useJSEditor';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { BiEdit } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import { generateId } from 'utils/Utils';
import { ABEntryButtonText } from 'views/automationBuilder/automationBuilder.styles';
import ABEntryList from 'views/automationBuilder/components/ABEntryList';
import { closeAutomation } from 'views/automationBuilder/reducer/automationReducer';
import { saveDecisionNodeAutomations } from 'views/automationBuilder/saveHandlers/saveDecisionNodeAutomations';
import { ABMetadataTypeEnum } from 'views/automationBuilder/types/ABType';
import { Button, Label } from 'views/DataField/DataField.styles';
import { Card, Heading, MainContainer } from 'views/workflows/actionsAndConditions.styles';
import 'views/workflows/css/flow.css';
import useAutomation from "views/automationBuilder/hooks/useAutomation";
import { default as SwitchNameEdit } from 'views/workflows/SwitchNameEdit';
import ConfigureAutomation from './ConfigureAutomation';
import useWorkFlow from './hooks/useWorkFlow';
import {
  deleteTransition, removeHandleFromDecisionNode,
  setSelectedAction
} from './reducers/workflowAction';
import { guardTypes } from './workflowactions/api/guard.types';

const DecisionInfo = () => {
  const dispatch = useDispatch();
  const [automationId, setAutomationId] = useState('');
  const [showSwitchNameEditModal, setShowSwitchNameEditModal] = useState(false);
  //@ts-ignore
  const { selectedDecisionNode, elements } = useWorkFlow();
  const {
    formState: { errors },
  } = useForm();
  const {
    mockData
  } = useJSEditor();

  const [openAB, setOpenAB] = useState(false);
  const { clearRightSide } = useAutomation();


  const deleteHandle = () => {
    dispatch(removeHandleFromDecisionNode(selectedDecisionNode));
    const allFaultyTransitions = elements.filter((ele: any) => ele.sourceHandle === selectedDecisionNode.id)
    allFaultyTransitions.forEach((transition: any) => {
      dispatch(deleteTransition(transition))
    })
  };


  return (
    <>
      <MainContainer className='controlCenter_sidebar grid grid-flow-col'>
        <form>
          <Heading> Configure Switch </Heading>
          <hr />
          <Label> Name </Label>

          <Card
            //@ts-ignore
            variant='secondary'
          >
            <BiEdit onClick={() => setShowSwitchNameEditModal(true)} size={'1.4rem'} />{' '}
            <p> {selectedDecisionNode.data.name} </p>
          </Card>

          <Button style={{marginTop:'20px'}} onClick={(e) => {
            e.preventDefault();
            setOpenAB(true);
            dispatch(setSelectedAction(null));
          }}
            width='full'
            variant='secondary'>
            <ABEntryButtonText>Configure Transition Automation</ABEntryButtonText>
          </Button>

          <ABEntryList automationList={selectedDecisionNode.data?.automations} onAutomationClick={(automationId) => {
            setAutomationId(automationId);
            setOpenAB(true);
            dispatch(setSelectedAction(null));
          }}/>

          {errors.name && <span>This field is required</span>}
          {
            selectedDecisionNode?.type === "IF" ? (
              <ConfigureAutomation
                metaDataType={ABMetadataTypeEnum.WORKFLOW_DECISION_NODE_TRANSITION_IF_SWITCH}
                prevAutomations={selectedDecisionNode?.data?.automations}
                onSaveHandler={saveDecisionNodeAutomations}
                onAutomationClose={() => {
                  clearRightSide();
                  dispatch(closeAutomation());
                  setOpenAB(false);
                }}
                automationSelected={automationId}
                otherConfigs={{
                  allowOnlySingleAutomation: true,
                  hideTriggerConfigureButton: false,
                  preFillTrigger: true
                }}
                openAutomationBuilder={openAB}
              />
            ) : (
              <ConfigureAutomation
                metaDataType={ABMetadataTypeEnum.WORKFLOW_DECISION_NODE_TRANSITION_ELSE_SWITCH}
                prevAutomations={selectedDecisionNode?.data?.automations}
                onSaveHandler={saveDecisionNodeAutomations}
                onAutomationClose={() => {
                  clearRightSide();
                  dispatch(closeAutomation());
                  setOpenAB(false);
                }}
                automationSelected={automationId}
                otherConfigs={{
                  allowOnlySingleAutomation: true,
                  hideTriggerConfigureButton: false,
                  showAutomationCondition: false,
                  preFillTrigger: true,
                  hideGuardBlock: true,
                }}
                openAutomationBuilder={openAB}
              />
            )
          }

        </form>
      </MainContainer>
      <MainContainer>
        {selectedDecisionNode.deletePossible && (
          //@ts-ignore
          <Button
            //  @ts-ignore
            width='full'
            type='button'
            variant='danger'
            onClick={() => deleteHandle()}
          >
            {' '}
            Delete Switch{' '}
          </Button>
        )}
      </MainContainer>

      <Modal
        open={showSwitchNameEditModal}
        onClose={() => dispatch(setShowSwitchNameEditModal(false))}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        {/* Get the info from central place */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: '100%'
          }}
        >
          <SwitchNameEdit setShowSwitchNameEditModal={setShowSwitchNameEditModal} />
        </div>

      </Modal>
    </>
  );
};

export default DecisionInfo;