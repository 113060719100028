import React, { Component } from "react";
import PropTypes from "prop-types";
import { Responsive, WidthProvider } from "react-grid-layout";

import GridItemContainer from "./GridItemContainer";
import initialState from "./initialState";
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"

import "./styles.css"

const ResponsiveGridLayout = WidthProvider(Responsive);

class GridLayout extends Component {
  render() {
    const { children } = this.props;
    const { layouts } = initialState;
    const data = Object.keys(initialState.data);
    const ref = React.createRef();
    return (
      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        isDraggable={false}
        isRearrangeable={false}
        isResizable={false}
        draggableHandle=".grid-item__title"
        breakpoints={{ lg: 1280, md: 992, sm: 767, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
      >
        {data.map((item) => (
          <GridItemContainer ref={ref} data={initialState.data} key={item} children={<div> {initialState.data[item].title}  </div>} />
        ))}
      </ResponsiveGridLayout>
    );
  }
}

GridLayout.propTypes = {
  layouts: PropTypes.object.isRequired,

};

export default GridLayout;