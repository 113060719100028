import { SelectChangeEvent } from "@mui/material/Select";
import { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch } from "react-redux";
import { recordRSEvent } from "utils/CommonUtils";
import { v4 as uuidv4 } from "uuid";
import useWorkFlow from "views/workflows/hooks/useWorkFlow";
import notification from "notifications/notifications";
import {
  addDataField,
  setShowDataFieldModal,
  setShowDataFieldCreateModal,
  setShowCreateDatafieldModalSingle,
  setActiveSubField,
} from "views/workflows/reducers/workflowAction";

import {
  BooleanField,
  DateField,
  FileField,
  ImageField,
  ListOfValues,
  Location,
  Number,
  SignatureField,
  TextField,
  CollectionField,
} from "./Components/index";
import {
  BottomDiv,
  Button,
  Container,
  FormFooter,
  HorizontalLine,
  Input,
  Label,
  TopDiv,
  SubFieldContainer,
} from "./DataField.styles";
import SelectDatafieldDropdown from "./HelperFunction/SelectDatafieldDropdown";
import {
  CreateDataFieldModalProps,
  DataFieldType,
  CollectionType,
} from "./DataField.types";
import {
  dataFieldNameErrorSchema,
  displayNameErrorSchema,
  idErrorSchema,
} from "./DataFieldErrorSchema";
import ErrorMessage from "./ErrorMessage";
import { getDefaultValue } from "views/DataField/HelperFunction/getDefaultValue";

export default function CreateView({
  handleClose,
  open,
}: CreateDataFieldModalProps) {
  const dispatch = useDispatch();
  const {
    dataFields: alreadyCreatedDataFields,
    taskTypeId,
    taskTypeName,
    selectedNode,
    showTaskCardConfig,
    dataFieldType: defaultDataType,
    showCreateDatafieldModalSingle,
  } = useWorkFlow();

  const [dataFieldType, setDataFieldType] = useState(defaultDataType.dataType);

  const formDefaultValue = {
    displayName: "",
    defaultValue: "",
    mandatory: false,
    isCreateTimeField: false,
    name: "",
    subFields: [],
  };

  const {
    handleSubmit,
    clearErrors,
    register,
    reset,
    control,
    watch,
    setError,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: formDefaultValue,
  });

  const closeModal = () => {
    reset({ ...formDefaultValue });
    if (showCreateDatafieldModalSingle) {
      dispatch(setShowCreateDatafieldModalSingle(false));
    } else {
      dispatch(setShowDataFieldCreateModal(false));
    }
    dispatch(setActiveSubField(null));
  };

  const submitForm = (data: any, fieldType: string): void => {
    const prevDataFieldsName =
      alreadyCreatedDataFields?.map((item: any) => item.name) || [];

    for (let i = 0; i < prevDataFieldsName?.length; i++) {
      if (prevDataFieldsName[i] === data.name) {
        setError("name", {
          type: "manual",
          message: "Data field with this Id already exist",
        });
        return;
      }
    }

    if (fieldType === DataFieldType.COLLECTION) {
      let prevSubfieldName: string[] = [];
      for (let i = 0; i < data?.subFields?.length; i++) {
        if (prevSubfieldName.includes(data.subFields[i]?.name)) {
          //@ts-ignore
          setError(`subFields.${i}.name`, {
            type: "manual",
            message: "Data field with this name already exist",
          });
          return;
        }
        prevSubfieldName.push(data.subFields[i]?.name);
      }
    }

    let dataFieldRequiredValue = {
      // name: data?.dataFieldName,
      name: data?.name,
      displayName: data?.displayName,
      type: fieldType,
      mandatory: data?.isCreateTimeField && data?.mandatory,
      isCreateTimeField: data?.isCreateTimeField,
      isDraft: true, // Marking the field as isDraft = true because this data field is created newly
    };

    switch (fieldType) {
      case DataFieldType.NUMBER:
        dataFieldRequiredValue = {
          ...dataFieldRequiredValue,
          //@ts-ignore
          defaultValue: data?.defaultValue,
        };
        break;

      case DataFieldType.LOCATION:
        dataFieldRequiredValue = {
          ...dataFieldRequiredValue,
          //@ts-ignore
          address: data?.address,
          children: [
            {
              name: "address",
              displayName: "Address",
              description: "Address",
              type: "TEXT",
              defaultValue: data?.location?.address || "",
            },
            {
              name: "latitude",
              displayName: "Latitude",
              description: "Latitude",
              type: "NUMBER",
              defaultValue: data?.location?.latitude,
            },
            {
              name: "longitude",
              displayName: "Longitude",
              description: "Longitude",
              type: "NUMBER",
              defaultValue: data?.location?.longitude,
            },
          ],
        };
        break;

      case DataFieldType.BOOLEAN:
        dataFieldRequiredValue = {
          ...dataFieldRequiredValue,
          //@ts-ignore
          defaultValue: data?.defaultValue === "false" ? false : true,
        };
        break;

      case DataFieldType.TEXT:
        dataFieldRequiredValue = {
          ...dataFieldRequiredValue,
          //@ts-ignore
          defaultValue: data?.defaultValue,
        };
        break;
      // {value: '', displayValue: '', default: false}
      case DataFieldType.COLLECTION:
        let subFields: CollectionType[] = [];
        let isDataFieldMandatory = false;

        data?.subFields?.forEach((item: CollectionType) => {
          let locationStruct = undefined;

          // if isDataFieldMandatory, we don't want to check it again as
          // we only need one subfield to be mandatory to make whole datafield
          // mandatory.
          if (!isDataFieldMandatory && item?.isCreateTimeField) {
            isDataFieldMandatory = item?.mandatory || false;
          }

          if (item.type === DataFieldType.LOCATION) {
            locationStruct = [
              {
                name: "address",
                displayName: "Address",
                description: "Address",
                type: "TEXT",
                defaultValue: item?.address,
              },
              {
                name: "latitude",
                displayName: "Latitude",
                description: "Latitude",
                type: "NUMBER",
                defaultValue: item?.latitude,
              },
              {
                name: "longitude",
                displayName: "Longitude",
                description: "Longitude",
                type: "NUMBER",
                defaultValue: item?.longitude,
              },
            ];
          }

          if (item.type === DataFieldType.BOOLEAN) {
            item.defaultValue = item?.defaultValue === "false" ? false : true;
          }

          subFields.push({
            type: item?.type,
            name: item?.name,
            displayName: item?.displayName,
            defaultValue: item?.defaultValue ?? getDefaultValue(item?.type),
            isCreateTimeField: item?.isCreateTimeField || false,
            mandatory: item?.isCreateTimeField ? item?.mandatory : false,
            // @ts-ignore
            children: locationStruct,
            id: uuidv4(),
            address: item?.address,
            latitude: item?.latitude,
            longitude: item?.longitude,
          });
        });

        dataFieldRequiredValue = {
          ...dataFieldRequiredValue,
          //@ts-ignore
          children: subFields,
          mandatory: data.isCreateTimeField ? isDataFieldMandatory : false,
          // isCreateTimeField: false,
        };
        break;

      case DataFieldType.LIST_OF_TEXT:
        dataFieldRequiredValue = {
          ...dataFieldRequiredValue,
        };
        break;

      case DataFieldType.SIGNATURE:
        dataFieldRequiredValue = {
          ...dataFieldRequiredValue,
        };
        break;

      case DataFieldType.FILE:
        dataFieldRequiredValue = {
          ...dataFieldRequiredValue,
        };
        break;

      case DataFieldType.IMAGE:
        dataFieldRequiredValue = {
          ...dataFieldRequiredValue,
        };
        break;

      case DataFieldType.DATETIME:
        dataFieldRequiredValue = {
          ...dataFieldRequiredValue,
        };
        break;
        
      case DataFieldType.LINKS:
        dataFieldRequiredValue = {
          ...dataFieldRequiredValue,
        };
        break;
    }

    reset({ ...formDefaultValue });
    dispatch(addDataField({ id: uuidv4(), ...dataFieldRequiredValue }));
    //@ts-ignore
    notification("success", "Successfully created a new data field");
    if (selectedNode || showTaskCardConfig?.show) {
      const dataForFlutter = {
        data: data,
        key: defaultDataType.key,
      };
      // @ts-ignore
      document
        .getElementById("app-builder")
        ?.contentWindow?.postMessage(dataForFlutter, "*");
      dispatch(setShowDataFieldModal(false));
    }
    closeModal();
    recordRSEvent("createdatafield_createbutton_click", {
      context_data: "datafield_create",
      workflow_id: taskTypeId,
      workflow_name: taskTypeName,
      dataField_name: data.name,
      dataField_type: fieldType,
    });
  };

  useEffect(() => {
    recordRSEvent("createdatafield_button_click", {
      context_data: "datafield_create",
      workflow_id: taskTypeId,
      workflow_name: taskTypeName,
    });
    reset({ ...formDefaultValue, subFields: [] });
  }, []);

  useEffect(() => {
    setDataFieldType(defaultDataType.dataType);
  }, [defaultDataType]);

  const handleSelect = (event: SelectChangeEvent) => {
    //@ts-ignore
    setDataFieldType(event.target.value);
    reset({
      ...getValues(),
      defaultValue: "",
    });
  };

  const isCreateTimeField = watch("isCreateTimeField");

  return (
    <div>
      {/* @ts-ignore */}
      <Container
        flutterView={showCreateDatafieldModalSingle}
        onSubmit={handleSubmit((data) => submitForm(data, dataFieldType))}
      >
        <TopDiv>
          <p>Create new Datafield</p>
          <HorizontalLine />
        </TopDiv>
        <BottomDiv>
          <Label>Name</Label>
          <Input autoFocus={true}
            {...register("displayName",displayNameErrorSchema )}
            type="text"
          />
          <ErrorMessage errors={errors} fieldName="displayName" />

          <Label>Id</Label>
          <Input {...register("name", idErrorSchema)} type="text" />
          <ErrorMessage errors={errors} fieldName="name" />

          <SelectDatafieldDropdown
            dataFieldType={dataFieldType}
            handleSelect={handleSelect}
          />

          {dataFieldType === DataFieldType.COLLECTION && (
            <div style={{ marginTop: "8px" }}>
              <Input
                type="checkbox"
                {...register("isCreateTimeField")}
                style={{ width: "5%" }}
              />
              <Label style={{ display: "inline" }}>
                {" "}
                Collect this data field as an input when a task is created
              </Label>
            </div>
          )}

          {/* TODO: switch */}
          {dataFieldType === DataFieldType.NUMBER ? (
            <Number errors={errors} register={register} />
          ) : dataFieldType === DataFieldType.LOCATION ? (
            //@ts-ignore
            <Location
              errors={errors}
              register={register}
              show={true}
              fieldName={"location"}
            />
          ) : dataFieldType === DataFieldType.BOOLEAN ? (
            <BooleanField control={control} />
          ) : dataFieldType === DataFieldType.TEXT ? (
            <TextField
              errors={errors}
              register={register}
              defaultValueName={"defaultValue"}
            />
          ) : dataFieldType === DataFieldType.DATETIME ? (
            <DateField register={register} />
          ) : dataFieldType === DataFieldType.LIST_OF_TEXT ? (
            <ListOfValues
              control={control}
              errors={errors}
              getValues={getValues}
            />
          ) : dataFieldType === DataFieldType.SIGNATURE ? (
            <SignatureField register={register} />
          ) : dataFieldType === DataFieldType.FILE ? (
            <FileField register={register} />
          ) : dataFieldType === DataFieldType.IMAGE ? (
            <ImageField register={register} />
          ) : dataFieldType === DataFieldType.COLLECTION ? (
            <>
              <Label style={{ marginTop: "8px" }}> Subfields </Label>
              <SubFieldContainer>
                <CollectionField
                  setValue={setValue}
                  clearErrors={clearErrors}
                  getValues={getValues}
                  errors={errors}
                  watch={watch}
                  register={register}
                  control={control}
                  isCreateView={true}
                />
              </SubFieldContainer>
            </>
          ) : null}

          {dataFieldType !== DataFieldType.COLLECTION && (
            <div style={{ marginTop: "2rem" }}>
              <div>
                <Input
                  type="checkbox"
                  {...register("isCreateTimeField")}
                  style={{ width: "5%" }}
                />
                <Label style={{ display: "inline" }}>
                  {" "}
                  Collect this data field as an input when a task is created
                </Label>
              </div>
              {isCreateTimeField && (
                <div>
                  <Input
                    type="checkbox"
                    {...register("mandatory")}
                    style={{ width: "5%" }}
                  />
                  <Label style={{ display: "inline" }}>
                    {" "}
                    Make this input mandatory{" "}
                  </Label>
                </div>
              )}
            </div>
          )}

          <FormFooter>
            <div>
              {/* onClick={handleSubmit((data) => submitForm(data,dataFieldType))} */}
              <Button
                type="button"
                onClick={() => {
                  closeModal();
                  recordRSEvent("createdatafield_cancelbutton_click", {
                    context_data: "datafield_create",
                    workflow_id: taskTypeId,
                    workflow_name: taskTypeName,
                  });
                }}
                variant="secondary"
              >
                {" "}
                Close{" "}
              </Button>
              <Button
                type="submit"
                variant="primary"
                id="create_button_data_field_modal"
              >
                {" "}
                Create{" "}
              </Button>
            </div>
          </FormFooter>
        </BottomDiv>
      </Container>
    </div>
  );
}