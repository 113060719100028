import { FilterComponentType } from "./components/FilterDynamicComponent";
import { IFilterContext } from "./constants/Filter.types";

export const getFilterValue = (value: any, dataType: string) => {
    let outputValue: any;
    if (dataType == FilterComponentType.MULTI_DROPDOWN) {
        outputValue = value.map((data: any) => data.value);
    } else if (dataType == FilterComponentType.DROPDOWN || dataType == FilterComponentType.BOOLEAN) {
        outputValue = value.value;
    } else if (dataType == FilterComponentType.DATETIME || dataType == FilterComponentType.DATE || dataType == FilterComponentType.TIME) {
        outputValue = new Date(value).toISOString();
    } else if (dataType == FilterComponentType.TEXT || dataType == FilterComponentType.STRING) {
        outputValue = value;
    } else if (dataType == FilterComponentType.NUMBER) {
        outputValue = parseInt(value);
    } else if (dataType == FilterComponentType.LIST_OF_TEXT) {
        outputValue = value.split(',');
    }
    return outputValue;
}

export const getFilterCount = (context: string, userId: string) => {
    const fetchFilterData = getFilter(context, userId);
    if (fetchFilterData) {
        return fetchFilterData.filterArray?.length || 0;
    } else {
        return 0;
    }
}

export const getFilter = (context: string, userId: string | null) => {
    let fetchFilterData: any;
    fetchFilterData = localStorage.getItem(`${context}-filter`);
    if (fetchFilterData) {
        const structuredFilteredJSONData = JSON.parse(fetchFilterData);
        if (userId) {
            if (structuredFilteredJSONData && structuredFilteredJSONData.hasOwnProperty(userId)) {
                return structuredFilteredJSONData[userId]
            }else{
                return {}
            }
        }

        if (!userId) {
            return structuredFilteredJSONData;
        }
    } else {
        return {};
    }
}

export const clearFilterStorage = (context: string) => {
    localStorage.setItem(`${context}-filter`, JSON.stringify(''));
}

export const getFilterListValue = (data: any, context: string, universalFilters: any, userId: string) => {
    const appliedFilter: any = {};
    const systemFilters: any = {};
    appliedFilter['includeDeleted'] = data['includeDeleted']
    let keyForLocalStorage = context;
    if(isDynamicPath()) {
        keyForLocalStorage = getDynamicPath();
    }

    data?.filterArray?.forEach((filterData: any) => {
        const filter = (universalFilters?.options || []).find((universalFilterData: any) => universalFilterData.value == filterData.filterKey)
        if (filter || filterData?.filterObject?.isUniversalFilter) {
            appliedFilter[filterData.filterKey] = getFilterValue(filterData.filterValue, filterData.valueDataType);
        } else {
            systemFilters[filterData.filterKey] = getFilterValue(filterData.filterValue, filterData.valueDataType);
        }
    })

    storeFilterValue(keyForLocalStorage, data, userId)

    return {
        fullFilter: { ...appliedFilter, data: systemFilters },
        appliedFilter: appliedFilter,
        systemFilters: systemFilters,
    }
}

export const storeFilterValue = (context: string, data: any, userId: string) => {
    if (context && userId) {
        const getFilterData = getFilter(context, null);

        if (Object.keys(getFilterData).length > 0) {
            getFilterData[userId] = data;
            localStorage.setItem(`${context}-filter`, JSON.stringify(getFilterData));
        } else {
            const newFilterData: any = {};
            newFilterData[userId] = data;
            localStorage.setItem(`${context}-filter`, JSON.stringify(newFilterData));
        }
    }
}

export const getStartOfTheDay = (dateTime: any) => {
    return (new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate(), 0, 0, 0, 0)).toISOString();
}

export const getEndOfTheDay = (dateTime: any) => {
    return (new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate(), 23, 59, 59, 999)).toISOString();
}

// TODO : 
export const isDynamicPath = () =>{
    const basePathArray = ['tasks', 'workflows', 'settings', 'alerts']
    // get the url
    const url = window.location.href;
    // split on the basis of "/"
    const urlArray = url.split("/");
    // find the index of #
    const index = urlArray.indexOf("#");
    let type = urlArray[index + 1];
    type = type.split("?")[0];
    let context:string = IFilterContext.TASK;

    if (urlArray.length > index + 1) {
      let basePath = urlArray[index + 1];
        // remove any query params
        basePath = basePath.split("?")[0];
      if(basePath !== "tasks") {
        context = urlArray[index + 1] || IFilterContext.TASK;
      }
      
    }
    // return type !== "tasks"
    return !basePathArray.includes(type);
}

export const getDynamicPath = () =>{
    if(isDynamicPath()){
        // get the url
        const url = window.location.href;
        // split on the basis of "/"
        const urlArray = url.split("/");
        // find the index of #
        const index = urlArray.indexOf("#");
        let context:string = IFilterContext.TASK;

        if (urlArray.length > index + 1) {
        let basePath = urlArray[index + 1];
        // remove any query params
        basePath = basePath.split("?")[0];
            if(basePath !== "tasks") {
                context = urlArray[index + 1] || IFilterContext.TASK;
                return context;
            }
        }
        
    }
    return ""
    
}

// When we are setting value for the datafield in the filter, we need to pass to this function,
// if the value in empty then it give back the current date with the start of the day or the end of the day based on the filterkey (from or to)
// if the value is given then it convert the time to the start or end of the day

export const getFormattedDateValue = (datType: string, value: any, filterName: string) => {
    if (datType !== FilterComponentType.DATE) {
        return null;
      }
    
      const now = value ? new Date(value) : new Date();
    
      if (filterName.toLowerCase().includes('from')) {
        return getStartOfTheDay(now);
      }
    
      if (filterName.toLowerCase().includes('to')) {
        return getEndOfTheDay(now);
      }
    
      return now.toISOString();
}