import React from "react";

type Props = {};

const ZPurchaseOrder = ({
  color,
  width,
  height,
}: {
  color?: string;
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9997 5C10.9997 5.79565 10.6836 6.55871 10.121 7.12132C9.5584 7.68393 8.79534 8 7.99969 8C7.20404 8 6.44098 7.68393 5.87837 7.12132C5.31576 6.55871 4.99969 5.79565 4.99969 5M1.7246 4.55104L1.19961 10.851C1.08682 12.2044 1.03043 12.8811 1.2592 13.4031C1.46021 13.8618 1.80846 14.2403 2.24883 14.4787C2.75004 14.75 3.42909 14.75 4.78717 14.75H11.2122C12.5703 14.75 13.2493 14.75 13.7506 14.4787C14.1909 14.2403 14.5392 13.8618 14.7402 13.4031C14.969 12.8811 14.9126 12.2044 14.7998 10.851L14.2748 4.55104C14.1777 3.38656 14.1292 2.80433 13.8713 2.36364C13.6443 1.97558 13.3061 1.66445 12.9006 1.47038C12.44 1.25 11.8557 1.25 10.6872 1.25L5.31217 1.25C4.14366 1.25 3.5594 1.25 3.09883 1.47038C2.69325 1.66445 2.35511 1.97558 2.12804 2.36364C1.87016 2.80432 1.82164 3.38656 1.7246 4.55104Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ZPurchaseOrder;
