import { Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import datasourceEditIcon from "assests/datasource_edit_icon.svg";
import datasourceEditIconBlue from "assests/datasource_edit_icon_blue.svg";
import AddNewDataSourceIcon from "assests/monitor_datasource_icon.svg";
import AddNewDataSourceIconBlue from "assests/monitor_datasource_icon_blue.svg";
import shopify_icon from "assests/shopify_icon.svg";
import SyncInProgressIcon from "assests/syn_in_progress_info.svg";
import SynchedIcon from "assests/synched_info.svg";
import { DateTime } from "luxon";
import React, { useState } from "react";
import styled from "styled-components";
import { IDatasourceCategory } from "../reducer/MonitorReducer";
import useMonitor from "../reducer/useMonitor";
import { IDatasourceListInterface } from "../ShopifyMonitor";
interface IDatasourceCardWrapperProps {
  selected: boolean;
}

const DatasourceCardWrapper = styled.div<IDatasourceCardWrapperProps>`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 4px;
  width: 211px;
  height: 80px;
  border: ${(props) =>
    props.selected ? "1px solid #3C69E7" : "1px solid #eaecf0"};
  &:hover {
    border: 1px solid #3c69e7;
    cursor: pointer;
  }
`;

const DatasourceCardheading = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
`;

const SynchInProgressDivWrapper = styled.div`
  display: flex;
  padding: 2px 8px 2px 6px;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1.5px solid var(--Primary-600, #3c69e7);
`;

const SynchInProgressText = styled.p`
  color: var(--Primary-700, #3054b9);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

const DataSynchedWrapper = styled.div`
  display: flex;
  padding: 2px 8px 2px 6px;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1.5px solid var(--Success-600, #039855);
`;

const DataSynchedText = styled.p`
  color: var(--Success-700, #027a48);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

interface IDatasourceCard {
  selectedDataSourceId?: string;
  onSelectDatasource: () => void;
  onEditDatasource: () => void;
  datasourceData: IDatasourceListInterface;
}

// showDatabaseName
// synchInProgress
// synched

const DatasourceCard = ({
  selectedDataSourceId,
  onSelectDatasource,
  onEditDatasource,
  datasourceData,
}: IDatasourceCard) => {
  const { shopifySyncData } = useMonitor();
  const [isHovered, setIsHovered] = useState(false);
  const isSelected = selectedDataSourceId === datasourceData.datasourceId;
  const isShopifyDatasource = datasourceData.datasourceCategory == IDatasourceCategory.SHOPIFY;
  return (
    <>
      <DatasourceCardWrapper
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        selected={isSelected}
        onClick={onSelectDatasource}>
        <DatasourceCardheading>
          {isShopifyDatasource ? (
            <img src={shopify_icon} alt="fnIcon" />
          ) : (isHovered || isSelected) ? (
            <img src={AddNewDataSourceIconBlue} alt="fnIcon" />
          ) : (
            <img src={AddNewDataSourceIcon} alt="fnIcon" />
          )}

<Tooltip 
placement="top-end"
title={<Typography>{datasourceData.displayName}</Typography>}>
      <div style={{ textOverflow:'ellipsis', width:'160px'}}>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "500",
              color: (isHovered || isSelected) ? "#3054B9" : "#101828",
              fontStyle: "normal",
              fontFamily:'Inter'
            }}
            noWrap>
            {datasourceData.displayName} 
          </Typography>
          </div>
          </Tooltip>
        </DatasourceCardheading>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {isShopifyDatasource ? (
            <>
              {shopifySyncData?.shopifyOngoingSync &&
                <Tooltip title={<Typography>  Sync may take a few minutes</Typography>}>
                  <SynchInProgressDivWrapper>
                    <img style={{ width: '12px', height: '12px' }} src={SyncInProgressIcon} alt="fnIcon" />
                    <SynchInProgressText>Sync-In-Progress</SynchInProgressText>
                  </SynchInProgressDivWrapper>
                </Tooltip>
              }

              {shopifySyncData?.shopifyLastTs && (
                  <Tooltip
                    title={
                      <Typography>
                        Last sync done at:{" "}
                        {DateTime.fromISO(
                          shopifySyncData?.shopifyLastTs
                        ).toFormat("dd LLL yyyy, hh:mm a")}
                      </Typography>
                    }
                  >
                    <DataSynchedWrapper>
                      <img
                        style={{ width: "12px", height: "12px" }}
                        src={SynchedIcon}
                        alt="fnIcon"
                      />
                      <DataSynchedText>Synced</DataSynchedText>
                    </DataSynchedWrapper>
                  </Tooltip>
                )}
            </>
          ) : (
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 400,
                color: (isHovered || isSelected) ? "#3c69e7" : "#667085",
                fontStyle: "normal",
                fontFamily:'Inter'
              }}
              noWrap
            >
              {datasourceData.datasourceType}
            </Typography>
          )}

          {datasourceData?.datasourceCategory ==
            IDatasourceCategory.USER_ADDED_DATASOURCE && (
            <img
              onClick={(e: any) => {
                e.stopPropagation();
                onEditDatasource();
              }}
              style={{ width: "18px", height: "18px" }}
              src={ (isHovered || isSelected) ? datasourceEditIconBlue: datasourceEditIcon}
              alt="fnIcon"
            />
          )}
        </div>
      </DatasourceCardWrapper>
    </>
  );
};

// export default DatasourceCard;
export default React.memo(DatasourceCard);
