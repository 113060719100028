import React, { useEffect, useState } from 'react'
import styled from "styled-components"
import Lottie from "lottie-react";
import ZLoading from "./ZLoading.json";
import quotes from "./Quotes.js" 


const Spinner = styled.div`
    height: inherit;
    width: inherit;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top:4rem;

    div > svg {
        height:60% !important;
        width:80% !important;
    }
`



const Quote = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top:-1rem;
        
p {
        font-weight:600;
        width:25%;
        text-align:center;
        font-size:18px;
    }
`

function randomIntFromInterval(min:number, max:number):number { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }


const LoadingScreen = () => {

    const [quoteIndex, setQuoteIndex] = useState(0)

    useEffect(() => {
        const index = randomIntFromInterval(0,quotes.length-1)
        setQuoteIndex(index)
    },[])
    
  return (
        <div>
            <Spinner>
                <Lottie 
                style={{display:"flex",justifyContent:"center"}} 
                loop={true} 
                autoplay={true} 
                animationData={ZLoading} 
                
                />
            </Spinner>
            <Quote>
                <p>
                    {quotes[quoteIndex]}
                </p>
            </Quote>

            
        </div>
  )
}

export default LoadingScreen 