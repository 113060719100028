import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 20px;
`;

export const Label = styled.p`
  font-weight: ${(props: { large?: boolean; bold?: boolean }) =>
    props?.bold ? "600" : "400"};
  font-size: ${(props: { large?: boolean }) =>
    props?.large ? "14px" : "12px"};
  margin-bottom: 4px;
`;

export const ResponsiveWrapper = styled.div`
  width: 50%;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const Info = styled.div`
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 0.75)
    ),
    #c2c9d1;
  padding: 20px;
`;

export const Text = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-style: 14px;
`;

export const Divider = styled.hr`
  margin-top: 4px;
`;

export const Block = styled.div`
    margin-top: 30px;
`

export const Heading = styled.p`
  color: #101828;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 16px;
`

export const SubHeading = styled.p`
  color: #101828;
  font-size: 18px;
  font-weight: 600;
`

export const HelperText = styled.p`
  font-size: 12px;
  color: #475467;
  text-align: center;
`

export const DNDContainer = styled.div`
      border: 1px dashed #EAECF0;
    border-radius: 12px;
    padding: 16px 24px;
`

export const IconContainer = styled.div`
    display: flex;
    justify-content: center;
`

export const IconP = styled.p`
   padding: 8px;
   border-radius: 100%;
   background: #F9FAFB;
`

export const InputWithCopy = styled.div`
    display: flex;  
    justify-content: space-between;
    align-items: center;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 4px;
    width: 30%;
    // medua query
    @media (max-width: 776px) {
      width: 100%;
      margin-bottom: 12px;
    }

`

export const Wrapper2 = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  @media (max-width: 776px) {
      display: grid;
  }


`

export const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 776px) {
      display: grid;
  }

`

export const CopyText = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color : #344054;
  font-size: 16px;
  font-weight: 600;
  border-left: 1px solid #D0D5DD;
  padding: 6px 16px;
  cursor: pointer;

`