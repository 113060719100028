import React, { FunctionComponent, useEffect, useState } from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Paper, Table, TableContainer, TableHead, TableRow, TableCell, Toolbar, TableBody, IconButton, Grid } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import EditIcon from '@material-ui/icons/Edit';
// import defaultImage from './MissingImage.png'
import AddProductModal from "./AddProductModal";
import { IZProductInterface } from "./IZProductInterface";
import { IZOrderConfigInterface } from "./IZOrderConfigInterface";
import { useSelector } from "react-redux";
import { IZOrderInterface } from "./IZOrderInterface";
import { EditProductModal } from "./EditProductModal";


const defaultImage = require("./MissingImage.png")

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function isValidImageURL(str: any) {
    if (typeof str !== 'string') return false;
    return !!str.match(/\w+\.(jpg|jpeg|gif|png|tiff|bmp)$/gi);
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 100,
    },
});
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "white",
        },
        '&:nth-of-type(even)': {
            backgroundColor: "#F5F5F5",
        },
    },
}))(TableRow);

const ProductList: FunctionComponent<{ config: IZOrderConfigInterface, orderProducts: Array<IZProductInterface>, onChange: Function }> = ({ config, orderProducts, onChange }) => {

    const [open, setOpen] = useState<boolean>(false);
    // const [products, setProducts] = useState<Array<IZProductInterface>>(orderProducts || []);
    const [edit, setEdit] = useState<IZProductInterface | undefined>();
    const [editOpen, setEditOpen] = useState<boolean>(false);
    const [index, setIndex] = useState<number | null>(null);
    const classes = useStyles();

    const order: IZOrderInterface = useSelector((state: any) => state.taskCreation[config.key]);

    // useEffect(() => {
    //     setProducts(props.value);
    // }, [props.value])

    const handleOpen = () => { setOpen(true); };
    const handleEditOpen = (index: number, val: IZProductInterface) => {
        setIndex(index);
        setEdit(val);
        setEditOpen(true);
    };
    const handleClose = () => { setOpen(false); };
    const handleEditClose = () => {
        // setEdit();
        setIndex(null);
        setEditOpen(false);
    };
    const handleAddProduct = (product: IZProductInterface) => {
        onChange(config.key, { ...order, products: [...order.products, product] });
        setOpen(false);
    };
    const deleteProduct = (index: any) => {
        let tempProducts = [...order.products];
        tempProducts.splice(parseInt(index));
        onChange(config.key, { ...order, products: tempProducts});
    };

    const handleEditProduct = (product: IZProductInterface) => {
        let tempProducts = [...order.products];
        tempProducts.splice(index!, 1, product);
        onChange(config.key, { ...order, products: tempProducts});        
        setEditOpen(false);
    };

    // useEffect(() => {
    //     setProducts(props.value || products || []);
    // }, [props])

    return (
        <Grid direction="column" justify="flex-start" container xs={12}>
            <div className="flex w-full justify-between pr-20 pt-10">
                <h3 className="ml-3 font-bold">Products</h3>
                <Button variant="outlined" startIcon={<AddCircleRoundedIcon />} color="primary" onClick={handleOpen}
                    style={{ marginRight: "10px", height: "30px", width: "100px" }}>
                    Add
                </Button>
            </div>
            <Grid container style={{ marginTop: "5px" }} >
                <TableContainer style={{ maxWidth: "90vw", overflowX: "auto", paddingLeft: "10px", paddingRight: "10px" }}>
                    <Table size="small" stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow style={{ 'backgroundColor': '#f5f5f5' }}>
                                <TableCell align="left" style={{ color: "#fff", backgroundColor: "#141411" }} ><b>Product Image</b></TableCell>
                                <TableCell align="left" style={{ color: "#fff", backgroundColor: "#141411" }} ><b>Category</b></TableCell>
                                <TableCell align="left" style={{ color: "#fff", backgroundColor: "#141411" }} ><b>SKU/Model</b></TableCell>
                                <TableCell align="left" style={{ color: "#fff", backgroundColor: "#141411" }} ><b>Product Id / Asset Id</b></TableCell>
                                <TableCell align="left" style={{ color: "#fff", backgroundColor: "#141411" }} ><b>Product Name</b></TableCell>
                                <TableCell align="left" style={{ color: "#fff", backgroundColor: "#141411" }} ><b>Quantity</b></TableCell>
                                <TableCell align="left" style={{ color: "#fff", backgroundColor: "#141411" }} ><b>Edit</b></TableCell>
                                <TableCell align="left" style={{ color: "#fff", backgroundColor: "#141411" }} ><b>Delete</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                order?.products?.map((product, index) => (
                                    <StyledTableRow key={Math.random()}>
                                        <TableCell align="left">
                                            {isValidImageURL(product?.productImageUrl) ? (
                                                <a href={product.productImageUrl} target="_blank" rel="noreferrer">
                                                    <img height="30px" width="30px" alt="img" src={product.productImageUrl}>
                                                    </img>
                                                </a>
                                            ) : (
                                                <img height="30px" width="30px" alt="img" src={defaultImage}>
                                                </img>
                                            )}
                                        </TableCell>
                                        <TableCell align="left" scope="row">
                                            {product.productCategory}
                                        </TableCell>
                                        <TableCell align="left" scope="row">
                                            {product.sku}
                                        </TableCell>
                                        <TableCell align="left">{product.productId}</TableCell>
                                        <TableCell align="left">{product.productName}</TableCell>
                                        <TableCell align="left">{product.qty}</TableCell>
                                        <TableCell align="left">
                                            <IconButton size="small" aria-label="edit" color="primary" onClick={event => handleEditOpen(index, product)}>
                                                <EditIcon />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell align="left">
                                            <IconButton size="small" aria-label="delete" color="primary" onClick={event => deleteProduct(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </StyledTableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {open && <AddProductModal onAdd={handleAddProduct} onClose={handleClose}></AddProductModal>}
            {editOpen && <EditProductModal product={edit!} onEdit={handleEditProduct} onClose={handleEditClose}></EditProductModal>}
            <br></br>
        </Grid >
    );
}

export default ProductList;