import jwt_decode from "jwt-decode";
import debounce from "lodash.debounce";
import { initEditor, initMockData } from "lowcode/state/scriptEditorState";
import { useEffect, useRef, useState } from "react";
import ConfigureWorkflowModal from "views/workflows/ConfigureWorkflow/ConfigureWorkflowModal";
import ReactFlow, {
  addEdge,
  Background,
  Controls,
  isEdge,
  updateEdge,
  isNode,
  ReactFlowProvider,
  removeElements,
} from "react-flow-renderer";
import { useDispatch, useSelector, useStore } from "react-redux";
import { checkElementsCollectLocation, recordRSEvent } from "utils/CommonUtils";
import PostPublishModal from 'components/post-publish-modal/PostPublishModal';
import notification from "../../notifications/notifications";
import { TaskFlowService } from "../../providers/data/services/TaskFlowService";
import { generateId } from "../../utils/Utils";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import "./css/flow.css";
import Header from "./Header";
import useWorkFlow from "views/workflows/hooks/useWorkFlow";
import useOnboarding from "views/onboarding/hooks/useOnboarding";

import {
  initWorkflow,
  setIsBeingSaved,
  setFirstLoad,
  setSelectedAction,
  setSelectedNode,
  setSelectedTransition,
  setShowNodeDropdown,
  setTaskTypeNameDescription,
  setShowWorkflowErrors,
  updateElements,
  setSelectedDecisionNode,
  setDeleteIconOnTransition,
  setShowWorkflowConfigModal,
  setContextMenuVisibility,
  setIsSaveWorkflowCalledAtleastOnce,
  setPossibleTerminalNodes,
  setSelectedSystemTransition,
  setIsWorkflowPublishedBefore,
} from "views/workflows/reducers/workflowAction";
import { WorkflowUtils } from "./WorkflowUtils";
import ZorpStateNode from "./nodes/ZorpStateNode";
import ZorpCustomEdge from "./ZorpTransitionEdge";
import FlowTransitionDetails from "./FlowTransitionDetails";
import { NodeTypes } from "./nodes/Nodes.types";
import ZorpDecisionNode from "./nodes/ZorpDecisionNode";
import DecisionInfo from "./DecisionInfo";
import UpdateDecisionNodeName from "views/workflows/UpdateDecisionNodeName";
import FlowNodeUI from "views/workflows/FlowNodeUI";
import HoverStrip from "./HoverStrip";
import { getConfig } from "config/envConfig/Config";
import { useMediaQuery, useTheme } from "@mui/material";
import { NotSupported } from "views/commonComponents/NotSupported";
import { DetectHomeNode } from "views/workflows/DetectHomeUtils";
import { BffService } from 'providers/data/services/BffService';
import FlutterOtherConfig from './FlutterOtherConfig';
import NotFound from "views/NotFound/NotFound";
import ConfigureFlowStep from "views/onboarding/modals/ConfigureFlowStep";
import OnboardingModal from "views/onboarding/modals/OnboardingModal";
import useDeviceType from "utils/useDeviceType";
import useAutomation from "views/automationBuilder/hooks/useAutomation";
import { setShowOnboardingModal } from "views/onboarding/reducer/onboardingAction";
import { useHistory, useLocation } from "react-router-dom";
import { TaskService } from "views/tasks/services/TaskService";
import UpdateStateNodeNameModal from "./UpdateStateNodeName";
import Preview from "views/preview/Preview";


const getId = () => generateId(6);

const Flow = (props) => {
  const theme = useTheme();
  const url = getConfig("appDesigner");
  const accessToken = localStorage.getItem("at");
  const {isOpen} = useAutomation()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();
  const store = useStore()
  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const { loading } = useSelector((state) => state.loading);
  const deviceType = useDeviceType()

  const {
    taskTypeId,
    taskTypeName,
    taskTypeDescription,
    elements,
    selectedTransition,
    selectedAction,
    dataFields,
    isNewTaskType,
    selectedNode,
    selectedDecisionNode,
    deleteIconOnTransition,
    showUpdateDecisionNodeModal,
    showTaskCardConfig,
    showWorkflowConfigModal,
    updateTeam,
    updateSlot,
    updateTask,
    createTask,
    deleteTask,
    isFirstLoad,
    changesInFlutter,
    appTaskCreateConfig,
    isContextMenuVisible,
    workflowNotLoaded,
    postPublish,workflowStatus,
    systemTransitionAutomations,
    createdFromTemplateId,
    version,
    showUpdateNodeNameModal,
    showPreviewModal,
    creationInfo
  } = useWorkFlow();

  const { mockData} = useSelector((state) => state.scriptEditor);
  const {isBeingOnboarded} = useOnboarding()
  const footerStyle = {
    position: "absolute",
    width: "100%",
    // height: selectedNode !=  null ? "35%" : "0%",
    height: "42%",
    bottom: "0%" /* stick to bottom */,
    background: "rgb(254, 254, 254)",
    // borderLeft: "0.5px solid rgba(107, 114, 128)",
    borderTop: "2px solid #EAEAEF",
    // borderBottom: "0.5px solid rgba(107, 114, 128)",
    // paddingLeft: "0.5rem",
    borderLeft: "1px solid #eaeaef",
    // paddingRight: '1.4rem',
    zIndex: "100",
    marginLeft: "-1px",
  };

  useEffect(() => {
    if (window?.location?.hash?.includes("/workflows/create")) {
      recordRSEvent("newworkflow_button_click", {
        context: "builder_listing",
      });
    }
  }, []);

  

  useEffect(() => {
    function handleEscapeKey(event) {
      if (event.code === "Escape") {
        dispatch(setSelectedNode(null));
        // setSelectedStateNode(null);
        dispatch(setSelectedTransition(null));
        dispatch(setSelectedAction(null));
        dispatch(setSelectedSystemTransition(null));
        dispatch(setShowWorkflowConfigModal(false));
      }
    }

    document.addEventListener("keydown", handleEscapeKey);

    recordRSEvent("page_load", {
      context: "builder_canvas",
      workflow_id: taskTypeId,
      workflow_name: taskTypeName,
    });
    dispatch(setShowWorkflowConfigModal(false));
    return () => document.removeEventListener("keydown", handleEscapeKey);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Init workflow
    WorkflowUtils.initBuilder(dispatch, props.id);
  }, [props.id]); // eslint-disable-line react-hooks/exhaustive-deps

  /* In case of a new workflow
    1. Generate a fake name for workflow - "workflow " + user.name + "random id"
    2. Add three steps : New, Completed and Deleted
    3. Connect New -> Completed, New -> Deleted 
 */

  useEffect(() => {
    if (isNewTaskType) {
      const newNodeId = getId();
      const deletedNodeId = getId();
      const completedNodeId = getId();
      const completeTransitionId = getId();

      const initialElements = [
        {
          id: newNodeId,
          name: "First Screen",
          type: "zorpState",
          data: {
            name: "First Screen",
            id: newNodeId,
            type: NodeTypes.STATE_NODE,
            isHomeNode: true,
            isTerminalNode: false,
            onChange: updateStateNode,
          },
          position: { x: 250, y: 200 },
        },
        {
          id: completedNodeId,
          name: "Completed Screen",
          type: "zorpState",
          data: {
            name: "Completed Screen",
            id: completedNodeId,
            type: NodeTypes.STATE_NODE,
            isHomeNode: false,
            isTerminalNode: true,
            onChange: updateStateNode,
          },
          position: { x: 650, y: 100 },
        },
        {
          id: completeTransitionId,
          source: newNodeId,
          target: completedNodeId,
          label: "Complete",
          sourceHandle: "a",
          targetHandle: "b",
          type: "zorpEdge",
          arrowHeadType: "arrowclosed",
          // arrowHeadType: 'arrowclosed',
          data: {
            onChange: updateEdgeProperties,
            type: NodeTypes.TRANSITION,
            event: "Complete",
            label: "Complete",
            id: completeTransitionId,
            collectLocation: false,
          },
        },
      ];
      const updatedElements = elements.concat(initialElements);
      dispatch(updateElements(updatedElements));
      DetectHomeNode.setHomeAndTerminalNodes(
        store.getState()?.builder?.workflow?.canvas?.elements || [],
        dispatch
      );
      DetectHomeNode.markAllReachableNode(store.getState()?.builder?.workflow?.canvas?.elements || [],
      dispatch)

      const at = localStorage.getItem("at");
      const decoded = jwt_decode(at);

      WorkflowUtils.saveNewStep(
        decoded.accountId,
        taskTypeId,
        taskTypeName,
        newNodeId,
        "New",
        saveWorkflow
      );
      WorkflowUtils.saveNewStep(
        decoded.accountId,
        taskTypeId,
        taskTypeName,
        completedNodeId,
        "Completed",
        saveWorkflow
      );
      WorkflowUtils.saveNewStep(
        decoded.accountId,
        taskTypeId,
        taskTypeName,
        deletedNodeId,
        "Deleted",
        saveWorkflow
      );

      dispatch(
        setTaskTypeNameDescription({
          taskTypeName: `Workflow: ${decoded?.name} ${taskTypeId}`,
          taskTypeDescription: "This workflow is meant for ....",
        })
      );
      dispatch(initMockData({taskTypeId}));
    }
  }, [isNewTaskType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // isFirstLoad would be true if any of the already created is loaded for the first time.
    // i.e when you click on any workflow from /workflows page.
    // If firstLoading true, we will not call saveWorkflow().
    // though we need to call saveWorkflow is the workflowStatus is draft.
    // We will also set it to false so that saveWorkflow() is called for any further changes.
    if (isFirstLoad === false) {
      let timeoutId = setTimeout(() => {
        saveWorkflow();
      }, 1000);
      // debounceSaveWorkflow()
      return () => {
        clearTimeout(timeoutId);
      };
    } else {
      // We won't set it to false if isFirstLoad is undefined.
      if (isFirstLoad === true) {
        if(workflowStatus === "draft"){
          saveWorkflow()
        }
        dispatch(setFirstLoad(false));
        WorkflowUtils.validateWorkflow(
          dispatch,
          taskTypeId,
          selectedTransition,
          selectedDecisionNode
        )
          .then(() => console.log("validation completed"))
          .catch((e) => console.log("error while validating on first load", e));
        DetectHomeNode.setHomeAndTerminalNodes(elements, dispatch,true);
        DetectHomeNode.markAllReachableNode(elements,dispatch)

      }
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    elements,
    dataFields,
    taskTypeName,
    taskTypeDescription,
    updateTeam,
    updateSlot,
    updateTask,
    createTask,
    deleteTask,
    changesInFlutter,
    appTaskCreateConfig,
    systemTransitionAutomations
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  

  
  const nodeTypes = {
    zorpState: ZorpStateNode,
    zorpDecisionNode: ZorpDecisionNode,
  };

  const onMove = debounce(() => {
    dispatch(
      setDeleteIconOnTransition({
        posX: null,
        posY: null,
        transition: {},
        onStrip: false,
      })
    );
  }, 1000);

  const edgeTypes = {
    zorpEdge: ZorpCustomEdge,
  };

  const updateEdgeProperties = (updatedTransitionEdge) => {
    const updatedElements = elements.map((ele) => {
      if (ele.id === updatedTransitionEdge.id) {
        updatedTransitionEdge.label = updatedTransitionEdge.data.event;
        return updatedTransitionEdge;
      }
      return ele;
    });
    dispatch(updateElements(updatedElements));

    dispatch(setSelectedTransition(null));
  };

  const removeEdge = (edge) => {
    dispatch(removeElements([edge], elements));
    DetectHomeNode.setHomeAndTerminalNodes(
      store.getState()?.builder?.workflow?.canvas?.elements || [],
      dispatch
    );
    DetectHomeNode.markAllReachableNode(store.getState()?.builder?.workflow?.canvas?.elements || [],dispatch)
  };

  const onConnect = (transitionEdge) => {
    dispatch(setSelectedNode(null));
    transitionEdge.arrowHeadType = "arrowclosed";
    transitionEdge.data = {
      onChange: updateEdgeProperties,
      type: "edge",
      event: "NEXT",
      label: "NEXT",
      id: getId(),
      collectLocation: false,
    };

    const updatedElements = addEdge(
      { ...transitionEdge, type: "zorpEdge" },
      elements
    );

    dispatch(updateElements(updatedElements));
    DetectHomeNode.setHomeAndTerminalNodes(
      store.getState()?.builder?.workflow?.canvas?.elements || [],
      dispatch
    );
    DetectHomeNode.markAllReachableNode(store.getState()?.builder?.workflow?.canvas?.elements || [],
      dispatch)
    // The currently added transition would always be the last element in the updateElements
    // array. Using transitionEdge iss buggy as the transition edge doesn't have
    // a specific id provided by react-flow. So it was causing serious issue
    // when deleting any transition.

    // TOo check if the source is decision node
    const sourceNode = elements?.filter(
      (ele) => ele.id === transitionEdge?.source
    )[0];

    // The transition coming out of decision node should not have any action/conditions within it
    if (sourceNode?.type !== NodeTypes.DECISION_NODE) {
      dispatch(
        setSelectedTransition(updatedElements[updatedElements.length - 1])
      );
    }
  };

  const onElementsRemove = (elementsToRemove) => {
    const updatedElements = removeElements(elementsToRemove, elements);
    dispatch(updateElements(updatedElements));
  };

  const onLoad = (_reactFlowInstance) =>
    setReactFlowInstance(_reactFlowInstance);

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
    dispatch(
      setDeleteIconOnTransition({
        posX: null,
        posY: null,
        transition: {},
        onStrip: false,
      })
    );
  };

  const onNodeDrag = (event) => {
    event.preventDefault();
    dispatch(
      setDeleteIconOnTransition({
        posX: null,
        posY: null,
        transition: {},
        onStrip: false,
      })
    );
    saveWorkflow();
  };

  const onEdgeUpdate = (oldEdge, newConnection) => {
    let isValid = true;
    
    elements.forEach((ele) => {
      if (
        ele.target === newConnection.target &&
        ele.source === newConnection.source &&
        ele.sourceHandle === newConnection.sourceHandle &&
        ele.targetHandle === newConnection.targetHandle
      ) {
        isValid = false;
      }
    });

    // check if the oldEdge source is Decision Node 
    const isSourceOfOldEdgeDecisionNode = elements?.filter((ele) => ele.id === oldEdge?.source && ele.type === NodeTypes.DECISION_NODE)?.length > 0
    
    if(isSourceOfOldEdgeDecisionNode){
      isValid = elements.filter((ele) => newConnection.source === ele.id && ele.type === NodeTypes.DECISION_NODE).length > 0
    }

    if(!isSourceOfOldEdgeDecisionNode){
      isValid = elements.filter((ele) => newConnection.source === ele.id && ele.type === NodeTypes.STATE_NODE).length > 0
      console.log(">>>>", oldEdge, newConnection,isValid);
    }

    
    if (isValid) {
      const updatedElements = updateEdge(oldEdge, newConnection, elements);
      dispatch(updateElements(updatedElements));
      dispatch(
        setDeleteIconOnTransition({
          posX: null,
          posY: null,
          transition: {},
          onStrip: false,
        })
      );
    } else {
      notification("error", "You can only update transitions between decision nodes");
    }
    DetectHomeNode.setHomeAndTerminalNodes(
      store.getState()?.builder?.workflow?.canvas?.elements || [],
      dispatch
    );
    DetectHomeNode.markAllReachableNode(store.getState()?.builder?.workflow?.canvas?.elements || [],
      dispatch)
    dispatch(setSelectedTransition(null));
  };

  const updateStateNode = (updatedStateNode) => {
    const updatedElements = elements.map((stateNode) => {
      if (stateNode.id === updatedStateNode.status) {
        stateNode.data.name = updatedStateNode.statusName;
        stateNode.name = updatedStateNode.statusName;
        return stateNode;
      }
      return stateNode;
    });
    dispatch(updateElements(updatedElements));

    dispatch(setSelectedNode(null));
  };

  const onDrop = (event) => {
    event.preventDefault();
    // To not show the delete and info icon on top of transition label.
    dispatch(
      setDeleteIconOnTransition({
        posX: null,
        posY: null,
        transition: {},
        onStrip: false,
      })
    );
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = event.dataTransfer.getData("application/reactflow");
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });
    const newNodeId = getId();
    let newNode = {};
    if (type === NodeTypes.STATE_NODE) {
      newNode = {
        id: newNodeId,
        name: newNodeId,
        description: "Please describe this event!",
        type,
        position,
        data: {
          name: newNodeId,
          id: newNodeId,
          type: "node",
          onChange: updateStateNode,
          isHomeNode: false,
        },
      };
    } else if (type === NodeTypes.DECISION_NODE) {
      newNode = {
        id: newNodeId,
        name: "DECISION_NODE",
        description: "This is the decision node!",
        type,
        position,
        data: {
          name: "DECISION_NODE",
          id: newNodeId,
          type: "node",
          description: "",
          expression: "",
          onChange: updateStateNode,
          isHomeNode: false,
        },
      };
    }
    const updatedElements = elements.concat(newNode);
    dispatch(updateElements(updatedElements));
    //setElements((es) => es.concat(newNode));
    dispatch(setSelectedNode(newNode));
    // setSelectedStateNode(newNode);

    WorkflowUtils.addNewStep(
      type,
      taskTypeId,
      taskTypeName,
      dispatch,
      event,
      reactFlowWrapper,
      reactFlowInstance,
      elements,
      saveWorkflow
    );
  };

  const addNodeOnClick = (event, reactFlowWrap, nodeType) => {
    event.preventDefault();

    const reactFlowBounds = reactFlowWrap.getBoundingClientRect();
    const type = nodeType;
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });
    const newNodeId = getId();
    const newNode = {
      id: newNodeId,
      name: "UNNAMED_STEP",
      description: "Please describe this event!",
      type,
      position,
      data: {
        name: "UNNAMED_STEP",
        id: newNodeId,
        type: "node",
        onChange: updateStateNode,
        isHomeNode: false,
      },
    };

    const updatedElements = elements.concat(newNode);
    dispatch(updateElements(updatedElements));
    DetectHomeNode.setHomeAndTerminalNodes(
      store.getState()?.builder?.workflow?.canvas?.elements || [],
      dispatch
    );
    DetectHomeNode.markAllReachableNode(store.getState()?.builder?.workflow?.canvas?.elements || [],
      dispatch)
    //setElements((es) => es.concat(newNode));
    // dispatch(setSelectedNode(newNode));
    // setSelectedStateNode(newNode);
    dispatch(setShowNodeDropdown(false));

    WorkflowUtils.addNewStep(
      taskTypeId,
      taskTypeName,
      dispatch,
      event,
      reactFlowWrap,
      reactFlowInstance,
      elements,
      saveWorkflow,
      type
    );
  };

  const onNodeClick = (_e, selectedElement) => {
    if (!WorkflowUtils.checkValidElementClick(_e)) {
      return;
    }
    if (isContextMenuVisible) {
      dispatch(setContextMenuVisibility(false));
      return;
    }
    if (selectedElement && isEdge(selectedElement)) {
      const isValidClick = elements.filter((ele) => selectedElement.source === ele.id &&  ele.type === NodeTypes.STATE_NODE).length > 0;
      if(isValidClick) {
        console.log("Transition clicked", selectedElement)
        const updateElements = checkElementsCollectLocation(selectedElement);
        dispatch(setSelectedDecisionNode(null));
        dispatch(setShowWorkflowConfigModal(false));
        dispatch(setSelectedTransition(updateElements));
        dispatch(setSelectedNode(null));
        dispatch(
          setDeleteIconOnTransition({
            xPos: null,
            yPos: null,
            transition: {},
            onStrip: false,
          })
        );
      }
      
    } else if (selectedElement && isNode(selectedElement)) {
      // Also fetch the connected edges/transitions from this node
      if (!reactFlowInstance || !reactFlowInstance.toObject()) return;
      if (selectedElement.type === NodeTypes.DECISION_NODE) {
        return;
      }
      dispatch(setSelectedNode(selectedElement));
      dispatch(setShowWorkflowConfigModal(false));
      dispatch(setSelectedTransition(null));
      dispatch(setSelectedAction(null));
      dispatch(setSelectedDecisionNode(null));
      dispatch(
        setDeleteIconOnTransition({
          xPos: null,
          yPos: null,
          transition: {},
          onStrip: false,
        })
      );
    }
  };

  const onCanvasClick = () => {
    dispatch(setSelectedNode(null));
    // setSelectedStateNode(null);
    dispatch(setSelectedTransition(null));
    dispatch(setShowWorkflowConfigModal(false));
    dispatch(setSelectedAction(null));
    dispatch(setSelectedDecisionNode(null));
    dispatch(setSelectedSystemTransition(null));
    dispatch(
      setDeleteIconOnTransition({
        posX: null,
        posY: null,
        onStrip: false,
        transition: {},
      })
    );
    dispatch(setShowWorkflowErrors(false));
    // setSelectedTransition(null);
  };

  const saveWorkflow = () => {
    dispatch(setIsBeingSaved(true));
    if (!taskTypeId) {
      dispatch(setIsBeingSaved(false));
      return;
    }
    if (!reactFlowInstance || !reactFlowInstance.toObject()) {
      dispatch(setIsBeingSaved(false));
      return;
    }
    const flowGraph = reactFlowInstance.toObject();


    const states = [];
    const transitions = [];

    Array.isArray(flowGraph?.elements) &&
      flowGraph.elements.forEach((ele) => {
        if (ele?.type === "zorpState") {
          states.push({
            id: ele?.data?.id,
            name: ele?.data?.name,
          });
        } else {
          const source = flowGraph.elements.filter(
            (e) => e.type === "zorpState" && e.data?.id === ele.source
          )?.[0]?.data?.id;
          const target = flowGraph.elements.filter(
            (e) => e.type === "zorpState" && e.data?.id === ele.target
          )?.[0]?.data?.id;

          transitions.push({
            event: ele?.data?.event,
            id: ele?.data?.id,
            source: source,
            target: target,
          });
        }
      });

    let updatedDate = new Date();
    const taskFlowPayload = {
      updatedOn: updatedDate.toISOString(),
      taskType: taskTypeId,
      taskTypeDisplayName: taskTypeName,
      taskTypeDescription: taskTypeDescription,
      version, 
      creationInfo,
      flowConfig: {
        states: states,
        transitions: transitions,
      },
      createdFromTemplateId: createdFromTemplateId, 
      inventoryBased: true,
      canvas: flowGraph,
      mockData,
      dataFields,
      updateTeam,
      updateSlot,
      updateTask,
      createTask,
      deleteTask,
      appTaskCreateConfig,
      systemTransitionAutomations
    };
    TaskFlowService.saveTaskFlow(taskFlowPayload, taskTypeId)
      .then(async (res) => {
        if (res?.code && res?.code.indexOf("200") < 0) {
          // Error
          if (res?.code === "401") {
            notification("error", res.message);
          } else {
            notification("error", res.message);
          }
          dispatch(setIsBeingSaved(false));
        } else {
          setTimeout(() => {
            dispatch(setIsBeingSaved(false));
            // setIsSaving(false);
          }, 1000);

          // This updates the script variables
          dispatch(initEditor(taskTypeId));
          dispatch(setIsSaveWorkflowCalledAtleastOnce(true))


          // Validate workflow data
          await WorkflowUtils.validateWorkflow(
            dispatch,
            taskTypeId,
            selectedTransition,
            selectedDecisionNode,
            store.getState()?.validWorkflow?.errors,
            store
          );
        }
      })
      .catch((err) => {
        setTimeout(() => {
          dispatch(setIsBeingSaved(false));
        }, 2000);
        console.log("Error", err);
      });
  };

  return workflowNotLoaded ? <NotFound/> : deviceType === "mobile" ? (
    <NotSupported taskTypeId={taskTypeId} taskTypeName={taskTypeName} />
  ) : (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: `${
            selectedTransition ||
            selectedDecisionNode ||
            showWorkflowConfigModal
              ? "75% 25%"
              : ""
          }`,
        }}
      >
        <div style={{ marginTop: "-8px" }} className="react__flowWrapper">
          {loading ? (
            <LoadingScreen />
          ) : (
            <>
              <Header addNodeOnClick={addNodeOnClick} />

              <>
                <div
                  style={{ height: "inherit" }}
                  className="bg-gray-50 flex-auto flex-col"
                >
                  <>
                    {showUpdateDecisionNodeModal && <UpdateDecisionNodeName />}
                    {showUpdateNodeNameModal && <UpdateStateNodeNameModal />}
                    <div className="canvas">
                      <ReactFlowProvider>
                        {/* Dont change the className "reactflow-wrapper" as it is used to add a new node on click */}
                        <div
                          className="reactflow-wrapper"
                          ref={reactFlowWrapper}
                        >
                          <ReactFlow
                            elements={elements}
                            nodeTypes={nodeTypes}
                            edgeTypes={edgeTypes}
                            onElementClick={onNodeClick}
                            // onEdReactgeUpdate={onEdgeUpdate}
                            onPaneClick={onCanvasClick}
                            onNodeDoubleClick={onCanvasClick}
                            onConnect={onConnect}
                            onElementsRemove={onElementsRemove}
                            onLoad={onLoad}
                            onDrop={onDrop}
                            onDragOver={onDragOver}
                            onEdgeUpdate={onEdgeUpdate}
                            onNodeDragStop={onNodeDrag}
                            minZoom={0.75}
                            onMove={onMove}
                            deleteKeyCode={null}
                          >
                            <Background
                              variant="lines"
                              color="#E5E5E5"
                              gap={64}
                              size={0.75}
                            />
                            <Controls />

                            {/* {deleteIconOnTransition.posX && <HoverStrip />} */}
                          </ReactFlow>
                        </div>
                      </ReactFlowProvider>
                    </div>
                  </>
                </div>
              </>
            </>
          )}
          {
          isBeingOnboarded && (
            <OnboardingModal>
              <ConfigureFlowStep/>
            </OnboardingModal>
          )
          }
        </div>
        <div
          style={{
            height: `${
              selectedTransition || showWorkflowConfigModal ? "94vh" : ""
            }`,
            overflowY: "auto",
          }}
        >
          {selectedNode && <FlowNodeUI saveWorkflow={saveWorkflow} />}
          {selectedTransition && (
            <FlowTransitionDetails
              onUpdate={updateEdgeProperties}
              onDelete={removeEdge}
              selectedTransitionEdge={selectedTransition}
              onClose={() => dispatch(setSelectedTransition(null))}
            />
          )}
          {selectedDecisionNode && <DecisionInfo />}
          {showWorkflowConfigModal && <ConfigureWorkflowModal />}
        </div>
        
        {showTaskCardConfig?.show && (
          <FlutterOtherConfig/>
        )}
        {
          showPreviewModal &&
          (
            <Preview/>
          )
        }
        
        {/* {postPublish && <PostPublishModal />} */}
        
            
      </div>
      
    </>
  );
};

export default Flow;
