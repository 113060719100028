import React, { ReactElement } from 'react'
import { CalloutDiv } from '../styles/OnboardingStyles'

type Props = {
    text: ReactElement<any,any>
}

const Callout = ({text}: Props) => {
  return (
    <CalloutDiv>
      {text}
        
    </CalloutDiv>
  )
}

export default Callout