import { makeStyles, Grid, Paper, Checkbox, MenuItem, Select } from '@material-ui/core';
import ProductList from './ProductList';
import React, { FunctionComponent, useState, useEffect } from "react";
import { UseFormRegister, FieldErrors } from "react-hook-form";
import { IZOrderConfigInterface } from './IZOrderConfigInterface';
import { IZOrderInterface } from './IZOrderInterface';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        boxShadow: "none",
        backgroundColor: "transparent"
    },
}));

export const ZOrder: FunctionComponent<{ config: IZOrderConfigInterface, onChange: Function, register: UseFormRegister<any>, errors: FieldErrors<any> }> = ({ config, onChange, register, errors }) => {
    const [orderId, setOrderId] = useState(config?.value?.orderId);
    const [products, setProducts] = useState(config?.value?.products || []);

    useEffect(() => {
        if (config.value)
            onChange(config.key, config.value)
        else {
            const newOrder: IZOrderInterface = {
                orderId: "",
                products: []
            }
            onChange(config.key, newOrder);
        }
    }, []);

    // const [delInstructions, setDelInstructions] = React.useState(props.order.delInstructions);
    // const [taskTypeFields, setTaskTypeFields] = useState({});
    // const [uiFieldsLeft, setUiFieldsLeft] = useState([]);
    // const [uiFieldsRight, setUiFieldsRight] = useState([]);

    // uiFieldsLeft.push(
    //     <Grid container className="mt-2">
    //         <Grid item className="bt-col-md-4">
    //             <strong>Order Id: </strong>
    //         </Grid>
    //         <Grid item className="bt-col-md-8">
    //             <TextValidator id="orderId"
    //                 autoComplete={false}
    //                 value={orderId}
    //                 variant="outlined"
    //                 size="small"
    //                 className="inc-t0 oid-wd"
    //                 onChange={(event) => handleOrderIdChange(event.target.value)}
    //                 validators={['required']}
    //                 errorMessages={['This field is required']}
    //                 style={{ width: "100%" }}
    //             />
    //         </Grid>
    //     </Grid>
    // );

    // const handleTaskTypeFieldsChange = (fName, val) => {
    //     let taskTypeFieldJson = {
    //         'orderId': orderId,
    //         'products': {
    //             'DELIVERY': deliveryProducts,
    //             'REPAIR': repairProducts,
    //             'PICKUP': pickupProducts
    //         }
    //     }
    //     taskTypeFields[fName] = val;
    //     setTaskTypeFields(taskTypeFields);

    //     getTaskTypeFields(props.taskType).forEach((f) => {
    //         taskTypeFieldJson[f.key] = taskTypeFields[f.key];
    //     })
    //     props.onChange(taskTypeFieldJson)
    // }

    // let counter = 1;
    // getTaskTypeFields(props.taskType).forEach((f) => {
    //     if (f.type === "select"){
    //         taskTypeFields[f.key] =  props.order ? (props.order[f.key] ?  props.order[f.key] : f.options[0]?.value) : "";
    //         // handleTaskTypeFieldsChange(f.key, taskTypeFields[f.key])
    //     }            
    //     else
    //         taskTypeFields[f.key] = props.order ? (props.order[f.key] ? props.order[f.key] : "") : "";
    //     let uiField = (
    //         <Grid container className="mt-2">
    //             <Grid item className="bt-col-md-4">
    //                 <strong>{f.label}: </strong>
    //             </Grid>
    //             <Grid item className="bt-col-md-8">
    //                 {f.type === "select" ?
    //                     <Select
    //                         value={taskTypeFields[f.key] ? taskTypeFields[f.key] : f.options[0].value}
    //                         variant="outlined"
    //                         onChange={(event) => handleTaskTypeFieldsChange(f.key, event.target.value)}
    //                         style={{ width: "100%", height: "40px" }}
    //                     >
    //                         {f.options?.map((o) => {
    //                             return (
    //                                 <MenuItem value={o.value}>{o.label}</MenuItem>
    //                             )
    //                         })}
    //                     </Select>
    //                     // <Checkbox
    //                     //     checked={taskTypeFields[f.key] === true ? true : false}
    //                     //     onChange={(event) => handleTaskTypeFieldsChange(f.key, event.target.checked)}
    //                     //     style={{ float: "left" }}
    //                     //     inputProps={{ 'aria-label': 'controlled' }}
    //                     // />
    //                     :
    //                     <TextValidator id={f.key}
    //                         autoComplete={false}
    //                         value={taskTypeFields[f.key]}
    //                         variant="outlined"
    //                         size="small"
    //                         className="inc-t0 oid-wd"
    //                         onChange={(event) => handleTaskTypeFieldsChange(f.key, event.target.value)}
    //                         // validators={['required']}
    //                         // errorMessages={['This field is required']}
    //                         style={{ width: "100%" }}
    //                     />}
    //             </Grid>
    //         </Grid>
    //     );
    //     if (counter % 2 === 0) {
    //         uiFieldsLeft.push(uiField);
    //     } else {
    //         uiFieldsRight.push(uiField);
    //     }
    //     counter++;
    // });


    const classes = useStyles();

    // useEffect(() => {
    //     setOrderId(props.order.orderId);
    //     setdelInstructions(props.order.delInstructions);
    //     setDeliveryProducts(props.order.deliveryProducts);
    //     setRepairProducts(props.order.repairProducts);
    //     setPickupProducts(props.order.pickupProducts);
    // }, [props.order])

    // const handleOrderIdChange = (val) => {
    //     setOrderId(val);
    //     props.onChange(handleOtherFields({
    //         'orderId': val,
    //         // 'delInstructions': delInstructions,
    //         'products': {
    //             'DELIVERY': deliveryProducts,
    //             'REPAIR': repairProducts,
    //             'PICKUP': pickupProducts
    //         }
    //     }))
    // }

    // const handleProductChange = (val) => {
    //     setDeliveryProducts(val);
    //     props.onChange(handleOtherFields({
    //         'orderId': orderId,
    //         // 'delInstructions': delInstructions,
    //         'products': {
    //             'DELIVERY': val,
    //             'REPAIR': repairProducts,
    //             'PICKUP': pickupProducts
    //         }
    //     }));
    // }

    // const handleRepairProductChange = (val) => {
    //     setRepairProducts(val);
    //     props.onChange(handleOtherFields({
    //         'orderId': orderId,
    //         // 'delInstructions': delInstructions,
    //         'products': {
    //             'DELIVERY': deliveryProducts,
    //             'REPAIR': val,
    //             'PICKUP': pickupProducts
    //         }
    //     }))
    // }

    // const handlePickupProductChange = (val) => {
    //     setPickupProducts(val);
    //     props.onChange(handleOtherFields({
    //         'orderId': orderId,
    //         // 'delInstructions': delInstructions,
    //         'products': {
    //             'DELIVERY': deliveryProducts,
    //             'REPAIR': repairProducts,
    //             'PICKUP': val
    //         }
    //     }))
    // }

    // function handleOtherFields(json) {
    //     for (let key in taskTypeFields) {
    //         json[key] = taskTypeFields[key];
    //     }

    //     return json;
    // }

    // const handleDelInstructionsChange = (val) => {
    //     setDelInstructions(val);
    //     props.onChange({
    //         'orderId': orderId,
    //         'delInstructions': val,
    //         'products': {
    //             'DELIVERY': deliveryProducts,
    //             'REPAIR': repairProducts,
    //             'PICKUP': pickupProducts
    //         }
    //     })
    // }



    // useEffect(() => {
    //     setOrderId(props.order.orderId);
    //     // setDelInstructions(props.order.delInstructions);
    //     setDeliveryProducts(props.order.products.DELIVERY || deliveryProducts);
    //     setRepairProducts(props.order.products.REPAIR || repairProducts);
    //     setPickupProducts(props.order.products.PICKUP || pickupProducts);
    //     setTaskTypeFields({});
    //     setUiFieldsLeft([]);
    //     setUiFieldsRight([]);
    // }, [props])


    return (
        <>
            {/* <div className="bt-row">
                <div className="bt-col-md-6">
                    {uiFieldsLeft}
                </div>
                <div className="bt-col-md-6">
                    {uiFieldsRight}
                </div>
            </div> */}


            <div className="col-span-8">
                <ProductList config={config} orderProducts={products} onChange={onChange} />
                {/* <ProductList id="pickupProducts" title='Pickup' value={pickupProducts} onChange={(products) => handlePickupProductChange(products)} />
                        <ProductList id="repairProducts" title='Repair' value={repairProducts} onChange={(products) => handleRepairProductChange(products)} /> */}
            </div>
        </>
    )
}
