import {
  FormControl,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { PageCategoryEnum, recordPageVisit } from "config/Rudderstack";
import { DocsLink } from "constants/CommonConstants";
import notification from "notifications/notifications";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { TaskFormBody } from "render-engine/render.styles";
import { recordRSEvent } from "utils/CommonUtils";
import {
  ZButton,
  ZLabel,
} from "views/commonComponents/commonComponents.styles";
import { ZInfo } from "views/commonComponents/ZInfo";
import ZPhoneInput from "views/commonComponents/ZPhoneInput";
import ZSelect from "views/commonComponents/ZSelect";
import ZInput from "views/commonComponents/ZTextInput";
import Callout from "views/onboarding/components/Callout";
import InfoIcon from "@mui/icons-material/Info";
import {
  clearUserCreationData,
  clearUsersPageData,
  createUserAction,
  getTeamsList,
  getUserById,
  getUsersList,
  updateUserAction,
} from "./reducers/Users.actions";
import { BsInfoCircleFill } from "react-icons/bs";
import useLoggedInUser from "hooks/useLoggedInUser";

const UserCreate = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id = "" } = useParams();

  let {
    rolesList = [],
    teamsListOptions = [],
    userDetails = {},
    isCreatedUserStatus = "",
    isUpdatedUserStatus = "",
  } = useSelector((state) => state.usersPage);

  const loggedInUser = useLoggedInUser();

  if (
    loggedInUser?.accountId === "e1s2v8" &&
    loggedInUser?.role === "whhvxm"
  ) {
    rolesList = rolesList.filter((role) => {
      return (
        role.value === "cn4wq4" ||
        role.value === "whhvxm" ||
        role.value === "oizh3g"
      );
    });
  } else if (
    loggedInUser?.accountId === "e1s2v8" &&
    loggedInUser?.role === "oizh3g"
  ) {
    rolesList = rolesList.filter((role) => {
      return role.value === "cn4wq4" || role.value === "oizh3g";
    });
  }

  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const initForm = (data) => {
    const {
      name = "",
      email = "",
      role = "",
      teams = [],
      phone: { num = "", countryCode = {} } = {},
    } = data;
    setValue("name", name);
    setValue("email", email);
    setValue("role", { label: role, value: role });
    setValue(
      "team",
      teams.map((data) => ({ label: data?.name, value: data?.teamId }))
    );
    setValue("phone", `${countryCode}${num}`);
    setValue("phoneCode", `${countryCode}`);
  };

  useEffect(() => {
    dispatch(getUsersList());
    dispatch(getTeamsList());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    recordPageVisit(PageCategoryEnum.user, "user_create");
  }, [props]);

  useEffect(() => {
    if (id) dispatch(getUserById(id));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userDetails?.userId) {
      initForm(userDetails);
    }
  }, [userDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      dispatch(clearUsersPageData());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      isCreatedUserStatus.match(/200|201/) ||
      isUpdatedUserStatus.match(/200|201/)
    ) {
      history.push("/settings/users");
      dispatch(clearUserCreationData());
    }
  }, [isCreatedUserStatus, isUpdatedUserStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const cancelCreate = () => {
    notification("info", "User creation cancelled!");
    recordRSEvent("create_user_button_click", {
      context: "user_create_cancelled",
      user_name: getValues("name"),
      user_phone: getValues("phone"),
      user_role: getValues("role"),
      user_team: [getValues("team")],
    });
    history.push("/settings/users");
  };

  const createUser = (data) => {
    // Check if password and confirm password are the same?
    if (data?.password !== data?.confirmPassword)
      return notification(
        "error",
        "Password and Confirm Password do not match"
      );

    const userPayLoad = {
      name: data?.name,
      phone: {
        num: data?.phone?.slice(data?.phoneCode?.length),
        countryCode: data?.phoneCode,
      },
      role: data?.role?.value,
      teamIds: Array.isArray(data?.team)
        ? data?.team?.map((team) => team?.value)
        : [data?.team?.value],
      password: data?.password,
      email: data?.email,
      externalId: data?.externalId,
    };
    if (id) {
      try {
        dispatch(updateUserAction({ ...userPayLoad, userId: id }));
      } catch (error) {
        recordRSEvent("edit_user_button_click", {
          context: "user_edit_failed",
          user_name: data?.name,
          user_phone: data?.phone,
          user_role: data.role,
          user_team: [data?.team],
        });
      }
    } else {
      try {
        dispatch(createUserAction(userPayLoad));
      } catch (error) {
        console.log(error);
        recordRSEvent("create_user_button_click", {
          context: "user_create_failed",
          user_name: data?.name,
          user_phone: data?.phone,
          user_role: data.role,
          user_team: [data?.team],
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(createUser)} style={{ padding: "2rem" }}>
      <TaskFormBody>
        <div className="contentWrapper">
          <Callout
            text={
              <p>
                {" "}
                You can create your users here.{" "}
                <a
                  style={{ color: "blue" }}
                  href={DocsLink.USERS}
                  target="_black"
                  rel="noreferrer"
                >
                  {" "}
                  Know more about User{" "}
                </a>{" "}
              </p>
            }
          />
          <Grid container spacing={2} mt={3} mb={4}>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" fullWidth>
                <ZLabel>
                  {" "}
                  Name <span className="text-red-500">*</span>{" "}
                </ZLabel>
                <ZInput
                  register={register}
                  fullWidth
                  name="name"
                  validations={{ required: true }}
                />
                {errors && errors["name"] && (
                  <ZInfo
                    className="mt-1"
                    errors={errors}
                    name={"name"}
                    requiredMessage={"Name is required"}
                    isError={true}
                  />
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" fullWidth>
                <ZLabel>Phone Number</ZLabel>
                <ZPhoneInput
                  name={"phone"}
                  control={control}
                  clearErrors={clearErrors}
                  getValues={getValues}
                  setValue={setValue}
                  secondaryName={"phoneCode"}
                />
                {errors && errors["phone"] && (
                  <ZInfo
                    className="mt-1"
                    errors={errors}
                    name={"phone"}
                    requiredMessage={"Phone number is required"}
                    isError={true}
                  />
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" fullWidth>
                <ZLabel sx={{ marginBottom: "-8px" }}>
                  Role <span className="text-red-500">*</span>
                </ZLabel>
                <ZSelect name="role" control={control} options={rolesList} />
                {errors && errors["role"] && (
                  <ZInfo
                    className="mt-1"
                    errors={errors}
                    name={"role"}
                    requiredMessage={"Role is required"}
                    isError={true}
                  />
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" fullWidth>
                <ZLabel sx={{ marginBottom: "-8px" }}>
                  Team <span className="text-red-500">*</span>
                </ZLabel>
                <ZSelect
                  name="team"
                  control={control}
                  options={teamsListOptions}
                />
                {errors && errors["team"] && (
                  <ZInfo
                    className="mt-1"
                    errors={errors}
                    name={"team"}
                    requiredMessage={"Team is required"}
                    isError={true}
                  />
                )}
              </FormControl>
            </Grid>
            {!id && (
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth>
                  <ZLabel>
                    Password <span className="text-red-500">*</span>
                    <Tooltip title="Minimum 8 characters, At least 1 Uppercase, At least 1 lower case, At least one number, At least one special character, No consecutive alphabets,  No consecutive numbers, No username">
                    <IconButton size="medium">
                      <InfoIcon fontSize="medium" />
                    </IconButton>
                  </Tooltip>
                  </ZLabel>
                  <ZInput
                    register={register}
                    type="password"
                    fullWidth
                    name="password"
                    validations={{
                      required: true,
                      minLength: {
                        value: 8,
                        message: "Password should be atleast 8 characters",
                      },
                    }}
                  />
                  {errors && errors["password"] && (
                    <ZInfo
                      className="mt-1"
                      errors={errors}
                      name={"password"}
                      requiredMessage={"Password is required"}
                      isError={true}
                    />
                  )}
                </FormControl>
              </Grid>
            )}
            {!id && (
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth>
                  <ZLabel>
                    Confirm Password <span className="text-red-500">*</span>
                  </ZLabel>
                  <ZInput
                    register={register}
                    type="password"
                    fullWidth
                    name="confirmPassword"
                    validations={{
                      required: true,
                      minLength: {
                        value: 8,
                        message: "Password should be atleast 8 characters",
                      },
                    }}
                  />
                  {errors && errors["confirmPassword"] && (
                    <ZInfo
                      className="mt-1"
                      errors={errors}
                      name={"confirmPassword"}
                      requiredMessage={"Confirm Password is required"}
                      isError={true}
                    />
                  )}
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" fullWidth>
                <ZLabel>Email</ZLabel>
                <ZInput
                  register={register}
                  fullWidth
                  name="email"
                  validations={{ required: false }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" fullWidth>
                <ZLabel>
                  External Id
                  <Tooltip title="This is the user id used within your ogranization">
                    <IconButton size="medium">
                      <InfoIcon fontSize="medium" />
                    </IconButton>
                  </Tooltip>
                </ZLabel>
                <ZInput
                  register={register}
                  fullWidth
                  name="externalId"
                  validations={{ required: false }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <hr />
          <Box
            mt={16}
            display="flex"
            alignItems="center"
            justifyContent="start"
          >
            <ZButton type="submit" variant="contained">
              {!id ? `Create User` : `Update User`}
            </ZButton>
            <ZButton
              variant="contained"
              onClick={cancelCreate}
              secondary
              className="ml-2"
            >
              Cancel
            </ZButton>
          </Box>
        </div>
      </TaskFormBody>
    </form>
  );
};

export default UserCreate;
