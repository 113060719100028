import notification from "notifications/notifications";
import { AccountService } from "providers/data/services/AccountService";
import { Dispatch } from "react";
import { getAccountId } from "utils/CommonUtils";
import { ABMetadataTypeEnum, IAutomation } from "views/automationBuilder/types/ABType";

export const userAutomationSaveHandler = async ({
    allAutomations,
    dispatch,
    store,
    callback,
}: {
    allAutomations: IAutomation[];
    dispatch: Dispatch<any>;
    store?: any;
    callback: () => void;
}) => {
    const accountId = getAccountId();

    const teamAutomation = {
        automationUiFormat:allAutomations,
        automationType:ABMetadataTypeEnum.USER
    }
    
    const response = await AccountService.updateAutomationData(accountId, teamAutomation);
    
    if (response?.code == '200') {
        notification("success", "User Automation Saved")
        callback()
    } else {
        notification("error", response?.message || `User Automation Failed`)
    }
};