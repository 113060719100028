import React, { useEffect } from 'react'
import useEntity from '../hooks/useEntity'
import NoEntity from '../components/NoEntity'
import {useHistory} from "react-router-dom"

type Props = {}

const TableLanding = (props: Props) => {
    const { selectedEntity,allEntities } = useEntity()
    const history = useHistory()
    useEffect(() => {
      if(allEntities && allEntities.length > 0 && selectedEntity) {
        history.push(`/table/${selectedEntity?.tableType}`)
      }
      
    },[selectedEntity?.tableType])
  return (
    <>
        {
          allEntities && allEntities.length === 0 && <NoEntity/> 
        }
        
    </>
  )
}

export default TableLanding