import { useSelector } from 'react-redux'

const useAccount = () => {

    const {account} = useSelector((state: any) => state)

    const accountInfo = account.account
    const whitelabelling = account.whitelabelling
  

    return {
        account: accountInfo,
        whitelabelling: whitelabelling
    }
  
}

export default useAccount