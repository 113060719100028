import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import consoleImg from "assests/console.svg";
import runScript from "assests/run_script_btn.svg";
import saveImg from "assests/save.svg";
import { ISaveType } from "lowcode/ZJSEditorLayout";
import styled, { useTheme } from "styled-components";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { getOperatingSystem } from 'utils/Utils';
const outputInitialTxt = "Output goes here";

interface ILowCodeEditorFooter {
  output: any;
  handleSave: (context: ISaveType) => void;
  jsScript: any;
  onConsoleClick: () => void;
  isDirty: boolean;
  runCode: () => void;
  isScriptRunning: boolean;
}

const LowCodeEditorFooter = ({ output, handleSave, jsScript, isDirty, onConsoleClick, runCode, isScriptRunning }: ILowCodeEditorFooter) => {
  const theme = useTheme();
  const [commandKey] = useState(getOperatingSystem() == 'MacOS' ? '⌘' : 'Ctrl');
  const [ctrlKey] = useState(getOperatingSystem() == 'MacOS' ? '^' : 'Ctrl');
  const CircularDot = styled.div`
    width: 10px; /* Adjust the width and height as needed */
    height: 10px;
    margin-left: 20px;
    margin-right: 5px;
    border-radius: 50%; /* Makes the element circular */
    background-color: ${theme.color.primary}; /* Set the background color of the dot */
  `;

  return (
    <div style={{
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '12px 16px',
      backgroundColor: '#F9FAFB'
    }}>
      <div className="">
        <Tooltip placement='right-start' title={<Typography color={'white'}>{`${commandKey} + C`}</Typography>}>
          <ZButton
            onClick={() => {
              onConsoleClick();
            }}
            startIcon={<img src={consoleImg} />}
            style={{
              color: '#344054',
              height: '36px',
              fontWeight: 600,
              fontSize: '14px',
              border: '1px solid #D0D5DD',
              width: 'auto',
              textTransform: 'none'
            }}
            variant="outlined"
          >
            Console
          </ZButton>
        </Tooltip>
      </div>

      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '12px',
        marginRight: '16px'
      }}>
        {isDirty && (
          <>
            <CircularDot />
            <Typography color={theme.color.primary} fontWeight={"bold"}>Unsaved Changes</Typography>
          </>
        )}

        <Tooltip placement='right-start' title={<Typography color={'white'}>{`${ctrlKey} + Enter`}</Typography>}>
          <LoadingButton
            onClick={async (e) => runCode()}
            loading={isScriptRunning}
            disabled={isScriptRunning}
            loadingPosition="start"
            startIcon={<img src={runScript} />}
            style={{
              fontSize: '13px',
              fontWeight: 600,
              height: '36px',
              color: '#344054',
              border: '1px solid #D0D5DD',
              padding: '8px 14px',
              cursor: isScriptRunning ? 'no-drop' : 'pointer'
            }}
            sx={{ height: '36px' }}
            variant="outlined"
          >
            Run Script
          </LoadingButton>
        </Tooltip>



        {!output?.error && (
          <Tooltip disableHoverListener={!isDirty} title={<Typography>{'Run the Script Before Saving'}</Typography>}>
            <span>

              <ZButton
                style={{
                  fontWeight: 600,
                  fontSize: '14px',
                  fontStyle: 'normal',
                  marginRight: '16px'
                }}
                startIcon={<img height={'20px'} width={'20px'} src={saveImg} />}
                variant="contained"
                onClick={async (e) => handleSave(ISaveType.SAVE)}
                disabled={
                  !jsScript ||
                  output?.error ||
                  output?.data === outputInitialTxt ||
                  !output?.allowSave ||
                  isDirty
                }
              >
                Save
              </ZButton>
            </span>
          </Tooltip>
        )}

        {output?.error && (
          <ZButton
            style={{
              fontWeight: 600,
              fontSize: '14px',
              fontStyle: 'normal',
              marginRight: '16px'
            }}
            variant="contained"
            onClick={(e) =>
              handleSave(ISaveType.FORCE_SAVE)
            }
            disabled={!output?.allowSave}
            danger>
            Force Save
          </ZButton>
        )}
      </div>
    </div>
  )
}

export default LowCodeEditorFooter