import React, { useEffect } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Button, ButtonGroup } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import notification from "../../notifications/notifications";
import authProvider from "../../providers/auth/authProvider";
import { TeamService } from "../../providers/data/services/TeamService";
import { TeamValidator } from "../tasks/components/Validator";
import { recordPageVisit, PageCategoryEnum } from "../../config/Rudderstack";
import { TaskFormBody } from "render-engine/render.styles";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import TopBar from "render-engine/TopBar";
import { Tooltip, Typography } from "@mui/material";
import { BsInfoCircleFill } from "react-icons/bs";

const TeamEdit = (props) => {
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [parentTeamId, setParentTeamId] = React.useState("");
  const history = useHistory();

  useEffect(() => {
    recordPageVisit(PageCategoryEnum.team, "team_details");
  }, [props]);

  useEffect(() => {
    ValidatorForm.addValidationRule("parentTeamIdCheck", (value) => {
      if (parentTeamId && parentTeamId.length > 0) {
        return true;
      } else {
        return false;
      }
    });
  });

  const handleParentTeamIdChange = (parentId) => setParentTeamId(parentId);

  useEffect(() => {
    var id = props.id;
    TeamService.getTeam(id)
      .then((res) => {
        const team = res?.data[0];
        setName(team?.name);
        setDescription(team?.description);
        setParentTeamId(team?.parentTeamId);
      })
      .catch((err) => {
        notification("error", "Error while fetching team details");
        console.log(err);
      });
  }, [props.id]);

  const handleNameChange = (val) => {
    if (val?.trim()?.length === 0) setName(val?.trim());
    else setName(val);
  };
  const handleDescriptionChange = (val) => {
    if (val?.trim()?.length === 0) setDescription(val?.trim());
    else setDescription(val);
  };

  const cancelEdit = () => {
    notification("info", "Team edit cancelled!");
    history.push(`/teams/${props.id}/show`);
  };

  const editTeam = () => {
    const teamPayLoad = {
      name: name,
      description: description,
      parentTeamId: parentTeamId,
    };

    TeamService.updateTeam(props.id, teamPayLoad)
      .then((res) => {
        if (res?.code && res?.code.indexOf("200") < 0) {
          // Error
          if (res?.code === "401") {
            notification("error", res.message);
            authProvider.logout();
          } else {
            notification("error", res.message);
          }
        } else {
          notification(
            "success",
            `Team with id ${props.id} edited successfully!`
          );
          history.push(`/teams/${res.data.teamId}/show`);
        }
      })
      .catch((err) => {
        console.log("Error", err);
        notification("error", "Team cannot be edited!");
      });
  };

  return (
    <ValidatorForm
      onSubmit={editTeam}
      onError={(errors) => console.log(errors)}
    >
      <TaskFormBody>
        <TopBar
          title="Edit Team"
          onCancelText={"Cancel"}
          onSaveText="Edit"
          onSave={() => {}}
          onCancel={cancelEdit}
        />
        <div className="contentWrapper">
          <div className="grid grid-cols-1 sm:grid-cols-8 gap-x-8">
            <div className="col-span-4">
              <div
                className="col-span-1 font-medium"
                style={{ padding: "20px 0px 4px 0px" }}
              >
                Name <span className="text-red-500">*</span>
              </div>
              <TextValidator
                autoComplete={false}
                value={name}
                variant="outlined"
                size="medium"
                className="inc-t0 oid-wd w-full"
                onChange={(event) => handleNameChange(event.target.value)}
                validators={["required"]}
                errorMessages={["This field is required"]}
              />
            </div>

            <div className="col-span-4">
              <div
                className="col-span-1 font-medium"
                style={{ padding: "20px 0px 4px 0px" }}
              >
                Description
              </div>
              <TextValidator
                autoComplete={false}
                value={description}
                variant="outlined"
                size="medium"
                className="inc-t0 oid-wd w-full"
                onChange={(event) =>
                  handleDescriptionChange(event.target.value)
                }
                validators={["required"]}
                errorMessages={["This field is required"]}                
              />
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-8 gap-x-8">
            <div className="col-span-4">
              <div
                className="col-span-1 font-medium"
                style={{ padding: "20px 0px 4px 0px" }}
              >
                Parent Team
              </div>
              {parentTeamId && (
                <TeamValidator
                  id="parentTeamField"
                  onChange={handleParentTeamIdChange}
                  disabled={false}
                  team={parentTeamId}
                  donotShowTeamId={props.id}
                  // validators={["parentTeamIdCheck"]}
                  // errorMessages={["This field is required"]}
                />
              )}
              {!parentTeamId && (
                <span className="text-gray-500" style={{ fontSize: "12px" }}>
                  Parent team cannot be changed for root level team
                  &nbsp;&nbsp;
                  <Tooltip
                    title={<Typography color="inherit">Root level team cannot have any parent team. In Zorp, the root level team is always fixed. You can only change the name and description for root team</Typography>}
                  >
                    <div style={{ display: "inline" }}>
                      <BsInfoCircleFill
                        size={"1.0rem"}
                        color="#1976d2"
                        style={{
                          display: "inline",
                          marginRight: "1.4rem",
                          marginTop: 2,
                        }}
                      />
                    </div>
                  </Tooltip>
                </span>
              )}
            </div>
            <div></div>
          </div>
        </div>
      </TaskFormBody>
    </ValidatorForm>
  );
};

export default TeamEdit;
