import jwt_decode from 'jwt-decode';
import { AccountService } from 'providers/data/services/AccountService';

export const userDecodedData = () => {
  if (!!localStorage.getItem('at')?.length) {
    return jwt_decode(localStorage.getItem('at')!);
} else {
  return {}
}
  } 

/**
 * Get the auth token for the user
 * @returns 
 */
export const getAuthToken = () => {
  return localStorage.getItem('at')
}

/**
 * Get Account Name and store it in local storage
 */
export const fetchAndStoreAccountName = async () => {
  const decodedData: any = userDecodedData();
  if(decodedData?.accountId){
    const accountNameData:any = await AccountService.getAccountName(decodedData?.accountId);
    if(accountNameData.code == '200'){
        localStorage.setItem('accountName', accountNameData?.data?.accountName);
    }
  }
}