import styled from "styled-components"
import useAutomation from 'views/automationBuilder/hooks/useAutomation'
import useWorkFlow from 'views/workflows/hooks/useWorkFlow'
import ActionDetails from "views/workflows/workflowactions/ActionDetails"
import SelectTrigger from './components/SelectTrigger'
import ShowActionOrConditionSelectionDropdown from './components/ShowActionOrConditionSelectionDropdown'

/**
 * Left pane can be in three possible states:
 * 1. Initial => Where we ask the user to configure the trigger
 * 2. Intermediate => Where we ask the user to configure the action
 * 3. Configuration => Where we ask the user to configure the condition
 * @param props 
 * @returns 
 */

const Wrapper = styled.div`
  padding: 10px 22px 0px 24px;
  overflow-y: scroll;
  height: 93vh;
`
const RightPane = () => {
  const { selectedConfiguration, selectedTrigger } = useAutomation();
  const { selectedAction } = useWorkFlow()

  return (
    <Wrapper>
      {
        (selectedAction?.actionId || selectedAction?.guardId || selectedAction?.conditionId  ) ?
          <ActionDetails /> :
          (selectedConfiguration == "action" || selectedConfiguration == "condition" || selectedConfiguration == "guard" || selectedConfiguration == "trigger") ?
            <ShowActionOrConditionSelectionDropdown type={selectedConfiguration} /> : null
      }
    </Wrapper>
  )
}

export default RightPane