import React from "react";

type Props = {};

const ZTaskIcon = ({color, width, height}: {color ?:string, width?:string, height?:string}) => {
  return (

    <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.6673 9.16797H6.66732M8.33398 12.5013H6.66732M13.334 5.83464H6.66732M16.6673 5.66797V14.3346C16.6673 15.7348 16.6673 16.4348 16.3948 16.9696C16.1552 17.44 15.7727 17.8225 15.3023 18.0622C14.7675 18.3346 14.0674 18.3346 12.6673 18.3346H7.33398C5.93385 18.3346 5.23379 18.3346 4.69901 18.0622C4.2286 17.8225 3.84615 17.44 3.60647 16.9696C3.33398 16.4348 3.33398 15.7348 3.33398 14.3346V5.66797C3.33398 4.26784 3.33398 3.56777 3.60647 3.03299C3.84615 2.56259 4.2286 2.18014 4.69901 1.94045C5.23379 1.66797 5.93385 1.66797 7.33398 1.66797H12.6673C14.0674 1.66797 14.7675 1.66797 15.3023 1.94045C15.7727 2.18014 16.1552 2.56259 16.3948 3.03299C16.6673 3.56777 16.6673 4.26784 16.6673 5.66797Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
   
  );
};

export default ZTaskIcon;