import React, { ReactElement, memo } from 'react';
import { Controller, useForm } from "react-hook-form";
import Select, { components } from "react-select";
import FilterSelect from "assests/filter_dropdown_arrow.svg";
import FilterErrorMessage from 'filter/components/FilterErrorMessage';
import styled from "styled-components";
import { SelectedOptionValue } from '../Monitor.styles';
const { Option, SingleValue } = components;



interface IMonitorInterfaceSelect {
  control: any;
  errors: any;
  options: any;
  placeholder: string;
  name: string;
  rules?: any;
  isLoading: boolean;
  isDisabled: boolean;
  onChange: (MonitorInterfaceSelectValue: any) => void;
  isClearable?: boolean;
  isMulti?: boolean;
  showSingleSelectImg?: boolean;
  singleSelectImg?: ReactElement;
  defaultValue?: any;
  IconOption?:any;
}

const MonitorInterfaceSelect = ({ name, control, errors, options, placeholder, rules, onChange, isLoading, isDisabled, isClearable = false, isMulti = false, defaultValue, showSingleSelectImg = false, singleSelectImg, IconOption }: IMonitorInterfaceSelect) => {

  const DropdownIndicator = () => (
    <img
      src={FilterSelect}
      style={{ paddingRight: "14px" }}
      className="cursor-pointer"
    />
  );

  const SingleValueOption = ({ children, ...props }: any) => (
    <>
      <SingleValue {...props} style={{ display: 'flex' }} >
        <div style={{ display: 'flex' }}>
          {
            props.showSingleSelectImg &&
            { singleSelectImg }

          }
          <div> 
          <SelectedOptionValue>
          {props.data.label}
          </SelectedOptionValue>
          </div>
        </div>
      </SingleValue>
    </>
  )
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { ref, ...field } }) => (
        <div style={{ width: '100%' }}>
          <Select
            isSearchable
            isLoading={isLoading}
            isMulti={isMulti}
            isDisabled={isDisabled}
            defaultValue={defaultValue}
            isClearable={isClearable}
            {...field}
            ref={ref}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator,
              SingleValue: (props) => <SingleValueOption {...props} showSingleSelectImg={showSingleSelectImg} />,
              // Conditionally include the Option property
              ...(IconOption ? { Option: IconOption } : {})
            }}
            options={options}
            placeholder={placeholder}
            menuPosition={'fixed'}
            noOptionsMessage={({ inputValue }) => 'No Options'}
            styles={{
              groupHeading: (base) => ({
                ...base,
                flex: "1 1",
                color: "white",
                margin: 0,
              }),
              control: (base: any, state: any) => ({
                ...base,
                width: "100%",
                "min-height": "32px",
                minHeight: "32px",
                alignContent: "center",
                alignItems: "center",
              }),
              option: (provided: any, state: { isSelected: any }) => ({
                ...provided,
                paddingLeft: "24px",
                paddingTop: "6px",
                paddingBottom: "6px",
                fontWeight: 400,
                wordWrap: "break-word",
                whiteSpace: "normal",
                fontSize: " 12px",
                color: state.isSelected ? 'white' : 'black'
              }),
              menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
                color: "#7E7E7F",
              }),
              placeholder: (defaultStyles) => ({ // Add this block
                ...defaultStyles,
                color: '#667085', // Change placeholder text color to red
                fontSize:'12px'
              })
            }}
            onChange={(MonitorInterfaceSelectValue: any) => {
              onChange(MonitorInterfaceSelectValue);
            }}
          />
          <FilterErrorMessage errors={errors} name={name} />
        </div>
      )}
    />
  )
}

export  default memo(MonitorInterfaceSelect);