import React, { useState, useEffect } from 'react';
import { PermissionConfiguration, TableEntities, TaskEntities, TaskPermissionsList, TablePermissionsList } from '../types';
import styled from 'styled-components';
import useEntity from 'views/entities/hooks/useEntity';
import { useDispatch } from 'react-redux';
import { setEntityPermissions, setpermissionConfiguration, setFinalEntityPermissions, getEntityMetadata } from 'views/entities/reducers/entityReducers';
import { ResourceTypeList, ResourceTypeConstant } from '../types';
import notification from "notifications/notifications";
import { defaultTablePermissionsList, defaultTaskPermissionsList } from 'constants/CommonConstants';
import { TablePermissionDependencies, TaskPermissionDependencies } from 'constants/CommonConstants';
import { InfoTooltip } from 'views/workflows/Tootltips/InfoTooltip';
import jwtDecode from 'jwt-decode';
import { EntityService } from 'providers/data/services/EntityService';
import ChoiceConfirmation from 'components/user-choice-confirmation/ChoiceConfirmation';
import { TablePermissionRsEvents } from 'views/entities/types';
import { recordRSEvent } from 'utils/CommonUtils';

const PermissionBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

const SaveInputContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 8px;
    justify-content: flex-end;
    align-items: center;
`

const SaveButton = styled.button<{ $active?: boolean }>`
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid ${({ $active }) => $active ? 'var(--Primary-600, #3C69E7)' : `var(--Primary-600, grey)`};
    background: ${({ $active }) => $active ? 'var(--Primary-600, #3C69E7)' : 'grey'};
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    cursor: ${({ $active }) => $active ? 'pointer' : 'not allowed'};
    color: var(--Base-White, #FFF);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
`

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  max-height: 50vh;
  overflow: auto;
`;

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledPermissionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const StyledPermissions = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
`

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  color: #344054;
`;

const StyledInput = styled.input`
  border-radius: 4px;
`;

const Button = styled.button<{ $color: string; $background: string; $borderColor: string; }>`
    color: ${props => props.$color};
    background: ${props => props.$background};
    border: 1px solid ${props => props.$borderColor};
    font-size: 14px;
    padding: 8px 14px;
    border-radius: 4px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
`

const Preset = styled.div`
    color: "#667085";
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 24px;
`

type PermissionsProps = {
    selectedPermissionList: PermissionConfiguration;
    resourceType: ResourceTypeList;
    isPermissionEdited: boolean;
    setIsPermissionEdited: (isPermissionEdited: boolean) => void;
    setSelectedPermissionList: (selectedPermissionList: PermissionConfiguration) => void
};

const PermissionsComponent = ({ selectedPermissionList, resourceType, setSelectedPermissionList, isPermissionEdited, setIsPermissionEdited }: PermissionsProps) => {

    const { permissionConfiguration, entityPermissions, selectedEntity, finalEntityPermissions } = useEntity();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [confirmSave, setConfirmSave] = useState<boolean>(false);

    const [selectedPermissions, setSelectedPermissions] = useState<(TablePermissionsList | TaskPermissionsList)[]>(
        selectedPermissionList?.permissions || []
    );
    useEffect(() => {
        setSelectedPermissions(selectedPermissionList?.permissions || []);
    }, [selectedPermissionList]);

    const getPermissionSections = (): (TableEntities[] | TaskEntities[]) => {
        switch (resourceType) {
            case ResourceTypeConstant.TABLE: {
                const sections = Object.keys(defaultTablePermissionsList)
                return sections as TableEntities[];
            }
            case ResourceTypeConstant.TASK: {
                const sections = Object.keys(defaultTaskPermissionsList)
                return sections as TaskEntities[];
            }
            default: return []
        }
    };

    const permissionSections = getPermissionSections();

    const updatePermissionConfiguration = (updatedPermissions: (TablePermissionsList | TaskPermissionsList)[]) => {
        const permissionIndex = permissionConfiguration.findIndex(p => p.permissionSetName === selectedPermissionList.permissionSetName);

        if (permissionIndex !== -1) {
            const newPermissionConfig = [...permissionConfiguration];
            newPermissionConfig[permissionIndex] = {
                ...newPermissionConfig[permissionIndex],
                permissions: updatedPermissions
            };
            dispatch(setpermissionConfiguration(newPermissionConfig));
        }
    };


    const handleCheckboxChange = (permission: (TablePermissionsList | TaskPermissionsList)) => {
        if (selectedPermissionList.permissionSetName.toLowerCase() === 'creator') {
            notification("error", "Creator permissions cannot be edited.");
            return;
        }
        const permissionIndex = permissionConfiguration.findIndex(p => p.permissionSetName === selectedPermissionList.permissionSetName);
        if (permissionIndex === -1) {
            notification("error", "Permission configuration not found.");
            return;
        }
        let updatedPermissions: (TablePermissionsList | TaskPermissionsList)[] = [...selectedPermissions];

        if (updatedPermissions.includes(permission)) {
            recordRSEvent(TablePermissionRsEvents.UNCHECKED_PERMISSION_CLICKED);
            const dependenciesMap = resourceType === ResourceTypeConstant.TABLE ? TablePermissionDependencies : TaskPermissionDependencies;
            const dependentPermissions = Object.entries(dependenciesMap)
                .filter(([key, deps]) => updatedPermissions.includes(key as any) && deps.includes(permission))
                .map(([key]) => key.replace('_', ' ').charAt(0).toUpperCase() + key.replace('_', ' ').slice(1));

            if (dependentPermissions.length > 0) {
                // If there are dependent permissions, notify the user which permissions are preventing the uncheck
                const dependentPermissionsList = dependentPermissions.join(', ');
                notification("error", `Cannot uncheck this permission because it is a dependency for the following checked permission(s): ${dependentPermissionsList}.`);
                recordRSEvent(TablePermissionRsEvents.UNCHECKED_PERMISSION_FAILURE, {
                    dependentPermissions: dependentPermissionsList
                })
                return;
            } else {
                setIsPermissionEdited(true);
                updatedPermissions = updatedPermissions.filter(p => p !== permission);
                recordRSEvent(TablePermissionRsEvents.UNCHECKED_PERMISSION_SUCCESS, {
                    permissionChangedFor: selectedPermissionList.permissionSetName,
                    newPermissions: updatedPermissions
                })
            }
        } else {
            setIsPermissionEdited(true);
            const dependencies = (resourceType === ResourceTypeConstant.TABLE
                ? TablePermissionDependencies[permission as keyof typeof TablePermissionDependencies]
                : TaskPermissionDependencies[permission as keyof typeof TaskPermissionDependencies]) || [];
            updatedPermissions = Array.from(new Set([...updatedPermissions, ...dependencies, permission]));
            recordRSEvent(TablePermissionRsEvents.CHECKED_PERMISSION_CLICKED, {
                permissionChangedFor: selectedPermissionList.permissionSetName,
                newPermissions: updatedPermissions,
                dependentPermissions: dependencies
            })
        }

        setSelectedPermissions(updatedPermissions);
        const updatedEntityPermissions = entityPermissions.map((permission, index) => {
            if (index === permissionIndex) {
                return {
                    ...permission,
                    permissions: updatedPermissions
                }
            }
            return permission;
        });
        updatePermissionConfiguration(updatedPermissions);
        dispatch(setEntityPermissions(updatedEntityPermissions));
    };

    const getSectionPermissions = (section: TableEntities | TaskEntities): Array<{ id: TablePermissionsList, name: string, description: string }> => {
        if (resourceType === ResourceTypeConstant.TABLE) {
            const sectionPermissions = defaultTablePermissionsList[section as TableEntities];
            return sectionPermissions
                ? Object.values(sectionPermissions).map(permission => ({
                    id: permission.id,
                    name: permission.displayName,
                    description: permission.description
                }))
                : [];
        }
        return [];
    };

    const handleSave = () => {
        setIsLoading(true);

        recordRSEvent(TablePermissionRsEvents.SAVE_PERMISSION_CHANGES_BUTTON_CLICKED);

        const at = localStorage.getItem('at');
        const decoded: any = !!at?.length ? jwtDecode(at) : null;
        const permissionIndex = permissionConfiguration.findIndex(p => p.permissionSetName === selectedPermissionList.permissionSetName);

        // Checking if the current user is trying to change their own permissions
        if (entityPermissions[permissionIndex].or.roleIds.find(role => role.id === decoded?.role) ||
            entityPermissions[permissionIndex].or.userIds.find(user => user.id === decoded?.userId) ||
            entityPermissions[permissionIndex].or.teamIds.some(team => decoded.teamIds.includes(team.id))) {
            setConfirmSave(true);
            setIsLoading(false);
            recordRSEvent(TablePermissionRsEvents.MANAGE_PERMISSIONS_CHANGING_OWN_PERMISSIONS_TRIGGERED, {
                permissionChangedFor: selectedPermissionList.permissionSetName
            })
            return;
        } else {
            proceedWithSave();
        }
    };

    const proceedWithSave = async () => {
        setIsLoading(true);
        const at = localStorage.getItem('at');
        const decoded: any = !!at?.length ? jwtDecode(at) : null;
        const permissionIndex = permissionConfiguration.findIndex(p => p.permissionSetName === selectedPermissionList.permissionSetName);

        const updatedEntityPermissions = entityPermissions.map((permission, index) => {
            if (index === permissionIndex) {
                return {
                    ...permission,
                    permissions: selectedPermissions
                }
            }
            return permission;
        });
        updatePermissionConfiguration(selectedPermissions);
        dispatch(setEntityPermissions(updatedEntityPermissions));

        const payloadPermissions = Array.from(entityPermissions.values()).map(permission => ({
            permissionId: permission.permissionId,
            permissionSetName: permission.permissionSetName,
            permissions: permission.permissions,
            or: {
                roleIds: Array.from(permission.or.roleIds).map(role => (typeof role === 'object' && role !== null) ? role.id || '' : ''),
                teamIds: Array.from(permission.or.teamIds).map(team => (typeof team === 'object' && team !== null) ? team.id || '' : ''),
                userIds: Array.from(permission.or.userIds).map(user => (typeof user === 'object' && user !== null) ? user.id || '' : ''),
            },
            and: {
                roleIds: Array.from(permission.and.roleIds).map(role => (typeof role === 'object' && role !== null) ? role.id || '' : ''),
                teamIds: Array.from(permission.and.teamIds).map(team => (typeof team === 'object' && team !== null) ? team.id || '' : ''),
                userIds: Array.from(permission.and.userIds).map(user => (typeof user === 'object' && user !== null) ? user.id || '' : ''),
            },
            isEditable: permission.isEditable,
        }));

        const payload = {
            tablePermissions: payloadPermissions,
            tableType: selectedEntity?.tableType,
            accountId: decoded?.accountId,
            notifyUsers: false,
        };

        try {
            const response = await EntityService.updateEntityPermissions(payload);
            if (response) {
                setSelectedPermissions(updatedEntityPermissions[permissionIndex].permissions);
                dispatch(setFinalEntityPermissions(updatedEntityPermissions));
                dispatch(getEntityMetadata(selectedEntity?.tableType || ""));
                setSelectedPermissionList({ permissionId: permissionConfiguration[permissionIndex].permissionId, permissionSetName: permissionConfiguration[permissionIndex].permissionSetName, permissions: updatedEntityPermissions[permissionIndex].permissions });
                setIsPermissionEdited(false);
                recordRSEvent(TablePermissionRsEvents.SAVE_PERMISSION_CHANGES_SUCCESSFUL, {
                    payload: payload,
                    response: response,
                });
                notification("success", "Permissions updated successfully");
            } else {
                notification("error", "Error while updating permissions");
            }
        } catch (error) {
            console.error(error);
            recordRSEvent(TablePermissionRsEvents.SAVE_PERMISSION_CHANGES_FAILURE, {
                payload: payload,
                error: error
            })
            notification("error", "Error while updating permissions");
        } finally {
            setIsLoading(false);
        }
    }

    const onCloseHandler = () => {
        setConfirmSave(false);
        setIsPermissionEdited(false);
        setSelectedPermissions(selectedPermissionList.permissions || []);
        recordRSEvent(TablePermissionRsEvents.MANAGE_PERMISSIONS_CLOSE_BUTTON_CLICKED, {
            permissionsAfterClose: selectedPermissionList 
        });
    }

    const onCancelHandler = () => {
        setIsPermissionEdited(false);
        setSelectedPermissions(selectedPermissionList.permissions || []);
        recordRSEvent(TablePermissionRsEvents.CANCEL_PERMISSION_CHANGES_BUTTON_CLICKED, {
            permissionsAfterCancel: selectedPermissionList
        });
        dispatch(setEntityPermissions(finalEntityPermissions))
        const newPermissionConfig = finalEntityPermissions.map(permission => {
            return {
                permissionSetName: permission.permissionSetName,
                permissions: permission.permissions
            }
        })
        dispatch(setpermissionConfiguration(newPermissionConfig))
    }

    return (
        <PermissionBox>
            <StyledContainer>
                {Array.from(permissionSections).map(section => (
                    <StyledSection key={section}>
                        <Preset>{section.charAt(0).toUpperCase() + section.slice(1)}</Preset>
                        <StyledPermissionContainer>
                            {getSectionPermissions(section).map((permission) => (
                                <StyledPermissions key={permission.id}>
                                    <StyledInput
                                        type='checkbox'
                                        checked={selectedPermissions?.includes(permission.id) || false}
                                        onChange={() => handleCheckboxChange(permission.id)}
                                    />
                                    <InfoTooltip placement="right" title={<pre>{permission.description}</pre>}>
                                        <StyledLabel style={{ cursor: "pointer" }}>{permission.name}</StyledLabel>
                                    </InfoTooltip>
                                </StyledPermissions>
                            ))}
                        </StyledPermissionContainer>
                    </StyledSection>
                ))}
            </StyledContainer>
            {isPermissionEdited && <SaveInputContainer>
                <Button $color='#344054' $background='#FFF' $borderColor='#344054' onClick={onCancelHandler}> Cancel </Button>
                <SaveButton $active={!isLoading} disabled={isLoading} onClick={handleSave}>{isLoading ? "Saving..." : "Save"}</SaveButton>
            </SaveInputContainer>}
            <ChoiceConfirmation
                isOpen={confirmSave}
                confirmationTitle='Warning'
                confirmationMessage='You are about to change your own permissions'
                onClose={onCloseHandler}
                onConfirm={(e: any) => { proceedWithSave(); setConfirmSave(false) }}
            />
        </PermissionBox>
    );
};

export default PermissionsComponent;
