import { getConfig } from 'config/envConfig/Config';

type Props = {}

const AppIframe = (props: Props) => {

    const at = localStorage.getItem("at");
    const url = getConfig("appPreviewUrl");

  return (
    <iframe
        id="app_preview_flutter"
        src={`${url}/#/?at=${at}&preview=true`}
        title="Zorp app preview"
        allow="camera https://zorp-app.web.app; microphone https://zorp-app.web.app; geolocation"
        frameBorder="0"
        allowFullScreen
        style={{
        height: "100%",
        width: "100%",
        }}
    ></iframe>
  )
}

export default AppIframe