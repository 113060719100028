import WarningIcon from '@mui/icons-material/Warning';
import { Box } from '@mui/material';
import { ZMessage } from './commonComponents.styles';

type ZInfoPropType = {
  errors: any;
  name: string;
  requiredMessage: string;
  sx?: any;
  isError?: boolean;
  className?: string;
};

export const ZInfo = ({ errors, name, requiredMessage, className, isError }: ZInfoPropType) => {
  return (
    <ZMessage className={className} isError={isError}>
      <WarningIcon sx={{ color: '#F03738', height: '1.1rem' }} />{' '}
      <Box sx={{ ml: 0.6 }}>{errors[name] && (errors[name]?.message || requiredMessage)}</Box>
    </ZMessage>
  );
};
