import React, { useEffect } from 'react'

type Props = {
    fields:any,
    selectedTaskType:string | undefined
}

const DownloadTemplateCSV = ({fields,selectedTaskType}: Props) => {

    const [templateLink, setTemplateLink] = React.useState('')
    const [fieldName, setFieldName] = React.useState('')

    useEffect(() => {
    let csvHeaders = "";
      for (let i = 0; i < fields.length; i++) {
        csvHeaders = csvHeaders + fields[i].label;
        if (i + 1 < fields.length) {
          csvHeaders = csvHeaders + ",";
        }
      }
      let newTemplateLink = URL.createObjectURL(
        new Blob([csvHeaders], { type: "data:attachment/text" })
      );
      let newFieldName = selectedTaskType + ".csv";

      setTemplateLink(newTemplateLink);
      setFieldName(newFieldName);
    },[fields, selectedTaskType])

  return (
    <span
          className="custom-lf-2 text-md-center"
          style={{
            marginLeft: "10px",
            marginTop: "50px",
            color: "black",
            fontSize: "15px",
          }}
        >
          Download{" "}
          <a style={{ color: "blue" }} href={templateLink} download={fieldName}>
            {" "}
            template CSV
          </a>
        </span>
  )
}

export default DownloadTemplateCSV