import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@mui/material";
import { recursiveHelperSmartComponents } from "components/listDatafield/utils";
import { useJSEditor } from "lowcode/hooks/useJSEditor";
import { useEffect, useState, useRef } from "react";
import { Mention, MentionsInput } from "react-mentions";
import useAutomation from "views/automationBuilder/hooks/useAutomation";
import { actionType, IAction } from "views/automationBuilder/types/ABType";
import { ISelectDataField } from "./ISelectDataField";
import { isEmpty } from "lodash";

const useStyles = makeStyles((theme) => ({
  mentionsInput: {
    border: "none",
    borderRadius: "0px",
    color: "rgba(0,0,0,0.8)",
    fontWeight: 400,
    height:'39px',
    lineHeight: 1,
    fontSize: "14px",
    "&__highlighter": {
      padding: "10px",
      height: "40px"
    },
    "&__input": {
      top: "50% !important",
      padding: "18px 8px",
      height: "31px",
      transform: "translateY(-50%)",
      "&:disabled": {
        height: "40px",
        backgroundColor: "#2E4CBC !important",
        borderRadius: "5px !important",
        color: "white",
      },
      "&:focus": {
        border: "none !important",
        outline: "none !important",
      },
      "&:focus-visible": {
        border: "none !important",
        outline: "none !important",
      },
    },
    "&__suggestions": {
      border: "2px solid #eaeaef",
      height: "350px",
      borderRadius: "5px",
      minWidth: "190px",
      position: 'fixed !important',
      overflowY: "auto",
      width: 'max-content !important',
      visibility: 'hidden',
      "&__item": {
        padding: "10px",
        fontSize: "14px",
        fontWeight: 400,
      },
    },
  },
  mentionsInputInner: {
    padding: "8px",
    minWidth: "170px",
    "&:focus": {
      border: "none",
    },
    "&:focus-visible": {
      border: "none",
    },
  },
  closeButton: {
    position: 'absolute',
    display: 'block',
    right: '12px',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 999,
    color: 'white',
    cursor: 'pointer',
  },
}));

export default function SelectDatafield(props: ISelectDataField) {
  const classes = useStyles();

  const { intelliSense} = useJSEditor();
  const { metadataType, actions, isSmartAutomation } = useAutomation();
  const containerRef = useRef<HTMLDivElement>(null);
  const mentionsInputRef = useRef(null);


  const { type = '', value, placeholder, handleChange } = props;

  useEffect(() => {
    if (isEmpty(value)) {
      setFormState({ ...formState, comment: '' })
    }
  },[value])

  const [formState, setFormState] = useState(() => ({
    comment: value || "",
  }));

  const clearMentions = (e: any) => {
    handleChange(e);
    setFormState({ ...formState, comment: '' })
  };

  const appComponentsData: any = {};
  appComponentsData.appComponents = actions.find(
    (actionData: IAction) =>
      actionData.actionType == actionType.SCREEN_COMPONENT
  )?.struct;

  const options = (
    metadataType
      ? recursiveHelperSmartComponents({
          obj: appComponentsData,
          deStructureObj: isSmartAutomation,
        }).concat(intelliSense)
      : intelliSense
  )
    .filter((option) => option.type !== "LIST")
    .map((option) => ({
      id: `\${${option.name}}`,
      display: `${option.name}`,
    }));

  const updateDropdownPosition = () => {
    const suggestions = document.querySelector(`.${classes.mentionsInput}__suggestions`);
    if (suggestions && containerRef.current) {
      const inputRect = containerRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const spaceBelow = viewportHeight - inputRect.bottom;
      const spaceAbove = inputRect.top;

      if (spaceBelow >= suggestions.offsetHeight || spaceBelow > spaceAbove) {
        suggestions.style.top = `${inputRect.bottom + window.scrollY}px`;
        suggestions.style.bottom = 'auto';
      } else {
        suggestions.style.top = 'auto';
        suggestions.style.bottom = `${viewportHeight - inputRect.top + window.scrollY}px`;
      }

      suggestions.style.left = `${inputRect.left + window.scrollX}px`;
      suggestions.style.width = `max-content !important`;
      suggestions.style.visibility = 'visible';
    }
  };

  useEffect(() => {
    if (formState.comment) {
      updateDropdownPosition();

      window.addEventListener('resize', updateDropdownPosition);
      window.addEventListener('scroll', updateDropdownPosition, true);
      return () => {
        window.removeEventListener('resize', updateDropdownPosition);
        window.removeEventListener('scroll', updateDropdownPosition, true);
      };
    }
  }, [formState.comment, options.length]);

  const handleFocus = () => {
    setTimeout(updateDropdownPosition, 0);
  };

  const handleChangeWithUpdate = (e: any) => {
    setFormState({ ...formState, comment: e.target.value });
    handleChange(e);
    setTimeout(updateDropdownPosition, 0);
  };

  return (
    <Box position="relative" ref={containerRef}>
      <MentionsInput
        ref={mentionsInputRef}
        singleLine={true}
        className={classes.mentionsInput}
        value={type === "script" ? "Fx  Smart Script" : formState.comment}
        onChange={handleChangeWithUpdate}
        placeholder={placeholder}
        style={{ backgroundColor: "white" }}
        disabled={type === "script" || type === "dataField"}
        onFocus={handleFocus}
        onClick={handleFocus}
      >
        <Mention
          data={options}
          className={classes.mentionsInputInner}
          appendSpaceOnAdd={true}
          trigger="${"
          displayTransform={(id, display) => `\${${display}}`}
          markup="${__display__}"
        />
      </MentionsInput>
      {(type === 'script' || type === "dataField") && (
        <span className={classes.closeButton} onClick={clearMentions}>
          X
        </span>
      )}
    </Box>
  );
}