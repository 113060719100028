import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";
import { BffService } from "providers/data/services/BffService";
import { EntityService } from "providers/data/services/EntityService";
import { TaskRenderService } from "providers/data/services/TaskRenderService";
import { TaskService } from "views/tasks/services/TaskService";

const initialState = {
  taskRenderDetails: {},
  // contains list of applies workflow filter
  taskListFilterInfo: {},
  // contains the current task data (with the user-made changes)
  taskData: {},
  // contains the task data as it was when the task was first loaded (can be used to check if the task data has changed or to reset the task data to its initial state)
  initialTaskData: {},
  // contains the task layout metadata (will be used when user create/edit a tab or section)
  taskShowLayoutMetadata: {},
  recordDetails: {},
  /**
   * contains key-value
   * key : nodeId
   * value : true/false
   * true : node is in edit state
   * false : node is not in edit state
   */
  inEditState: {},
  /**
   * contains list of rows to delete
   */
  rowsToDelete: {},
  addedRecords: {},
  subRecordCreateMode: false,
  subRecordData: {},
  taskDatafields: [],
  // listen to this if you want to perform any action whenever taskData is updated
  taskDataUpdated: false,
}

export const initRecordShowMetaData = createAsyncThunk(
  "taskShow/initRecordShowMetaData",
  async ({ tableType, recordId }: { tableType: string; recordId: string }) => {
    try {
      const response = await EntityService.getEntityShowView(
        tableType,
        recordId,
        "show"
      );
      return {
        taskRenderDetails: response?.data,
        taskShowLayoutMetadata: response?.data?.metadata,
        initialTaskData: response?.data?.recordData?.data,
        recordDetails: response?.data?.recordData?.recordDetails,
      };
    } catch (error) {
      console.log("error", error);
      return {
        taskRenderDetails: {},
        taskShowLayoutMetadata: {},
        initialTaskData: {},
      };
    }
  }
);

export const initRecordCreateMetaData = createAsyncThunk(
  "taskShow/initRecordCreateMetaData",
  async ({ tableType }: { tableType: string }) => {
    try {
      const response = await EntityService.getEntityCreateView(
        tableType,
        "create"
      );
      return {
        taskRenderDetails: response?.data,
        taskShowLayoutMetadata: response?.data?.metadata,
        initialTaskData: response?.data?.recordData?.data,
        recordDetails: response?.data?.recordData?.recordDetails,
      };
    } catch (error) {
      console.log("error", error);
      return {
        taskRenderDetails: {},
        taskShowLayoutMetadata: {},
        initialTaskData: {},
      };
    }
  }
);

export const initTaskShowMetaData = createAsyncThunk(
  "taskShow/initTaskShowMetaData",
  async ({ taskId }: {  taskId: string }) => {
    try {
      const response = await TaskRenderService.fetchRenderConfigForTaskId(
        taskId, "show"
      )
      return {
        taskRenderDetails: response?.data,
        taskShowLayoutMetadata: response?.data?.metadata,
        initialTaskData: response?.data?.recordData?.data,
        recordDetails: response?.data?.recordData?.recordDetails,
        taskDatafields: response?.data?.recordData?.dataFields
      };
    } catch (error) {
      console.log("error", error);
      return {
        taskRenderDetails: {},
        taskShowLayoutMetadata: {},
        initialTaskData: {},
      };
    }
  }
);

export const initTaskCreateMetaData = createAsyncThunk(
  "taskShow/initTaskCreateMetaData",
  async ({ taskType }: { taskType: string }) => {
    try {
      const response = await TaskRenderService.fetchRenderConfig(
        taskType, "create"
      )
      return {
        taskRenderDetails: response?.data,
        taskShowLayoutMetadata: response?.data?.metadata,
        initialTaskData: response?.data?.recordData?.data,
        recordDetails: response?.data?.recordData?.recordDetails,
        taskDatafields: response?.data?.recordData?.dataFields
      };
    } catch (error) {
      console.log("error", error);
      return {
        taskRenderDetails: {},
        taskShowLayoutMetadata: {},
        initialTaskData: {},
      };
    }
  }
);


export const TaskShow = createSlice({
  name: "taskShow",
  initialState,
  reducers: {
    setTaskDetails: (state, action) => {
      state.taskRenderDetails = action.payload;
    },
    setTaskRenderDetails: (state: any, action: any) => {
      state.taskRenderDetails = action.payload;
    },
    setTaskListFilterInfo: (state: any, action: any) => {
      state.taskListFilterInfo = action.payload;
    },
    setTaskShowLayoutMetadata: (state: any, action: any) => {
      state.taskShowLayoutMetadata = action.payload;
    },
    // on the initial load, set edied task data to initial task data
    setInitialTaskData: (state: any, action: any) => {
      state.initialTaskData = action.payload;
      // state.taskData = action.payload
    },
    setRowsToDelete: (
      state: any,
      action: {
        payload: {
          nodeId: string;
          data: any;
        };
      }
    ) => {
      const { nodeId, data } = action.payload;
      state.rowsToDelete[nodeId] = data;
    },
    clearRowsToDelete: (state: any) => {
      state.rowsToDelete = {};
    },
    setTaskData: (
      state: any,
      action: {
        payload: {
          nodeId: string;
          data: any;
        };
      }
    ) => {
      const { nodeId, data } = action.payload;
      // use lodash to set the value
      if (!state.subRecordCreateMode) {
        set(state.taskData, nodeId, data);
      } else {
        set(state.subRecordData, nodeId, data);
      }

      // state.taskData[nodeId] = data
    },
    // to remove all the enteries from task data
    clearTaskData: (state: any) => {
      state.taskData = {};
    },
    setInEditState: (
      state: any,
      action: {
        payload: {
          nodeId: string;
          inEditState: boolean;
        };
      }
    ) => {
      const { nodeId, inEditState } = action.payload;
      state.inEditState[nodeId] = inEditState;
    },
    moveSection: (
      state: any,
      action: {
        payload: {
          currentIndex: number;
          newPosition: number;
          tabId: string;
        };
      }
    ) => {
      const { currentIndex, newPosition, tabId } = action.payload;
      // newPosition is either +1 or -1
      const newIndex = currentIndex + newPosition;
      const currentTab = state.taskShowLayoutMetadata.tabs.find(
        (tab: any) => tab.id === tabId
      );

      // copy the section at the newIndex
      const sectionToMove = currentTab.sections[currentIndex];
      const sectionToReplace = currentTab.sections[newIndex];

      // replace the section at the newIndex with the section to move
      currentTab.sections[newIndex] = sectionToMove;
      // replace the section at the currentIndex with the section to replace
      currentTab.sections[currentIndex] = sectionToReplace;

      // update the state
      state.taskShowLayoutMetadata = {
        ...state.taskShowLayoutMetadata,
        tabs: state.taskShowLayoutMetadata.tabs,
      };
    },
    // CLEAR inEditState
    clearInEditState: (state: any) => {
      state.inEditState = {};
    },
    // REVERT TO INITIAL STATE
    revertToInitialState: (state: any) => {
      state.taskData = state.initialTaskData;
      state.inEditState = {};
      state.rowsToDelete = {};
      state.addedRecords = {};
    },
    // reset the whole state
    resetTaskShow: (state: any) => {
      state.taskRenderDetails = {};
      state.taskShowLayoutMetadata = {};
      state.initialTaskData = {};
      state.taskData = {};
      state.inEditState = {};
      state.rowsToDelete = {};
      state.addedRecords = {};
    },
    setSubRecordCreateMode: (state: any, action: any) => {
      state.subRecordCreateMode = action.payload;
    },
    resetSubRecordData: (state: any) => {
      state.subRecordData = {};
    },
    setAddedRecords: (state: any, action: any) => {
      state.addedRecords[action.payload.key] = action.payload.value;
    },
    setTaskDataUpdated: (state: any, action: any) => {
      state.taskDataUpdated = !state.taskDataUpdated
    }
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(initRecordShowMetaData.fulfilled, (state, action) => {
        state.taskRenderDetails = action.payload.taskRenderDetails;
        state.taskShowLayoutMetadata = action.payload.taskShowLayoutMetadata;
        state.initialTaskData = action.payload.initialTaskData;
        state.recordDetails = action.payload.recordDetails;
      })
      .addCase(initRecordShowMetaData.rejected, (state, action) => {
        state.taskRenderDetails = {};
        state.taskShowLayoutMetadata = {};
        state.initialTaskData = {};
      })
      .addCase(initRecordShowMetaData.pending, (state, action) => {
        state.taskRenderDetails = {};
        state.taskShowLayoutMetadata = {};
        state.initialTaskData = {};
      })
      .addCase(initRecordCreateMetaData.fulfilled, (state, action) => {
        state.taskRenderDetails = action.payload.taskRenderDetails;
        state.taskShowLayoutMetadata = action.payload.taskShowLayoutMetadata;
        state.initialTaskData = action.payload.initialTaskData;
        state.recordDetails = action.payload.recordDetails;
        //set default values
        state.taskData = action.payload.initialTaskData;
      })
      .addCase(initRecordCreateMetaData.rejected, (state, action) => {
        state.taskRenderDetails = {};
        state.taskShowLayoutMetadata = {};
        state.initialTaskData = {};
      })
      .addCase(initRecordCreateMetaData.pending, (state, action) => {
        state.taskRenderDetails = {};
        state.taskShowLayoutMetadata = {};
        state.initialTaskData = {};
      })
      .addCase(initTaskShowMetaData.fulfilled, (state, action) => {
        state.taskRenderDetails = action.payload.taskRenderDetails;
        state.taskShowLayoutMetadata = action.payload.taskShowLayoutMetadata;
        state.initialTaskData = action.payload.initialTaskData;
        state.recordDetails = action.payload.recordDetails;
        //set default values
        state.taskData = action.payload.initialTaskData;
        state.taskDatafields = action.payload.taskDatafields;
      })
      .addCase(initTaskShowMetaData.rejected, (state, action) => {
        state.taskRenderDetails = {};
        state.taskShowLayoutMetadata = {};
        state.initialTaskData = {};
        state.taskDatafields = [];
      })
      .addCase(initTaskShowMetaData.pending, (state, action) => {
        state.taskRenderDetails = {};
        state.taskShowLayoutMetadata = {};
        state.initialTaskData = {};
        state.taskDatafields = [];
      })
      .addCase(initTaskCreateMetaData.fulfilled, (state, action) => {
        state.taskRenderDetails = action.payload.taskRenderDetails;
        state.taskShowLayoutMetadata = action.payload.taskShowLayoutMetadata;
        state.initialTaskData = action.payload.initialTaskData;
        state.recordDetails = action.payload.recordDetails;
        //set default values
        state.taskData = action.payload.initialTaskData;
        state.taskDatafields = action.payload.taskDatafields;
      })
      .addCase(initTaskCreateMetaData.rejected, (state, action) => {
        state.taskRenderDetails = {};
        state.taskShowLayoutMetadata = {};
        state.initialTaskData = {};
        state.taskDatafields = [];
      })
      .addCase(initTaskCreateMetaData.pending, (state, action) => {
        state.taskRenderDetails = {};
        state.taskShowLayoutMetadata = {};
        state.initialTaskData = {};
        state.taskDatafields = [];
      });
  },
});

export const {
  setTaskRenderDetails,
  setTaskListFilterInfo,
  setTaskData,
  setInEditState,
  setInitialTaskData,
  setTaskShowLayoutMetadata,
  setRowsToDelete,
  clearTaskData,
  revertToInitialState,
  clearRowsToDelete,
  clearInEditState,
  resetTaskShow,
  setSubRecordCreateMode,
  resetSubRecordData,
  setAddedRecords,
  setTaskDataUpdated
} = TaskShow.actions

export default TaskShow.reducer;
