import notification from "notifications/notifications";
import { EntityService } from "providers/data/services/EntityService";
import { AiOutlinePlus } from "react-icons/ai";
import { useHistory, useParams } from "react-router-dom";
import { OnboardingIdsForComponent } from "utils/CommonUtils";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import ZTable from "./ZTable";

const Toolbar = () => {
  const { tableType } = useParams();
  const history = useHistory();

  return (
    <ZButton
    style={{
      margin:'0px 5px'
    }}
      onClick={() => {
        history.push(`/table/create/${tableType}?context=ticket`);
      }}
      secondary
      variant="contained"
      startIcon={<AiOutlinePlus />}
      id={OnboardingIdsForComponent.CREATE_NEW_TABLE_RECORD_BUTTON}
    >
      {" "}
      New Ticket{" "}
    </ZButton>
  );
};

const TicketTable = () => {
  const { tableType } = useParams();
  const history = useHistory();
  const fetchListingView = async ({
    tableType,
    page = 0,
    pageSize = 20,
    sort = [],
    filter = [],
    search = "",
  }: {
    tableType: string;
    page: number;
    pageSize: number;
    sort: any[];
    filter: any[];
    search: string;
  }) => {
    try {
      if (tableType) {
        const data = await EntityService.getListingView(
          tableType,
          "listing",
          page,
          pageSize,
          sort,
          search || "",
          filter,
          ""
        );

        return data;
      } else {
        notification("error", "Table Type is not defined");
        return {};
      }
    } catch (error) {
      notification("error", error?.message);
      return {};
    }
  };


  return (
    <ZTable
      tableType={tableType}
      fetcherFunction={fetchListingView}
      onRowDoubleClick={(row: any) => {
        history.push(`/table/${tableType}/show/${row.row.original.recordId}?context=ticket`);
        console.log(row);
      }}
      toolbarComponent={Toolbar}
    />
  );
};

export default TicketTable;
