import styled from 'styled-components';

export const FilterCloseHeaderRightWrapper = styled.div`
    display: flex;
    align-items:center;
    gap:16px;
`

export const FilterDocumentationText = styled.p`
height: 15px;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
color:#C2C9D1;
line-height: 15px;
cursor:pointer;
text-align: right;
text-decoration-line: underline;
color: rgba(0, 0, 0, 0.5);
`
export const FilterHeading = styled.p`
font-family: 'Inter';
font-style: normal;
color: rgba(0, 0, 0, 0.5);
font-weight: 600;
font-size: 14px;
line-height: 20px;
`

export const FilterAddModalHeading = styled.p`
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 20px;
color: rgba(0, 0, 0, 0.5);
padding-top: 10px;
padding-bottom: 10px;
padding-left: 16px;
`
export const FilterAddModalSubHeading = styled.p`
font-weight: 600;
font-size: 12px;
line-height: 16px;
padding-top:10px;
padding-bottom:10px;
padding-left:16px;
`

export const FilterAddModalListName = styled.p`
font-weight: 400;
font-size: 14px;
line-height: 20px;
padding-top:10px;
padding-bottom:10px;
padding-left:24px;
`
export const FilterAddModalListNameDisabled = styled.p`
font-weight: 400;
font-size: 14px;
line-height: 20px;
padding-top:10px;
padding-bottom:10px;
padding-left:24px;
color:#C7C7C7;
`

export const FilterGroupListWrapper = styled.div`
font-family: 'Inter';
font-style: normal;
color: rgba(0, 0, 0, 0.5);
`

export const FilterClearText = styled.p`
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 16px;
cursor:pointer;
color: rgba(0, 0, 0, 0.5);
`
export const FilterCheckBoxText = styled.p`
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 16px;
color: #616569;
`

export const FilterSeparateText = styled.p`
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 15px;
text-align: right;
margin-top:10px;
color: rgba(0, 0, 0, 0.5);
`

export const ReactFilterSelectedValue = styled.p`
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color:rgba(0, 0, 0, 0.8);
`