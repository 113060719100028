import { TabContext, TabPanel } from "@mui/lab"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import { HomeRIcon, TableRIcon } from "assests"
import { FaRegSave as Save } from "react-icons/fa";
import { LuSettings as SettingsIcon } from "react-icons/lu"
import { cloneDeep } from "lodash"
import { GoDotFill as DotIcon } from "react-icons/go";
import { ZJSEditorLayout } from "lowcode/ZJSEditorLayout"
import { debounce } from "lodash";
import { useJSEditor } from "lowcode/hooks/useJSEditor"
// import { initMockData } from "lowcode/state/scriptEditorState"
import { setScriptEditorPayload } from "lowcode/state/scriptOpenState"
import { EntityService } from "providers/data/services/EntityService"
import React, { useCallback, useEffect, useState, useRef, useLayoutEffect, memo, useMemo } from "react"
import { Menu, MenuItem } from '@szhsin/react-menu';
import { useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import styled, { useTheme } from "styled-components"
import { recordRSEvent } from 'utils/CommonUtils';
import { RiArrowDropDownLine as DropDown } from "react-icons/ri"
import { IoIosAdd as AddIcon } from "react-icons/io";
import { ViewsRSEvents } from "./types";
import {
  getLowCodeEditorDefaultReturnText,
  getLowCodeEditorText,
} from "utils/Utils"
import LoadingScreen from "views/LoadingScreen/LoadingScreen"
import usePayload from "views/automationBuilder/hooks/usePayload"
import { ZTabList } from "views/commonComponents/commonComponents.styles"
import useEntity from "views/entities/hooks/useEntity"
import { WorkflowUtils } from "views/workflows/WorkflowUtils"
import "../../styles/zorpTable.css"
import { getEntityMetadata, initEntities, setSavedViewChanges, setShowTableAppConfigCard, setSelectedViewId } from "./reducers/entityReducers"
import MEntityTable from "./tables/MEntityTable"
import VirtualizedMEntityTable from "./tables/VirtualizedMEntityTable"
import TableHeader from "./tables/TableHeader"
import ManageTableViews from "./tables/ManageTableViews"
import notification from 'notifications/notifications';
import { getCreateViewPayload, getViewsObj } from "utils/CommonUtils";
import useAccessPermissions from "hooks/useAccessPermissions";
import { ResourceTypeConstant } from "./permissions/types";
import LocalStorageUtil from "utils/LocalStorageUtil";
import MonitorInTableModal from "./components/MonitorInTableModal";
import { intDatasource, setConfirmMonitorModalData } from "views/monitor/reducer/MonitorReducer";
import useMonitor from "views/monitor/reducer/useMonitor";

type Props = {}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const style = {
  fontFamily: "Inter",
  fontWeight: 600,
  fontSize: "14px",
}

const SaveButton = styled.button<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ $isActive }) => $isActive ? "#3054B9" : "#98A2B3"};
  cursor: ${({ $isActive }) => $isActive ? "pointer" : "not-allowed"};
  gap: 8px;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
`

const StyledPill = styled.button`
  display: flex;
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
  white-space: nowrap;
  mix-blend-mode: multiply;
  color: #3054B9;
  text-align: center;
`;

const TabSpan = styled.span`
  max-width: 110px;
  display: inline-block;
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    cursor: pointer;
    overflow: visible;
    max-width: fit-content;
  }
`

const StyledSpan = styled.span`
  display: flex;
  padding: 4px 8px 4px 4px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  background: #F2F4F7;
  mix-blend-mode: multiply;
  color: #344054;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Container = styled.div`
  height: 104vh;
`

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--Primary-50, #F5F8FF);
  border-radius: 4px;
  padding: 4px 4px 4px 8px;
  align-items: center;
  gap: 4px;
`;

const ZTabListMenu = styled(ZTabList)`
  .MuiTabs-scroller {
    position: inherit;
    white-space: normal;
  }
  .MuiTabs-root {
    border-bottom: none;
  }
  .Mui-selected {
    border-bottom: 2px solid ${(props) =>
      `${props.theme.color.primary} !important`}; !important; // Dictates the active selected tab for tables
    transition: border-bottom 0.5s;
  }
  .MuiTabs-flexContainer {
    display: flex !important;
  }
`;

const Entity = (props: Props) => {
  const { datasourceListData } = useMonitor();
  const history = useHistory()
  const { tableType, viewId } = useParams()
  const currentTableTypeRef = useRef(tableType)
  const { showTableAppConfigCard, selectedEntity, savedViewChanges, selectedEntityMetadata } = useEntity()
  const theme = useTheme()
  const [showManageViewModal, setShowManageViewModal] = useState(false);
  const [viewModalContext, setViewModalContext] = useState<'create' | 'update'>('create');
  const [modalView, setModalView] = useState<any>(null);
  const [views, setViews] = useState<any>([])
  const [openMonitorInTableModal, setMonitorInTableModal] = useState<boolean>(false);
  const [viewChanges, setViewChanges] = useState<{ [viewId: string]: boolean }>({})
  const [isDefaultView, setIsDefaultView] = useState<boolean>(true);
  const [defaultView, setDefaultView] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true)
  const [selectedView, setSelectedView] = useState<{ viewId?: number, name?: string, description?: string }>({});
  const [maxViewsToShow, setMaxViewsToShow] = useState<number>(6);
  const [width, setWidth] = useState(0);
  const [tableInstance, setTableInstance] = useState<any>(null);
  const getTableInstance = useCallback((instance) => {
    setTableInstance(instance);
  }, []);
  const accessPermissions = useAccessPermissions({ resourceType: ResourceTypeConstant.TABLE });
  const [value, setValue] = useState(() => {
    if (viewId) {
      return viewId
    } else {
      return 1
    }
  })

  useEffect(() => {
    currentTableTypeRef.current = tableType;
  }, [tableType]);

  useEffect(() => {
    setWidth(window.innerWidth);
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const sideBarWidth = 250;
    const addViewAndAllViewsContainerWidth = 190;
    const eachTabWidth = 140;
    const containerPadding = 40;
      const saveViewWidth = 130;
      const unsavedViewWidth = 305;
      if (viewChanges[viewId]) {
        const containerWidth = (width - containerPadding) - sideBarWidth - addViewAndAllViewsContainerWidth - unsavedViewWidth - 40;
        setMaxViewsToShow(Math.floor(containerWidth / eachTabWidth));
      }
      else {
        const containerWidth = (width - containerPadding) - sideBarWidth - addViewAndAllViewsContainerWidth - saveViewWidth - 40;
        setMaxViewsToShow(Math.floor(containerWidth / eachTabWidth));
      }
  }, [views, isDefaultView, viewChanges, width, viewId])

  const renderDropdownButton = () => (
    maxViewsToShow ? <Flex
      style={{ cursor: "pointer" }}
    >
      <StyledPill>All Views</StyledPill>
      <DropDown />
    </Flex> : null
  );

  const { openJSEditorLayout, scriptEditorOpen } = useJSEditor();
  const { actionConditionPayload } = usePayload();
  const onMessageReceivedFromIframe = useCallback((event: any) => {
    if (event.data.name === "exit_fullscreen") {
      dispatch(setShowTableAppConfigCard(false))
    }

    if (
      event?.data?.source === "zorp_web" &&
      event?.data?.name === "open_jsScriptEditor" &&
      event?.data?.payload?.isFromTable == true
    ) {
      const editorConfig = {
        script:
          event?.data.payload?.["script"] ||
          getLowCodeEditorText(
            event?.data.payload?.["editorLocation"],
            event?.data.payload?.["dataType"],
            `return ${getLowCodeEditorDefaultReturnText(
              event?.data.payload?.["dataType"]
            )}`
          ),
        dataType: event?.data.payload?.["dataType"],
        key: event?.data.payload?.["key"],
      };

      const apiPayLoad = cloneDeep(actionConditionPayload);
      apiPayLoad.payload.inclusions = ["RECORD"];
      apiPayLoad.payload.exclusions = ["task"];

      dispatch(setScriptEditorPayload(apiPayLoad));
      openJSEditorLayout({
        config: editorConfig,
        editorLocation: event?.data.payload?.["editorLocation"],
      });

    }
  }, []);

  useEffect(() => {
    window.addEventListener("message", onMessageReceivedFromIframe);
  }, [onMessageReceivedFromIframe]);

  const setViewsOrder = (views: any) => {
    const viewsArr = views?.data || [];
    const index = viewsArr.findIndex((item:any) => item.isDefault === true);
    if (index > 0) {
        let [item] = viewsArr.splice(index, 1);
        viewsArr.unshift(item);
    }
    setViews(viewsArr);
  }

  //This is specifically called for create view, update view and copy view and it is mainly used for setting the URL
  //and the views in localStorage and it has a fallback to throw err
  const createUpdateView = useCallback(async (context: string, viewId?: string, prevViewId?: string) => {
    try {
      //fetch table views api
      const allViews = await EntityService.getViews(tableType)
      setViewsOrder(allViews);
      if (context === 'create') {
        setValue(allViews?.data?.[allViews?.data?.length - 1].viewId)
        history.push(`/table/${tableType}/view/${allViews?.data?.[allViews?.data?.length - 1].viewId}`)
      }
      else if (context === 'update') {
        setValue(viewId)
        history.push(`/table/${tableType}/view/${viewId}`)
      }
      else if (context === 'copy' && prevViewId && viewId) {
        const viewParamsObj = getViewsObj(savedViewChanges[prevViewId]);
        const newTableState = JSON.stringify(viewParamsObj);
        const newViewChanges = { ...savedViewChanges, [viewId]: savedViewChanges[prevViewId] };
        const storedViewChanges = localStorage.getItem(`viewChanges_${selectedEntity?.tableType}`);
        const viewChanges = storedViewChanges ? JSON.parse(storedViewChanges) : {};
        const updatedViewChanges = JSON.stringify({ ...viewChanges, [viewId]: false });

        LocalStorageUtil.setItem(`tableState_${selectedEntity?.tableType}_${viewId}`, newTableState);
        LocalStorageUtil.setItem(`viewChanges_${selectedEntity?.tableType}`, updatedViewChanges);

        dispatch(setSavedViewChanges(newViewChanges));
        setValue(viewId);
        if (setViewChanges) setViewChanges({ ...viewChanges, [viewId]: false });

        history.push(`/table/${tableType}/view/${viewId}`);
      }
      else {
        allViews?.data?.forEach((view: any) => {
          if (view.isDefault) {
            history.push(`/table/${tableType}/view/${view.viewId}`)
          }
        })
      }
    }
    catch (err) {
      console.log(err);
      throw err
    }
  }, [])

  const setViewChangesLocalStorage = () => {
    const viewChangesObj = views?.reduce((acc: Record<string, boolean>, view: any) => {
      if (typeof view.viewId === "string") {
        acc[view.viewId] = false;
      }
      return acc;
    }, {});
    setViewChanges(viewChangesObj);
    if (selectedEntity?.tableType && views.length > 0) LocalStorageUtil.setItem(`viewChanges_${selectedEntity?.tableType}`, JSON.stringify(viewChangesObj))
  }

  //This useEffect is used to set local state of view changes which is an object having viewId as key and boolean value,
  //If it already finds the localStorage value then it sets it directly to local state and of not then it calls a function to 
  //create the view changes and then sets it both to local state and global state.
  useEffect(() => {
    if (!selectedEntity) return;
    try {
      const viewChangesVal = localStorage.getItem(`viewChanges_${selectedEntity?.tableType}`);
      if (viewChangesVal) {
        const viewChanges = JSON.parse(viewChangesVal);
        if (typeof viewChanges !== "object" || views.length <= 0 || Object.keys(viewChanges).length !== views.length) return
        setViewChanges(viewChanges)
        return;
      }
    } catch (err) {
      console.log(err);
      setViewChangesLocalStorage();
    }
  }, [selectedEntity, views])

  const fetchTableViews = useCallback(debounce(async (tableType) => {
    if (!tableType) return;
    setIsLoading(true);
    try {
      if (tableType !== currentTableTypeRef.current) {
        return;
      }
      const allViews = await EntityService.getViews(tableType);
      setViewsOrder(allViews);
      let persistedViewChanges = localStorage.getItem(`viewChanges_${tableType}`);
      if (allViews?.data?.length === 0) {
        setValue(1);
        return;
      } else {
        if (persistedViewChanges) {
          const persistedViewChangesParsed = JSON.parse(persistedViewChanges);
          if (allViews.data.length === Object.keys(persistedViewChangesParsed).length) {
            setViewChanges(persistedViewChangesParsed);
          } else {
            const viewChangeDiff = allViews.data.reduce((acc: Record<string, boolean>, view: any) => {
              if (typeof view.viewId === "string" && !(view.viewId in persistedViewChangesParsed)) {
                acc[view.viewId] = false;
              }
              return acc;
            }, {});
            const mergedViewChanges = { ...persistedViewChangesParsed, ...viewChangeDiff };
            setViewChanges(mergedViewChanges);
            if (tableType && allViews.data.length > 0) {
              LocalStorageUtil.setItem(`viewChanges_${tableType}`, JSON.stringify(mergedViewChanges));
            }
          }
        } else {
          const viewChangesObj = allViews.data.reduce((acc: Record<string, boolean>, view: any) => {
            if (typeof view.viewId === "string") {
              acc[view.viewId] = false;
            }
            return acc;
          }, {});
          setViewChanges(viewChangesObj);
          persistedViewChanges = JSON.stringify(viewChangesObj);
          if (tableType && allViews.data.length > 0) {
            LocalStorageUtil.setItem(`viewChanges_${tableType}`, persistedViewChanges);
          }
        }
      }
      allViews?.data?.forEach((view: any) => {
        if (view.isDefault) {
          setDefaultView(view);
        }
      });
      if (viewId) {
        dispatch(setSelectedViewId(viewId));
        setValue(viewId);
        history.push(`/table/${tableType}/view/${viewId}`);
      } else {
        allViews?.data?.forEach((view: any) => {
          if (view.isDefault) {
            setValue(view.viewId);
            dispatch(setSelectedViewId(view?.viewId));
            setIsDefaultView(true);
            history.push(`/table/${tableType}/view/${view.viewId}`);
          }
        });
      }
      const currView = viewId ? allViews?.data?.find((view: any) => view.viewId === viewId) : allViews?.data?.find((view: any) => view.isDefault);
      if (currView) {
        if (persistedViewChanges && !(JSON.parse(persistedViewChanges)[currView.viewId])) {
          const viewParamsObj = getViewsObj(currView.params);
          LocalStorageUtil.setItem(`tableState_${tableType}_${currView.viewId}`, JSON.stringify(viewParamsObj));
        }
        if (currView.isDefault) {
          setIsDefaultView(true);
          setDefaultView(currView);
        } else {
          setIsDefaultView(false);
        }
        dispatch(setSavedViewChanges({ ...savedViewChanges, [currView.viewId]: getViewsObj(currView.params) }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, 500), [tableType]);


  const dispatch = useDispatch()

  const debouncedGetEntityMetadata = useCallback(debounce((tableType) => {
    if (tableType === currentTableTypeRef.current) {
      dispatch(getEntityMetadata(tableType));
    }
  }, 500), [dispatch]);

  const debouncedInitEntities = useCallback(debounce((tableType) => {
    if (tableType === currentTableTypeRef.current) {
      dispatch(initEntities(tableType));
    }
  }, 500), [dispatch]);

  // const debouncedInitMockData = useCallback(debounce((tableType) => {
  //   if (tableType === currentTableTypeRef.current) {
  //     dispatch(initMockData({taskTypeId: tableType, type: 'entity'}));
  //   }
  // }, 500), [dispatch]);

  useLayoutEffect(() => {
    debouncedGetEntityMetadata(tableType);
    debouncedInitEntities(tableType);
    // debouncedInitMockData(tableType);

    return () => {
      debouncedGetEntityMetadata.cancel();
      debouncedInitEntities.cancel();
      // debouncedInitMockData.cancel();
    };
  }, [tableType, debouncedGetEntityMetadata, debouncedInitEntities]);

  useEffect(()=>{
    if (datasourceListData?.length == 0) {
      dispatch(intDatasource());
    }
  },[datasourceListData?.length])

  useEffect(() => {
    fetchTableViews(tableType)

    return () => {
      fetchTableViews.cancel();
    };
  }, [tableType, fetchTableViews]);

  const handleChange = (event: React.SyntheticEvent | null, newValue: string) => {
    if (!newValue) return;
    if (defaultView !== null && defaultView.viewId === newValue) setIsDefaultView(true);
    else setIsDefaultView(false);
    recordRSEvent(ViewsRSEvents.SWITCHED_TO_DIFFERENT_VIEW, {
      previousView: value,
      newView: newValue
  })
    setValue(newValue);
    const persistedViewChanges = localStorage.getItem(`viewChanges_${selectedEntity?.tableType}`);
    if (persistedViewChanges && !(JSON.parse(persistedViewChanges)[newValue])) {
      views.forEach((view: any) => {
        if (view.viewId === newValue) {
          dispatch(setSelectedViewId(view?.viewId));
          dispatch(setSavedViewChanges({ ...savedViewChanges, [newValue]: getViewsObj(view.params) }));
          const viewParamsObj = getViewsObj(view.params);
          LocalStorageUtil.setItem(`tableState_${selectedEntity?.tableType}_${view.viewId}`, JSON.stringify(viewParamsObj));
        }
      })
    }
    const selectedIndex = views.findIndex((view: any) => view.viewId === newValue);
    if (maxViewsToShow && selectedIndex >= maxViewsToShow && !views[maxViewsToShow - 1].isDefault) {
      swapTabs(selectedIndex);
    }
    setSelectedView(getSelectedViewDetails(newValue));
    if (newValue !== '1') {
      recordRSEvent(ViewsRSEvents.URL_UPDATE_FOR_NEW_VIEW, {
        newURL: `/table/${tableType}/view/${newValue}`
      });
      if(event === null) fetchTableViews(tableType);
      history.push(`/table/${tableType}/view/${newValue}`)
    } else {
      recordRSEvent(ViewsRSEvents.URL_UPDATE_FOR_NEW_VIEW, {
        newURL: `/table/${tableType}`
      });
      history.push(`/table/${tableType}?/`)
    }
  }

  const swapTabs = (selectedIndex: number) => {
    setViews((currentViews:any) => {
      const newViews = [...currentViews];
      if (selectedIndex > 0) {
        const selectedView = newViews.splice(selectedIndex, 1)[0];
        const newPosition = 1;
        newViews.splice(newPosition, 0, selectedView);
      }
      return newViews;
    });
  };

  useEffect(() => {
    getSelectedViewDetails(viewId);
  }, [views, viewId])

  const getSelectedViewDetails = useCallback((viewId: string) => {
    const selectedView = views.find((view: any) => view.viewId === viewId);
    //check if the selected view is default view or not
    if (selectedView?.isDefault) {
      setIsDefaultView(true);
    } else {
      setIsDefaultView(false);
    }
    if (views.length > 0 && viewId && !selectedView) {
      return {};
    }
    else if (selectedView) {
      setSelectedView(selectedView);
    }
    return {
      name: selectedView?.name,
      description: selectedView?.isDeafault ? selectedEntityMetadata?.tableMetadata?.tableDescription : selectedView?.description,
      viewId: selectedView?.viewId
    }
  }, [views, viewId])

  const onSaveViewHandler = async (value: string) => {
    recordRSEvent(ViewsRSEvents.SAVE_THIS_VIEW_BUTTON_CLICKED);
    try {
      const payload = localStorage.getItem(`tableState_${selectedEntity?.tableType}_${value}`)
      if (!payload) {
        notification('error', 'Failed to update view');
        return;
      }

      const parsedPayload = JSON.parse(payload);
      if (!selectedEntityMetadata) return;

      const finalPayload = getCreateViewPayload(selectedEntityMetadata?.customFields, parsedPayload);

      if (selectedEntity && value) {
        if (!selectedView?.name) {
          notification('error', 'Failed to update view');
          return;
        }
        if (selectedView?.name && selectedView?.name.trim().length === 0) {
          notification('error', 'Failed to update view');
          return;
        }
        const name: string = selectedView?.name ? selectedView?.name : "";
        const description: string = selectedView?.description ? selectedView?.description : "";
        const saveViewPayload = {
          ...finalPayload,
          name: name,
          description: description,
          isDeleted: false,
        }
        EntityService.saveView(selectedEntity?.tableType, value, saveViewPayload).then((res) => {
          notification('success', 'View updated successfully');
          recordRSEvent(ViewsRSEvents.SAVE_THIS_VIEW_SUCCESS, {
            tableType: selectedEntity?.tableType,
            viewId: value,
            ...saveViewPayload
          })
          if(res.view) setModalView(res.view);
          setViews((prevOrder: any[]) => {
            return prevOrder.map((item: any) => {
              if (item.viewId === value) {
                return { ...item, params: res.view.params };
              }
              return item;
            });
          });
          setViewChanges({ ...viewChanges, [value]: false });
          LocalStorageUtil.setItem(`viewChanges_${selectedEntity?.tableType}`, JSON.stringify({ ...viewChanges, [value]: false }));
          dispatch(setSavedViewChanges({ ...savedViewChanges, [res.view.viewId]: getViewsObj(res.view.params) }));
        }).catch((err) => {
          notification('error', 'Failed to update view');
        })
      }
    } catch (err) {
      console.log(err);
      recordRSEvent(ViewsRSEvents.SAVE_THIS_VIEW_FAILURE, {
        tableType: selectedEntity?.tableType,
        viewId: value,
      });
      notification('error', 'Error while updating view');
      throw err
    }
  };

  const preventSwipeBackAndForth = (event: any) => {
    // Check for specific conditions you want to prevent, like horizontal swipes
    if (Math.abs(event.deltaX) > Math.abs(event.deltaY)) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    window.addEventListener("message", onMessageReceivedFromIframe);
  }, [onMessageReceivedFromIframe]);

  // Effect hook to add and remove event listeners
  useEffect(() => {
    const tableElement = document.getElementById('zorp-table-container'); // Get your table element by ID or ref

    if (!tableElement) return;
    // Add event listeners
    tableElement.addEventListener('touchstart', preventSwipeBackAndForth, { passive: false });
    tableElement.addEventListener('touchmove', preventSwipeBackAndForth, { passive: false });

    // Clean up event listeners
    return () => {
      tableElement.removeEventListener('touchstart', preventSwipeBackAndForth);
      tableElement.removeEventListener('touchmove', preventSwipeBackAndForth);
    };
  }, []);

  const resetViewClickHandler = () => {
    if (tableInstance) {
      recordRSEvent(ViewsRSEvents.RESET_VIEW_BUTTON_CLICKED);
    if (!savedViewChanges || !viewId || !savedViewChanges[viewId]) return;
    tableInstance.resetSorting()
    if (savedViewChanges[viewId].sorting) tableInstance.setSorting(savedViewChanges[viewId].sorting);
    tableInstance.resetGlobalFilter()
    if (savedViewChanges[viewId].globalFilter) tableInstance.setGlobalFilter(savedViewChanges[viewId].globalFilter);
    tableInstance.resetColumnFilters()
    if (savedViewChanges[viewId].columnFilters) tableInstance.setColumnFilters(savedViewChanges[viewId].columnFilters);
    tableInstance.resetColumnOrder()
    if (savedViewChanges[viewId].sorting) tableInstance.setColumnOrder(savedViewChanges[viewId].columnOrdering);
    tableInstance.resetColumnVisibility()
    if (savedViewChanges[viewId].columnVisibility) tableInstance.setColumnVisibility(savedViewChanges[viewId].columnVisibility);
    if (viewId) LocalStorageUtil.setItem(`tableState_${selectedEntity?.tableType}_${viewId}`, { ...savedViewChanges[viewId] });
    if (setViewChanges && viewChanges && viewId) {
      LocalStorageUtil.setItem(`viewChanges_${selectedEntity?.tableType}`, JSON.stringify({ ...viewChanges, [viewId]: false }));
      setViewChanges({ ...viewChanges, [viewId]: false });
    }
    }
  };

  useEffect(() => {

    return () => {
      dispatch(setConfirmMonitorModalData(null));
    }
  }, [])

  return (
    <>
      {isLoading || !selectedEntityMetadata ? (
        <>
          <LoadingScreen />
        </>
      ) : (
        <Container>
          <TableHeader 
          handleConfigureAlert={()=>{
            setMonitorInTableModal(true);
          }}
          name={selectedView && selectedView.name ? selectedView?.name : 'Home View'} 
          description={selectedView?.description} />
          <TabContext value={value}>
            <ZTabListMenu sx={{ padding: "0px 8px", borderBottom: '1px solid #EAECF0' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', margin: '0 8px' }}>
                <div style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: '100%',
                  alignItems: 'center'
                }}>
                  <Tabs
                    value={value}
                    onChange={(e) => handleChange(e, value)}
                    aria-label="basic tabs example"
                    TabIndicatorProps={{
                      style: {
                        height: '2px',
                        backgroundColor: theme.color.primary
                      }
                    }}
                  >
                    {views.slice(0, maxViewsToShow).map((view: any, index: number) => (
                      <Tab style={{minWidth: 'auto'}}
                        key={view.viewId}
                        value={view.viewId}
                        onClick={(e) => handleChange(e, view.viewId)}
                        sx={style}
                        disableRipple
                        label={
                          <div style={{ display: "flex", alignItems: "center" }}>
                            {view.isDefault ? <HomeRIcon color={theme.color.primary as string} /> : <TableRIcon color={theme.color.primary as string} />}
                            <TabSpan>{view.name}</TabSpan>
                            {accessPermissions?.manage_table_view && <SettingsIcon onClick={(e) => { e.stopPropagation(); setShowManageViewModal(true); setViewModalContext('update'); setModalView(view) }} color={value === view.viewId ? theme.color.primary : '#98A2B3'} style={{ marginLeft: "10px" }} />}
                          </div>
                        }
                      />
                    ))}
                  </Tabs>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: '0px 8px',
                      gap: '4px',
                    }}
                  >
                    {(views && maxViewsToShow && views.length > maxViewsToShow) ? (
                      <>
                        <Menu
                          menuClassName="my-menu"
                          menuButton={renderDropdownButton()}
                          direction="bottom"
                          transition
                          gap={12}
                          align="start"
                          menuStyle={{ width: "max-content" }}
                        >
                          {views.map((view: any, index: number) => (
                            <MenuItem key={view.viewId}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                  width: "100%",
                                  fontSize: '12px',
                                  color: value === view.viewId ? theme.color.primary : '#475467'
                                }}
                                onClick={(e) => {
                                  handleChange(e, view.viewId);
                                }}
                              >{view.isDefault ?
                                <HomeRIcon color={theme.color.primary as string} />
                               : 
                                <TableRIcon color={theme.color.primary as string} />
                                }
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                  <span
                                    style={{
                                      margin: "0px 10px",
                                      textTransform: "none",
                                    }}
                                  >
                                    {view.name}
                                  </span>
                                  {accessPermissions?.manage_table_view && <SettingsIcon onClick={(e) => { e.stopPropagation(); setShowManageViewModal(true); setViewModalContext('update'); setModalView(view) }} color={value === view.viewId ? theme.color.primary : '#98A2B3'} />}
                                </div>
                              </div>
                            </MenuItem>
                          ))}
                        </Menu>
                      </>
                    ) : null}
                    {accessPermissions?.manage_table_view && 
                    <StyledSpan onClick={() => {
                      setViewModalContext('create'); 
                      setShowManageViewModal(true);
                      recordRSEvent(ViewsRSEvents.ADD_NEW_VIEW_BUTTON_CLICKED);
                      }}>
                      <AddIcon fontSize={12} color={'#667085'} strokeWidth={2} /> 
                      Add View
                    </StyledSpan>}
                  </div>
                </div>
                {accessPermissions?.manage_table_view &&
                  <div style={{ width: 'fit-content', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <SaveButton $isActive={viewId ? viewChanges[viewId] : false} disabled={!viewId || !viewChanges[viewId]}>
                      {(viewId && viewChanges[viewId]) && <div onClick={(resetViewClickHandler)} style={{ color: '#F03738', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><DotIcon fontSize={'12px'} color={'#F03738'} />Reset View</div>}
                      <div onClick={() => onSaveViewHandler(value)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}><Save color={'#98A2B3'} /> Save For Everyone</div>
                    </SaveButton>
                  </div>
                }
              </div>
            </ZTabListMenu>
            {views.map((view: any, index: number) => (
              <TabPanel sx={{ height: "90%", padding: 0 }} key={view.viewId} value={view.viewId}>
                {selectedEntity && <Wrapper>
                  {
                    (tableType === "rb25aa" || tableType === "dqzs5f") ? <VirtualizedMEntityTable getTableInstance={getTableInstance} enableRowSelection viewChanges={viewChanges} setViewChanges={setViewChanges} view={view} isDefaultView={isDefaultView}  /> : <MEntityTable getTableInstance={getTableInstance} enableRowSelection viewChanges={viewChanges} setViewChanges={setViewChanges} view={view} isDefaultView={isDefaultView} />
                  }
                </Wrapper>}
              </TabPanel>
            ))}
          </TabContext>
        </Container>
      )}
      {
        showTableAppConfigCard && <div>
          <iframe
            id='app-builder'
            key={`tableCardConfig-${tableType}`}
            title='Zorp Designer'
            src={WorkflowUtils.getTaskCardConfigSrc(tableType || "", { isTableCardConfig: true }, "")}
            frameBorder='0'
            style={{
              display: 'block',
              height: '100vh',
              width: '100vw',
              position: 'absolute',
              top: '0',
              left: '0',
              zIndex: '300',
            }}
          />
        </div>
      }
      {
openMonitorInTableModal &&
        <MonitorInTableModal
        handleModalClose={()=>{
          dispatch(setConfirmMonitorModalData(null));
          setMonitorInTableModal(false);
        }}
        isMonitorInTableModalOpen={openMonitorInTableModal}
        />
      }
      {scriptEditorOpen && <ZJSEditorLayout />}
      {showManageViewModal && <ManageTableViews onDeleteView={handleChange} setIsLoadingTransition={setIsLoading} onSave={createUpdateView} context={viewModalContext} view={modalView} views={views} onClose={() => setShowManageViewModal(false)} />}
    </>
  )
}

export default memo(Entity)