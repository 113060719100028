import { Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import CustomizeAlertsIcon from 'assests/customize_alert_icon.svg';
import { cronDescriptionArray } from 'constants/MonitorConstant';
import FilterErrorMessage from 'filter/components/FilterErrorMessage';
import moment from "moment-timezone";
import { MonitorService } from 'providers/data/services/MonitorService';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { ISelectValueType } from '../DatasourceModal';
import { LabelText, MonitorCronNextRun, MonitorTextField } from '../Monitor.styles';
import CollapsableComponent from '../MonitorInterfaceComponents/CollapsableComponent';
import { ICronValueType, setMonitorInterfaceContext } from '../reducer/MonitorReducer';
import useMonitor from '../reducer/useMonitor';
import MonitorInterfaceSelect from './MonitorInterfaceSelect';
import { recordRSEvent } from 'utils/CommonUtils';
import { RudderStackAutomationBuilderEvents } from 'constants/RudderStackConstants';

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const MonitorCronDescText = styled.p`
  color: black;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  margin-top: 10px;
`;

const CronHelperText = styled.p`
  color: black;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
`;



// Create a styled version of FormControlLabel
const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  color: '#344054',
  marginRight: '10px',
  '& .MuiFormControlLabel-label': {
    fontSize: '12px',
    fontWeight: '500',
    fontStyle: 'normal',
    fontFamily: 'Inter, sans-serif', // Added sans-serif as fallback
  },
}));

interface ICustomiseMyAlertSection {
  errors: any;
  control: any;
  register: any;
  setValue: any;
  watch: any;
}

const CustomiseMyAlertSection = ({ errors, control, register, setValue, watch }: ICustomiseMyAlertSection) => {
  const dispatch = useDispatch();
  const { monitorInterfaceContext } = useMonitor();
  const [allTimezones, setAllTimezones] = useState<ISelectValueType[]>([]);
  const [cronTypeValue, setCronTypeValue] = useState<string>(watch()?.cronType);
  const [cronDesc, setCronDesc] = useState<string>('');
  const [cronNextRun, setCronNextRun] = useState<string>('');
  const [cronDescLoading, setCronDescLoading] = useState<boolean>(false);
  const timeZoneDefaultValue = { value: 'Asia/Kolkata', label: 'Asia/Kolkata' };
  const isCreateBulkAlertEnabled = monitorInterfaceContext?.monitorComponentsData?.isDisableCreateBulkAlert;
  
  
  const getTimeZoneList = () => {
    const timezonelist = moment.tz
      .names()
      .map((timezone: any) => ({ value: timezone, label: timezone }));
    const timeZoneData = [...timezonelist];
    setAllTimezones(timeZoneData);
    if (monitorInterfaceContext?.monitorData?.cronData?.timeZone && monitorInterfaceContext.monitorScreenContext == 'EDIT') {
      const selectedTimezoneValue = timeZoneData.find((timezone: any) => timezone.value === monitorInterfaceContext?.monitorData?.cronData?.timeZone);
      // setting default value for both the cron interval and time zone
      setValue('timeZone', selectedTimezoneValue);
      setValue('cronInterval', cronDescriptionArray[0])

      setTimeout(() => {

        if (watch()?.cronType && (watch()?.cronInterval || watch()?.cronExpression)) {
          getCronDescription();
        }

      }, 100);

    } else {
      setValue('timeZone', timeZoneDefaultValue);
    }
  }

  useEffect(() => {
    getTimeZoneList();
  }, [])

  useEffect(() => {
    if (watch()?.cronType) {
      setCronTypeValue(watch()?.cronType);
    }
  }, [watch()?.cronType])

  const getCronDescription = async () => {
    setCronDescLoading(true);
    let monitorDescriptionPayload;
    const timeZone = watch()?.timeZone?.value;
    if (cronTypeValue == 'INTERVAL' && watch()?.cronInterval?.value && timeZone) {
      monitorDescriptionPayload = {
        timeZone: timeZone,
        cronData: {
          hours: parseInt(watch()?.cronInterval?.value.split(':')[0]),
          minutes: parseInt(watch()?.cronInterval?.value.split(':')[1])
        },
        cronType: cronTypeValue,
      }
    } else if (cronTypeValue == 'EXPRESSION' && watch()?.cronExpression && timeZone) {
      monitorDescriptionPayload = {
        timeZone: timeZone,
        cronData: {
          expression: watch()?.cronExpression
        },
        cronType: cronTypeValue
      }
    } else {
      return;
    }
    const monitorDescOutput = await MonitorService.getCronDescription(monitorDescriptionPayload);
    setCronDescLoading(false);
    if (monitorDescOutput?.code == '200') {
      setCronDesc(monitorDescOutput.data.cronDescription);
      setCronNextRun(monitorDescOutput.data.cronNextRun.toString());
    } else {
      setCronDesc('');
      setCronNextRun('');
    }

  }

  return (
    <>
      <CollapsableComponent
        imgIcon={<img style={{ paddingRight: '8px' }} src={CustomizeAlertsIcon} alt='Icon' />}
        color='#B42318'
        title='Set frequency'
        collapseState={!monitorInterfaceContext?.monitorComponentsData?.isMonitorCustomizationSectionHidden} onClick={() => {
          dispatch(setMonitorInterfaceContext({
            ...monitorInterfaceContext, // Spread the previous state to retain other values
            monitorComponentsData: {
              ...monitorInterfaceContext?.monitorComponentsData, // Spread the previous monitorComponentsData
              isMonitorCustomizationSectionHidden: !monitorInterfaceContext?.monitorComponentsData?.isMonitorCustomizationSectionHidden // Set your desired property
            }
          }));
        }} />

      {
        monitorInterfaceContext?.monitorComponentsData?.isMonitorCustomizationSectionHidden &&
        <>
          <Box marginTop={'24px'} padding={'24px'} style={{ border: '1px solid #D0D5DD' }} width={'900px'} borderRadius={'4px'}>

            <TextWrapper>
              <LabelText>Alert name</LabelText>

              <Controller
                name={'monitorName'}
                control={control}
                rules={{ required: "Display Name field is requried" }} 
                render={({
                  fieldState: { error }
                }) => (
                  <MonitorTextField
                  placeholder='Name this alert to easily identify it'
                    error={!!error}
                    sx={{
                      fontSize:'12px'
                    }}
                    variant="outlined"
                    {...register("monitorName", {
                      required: "Alert Name field is requried",
                    })}
                  />
                )}
              />
              <FilterErrorMessage errors={errors} name={'monitorName'} />
            </TextWrapper>

            <TextWrapper style={{ marginTop: '32px', marginBottom: '24px' }}>
              <LabelText>Describe your alert</LabelText>

              <Controller
                name={'monitorDescription'}
                control={control}
                rules={{ required: "Alert description field is requried" }}
                render={({
                  fieldState: { error }
                }) => (
                  <MonitorTextField
                  placeholder='Include a brief for quick reference'
                    error={!!error}
                    variant="outlined"
                    {...register("monitorDescription", {
                      required: "Alert Description field is requried",
                    })}
                  />
                )}
              />
              <FilterErrorMessage errors={errors} name={'monitorDescription'} />
            </TextWrapper>

            <Box ml={'11px'}>
              <Controller
                name="createBulkAlert"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <input
                        {...field}
                        disabled={isCreateBulkAlertEnabled}
                        type="checkbox"
                        style={{ marginRight: "10px", marginTop: "5px", marginBottom: "5px", height: '16px', width: '16px' }}
                        checked={field.value}
                        onChange={(e) => {
                          setValue('createBulkAlert', e.target.checked);

                          if (e.target.checked) {
                            recordRSEvent(RudderStackAutomationBuilderEvents.createSingleAlert, {
                              context: RudderStackAutomationBuilderEvents.monitorContext
                            });
                          }
                          
                        }}
                      />
                    }
                    label={
                      <Tooltip
                        disableFocusListener={!isCreateBulkAlertEnabled}
                        disableHoverListener={!isCreateBulkAlertEnabled}
                        disableTouchListener={!isCreateBulkAlertEnabled}
                        disableInteractive={!isCreateBulkAlertEnabled}
                        title={
                          <Typography>
                            {!isCreateBulkAlertEnabled
                              ? ""
                              : "When the query output is greater than 25, this will be disabled as default"}
                          </Typography>
                        }
                      >
                        <span>

                          <LabelText
                            style={{
                        marginBottom:'0px'                              
                            }}>
                            Trigger separate Alert for each matching result
                          </LabelText>
                        </span>
                      </Tooltip>
                    }
                  />
                )}
              />
            </Box>




            <Divider style={{ marginTop: '24px', color: '#475467', fontSize: '12px', fontWeight: 500 }} textAlign="left">Monitoring Schedule</Divider>

            <FormControl>
              <Controller
                name="cronType"
                control={control}
                rules={{ required: 'This field is required' }} // Validation rule
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={(e) => {
                      console.log('e.target.value', e.target.value);
                      setCronTypeValue(e.target.value);
                      setValue('cronType', e.target.value);

                      if (e.target.value === ICronValueType.INTERVAL) {
                        setValue('cronExpression', '');
                      } else if (e.target.value === ICronValueType.EXPRESSION) {
                        setValue('cronInterval', '');
                      }

                      setCronDesc('');
                      setCronNextRun('');
                    }}
                    style={{ marginTop: '12px' }}
                  >
                    <CustomFormControlLabel
                      value={ICronValueType.INTERVAL}
                      control={<Radio />}
                      label="Set Time Intervals"
                    />
                    <CustomFormControlLabel
                      value={ICronValueType.EXPRESSION}
                      control={<Radio />}
                      label="Advanced Scheduling"
                    />
                  </RadioGroup>
                )}
              />
              <FilterErrorMessage errors={errors} name={'cronType'} />
            </FormControl>

            {
              cronTypeValue == 'SCHEDULE' ?
                <Box marginTop={'24px'}>
                  Coming Soon...
                </Box> : cronTypeValue == 'INTERVAL' ?

                  <Box display={'flex'} alignItems={'center'} gap={'16px'} marginTop={'24px'} width={'50%'} justifyContent={'space-between'}>
                    <LabelText>Monitor every</LabelText>

                    <Box width={'70%'}>
                      <MonitorInterfaceSelect
                        control={control}
                        name={"cronInterval"}
                        errors={errors}
                        defaultValue={cronDescriptionArray[0]}
                        options={cronDescriptionArray}
                        isLoading={false}
                        placeholder=''
                        isDisabled={false}
                        rules={{ required: "cron interval is required" }}
                        onChange={async (datasourceValue: any) => {
                          setValue('cronInterval', datasourceValue)
                          getCronDescription();
                        }}
                      />
                    </Box>


                  </Box>
                  : cronTypeValue == 'EXPRESSION' ?
                    <>
                      <Box marginTop={'24px'} width={'50%'}>
                        <TextWrapper style={{ marginTop: '18px' }}>
                          <LabelText>Cron Expression</LabelText>
                          <Controller
                            name={'cronExpression'}
                            control={control}
                            rules={{ required: "Cron Expression field is requried" }}
                            render={({
                              fieldState: { error }
                            }) => (
                              <MonitorTextField
                                error={!!error}
                                variant="outlined"
                                placeholder='*/10 * * * *'
                                {...register("cronExpression", {
                                  required: "Cron Expression field is requried",
                                })}
                              />
                            )}
                          />
                          <FilterErrorMessage errors={errors} name={'cronExpression'} />
                        </TextWrapper>
                          </Box>
                          <Box width={'65%'}>
                        <CronHelperText>
                        Need help with writing the expression? Use the <a style={{
                            color: '#3054B9',
                            textDecoration: 'none'
                          }} href="https://crontab.guru" target="_blank" rel="noopener noreferrer">Cron Editor</a> here.
                        </CronHelperText></Box>
                    </>
                    : null
            }

            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={'16px'} marginTop={'18px'} width={'50%'} justifyContent={'space-between'}>

              <LabelText>Timezone</LabelText>

              <Box width={'70%'}>

                <MonitorInterfaceSelect
                  control={control}
                  name={"timeZone"}
                  errors={errors}
                  defaultValue={timeZoneDefaultValue}
                  options={allTimezones}
                  isLoading={false}
                  placeholder=''
                  isDisabled={false}
                  rules={{ required: "Timezone is required" }}
                  onChange={async (datasourceValue: any) => {
                    setValue('timeZone', datasourceValue)
                    getCronDescription();
                  }}
                />
              </Box>


            </Box>


            {
              cronDesc && cronNextRun &&
              <Box height={'25px'} marginBottom={'10px'}>
                {
                  cronDescLoading && <CircularProgress color="inherit" />
                }

                {
                  !cronDescLoading && <>
                    <MonitorCronDescText>{cronDesc}</MonitorCronDescText>

                    <MonitorCronNextRun>{cronNextRun}</MonitorCronNextRun>
                  </>
                }

              </Box>

            }

          </Box>
        </>
      }
    </>
  )
}

// export default CustomiseMyAlertSection
export default React.memo(CustomiseMyAlertSection);