import { ThemeProvider } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Tooltip,
} from "@mui/material";
import { userDecodedData } from "utils/UserAuth.utils";
import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";
import { PageCategoryEnum, recordPageVisit } from "config/Rudderstack";
import notification from "notifications/notifications";
import { UserService } from "providers/data/services/UserService";
import React, { useState, FunctionComponent, useEffect } from "react";
import theme from "styles/theme";
import { useHistory, Redirect } from "react-router-dom";
import { recordRSEvent } from "utils/CommonUtils";
import { ZButton } from "../../../views/commonComponents/commonComponents.styles";
import LoadingScreen from "views/LoadingScreen/LoadingScreen";
import InfoIcon from "@mui/icons-material/Info";
const logo: string = require("../../../assests/darklogo.svg").default;

const FPChangePassword: FunctionComponent = () => {
  const history = useHistory();
  const { userId, otp } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showChangePassword, setShowChangePassword] = useState<boolean>(false);

  useEffect(() => {
    recordPageVisit(PageCategoryEnum.changePassword, "change_password");
  }, []);

  const submit = (e: React.SyntheticEvent) => {
    recordRSEvent("confirm_password_click", {
      context: "forgot_password",
      userId: userId,
    });
    e.preventDefault();
    if (password.length < 8 || password !== confirmPassword) {
      notification(
        "error",
        "Both the passwords should be same and minimum length of 8 characters"
      );
    } else {
      setLoading(true);
      UserService.resetPassword(userId, { password, otp })
        .then((res) => {
          if (res) {
            if (res?.code == "200") {
              recordRSEvent("confirm_password_success", {
                context: "forgot_password",
                userId: userId,
              });
              notification("success", "Password Changed");
              history.push("/login");
            } else {
              if (res?.data?.[0])
                res?.data?.[0]?.split(",")?.forEach((errMsg: string) => {
                  notification("error", errMsg);
                });
              else notification("error", res?.message);
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          recordRSEvent("confirm_password_fail", {
            context: "forgot_password",
            userId: userId,
          });
          setLoading(false);
          notification("error", "Error in Changing Password");
        });
    }
  };

  if (!isEmpty(userDecodedData())) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }

  const handleClickShowPassword = () => {
    !showPassword ? setShowPassword(true) : setShowPassword(false);
  };

  const handleClickShowConfirmPassword = () => {
    !showChangePassword
      ? setShowChangePassword(true)
      : setShowChangePassword(false);
  };

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <ThemeProvider theme={theme}>
            <div className="login">
              <div className="loginInner">
                <div className="loginImg">
                  <img src={logo} alt="" />
                </div>
                <h2 className="loginTitle">Reset Password</h2>
                <form className="loginForm" onSubmit={submit}>
                  <Box sx={{ width: "320px", mt: 3 }}>
                    <FormControl variant="standard" fullWidth>
                      <FormLabel
                        sx={{
                          fontFamily: "'Inter', sans-serif",
                          mb: 1,
                          fontWeight: "bold",
                          color: "rgba(0,0,0,0.8)",
                          fontSize: "small",
                        }}
                        htmlFor="my-input"
                      >
                        Enter new Password
                        <Tooltip title="Minimum 8 characters, At least 1 Uppercase, At least 1 lower case, At least one number, At least one special character, No consecutive alphabets,  No consecutive numbers, No username">
                    <IconButton size="medium">
                      <InfoIcon fontSize="medium" />
                    </IconButton>
                  </Tooltip>
                      </FormLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        size="small"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              sx={{}}
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Box>
                  <Box sx={{ width: "320px", mt: 3 }}>
                    <FormControl variant="standard" fullWidth>
                      <FormLabel
                        sx={{
                          fontFamily: "'Inter', sans-serif",
                          mb: 1,
                          fontWeight: "bold",
                          color: "rgba(0,0,0,0.8)",
                          fontSize: "small",
                        }}
                        htmlFor="my-input"
                      >
                        Confirm your new Password
                      </FormLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={showChangePassword ? "text" : "password"}
                        size="small"
                        required
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              sx={{}}
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfirmPassword}
                              edge="end"
                            >
                              {showChangePassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Box>
                  <ZButton
                    variant="contained"
                    fullWidth
                    type="submit"
                    sx={{ mt: 4, mb: 4 }}
                  >
                    Confirm Password
                  </ZButton>
                </form>
              </div>
            </div>
          </ThemeProvider>
        </>
      )}
    </>
  );
};

export default FPChangePassword;
