import filter from 'lodash/filter';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import T from 'prop-types';
import React, { Component } from 'react';
import { Datagrid } from 'react-admin';
import { IoIosListBox } from 'react-icons/io';
import { ZButton } from 'views/commonComponents/commonComponents.styles.tsx';
import LocalUserSettings from '../../utils/LocalUserSettings';
import TableColumnSelectionDialog from './TableColumnSelectionDialog';

const arrayToSelection = (values) =>
  values.reduce((selection, columnName) => {
    selection[columnName] = true;
    return selection;
  }, {});

// CustomizableDatagrid allows to show/hide columns dynamically
// the preferences are stored in local storage
class CustomizableDatagrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpened: false,
      selection: this.getInitialSelection(),
    };
  }

  getColumnNames() {
    const { children } = this.props;
    return filter(React.Children.map(children, (field) => get(field, ['props', 'source'])));
  }

  getColumnLabels() {
    const { children } = this.props;
    return filter(
      React.Children.map(
        children,
        (field) =>
          field && {
            source: get(field, ['props', 'source']),
            label: get(field, ['props', 'label']),
          }
      ),
      (item) => item && item.source
    );
  }

  getInitialSelection() {
    const { defaultColumns, resource, children, storage } = this.props;

    const previousSelection = storage.get(resource);

    // if we have a previously stored value, let's return it
    if (!isEmpty(previousSelection)) {
      return previousSelection;
    }

    // if defaultColumns are set let's return them
    if (!isEmpty(defaultColumns)) {
      return arrayToSelection(defaultColumns);
    }

    // otherwise we fallback on the default behavior : display all columns
    return arrayToSelection(this.getColumnNames());
  }

  // updates the storage with the internal state value
  updateStorage = () => {
    this.props.storage.set(this.props.resource, this.state.selection);
  };

  toggleColumn = (columnName) => {
    const previousSelection = this.state.selection;
    const selection = {
      ...previousSelection,
      [columnName]: !previousSelection[columnName],
    };
    this.setState({ selection }, this.updateStorage);
  };

  handleOpen = () => this.setState({ modalOpened: true });
  handleClose = () => this.setState({ modalOpened: false });

  renderChild = (child) => {
    const source = get(child, ['props', 'source']);
    const { selection } = this.state;

    // Show children without source, or children explicitly visible
    if (!source || selection[source]) {
      return React.cloneElement(child, {});
    }

    return null;
  };

  render() {
    const { children, defaultColumns, ...rest } = this.props;
    const { selection, modalOpened } = this.state;

    return (
      <>
        {this?.props?.modalOpened && (
          <TableColumnSelectionDialog
            selection={selection}
            columns={this.getColumnLabels()}
            onColumnClicked={this.toggleColumn}
            onClose={this.props.handleClose}
          />
        )}
        <div style={{ overflowX: 'auto', display: 'block', whiteSpace: 'nowrap',marginTop:"-20px" }}>
          <Datagrid {...rest}>{React.Children.map(children, this.renderChild)}</Datagrid>
        </div>
      </>
    );
  }
}

CustomizableDatagrid.propTypes = {
  defaultColumns: T.arrayOf(T.string),
  storage: T.shape({
    get: T.func.isRequired,
    set: T.func.isRequired,
  }),
};

CustomizableDatagrid.defaultProps = {
  defaultColumns: [],
  storage: LocalUserSettings,
};

export default CustomizableDatagrid;
