import jwt_decode from "jwt-decode"

export enum OnboardingStep {
    OVERVIEW = "overview",
    WORKFLOW = "workflow",
    "CREATE_TASK" = "create_task",
    COMPLETED = "completed",
    "EXECUTE_TASK" = "execute_task"

}

export type StepType = {
    stepId: string,
    startedOn: string,
    completedOn: string,
    isCompleted: boolean
}

export type InitialStateType = {
    showOnboardingModal: boolean,
    isBeingOnboarded: boolean,
    onboarding: {
        currentStepId: OnboardingStep | "",
        isOnboardingDoneForWeb: boolean,
        isOnboardingDoneForApp: boolean,
        onboardingCompletedOn: string | null,
        onboardingStartedOn: string | null,
        steps: StepType[] | []
    }


}

export enum RedirectButtonTypes {
    VIDEO_TUTORIAL = "video_tutorial",
    CHAT_SUPPORT = "chat_support",
    EMAIL_SUPPORT = "email_support",
    SUGGEST_A_TEMPLATE = "suggest_a_template",
    BOOK_A_SESSION = "book_a_session",
    SLACK_COMMUNITY = "slack_community",
    FAQ_PAGE = "faq_page",
    DOCUMENTATION = "documentation"
}


export const OnboardingLinks = {
    [RedirectButtonTypes.VIDEO_TUTORIAL]: "https://www.youtube.com/channel/UCNDY7jiOh7uOJWWRDnLE9Vw/videos",
    [RedirectButtonTypes.EMAIL_SUPPORT]: "support@zorp.one",
    [RedirectButtonTypes.SUGGEST_A_TEMPLATE]: "https://forms.gle/zZxaKxAPKU7H164A7",
    // [RedirectButtonTypes.BOOK_A_SESSION]: "https://calendly.com/bala-zorp/zorp-demo",
    [RedirectButtonTypes.BOOK_A_SESSION]: "https://www.zorp.one/book-a-free-demo",
    [RedirectButtonTypes.SLACK_COMMUNITY]: "https://join.slack.com/t/zorpcommunity/shared_invite/zt-19nmuuwps-ZrpUpgr3M~dd0Vqx3efh2A",
    [RedirectButtonTypes.DOCUMENTATION]: "https://docs.zorp.one/",
    [RedirectButtonTypes.FAQ_PAGE]: "https://docs.zorp.one/product-docs/frequently-asked-questions-faqs"
}

export const getTemplateWorflowId = () => {
    if (process.env["REACT_APP_ENVIRONMENT"] === "prod") {
        return "qfiv8y"
    } else {
        return "xpq8d6"
    }
}

export const getUserName = () => {
    try {
        const at = localStorage.getItem("at")
        //@ts-ignore
        const decoded = jwt_decode(at) || {}
        //@ts-ignore 
        return decoded?.name || "Buddy"
    } catch (error) {
        return "Buddy"
    }

}