import { IFilterContext } from "filter/constants/Filter.types";
import { filterTitle } from "filter/constants/FilterConstants";
import FilterModal from "filter/FilterModal";
import { clearFilterStorage, getFilterCount } from 'filter/filterUtils';
import useApplyFilter from "filter/hooks/useApplyFilter";
import { useEffect, useState } from 'react';
import {
  ChipField, Datagrid, ExportButton, List, TextField, TopToolbar, useRecordContext
} from 'react-admin';
import { userDecodedData } from "utils/UserAuth.utils";
import Callout from 'views/onboarding/components/Callout';
import FilterButton from 'views/users/components/FilterButton';
import { PageCategoryEnum, recordPageVisit } from '../../config/Rudderstack';
import { useStyles } from '../../styles/tableStyles';
import CustomUrlField from '../components/CustomUrlField';
import { useTheme } from "styled-components";



const AlertListActions = ({
  resource,
  filters,
  displayedFilters,
  hideFilter,
  filterValues,
  basePath,
  setFilters,
  showFilter,
  refresh,
  modalOpened,
  setShowColumnSelector,
  ...rest
}) => {
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const getFilterValue = useApplyFilter(IFilterContext.ALERT, setFilters);
  const [filterCount, setFilterCount] = useState(getFilterValue.filterCount || 0);
  const [modalPosition, setModalPosition] = useState([0, 0, 0])  //[X,Y]
  const { userId = "" } = userDecodedData();

  useEffect(() => {
    if(getFilterValue?.filterCount >= 0){
      setFilterCount(getFilterValue.filterCount)
    }
  }, [getFilterValue.filterCount])


  useEffect(() => {
    setFilterCount(getFilterCount(IFilterContext.ALERT,userId))
  }, [])

  return (
    <TopToolbar className='tool-bar'>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignContent: "center",
          gap:'10px',
          width:'100%'
        }}
      >

        <FilterButton filterCount={filterCount} onRemoveFilterClick={() => {
          setFilters({}, []);
          setFilterCount(0);
          clearFilterStorage(IFilterContext.ALERT, userId);
        }}
          onOpenFilterClick={(pos) => {
            const x = pos.current.offsetParent.offsetLeft + pos.current.offsetLeft;
            const y = pos.current.offsetParent.offsetParent.offsetTop + pos.current.offsetTop + pos.current.offsetHeight;
            const buttonHeight = pos.current.offsetHeight;
            const buttonWidth = pos.current.offsetWidth;
            setModalPosition([x, y, buttonHeight,buttonWidth]);
            setOpenFilterModal(true);
            showFilter("main");
          }}
        />

        {
          (displayedFilters?.main && openFilterModal) &&
          <FilterModal position={modalPosition} filterTitle={filterTitle} urlEndpoint="/alerts" context={IFilterContext.ALERT} openModal={openFilterModal} handleClose={(appliedFilterCount) => {
            setOpenFilterModal(false);
            hideFilter("main");
            if (appliedFilterCount >= 0) {
              setFilterCount(appliedFilterCount);
            }
          }} />
        }

        <ExportButton
          style={{
            border: "1px solid #c2c9d1",
            color: "#616569",
            fontSize: "1rem",
            fontWeight: "600",
            backgroundColor: "#F2F2F2",
            marginRight: "8px",
            padding: "6px 16px",
            width:'87px'
          }}

        />
      </div>
    </TopToolbar>
  );
};

const ConditionalChipField = (props) => {
  const theme = useTheme()
  const classes = useStyles({theme});
  const record = useRecordContext(props);
  if (record && record.status === 'NEW')
    return (
      <ChipField
        className='svg_icons_m'
        label='Status'
        source='status'
        classes={{ chip: classes.chip }}
        style={{ backgroundColor: record.isDeleted ? '#D3D3D3' : '#F7CF60', margin: "-9px 0px -6px -12px", color: "black", fontSize: "14px", fontWeight: "400" }}
      />
    );

  if (record && record.status === 'DONE')
    return (
      <ChipField
        className='svg_icons_m'
        label='Task Status'
        style={{ backgroundColor: record.isDeleted ? '#D3D3D3' : '#8ADA89', margin: "-9px 0px -6px -12px", color: "black", fontSize: "14px", fontWeight: "400" }}
        source='status'
        classes={{ chip: classes.chip }}
      />
    );

  return (
    <ChipField
      className='svg_icons_m'
      label='Status'
      style={{ backgroundColor: '#81d4fa' }}
      color='default'
      source='status'
      classes={{ chip: classes.chip }}
    />
  );
};
export const AlertList = (props) => {
  const theme = useTheme()
  const classes = useStyles({theme});

  useEffect(() => {
    recordPageVisit(PageCategoryEnum.user, 'alert_list');
  }, [props]);

  return (
    <>
      <div style={{ padding: "20px" }}>
        <Callout text={
          <p> Any API Failures from the Actions setup in your workflows will be shown here </p>
        } />
      </div>
      <div>

        <List
          {...props}
          bulkActionButtons={false}
          actions={<AlertListActions
            {...props}
          />}
          classes={{ main: classes.main }}
          perPage={25}
        >
          <div style={{ overflowX: "auto" }}>
            <Datagrid
              classes={{headerCell: classes.headerCell,
                headerRow: classes.headerRow,
                rowEven: classes.rowEven,
                rowOdd: classes.rowOdd,
                rowCell: classes.rowCell,
                table : classes.table }}
              rowClick='show'
            >
              <TextField sortable={false} textAlign='left' label='Alert Id' source='alertId' />
              <TextField sortable={false} textAlign='left' label='Created On' source='createdOn' />
              <TextField
                sortable={false}
                textAlign='left'
                label='Message'
                source='message'
                className={classes.ellipsisAlert}
              />
              <CustomUrlField label='Entity' textAlign='left' source='entityUrl' text='entityText' />
              <TextField sortable={false} textAlign='left' label='Team' source='teamsInfo' />
              <ConditionalChipField sortable={false} label='Status' />
            </Datagrid>
          </div>
        </List>
      </div>
    </>
  );
};
