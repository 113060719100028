import { isEdge, isNode } from "react-flow-renderer";
import { NodeTypes } from "views/workflows/nodes/Nodes.types";
import { guardTypes } from "views/workflows/workflowactions/api/guard.types";
import { TaskFlowService } from "../../providers/data/services/TaskFlowService";
import invalidEventName from "./InvalidEventName";
//@ts-ignore
import { ErrorLocations } from "views/workflows/WorkflowErrors/utils";
import { actionType } from "views/automationBuilder/types/ABType";
import { cloneDeep } from "lodash";

type WorkflowErrorType = {
  message: string;
  location: ErrorLocations
  payload: {};
};

export class WorkflowValidationUtils {
  /**
   * This function validates the entire workflow (workflow, state details, state components, transition details, transition actions)
   */
  public static validateWorkflow = async (taskTypeId: string,store:any): Promise<any> => {
    // let flowGraphCopy = JSON.parse(JSON.stringify(flowGraph));
    let workflowData = store?.getState()?.builder?.workflow

    workflowData = cloneDeep(workflowData)
    let allActionsNames: any = {};

    // remove all null from conditions/actions in choice node
    workflowData?.canvas?.elements?.forEach((ele: any) => {
      if (ele.type === "zorpDecisionNode") {
        ele.data.handleInfo.forEach((handle: any) => {
          handle.data.conditions = handle.data.conditions.filter(
            (condition: any) => condition
          );
          handle.data.actions = handle.data.actions.filter(
            (action: any) => action
          );
        });
      }
    });

    // remove all null values from conditions/actions in transitions
    workflowData?.canvas?.elements?.forEach((ele: any) => {
      if (isEdge(ele)) {
        if (ele.data?.actions) {
          ele.data.actions = ele.data.actions.filter(
            (action: any) => action
          );
        }

        if (ele.data?.guards) {
          ele.data.guards = ele.data.guards.filter((guard: any) => guard);
        }

        if (ele.data?.conditions) {
          ele.data.conditions = ele.data.conditions.filter((guard: any) => guard);
        }
      }
    });
    let allWorkflowErrors: WorkflowErrorType[] = [];

    workflowData?.canvas?.elements?.forEach((element: any) => {
      if (isEdge(element)) {
        //@ts-ignore
        element?.data?.actions?.forEach((item: any) => {
          if (item.actionName in allActionsNames) {
            allActionsNames[item.actionName] += 1;
          } else {
            allActionsNames[item.actionName] = 1;
          }
        });
      }
    });

    // Validate workflow attribute details

    let workflowAttributeError =
      WorkflowValidationUtils.validateWorkflowAttributes(
        workflowData,
        allWorkflowErrors
      );

    // Validate step attribute details and step components
    let stepAttributeError = WorkflowValidationUtils.validateStepAttributes(
      workflowData,
      allWorkflowErrors
    );

    // Validate transition details
    let transitionError = WorkflowValidationUtils.validateTransitions(
      workflowData,
      allWorkflowErrors
    );

    // validate push notification action
    let pushNotificationError = WorkflowValidationUtils.validPushNotification(
      workflowData,
      allActionsNames,
      allWorkflowErrors
    );

    //  Validate API action
    let apiErrors = WorkflowValidationUtils.validAPIAction(
      workflowData,
      allActionsNames,
      allWorkflowErrors
    );

    let atleastOneConditionInChoiceNodeError =
      WorkflowValidationUtils.atleastOneConditionInChoiceNodeSwitch(
        workflowData,
        allWorkflowErrors
      );

    let validConditionAndGuard =
      WorkflowValidationUtils.validConditionsAndActions(
        workflowData,
        allWorkflowErrors
      );

    let isValidEdgeConnectionForChoiceNode =
      WorkflowValidationUtils.validateIfAllHandlesHaveEdgesInChoiceNode(
        workflowData,
        allWorkflowErrors
      );

    let hasIncomingEdge =
      WorkflowValidationUtils.validateIfChoiceNodeHasIncomingEdge(
        workflowData,
        allWorkflowErrors
      );

    let errorInGuardsInTransition =
      WorkflowValidationUtils.validateGuardsInTransitions(
        workflowData,
        allWorkflowErrors
      );

    let systemTrasitionActionError = WorkflowValidationUtils.validateSystemTransitionActions(
      workflowData,
      allWorkflowErrors
    );

    let isHomeNodePresent = WorkflowValidationUtils.checkIfWorkflowHasHomeNode(
      workflowData,
      allWorkflowErrors
    )

    let isTerminalNode = WorkflowValidationUtils.checkIfWorkflowHasTerminalNode(
      workflowData,
      allWorkflowErrors
    )

    let checkMultipleNodeWithSameName = WorkflowValidationUtils.checkMultipleNodesWithSameName(workflowData, allWorkflowErrors)

    // check multiple transition from same node with same name.
    let hasMutilpleTransitionFromSameNodeWithSameName = WorkflowValidationUtils.checkMultipleTransitionName(workflowData, allWorkflowErrors)

    // check if any node is dangling i.e not connected to any transition
    let hasUnconnectedNode = WorkflowValidationUtils.checkNodeNotConnected(workflowData, allWorkflowErrors)

    // check if task create option has a valid display name
    let hasValidTaskCreateOption = WorkflowValidationUtils.checkAppTaskCreateConfig(workflowData, allWorkflowErrors)

    return {
      workflowData,
      allWorkflowErrors,
      isErrorPresent:
        systemTrasitionActionError ||
        errorInGuardsInTransition ||
        hasIncomingEdge ||
        isValidEdgeConnectionForChoiceNode ||
        validConditionAndGuard ||
        apiErrors ||
        pushNotificationError ||
        transitionError ||
        stepAttributeError ||
        workflowAttributeError ||
        atleastOneConditionInChoiceNodeError ||
        !isHomeNodePresent ||
        !isTerminalNode ||
        hasMutilpleTransitionFromSameNodeWithSameName ||
        checkMultipleNodeWithSameName ||
        hasUnconnectedNode ||
        hasValidTaskCreateOption
    };
  };

  /**
   * Validate workflow attributes
   * 1. Workflow name is provided
   * 2. Workflow name is not a duplicate
   * 3. Workflow name is not greater than 100 chars
   * 4. Workflow name should not have special characters - Only alphanumeric, underscore, space and hyphen
   * 5. There are no duplicate step names
   * @param workflowData
   */

  private static validateWorkflowAttributes = (
    workflowData: any,
    allWorkflowErrors: WorkflowErrorType[]
  ) => {
    if (!workflowData) 
      return;
    workflowData.errors = [];
    let workflowAttributeError = false;
    // Workflow name is provided
    if (
      !workflowData.taskTypeDisplayName ||
      workflowData?.taskTypeDisplayName?.trim()?.length === 0
    ) {
      workflowData.errors.push("Workflow name cannot be empty");
      allWorkflowErrors.push({
        location: ErrorLocations.WORKFLOW,
        message: "Workflow name cannot be empty",
        payload: {},
      });
    }

    // Workflow name is not a duplicate
    // TBD

    // Workflow name is not greater than 100 chars
    if (workflowData?.taskTypeDisplayName?.trim()?.length > 100) {
      workflowData.errors.push(
        "Workflow name cannot be more than 100 characters"
      );

      allWorkflowErrors.push({
        location: ErrorLocations.WORKFLOW,
        message: "Workflow name cannot be more than 100 characters",
        payload: {},
      });
    }

    // Workflow name should not have special characters - Only alphanumeric, underscore, space and hyphen
    if (
      !new RegExp(/^[a-z0-9:_-\s]+$/i).test(workflowData?.taskTypeDisplayName)
    ) {
      workflowData.errors.push(
        "Workflow name can only have alphanumeric, underscore, space and hyphen as characters"
      );

      allWorkflowErrors.push({
        location: ErrorLocations.WORKFLOW,
        message:
          "Workflow name can only have alphanumeric, underscore, space and hyphen as characters",
        payload: {},
      });
    }

    // There are no duplicate step names
    // TBD
    if (workflowData?.errors?.length > 0) {
      workflowAttributeError = true;
    }

    return workflowAttributeError;
  };

  /**
   * Validate step attribute
   * 1. Step name is provided
   * 2. Step name is not greater than 100 chars
   * 3. Step name is not default name (UNNAMED_STEP)
   * 4. Two or more steps cannot have the same name
   *
   * // Step components validation
   * 1. Step has atleast one component
   * 2. Each step component has
   *      2a. componentKey is not empty
   *      2b. componentKey is a valid data field
   *      2c. expression is a valid javscript statement
   *      2d. function is a valid javscript function
   * @param workflowData
   */
  private static validateStepAttributes = (
    workflowData: any,
    allWorkflowErrors: WorkflowErrorType[]
  ) => {
    let validateStepAttributeError = false;
    workflowData?.canvas?.elements?.forEach((element: any) => {
      if (isNode(element)) {
        element.data.errors = [];

        // Step name is provided
        //@ts-ignore
        if (!element?.name || element?.name?.trim()?.length === 0) {
          element.data.errors.push("Step name cannot be empty");
          allWorkflowErrors.push({
            location: ErrorLocations.NODE,
            message: "Step name cannot be empty",
            payload: {
              nodeData: element,
            },
          });
        }

        // Step name is not greater than 100 chars
        //@ts-ignore
        if (element?.name?.trim()?.length > 100) {
          element.data.errors.push(
            "Step name cannot be more than 100 characters"
          );
          allWorkflowErrors.push({
            location: ErrorLocations.NODE,
            message: "Step name cannot be more than 100 characters",
            payload: {
              nodeData: element,
            },
          });
        }

        // Step name is not default name (UNNAMED_STEP)
        //@ts-ignore
        if (element?.name?.trim() === "UNNAMED_STEP") {
          element.data.errors.push(
            "Step name has to be different than UNNAMED_STEP"
          );
          allWorkflowErrors.push({
            location: ErrorLocations.NODE,
            message: "Step name has to be different than UNNAMED_STEP",
            payload: {
              nodeData: element,
            },
          });
        }

        if (element.data.errors.length > 0) {
          validateStepAttributeError = true;
        }
        // Step components validation
      }
    });

    return validateStepAttributeError;
  };

  /**
   * Validate transition details
   * 1. Transition has a valid name
   * 2. Validate API actions if present. Each API action should have
   *      2a. Name
   *      2b. Valid URL
   *      2c. If not GET, should have a valid JSON body
   *      2d. If header has a key, should have a value
   * 3. Validate Push notification actions if present. Each pushy notification action should have
   *      3a. Name
   *      3b. Title
   *      3c. Message
   * @param workflowData
   */
  private static validateTransitions = (
    workflowData: any,
    allWorkflowErrors: WorkflowErrorType[]
  ) => {
    let transitionError = false;
    workflowData?.canvas?.elements?.forEach((element: any) => {
      if (isEdge(element)) {
        element.data.errors = [];
        if (element.data.event === "") {
          element.data.errors.push("The transition must have a name");
          allWorkflowErrors.push({
            location: ErrorLocations.TRANSITION,
            message: "The transition must have a name",
            payload: {
              transition: element,
            },
          });
        }

        if (invalidEventName.includes(element?.data?.event)) {
          element.data.errors.push(
            `Event name cannot be ${invalidEventName?.map(
              (name, index) => `${name}`
            )} as these are reserved keywords.`
          );
          allWorkflowErrors.push({
            location: ErrorLocations.TRANSITION,
            message: `Event name cannot be ${invalidEventName?.map(
              (name, index) => `${name}`
            )} as these are reserved keywords.`,
            payload: {
              transition: element,
            },
          });
        }

        if (!new RegExp(/^[a-z0-9_-\s]+$/i).test(element?.data?.event)) {
          element.data.errors.push(
            "Transition name  can only have alphanumeric, underscore, space and hyphen as characters"
          );
          allWorkflowErrors.push({
            location: ErrorLocations.TRANSITION,
            message:
              "Transition name  can only have alphanumeric, underscore, space and hyphen as characters",
            payload: {
              transition: element,
            },
          });
        }
        if (element.data.errors.length > 0) {
          transitionError = true;
        }
      }
    });

    return transitionError;
  };

  private static checkIfWorkflowHasHomeNode = (
    workflowData: any,
    allWorkflowErrors: WorkflowErrorType[]
  ) => {
    let isHomeNodePresent = false
    workflowData?.canvas?.elements?.forEach((element: any) => {
      if (element.data?.isHomeNode) {
        isHomeNodePresent = true
      }
    })
    if (!isHomeNodePresent) {
      allWorkflowErrors.push({
        location: ErrorLocations.HOME_NODE,
        message: "Please select any node as a home node",
        payload: {}
      })
    }
    return isHomeNodePresent

  }

  private static checkIfWorkflowHasTerminalNode = (
    workflowData: any,
    allWorkflowErrors: WorkflowErrorType[]
  ) => {
    let isTerminalNodePresent = false
    workflowData?.canvas?.elements?.forEach((element: any) => {
      if (element.data?.isTerminalNode) {
        isTerminalNodePresent = true
      }
    })
    if (!isTerminalNodePresent) {
      allWorkflowErrors.push({
        location: ErrorLocations.TERMINAL_NODE,
        message: "Please select any node as a terminal node",
        payload: {}
      })
    }
    return isTerminalNodePresent

  }

  private static validPushNotification = (
    workflowData: any,
    allActionsNames: any,
    workflowErrors: WorkflowErrorType[]
  ) => {
    let pushNotificationError = false;
    const invalidName = (str: string): boolean => {
      if (!new RegExp(/^[a-z0-9_\s${}.]+$/i).test(str)) {
        return true;
      }

      return false;
    };

    workflowData?.canvas?.elements?.forEach((element: any) => {
      if (isEdge(element)) {
        //@ts-ignore
        element?.data?.actions?.forEach((item: any) => {
          //@ts-ignore
          if (item.actionType === actionType.PUSH_NOTIFICATION) {
            item.errors = [];
            //@ts-ignore

            if (!item?.actionName || item?.actionName === "") {
              item.errors.push("Push Notification must have a name");
              element.data.errors.push("Push Notification must have a name");
              workflowErrors.push({
                message: "Push Notification must have a name",
                location: ErrorLocations.ACTION_TRANSITION,
                payload: {
                  transition: element,
                  action: item,
                },
              });

              if (invalidName(item?.message)) {
                item.errors.push(
                  "Push Notification Message can only have alphanumeric, underscore, space and hyphen,$,{,} and period(.) as characters"
                );
                workflowErrors.push({
                  message:
                    "Push Notification Message can only have alphanumeric, underscore, space and hyphen,$,{,} and period(.) as characters",
                  location: ErrorLocations.ACTION_TRANSITION,
                  payload: {
                    transition: element,
                    action: item,
                  },
                });
                // element.data.errors.push("Push Notification title can only have alphanumeric, underscore, space and hyphen as characters")
              }
            }

            // if(!item?.message) {
            //   item.errors.push("Push Notification must have a message");
            //   workflowErrors.push({
            //     message: "Push Notification must have a message",
            //     location: ErrorLocations.ACTION_TRANSITION,
            //     payload:{
            //       transition: element,
            //       action: item
            //     }
            //   })
            // }

            // if(!item?.title) {
            //   item.errors.push("Push Notification must have a title");
            //   workflowErrors.push({
            //     message: "Push Notification must have a title",
            //     location: ErrorLocations.ACTION_TRANSITION,
            //     payload:{
            //       transition: element,
            //       action: item
            //     }
            //   })
            // }

            // as some of the older worklows may have PN message as simple string and not an object.

            if (typeof item?.message === "string") {
              if (item?.message || item?.message === "") {
                item.errors.push("Push Notification must have a message");
                workflowErrors.push({
                  message: "Push Notification must have a message",
                  location: ErrorLocations.ACTION_TRANSITION,
                  payload: {
                    transition: element,
                    action: item,
                  },
                });
                // element.data.errors.push("Push Notification must have a message")
              }
            } else {
              if (!item?.message?.value || item?.message?.value === "") {
                item.errors.push("Push Notification must have a message");
                workflowErrors.push({
                  message: "Push Notification must have a message",
                  location: ErrorLocations.ACTION_TRANSITION,
                  payload: {
                    transition: element,
                    action: item,
                  },
                });
                // element.data.errors.push("Push Notification must have a message")
              }
            }

            if (typeof item?.title === "string") {
              if (item?.title || item?.title === "") {
                item.errors.push("Push Notification must have a title");
                workflowErrors.push({
                  message: "Push Notification must have a title",
                  location: ErrorLocations.ACTION_TRANSITION,
                  payload: {
                    transition: element,
                    action: item,
                  },
                });
                // element.data.errors.push("Push Notification must have a message")
              }

              if (invalidName(item?.title)) {
                item.errors.push(
                  "Push Notification Title can only have alphanumeric, underscore, space and hyphen,$,{,} and period(.)as characters"
                );
                workflowErrors.push({
                  message:
                    "Push Notification Title can only have alphanumeric, underscore, space and hyphen,$,{,} and period(.)as characters",
                  location: ErrorLocations.ACTION_TRANSITION,
                  payload: {
                    transition: element,
                    action: item,
                  },
                });
                // element.data.errors.push("Push Notification message can only have alphanumeric, underscore, space and hyphen as characters")
              }
            } else {
              if (item?.title?.value === false || item?.title?.value === "") {
                item.errors.push("Push Notification must have a title");
                workflowErrors.push({
                  message: "Push Notification must have a title",
                  location: ErrorLocations.ACTION_TRANSITION,
                  payload: {
                    transition: element,
                    action: item,
                  },
                });

                // element.data.errors.push("Push Notification must have a message")
              }
            }

            if (invalidName(item?.actionName)) {
              item.errors.push(
                "Push Notification Name can only have alphanumeric, underscore, space,hyphen,$,{,} and period(.) as characters"
              );
              workflowErrors.push({
                message:
                  "Push Notification Name can only have alphanumeric, underscore, space,hyphen,$,{,} and period(.) as characters",
                location: ErrorLocations.ACTION_TRANSITION,
                payload: {
                  transition: element,
                  action: item,
                },
              });
              // element.data.errors.push("Push Notification Name can only have alphanumeric, underscore, space and hyphen as characters")
            }

            // Unique Name check
            // if(allActionsNames.includes(item?.actionName)){
            //     item.errors.push("All actions must have a unique name!")
            // }
            //@ts-ignore
            if (allActionsNames[item?.actionName] > 1) {
              item.errors.push("All actions must have a unique name!");
              workflowErrors.push({
                message: "All actions must have a unique name!",
                location: ErrorLocations.ACTION_TRANSITION,
                payload: {
                  transition: element,
                  action: item,
                },
              });
            }
          }
          if (item?.errors?.length > 0) {
            pushNotificationError = true;
          }
        });
      }
    });

    return pushNotificationError;
  };

  private static validAPIAction = (
    workflowData: any,
    allActionsNames: string[],
    workflowErrors: WorkflowErrorType[]
  ) => {
    let apiErrors = false;
    const invalidName = (str: string): boolean => {
      if (!new RegExp(/^[a-z0-9_\s${}.]+$/i).test(str)) {
        return true;
      }

      return false;
    };

    const invalidURL = (str: string): boolean => {
      if (
        !new RegExp(
          /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
        ).test(str)
      ) {
        return true;
      }
      return false;
    };

    // function for checking valid JSON

    const tryParseJSONObject = (jsonString: string): boolean => {
      try {
        let o = JSON.parse(jsonString);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object",
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === "object") {
          return true;
        }
      } catch (e) { }

      return false;
    };

    workflowData?.canvas?.elements?.forEach((element: any) => {
      if (isEdge(element)) {
        if (!element.data.errors) {
          element.data.errors = [];
        }

        //@ts-ignore
        element?.data?.actions?.forEach((item: any) => {
          //@ts-ignore
          if (item.actionType === actionType.API_ACTION) {
            item.errors = [];

            // Must have a name check
            if (!item?.actionName || item?.actionName === "") {
              item.errors.push("API action must have a valid name!");
              workflowErrors.push({
                message: "All actions must have a unique name!",
                location: ErrorLocations.ACTION_TRANSITION,
                payload: {
                  transition: element,
                  action: item,
                },
              });
            }

            //Must have a valid name check
            if (invalidName(item?.actionName)) {
              item.errors.push("API action must have a valid name!");
              workflowErrors.push({
                message: "API action must have a valid name!",
                location: ErrorLocations.ACTION_TRANSITION,
                payload: {
                  transition: element,
                  action: item,
                },
              });

            }

            //Must have a unique name:
            // if(allActionsNames.includes(item?.actionName)){
            //     item.errors.push("All actions must have a unique name!")
            // }
            //@ts-ignore
            if (allActionsNames[item?.actionName] > 1) {
              item.errors.push("All actions must have a unique name!");
              workflowErrors.push({
                message: "All actions must have a unique name!",
                location: ErrorLocations.ACTION_TRANSITION,
                payload: {
                  transition: element,
                  action: item,
                },
              });
            }

       

            // Must have a valid URL
            if (invalidURL(item?.url?.value || item?.url)) {
              item.errors.push("Invalid URL Provided");
              workflowErrors.push({
                message: "Invalid URL Provided",
                location: ErrorLocations.ACTION_TRANSITION,
                payload: {
                  transition: element,
                  action: item,
                },
              });
            }

            // valid header check
            if (item?.headers !== "") {
              if (typeof item?.headers === "string") {
                const keyValuePair = item?.headers?.split("###");
                keyValuePair?.forEach((kvPair: string) => {
                  //split based on ":"
                  const singleKeyValue = kvPair?.split(":");
                  if (singleKeyValue.length !== 2) {
                    item.errors.push("Invalid Headers providers");
                    workflowErrors.push({
                      message: "Invalid Headers providers",
                      location: ErrorLocations.ACTION_TRANSITION,
                      payload: {
                        transition: element,
                        action: item,
                      },
                    });
                  }

                  singleKeyValue.forEach((skPair: any) => {
                    if (skPair === "") {
                      item.errors.push("Each Header must have a key value pair");
                      workflowErrors.push({
                        message: "Each Header must have a key value pair",
                        location: ErrorLocations.ACTION_TRANSITION,
                        payload: {
                          transition: element,
                          action: item,
                        },
                      });
                    }
                  });
                });
              } else {
                const allkeys = Object.keys(item?.headers || {});
                allkeys.forEach((key: string) => {
                  if (key.trim().length === 0) {
                    item.errors.push("Each Header must have a key value pair");
                    workflowErrors.push({
                      message: "Invalid Headers providers",
                      location: ErrorLocations.ACTION_TRANSITION,
                      payload: {
                        transition: element,
                        action: item,
                      },
                    });
                  }

                  if (item?.headers[key].length === 0) {
                    item.errors.push("Each Header must have a key value pair");
                    workflowErrors.push({
                      message: "Invalid Headers providers",
                      location: ErrorLocations.ACTION_TRANSITION,
                      payload: {
                        transition: element,
                        action: item,
                      },
                    });
                  }
                })
              }
            }

            if (item.errors.length > 0) {
              apiErrors = true;
            }

            // valid body(JSON) check
            // if(item?.body !== "") {
            //     if(!tryParseJSONObject(item?.body)){
            //         item.errors.push("Invalid Body provided. Body must be a valid json.")
            //     }
            // }
          }
        });

        if (element?.data?.errors?.length > 0) {
          apiErrors = true;
        }
      }
    });

    return apiErrors;
  };

  private static validateGuardsInTransitions = (
    workflowData: any,
    allWorkflowErrors: WorkflowErrorType[]
  ) => {
    let errorInGuardInTransition = false;
    workflowData?.canvas?.elements?.forEach((element: any, eleIdx: number) => {
      if (isEdge(element)) {
        element?.data?.guards?.forEach((guard: any, guardIdx: number) => {
          guard.errors = [];
          let guardErrors: any = this.validateGuard(guard) || [];
          guard.errors = [...guardErrors];
          if (guard.errors.length > 0) {
            errorInGuardInTransition = true;
            guard.errors.forEach((err: any) => {
              allWorkflowErrors.push({
                message: err,
                location: ErrorLocations.CONDITION_TRANSITION,
                payload: {
                  transition: element,
                  condition: guard,
                },
              });
            });
          }
        });
      }
    });

    return errorInGuardInTransition;
  };

  /**
   * 1. Get the choice nodes from all Nodes
   * 2. Loops through all the handles and filter IF handles.
   * 3. Loop through each handle and check if it has one action or one condition.
   * This means that length of "conditions" or "actions" array must be greater than one.
   *
   */

  private static atleastOneConditionInChoiceNodeSwitch = (
    workflowData: any,
    allWorkflowErrors: WorkflowErrorType[]
  ) => {
    let oneConditionError = false;
    workflowData?.canvas?.elements?.forEach((ele: any) => {
      if (ele.type === NodeTypes.DECISION_NODE) {
        ele.data.errors = [];
        ele.data.handleInfo.forEach((handle: any) => {

          if (handle.type === 'IF' && handle.data?.automations == undefined) {
            oneConditionError = true;

            const errorMessage = `If switch with name "${handle.data.name}" must have atleast Automation.`;

            ele.data.errors.push(errorMessage);

            allWorkflowErrors.push({
              message: errorMessage,
              location: ErrorLocations.CHOICE_NODE_HANDLE,
              payload:{
                ...handle,
                nodeId: ele.id,
                deletePossible:
                  ele?.data?.handleInfo?.slice(
                    0,
                    ele?.data?.handleInfo.length - 1
                  ).length > 1,
              }
            });
          }

          // Check Validation for Automation || index 0 is used, because decision node automation has only one automation
          if (handle.type === 'IF' && handle.data?.automations?.[0]?.guards?.length == 0) {
            oneConditionError = true;

            const errorMessage = `If switch with name "${handle.data.name}" must have atleast one Guard in Automation.`;

            ele.data.errors.push(errorMessage);

            allWorkflowErrors.push({
              message: errorMessage,
              location: ErrorLocations.CHOICE_NODE_HANDLE,
              payload: handle.data?.automations[0]
            });
          }
        });
      }


      if (ele.type === NodeTypes.DECISION_NODE) {
        ele.data.errors = [];
      }

    });



    return oneConditionError;
  };

  private static validConditionsAndActions = (
    workflowData: any,
    allWorkflowErrors: any
  ) => {
    let invalidConditionAndGuard = false;
    workflowData?.canvas?.elements?.forEach((ele: any) => {
      if (ele.type === NodeTypes.DECISION_NODE) {
        ele.data.handleInfo.forEach((handle: any, handleIdx: number) => {
          if (!ele.data.handleInfo[handleIdx].errors) {
            ele.data.handleInfo[handleIdx].errors = false;
          }

          // Conditions:
          let conditionErrorInHandle = false;
          handle.data?.conditions.forEach(
            (condition: any, conditionIdx: number) => {
              if (
                !ele.data.handleInfo[handleIdx].data.conditions[conditionIdx]
                  .errors
              ) {
                ele.data.handleInfo[handleIdx].data.conditions[
                  conditionIdx
                ].errors = [];
              }
              let conditionError = this.validateGuard(condition) || [];
              if (conditionError.length > 0) {
                conditionErrorInHandle = true;

                // pushing data into allWorkflowErrors
                conditionError.forEach((err) => {
                  allWorkflowErrors.push({
                    message: err,
                    location: ErrorLocations.CONDITION_CHOICE,
                    payload: {
                      condition,
                      decisionNode: {
                        ...handle,
                        nodeId: ele.id,
                        deletePossible:
                          ele?.data?.handleInfo?.slice(
                            0,
                            ele?.data?.handleInfo.length - 1
                          ).length > 1,
                      },
                    },
                  });
                });
              }
              ele.data.handleInfo[handleIdx].data.conditions[
                conditionIdx
              ].errors = [
                  ...(ele.data.handleInfo[handleIdx].data.conditions[
                    conditionIdx
                  ].errors = []),
                  ...conditionError,
                ];
            }
          );

          if (conditionErrorInHandle) {
            ele.data.handleInfo[handleIdx].errors = true;
            invalidConditionAndGuard = true;
          } else {
            ele.data.handleInfo[handleIdx].errors = false;
          }

          // Actions
          let actionErrorInHandle = false;
          handle.data?.actions.forEach((action: any, actionIdx: number) => {
            if (
              !ele.data.handleInfo[handleIdx].data.actions[actionIdx].errors
            ) {
              ele.data.handleInfo[handleIdx].data.actions[actionIdx].errors =
                [];
            }
            let actionError = this.validAction(action) || [];
            if (actionError.length > 0) {
              actionErrorInHandle = true;
              actionError.forEach((err) => {
                allWorkflowErrors.push({
                  message: err,
                  location: ErrorLocations.ACTION_CHOICE,
                  payload: {
                    action,
                    decisionNode: {
                      ...handle,
                      nodeId: ele.id,
                      deletePossible:
                        ele?.data?.handleInfo?.slice(
                          0,
                          ele?.data?.handleInfo.length - 1
                        ).length > 1,
                    },
                  },
                });
              });
            }
            ele.data.handleInfo[handleIdx].data.actions[actionIdx].errors = [
              ...(ele.data.handleInfo[handleIdx].data.actions[
                actionIdx
              ].errors = []),
              ...actionError,
            ];
          });

          if (actionErrorInHandle) {
            ele.data.handleInfo[handleIdx].errors = true;
            invalidConditionAndGuard = true;
          } else {
            ele.data.handleInfo[handleIdx].errors =
              ele.data.handleInfo[handleIdx].errors || false;
          }
        });
      }
    });
    return invalidConditionAndGuard;
  };

  /**
   * General function for validating any guard.
   * Location Guard:
   * guardName
   * validationType
   * latitude
   * longitude
   * customerLatitude
   * customerLongitude
   * expectedDistance
   *
   * Expression Guard:
   * guardName
   * errorMessage
   * expression
   */

  private static validateGuard = (guard: any) => {
    const invalidName = (str: string): boolean => {
      if (!new RegExp(/^[a-z0-9_\s${}.]+$/i).test(str)) {
        return true;
      }

      return false;
    };

    const invalidURL = (str: string): boolean => {
      if (
        !new RegExp(
          /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
        ).test(str)
      ) {
        return true;
      }
      return false;
    };
    let guardErrors: any[] = [];
    if (guard.guardType === guardTypes.LOCATION_CHECK) {
      if (!guard.guardName || guard.guardName === "") {
        guardErrors.push("Guards must have a name");
      }

      if (!guard.validationType || !guard.validationType) {
        guardErrors.push("Location must have a valid alogrithm");
      }

      if (
        !guard.latitude ||
        (guard.latitude.type === "literal" && !guard.latitude.value)
      ) {
        guardErrors.push("Location must have a valid latitude");
      }

      if (
        !guard.longitude ||
        (guard.longitude.type === "literal" && !guard.longitude.value)
      ) {
        guardErrors.push("Location must have a valid longitude");
      }

      if (
        !guard.customerLatitude ||
        (guard.customerLatitude.type === "literal" &&
          !guard.customerLatitude.value)
      ) {
        guardErrors.push("Location must have a valid customer latitude");
      }

      if (
        !guard.customerLongitude ||
        (guard.customerLongitude.value === "literal" &&
          !guard.customerLongitude.value)
      ) {
        guardErrors.push("Location must have a valid customer longitude");
      }

      if (
        !guard.expectedDistance ||
        (guard.expectedDistance.value === "literal" &&
          !guard.expectedDistance.value &&
          guard.expectedDistance.value < 0)
      ) {
        guardErrors.push("Expected Distance must be a valid number");
      }
    } else if (guard.guardType === guardTypes.EXPRESSION_GUARD) {
      if (!guard.guardName || guard.guardName === "") {
        guardErrors.push("Guards must have a name");
      }

      if (!guard.expression || !guard.expression) {
        guardErrors.push("Expression check must have an expression");
      }

    } else if (guard.guardType === guardTypes.API_GUARD) {
      if (!guard?.guardName || guard?.guardName === "") {
        guardErrors.push("API action must have a valid name!");
      }

      //Must have a valid name check
      if (invalidName(guard?.guardName)) {
        guardErrors.push("API action must have a valid name!");
      }


      // Must have a valid URL
      if (invalidURL(guard?.url?.value)) {
        guardErrors.push("Invalid URL Provided");
      }

      // valid header check
      if (guard?.headers !== "") {
        const keyValuePair = guard?.headers?.split("###");
        keyValuePair?.forEach((kvPair: string) => {
          //split based on ":"
          const singleKeyValue = kvPair?.split(":");
          if (singleKeyValue.length !== 2) {
            guardErrors.push("Invalid Headers providers");
          }

          singleKeyValue.forEach((skPair: any) => {
            if (skPair === "") {
              guardErrors.push("Each Header must have a key value pair");
            }
          });
        });
      }
    }
    return guardErrors;
  };

  /**
   * General function for validating actions.
   * Push Notification
   * name
   * title
   * message
   *
   */

  private static validAction = (action: any) => {
    let actionErrors = [];
    if (action.actionType === "PUSH") {
      if (!action?.actionName || action?.actionName === "") {
        action.errors.push("Push Notification must have a name");
      }

      if (
        !action?.message ||
        action?.message?.value === "" ||
        !action?.message?.value
      ) {
        actionErrors.push("Push Notification must have a message");
        // element.data.errors.push("Push Notification must have a message")
      }

      if (
        !action?.title ||
        action?.title?.value === "" ||
        !action?.title?.value
      ) {
        actionErrors.push("Push Notification must have a title");
        // element.data.errors.push("Push Notification must have a title")
      }
    } else if (action.actionType === actionType.API_ACTION) {
      // Must have a name check
      if (!action?.actionName || action?.actionName === "") {
        actionErrors.push("API action must have a valid name!");
      }

      // Must have a valid URL
      // if (invalidURL(item?.url)) {
      //   item.errors.push("Invalid URL Provided");
      // }

      // valid header check
      if (action?.headers !== "") {
        if (typeof action?.headers === "string") {
          const keyValuePair = action?.headers?.split("###");
          keyValuePair?.forEach((kvPair: string) => {
            //split based on ":"
            const singleKeyValue = kvPair?.split(":");
            if (singleKeyValue.length !== 2) {
              actionErrors.push("Invalid Headers providers");
            }

            singleKeyValue.forEach((skPair: any) => {
              if (skPair === "") {
                actionErrors.push("Each Header must have a key value pair");
              }
            });
          });
        } else {
          const allkeys = Object.keys(action?.headers || {});
          allkeys.forEach((key: string) => {
            if (key.trim().length === 0) {
              actionErrors.push("Each Header must have a key value pair");

            }

            if (action?.headers[key].length === 0) {
              actionErrors.push("Each Header must have a key value pair");

            }
          })
        }
      }

      // valid body(JSON) check
      // if(item?.body !== "") {
      //     if(!tryParseJSONObject(item?.body)){
      //         item.errors.push("Invalid Body provided. Body must be a valid json.")
      //     }
      // }
    }

    return actionErrors;
  };

  /**
   * Get all the edges info.
   * Gte the node id for the choice node.
   * Get the edges with source as nodeId.
   * As each handle can have only one edge (validated from UI.) The number of handle count for node should be equal to the number of edges having source as node id.
   * Count of handles can be derived from the length of handleInfo
   * @param workflowData
   */

  private static validateIfAllHandlesHaveEdgesInChoiceNode = (
    workflowData: any,
    allWorkflowErrors: WorkflowErrorType[]
  ) => {
    let isValidEdgeConnection = true;
    const validHandlesForEachNode: any = {}
    // Get nodeId of all the choice node.
    let allChoiceNodes: any[] = [];
    workflowData?.canvas?.elements?.forEach((element: any) => {
      if (element.type === "zorpDecisionNode") {
        allChoiceNodes.push(element.id);
        element.data.handleInfo.forEach((handle: any) => {
          if (!validHandlesForEachNode[element.id]) {
            validHandlesForEachNode[element.id] = []
          }
          validHandlesForEachNode[element.id].push(handle.id)

        })
      }
    });

    // Get the number of edges having source as a choice node.
    let countOfEdgesFromChoiceNode: any = {};
    allChoiceNodes.forEach((node) => {
      countOfEdgesFromChoiceNode[node] = 0;
    });

    workflowData?.canvas?.elements?.forEach((element: any) => {
      if (isEdge(element)) {
        if (allChoiceNodes.includes(element.source)) {
          if (validHandlesForEachNode[element.source].includes(element.sourceHandle)) {
            countOfEdgesFromChoiceNode[element.source] += 1;
          }
        }

      }
    });


    // Check if the number of handle === no od edges
    workflowData?.canvas?.elements?.forEach((element: any) => {
      if (element.type === "zorpDecisionNode") {
        if (!element.data?.errors) {
          element.data.errors = [];
        }
        if (
          element?.data?.handleInfo?.length !==
          countOfEdgesFromChoiceNode[element.id]
        ) {
          isValidEdgeConnection = false;
          element.data.errors.push(
            `Each handle must have an outgoing transition`
          );
          allWorkflowErrors.push({
            location: ErrorLocations.CHOICE_NODE,
            message: `Each handle must have an outgoing transition`,
            payload: {
              nodeId: element.id,
            },
          });
        }
      }
    });

    return !isValidEdgeConnection;
  };

  /**
   * 1. Get node id of all choice nodes.
   * 2. Get edges info of all edges.
   * 3. Create a hashmap which has keys as nodeID and value as number of incoming edges.
   * 4. Now for each node, check if its the value against its nodeId > 0
   * 5. Else mark error in the node
   *
   * @param workflow
   */

  private static validateIfChoiceNodeHasIncomingEdge = (
    workflowData: any,
    allWorkflowErrors: WorkflowErrorType[]
  ) => {
    let hasIncomingEdge = true;
    let allChoiceNodes: any[] = [];
    workflowData?.canvas?.elements?.forEach((element: any) => {
      if (element.type === "zorpDecisionNode") {
        allChoiceNodes.push(element.id);
      }
    });

    let countOfIncomingEdgesToChoiceNode: any = {};
    allChoiceNodes.forEach((node) => {
      countOfIncomingEdgesToChoiceNode[node] = 0;
    });

    workflowData?.canvas?.elements?.forEach((element: any) => {
      if (isEdge(element)) {
        if (allChoiceNodes.includes(element.target))
          countOfIncomingEdgesToChoiceNode[element.target] =
            countOfIncomingEdgesToChoiceNode[element.target] + 1;
      }
    });

    workflowData?.canvas?.elements?.forEach((element: any) => {
      if (element.type === "zorpDecisionNode") {
        if (!element.data?.errors) {
          element.data.errors = [];
        }
        if (countOfIncomingEdgesToChoiceNode[element.id] === 0) {
          hasIncomingEdge = false;
          element.data.errors.push(
            `Each Choice node must have an incoming transition`
          );
          allWorkflowErrors.push({
            location: ErrorLocations.CHOICE_NODE,
            message: `Each Choice node must have an incoming transition`,
            payload: {
              nodeId: element.id,
            },
          });
        }
      }
    });

    return !hasIncomingEdge;
  };

  private static validateSystemTransitionActions = (
    workflowData: any,
    allWorkflowErrors: WorkflowErrorType[]
  ) => {
    let allErrors: any[] = [];

    // update Slots
    workflowData?.updateSlot?.actions?.forEach((action: any) => {
      let actionError = this.validAction(action) || [];
      action.errors = actionError;
      if (actionError.length > 0) {
        allWorkflowErrors.push({
          message: `Action with name: ${action.actionName} in system transition Update Slot is not valid.`,
          location: ErrorLocations.SYSTEM_TRANSITIONS,
          payload: {
            data: action,
            systemTransitionInfo: {
              transitionType: "updateSlot",
              type: "actions",
            },
          },
        });
      }

      allErrors = [...allErrors, ...actionError];
    });


    workflowData?.updateSlot?.conditions?.forEach((action: any) => {
      let actionError = this.validateGuard(action) || [];
      action.errors = actionError;
      if (actionError.length > 0) {
        allWorkflowErrors.push({
          message: `Condition with name: ${action.guardName} in system transition Update Slot is not valid.`,
          location: ErrorLocations.SYSTEM_TRANSITIONS,
          payload: {
            data: action,
            systemTransitionInfo: {
              transitionType: "updateSlot",
              type: "conditions",
            },
          },
        });
      }

      allErrors = [...allErrors, ...actionError];
    });

    // update teams
    workflowData?.updateTeam.assignActions.forEach((action: any) => {
      let actionError = this.validAction(action) || [];
      action.errors = actionError;
      if (actionError.length > 0) {
        allWorkflowErrors.push({
          message: `Action with name: ${action.actionName} in system transition Update Team is not valid.`,
          location: ErrorLocations.SYSTEM_TRANSITIONS,
          payload: {
            data: action,
            systemTransitionInfo: {
              transitionType: "updateTeam",
              type: "assignActions",
            },
          },
        });
      }

      allErrors = [...allErrors, ...actionError];
    });

    workflowData?.updateTeam.assignConditions.forEach((condition: any) => {
      let conditionError = this.validateGuard(condition) || [];
      condition.errors = conditionError;
      if (conditionError.length > 0) {
        allWorkflowErrors.push({
          message: `Condition with name: ${condition.guardName} in system transition Update Team is not valid.`,
          location: ErrorLocations.SYSTEM_TRANSITIONS,
          payload: {
            data: condition,
            systemTransitionInfo: {
              transitionType: "updateTeam",
              type: "assignConditions",
            },
          },
        });
      }

      allErrors = [...allErrors, ...conditionError];
    });

    workflowData?.updateTeam.unassignActions.forEach((action: any) => {
      let actionError = this.validAction(action) || [];
      action.errors = actionError;
      if (actionError.length > 0) {
        allWorkflowErrors.push({
          message: `Action with name: ${action.actionName} in system transition Update Team is not valid.`,
          location: ErrorLocations.SYSTEM_TRANSITIONS,
          payload: {
            data: action,
            systemTransitionInfo: {
              transitionType: "updateTeam",
              type: "unassignActions",
            },
          },
        });
      }

      allErrors = [...allErrors, ...actionError];
    });

    workflowData?.updateTeam.unassignConditions.forEach((condition: any) => {
      let conditionError = this.validateGuard(condition) || [];
      condition.errors = conditionError;
      if (conditionError.length > 0) {
        allWorkflowErrors.push({
          message: `Condition with name: ${condition.guardName} in system transition Update Team is not valid.`,
          location: ErrorLocations.SYSTEM_TRANSITIONS,
          payload: {
            data: condition,
            systemTransitionInfo: {
              transitionType: "updateTeam",
              type: "unassignConditions",
            },
          },
        });
      }

      allErrors = [...allErrors, ...conditionError];
    });

    //Update Tasks
    workflowData?.updateTask.actions.forEach((action: any) => {
      let actionError = this.validAction(action) || [];
      action.errors = actionError;
      if (actionError.length > 0) {
        allWorkflowErrors.push({
          message: `Action with name: ${action.actionName} in system transition Update Task is not valid.`,
          location: ErrorLocations.SYSTEM_TRANSITIONS,
          payload: {
            data: action,
            systemTransitionInfo: {
              transitionType: "updateTask",
              type: "actions",
            },
          },
        });
      }

      allErrors = [...allErrors, ...actionError];
    });

    workflowData?.updateTask.conditions.forEach((condition: any) => {
      let conditionError = this.validateGuard(condition) || [];
      condition.errors = conditionError;
      if (conditionError.length > 0) {
        allWorkflowErrors.push({
          message: `Condition with name: ${condition.guardName} in system transition Update Task is not valid.`,
          location: ErrorLocations.SYSTEM_TRANSITIONS,
          payload: {
            data: condition,
            systemTransitionInfo: {
              transitionType: "updateTask",
              type: "conditions",
            },
          },
        });
      }

      allErrors = [...allErrors, ...conditionError];
    });

    // Create Task
    workflowData?.createTask.actions.forEach((action: any) => {
      let actionError = this.validAction(action) || [];
      action.errors = actionError;
      if (actionError.length > 0) {
        allWorkflowErrors.push({
          message: `Action with name: ${action.actionName} in system transition Create Task is not valid.`,
          location: ErrorLocations.SYSTEM_TRANSITIONS,
          payload: {
            data: action,
            systemTransitionInfo: {
              transitionType: "createTask",
              type: "actions",
            },
          },
        });
      }

      allErrors = [...allErrors, ...actionError];
    });

    // Delete Task
    workflowData?.deleteTask.actions.forEach((action: any) => {
      let actionError = this.validAction(action) || [];
      action.errors = actionError;
      if (actionError.length > 0) {
        allWorkflowErrors.push({
          message: `Action with name: ${action.actionName} in system transition Delete Task is not valid.`,
          location: ErrorLocations.SYSTEM_TRANSITIONS,
          payload: {
            data: action,
            systemTransitionInfo: {
              transitionType: "deleteTask",
              type: "actions",
            },
          },
        });
      }

      allErrors = [...allErrors, ...actionError];
    });

    workflowData?.deleteTask.conditions.forEach((condition: any) => {
      let conditionError = this.validateGuard(condition) || [];
      condition.errors = conditionError;
      if (conditionError.length > 0) {
        allWorkflowErrors.push({
          message: `Condition with name: ${condition.guardName} in system transition Delete Task is not valid.`,
          location: ErrorLocations.SYSTEM_TRANSITIONS,
          payload: {
            data: condition,
            systemTransitionInfo: {
              transitionType: "deleteTask",
              type: "conditions",
            },
          },
        });
      }

      allErrors = [...allErrors, ...conditionError];
    });

    return allErrors.length > 0 ? true : false;
  };

  /**
   * check if the multiple transition from the same node doesn't have the same name
   */
  private static checkMultipleTransitionName = (workflowData: any, allWorkflowErrors: WorkflowErrorType[]) => {

    // create a map of all the nodes with nodeId as key and name as value
    const nodeMap = new Map<string, string>();
    workflowData.canvas.elements.forEach((node: any) => {
      if (isNode(node)) {
        nodeMap.set(node.data.id, node.data.name)
      }
    })

    const states: any = {};
    let tranitionWithSameNamePresent = false
    workflowData.canvas.elements.forEach((transition: any) => {
      if (isEdge(transition)) {
        if (!transition.data.errors) {
          transition.data.errors = [];
        }
        if (states[transition.source + "-" + transition.sourceHandle + "-" + transition.data?.event] !== undefined) {
          allWorkflowErrors.push({
            message: `Multiple transitions from the node ${nodeMap.get(transition.source) || ""} with the same name ${transition.data?.event} is not allowed.`,
            location: ErrorLocations.TRANSITION,
            payload: {
              transition,
            },
          });
          transition.data.errors.push(`Multiple transitions from the node ${transition.source} with the same name ${transition.data?.event} is not allowed.`);
        }
        else
          states[transition.source + "-" + transition.sourceHandle + "-" + transition.data?.event] = 1;

      }

      if (transition.data?.errors?.length > 0) {
        tranitionWithSameNamePresent = true;
      }
    });

    return tranitionWithSameNamePresent;

  }

  /**
   * check if mutilple nodes in the workflow have the same name
   */

  private static checkMultipleNodesWithSameName = (workflowData: any, allWorkflowErrors: WorkflowErrorType[]) => {
    const states: any = {};
    let nodeWithSameNamePresent = false
    workflowData.canvas.elements.forEach((node: any) => {
      if (isNode(node)) {
        if (!node.data.errors) {
          node.data.errors = [];
        }
        if (states[node.data?.name] !== undefined) {
          allWorkflowErrors.push({
            message: `Multiple nodes with the same name ${node.data?.name} is not allowed.`,
            location: ErrorLocations.NODE,
            payload: {
              nodeData: node,
            },
          });
          node.data.errors.push(`Multiple nodes with the same name ${node.data?.name} is not allowed.`);
        }
        else
          states[node.data?.name] = 1;

      }

      if (node.data?.errors?.length > 0) {
        nodeWithSameNamePresent = true;
      }
    });

    return nodeWithSameNamePresent;

  }

  /**
   * check if any node in the node is not the source and target of any transition
   */
  private static checkNodeNotConnected = (workflowData: any, allWorkflowErrors: WorkflowErrorType[]) => {
    // create a empty set
    const states = new Set()

    // for all transitions, add the source and target to the set
    workflowData.canvas.elements.forEach((transition: any) => {
      if (isEdge(transition)) {
        states.add(transition.source)
        states.add(transition.target)
      }
    })

    // for all Nodes, check if the node is present in the set
    let nodeNotConnected = false
    workflowData.canvas.elements.forEach((node: any) => {
      if (isNode(node)) {
        if (!states.has(node.id)) {
          allWorkflowErrors.push({
            message: `Node with name ${node.data?.name} is not connected to any transition.`,
            location: ErrorLocations.GENERAL,
            payload: {
              message: `Node with name ${node.data?.name} is not connected to any transition.`,
            },
          });
          node.data.errors.push(`Node with name ${node.data?.name} is not connected to any transition.`);
        }
      }
      if (node.data?.errors?.length > 0) {
        nodeNotConnected = true;
      }
    })

    return nodeNotConnected;
  }

  /**
   * check if workflowData.appTaskCreateConfig has a valid displayName
   */
  private static checkAppTaskCreateConfig = (workflowData: any, allWorkflowErrors: WorkflowErrorType[]) => {
    let hasInvalidTaskCreateConfig = false
    if (!workflowData.appTaskCreateConfig?.isTaskCreateEnable) {
      return false
    }
    // if (!workflowData.appTaskCreateConfig?.displayName) {
    //   allWorkflowErrors.push({
    //     message: `App Task Create Config display name is not valid.`,
    //     location: ErrorLocations.WORKFLOW,
    //     payload: {
    //         message:`App Task Create Config display name is not valid.`,
    //     },
    //   });
    //   hasInvalidTaskCreateConfig = true
    // }

    if (!workflowData.appTaskCreateConfig?.visibleForRoles?.length) {
      allWorkflowErrors.push({
        message: `App Task Create Config must have atleast one role checked.`,
        location: ErrorLocations.WORKFLOW,
        payload: {
          message: `App Task Create Config must have atleast one role checked.`,
        },
      });
      hasInvalidTaskCreateConfig = true
    }

    return hasInvalidTaskCreateConfig

  }
}
