import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import goToAlertsImg from "assests/go_to_alerts.svg";
import closeMonitor from "assests/monitor_close.svg";
import enableBlueBee from "assests/enable_monitor_bluebee.svg";
import EnableAlertCheck from "assests/enable_alert_check.svg";
import { RudderStackAutomationBuilderEvents } from "constants/RudderStackConstants";
import notification from "notifications/notifications";
import { ZorpResponse } from "providers/data/models/ZorpResponse";
import { MonitorService } from "providers/data/services/MonitorService";
import { useEffect, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { getAccountId, recordRSEvent } from "utils/CommonUtils";
import { generateId } from "utils/Utils";
import { setSelectedMonitorId } from 'views/automationBuilder/reducer/automationReducer';
import { ZButton } from "views/commonComponents/commonComponents.styles";
import useEntity from "views/entities/hooks/useEntity";
import useAccount from "views/layouts/reducer/useAccount";
import { IQueryGeneratedType } from "views/monitor/MonitorInterface";
import CreateFromScratchBtn, { IMonitorCreateBtnContext } from "views/monitor/MonitorListComponents/CreateFromScratchBtn";
import {
  ICronValueType,
  IDatasourceCategory,
  IScreenContext,
  setConfirmMonitorModalData,
  setMonitorInterfaceContext, setOnEditClassicBuilderTemplateData,
  setSelectedMonitor,
  setShowInterfaceUI
} from "views/monitor/reducer/MonitorReducer";
import useMonitor from "views/monitor/reducer/useMonitor";
import { IShopifyTemplate } from "views/monitor/ShopifyMonitor";
import ShopifyMonitorCard from "views/monitor/ShopifyMonitorCard";
import TemplateEditContent from "views/monitor/TemplateEditContent";
import { BffService } from "providers/data/services/BffService";
import { LoadingButton } from "@mui/lab";

const MonitorInTableModalComponent = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: 560px;
  height: 95%;
  background: #ffffff;
  box-shadow: 0px 10px 14px -2px rgba(16, 24, 40, 0.01),
    0px 4px 4px -2px rgba(16, 24, 40, 0.01);
  position: absolute;
  top: 1%;
  right: 2%;
  border-radius: 8px;
  font-family: Inter;
`;

const ModalHeader = styled.div`
  width: 100%;
  padding: 16px 24px 0px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
`;

const ModalBody = styled.div`
  width: 100%;
  padding: 16px 24px;
  overflow: auto;
`;

const MonitorNotEnabledWrapper = styled.div`
width:100%;
display:flex;
flex-direction:column;
height:100%;
align-items:center;
justify-content:center;
`

const MonitorNotEnabledTitle = styled.p`
color: #101828;
text-align: center;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
margin-top:8px;
`

const MonitorNotEnabledBtnWrapper = styled.div`
  display:flex;
  gap:8px;
  width:wrap-content;
  margin-top:24px;
`

const MonitorNotEnabledLabel = styled.p`
color: #475467;
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
margin-top:16px;
font-weight: 400;
`



const HeaderTitle = styled.p`
  color: #101828;
  font-size: 16px;
  width: 30%;
  font-style: normal;
  font-weight: 600;
`;

interface IMonitorInTable {
  handleModalClose: () => void;
  isMonitorInTableModalOpen: boolean;
}

const MonitorInTableModal = ({
  handleModalClose,
  isMonitorInTableModalOpen,
}: IMonitorInTable) => {
  const dispatch = useDispatch();
  const accountId = getAccountId();
  const { account } = useAccount();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [isMonitorEnabled, setIsMonitorEnabled] = useState(account?.accountConfig?.ticketTableId);
  const { selectedEntity } = useEntity();
  const history = useHistory();
  const [templates, setTemplates] = useState<IShopifyTemplate[]>([]);
  const [isTemplateGenerated, setIsTemplateGenerated] = useState<boolean>();
  const { confirmMonitorModalData, datasourceListData } = useMonitor();
  const selectedTableType = 'records_' + selectedEntity?.tableType as string;
  const zorpDefaultTableSchemaName = 'zorp';

  const closeModal = () => {
    recordRSEvent(RudderStackAutomationBuilderEvents.tableMonitorModalClose, {
      context: RudderStackAutomationBuilderEvents.monitorContext
    });
    handleModalClose();
  }

  const initiateMonitor = async () => {
    setButtonLoading(true);
    const accountId = getAccountId();
    const initateMonitor = await BffService.initateMonitor(accountId);
    setButtonLoading(false);
    if (!initateMonitor) {
      notification(
        "error",
        "Issue In Initiating Monitor, Try Reloading or contact Zorp Team"
      );
    } else {
      setIsMonitorEnabled(true);
    }
  };

  const fetchMonitorTemplates = async (tableType: string) => {
    try {
      const datasourceId = datasourceListData.find((data) => data.datasourceCategory == IDatasourceCategory.ZORP_DEFAULT_CLICKHOUSE)?.datasourceId as string;
      const variables: { category: string; primaryTable?: string; datasourceId:string } = {
        category: "zorp",
        primaryTable: tableType,
        datasourceId: datasourceId
      }
      const templateData = await MonitorService.executeGQL({
        query: "query GetTemplates($category: String!, $datasourceId: String, $primaryTable: String) { getTemplates(category: $category, datasourceId: $datasourceId, primaryTable: $primaryTable) { isTemplateGenerated templates {title tags templateId description emailSubject emailBody sqlQuery queryDescription status} } }",
        variables
      });
      setTemplates(templateData?.data?.getTemplates?.templates || []);
      setIsTemplateGenerated(templateData?.data?.getTemplates?.isTemplateGenerated || false)
    } catch (err: any) {
      notification("error", err?.message || "Error in fetching templates");
    }
  };

  useEffect(() => {
    fetchMonitorTemplates(selectedTableType);
  }, []);

  const handleCreateMonitorFromTemplateClick = (template: IShopifyTemplate) => {
    const monitorPayload = {
      actionEmailId: template.accountEmail,
      payloadType: "PAYLOAD",
      templatePayload: template,
    };

    MonitorService.createMonitorsFromTemplateIds(monitorPayload)
      .then((response: ZorpResponse) => {
        if (response.code !== "200") {
          notification(
            "error",
            response?.message || "Error in creating monitors, pls try again"
          );
          return;
        }
        closeModal();
        notification("success", "Alert created successfully");
      })
      .catch((error: any) => {
        console.log("error: ", error);
        notification("error", "Error in creating monitors, pls try again");
      });
  }

  const designFromScratchBtn = async () => {
    recordRSEvent(RudderStackAutomationBuilderEvents.tableDesignFromScratch, {
      context: RudderStackAutomationBuilderEvents.monitorContext
    });
    const monitorCreateResponse = await MonitorService.createUnInitiatedMonitor();
    if (monitorCreateResponse?.code == '201') {
      const monitorId = monitorCreateResponse?.data?.monitorId;
      const datasourceId = datasourceListData.find((data) => data.datasourceCategory == IDatasourceCategory.ZORP_DEFAULT_CLICKHOUSE)?.datasourceId as string;
      // set your data
      const monitorData = {
        "monitorId": monitorId,
        "accountId": accountId,
        "isActive": true,
        "isDeleted": false,
        "avoidDuplicate": false,
        "autoResolve": false,
        "timesRan": 0,
        "createBulkAlert": false,
        "isInitiated": true,
        "actionData": [
          {
            "actionType": "EMAIL",
            "actionId": generateId(6),
            "label": "Email",
            "isActive": true,
            "payload": {
              "userEmail": account?.primaryContactEmail,
              "mailSubject": '',
              "mailBody": '',
              "attachCSV": true
            }
          }
        ],
        "autoResolveCount": 0,
        "cronData": {
          "cronType": ICronValueType.EXPRESSION,
          "timeZone": "Asia/Kolkata",
          "cronData": {
            "expression": '0 0 * * *' as string
          },
          "isActive": true
        },
        "timeZone": "Asia/Kolkata",
        "tableName": zorpDefaultTableSchemaName + '.' + selectedTableType,
        "datasourceId": datasourceId,
        "monitorDescription": '',
        "monitorName": '',
        "query": `select * from ${zorpDefaultTableSchemaName}.${selectedTableType} limit 10`,
        "query_generated_type": IQueryGeneratedType.SQL,
      }
      dispatch(setMonitorInterfaceContext({
        monitorScreenContext: IScreenContext.EDIT,
        monitorComponentsData: {
          isdDatasourceSectionHidden: true,
          isConditionSectionHidden: true,
          isChannelSectionHidden: true,
          isMonitorCustomizationSectionHidden: true,
          selectedActionDetails: undefined,
          tablesColumnsList: [],
          isDisableCreateBulkAlert: false,
        },
        monitorData: monitorData
      }));

      dispatch(setSelectedMonitor({ monitorId: monitorId, monitorAutomation: [] }))
      dispatch(setSelectedMonitorId(monitorId));
      dispatch(setShowInterfaceUI(true))
      recordRSEvent(RudderStackAutomationBuilderEvents.aiModalEditInClassicBuilder, {
        context: RudderStackAutomationBuilderEvents.monitorContext
      });
      history.push("/monitor");
      closeModal();
    } else {
      notification('error', 'Error creating monitor')
    }
  }

  return (
    <MonitorInTableModalComponent
      open={isMonitorInTableModalOpen}
      onClose={closeModal}>
      <>
        <ModalContainer>
          <>

            {isMonitorEnabled ? (
              <>
                {!confirmMonitorModalData ? (
                  <>
                    {templates?.length > 0 ?
                      <>
                        <ModalHeader>
                          <HeaderTitle>Zorp Alerts</HeaderTitle>

                          <ZButton
                            secondary
                            style={{ padding: "5px 12px", width: "30%", height: "32px", color: '#344054', fontSize: '12px', fontWeight: 600 }}
                            variant="contained"
                            onClick={() => {
                              recordRSEvent(RudderStackAutomationBuilderEvents.tableTemplategoToAlerts, {
                                context: RudderStackAutomationBuilderEvents.monitorContext
                              });
                              history.push("/monitor");
                            }}
                            fontSize={14}
                            endIcon={<img src={goToAlertsImg} />}
                          >
                            Go To Alerts
                          </ZButton>

                          <CreateFromScratchBtn
                            onCreateCallBack={async () => {
                              
                              await designFromScratchBtn();
                            }}
                            context={IMonitorCreateBtnContext.TABLE}
                          />
                        </ModalHeader>

                        <ModalBody>
                          {templates.map((templateData: IShopifyTemplate) => {
                            return (
                              <Grid
                                item
                                style={{
                                  width: "100%"
                                }}>
                                <ShopifyMonitorCard
                                  onAlertMeClick={() => {
                                    recordRSEvent(RudderStackAutomationBuilderEvents.tableAlertMeBtn, {
                                      context: RudderStackAutomationBuilderEvents.monitorContext
                                    });
                                    const selectedDataSource = datasourceListData.find((data) => data.datasourceCategory == IDatasourceCategory.ZORP_DEFAULT_CLICKHOUSE);
                                    const selectedTemplate = { ...templateData, accountEmail: account?.primaryContactEmail, selectedDataSource };

                                    dispatch(setConfirmMonitorModalData(selectedTemplate));
                                  }}
                                  onOpenMonitorPage={() => { }}
                                  onFineTuneTemplate={() => { }}
                                  monitorTestingLoading={false}
                                  createMonitorLoading={false}
                                  template={templateData}
                                  onExecuteTemplate={async () => {
                                    // await executeTemplateMonitor(templateData);
                                  }}
                                />
                              </Grid>
                            );
                          })}
                        </ModalBody>
                      </> : <>
                        <MonitorNotEnabledWrapper>
                          <img src={enableBlueBee} />

                          {isTemplateGenerated ?
                            <MonitorNotEnabledTitle>
                              No relevant templates found for this table, <br /> you can setup an alert manually
                            </MonitorNotEnabledTitle>
                            :
                            <MonitorNotEnabledTitle>
                              Bluebee is analyzing your data to identify <br /> relevant alert templates. In the meantime, <br /> you can setup an alert manually
                            </MonitorNotEnabledTitle>
                          }

                          <MonitorNotEnabledBtnWrapper>
                            <CreateFromScratchBtn
                              onCreateCallBack={async () => {
                                await designFromScratchBtn();
                              }}
                              context={IMonitorCreateBtnContext.TABLE}
                            />
                            <ZButton
                              secondary
                              style={{ padding: "5px 12px", height: "32px", color: '#344054', fontSize: '12px', fontWeight: 600 }}
                              variant="contained"
                              onClick={() => {
                                closeModal();
                              }}
                              fontSize={14}
                              startIcon={<img src={closeMonitor} />}
                            >
                              Close
                            </ZButton>
                          </MonitorNotEnabledBtnWrapper>

                        </MonitorNotEnabledWrapper>
                      </>
                    }
                  </>
                ) : (

                  <>
                    <ModalHeader>

                      <ZButton
                        secondary
                        style={{ height: "32px", color: '#344054', fontSize: '12px', fontWeight: 600, padding: '8px 14px' }}
                        variant="contained"
                        onClick={() => {
                          dispatch(setConfirmMonitorModalData(null))
                        }}
                        fontSize={14}
                        startIcon={<AiOutlineArrowLeft />}>
                        Go Back
                      </ZButton>


                    </ModalHeader>
                    <TemplateEditContent
                      onTemplateEdit={async (templatePayload: IShopifyTemplate) => {
                        recordRSEvent(RudderStackAutomationBuilderEvents.tableTemplateCustomizeFurther, {
                          context: RudderStackAutomationBuilderEvents.monitorContext
                        });
                        dispatch(setOnEditClassicBuilderTemplateData(templatePayload));

                        const monitorCreateResponse = await MonitorService.createUnInitiatedMonitor();
                        if (monitorCreateResponse?.code == '201') {
                          const monitorId = monitorCreateResponse?.data?.monitorId;
                          const datasourceId = datasourceListData.find((data) => data.datasourceCategory == IDatasourceCategory.ZORP_DEFAULT_CLICKHOUSE)?.datasourceId as string;
                          // set your data
                          const monitorData = {
                            "monitorId": monitorId,
                            "accountId": accountId,
                            "isActive": true,
                            "isDeleted": false,
                            "avoidDuplicate": false,
                            "autoResolve": false,
                            "timesRan": 0,
                            "createBulkAlert": false,
                            "isInitiated": true,
                            "actionData": [
                              {
                                "actionType": "EMAIL",
                                "actionId": generateId(6),
                                "label": "Email",
                                "isActive": true,
                                "payload": {
                                  "userEmail": account?.primaryContactEmail,
                                  "mailSubject": templatePayload.emailSubject,
                                  "mailBody": templatePayload.emailBody,
                                  "attachCSV": true
                                }
                              }
                            ],
                            "autoResolveCount": 0,
                            "cronData": {
                              "cronType": ICronValueType.EXPRESSION,
                              "timeZone": "Asia/Kolkata",
                              "cronData": {
                                "expression": templatePayload.cronExpression as string
                              },
                              "isActive": true
                            },
                            "timeZone": "Asia/Kolkata",
                            "tableName": zorpDefaultTableSchemaName + '.' + selectedTableType,
                            "datasourceId": datasourceId,
                            "monitorDescription": templatePayload.description,
                            "monitorName": templatePayload.title,
                            "query": templatePayload.sqlQuery,
                            "query_generated_type": IQueryGeneratedType.SQL,
                          }
                          dispatch(setMonitorInterfaceContext({
                            monitorScreenContext: IScreenContext.EDIT,
                            monitorComponentsData: {
                              isdDatasourceSectionHidden: true,
                              isConditionSectionHidden: true,
                              isChannelSectionHidden: true,
                              isMonitorCustomizationSectionHidden: true,
                              selectedActionDetails: undefined,
                              tablesColumnsList: [],
                              isDisableCreateBulkAlert: false,
                            },
                            monitorData: monitorData
                          }));

                          dispatch(setSelectedMonitor({ monitorId: monitorId, monitorAutomation: [] }))
                          dispatch(setSelectedMonitorId(monitorId));
                          dispatch(setShowInterfaceUI(true))
                          recordRSEvent(RudderStackAutomationBuilderEvents.aiModalEditInClassicBuilder, {
                            context: RudderStackAutomationBuilderEvents.monitorContext
                          });
                          history.push("/monitor");
                          closeModal();

                        } else {
                          notification('error', 'Error creating monitor')
                        }
                      }}
                      onAcivateAlerts={(editedTemplateData: IShopifyTemplate) => {
                        recordRSEvent(RudderStackAutomationBuilderEvents.tableTemplateActivateAlerts, {
                          context: RudderStackAutomationBuilderEvents.monitorContext
                        });
                        handleCreateMonitorFromTemplateClick(editedTemplateData);
                      }}
                      handleModalClose={closeModal}
                    />
                  </>
                )}

              </>) : (
              <>
                <MonitorNotEnabledWrapper>
                  <img src={enableBlueBee} />
                  <MonitorNotEnabledTitle>
                    Enable Zorp Alerts to start <br /> monitoring data
                  </MonitorNotEnabledTitle>

                  <MonitorNotEnabledLabel>
                    For this feature to work, you need <br /> to enable Zorp alerts.
                  </MonitorNotEnabledLabel>

                  <MonitorNotEnabledBtnWrapper>
                    <LoadingButton
                      primary
                      loadingPosition="start"
                      variant="contained"
                      loading={buttonLoading}
                      startIcon={<img src={EnableAlertCheck} />}
                      style={{
                        backgroundColor: "#3C69E7",
                        padding: "5px 12px", height: "32px", color: '#fff', fontSize: '12px', fontWeight: 600,
                        boxShadow: 'none'
                      }}
                      variant="contained"
                      onClick={() => {
                        initiateMonitor();
                      }}
                      fontSize={14}
                    >
                      Enable Alerts
                    </LoadingButton>
                    <ZButton
                      secondary
                      style={{ padding: "5px 12px", height: "32px", color: '#344054', fontSize: '12px', fontWeight: 600 }}
                      variant="contained"
                      onClick={() => {
                        handleModalClose();
                      }}
                      fontSize={14}
                      startIcon={<img src={closeMonitor} />}
                    >
                      Close
                    </ZButton>
                  </MonitorNotEnabledBtnWrapper>

                </MonitorNotEnabledWrapper>
              </>
            )
            }
          </>

        </ModalContainer>
      </>
    </MonitorInTableModalComponent>
  );
};

export default MonitorInTableModal;
