import React from 'react';
// @ts-ignore
import fnIcon from 'assests/expr.svg';
import { IScriptButton } from './IScriptButton';

const ScriptButton = (props: IScriptButton) => {
  const { handleClick, isVisible, isInline, isDisabled } = props;
  const style = isInline
    ? {
        marginLeft: '10px',
        maxHeight: '30px',
        display: 'inline',
        marginTop: '5px',
      }
    : {
        marginLeft: '10px',
        maxHeight: '30px',
        marginRight: '10px',
        // marginTop: '5px',
      };
  return (
    <>
     
      {isVisible && !isDisabled ? (
        <img style={style} src={fnIcon} alt='fnIcon' onClick={handleClick} className={'pointer'} />
      ) : (
        <img style={style} src={fnIcon} alt='fnIcon' className={'disabledImg'} title='No Data Selected' />
      )}
      
    </>
  );
};

export default ScriptButton;

ScriptButton.defaultProps = {
  handleClick: () => {},
  isVisible: true,
  isDisabled: false,
};
