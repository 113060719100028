import { toast } from 'react-toastify';
import NotificationContent from './NotificationContent';


const notification = (type, message, heading="", func) => {
  if(!message) return;
  switch (type) {
    case 'info':
      toast.info(<NotificationContent heading={ heading || "Notification" } message={message} />);
      break;
    case 'success':
      toast.success(<NotificationContent heading={heading || "Success"} message={message} />);
      break;
    case 'warning':
      toast.warn(<NotificationContent heading={heading || "Warning"} message={message} />);
      break;
    case 'error':
      toast.error(<NotificationContent heading={heading || "Error"} message={message} />);
      break;
    case 'promise':
      toast.promise(func, {
        pending: <NotificationContent heading={heading || "Error"} message={"Loading"} />,
        success: <NotificationContent heading={heading || "Error"} message={"Success"} />,
        error: <NotificationContent heading={heading || "Error"} message={"Error"} />
      })
      break;
    default:
      break;
  }
};

export default notification;
