import DatasourceCard from "./DatasourceCard";
import AddNewDataSourceIcon from "assests/monitor_add_new_datasource.svg";
import AddNewDataSourceIconBlack from "assests/monitor_add_new_datasource_black.svg";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import React, { useState } from 'react';
import { IDatasourceListInterface } from "../ShopifyMonitor";
import { IDatasourceCategory, IScreenContext, setDatasourceModalContext } from "../reducer/MonitorReducer";
import { defaultDatasourceEdit } from "constants/MonitorConstant";

const AddNewDataSourceWrapper = styled.p`
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 211px;
  height: 80px;
  align-self: stretch;
  border-radius: 4px;
  background: #f5f8ff;
  // border: 1px solid #fff;
  border: 1px solid #3c69e7;
  font-size:12px;
  &:hover {
    cursor: pointer;
  }
`;

const DatasourceListComponentWrapper = styled.div`
  display: flex;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  &::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }
  overflow: auto;
  gap: 16px;
  margin-right: 10px;
`;

const DatasourceWrapper = styled.div`
  display: flex;
  gap: 16px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  &::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }
`;

interface IDatasourceListComponent {
  datasource: IDatasourceListInterface[];
  retoolCreatedDataSourceId?: IDatasourceListInterface;
  gotoDataSourceAdd: () => void;
  onDatasourceSelect: (datasourceValue: IDatasourceListInterface) => void;
  onEditDatasource: (datasourceValue: any) => void;
}

const DatasourceListComponent = ({
  datasource,
  retoolCreatedDataSourceId,
  gotoDataSourceAdd,
  onDatasourceSelect,
  onEditDatasource
}: IDatasourceListComponent) => {
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(true);

  return (
    <DatasourceListComponentWrapper>
      <DatasourceWrapper>
          <AddNewDataSourceWrapper
           onClick={gotoDataSourceAdd}>
            <img src={isHovered ?  AddNewDataSourceIcon : AddNewDataSourceIconBlack} alt="fnIcon" />
            <p style={{
              color: isHovered ? "#3c69e7" : "",
            }}>Add New Datasource</p>
          </AddNewDataSourceWrapper>
        {datasource?.map(
          (datasourceData: IDatasourceListInterface, index: number) => {
            return (
              <>
                <DatasourceCard
                key={index}
                onEditDatasource={() => {
                  onEditDatasource(datasourceData);
                  dispatch(setDatasourceModalContext({datasourceContext:IScreenContext.EDIT, contextData: {datasourceType: datasourceData.datasourceType, displayName: datasourceData.displayName, name: datasourceData.name, host: datasourceData.host, port: datasourceData.port, username: datasourceData.username, password: defaultDatasourceEdit, ssl: datasourceData.ssl, datasourceId: datasourceData.datasourceId, datasourceCategory:datasourceData.datasourceCategory as IDatasourceCategory}}));
                }}
                  onSelectDatasource={() => {
                    onDatasourceSelect(datasourceData);
                  }}
                  datasourceData={datasourceData}
                  selectedDataSourceId={retoolCreatedDataSourceId?.datasourceId}
                />
              </>
            );
          }
        )}
      </DatasourceWrapper>
    </DatasourceListComponentWrapper>
  );
};

// export default DatasourceListComponent;
export  default React.memo(DatasourceListComponent);