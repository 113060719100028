import { Backdrop, Tooltip } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import InputAdornment from "@mui/material/InputAdornment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Save, SignatureRIcon } from "assests";
import ImageIco from "assests/imageIcoBlue.svg";
import { ImageModal } from "components/ZImageViewer/ZShowImage";
import ImageSlider from "components/imageviewer/ImageSlider";
import usePorterPermissions from "hooks/usePorterPermissions";
import { BiShieldQuarter as PermissionsIcon } from "react-icons/bi";
import { isArray, isEqual, isFunction, isObject, union } from "lodash";
import {
  MRT_ColumnFiltersState,
  MRT_DensityState,
  MRT_PaginationState,
  MRT_SortingState,
  MRT_VisibilityState,
  MaterialReactTable,
} from "material-react-table";
import moment from "moment";
import notification from "notifications/notifications";
import { EntityService } from "providers/data/services/EntityService";
import { useEffect, useRef, useState } from "react";
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  OnboardingIdsForComponent,
  complimentColor,
  getTablePropsLSKey,
  recordRSEvent,
  stringAvatar,
} from "utils/CommonUtils";
import {
  ActionTypeEntity,
  DataFieldType,
  EntityTypeField,
} from "views/DataField/DataField.types";
import LoadingScreen from "views/LoadingScreen/LoadingScreen";
import {
  InfoPill,
  ZButton,
} from "views/commonComponents/commonComponents.styles";
import useAccount from "views/layouts/reducer/useAccount";
import { InfoTooltip } from "views/workflows/Tootltips/InfoTooltip";
import useEntity from "../hooks/useEntity";
import { setShowCreationModal } from "../reducers/entityReducers";
import {
  IButtonActionType,
  IColDefs,
  IRelationReferringEntityType,
  IRowDefs,
  TableRSEvents,
} from "../types";
import ButtonAutomationHeader from "./ButtonAutomationHeader";
import CustomIcons from "./CustomIcons";
import CustomToolbar from "./CustomToolbar";
import ListOfValueBadges from "./ListOfValueBadges";
import RelationCellBadge from "./RelationCellBadge";
import SystemFieldHeader from "./SystemFieldHeader";
import { setRowsToDelete } from "render-engine/reducers/show/TaskShowReducer";
import { useParams } from "react-router-dom";
import "../../../styles/zorpTable.css";
import CustomToolbarForAddRecordToRelationModal from "./CustomToolbarForAddRecordToRelationTable";
import { FaLessThanEqual } from "react-icons/fa";
import useTaskShow from "views/workflows/hooks/useTaskShow";
import styled from "styled-components";

const StyledSpan = styled.span`
  padding: 4px 12px;
  margin-top: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #344054;
  font-weight: 500;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

type Props = {
  tableType: string;
  initTableConfig?: any;
  viewId?: string;
  view?: any;
  fromShowPage?: boolean;
  nameOfTheTableInRelatedTable?: string;
  setIsEntityTableEmpty?: React.Dispatch<React.SetStateAction<boolean>>;
  addedRecords?: any[];
  rowsToDelete?: any[];
  onNewRecordRecordClick: () => void;
  recordTypeLabel?: string;
  onRowSelectionClick: (selectedRows: any) => void;
  isSingleSelect: boolean;
  formKey: string;
  onSave: () => void;
  tempAddedRecords: string[];
  columnId: string;
};

const AddRecordToRelationTable = ({
  tableType,
  initTableConfig,
  viewId,
  view,
  fromShowPage = false,
  nameOfTheTableInRelatedTable = undefined,
  addedRecords = [],
  setIsEntityTableEmpty,
  rowsToDelete = [],
  onNewRecordRecordClick,
  onRowSelectionClick,
  recordTypeLabel,
  isSingleSelect,
  onSave,
  formKey,
  tempAddedRecords = [],
  columnId,
}: Props) => {
  const [rowData, setRowData] = useState<any>([]); // Set rowData to Array of Objects, one Object per Row
  const [showImage, setShowImage] = useState<boolean>(false);
  const [linksArray, setLinksArray] = useState<any[]>([]); // Set rowData to Array of Objects, one Object per Row
  const dispatch = useDispatch();
  const { allEntities, selectedEntityMetadata } = useEntity();
  const [gridApi, setGridApi] = useState<any>(null);
  const [openAB, setOpenAB] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [columnDefs, setColumnDefs] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [columnFilterFns, setColumnFilterFns] = useState<any>({});
  const { account } = useAccount();
  const { convertDataTypeToString, taskDataUpdated, subRecordCreateMode } =
    useTaskShow();
  const value = convertDataTypeToString(formKey, false, []);

  const [columnPinning, setColumnPinning] = useState<any>({
    left: [],
    right: ["showRecord", "addRow"],
  });
  const { tableTypeFromParam } = useParams();
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageSize: 10,
    pageIndex: 0,
  });
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [refresh, setRefresh] = useState(false);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [selectedRows, setSelectedRows] = useState(() => {
    let selectedRowInitialState = {};
    initTableConfig?.rowDef?.forEach((row: any) => {
      if (rowsToDelete?.includes(row?.recordId)) {
        selectedRowInitialState[row?.recordId] = true;
      }
      if (value?.includes(row?.recordId)) {
        selectedRowInitialState[row?.recordId] = true;
      }
    });
    return selectedRowInitialState;
  });

  const isFirstRender = useRef(true);
  const porterPermissions = usePorterPermissions();

  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(
    {}
  );
  const [density, setDensity] = useState<MRT_DensityState>("comfortable");
  const [globalFilter, setGlobalFilter] = useState<string | undefined>("");
  const [showGlobalFilter, setShowGlobalFilter] = useState(true);
  const [showColumnFilters, setShowColumnFilters] = useState(false);
  const [columnOrdering, setColumnOrdering] = useState<any[]>([]);

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const executeButtonClick = async (data: any) => {
    notification("info", "Cannot execute button click. Go to the table page");
    return;
  };

  const convertToTitleCase = (str: string) => {
    return str
        .replace(/([A-Z])/g, ' $1')
        .trim()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
}

  const showRecordClick = (recordId: string) => {
    // const selectedTableType = initTableConfig?.tableType || tableType;
    // // open `/table/${tableType}/show/${recordId}` in new tab
    // // check if the url has 'show"
    // /**
    //  * this is to open the details page in the new tab if the record details
    //  * is invoked from the show page itself
    //  * this happens when a record has a relation and we double click on the
    //  * row to open the details page
    //  * For the main table, it will open on the same tab, but for the table
    //  * in the relation, it will open in the new tab.
    //  */

    // const url = window.location.href;
    // if (url.includes("show")) {
    //   window.open(`/#/table/${selectedTableType}/show/${recordId}`, "_blank");
    // } else {
    //   history.push(`/table/${selectedTableType}/show/${recordId}`);
    // }
    // // window.open(`/#/table/${tableType}/show/${recordId}`, "_blank")
    // // history.push(`/table/${tableType}/show/${recordId}`)
    // recordRSEvent(TableRSEvents.TABLE_ROW_CLICK, {
    //   recordId,
    // });
    return;
  };

  const formatListingConfig = (data: any) => {
    // get the timestamp when the function is called
    const startTime = moment(new Date());
    const colDefs: any = data?.colDef?.headers
      ?.filter(
        (col: IColDefs) => viewId === undefined || col.isVisible === true
      )
      .map((col: IColDefs) => {
        const extraProps: any = {};
        if (col.colDef.dataType === DataFieldType.TEXT) {
          extraProps.columnFilterModeOptions = [
            "contains",
            "empty",
            "endsWith",
            "equals",
            "startsWith",
            "empty",
            "notEmpty",
            "notEquals",
          ];
          /**
           * Cell should only show first 40 characters and then show ... if more
           *
           */
          extraProps.Cell = (params: any) => {
            // return <p>ascasc</p>
            if (
              isArray(params?.cell?.renderValue?.()) ||
              isObject(params?.cell?.renderValue?.())
            ) {
              return <></>;
            }
            return params?.cell?.renderValue?.()?.length > 25 ? (
              <InfoTooltip
                placement="top"
                title={params?.cell?.renderValue?.()}
              >
                <div>
                  <p
                    style={{
                      padding: "4px 12px",
                    }}
                  >
                    {params?.cell?.renderValue?.()?.length > 25
                      ? `${params?.cell
                          ?.renderValue?.()
                          ?.substring?.(0, 25)} ...`
                      : params?.cell?.renderValue?.()}
                  </p>
                </div>
              </InfoTooltip>
            ) : (
              <div>
                <p
                  style={{
                    padding: "4px 12px",
                  }}
                >
                  {params?.cell?.renderValue()}
                </p>
              </div>
            );
          };
          extraProps.size = 250;
        }
        if (col.colDef.dataType === DataFieldType.NUMBER) {
          extraProps.columnFilterModeOptions = [
            "equals",
            "greaterThan",
            "greaterThanOrEqualTo",
            "lessThan",
            "lessThanOrEqualTo",
            "between",
            "notEquals",
            "empty",
            "notEmpty",
          ];
          // extraProps.Filter = ({ column }) => {
          //   console.log(">>>> column", column.columnDef._filterFn);
          //   return (
          //     <>
          //       <TextField
          //         type="number"
          //         placeholder={`Filter by ${col.colDef.displayName}`}
          //         fullWidth
          //         variant="standard"
          //         value={parseInt(column.getFilterValue()) || null}
          //         onChange={(e) => {
          //           column.setFilterValue(e.target.value);
          //         }}
          //       />
          //       <p
          //         style={{
          //           fontSize: "9px",
          //           fontWeight: 300,
          //         }}
          //       >
          //         {" "}
          //         Filter Mode : {columnFilterFns[column.id] || ""}{" "}
          //       </p>
          //     </>
          //   );
          // };
        }
        if (col.colDef.dataType === DataFieldType.BOOLEAN) {
          extraProps.enableColumnFilter = true;
          extraProps.enableSorting = false;
          extraProps.columnFilterModeOptions = ["equals"]; //only allow these filter modes

          extraProps.Cell = (params: any) => {
            // put the value in bold
            return (
              <Tooltip
                title={
                  params.column.getIsFiltered()
                    ? "Filter Applied"
                    : params.column.getIsSorted()
                    ? "Column is Sorted"
                    : ""
                }
              >
                <div>
                  <p
                    style={{
                      padding: "4px 12px",
                      background: params.column.getIsFiltered()
                        ? "#fef3ee"
                        : params.column.getIsSorted()
                        ? "#ecfbe7"
                        : "",
                    }}
                  >
                    {params?.cell?.renderValue() === undefined
                      ? ""
                      : params?.cell?.renderValue()
                      ? "True"
                      : "False"}{" "}
                  </p>
                </div>
              </Tooltip>
            );
          };
        }
        if (col.colDef.dataType === DataFieldType.ONLY_DATE) {
          extraProps.enableColumnFilter = true;
          extraProps.enableSorting = true;
          extraProps.sortingFn = "datetime";
          extraProps.size = 210;
          extraProps.columnFilterModeOptions = [
            "greaterThanOrEqualTo",
            "lessThanOrEqualTo",
            "equals",
            "empty",
            "notEmpty",
            "lessThan",
            "greaterThan",
          ]; //only allow these filter modes
          
          extraProps.filterFn = "equals";
          // convert to MMM dd yyyy, hh:mm a from ISO
          extraProps.Cell = (params: any) => {
            // put the value in bold
            return (
              <Tooltip
                title={
                  params.column.getIsFiltered()
                    ? "Filter Applied"
                    : params.column.getIsSorted()
                    ? "Column is Sorted"
                    : ""
                }
              >
                <div>
                  <p
                    style={{
                      padding: "4px 12px",
                      background: params.column.getIsFiltered()
                        ? "#fef3ee"
                        : params.column.getIsSorted()
                        ? "#ecfbe7"
                        : "",
                    }}
                  >
                    {" "}
                    {params?.cell?.renderValue()
                      ? moment(params?.cell?.renderValue()).format(
                          "Do MMM YYYY"
                        )
                      : ""}
                  </p>
                </div>
              </Tooltip>
            );
          };
          extraProps.Filter = ({ column }) => (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                onChange={(newValue) => {
                  column.setFilterValue(newValue);
                }}
                slotProps={{
                  textField: {
                    helperText: `Filter Mode: ${
                      columnFilterFns[column.id]
                        ? columnFilterFns[column.id] === "contains"
                          ? "Less Than Or Equal To"
                          : convertToTitleCase(columnFilterFns[column.id])
                        : "Less Than Or Equal To"
                    }`,
                    sx: { minWidth: "120px" },
                    variant: "standard",
                  },
                  field: {
                    clearable: true,
                    onClear: () => column.setFilterValue(null),
                  },
                }}
                value={
                  column.getFilterValue()
                    ? new Date(column.getFilterValue())
                    : null
                }
              />
            </LocalizationProvider>
          );
        }
        if (
          col.colDef.dataType === DataFieldType.DATE ||
          col.colDef.dataType === DataFieldType.DATE_TIME
        ) {
          extraProps.enableColumnFilter = true;
          extraProps.enableSorting = true;
          extraProps.sortingFn = "datetime";
          extraProps.size = 210;
          extraProps.columnFilterModeOptions = [
            "greaterThan",
            "lessThan",
            "equals",
            "empty",
            "notEmpty",
          ]; //only allow these filter modes
          extraProps.filterFn = "equals";
          // convert to MMM dd yyyy, hh:mm a from ISO
          extraProps.Cell = (params: any) => {
            // put the value in bold
            return (
              <Tooltip
                title={
                  params.column.getIsFiltered()
                    ? "Filter Applied"
                    : params.column.getIsSorted()
                    ? "Column is Sorted"
                    : ""
                }
              >
                <div>
                  <p
                    style={{
                      padding: "4px 12px",
                      background: params.column.getIsFiltered()
                        ? "#fef3ee"
                        : params.column.getIsSorted()
                        ? "#ecfbe7"
                        : "",
                    }}
                  >
                    {" "}
                    {params?.cell?.renderValue()
                      ? moment(params?.cell?.renderValue()).format(
                          "Do MMM YYYY , h:mm A"
                        )
                      : ""}
                  </p>
                </div>
              </Tooltip>
            );
          };
          extraProps.Filter = ({ column }) => (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                onChange={(newValue) => {
                  column.setFilterValue(newValue);
                }}
                slotProps={{
                  textField: {
                    helperText: "Filter Mode: Less Than",
                    sx: { minWidth: "120px" },
                    variant: "standard",
                  },
                  field: {
                    clearable: true,
                    onClear: () => column.setFilterValue(null),
                  },
                }}
                value={
                  column.getFilterValue()
                    ? new Date(column.getFilterValue())
                    : null
                }
              />
            </LocalizationProvider>
          );
        }
        if (
          col.colDef.dataType === DataFieldType.SIGNATURE ||
          col.colDef.dataType === DataFieldType.IMAGE ||
          col.colDef.dataType === DataFieldType.FILE
        ) {
          // disable filtering and sorting
          extraProps.enableColumnFilter = false;
          extraProps.enableSorting = false;
          console.log(">>", col);
          extraProps.size = 300;

          extraProps.Cell = (params: any) => {
            // put the value in bold
            return (
              <>
                {" "}
                {params?.cell?.renderValue()?.length > 0 ? (
                  <p style={{ color: "#3067c4", padding: "4px 12px" }}>
                    {" "}
                    {col.colDef.dataType === DataFieldType.SIGNATURE ? (
                      <SignatureRIcon
                        style={{ display: "inline" }}
                        color="#667085"
                        size="1.2rem"
                      />
                    ) : (
                      <img
                        height={18}
                        width={18}
                        src={ImageIco}
                        style={{
                          display: "inline",
                          marginRight: "4px",
                          color: "#3067c4",
                        }}
                      />
                    )}
                    View Uploaded Images/Files{" "}
                  </p>
                ) : (
                  <p style={{ color: "#3067c4" }}> </p>
                )}{" "}
              </>
            );
          };
          extraProps.muiTableBodyCellProps = (params: any) => ({
            onClick: () => {
              const uploadedFiles = params?.cell?.renderValue();
              if (!uploadedFiles) return;
              if (uploadedFiles?.length === 0) return;

              if (typeof uploadedFiles === "string") {
                setLinksArray([{ url: uploadedFiles, title: "Image" }]);
              } else {
                const links = uploadedFiles.map((link: any, idx: any) => {
                  return { url: link, title: `Image ${idx}` };
                });
                if (!links) {
                  setLinksArray(undefined);
                }
                setLinksArray(links);
              }
              setShowImage(true);
            },
          });

          extraProps.cellType = col.colDef.dataType;

          extraProps.enableColumnFilter = false;
          extraProps.enableSorting = false;
        }
        if (col.colDef.dataType === ActionTypeEntity.BUTTON) {
          extraProps.Cell = (params: any) => {
            // put the value in bold
            return (
              <>
                {" "}
                {
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      padding: "4px 12px",
                    }}
                  >
                    <ZButton
                      fullWidth
                      disabled={buttonLoading}
                      secondary={
                        col.colDef?.button?.value.includes("secondary")
                          ? true
                          : false
                      }
                      primary={
                        col.colDef?.button?.value.includes("primary")
                          ? true
                          : false
                      }
                      style={{ padding: "0px 6px", fontSize: "12px" }}
                      variant={
                        col.colDef?.properties?.type.includes("Outlined")
                          ? "contained"
                          : "contained"
                      }
                      // primary={col.colDef?.properties?.properties?.type.includes("Primary")}
                      onClick={(e) => {
                        e.stopPropagation();
                        executeButtonClick({
                          recordId: params.row.original.recordId,
                          tableType: tableType,
                          buttonId: col.colDef?.name,
                        });
                      }}
                    >
                      {" "}
                      {buttonLoading
                        ? "Loading"
                        : col.colDef?.buttonLabel || "Click Me"}{" "}
                    </ZButton>
                    {/* <ZIconButton2
                          onClick={() => {
                            dispatch(setSelectedActionColumn(col.colDef));
                            setOpenAB(true);
                          }}
                        >
                          <AiOutlineSetting />
                        </ZIconButton2> */}
                  </div>
                }{" "}
              </>
            );
          };

          extraProps.disableColumnMenu = true;
          extraProps.enableColumnActions = false;
          extraProps.onClick = () => {
            return {};
          };
          extraProps.Header = (params: any) => {
            return (
              <div style={{ width: "100%" }}>
                <ButtonAutomationHeader
                  allowColumnEdit={viewId === undefined}
                  col={col}
                  params={params}
                />
              </div>
            );
          };
          extraProps.enableColumnFilter = false;
          extraProps.enableSorting = false;
        }

        if (col.colDef.dataType === DataFieldType.LOCATION) {
          extraProps.Cell = (params: any) => {
            // put the value in bold
            const val =
              params?.cell?.renderValue?.()?.address?.length > 25
                ? `${params?.cell
                    ?.renderValue?.()
                    ?.address?.substring?.(0, 25)} ...`
                : params?.cell?.renderValue?.()?.address;

            return params?.cell?.renderValue?.()?.address?.length > 25 ? (
              <InfoTooltip
                placement="top"
                title={params?.cell?.renderValue?.()?.address}
              >
                <div>
                  <p
                    style={{
                      padding: "4px 12px",
                      color: "#3067c4",
                    }}
                  >
                    {params?.cell?.renderValue() &&
                    (params?.cell?.renderValue()?.address ||
                      (params?.cell?.renderValue()?.latitude &&
                        params?.cell?.renderValue()?.longitude))
                      ? val ||
                        `${params?.cell?.renderValue()?.latitude || ""}, ${
                          params?.cell?.renderValue()?.longitude || ""
                        }`
                      : ""}
                  </p>
                </div>
              </InfoTooltip>
            ) : (
              <div>
                <p
                  style={{
                    padding: "4px 12px",
                    color: "#3067c4",
                  }}
                >
                  {params?.cell?.renderValue() &&
                  (params?.cell?.renderValue()?.address ||
                    (params?.cell?.renderValue()?.latitude &&
                      params?.cell?.renderValue()?.longitude))
                    ? params?.cell?.renderValue?.()?.address ||
                      `${params?.cell?.renderValue()?.latitude || ""}, ${
                        params?.cell?.renderValue()?.longitude || ""
                      }`
                    : ""}
                </p>
              </div>
            );
          };
          extraProps.enableColumnFilter = true;
          extraProps.enableSorting = false;
          extraProps.disableColumnMenu = true;
          extraProps.size = 250;
          extraProps.columnFilterModeOptions = [
            "contains",
            "empty",
            "endsWith",
            "equals",
            "startsWith",
            "empty",
            "notEmpty",
            "notEquals",
          ];
        }

        if (col.colDef.dataType === DataFieldType.LIST_OF_TEXT) {
          extraProps.Cell = (params: any) => {
            return (
              <div style={{ display: "flex", gap: "8px", margin: '0px 12px', justifyContent: 'flex-start', alignItems: 'center' }}>
                {isArray(params?.cell?.renderValue?.()) && (
                  <>
                    {params?.cell
                      ?.renderValue?.()
                      ?.slice?.(0, 3)
                      ?.map?.((value: string) => {
                        if (
                          typeof value !== "object" &&
                          !Array.isArray(value)
                        ) {
                          return (
                            <ListOfValueBadges
                              width="fit-content"
                              value={value}
                              backgroundColor={
                                complimentColor[
                                  col.colDef?.properties?.color?.[value.trim()]
                                ] || "#F2F4F7"
                              }
                              color={
                                col.colDef?.properties?.color?.[value.trim()] ||
                                "#344054"
                              }
                            />
                          );
                        } else {
                          return null;
                        }
                      })}
                    {params?.cell?.renderValue()?.length > 3 ? (
                      <Tooltip title={params?.cell?.renderValue()?.join(", ")}>
                        <div style={{ padding: "4px" }}>
                          <ListOfValueBadges
                            width="fit-content"
                            backgroundColor="#F2F4F7"
                            color="#344054"
                            value={`+${
                              params?.cell?.renderValue()?.length - 3
                            }`}
                          />
                        </div>
                      </Tooltip>
                    ) : null}
                  </>
                )}
              </div>
            );
          };
          extraProps.filterSelectOptions = col.colDef?.properties?.options?.map(
            (val: any) => {
              return {
                text: val,
                value: val,
              };
            }
          );
          extraProps.filterVariant = "select";
          extraProps.enableSorting = false;
          extraProps.columnFilterModeOptions = [
            "contains",
            "empty",
            "notEmpty",
          ];
          extraProps.size = 220;
        }
        if (col.colDef.dataType === DataFieldType.RELATION) {
          let len = 0;
          extraProps.Cell = (params: any) => {
            const val = params.cell.renderValue?.();
            // return <p>kaush</p>
            if (!isArray(val)) {
              return <></>;
            }
            const displayColumn = col.colDef?.displayColumn;
            const referringEntity =
              col.colDef?.relationDetails?.referringEntity;
            const defaultDisplayColumn = ["recordId", "taskId"];
            len = val?.length;
            const values = val?.map((v: any) =>
              defaultDisplayColumn.includes(displayColumn)
                ? v?.[displayColumn]
                : referringEntity === IRelationReferringEntityType.USER
                ? v?.[displayColumn]
                : v?.data?.[displayColumn]
            );
            const valuesToShow =
              referringEntity === IRelationReferringEntityType.USER ? 6 : 3;
            return (
              <div style={{ display: "flex", gap: "8px" }}>
                {
                  // get only first valuesToShow values
                  values?.slice(0, valuesToShow)?.map((value: string) => {
                    return referringEntity ===
                      IRelationReferringEntityType.USER ? (
                      value && (
                        <Tooltip title={value}>
                          <Avatar {...stringAvatar(value)} />
                        </Tooltip>
                      )
                    ) : (
                      <RelationCellBadge
                        value={value}
                        backgroundColor={"#F2F4F7"}
                        color={"#344054"}
                      />
                    );
                  })
                }
                {values?.length > valuesToShow ? (
                  referringEntity === IRelationReferringEntityType.USER ? (
                    <Tooltip title={values?.join(", ")}>
                      <div>
                        <Avatar
                          {...stringAvatar(
                            `+ ${values?.length - valuesToShow}`
                          )}
                        />
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip title={values?.join(", ")}>
                      <div>
                        <RelationCellBadge
                          backgroundColor={"#F2F4F7"}
                          color={"#344054"}
                          value={`+${values?.length - valuesToShow}`}
                        />
                      </div>
                    </Tooltip>
                  )
                ) : null}
              </div>
            );
          };
          // extraProps.Cell = (params: any) => {
          // return <p>Rrlation</p>
          // }

          extraProps.enableColumnFilter =
            col.colDef?.relationDetails?.referringEntity === "CUSTOM"
              ? true
              : false;
          extraProps.enableSorting = false;
          extraProps.disableColumnMenu = true;
          extraProps.size = 350;
        }

        if (col.colDef.dataType === EntityTypeField.COMPUTED) {
          extraProps.enableColumnFilter = false;
          extraProps.enableSorting = false;
          extraProps.disableColumnMenu = true;
        }
        return {
          accessorKey: col.id || col.type,
          header: col.label,
          filterFn: "contains",
          data: {
            ...col.colDef,
            type: col.colDef.dataType,
          },
          Header: (params: any) => {
            return (
              <div style={{ width: "100%" }}>
                <ButtonAutomationHeader
                  allowColumnEdit={viewId === undefined}
                  col={col}
                  params={params}
                />
              </div>
            );
          },
          Cell: (params: any) => {
            return (
              <Tooltip
                title={
                  params.column.getIsFiltered()
                    ? "Filter Applied"
                    : params.column.getIsSorted()
                    ? "Column is Sorted"
                    : ""
                }
              >
                <div>
                  <p
                    style={{
                      padding: "4px 12px",
                      background: params.column.getIsFiltered()
                        ? "#fef3ee"
                        : params.column.getIsSorted()
                        ? "#ecfbe7"
                        : "",
                    }}
                  >
                    {params?.cell?.renderValue() === undefined
                      ? ""
                      : params?.cell?.renderValue()}
                  </p>
                </div>
              </Tooltip>
            );
          },
          ...extraProps,
        };
      });

    // add {field: id, headerName: id} to colDefs at index 1
    if (
      viewId === undefined ||
      view?.params?.visibleColumns.includes("recordId")
    ) {
      colDefs?.splice(0, 0, {
        accessorKey: "recordId",
        header: "Record Id",
        enableClickToCopy: true,
        // enableColumnFilter: true,
        enableSorting: true,
        pinned: "left",
        headerCheckboxSelection: true,
        checkboxSelection: true,
        cellStyle: { fontWeight: "500" },
        props: {
          isSystemField: true,
        },
        size: 120,
        columnFilterModeOptions: [
          "contains",
          "empty",
          "endsWith",
          "equals",
          "startsWith",
          "empty",
          "notEmpty",
          "notEquals",
        ],
        Cell: (params: any) => {
          // put the value in bold
          return (
            <Tooltip
              title={
                params.column.getIsFiltered()
                  ? "Filter Applied"
                  : params.column.getIsSorted()
                  ? "Column is Sorted"
                  : ""
              }
            >
              <div>
                <p
                  style={{
                    padding: "4px 12px",
                    background: params.column.getIsFiltered()
                      ? "#fef3ee"
                      : params.column.getIsSorted()
                      ? "#ecfbe7"
                      : "",
                  }}
                >
                  {params.cell.getValue()}
                </p>
              </div>
            </Tooltip>
          );
        },
        Header: (params: any) => {
          return (
            <div style={{ width: "100%" }}>
              <SystemFieldHeader
                dataType={DataFieldType.ID}
                name="ID"
                params={params}
              />
            </div>
          );
        },
      });
    }

    // add {field: createdOn, headerName: Created On} to colDefs at index 1
    if (
      viewId === undefined ||
      view?.params?.visibleColumns?.includes("createdOn")
    ) {
      colDefs?.push({
        accessorKey: "createdOn",
        header: "Created On",
        accessorFn: (row) =>
          moment(row.createdOn).tz(account?.accountConfig?.timezone),
        Header: (params: any) => {
          return (
            <div style={{ width: "100%" }}>
              <SystemFieldHeader
                dataType={DataFieldType.DATE}
                name="Created On"
                params={params}
              />
            </div>
          );
        },
        size: 210,
        enableColumnFilter: true,
        enableSorting: true,
        pinned: "left",
        columnFilterModeOptions: [
          "greaterThan",
          "lessThan",
          "equals",
          "empty",
          "notEmpty",
        ], //only allow these filter modes
        // columnFilterModeOptions: ["greaterThan", "lessThan","between", "greaterThanOrEqualTo", "lessThanOrEqualTo"],
        filterFn: "equals",
        sortingFn: "datetime",
        lockPinned: true,
        cellStyle: { fontWeight: "500" },
        props: {
          isSystemField: true,
        },
        Cell: (params: any) => {
          // put the value in bold
          return (
            <Tooltip
              title={
                params.column.getIsFiltered()
                  ? "Filter Applied"
                  : params.column.getIsSorted()
                  ? "Column is Sorted"
                  : ""
              }
            >
              <div>
                <p
                  style={{
                    padding: "4px 12px",
                    background: params.column.getIsFiltered()
                      ? "#fef3ee"
                      : params.column.getIsSorted()
                      ? "#ecfbe7"
                      : "",
                  }}
                >
                  {" "}
                  {moment(params.cell.getValue()).format(
                    "Do MMM YYYY , h:mm A"
                  )}{" "}
                </p>
              </div>
            </Tooltip>
          );
        },
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              slotProps={{
                textField: {
                  helperText: "Filter Mode: Less Than",
                  sx: { minWidth: "120px" },
                  variant: "standard",
                },
                field: {
                  clearable: true,
                  onClear: () => column.setFilterValue(null),
                },
              }}
              value={
                column.getFilterValue()
                  ? new Date(column.getFilterValue())
                  : null
              }
            />
          </LocalizationProvider>
        ),
      });
    }

    // // add {field: updatedOn, headerName: Updated On} to colDefs at index 2
    if (
      viewId === undefined ||
      view?.params?.visibleColumns?.includes("updatedOn")
    ) {
      colDefs?.push({
        accessorKey: "updatedOn",
        accessorFn: (row) =>
          moment(row.updatedOn).tz(account?.accountConfig?.timezone),
        header: "Updated On",
        enableColumnFilter: true,
        enableSorting: true,
        size: 210,
        columnFilterModeOptions: [
          "greaterThan",
          "lessThan",
          "equals",
          "empty",
          "notEmpty",
        ], //only allow these filter modes
        sortingFn: "datetime",
        filterFn: "equals",
        pinned: "left",
        lockPinned: true,
        cellStyle: { fontWeight: "500" },
        props: {
          isSystemField: true,
        },
        Cell: (params: any) => {
          // put the value in bold
          return (
            <Tooltip
              title={
                params.column.getIsFiltered()
                  ? "Filter Applied"
                  : params.column.getIsSorted()
                  ? "Column is Sorted"
                  : ""
              }
            >
              <div>
                <p
                  style={{
                    padding: "4px 12px",
                    background: params.column.getIsFiltered()
                      ? "#fef3ee"
                      : params.column.getIsSorted()
                      ? "#ecfbe7"
                      : "",
                  }}
                >
                  {" "}
                  {moment(params.cell.getValue()).format(
                    "Do MMM YYYY , h:mm A"
                  )}{" "}
                </p>
              </div>
            </Tooltip>
          );
        },
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              slotProps={{
                textField: {
                  helperText: "Filter Mode: Less Than",
                  sx: { minWidth: "120px" },
                  variant: "standard",
                },
                field: {
                  clearable: true,
                  onClear: () => column.setFilterValue(null),
                },
              }}
              value={
                column.getFilterValue()
                  ? new Date(column.getFilterValue())
                  : null
              }
            />
          </LocalizationProvider>
        ),
        Header: (params: any) => {
          return (
            <div style={{ width: "100%" }}>
              <SystemFieldHeader
                dataType={DataFieldType.DATE}
                name="Updated On"
                params={params}
              />
            </div>
          );
        },
      });
    }

    // if (
    //   viewId === undefined &&
    //   initTableConfig === undefined &&
    //   porterPermissions.checkPermissionsForPorter(tableType, "add_column")
    // ) {
    //   colDefs.push({
    //     accessorKey: "addRow",
    //     header: "Add Column",
    //     size: 50,
    //     pinned: "right",
    //     lockPinned: true,
    //     enableSorting: false,
    //     enableColumnFilter: false,
    //     enableColumnActions: false,
    //     enableColumnOrdering: false,
    //     enableHiding: false,
    //     enableResizing: false,
    //     disableColumnMenu: true,

    //     // enablePinning: false,

    //     onClick: () => {
    //       return {};
    //     },
    //     Header: () => {
    //       return (
    //         <>
    //           <Tooltip title={"Add New Column"}>
    //             <div
    //               style={{ width: "100%" }}
    //               onClick={() => dispatch(setShowCreationModal(true))}
    //               id={OnboardingIdsForComponent.CREATE_NEW_COLUMN_BUTTON}
    //             >
    //               <AiOutlinePlus size="1.5rem" strokeWidth={"2px"} />
    //             </div>
    //           </Tooltip>
    //         </>
    //       );
    //     },
    //   });
    // }

    // get the timestamp
    const colComputeEndTime = moment(new Date());
    // time for execution of colDefs
    const diffTime = colComputeEndTime.diff(startTime);
    // console.log(">>>>> formatListingConfig colComputeTime", diffTime);

    const rowComputeStartTime = moment(new Date());

    const rowDefs = data?.rowDef
      ?.filter((row: IRowDefs) => {
        // check if row?.recordId is not in addedRecords
        // if it is, then return false
        // else return true

        /**
         * remove ids that are in rowsToDelete
         */

        if (addedRecords?.includes(row?.recordId)) {
          return false;
        }
        // check if row?.recordId is not in tempAddedRecords
        // if it is, then return false
        // else return true
        const newlyAddedRecord = tempAddedRecords?.filter(
          (item: any) => !addedRecords.includes(item)
        );
        if (newlyAddedRecord?.includes(row?.recordId)) {
          return true;
        }
        // check if row?.recordId is not in rowsToDelete
        // if it is, then return false
        // else return true
        if (rowsToDelete?.includes(row?.recordId)) {
          return false;
        }
        return true;
      })
      ?.map((row: IRowDefs) => {
        return {
          ...row.data,
          recordId: row?.recordId,
          createdOn: row?.createdOn,
          updatedOn: row?.updatedOn,
        };
      });

    const rowComputeEndTime = moment(new Date());
    // time for execution of rowDefs
    const rowDiffTime = rowComputeEndTime.diff(rowComputeStartTime);
    // console.log(
    //   ">>>>> formatListingConfig total time to compute rows",
    //   rowDiffTime
    // );

    return {
      colDefs,
      rowDefs,
    };
  };

  const fetchListingView = async () => {
    setLoading(true);

    const allowedDirectSortFields = ["recordId", "createdOn", "updatedOn"];
    // keyword:false to each object in sorting array
    sorting.forEach((sortObj) => {
      if (!allowedDirectSortFields.includes(sortObj.id)) {
        sortObj.keyword = true;
      }
    });

    // add filterType to each column filter
    columnFilters.forEach((filterObj) => {
      if (["createdOn", "updatedOn"].includes(filterObj.id)) {
        filterObj.filterType = columnFilterFns[filterObj.id] || "equals";
      } else {
        filterObj.filterType = columnFilterFns[filterObj.id] || "contains";
      }
    });
    try {
      if (tableType) {
        const data = await EntityService.getListingView(
          tableType,
          "listing",
          pagination.pageIndex,
          pagination.pageSize,
          sorting,
          globalFilter || "",
          columnFilters,
          viewId || ""
        );

        setRowCount(data?.count || 0);

        const { colDefs, rowDefs } = formatListingConfig(data.body);
        setRowData(() => [...rowDefs]);
        let selectedRowInitialState = { ...selectedRows };
        rowDefs?.forEach((row: any) => {
          if (rowsToDelete?.includes(row?.recordId)) {
            selectedRowInitialState[row?.recordId] = true;
          }
          if (value?.includes(row?.recordId)) {
            selectedRowInitialState[row?.recordId] = true;
          }
          if (addedRecords?.includes(row?.recordId)) {
            selectedRowInitialState[row?.recordId] = true;
          }
          if (tempAddedRecords?.includes(row?.recordId)) {
            selectedRowInitialState[row?.recordId] = true;
          }
          setSelectedRows(selectedRowInitialState);
        });
        setSelectedRows(selectedRowInitialState);
        if (rowDefs.length === 0) {
          setIsEntityTableEmpty?.(true);
        }
        setColumnDefs(colDefs);

        setTimeout(() => {
          setLoading(false);
        }, 10);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(">>>> error", error?.message);
      notification("error", error?.message);
      setLoading(false);
    }
  };

  const handleRowSelectionChange = (updaterFn) => {
    setSelectedRows((currentSelectedRows) => {
      // Update the state with the new selection
      if (isFunction(updaterFn)) return updaterFn(currentSelectedRows);
    });

    console.log("&&&&&&", selectedRows);
  };

  useEffect(() => {
    // if(isFirstRender.current) return;
    console.log("Updated selected rows: ", selectedRows);
    // get all the keys of selected rows
    const deletedRecords = Object.keys(selectedRows);
    // loop through the keys:
    // const deletedRecords = selectedRowKeys.map((key: number) => {
    //   // if the value is true, then the row is selected
    //   if (selectedRows[key]) {
    //     //get this index from initConfig.rowData
    //     const val = initTableConfig?.rowDef?.[key];
    //     return val?.recordId;
    //   }
    // });
    // remove undefined values
    // const filteredDeletedRecords = deletedRecords.filter(
    //   (val) => val !== undefined
    // );

    // const newRowsToDelete = union(rowsToDelete, filteredDeletedRecords);

    // if (nameOfTheTableInRelatedTable) {
    //   // remove value that are in filteredDeletedRecords from t
    //   // set the state
    //   dispatch(
    //     setRowsToDelete({
    //       nodeId: nameOfTheTableInRelatedTable,
    //       data: [...filteredDeletedRecords],
    //     })
    //   );
    // }
    if (isFirstRender.current) return;
    /**
     * if isSingleSelect is true, then we need to remove all the other selected rows
     */

    onRowSelectionClick(deletedRecords);

    //
    // Additional logic to handle the updated state
  }, [selectedRows]);

  useEffect(() => {
    if (isFirstRender.current) return;
    setRefresh((prev) => !prev);
  }, [taskDataUpdated]);

  useEffect(() => {
    if (initTableConfig === undefined) {
      fetchListingView();
    }
  }, [
    // allEntities,
    tableType,
    columnFilters,
    // selectedEntityMetadata,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    refresh,
  ]);

  useEffect(() => {
    if (initTableConfig) {
      const { colDefs: initColDefs, rowDefs: initRowDefs } =
        formatListingConfig(initTableConfig);
      setColumnDefs(initColDefs);
      setRowData(initRowDefs);
      if (initRowDefs.length === 0) {
        setIsEntityTableEmpty?.(true);
      }
    }
  }, []);

  useEffect(() => {
    const childDiv = document.querySelector(".ZorpTablePagination-root");
    if (childDiv) {
      const parentDiv = childDiv.parentElement;
      if (parentDiv) {
        parentDiv.classList.add("ZorpTablePaginationParent-root");
      }
    }
  }, []);

  useEffect(() => {
    if (columnDefs.length > 0) {
      const defaultColumnOrdering = columnDefs?.map(
        (col: any) => col.accessorKey
      );
      // add mr-row-select to defaultColumnOrdering
      defaultColumnOrdering.unshift("mrt-row-select");
      setColumnOrdering(defaultColumnOrdering);
    }
  }, [columnDefs]);

  //load state from local storage
  useEffect(() => {
    if (!fromShowPage) {
      const parsedColumnPinning = JSON.parse(columnPinning);
      // always add "showRecords" and "addRow" to right pinning
      const currRight = parsedColumnPinning.right;
      const currLeft = parsedColumnPinning.left;
      // add "mrt-row-select" to left pinning
      const left = ["mrt-row-select"];
      const right = ["showRecord", "addRow"];
      parsedColumnPinning.right = union(currRight, right);
      parsedColumnPinning.left = union(currLeft, left);
      setColumnPinning(parsedColumnPinning);
    } else {
      // add "showRecords" and "addRow" to right pinning
      const right = ["showRecord", "addRow"];
      const pinnedColumn = {
        left: [],
        right,
      };
      setColumnPinning(pinnedColumn);
    }

    isFirstRender.current = false;
  }, [tableType]);

  const handleColumnFilterFnsChange = (updater: any) => {
    //call the setState as normal, but need to check if using an updater callback with a previous state
    setColumnFilterFns((prev) =>
      updater instanceof Function ? updater(prev) : updater
    );
  };

  console.log(">>>>> selected Rows", selectedRows);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: " 100%",
          marginBottom: "8px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
            gap: "8px",
            padding: "0px 8px",
          }}
        >
          <div
            style={{
              fontSize: "16px",
              color: "#101828",
              fontWeight: "600",
              marginBottom: "8px",
            }}
          >
            {" "}
            Attach a referenced {recordTypeLabel || "record"}{" "}
          </div>
          <div>
            {isSingleSelect && (
              <InfoPill>You can only select one {recordTypeLabel || "record"}</InfoPill>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "8px",
          }}
        >
          {porterPermissions.checkPermissionsForPorter(
            tableType,
            "create_record"
          ) &&
            !subRecordCreateMode && (
              <ZButton
                onClick={() => {
                  //   history.push(`/table/create/${tableType}`)
                  onNewRecordRecordClick();
                  recordRSEvent(TableRSEvents.ADD_NEW_RECORD_BUTTON_CLICKED, {
                    tableType: tableType || "",
                  });
                }}
                variant="contained"
                secondary
                startIcon={<AiOutlinePlus size={"16px"} />}
                disabled={!tableType}
                id={OnboardingIdsForComponent.CREATE_NEW_TABLE_RECORD_BUTTON}
              >
                Create
              </ZButton>
            )}
          <ZButton
            onClick={() => onSave()}
            variant="contained"
            startIcon={Save}
            disabled={!tableType}
            //   id={OnboardingIdsForComponent.CREATE_NEW_TABLE_RECORD_BUTTON}
          >
            Save
          </ZButton>
        </div>
      </div>
      <div
        className={
          initTableConfig === undefined
            ? "zorpTable zorpAddRelationTable"
            : "zorpTable zorpTableComponent"
        }
        style={
          initTableConfig === undefined
            ? { height: "calc(100% - 125px)", width: "100%" }
            : {}
        }
      >
        <MaterialReactTable
          data={rowData}
          enableColumnResizing
          defaultColumn={{
            minSize: 20, //allow columns to get smaller than default
            maxSize: 400, //allow columns to get larger than default
            // size: 260, //make columns wider by default
          }}
          muiTableBodyCellProps={({ cell }) => ({
            onDoubleClick: (event) => {
              event.stopPropagation();
              //@ts-ignore
              showRecordClick(cell.row.original.recordId);
            },
          })}
          muiSelectCheckboxProps={{
            color: "primary",
          }}
          columns={columnDefs}
          enablePinning
          enableStickyHeader
          muiTablePaperProps={{
            style: {
              height: "100%",
              position: "relative",
              zIndex: 0,
              boxShadow: "none",
            },
          }}
          getRowId={(row) => row.recordId}
          // muiTopToolbarProps={{
          //   style: {
          //     display: "none",
          //   },
          // }}
          muiTableHeadCellProps={{
            sx: {
              "& .Mui-TableHeadCell-Content": {
                justifyContent: "space-between",
              },
              "& .MuiButtonBase-root": {
                justifyContent: "start",
              },
              "& .Mui-TableHeadCell-ResizeHandle-Wrapper": {
                "& .MuiDivider-root": {
                  borderWidth: "0px",
                  borderColor: "none !important",
                },
              },
            },
          }}
          muiTableBodyRowProps={({ row }) => {
            return {
              sx: {
                backgroundColor: rowsToDelete?.includes(row?.original.recordId)
                  ? "#FFF4ED !important"
                  : addedRecords?.includes(row?.original?.recordId)
                  ? "#ECF3FF !important"
                  : "white",
                // Apply any other row styles here
              },
            };
          }}
          renderTopToolbarCustomActions={(props) => {
            return initTableConfig === undefined || fromShowPage ? (
              <CustomToolbarForAddRecordToRelationModal
                tableType={tableType}
                showColumnFilters={showColumnFilters}
                columnFilterFns={columnFilterFns}
                setLoading={setLoading}
                setRefresh={setRefresh}
                setSelectedRows={setSelectedRows}
                fromShowPage={fromShowPage}
                viewId={viewId}
                onNewRecordRecordClick={onNewRecordRecordClick}
                {...props}
              />
            ) : (
              <></>
            );
          }}
          muiTopToolbarProps={{
            sx: {
              padding: "0px !important",
            },
            className: "zorpTableTopToolbar",
            id: "muitoptoolbar",
          }}
          renderToolbarInternalActions={({ table }) => {
            return initTableConfig === undefined ? (
              <div
                style={{
                  visibility: "hidden",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                {/* along-side built-in buttons in whatever order you want them */}
                {/* <MRT_ToggleGlobalFilterButton table={table} />
                   <MRT_ToggleFiltersButton table={table} />

                   <MRT_ShowHideColumnsButton table={table} /> */}
                {/* <GlobalSearch table={table} /> */}
                {/* <FilterAction table={table} /> */}
                {/* <ShowHideColumnAction table={table} /> */}
                {/* <RefreshTableButton
                        setRefresh={setRefresh}
                        table={table}
                      />
                      {porterPermissions.checkPermissionsForPorter(
                        tableType,
                        "create_record"
                      ) && (
                        <ZButton
                          onClick={() => {
                            history.push(
                              `/table/create/${tableType}`
                            )
                            recordRSEvent(
                              TableRSEvents.ADD_NEW_RECORD_BUTTON_CLICKED,
                              {
                                tableType: tableType || "",
                              }
                            )
                          }}
                          variant="contained"
                          startIcon={<AiOutlinePlus />}
                          disabled={!selectedEntity}
                          id={
                            OnboardingIdsForComponent.CREATE_NEW_TABLE_RECORD_BUTTON
                          }
                        >
                          {" "}
                          New Record{" "}
                        </ZButton>
                      )} */}
                {/* <Actions
                        columnFilterFns={columnFilterFns}
                        setLoading={setLoading}
                        table={table}
                      /> */}
              </div>
            ) : (
              <></>
            );
          }}
          initialState={{
            columnPinning: {
              // left: ["recordId"],
              right: ["addRow"],
            },
            pagination,
            columnFilters,
            globalFilter,
            sorting,
            rowSelection: { "0": true },
          }}
          rowCount={initTableConfig === undefined ? rowCount : undefined}
          enableRowSelection={(row) => {
            return addedRecords?.includes(row?.id) === false;
          }}
          onPaginationChange={setPagination}
          onColumnFiltersChange={setColumnFilters}
          onColumnVisibilityChange={setColumnVisibility}
          onDensityChange={setDensity}
          enableGlobalFilter={initTableConfig === undefined}
          onGlobalFilterChange={setGlobalFilter}
          onColumnOrderChange={setColumnOrdering}
          onShowColumnFiltersChange={setShowColumnFilters}
          onShowGlobalFilterChange={setShowGlobalFilter}
          onSortingChange={setSorting}
          onColumnFilterFnsChange={handleColumnFilterFnsChange}
          onColumnPinningChange={setColumnPinning}
          enableColumnFilterModes
          enableMultiRowSelection={isSingleSelect ? false : true}
          manualFiltering={initTableConfig === undefined}
          positionGlobalFilter="left"
          muiSearchTextFieldProps={{
            placeholder: `Search ${rowCount} records`,
            inputProps: {
              sx: {
                fontSize: "11px",
                display: "block",
              },
            },
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">
                  <AiOutlineSearch
                    style={{ marginLeft: "4px" }}
                    size={"16px"}
                  />
                </InputAdornment>
              ),
            },
            sx: {
              border: "1px solid #EAECF0",
              padding: "3px",
              borderRadius: "4px",
              width: "100%",
              marginRight: "10px",
            },
          }}
          manualPagination={initTableConfig === undefined}
          manualSorting={initTableConfig === undefined}
          globalFilterFn="contains"
          icons={CustomIcons}
          muiTablePaginationProps={{
            rowsPerPageOptions: [10, 20, 30, 50, 100],
            showFirstButton: false,
            showLastButton: false,
            className: "ZorpTablePagination-root",
          }}
          onRowSelectionChange={handleRowSelectionChange}
          // enableColumnVirtualization = {columnDefs.headers?.length > 10}
          state={{
            columnFilters,
            columnVisibility,
            density,
            globalFilter,
            showColumnFilters,
            showGlobalFilter,
            sorting,
            isLoading: loading,
            pagination,
            columnFilterFns,
            columnOrder: columnOrdering,
            columnPinning: {
              left: union(["mrt-row-select"], columnPinning.left),
              right: union(columnPinning.right, ["showRecord", "addRow"]),
            },
            rowSelection: selectedRows,
          }}
          enableColumnOrdering={false}
        />
      </div>
      {linksArray?.length > 0 && (
        <ImageModal
          BackdropComponent={Backdrop}
          BackdropProps={{
            color: "rgba(0,0,0,0.8)",
          }}
          open={showImage}
          onClose={() => setShowImage(false)}
          // aria-labelledby="modal-modal-title"
          // aria-describedby="modal-modal-description"
        >
          <ImageSlider
            //@ts-ignore
            onClose={() => setShowImage(false)}
            startIndex={0}
            slides={linksArray}
          />
        </ImageModal>
      )}
    </>
  );
};

export default AddRecordToRelationTable;