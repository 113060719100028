import React from 'react'
import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";
import { MonitorTextField } from '../Monitor.styles';

interface IQueryBuilderTextField {
  control:any;
  register:any;
  name:string;
  onChange:any;
}

const QueryBuilderTextField = ({control, register, name, onChange}:IQueryBuilderTextField) => {
  const handleCustomChange = (event:any, fieldOnChange:any) => {
    // Custom logic here
    console.log("Custom onChange logic:", event.target.value);
    onChange(event.target.value)
    // Call the React Hook Form onChange function
    fieldOnChange(event);
  };

  return (
    <>
   <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field: { onChange, ...field } }) => (
          <MonitorTextField
            {...field}
            inputProps={{
              "aria-label": "none",
            }}
            onChange={(event) => handleCustomChange(event, onChange)}
          />
        )}
      />
  </>
  )
}

// export default QueryBuilderTextField
export default React.memo(QueryBuilderTextField);


