import React from 'react'
import {useSelector} from "react-redux"

const useOnboarding = () => {
    const {userOnboarding} = useSelector((state:any) => state)

    const onboarding:any = userOnboarding?.onboarding
    const currentStep:any = userOnboarding?.onboarding.currentStepId || ""
    const showOnboardingModal:any = userOnboarding?.showOnboardingModal || false
    const isOnboardingDoneForWeb:any = userOnboarding?.onboarding?.isOnboardingDoneForWeb || false
    const isBeingOnboarded:any = userOnboarding?.isBeingOnboarded || false
    return {
        onboarding,
        currentStep,
        showOnboardingModal,
        isOnboardingDoneForWeb,
        isBeingOnboarded
    }

}

export default useOnboarding