import { AxiosResponse } from "axios";
import HttpUtil from "config/AxiosConfig";
import { ZorpResponse } from "providers/data/models/ZorpResponse";
import { getConfig as Config } from "../../../config/envConfig/Config";
import { IAutomation } from "views/automationBuilder/types/ABType";

export enum ICronExecutionType {
  CRON = 'CRON',
  NO_CRON = 'NO_CRON'
}

export interface IExecuteCronPayload {
  cronId?: string;
  cronExecutionType: ICronExecutionType;
  cronPayload?:IAutomation;
}

export class CronService {
  public static executeCron(payload: IExecuteCronPayload): Promise<ZorpResponse | undefined> {
    return HttpUtil.cronInstance
      .post("/run/cronjob", payload)
      .then((response: AxiosResponse) => {
        return response;
      }).catch((error: any) => {
        return error?.response || 'Something went wrong!';
      })
  }

  public static getAllCron = (): Promise<ZorpResponse | undefined> => {
    // fetch only monitorType Automation
    const fetchCronUrl = Config("services.cronSvc.api.getAllCron") + `?cronType=AUTOMATION`;
    return HttpUtil.cronInstance.get(fetchCronUrl).then((response: AxiosResponse) => {
      return response?.data as ZorpResponse;
    });
  }

  public static CreateCron = (allAutomationPayload: any): Promise<ZorpResponse | undefined> => {
    // fetch only monitorType Automation
    const fetchCronUrl = Config("services.cronSvc.api.addAutomationCron");
    const payload = allAutomationPayload
    return HttpUtil.cronInstance.post(fetchCronUrl, payload).then((response: AxiosResponse) => {
      return response?.data as ZorpResponse;
    });
  }

  public static updateCronData = (cronId: string, payload: any): Promise<ZorpResponse | undefined> => {
    const fetchCronUrl = (Config("services.cronSvc.api.updateAutomationCron") as string).replace('{cronId}', cronId);
    return HttpUtil.cronInstance.put(fetchCronUrl, payload).then((response: AxiosResponse) => {
      return response?.data as ZorpResponse;
    });
  }

  public static updateCronMockData = (cronId: string, payload: any): Promise<ZorpResponse | undefined> => {
    const updateCronMockDataPayload = {
      cronId, payload
    }

    const fetchCronUrl = Config("services.cronSvc.api.updateCronMockData");
    return HttpUtil.cronInstance.put(fetchCronUrl, updateCronMockDataPayload).then((response: AxiosResponse) => {
      return response?.data as ZorpResponse;
    });
  }

  public static fetchCronMockData = (cronId: string): Promise<ZorpResponse | undefined> => {
    const fetchCronUrl = Config("services.cronSvc.api.fetchCronMockData");
    const fetchCronMockDataAxiosPayload = {
      cronType: cronId ? undefined : 'NEW',
      cronId: cronId || undefined
    }
    return HttpUtil.cronInstance.post(fetchCronUrl, fetchCronMockDataAxiosPayload).then((response: AxiosResponse) => {
      return response?.data as ZorpResponse;
    });
  }
}
