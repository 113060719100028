import { isEmpty } from "lodash";
import { EntityService } from "providers/data/services/EntityService";
import { FunctionComponent, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getComponentParams } from "render-engine/RenderUtils";
import TopBar from "render-engine/TopBar";
import { initTaskCreation } from "render-engine/reducers/create";
import { resetListInfo } from "render-engine/reducers/listInfo";
import { TaskFormBody } from "render-engine/render.styles";
import notification from "../../notifications/notifications";
import "../../styles/App.css";
import LoadingScreen from "../../views/LoadingScreen/LoadingScreen";
import { setLoading } from "../../views/reducer/index";
import { RenderV2 } from "render-engine/RenderV2";
import {
  clearRowsToDelete,
  clearTaskData,
  initRecordCreateMetaData,
  resetTaskShow,
  revertToInitialState,
  setInitialTaskData,
} from "render-engine/reducers/show/TaskShowReducer";
import { PageCategoryEnum, recordPageVisit } from "config/Rudderstack";
import useTaskShow from "views/workflows/hooks/useTaskShow";
import { getEntityMetadata } from "./reducers/entityReducers";
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryComp from "views/NotFound/ErrorBoundaryComp";
import { sendCCErrorAlertToSlack } from "utils/SlackController";
import useQuery from "./hooks/useQuery";
import useEntity from "./hooks/useEntity";
import ChecklistBuilder from "render-engine/show/ChecklistBuilder";
import { getConfig } from "config/envConfig/Config";

function fallbackRender({
  error,
  resetErrorBoundary,
}: {
  error: any;
  resetErrorBoundary: any;
}) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <ErrorBoundaryComp
      message="error"
      resetErrorBoundary={resetErrorBoundary}
    />
  );
}

const checklistBuilderTable = getConfig("checklistBuilderTable");

const EntityCreate: FunctionComponent<{
  isCreate?: boolean;
  taskId?: string;
}> = ({ isCreate = false, taskId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const createTaskForm = useForm();
  const { tableType } = useParams();
  const query = useQuery();
  const {
    selectedViewId,
    selectedEntity,
    selectedEntityMetadata,
    recordTypeLabel,
  } = useEntity();

  const { taskRenderDetails, taskShowLayoutMetadata } = useTaskShow();
  //@ts-ignore
  const { loading } = useSelector((state) => state.loading);
  const { taskData: data, isDataEdited } = useTaskShow();

  // Fetch the config for CC
  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(initTaskCreation({}));
    dispatch(resetListInfo());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const createEntity = async () => {
    try {
      const teamIds = data.teamIds || [];
      delete data.teamIds;
      dispatch(setLoading(true));
      let newData = { data: data, tableType, teamIds };
      console.log("newData:", newData);
      const createdRecord = await EntityService.createEntityRecord(newData);
      notification("success", `${recordTypeLabel} created successfully!`);
      dispatch(revertToInitialState());
      // history.push(`/table/${tableType}?allowRedirect=false`);
      if (createdRecord?.data?.recordId) {
        onTaskCreateSuccess(createdRecord?.data?.recordId);
      } else {
        cancelTask();
      }

      dispatch(setLoading(false));
    } catch (error) {
      // notification(
      //   "error",
      //   error?.response?.data?.message || "Something went wrong"
      // );
      // history.goBack();
      dispatch(setLoading(false));
    }
  };
  useEffect(() => {
    dispatch(resetTaskShow());
    recordPageVisit(PageCategoryEnum.task, "entity_create");
    // dispatch(resetListInfo())
    // Clear edit state if a user comes back to show page
    //TODO: ADD for create task as well
    setLoading(true);
    dispatch(
      initRecordCreateMetaData({
        tableType,
      })
    );
    dispatch(getEntityMetadata(tableType || ""));
    // clearing the initial data
    dispatch(clearTaskData());
    dispatch(clearRowsToDelete());
    setLoading(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const cancelTask = () => {
    const context = query.get("context");
    const viewId = query.get("viewId") || selectedViewId;
    if (context === "ticket") {
      history.push(`/ticket/${tableType}?allowRedirect=false`);
      return;
    }
    if(viewId) {
      history.push(`/table/${tableType}/view/${viewId}/?allowRedirect=false`);
    } else {
      history.push(`/table/${tableType}/?allowRedirect=false`);
    }
  };

  const onTaskCreateSuccess = (id: string) => {
    const viewId = query.get("viewId") || selectedViewId;
    const context = query.get("context");
    if (context === "ticket") {
      history.push(`/ticket/${tableType}/show/${id}`);
      return;
    }
    history.push(`/table/${tableType}/show/${id}/?viewId=${viewId}`);
  };

  const sendSlackNotification = async (
    error: Error,
    info: { componentStack: string }
  ) => {
    try {
      await sendCCErrorAlertToSlack({
        endpoint: `Record create did not load for ${tableType} with record id and message is ${error.message}`,
        code: 0,
        message: JSON.stringify(info),
        type: "ERROR",
      });
    } catch (error) {}
  };

  return (
    <>
      <ErrorBoundary
        fallbackRender={fallbackRender}
        onError={sendSlackNotification}
      >
        {loading || isEmpty(taskRenderDetails) ? (
          <LoadingScreen />
        ) : (checklistBuilderTable.includes(tableType)) ? (
          <ChecklistBuilder
            checklistTabletype={tableType}
            taskRenderDetails={taskRenderDetails}
            mode="create"
          />
        ) : (
          <>
            <FormProvider {...createTaskForm}>
              <form
                style={{ overflow: "hidden" }}
                onSubmit={createTaskForm.handleSubmit(createEntity)}
              >
                {
                  <TopBar
                    heading={`Create ${recordTypeLabel}`}
                    onCancelText={"Cancel"}
                    onSaveText="Create"
                    onSave={() => {}}
                    onCancel={cancelTask}
                  />
                }
                <TaskFormBody key="taskCreate" className={`grid`}>
                  {/* <div className='flex font-bold text-3xl justify-center pb-8'>Create Task</div> */}
                  {/* <hr style={{ marginBottom: '3rem' }} /> */}
                  <div className="contentWrapper-entityCreate">
                    {!isEmpty(taskRenderDetails) && (
                      <RenderV2
                        componentParams={getComponentParams(
                          dispatch,
                          createTaskForm
                        )}
                        entityCategory={"TABLE"}
                        entityType={tableType}
                        entityId={undefined}
                        showComments={false}
                        mode={"create"}
                        setShowComments={() => {}}
                      ></RenderV2>
                    )}
                  </div>
                </TaskFormBody>
              </form>
            </FormProvider>
          </>
        )}
      </ErrorBoundary>
    </>
  );
};

export default EntityCreate;
