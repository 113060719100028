import React, { useEffect } from 'react'
import styled from 'styled-components'
import { IoClose as CloseIcon } from "react-icons/io5";
import SearchInputContainer from '../permissions/components/SearchInputContainer';
import { OptionType, ResourceTypeConstant } from '../permissions/types';
import useAccessPermissions from 'hooks/useAccessPermissions';
import notification from 'notifications/notifications';
import { EntityService } from 'providers/data/services/EntityService';
import useEntity from '../hooks/useEntity';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import jwtDecode from 'jwt-decode';
import { ColumnPermissionsRsEvents } from '../types';
import { recordRSEvent } from 'utils/CommonUtils';

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
`;

const ModalContainer = styled.div`
    background-color: white;
    padding: 24px;
    border-radius: 4px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: flex-start;
    width: 100%;
    z-index: 1001;
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
`

const Heading = styled.div`
    display: flex;
    flex-direction: column;
`

const Text = styled.p<{ $color: string; $fontSize: string; $fontWeight: string }>`
    color: ${(props) => props.$color};
    font-size: ${(props) => props.$fontSize};
    font-weight: ${(props) => props.$fontWeight};
`

const InputContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 8px;
`

const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
    align-items: center;
`

const SaveButton = styled.button<{ $active?: boolean; $color: string; $background: string; $borderColor: string; }>`
    color: ${props => props.$active ? props.$color : "#fff"};
    background: ${props => props.$active ? props.$background : "grey"};
    border: 1px solid ${props => props.$active ? props.$borderColor : "grey"};
    cursor: ${props => props.$active ? "pointer" : "not-allowed"};
    font-size: 14px;
    padding: 8px 14px;
    border-radius: 4px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
`

const Button = styled.button`
    display: flex;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid var(--Gray-300, #D0D5DD);
    background: var(--Base-White, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
`

function ColumnPermissions({ key, open, setOpen, colName }: { key: string, open: boolean, colName: string, setOpen: (isOpen: boolean) => void }) {
    const [viewSelectedOptions, setViewSelectedOptions] = React.useState<OptionType[]>([])
    const [editSelectedOptions, setEditSelectedOptions] = React.useState<OptionType[]>([])
    const { selectedEntity } = useEntity();
    const [isLoading, setIsLoading] = React.useState(false)
    const accessPermissions = useAccessPermissions({ resourceType: ResourceTypeConstant.TABLE })

    useEffect(() => {
        if (open) {
            if (!accessPermissions.update_table_acl) {
                notification("error", "You do not have permissison to share the resource")
                return;
            }
            fetchAndSetTablePermissions();
            recordRSEvent(ColumnPermissionsRsEvents.COLUMN_PERMISSIONS_FETCH_BUTTON_CLICKED);
        }
    }, [accessPermissions])

    const handleSave = async () => {
        recordRSEvent(ColumnPermissionsRsEvents.COLUMN_PERMISSIONS_SAVE_BUTTON_CLICKED);
        if (!accessPermissions.update_table_acl) {
            notification("error", "You are not authorized to change access levels for this resource");
            return;
        }

        setIsLoading(true);

        const at = localStorage.getItem('at');
        const decoded: any = at ? jwtDecode(at) : null;

        // Initialize the structure to collect IDs based on the type
        let readPermissions: { roleIds: string[], teamIds: string[], userIds: string[] } = { roleIds: [], teamIds: [], userIds: [] };

        // Process viewSelectedOptions for read permissions
        viewSelectedOptions.forEach((option) => {
            if (option.type === 'role') readPermissions.roleIds.push(option.value);
            else if (option.type === 'team') readPermissions.teamIds.push(option.value);
            else if (option.type === 'user') readPermissions.userIds.push(option.value);
        },);

        //Adding admin for the case when viewSelected Options is not empty but is not having admin role.

        if (viewSelectedOptions.length > 0 && !readPermissions.roleIds.includes("admin")) {
            readPermissions.roleIds.push("admin");
        }

        let writePermissions: { roleIds: string[], teamIds: string[], userIds: string[] } = { roleIds: [], teamIds: [], userIds: [] };

        // Process editSelectedOptions for write permissions
        editSelectedOptions.forEach((option) => {
            if (option.type === 'role') writePermissions.roleIds.push(option.value);
            else if (option.type === 'team') writePermissions.teamIds.push(option.value);
            else if (option.type === 'user') writePermissions.userIds.push(option.value);
        });

        //Adding admin for the case when editSelected Options is not empty but is not having admin role.

        if (editSelectedOptions.length > 0 && !writePermissions.roleIds.includes("admin")) {
            writePermissions.roleIds.push("admin");
        }

        const payload = {
            tableType: selectedEntity?.tableType,
            accountId: decoded?.accountId,
            columnPermissions: [{
                [colName]: {
                    "read": {
                        "and": {
                            "roleIds": [],
                            "teamIds": [],
                            "userIds": []
                        },
                        "or": readPermissions
                    },
                    "write": {
                        "and": {
                            "roleIds": [],
                            "teamIds": [],
                            "userIds": []
                        },
                        "or": writePermissions
                    }
                }
            }]
        };

        try {
            const res = await EntityService.updateEntityPermissions(payload);
            recordRSEvent(ColumnPermissionsRsEvents.COLUMN_PERMISSIONS_SAVE_SUCCESS, {
                colName: colName,
                viewSelectedOptions: viewSelectedOptions,
                editSelectedOptions: editSelectedOptions,
                response: res,
            });
            notification("success", "Permissions updated successfully");
            setOpen(false);
        } catch (err) {
            console.error("Failed to save column permissions", err);
            recordRSEvent(ColumnPermissionsRsEvents.COLUMN_PERMISSIONS_SAVE_FAILURE, {
                colName: colName,
                viewSelectedOptions: viewSelectedOptions,
                editSelectedOptions: editSelectedOptions,
                error: err,
            });
            notification("error", "Failed to save column permissions");
        } finally {
            setIsLoading(false);
        }
    };

    const fetchAndSetTablePermissions = async () => {
        setIsLoading(true);
        try {
            const response = await EntityService.getEntityPermissions(selectedEntity?.tableType || "");
            const columnPermissions = response?.result?.data?.columnPermissions;
            if (columnPermissions && colName) {
                const currentColumnPermissions = columnPermissions[colName];

                let viewPermissions: OptionType[] = [];
                let editPermissions: OptionType[] = [];

                if (currentColumnPermissions && currentColumnPermissions.read) {
                    viewPermissions = transformPermissions(currentColumnPermissions.read);
                }

                if (currentColumnPermissions && currentColumnPermissions.write) {
                    editPermissions = transformPermissions(currentColumnPermissions.write);
                }

                recordRSEvent(ColumnPermissionsRsEvents.COLUMN_PERMISSIONS_FETCH_SUCCESS, {
                    permissions: columnPermissions,
                    colName: colName,
                    usersForView: viewPermissions,
                    usersForEdit: editPermissions,
                    ...response
                });

                setViewSelectedOptions(viewPermissions);
                setEditSelectedOptions(editPermissions);
            }
        } catch (error) {
            console.error("Failed to fetch table permissions", error);
            recordRSEvent(ColumnPermissionsRsEvents.COLUMN_PERMISSIONS_FETCH_FAILURE, {
                colName: colName,
                error: error,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const transformPermissions = (permissions: any) => {
        let options: OptionType[] = [];

        ['and', 'or'].forEach((condition) => {
            ['roleIds', 'teamIds', 'userIds'].forEach((type) => {
                const entities = permissions[condition][type];
                if (entities) {
                    entities.forEach((entity: any) => {
                        options.push({
                            value: entity.id,
                            label: entity.name,
                            type: type === 'roleIds' ? 'role' : (type === 'teamIds' ? 'team' : 'user')
                        });
                    });
                }
            });
        });

        return options.filter((option, index, self) =>
            index === self.findIndex((t) => t.value === option.value && t.type === option.type)
        );
    };

    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {open && !isLoading && <ModalOverlay>
                <ModalContainer>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: "24px" }}>
                        <HeaderContainer>
                            <Heading>
                                <Text $color="var(--Gray-900, #101828)" $fontSize="18px" $fontWeight="600">
                                    Manage Column Permissions for {colName}
                                </Text>
                                <Text $color="var(--Gray-500, #667085)" $fontSize="14px" $fontWeight="400">
                                    Select who can view and edit
                                </Text>
                            </Heading>
                            <CloseIcon fontSize={24} color={"#667085"} onClick={() => {
                                recordRSEvent(ColumnPermissionsRsEvents.COLUMN_PERMISSION_CLOSE_MODAL_BUTTON_CLICKED);
                                setOpen(false)
                            }} />
                        </HeaderContainer>
                        <InputContainer>
                            <Text $color="#344054" $fontSize="12px" $fontWeight="400">
                                Who can view:
                            </Text>
                            <SearchInputContainer placeholder='All teams, all roles and all users' context='update' onChangeSelect={setViewSelectedOptions} selectedOptions={viewSelectedOptions} />
                        </InputContainer>
                        <InputContainer>
                            <Text $color="#344054" $fontSize="12px" $fontWeight="400">
                                Who can edit:
                            </Text>
                            <SearchInputContainer placeholder='All teams, all roles and all users' context='update' onChangeSelect={setEditSelectedOptions} selectedOptions={editSelectedOptions} />
                        </InputContainer>
                    </div>
                    <ButtonContainer>
                        <Button onClick={() => {
                            recordRSEvent(ColumnPermissionsRsEvents.COLUMN_PERMISSIONS_CANCEL_BUTTON_CLICKED);
                            setOpen(false)
                        }}>
                            Cancel
                        </Button>
                        <SaveButton $active={!isLoading && (viewSelectedOptions.length > 0 || editSelectedOptions.length > 0)} disabled={isLoading || (viewSelectedOptions.length === 0 && editSelectedOptions.length === 0)} onClick={handleSave} $color="#fff" $background="#3C69E7" $borderColor="#3C69E7">
                            {isLoading ? "Saving..." : "Save"}
                        </SaveButton>
                    </ButtonContainer>
                </ModalContainer>
            </ModalOverlay>}
        </div>
    )
}

export default ColumnPermissions
