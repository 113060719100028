import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Button } from "views/DataField/DataField.styles";
import useWorkflow from "views/workflows/hooks/useWorkFlow";
import { updateSwitchName } from "views/workflows/reducers/workflowAction";
import {
  ButtonContainer,
  Container,
  Heading,
  Input,
  InputContainer,
  Label,
} from "views/workflows/workflowactions/ModalContent.styles";


type SwitchNameEditProps = {
  setShowSwitchNameEditModal: (state: boolean) => void;
};

const SwitchNameEdit = ({
  setShowSwitchNameEditModal,
}: SwitchNameEditProps) => {
  const { selectedDecisionNode } = useWorkflow();
  const dispatch = useDispatch();

  const { register, handleSubmit } = useForm();

  const onFormSubmit = (data: any) => {
    const { newName } = data;
    dispatch(
      updateSwitchName({
        nodeId: selectedDecisionNode.nodeId,
        switchId: selectedDecisionNode.id,
        newName,
      })
    );

    setShowSwitchNameEditModal(false);
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Container>
        <Heading> Update switch name </Heading>
        <Label> Switch Name </Label>
        <InputContainer>
          <Input
            defaultValue={selectedDecisionNode?.data?.name}
            {...register("newName")}
          />
        </InputContainer>

        <ButtonContainer>
          <Button
            onClickCapture={() => setShowSwitchNameEditModal(false)}
            type="button"
            variant="secondary"
          >
            {" "}
            Cancel{" "}
          </Button>
          <Button type="submit" variant="primary">
            {" "}
            Save{" "}
          </Button>
        </ButtonContainer>
      </Container>
    </form>
  );
};

export default SwitchNameEdit;
