type ErrorMessageProps = {
    errors:any;
    fieldName:string
    display?: string
}
/**
 * 
 * @param errors errors object from React hook form
 * @param fieldName name of the field for which the error message needs to be shown 
 * @returns responsible for showing an error message.
 */

const ErrorMessage = ({errors,fieldName,display}:ErrorMessageProps) => {

  const style = {
    display: display ? "block" : "",
  }
    
  return (
      <>
        <span  style={style} className="text-red-500 text-xs"> {errors[fieldName]?.message}</span>
      </>
  )
}

export default ErrorMessage