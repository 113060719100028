//@ts-nocheck
import { PageCategoryEnum, recordPageVisit } from 'config/Rudderstack';
import { ActionToPerform, TEXT } from 'constants/CommonConstants';
import { RudderStackAutomationBuilderContext, RudderStackAutomationBuilderEvents } from 'constants/RudderStackConstants';
import { cloneDeep } from 'lodash';
import { ZJSEditorLayout } from "lowcode/ZJSEditorLayout";
import { useJSEditor } from 'lowcode/hooks/useJSEditor';
import { EditorScriptLocation } from 'lowcode/state/types/types';
import getInputType from "lowcode/util/getInputType";
import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { recordRSEvent, removeBraces, stopPropagate } from "utils/CommonUtils";
import { getLowCodeEditorDefaultReturnText, getLowCodeEditorText } from 'utils/Utils';
import { DataFieldType } from 'views/DataField/DataField.types';
import useWorkflow from "views/workflows/hooks/useWorkFlow";
import { setSelectedAction } from 'views/workflows/reducers/workflowAction';
import DatafieldSelector from "views/workflows/workflowactions/DatafieldSelector";
import {
    Heading, HeadingWrapper, Label
} from "views/workflows/workflowactions/ModalContent.styles";
import IsActive from "views/workflows/workflowactions/api/components/IsActive";
import ModalSmartInputTextField from "views/workflows/workflowactions/api/components/ModalSmartInputTextField";
import { ValidLocation, saveCondition, generateTempId, isTempIdUnique, IIsTempIdUniqueContext, getActionNameForTempId } from "views/workflows/workflowactions/api/utils/utils";
import InputTempId from "views/automationBuilder/components/InputTempId";
import {
    ABSelectWrapper,
    LabelWrapper,
    Text
} from "../automationBuilder.styles";
import useAutomation from '../hooks/useAutomation';
import usePayload from '../hooks/usePayload';
import { setActionPayload } from '../reducer/automationReducer';
import { conditionTypes } from '../types/ABType';
import ABSelect from './AppComponents/ABSelect';
const ABConditionComponent = () => {
    const optionLabel = (option: any) => option.name;
    const optionId = (option: any) => option.id;

    const { selectedAction, selectedTransition, selectedDecisionNode, selectedSystemTransition, getScriptValue, dataPicker } = useWorkflow();
    const dispatch = useDispatch();
    const { openJSEditorLayout, scriptEditorOpen } = useJSEditor();
    const { actionDropdown, conditions, selectedActionBlock, selectedAutomation, metadataType, clearRightSide } = useAutomation();

    const [valueDataType, setValueDataType] = useState<DataFieldType>();
    const [showOperatorDropdown, setShowOperatorDropdown] = useState([]);
    const [operatorSelectedValue, setOperatorSelectedValue] = useState({});
    const [showValue, setShowValue] = useState(selectedAction?.actionToPerform == ActionToPerform.EDIT ? true : false);
    const [isEditing, setIsEditing] = useState(false);
    const [tempIdChanged, setTempIdChanged] = useState(true);
    const [currTempIdVal, setCurrTempIdVal] = useState(selectedAction?.tempId || "");

    useEffect(() => {
        if (selectedAction?.componentData && actionDropdown?.length > 0) {
            const operatorArray = getDefaultFunction(selectedAction?.componentData.length - 1)?.operators;

            // setting data Type | NUMBER | TEXT | BOOLEAN | DATE
            const dataType = getDefaultFunction(selectedAction?.componentData.length - 1)?.dataType;

            if (dataType && Object.keys(DataFieldType).includes(dataType)) {
                setValueDataType(dataType);
            }

            if (operatorArray) {
                const operatorSelectedValue = operatorArray.find((operatorData) => operatorData.id == selectedAction.operator);
                setOperatorSelectedValue(operatorSelectedValue);
                setShowOperatorDropdown(operatorArray);
            }
        }
    }, [actionDropdown])

    useEffect(() => {
        recordPageVisit(PageCategoryEnum.automationBuilder, RudderStackAutomationBuilderEvents.smartComponentConditionOnLoad);
    }, [])

    useEffect(() => {
        if (selectedAction?.componentData) {
            dispatch(setActionPayload(selectedAction?.componentData));
        }
    }, [])

    const getDefaultFunction = (index: number) => {
        const value = getArrayToIterate(index)?.find((data: any) => data.id == getSelectedValue(index));
        // if the value is undefined, sometime the  value in dropdown is not clearing, so we are sending {} - empty object when value is undefined
        return value || {};
    }

    const getSelectedValue = (index: number) => {
        return actionDropdown?.[index]
    }

    const getArrayToIterate = (index: number) => {
        const smartComponentCondition = conditions.find((conditionData) => conditionData.conditionType == conditionTypes.SCREEN_COMPONENT);
        if (index == 0) {
            return smartComponentCondition?.struct;
        } else {
            const dropDownOptionsArray = [];
            dropDownOptionsArray.push(smartComponentCondition?.struct);
            for (let loopIndex = 0; loopIndex < actionDropdown.length; loopIndex++) {
                const dropDownArrayData = dropDownOptionsArray[loopIndex]?.find((data: any) => data.id == actionDropdown?.[loopIndex]);
                dropDownOptionsArray.push(dropDownArrayData?.struct);
                if (loopIndex == index) {
                    break;
                }
            }
            return dropDownOptionsArray[index];
        }
    }

    const handleValueChange = (e) => {
        if (valueDataType === DataFieldType.LIST_OF_TEXT) {
            dispatch(
                setSelectedAction({
                    ...selectedAction,
                    hasChanged: true,
                    value: {
                        type: getInputType(e.target.value),
                        value: [],
                        dataType: valueDataType,
                    }
                }
                ));
        } else {
            dispatch(
                setSelectedAction({
                    ...selectedAction,
                    hasChanged: true,
                    value: {
                        type: getInputType(e.target.value),
                        value: removeBraces(e.target.value),
                        dataType: valueDataType,
                    }
                }
                ));
        }

    };

    const getDefaultValue = (dataType: DataFieldType) => {
        switch (dataType) {
            case DataFieldType.LIST_OF_TEXT:
                return []
            // case DataFieldType.DATETIME:
            //     return new Date().toISOString();
            default:
                return []
        }
    }

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        setValue,
        control,
        reset,
        getValues,
        clearErrors
    } = useForm({
        defaultValues: {
            isActive: selectedAction?.isActive ?? true,
            value: selectedAction?.value?.value,
            conditionName: selectedAction?.conditionName,
            tempId: generateTempId(selectedAction, selectedAutomation, getActionNameForTempId(selectedAction), "condition"),
        }
    });
    const { actionConditionPayload } = usePayload();

    const onFormSubmit = (data) => {

        let tempActionId: string = selectedAction?.tempId || data?.tempId;
        if (!isEditing) {
            tempActionId = currTempIdVal.length === 0 ? (selectedAction?.actionId || selectedAction?.guardId || selectedAction?.conditionId) : currTempIdVal;
            dispatch(setSelectedAction({ ...selectedAction, tempId: currTempIdVal }));
        }

        let isTempIdChanged = tempIdChanged;
        if (tempActionId === (selectedAction?.actionId || selectedAction?.guardId || selectedAction?.conditionId)) {
            setTempIdChanged(false);
            isTempIdChanged = false;
        }

        if (isTempIdChanged && !isTempIdUnique(tempActionId, selectedAutomation)) {
            setError("tempId", {
                type: "manual",
                message: "This ID already exists. Please choose a different one.",
            });
            return;
        }
        // validation for Condition Name
        if (!data.conditionName) {
            setError("conditionName", {
                type: "manual",
                message: "This is a required field",
            });
            return;
        }

        if (data.conditionName.length <= 3) {
            setError("conditionName", {
                type: "manual",
                message: "Name must be greater than 3 characters",
            });
            return;
        }

        if (!selectedAction?.value?.value || (valueDataType == DataFieldType.LIST_OF_TEXT && selectedAction?.value?.value.length == 0)) {
            setError("value", {
                type: "manual",
                message: "This is a required field",
            });
            return;
        }

        recordRSEvent(RudderStackAutomationBuilderEvents.smartComponentConditionSaveClick, {
            context: RudderStackAutomationBuilderContext.automationBuilderCondition,
            automationId: selectedAutomation.id,
            conditionType: selectedAction.conditionType,
            conditionId: selectedAction.conditionId
        })

        const payload = {
            conditionId: tempActionId,
            tempId: tempActionId,
            conditionName: data.conditionName,
            conditionType: selectedAction.conditionType,
            isActive: selectedAction?.isActive ?? true,
            componentData: actionDropdown,
            operator: operatorSelectedValue.id,
            value: selectedAction?.value,
            metadataType
        }

        saveCondition({
            payload,
            location: ValidLocation.AUTOMATION,
            dispatch,
            selectedAction,
            selectedActionBlock,
            selectedAutomation
        });

        clearRightSide();
    }


    const valueScriptConfig = {
        MessageScriptConfig: {
            script: getScriptValue(EditorScriptLocation.AB_SMART_CONDITION_VALUE) || getLowCodeEditorText(EditorScriptLocation.AB_SMART_CONDITION_VALUE, valueDataType, `return ${getLowCodeEditorDefaultReturnText(valueDataType)}`),
            dataType: valueDataType,
        },
    };

    const handleTempActionValChange = (name, value) => {
        setCurrTempIdVal(value);
    }

    const handleEditClick = () => {
        setIsEditing(true);
        setTempIdChanged(true);
    };

    const handleTickClick = () => {
        setIsEditing(false);
    };

    const setTempIdError = (error) => {
        if (error) {
            setError("tempId", { type: "manual", message: error });
        } else {
            clearErrors("tempId");
        }
    };


    return (
        <>
            <form onSubmit={stopPropagate(handleSubmit(onFormSubmit))}>
                <HeadingWrapper>
                    <Heading> Configure Condition </Heading>
                    <IsActive control={control} closeOnClick={clearRightSide} />
                </HeadingWrapper>
                <Text>
                    {" "}
                    Conditions are checks that need to pass to allow the automation to proceed after the transition is triggered
                </Text>

                <LabelWrapper>
                    <Label> Name the Condition</Label>
                </LabelWrapper>

                <ModalSmartInputTextField
                    dataType={DataFieldType.TEXT}
                    isSmartField={false}
                    value={selectedAction?.conditionName}
                    placeholder="Describe the Condition Name Here"
                    register={register}
                    control={control}
                    errors={errors}
                    name="conditionName"
                />

                <InputTempId
                    label="Condition Id"
                    requiredErrorMessage="Condition Id is required"
                    onChange={handleTempActionValChange}
                    register={register}
                    errors={errors}
                    isReadOnly={!isEditing}
                    handleTickClick={handleTickClick}
                    handleEditClick={handleEditClick}
                    getValues={getValues}
                    setTempIdError={setTempIdError}
                    isEditing={isEditing}
                    name="tempId"
                />

                <ABSelectWrapper>
                    <ABSelect
                        options={conditions.find((conditionData) => conditionData.conditionType == conditionTypes.SCREEN_COMPONENT)?.struct}
                        optionLabel={optionLabel}
                        optionId={optionId}
                        placeHolder={"Select Component"}
                        errors={Object.keys(getDefaultFunction(0))?.length > 0 ? {} : errors}
                        errorMessageName={"value"}
                        label={"Select Component"}
                        defaultValue={getDefaultFunction(0)}
                        onClickFunction={(optionValue) => {
                            clearErrors('value');

                            if (optionValue) {
                                dispatch(setActionPayload([optionValue.id]));
                            }
                            else {
                                dispatch(setActionPayload([]));
                            }


                            setShowOperatorDropdown([]);
                            setOperatorSelectedValue({});

                            setShowValue(false);
                            // clearing value field
                            dispatch(
                                setSelectedAction({
                                    ...selectedAction,
                                    hasChanged: true,
                                    value: {}
                                }
                                ));
                        }}
                    />
                </ABSelectWrapper>

                {actionDropdown?.length > 0 &&
                    actionDropdown.map((actionDropdownData: any, index: number) => {
                        return (
                            <>
                                {
                                    getDefaultFunction(index)?.struct?.length > 0 &&
                                    <>
                                        <ABSelectWrapper>
                                            <ABSelect
                                                options={getDefaultFunction(index)?.struct}
                                                optionLabel={optionLabel}
                                                optionId={optionId}
                                                errors={Object.keys(getDefaultFunction(index + 1)).length > 0 ? {} : errors}
                                                errorMessageName={"value"}
                                                placeHolder={getDefaultFunction(index)?.label}
                                                label={getDefaultFunction(index)?.label}
                                                defaultValue={getDefaultFunction(index + 1)}
                                                onClickFunction={(optionValue: any) => {
                                                    clearErrors('value');

                                                    if (optionValue) {
                                                        if (actionDropdown.length === index + 1) {
                                                            // Newly selecting in dropdown value
                                                            dispatch(setActionPayload([...actionDropdown, optionValue?.id]));
                                                        } else {
                                                            // when already selected value is changed
                                                            // updating the array and remove the balance elements
                                                            const tmpData = cloneDeep(actionDropdown);
                                                            tmpData[index + 1] = optionValue?.id
                                                            tmpData.splice(index + 2);
                                                            dispatch(setActionPayload(tmpData));

                                                            // Hide textfield when value in middle is changed and value is not hidden if the last dropdown value is changed
                                                            // if (optionValue?.struct) {
                                                            // making value textfield hidden
                                                            setShowOperatorDropdown([]);
                                                            setOperatorSelectedValue({});
                                                            // }
                                                        }

                                                        // show value textfield, this is for the last dropdown scenario.
                                                        if (optionValue?.operators?.length > 0) {

                                                            if (optionValue.dataType) {
                                                                setValueDataType(optionValue?.dataType || TEXT);
                                                            }

                                                            if (optionValue?.operators) {
                                                                setShowOperatorDropdown(optionValue?.operators);
                                                                setOperatorSelectedValue({});
                                                            }
                                                        }

                                                    } else {
                                                        // When clear button in dropdown is pressed
                                                        const tmpData = cloneDeep(actionDropdown);
                                                        tmpData.splice(index + 1);
                                                        dispatch(setActionPayload(tmpData));
                                                    }

                                                    // clearing value field
                                                    setValue(EditorScriptLocation.AB_SMART_CONDITION_VALUE, getDefaultValue(optionValue?.dataType));

                                                    dispatch(
                                                        setSelectedAction({
                                                            ...selectedAction,
                                                            hasChanged: true,
                                                            value: {}
                                                        }
                                                        ));
                                                    setShowValue(false);
                                                }}
                                            />
                                        </ABSelectWrapper>
                                    </>
                                }
                            </>
                        )
                    })
                }

                {
                    showOperatorDropdown.length > 0 &&
                    (
                        <>
                            <ABSelectWrapper>
                                <ABSelect
                                    options={showOperatorDropdown}
                                    optionLabel={optionLabel}
                                    optionId={optionId}
                                    placeHolder={"Condition Operator"}
                                    label={"Condition Operator"}
                                    errors={(operatorSelectedValue && Object.keys(operatorSelectedValue)?.length) > 0 ? {} : errors}
                                    errorMessageName={"value"}
                                    defaultValue={operatorSelectedValue}
                                    onClickFunction={(operatorValue) => {
                                        clearErrors('value');

                                        setShowValue(true);
                                        // clearing value field

                                        if (valueDataType === DataFieldType.DATETIME) {
                                            dispatch(
                                                setSelectedAction({
                                                    ...selectedAction,
                                                    hasChanged: true,
                                                    value: {
                                                        type:"literal",
                                                        value: getDefaultValue(valueDataType || ''),
                                                        dataType: valueDataType
                                                    }
                                                }
                                                ));

                                        } else {

                                            dispatch(
                                                setSelectedAction({
                                                    ...selectedAction,
                                                    hasChanged: true,
                                                    value: {}
                                                }
                                                ));

                                        }

                                        setOperatorSelectedValue(operatorValue);
                                    }}
                                />
                            </ABSelectWrapper>
                        </>
                    )
                }

                {
                    (showValue && valueDataType) &&
                    (
                        <>
                            <LabelWrapper>
                                <Label> Value </Label>
                            </LabelWrapper>
                            <ModalSmartInputTextField
                                type={selectedAction?.value?.type}
                                dataType={valueDataType}
                                isSmartField={true}
                                control={control}
                                step="any"
                                getValues={getValues}
                                value={selectedAction?.value?.value}
                                placeholder="Enter ${ to Select Dynamic value"
                                register={register}
                                name="value"
                                clearErrors={clearErrors}
                                getValues={getValues}
                                setError={setError}
                                errors={errors}
                                apiBodyPayload={actionConditionPayload}
                                onChangeFunc={handleValueChange}
                                config={valueScriptConfig.MessageScriptConfig}
                                editorLocation={EditorScriptLocation.AB_SMART_CONDITION_VALUE}
                                openJSEditor={openJSEditorLayout}
                            />
                        </>
                    )
                }

                {scriptEditorOpen && <ZJSEditorLayout />}
                { dataPicker?.isOpened && <DatafieldSelector setValue={setValue} />}
            </form>

        </>
    )
}

export default ABConditionComponent