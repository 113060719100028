import { SelectType, Label } from "../DataField.styles";
import { DataFieldType } from "../DataField.types";
import useWorkflow from "views/workflows/hooks/useWorkFlow";
import { SelectChangeEvent } from "@mui/material/Select";

type SelectDatafieldDropdownType = {
  dataFieldType: string;
  handleSelect: (event: SelectChangeEvent) => void;
};

const SelectDatafieldDropdown = ({
  dataFieldType,
  handleSelect,
}: SelectDatafieldDropdownType) => {
  const { selectedNode, showTaskCardConfig } = useWorkflow();
  return (
    <>
      <Label> Type </Label>
      <SelectType
        value={dataFieldType}
        placeholder="Select Data Field Type"
        disabled={selectedNode || showTaskCardConfig?.show ? true : false}
        // @ts-ignore
        onChange={handleSelect}
      >
        <option value={DataFieldType.NUMBER}>Number</option>
        <option value={DataFieldType.TEXT}>Text</option>
        <option value={DataFieldType.LOCATION}>Location</option>
        <option value={DataFieldType.BOOLEAN}>Boolean</option>
        <option value={DataFieldType.DATETIME}>Datetime</option>
        <option value={DataFieldType.ONLY_DATE}>Date</option>
        <option value={DataFieldType.TIME}>Time</option>
        <option value={DataFieldType.LIST_OF_TEXT}>List of Text</option>
        <option value={DataFieldType.COLLECTION}>List</option>
        <option value={DataFieldType.IMAGE}>Image</option>
        <option value={DataFieldType.SIGNATURE}>Signature</option>
        <option value={DataFieldType.FILE}>File</option>
        <option value={DataFieldType.LINKS}>Links</option>
        <option value={DataFieldType.COMMENT}>Comments</option>
      </SelectType>
    </>
  );
};

export default SelectDatafieldDropdown;
