import { useSelector } from 'react-redux';

type Props = {
  name ?: string
}

const useTaskCreation = ({name=""}:Props) => { 
  const {taskCreation} = useSelector((state:any) => state)

  const taskType = taskCreation.taskType
  const teamId = taskCreation?.teamIds
  const metaData = taskCreation?.metaData
  const createViewAlreadyCreatedItems = useSelector((state: any) => taskCreation[name])
  const userId = taskCreation?.userId



  return {
    taskType,
    teamId,
    metaData,
    createViewAlreadyCreatedItems,
    userId
  };
};

export default useTaskCreation;
