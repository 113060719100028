import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import FilterModalClose from "assests/FilterModalClose.svg";
import { defaultDatasourceEdit } from "constants/MonitorConstant";
import { RudderStackAutomationBuilderEvents } from "constants/RudderStackConstants";
import FilterErrorMessage from "filter/components/FilterErrorMessage";
import notification from "notifications/notifications";
import { IDataSourcePayload, MonitorService } from "providers/data/services/MonitorService";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AiFillWarning } from "react-icons/ai";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { recordRSEvent } from "utils/CommonUtils";
import ZSwitch from "views/commonComponents/ZSwitch";
import {
  HeaderContentWrapper
} from "../workflows/workflowactions/api/APIAction/ImportCurl/CurlStyledComponents";
import { LabelText, MonitorTextField } from "./Monitor.styles";
import MonitorInterfaceSelect from "./MonitorInterfaceComponents/MonitorInterfaceSelect";
import {
  IDatasourceCategory,
  IScreenContext,
  intDatasource,
  setDatasourceModalContext
} from "./reducer/MonitorReducer";
import useMonitor from "./reducer/useMonitor";

export type ISelectValueType = {
  label: string;
  value: string;
};

const datasourceListArray: ISelectValueType[] = [
  {
    label: "PostgreSQL",
    value: "postgres",
  },
  {
    label: "MYSQL",
    value: "mysql",
  },
  {
    label: "Clickhouse",
    value: "clickhouse",
  },
];

const ImportCurlHeading = styled.p`
  color: #101828;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-top: 16px;
`;

const CurlFooterRightWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 24px 24px 24px 24px;
  justify-content: flex-end;
  gap: 12px;
`;

interface ICircle {
  color: string;
}

const Circle = styled.div`
  width: 6px;
  height: 6px;
  margin-right: 5px;
  background-color: ${(props: ICircle) => `${props.color}`};
  border-radius: 50%; /* This makes the div circular */
`;

const CurlModalFooter = styled.div`
  justify-content: space-between;
  display: flex;
  width: 100%;
`;

const TemplateModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

const CurlModalContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: ${(props: { height?: string; width?: string }) =>
    props?.width ? props.width : "560px"};
  background: #ffffff;
  box-shadow: 0px 10px 14px -2px rgba(16, 24, 40, 0.01),
    0px 4px 4px -2px rgba(16, 24, 40, 0.01);
  position: absolute;
  border-radius: 4px;
`;

const CurlModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: #ffffff;
  width: 100%;
  padding: 24px 24px 0px 24px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 24px 0px 24px;
  width: 100%;
  margin-top: 16px;
`;

const DatasourceModal = () => {
  const dispatch = useDispatch();
  const { datasourceModalContext } = useMonitor();
  const [disableSaveBtn, setDisableSaveBtn] = useState<boolean>(false);
  const [disableTestConnectionBtn, setDisableTestConnectionBtn] = useState<boolean>(false);
  const [datasourceErrorMessage, setDatasourceErrorMessage] = useState<string>("");
  const [isDatasourceConnectionSuccess, setIsDatasourceConnectionSuccess] = useState<boolean>(false);
  const isDatasourceInEditMode = datasourceModalContext?.datasourceContext == IScreenContext.EDIT;
  const datasourceEditData = datasourceModalContext?.contextData;
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
    trigger
  } = useForm({
    defaultValues: {
      displayName: isDatasourceInEditMode ? datasourceEditData?.displayName : "",
      datasourceType: isDatasourceInEditMode ? datasourceListArray.find((data) => data.value == datasourceEditData?.datasourceType) : undefined,
      host: isDatasourceInEditMode ? datasourceEditData?.host : "",
      port: isDatasourceInEditMode ? datasourceEditData?.port : "",
      name: isDatasourceInEditMode ? datasourceEditData?.name : "",
      username: isDatasourceInEditMode ? datasourceEditData?.username : "",
      password: isDatasourceInEditMode ? datasourceEditData?.password : "",
      ssl: isDatasourceInEditMode ? datasourceEditData?.ssl : "",
    },
  });

  const handleModalClose = () => {
    dispatch(setDatasourceModalContext(undefined));
  };

  const submitForm = async (data: any) => {
    setDisableSaveBtn(true);
    setDisableTestConnectionBtn(true);

    let addDatasourcePayload: IDataSourcePayload = {
      datasourceType: data.datasourceType.value,
      displayName: data.displayName,
      databaseVersion: "",
      name: data.name,
      host: data.host,
      port: data.port,
      username: data.username,
      password: data.password,
      ssl: data.ssl || true,
      datasourceCategory: IDatasourceCategory.USER_ADDED_DATASOURCE
    }

    if (datasourceModalContext?.datasourceContext == IScreenContext.CREATE) {
      recordRSEvent(RudderStackAutomationBuilderEvents.addDatasource, {
        context: RudderStackAutomationBuilderEvents.monitorContext,
        ...addDatasourcePayload
      });

      const addDatasource = await MonitorService.addDatasource(addDatasourcePayload)
      setDisableSaveBtn(false);
      setDisableTestConnectionBtn(false);
      if (addDatasource?.code == "200") {
        handleModalClose();
        
        notification('success', 'Datasource Added successfully');
      } else {
        setDatasourceErrorMessage(addDatasource?.message || 'Error in adding datasource');
      }
    } else if (datasourceModalContext?.datasourceContext == IScreenContext.EDIT) {
      recordRSEvent(RudderStackAutomationBuilderEvents.editDatasource, {
        context: RudderStackAutomationBuilderEvents.monitorContext,
        ...addDatasourcePayload
      });
      addDatasourcePayload.password = addDatasourcePayload.password == defaultDatasourceEdit ? undefined : addDatasourcePayload.password;
      const editDatasource = await MonitorService.updateDatasource(addDatasourcePayload, datasourceEditData?.datasourceId as string)
      setDisableSaveBtn(false);
      setDisableTestConnectionBtn(false);
      if (editDatasource?.code == "200") {
        handleModalClose();
        notification('success', 'Datasource updated successfully');
      } else {
        setDatasourceErrorMessage(editDatasource?.message || 'Error in adding datasource');
      }
    }

    dispatch(intDatasource())
  }

  const testDatasource = async () => {
    const data = watch();

    const formValidation = await trigger(["displayName", "datasourceType", "host", "port", "name", "username", "password"])

    if (formValidation) {
      const datasourcePayload: IDataSourcePayload = {
        datasourceType: data.datasourceType?.value as string,
        displayName: data.displayName as string,
        databaseVersion: "",
        name: data.name as string,
        host: data.host as string,
        port: data.port as number,
        username: data.username as string,
        password: data.password as string,
        ssl: (data.ssl || true) as boolean,
        datasourceCategory: IDatasourceCategory.USER_ADDED_DATASOURCE
      }

      const testDatasourceConnection = await MonitorService.testDatasourceConnection(datasourcePayload)
      setDisableSaveBtn(false);
      setDisableTestConnectionBtn(false);
      if (testDatasourceConnection?.code == "200") {
        setIsDatasourceConnectionSuccess(true)
        setDatasourceErrorMessage(testDatasourceConnection?.message || 'Datasource connection successful');
      } else {
        setIsDatasourceConnectionSuccess(false)
        setDatasourceErrorMessage(testDatasourceConnection?.message || 'Error in Testing datasource connection');
      }
    }
  }

  return (
    <TemplateModal
      open={!!datasourceModalContext?.datasourceContext}
      onClose={handleModalClose}
    >
      <CurlModalContainer>
        <form onSubmit={handleSubmit(submitForm)} style={{ width: '100%', height: '100%' }}>

          <CurlModalHeader>
            <HeaderContentWrapper>
              <ImportCurlHeading>{datasourceModalContext?.datasourceContext == IScreenContext.CREATE ? 'Add Datasource' : 'Edit Datasource'}</ImportCurlHeading>
              <div>
                <img
                  src={FilterModalClose}
                  className="cursor-pointer"
                  onClick={handleModalClose}
                />
              </div>
            </HeaderContentWrapper>
          </CurlModalHeader>

          {/* Change cron expression here */}
          <TextWrapper style={{ paddingBottom: "0px" }}>
            <LabelText>Datasource Type</LabelText>
            <div
              style={{
                width: "100%",
              }}
            >
             <MonitorInterfaceSelect
                control={control}
                name={"datasourceType"}
                isDisabled={false}
                isLoading={false}
                errors={errors}
                options={datasourceListArray}
                placeholder='select datasource type'
                rules={{ required: "Data source type is required" }}
                onChange={(datasourceValue: any) => {
                  setValue("datasourceType", datasourceValue);
                }}
              />
            </div>

          </TextWrapper>

          <TextWrapper>
            <LabelText>Display Name</LabelText>
            <Controller
              name={'displayName'}
              control={control}
              rules={{ required: "Display Name field is requried" }}
              render={({
                fieldState: { error },
              }) => (
                <MonitorTextField
                  placeholder='Name this datasource'
                    error={!!error}
                    variant="outlined"
                    {...register("displayName", {
                      required: "Display Name field is requried",
                    })}
                  />
                )}
              />
              <FilterErrorMessage errors={errors} name={'displayName'} />
            </TextWrapper>


          <TextWrapper>
            <LabelText>Host</LabelText>
            <Controller
              name={'host'}
              control={control}
              rules={{ required: "Host field is requried" }}
              render={({
                fieldState: { error },
              }) => (
                <MonitorTextField
                  placeholder='Host'
                    error={!!error}
                    variant="outlined"
                    {...register("host", {
                      required: "Host field is requried",
                    })}
                  />
                )}
              />
              <FilterErrorMessage errors={errors} name={'host'} />
          </TextWrapper>


          <TextWrapper>
            <LabelText>Port</LabelText>
            <Controller
              name={'port'}
              control={control}
              rules={{ required: "Port field is requried" }}
              render={({
                fieldState: { error },
              }) => (
                <MonitorTextField
                  type="number"
                  placeholder='Port'
                    error={!!error}
                    variant="outlined"
                    {...register("port", {
                      required: "Port field is requried",
                    })}
                  />
                )}
              />
              <FilterErrorMessage errors={errors} name={'port'} />
          </TextWrapper>


          <TextWrapper>
            <LabelText>Database Name</LabelText>
            <Controller
              name={'name'}
              control={control}
              rules={{ required: "Database Name field is requried" }}
              render={({
                fieldState: { error },
              }) => (
                <MonitorTextField
                  placeholder='Database Name'
                    error={!!error}
                    variant="outlined"
                    {...register("name", {
                      required: "Database Name field is requried",
                    })}
                  />
                )}
              />
              <FilterErrorMessage errors={errors} name={'name'} />
          </TextWrapper>


          <div className="" style={{ display: 'flex', gap: '16px' }}>
            <TextWrapper style={{ paddingRight: '0px' }}>
              <LabelText>Username</LabelText>
              <Controller
                name={'username'}
                control={control}
                rules={{ required: "Username field is requried" }}
                render={({
                  fieldState: { error },
                }) => (
                  <MonitorTextField
                  placeholder='username'
                    error={!!error}
                    variant="outlined"
                    {...register("username", {
                      required: "Username field is requried",
                    })}
                  />
                )}
              />
              <FilterErrorMessage errors={errors} name={'username'} />
            </TextWrapper>

            <TextWrapper style={{ paddingLeft: '0px' }}>
              <LabelText>Password</LabelText>
              <Controller
                name={'password'}
                control={control}
                rules={{ required: "Password field is requried" }}
                render={({
                  fieldState: { error },
                }) => (
                  <MonitorTextField
                  placeholder='Password'
                    error={!!error}
                    variant="outlined"
                    {...register("password", {
                      required: "Password field is requried",
                    })}
                  />
                )}
              />
              <FilterErrorMessage errors={errors} name={'password'} />
            </TextWrapper>

          </div>

          <div style={{ marginLeft: '24px', marginTop: '16px' }}>
            <Controller
              name="ssl"
              control={control}
              render={({ field: { onChange, value } }) => (
                <ZSwitch
                  labelDirection="left"
                  size="small"
                  checked={value as boolean}
                  onChange={onChange}
                  inputProps={{ "aria-label": "controlled" }}
                  label="Enable SSL"
                />
              )}
            />
          </div>
          <CurlModalFooter>
            <CurlFooterRightWrapper>
              <div style={{ width: '40%' }}>

                {datasourceErrorMessage &&
                  <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '24px', marginTop: '10px' }}>

                    {!isDatasourceConnectionSuccess && <AiFillWarning
                      size={"15px"}
                      fill='red'
                      stroke="red"
                      color="red"
                      style={{ display: "inline", marginRight: "4px" }}
                    />
                    }
                    <p style={{ fontSize: '12px', color: `${isDatasourceConnectionSuccess ? 'green' : '#000000'}` }} className="opacity-50 ">{datasourceErrorMessage}</p>
                  </div>
                }
              </div>
              <div style={{ display: 'flex', gap: '12px', width: '60%', justifyContent: 'flex-end' }}>
                {datasourceModalContext?.datasourceContext == IScreenContext.CREATE ?
                  <Button
                    disabled={disableTestConnectionBtn}
                    style={{
                      color: "#344054",
                      fontFamily: "inter",
                      padding: "8px 14px",
                      fontStyle: "normal",
                      fontWeight: 'normal',
                      border: "1px solid #D0D5DD",
                      height: "34px",
                      fontSize: '12px',
                      textTransform: "none",
                    }}
                    onClick={async () => {
                      await testDatasource();
                    }}>
                    <Circle color={isDatasourceConnectionSuccess ? 'green' : 'red'} />
                    Test Connection
                  </Button> : <><div></div></>}

                <Button
                  disabled={disableSaveBtn}
                  disableElevation
                  variant="contained"
                  type="submit"
                  style={{
                    color: "#fff",
                    fontFamily: "inter",
                    padding: "8px 14px",
                    fontStyle: "normal",
                    fontWeight: 'normal',
                    height: "34px",
                    background: "#3C69E7",
                    fontSize: '12px',
                    textTransform: "none",
                  }}
                >
                  {datasourceEditData ? 'Test and Update Datasource' : 'Add Datasource'}
                </Button>
              </div>
            </CurlFooterRightWrapper>
          </CurlModalFooter>

        </form>
      </CurlModalContainer>
    </TemplateModal>
  );
};

export default DatasourceModal;