import { Controller } from 'react-hook-form';
import { ZPhone } from './commonComponents.styles';

const ZPhoneInput = (props: any) => {
  const { name, control, clearErrors, getValues, setValue, secondaryName } = props;

  return (
    <Controller
      render={({ field }) => (
        <ZPhone
          country={'in'}
          // @ts-ignore
          name={name}
          value={getValues(name)}
          onChange={(e, data: any) => {
            clearErrors(name);
            setValue(name, e);
            setValue(secondaryName, data?.dialCode);
          }}
          countryCodeEditable={false}
        />
      )}
      name={name}
      control={control}
      rules={{ required: { value: false, message: `${name} is required` } }}
      defaultValue={getValues(name)}
    />
  );
};

export default ZPhoneInput;
