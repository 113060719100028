import axios, { AxiosError, AxiosResponse } from 'axios';
import HttpUtil from 'config/AxiosConfig';
import { ZorpResponse } from 'providers/data/models/ZorpResponse';
import { resetPasswordPayloadType } from '../models/UserType';
import { RoleService } from './RoleService';

export class UserService {
  public static listUsers(params: any): Promise<ZorpResponse | undefined> {
    const filterData = params.filter;
    let usersSearchPayload: any = {};
    if (filterData.name) usersSearchPayload.name = filterData.name;
    if (filterData.status) usersSearchPayload.status = filterData.status;
    if (filterData.isActive) usersSearchPayload.isActive = filterData.isActive;
    if (filterData.teamIds) usersSearchPayload.teamIds = filterData.teamIds;
    if (filterData.search) usersSearchPayload.search = filterData.search;
    const { page, perPage } = params.pagination;
    let queryParams = 'page=' + (page - 1) + '&size=' + perPage + '&teamdetails=true';
    if (params.location && params.location === true) {
      queryParams = '&location=true';
    }
    return HttpUtil.userInstance
      .post(`/users/search?${queryParams}`, usersSearchPayload)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }

  public static allAcctUsers(search?: string): Promise<ZorpResponse | undefined> {
    return HttpUtil.userInstance
      .get(`/users/account?search=${search ? search : ""}`)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }

  public static getUser(userId: string): Promise<ZorpResponse | undefined> {
    return HttpUtil.userInstance.get(`/user/${userId}`).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static createUser(user: any): Promise<ZorpResponse | undefined> {
    return HttpUtil.userInstance
      .post('/user', user)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((err) => {
        console.log('Error', err);
        return err;
      });
  }

  public static updateStatus(user: any): Promise<ZorpResponse | undefined> {
    return HttpUtil.userInstance.put(`/user/${user.id}/status/${user.status}`, user).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static userSelect(taskId: string | undefined, page: number = 0, size: number = 3000): Promise<ZorpResponse | undefined> {
    return HttpUtil.userInstance
      .get(`/users/assignment?taskId=${taskId}&page=${page}&size=${size}`)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }

  public static userUpdate(taskPayload: any): Promise<ZorpResponse | undefined> {
    return HttpUtil.userInstance.put(`/user/${taskPayload.userId}`, taskPayload).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static changePasswd(taskPayload: any): Promise<ZorpResponse | undefined> {
    return HttpUtil.userInstance.patch('/user/password', taskPayload).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static getRoles(fetchInternalRoles?: boolean): Promise<ZorpResponse | undefined> {
    return RoleService.listRoles(fetchInternalRoles);
  }

  public static login(userId: string, payload: any, headers: any): Promise<ZorpResponse | AxiosError | undefined> {
    payload = {
      ...payload,
      channel: "CC"
    };
    return new Promise((resolve, reject) => {
      HttpUtil.userInstance
        .post(`/user/${userId}/login`, payload, {
          headers: headers,
          validateStatus: function (status) {
            return status >= 200 && status <= 400; // default
          },
        })
        .then((response: AxiosResponse) => {
          return resolve(response.data as ZorpResponse);
        })
        .catch((err: AxiosError) => {
          return reject(err);
        });
    });
  }

  public static loginWithGoogleOAuth(email: string, token: any): Promise<ZorpResponse | AxiosError | undefined> {
    return new Promise((resolve, reject) => {
      const payload = {
        email: email,
        token: token,
        channel: "CC"
      };
      HttpUtil.userInstance
        .post(`/google/login`, payload, {
          validateStatus: function (status) {
            return status >= 200 && status <= 400; // default
          },
        })
        .then((response: AxiosResponse) => {
          return resolve(response.data as ZorpResponse);
        })
        .catch((err: AxiosError) => {
          return reject(err);
        });
    });
  }

  public static deactivateUser(userId: string, isPermanentDelete?: boolean): Promise<ZorpResponse | undefined> {
    return HttpUtil.userInstance.delete(`/user/${userId}?isPermanentDelete=${isPermanentDelete}`).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static userSignup(payload: any): Promise<ZorpResponse | undefined> {
    return HttpUtil.accountInstance.post('/signup', payload).then((response: AxiosResponse | AxiosError) => {
      if (response instanceof AxiosError)
        return response?.response?.data as ZorpResponse;
      else 
        return response.data as ZorpResponse;
    });
  }

  /**
   * Function to call the user logout
   * @param userId 
   * @returns 
   */
  public static logoutUser(userId: string): Promise<ZorpResponse | undefined> {
    return HttpUtil.userInstance.post(`/user/${userId}/logout`, {}).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static updateUserOnboarding({ userId, steps }: { userId: string, steps: any[] }): Promise<ZorpResponse | undefined> {
    return HttpUtil.userInstance.put(`/user/${userId}/onboarding`, {
      steps
    }).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static getUserOnboarding({ userId }: { userId: string }): Promise<ZorpResponse | undefined> {
    return HttpUtil.userInstance.get(`/user/${userId}/onboarding`).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }
  public static generateOTP(userId: string): Promise<ZorpResponse | undefined> {
    return HttpUtil.userInstance.post(`/user/${userId}/forgotpassword/otp`, {}).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    })
  }

  public static validateOTP(userId: string, otpCode: string): Promise<ZorpResponse | undefined> {
    return HttpUtil.userInstance.post(`user/${userId}/forgotpassword/otp/${otpCode}/validate`, { otpCode }).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    })
  }

  public static validateLoginOTP(userId: string, otpCode: string): Promise<ZorpResponse | undefined> {
    return HttpUtil.userInstance.post(`user/${userId}/otp/${otpCode}/validate`, { otpCode }).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    })
  }

  public static resetPassword(userId: string, payLoad: resetPasswordPayloadType): Promise<ZorpResponse | undefined> {
    return HttpUtil.userInstance.post(`/user/${userId}/forgotpassword/reset`, payLoad).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    })
  }

  // Fetch the user info based on location
  public static getUserInfoByIP(): Promise<ZorpResponse | undefined> {
    return axios.get("https://api.ipregistry.co/?key=q9glkc4g5z688357&fields=location,time_zone");
  }
}