import {
    type MRT_Icons,
  } from 'material-react-table';
import { AiFillDelete, AiOutlineFilter } from 'react-icons/ai';
import { FaRegListAlt } from 'react-icons/fa';
import { BsSearch,BsFilter } from 'react-icons/bs';
import {MdOutlineFilterAltOff} from 'react-icons/md';
import { Drag } from "assests"
import { TiPinOutline } from "react-icons/ti";


const CustomIcons: Partial<MRT_Icons> = {
   FilterListIcon: AiOutlineFilter,
   ViewColumnIcon: FaRegListAlt,
   SearchIcon: BsSearch,
   SearchOffIcon: BsSearch,
   FilterAltIcon: AiOutlineFilter,
   VisibilityOffIcon: FaRegListAlt,
   FilterListOffIcon: MdOutlineFilterAltOff,
   DragHandleIcon: Drag,
   PushPinIcon: TiPinOutline
};

export default CustomIcons;