import AlertArrow from "assests/cron_no_secret_key_arrow.svg";
import AlertIcon from "assests/cron_secret_key_alert.svg";
import { useHistory } from 'react-router-dom';
import styled from "styled-components";

const PlaceholderIcon = styled.img`
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
`;
const Share01Icon = styled.img`
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
`;
const Text5 = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 600;
`;
const SupportingText1 = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 20px;
  display: none;
`;
const NoSecretKeyText = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
`;
const Text6 = styled.div`
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: none;
`;
const Button4 = styled.div`
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
const Text7 = styled.a`
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  color: inherit;
`;
const Button5 = styled.div`
  overflow: hidden;
  cursor:pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #b42318;
`;
const GoToSecretLink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  color: #da2d20;
`;
const NoSecretKeyTextDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
`;
const NoSecretKeyDiv = styled.div`
  align-self: stretch;
  border-radius: 12px;
  background-color: #fffafa;
  border: 1px solid #fda29b;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px;
  gap: 12px;
`;

const NoSecretKeyDivParent = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
`;
const Section = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
`;
const Main = styled.section`
  align-self: stretch;
  flex: 1;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: 14px;
  color: #b42318;
  font-family: Inter;
`;
const MainWrapper = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top:24px;
`;

interface INoCron  {
  onTabChange: () => void;
}

const NoSecretKeyConditionKeyCron = ({onTabChange}:INoCron) => {
  const history = useHistory();
  return (
      <MainWrapper>
        <Main>
          <Section>
            <NoSecretKeyDivParent>
              <NoSecretKeyDiv id="no_secret_key_div">
              
              <Share01Icon alt="" src={AlertIcon} />
                <NoSecretKeyTextDiv>
                  <NoSecretKeyText>
                    <Text5>
                      You have not enabled secret keys yet. Please enable secret
                      keys to use scheduled automations
                    </Text5>
                    <SupportingText1>
                      You need to have at least one secret key enabled to use
                      automation
                    </SupportingText1>
                  </NoSecretKeyText>
                  <GoToSecretLink>
                    <Button4>
                      <PlaceholderIcon alt="" src="/placeholder.svg" />
                      <Text6>Learn more</Text6>
                      <PlaceholderIcon alt="" src="/placeholder.svg" />
                    </Button4>
                    <Button5 onClick={()=>{
                      onTabChange()
                      history.push(`/settings/secret`);
                    }}>
                      <PlaceholderIcon alt="" src="/placeholder3.svg" />
                      <Text7>Go to Secrets Keys</Text7>
                      <Share01Icon alt="" src={AlertArrow} />
                    </Button5>
                  </GoToSecretLink>
                </NoSecretKeyTextDiv>
                {/* <Share01Icon alt="" src={CloseIcon} /> */}
              </NoSecretKeyDiv>
            </NoSecretKeyDivParent>
          </Section>
        </Main>
      </MainWrapper>
  );
};

export default NoSecretKeyConditionKeyCron;
