import React, { memo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { InfoTooltip as Tooltip } from "views/workflows/Tootltips/InfoTooltip";
import ListOfValueBadges from './ListOfValueBadges';

type Props = {
  params: any;
  col: any;
  complimentColor: any;
};

const ListOfText = ({ params, col, complimentColor }: Props) => {
  const renderValue = params?.cell?.renderValue?.();

  const [isOverflowing, setIsOverflowing] = useState(false);
  const cellRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (cellRef.current) {
      setIsOverflowing(cellRef.current.scrollWidth > cellRef.current.clientWidth);
    }
  }, [renderValue]);

  return (
    <Tooltip title={isOverflowing ? renderValue.join(', ') : ''} arrow>
      <div
        ref={cellRef}
        style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: '100%' }}
      >
        {Array.isArray(renderValue) && (
          <>
            {renderValue.map((value) => {
              if (typeof value !== 'object' && !Array.isArray(value)) {
                const trimmedValue = value.trim();
                const backgroundColor =
                  complimentColor[col.colDef?.properties?.color?.[trimmedValue]] || '#F2F4F7';
                const color = col.colDef?.properties?.color?.[trimmedValue] || '#344054';

                return (
                  <ListOfValueBadges
                    width='max-content'
                    key={trimmedValue}
                    value={trimmedValue}
                    backgroundColor={backgroundColor}
                    color={color}
                    marginLeft='4px'
                    padding='0 8px'
                  />
                );
              }
              return null;
            })}
          </>
        )}
      </div>
    </Tooltip>
  );
};

ListOfText.propTypes = {
  params: PropTypes.object.isRequired,
  col: PropTypes.object.isRequired,
  complimentColor: PropTypes.object.isRequired,
};

export default memo(ListOfText);