import { Show, ShowController, TopToolbar, ListButton, EditButton } from 'react-admin';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import React, { useEffect } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { UserService } from '../../providers/data/services/UserService';
import notification from '../../notifications/notifications';
import RoleAcl from './RoleAcl';
import { recordPageVisit, PageCategoryEnum } from '../../config/Rudderstack';
import { DetailsLabel } from 'views/commonComponents/commonComponents.styles';
import { TaskFormBody } from 'render-engine/render.styles';
import UserDisplayBlock from 'views/users/UserDisplayBlock';
import { hasPermission } from 'providers/auth/authUtils';

const RoleShowActions = ({ basePath, data, resource }) => {
  return (
    <>
      <TopToolbar className='tool-bar'>
        <div>
        <ListButton
            style={{
              border: "1px solid #c2c9d1",
              color: "#344054",
              fontSize: "1rem",
              fontWeight: "600",
              backgroundColor: "#ffffff",
              marginRight: "8px",
              padding: "6px 16px",
              borderRadius: "4px",
            }}
            basePath={'/settings/roles'} label='Back' icon={<ChevronLeft />} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          
          {hasPermission("role", "edit") && <EditButton
            style={{
              border: "1px solid #c2c9d1",
              color: "#344054",
              fontSize: "1rem",
              fontWeight: "600",
              backgroundColor: "#FFF",
              padding: "6px 16px",
            }}
          basePath={basePath} record={data} />}
        </div>
      </TopToolbar>
    </>
  );
};

const UsersOfRole = (props) => {
  const [users, setUsers] = React.useState([]);

  useEffect(() => {
    UserService.listUsers({ filter: { teamIds: [props.teamId] }, pagination: { page: 1, perPage: 3000 } })
      .then((response) => {
        setUsers(response?.data?.users);
      })
      .catch((error) => {
        notification('error', 'Error while fetching users for team');
      });
  }, [props.teamId]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align='left' style={{ color: '#fff', backgroundColor: '#141411' }}>
            <b>User Id</b>
          </TableCell>
          <TableCell align='left' style={{ color: '#fff', backgroundColor: '#141411' }}>
            <b>Name</b>
          </TableCell>
          <TableCell align='left' style={{ color: '#fff', backgroundColor: '#141411' }}>
            <b>Phone Number</b>
          </TableCell>
          <TableCell align='left' style={{ color: '#fff', backgroundColor: '#141411' }}>
            <b>Role</b>
          </TableCell>
          {/* <TableCell
                        align="left"
                        style={{ color: "#fff", backgroundColor: "#141411" }}
                    >
                        <b>Team</b>
                    </TableCell> */}
          <TableCell align='left' style={{ color: '#fff', backgroundColor: '#141411' }}>
            <b>Status</b>
          </TableCell>
          <TableCell align='left' style={{ color: '#fff', backgroundColor: '#141411' }}>
            <b>Active</b>
          </TableCell>
        </TableRow>
      </TableHead>
      {users?.map((user, index) => (
        <TableBody>
          <TableRow style={index % 2 ? { background: '#fff' } : { background: '#F5F5F5' }} key={user.userId}>
            <TableCell align='left' scope='row'>
              {user.userId}
            </TableCell>
            <TableCell align='left' scope='row'>
              {user.name}
            </TableCell>
            <TableCell align='left' scope='row'>
              {user.phone.num}
            </TableCell>
            <TableCell align='left' scope='row'>
              {user.role}
            </TableCell>
            {/* <TableCell align="left" scope="row">{user.teamIds}</TableCell> */}
            <TableCell align='left' scope='row'>
              {user.status}
            </TableCell>
            <TableCell align='left' scope='row'>
              {user.isActive.toString()}
            </TableCell>
          </TableRow>
        </TableBody>
      ))}
    </Table>
  );
};

const RoleShow = (props) => {
  // const classes = useStyles();

  useEffect(() => {
    recordPageVisit(PageCategoryEnum.role, 'role_details');
  }, [props]);

  return (
    <div className='showTaskParentDiv'>
      <TaskFormBody>
        
          <ShowController {...props} component='div'>
            {(controllerProps) => {
              // console.log("Controller Props &&&&", controllerProps);
              return (
                <Show {...props} actions={<RoleShowActions />} {...controllerProps}>
                  <div className='contentWrapper'>
                    <div>
                      <h3 className="font-medium" style={{ padding: "10px 0px" }}>
                        Role Details
                      </h3>
                      <hr/>
                    <div className="grid grid-cols-1 sm:grid-cols-2 mt-6 gap-x-8">
                  
                      <UserDisplayBlock label='Role Id' value={controllerProps?.record?.roleId}/>
                      
                      <UserDisplayBlock label='Name' value={controllerProps?.record?.name}/>
                      
                      <UserDisplayBlock label='Description' value={controllerProps?.record?.description}/>
                      
                      <UserDisplayBlock label='Created On' value={controllerProps?.record?.createdOn}/>
                    </div>
                    </div>
                    <div class='pt-10'>
                      <RoleAcl roleId={props.id} readOnly={true} />
                    </div>
                    {/* <div class="pt-10">
                                        <UsersOfRole teamId={props.id} />
                                    </div> */}
                  </div>
                </Show>
              );
            }}
          </ShowController>
        
      </TaskFormBody>
    </div>
  );
};

export default RoleShow;
