import { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  clearRowsToDelete,
  clearTaskData,
  initRecordShowMetaData,
  setInitialTaskData,
  setTaskData,
  setTaskRenderDetails,
  setTaskShowLayoutMetadata,
} from "render-engine/reducers/show/TaskShowReducer";
import { TaskFormBody } from "render-engine/render.styles";
import LoadingScreen from "views/LoadingScreen/LoadingScreen";
import { PageCategoryEnum, recordPageVisit } from "../../config/Rudderstack";
import notification from "../../notifications/notifications";
import { RenderV2 } from "render-engine/RenderV2";
import { getComponentParams } from "render-engine/RenderUtils";
import { ITaskTypeWebRenderModel } from "render-engine/models/ITaskTypeWebRenderModel";
import { EntityService } from "providers/data/services/EntityService";
import { useParams } from "react-router-dom";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { ViewModeHeader } from "./styles";
import { RiDeleteBin2Line } from "react-icons/ri";
import { error } from "ajv/dist/vocabularies/applicator/dependencies";
import DeletionConfirmationDialog from "utils/DeletionConfirmationDialog";
import { convertDateToReadableFormat, recordRSEvent } from "utils/CommonUtils";
import { TableRSEvents } from "./types";
import usePorterPermissions from "hooks/usePorterPermissions";
import ShowRecordActions from "render-engine/show/ShowRecordActions";
import { get, isEmpty } from "lodash";
import useTaskShow from "views/workflows/hooks/useTaskShow";
import { getEntityMetadata } from "./reducers/entityReducers";
import { ErrorBoundary } from "react-error-boundary";
import { sendCCErrorAlertToSlack } from "utils/SlackController";
import { Info } from "@mui/icons-material";
import ErrorBoundaryComp from "views/NotFound/ErrorBoundaryComp";
import ChecklistBuilder from "render-engine/show/ChecklistBuilder";
import { getConfig } from "config/envConfig/Config";

function fallbackRender({
  error,
  resetErrorBoundary,
}: {
  error: any;
  resetErrorBoundary: any;
}) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <ErrorBoundaryComp
      message="error"
      resetErrorBoundary={resetErrorBoundary}
    />
  );
}

const typeMapping: any = {
  TEXT: {
    label: "Text",
    value: "TEXT",
  },
  NUMBER: {
    label: "Number",
    value: "NUMBER",
  },
  "SINGLE SELECT": {
    label: "Single Select",
    value: "SINGLE SELECT",
  },
  "MULTI SELECT": {
    label: "Multi Select",
    value: "MULTI SELECT",
  },
  FILE: {
    label: "File",
    value: "FILE",
  },
  SIGNATURE: {
    label: "Signature",
    value: "SIGNATURE",
  },
  DATE: {
    label: "Date",
    value: "DATE",
  },
  TIME: {
    label: "Time",
    value: "TIME",
  },
  BOOLEAN: {
    label: "Boolean",
    value: "BOOLEAN",
  },
};

const checklistBuilderTable = getConfig("checklistBuilderTable");

const EntityShowComp = (props: any) => {
  const [renderConfig, setRenderConfig] = useState<ITaskTypeWebRenderModel>({});
  const dispatch = useDispatch();
  const showTaskForm = useForm();
  const [loading, setLoading] = useState(false);
  const { recordId, tableType } = useParams();
  const { taskRenderDetails, recordDetails } = useTaskShow();

  const [showComments, setShowComments] = useState<boolean>(true);
  const [showRecordLogs, setShowRecordLogs] = useState(false);
  // if hideInfoPanel is true, then hide the info panel (comment/logs panel)
  const [hideInfoPanel, setHideInfoPanel] = useState(false);
  const [checklistDefaultValues, setChecklistDefaultValues] = useState<any>({});

  useEffect(() => {
    recordPageVisit(PageCategoryEnum.task, "entity_details");
    // dispatch(resetListInfo())
    // Clear edit state if a user comes back to show page
    //TODO: ADD for create task as well
    setLoading(true);
    dispatch(
      initRecordShowMetaData({
        tableType,
        recordId,
      })
    );
    // clearing the initial data
    dispatch(getEntityMetadata(tableType));
    dispatch(clearTaskData());
    dispatch(clearRowsToDelete());
    setLoading(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const sendSlackNotification = async (
    error: Error,
    info: { componentStack: string }
  ) => {
    try {
      await sendCCErrorAlertToSlack({
        endpoint: `Record details did not load for ${tableType} with record id ${recordId} and message is ${error.message}`,
        code: 0,
        message: JSON.stringify(info),
        type: "ERROR",
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {}, [loading, tableType, taskRenderDetails]);

  return (
    <ErrorBoundary
      fallbackRender={fallbackRender}
      onReset={(details) => {
        // Reset the state of your app so the error doesn't happen again
      }}
      onError={sendSlackNotification}
    >
      <div className="showTaskParentDiv">
        {loading || isEmpty(taskRenderDetails) ? (
          <LoadingScreen />
        ) : (checklistBuilderTable.includes(tableType)) && checklistDefaultValues ? (
          <ChecklistBuilder
            checklistTabletype={tableType}
            taskRenderDetails={taskRenderDetails}
            defaultValues={checklistDefaultValues}
            mode="show"
            recordId={recordId}
          />
        ) : (
          <TaskFormBody>
            <ShowRecordActions
              createdOn={convertDateToReadableFormat(recordDetails?.createdOn)}
              updatedOn={convertDateToReadableFormat(recordDetails?.updatedOn)}
              tableTypeName={recordDetails?.tableTypeName}
              tableType={tableType}
              recordId={recordId}
              showTaskForm={showTaskForm}
              resource="table"
            />

            <div
              className={
                !hideInfoPanel && (showComments || showRecordLogs)
                  ? "contentWrapper-show-entity-new"
                  : "contentWrapperShowMode-entity"
              }
              style={{ height: "90vh !important" }}
            >
              {/* <div style={{ height: "100%" }}> */}
              <RenderV2
                componentParams={getComponentParams(dispatch, showTaskForm)}
                entityCategory={"TABLE"}
                entityType={tableType}
                entityId={recordId}
                showComments={showComments}
                mode={"show"}
                setShowComments={setShowComments}
                showRecordLogs={showRecordLogs}
                setShowRecordLogs={setShowRecordLogs}
                hideInfoPanel={hideInfoPanel}
                setHideInfoPanel={setHideInfoPanel}
              ></RenderV2>
              {/* </div> */}
            </div>
          </TaskFormBody>
        )}
      </div>
    </ErrorBoundary>
  );
};

export const EntityShow = EntityShowComp;
