// Events

// creating New Automation
const automationCreateNew = 'automation_builder_create_new_automation';
// Switching Automation
const switchDifferentAutomation = 'automation_builder_switch_automation';

// Actions

// API Action
const apiActionSaveClick = 'automation_builder_api_action_save_button_click';
const apiActionOnLoad = 'automation_builder_api_action_on_load';

// Push Notification
const pushNotificationActionOnLoad = 'automation_builder_push_notification_on_load';
const pushNotificationActionSaveClick = 'automation_builder_push_notification_save_button_click';

// Update Team 
const updateTeamActionOnLoad = 'automation_builder_update_team_action_on_load';
const updateTeamActionSaveClick = 'automation_builder_update_team_action_save_button_click';

// Update Data Fields
const updateDataFieldActionOnLoad = 'automation_builder_update_data_field_on_load';
const updateDataFieldActionSaveClick = 'automation_builder_update_data_field_save_button_click';

// Smart Component Action
const smartComponentActionOnLoad = 'automation_builder_smart_component_action_on_load'
const smartComponentActionSaveClick = 'automation_builder_smart_component_action_save_button_click'

// Conditions

// Location Check
const locationConditionOnLoad = 'automation_builder_location_condition_on_load';
const locationConditionSaveClick = 'automation_builder_location_condition_save_button_click';

// Expression Condition
const expressionConditionOnLoad = 'automation_builder_expression_condition_on_load';
const expressionConditionSaveClick = 'automation_builder_expression_condition_save_button_click';

// Expression Action - Javascript Function
const expressionActionOnLoad = 'automation_builder_expression_action_on_load';
const expressionActionSaveClick = 'automation_builder_expression_action_save_button_click';

// Smart Component Condition
const smartComponentConditionOnLoad = 'automation_builder_smart_component_condition_on_load';
const smartComponentConditionSaveClick = 'automation_builder_smart_component_condition_save_button_click'

// Guard
// Expression Guard
const expressionGuardOnLoad = 'automation_builder_expression_guard_save_on_load';
const expressionGuardSaveClick = 'automation_builder_expression_guard_save_button_click';

// Trigger
const smartComponentTriggerOnLoad = 'automation_builder_smart_component_trigger_on_load';
const smartComponentTriggerSaveClick = 'automation_builder_smart_component_trigger_save_button_click';

// Add Another Action Block Button
const addAnotherActionBlockBtnClick = 'automation_builder_add_action_block_btn_click';
const addAnotherTriggerBtnClick = 'automation_builder_add_trigger_block_btn_click';
const addAnotherGuardBtnClick = 'automation_builder_add_guard_block_btn_click';
const addAnotherActionBtnClick = 'automation_builder_add_action_block_btn_click';
const addAnotherConditionBtnClick = 'automation_builder_add_condition_block_btn_click';

// test automation
const testAutomationBtnClick = 'automation_test_btn';

// Monitor Events
const monitorContext = 'monitor';
const addDatasource = 'monitor_add_datasource';
const editDatasource = 'monitor_edit_datasource';
const monitorDetailsClick = 'monitor_details_click';
const monitorDesignFromScratch = 'monitor_design_from_scratch';
const monitorUpdate = 'monitor_update';
const discardChanges = 'discard_changes';
const createNewMonitor = 'create_new_monitor';
const click_edit_monitor = 'click_edit_monitor';
const testAlertInList = 'test_alert_in_list';
const testMonitor = 'test_monitor';
const deActivateMonitor = 'deactivate_monitor';
const enableMonitor = 'enable_monitor'; 
const emailActionAdd = 'email_action_add';
const emailActionDelete = 'email_action_delete';
const ticketActionAdd = 'ticket_action_add';
const ticketActionDelete = 'ticket_action_delete';
const ticketAvoidDuplicate = 'ticket_avoid_duplicate';
const ticketAutoClose = 'ticket_auto_close';
const createSingleAlert = 'create_single_alert';
const slackActionAdd = 'slack_action_add';
const slackActionDelete = 'slack_action_delete';
const automationAdd = 'automation_add';
const automationDelete = 'automation_delete';
const customize_with_blueblee = 'customize_with_blueblee';
const monitorThis = 'monitor_this';
const aiModalEditInClassicBuilder = 'ai_modal_edit_in_classic_builder';
const aiModalMonitorThis = 'ai_modal_monitor_this';
const aiCoversationMessage = 'ai_coversation_message';
const tableConfigureAlertBtn = 'table_configure_alert';
const tableEnableAlert = 'table_enable_alert';
const tableDesignFromScratch = 'table_design_from_scratch';
const tableMonitorModalClose = 'table_monitor_modal_close';
const tableAlertMeBtn = 'table_alert_me_btn';
const tableTemplateCustomizeFurther = 'table_template_customize_further';
const tableTemplateActivateAlerts = 'table_template_activate_alerts';
const tableTemplategoToAlerts = 'table_template_go_to_alerts';


export const RudderStackAutomationBuilderEvents = {
    automationCreateNew,
    switchDifferentAutomation,
    apiActionSaveClick,
    pushNotificationActionSaveClick,
    updateTeamActionSaveClick,
    updateDataFieldActionSaveClick,
    smartComponentActionSaveClick,
    locationConditionSaveClick,
    expressionConditionSaveClick,
    expressionGuardSaveClick,
    smartComponentConditionSaveClick,
    smartComponentTriggerSaveClick,
    apiActionOnLoad,
    pushNotificationActionOnLoad,
    updateTeamActionOnLoad,
    updateDataFieldActionOnLoad,
    smartComponentActionOnLoad,
    locationConditionOnLoad,
    expressionConditionOnLoad,
    smartComponentConditionOnLoad,
    expressionGuardOnLoad,
    smartComponentTriggerOnLoad,
    addAnotherActionBlockBtnClick,
    addAnotherTriggerBtnClick,
    addAnotherGuardBtnClick,
    addAnotherConditionBtnClick,
    addAnotherActionBtnClick,
    expressionActionOnLoad,
    expressionActionSaveClick,
    monitorContext,
    addDatasource,
    editDatasource,
    monitorDetailsClick,
    monitorDesignFromScratch,
    monitorUpdate,
    discardChanges,
    createNewMonitor,
    click_edit_monitor,
    testAlertInList,
    deActivateMonitor,
    enableMonitor,
    emailActionAdd,
    emailActionDelete,
    ticketActionAdd,
    ticketActionDelete,
    ticketAvoidDuplicate,
    testMonitor,
    ticketAutoClose,
    createSingleAlert,
    slackActionAdd,
    slackActionDelete,
    automationAdd,
    automationDelete,
    testAutomationBtnClick,
    customize_with_blueblee,
    monitorThis,
    aiModalEditInClassicBuilder,
    aiModalMonitorThis,
    aiCoversationMessage,
    tableConfigureAlertBtn,
    tableEnableAlert,
    tableDesignFromScratch,
    tableMonitorModalClose,
    tableAlertMeBtn,
    tableTemplateCustomizeFurther,
    tableTemplateActivateAlerts,
    tableTemplategoToAlerts
}

// Context
const automationBuilder = 'automation_builder';
const automationBuilderTrigger = 'automation_builder_trigger';
const automationBuilderGuard = 'automation_builder_guard';
const automationBuilderAction = 'automation_builder_action';
const automationBuilderCondition = 'automation_builder_condition';

export const RudderStackAutomationBuilderContext = {
    automationBuilder,
    automationBuilderTrigger,
    automationBuilderGuard,
    automationBuilderAction,
    automationBuilderCondition
}