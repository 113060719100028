const quotes = [
  "Chronos: Create Reports and Dashboards on top of your data",
  "Workflows help you model your Mobile Process",
  "Tasks follow the blueprint of the corresponding workflow",
  "Use CSV Bulk Upload to create multiple tasks or records",
  "Tables: Use Buttons to trigger an automation or open a url",
  "You can export any data instantly through Export button in Tables and Tasks",
  "Use Relation column to connect multiple tables. Better than lookup",
  "Do you know you can add files and images to your Tables?",
  "Use Automation to connect your Mobile workflows to Table data and vice versa",
  "Use Validations (inside automations) to check if the data entered by a user is valid",
  "You can use Location validation to see if your users are present at the correct location",
];

export default quotes;
