import React from "react";

type Props = {};

const ZDocIcon = ({
  color,
  width,
  height,
}: {
  color?: string;
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6666 15.8346V13.3346H5.83325C4.45254 13.3346 3.33325 14.4539 3.33325 15.8346M7.33325 18.3346H13.9999C14.9333 18.3346 15.4 18.3346 15.7566 18.153C16.0702 17.9932 16.3251 17.7382 16.4849 17.4246C16.6666 17.0681 16.6666 16.6014 16.6666 15.668V4.33464C16.6666 3.40121 16.6666 2.9345 16.4849 2.57798C16.3251 2.26438 16.0702 2.00941 15.7566 1.84962C15.4 1.66797 14.9333 1.66797 13.9999 1.66797H7.33325C5.93312 1.66797 5.23306 1.66797 4.69828 1.94045C4.22787 2.18014 3.84542 2.56259 3.60574 3.03299C3.33325 3.56777 3.33325 4.26784 3.33325 5.66797V14.3346C3.33325 15.7348 3.33325 16.4348 3.60574 16.9696C3.84542 17.44 4.22787 17.8225 4.69828 18.0622C5.23306 18.3346 5.93312 18.3346 7.33325 18.3346Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ZDocIcon;