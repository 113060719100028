type ISplitDivider = {
    showText?:boolean;
    textTitle?:string;
}

const SplitDivider = ({showText, textTitle}:ISplitDivider) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ backgroundColor: '#91979d', width: '2px', height: '80px', marginLeft: '30px' }}>
            </div>
            <div className="" style={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
            {showText &&
                <p style={{ marginLeft: '20px',fontSize:'14px',fontWeight:'400' }}>{textTitle}</p>}
            </div>
        </div>
    )
}

export default SplitDivider