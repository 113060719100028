import React, { FunctionComponent, useEffect } from "react"
import { UseFormRegister, FieldErrors } from "react-hook-form"
import { useStore } from "react-redux"
import { ZComponent } from "../common/ZComponent"
import { ZEmpty } from "../empty/ZEmpty"
import { IZComponentPropType } from "render-engine/models/ITaskTypeWebBodyRenderModel"
import { IZLocationInputInterface } from "./IZLocationInputInterface"

export const ZLocationInput: FunctionComponent<IZComponentPropType> = ({
  config,
  onChange,
  register,
  errors,
  setValue,
  control,
  isOpenedAsSubfield,
  getValues,
}) => {
  // Construct validation
  const validation: any = {}

  const store = useStore()

  const mode =
    Object.keys(store.getState().taskCreation).length > 0
      ? "taskCreation"
      : "taskEdit"

  if (config.isRequired) validation.required = `required`

  useEffect(() => {
    if (config.value)
      // onChange(config.key, {
      //     address: config.value?.address?.value,
      //     latitude: config.value?.latitude?.value && parseFloat(config.value?.latitude?.value as string),
      //     longitude: config.value?.longitude?.value && parseFloat(config.value?.longitude?.value as string)
      // })
      setValue(config.key, {
        address: config.value?.address?.value,
        latitude:
          config.value?.latitude?.value &&
          parseFloat(config.value?.latitude?.value as string),
        longitude:
          config.value?.longitude?.value &&
          parseFloat(config.value?.longitude?.value as string),
      })
  }, [])

  let isVisible = true
  if (config.hasOwnProperty("isVisible")) {
    if (
      (typeof config.isVisible === "string" ||
        config.isVisible instanceof String) &&
      config.isVisible.toLowerCase().trim() === "false"
    )
      isVisible = false
    else if (typeof config.isVisible === "boolean") isVisible = config.isVisible
    else if (typeof config.isVisible === "function")
      isVisible =
        config.isVisible() === "true" || config.isVisible() === true
          ? true
          : false
  }

  const handleAddressChange = (addresKey: string, newAddressValue: string) => {
    if (store.getState()[mode] && store.getState()[mode][config.key])
      onChange(config.key, {
        address: newAddressValue,
        latitude: store.getState()[mode][config.key]["latitude"],
        longitude: store.getState()[mode][config.key]["longitude"],
      })
  }

  const handleLatitudeChange = (latitudeKey: string, newLatitude: number) => {
    if (store.getState()[mode] && store.getState()[mode][config.key])
      onChange(config.key, {
        address: store.getState()[mode][config.key]["address"],
        latitude: newLatitude,
        longitude: store.getState()[mode][config.key]["longitude"],
      })
  }

  const handleLongitudeChange = (
    longitudeKey: string,
    newLongitude: number
  ) => {
    if (store.getState()[mode] && store.getState()[mode][config.key])
      onChange(config.key, {
        address: store.getState()[mode][config.key]["address"],
        latitude: store.getState()[mode][config.key]["latitude"],
        longitude: newLongitude,
      })
  }

  const componentParams = {
    onChange: onChange,
    register: register,
    errors: errors,
    setValue: setValue,
    control,
    isOpenedAsSubfield,
    getValues: getValues,
  }

  // Create a custome validate function for address, latitude and longitude.
  // For all the validate calls, if config.required is true, then we need to validate the field.
  // Else, we don't need to validate the field.
  // For address:
  /**
   * if config.required is true, and lat and long both are not present, then address is required.
   */

  const addressConfig = { ...config.value.address }
  const latConfig = { ...config.value.latitude }
  const longConfig = { ...config.value.longitude }

  addressConfig.validate = () => {
    if (!config.required) {
      return true
    }
    if (
      !getValues()?.[config.key]?.address &&
      !getValues()?.[config.key]?.latitude &&
      !getValues()?.[config.key]?.longitude
    )
      return "required"
    else return true
  }

  // For latitude:
  /**
   * if longitude is there: latitude is required.
   * if address is there and longitude is not there, its fine.
   * if address is not there and longitude is not there, its required.
   */

  latConfig.validate = () => {
    if (!config.required) {
      return true
    }
    if (getValues()?.[config.key]?.latitude) {
      return true
    }
    if (
      getValues()?.[config.key]?.longitude &&
      !getValues()?.[config.key]?.latitude
    ) {
      return "required"
    }
    if (
      getValues()?.[config.key]?.address &&
      !getValues()?.[config.key]?.longitude
    ) {
      return true
    }
    if (
      !getValues()?.[config.key]?.address &&
      !getValues()?.[config.key]?.longitude
    ) {
      return "required"
    }
    return true
  }

  // For longitude
  /**
   * if latitude is there: longitude is required.
   * if address is there and latitude is not there, its fine.
   * if address is not there and latitude is not there, its required.
   */
  longConfig.validate = () => {
    if (!config.required) {
      return true
    }
    if (
      getValues()?.[config.key]?.latitude &&
      !getValues()?.[config.key]?.longitude
    ) {
      return "required"
    }
    if (
      getValues()?.[config.key]?.address &&
      !getValues()?.[config.key]?.latitude
    ) {
      return true
    }
    if (
      !getValues()?.[config.key]?.address &&
      !getValues()?.[config.key]?.latitude
    ) {
      return "required"
    }
    return true
  }

  if (isVisible)
    return (
      <>
        {/* @ts-ignore */}
        <ZComponent
          config={addressConfig}
          {...componentParams}
          onChange={handleAddressChange}
        ></ZComponent>
        <ZEmpty></ZEmpty>
        {/* @ts-ignore */}
        <ZComponent
          config={latConfig}
          {...componentParams}
          onChange={handleLatitudeChange}
        ></ZComponent>
        {/* @ts-ignore */}
        <ZComponent
          config={longConfig}
          {...componentParams}
          onChange={handleLongitudeChange}
        ></ZComponent>
      </>
    )
  else return <div></div>
}
