import styled from "styled-components"

export const MainContainer = styled.div`
  width: 95%;
  padding: 10px 16px;
`;

export const PositionChangeHandle = styled.div`
  margin-left: auto;
`;

export const Card = styled.div`
  display: grid;
  grid-template-columns: 8% 61% 21% 10%;
  align-items: center;
  padding: 10px 8px;
  margin-top: ${(props: any) => (props.type === "infoCard" ? "" : "10px")};
  margin-bottom: ${(props: any) => (props.type === "infoCard" ? "8px" : "")};
  border: ${(props: any) =>
    props.variant === "secondary" ? "1px solid #C2C9D1" : "1px solid #F03738"};
  width: 100%;
  border-radius: 4px;
  background-color: ${(props: any) =>
    props.type === "infoCard" ? "#F2F2F2" : ""};

  p {
    margin-left: 8px;
    font-size: 14px;
    font-weight: ${(props: any) => (props.type === "infoCard" ? "600" : "400")};
    color: ${(props: any) =>
      props.type === "infoCard" ? "#616569" : ""}; #616569
  }

  svg {
    margin-left: 8px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const Heading = styled.p`
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
`;