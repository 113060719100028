import React, { useState, useCallback, useEffect } from 'react';
import { components } from 'react-select';
import debounce from 'lodash/debounce';
import { TbUsers as TeamIcon } from "react-icons/tb";
import { HiOutlineKey as RoleIcon } from "react-icons/hi";
import { FiUser as UserIcon } from "react-icons/fi";
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import { OptionType } from '../types';
import { BffService } from 'providers/data/services/BffService';
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';
import { recordRSEvent } from 'utils/CommonUtils';
import { TablePermissionRsEvents } from 'views/entities/types';

type Props = {
    context: "create" | "update" | "updateNew";
    selectedOptions: OptionType[];
    onChangeSelect: (options: OptionType[]) => void;
    placeholder?: string;
}

const IconWrapper = styled.span<{ background?: string; color?: string }>`
    background: ${props => props.background ? props.background : "transparent"};
    color: ${props => props.color ? props.color : "#344054"};
    padding: 2px 8px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    margin-right: 8px;
`

const OptionContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 10px;
    &:hover {
        background-color: #F2F4F7;
    }
`

const CustomOption = ({ innerProps, data, selectProps }: any) => {
    const { inputValue } = selectProps;
    const getHighlightedText = (text: string, highlight: string) => {
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return parts.map((part, index) => (
            part.toLowerCase() === highlight.toLowerCase() ? (
                <span key={index} style={{ background: 'rgba(250, 197, 21, 0.30)', whiteSpace: 'pre' }}>{part}</span>
            ) : (
                <span key={index} style={{ color: "#344054", whiteSpace: 'pre' }}>{part}</span>
            )
        ));
    };

    return (
        <OptionContainer {...innerProps}>
            <IconWrapper background={data.type === "team" ? "#FEF7C3" : data.type === "role" ? "#F2F4F7" : "#ECF3FF"} color={data.type === "team" ? "#CA8504" : data.type === "role" ? "#344054" : "#3054B9"}>{
                data.type === "team" ? <TeamIcon style={{ marginRight: '4px' }} /> : data.type === "role" ? <RoleIcon style={{ marginRight: '4px' }} /> : <UserIcon style={{ marginRight: '4px' }} />}
                {data.type === "team" ? "Team" : data.type === "role" ? "Role" : "User"
                }</IconWrapper>
            {getHighlightedText(data.label.trim(), inputValue)}
        </OptionContainer>
    );
};

const CustomMultiValue = (props: any) => {
    return (
        <components.MultiValue {...props} style={{ ...props.style, background: '#fff', border: "1px solid #D0D5DD", borderRadius: '4px' }}>
            {props.children}
        </components.MultiValue>
    );
};

const CustomMultiValueLabel = ({ innerProps, data }: any) => {
    return (
        <div {...innerProps} style={{ display: 'flex', borderRadius: '4px', alignItems: 'center', padding: '3px 4px', background: "#fff" }}>
            {data.type === "team" ? <TeamIcon color='#475467' style={{ marginRight: '5px' }} /> : data.type === "role" ? <RoleIcon color='#475467' style={{ marginRight: '5px' }} /> : <UserIcon color='#475467' style={{ marginRight: '5px' }} />}
            {data.label}
        </div>
    );
};

const CustomClearIndicator = (props: any) => {
    return (
        <div style={{ position: 'sticky', top: 0, zIndex: 5 }}>
            <components.ClearIndicator {...props} />
        </div>
    );
};


const CustomNoOptionsMessage = ({ searchLength, isLoading, ...props }: any) => {
    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                <CircularProgress size={20} />
            </div>
        );
    }

    // Showing custom message if searchLength is less than 3
    if (searchLength < 3) {
        return (
            <components.NoOptionsMessage {...props}>
                Type at least 3 characters...
            </components.NoOptionsMessage>
        );
    }

    return (
        <components.NoOptionsMessage {...props}>
            {props.children}
        </components.NoOptionsMessage>
    );
};

const SearchInputContainer = ({ context, placeholder, selectedOptions, onChangeSelect }: Props) => {
    const [search, setSearch] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [searchLength, setSearchLength] = useState(0);
    const [lastOptions, setLastOptions] = useState([]);
    const [key, setKey] = useState(0);
    const [menuIsOpen, setMenuIsOpen] = useState(false); 

    const debouncedSearch = useCallback(
        debounce((newValue) => {
            setSearch(newValue);
            setSearchLength(newValue.length);
        }, 1000),
        []
    );

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (!event.target.closest('#async-select-container')) {
                setMenuIsOpen(false);
            }
        };

        if (menuIsOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [menuIsOpen]);

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            borderRadius: "4px",
            border: "1px solid var(--Gray- 300, #D0D5DD) !important",
            padding: "4px 12px",
            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            maxHeight: '150px !important',
            overflowY: 'auto',
            minWidth: '100%',
        }),
        indicatorsContainer: (provided: any) => ({
            ...provided,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            position: 'relative',
        }),
        indicatorContainer: (provided: any) => ({
            ...provided,
            position: 'sticky',
            top: 0,
            zIndex: 5,
        }),
        menu: (provided: any) => ({
            ...provided,
            width: '100%',
            maxHeight: '300px',
            overflowY: 'auto',
            padding: '0 6px',
        }),
        placeholder: (provided: any) => ({
            ...provided,
            textAlign: 'left',
            color: placeholder ? '#344054' : '#667085',
        }),
        indicatorSeparator: (provided: any) => ({
            ...provided,
            display: 'none',
        }),
        dropdownIndicator: (provided: any) => ({
            ...provided,
            display: "none",
        }),
        valueContainer: (provided: any) => ({
            ...provided,
            padding: '0',
            fontSize: '14px',
        }),
        multiValue: (provided: any) => ({
            ...provided,
            background: '#fff',
            border: "1px solid #D0D5DD",
            borderRadius: "4px",
        }),
        multiValueLabel: (provided: any) => ({
            ...provided,
            color: '#fff',
        }),
    };


    const loadOptions: LoadOptions<OptionType, any, { page: number }> = async (searchValue, { page }) => {

        if (searchValue === '') {
            return { options: lastOptions, hasMore: false, additional: { page: page + 1 } };
        }

        if (searchValue.length < 3) {
            return { options: [], hasMore: false, additional: { page: page + 1 } };
        }

        setIsLoading(true);
        try {
            const res = await BffService.getUserSearchResult({
                searchText: searchValue,
                page,
                size: 100,
                currentValue: { teamIds: [], roleIds: [], userIds: [] }
            });

            const fetchedOptions = res?.code === "200" ? [
                ...res.data.teams.map((team: { teamId: string, name: string, type: 'team' }) => ({ value: team.teamId, label: team.name, type: 'team' })),
                ...res.data.users.map((user: { userId: string, name: string, type: 'user' }) => ({ value: user.userId, label: user.name, type: 'user' })),
                ...res.data.roles.map((role: { roleId: string, name: string, type: 'role' }) => ({ value: role.roleId, label: role.name, type: 'role' }))
            ] : [];

            setLastOptions(fetchedOptions);

            return {
                options: fetchedOptions,
                hasMore: !!res.data.hasMore,
                additional: { page: page + 1 },
            };
        } catch (error) {
            console.error('Error loading options:', error);
            return { options: [], hasMore: false, additional: { page: page + 1 } };
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (selected: OptionType[]) => {
        recordRSEvent(TablePermissionRsEvents.SHARE_RESOURCE_SELECTED_USERS_VALUE_CHANGED, {
            selectedOptions: selected
        });
        onChangeSelect(selected);
        setInputValue('');
        setKey(prevKey => prevKey + 1);
        setMenuIsOpen(selected.length > 0);
    };


    const handleInputChange = (newValue:string, actionMeta:any) => {
        if (actionMeta.action === 'input-change') {
            setInputValue(newValue);
            debouncedSearch(newValue);
            setMenuIsOpen(true);
        } else if (actionMeta.action === 'menu-close') {
            setMenuIsOpen(false);
        }
    };

    return (
        <div id="async-select-container" style={{ width: "100%" }} onClick={(e) => e.stopPropagation()}>
            <AsyncPaginate
                isMulti
                key={key}
                autoFocus
                value={selectedOptions}
                closeMenuOnSelect={false}
                inputValue={inputValue}
                menuIsOpen={menuIsOpen}
                loadOptions={loadOptions}
                additional={{ page: 1 }}
                components={{
                    Option: CustomOption,
                    MultiValue: CustomMultiValue,
                    MultiValueLabel: CustomMultiValueLabel,
                    ClearIndicator: CustomClearIndicator,
                    NoOptionsMessage: (props) => <CustomNoOptionsMessage {...props} isLoading={isLoading} searchLength={searchLength} />
                }}
                onChange={handleChange}
                styles={customStyles}
                onInputChange={handleInputChange}
                isLoading={isLoading}
                debounceTimeout={500}
                placeholder={placeholder ? placeholder : "Search user, team or roles..."}
            />
        </div >
    );
};

export default SearchInputContainer;
