import { Box } from '@mui/material';
import notification from "notifications/notifications";
import { MonitorService } from 'providers/data/services/MonitorService';
import { memo, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { setSelectedMonitorId } from 'views/automationBuilder/reducer/automationReducer';
import MonitorInterface from './MonitorInterface';
import MonitorListDetailPage from './MonitorListComponents/MonitorListDetailPage';
import { IMonitorContentMenu } from './MonitorListComponents/MonitorListDetailPageContextMenu';
import MonitorListHeader from './MonitorListComponents/MonitorListHeader';
import MonitorTable from './MonitorListComponents/MonitorTable';
import { IScreenContext, setMonitorInterfaceContext, setSelectedMonitor, setShowInterfaceUI } from './reducer/MonitorReducer';
import useMonitor from "./reducer/useMonitor";
import { RudderStackAutomationBuilderEvents } from "constants/RudderStackConstants";
import { recordRSEvent } from 'utils/CommonUtils';
import { PageCategoryEnum, recordPageVisit } from 'config/Rudderstack';

interface IMonitorList {
    openTemplatePage: () => void;
    selectedMonitorData: any;
    monitorList: any;
    onUpdateSuccess:() => void;
    onSelectMonitorChange:(monitorData:any) => void;
}

const MonitorList = ({ openTemplatePage, selectedMonitorData, monitorList, onUpdateSuccess, onSelectMonitorChange }: IMonitorList) => {
    const dispatch = useDispatch();
    const { showInterfaceUI, monitorInterfaceContext } = useMonitor();
    const changeMonitorStatus = async () => {
        const monitorAPIPayload = {
            "isActive": !selectedMonitorData.isActive,
            "cronData": {
                ...selectedMonitorData.cronData,
                "isActive": !selectedMonitorData.isActive
            }
        }

        const getMonitorStatus = await MonitorService.updateMonitor(monitorAPIPayload, selectedMonitorData.monitorId);
        if (getMonitorStatus?.code == '200') {
            onUpdateSuccess();
        } else {
            notification("error", "Failed while updating monitor status");
        }
    }

    useEffect(()=>{
        recordPageVisit(
            RudderStackAutomationBuilderEvents.monitorContext,
            PageCategoryEnum.monitorList
          );
    },[])

    return (
        <>
            {
                !showInterfaceUI && (
                    <>
                        <Box width={'100%'} height={'100%'}>
                            <MonitorListHeader
                                openTemplatePage={openTemplatePage}
                            />

                            <Box width={'100%'} display={'flex'} height={'100vh'}>
                                <Box width={'50%'} height={'100%'}>
                                    <MonitorTable
                                        onClick={(hoverData: any) => {
                                            onSelectMonitorChange(hoverData);

                                            recordRSEvent(RudderStackAutomationBuilderEvents.monitorDetailsClick, {
                                                context: RudderStackAutomationBuilderEvents.monitorContext,
                                                monitorId: hoverData.monitorId
                                              });
                                        }}
                                        monitorList={monitorList}
                                    />
                                </Box>

                                <Box width={'50%'} height={'100%'} borderTop={'1px solid var(--Gray-200, #EAECF0)'} borderLeft={'1px solid var(--Gray-200, #EAECF0)'} bgcolor={'#FCFCFD'}>
                                    {
                                        selectedMonitorData?.monitorId &&
                                        <MonitorListDetailPage
                                            onClickEdit={(editContext: IMonitorContentMenu) => {
                                                if (editContext == IMonitorContentMenu.EDIT_MONITOR) {
                                                    dispatch(setShowInterfaceUI(true))

                                                    // set your data
                                                    dispatch(setMonitorInterfaceContext({
                                                        monitorScreenContext: IScreenContext.EDIT,
                                                        monitorComponentsData: {
                                                            isdDatasourceSectionHidden: true,
                                                            isConditionSectionHidden: true,
                                                            isChannelSectionHidden: true,
                                                            isMonitorCustomizationSectionHidden: true,
                                                            selectedActionDetails: undefined
                                                        },
                                                        monitorData: selectedMonitorData
                                                    }));
                                                    dispatch(setSelectedMonitor({ monitorId: selectedMonitorData.monitorId, monitorAutomation:selectedMonitorData?.automationPayload?.uiFormat ? [selectedMonitorData?.automationPayload?.uiFormat] : [] }))
                                                    dispatch(setSelectedMonitorId(selectedMonitorData.monitorId));
                                                } else if (editContext == IMonitorContentMenu.PAUSE_MONITOR) {
                                                    changeMonitorStatus();
                                                }
                                            }}
                                            selectedMonitorData={selectedMonitorData} />
                                    }

                                </Box>
                            </Box>
                        </Box>
                    </>
                )
            }

            {
                (showInterfaceUI && monitorInterfaceContext) && <MonitorInterface openTemplatePage={(fetchMonitorList: boolean) => {
                    dispatch(setShowInterfaceUI(false))
                    if (fetchMonitorList) {
                        onUpdateSuccess();
                    }
                }} />
            }
        </>
    )
}

export default memo(MonitorList);