import { Badge } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { styled as muiStyled } from "@material-ui/styles";
import classnames from "classnames";
import notification from "notifications/notifications";
import PropTypes from "prop-types";
import { CommunicationService } from "providers/data/services/CommunicationService";
import React, {
  cloneElement,
  createElement,
  forwardRef,
  useCallback,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  IEmbedLinkType,
  setSelectedEmbedLink,
} from "./reducer/menuSlice";
import { useLogout, useRefresh } from "react-admin";
import styled from "styled-components";
import MenuItemWithDropdown from "./MenuItemWithDropdown";
import { LogoutIcon } from "assests";
import { useMediaQuery, useTheme } from "@mui/material";
import { resetEntity } from "views/entities/reducers/entityReducers";
import { useHistory } from "react-router-dom";

import {
  setSelectEntity,
} from "views/entities/reducers/entityReducers";

const NavLinkRef = forwardRef((props, ref) => (
  <NavLink innerRef={ref} {...props} />
));

const useStyles = makeStyles(
  (theme) => ({
    root: {
      color: "#1D2939", // Menu Item In-Active State
      marginBottom: "5px 0px",
      padding: "6px 0px",
      borderRadius: "4px",
    },
    active: {
      color: "white", // Menu Active State
      backgroundColor: (props) => props.zTheme?.color?.primary,
      borderRadius: "4px",
    },
    icon: { minWidth: theme.spacing(5) },
  }),
  { name: "RaMenuItemLink" }
);

const StyledBadge = muiStyled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: "0px",
    padding: "2px 0px",
    background: "orange",
    color: "white",
    fontSize: "10px",
    fontWeight: "bold",
    top: "4px",
  },
}));

export const SingleMenuItem = styled.div`
  width: 100%;
  display: flex;
  padding: 0px 10px;
  gap: 12px;
  align-items: center;
  div {
    font-weight: 500;
    font-size: 13px;
  }
`;

const specialCMMSTableMenuIds = [
  "workOrderMenu",
  "preventiveMaintenanceMenu",
  "checklistsMenu",
  "newTasksMenu",
  "newAssetsMenu",
  "assetsMenu",
  "questionsMenu",
  "inventoryMenu",
  "vendorsMenu",
  "purchaseOrderMenu",
  "locationsMenu",
  "newTicketsMenu",
  "ticketsMenu",
  "resourcesMenu",
  "preventiveMaintenanceSchedulingMenu",
  "assessmentchecklistsMenu",
  "workOrderAssessmentMenu",
  "resources",
  "customersMenu",
];

const MenuItemLink = React.memo(
  forwardRef((props, ref) => {
  const {
    className,
    classes: classesOverride,
    primarytext,
    leftIcon,
    icon,
    text,
    style,
    onClick,
    sidebarIsOpen,
    tooltipProps,
    menuId,
    isSupportType,
    isEmbed,
    embedUrl,
    isInternal,
    type,
    dropdownItems,
    allowCreate = true,
    data,
    ...rest
  } = props;

  const classes = useStyles(props);
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const theme = useTheme();
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const logout = useLogout();
  const history = useHistory()

  const handleMenuTap = useCallback(
    (e) => {
      onClick && onClick(e);
      if (menuId === "logoutIcon") {
        dispatch(resetEntity());
        logout();
      } else if (isEmbed) {
        dispatch(setSelectedEmbedLink(embedUrl));
      } else if (specialCMMSTableMenuIds.includes(menuId)) {
        dispatch(
          setSelectEntity({
            tableType: data.tableType,
            tableDisplayName: data.tableDisplayName,
            defaultViewId: data.defaultViewId,
          })
        );
        // history.push(to);
        // set tableType to local storage
        localStorage.setItem("tableType", data.tableType);
      }
      // localStorage.setItem('reportUrl', to);
    },
    [onClick]
  );

  const [count, setCount] = React.useState(0);

  function useInterval(callback, delay) {
    const savedCallback = React.useRef();
  
    React.useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    React.useEffect(() => {
      if (delay !== null) {
        const id = setInterval(() => savedCallback.current(), delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  // poll every 15 min for the alert
  useInterval(() => {
    if (menuId === "alertsMenu") {
      let statuses = ["NEW"];
      CommunicationService.getAlertCount(statuses).then((response) => {
        // Display the alert notification only if the current count > previous count
        // TODO: This logic has a short coming that, if the an alert has been marked as done in between, the previous and current count will be the same
        if (response?.data > count) {
          notification("info", "New alerts available!");
        }
        setCount(response?.data);
      });
    }
  }, 900000);

  const handleSupportLinkTap = useCallback((e) => {
    e.preventDefault();
    switch (menuId) {
      case "supportMenu":
        window.$crisp.push(["do", "chat:toggle"]);
        break;
      case "docsMenu":
        window.open("https://docs.zorp.one/", "_blank");
        break;
      case "appMenu":
        window.open("https://www.zorp.one/app-download", "_blank");
        break;
      case "feedbackMenu":
        window.open(
          "https://docs.google.com/forms/d/11M7h8cHEI6f2c_SUJDIAopcXnBEpiH7TWQgTReWwdbo",
          "_blank"
        );
        break;
      default:
        break;
    }
  }, [menuId]);

  const renderMenuItem = () => {
    return (
      <>
        {type === IEmbedLinkType.DROPDOWN ? (
          <MenuItemWithDropdown
            text={text}
            icon={icon}
            menuId={menuId}
            dropdownItems={dropdownItems}
            // classes from Menu.tsx
            classes={props.classes}
            menuItemClass={classes}
            onClick={!isSupportType ? handleMenuTap : handleSupportLinkTap}
            open={open}
            isMobile={isMobile}
            allowCreate={allowCreate}
          />
        ) : (
          <MenuItem
            className={classnames(classes.root, className)}
            activeClassName={classes.active}
            component={menuId !== "logoutIcon" ? NavLinkRef : "div"}
            ref={ref}
            style={style}
            tabIndex={0}
            {...rest}
            onClick={!isSupportType ? handleMenuTap : handleSupportLinkTap}
          >
            {isMobile ? (
              type === IEmbedLinkType.DROPDOWN ? (
                <MenuItemWithDropdown
                  text={text}
                  icon={icon}
                  menuId={menuId}
                  dropdownItems={dropdownItems}
                  open={open}
                  isMobile={isMobile}
                  allowCreate={allowCreate}
                />
              ) : (
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    {menuId === "logoutIcon" ? (
                      <LogoutIcon />
                    ) : menuId === "alertsMenu" ? (
                      <StyledBadge badgeContent={count} color="secondary">
                        {cloneElement(icon)}
                      </StyledBadge>
                    ) : // check if icon is a react element
                    typeof icon === "object" ? (
                      cloneElement(icon)
                    ) : (
                      createElement(icon, {
                        size: "0.8rem",
                      })
                    )}
                  </div>

                  <div
                    style={{
                      marginTop: "0.5rem",
                      fontSize: 11,
                      justifyContent: "center",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    {text}
                  </div>
                </div>
              )
            ) : open ? (
              type === IEmbedLinkType.DROPDOWN ? (
                <MenuItemWithDropdown
                  text={text}
                  icon={icon}
                  menuId={menuId}
                  dropdownItems={dropdownItems}
                  open={open && isMobile}
                  allowCreate={allowCreate}
                />
              ) : (
                <SingleMenuItem>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        {menuId === "alertsMenu" ? (
                          <StyledBadge badgeContent={count} color="secondary">
                            {cloneElement(icon)}
                          </StyledBadge>
                        ) : // check if icon is a react element
                        typeof icon === "object" ? (
                          cloneElement(icon)
                        ) : (
                          createElement(icon, {
                            size: "16px",
                          })
                        )}
                      </div>

                      <div
                        style={{
                          fontSize: "13px", // main item font size
                          lineHeight: "13px",
                          fontWeight: 500,
                        }}
                      >
                        {text}
                      </div>
                    </div>
                  </div>
                </SingleMenuItem>
              )
            ) : type === IEmbedLinkType.DROPDOWN ? (
              <MenuItemWithDropdown
                text={text}
                icon={icon}
                menuId={menuId}
                dropdownItems={dropdownItems}
                open={open}
                isMobile={isMobile}
                allowCreate={allowCreate}
              />
            ) : (
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    width: "100%",
                  }}
                >
                  {menuId === "logoutIcon" ? (
                    <LogoutIcon />
                  ) : menuId === "alertsMenu" ? (
                    <StyledBadge badgeContent={count} color="secondary">
                      {cloneElement(icon)}
                    </StyledBadge>
                  ) : // check if icon is a react element
                  typeof icon === "object" ? (
                    cloneElement(icon)
                  ) : (
                    createElement(icon, {
                      size: "0.8rem",
                    })
                  )}
                </div>

                <div
                  style={{
                    marginTop: "0.5rem",
                    fontSize: 13,
                    justifyContent: "center",
                    display: "flex",
                    width: "100%",
                  }}
                >
                  {text}
                </div>
              </div>
            )}
          </MenuItem>
        )}
      </>
    );
  };

  if (sidebarIsOpen) {
    return renderMenuItem();
  }

  return renderMenuItem();
}));

MenuItemLink.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  leftIcon: PropTypes.element,
  onClick: PropTypes.func,
  primarytext: PropTypes.node,
  staticContext: PropTypes.object,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  sidebarIsOpen: PropTypes.bool,
  isSupportType: PropTypes.bool,
};

export default MenuItemLink;