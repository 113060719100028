import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

function ZorpCalendarTask(props) {
    return (
      <HtmlTooltip
        title={
          <>
            <b>{"Task Id - "}</b>
            {props.event.id}
            <br />
            <b>{"Customer Name - "}</b>
            {props.event.customerName}
            <br />
            <b>{"Task Type - "}</b>
            {props.event.taskName}
            <br />
            <b>{"Status - "}</b>
            {props.event.taskStatusName}
            <br />
            <b>{"Orders - "}</b>
            {props.event.orders}
            <br />
            <b>{"Products Count - "}</b>
            {props.event.productCount}
          </>
        }
      >
        <div>
          <span>{props.event.title}</span>
        </div>
      </HtmlTooltip>
    );
  }

export default ZorpCalendarTask;