import Editor from '@monaco-editor/react';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import { memo } from 'react';
const defaultEditorLanguage = 'mysql';

interface IMonitorEditor {
  onchange:(value:string) => void;
  defaultEditorValue:string;
}

export type Monaco = typeof monaco;
const MonitorEditor = ({onchange, defaultEditorValue}:IMonitorEditor) => {
  
  function handleEditorChange(value: string | undefined, event: monaco.editor.IModelContentChangedEvent) {
    onchange(value as string)
  }
  
  return (
    <Editor
    theme='myTheme'
    height="150px"
    defaultLanguage={defaultEditorLanguage}
    defaultValue={defaultEditorValue}
    value={defaultEditorValue}
    loading="Loading..."
    onChange={handleEditorChange}
    options={{
      fontSize: 12,
      fontFamily: 'Inter',
      minimap: { enabled: false },
    }}
  />
  )
}

export default memo(MonitorEditor);