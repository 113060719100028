import { styled as MuiStyled } from '@material-ui/core/styles';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
export const VariableHelperText = styled.p`
color:black;
font-size:12px;
margin:0px;
padding:0px;
margin-top:-1px;
`

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #EAECF0; /* Change the color as needed */
`;

export const MessageBodyLabel = styled.p`
color: black;
font-family: Inter;
font-size: 12px;
font-style: normal;
margin-top:16px;
font-weight: 500;
line-height: 18px; /* 150% */
`

export const LabelText = styled.p`
color: black;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
margin-bottom: 6px;
`


  export const MonitorCronNextRun = styled.p`
  color: black;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 10px;
`;

export const HeadingText = styled.p`
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  color: black;
`

export const SelectedOptionValue = styled.p`
color: black;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
`


// Define the styled SearchBox component
export const MonitorTextField = MuiStyled(TextField)({
  // General styles
  backgroundColor: '#fff',
  width: '100%',
  // Styles for the input element
  '& .MuiInputBase-input': {
    WebkitTextFillColor: "black", // Color of the text
    fontWeight: 400, // Font weight
    fontFamily:'Inter',
    fontSize: '12px !important', // Setting the font size to 12px
  },

  // Styles for the disabled state
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "black", // Color of the text in disabled state
    fontWeight: 400, // Font weight in disabled state
    fontSize: '12px !important', // Ensure font size is consistent in disabled state
  },

  // Styles for the root of the InputBase component
  '& .MuiInputBase-root': {
    height: '34px',
    padding: '8px 0px',
  },

  // Styles for the fieldset
  '& fieldset': {
    borderRadius: '4px',
  },
});
