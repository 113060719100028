import { FormControl, InputLabel, Button, MenuItem, Select } from "@material-ui/core";
import { ShowController, useRedirect, useRefresh, Show }  from "react-admin";
import React, { useEffect } from "react";
import { PageCategoryEnum, recordPageVisit } from "../../config/Rudderstack";
import notification from "../../notifications/notifications";
import { CommunicationService } from "../../providers/data/services/CommunicationService";
import { hasPermission } from "../../providers/auth/authUtils";

const AlertDetails = (props) => {
    const [infos, setInfos] = React.useState([]);

    useEffect(() => {
        let info = [];
        if (props?.record?.info) {
            let map = props.record.info;
            for (let [key, value] of Object.entries(map)) {
                key = key.replace(/([A-Z]+)/g, " $1").replace(/^\w/, (c) => c.toUpperCase()).replace(/([A-Z][a-z])/g, " $1");   
                info.push(<li>{key} : {value}</li>);
            }
            setInfos(info);
        }
    }, [props.record?.alertId])

    return (
      <div>
        <h3 className="bg-zorpBlack py-2 pl-2 rounded-md font-bold text-white text-xl">
          Alert Details
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 pt-2 gap-y-4 gap-x-8">
          <div className="flex rounded-md bg-gray-100  pt-3 pb-3 justify-between space-x-4 pl-2 pr-2">
            <p className="font-bold text-left pr-8">Alert Id:</p>
            <p className="text-right">{props?.record?.alertId}</p>
          </div>
          <div className="flex rounded-md bg-gray-100  pt-3 pb-3 justify-between space-x-4 pl-2 pr-2">
            <p className="font-bold text-left pr-8">Created On:</p>
            <p className="text-right">{props?.record?.createdOn}</p>
          </div>
          <div className="flex rounded-md bg-gray-100  pt-3 pb-3 justify-between space-x-4 pl-2 pr-2">
            <p className="font-bold text-left pr-8">Status:</p>
            <p className="text-right">{props?.record?.status}</p>
          </div>
          <div className="flex rounded-md bg-gray-100  pt-3 pb-3 justify-between space-x-4 pl-2 pr-2">
            <p className="font-bold text-left pr-8">Message:</p>
            <p className="text-right">{props?.record?.message}</p>
          </div>
          <div className="flex rounded-md bg-gray-100  pt-3 pb-3 justify-between space-x-4 pl-2 pr-2">
            <p className="font-bold text-left pr-8">Entity:</p>
            <p className="text-right">{props?.record?.entity}</p>
          </div>
          <div className="flex rounded-md bg-gray-100  pt-3 pb-3 justify-between space-x-4 pl-2 pr-2">
            <p className="font-bold text-left pr-8">Entity Id:</p>
            <p className="text-right">{props?.record?.entityId}</p>
          </div>
          <div className="flex rounded-md bg-gray-100  pt-3 pb-3 justify-between space-x-4 pl-2 pr-2">
            <p className="font-bold text-left pr-8">Teams: </p>
            <p className="text-right">{props?.record?.teamsInfo}</p>
          </div>
          <div className="flex rounded-md bg-gray-100  pt-3 pb-3 justify-between space-x-4 pl-2 pr-2">
            <p className="font-bold text-left pr-8">Other Information: </p>
            <p className="text-right"><ul style={{textAlign:"left"}}>{infos}</ul></p>
          </div>
        </div>
      </div>
    );
  };


  const AlertSwitches = (props) => {
    const refresh = useRefresh();
    const redirect = useRedirect();
    const [id, setId] = React.useState(props?.record?.id);
    const [status, setStatus] = React.useState(props?.record?.status);
    const [statusDisabled, setStatusDisabled] = React.useState(true);
   
    const handleStatusChange = (e) => {
      if (e.target.value !== props?.record?.status) {
        setStatus(e.target.value);
        setStatusDisabled(false);
      } else {
        setStatus(e.target.value);
        setStatusDisabled(true);
      }
    };
  
    const statusChange = () => {
      // Logic to connect with the API to change the status.
      var user = {
        id: id,
        status: status,
      };
  
      CommunicationService.updateStatus(user)
        .then((res) => {
          if (res.code === "200") {
            notification("success", res?.message);
            redirect(`/alerts/${id}/show`);
            refresh();
            setStatusDisabled(true);
          } else {
            notification("error", res?.message);
            redirect(`/alerts/${id}/show`);
            refresh();
          }
        })
        .catch((err) => {
          console.log(err);
          return err;
        });
    };
  
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-4 gap-x-8">
        <div className="flex flex-col sm:flex-row justify-between items-center rounded-md bg-gray-100  pt-3 pb-3 space-x-4 pl-2 pr-2">
          <div className="flex-grow">
            <FormControl variant="filled" size="small" style={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-filled-label">
                Alert Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                disabled={!hasPermission("alert", "update") && !hasPermission("alert", "edit")}
                value={status}
                onChange={(e) => handleStatusChange(e)}
              >
                <MenuItem value={"NEW"}>NEW</MenuItem>
                <MenuItem value={"DONE"}>DONE</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <Button
              onClick={statusChange}
              disabled={statusDisabled}
              variant="outlined"
              color="secondary"
              size="large"
              style={{backgroundColor: "#3E66FB",color: "white",}}
            >
              Change Status
            </Button>
          </div>
        </div>
      </div>
    );
  };
  
  export const AlertShow = (props) => {
  
    useEffect(() => {
      recordPageVisit(PageCategoryEnum.user, "alert_details");
    }, [props]);
  
    return (
      <div className="showTaskParentDiv">
        <ShowController {...props} component="div">
          {(controllerProps) => {
            return (
              <Show {...props}  {...controllerProps}>
                <div className="flex flex-col p-4">
                  <div>
                    <AlertDetails {...controllerProps} />
                  </div>
                  <div className="py-4">
                    <AlertSwitches {...controllerProps} />
                  </div>
                </div>
              </Show>
            );
          }}
        </ShowController>
      </div>
    );
  };