import { AxiosResponse } from "axios";
import HttpUtil from "../../../config/AxiosConfig";
import { ZorpResponse } from "../../../providers/data/models/ZorpResponse";
import { getConfig as Config } from "../../../config/envConfig/Config";

export class TaskService {
  public static getTaskTypeMeta(
    accountId: string,
    taskType: string
  ): Promise<ZorpResponse | undefined> {
    const payload = {
      accountId: accountId,
      taskType: taskType,
    };

    return HttpUtil.taskInstance
      .post("/task/type/flow", payload)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }

  public static getTaskTypes(
    showInternalCreationAlso?: boolean,
    isActive?: boolean
  ): Promise<ZorpResponse | Error> {
    return (
      HttpUtil.taskInstance
        //  isActive
        .post("/task/type/list", {
          showInternalCreationAlso: showInternalCreationAlso ? true : false,
          isActive: isActive,
        })
        .then((response: AxiosResponse) => {
          return response.data as ZorpResponse;
        })
        .catch((e) => {
          console.log(e);
          return e;
        })
    );
  }

  public static getTaskTypeFlowStatuses(): Promise<ZorpResponse | Error> {
    return HttpUtil.taskInstance
      .post("/task/type/statuses", {})
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((e) => {
        console.log(e);
        return e;
      });
  }

  // get list of published workflow list for the monitor creation
  public static getWorkflowList(accountId:string): Promise<ZorpResponse | Error> {
    const getWorkFlowListUrl = Config("services.taskSvc.api.getWorkflowList")
    return HttpUtil.taskInstance
      .post(getWorkFlowListUrl, {
        accountId:accountId
      })
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((e) => {
        console.log(e);
        return e;
      });
  }

  public static createTask(task: any): Promise<ZorpResponse | Error> {
    const preview = localStorage.getItem("isPreview") ?? false;
    task = { ...task, preview: preview };
    return HttpUtil.bffInstance
      .post("/task", task)
      .then((response: any) => {
        return (response.data || response.response.data) as ZorpResponse;
      })
      .catch((e) => {
        console.log(e);
        return e;
      });
  }

  // public static getDateTasks(date: Date): Promise<ZorpResponse | Error> {
  //     const payLoad = {"date": date}
  //     return HttpUtil.taskInstance.post('http://13.127.89.126:8080', payLoad)
  //     .then((response: AxiosResponse) => {

  //     }).catch((err) => {
  //         console.log(err);
  //         return err;
  //     })
  // }

  public static getDateTasks(
    start?: string,
    end?: string
  ): Promise<ZorpResponse | Error> {
    var payLoad = {
      scheduledFromTime: start,
      scheduledToTime: end,
      // excludeDeleted: true,
    };
    return HttpUtil.taskInstance
      .post(`/task/search/`, payLoad)
      .then((response: AxiosResponse) => {
        // console.log("Response",response);
        return response.data as ZorpResponse;
      });
  }

  public static getTasksFromTaskType({ taskType }: { taskType: string }) {
    return HttpUtil.taskInstance
      .post(`/task/search`, { taskType: taskType,page:0, size:10000, excludeDeleted: true })
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }

  public static schedule(data: any): Promise<ZorpResponse | undefined> {
    var unassign = data?.unassign;
    let taskPayload = {
      taskId: data?.taskId ? data.taskId : null,
      event: data?.event ? data.event : null,
      formData: {
        scheduledSlot: data?.scheduledSlot ? data.scheduledSlot : null,
        userId: data?.userId ? data.userId : null,
        data: data?.data ? data.data : null,
      },
    };

    let PayLoad = {
      taskId: data?.taskId ? data.taskId : null,
      event: "UNSCHEDULE",
    };

    if (unassign === true) {
      let PayLoad1 = {
        taskId: data?.taskId ? data.taskId : null,
        event: "UNASSIGN",
      };

      return HttpUtil.taskInstance
        .post(`/task/transit`, PayLoad1)
        .then((res) => {
          return HttpUtil.taskInstance
            .post(`/task/transit`, PayLoad)
            .then((res) => {
              return HttpUtil.taskInstance
                .post(`/task/transit`, taskPayload)
                .then((response: AxiosResponse) => {
                  taskPayload = {
                    ...taskPayload,
                    event: "ASSIGN",
                  };
                  return HttpUtil.taskInstance
                    .post(`/task/transit`, taskPayload)
                    .then((response: AxiosResponse) => {
                      return response.data as ZorpResponse;
                    })
                    .catch((err) => {
                      console.log(err);
                      return err;
                    });
                })
                .catch((err) => {
                  console.log(err);
                  return err;
                });
            })
            .catch((err) => {
              console.log(err);
              return err;
            });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      return HttpUtil.taskInstance
        .post(`/task/transit`, PayLoad)
        .then((res: AxiosResponse) => {
          // console.log("Response - Unassign", res);
          return HttpUtil.taskInstance
            .post(`/task/transit`, taskPayload)
            .then((response: AxiosResponse) => {
              // console.log("Response - Assign", response)
              return response.data as ZorpResponse;
            })
            .catch((err) => {
              // console.log("Assign Err",err);
              return err;
            });
        })
        .catch((err) => {
          // console.log("Unassign - err",err);
          return err;
        });
    }
  }

  public static updateScheduledSlot(
    taskId: String,
    scheduledFrom: Date,
    scheduledTo: Date
  ): Promise<ZorpResponse | undefined> {
    let transitPayLoad = {
      taskId: taskId,
      event: "UPDATE_SLOT",
      channel: "CC",
      formData: {
        scheduledSlot: {
          from: scheduledFrom,
          to: scheduledTo,
        },
      },
    };
    return HttpUtil.taskInstance
      .post(`/task/transit`, transitPayLoad)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((err) => {
        console.log("Scheduled Slot change error", err);
        return err;
      });
  }

  public static updateTeamUser(
    taskId: String,
    userId: String,
    teamIds: String[]
  ): Promise<ZorpResponse | undefined> {
    let transitPayLoad = {
      taskId: taskId,
      event: "UPDATE_TEAM",
      channel: "CC",
      formData: {
        userId: userId,
        teamIds: teamIds,
      },
    };
    return HttpUtil.taskInstance
      .post(`/task/transit`, transitPayLoad)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((err) => {
        console.log("Assign error", err);
        return err;
      });
  }

  public static updateTeamUserEvent(taskId: String, userId: String, teamIds: String[], event: String): Promise<ZorpResponse | undefined> {
    let transitPayLoad = {
      taskId: taskId,
      event: event,
      channel :"CC",
      formData: {
        "userId": userId,
        "teamIds": teamIds
      }
    };
    return HttpUtil.taskInstance
      .post(`/task/transit`, transitPayLoad)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((err) => {
        console.log("Assign error", err);
        return err;
      });
  }

  public static assign(data: any): Promise<ZorpResponse | undefined> {
    var unassign = data?.unassign;
    var assign = data?.assign;
    // console.log("Assign Func", unassign);
    let PayLoad = {
      taskId: data?.taskId ? data.taskId : null,
      event: "UNASSIGN",
    };

    let taskPayload = {
      taskId: data?.taskId ? data.taskId : null,
      event: data?.event ? data.event : null,
      formData: {
        scheduledSlot: data?.scheduledSlot ? data.scheduledSlot : null,
        userId: data?.userId ? data.userId : null,
        data: data?.data ? data.data : null,
      },
    };
    if (unassign === true && assign === true) {
      console.log("Unassign - 2");
      return HttpUtil.taskInstance
        .post(`/task/transit`, PayLoad)
        .then((res: AxiosResponse) => {
          return HttpUtil.taskInstance
            .post(`/task/transit`, taskPayload)
            .then((response: AxiosResponse) => {
              return response.data as ZorpResponse;
            })
            .catch((err) => {
              console.log("Assign Err", err);
              return err;
            });
        })
        .catch((err) => {
          console.log("Unassign - err", err);
          return err;
        });
    } else {
      if (unassign === false && assign === true) {
        return HttpUtil.taskInstance
          .post(`/task/transit`, taskPayload)
          .then((response: AxiosResponse) => {
            return response.data as ZorpResponse;
          })
          .catch((err) => {
            console.log("Assign Err", err);
            return err;
          });
      }

      return HttpUtil.taskInstance
        .post(`/task/transit`, PayLoad)
        .then((response: AxiosResponse) => {
          return response.data as ZorpResponse;
        })
        .catch((err) => {
          console.log("Assign Err", err);
          return err;
        });
    }
  }

  public static updateTask(task: any): Promise<any | undefined> {
    if (task?.scheduleFlag) {
      // console.log("Scheduling Edit");
      task = { ...task, event: "SCHEDULE" };
      var data = task;
      var unassign = data?.unassign;
      let taskPayload = {
        taskId: data?.taskId ? data.taskId : null,
        event: data?.event ? data.event : null,
        formData: {
          scheduledSlot: data?.scheduledSlot ? data.scheduledSlot : null,
          userId: data?.userId ? data.userId : null,
          data: data?.data ? data.data : null,
        },
      };

      let PayLoad = {
        taskId: data?.taskId ? data.taskId : null,
        event: "UNSCHEDULE",
      };

      if (unassign === true) {
        let PayLoad1 = {
          taskId: data?.taskId ? data.taskId : null,
          event: "UNASSIGN",
        };

        return HttpUtil.taskInstance
          .post(`/task/transit`, PayLoad1)
          .then((res) => {
            return HttpUtil.taskInstance
              .post(`/task/transit`, PayLoad)
              .then((res) => {
                return HttpUtil.taskInstance
                  .post(`/task/transit`, taskPayload)
                  .then((response: AxiosResponse) => {
                    taskPayload = {
                      ...taskPayload,
                      event: "ASSIGN",
                    };
                    return HttpUtil.taskInstance
                      .post(`/task/transit`, taskPayload)
                      .then((response: AxiosResponse) => {
                        return HttpUtil.bffInstance.put(`/task`, task);
                        // .then((res) => {
                        //   return res.data as ZorpResponse;
                        // })
                        // .catch((err) => {
                        //   console.log(err);
                        //   return err;
                        // });
                        // return response.data as ZorpResponse;
                      })
                      .catch((err) => {
                        console.log(err);
                        return err;
                      });
                  })
                  .catch((err) => {
                    console.log(err);
                    return err;
                  });
              })
              .catch((err) => {
                console.log(err);
                return err;
              });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        return HttpUtil.taskInstance
          .post(`/task/transit`, PayLoad)
          .then((res: AxiosResponse) => {
            // console.log("Response - Unassign", res);
            return HttpUtil.taskInstance
              .post(`/task/transit`, taskPayload)
              .then((response: AxiosResponse) => {
                return HttpUtil.bffInstance.put(`/task`, task);
                // .then((res) => {
                //   return res.data as ZorpResponse;
                // })
                // .catch((err) => {
                //   console.log(err);
                //   return err;
                // });
              })
              .catch((err) => {
                // console.log("Assign Err",err);
                return err;
              });
          })
          .catch((err) => {
            // console.log("Unassign - err",err);
            return err;
          });
      }
    }
    return HttpUtil.bffInstance.put(`/task`, task).then((res) => {
      return res.data as ZorpResponse;
    });
    // .catch((err) => {
    //   console.log(err);
    //   return err;
    // });
  }

  public static getTask(taskId: string): Promise<any | undefined> {
    return HttpUtil.bffInstance.get(`/task/${taskId}`);
  }

  public static scheduleAssign(data: any): Promise<ZorpResponse | undefined> {
    var unassign = data?.unassign;
    var assign = data?.assign;
    let taskPayload = {
      taskId: data?.taskId ? data.taskId : null,
      event: data?.event ? data.event : null,
      formData: {
        scheduledSlot: data?.scheduledSlot ? data.scheduledSlot : null,
        userId: data?.userId ? data.userId : null,
        data: data?.data ? data.data : null,
      },
    };

    let PayLoad = {
      taskId: data?.taskId ? data.taskId : null,
      event: "UNSCHEDULE",
    };

    let PayLoad1 = {
      taskId: data?.taskId ? data.taskId : null,
      event: "UNASSIGN",
    };

    if (unassign === true && assign === true) {
      return HttpUtil.taskInstance
        .post(`/task/transit`, PayLoad1)
        .then((res) => {
          return HttpUtil.taskInstance
            .post(`/task/transit`, PayLoad)
            .then((res) => {
              taskPayload = {
                ...taskPayload,
                event: "SCHEDULE",
              };
              return HttpUtil.taskInstance
                .post(`/task/transit`, taskPayload)
                .then((response: AxiosResponse) => {
                  taskPayload = {
                    ...taskPayload,
                    event: "ASSIGN",
                  };
                  return HttpUtil.taskInstance
                    .post(`/task/transit`, taskPayload)
                    .then((response: AxiosResponse) => {
                      return response.data as ZorpResponse;
                    })
                    .catch((err) => {
                      console.log(err);
                      return err;
                    });
                })
                .catch((err) => {
                  console.log(err);
                  return err;
                });
            })
            .catch((err) => {
              console.log(err);
              return err;
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (unassign === false && assign === true) {
      return HttpUtil.taskInstance
        .post(`/task/transit`, PayLoad)
        .then((res: AxiosResponse) => {
          taskPayload = {
            ...taskPayload,
            event: "SCHEDULE",
          };
          return HttpUtil.taskInstance
            .post(`/task/transit`, taskPayload)
            .then((response: AxiosResponse) => {
              taskPayload = {
                ...taskPayload,
                event: "ASSIGN",
              };
              return HttpUtil.taskInstance
                .post(`/task/transit`, taskPayload)
                .then((response: AxiosResponse) => {
                  return response.data as ZorpResponse;
                })
                .catch((err) => {
                  console.log(err);
                  return err;
                });
            })
            .catch((err) => {
              console.log(err);
              return err;
            });
        })
        .catch((err) => {
          console.log(err);
          return err;
        });
    }

    return HttpUtil.taskInstance
      .post(`/task/transit`, PayLoad1)
      .then((res: AxiosResponse) => {
        return HttpUtil.taskInstance
          .post(`/task/transit`, PayLoad)
          .then((response: AxiosResponse) => {
            taskPayload = {
              ...taskPayload,
              event: "SCHEDULE",
            };
            return HttpUtil.taskInstance
              .post(`/task/transit`, taskPayload)
              .then((response: AxiosResponse) => {
                return response.data as ZorpResponse;
              })
              .catch((err) => {
                console.log(err);
                return err;
              });
          })
          .catch((err) => {
            console.log(err);
            return err;
          });
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  public static deleteTaskLegacy(
    taskId?: string
  ): Promise<ZorpResponse | undefined> {
    let taskPayload = {
      taskId: taskId,
      event: "DELETE",
    };

    return HttpUtil.taskInstance
      .post(`/task/transit`, taskPayload)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((err) => {
        return err;
      });
  }

  public static deleteTask(taskId?: string): Promise<ZorpResponse | undefined> {
    return HttpUtil.taskInstance
      .post(`/task/delete/${taskId}`)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((err) => {
        return err;
      });
  }

  public static deleteBulkTask(
    taskIds?: any
  ): Promise<ZorpResponse | undefined> {
    let taskPayload = {
      taskIds: taskIds,
    };

    return HttpUtil.taskInstance
      .post(`/task/bulk/delete`, taskPayload)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((err) => {
        return err;
      });
  }

  public static getTaskTypeDataFields(
    taskTypes: any
  ): Promise<ZorpResponse | undefined> {
    return HttpUtil.taskInstance
      .post(`/entitydatamodel/list`, {
        entity: "TASK",
        taskType: taskTypes,
      })
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }

  public static checkWorkflowStatus(
    taskId: string
  ): Promise<ZorpResponse | undefined> {
    return HttpUtil.taskInstance
      .post(`task/type/flow`, {
        taskType: taskId,
      })
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      });
  }

  public static getTaskLogs(taskId: string): Promise<ZorpResponse | undefined> {
    return HttpUtil.taskInstance
      .get(`/task/find_with_logs?taskId=${taskId}`)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((err) => {
        return err;
      });
  }
}
