import React, { useEffect } from 'react';
import Select from 'react-select';
import { TeamService } from '../../providers/data/services/TeamService';

const Teams = (props) => {
  const [teams, setTeams] = React.useState([]);
  const [selectedTeam, setSelectedTeam] = React.useState(props.team);
  const [disabled, setDisabled] = React.useState(true);

  useEffect(() => {
    setDisabled(props.disabled);
    setSelectedTeam(props.type);
  }, [props.type, props.disabled, props.size, props.label, props.variant]);

  useEffect(() => {
    setSelectedTeam(props.team || selectedTeam);
  }, [props.team, selectedTeam]);

  useEffect(() => {
    const teamsResponse = TeamService.listTeams({ filter: {}, pagination: { page: 1, perPage: 3000 } });
    teamsResponse
      .then((res) => {
        var teams = [];
        res?.data?.teams
          .filter((team) => !props.donotShowTeamId || props.donotShowTeamId !== team?.teamId)
          .map((team) => {
          teams.push({
            value: team?.teamId,
            label: team?.name + ' (' + team?.teamId + ')',
          });
        });
        setTeams(teams);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChange = (event) => {
    setSelectedTeam(event?.value);
    props.onChange(event?.value);
  };


  return (
    <>
      {
        (selectedTeam || teams) &&
        <>
          <Select
            value={teams.find((c) => c.value === selectedTeam)}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            isSearchable
            isClearable
            disabled={disabled}
            onChange={handleChange}
            placeholder='Select Team'
            name="select-team"
            menuPosition={"fixed"}
            menuPlacement={"bottom"}
            options={teams}
          />
        </>
      }
    </>
  );
};

export default Teams;
