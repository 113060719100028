import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import L from 'leaflet';
import React, { useEffect } from 'react';
import { Marker, Popup } from 'react-leaflet';
import { useHistory } from 'react-router-dom';
import { getLocalizedTime } from 'utils/DateFunctions.utils';
import { builderTaskLocation, filterTasks, pickupLocationTasks } from 'utils/TasktypeUtils';
import { TaskService } from '../../providers/data/services/TaskService';
import { ModifyScheduleSlot } from '../tasks/screens/task/edit/ModifyScheduleSlot';
import { ModifyTeamUser } from '../tasks/screens/task/edit/ModifyTeamUser';
import iconAssignedOverdue from './task_assigned_overdue.png';
import iconCompleted from './task_completed.png';
import iconInprogress from './task_inprogress.png';
import iconNew from './task_new.png';
import iconNewAssigned from './task_new_assigned.png';
import iconNewOverdue from './task_new_overdue.png';

let taskNewIcon = L.icon({
  iconUrl: iconNew,
  iconSize: [20, 30],
  // shadowUrl: iconShadow
});

let taskNewSelectedIcon = L.icon({
  iconUrl: iconNew,
  iconSize: [30, 40],
  // shadowUrl: iconShadow,
});

let taskNewOverdueIcon = L.icon({
  iconUrl: iconNewOverdue,
  iconSize: [20, 30],
  // shadowUrl: iconShadow
});

let taskNewOverdueSelectedIcon = L.icon({
  iconUrl: iconNewOverdue,
  iconSize: [30, 40],
  // shadowUrl: iconShadow,
});

let taskNewAssignedIcon = L.icon({
  iconUrl: iconNewAssigned,
  iconSize: [20, 30],
  // shadowUrl: iconShadow,
});

let taskNewAssignedSelectedIcon = L.icon({
  iconUrl: iconNewAssigned,
  iconSize: [30, 40],
  // shadowUrl: iconShadow,
});

let taskAssignedOverdueIcon = L.icon({
  iconUrl: iconAssignedOverdue,
  iconSize: [20, 30],
  // shadowUrl: iconShadow,
});

let taskAssignedOverdueSelectedIcon = L.icon({
  iconUrl: iconAssignedOverdue,
  iconSize: [30, 40],
  // shadowUrl: iconShadow,
});

let taskInprogressIcon = L.icon({
  iconUrl: iconInprogress,
  iconSize: [20, 30],
  // shadowUrl: iconShadow
});

let taskInprogressSelectedIcon = L.icon({
  iconUrl: iconInprogress,
  iconSize: [30, 40],
  // shadowUrl: iconShadow,
});

let taskCompletedIcon = L.icon({
  iconUrl: iconCompleted,
  iconSize: [20, 30],
  // shadowUrl: iconShadow
});

let taskCompletedSelectedIcon = L.icon({
  iconUrl: iconCompleted,
  iconSize: [30, 40],
  // shadowUrl: iconShadow,
});

// L.Marker.prototype.options.icon = DefaultIcon;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '25vw',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

export default function MapTaskList(props) {
  // const pickupLocationTasks = ['porter_order_pickup_task', 'porter_order_return_task', 'towing_service'];
  // const builderTaskLocation = new Map();
  // builderTaskLocation.set('wgpaq1' ,  'Customer_Location');
  // const filteredTasks = ['inventory_pickup_task', 'smoor_order_pickup_task'];

  const history = useHistory();
  const classes = useStyles();

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [editEvent, setEditEvent] = React.useState();
  const [refresh, setRefresh] = React.useState();
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const [tasks, setTasks] = React.useState([]);
  const [taskIndexMap, setTaskIndexMap] = React.useState(new Map());
  const [unassignedTaskCardList, setUnassignedTaskCardList] = React.useState([]);
  const [assignedTaskCardList, setAssignedTaskCardList] = React.useState([]);
  const [inprogressTaskCardList, setInprogressTaskCardList] = React.useState([]);
  const [completedTaskCardList, setCompletedTaskCardList] = React.useState([]);
  const [selectedTask, setSelectedTask] = React.useState(null);
  const [expanded, setExpanded] = React.useState('panel1');
  const [dialogListShow, setDialogListShow] = React.useState({
    reschedule: true,
    changeDriver: true,
    details: true,
  });

  const [openChangeScheduleSlot, setOpenChangeScheduleSlot] = React.useState(false);
  const [openTeamUserChange, setOpenTeamUserChange] = React.useState(false);

  const handleScheduleSlot = () => {
    setOpenChangeScheduleSlot(true);
  };

  const handleUpdateTeamUserClick = () => {
    setOpenTeamUserChange(true);
  };

  const closeModification = (cancelled) => {
    setOpenChangeScheduleSlot(false);
    setOpenTeamUserChange(false);
    setDialogOpen(false);
    if (!cancelled && cancelled !== true) {
      setRefresh(!refresh);
    }
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleListItemClick = (event, index, tasks, task) => {

    setSelectedTask(task);
    setSelectedIndex(index);
    props.onTaskSelect(task);
  };

  const [assignedOrUnassignedTask, setAssignedOrUnassignedTask] = React.useState();

  let params = {};
  let filter = {};
  let pagination = {};
  let sort = {};
  sort.field = 'scheduledSlot.from';
  sort.order = 'ASC';
  pagination.page = 1;
  pagination.perPage = 1000;
  // filter.search = "OPEN_STATES_ONLY";
  // filter.excludeDeleted = true;
  filter.scheduledFromTime = props.selectedDateStartTime;
  filter.scheduledToTime = props.selectedDateEndTime;
  filter.teamIds = props.selectedTeam ? [props.selectedTeam] : undefined;
  params.filter = filter;
  params.pagination = pagination;
  params.sort = sort;

  useEffect(() => {
    setRefresh(props.refresh);
  }, [props.refresh]);

  useEffect(() => {
    setAssignedOrUnassignedTask(props.assignedOrUnassignedTask);
  }, [props.assignedOrUnassignedTask]);

  useEffect(() => {
    async function fetchData() {
      setTasks([]);
      setSelectedTask(null);
      setSelectedIndex(-1);
      setUnassignedTaskCardList([]);
      setAssignedTaskCardList([]);
      setCompletedTaskCardList([]);
      setInprogressTaskCardList([]);
      props.onTaskSelect(null);

      const response = await TaskService.listTasks(params);
      // console.log("Response", response)
      if (response && response.code === '200') {
        const tasksTemp = response?.data?.tasks;
        let fTasksTemp = filterTasks(tasksTemp);
        setTasks(fTasksTemp);
        buildTaskCards(fTasksTemp, -1);
        handleMarkers(fTasksTemp, null);

        if (assignedOrUnassignedTask) {
          let selTask = getTaskById(fTasksTemp, assignedOrUnassignedTask.taskId);

          setExpanded(assignedOrUnassignedTask.user ? 'panel1' : 'panel2');
          setSelectedTask(selTask);
          if (selTask) {
            setSelectedIndex(taskIndexMap.get(selTask.taskId));
          } else {
            setSelectedIndex(-1);
          }
          props.onTaskSelect(selTask);
        }
      }
    }

    fetchData();
  }, [props.selectedDateStartTime, props.selectedDateEndTime, assignedOrUnassignedTask, props.selectedTeam]);

  useEffect(() => {
    async function fetchData() {
      setTasks([]);
      setSelectedTask(null);
      setSelectedIndex(-1);
      setUnassignedTaskCardList([]);
      setAssignedTaskCardList([]);
      setCompletedTaskCardList([]);
      setInprogressTaskCardList([]);
      props.onTaskSelect(null);

      const response = await TaskService.listTasks(params);
      if (response && response.code === '200') {
        const tasksTemp = response?.data?.tasks;
        let fTasksTemp = filterTasks(tasksTemp);

        buildTaskCards(fTasksTemp, -1);
        setTasks(fTasksTemp);
        handleMarkers(fTasksTemp, null);
        setExpanded('panel1');
      }
    }

    fetchData();
  }, [refresh]);

  useEffect(() => {
    if (selectedTask) {
      buildTaskCards(tasks, selectedIndex);
    }
    if (selectedTask && selectedTask.taskInTerminalState === true) {
      handleMarkers(tasks, selectedTask);
    }
  }, [selectedIndex]);

  function getTaskById(tasks, taskId) {
    let t = null;

    for (let i = 0; i < tasks.length; i++) {
      if (tasks[i].taskId === taskId) {
        t = tasks[i];
        break;
      }
    }

    return t;
  }

  // function filterTasks(tasks) {
  //   let fTasks = [];

  //   tasks.forEach((task) => {
  //     if ((task?.data[0]?.info && !filteredTasks.includes(task.taskType)) || builderTaskLocation.has(task.taskType)) {
  //       fTasks.push(task);
  //     }
  //   });

  //   return fTasks;
  // }

  const buildTaskCards = (tasks, sIndex) => {
    let cardIndex = -1;
    let unassignedTasks = getUnassignedTasks(tasks);
    let unassignedTaskCardList = getTaskCardList(unassignedTasks, tasks, cardIndex, sIndex);

    cardIndex = cardIndex + unassignedTasks.length;
    let assignedTasks = getAssignedTasks(tasks);
    let assignedTaskCardList = getTaskCardList(assignedTasks, tasks, cardIndex, sIndex);

    cardIndex = cardIndex + assignedTasks.length;
    let inprogressTasks = getInprogressTasks(tasks);
    let inprogressTaskCardList = getTaskCardList(inprogressTasks, tasks, cardIndex, sIndex);

    cardIndex = cardIndex + inprogressTasks.length;
    let completedTasks = getCompletedTasks(tasks);
    let completedTaskCardList = getTaskCardList(completedTasks, tasks, cardIndex, sIndex);

    setUnassignedTaskCardList(unassignedTaskCardList);
    setAssignedTaskCardList(assignedTaskCardList);
    setInprogressTaskCardList(inprogressTaskCardList);
    setCompletedTaskCardList(completedTaskCardList);
  };

  function getTaskCardList(tasks, allTasks, cardIndex, sIndex) {
    let taskCardList = [];
    for (let index = 0; index < tasks.length; index++) {
      cardIndex++;
      taskCardList.push(buildCard(cardIndex, tasks[index], allTasks, sIndex));
    }
    return taskCardList;
  }

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleMenuOpen = (e, taskDetails) => {
    e.stopPropagation();
    const event = {
      start: new Date(taskDetails?.scheduledSlot?.from),
      end: new Date(taskDetails?.scheduledSlot?.to),
      resourceId: taskDetails?.userId ? taskDetails?.userId : null,
      id: taskDetails?.taskId,
      teamIds: taskDetails?.teamIds,
    };

    if (taskDetails.taskInTerminalState) {
      setDialogListShow({
        ...dialogListShow,
        reschedule: false,
        changeDriver: false,
      });
    } else {
      setDialogListShow({
        reschedule: true,
        changeDriver: true,
        details: true,
      });
    }

    setEditEvent(event);
    setOpenChangeScheduleSlot(false);
    setOpenTeamUserChange(false);
    setDialogOpen(true);
    // setAnchorEl(e.currentTarget);
  };

  const redirect = () => {
    // setAnchorEl(null);
    setDialogOpen(false);
    history.push(`tasks/${editEvent.id}/show`);
  };

  const buildCard = (cardIndex, task, allTasks, sIndex) => {
    taskIndexMap.set(task.taskId, cardIndex);
    setTaskIndexMap(taskIndexMap);
    const taskDetails = task;
    let slot =
      getLocalizedTime(task?.scheduledSlot?.from) +
      ' TO ' +
      getLocalizedTime(task?.scheduledSlot?.to);
    let text =
      task?.taskTypeDisplayName?.slice(0, task?.taskTypeDisplayName?.length - 5) + ' ( # ' + task?.taskId + ' )';
    let secondaryText = task?.data[0]?.info?.orderId ? ('Order # ' + task?.data[0]?.info?.orderId) : "";
    let location = pickupLocationTasks.includes(task?.taskType)
      ? task?.data[0]?.info?.pickup?.location
      : (builderTaskLocation.has(task?.taskType) ?  task?.data[builderTaskLocation.get(task?.taskType)] : task?.data[0]?.info?.customer?.location);
    return (
      <List
        style={{
          display: 'flex',
          flexDirection: 'row',
          padding: 0,
          width: '100%',
        }}
      >
        <ListItem
          key={cardIndex}
          button
          selected={sIndex === cardIndex}
          style={{
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
            marginBottom: 12,
            width: '100%',
            borderRadius: '2%',
          }}
          onClick={(event) => handleListItemClick(event, cardIndex, allTasks, task)}
        >
          <div className='flex flex-col w-full'>
            <div className='flex flex-row justify-between'>
              <div className=''>
                <span className='text-xs bg-green-600 px-1 text-white rounded-md'>{slot}</span>
              </div>
              <div style={{ float: 'right' }}>
                <span className='text-xs bg-orange px-1 text-white rounded-md'>{task.taskStatusName}</span>
              </div>
              <div>
                <IconButton
                  aria-controls='simple-menu'
                  aria-haspopup='true'
                  style={{ marginRight: '-24px', marginTop: '-16px' }}
                  onClick={(e) => handleMenuOpen(e, taskDetails)}
                >
                  <MoreVertIcon />
                </IconButton>
              </div>
            </div>
            <div className='flex flex-col' style={{ marginTop: '-5px' }}>
              <div>
                <span className='text-xs font-bold'>{text}</span>
              </div>
              <div>
                <span className='text-xs '>{secondaryText}</span>
              </div>
              <div>
                {task?.data[0]?.info?.customer &&
                (<span className='text-sm'>
                  {task?.data[0]?.info?.customer?.name} ({task?.data[0]?.info?.customer?.phone?.countryCode}
                  {task?.data[0]?.info?.customer?.phone?.num})
                </span>)}
                {task?.data?.Customer_Name &&
                (<span className='text-sm'>
                  {task?.data?.Customer_Name} ({task?.data?.Customer_Mobile})
                </span>)}
              </div>
              <div>
                <span className='text-xs	'>{location?.address}</span>
              </div>
            </div>
          </div>
        </ListItem>
      </List>
    );
  };

  function getUnassignedTasks(allTasks) {
    let unassignedTasks = [];
    for (let index = 0; index < allTasks.length; index++) {
      if (!allTasks[index].userId && !allTasks[index].taskInTerminalState) {
        unassignedTasks.push(allTasks[index]);
      }
    }

    return unassignedTasks;
  }

  function getAssignedTasks(allTasks) {
    let assignedTasks = [];
    for (let index = 0; index < allTasks.length; index++) {
      if (allTasks[index].userId !== null && allTasks[index].taskInInitialState) {
        assignedTasks.push(allTasks[index]);
      }
    }

    return assignedTasks;
  }

  function getInprogressTasks(allTasks) {
    let inprogressTasks = [];
    for (let index = 0; index < allTasks.length; index++) {
      if (!allTasks[index].taskInInitialState && !allTasks[index].taskInTerminalState) {
        inprogressTasks.push(allTasks[index]);
      }
    }

    return inprogressTasks;
  }

  function getCompletedTasks(allTasks) {
    let completedTasks = [];
    for (let index = 0; index < allTasks.length; index++) {
      if (allTasks[index].taskInTerminalState) {
        completedTasks.push(allTasks[index]);
      }
    }

    return completedTasks;
  }

  const handleMarkers = (tasks, selectedTask) => {
    let markers = [];
    let index = -1;

    tasks.forEach((task) => {
      index = index + 1;
      let assignedText = 'None';
      let taskSelected = selectedTask && task.taskId === selectedTask.taskId;
      let icon = null;
      switch (task.progress) {
        case 'NEW':
          icon = taskSelected === true ? taskNewSelectedIcon : taskNewIcon;
          break;
        case 'NEW-ASSIGNED':
          icon = taskSelected === true ? taskNewAssignedSelectedIcon : taskNewAssignedIcon;
          assignedText = task.user.name + ' ( ' + task.userId + ' )';
          break;
        case 'NEW-OVERDUE':
          icon = taskSelected === true ? taskNewOverdueSelectedIcon : taskNewOverdueIcon;
          break;
        case 'ASSIGNED-OVERDUE':
          icon = taskSelected === true ? taskAssignedOverdueSelectedIcon : taskAssignedOverdueIcon;
          assignedText = task?.user?.name + ' ( ' + task?.userId + ' )';
          break;
        case 'INPROGRESS':
          icon = taskSelected === true ? taskInprogressSelectedIcon : taskInprogressIcon;
          assignedText = task?.user?.name + ' ( ' + task?.userId + ' )';
          break;
        case 'COMPLETED':
          icon = taskSelected === true ? taskCompletedSelectedIcon : taskCompletedIcon;
          assignedText = task?.user?.name + ' ( ' + task?.userId + ' )';
          break;
        default:
          icon = taskSelected === true ? taskNewSelectedIcon : taskNewIcon;
          break;
      }

      let orderInfo = task?.data[0]?.info;
      let location = pickupLocationTasks.includes(task?.taskType)
        ? task?.data[0]?.info?.pickup?.location
        : (builderTaskLocation.has(task?.taskType) ?  task?.data[builderTaskLocation.get(task?.taskType)] : task?.data[0]?.info?.customer?.location);
      markers.push(
        <Marker position={[location?.latitude || 0, location?.longitude || 0]} icon={icon} key={index}>
          <Popup closeButton={false}>
            Assigned To - <b>{assignedText}</b>
            <br />
            <br />
            {task.taskTypeDisplayName} - <b>{task.taskId}</b> (<b>{task.taskStatusName}</b>)
            {orderInfo?.customer &&
            (<span>
            <br /> Order # - <b>{orderInfo?.orderId ? orderInfo?.orderId : "NA"}</b>
            <br /> Customer Name - <b>{orderInfo?.customer?.name}</b>
            </span>)}
            <br /> Address - <b>{location?.address}</b>
            <br /> Location -{' '}
            <b>
              {location?.latitude || 0}, {location?.longitude || 0}
            </b>
          </Popup>
        </Marker>
      );
    });
    // console.log("Refreshing markers");
    props.setTaskMarkerList(markers);
  };

  return (
    <>
      <div className={classes.root}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon fontSize='inherit' />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            style={{ backgroundColor: '#e7e8e5' }}
          >
            <Typography className={classes.heading}>Unassigned Tasks ({unassignedTaskCardList.length})</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ width: '100%', height: '100%' }}>
            <div style={{ width: '100%' }}>{unassignedTaskCardList}</div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon fontSize='inherit' />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            style={{ backgroundColor: '#e7e8e5' }}
          >
            <Typography className={classes.heading}>Assigned Tasks ({assignedTaskCardList.length})</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ width: '100%', height: '100%' }}>
            <div style={{ width: '100%' }}>{assignedTaskCardList}</div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon fontSize='inherit' />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            style={{ backgroundColor: '#e7e8e5' }}
          >
            <Typography className={classes.heading}>Inprogress Tasks ({inprogressTaskCardList.length})</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ width: '100%', height: '100%' }}>
            <div style={{ width: '100%' }}>{inprogressTaskCardList}</div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon fontSize='inherit' />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            style={{ backgroundColor: '#e7e8e5' }}
          >
            <Typography className={classes.heading}>Completed Tasks ({completedTaskCardList.length})</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ width: '100%', height: '100%' }}>
            <div style={{ width: '100%' }}>{completedTaskCardList}</div>
          </AccordionDetails>
        </Accordion>
      </div>
      <Dialog onClose={handleDialogClose} aria-labelledby='task-details' open={dialogOpen}>
        <DialogTitle id='simple-dialog-title'>Choose Option</DialogTitle>
        <List>
          <ListItem button onClick={handleScheduleSlot}>
            {openChangeScheduleSlot && (
              <ModifyScheduleSlot
                taskId={editEvent.id}
                scheduledFrom={editEvent?.start ? editEvent.start : null}
                scheduledTo={editEvent?.end ? editEvent.end : null}
                onClose={closeModification}
              />
            )}
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <SwapHorizIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={'Reschedule'} />
          </ListItem>

          <ListItem button onClick={handleUpdateTeamUserClick}>
            {openTeamUserChange && (
              <ModifyTeamUser
                taskId={editEvent.id}
                userId={editEvent.resourceId}
                teamId={editEvent.teamIds[0]}
                onClose={closeModification}
              />
            )}
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <ScheduleIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={'Change Team/User'} />
          </ListItem>

          <ListItem button onClick={() => redirect()}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <AssignmentTurnedInIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={'Show Details'} />
          </ListItem>
        </List>
      </Dialog>
    </>
  );
}
