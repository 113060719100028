import { BsFillCaretRightFill, BsFillCaretDownFill } from 'react-icons/bs';
import {TreeView, TreeItem, TreeItemProps} from "@mui/x-tree-view"
import { capitalize } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { recordRSEvent } from 'utils/CommonUtils';
import { generateId } from 'utils/Utils';
import { SidePaneTemplateClick } from 'views/workflow-templates/constants/WTConstants';
import { addKeyValue } from 'views/workflow-templates/helpers/WT.helpers';
import { setSelectedTemplateLHS } from 'views/workflow-templates/reducers/WorkflowTemplate.actions';

const WTTemplatesTree = () => {
  const dispatch = useDispatch();

  const [parentTreeItem, setParentTreeItem] = useState<any>([]);

  const { workflowTemplateHome: { categories = {} } = {} } = useSelector((state: any) => state.workflowTemplates);

  useEffect(() => {
    const data = [];
    for (let category in categories) {
      data.push(category);
    }
    setParentTreeItem(data);
  }, [categories]);

  return (
    <TreeView
      aria-label='file system navigator'
      defaultCollapseIcon={<BsFillCaretDownFill />}
      defaultExpandIcon={<BsFillCaretRightFill />}
      sx={{ flexGrow: 1, maxWidth: 400, overflowY: 'scroll', p: 2.2 }}
    >
      {parentTreeItem.map((category: string, index: number) => {
        return (
          <TreeItem nodeId={generateId(6)} label={capitalize(category)}>
            {categories?.[category]?.map((variable: any) => {
              return (
                <TreeItem
                  nodeId={generateId(6)}
                  label={capitalize(variable?.name)}
                  onClick={() => {
                    recordRSEvent(
                      SidePaneTemplateClick.name,
                      addKeyValue(SidePaneTemplateClick, 'template_id', variable?.templateId)
                    );
                    dispatch(setSelectedTemplateLHS(variable?.templateId));
                  }}
                />
              );
            })}
          </TreeItem>
        );
      })}
    </TreeView>
  );
};

export default WTTemplatesTree;
