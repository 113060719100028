import React, { useEffect, useState } from "react"
import { WTModal } from "views/workflow-templates/workflow-template.styles"
import ZorpGPTNew from "./ZorpGPTNew"
import { useMediaQuery } from "@mui/material"
import { useDispatch } from "react-redux"
import { useTheme } from "styled-components"
import { fetchWorkflowTemplates } from "views/workflow-templates/reducers/WorkflowTemplate.actions"
import { useHistory, useLocation } from "react-router-dom"

type Props = {}

const ZorpGPTModal = (props: Props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()

  //   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = useState(location.pathname === "/zorp-gpt")

  useEffect(() => {
    dispatch(fetchWorkflowTemplates())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => {
    setOpen(false)
    history.push("/workflows")
  }

  const handleCloseMobile = (redirect: boolean) => {
    setOpen(false)
    redirect && history.push("/workflows")
  }

  return <ZorpGPTNew openModal={open} handleClose={handleClose} />
}

export default ZorpGPTModal
