import { AxiosResponse } from 'axios';
import HttpUtil from 'config/AxiosConfig';
import { ZorpResponse } from 'providers/data/models/ZorpResponse';

export class TeamService {
  private static validateStatus = (status: number) => {
    return status >= 200 && status < 500; // default
  };

  public static listTeams(params: any): Promise<ZorpResponse | undefined> {
    let queryParams = '';
    const filterData = params.filter;
    let payload: any = {};
    if (filterData && filterData.name) {
      payload.name = filterData.name;
    }

    if (filterData.teamIds) {
      payload.teamIds = filterData.teamIds;
    }

    const { page, perPage } = params.pagination;
    queryParams = queryParams + '&page=' + (page - 1) + '&size=' + perPage;

    return HttpUtil.teamInstance.post(`/teams?${queryParams}`, payload).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static createTeam(team: any): Promise<ZorpResponse | undefined> {
    return HttpUtil.teamInstance.post('/team', team).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static getTeam(teamId: any): Promise<ZorpResponse | undefined> {
    return HttpUtil.teamInstance.get(`/team/${teamId}`).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static updateTeam(teamId: String, team: any): Promise<ZorpResponse | undefined> {
    return HttpUtil.teamInstance.put(`/team/${teamId}`, team).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static deleteTeam(teamId: String): Promise<ZorpResponse | undefined> {
    return HttpUtil.teamInstance.delete(`/team/${teamId}`).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static findTeamNamesFromLocalStorage(teamIds: String[]) {
    // team names
    let taskTeams: any[] = [];
    if (teamIds && localStorage.getItem('teams')) {
      const localStorageTeams: any = JSON.parse(localStorage.getItem('teams') || '[]');
      teamIds.forEach((teamId) => {
        localStorageTeams
          .filter((team: any) => {
            return team.teamId === teamId;
          })
          .map((filteredTeam: any) => taskTeams.push(filteredTeam?.name + ' (' + filteredTeam?.teamId + ')'));
      });
    }
    return taskTeams;
  }
}
