export const CLOSE_EDITOR: string = 'Closing script editor';
export const CLOSE_AB: string = 'Closing automation builder';

export const DELETE_ACTION: string = 'Delete Action?';
export const DELETE_ACTION_CONFIRMATION: string = 'Do you want to delete Action ?';

export const DELETE_CONDITION: string = 'Delete Condition?';
export const DELETE_CONDITION_CONFIRMATION: string = 'Do you want to delete Condition ?';

export const CONTEXT_SWITCHING: string = 'Closing Configuration';
export const CONTEXT_SWITCHING_CONFIRMATION: string = 'You may have some unsaved changes, Do you want to proceed without saving ?';

export const AUTOMATION_SAVE: string = 'Save Confirmation';
export const AUTOMATION_SAVE_CONFIRMATION: string = 'You may have some unsaved changes, Do you want to proceed without saving ?';

export const DELETE_TRIGGER: string = 'Delete Trigger?';
export const DELETE_TRIGGER_CONFIRMATION: string = 'Do you want to delete Trigger ?';

export const DELETE_GUARD: string = 'Delete Validation?';
export const DELETE_GUARD_CONFIRMATION: string = 'Do you want to delete Validation ?';


export const DELETE_ACTION_BLOCK: string = 'Delete Action Block?';
export const DELETE_ACTION_BLOCK_CONFIRMATION: string = 'Do you want to delete Action Block ?';

export const DELETE_AUTOMATION_BLOCK: string = 'Delete Automation?';
export const DELETE_AUTOMATION_CONFIRMATION: string = "Do you want to delete this automation? This change can't be undone ";

export const CLOSE_CONFIRMATION: string = 'Are you sure you want to close?';
export const REMOVE_SCRIPT: string = 'Are you sure you want to remove string?';
export const REMOVE_SCRIPT_HEADING = 'Removing script';
export const RUN_BUTTON_DATA: string =
  'Execute the script using the test data. In case you need to change the test data, click on the "Update Test Data" button and Run the script again.  Result is provided in "Output" section';

export const FORCE_SAVE_WARNING: string = 'You are about to force save the script';

export const FORCE_SAVE_CONFIRMATION: string = 'Are you sure you want to force save the script?';
