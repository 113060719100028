import { useJSEditor } from "lowcode/hooks/useJSEditor.tsx";
import { setScriptEditorPayload } from "lowcode/state/scriptOpenState";
import { LowCodeInvocation } from "lowcode/state/types/types";
import { ZJSEditorLayout } from "lowcode/ZJSEditorLayout.tsx";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getLowCodeEditorDefaultReturnText, getLowCodeEditorText } from "utils/Utils";
import {
  deleteNode,
  setDataFieldType,
  setSelectedAction,
  setSelectedNode,
  setSelectedTransition,
  setShowCreateDatafieldModalSingle,
  setShowDataFieldEditModalSingle,
  updateHome,
  updateNodeName,
  setChangeInFlutter,
  setSelectedSystemTransition
} from "views/workflows/reducers/workflowAction";
import { getConfig } from "../../config/envConfig/Config";
import useWorkFlow from "./hooks/useWorkFlow";
import { WorkflowUtils } from "./WorkflowUtils";

const FlutterOtherConfig = ({saveWorkflow}) => {
  let isHomeNode = false;
  let canBeHomeNode = false;

  const dispatch = useDispatch();
  const url = getConfig("appDesigner");
  const accessToken = localStorage.getItem("at");

  const { openJSEditorLayout, scriptEditorOpen } = useJSEditor();
  const {
    elements: allNodes,
    accountId,
    selectedNode,
    taskTypeId,
    changesInFlutter,
    showTaskCardConfig
  } = useWorkFlow();

  const apiPayloadData = {
    "payload": {
      "inclusions": [],
      "exclusions": []
    }
  }


  const onMessageReceivedFromIframe = useCallback((event) => {

    if (
      event?.data?.source === "zorp_web" &&
      event?.data?.name === "save_state"
    ) {
      dispatch(updateNodeName(event.data.payload));
    } else if (
      event?.data?.source === "zorp_web" &&
      (event?.data?.name === "delete_state") &
        (event?.data?.stateId === selectedNode?.id)
    ) {
      dispatch(deleteNode(selectedNode));
    } else if (
      event?.data?.source === "zorp_web" &&
      event?.data?.name === "home_node"
    ) {
      dispatch(updateHome(event.data.stateId));
    } else if (
      event?.data?.source === "zorp_web" &&
      event?.data?.name === "open_jsScriptEditor"
    ) {
      const editorConfig = {
        script: event?.data.payload?.["script"] || getLowCodeEditorText(event?.data.payload?.["editorLocation"], event?.data.payload?.["dataType"], `return ${getLowCodeEditorDefaultReturnText(event?.data.payload?.["dataType"])}`),
        dataType: event?.data.payload?.["dataType"],
        key: event?.data.payload?.["key"],
      };
      dispatch(setScriptEditorPayload(apiPayloadData));

      openJSEditorLayout({
        config: editorConfig,
        editorLocation: event?.data.payload?.["editorLocation"],
      });
    } else if (
      event?.data?.source === "zorp_web" &&
      event?.data?.name === "exit_fullscreen"
    ) {
      if(event.data.updateWorkflow) 
        dispatch(setChangeInFlutter(!changesInFlutter))
      onCanvasClick();
    } else if (
      event?.data?.source === "zorp_web" &&
      event?.data?.name === "create_datafield"
    ) {
      if (event.data.payload.listName === null)
        dispatch(setShowCreateDatafieldModalSingle(true));
      else
        dispatch(setShowDataFieldEditModalSingle(event.data.payload.listName));
      dispatch(setDataFieldType(event.data.payload));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.addEventListener("message", onMessageReceivedFromIframe);
  }, [onMessageReceivedFromIframe]);

//   useEffect(() => {
//     checkStale();
//     setIframeUrl(
//       `${url}/#/app-builder?taskStatus=${selectedNode?.id}&at=${accessToken}&accountId=${accountId}&taskType=${taskTypeId}&canBeHomeNode=${canBeHomeNode}&isHomeNode=${isHomeNode}`
//     );
//   }, [selectedNode]); // eslint-disable-line react-hooks/exhaustive-deps

  function checkStale() {
    var listOfEdges = [];
    var selectedNodeId = selectedNode?.id;
    isHomeNode = selectedNode.data.isHomeNode;
    allNodes.forEach((element) =>
      element["type"] === "zorpEdge" ? listOfEdges.push(element) : null
    );
    listOfEdges.forEach((element) =>
      element["source"] === selectedNodeId ? (canBeHomeNode = true) : null
    );
  }

  const onCanvasClick = () => {
    dispatch(setSelectedNode(null));
    dispatch(setSelectedTransition(null));
    dispatch(setSelectedAction(null));
    dispatch(setSelectedSystemTransition(null));
  };

  return (
    <div>
      {showTaskCardConfig?.show && (
        <iframe
        id='app-builder'
        key={WorkflowUtils.getTaskCardConfigSrc(taskTypeId,showTaskCardConfig,accountId)}
        title='Zorp Designer'
        src={WorkflowUtils.getTaskCardConfigSrc(taskTypeId,showTaskCardConfig,accountId)}
        frameBorder='0'
        style={{
          display: 'block',
          height: '100vh',
          width: '100vw',
          position: 'absolute',
          top: '0',
          left: '0',
          zIndex: '300',
        }}
      />
      )}

      {scriptEditorOpen && <ZJSEditorLayout />}
    </div>
  );
};

export default FlutterOtherConfig;
