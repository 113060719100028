import { AxiosResponse } from 'axios';
import HttpUtil from 'config/AxiosConfig';
import { ZorpResponse } from 'providers/data/models/ZorpResponse';

export class AssignmentService {
  public static getEligibleUsersForAssignment(taskId: string, locationDataField: string, payload: any): Promise<ZorpResponse | undefined> {
    return HttpUtil.assignmentInstance
      .post(locationDataField ? `/task/${taskId}/users?locationDataField=${locationDataField}` : `/task/${taskId}/users`, payload)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  public static getTeamUsers(payload: any): Promise<ZorpResponse | undefined> {
    return HttpUtil.assignmentInstance
      .post(`/team/users`, payload)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  public static getUserOfAssignmentTask(
    userId: string,
    payload: any,
    contextTaskId?: string
  ): Promise<ZorpResponse | undefined> {
    return HttpUtil.assignmentInstance
      .post(`/user/${userId}?contextTaskId=${contextTaskId}`, payload)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  public static assignTask(taskId?: string, userId?: string): Promise<ZorpResponse | undefined> {
    let taskPayload = {
      taskId: taskId,
      event: 'ASSIGN',
      formData: {
        userId: userId,
      },
    };

    return HttpUtil.taskInstance
      .post(`/task/transit`, taskPayload)
      .then((response: AxiosResponse) => {
        // console.log("After Delete", response)
        return response.data as ZorpResponse;
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  }

  public static unassignTask(taskId?: string): Promise<ZorpResponse | undefined> {
    let taskPayload = {
      taskId: taskId,
      event: 'UNASSIGN',
    };

    return HttpUtil.taskInstance
      .post(`/task/transit`, taskPayload)
      .then((response: AxiosResponse) => {
        return response.data as ZorpResponse;
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  }
}
