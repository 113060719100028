import TestApiLoadingScreen from "views/LoadingScreen/TestApiLoadingScreen";
import useWorkFlow from "views/workflows/hooks/useWorkFlow";
import ApiTest from "./ApiTest";
import ApiTestStatusBar from "./ApiTestStatusBar";

type Props = {
  url?: string;
  method?: string;
};

const ApiTestWrapper = ({}: Props) => {
  const { apiResponse, fetchingTestAPIResponse } = useWorkFlow();

  return (
    <>
      {fetchingTestAPIResponse && <TestApiLoadingScreen />}

      {!fetchingTestAPIResponse && (
        <> 
          {apiResponse?.data &&  (
            <ApiTestStatusBar/>
          )}
          <ApiTest json={apiResponse?.data ?? {}} />
        </>
      )}
    </>
  );
};

export default ApiTestWrapper;
