import { Dispatch } from "redux";

export enum ABMetadataTypeEnum {
  WORKFLOW_SYSTEM_TRANSITION = "WORKFLOW_SYSTEM_TRANSITION",
  WORKFLOW_SCREEN_TRANSITION = "WORKFLOW_SCREEN_TRANSITION",
  WORKFLOW_DECISION_NODE_TRANSITION_IF_SWITCH = "WORKFLOW_DECISION_NODE_TRANSITION_IF_SWITCH",
  WORKFLOW_DECISION_NODE_TRANSITION_ELSE_SWITCH = "WORKFLOW_DECISION_NODE_TRANSITION_ELSE_SWITCH",
  WORKFLOW_SCREEN_ONLOAD = "WORKFLOW_SCREEN_ONLOAD",
  WORKFLOW_SCREEN_SMART_COMPONENT = "WORKFLOW_SCREEN_SMART_COMPONENT",
  USER = "USER",
  TEAM = "TEAM",
  CRON = "CRON",
  WEBHOOK = "WEBHOOK",
  MONITOR = "MONITOR",
  ENTITY_AUTOMATION = "ENTITY_AUTOMATIONS",
  BUTTON_AUTOMATION = "ENTITY_COLUMN_AUTOMATION"
}

export enum ITriggerId {
  "USER_CREATE" = "user_create",
  "USER_UPDATE" = "user_delete",
  "TEAM_USER_ASSIGNMENT" = "team_user_assignment",
  "TEAM_USER_UNASSIGNMENT" = "team_user_unassignment",
}

export enum IAutomationBlocksType {
  ACTION = "ACTION",
  GUARD = "GUARD",
  CONDITION = "CONDITION"
}

export enum IABConditionType {
  AB_CONDITION = 'ab_condition',
}

export interface ITrigger {
  triggerId: string,
  icon?: string | null,
  label: string,
  description: string,
  isDefault: string,
  event: any;
}

export interface IAutomationName {
  automationName: string,
}

export interface IActionBlock {
  actions: [],
  conditions: [],
  actionBlockId: string,
  originalId?: string,
}

/**
 * condition can have AND or OR, check this when defining the interface for condition
 */

export interface IAutomation {
  // xecution => trigger[0] || trigger[1] || trigger[2] || trigger[3]
  triggers: ITrigger[],
  guards?: [],
  actionBlocks: IActionBlock[],
  errors: [],
  id: string,
  name: string
}

export interface IAutomationReducerInitialState {
  isOpen: boolean,
  trigger: {},
  payload: {},
  actions: [],
  conditions: [],
  guards: [],
  metadataType: ABMetadataTypeEnum | null,
  otherConfigs: IAutomationBuilderOtherConfigs | null,
  selectedTrigger: any,
  allAutomations: IAutomation[],
  selectedAutomation: IAutomation | null,
  selectedConfiguration: "action" | "condition" | "trigger" | "guard" | null,
  selectedActionBlock: IActionBlock | null,
  prevAutomations: any,
  saveAutomationHandler: any,
  errors: any,
  actionDropdown: any | null,
  selectedWebhook:{},
}

export enum conditionTypes {
  EXPRESSION = "FUNCTION",
  SCREEN_COMPONENT = "SCREEN_COMPONENT"
}

export enum actionType {
  API_ACTION = 'API',
  PUSH_NOTIFICATION = 'PUSH',
  UPDATE_DATA_FIELD = 'updateDatafield',
  EXPRESSION = 'FUNCTION',
  JS_FUNCTION_ACTION = 'JS_FUNCTION_ACTION',
  UPDATE_DATA_FIELD_DATA = 'updateDataFieldData',
  TASK_UPDATE_TEAM_ACTION = "TASK_UPDATE_TEAM_ACTION",
  SCREEN_COMPONENT = 'SCREEN_COMPONENT',
  CREATE_ZORP_RECORD = 'CREATE_ZORP_RECORD',
  UPDATE_ZORP_RECORD = 'UPDATE_ZORP_RECORD',
  DELETE_ZORP_RECORD = 'DELETE_ZORP_RECORD',
  CREATE_ZORP_TASK = 'CREATE_ZORP_TASK',
  UPDATE_ZORP_TASK = 'UPDATE_ZORP_TASK',
  DELETE_ZORP_TASK = 'DELETE_ZORP_TASK',
  SEARCH_ZORP_TASK = 'SEARCH_ZORP_TASK',
  SEARCH_ZORP_RECORD = 'SEARCH_ZORP_RECORD',
  UPDATE_TEAM = 'UPDATE_TEAM', 
}

export interface IAction {
  actionType: string;
  actionName: string;
  id: string;
  description: string;
  isActive: boolean;
}

export type IConfiguration = "trigger" | "action" | "condition" | "guard";

export type IComponent = {
  id: string,
  name: string,
  type?: string,
  events?: any[],
  attributes?: any[]
}

export interface IAutomationBuilderOtherConfigs {
  allowOnlySingleAutomation?: boolean,
  hideTriggerConfigureButton?: boolean,
  showAutomationCondition?: boolean,
  preFillTrigger?: boolean,
  hideGuardBlock?: boolean,
  hideActionBlock?: boolean,
  hideTrigger?: boolean,
  showRunAutomation?:boolean;
  triggerConfigureBtnName?:boolean;
}

export interface IAttribute {
  id: string,
  name: string,
  dataType: string,
  isReadOnly: string,
  struct: any[]
}

export interface IOperators {
  id: string,
  name: string,
}

export interface IComponentStruct {
  id: string,
  name: string,
  dataType: string,
  isReadOnly: boolean,
  Operators: IOperators[],
}

export type OnSaveHandlerFunction = ({ allAutomations, dispatch }: { allAutomations: IAutomation[], dispatch: Dispatch<any> }) => void
