import React from 'react'
import { useSelector } from 'react-redux'





const useZMenu = () => {

    const {menu} = useSelector((state: any) => state)

    const selectedEmbedLink = menu.selectedEmbedLink
    const embedLinks = menu.embedLinks
    const theme = menu.theme

    return {
        selectedEmbedLink,
        embedLinks,theme
    }
  
}

export default useZMenu