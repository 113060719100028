import { useSelector } from 'react-redux';

const useAction = () => {
    const copiedAction = useSelector((state: any) => state?.action?.copiedAction);

    return {
        copiedAction
    }

}

export default useAction;