import { TextareaAutosize, TextField } from "@mui/material";
import React, { FunctionComponent, useState, useEffect } from "react";
import { UseFormRegister, FieldErrors } from "react-hook-form";
import { ComponentType } from "../ComponentTypeEnum";
import { IZComponentPropType } from "render-engine/models/ITaskTypeWebBodyRenderModel";
import { get } from "lodash";

import { IZInputInterface } from "./IZInputIInterface";
import { AiOutlineWarning } from "react-icons/ai";
import { Padding } from "@mui/icons-material";
import { TaskFormLabelForCreateAndEdit } from "render-engine/render.styles";

export const ZInput: FunctionComponent<IZComponentPropType> = ({
  config,
  onChange,
  register,
  errors,
  setValue,
}) => {
  // Construct validation
  const validation: any = {};
  let validate: any = () => true;

  if (config.isRequired) validation.required = `required`;

  if (config.maxChars) validation.maxLength = config.maxChars;

  if (config.validate) {
    validate = config.validate;
  }

  const textFieldRef = React.useRef(null);

  // if (config.shape.toUpperCase() === 'NUMBER')
  //     validation.pattern = /^\d+$/;

  useEffect(() => {
    if (config.value) {
      if (config.shape.toUpperCase() === "NUMBER") {
        setValue(config.key, parseFloat(config.value as string));
      } else {
        setValue(config.key, config.value);
      }
    }
  }, []);

  let isVisible = config?.isVisible ?? true;
  
  const registerObject = {
    valueAsNumber: config.shape.toUpperCase() === "NUMBER" ? true : false,
    ...validation,
    validate: validate,
  };

  const { ref, ...rest } = register(`${config.key}`, registerObject);

  if (isVisible)
    return (
      <>
        <div className="col-span-4">
          {config?.label?.isVisible && (
            <TaskFormLabelForCreateAndEdit
              className="col-span-1 font-medium"
              fontSize={config?.label?.fontSize}
              color={config?.label?.color}
            >
              {config?.label?.val}{config?.isRequired && <span className="text-red-500">*</span>}
            </TaskFormLabelForCreateAndEdit>
          )}

          {
            config.shape.toLowerCase() === "number" ? (
              <TextField
                className="w-full"
                inputRef={(e) => {
                  ref(e);
                  textFieldRef.current = e;
                  if (e) {
                    e.onwheel = (event) => event.preventDefault();  // Disable wheel event
                  }
                }}
                {...rest}
                // multiline={config.shape.toLowerCase() !== "number"}
                type={config.shape.toLowerCase()}
                defaultValue={config.value}
                maxRows={config.maxLines}
                {...register(`${config.key}`, registerObject)}
                id={config?.key}
                variant="outlined"
                inputProps={{
                  step: "any",
                }}
              />
            ) : (
              <TextareaAutosize
                className="w-full textarea"
                // multiline={config.shape.toLowerCase() !== "number"}
                minRows={1}
                defaultValue={config.value}
                maxRows={config.maxLines}
                {...register(`${config.key}`, registerObject)}
                id={config?.key}
            />
            )
          }

         
          {errors[config.key]?.message?.length > 0 && (
            <div className="font-semibold text-xs text-red-500 mt-1">
              <AiOutlineWarning
                size={"1rem"}
                color="red"
                style={{ display: "inline", marginRight: "4px" }}
              />{" "}
              {errors[config.key]?.message}
            </div>
          )}
          {config?.key?.includes(".") ? (
            get(errors, config.key)?.message && (
              <div className="font-semibold text-xs text-red-500 mt-q">
                <AiOutlineWarning
                  size={"1rem"}
                  color="red"
                  style={{ display: "inline", marginRight: "4px" }}
                />{" "}
                {get(errors, config.key)?.message}
              </div>
            )
          ) : (
            <p></p>
          )}
        </div>
      </>
    );
  else return <div></div>;
};