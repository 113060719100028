import { PageCategoryEnum, recordPageVisit } from "config/Rudderstack";
import { BOOLEAN, OBJECT, TEXT } from "constants/CommonConstants";
import { RudderStackAutomationBuilderContext, RudderStackAutomationBuilderEvents } from "constants/RudderStackConstants";
import { cloneDeep } from "lodash";
import { useJSEditor } from "lowcode/hooks/useJSEditor";
import { setScriptEditorPayload } from "lowcode/state/scriptOpenState";
import { EditorScriptLocation } from "lowcode/state/types/types";
import getInputType from "lowcode/util/getInputType";
import { ZJSEditorLayout } from "lowcode/ZJSEditorLayout";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector, useStore } from "react-redux";
import { recordRSEvent, removeBraces, stopPropagate } from "utils/CommonUtils";
import { getLowCodeEditorDefaultReturnText, getLowCodeEditorText } from "utils/Utils";
import InputTempId from "views/automationBuilder/components/InputTempId";
import useAutomation from "views/automationBuilder/hooks/useAutomation";
import usePayload from "views/automationBuilder/hooks/usePayload";
import { DataFieldType } from "views/DataField/DataField.types";
import useWorkflow from "views/workflows/hooks/useWorkFlow";
import {
  IIsTempIdUniqueContext,
  generateTempId,
  isTempIdUnique,
} from "../../views/workflows/workflowactions/api/utils/utils";
import ErorMessageInput from "views/workflows/workflowactions/api/components/ErrorMessageInput";
import ExecuteCondition from "views/workflows/workflowactions/api/components/ExecuteCondition";
import IsActive from "views/workflows/workflowactions/api/components/IsActive";
import ModalSmartInputTextField from "views/workflows/workflowactions/api/components/ModalSmartInputTextField";
import {
  getActionNameForTempId,
  saveAction,
  ValidLocation,
} from "views/workflows/workflowactions/api/utils/utils";
import DatafieldSelector from "views/workflows/workflowactions/DatafieldSelector";
import { Heading, HeadingWrapper, Label } from "views/workflows/workflowactions/ModalContent.styles";
import {
  setSelectedAction
} from "../workflows/reducers/workflowAction";
import "../workflows/workflowactions/api/PushNotification.css";
import { updateMockDataValue } from "./utils/util";
import { LuSettings2 } from "react-icons/lu";
import ErrorMessageInput from "views/workflows/workflowactions/api/components/ErrorMessageInput";
import ErrorHandlingModal from "views/workflows/workflowactions/api/APIAction/ErrorHandlingModal";
import { AccountService } from "providers/data/services/AccountService";

const ExpressionAction = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const { selectedAction, selectedTransition, getScriptValue, dataPicker } = useWorkflow();
  const { selectedActionBlock, selectedAutomation, clearRightSide } = useAutomation();
  const [isEditing, setIsEditing] = useState(false);
  const [tempIdChanged, setTempIdChanged] = useState(true);
  const [currTempIdVal, setCurrTempIdVal] = useState(selectedAction?.tempId || "");
  const { mockData } = useSelector((state: any) => state.scriptEditor);
  const { openJSEditorLayout, scriptEditorOpen } = useJSEditor();
  const { actionConditionPayload } = usePayload();
  const {selectedEntity} = store?.getState()?.entity;
  const [openErrorHandlingModal, setOpenErrorHandlingModal] = useState(false);
  // This function gets called when the user click on save button in Modal.
  // If the user is setting switch in choice node, updateConditionSwitch is called
  // else if user is setting up normal guard, updateGuard will be called.

  const onFormSubmit = (data: any) => {
    let updatedExpression = selectedAction?.expression;
    let tempActionId: string = selectedAction?.tempId || data?.tempId;
    if (!isEditing) {
      tempActionId = currTempIdVal.length === 0 ? (selectedAction?.actionId || selectedAction?.guardId || selectedAction?.conditionId) : currTempIdVal;
      dispatch(setSelectedAction({ ...selectedAction, tempId: currTempIdVal }));
    }


    let isTempIdChanged = tempIdChanged;
    if (tempActionId === (selectedAction?.actionId || selectedAction?.guardId || selectedAction?.conditionId)) {
      setTempIdChanged(false);
      isTempIdChanged = false;
    }

    if (isTempIdChanged && !isTempIdUnique(tempActionId, selectedAutomation)) {
      setError("tempId", {
        type: "manual",
        message: "This ID already exists. Please choose a different one.",
      });
      return;
    }

    if (!data.actionName) {
      setError("actionName", {
        type: "manual",
        message: "This is a required field",
      });
      return;
    }

    if (data.actionName.length < 3) {
      setError("actionName", {
        type: "manual",
        message: "Function Name must have more than 3 characters",
      });
      return;
    }

    let payload = {
      ...data,
      actionId: tempActionId,
      tempId: tempActionId,
      actionType: selectedAction.actionType,
      isActive: selectedAction?.isActive ?? true,
      expression: updatedExpression,
      transitionId: selectedTransition?.id,
      retries: parseInt(selectedAction?.retries) || 3,
      delay: parseInt(selectedAction?.delay) || 2000,
      exponential: data.exponential === "exponential" ? true : false,
      isRetryEnabled: data.isRetryEnabled === "retryEnabled" ? true : false,
      exponentialFactor: parseInt(selectedAction?.exponentialFactor) || 2,
      [EditorScriptLocation.AB_SMART_ACTION_JS_FUNCTION]:
        selectedAction[EditorScriptLocation.AB_SMART_ACTION_JS_FUNCTION] ||
        undefined,
      [EditorScriptLocation.ERROR_MESSAGE]:
        selectedAction[EditorScriptLocation.ERROR_MESSAGE],
      [EditorScriptLocation.API_ACTION_SHOULD_RETRY]:
        selectedAction[EditorScriptLocation.API_ACTION_SHOULD_RETRY] || {
          type: "script",
          dataType: BOOLEAN,
          value:`return false` ,

        }
    };

    recordRSEvent(
      RudderStackAutomationBuilderEvents.expressionActionSaveClick,
      {
        context: RudderStackAutomationBuilderContext.automationBuilderCondition,
        automationId: selectedAutomation.id,
        actionType: selectedAction.actionType,
        actionId: selectedAction.actionId,
      }
    );
    saveAction({
      payload,
      location: ValidLocation.AUTOMATION,
      dispatch,
      selectedAction,
      selectedActionBlock,
      selectedAutomation,
    });

    clearRightSide();
  };

  useEffect(() => {
    recordPageVisit(
      PageCategoryEnum.automationBuilder,
      RudderStackAutomationBuilderEvents.expressionActionOnLoad
    );
  }, []);

  const handleErrorMessageChange = (e) => {
    dispatch({
      ...selectedAction,
      hasChanged: true,
      [EditorScriptLocation.ERROR_MESSAGE]: {
        type: getInputType(e.target.value),
        value: removeBraces(e.target.value),
        dataType: TEXT,
      },
    });
  };

  const handleValChange = (name, value) => {
    dispatch(
      setSelectedAction({ ...selectedAction, [name]: value, hasChanged: true })
    );
  };

  const actionExecutionScriptConfig = {
    ShouldRetry: {
      script:
        getScriptValue(EditorScriptLocation.API_ACTION_SHOULD_RETRY) ||
        getLowCodeEditorText(
          EditorScriptLocation.API_ACTION_SHOULD_RETRY,
          DataFieldType.BOOLEAN,
          `return false`
        ),
      dataType: BOOLEAN,
    },
  };

  const ShowCurrentActionPayload = cloneDeep(actionConditionPayload);
  if (ShowCurrentActionPayload.payload.inclusions.length > 0) {
    if (ShowCurrentActionPayload.payload.inclusions[0].config) {
      ShowCurrentActionPayload.payload.inclusions[0].config.showCurrentAction =
        true;
      ShowCurrentActionPayload.payload.inclusions[0].config.actionName =
        selectedAction?.actionName || selectedAction?.guardName;
    }
  }

  const {
    register,
    setValue,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    control,
    getValues,
    watch
  } = useForm({
    defaultValues: {
      actionName: selectedAction?.actionName,
      tempId: generateTempId(
        selectedAction,
        selectedAutomation,
        "javascript function",
        "action"
      ),
      errorMessage:
        selectedAction?.errorMessage?.type === "script"
          ? "Smart Script"
          : selectedAction?.errorMessage?.value,
      isActive: selectedAction?.isActive ?? true,
      expression:
        selectedAction?.expression?.type === "script"
          ? "Smart Script"
          : selectedAction?.expression?.value,
      retries: selectedAction?.retries || 3,
      delay: selectedAction?.delay || 2000,
      exponentialFactor: selectedAction?.exponentialFactor || 2,
      isRetryEnabled:
        selectedAction?.isRetryEnabled === true
          ? "retryEnabled"
          : "retryDisabled",
      exponential:
        selectedAction?.exponential === undefined ||
        selectedAction?.exponential === false
          ? "uniform"
          : "exponential",
      [EditorScriptLocation.API_ACTION_SHOULD_RETRY] : selectedAction[EditorScriptLocation.API_ACTION_SHOULD_RETRY] || `return false` ,
    },
  });

  const expressionActionConfig = {
    ExpressionGuardAction: {
      script:
        getScriptValue(EditorScriptLocation.AB_SMART_ACTION_JS_FUNCTION) ||
        getLowCodeEditorText(
          EditorScriptLocation.AB_SMART_ACTION_JS_FUNCTION,
          DataFieldType.OBJECT,
          `return ${getLowCodeEditorDefaultReturnText(DataFieldType.OBJECT)}`
        ),
      dataType: DataFieldType.OBJECT,
    },
    errorMessageConfig: {
      script:
        getScriptValue(EditorScriptLocation.ERROR_MESSAGE) ||
        getLowCodeEditorText(
          EditorScriptLocation.ERROR_MESSAGE,
          TEXT,
          `return ${getLowCodeEditorDefaultReturnText(TEXT)}`
        ),
      dataType: TEXT,
    },
  };

  const handleTempActionValChange = (name, value) => {
    setCurrTempIdVal(value);
  }

  const handleEditClick = () => {
    setIsEditing(true);
    setTempIdChanged(true);
  };

  const handleTickClick = () => {
    setIsEditing(false);
    const oldTempId = selectedAction?.tempId ? selectedAction?.tempId : selectedAction?.actionId;
    updateMockDataValueFunc(oldTempId, currTempIdVal)
  };

  const updateMockDataValueFunc  = (oldId:string, newId:string) => {
    if (!oldId || !newId) return;
    else if (oldId === newId) return;

    const mockDataPayload = cloneDeep(mockData);
    mockDataPayload[`response_${newId}`] = mockDataPayload?.[`response_${oldId}`]
    
    updateMockDataValue(store, dispatch, mockDataPayload);
  }

  const setTempIdError = (error) => {
    if (error) {
      setError("tempId", { type: "manual", message: error });
    } else {
      clearErrors("tempId");
    }
  };

  return (
    <>
      {/* Name textfield */}
      <form onSubmit={stopPropagate(handleSubmit(onFormSubmit))}>
        <HeadingWrapper>
          <Heading> Javascript Function </Heading>
          <IsActive control={control} closeOnClick={clearRightSide} />
        </HeadingWrapper>
        <Label> Name </Label>
        <ModalSmartInputTextField
          value={selectedAction?.actionName}
          placeholder="Name this Javascript Function"
          dataType={DataFieldType.TEXT}
          register={register}
          control={control}
          name="actionName"
          errors={errors}
        />
        <InputTempId
          label="Action Id"
          requiredErrorMessage="Action Id is required"
          onChange={handleTempActionValChange}
          register={register}
          errors={errors}
          isReadOnly={!isEditing}
          handleTickClick={handleTickClick}
          handleEditClick={handleEditClick}
          getValues={getValues}
          setTempIdError={setTempIdError}
          isEditing={isEditing}
          name="tempId"
        />
        <ExecuteCondition
          showExpressionActive={selectedAction[EditorScriptLocation.AB_SMART_ACTION_JS_FUNCTION]?.value ? true : false}
          conditionLabel="Write Expression to Execute"
          handleClick={() => {
            dispatch(setScriptEditorPayload(actionConditionPayload));
            openJSEditorLayout({
              config: expressionActionConfig.ExpressionGuardAction,
              editorLocation: EditorScriptLocation.AB_SMART_ACTION_JS_FUNCTION,
            });
          }}
        />

        <div
          onClick={() => setOpenErrorHandlingModal(true)}
          style={{
            color: "#3e66fb",
            fontWeight: 500,
            cursor: "pointer",
          }}
        >
          <LuSettings2
            style={{
              display: "inline",
            }}
          />{" "}
          Error Handling
        </div>

        <ErrorMessageInput
          label={`Error Message to be shown if the Expression Action fails`}
          onChangeFunc={handleErrorMessageChange}
          register={register}
          errors={errors}
          apiBodyPayload={actionConditionPayload}
          openJSEditorLayout={openJSEditorLayout}
          config={expressionActionConfig.errorMessageConfig}
        />
        {dataPicker?.isOpened && <DatafieldSelector setValue={setValue} />}
        {scriptEditorOpen && <ZJSEditorLayout />}
      </form>
      {openErrorHandlingModal && (
        <ErrorHandlingModal
          openErrorHandlingModal={openErrorHandlingModal}
          handleModalClose={() => {
            setOpenErrorHandlingModal(false);
          }}
          register={register}
          handleValChange={handleValChange}
          actionExecutionScriptConfig={actionExecutionScriptConfig}
          selectedAction={selectedAction}
          ShowCurrentActionPayload={ShowCurrentActionPayload}
          control={control}
          getValues={getValues}
          watch={watch}
        />
      )}
    </>
  );
};

export default ExpressionAction;
