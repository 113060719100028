import React from "react";

type Props = {};

const ZReportIcon = ({color, width, height}: {color ?:string, width?:string, height?:string}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 17.5H3.83333C3.36662 17.5 3.13327 17.5 2.95501 17.4092C2.79821 17.3293 2.67072 17.2018 2.59083 17.045C2.5 16.8667 2.5 16.6334 2.5 16.1667V2.5M17.5 5.83333L12.9714 10.3619C12.8064 10.5269 12.7239 10.6094 12.6288 10.6404C12.5451 10.6675 12.4549 10.6675 12.3712 10.6404C12.2761 10.6094 12.1936 10.5269 12.0286 10.3619L10.4714 8.80474C10.3064 8.63973 10.2239 8.55723 10.1288 8.52632C10.0451 8.49912 9.95493 8.49912 9.87124 8.52632C9.77611 8.55723 9.6936 8.63973 9.5286 8.80474L5.83333 12.5M17.5 5.83333H14.1667M17.5 5.83333V9.16667"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ZReportIcon;