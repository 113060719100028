import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ShowTaskActionWrapper,
  ZActionButton,
} from "render-engine/render.styles";
import { makeStyles } from "@material-ui/core/styles";
import { AiOutlineArrowLeft, AiOutlineWarning } from "react-icons/ai";
import { FiTrash2 } from "react-icons/fi";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import DeletionConfirmationDialog from "utils/DeletionConfirmationDialog";
import { BiEdit } from "react-icons/bi";
import notification from "notifications/notifications";
import { EntityService } from "providers/data/services/EntityService";
import { RiDeleteBin2Line, RiDeleteBin6Line } from "react-icons/ri";
import { formatDate, recordRSEvent } from "utils/CommonUtils";
import { TableRSEvents } from "views/entities/types";
import usePorterPermissions from "hooks/usePorterPermissions";
import { ReplyRIcon, SettingRIcon } from "assests";
import { isPlainObject } from "lodash";
import { setLoading } from "views/reducer";
import { useDispatch } from "react-redux";
import useAccessPermissions from "hooks/useAccessPermissions";
import useTaskShow from "views/workflows/hooks/useTaskShow";
import useEntity from "views/entities/hooks/useEntity";
import { Menu, MenuItem } from "@szhsin/react-menu";
import styled from "styled-components";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import "../../styles/zorpTable.css";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  clearInEditState,
  clearTaskData,
  initRecordShowMetaData,
  resetTaskShow,
  revertToInitialState,
} from "render-engine/reducers/show/TaskShowReducer";
import { UseFormReturn } from "react-hook-form";
import { InfoTooltip } from "views/workflows/Tootltips/InfoTooltip";
import { ResourceTypeConstant } from "views/entities/permissions/types";
import useQuery from "hooks/useQuery";


type Props = {
  tableTypeName: string;
  recordId: string;
  tableType: string;
  createdOn?: string;
  updatedOn?: string;
  showTaskForm: UseFormReturn<any>;
  resource: "task" | "table";
};

const Button = styled.button`
  padding: 8px !important;
  border: 1px solid #d0d5dd !important;
  border-radius: 4px;
`;

//@ts-ignore
const useStyles = makeStyles((theme) => ({
  list: {
    padding: "5px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  icon: {
    left: 0,
  },
  iconOpen: {
    transform: "none",
  },
  formControlLabel: {
    left: 24,
  },
  selectSelect: {
    paddingLeft: "24px",
  },
  text: {
    color: "#616569",
    fontWeight: 600,
    fontSize: "14px",
  },
  heading: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#101828",
  },
  badge: {
    background: "#ECFDF3",
    padding: "4px 12px",
    borderRadius: "16px",
    fontWeight: "500",
    color: "#027A48",
  },
  backButton: {
    padding: "7px",
    border: "1px solid #D0D5DD",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "4px",
  },
  subHeading: {
    color: "#667085",
    fontWeight: 500,
    fontSize: "14px",
  },
  subText: {
    color: "#344054",
    fontWeight: 500,
    fontSize: "14px",
    marginLeft: "8px",
  },
}));

const IconWrapper = styled.div`
  padding: 8px !important;
`;

const IconText = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: #344054;
`;

const ShowRecordActions = ({
  tableTypeName,
  tableType,
  recordId,
  createdOn,
  updatedOn,
  showTaskForm,
}: Props) => {
  const history = useHistory();
  const classes = useStyles();
  const {
    taskData: data,
    isDataEdited,
    rowsToDelete,
    initTaskData,
  } = useTaskShow();
  const { selectedViewId, recordTypeLabel } = useEntity();

  const [loading, setLocalLoading] = useState(false);
  const query = useQuery();
  const dataEdited = isDataEdited();

  const [showDeletionConfirmationDialog, setShowDeletionConfirmationDialog] =
    useState(false);

  const dispatch = useDispatch();

  const handleEditClick = () => {
    // history.push(`/table/${tableType}/edit/${recordId}`)
    /**
     * Removing location dataType key if the address, latitude and longitude are undefined
     */
    // loop through all the keys of data
    const payload = { ...data };
    setLocalLoading(true);
    const dataKeys = Object.keys(payload);
    // loop through dataKeys and check if the value is an object
    dataKeys.forEach((key) => {
      if (isPlainObject(payload[key])) {
        // get all the keys of the object
        const objectKeys = Object.keys(payload[key]);
        // check if ojectKey has a value called address, latitude and longitude
        if (
          objectKeys.includes("address") &&
          objectKeys.includes("latitude") &&
          objectKeys.includes("longitude")
        ) {
          // check if the value of address, latitude and longitude is undefined
          if (
            (payload[key].address === undefined ||
              payload[key].address === "") &&
            payload[key].latitude === undefined &&
            payload[key].longitude === undefined
          ) {
            // if yes, then delete the key from payload
            delete payload[key];
          }
        }
      }
    });

    const keysFromRowsToDelete = Object.keys(rowsToDelete);
    // loop through the keys of rowsToDelete
    keysFromRowsToDelete.forEach((key) => {
      // check of the value of key is an array and it has length greater than 0
      if (Array.isArray(rowsToDelete[key]) && rowsToDelete[key].length > 0) {
        // check if this key is in payload
        if (dataKeys.includes(key)) {
          // if yes, then remove the rowsToDelete[key] from payload[key]
          const modifiedData = payload[key].filter(
            (item: any) => !rowsToDelete[key].includes(item)
          );
          payload[key] = modifiedData;
        } else {
          // check if the key is in initTaskData
          if (initTaskData && initTaskData[key]) {
            // if no, then remove the rowsToDelete[key] from initTaskData[key]
            const modifiedData = initTaskData[key].filter(
              (item: any) => !rowsToDelete[key].includes(item)
            );
            payload[key] = modifiedData;
          }
        }
      }
    });

    dispatch(setLoading(true));
    // const teamIds = data?.teamIds || []
    // delete data?.teamIds
    const taskEditPayload: any = {
      tableType: tableType,
      data: payload,
      teamIds: [],
    };

    // const omitFieldsInData: Array<string> = ['scheduledSlot', 'taskType', 'teamIds'];
    // for (let key of Object.keys(store.getState().taskEdit)) {
    //   // Skip the omit fields for data
    //   if (!omitFieldsInData.includes(key)) taskEditPayload.data[key] = store.getState().taskEdit[key];
    // }
    EntityService.updateEntityRecord(tableType, recordId, taskEditPayload)
      .then((res: any) => {
        if (res?.code) {
          if (res?.code.indexOf("200") < 0) {
            if (res?.code === "304") {
              notification("warning", "No change", res.message);
              setLocalLoading(false);
            } else {
              // setDisableEditButton(false)
              // editTaskForm.reset()
              notification("error", res.message);
              setLocalLoading(false);
            }
          } else {
            notification("success", `${recordTypeLabel} Edited successfully`);
            // setDisableEditButton(false)
            recordRSEvent(TableRSEvents.EDIT_SINGLE_RECORD_SUCCESS, {
              context: "record_edit",
              recordId,
              tableType: taskEditPayload.taskType,
            });
            dispatch(clearTaskData());
            dispatch(clearInEditState());

            // push to previous page
            dispatch(
              initRecordShowMetaData({
                tableType,
                recordId,
              })
            );

            // localStorage.removeItem("isPreview");
          }
        }
        dispatch(setLoading(false));
        setLocalLoading(false);
      })
      .catch((err) => {
        // setDisableEditButton(false)
        // editTaskForm.reset()
        console.error("Error", err);
        dispatch(setLoading(false));
        recordRSEvent(TableRSEvents.EDIT_SINGLE_RECORD_FAILURE, {
          context: "record_edit",
          recordId,
          tableType: taskEditPayload.taskType,
        });
        notification(
          "error",
          err?.response?.data?.message ||
            "Something went wrong. Please try again."
        );
        setLocalLoading(false);
      });
  };

  const porterPermissions = usePorterPermissions();
  const accessPermissions = useAccessPermissions({resourceType: ResourceTypeConstant.TABLE});

  const handleDelete = () => {
    EntityService.deleteRecord(tableType, recordId)
      .then((res) => {
        if (res?.code && res?.code.indexOf("200") < 0) {
          if (res?.code === "401") {
            notification("error", res.message);
          } else {
            notification("error", res.message);
          }
        } else {
          notification("success", `${recordTypeLabel} Deleted Successfully`);
          goBack();
          recordRSEvent(TableRSEvents.DELETE_SINGLE_RECORD_SUCCESS, {
            recordId: recordId,
            tableType: tableType,
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
        notification(
          "error",
          err?.response?.data?.message || "Something went wrong"
        );
        recordRSEvent(TableRSEvents.DELETE_SINGLE_RECORD_FAILURE, {
          recordId: recordId,
          tableType: tableType,
        });
      });
  };

  const goBack = () => {
    const context = query.get("context");
    const viewId = query.get("viewId") || selectedViewId;
    if (context === "ticket") {
      history.push(`/ticket/${tableType}?allowRedirect=false`);
      return
    }
    if(viewId) history.push(`/table/${tableType}/view/${viewId}/?allowRedirect=false`);
    else history.push(`/table/${tableType}/?allowRedirect=false`);
    dispatch(resetTaskShow());
  }

  return (
    <div
      style={{
        borderBottom: "solid 1px #EAECF0",
        paddingBottom: "8px",
      }}
    >
      <ShowTaskActionWrapper>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
            // padding: "10px",
          }}
        >
          <div
            onClick={() =>
              goBack()
            }
            className={classes.backButton}
          >
            <AiOutlineArrowLeft
              style={{ fontSize: "20px", color: "#101828" }}
            />
          </div>

          <p className={classes.heading}>
            Record Id
            <span
              style={{ fontSize: "16px", color: "#101828" }}
              className={classes.text}
            >
              {" "}
              {recordId}{" "}
            </span>{" "}
          </p>
          <div className={classes.badge}> {tableTypeName} </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <div>
            {/* <p>
              {" "}
              <span
                style={{
                  color: "#667085",
                  fontSize: "12px",
                }}
              >
                Created on{" "}
              </span>
              :{" "}
              <span style={{ color: "#667085", fontSize: "12px" }}>
                {formatDate(createdOn)}
              </span>{" "}
            </p> */}
            {isDataEdited() ? (
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: 500,
                  color: "#d92d20",
                }}
              >
                <AiOutlineWarning size="1.2rem" style={{ display: "inline" }} />{" "}
                You have unsaved changes{" "}
              </p>
            ) : (
              <InfoTooltip
                placement="top"
                title={
                  <>
                    <p>Created on: {createdOn}</p>
                    <p>Updated on: {updatedOn}</p>
                  </>
                }
              >
                <div>
                  <p>
                    <span
                      style={{
                        color: "#667085",
                        fontSize: "12px",
                      }}
                    >
                      {" "}
                      Updated{" "}
                    </span>{" "}
                    <span style={{ color: "#667085", fontSize: "12px" }}>
                      {formatDate(updatedOn)}
                    </span>{" "}
                  </p>
                </div>
              </InfoTooltip>
            )}
          </div>
          {dataEdited && (
            // <ZButton
            //   secondary
            //   variant="contained"
            //   onClick={() => {
            //     dispatch(revertToInitialState())
            //     showTaskForm.reset()
            //   }}
            // >
            //   Discard Changes
            // </ZButton>
            <ZActionButton
              onClick={() => {
                dispatch(revertToInitialState());
                showTaskForm.reset();
              }}
            >
              Discard Changes
            </ZActionButton>
          )}
          {porterPermissions.checkPermissionsForPorter(
            tableType,
            "update_record"
          ) && (
            <ZButton
              disabled={!isDataEdited() || loading}
              variant="contained"
              onClick={handleEditClick}
            >
              {loading ? "Saving.." : "Save"}
            </ZButton>
          )}
          {accessPermissions.delete_record && (<Menu
            menuClassName="my-menu"
            menuButton={
              <Button>
                <BsThreeDotsVertical color="#344054" size={"1.5rem"} />
              </Button>
            }
            transition
            // direction="left"
            gap={12}
            align="start"
          >
            {porterPermissions.checkPermissionsForPorter(
              tableType,
              "delete_record"
            ) && (
              <MenuItem
                className="menuItem-delete"
                onClick={() => {
                  setShowDeletionConfirmationDialog(true);
                  recordRSEvent(
                    TableRSEvents.DELETE_SINGLE_RECORD_BUTTON_CLICKED,
                    {
                      recordId: recordId,
                      tableType: tableType,
                    }
                  );
                }}
              >
                <IconWrapper>
                  <RiDeleteBin6Line
                    color="#B42318"
                    size={"1.5rem"}
                    stroke="2"
                  />
                </IconWrapper>
                <IconText
                  style={{
                    color: "#B42318",
                  }}
                >
                  {" "}
                  Delete {recordTypeLabel}{" "}
                </IconText>
              </MenuItem>
            )}
          </Menu>)}
        </div>
        <DeletionConfirmationDialog
          id={"recordDelete"}
          deletionTitle={`Delete this ${recordTypeLabel}?`}
          deletionText={`Are you sure you want to delete this ${recordTypeLabel}?`}
          isOpen={showDeletionConfirmationDialog}
          onConfirmDelete={() => handleDelete()}
          onClose={() => setShowDeletionConfirmationDialog(false)}
        />
      </ShowTaskActionWrapper>
      {/* <ShowTaskActionWrapper>
        <div className={classes.subHeading}>
          Team <span className={classes.subText}>{team || "N/A"}</span>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
            // padding: "10px",
          }}
        >
          <div className={classes.subHeading}>
            Created <span className={classes.subText}>{createdOn}</span>
          </div>
          <div className={classes.subHeading}>
            Updated <span className={classes.subText}>{updatedOn}</span>
          </div>
        </div>
      </ShowTaskActionWrapper> */}
    </div>
  );
};

export default ShowRecordActions;