import { ILoggedInUser } from "@zorp/common-libs-js/dist/common/types/types";
import { ReactNode } from "react";

export interface IStepper {
  label: string;
  description: string;
  detailedInfo: any;
  createdOn: string;
  subDescription?: string;
  points: IPoint[];
  icon?: string;
}

export interface IPoint {
  label: string;
  createdOn: string;
  channel: "CC" | "API" | "App";
  event: string;
  showMore?: ReactNode;
}

export interface ITaskLogs {
  taskLogs: [ISingleTaskLog];
}

export enum LogType {
  TASK_LOGS = "task_form_data_log",
  REST_API = "rest_api_action_log",
  REST_API_GUARDS = "rest_api_action_guard_log",
}
export interface ISingleTaskLog {
    allLogs: ISingleTaskLog[];
    logType: LogType,
    createdOn: string;
    updatedBy: ILoggedInUser,
    accountId: string;
    taskId: string;
    sourceState: string;
    targetState: string;
    sourceStateName: string;
    errorMessage ?: string;
    guardStatus ?: boolean;
    targetStateName: string;
    event: string;
    channel: "CC" | "API" | "App";
    mode: "single" | "bulk";
    isRetried ?: boolean;
    actionName ?: string;
    actionDisplayName ?: string;
    actionDescription ?: string;
    actionId ?: string;
    url ?: string;
    body ?: string;
    response ?: {
        status : string,
        headers : string,
        body : string
    };
    headers ?: string;

    formData: {
        userId: string
        scheduledSlot: {
            from: string,
            to: string
        }
    };

}

export interface RecordHistory {
    updatedBy: {
        userId: string;
        name: string;
        channel: string;
    };
    updatedOn: string;
    changes: LogChange[];
}

export interface LogChange {
    path: string;
    changeType: string;
    nestedArrayChanges?: NestedArrayChange[];
    newValue?: any;
    oldValue?: string;
}

export interface NestedArrayChange {
    path: string;
    changeType: string;
    oldValue?: string;
    newValue?: NestedArrayChange[];
}

export interface RecordHistory {
  updatedBy: {
    userId: string;
    name: string;
    channel: string;
  };
  updatedOn: string;
  changes: LogChange[];
}

export interface LogChange {
  path: string;
  changeType: string;
  nestedArrayChanges?: NestedArrayChange[];
  newValue?: any;
  oldValue?: string;
}

export interface NestedArrayChange {
  path: string;
  changeType: string;
  oldValue?: string;
  newValue?: NestedArrayChange[];
}
