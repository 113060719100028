import React from "react";
import useWorkFlow from "views/workflows/hooks/useWorkFlow";
import styled, { useTheme } from "styled-components";
import statusCodeMessage from "./StatusCode";
import notification from "notifications/notifications";
import {MdOutlineContentCopy} from "react-icons/md"

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const Status = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
`;

const Text = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
`;

const Span = styled.span`
  color: ${(props: any) => props?.color || "black"};
  &:hover {
    cursor: pointer;
  }

  svg {
    display: inline;
    margin-right: 4px;
  }
`;

const CopyDiv = styled.div``;

type Props = {};

const copyToClipBoard = async (copyMe: string) => {
  try {
    await navigator.clipboard.writeText(copyMe);
    notification("success", "Copied to clipboard");
  } catch (err) {
    notification("error", "Something went wrong, try again");
  }
};

const ApiTestStatusBar = (props: Props) => {
  const { apiResponse } = useWorkFlow();
  const theme = useTheme()

  return (
    <Wrapper>
      <Status>
        <Text>
          {" "}
          Status :{" "}
          <Span color={apiResponse?.status <= 300 ? "green" : "red"}>
            {" "}
            {apiResponse?.status} {statusCodeMessage[apiResponse?.status] || ""}{" "}
          </Span>
        </Text>
        <Text>
          {" "}
          Time : <Span> {apiResponse?.requestTime} ms </Span>{" "}
        </Text>
        <Text>
          {" "}
          Size : <Span> {apiResponse?.size / 1000} KB </Span>{" "}
        </Text>
      </Status>
      <CopyDiv>
        <Text>
          {" "}
          <Span color={theme.color.primary} onClick={() => copyToClipBoard(JSON.stringify(apiResponse.data))}> 
          <MdOutlineContentCopy />
          Copy to clipboard </Span>{" "}
        </Text>
      </CopyDiv>
    </Wrapper>
  );
};

export default ApiTestStatusBar;
