import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefaultJSON } from 'lowcode/constants/Snippets';
import { EditorScriptLocation } from 'lowcode/state/types/types';
import { IScriptEditorVariableApiPayload } from 'providers/data/services/BffService';

const initialState = {
  scriptEditorOpen: false,
  scriptEditorApiPayload:{},
  scriptEditorOptions: {
    config: {
      script: ``,
      dataType: '',
      key: '',
    },
    editorLocation: '',
    isEditorDisabled: false,
    defaultEditorLanguage: 'javascript',
  },
  userClearedScript: {
    state: false,
    editorLocation: '',
  },
};

export const editorOpenState = createSlice({
  name: 'editorOpen',
  initialState,
  reducers: {
    setScriptEditorOpen: (state: any, action: PayloadAction<boolean>) => {
      document.body.classList.add('modal-open');
      state.scriptEditorOpen = action.payload;
    },
    setScriptEditorPayload:(state:any,action:PayloadAction<IScriptEditorVariableApiPayload | {}>)=>{
      state.scriptEditorApiPayload = action.payload;
    },
    addScriptEditorOptions: (state: any, action: any) => {
      state.scriptEditorOptions.config.script = action.payload.config.script || state.scriptEditorOptions.config.script;
      state.scriptEditorOptions.config.dataType = action.payload.config.dataType;
      state.scriptEditorOptions.config.key = action.payload.config.key || '';
      state.scriptEditorOptions.editorLocation = action.payload.editorLocation;
      state.scriptEditorOptions.defaultEditorLanguage = 'javascript';
      state.scriptEditorOptions.isEditorDisabled = action.payload.isEditorDisabled || false;
    },
    apiActionJsonBody: (state: any, action: any) => {
      state.scriptEditorOptions.config.script = action.payload.config.script;
      state.scriptEditorOptions.config.dataType = action.payload.config.dataType;
      state.scriptEditorOptions.editorLocation = action.payload.editorLocation;
      state.scriptEditorOptions.defaultEditorLanguage = action.payload.defaultEditorLanguage;
      if (action.payload.editorLocation === EditorScriptLocation.API_ACTION_JSON_BODY) {
        state.userClearedScript.state = false;
        state.userClearedScript.editorLocation = '';
      }
      state.scriptEditorOptions.isEditorDisabled = action.payload.isEditorDisabled || false;
    },
    clearScriptEditor: (state: any) => {
      state.scriptEditorOptions = initialState.scriptEditorOptions;
    },
    clearSelectedLocationScript: (state: any, action: any) => {
      state.scriptEditorOptions = {
        ...initialState.scriptEditorOptions,
        isEditorDisabled: false,
        defaultEditorLanguage: 'json',
        config: { ...initialState.scriptEditorOptions.config, script: DefaultJSON },
      };
      state.userClearedScript = {
        ...initialState.userClearedScript,
        state: action.payload.state,
        editorLocation: action.payload.editorLocation,
      };
    },
    updateScriptEditorConfigScript:(state:any, action:PayloadAction<string>) => {
      state.scriptEditorOptions.config.script = action.payload;
    }
  },
});

export const {
  setScriptEditorOpen,
  setScriptEditorPayload,
  addScriptEditorOptions,
  apiActionJsonBody,
  clearScriptEditor,
  clearSelectedLocationScript,
  updateScriptEditorConfigScript
} = editorOpenState.actions;

export default editorOpenState.reducer;
