import { isArray, isDate, isEqual } from 'lodash'
import moment from 'moment'
import { useSelector } from 'react-redux';

type Props = {
  name ?: string
}

const useTaskShow = () => { 
  const {taskShow} = useSelector((state:any) => state)

  const taskRenderDetails = taskShow.taskRenderDetails
  const taskData = taskShow.taskData
  const initTaskData = taskShow.initialTaskData
  const inEditState = taskShow.inEditState
  const rowsToDelete = taskShow.rowsToDelete
  const taskShowLayoutMetadata = taskShow.taskShowLayoutMetadata
  const recordDetails = taskShow.recordDetails
  const subRecordCreateMode = taskShow.subRecordCreateMode
  const subRecordData = taskShow.subRecordData
  const addedRecordsToRelation = taskShow.addedRecords
  const taskDatafields = taskShow.taskDatafields
  const taskDataUpdated = taskShow.taskDataUpdated
  const enrichedData = taskShow.taskRenderDetails?.recordData?.enrichedData


  /**
   * As ZText is a generic component, it can be used for multiple data types.
   * toConvert is a boolean flag to convert data type to string
   * @param key
   * @param toConvert
   * @param defaultValue
   * @param store "parentRecord" | "subRecord"
   * @returns string
   * 
   * If opened from the sub record view moe, then the data is fetched from subRecordData
   * but if opened from the parent record view mode, then the data is fetched from taskData
   * there might be a case where even if the subRecord Data is present, we want to fetch the data from parent record
   * in that case, we can pass store="parentRecord"
   * if store="parentRecord", then the data is fetched from taskData
   */
  const convertDataTypeToString = (key: string, toConvert=true, defaultValue="", store="") => {
    if(!key) return defaultValue
    let data;
    if(subRecordCreateMode && store !== "parentRecord") {
      data = subRecordData?.[key] ?? defaultValue
    } 
    else {
      data = taskData?.[key] ?? initTaskData?.[key] ?? defaultValue
    }
    
    if (isArray(data) && toConvert) {
      return data.join(", ");
    } else if(false) {
      // use moment to convert date to string
      return moment(data).format("DD-MM-YYYY HH:mm:ss");
    }
      return data;
    }

  const hasKeyValueEdited = (key: string) => {
    // check if they value of key is edited
    /**
     * if taskData[key] is different from initTaskData[key], then it is edited
     * if taskData[key] is same as initTaskData[key], then it is not edited
     * if taskData[key] is undefined, then it is not edited
     * taskData[key] and initTaskData[key] can be either string, number, boolean, array, object
     */
    if (taskData?.[key] === undefined) return false;
    if (!isEqual(taskData?.[key], initTaskData?.[key])) return true;
    return false;
  }

  const isDataEdited = () => {
    if(!taskData) return false
    
    if (taskData === undefined) return false;
    // keys of taskData
    const keys = Object.keys(taskData);
    // check if any of the keys is edited
    for (let i = 0; i < keys.length; i++) {
      if (hasKeyValueEdited(keys[i])) return true;
    }
    // check if there is any value in rowsToDelete
    /**
     * rowsToDelete is an object
     * {
     *  "key": [rowIds]
     * "key2": [rowIds]
     * ...
     * }
     */
    const rowKeys = Object.keys(rowsToDelete);
    for (let i = 0; i < rowKeys.length; i++) {
      if (rowsToDelete[rowKeys[i]].length > 0) return true;
    }

    return false;

  }


  return {
    taskRenderDetails,
    taskData,
    initTaskData,
    inEditState,
    convertDataTypeToString,
    rowsToDelete,
    taskShowLayoutMetadata,
    hasKeyValueEdited,
    isDataEdited,
    recordDetails,
    subRecordCreateMode,
    subRecordData,
    addedRecordsToRelation,
    taskDatafields,
    taskDataUpdated,
    enrichedData
  };
};

export default useTaskShow;