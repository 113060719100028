import { cloneDeep, isEmpty } from "lodash";
import notification from "notifications/notifications";
import React, {
  ReactNode,
  ChangeEvent,
  useEffect,
  useState,
  useRef,
  CSSProperties,
} from "react";
import { useForm } from "react-hook-form";
import ZInput from "views/commonComponents/ZTextInput";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import useEntity from "views/entities/hooks/useEntity";
import useTaskShow from "views/workflows/hooks/useTaskShow";
import { GPTDiv } from "views/workflows/workflowactions/api/APIAction/ImportCurl/CurlStyledComponents";
import { useParams } from "react-router-dom";
import { EntityService } from "providers/data/services/EntityService";
import {
  initRecordCreateMetaData,
  initRecordShowMetaData,
  initTaskCreateMetaData,
  initTaskShowMetaData,
} from "render-engine/reducers/show/TaskShowReducer";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggableProvided,
  DroppableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Drag, TrashRIcon } from "assests";
import SearchIcon from "@mui/icons-material/Search";
import { TeamService } from "providers/data/services/TeamService";
import SelectTeamDropdown from "./SelectTeamDropdown";
import DeletionConfirmationDialog from "utils/DeletionConfirmationDialog";
import { convertDatafieldNameToReadableFormat } from "utils/CommonUtils";
import { InfoTooltip } from "views/workflows/Tootltips/InfoTooltip";
import { BuilderService } from "providers/data/services/BuilderService";
import useTaskCreation from "views/workflows/hooks/useTaskCreation";

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ModalWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
const SectionListItem = styled.div`
  flex: 1.5;
  border-right: 1px solid #ccc;
  padding: 16px;
  flex-direction: column;
  display: flex;
`;
const SectionContent = styled.div`
  flex: 4;
  padding: 16px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const List = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
`;
const ListItem = styled.div`
  color: #344054;
  line-height: 20px;
  font-weight: 500;
  font-size: 12px;
`;
const ListItemDesc = styled.span`
  color: #98a2b3;
  line-height: 20px;
  font-size: 12px;
  font-weight: 400;
`;
const Header = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  justify-content: flex-start;
  width: 100%;
  gap: 8px;
  border: 1px solid #d0d5dd;
  background: "#FFF";
  padding: 8px 12px;
  position: sticky;
  align-self: stretch;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
`;
const SearchInput = styled.input`
  width: 100%;
  color: #667085;
  font-size: 12px;
  border: none;
  outline: none;
`;
const CheckOption = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
const ZTagButton = styled(ZButton)`
  border-radius: 6px !important;
  border: 1px solid #d0d5dd !important;
  padding: 4px 8px !important;
  background: #fff !important;
  color: #344054 !important;
  text-align: center !important;
  font-size: 12px !important;
  font-weight: 500 !important;
`;
const Checkbox = styled.input`
  margin-right: 8px;
  border-radius: 4px;
  border: 1px solid #d0d5dd;
  background: #fff;
  width: 16px;
  height: 16px;
  cursor: pointer;
  appearance: none;
  outline: none;
  position: relative;

  &:checked {
    background: #f5f8ff;
    border: 1px solid #3c69e7;
  }

  &:checked::before {
    content: "✔";
    font-size: 12px;
    color: #3c69e7;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
const Wrapper = styled(GPTDiv)`
  border-radius: 4px;
`;
const Label = styled.div`
  color: var(--gray-700, #344054);
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 8px;
`;

type CheckOptionWrapperProps = {
  children: ReactNode;
  checked: boolean;
  onClick: (event: ChangeEvent<HTMLInputElement>) => void;
  style?: CSSProperties;
};
type Props = {
  tabId: string;
  onCancel: () => void;
  sectionId: string;
  editSectionDetail: any;
  sectionType: "SECTION" | "RELATION";
};

export const CheckOptionWrapper: React.FC<CheckOptionWrapperProps> = ({
  children,
  checked,
  onClick,
  style,
}) => {
  return (
    <CheckOption style={style}>
      <Checkbox type="checkbox" checked={checked} onChange={onClick} />
      {children}
    </CheckOption>
  );
};

const CreateSectionModal = ({
  tabId,
  onCancel,
  editSectionDetail,
  sectionType,
}: Props) => {
  const { selectedEntityMetadata } = useEntity();
  const { taskShowLayoutMetadata, taskDatafields } = useTaskShow();
  const resource = window.location.href.includes("task") ? "task" : "table";
  const [dataFields, setDataFields] = useState<any[]>(() => {
    if (resource === "task") {
      return taskDatafields || [];
    } else {
      return selectedEntityMetadata?.customFields || [];
    }
  });
  const [filteredDataFields, setFilteredDataFields] = useState<any[]>(() => {
    if (taskDatafields) {
      return taskDatafields || [];
    } else {
      return selectedEntityMetadata?.customFields || [];
    }
  });
  const [teams, setTeams] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const debounceTimer = useRef<ReturnType<typeof setTimeout>>();

  const [selectedField, setSelectedField] = useState<any>(() => {
    if (editSectionDetail) {
      return editSectionDetail.rows
        .map((row: any) => {
          return row.map((node: any) => {
            return node.key;
          });
        })
        .flat();
    } else {
      return [];
    }
  });
  const [selectedActions, setSelectedActions] = useState<any>(() => {
    if (editSectionDetail) {
      return editSectionDetail.actions.map((action: any) => {
        return action.key;
      });
    } else {
      return [];
    }
  });
  const actionFields = useState<any[]>(() => {
    let fields: any[] = [];
    const keys = Object.keys(
      selectedEntityMetadata?.tableMetadata?.automations?.actions || {}
    );
    keys.forEach((key) => {
      const val =
        selectedEntityMetadata?.tableMetadata?.automations?.actions[key];
      if (val) {
        fields.push(
          selectedEntityMetadata?.tableMetadata?.automations?.actions[key]
        );
      }
    });
    return fields;
  })[0];
  const [filteredActionFields, setFilteredActionFields] =
    useState<any[]>(actionFields);
  const [showDelete, setShowDelete] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      sectionName: editSectionDetail?.label || "",
    },
  });
  const { taskType: taskTypeFromHook } = useTaskCreation({ name: "" });
  const { tableType, recordId, taskType, taskId } = useParams();
  const dispatch = useDispatch();

  const handleAddField = (fieldKey: string) => {
    if (sectionType === "RELATION") {
      // only one field can be added in relation section
      if (selectedField.includes(fieldKey)) {
        setSelectedField([]);
      } else {
        setSelectedField([fieldKey]);
      }
      return;
    }
    if (selectedField.includes(fieldKey)) {
      setSelectedField(selectedField.filter((key: string) => key !== fieldKey));
    } else {
      setSelectedField([...selectedField, fieldKey]);
    }
  };

  const handleRemoveField = (fieldKey: string) => {
    setSelectedField(selectedField.filter((key: string) => key !== fieldKey));
  };

  const onSubmit = async (data: any) => {
    if (sectionType === "RELATION" && selectedField.length === 0) {
      notification("error", "Error", "Please select atleast one relation");
      return;
    }
    const mode = window.location.href.includes("create") ? "create" : "show";
    const resource = window.location.href.includes("task") ? "task" : "table";
    try {
      const tempMetadata = cloneDeep(taskShowLayoutMetadata);
      let newTabs;
      if (editSectionDetail) {
        if (sectionType === "SECTION") {
          newTabs = tempMetadata.tabs.map((tab: any) => {
            if (tab.tabId === tabId) {
              tab.sections = tab.sections.map((section: any) => {
                if (section.sectionId === editSectionDetail.sectionId) {
                  const nodes = [];
                  const fieldNodes = selectedField.map((field: string) => {
                    // get currentValue of this field from section.nodes
                    const currentNode = section.nodes.find(
                      (node: any) => node.nodeId === field
                    );
                    return {
                      ...currentNode,
                      nodeId: field,
                    };
                  });
                  const actionNodes = selectedActions.map((action: string) => {
                    // get currentValue of this field from section.nodes
                    const currentNode = section.nodes.find(
                      (node: any) => node.nodeId === action
                    );
                    return {
                      ...currentNode,
                      nodeId: action,
                    };
                  });
                  nodes.push(...fieldNodes);
                  nodes.push(...actionNodes);
                  return {
                    ...section,
                    label: data.sectionName,
                    allowedTeams: data.allowedTeams.map(
                      (team: any) => team.value
                    ),
                    nodes,
                  };
                }
                return section;
              });
              return tab;
            }
            return tab;
          });
        } else {
          newTabs = tempMetadata.tabs.map((tab: any) => {
            if (tab.tabId === tabId) {
              tab.sections = tab.sections.map((section: any) => {
                if (section.sectionId === editSectionDetail.sectionId) {
                  const nodes = [];
                  const fieldNodes = selectedField.map((field: string) => {
                    // get currentValue of this field from section.nodes
                    const currentNode = section.nodes.find(
                      (node: any) => node.nodeId === field
                    );
                    return {
                      ...currentNode,
                      nodeId: field,
                    };
                  });
                  const actionNodes = selectedActions.map((action: string) => {
                    // get currentValue of this field from section.nodes
                    const currentNode = section.nodes.find(
                      (node: any) => node.nodeId === action
                    );
                    return {
                      ...currentNode,
                      nodeId: action,
                    };
                  });
                  nodes.push(...fieldNodes);
                  nodes.push(...actionNodes);
                  return {
                    ...section,
                    label: data.sectionName,
                    allowedTeams: data.allowedTeams.map(
                      (team: any) => team.value
                    ),
                    nodes,
                  };
                }
                return section;
              });
              return tab;
            }
            return tab;
          });
        }
        // edit section

        const newTaskShowLayoutMetadata = {
          ...tempMetadata,
          tabs: newTabs,
        };
        // if url has create, then mode is create
        // if url has show, then mode is show
        if (resource === "table") {
          await EntityService.updateTableLayout({
            tableType,
            mode,
            layout: newTaskShowLayoutMetadata,
          });
          notification("success", "Success", "Section updated successfully");
          if (mode === "show") {
            dispatch(
              initRecordShowMetaData({
                tableType,
                recordId,
              })
            );
          } else {
            dispatch(
              initRecordCreateMetaData({
                tableType,
              })
            );
          }
        } else {
          await BuilderService.updateTaskRenderLayout({
            taskType: taskTypeFromHook || taskType,
            mode,
            layout: newTaskShowLayoutMetadata,
          });
          notification("success", "Success", "Section updated successfully");
          if (mode === "show") {
            dispatch(
              initTaskShowMetaData({
                taskId,
              })
            );
          } else {
            dispatch(
              initTaskCreateMetaData({
                tableType,
              })
            );
          }
        }

        return;
      } else {
        // create new section
        const tempMetadata = cloneDeep(taskShowLayoutMetadata);
        const newTabs = tempMetadata.tabs.map((tab: any) => {
          if (tab.tabId === tabId) {
            const nodes = [];
            const fieldNodes = selectedField.map((field: string) => {
              // get currentValue of this field from section.nodes

              return {
                nodeId: field,
              };
            });
            const actionNodes = selectedActions.map((action: string) => {
              // get currentValue of this field from section.nodes

              return {
                nodeId: action,
              };
            });
            nodes.push(...fieldNodes);
            nodes.push(...actionNodes);
            tab.sections.push({
              label: data.sectionName,
              allowedTeams: data.allowedTeams.map((team: any) => team.value),
              sectionId: `${Date.now()}`,
              sectionType: sectionType,
              nodes,
            });
            return tab;
          }
          return tab;
        });
        const newTaskShowLayoutMetadata = {
          ...tempMetadata,
          tabs: newTabs,
        };

        if (resource === "table") {
          await EntityService.updateTableLayout({
            tableType,
            mode,
            layout: newTaskShowLayoutMetadata,
          });
          notification("success", "Success", "Section created successfully");
          if (mode === "show") {
            dispatch(
              initRecordShowMetaData({
                tableType,
                recordId,
              })
            );
          } else {
            dispatch(
              initRecordCreateMetaData({
                tableType,
              })
            );
          }
        } else if (resource === "task") {
          await BuilderService.updateTaskRenderLayout({
            taskType: taskTypeFromHook || taskType,
            mode,
            layout: newTaskShowLayoutMetadata,
          });
          notification("success", "Success", "Section created successfully");
          if (mode === "show") {
            dispatch(
              initTaskShowMetaData({
                taskId,
              })
            );
          } else {
            dispatch(
              initTaskCreateMetaData({
                taskType,
              })
            );
          }
        }
        return;
      }
    } catch (error) {
      console.log(error);
      notification("error", "Error", "Error while creating section");
    }
  };

  const handleDelete = async () => {
    const mode = window.location.href.includes("create") ? "create" : "show";
    const resource = window.location.href.includes("task") ? "task" : "table";
    try {
      const tempMetadata = cloneDeep(taskShowLayoutMetadata);
      const newTabs = tempMetadata.tabs.map((tab: any) => {
        if (tab.tabId === tabId) {
          tab.sections = tab.sections.filter(
            (section: any) => section.sectionId !== editSectionDetail.sectionId
          );
          return tab;
        }
        return tab;
      });
      const newTaskShowLayoutMetadata = {
        ...tempMetadata,
        tabs: newTabs,
      };
      if (resource === "table") {
        await EntityService.updateTableLayout({
          tableType,
          mode,
          layout: newTaskShowLayoutMetadata,
        });
        notification("success", "Success", "Section deleted successfully");
        if (mode === "show") {
          dispatch(
            initRecordShowMetaData({
              tableType,
              recordId,
            })
          );
        } else {
          dispatch(
            initRecordCreateMetaData({
              tableType,
            })
          );
        }
      } else if (resource === "task") {
        await BuilderService.updateTaskRenderLayout({
          taskType,
          mode,
          layout: newTaskShowLayoutMetadata,
        });
        notification("success", "Success", "Section deleted successfully");
        if (mode === "show") {
          dispatch(
            initTaskShowMetaData({
              taskId,
            })
          );
        } else {
          dispatch(
            initRecordCreateMetaData({
              tableType,
            })
          );
        }
      }

      setShowDelete(false);
      onCancel && onCancel();
    } catch (error) {
      console.log(error);
      notification("error", "Error", "Error while deleting section");
    }
  };

  const fetchTeams = async () => {
    try {
      const res = await TeamService.listTeams({
        pagination: {
          page: 1,
          perPage: 100,
        },
        filter: {},
      });
      setTeams(res?.data?.teams || []);
    } catch (error) {
      console.log("error", error);
      setTeams([]);
    }
  };

  useEffect(() => {
    fetchTeams();
  }, []);

  const handleAddAction = (fieldKey: string) => {
    if (selectedActions.includes(fieldKey)) {
      setSelectedActions(
        selectedActions.filter((key: string) => key !== fieldKey)
      );
    } else {
      setSelectedActions([...selectedActions, fieldKey]);
    }
  };

  useEffect(() => {
    // Clear the existing timer
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    // Set a new timer
    debounceTimer.current = setTimeout(() => {
      if (searchTerm.trim() === "") {
        setFilteredDataFields(dataFields || []);
        setFilteredActionFields(actionFields || []);
      } else {
        const searchTermLower = searchTerm.toLowerCase();
        const filtered =
          dataFields.filter((field: any) =>
            field.name.toLowerCase().includes(searchTermLower)
          ) || [];
        const filteredAction =
          actionFields?.filter((action: any) =>
            action.name.toLowerCase().includes(searchTermLower)
          ) || [];
        setFilteredActionFields(filteredAction);
        setFilteredDataFields(filtered);
      }
    }, 500); // 500ms delay

    // Cleanup function to clear the timer when the component is unmounted or before the effect runs again
    return () => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
    };
  }, [searchTerm, dataFields, actionFields]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  // Function to reorder the list
  const reorder = (
    list: string[],
    startIndex: number,
    endIndex: number
  ): string[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  // Handling drag end
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      selectedField,
      result.source.index,
      result.destination.index
    );
    setSelectedField(items);
  };

  const mode = window.location.href.includes("create") ? "create" : "show";

  return (
    <Wrapper
      style={{
        height: "60vh",
        width: "60vw",
      }}
    >
      {console.log(">>>", sectionType)}
      <ModalWrapper>
        <SectionListItem>
          <SearchContainer>
            <SearchIcon style={{ margin: "0px", color: "#667085" }} />
            <SearchInput placeholder="Search..." onChange={handleInputChange} />
          </SearchContainer>
          <List>
            <Label style={{ marginTop: "0" }}>Available Actions</Label>
            {filteredActionFields?.map((field, index) => (
              <>
                <CheckOptionWrapper
                  key={index}
                  checked={selectedActions.includes(field.name)}
                  onClick={() => handleAddAction(field.name)}
                  style={{ marginBottom: "1rem" }}
                >
                  <ListItem>{field.name} </ListItem>
                </CheckOptionWrapper>
              </>
            ))}
            <Label>
              Available{" "}
              {sectionType === "RELATION"
                ? resource === "task"
                  ? "Lists"
                  : "Relations"
                : resource === "task"
                ? "Datafields"
                : "Columns"
              }
            </Label>
            {filteredDataFields
              // remove all non create time field from the options
              ?.filter((field) => {
                if (
                  resource === "task" &&
                  mode === "create" &&
                  !field.isCreateTimeField
                ) {
                  return false;
                } else {
                  return true;
                }
              })
              ?.filter((field) => {
                if (sectionType === "SECTION" && field.type === "LIST") {
                  return false;
                } else {
                  return true;
                }
              })
              ?.filter((field) => {
                if (field.isDeleted) return false;
                if (field.type === "COMMENT") return false;
                if (sectionType === "RELATION") {
                  return (
                    field.type === "LIST" ||
                    (field.type === "RELATION" &&
                      (field.relationDetails.referringEntity !== "USER" && field.relationDetails.referringEntity !== "USER"))
                  );
                } else {
                  if (resource === "task") {
                    return field.type !== "LIST";
                  } else {
                    return field.type !== "RELATION" ||
                      field.relationDetails.referringEntity === "USER" || field.relationDetails.referringEntity === "TEAM";
                  }
                }
              })
              .map((field, index) => (
                <>
                  <CheckOptionWrapper
                    key={index}
                    checked={selectedField.includes(field.name)}
                    onClick={() => handleAddField(field.name)}
                    style={{ marginBottom: "1rem" }}
                  >
                    <InfoTooltip title={field.name} placement="right">
                      <div>
                        <ListItem>
                          {/* show only first 22 chars and ... */}
                          {field?.name?.length > 22
                            ? field.name.substring(0, 22) + "..."
                            : field.name}{" "}
                          <ListItemDesc>
                            {" "}
                            {convertDatafieldNameToReadableFormat(
                              `${
                                field?.relationDetails?.referringEntity ===
                                "CUSTOM"
                                  ? "TABLE"
                                  : field?.relationDetails?.referringEntity
                                  ? field?.relationDetails?.referringEntity
                                  : ""
                              }_${field.type}`
                            )}{" "}
                          </ListItemDesc>
                        </ListItem>
                      </div>
                      {/* <span>type : {field.type} </span> */}
                    </InfoTooltip>
                  </CheckOptionWrapper>
                </>
              ))}
          </List>
        </SectionListItem>
        <SectionContent>
          <div style={{ height: "487px" }} className="main">
            <Header>
              <div>Configure Section</div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <div>
                  {!isEmpty(editSectionDetail) &&
                    editSectionDetail?.sectionType !== "DEFAULT" && (
                      <div onClick={() => setShowDelete(true)}>
                        <TrashRIcon color="#B42318" />
                      </div>
                    )}
                </div>
                <Flex className="gap-2">
                  <div>
                    <ZButton
                      secondary
                      variant="contained"
                      onClick={() => {
                        setSelectedField([]);
                        onCancel && onCancel();
                      }}
                    >
                      Cancel
                    </ZButton>
                  </div>
                  <ZButton
                    style={{
                      backgroundColor: "#3C69E7",
                      borderColor: "#3C69E7",
                    }}
                    variant="contained"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Save
                  </ZButton>
                </Flex>
              </div>
            </Header>
            <Label>Name of the section</Label>
            <ZInput
              autoFocus
              name="sectionName"
              placeholder="Section Name"
              register={register}
              validations={{
                required: "Section Name is required",
                minLength: {
                  value: 3,
                  message: "Section Name should be atleast 3 characters",
                },
              }}
            />
            {errors.sectionName && (
              <div className="mt-1" style={{ color: "red", fontSize: "12px" }}>
                {errors.sectionName.message}
              </div>
            )}
            <Label className="mt-2">Section visible for</Label>
            <SelectTeamDropdown
              defaultValue={editSectionDetail?.allowedTeams || []}
              control={control}
              teams={teams}
              name="allowedTeams"
            />

            <Label className="mt-2">Actions in this section:</Label>
            <div
              style={{
                display: "flex",
                gap: "8px",
                flexWrap: "wrap",
                marginBottom: "16px",
              }}
            >
              {selectedActions.map((fieldName: string, index: string) => (
                <span key={index}>
                  <ZTagButton
                    variant="contained"
                    onClick={() => handleRemoveField(fieldName)}
                    style={{ gap: "8px" }}
                  >
                    {/* <Drag /> */}
                    {fieldName}
                  </ZTagButton>
                </span>
              ))}
            </div>
            <Label>Datafields in this section:</Label>
            <div
              style={{
                overflow: "auto",
                height: "130px",
              }}
            >
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {selectedField.map((fieldName: string, index: number) => (
                        <Draggable
                          key={fieldName}
                          draggableId={fieldName}
                          index={index}
                        >
                          {(
                            provided: DraggableProvided,
                            snapshot: DraggableStateSnapshot
                          ) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "8px",
                                gap: "8px",
                                ...provided.draggableProps.style,
                              }}
                            >
                              <Drag />

                              <Label
                                style={{
                                  margin: "0",
                                  flexGrow: "1",
                                  color: "#344054",
                                }}
                              >
                                {fieldName}
                              </Label>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </SectionContent>
      </ModalWrapper>
      <DeletionConfirmationDialog
        id={"subfieldRow"}
        deletionTitle={"Delete this section?"}
        deletionText={
          "Do you want to delete this section? This cannot be undone."
        }
        isOpen={showDelete}
        onConfirmDelete={() => handleDelete()}
        onClose={() => setShowDelete(false)}
      />
    </Wrapper>
  );
};

export default CreateSectionModal;
