import Modal from "@mui/material/Modal";
import styled from "styled-components";
import {Button} from "views/DataField/DataField.styles"
import notification from "notifications/notifications";
import { useState } from "react";



const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 33vw;
  background-color: white;
  border: 1px solid #e5e5e5;
  padding: 20px;
`;

const Heading = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const Label = styled.p`
  margin-top: 20px;
  font-size: 14px;
  font-weight: 600;
`;

const Input = styled.input`
  margin-top: 16px;
  border: 1px solid #e5e5e5;
  padding: 8px;
  width: 100%;
  border-radius: 4px;
`;

const ButtonContainer = styled.div`
  margin-top: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;



export default function CollectEmailModal({showModal, setShowModal,callback}: {showModal: boolean, setShowModal: any, callback: any}) {
    
    const [email, setEmail] = useState("");
    const handleClose = () => {
        setShowModal(false);
    }

    const handleSave = () => {
        if(email === ""){
            notification("error", "Please enter email address");
            return;
        }else{
            callback(email);
        }
    }

  return (
    <div>
      <Modal
        open={showModal}
        onClose={() => {}}
        aria-labelledby="configure-workflow"
        aria-describedby="configure-your-workflow-attributes"
      >
        
          <Container>
            <Heading> Enter Your email </Heading>
            <hr />
            <Label style={{fontWeight:400}}> This might take few minutes. We will notify you on this email once all the records are imported. </Label>
            <Input
              placeholder="Enter your email..."
                value={email}
                onChange={(e) => {
                    setEmail(e.target.value);
                }}
            />
            <ButtonContainer>
              {/* @ts-ignore */}
              <Button onClick={handleClose} variant="secondary">
                {" "}
                Cancel{" "} 
              </Button>
              {/* @ts-ignore */}
              <Button onClick={handleSave} variant="primary"> Upload </Button>
              
            </ButtonContainer>
          </Container>
        
      </Modal>
    </div>
  );
}
