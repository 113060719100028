// Note: Don't add console.log in this file: 
// - by sathithya yogi MP
import Editor, { useMonaco } from '@monaco-editor/react';
import Box from '@mui/material/Box';
import ZActionsLib from "@zorp/actions-lib/dist/actions/zorp/ZorpActionsLib";
import zorpCommonLib from "@zorp/common-libs-js/dist/app/functions";
import lowCodeEditorBackBtn from "assests/low_code_back.svg";
import axios from "axios";
import ChoiceConfirmation from 'components/user-choice-confirmation/ChoiceConfirmation';
import { ActionToPerform, OBJECT } from "constants/CommonConstants";
import { CLOSE_CONFIRMATION, CLOSE_EDITOR, FORCE_SAVE_CONFIRMATION, FORCE_SAVE_WARNING } from 'constants/CommonMessages';
import lodash, { cloneDeep, isEmpty, set } from "lodash";
import { DefaultJSON, DefaultJSONDisabled, sdkSuggestions } from "lowcode/constants/Snippets";
import { useJSEditor } from 'lowcode/hooks/useJSEditor';
import { initMockData, setMockDataContext } from "lowcode/state/scriptEditorState";
import { EditorScriptLocation } from "lowcode/state/types/types";
import { IJSErrorType } from "lowcode/variables/types/Common";
import { DateTime as DateTimeFnc } from "luxon";
import notification from "notifications/notifications";
import { BffService } from "providers/data/services/BffService";
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { IoIosArrowForward } from "react-icons/io";
import ReactJson from 'react-json-view';
import { useDispatch, useSelector, useStore } from "react-redux";
import { Panel, PanelGroup } from "react-resizable-panels";
import { useHistory } from 'react-router-dom';
import styled from "styled-components";
import { clearGlobalVariablesData, getAccountId, getRunTimeVariableData, storeGlobalVariablesData } from "utils/CommonUtils";
import DeletionConfirmationDialog from 'utils/DeletionConfirmationDialog';
import { matchReturnDataType, processResponseStructure } from "utils/Utils";
import useAutomation from "views/automationBuilder/hooks/useAutomation";
import { addConditionInAutomation, setSelectedConfiguration, updateConditionInAutomation } from "views/automationBuilder/reducer/automationReducer";
import { ABMetadataTypeEnum, actionType, conditionTypes, IABConditionType } from "views/automationBuilder/types/ABType";
import { updateMockDataValue } from 'views/automationBuilder/utils/util';
import { ModalHeader, ZButton } from 'views/commonComponents/commonComponents.styles';
import ZEditorModal from 'views/commonComponents/ZEditorModal';
import { EntityTypeField } from 'views/DataField/DataField.types';
import useEntity from 'views/entities/hooks/useEntity';
import { setActiveEditColumn } from 'views/entities/reducers/entityReducers';
import useWorkFlow from "views/workflows/hooks/useWorkFlow";
import { guardTypes } from "views/workflows/workflowactions/api/guard.types";
import { setSelectedAction } from "../views/workflows/reducers/workflowAction";
import LowCodeEditorFooter from './components/LowCodeEditorFooter';
import ResizeHandle from './ResizeHandle';
import { initScriptEditor } from "./state/scriptEditorState";
import { clearScriptEditor, setScriptEditorOpen } from "./state/scriptOpenState";
import styles from "./style.module.css";
import { runJSCode } from "./util/executeCode";
import { getLowCodeEditorTitle } from './util/returnUpdatedObject';
import { VariableTreeLowCodeEditor } from './variables/VariableTreeLowCodeEditor';
import {retryFunction} from "@zorp/actions-lib/dist/actions/common/function"
const outputInitialTxt = "Output goes here";

export enum ISaveType {
    SAVE = 'SAVE',
    CLOSE_WITHOUT_SAVE = 'CLOSE_WITHOUT_SAVE',
    RUN = 'RUN',
    FORCE_SAVE = 'FORCE_SAVE',
    EMPTY = '',
}

const Input = styled.input`
  width: 100%;
  font-size: 11px;
  outline: none;
  border: none;
`

const ScriptDescWrapper = styled.div`
padding:10px 16px;
background-color:#F9FAFB;
height:100%;
display:flex;
flex-direction:column;
`

const OutputWrapper = styled.div`
  border-width: 2px;
  border-radius: 0.375rem;
  border-color: #D0D5DD;
  background-color:#fff;
  overflow: scroll;
  height: 100%;
  max-height: 85%;
  z-index: 1;
`;

interface IConsoleItem {
    selected: boolean;
}

const ConsoleItem = styled.div<IConsoleItem>`
  border-top: 1px solid ${props => (props.selected ? '#CCDDF6' : 'rgb(239,239,239)')};
  border-bottom: 1px solid ${props => (props.selected ? '#CCDDF6' : 'rgb(239,239,239)')};
  background-color: ${props => (props.selected ? '#F7F8F7' : '')};
  padding-top: 2px;
  padding-bottom: 2px;
`;

const ReturnHelperText = styled.p`
    font-size: 12px;
    font-weight: 400;
    padding-top: 2px;
    padding-bottom: 10px;
`

interface ISetOutputInterface {
    data: string;
    error: boolean;
    allowSave: boolean;
    lineNumber?: string;
}
export const ZJSEditorLayout: FunctionComponent = () => {
    const originalConsoleLog = useRef(console.log);
    const [logs, setLogs] = useState([]);
    const monaco: any = useMonaco();
    const editorRef = useRef(null);
    const { lodashFunctions, copiedPreDefined, openJSEditor, initialScript, config, editorLocation, dataType, scriptEditorApiPayload, scriptEditorOptions: { config: { script = '' } = {}, defaultEditorLanguage, isEditorDisabled } } = useJSEditor();
    const [code, setCode] = useState(script);
    const accountId = getAccountId();
    const [open, setOpen] = useState(false);
    const [jsScript, setJsScript] = useState<string>("");
    const history = useHistory();
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [expanded, setExpanded] = useState(false);
    const [confirmClose, setConfirmClose] = useState(false);
    const [isScriptRunning, setIsScriptRunning] = useState<boolean>(false);
    const [runTimeVariable, setRunTimeVariable] = useState({});
    const [selectedConsole, setSelectedConsole] = useState<number>();
    const [variableTreeData, setVariableTreeData] = useState([]);
    const [confirmForceSave, setConfirmForceSave] = useState(false);
    const [showVariablePanel, setShowVariablePanel] = useState(true);
    const [filteredSuggestion, setFilteredSuggestion] = useState<string[]>([]);
    const [selectedLogOutput, setSelectedLogOutput] = useState<boolean>(false);
    const [loadingVariableTree, setLoadingVariableTree] = useState(false);
    const [output, setOutput] = useState<ISetOutputInterface | undefined>({ data: outputInitialTxt, error: false, allowSave: false, lineNumber: undefined });
    const store = useStore();
    const dispatch = useDispatch();
    const { selectedActionBlock, selectedAutomation, metadataType, isEntityAutomation, isCronAutomation, isMonitorAutomation, selectedMonitorId, selectedCron, isTeamAutomation, isUserAutomation, isWebHookAutomation, selectedWebhook } = useAutomation();
    const { mockData } = useSelector((state: any) => state.scriptEditor);
    const { selectedDecisionNode, selectedAction, selectedTransition, getScriptValue, taskTypeId, getLiteralValue, showWorkflowConfigModal, selectedNode, showTaskCardConfig } = useWorkFlow();
    const { selectedEntity, showColumnCreationModal, activeEditColumn } = useEntity();
    const apiActionJsonBodyDataScript = getScriptValue(EditorScriptLocation.API_ACTION_JSON_BODY);
    const apiActionJsonBodyDataLiteral = getLiteralValue(EditorScriptLocation.API_ACTION_JSON_BODY);
    const [disabledState] = useState(isEditorDisabled);
    const [editorLanguageState] = useState(defaultEditorLanguage);


    const helperTxt =  <ReturnHelperText>
    Please remember to use the return keyword to return back any value. You are currently setting value for <span className="text-black font-semibold">        {editorLocation.startsWith("builder_")
            ? editorLocation.substring(8)
            : editorLocation} </span>,output should be in <span className="text-black font-semibold">
            {dataType}
        </span> format
</ReturnHelperText>;

    const settings = {
        minimap: false,
        wordWrap: false,
        fontSize: 15,
        vimMode: false,
        theme: 'vs-dark',
    };

    function handleEditorDidMount(editor, monaco) {
        editorRef.current = editor;
    }

    const highlightLine = (lineNumber: number) => {
        const decoration = {
            range: new monaco.Range(lineNumber, 1, lineNumber, 1),
            options: {
                isWholeLine: true,
                className: 'lineHighlight'
            }
        };

        const decorations = editorRef.current.deltaDecorations([], [decoration]);

        setTimeout(() => {
            editorRef.current.deltaDecorations(decorations, []);
        }, 3000);
    };
    const literalActionBodyConfig = {
        ApiActionJsonBody: {
            script: DefaultJSONDisabled,
            type: OBJECT,
        },
        ApiActionBody: {
            script: selectedAction?.api_action_json_body?.value ?? DefaultJSON,
            type: OBJECT,
        },
        APIActionJsonBodyUrl: {
            script: apiActionJsonBodyDataScript ? DefaultJSONDisabled : apiActionJsonBodyDataLiteral ? apiActionJsonBodyDataLiteral : DefaultJSON,
            type: OBJECT,
        }
    };

    useEffect(() => {
        // Function to show confirmation dialog
        const handleBeforeUnload = (e: any) => {
            // Cancel the event as stated by the standard.
            e.preventDefault();
            // Chrome requires returnValue to be set.
            e.returnValue = '';
        };

        // Adding the event listener for the beforeunload event
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []); // Empty array means this effect runs once on mount

    useEffect(() => {
        // Block navigation and show confirmation dialog
        const unblock = history.block((location: any, action: any) => {
            // action can be 'PUSH', 'POP', 'REPLACE'
            if (action === 'POP') {
                // 'POP' action occurs on going back or forward
                if (window.confirm("Do you really want to leave? You may lose unsaved changes.")) {
                    // If the user confirms, unblock navigation and navigate
                    unblock();
                    return true; // This allows the navigation
                } else {
                    // If the user cancels, stop navigation
                    return false; // This blocks the navigation
                }
            }

            // For other actions (like 'PUSH', 'REPLACE'), let it proceed
            return true;
        });

        // Cleanup function to unblock navigation when component unmounts
        return () => {
            unblock();
        };
    }, [history]);


    const handleLog = (args: any) => {
        setLogs((prevLogs) => [...prevLogs, args.join(' ')]);
    };

  useEffect(() => {
    let monacoEditorPref: any;
    if (monaco && filteredSuggestion?.length > 0) {
        // validation settings
        monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
            noSemanticValidation: true,
            noSyntaxValidation: false,
         });
    
        // compiler options
        monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
            target: monaco.languages.typescript.ScriptTarget.ES2016,
            allowNonTsExtensions: true,
        });
     
        monacoEditorPref = monaco.languages.registerCompletionItemProvider('javascript', {
            provideCompletionItems: (model:any, position:any) => {

            const suggestions = (filteredSuggestion || []).concat(sdkSuggestions || []).concat(lodashFunctions || [])?.map((data:any)=>{        
                    return {
                        label: data?.label || data,
                        kind: monaco.languages.CompletionItemKind.keyword,
                        insertText: data?.insertText || data,
                        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                        documentation: data?.documentation || '',
                    }
                })
             
                return { suggestions };
            }
        });
    }

    return () => {
        if (monacoEditorPref) {
            monacoEditorPref.dispose();
        }
    };
}, [monaco, filteredSuggestion?.length]);

    useEffect(() => {
        if (!isEmpty(initialScript) && !isEmpty(jsScript) && initialScript != jsScript) {
            setIsDirty(true)
        }

        if (!isEmpty(initialScript) && !isEmpty(jsScript) && initialScript == jsScript) {
            setIsDirty(false)
        }
    }, [jsScript])

    useEffect(() => {
        setJsScript(initialScript);
    }, [initialScript]);

    useEffect(() => {
        setOpen(true);
        dispatch(initScriptEditor(config));
    }, [config]);

    const fetchScriptVariableAPI = async () => {
        if (scriptEditorApiPayload) {
            setLoadingVariableTree(true);

            let variableValueResponse: any;

            if (isCronAutomation && selectedCron?.cronId) {
                variableValueResponse = BffService.getScriptVariableWithResponse(selectedCron?.cronId,scriptEditorApiPayload)
            } else if (isEntityAutomation && selectedEntity?.tableType) {
                variableValueResponse = BffService.getScriptVariableWithResponse(selectedEntity?.tableType,scriptEditorApiPayload);
            } else if (isTeamAutomation || isUserAutomation) {
                variableValueResponse = BffService.getScriptVariableWithResponse(accountId, scriptEditorApiPayload)
            } else if (isMonitorAutomation) {
                variableValueResponse = BffService.getScriptVariableWithResponse(selectedMonitorId, scriptEditorApiPayload);
            } else if (isWebHookAutomation) {
                variableValueResponse = BffService.getScriptVariableWithResponse(selectedWebhook?.webhookId, scriptEditorApiPayload);
            } else if (taskTypeId) {
                variableValueResponse = BffService.getScriptVariableWithResponse(taskTypeId, scriptEditorApiPayload);
            }

            variableValueResponse
                .then((res: any) => {
                    if (res?.code == "200") {
                        setLoadingVariableTree(false);
                        setVariableTreeData(res?.data);
                        dispatch(setMockDataContext(res?.data));
                        const output = processResponseStructure(res?.data);
                        setFilteredSuggestion(output)
                    } else {
                        setLoadingVariableTree(false);
                        notification("error", "Please open the Script Builder and try again");
                    }
                })
                .catch((err: any) => {
                    setLoadingVariableTree(false);
                    notification("error", "Please open the Script Builder and try again");
                });
        }
    }

    useEffect(() => {
        const handleKeyDown = (event:any) => {
          if (event.metaKey && event.key.toLowerCase() === 'b') {
            runCode();
          } else if (event.metaKey && event.key.toLowerCase() === 'c') {
            setExpanded((data) => !data);
          } else if (event.ctrlKey && event.key === 'Enter') {
            // Add your control + enter shortcut logic here
            runCode(); // or any other function you want to call
          }
        }
    
        window.addEventListener('keydown', handleKeyDown);
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }, []);

    useEffect(() => {
        fetchScriptVariableAPI();
    }, [`${scriptEditorApiPayload}`]);

    useEffect(() => {
        if (isEntityAutomation) {
            dispatch(initMockData({ taskTypeId: selectedEntity?.tableType as string, type: "entity" }));
        } else if (isCronAutomation) {
            dispatch(initMockData({ taskTypeId: selectedCron?.cronId, type: ABMetadataTypeEnum.CRON }));
        } else if (isMonitorAutomation) {
            dispatch(initMockData({ taskTypeId: selectedMonitorId, type: ABMetadataTypeEnum.MONITOR }));
        } else if (isTeamAutomation || isUserAutomation) {
            dispatch(initMockData({ taskTypeId: accountId, type: metadataType }));
        } else if (taskTypeId) {
            // fetch Test data and update in redux while opening Low Code Editor
            dispatch(initMockData({ taskTypeId, type: "workflow" }));
        }

    }, [taskTypeId, isEntityAutomation, isCronAutomation, isMonitorAutomation]);

    useEffect(() => {
        if (editorRef?.current) {
            editorRef.current.trigger('keyboard', 'type', { text: copiedPreDefined?.data });
        }
    }, [copiedPreDefined]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleClose = (type: ISaveType) => {
        if ([ISaveType.SAVE, ISaveType.CLOSE_WITHOUT_SAVE]?.includes(type)) {
            ![EditorScriptLocation.API_ACTION_JSON_BODY].includes(editorLocation) &&
                dispatch(clearScriptEditor());
            dispatch(setScriptEditorOpen(false));
            if ([EditorScriptLocation.API_ACTION_JSON_BODY].includes(editorLocation)) {
                if ([ISaveType.SAVE].includes(type)) {
                    openJSEditor({
                        config: literalActionBodyConfig.ApiActionJsonBody,
                        editorLocation: EditorScriptLocation.API_ACTION_JSON_BODY,
                        isEditorDisabled: true,
                        defaultEditorLanguage: "javascript",
                    });
                } else if (getScriptValue(editorLocation)?.length <= 0) {
                    openJSEditor({
                        config: literalActionBodyConfig.ApiActionBody,
                        editorLocation: EditorScriptLocation.API_ACTION_JSON_BODY,
                        defaultEditorLanguage: "json",
                    });
                } else {
                    openJSEditor({
                        config: literalActionBodyConfig.ApiActionJsonBody,
                        editorLocation: EditorScriptLocation.API_ACTION_JSON_BODY,
                        isEditorDisabled: true,
                        defaultEditorLanguage: "javascript",
                    });
                }
            }

            if (editorLocation == EditorScriptLocation.AB_API_ACTION_URL ||editorLocation == EditorScriptLocation.ERROR_MESSAGE ||editorLocation == EditorScriptLocation.EXPRESSION_CONDITION_EXPRESSION_TO_VALIDATE ||editorLocation == EditorScriptLocation.EXPRESSION_GUARD_CONDITION ||editorLocation === EditorScriptLocation.API_ACTION_SHOULD_RETRY) {
                const apiDataScriptData = getScriptValue(EditorScriptLocation.API_ACTION_JSON_BODY);

                openJSEditor({
                    config: literalActionBodyConfig.APIActionJsonBodyUrl,
                    editorLocation: EditorScriptLocation.API_ACTION_JSON_BODY,
                    isEditorDisabled: apiDataScriptData ? true : false,
                    defaultEditorLanguage: apiDataScriptData ? "javascript" : "json",
                });
            }

            clearGlobalVariablesData(runTimeVariable);
            document.body.classList.remove("modal-open");
        } else if ([ISaveType.FORCE_SAVE].includes(type)) {
            let data = {
                type: "script",
                value: jsScript,
                dataType: config.dataType,
            };

            if (editorLocation.startsWith("builder_")) {
                const dataForFlutter = {
                    type: "literal/script",
                    dataType: dataType,
                    script: jsScript,
                    key: config?.key,
                };
                document
                    .getElementById("app-builder")
                    ?.contentWindow?.postMessage(dataForFlutter, "*");
                handleClose(ISaveType.SAVE);
            } else {
                const tempAction = cloneDeep({ ...selectedAction, hasChanged: true });

                if (selectedAction?.actionType === actionType?.API_ACTION || selectedAction?.guardType === guardTypes.API_GUARD) {
                    set(tempAction, editorLocation, data);
                    dispatch(setSelectedAction(tempAction));
                } else if (selectedAction?.actionType === actionType?.UPDATE_DATA_FIELD && editorLocation !== EditorScriptLocation.ERROR_MESSAGE) {
                    const FieldDataIndex = editorLocation.split(" ").pop();

                    const checkKeyAlreadyPresentIndex =
                        tempAction?.updateDataFieldData?.findIndex((data: any) =>
                            data.hasOwnProperty(editorLocation.match(/^[^\s]+/g))
                        );

                    if ((checkKeyAlreadyPresentIndex != undefined || checkKeyAlreadyPresentIndex != null) && checkKeyAlreadyPresentIndex >= 0) {
                        tempAction.updateDataFieldData[FieldDataIndex] = {
                            [editorLocation.match(/^[^\s]+/g)]: data,
                        };
                        dispatch(setSelectedAction(tempAction));
                    } else {
                        dispatch(
                            setSelectedAction({
                                ...tempAction,
                                updateDataFieldData: tempAction?.updateDataFieldData
                                    ? [
                                        ...tempAction?.updateDataFieldData,
                                        { [editorLocation.match(/^[^\s]+/g)]: data },
                                    ]
                                    : [{ [editorLocation.match(/^[^\s]+/g)]: data }],
                            })
                        );
                    }
                } else if (showColumnCreationModal) {
                    dispatch(setActiveEditColumn({
                        ...activeEditColumn,
                        script: data
                    }))

                    dispatch(
                        setSelectedAction({
                            ...tempAction,
                            [editorLocation]: data,
                        })
                    );

                    handleClose(ISaveType.SAVE);
                }
                else {
                    /**
                     * set using lodash's set function
                     */
                    const newAction = set(tempAction, editorLocation, data);
                    setTimeout(() => {
                        dispatch(
                            setSelectedAction({
                                ...newAction
                            })
                        );
                    }, 100);
                }
                handleClose(ISaveType.SAVE);
                setConfirmForceSave(false);
            }
        } else {
            if (isDirty) {
                setConfirmClose(true);
            } else {
                handleClose(ISaveType.CLOSE_WITHOUT_SAVE)
            }
        }
    };

    const handleSave = async (type: ISaveType) => {
        if (jsScript) {
            try {

                let data = {
                    type: "script",
                    value: jsScript,
                    dataType: config.dataType,
                };

                const actionORGuardCheck = selectedAction?.actionType === actionType?.API_ACTION || selectedAction?.guardType === guardTypes.API_GUARD;
                const updateDataFieldCheck = selectedAction?.actionType === actionType?.UPDATE_DATA_FIELD && editorLocation !== EditorScriptLocation.ERROR_MESSAGE;
                const updateABConditionCheck = selectedAction?.actionType == IABConditionType.AB_CONDITION && editorLocation == EditorScriptLocation.AB_CONDITION;

                if (type === ISaveType.SAVE && matchReturnDataType(config.dataType, output?.data)) {
                    if (editorLocation.startsWith("builder_")) {
                        // low code editor launched from flutter builder
                        const dataForFlutter = {
                            type: "literal/script",
                            dataType: dataType,
                            script: jsScript,
                            key: config?.key,
                        };

                        document.getElementById("app-builder")?.contentWindow?.postMessage(dataForFlutter, "*");
                        handleClose(ISaveType.SAVE);
                    }

                    else if (selectedDecisionNode || selectedTransition || showWorkflowConfigModal || selectedAction) {
                        // low code editor launched from decision node
                        const tempAction = cloneDeep({ ...selectedAction, hasChanged: true });

                        if (actionORGuardCheck) {

                            const tempData = cloneDeep(selectedAction);
                            set(tempData, editorLocation, data);
                            dispatch(setSelectedAction(tempData));

                        } else if (updateABConditionCheck) {

                            if (selectedAction.actionToPerform == ActionToPerform.CREATE) {

                                dispatch(
                                    addConditionInAutomation({
                                        actionBlockId: selectedActionBlock?.actionBlockId,
                                        condition: {
                                            ...tempAction,
                                            [editorLocation]: data,
                                        },
                                    })
                                );

                            } else if (selectedAction.actionToPerform == ActionToPerform.EDIT) {

                                dispatch(
                                    updateConditionInAutomation({
                                        automationId: selectedAutomation.id,
                                        actionBlockId: selectedActionBlock.actionBlockId,
                                        conditionId: selectedAction.conditionId,
                                        data: {
                                            ...tempAction,
                                            [editorLocation]: data,
                                        },
                                    })
                                );
                            }

                            // This remove the right side panel for trigger configuration
                            dispatch(setSelectedConfiguration({ type: null }));

                            dispatch(setSelectedAction(null));
                        } else if (updateDataFieldCheck) {
                            const FieldDataIndex = editorLocation.split(" ").pop();

                            const checkKeyAlreadyPresentIndex = tempAction?.updateDataFieldData?.findIndex((data: any) => data.hasOwnProperty(editorLocation.match(/^[^\s]+/g)));

                            if ((checkKeyAlreadyPresentIndex != undefined || checkKeyAlreadyPresentIndex != null) && checkKeyAlreadyPresentIndex >= 0) {

                                tempAction.updateDataFieldData[FieldDataIndex] = {
                                    [editorLocation.match(/^[^\s]+/g)]: data,
                                };

                                dispatch(setSelectedAction(tempAction));

                            } else {
                                dispatch(
                                    setSelectedAction({
                                        ...tempAction,
                                        updateDataFieldData: tempAction?.updateDataFieldData
                                            ? [
                                                ...tempAction?.updateDataFieldData,
                                                { [editorLocation.match(/^[^\s]+/g)]: data },
                                            ]
                                            : [{ [editorLocation.match(/^[^\s]+/g)]: data }],
                                    })
                                );
                            }
                        }

                        else {
                            setTimeout(() => {
                                const newData = cloneDeep(tempAction);
                                const pathkey = editorLocation;
                                set(newData, pathkey, data);
                                dispatch(setSelectedAction(newData));
                            }, 100);
                        }
                        handleClose(ISaveType.SAVE);
                    }
                    else if (showColumnCreationModal) {
                        dispatch(setActiveEditColumn({
                            ...activeEditColumn,
                            script: data
                        }))
                        handleClose(ISaveType.SAVE);
                    }
                } else if (type === ISaveType.SAVE && !matchReturnDataType(config.dataType, output?.data)) {
                    setOutput({
                        data: "There is a data type mismatch from script",
                        error: true,
                        allowSave: true
                    });

                    setExpanded(true);
                } else if (type === ISaveType.FORCE_SAVE && output?.allowSave) {
                    setConfirmForceSave(true);
                }

                if (type === ISaveType.FORCE_SAVE && output?.allowSave) {
                    setConfirmForceSave(true);
                }
            } catch (err: any) {
                setErrorMessageFromScript(err, err?.name, type, err?.stack);
            }
        } else {
            setOutput({
                data: "Please add valid script",
                error: true,
                allowSave: false
            });
            setExpanded(true);
        }

    };

    const getErrorMessageWithLineNumber = (errorType: string, err: string, errorStack: string) => {
        const lineNumber = errorStack.match(/<anonymous>:(\d+):(\d+)/);
        const line = lineNumber ? `${lineNumber[1]}:${lineNumber[2]}` : 'Unknown line';
        return { message: `Line number: ${line}, \n ${errorType}: ${err}`, lineNumber: line };
    }

    const setErrorMessageFromScript = (err: any, errorType: IJSErrorType, type: ISaveType, errorStack: string) => {

        let errorMessage = '';
        let lineNumber;

        if (errorStack) {
            const getErrorMessageLineNumberData = getErrorMessageWithLineNumber(errorType, err, errorStack);;
            errorMessage = getErrorMessageLineNumberData.message
            lineNumber = getErrorMessageLineNumberData.lineNumber;
        } else {
            errorMessage = err;
        }

        if (errorType == IJSErrorType.SYNTAX_ERROR || errorType == IJSErrorType.REFERENCE_ERROR) {

            // Don't Allow to save
            setOutput({ data: errorMessage, error: true, allowSave: false, lineNumber });
            setExpanded(true);
        } else if (errorType == IJSErrorType.TYPE_ERROR) {
            // Allow to Save
            setOutput({ data: errorMessage, error: true, allowSave: true, lineNumber });

            if (type === ISaveType.FORCE_SAVE) {
                setConfirmForceSave(true);
            }
        } else if (errorType == IJSErrorType.ERROR) {
            setOutput({ data: errorMessage, error: true, allowSave: true, lineNumber });

            if (type === ISaveType.FORCE_SAVE) {
                setConfirmForceSave(true);
            }
        } else if (errorType == IJSErrorType.AXIOS_ERROR) {
            setOutput({ data: errorMessage, error: true, allowSave: true, lineNumber });
            if (type === ISaveType.FORCE_SAVE) {
                setConfirmForceSave(true);
            }
        } else {
            setOutput({ data: err, error: true, allowSave: true, lineNumber });
            if (type === ISaveType.FORCE_SAVE) {
                setConfirmForceSave(true);
            }
        }
    }

    const runCode = async () => {
        const executableCode = editorRef.current.getValue();

        setIsScriptRunning(true);
        setSelectedLogOutput(false);
        setLogs([]);
        setOutput(undefined);

        // ExPanding the below window
        setExpanded(true);
        setIsDirty(false);
        const DateTime = DateTimeFnc;
        const _ = lodash;

        const appComponents = [];
        for (let c in mockData?.appComponents) {
            appComponents.push(mockData?.appComponents[c]);
        }
        const z = new zorpCommonLib(appComponents);
        const authToken = mockData?.company?.secretKeys?.zorp || "";
        const zorp = new ZActionsLib(authToken)


        // TODO: This needs to be improved.. temporary hack to make sure the libs are available for eval
        // Set the global variables for eval
        window.z = z;
        window.DateTime = DateTime;
        window.axios = axios;
        window._ = _;
        window.zorp = zorp
        window.error = {};
        window.response = {}
        window.retryFunction = retryFunction;


        // get runTimeVariable
        const runTimeVariableData = getRunTimeVariableData(variableTreeData, mockData);
        setRunTimeVariable(runTimeVariableData);
        storeGlobalVariablesData(runTimeVariableData);


        if (executableCode) {
            try {
                console.log = (...args) => {
                    const data = args.map((data)=>{
                        try{
                            if (typeof data == 'object') {
                                return JSON.stringify(data);
                            } else {
                                return data;
                            }
                        } catch(e){
                            return data;
                        }
                      
                    })
                    handleLog(data);
                };
                const jsOutput = await runJSCode(executableCode);
                console.log = originalConsoleLog.current;
                setIsScriptRunning(false);
                if (jsOutput?.zorpErrorLowCodeEditor) {
                    setErrorMessageFromScript(jsOutput?.message, jsOutput.name, ISaveType.RUN, jsOutput?.zorpErrorLowCodeEditor);
                    return;
                }

                // Unset the global variables after the eval
                delete window["z"];
                delete window["DateTime"];
                delete window["axios"];
                delete window["_"];
                delete window['ZActionsLib']

                if (jsOutput != undefined || jsOutput != null || typeof jsOutput === "boolean") {

                    setOutput({ data: jsOutput, error: false, allowSave: true });
                    setExpanded(true);

                    // when the script executed success, then update the Test data
                    // check for the actionId and action type of js function


                    // check for guardId and guard type
                    const isValidAcionTypeToUpdateMockData = selectedAction?.actionType == actionType.JS_FUNCTION_ACTION || selectedAction?.actionType == actionType.EXPRESSION;
                    const isValidGuardTypeToUpdateMockData = selectedAction?.guardType == guardTypes.EXPRESSION_GUARD;
                    const isValidConditionTypeToUpdateMockData = selectedAction?.conditionType == conditionTypes.EXPRESSION;
                    const isValidEditorLocationToUpdateMockData = editorLocation == EditorScriptLocation.AB_SMART_ACTION_JS_FUNCTION || editorLocation == EditorScriptLocation.EXPRESSION_CONDITION_EXPRESSION_TO_VALIDATE || editorLocation == EditorScriptLocation.AB_CONDITION;

                    if ((isValidAcionTypeToUpdateMockData || isValidGuardTypeToUpdateMockData || isValidConditionTypeToUpdateMockData) && isValidEditorLocationToUpdateMockData) {
                        const mockDataPayload = cloneDeep(mockData);

                        if (selectedAction?.actionType == actionType.JS_FUNCTION_ACTION || selectedAction?.actionType == actionType.EXPRESSION) {
                            mockDataPayload[`response_${selectedAction?.actionId}`] = jsOutput;
                        } else if (selectedAction?.guardType == guardTypes.EXPRESSION_GUARD) {
                            mockDataPayload[`response_${selectedAction?.guardId}`] = jsOutput;
                        } else if (selectedAction?.conditionType == conditionTypes.EXPRESSION) {
                            mockDataPayload[`response_${selectedAction?.conditionId}`] = jsOutput;
                        }

                        updateMockDataValue(store, dispatch, mockDataPayload);
                    }


                } else {
                    setOutput({
                        data: "There is an error in the script, you are probably returning undefined",
                        error: true,
                        allowSave: true
                    });
                    setExpanded(true);
                }
            } catch (err: any) {
                setIsScriptRunning(false);
                setErrorMessageFromScript(err, err.name, ISaveType.RUN, err?.stack);
            }
        } else {
            setIsScriptRunning(false);
            setOutput({
                data: "Please add valid script",
                error: true,
                allowSave: false,
            });
            setExpanded(true);
        }
    };

    const handleCloseItem = () => {
        if (isDirty) {
            setConfirmClose(true);
        } else {
            handleClose(ISaveType.CLOSE_WITHOUT_SAVE);
        }
    };

    return (
        <>
            <ZEditorModal
                title=''
                isOpen={open}
                handleClose={handleCloseItem}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                childComponent={
                    <>
                        <Box sx={
                            {
                                border: '1px solid #F2F2F2',
                                width: '100%',
                                bgcolor: 'background.paper',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column'
                            }
                        }>

                            <ModalHeader>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>
                                    <ZButton
                                        startIcon={<img src={lowCodeEditorBackBtn} />}
                                        style={{
                                            width: '112px',
                                            padding: '14px 8px',
                                            color: '#344054',
                                            height: '36px',
                                            fontSize: '14px',
                                            fontWeight: 600,
                                            marginRight: '8px'
                                        }} secondary onClick={() => {
                                            handleClose(ISaveType.EMPTY)
                                        }} variant="contained">
                                        Go Back
                                    </ZButton>

                                    <p style={{
                                        fontSize: '16px'
                                    }}>
                                        Script Builder : &nbsp;
                                        <span style={{ color: '#667085', fontSize: '14px' }}>
                                            {getLowCodeEditorTitle(metadataType, selectedTransition, selectedDecisionNode, selectedAutomation, selectedAction, editorLocation, selectedNode, showWorkflowConfigModal, showTaskCardConfig, selectedEntity, isEntityAutomation || activeEditColumn?.type === EntityTypeField.COMPUTED, activeEditColumn, selectedCron)}
                                        </span>
                                    </p>

                                </div>
                            </ModalHeader>


                            <div style={{ height: '100%', overflow: 'none' }}>

                                <div style={{ display: 'flex', flex: '1', height: '100%', width: '100%', flexWrap: 'wrap' }}>
                                    {
                                        showVariablePanel &&
                                        <div style={{ height: '90vh', width: '20%', flex: '20%', flexGrow: '1' }}>

                                            <VariableTreeLowCodeEditor
                                                variableTreeData={variableTreeData}
                                                loading={loadingVariableTree}
                                            />

                                        </div>
                                    }

                                    <div style={{ height: '100%', width: '80%', flex: '80%', flexGrow: '1', borderLeft: '1px solid #e1e4e8', backgroundColor:'#F9FAFB' }}>
                                        <PanelGroup autoSaveId="example" direction="vertical">

                                            <Panel className={styles.Panel} order={2}>
                                                <div className="" style={{ height: '100%' }}>
                                                    <>
                                                    <div className="" style={{
                                                        backgroundColor:'#1E1E1E',
                                                        height:'18px'
                                                    }}>

                                                    </div>
                                                        <Editor
                                                            height={'100%'}
                                                            language={editorLanguageState}
                                                            value={code}
                                                            onMount={handleEditorDidMount}
                                                            onChange={(value) => {
                                                                setCode(value);
                                                                setJsScript(value);
                                                            }}
                                                            theme={settings.theme}
                                                            options={{
                                                                minimap: { enabled: settings.minimap },
                                                                wordWrap: settings.wordWrap ? 'on' : 'off',
                                                                fontSize: `${settings.fontSize || 0}px`,
                                                                automaticLayout: true,
                                                                readOnly: disabledState,
                                                            }}
                                                        />
                                                    </>
                                                </div>
                                            </Panel>
                                            {expanded && (
                                                <>
                                                    <ResizeHandle />
                                                    <Panel className={styles.Panel} defaultSize={20} order={3}>
                                                        <ScriptDescWrapper>

                                                            {helperTxt}
                                                            {
                                                                (typeof output?.data == 'object' && !output?.error) ?
                                                                    <OutputWrapper>

                                                                        {
                                                                            logs.map((data, index) => {
                                                                                return (
                                                                                    <>
                                                                                        <ConsoleItem
                                                                                            selected={selectedConsole == index}
                                                                                            onClick={() => {
                                                                                                setSelectedLogOutput(false)
                                                                                                setSelectedConsole(index)
                                                                                            }}
                                                                                            onDoubleClick={() => {
                                                                                                setSelectedLogOutput(false)
                                                                                                navigator.clipboard.writeText(data);
                                                                                                notification('success', 'console value copied to clipboard');
                                                                                            }}
                                                                                        >
                                                                                            <p style={{ padding: '2px 4px', fontSize: '12px' }}>{data}</p>
                                                                                        </ConsoleItem>

                                                                                    </>
                                                                                )
                                                                            })
                                                                        }

                                                                        <ConsoleItem
                                                                            selected={selectedLogOutput}
                                                                            onClick={() => {
                                                                                setSelectedLogOutput(true)
                                                                                setSelectedConsole(undefined)
                                                                            }}
                                                                        >

                                                                            <ReactJson
                                                                                displayDataTypes={false}
                                                                                style={{ overflow: "auto", height: '100%', fontSize: '12px' }}
                                                                                name={false}
                                                                                enableClipboard={async (data) => {
                                                                                    await navigator.clipboard.writeText(JSON.stringify(data.src));
                                                                                }}
                                                                                src={output?.data}
                                                                            />
                                                                        </ConsoleItem>
                                                                    </OutputWrapper>
                                                                    :
                                                                    <>

                                                                        <OutputWrapper>
                                                                            {
                                                                                logs.map((data, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <ConsoleItem
                                                                                                selected={selectedConsole == index}
                                                                                                onClick={() => {
                                                                                                    setSelectedLogOutput(false)
                                                                                                    setSelectedConsole(index)
                                                                                                }}
                                                                                                onDoubleClick={() => {
                                                                                                    setSelectedLogOutput(false)
                                                                                                    navigator.clipboard.writeText(data);
                                                                                                    notification('success', 'console value copied to clipboard');
                                                                                                }}>
                                                                                                <p style={{ padding: '2px 4px', fontSize: '12px' }}>{data}</p>
                                                                                            </ConsoleItem>

                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }

                                                                            <ConsoleItem
                                                                                selected={selectedLogOutput}
                                                                                onDoubleClick={() => {
                                                                                    navigator.clipboard.writeText(output?.data as string);
                                                                                    notification('success', 'console value copied to clipboard');
                                                                                }}
                                                                                onClick={() => {
                                                                                    setSelectedLogOutput(true);
                                                                                    setSelectedConsole(undefined)
                                                                                }}>
                                                                                <div style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'center'
                                                                                }}>
                                                                                    <IoIosArrowForward color='#3170F9' />
                                                                                    <p
                                                                                        style={{
                                                                                            padding: '2px 4px',
                                                                                            fontSize: '12px',
                                                                                            cursor: output?.error ? 'pointer' : ''
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            const lineNumber = parseInt(output?.lineNumber?.split?.(':')?.[0] as string);
                                                                                            if (lineNumber) {
                                                                                                editorRef?.current?.revealLineInCenter(lineNumber);
                                                                                                highlightLine(lineNumber);
                                                                                            }
                                                                                        }} className={output?.error ? 'text-red-500' : ''}>{output?.data}</p>
                                                                                </div>
                                                                            </ConsoleItem>
                                                                        </OutputWrapper>
                                                                    </>
                                                            }
                                                        </ScriptDescWrapper>
                                                    </Panel>

                                                </>
                                            )}

                                            {
                                                !expanded &&
                                                <div style={{
                                                    padding: '10px 16px',
                                                    backgroundColor: '#F9FAFB',
                                                    height: '38px'
                                                }}>
                                                    {helperTxt}
                                                </div>
                                            }

                                            <LowCodeEditorFooter
                                                isDirty={isDirty}
                                                jsScript={jsScript}
                                                isScriptRunning={isScriptRunning}
                                                output={output}
                                                runCode={() => {
                                                    runCode()
                                                }}
                                                onConsoleClick={() => {
                                                    setExpanded((data) => !data);
                                                }}
                                                handleSave={(context: ISaveType) => {
                                                    handleSave(context);
                                                }}
                                            />
                                        </PanelGroup>
                                    </div>
                                </div>
                            </div>
                        </Box>

                    </>
                }
            />

            <DeletionConfirmationDialog
                id={"scriptEditor"}
                deletionTitle={CLOSE_EDITOR}
                deletionText={CLOSE_CONFIRMATION}
                isOpen={confirmClose}
                onConfirmDelete={(e: any) => handleClose(ISaveType.CLOSE_WITHOUT_SAVE)}
                onClose={() => setConfirmClose(false)}
            />
            <ChoiceConfirmation
                isOpen={confirmForceSave}
                confirmationTitle={FORCE_SAVE_WARNING}
                confirmationMessage={FORCE_SAVE_CONFIRMATION}
                onClose={() => setConfirmForceSave(false)}
                onConfirm={(e: any) => handleClose(ISaveType.FORCE_SAVE)}
            />

        </>
    );
};