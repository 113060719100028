import React, {useState, useRef, useEffect, memo} from "react";
import { AiOutlineCalendar, AiOutlineLink, AiOutlineSetting } from "react-icons/ai";
import {
  setSelectedActionColumn,
  setActiveEditColumn,
  setShowCreationModal,
} from "../reducers/entityReducers";
import { useDispatch } from "react-redux";
import useAccessPermissions from "hooks/useAccessPermissions";
import { ResourceTypeConstant } from "../permissions/types";
import {
  DataFieldType,
  EntityTypeField,
} from "views/DataField/DataField.types";
import { Tooltip } from "@mui/material";
import { IRelationReferringEntityType } from "../types";
import { AutomationRIcon, CalendarRIcon, ComputedRIcon, DropdownRIcon, FileRIcon, ImageRIcon, IntegerRIcon, LocationRIcon, RelationRIcon, SignatureRIcon, TextRIcon, ToggleRIcon, UserRIcon } from "assests"
import { LuClock } from "react-icons/lu";
import { TbUsers } from "react-icons/tb";
import styled from "styled-components";

type Props = {
  col: any;
  params: any;
  allowColumnEdit?: boolean;
};

const GearIconContainer = styled.div<{ $hover?: boolean; }>`
  cursor: pointer;
  visibility: ${({ $hover }) => ($hover ? "visible" : "hidden")};
`

const getDataTypeIcon = (type: DataFieldType | EntityTypeField) => {
  switch (type) {
    case DataFieldType.TEXT:
      return <TextRIcon color="#667085" size="12px" />;
    case DataFieldType.NUMBER:
      return <IntegerRIcon color="#667085" size="1.2rem" />;
    case DataFieldType.BOOLEAN:
      return <ToggleRIcon color="#667085" size="1.2rem" />;
    case DataFieldType.DATE:
      return <CalendarRIcon color="#667085" size="1.2rem" />;
    case DataFieldType.SIGNATURE:
      return <SignatureRIcon color="#667085" size="1.2rem" />;
    case DataFieldType.IMAGE:
      return <ImageRIcon color="#667085" size="1.2rem" />;
    case DataFieldType.LIST_OF_TEXT:
      return <DropdownRIcon color="#667085" size="1.2rem" />;
    case DataFieldType.FILE:
      return <FileRIcon color="#667085" size="1.2rem" />;
    case DataFieldType.LOCATION:
      return <LocationRIcon color="#667085" size="1.2rem" />;
    case DataFieldType.ONLY_DATE:
      return <AiOutlineCalendar size="1.2rem" color="#667085" strokeWidth={2} />
    case DataFieldType.TIME:
      return <LuClock size="1.2rem" color="#667085" strokeWidth={2} />
    case DataFieldType.LINKS:
      return <AiOutlineLink size="1.8rem" color="#667085" />
    case EntityTypeField.COMPUTED:
      return <ComputedRIcon color="#667085" size="1.2rem" />;
    case EntityTypeField.RELATION:
      return <RelationRIcon color="#667085" size="1.2rem" />;
    case EntityTypeField.USER:
      return <UserRIcon color="#667085" size="1.2rem" />;
      case EntityTypeField.TEAM:
        return <TbUsers color="#667085" size="1.4rem" />;
    case EntityTypeField.BUTTON:
      return <AutomationRIcon color="#667085" />;
   
    default:
      return <TextRIcon color="#667085" size="1.2rem" />;
  }
};

const isTextOverflowing = (element: HTMLElement | null) => {
  return element ? element.scrollWidth > element.clientWidth : false;
};

const ButtonAutomationHeader = ({
  col,
  params,
  allowColumnEdit = true,
}: Props) => {
  const dispatch = useDispatch();
  const [hover, setHover] = useState(false);
  const accessPermissions = useAccessPermissions({resourceType: ResourceTypeConstant.TABLE});
  const textRef = useRef<HTMLDivElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      setIsOverflow(isTextOverflowing(textRef.current));
    }
  }, [params]);

  return (
    <div
      style={{
        display: "flex",
        width: "95%",
        gap: "8px",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "6px 0px",
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        style={{
          display: "flex",
          gap: "4px",
          alignItems: "center",
          width: "80%",
        }}
      >
        <Tooltip
          title={
            params?.column?.columnDef?.data?.type === DataFieldType.LIST_OF_TEXT
              ? "Dropdown"
              : params?.column?.columnDef?.data?.type
          }
        >
          <div>
            {getDataTypeIcon(
              params?.column?.columnDef?.data?.relationDetails?.referringEntity ===
                IRelationReferringEntityType.USER
                ? IRelationReferringEntityType.USER
                : params?.column?.columnDef?.data?.relationDetails?.referringEntity ===
                  IRelationReferringEntityType.TEAM
                ? IRelationReferringEntityType.TEAM
                : 
                params?.column?.columnDef?.data?.type
            )}
          </div>
        </Tooltip>

        <Tooltip title={isOverflow ? params?.column?.columnDef?.data?.displayName : ""}>
          <div
            ref={textRef}
            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: "100%" }}
          >
            {params?.column?.columnDef?.data?.displayName || "Action"}
          </div>
        </Tooltip>
      </div>
      {allowColumnEdit && accessPermissions.manage_table_column && (
        <GearIconContainer $hover={hover}>
          <AiOutlineSetting
            onClick={(e) => {
              e.stopPropagation();
              console.log(">>>> params", params);
              dispatch(setSelectedActionColumn(col.colDef));
              // setOpenAB(true);
              dispatch(setActiveEditColumn(params.column.columnDef.data));
              dispatch(setShowCreationModal(true));
            }}
          />
        </GearIconContainer>
      )}
    </div>
  );
};

export default memo(ButtonAutomationHeader);
