import React from 'react'
import { ToastProps } from 'react-toastify/dist/types'

type Props = {
    closeToast: () => void
    toastProps: ToastProps
    message: string
    heading ?: string
}

const NotificationContent = ({ closeToast, toastProps,message,heading }: Props) => {
  return (
    <div> 
        <p style={{fontWeight:500, fontSize:"16px"}}> { heading || "Notification" } </p>
        <p> {message} </p>
    </div>
  )
}

export default NotificationContent