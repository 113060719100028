import React, { useEffect } from 'react';
import OrganizationChart from '@dabeng/react-orgchart';

import { BffService } from '../../providers/data/services/BffService';

export const TeamHierarchy = () => {
  const [teamStructure, setTeamStructure] = React.useState({});

  useEffect(() => {
    BffService.getTeamDescendants()
      .then((res) => {
        setTeamStructure(res[0] || {});
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return <>
    <div style={{width: "100%"}}>
      <OrganizationChart datasource={teamStructure} />
    </div>
  </>
};
