import React from "react";
import styled from "styled-components";
import Lottie from "lottie-react";
import ZErrorBoundary from "./ZErrorBoundary.json";
import { ZButton } from "views/commonComponents/commonComponents.styles";

const Quote = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -2rem;

  p {
    span {
      font-size: 18px;
      font-weight: 600;
      color: black;
    }
    font-size: 14px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.6);
    width: 25%;
    text-align: center;
    margin-top: 1rem;
  }
`;

const Image = styled.div`
  height: inherit;
  width: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;

  div > svg {
    height: 55% !important;
    width: 90% !important;
  }
`;
function randomIntFromInterval(min: number, max: number): number {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const ErrorBoundaryComp = ({
  message,
  resetErrorBoundary,
}: {
  message: string;
  resetErrorBoundary: any;
}) => {
  return (
    <div>
      <Image>
        <Lottie
          style={{ display: "flex", justifyContent: "center" }}
          loop={true}
          autoplay={true}
          animationData={ZErrorBoundary}
        />
      </Image>
      <div className="mt-2"></div>
      <Quote>
        <p>
          <span>
            We've detected an issue, and specific error logs have been sent to
            our team for immediate attention. Thanks for your patience as we
            work on a solution.
          </span>{" "}
          <br />
          <br />
          <br />
          {message}
          <br />
          <br />
          We suggest you to{" "}
          <u
            onClick={() => {
              resetErrorBoundary();
              window.location.reload();
            }}
            style={{ cursor: "pointer", color: "blue" }}
          >
            refresh
          </u>{" "}
          the page.
        </p>
      </Quote>
    </div>
  );
};

export default ErrorBoundaryComp;
