import {DataFieldType} from "views/DataField/DataField.types"

export const getDefaultValue = (type:string):any => {
    switch(type) {
      case DataFieldType.NUMBER:
        return undefined
      case DataFieldType.TEXT:
        return ""
      case DataFieldType.BOOLEAN:
        return false 
      default: 
      return undefined
    }
  }