import { useEffect } from 'react';
import { recordRSEvent } from 'utils/CommonUtils';
import ABActionComponent from 'views/automationBuilder/components/ABActionComponent';
import ABConditionComponent from 'views/automationBuilder/components/ABConditionComponent';
import ExpressionAction from 'views/automationBuilder/ExpressionAction';
import ExpressionCondition from 'views/automationBuilder/ExpressionCondition';
import { actionType, conditionTypes } from 'views/automationBuilder/types/ABType';
import useWorkFlow from '../hooks/useWorkFlow';
import APIAction from './api/APIAction/APIAction';
import ExpressionGuard from './api/ExpressionGuard/ExpressionGuard';
import { guardTypes } from './api/guard.types';
import LocationCheck from './api/LocationCheck/LocationCheck';
import PushNotification from './api/PushNotification/PushNotification';
import UpdateDatefield from './api/UpdateDatafield/UpdateDataField';
import {UpdateTeamAction} from './api/APIAction/UpdateTeamAction';
import ZorpAutomation from 'views/automationBuilder/components/ZorpAutomation';
import DynamicForm from 'views/automationBuilder/components/AutomationBuilder';


const ActionDetails = () => {
  const { selectedAction: action, selectedTransition: transition } = useWorkFlow();
  // actionType for "Push Notification is undefined so has a workaround"
  useEffect(() => {
    recordRSEvent('action_modal_open', {
      context: 'builder_transition_config',
      transition_id: transition?.id,
      action_type: action.actionType,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{height:'100%'}}>
      {action?.actionType === actionType.API_ACTION ? (

        <APIAction type='action' />

      ) : action?.actionType === actionType.SCREEN_COMPONENT ? (

        <ABActionComponent />

      ): action?.actionType === actionType.TASK_UPDATE_TEAM_ACTION ? (

        <UpdateTeamAction />

      ): action?.actionType === actionType.EXPRESSION ? (

        <ExpressionAction />

      ): action?.actionType === actionType.JS_FUNCTION_ACTION ? (

        <ExpressionAction />

      ) : action?.actionType === actionType.UPDATE_DATA_FIELD ? (

        <UpdateDatefield type='update' />

      ) : action?.actionType === actionType.PUSH_NOTIFICATION ? (

        <PushNotification />

      ) : action?.guardType === guardTypes.LOCATION_CHECK ? (

        <LocationCheck />

      ) : action?.guardType === guardTypes.API_GUARD ? (

        <APIAction type={'guard'} />

      ) : (action?.guardType === guardTypes.EXPRESSION_GUARD || action?.guardType === guardTypes.EXPRESSIONGUARD) ? (

        <ExpressionGuard />

      ) : action?.conditionType === conditionTypes.EXPRESSION ? (

        <ExpressionCondition />

      ): action?.conditionType === conditionTypes.SCREEN_COMPONENT ? (

        <ABConditionComponent />

      ) : action?.actionType === actionType.CREATE_ZORP_RECORD || action?.actionType === actionType.UPDATE_ZORP_RECORD || action?.actionType === actionType.DELETE_ZORP_RECORD || action?.actionType === actionType.DELETE_ZORP_RECORD || action?.actionType === actionType.CREATE_ZORP_TASK || action?.actionType === actionType.UPDATE_ZORP_TASK || action?.actionType === actionType.DELETE_ZORP_TASK || action?.actionType === actionType.SEARCH_ZORP_TASK || actionType.SEARCH_ZORP_RECORD || actionType.UPDATE_TEAM  ? (

        <ZorpAutomation />

      ) : null }
    </div>
  );
};

export default ActionDetails;
