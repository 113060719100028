import React from 'react'
import { useSelector } from 'react-redux'
import { IInitState } from '../types'
import { EditorScriptLocation } from 'lowcode/state/types/types'

type Props = {}

const useEntity = () => {
  const entities: IInitState = useSelector((state: any) => state.entity)

  const allEntities = entities.entities
  const selectedEntity = entities.selectedEntity
  const showCreateEntityModal = entities.showCreateEntityModal
  const entityPermissions = entities.entityPermissions
  const finalEntityPermissions = entities.finalEntityPermissions
  const permissionConfiguration = entities.permissionConfiguration
  const savedViewChanges = entities.savedViewChanges
  const selectedViewId = entities.selectedViewId
  const showColumnCreationModal = entities.showColumnCreationModal
  const selectedEntityMetadata = entities.selectedEntityMetadata
  const selectedActionColumn = entities.selectedActionColumn
  const selectedEntityData = entities.selectedEntityData
  const activeEditColumn = entities.activeEditColumn
  const activeCreateColumn = entities.activeCreateColumn
  const selectedRow = entities.selectedRow
  const rowsAfterAnyOps = entities.rowsAfterAnyOps
  const showEditEntityModal = entities.showEntityEditModal
  const showTableAppConfigCard = entities.showTableAppConfigCard

  const getDataTypeOfKey = (key: string): string | null => {
    if (entities.selectedEntityMetadata) {
      const field = entities.selectedEntityMetadata.customFields.find((field: any) => field.name === key)
      if (field) {
        return field.type
      } else {
        return null
      }

    }
    return null
  }

  const recordTypeLabel = selectedEntityMetadata?.tableMetadata?.recordTypeLabel || selectedEntity?.tableDisplayName || "Record";


  const getScriptValueForEntity = (editorLocation: string) => {
    if (editorLocation === EditorScriptLocation.ENTITY_COMPUTED_COLUMN) {

      /**
       * it is possible that column is in creation stage even if activeEditColumn is true.
       * this can happend when we are adding script to any column for example in computed column or in button
       * to differentiate, we can check type property on activeEditColumn. if it is there than column is in edit stage.
       */
      // if type is there than column is in edit stage. 
      if (activeEditColumn?.hasOwnProperty('type')) {
        /**
         * if column is in edit stage than we need to check if it is computed column or not.
         * if it is computed column than we need to check if script is there or not.
         * script is present as it contains the currently modified script. 
         * For ex: in computed column the actual script is stored in activeEDitColumn.otherDetails.expression
         * but to make it constant for all columns we are storing it in activeEditColumn.script
         * So, initialy, none of them is present.
         * if the column is in edit stage but the script is not edited, then activeEditColumn.script is undefined
         * but activeEditColumn.otherDetails.expression is present.
         * else if some edits have been made to script, than activeEditColumn.script is present.
         */
        let data = activeEditColumn?.script || activeEditColumn?.otherDetails?.expression
        if (data?.hasOwnProperty('type') && data?.type !== 'literal') {
          return data?.value;
        }
      } else {
        let data = activeEditColumn?.script
        if (data?.hasOwnProperty('type') && data?.type !== 'literal') {
          return data?.value;
        }
      }
    }

    if (editorLocation === EditorScriptLocation.BUTTON_AUTOMATION_URL) {
      /**
       * same as above
       */
      if (activeEditColumn?.hasOwnProperty('type')) {
        let data = activeEditColumn?.script || activeEditColumn?.url?.link
        if (data?.hasOwnProperty('type') && data?.type !== 'literal') {
          return data?.value;
        }
      } else {
        let data = activeEditColumn?.script
        if (data?.hasOwnProperty('type') && data?.type !== 'literal') {
          return data?.value;
        }
      }

    }

    return ""
  }

  return {
    allEntities,
    activeEditColumn,
    selectedEntity,
    showCreateEntityModal,
    entityPermissions,
    finalEntityPermissions,
    permissionConfiguration,
    savedViewChanges,
    selectedViewId,
    showColumnCreationModal,
    selectedEntityMetadata,
    selectedActionColumn,
    selectedEntityData,
    getScriptValueForEntity,
    activeCreateColumn,
    selectedRow,
    rowsAfterAnyOps,
    showEditEntityModal,
    getDataTypeOfKey,
    recordTypeLabel,
    showTableAppConfigCard
  }

}

export default useEntity