/**
 * The Monitor component is responsible for displaying the monitoring functionality of the application.
 * It fetches account details, checks if the account is enabled for Shopify, and renders the appropriate content based on the account configuration.
 */
import { TabContext } from '@mui/lab';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import notification from "notifications/notifications";
import { ZorpResponse } from "providers/data/models/ZorpResponse";
import { AccountService } from "providers/data/services/AccountService";
import { BffService } from "providers/data/services/BffService";
import { MonitorService } from "providers/data/services/MonitorService";
import { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAccountId, recordRSEvent } from "utils/CommonUtils";
import LoadingScreen from "views/LoadingScreen/LoadingScreen";
import ConfirmationDialog from 'views/automationBuilder/components/ConfirmationDialog';
import { ZTabList } from "views/commonComponents/commonComponents.styles";
import { initColor } from "views/settings/GeneralSetting/ThemeColorSelector";
import MonitorInterface from './MonitorInterface';
import MonitorList from "./MonitorList";
import MonitorOnboardingScreen from "./MonitorOnboardingScreen";
import ShopifyMonitor from "./ShopifyMonitor";
import { IShopifySyncData, intDatasource, setIsConfirmMonitorModalOpen, setShopifySyncData, setShowInterfaceUI } from "./reducer/MonitorReducer";
import useMonitor from "./reducer/useMonitor";

const style = {
  fontFamily: 'Inter',
  fontWeight: 600,
  fontSize: '12px !important',
  justifyContent: 'center',
  flexDirection: 'row'
};

function TabPanel(props:any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const Monitor = () => {
  const [ticketTableCreated, setTicketTableCreated] = useState<boolean>();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [colourTheme, setColourTheme] = useState<string>();
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [changeTabConfirmation, setTabChangeConfirmation] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { clearMonitorState, datasourceListData } = useMonitor();
  const { onEditClassicBuilderTemplateData, showInterfaceUI, monitorInterfaceContext } = useMonitor();
  const [monitorList, setMonitorList] = useState([]);
  const [selectedMonitorData, setSelectedMonitorData] = useState<any>({});
  const accountId = getAccountId();

  useEffect(() => {
    if (onEditClassicBuilderTemplateData?.title) {
      dispatch(setIsConfirmMonitorModalOpen(false));
    }
  }, [onEditClassicBuilderTemplateData?.title]);


  useEffect(() => {
    getAccountDetails();

    return () => {
      clearMonitorState();
    };
  }, []);

  useEffect(()=>{
    recordRSEvent("page_load", {
      context: "monitor", accountId
    });
  },[])

  const getAccountDetails = async () => {
    
    const data: any = await AccountService.getAccount(accountId);
    if (data?.code == "200") {
      setTicketTableCreated(!!data?.data?.accountConfig?.ticketTableId);
      setColourTheme((data?.data?.accountConfig?.theme?.colors?.primary || initColor.primary)?.replace("#", ""));
    } else {
      notification(
        "error",
        "Issue In Fetching Account Details, Try Reloading or contact Zorp Team"
      );
    }
    fetchInitalData();
  };

  const fetchInitalData = () =>{
    fetchMonitor();
    if (datasourceListData?.length == 0) {
      dispatch(intDatasource())
    }
  }

  const initiateMonitor = async () => {
    setButtonLoading(true);
    const accountId = getAccountId();
    const initateMonitor = await BffService.initateMonitor(accountId);
    setButtonLoading(false);
    if (initateMonitor) {
      setTicketTableCreated(true);
      fetchInitalData();
    } else {
      notification(
        "error",
        "Issue In Initiating Monitor, Try Reloading or contact Zorp Team"
      );
    }
  };

  const checkIfAccountEnabledForShopify = async () => {
    try {
      return await MonitorService.executeGQL({
        query:
          "query GetSyncStatus($category: String!) { getSyncStatus(category: $category) { isShopifyApp isOngoingSync lastTs ongoingTs count} }",
        variables: {
          category: "shopify",
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (event: any, newValue: any) => {
    if (showInterfaceUI) {
      setTabChangeConfirmation(true);
    } else {
      setValue(newValue)
    }

    if (newValue == 0) {
      fetchMonitor();
    }
  }

  const fetchMonitor = async () => {
    const getMonitorList = await MonitorService.getMonitor();
    if (getMonitorList?.code == '200') {
      setMonitorList(getMonitorList?.data);
      setSelectedMonitorData(getMonitorList?.data[0]);

      if (getMonitorList?.data.length == 0) {
        setValue(getMonitorList?.data.length == 0 ? 0 : 1);
      }
    } else {
      setMonitorList([])
    }
  }

  useEffect(() => {
    checkIfAccountEnabledForShopify().then((res: ZorpResponse | undefined) => {
      if (res?.data?.getSyncStatus?.isShopifyApp === true) {
        const shopifyDataPayload: IShopifySyncData = {
          shopifyOngoingSync: res?.data?.getSyncStatus?.isOngoingSync,
          shopifyLastTs: res?.data?.getSyncStatus?.lastTs,
        };
        dispatch(setShopifySyncData(shopifyDataPayload));
      }
      setIsLoading(false);
    });
  }, []);

  if (isLoading || !(colourTheme)) return <LoadingScreen />;
  else {
    if (!ticketTableCreated) {
      return (
        <>
          <MonitorOnboardingScreen
            buttonLoading={buttonLoading}
            onClick={()=>{
                initiateMonitor();
            }}
          />
        </>
      );
    } else {
      return (
        <>
          <TabContext value={value}>
            <ZTabList sx={{ overflow: "auto", marginLeft: '10px' }} onChange={handleChange}>
              <Tabs
                value={value}
                aria-label="basic tabs example"
                TabIndicatorProps={{ style: { background: "black" } }}>

                {
                  monitorList.length > 0 && <Tab disabled={monitorList?.length == 0} sx={style} disableRipple label="My Alerts" />
                }
                
                <Tab sx={style} disableRipple label="Create a new Alert" />
              </Tabs>
            </ZTabList>

            <hr />
            {
            monitorList.length > 0 &&
            <TabPanel value={value} index={0}>
              <MonitorList
                onSelectMonitorChange={(hoverData: any) => {
                  setSelectedMonitorData(hoverData);
                }}
                onUpdateSuccess={() => {
                  fetchMonitor();
                }}
                selectedMonitorData={selectedMonitorData}
                monitorList={monitorList}
                openTemplatePage={() => {
                  setValue(monitorList.length == 0 ? 0 : 1);
                }}
              />

            </TabPanel>}


            <TabPanel value={value} index={monitorList.length == 0 ? 0 : 1}>
              {
                !showInterfaceUI &&
                <ShopifyMonitor
                  gotoMonitor={async () => {
                    // show monitor list screen
                    await fetchMonitor();
                    setValue(0);
                  }}
                />
              }

              {
                (showInterfaceUI && monitorInterfaceContext) && <MonitorInterface openTemplatePage={(fetchMonitorList: boolean) => {
                  dispatch(setShowInterfaceUI(false))
                  if (fetchMonitorList) {
                    fetchMonitor();
                  }
                }} />
              }
            </TabPanel>
          </TabContext>

          <ConfirmationDialog
            id={'tab_change_confirmation'}
            deletionTitle={'Confirmation for changing tab'}
            deletionText={`You will lose all the changess you have made to this monitor.`}
            isOpen={changeTabConfirmation}
            primaryButtonName="Yes"
            variant="danger"
            secondaryButtonName="No"
            onPrimaryBtnClick={() => {
              dispatch(setShowInterfaceUI(false))
              setTabChangeConfirmation(false);
              clearMonitorState();
              setValue(monitorList.length == 0 ? 0 :1);
            }}
            onSecondaryClick={() => {
              setTabChangeConfirmation(false);
            }}
            onClose={() => {
              setTabChangeConfirmation(false);
            }}
          />
        </>
      );
    }
  }
};

export default memo(Monitor);