import { FunctionComponent, useEffect } from 'react';
import { ZButton } from './commonComponents.styles';
import {
  ButtonsDiv, 
  FrameDiv,
  FrameDiv1,
  FrameIcon,
  NotSupportedDiv,
  SorryOurBuilder,
  ViewThisScreenOnYourDesk,
} from './NotSupported.styles';
// @ts-ignore
import ExpandIcon from 'assests/browser.svg'; 
import ZModal from './ZModal';
import { useHistory } from 'react-router-dom';
import { useDispatch, useStore } from 'react-redux';
import { setLoading } from 'views/reducer';
import { recordRSEvent } from 'utils/CommonUtils';
import { setIsBeingSaved, setPostPublish, setShowWorkflowConfigModal } from 'views/workflows/reducers/workflowAction';
import { TaskFlowService } from 'providers/data/services/TaskFlowService';
import notification from 'notifications/notifications';
import useOnboarding from 'views/onboarding/hooks/useOnboarding';
import { OnboardingUtil } from 'views/onboarding/OnboardingUtil';
import { OnboardingStep } from 'views/onboarding/types/OnboardingTypes';
import { setShowOnboardingModal } from 'views/onboarding/reducer/onboardingAction';
import useWorkFlow from 'views/workflows/hooks/useWorkFlow';
import {useSelector} from 'react-redux';

type Props = {
  taskTypeId:string,
  taskTypeName:string
} 

export const NotSupported = ({taskTypeId, taskTypeName}:Props) => {
  const history = useHistory();
  const dispatch = useDispatch()
  const {isBeingOnboarded} = useOnboarding()
  const store = useStore();
  const {isBeingSaved} = useWorkFlow()

  const { errors: invalidWorkflow } = useSelector(
    (state:any) => state?.validWorkflow
  );

  const publishWorkflow = () => {
    dispatch(setLoading(true));
    recordRSEvent("publish_button_click", {
      context: "builder_general",
      workflow_id: taskTypeId,
      workflow_name: taskTypeName,
    });
    dispatch(setShowWorkflowConfigModal(false));
    TaskFlowService.publishWorkflow(taskTypeId)
      .then((res:any) => {
        if (!res) {
          notification("error", res?.message);
        }
        else if (res?.code && res?.code.indexOf("200") < 0) {
          // Error
          if (res?.code === "401") {
            notification("error", res?.message);
          } else {
            notification("error", res?.message);
          }
        } else {
          notification("success", res?.message);
          if (isBeingOnboarded) {
            OnboardingUtil.start({
              step: OnboardingStep.CREATE_TASK,
              dispatch,
              steps: store.getState()?.userOnboarding?.onboarding?.steps || [],
            });
            OnboardingUtil.updateOnboardingDataToBackend(
              OnboardingStep.WORKFLOW,
              dispatch,
              store.getState().userOnboarding?.onboarding?.steps || []
            );
            dispatch(setShowOnboardingModal(true));
            history.push("/home");
          } else {
            // Make the user stay in the same workflow screen
            history.push("/workflows");
          }

          dispatch(
            setPostPublish({
              postedWorkflow: taskTypeName,
              setPostPublish: true,
              postedWorkflowId: taskTypeId,
            })
          );
        }
        dispatch(setLoading(false));
        recordRSEvent("publish_event_success", {
          context: "builder_general",
          workflow_id: taskTypeId,
          workflow_name: taskTypeName,
        });
      })
      .catch((err) => {
        console.error("Error", err);
        recordRSEvent("publish_event_fail", {
          context: "builder_general",
          workflow_id: taskTypeId,
          workflow_name: taskTypeName,
        });
        dispatch(setLoading(false));
      });
  };

  
  const handleTaskClick = () => {
    history.push('/tasks');
  };

  const handleWorkflowClick = () => {
    if(isBeingOnboarded)
      history.push('/home');
    else
      history.push("/workflows")

  };

  return (
    <ZModal
      isSecondary
      isOpen={true}
      title=''
      handleClose={handleTaskClick}
      showCloseIcon={false}
      childComponent={
        <FrameDiv>
          <FrameDiv1>
            <FrameIcon src={ExpandIcon} />
            <SorryOurBuilder>
              <p className='text-center'> Sorry, our builder doesn’t support phones to make changes at the moment </p>
            </SorryOurBuilder>
            <FrameDiv1 className='mt-4'>
            <ViewThisScreenOnYourDesk>
              You can still Publish this template and try the app.
            </ViewThisScreenOnYourDesk>
          </FrameDiv1>
          <ButtonsDiv>
            <ZButton disabled={isBeingSaved || invalidWorkflow} style={{minWidth:"212px"}} variant='contained' className='mt-4' onClick={publishWorkflow}>
                Publish this workflow
            </ZButton>
          </ButtonsDiv>
          <FrameDiv1 className='mt-4'>
            <ViewThisScreenOnYourDesk>
            For best experience, visit the workflow builder on your desktop
            </ViewThisScreenOnYourDesk>
          </FrameDiv1>
          </FrameDiv1>
          <ButtonsDiv>
            <ZButton secondary style={{minWidth:"212px"}} variant='contained' className='mt-4' onClick={handleWorkflowClick}>
                Go Back
            </ZButton>
          </ButtonsDiv>      
          {
            invalidWorkflow && (
              <ViewThisScreenOnYourDesk className='mt-5'>
                  <p className='text-red-500 font-medium'>The workflow has some error. Please fix the errors and try again.</p>
              </ViewThisScreenOnYourDesk>
            )
          }
          
        </FrameDiv>
        
      }
    />
  );
};
