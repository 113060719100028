import * as React from "react";
import IconButton from "@mui/material/IconButton";
import styled from "styled-components";
import { BsStopCircleFill, BsThreeDotsVertical } from "react-icons/bs";
import { useDispatch, useStore } from "react-redux";
import {
  setPossibleHomeNode,
  setHomeNode,
  setContextMenuVisibility,
  setTerminalNode,
  unsetTerminalNode,
  deleteNode,
  setShowUpdateNodeNameModal,
} from "views/workflows/reducers/workflowAction";
import { DetectHomeNode } from "../DetectHomeUtils";
import { IoIosHome } from "react-icons/io";
import { ListItemIcon, ListItemText, Modal } from "@mui/material";
import useWorkFlow from "../hooks/useWorkFlow";
import { BuilderService } from "providers/data/services/BuilderService";
import { WorkflowUtils } from "../WorkflowUtils";
import { NodeTypes } from "./Nodes.types";
import DeletionConfirmationDialog from "utils/DeletionConfirmationDialog.js";
import { BffService } from "providers/data/services/BffService";
import { BiCopy, BiEdit } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { ZIconButton2 } from "views/entities/styles";
import { CopyIcon, EditIcon, HomeIcon, TerminalIcon, TrashIcon } from "assests";

type Props = {
  nodeId: string;
  showSetHomeNode?: boolean;
  showStrip?: boolean;
  showTerminalNodes?: boolean;
  data: any;
};

interface IOption {
  label: string;
  onClick: Function;
  visibility: boolean;
  icon: React.ReactElement;
}

const ITEM_HEIGHT = 48;

const Button = styled(IconButton)`
  background-color: transparent;
  &:hover {
    background-color: none;
  }
`;

const IconWrapper = styled.div`
  padding: 8px;
`;

const IconText = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: #344054;
`;

export default function LongMenu({
  nodeId,
  showStrip = false,
  showSetHomeNode = false,
  showTerminalNodes = false,
  data,
}: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | SVGElement>(null);
  const dispatch = useDispatch();

  const { getHomeNode, getTerminalNodes, taskTypeId } = useWorkFlow();
  const store = useStore();
  const [showDeleteNode, setShowDeleteNode] = React.useState(false);
  const [showEditNode, setShowEditNode] = React.useState(false);

  const handleCopy = async (nodeId: string) => {
    try {
      // make call to Builder service and save node in the redis
      const res: any = await BuilderService.copyNode(taskTypeId, nodeId);
      // add the node to canvas.elements
      WorkflowUtils.addNewNodeWithKey(
        res.data.status,
        NodeTypes.STATE_NODE,
        res.data.statusName,
        dispatch,
        store.getState()?.builder?.workflow?.canvas?.elements
      );
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleDelete = async () => {
    try {
      const res: any = await BuilderService.deleteNode(taskTypeId, nodeId);
      // delete the node from canvas.elements
      dispatch(deleteNode(data));
      setShowDeleteNode(false);
    } catch (error) {
      console.log(error);
    }
  };

  const allTerminalNodes = getTerminalNodes();
  const options: IOption[] = [
    {
      label: "Rename Node",
      onClick: () => {
        dispatch(setShowUpdateNodeNameModal(data));
      },
      visibility: true,
      icon: EditIcon,
    },
    {
      label: "Set as Home Node",
      onClick: () => {
        dispatch(setHomeNode({ nodeId }));
        DetectHomeNode.markAllReachableNode(
          store.getState()?.builder?.workflow?.canvas?.elements,
          dispatch
        );
      },
      visibility:
        !getHomeNode() ||
        (showSetHomeNode &&
          !allTerminalNodes?.includes(nodeId) &&
          nodeId !== getHomeNode()?.id),
      icon: HomeIcon,
    },
    {
      label: allTerminalNodes?.includes(nodeId)
        ? "Unset as terminal node"
        : "Set as Terminal Node",
      onClick: () => {
        if (allTerminalNodes?.includes(nodeId)) {
          dispatch(unsetTerminalNode({ nodeId }));
        } else {
          dispatch(setTerminalNode({ nodeId }));
        }
        DetectHomeNode.markAllReachableNode(
          store.getState()?.builder?.workflow?.canvas?.elements,
          dispatch
        );
      },
      visibility:
        nodeId !== getHomeNode()?.id &&
        (allTerminalNodes?.length === 0 || showTerminalNodes),
      icon: TerminalIcon
    },
    {
      label: "Duplicate Node",
      onClick: () => {
        handleCopy(nodeId);
      },
      visibility: true,
      icon: CopyIcon
    },
    {
      label: "Delete Node",
      onClick: () => {
        setShowDeleteNode(true);
      },
      visibility: true,
      icon: TrashIcon
    },

    // {
    //   label: "Unlink Node",
    //   onClick: () => {},
    //   visibility: true,
    //   icon: <IoIosHome size={"1rem"}/>

    // }
  ];

  const handleClick = (event: React.MouseEvent<SVGElement>) => {
    dispatch(setContextMenuVisibility(true));
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (e: any, callback: Function) => {
    // e.preventDefault();
    callback();
    handleClose();
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {/* <div 
          style={{
              position: "absolute",
              top: "22px",
              right: "18px",
              background: "#d0d1d2",
              padding: "2px",
              borderRadius: "4px"
              
          }} 
          className="node__contextMenu"
      >
        
          <BsThreeDotsVertical size="1.5rem" color='#494949' className="node__contextMenu" onClick={(e) => handleClick(e)}/>
        
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose} 
          style={{
            border: "none"
          }}
        >
          {options.map((option:IOption) => (
            <div>
              {
                (
                  <MenuItem disabled={!option.visibility} className="node__menuItem" key={option.label} onClick={(e) => handleMenuItemClick(e,option.onClick)}>
                    <>
                      <ListItemIcon> {option.icon} </ListItemIcon> 
                      <ListItemText>{option.label}</ListItemText>
                    </>
                  </MenuItem>
                )
              }
              
            </div>
            
            
          ))}
        </Menu>
        <DeletionConfirmationDialog
          id={"deleteNode"}
          deletionTitle={"Delete this Node"}
          deletionText={"All the transition which starts from this node will be deleted. This can't be undone"}
          isOpen={showDeleteNode}
          onConfirmDelete={() => handleDelete()}
          onClose={() => setShowDeleteNode(false)}
        />
      

      </div> */}
      <Menu
        menuClassName="my-menu node__menuItem"
        menuButton={
          <ZIconButton2 style={{
            position:"absolute",
            top:"15px",
            right:"10px"
          }} className="node__menuItem">
            <BsThreeDotsVertical className="node__menuItem" size={"1rem"} />
          </ZIconButton2>
        }
        transition
        direction="right"
        gap={12}
        align="end"
      >
        {options.map((option: IOption) => (
          <div>
            {
              <MenuItem
                disabled={!option.visibility}
                key={option.label}
                onClick={(e) => handleMenuItemClick(e, option.onClick)}
                className={`${option.label === "Delete Node" ? "menuItem-delete" : "menuItem-s"} node__menuItem`}
              >
                <IconWrapper className="node__menuItem">{option.icon}</IconWrapper>
                <IconText className="node__menuItem"> {option.label} </IconText>
              </MenuItem>
            }
          </div>
        ))}
      </Menu>
      <DeletionConfirmationDialog
          id={"deleteNode"}
          deletionTitle={"Delete this Node"}
          deletionText={"All the transition which starts from this node will be deleted. This can't be undone"}
          isOpen={showDeleteNode}
          onConfirmDelete={() => handleDelete()}
          onClose={() => setShowDeleteNode(false)}
        />
    </>
  );
}
