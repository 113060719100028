import { UserService } from "providers/data/services/UserService";
import { Dispatch } from "redux";
import store from "store";
import { updateOnboarding,setShowOnboardingModal, setIsBeingOnboarded } from "./reducer/onboardingAction";
import { OnboardingStep } from "./types/OnboardingTypes";
import jwt_decode from "jwt-decode";

export class OnboardingUtil {
  public static start = ({step=OnboardingStep.OVERVIEW, dispatch,steps}:{step:OnboardingStep, dispatch:Dispatch, steps:any[]}) => {
    let tempSteps = [...steps]
    switch(step){
            // for overview we set the current step to overview
           
            case OnboardingStep.OVERVIEW:
                dispatch(setIsBeingOnboarded(true))
                dispatch(updateOnboarding({currentStepId: OnboardingStep.OVERVIEW}))
                break;

            // for workflow we set the current step to workflow and make an api call to mark the overview step as completed
            case OnboardingStep.WORKFLOW:
              tempSteps.push({
                stepId: OnboardingStep.OVERVIEW,
                startedOn : new Date().toISOString(),
                completedOn : new Date().toISOString(),
                isCompleted : true
              })   
              dispatch(setIsBeingOnboarded(true)) 
              dispatch(updateOnboarding({currentStepId: OnboardingStep.WORKFLOW,steps:tempSteps}))
              break;
            
            case OnboardingStep.CREATE_TASK:
              
              tempSteps.push({
                stepId: OnboardingStep.WORKFLOW,
                startedOn : new Date().toISOString(),
                completedOn : new Date().toISOString(),
                isCompleted : true
              }) 
              dispatch(setIsBeingOnboarded(true))   
              dispatch(updateOnboarding({currentStepId: OnboardingStep.CREATE_TASK,steps:tempSteps}))
              break;
                
            case OnboardingStep.EXECUTE_TASK:
                tempSteps.push({
                  stepId: OnboardingStep.CREATE_TASK,
                  startedOn : new Date().toISOString(),
                  completedOn : new Date().toISOString(),
                  isCompleted : true
                })   
                dispatch(setIsBeingOnboarded(false)) 
                dispatch(updateOnboarding({
                  currentStepId: OnboardingStep.EXECUTE_TASK,
                  steps:tempSteps,
                  isOnboardingDoneForWeb: true,
                  onboardingCompletedOn: new Date().toISOString()
  
                }))
                break;
            case OnboardingStep.COMPLETED:
              tempSteps.push({
                stepId: OnboardingStep.EXECUTE_TASK,
                startedOn : new Date().toISOString(),
                completedOn : new Date().toISOString(),
                isCompleted : true
              })   
              dispatch(setIsBeingOnboarded(false)) 
              dispatch(updateOnboarding({
                currentStepId: OnboardingStep.COMPLETED,
                steps:tempSteps,
                isOnboardingDoneForWeb: true,
                onboardingCompletedOn: new Date().toISOString()

              }))
              break;
            default:
              console.log("uncatched in onboarding util")

        }
  }

  public static stop = (dispatch: Dispatch) => {
    dispatch(setShowOnboardingModal(false))
    dispatch(setIsBeingOnboarded(false))
  }

  public static updateOnboardingDataToBackend = async (stepType:OnboardingStep, dispatch: Dispatch,steps:any[]) => {
    try {
        const at:any = localStorage.getItem('at');
        const decoded:any = jwt_decode(at);
        await UserService.updateUserOnboarding({userId:decoded.userId, steps})
    } catch (error) {
     console.log(error)   
    }
  }
}