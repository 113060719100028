import { Card, CardContent, CardHeader } from '@mui/material';
import React, { FC } from 'react';

interface PlaceHolderProps {
    url: string;
}

const AwfisProjects: FC<PlaceHolderProps> = ({ url }) => {
    url = 'https://app.appsmith.com/app/awfis-project-management/projects-63805e1edbc6e8336d960455?embed=true';
    if (url && url.length > 0) {
        return (
            <Card>
                <div>
                    <iframe
                        title='iframe'
                        src={`${url}`}
                        frameBorder='0'
                        width='100%'
                        height={window.screen.height}
                        allowTransparency
                    ></iframe>
                </div>
            </Card>
        );
    }
    return (
        <Card>
            <CardHeader title='Welcome to Zorp!' />
            <CardContent></CardContent>
        </Card>
    );
}

export default AwfisProjects;