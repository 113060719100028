import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components';

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopM: '1440px',
  laptopL: '1920px',
  desktop: '2560px',
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopM: `(min-width: ${size.laptopM})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

export const WTDialogTitle = styled(DialogTitle)(({ theme }) => ({
  '&.MuiDialogTitle-root.MuiTypography-root': {
    fontSize: 16,
    fontWeight: 500,
    color: '#00000080',
    fontFamily: 'Inter',
    lineHeight: '19px',
    letterSpacing: '0em',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export const WTModal = styled(Dialog)`
  z-index: 1300;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 1px solid #e5e5e5;
  .MuiDialog-container {
    width: 100%;
    height: 100%;
    .MuiPaper-root {
      max-width: 100%;
      height: 100%;
      border-radius: 0;
      overflow: hidden;
      .MuiDialogContent-root {
        padding: 0;
        overflow: hidden;
        .MuiDialogContentText-root {
          height: 100%;
        }
      }
    }
  }
`;

export const ContentLayout = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

export const WTLHSidebarCOM = styled(Box)`
  background-color: #f0f2f3;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
`;

export const RecommendVideo = styled(Box)`
  border: 1px solid #f0f2f3;
  padding: 30px 20px;
  border-radius: 5px;
  img {
    width: 73px;
    height: 58px;
    object-fit: contain;
  }
  @media ${device.mobileS || device.mobileM} {
    margin-top: 1rem;
  }
`;

export const DocContainer = styled(Box)`
  width: 200px !important;
  max-width: 220px !important;
  min-width: 220px !important;
  min-height: 72px !important;
  max-height: 72px !important;
  @media ${device.mobileS || device.mobileM} {
    margin-top: 1rem;
    min-width: 100% !important;
    max-width: 100% !important;
  }
  @media ${device.laptopL} {
    max-width: 23.5rem !important;
    min-width: 23.5rem !important;
    min-height: 4.8rem !important;
    max-height: 4.8rem !important;
  }
  @media ${device.desktop || device.desktopL} {
    width: 32rem !important;
    height: 6.2rem !important;
    min-width: 32rem !important;
    min-height: 6.2rem !important;
    max-width: 32rem !important;
    max-height: 6.2rem !important;
  }
  border: 1px solid #f0f2f3;
  padding: 10px 14px;
  border-radius: 5px;
  img {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }
`;

export const TemplateContainer = styled(Box)`
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), #c2c9d1;
  padding: 15px;
  border: ${(props) => props.className === 'active' && `0.1rem solid ${props.theme.color.primary}}`};
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  min-height: 35rem;
  max-height: 35rem;
  min-width: 27rem;
  max-width: 27rem;
  @media ${device.laptopL} {
    min-width: 35rem;
    max-width: 35rem;
    min-height: 35rem;
    max-height: 35rem;
  }
  @media ${device.desktop || device.desktopL} {
    min-height: 50rem;
    max-height: 50rem;
    min-width: 50rem;
    max-width: 50rem;
  }
  &.active {
    opacity: 0.9;
    background-color: #ffffff;
    &:after {
      height: 100%;
    }
  }
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    background-color: #ffffff;
    opacity: 0.9;
    transition: height 0.5s ease-in-out;
  }
  &:hover {
    &:after {
      height: 100%;
    }
  }
  img {
    width: 60rem;
    height: 14rem;
    @media ${device.laptopL} {
      width: 38rem;
      height: 18rem;
    }
    @media ${device.desktop || device.desktopL} {
      width: 100rem;
      height: 30rem;
    }
  }
`;

export const ZChip = styled(Chip)`
  background-color: ${(props) => props.theme.color.primary} !important;
  padding: 2px 15px !important;
  border-radius: 29px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #fff !important;
`;

export const MainVideoTitle = styled(Typography)`
  color: #00000080;
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
`;

export const MainVideoDesc = styled(Typography)`
  color: #000;
  font-family: Inter !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  @media ${device.desktop || device.desktopL} {
    font-size: 28px !important;
    font-weight: 600 !important;
    line-height: 5rem !important;
  }
  line-height: 18.5px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
`;

export const MainVideoDuration = styled(Typography)`
  color: #00000080;
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  letter-spacing: 0px !important;
  text-align: left !important;
`;

export const SubVideoTitle = styled(Typography)`
  color: #00000080 !important;
  font-family: Inter !important;
  font-size: 10px !important;
  font-weight: 500 !important;
  line-height: 12px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
`;

export const SubVideoDesc = styled(Typography)`
  color: #000 !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 17px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
`;

export const SubVideoDuration = styled(Typography)`
  color: #00000080 !important;
  font-family: Inter !important;
  font-size: 8px !important;
  font-weight: 400 !important;
  line-height: 12px !important;
  @media ${device.desktop || device.desktopL} {
    font-size: 1.01rem !important;
    font-weight: 500 !important;
    line-height: 12px !important;
  }
  letter-spacing: 0em !important;
  text-align: left !important;
  margin-left: 0px !important;
`;

export const TemplateTitle = styled(Typography)`
  font-family: Inter !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 29px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  @media ${device.mobileS || device.mobileM} {
    font-size: 1.5rem !important;
  }
`;

export const TemplateDesc = styled(Typography)`
  font-family: Inter !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  letter-spacing: 0px !important;
  text-align: left !important;
  @media ${device.mobileS || device.mobileM} {
    font-size: 1.3rem !important;
    font-weight: 400 !important;
  }
`;
