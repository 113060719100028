import Tooltip from '@mui/material/Tooltip';
import { useEffect, useState } from 'react';
import { BiPaste } from "react-icons/bi";
import useAction from 'views/actions/useAction';
import { IConfiguration } from "../types/ABType";

type IActionPaste = {
    onClick: () => void;
    label: string;
    context: IConfiguration
}

const ActionPaste = ({ onClick, label, context }: IActionPaste) => {
    const { copiedAction } = useAction();
    const [showPasteButton, setShowPasteButton] = useState(false);
    useEffect(() => {
        if (copiedAction?.actionId && context === 'action') {
            setShowPasteButton(true);
        } else if (copiedAction?.conditionId && context === 'condition') {
            setShowPasteButton(true);
        } else if (copiedAction?.triggerId && context === 'trigger') {
            setShowPasteButton(true);
        } else if (copiedAction?.guardId && context === 'guard') {
            setShowPasteButton(true);
        } else {
            setShowPasteButton(false);
        }
    }, [context, copiedAction])
    return (
        <>  {
            showPasteButton &&
            <Tooltip title={label}>
                <BiPaste color="#606569" className='pointer mr-2' onClick={onClick} />
            </Tooltip>
        }
        </>
    )
}

export default ActionPaste