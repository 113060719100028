export enum ZResources {
    TASK = "/tasks",
    WORKFLOW = "/workflows"
}

export const getCreatePath = (component:ZResources) => {
    switch(component) {
        case ZResources.TASK:
            return "/tasks/create"
        case ZResources.WORKFLOW:
            return "/templates"
        default:
            return ""
    }
}

export const getEmptyScreenMessage = (component:ZResources) => {
    switch(component) {
        case ZResources.TASK:
            return "You haven't created any task yet. Click on the button below to create one!"
        case ZResources.WORKFLOW:
            return "You haven't created any workflow yet. Click on the button below to create one!"
        default:
            return ""
    }
}