/* eslint-disable import/no-anonymous-default-export */
import { getDefaultMiddleware } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import scriptEditorReducer from 'lowcode/state/scriptEditorState';
import scriptEditorOpen from 'lowcode/state/scriptOpenState';
import { adminReducer, adminSaga, USER_LOGOUT } from 'react-admin';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import taskShowReducer from 'render-engine/reducers/show/TaskShowReducer';
import validWorkflowReducer from 'views/reducer/validWorkflowErrorState';
import rolesPageReducer from 'views/roles/reducers/Roles.actions';
import usersPageReducer from 'views/users/reducers/Users.actions';
import teamsPageReducer from 'views/teams/reducers/Teams.actions';
import taskCreationReducer from '../render-engine/reducers/create';
import taskEditReducer from '../render-engine/reducers/edit';
import loadingStateReducer from '../views/reducer/index';
import workflowTemplateReducer from '../views/workflow-templates/reducers/WorkflowTemplate.actions';
import workflowReducer from '../views/workflows/reducers/workflowAction';
import listInfoReducer from '../render-engine/reducers/listInfo';
import userOnboardingReducer from "views/onboarding/reducer/onboardingAction"
import automationReducer from 'views/automationBuilder/reducer/automationReducer';
import AuthActions from 'views/signup/redux/AuthActions';
import MenuSlice from 'views/layouts/reducer/menuSlice';
import AccountSlice from 'views/layouts/reducer/accountSlice';
import Action from 'views/actions/Action';
import previewReducer from "views/preview/reducer"
import entityReducers from 'views/entities/reducers/entityReducers';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import MonitorReducer  from 'views/monitor/reducer/MonitorReducer';

const persistConfig = {
  key: 'zorp-redux-persist',
  storage,
}
export default ({ authProvider, dataProvider, history }) => {
  
  
  const reducer = combineReducers({
    admin: adminReducer,
    router: connectRouter(history),
    builder: workflowReducer,
    taskCreation: taskCreationReducer,
    taskEdit: taskEditReducer,
    loading: loadingStateReducer,
    scriptEditor: scriptEditorReducer,
    validWorkflow: validWorkflowReducer,
    taskShow: taskShowReducer,
    scriptEditorOpen,
    listInfo: listInfoReducer,
    workflowTemplates: workflowTemplateReducer,
    menu: MenuSlice,
    account: AccountSlice,
    usersPage: usersPageReducer,
    rolesPage: rolesPageReducer,
    teamsPage: teamsPageReducer,
    userOnboarding: userOnboardingReducer,
    automation: automationReducer,
    monitor: MonitorReducer,
    auth : AuthActions,
    action:Action,
    preview: previewReducer,
    entity: entityReducers
    // add your own reducers here
  });

  const resettableAppReducer = (state, action) => reducer(action.type !== USER_LOGOUT ? state : undefined, action);
  const persistedReducer = persistReducer(persistConfig, resettableAppReducer)
  const saga = function* rootSaga() {
    yield all([adminSaga(dataProvider, authProvider)].map(fork));
  };

  const sagaMiddleware = createSagaMiddleware();

  const composeEnhancers =
    (['development', 'dev', 'local','prod'].includes(process.env.NODE_ENV) &&
      typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })) ||
    compose;

  const store = createStore(
    persistedReducer,
    {
      /* set your initial state here */
    },
    composeEnhancers(
      applyMiddleware(
        sagaMiddleware,
        routerMiddleware(history),
        ...getDefaultMiddleware()
        // add your own middlewares here
      )
      // add your own enhancers here
    )
  );

  sagaMiddleware.run(saga);
  return store;
};
