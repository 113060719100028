//@ts-nocheck
import TextField from '@mui/material/TextField';
import calendarIcon from "assests/calendar.svg";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { requiredErrorMessage } from 'filter/constants/ErrorMessage';
import { getFormattedDateValue, getEndOfTheDay, getStartOfTheDay } from 'filter/filterUtils';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import FilterErrorMessage from './FilterErrorMessage';
import ReactFilterSelect from './ReactFilterSelect';

export enum FilterComponentType {
    TEXT = 'TEXT',
    STRING = 'STRING',
    NUMBER = 'NUMBER',
    DATE = 'DATE',
    TIME = 'TIME',
    DATETIME = 'DATETIME',
    LIST_OF_TEXT = 'LIST_OF_TEXT',
    DROPDOWN = 'DROPDOWN',
    BOOLEAN = 'BOOLEAN',
    MULTI_DROPDOWN = 'MULTI_DROPDOWN'
}

interface IFilterDynamicComponent {
    type: FilterComponentType;
    disabled: boolean;
    value?: any;
    errors: any;
    onChange: (value: any) => void;
    register: any;
    name: string;
    control: any;
    selectOptions: any[];
    setValue: any;
    filterKey: string;
    index:number;
}

const FilterDynamicComponent = ({ type, disabled, onChange, register, name, control, selectOptions = [], value, errors, setValue, filterKey, index }: IFilterDynamicComponent) => {
    const [defaultData, setDefaultDate] = useState<Dayjs | null | any>(dayjs(new Date()));

    const DateHandleChange = (newValue: any) => {
        const formattedDate = getFormattedDateValue(FilterComponentType.DATE, newValue, filterKey)
        onChange(formattedDate);
        setDefaultDate(newValue);
    };
    useEffect(() => {
        // when there is no value and the 
        if (!value && type == FilterComponentType.DATE) {
            const formattedDate = getFormattedDateValue(FilterComponentType.DATE, '', filterKey);
            onChange(formattedDate);
        }
    }, [])

    return (
        <>
            {
                (type == FilterComponentType.DROPDOWN || type == FilterComponentType.BOOLEAN || type == FilterComponentType.MULTI_DROPDOWN) &&
                <ReactFilterSelect
                    setValue={setValue}
                    errors={errors}
                    isMulti={type == FilterComponentType.MULTI_DROPDOWN ? true : false}
                    value={value}
                    isDisabled={false}
                    isLoading={false}
                    control={control}
                    name={name}
                    options={selectOptions}
                    onChange={(selectedValue) => {
                        onChange(selectedValue)
                    }} />
            }

            {
                type == FilterComponentType.NUMBER &&
                <TextField
                style={{
                    width: '95%',
                    height: '32px',
                    minHeight:'32px',
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: 'rgba(0, 0, 0, 0.8)'
                }}
                    type="number"
                    disabled={disabled}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    {...register(name, { required: requiredErrorMessage })}
                />
            }

            {
                type == FilterComponentType.DATE &&
                <Controller
                    control={control}
                    name={name}
                    render={({ field: { onChange, value } }) => (
                        <LocalizationProvider
                            style={{
                                width: '95%',
                                minHeight: '32px',
                                height: '32px'
                            }}
                            //@ts-ignore
                            dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                            components={{
                                OpenPickerIcon: () => <img src={calendarIcon} style={{height:'12px'}} className='cursor-pointer'/>
                              }}
                            PopperProps={
                                {
                                    sx:{
                                        "& .MuiPickersDay-root": {
                                            "&.Mui-selected": {
                                                color:'#ffffff !important'
                                            },
                                          },
                                          "& .css-3eghsz-PrivatePickersYear-button":{
                                                "&.Mui-selected":{
                                                color:'#ffffff !important'
                                                }
                                          }
                                      }
                                }
                            }
                                inputFormat="DD/MM/YYYY"
                                value={dayjs(value)}
                                onChange={DateHandleChange}
                                renderInput={(params) => <TextField
                                    {...params}
                                    style={{
                                        width: '95%',
                                        minHeight: '32px',
                                        height: '32px'
                                    }}
                                />
                                }
                            />
                        </LocalizationProvider>
                    )}
                />
            }

            {
                type == FilterComponentType.TIME &&
                <LocalizationProvider
                    style={{
                        width: '95%',
                        minHeight: '32px',
                        height: '32px'
                    }}
                    //@ts-ignore
                    dateAdapter={AdapterDayjs}>
                    <TimePicker
                        value={dayjs(defaultData)}
                        onChange={DateHandleChange}
                        renderInput={(params) => <TextField
                            style={{ width: '95%' }}
                            {...register(name, { required: requiredErrorMessage })}
                            {...params} />}
                    />
                </LocalizationProvider>
            }

            {
                type == FilterComponentType.DATETIME &&
                <LocalizationProvider
                    style={{
                        width: '95%',
                        minHeight: '32px',
                        height: '32px'
                    }}
                    //@ts-ignore
                    dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        value={dayjs(defaultData)}
                        onChange={DateHandleChange}
                        renderInput={(params) => <TextField
                            style={{ width: '95%' }}
                            {...register(name, { required: requiredErrorMessage })}
                            {...params} />}
                    />
                </LocalizationProvider>
            }

            {
                (type == FilterComponentType.TEXT || type == FilterComponentType.STRING) &&
                <TextField
                    disabled={disabled}
                    style={{
                        width: '95%',
                        height: '32px',
                        minHeight: '32px',
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: 'rgba(0, 0, 0, 0.8)'
                    }}
                    variant="outlined"
                    {...register(name, { required: requiredErrorMessage })}
                />
            }

            {
                type == FilterComponentType.LIST_OF_TEXT &&
                <TextField
                    style={{
                        width: '95%',
                        height: '32px',
                        minHeight: '32px',
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: 'rgba(0, 0, 0, 0.8)'
                    }}
                    disabled={disabled}
                    sx={{
                        "& .MuiFormHelperText-root":{
                            marginLeft:'2px'
                        }
                    }}
                    helperText={errors?.filterArray?.[index]?.filterValue?.message ?'' : 'Use comma separated values'}
                    variant="outlined"
                    {...register(name)}
                />
            }
            {
                (type != FilterComponentType.DROPDOWN && type != FilterComponentType.BOOLEAN && type != FilterComponentType.MULTI_DROPDOWN) &&
                <FilterErrorMessage errors={errors} name={name} />
            }
        </>
    )
}

export default FilterDynamicComponent