import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import iconNew from "../../../../maps/task_new.png";
import L, { Tooltip } from "leaflet";

let taskNewIcon = L.icon({
    iconUrl: iconNew,
    iconSize: [40, 50],
    // shadowUrl: iconShadow
});

export const TaskMap = ({ mapCenter, locations }) => {
    const markers = [];

    locations?.filter(loc => loc).map((loc) => {
        markers.push(<Marker
            position={[
                loc?.location?.latitude,
                loc?.location?.longitude,
            ]}
            icon={taskNewIcon}
            key={1000*Math.random()}
        >
            <Popup>{loc?.location?.address}</Popup>
        </Marker>);
    })


    return (
        <div>
            {mapCenter && <MapContainer
                id="task-map"
                center={[
                    mapCenter[0] || 0,
                    mapCenter[1] || 0,
                ]}
                zoom={14}
                scrollWheelZoom={false}
            >
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {markers}

            </MapContainer>}
        </div>
    );
}