import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { setShowDataFieldModal } from "views/workflows/reducers/workflowAction";
import { IoIosCube } from "react-icons/io";
import { InfoTooltip } from "./Tootltips/InfoTooltip";
import { Typography } from "@mui/material";
import { DataFieldContent } from "./TooltipContent/TooltipContent";
import { DataModelIcon } from "assests";
import { ZButton } from "views/commonComponents/commonComponents.styles";

const Button = styled.button`
  background-color: #f2f2f2;
  border: 1px solid #c2c9d1;
  color: #616569;
  padding: 10px 16px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  margin-right: 8px;
`;

const ConfigureDataFieldButton = () => {
  const dispatch = useDispatch();
  return (
    // <Button >
    //   <span style={{ display: 'inherit' }}>
    //     <InfoTooltip
    //       title={
    //         DataFieldContent
    //       }
    //     >
    //       <div style={{ display: 'inline' }}>
    //         {DataModelIcon}
    //       </div>
    //     </InfoTooltip>
    //   </span>
    //   <span style={{ marginLeft: '10px', marginTop: '-3px' }}> Data Model</span>
    // </Button>
    <ZButton
      secondary
      onClick={() => dispatch(setShowDataFieldModal(true))}
      startIcon={DataModelIcon}
      variant="contained"
    >
      {" "}
      Data Model{" "}
    </ZButton>
  );
};

export default ConfigureDataFieldButton;
