import { Dispatch } from "react";
import { IAutomation } from "views/automationBuilder/types/ABType";
import {
  getEntityMetadata,
  setColumnActionAutomation,
  setEntityAutomations,
} from "../reducers/entityReducers";
import { EntityService } from "providers/data/services/EntityService";
import { call } from "redux-saga/effects";
import notification from "notifications/notifications";

export const saveHandler = ({
  allAutomations,
  dispatch,
  store,
  callback,
}: {
  allAutomations: IAutomation[];
  dispatch: Dispatch<any>;
  store?: any;
  callback: () => void;
}) => {
  const selectedEntity = store?.getState()?.entity?.selectedEntity;
  const currEntityMetadata = {
    ...store?.getState()?.entity?.selectedEntityMetadata?.tableMetadata,
  };
  const payload = {
    ...currEntityMetadata?.automations,
    systemAutomation: {
      ...currEntityMetadata?.automations?.systemAutomation,
      uiAutomations: allAutomations,
    },
  };
  dispatch(setEntityAutomations(allAutomations));

  const automations: any = {
    ...payload,
  };

  try {
    EntityService.updateEntityAutomation(selectedEntity.tableType, automations)
      .then((response) => {
        console.log(response);
        callback();
        dispatch(getEntityMetadata(selectedEntity.tableType));
      })
      .catch((error) => {
        console.log(error);
        notification("error", error?.response?.data?.message || "Something went wrong")
        callback();
        dispatch(getEntityMetadata(selectedEntity.tableType));
      });
  } catch (error) {}
 
};

export const saveHandlerForButton = async ({
  allAutomations,
  dispatch,
  store,
  callback,
}: {
  allAutomations: IAutomation[];
  dispatch: Dispatch<any>;
  store?: any;
  callback: () => void;
}) => {
  const selectedActionColumn = store?.getState()?.entity?.selectedActionColumn;
  const selectedEntity = store?.getState()?.entity?.selectedEntity;
  // console.log(allAutomations)
  const currEntityMetadata = {
    ...store?.getState()?.entity?.selectedEntityMetadata?.tableMetadata,
  };
  const payload = {
    ...currEntityMetadata?.automations,
    actions: {
      ...currEntityMetadata?.automations?.actions,
      [selectedActionColumn?.name]: {
        ...currEntityMetadata?.automations?.actions?.[
          selectedActionColumn?.name
        ],
        uiAutomations: allAutomations,
      },
    },
  };

  try {
    EntityService.updateEntityAutomation(
      selectedEntity.tableType,
      payload
    ).then((response) => {
        console.log(response);
        dispatch(
            setColumnActionAutomation({
              columnName: selectedActionColumn?.name,
              automations: allAutomations,
            })
          );
        
          dispatch(getEntityMetadata(selectedEntity.tableType));
        callback();
    })
    
  } catch (error) {
    console.log(error);
    callback();
  }

  
};
