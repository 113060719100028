import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import { AccountService } from "providers/data/services/AccountService";
import { extractCompanyName, getAccountDetails, isWhitelabelledUrl } from "utils/CommonUtils";

const initialState = {
  account: {},
  whitelabelling: {
    isWhitelabelled: false,
    companyName : "app"
  },
};

const fetchAccountInfo = async () => {
  try {
    const accountInfo = await getAccountDetails()
    return accountInfo;
    
  } catch (error) {
    console.log("error", error);
    return {};
  }
};

export const initAccount = createAsyncThunk("account/init", async (taskTypeId) => {
  try {
    const res = await fetchAccountInfo();
    return res;
  } catch (error) {
    return [];
  }
});

export const AccountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    
  },
  extraReducers: (menu) => {
    menu
      .addCase(initAccount.pending, (state) => {
        if (state.account) {
          state.account = {};
        }
      })
      .addCase(initAccount.fulfilled, (state, action) => {
        state.account = action.payload;
        const companyName = extractCompanyName()
        const isWhitelabelled = isWhitelabelledUrl()
        if(isWhitelabelled){
          state.whitelabelling.isWhitelabelled = true;
          state.whitelabelling.companyName = companyName;
        }
      });
  },
});

export const { } = AccountSlice.actions;

export default AccountSlice.reducer;
