import React, { useState } from 'react'
import styled from 'styled-components'

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1010;
`;

const ModalContainer = styled.div`
    margin-top: 1rem;
    width: 650px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    border: 1px solid #C2C9D1;
    z-index: 1011;
`

const Heading = styled.h1`
    color: var(--Gray-900, #101828);
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
`;

const Label = styled.label`
    color: var(--Gray-600, #475467);
    font-family: Inter;
    font-size: 14px;
    line-height: 20px;
`;

const ActionButtonWrapper = styled.div`
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
`

const Button = styled.button <{ background?: string; color?: string; borderColor?: string; }>`
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.background ? props.background : props.theme.color.primary)};
  color: ${(props) => (props.color ? props.color : '#fff')};
  border: 1px solid ${(props) => (props.borderColor ? props.borderColor : props.theme.color.primary)};
  border-radius: 4px;
  cursor: pointer;
  padding: 8px 14px;
`;

const SaveButton = styled.button<{ $active?: boolean; }>`
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.$active ? props.theme.color.primary : 'grey')};
  border: 1px solid ${(props) => (props.$active ? props.theme.color.primary : 'grey')};
  color: ${({ $active }) => ($active ? '#fff' : '#fff')};
  border-radius: 4px;
  cursor: ${({ $active }) => ($active ? 'pointer' : 'not-allowed')};
  padding: 8px 14px;
  &:disabled {
    opacity: 0.5;
  }
`

const Input = styled.input`
    display: flex;
    padding: 5px 8px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    outline: none;
    border-radius: 4px;
    border: 1px solid var(--Gray-300, #D0D5DD);
    background: var(--Base-White, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    font-size: 14px;
`

const Main = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const Error = styled.span`
    color: #D92D20;
    font-size: 11px;
`

type props = {
    onClose: () => void;
    heading: string;
    label: string;
    isUnique?: boolean;
    inputText: string;
    placeholder: string;
    setIsUnique?: (isUnique: boolean) => void;
    isActive: boolean;
    setIsActive: (isActive: boolean) => void;
    setInputText: (value: string) => void;
    onSave: () => void;
}

function ModalComponent({ onClose, setIsUnique, isActive, setIsActive, isUnique, onSave, heading, label, inputText, setInputText, placeholder }: props) {
    const [isEmpty, setIsEmpty] = useState(false)
    return (
        <ModalOverlay onClick={onClose}>
            <ModalContainer onClick= {(e) => e.stopPropagation()}>
                <Main>
                    <Heading>{heading}</Heading>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <Label htmlFor="label">{label}</Label>
                        <Input
                            type="text"
                            id="label"
                            value={inputText}
                            onChange={(e) => {
                                if (e.target.value.trim() === '') {
                                    setIsEmpty(true);
                                    if (setIsUnique) setIsUnique(true);
                                }
                                else {
                                    setIsEmpty(false);
                                }
                                setInputText(e.target.value);
                            }}
                            placeholder={placeholder} />
                        {isEmpty && <Error>*Please enter permission name</Error>}
                        {!isUnique && <Error>*Permission name already exists</Error>}
                    </div>
                </Main>
                <ActionButtonWrapper>
                    <Button color='#344054' background='#fff' borderColor='#344054' onClick={onClose}> Cancel </Button>
                    <SaveButton $active={isActive} onClick={() => {
                        if (inputText.trim() === '') {
                            setIsEmpty(true);
                            if (setIsUnique) setIsUnique(true);
                            return;
                        }
                        else {
                            setIsActive(false);
                            onSave();
                        }
                    }}>
                        Save
                    </SaveButton>
                </ActionButtonWrapper>
            </ModalContainer>
        </ModalOverlay>)
}

export default ModalComponent
