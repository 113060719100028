import Lottie from "lottie-react";
import styled from "styled-components";
import TEemplateNotFound from "./template_animation.json";

const RedDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TopicText = styled.p`
  color: #475467;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  margin-top: 48px;
  margin-bottom: 8px;
  font-weight: 500;
`;

const ContentText = styled.p`
  color: #667085;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-left: 35px;
  margin-right: 35px;
`;

const Image = styled.div`
  height: inherit;
  width: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;

  div > svg {
    height: 50vh !important;
    width: 90% !important;
  }
`;

interface INoTemplates {
  accountEmail: string;
}

const NoTemplates = ({accountEmail}:INoTemplates) => {


  return (
    <RedDiv>
      <TopicText>BlueBee needs a second!</TopicText>
      <ContentText>
        <span style={{ marginBottom: "30px" }}>
        BlueBee is analyzing your data and designing templates that best suit you. 
        <br />
Once ready, BlueBee will drop an email to your inbox.
        </span>
        <br />
        {/* <span style={{ marginTop: "10px" }}>
          we will notify you at{" "}
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            {" "}
            {accountEmail}{" "}
          </span>{" "}
          once the template is generated.
        </span> */}

      </ContentText>
      <Image>
        <Lottie loop={true} autoplay={true} animationData={TEemplateNotFound} />
      </Image>
    </RedDiv>
  );
};

export default NoTemplates;
