import React from 'react';
import { ZDropdown } from './commonComponents.styles';
import { Controller } from 'react-hook-form';
import { HookFieldPropType } from './interfaces/hookComponents.types';

const ZSelect = ({ control, name, options, defaultValue, styles={}, components={}, required }: HookFieldPropType) => {
  return (
    <Controller
      render={({ field }) => (
        <>
        <ZDropdown
          styles={styles}
          {...field}
          className='react-select'
          classNamePrefix='react-select'
          placeholder=''
          options={options}
          components={components}
          // onChange={(prop:any) => {
          //   field.onChange(prop.value);
          // }}
          // value={field?.value?.value}
          // defaultValue={defaultValue}
          
        />
        </>
      )}
      name={name}
      control={control}
      rules={required ? { required: "This field is required" } : {}}
    />
  );
};

export default ZSelect;
