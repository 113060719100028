import { useSelector } from "react-redux";
import { IDatasourceListInterface, IShopifyTemplate } from "../ShopifyMonitor";
import { IConnectionMetadata, IDatasourceModalContext, IMonitorInterfaceContext, ISelectedMonitor, IShopifySyncData, setMonitorInterfaceContext, setSelectedMonitor, setShowInterfaceUI } from "./MonitorReducer";
import { useDispatch } from "react-redux";

const useMonitor = () => {
    const dispatch = useDispatch();

     const clearMonitorState = () => {
        dispatch(setMonitorInterfaceContext(undefined));
        dispatch(setSelectedMonitor(undefined));
        dispatch(setShowInterfaceUI(false));
    }

    const selectedDatasource :IDatasourceListInterface= useSelector((state: any) => state.monitor.selectedDatasource);
    const confirmMonitorModalData : IShopifyTemplate = useSelector((state: any) => state.monitor.confirmMonitorModalData);
    const isConfirmMonitorModalOpen : boolean = useSelector((state: any) => state.monitor.isConfirmMonitorModalOpen);
    const datasourceModalContext:IDatasourceModalContext | undefined = useSelector((state: any) => state.monitor.datasourceModalContext);
    const isTemplateGeneratingFromPrompt : boolean = useSelector((state: any) => state.monitor.isTemplateGeneratingFromPrompt);
    const onEditClassicBuilderTemplateData:IShopifyTemplate | undefined  = useSelector((state: any) => state.monitor.onEditClassicBuilderTemplateData);
    const monitorCreateFromTemplatePayload:IShopifyTemplate | undefined  = useSelector((state: any) => state.monitor.monitorCreateFromTemplatePayload);
    const shopifySyncData :IShopifySyncData | undefined = useSelector((state: any) => state.monitor.shopifySyncData);
    const datasourceListData :IConnectionMetadata[] = useSelector((state: any) => state.monitor.datasourceListData);
    const monitorInterfaceContext: IMonitorInterfaceContext = useSelector((state: any) => state.monitor.monitorInterfaceContext);
    const selectedMonitor:ISelectedMonitor = useSelector((state: any) => state.monitor.selectedMonitor);
    const showInterfaceUI:boolean = useSelector((state: any) => state.monitor.showInterfaceUI);
    return {
        selectedDatasource,
        confirmMonitorModalData,
        isConfirmMonitorModalOpen,
        isTemplateGeneratingFromPrompt,
        onEditClassicBuilderTemplateData,
        monitorCreateFromTemplatePayload,
        datasourceModalContext,
        shopifySyncData,
        datasourceListData,
        monitorInterfaceContext,
        selectedMonitor,
        showInterfaceUI,
        clearMonitorState
    }
}

export default useMonitor;