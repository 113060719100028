import { Box, Modal } from "@mui/material";
import Ajv from "ajv";
import FilterModalClose from "assests/FilterModalClose.svg";
import { JsonEditor as Editor } from "jsoneditor-react";
import _, { cloneDeep } from "lodash";
import { useJSEditor } from 'lowcode/hooks/useJSEditor';
import notification from "notifications/notifications";
import { useEffect, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import styled from "styled-components";
import { getRunTimeVariableData, recordRSEvent } from 'utils/CommonUtils';
import { getValueFromJson } from "utils/Utils";
import { updateMockDataValue } from "views/automationBuilder/utils/util";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import useWorkFlow from "views/workflows/hooks/useWorkFlow";

const Header = styled.p`
color: #101828;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
margin-bottom: 8px;
`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  height: "auto",
  overflow: "auto",
  borderRadius:"4px",
  Padding:"0px",
  Margin:"0px",
  padding:"16px",
  margin:"16px",
}

const ajv = new Ajv();

interface IMockDataEditor {
  isOpenPath:string;
  onClose:() => void;
}

const MockDataEditor = ({isOpenPath, onClose}:IMockDataEditor) => {
  const dispatch = useDispatch();
  const { mockDataContext } = useJSEditor();
  const { mockData } = useSelector((state) => state.scriptEditor);
  const store = useStore();
  const {taskTypeId} = useWorkFlow()
  const [updatedMockData, setUpdatedMockData] = useState();
  
  
  useEffect(()=>{
    if(mockData && mockDataContext){
      if (isOpenPath == 'wholeData') {
        const filteredMockData = getRunTimeVariableData(mockDataContext, mockData);
        setUpdatedMockData(filteredMockData);
      } else {
        const mockDataValue:any = getValueFromJson(mockData, isOpenPath);
        const mockDataValueArray = isOpenPath.split('.');
        const lastWord = mockDataValueArray[mockDataValueArray.length - 1];
        const finalValue:any = {[lastWord]:mockDataValue?.value};
        
        setUpdatedMockData(finalValue);
      }
    }
  },[mockData, mockDataContext])


  const handleClose = () => onClose();


  // Here "e" would not directly change the test data. So we could use mockData to
  // compare and check validity
  const handleChange = (e) => {
    setUpdatedMockData(e);
  };

  const onSubmit = async () => {
    try {
      let mockDataPayload: any = cloneDeep(updatedMockData);
      if (isOpenPath == "wholeData") {
        const mockDataKeyArray = Object.keys(mockData);
        const variableTreeKeyData = Object.keys(mockDataContext);

        const exlcudedMockData: string[] = [];

        // take the list of Test data variable not in the ui
        mockDataKeyArray.forEach((mockVariable) => {
          if (
            !variableTreeKeyData.some((element) =>
              element.includes(mockVariable)
            )
          ) {
            exlcudedMockData.push(mockVariable);
          }
        });

        // push the above value, so that will be updated
        exlcudedMockData.forEach((excludedMockDataValue) => {
          mockDataPayload[excludedMockDataValue] = mockData[excludedMockDataValue];
        });
      } else {
        const mockDataKeyArray = Object.keys(mockData);
        const variableTreeKeyData = Object.keys(mockDataContext);

        const exlcudedMockData: string[] = [];

        // take the list of Test data variable not in the ui
        mockDataKeyArray.forEach((mockVariable) => {
          if (
            !variableTreeKeyData.some((element) =>
              element.includes(mockVariable)
            )
          ) {
            exlcudedMockData.push(mockVariable);
          }
        });

        // push the above value, so that will be updated
        exlcudedMockData.forEach((excludedMockDataValue) => {
          mockDataPayload[excludedMockDataValue] = mockData[excludedMockDataValue];
        });

        const mockDataValueArray = isOpenPath.split(".");
        const lastWord = mockDataValueArray[mockDataValueArray.length - 1];
        const mockDataupdatedvalue = mockDataPayload[lastWord];

        let copiedWholeMockdata = cloneDeep(mockData);
        _.set(copiedWholeMockdata, isOpenPath, mockDataupdatedvalue);
        mockDataPayload = cloneDeep(copiedWholeMockdata);
      }

      updateMockDataValue(store, dispatch, mockDataPayload);
      notification("success", "successfully updated test data variables");

      recordRSEvent("update_test_data", {
        context: "Test_Data",
        taskTypeId: taskTypeId,
      });

      handleClose();
    } catch (error) {
      console.error(error);
      notification("error", "Error in updating Test data");
    }
  };

  return (
    <Modal
      open={!!isOpenPath}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>

        <div style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}>
          <Header>Update Test Data</Header>
          <div>
            <img
              src={FilterModalClose}
              className="cursor-pointer"
              onClick={handleClose}
            />
          </div>
        </div>

        <Editor
          ajv={ajv}
          value={updatedMockData}
          mode='tree'
          onChange={handleChange}
          allowedModes={["code", "tree"]}
        />
        <div style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "16px"
        }}>
          <ZButton type="submit" variant="contained"
            onClick={() => onSubmit()}>
            {" "}
            Submit{" "}
          </ZButton>
        </div>
      </Box>
    </Modal>
  );
};

export default MockDataEditor;