import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import DOMPurify from 'dompurify';
import { recordEvent } from "config/Rudderstack";
import { getEnv } from "utils/CommonUtils";
import jwt_decode from 'jwt-decode';

const Container = styled.div`
  background: var(--Base-White, #FFF);
  border: 1px solid var(--Gray-200, #EAECF0);
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
  padding: 16px;
  margin-bottom: 20px;
  transition: transform 0.3s ease-in-out;
  max-height: 100%; // Make sure the component doesn't exceed the parent's height
  overflow: auto; // Enables scrolling within the container if content exceeds its max-height
  &:hover {
    transform: translateY(-5px);
  }
`;

const Header = styled.header`
  padding: 4px;
  text-align: left;
`;

const Title = styled.h2`
  margin: 0;
  color: #333;
  font-size: 14px;
  font-weight: 500;
`;

const SubTitle = styled.p`
  margin: 0;
  color: #666;
  font-size: 12px;
`;

const FAQList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  overflow-y: auto; // This will add a scrollbar when the content exceeds the max-height
`;

const FAQItem = styled.li`
  padding: 8px 4px;
`;

const Question = styled.div`
  color: #3054b9;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
`;

const Answer = styled.div`
  max-height: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  padding-top: ${({ isOpen }) => (isOpen ? "5px" : "0")};
  color: var(--Primary-700, #101828);
  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
`;

const Toggle = styled.span`
  float: right;
  color: var(--Gray-200, #667085);
  cursor: pointer;
  position: relative;
  top: -18px; /* Adjust the value as needed to move the icon up */
`;

interface FAQ {
  question: string;
  answer: string;
}

interface FAQProps {
  title: string;
  subTitle: string;
  faqs: FAQ[];
}

const FAQComponent: React.FC<FAQProps> = ({ title, subTitle, faqs }) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [containerHeight, setContainerHeight] = useState<number | undefined>();
  const faqRef = useRef<HTMLUListElement>(null);

  let jwtDecoded: any = jwt_decode(localStorage.getItem("at"));

  useEffect(() => {
    if (faqRef.current) {
      setContainerHeight(faqRef.current.clientHeight);
    }
  }, []);

  const toggleFAQ = (index: number, event: any) => {    
    recordEvent("FAQ Question Open", {
      env : getEnv(),
      user: `${jwtDecoded?.name} (${jwtDecoded?.userId})`,
      accountId: jwtDecoded?.accountId,
      timestamp: new Date().toISOString(),
      question: faqs[index].question,
    });
    
    setOpenIndex(openIndex === index ? null : index);
  };

  const stopPropagation = (event: any) => {
    event.stopPropagation();
  };

  return (
    <Container>
      <Header>
        <Title>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>
      </Header>
      <FAQList onMouseDown={stopPropagation} onTouchStart={stopPropagation} ref={faqRef} style={{ maxHeight: containerHeight }}>
        {faqs.map((faq, index) => (
          <FAQItem key={index} onClick={(event) => toggleFAQ(index, event)}>
            <Question>{faq.question}</Question>
            <Toggle>{openIndex === index ? "-" : "+"}</Toggle>
            <Answer isOpen={openIndex === index}><div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faq.answer) }}/></Answer>
          </FAQItem>
        ))}
      </FAQList>
    </Container>
  );
};

export default FAQComponent;