import { Control, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import ZSwitch from "views/commonComponents/ZSwitch";
import useWorkflow from "views/workflows/hooks/useWorkFlow";
import { setSelectedAction } from "views/workflows/reducers/workflowAction";


type Props = {
  control?: Control<any>;
  closeOnClick?: () => void;
};

const IsActive = ({ control, closeOnClick }: Props) => {
  const dispatch = useDispatch();
  const { selectedAction } = useWorkflow();
  return (
    <>
      <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', gap: '10px' }}>

        {
          control &&
          <Controller
            name="isActive"
            control={control}
            render={() => (
              <ZSwitch
                size="small"
                checked={selectedAction?.isActive ?? true}
                onChange={(e: any) => {
                  dispatch(
                    setSelectedAction({
                      ...selectedAction,
                      hasChanged: true,
                      isActive: e.target.checked,
                    })
                  );
                }}
                inputProps={{ "aria-label": "controlled" }}
                label="Enabled"
              />
            )}
          />
        }

        <ZButton
          type="button"
          secondary
          variant="contained"
          onClick={closeOnClick}
          className="mr-2"
        >
          {" "}
          Close{" "}
        </ZButton>
        <ZButton type="submit" variant="contained">
          {" "}
          Save{" "}
        </ZButton>
      </div>
    </>
  );
};

export default IsActive;
