import React from "react";
import useWorkFlow from "../hooks/useWorkFlow";
import BaseButton from "./BaseButton";
import SelectNodeDropdown from "./SelectNodeDropdown";
import {useDispatch} from "react-redux"
import { setShowNodeDropdown } from "../reducers/workflowAction";
import { ClickAwayListener } from "@mui/material";


const SelectNodeButton = ({addNodeOnClick}) => {
  const {showNodeDropdown} = useWorkFlow()
  const dispatch = useDispatch()
  const drop = React.useRef(null);

  function handleClick(e) {
    if (!e.target.closest(`.${drop.current.className}`) && showNodeDropdown) {
      dispatch(setShowNodeDropdown(false))
    }
  }

  React.useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
  
  return (
    <ClickAwayListener onClickAway={()=>{

    }}>
    <div
      className="dropdown"
      ref={drop}
      style={{
        position: "relative",
        margin: "10px",
        width: "auto",
        display: "inline-block",
      }}
    >
      <BaseButton onClick={() => dispatch(setShowNodeDropdown(!showNodeDropdown))} />
      {showNodeDropdown && 
      <SelectNodeDropdown addNodeOnClick={addNodeOnClick}/> 
    }
    </div>
    </ClickAwayListener>
  ); 
};

export default SelectNodeButton;
