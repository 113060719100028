import { Modal as ModalUnstyled } from '@mui/base';
import styled from "styled-components";
import CircularProgress from '@mui/material/CircularProgress';
import { TabList as tabL } from '@mui/lab';
import {
  Box,
  Button as bt,
  ButtonGroup as btGp1,
  ButtonGroup as btGp2,
  FormLabel as lbl,
  Switch as sw,
  TextareaAutosize,
  TextField,
  Typography as message,
  Typography,
} from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import ReactSelect from 'react-select';

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopM: '1440px',
  laptopL: '1920px',
  desktop: '2560px',
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopM: `(min-width: ${size.laptopM})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

export const ZLabel = styled(lbl)`
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #000 !important;
  text-transform: capitalize !important;
  padding: 4px 0px !important;
  &:disabled {
    background-color: #000;
  }
`;

export const SubTitle = styled(Typography)`
  font-family: Inter !important;
  font-size: 12px !important;
  font-weight: ${(props: { extra?: boolean }) => (props.extra ? 600 : 400)};
  line-height: 16px !important;
  letter-spacing: 0px !important;
  text-align: left !important;
  color: #00000080 !important;
`;

export const ExpressionWrapper = styled.div`
margin-bottom: 12px;
margin-top: 12px;
cursor: pointer;
background-color: #F2F2F2;
border: 2px solid #e1e4e8;
border-radius: 0.4rem;
display: flex;
font-weight: 600;
font-size: 1rem;
flex-direction: column;
justify-content: center;
min-height: 3rem;
box-shadow: none;
padding: 0.5rem 1.6rem;
text-transform: capitalize;
color: #616569;
&:hover,
&:focus {
  background-color:#E0E4E8;
  // border-color:#F2F2F2;
}
`

export const ZButton = styled(bt)`
  &.MuiButton-contained {̦
    padding: 1rem 1.6rem;
    font-size: ${(props: { secondary?: boolean; tertiary?: boolean; danger?: boolean, fontSize?: number }) => props?.fontSize ? `${props.fontSize}px` : "1rem"};
    line-height: 1.8rem;
    font-weight: 500;
    text-transform: capitalize;
    border-radius: 4px;
    border: 1px solid;
    box-shadow: none !important;
    max-height: 4rem;
    min-width: auto;
    justify-content: center;
    background-color: ${(props: { special?: boolean; secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props.special ? "" : props.secondary ? '#FFFFFF' : props.tertiary ? 'transparent' : props.danger ? '#F03738' : props.theme.color.primary;
  }};
    background: ${(props: { special?: boolean; secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props.special ? "linear-gradient(45deg, #6941C6 0%, #7F56D9 100%);" : props.secondary ? '' : props.tertiary ? '' : props.danger ? '' : '';
  }};
    border-color: ${(props: { special?: boolean; secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props?.special ? "" : props.secondary ? '#D0D5DD' : props.tertiary ? 'transparent' : props.danger ? '#F03738' : props.theme.color.primary;
  }};
    color: ${(props: { secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props.secondary ? '#344054;' : props.tertiary ? props.theme.color.primary : props.danger ? '#fff' : '#fff';
  }};
    .MuiSvgIcon-root {
      font-size: 11px !important;
    }
    &:hover,
    &:focus {
      background-color: ${(props: { secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props.secondary ? '#F9FAFA' : props.tertiary ? '#F5F7FF' : props.danger ? '#B4292A' : props.theme.color.secondary;
  }};
      border-color: ${(props: { secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props.secondary ? '#92979D' : props.tertiary ? 'transparent' : props.danger ? '#B4292A' : props.theme.color.secondary;
  }};
    }
    &:active {
      background-color: ${(props: { secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props.secondary ? '#F0F2F3' : props.tertiary ? '#ECF0FF' : props.danger ? '#781C1C' : props.theme.color.secondary;
  }};
      border-color: ${(props: { secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props.secondary ? '#92979D' : props.tertiary ? 'transparent' : props.danger ? '#781C1C' : props.theme.color.secondary;
  }};
    }
    &.Mui-disabled {
      background-color: ${(props: { secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props.secondary ? '#fff' : props.tertiary ? 'transparent' : props.danger ? '#FDEBEB' : '#F0F2F3';
  }};
      border-color: ${(props: { secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props.secondary ? '#E1E4E8' : props.tertiary ? 'transparent' : props.danger ? '#FDEBEB' : '#F0F2F3';
  }};
      color: ${(props: { secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props.secondary ? '#C2C9D1' : props.tertiary ? '#C2C9D1' : props.danger ? '#F79B9B' : '#92979D';
  }};
    }
    &.MuiButton-sizeSmall {
      font-size: 12px;
      font-weight: 600;
      padding: 0.8rem 1.6rem;
      line-height: 1.3rem;
      max-height: 3.2rem;
    }
  
`;


// Should have a icon inside a button with top and bottom padding as 8px and left and right padding as 32px
export const ZIconButton = styled(ZButton)`
  padding: ${(props: { small?: boolean }) => props.small ? '8px 16px' : '8px 32px'};
  span > svg {
    background : none !important;
  }
`

export const ButtonGroup = styled(btGp1)`
    &.MuiButtonGroup-contained {
        box-shadow: none;
        border-radius: 0.4rem;
        overflow: hidden;
        .MuiButton-contained {
            padding: 1rem 0.8rem;
            font-size: 1.4rem;
            line-height: 2rem;
            font-weight: 600;
            text-transform: capitalize;
            border-radius: 0;
            border: 0;
            box-shadow: none;
            background-color: transparent;
            border: 1px solid;
            border-left: 0;
            border-right: 0;
            max-height: 4rem;
            
            background-color: ${(props: { secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props.secondary
      ? '#F2F2F2'
      : props.tertiary
        ? 'transparent'
        : props.danger
          ? '#F03738'
          : props.theme.color.primary;
  }};
            border-color: ${(props: { secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props.secondary
      ? '#C2C9D1'
      : props.tertiary
        ? 'transparent'
        : props.danger
          ? '#F03738'
          : props.theme.color.primary;
  }};
            color: ${(props: { secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props.secondary ? '#616569' : props.tertiary ? props.theme.color.primary : props.danger ? '#fff' : '#fff';
  }};
            .MuiSvgIcon-root {
                font-size: 1.6rem !important;
            }
            &:first-child {
                border-left: 1px solid ${(props: { secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props.secondary
      ? '#C2C9D1'
      : props.tertiary
        ? 'transparent'
        : props.danger
          ? '#F03738'
          : props.theme.color.primary;
  }};
                border-top-left-radius: 0.4rem;
                border-bottom-left-radius: 0.4rem;
                padding: 1rem 0.4rem 1rem 0.8rem;
                min-width: 2rem;
            }
            &:last-child {
                border-right: 1px solid ${(props: { secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props.secondary
      ? '#C2C9D1'
      : props.tertiary
        ? 'transparent'
        : props.danger
          ? '#F03738'
          : props.theme.color.primary;
  }};
                border-top-right-radius: 0.4rem;
                border-bottom-right-radius: 0.4rem;
                padding: 1rem 0.8rem 1rem 0.4rem;
                min-width: 2rem;
            }
            &:not(:last-of-type) {
                &.Mui-disabled {
                    border-right: 0 !important;
                }
            }
            &.Mui-disabled {
                background-color: ${(props: { secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props.secondary
      ? '#fff'
      : props.tertiary
        ? 'transparent'
        : props.danger
          ? '#FDEBEB'
          : '#F0F2F3';
  }};
            border-color: ${(props: { secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props.secondary
      ? '#E1E4E8'
      : props.tertiary
        ? 'transparent'
        : props.danger
          ? '#FDEBEB'
          : '#F0F2F3';
  }};
            &:first-child {
                border-left: 1px solid ${(props: { secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props.secondary
      ? '#E1E4E8'
      : props.tertiary
        ? 'transparent'
        : props.danger
          ? '#FDEBEB'
          : '#F0F2F3';
  }};
            }
            &:last-child {
                border-right: 1px solid ${(props: { secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props.secondary
      ? '#E1E4E8'
      : props.tertiary
        ? 'transparent'
        : props.danger
          ? '#FDEBEB'
          : '#F0F2F3';
  }};
            }
    
            color: ${(props: { secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props.secondary ? '#C2C9D1' : props.tertiary ? '#C2C9D1' : props.danger ? '#F79B9B' : '#92979D';
  }};
                }
            }
            .MuiButton-sizeSmall {
                font-size: 1.2rem;
                padding: 0.8rem 0.4rem;
                line-height: 1.3rem;
                max-height: 3.2rem;
            }
        }
        &:hover, &:focus {
            .MuiButton-contained {
                background-color: ${(props: { secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props.secondary
      ? '#F9FAFA'
      : props.tertiary
        ? '#F5F7FF'
        : props.danger
          ? '#B4292A'
          : '#2E4CBC';
  }};
                border-color: ${(props: { secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props.secondary
      ? '#92979D'
      : props.tertiary
        ? 'transparent'
        : props.danger
          ? '#B4292A'
          : '#2E4CBC';
  }};
                &:first-child {
                    border-left: 1px solid ${(props: { secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props.secondary
      ? '#92979D'
      : props.tertiary
        ? 'transparent'
        : props.danger
          ? '#B4292A'
          : '#2E4CBC';
  }};
                }
                &:last-child {
                    border-right: 1px solid ${(props: {
    secondary?: boolean;
    tertiary?: boolean;
    danger?: boolean;
  }) => {
    return props.secondary
      ? '#92979D'
      : props.tertiary
        ? 'transparent'
        : props.danger
          ? '#B4292A'
          : '#2E4CBC';
  }};
                }
            }
        }
        &:active {
            .MuiButton-contained {
                background-color: ${(props: { secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props.secondary
      ? '#F0F2F3'
      : props.tertiary
        ? '#ECF0FF'
        : props.danger
          ? '#781C1C'
          : '#1F337E';
  }};
                border-color: ${(props: { secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props.secondary
      ? '#92979D'
      : props.tertiary
        ? 'transparent'
        : props.danger
          ? '#781C1C'
          : '#1F337E';
  }};
                &:first-child {
                    border-left: 1px solid ${(props: { secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props.secondary
      ? '#92979D'
      : props.tertiary
        ? 'transparent'
        : props.danger
          ? '#781C1C'
          : '#1F337E';
  }};
                }
                &:last-child {
                    border-right: 1px solid ${(props: {
    secondary?: boolean;
    tertiary?: boolean;
    danger?: boolean;
  }) => {
    return props.secondary
      ? '#92979D'
      : props.tertiary
        ? 'transparent'
        : props.danger
          ? '#781C1C'
          : '#1F337E';
  }};
                }
            }
        }
    
`;

export const ButtonGroupOneSide = styled(btGp2)`
    &.MuiButtonGroup-contained {
        box-shadow: none;
        border-radius: 0.4rem;
        overflow: hidden;
        .MuiButton-contained {
            padding: 1rem;
            font-size: 1.4rem;
            line-height: 2rem;
            font-weight: 600;
            text-transform: capitalize;
            border-radius: 0;
            border: 0;
            box-shadow: none;
            background-color: transparent;
            border: 1px solid;
            max-height: 4rem;
            
            background-color: ${(props: { secondary?: boolean; danger?: boolean }) => {
    return props.secondary ? '#F2F2F2' : props.danger ? '#F03738' : props.theme.color.primary;
  }};
            border-color: ${(props: { secondary?: boolean; danger?: boolean }) => {
    return props.secondary ? '#C2C9D1' : props.danger ? '#F03738' : props.theme.color.primary;
  }};
            color: ${(props: { secondary?: boolean; danger?: boolean }) => {
    return props.secondary ? '#616569' : props.danger ? '#fff' : '#fff';
  }};
            .MuiSvgIcon-root {
                font-size: 1.6rem !important;
            }
            &:first-child {
                border-top-left-radius: 0.4rem;
                border-bottom-left-radius: 0.4rem;
            }
            &:last-child {
                border-top-right-radius: 0.4rem;
                border-bottom-right-radius: 0.4rem;
            }
            &:not(:last-of-type) {
                border-right-color: ${(props: { secondary?: boolean; danger?: boolean }) => {
    return props.secondary
      ? '#C2C9D1 !important'
      : props.danger
        ? 'rgb(255 255 255 / 50%) !important'
        : 'rgb(255 255 255 / 50%) !important';
  }};
            }
            &.Mui-disabled {
                background-color: ${(props: { secondary?: boolean; danger?: boolean }) => {
    return props.secondary ? '#fff' : props.danger ? '#FDEBEB' : '#F0F2F3';
  }};
                border-color: ${(props: { secondary?: boolean; danger?: boolean }) => {
    return props.secondary ? '#E1E4E8' : props.danger ? '#FDEBEB' : '#F0F2F3';
  }};
        
                color: ${(props: { secondary?: boolean; danger?: boolean }) => {
    return props.secondary ? '#C2C9D1' : props.danger ? '#F79B9B' : '#92979D';
  }};
                }
                &:not(:last-of-type) {
                    border-right-color: ${(props: { secondary?: boolean; danger?: boolean }) => {
    return props.secondary
      ? '#E1E4E8 !important'
      : props.danger
        ? '#F79B9B !important'
        : '#C2C9D1 !important';
  }};
                }
            }
            .MuiButton-sizeSmall {
                font-size: 1.2rem;
                padding: 0.8rem;
                line-height: 1.3rem;
                max-height: 3.2rem;
            }
        }
        &:hover, &:focus {
            .MuiButton-contained {
                background-color: ${(props: { secondary?: boolean; danger?: boolean }) => {
    return props.secondary ? '#F9FAFA' : props.danger ? '#B4292A' : '#2E4CBC';
  }};
                border-color: ${(props: { secondary?: boolean; danger?: boolean }) => {
    return props.secondary ? '#92979D' : props.danger ? '#B4292A' : '#2E4CBC';
  }};
            }
        }
        &:active {
            .MuiButton-contained {
                background-color: ${(props: { secondary?: boolean; danger?: boolean }) => {
    return props.secondary ? '#F0F2F3' : props.danger ? '#781C1C' : '#1F337E';
  }};
                border-color: ${(props: { secondary?: boolean; danger?: boolean }) => {
    return props.secondary ? '#92979D' : props.danger ? '#781C1C' : '#1F337E';
  }};
            }
        }
    
`;

export const WLCircularLoader = styled(CircularProgress)`
  height: 100px !important;
  width: 100px !important;
`;

export const ZTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    margin-top: 0.2rem;
    outline: none;
    & input {
      font-size: 1.2rem;
      padding: 8px 12px !important;
      font-weight: 400;
      line-height: 1.8rem;
    }
    & password {
      font-size: 1.2rem;
      padding: 0.8rem 0.8rem;
      font-weight: 400;
      line-height: 1.8rem;
      type: password;
    }
    & fieldset {
      font-size: 1.2rem;
      color: #000;
      border-radius: 0.4rem;
      background-color: transparent;
      font-weight: 400;
      border: 1px solid;
      border-color: ${(props: { error?: boolean; warning?: boolean; success?: boolean }) => {
    return props.error
      ? 'rgb(240 55 56 / 50%) !important'
      : props.success
        ? 'rgb(60 193 59 / 50%)'
        : props.warning
          ? 'rgb(243 187 28 / 50%)'
          : '#D0D5DD';
  }};
    }
    &:hover fieldset,
    &:active fieldset {
      border-color: ${(props: { error?: boolean; warning?: boolean; success?: boolean }) => {
    return props.error ? '#F03738 !important' : props.success ? '#3CC13B' : props.warning ? '#F3BB1C' : props.theme.color.primary;
  }};
    }
    &.Mui-focused fieldset {
      color: #000;
      border-width: 1px;
      border-color: ${(props: { error?: boolean; warning?: boolean; success?: boolean }) => {
    return props.error ? '#F03738 !important' : props.success ? '#3CC13B' : props.warning ? '#F3BB1C' : props.theme.color.primary;
  }};
    }
    &.Mui-disabled fieldset {
      border-color: #D0D5DD !important;
      background: #F9FAFB !important;
      opacity: 0.6 !important;
    }
    &:placeholder {
      color: rgb(0 0 0 / 30%);
    }
    &:-ms-input-placeholder {
      color: rgb(0 0 0 / 30%);
    }
    &:-ms-input-placeholder {
      color: rgb(0 0 0 / 30%);
    }
    .MuiSvgIcon-root {
      font-size: 2rem;
      color: #c2c9d1;
    }
  }
`;

export const ZMessage = styled(message)`
  margin-top: 0.8rem;
  font-weight: 400;
  color: ${(props: { isError?: boolean }) => {
    return props.isError ? '#F03738' : 'rgba(0, 0, 0, 0.5)';
  }};
  display: flex;
  align-items: center;
  font-size: 1.01rem !important;
  align-self: flex-start;
  padding: 0.1rem;
`;

export const ZFormHeader = styled(Typography)`
  font-size: 2rem;
  font-weight: 600;
  color: #000;
  margin-bottom: 0.8rem;
  margin-top: 0.8rem;
  text-align: left;
`;

export const ZPhone = styled(PhoneInput)`
  .form-control {
    padding: 0.2rem 0.8rem;
    font-size: 1.2rem;
    height: auto !important;
    color: #000;
    border-radius: 0.4rem;
    background-color: transparent;
    font-weight: 400;
    border: 1px solid;
    padding-left: 3rem;
    line-height: 1.2rem;
    border-color: ${(props: { error?: boolean; warning?: boolean; success?: boolean }) => {
    return props.error
      ? 'rgb(240 55 56 / 50%) !important'
      : props.success
        ? 'rgb(60 193 59 / 50%)'
        : props.warning
          ? 'rgb(243 187 28 / 50%)'
          : '#C2C9D1';
  }};
    ~ {
      .flag-dropdown {
        border-color: ${(props: { error?: boolean; warning?: boolean; success?: boolean }) => {
    return props.error
      ? 'rgb(240 55 56 / 50%) !important'
      : props.success
        ? 'rgb(60 193 59 / 50%)'
        : props.warning
          ? 'rgb(243 187 28 / 50%)'
          : '#C2C9D1';
  }};
      }
    }
    &:hover,
    &:active {
      border-color: ${(props: { error?: boolean; warning?: boolean; success?: boolean }) => {
    return props.error ? '#F03738 !important' : props.success ? '#3CC13B' : props.warning ? '#F3BB1C' : props.theme.color.primary;
  }};
      ~ {
        .flag-dropdown {
          border-color: ${(props: { error?: boolean; warning?: boolean; success?: boolean }) => {
    return props.error
      ? '#F03738 !important'
      : props.success
        ? '#3CC13B'
        : props.warning
          ? '#F3BB1C'
          : props.theme.color.primary;
  }};
        }
      }
    }
    &:focus {
      color: #000;
      border-width: 1px;
      border-color: ${(props: { error?: boolean; warning?: boolean; success?: boolean }) => {
    return props.error ? '#F03738 !important' : props.success ? '#3CC13B' : props.warning ? '#F3BB1C' : props.theme.color.primary;
  }};
      ~ {
        .flag-dropdown {
          border-color: ${(props: { error?: boolean; warning?: boolean; success?: boolean }) => {
    return props.error
      ? '#F03738 !important'
      : props.success
        ? '#3CC13B'
        : props.warning
          ? '#F3BB1C'
          : props.theme.color.primary;
  }};
        }
      }
    }
  }
`;

export const ZDropdown = styled(ReactSelect)`
  & .react-select {
    &__control {
      font-size: 14px;
      padding: 0.1rem 0;
      font-weight: 400;
      outline: none;
      margin-top: 0.8rem;
      line-height: 2rem;
      box-shadow: none;
      border-color: ${(props: { error?: boolean; warning?: boolean; success?: boolean }) => {
    return props.error
      ? 'rgb(240 55 56 / 50%) !important'
      : props.success
        ? 'rgb(60 193 59 / 50%)'
        : props.warning
          ? 'rgb(243 187 28 / 50%)'
          : '#C2C9D1';
  }};
      &:hover,
      &:active {
        border-color: ${(props: { error?: boolean; warning?: boolean; success?: boolean }) => {
    return props.error
      ? '#F03738 !important'
      : props.success
        ? '#3CC13B !important'
        : props.warning
          ? '#F3BB1C !important'
          : `${props.theme.color.primary} !important`;
  }};
      }
      &--is-focused {
        color: #000;
        border-width: 1px;
        border-color: ${(props: { error?: boolean; warning?: boolean; success?: boolean }) => {
    return props.error ? '#F03738 !important' : props.success ? '#3CC13B' : props.warning ? '#F3BB1C' : props.theme.color.primary;
  }};
      }
      &--is-disabled {
        border-color: rgb(194 201 209 / 50%);
        background-color: rgb(194 201 209 / 25%);
      }
      &:placeholder {
        color: rgb(0 0 0 / 30%);
      }
      &:-ms-input-placeholder {
        color: rgb(0 0 0 / 30%);
      }
      &:-ms-input-placeholder {
        color: rgb(0 0 0 / 30%);
      }
    }
    &__ValueContainer {
      padding: 2px 4px;
    }
    &__indicator-separator {
      display: none;
    }
    &__multi-value {
      border-radius: 3rem;
      padding: 0.2rem 0.8rem;
      line-height: 2rem;
      background-color: rgb(194 201 209 / 25%);
      align-items: center;
      &__label {
        font-size: 1.4rem;
      }
      &__remove {
        border-radius: 100%;
        background-color: #c2c9d1;
        width: 1.5rem;
        height: 1.5rem;
        padding: 0;
        color: #fff;
      }
    }
  }
`;

export const ZTabList = styled(tabL)`
  .MuiTabs-root {
    min-height: auto;
    border-bottom: 1px solid #EAECF0;
    /* margin-left: 2rem !important; */
  }
  .MuiTabs-scroller {
    .MuiTabs-fixed {
      .MuiTabs-indicator {
        background: ${(props) => `${props.theme.color.primary} !important`};
      }
    }
    .MuiTabs-indicator {
      background: none !important;
      transition: all 600ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    }
    .MuiTabs-flexContainer {
      display: block;
      .MuiButtonBase-root {
        padding: 8px 4px !important;
        min-width: 110px;
        min-height: auto;
        line-height: 1.6;
        font-size: 11px;
        font-weight: 500;
        color: #475467;
        align-items: start;
        text-transform: capitalize;
        &.Mui-selected {
          color: ${(props) => `${props.theme.color.primary} !important`};
          /* background: ${(props: { secondary?: boolean }) => {
    return props.secondary
      ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), #C2C9D1'
      : '#F0F2F3';
  }}; */
          border-bottom: 2px solid
            ${(props: { secondary?: boolean }) => {
    return props.secondary ? props.theme.color.primary : 'transparent';
  }};
        }
      }
    }
  }
`;

export const DetailsLabel = styled(Typography)`
  padding: 0.5rem;
  font-weight: 400 !important;
  background-color: #f0f2f3;
  border-radius: 0.4rem;
  margin-bottom: 0.1rem !important;
`;

export const ZHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.color.primary};
  color: #fff;
`;

export const ZSwitch = styled(sw)`
  &.MuiSwitch-root {
    padding: 0.5rem;
    width: 7rem;
    height: 4rem;
    .MuiSwitch-switchBase {
      padding: 0.75rem 0.8rem;
      .MuiSwitch-thumb {
        width: 2.6rem;
        height: 2.6rem;
        background-color: ${(props: { secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props.secondary
      ? '#616569 !important'
      : props.tertiary
        ? '#3E66FB !important'
        : props.danger
          ? '#ffffff !important'
          : '#ffffff !important';
  }};
      }
      &.Mui-checked {
        -webkit-transform: translateX(2.8rem);
        -moz-transform: translateX(2.8rem);
        -ms-transform: translateX(2.8rem);
        transform: translateX(2.8rem);
        + {
          .MuiSwitch-track {
            opacity: 1;
          }
        }
      }
      &.Mui-disabled {
        .MuiSwitch-thumb {
          opacity: 0.5;
          background-color: ${(props: { secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props.secondary
      ? '#C2C9D1 !important'
      : props.tertiary
        ? '#C2C9D1 !important'
        : props.danger
          ? '#F79B9B !important'
          : '#92979D !important';
  }};
        }
        + {
          .MuiSwitch-track {
            opacity: 0.8;
            background-color: ${(props: { secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props.secondary ? '#ffffff' : props.tertiary ? '#ffffff' : props.danger ? '#FDEBEB' : '#F0F2F3';
  }};
            border-color: ${(props: { secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props.secondary
      ? '#E1E4E8'
      : props.tertiary
        ? 'rgb(25 118 210 / 50%)'
        : props.danger
          ? '#FDEBEB'
          : '#F0F2F3';
  }};
          }
        }
      }
    }
    .MuiSwitch-track {
      border-radius: 3rem;
      border: 1px solid;
      background-color: ${(props: { secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props.secondary ? '#f2f2f2' : props.tertiary ? '#ffffff' : props.danger ? '#f03738' : props.theme.color.primary;
  }};
      border-color: ${(props: { secondary?: boolean; tertiary?: boolean; danger?: boolean }) => {
    return props.secondary ? '#C2C9D1' : props.tertiary ? '#1976d2' : props.danger ? '#f03738' : props.theme.color.primary;
  }};
    }
    &.MuiSwitch-sizeSmall {
      width: 5.4rem;
      height: 3rem;
      .MuiSwitch-thumb {
        width: 1.6rem;
        height: 1.6rem;
      }
      .MuiSwitch-switchBase {
        padding: 0.7rem 0.8rem;
        &.Mui-checked {
          -webkit-transform: translateX(2.3rem);
          -moz-transform: translateX(2.3rem);
          -ms-transform: translateX(2.3rem);
          transform: translateX(2.3rem);
        }
      }
    }
  }
`;

export const ZTextFieldArea = styled(TextareaAutosize)`
  & .MuiOutlinedInput-root {
    margin-top: 0.8rem;
    outline: none;
    padding: 0;
    & textarea {
      font-size: 1.4rem;
      padding: 1rem 0.8rem;
      font-weight: 400;
    }
    & fieldset {
      font-size: 1.4rem;
      color: #000;
      border-radius: 0.4rem;
      background-color: transparent;
      font-weight: 400;
      border: 1px solid;
      border-color: ${(props: { error?: boolean; warning?: boolean; success?: boolean }) => {
    return props.error
      ? 'rgb(240 55 56 / 50%) !important'
      : props.success
        ? 'rgb(60 193 59 / 50%)'
        : props.warning
          ? 'rgb(243 187 28 / 50%)'
          : '#C2C9D1';
  }};
    }
    &:hover fieldset,
    &:active fieldset {
      border-color: ${(props: { error?: boolean; warning?: boolean; success?: boolean }) => {
    return props.error ? '#F03738 !important' : props.success ? '#3CC13B' : props.warning ? '#F3BB1C' : props.theme.color.primary;
  }};
    }
    &.Mui-focused fieldset {
      color: #000;
      border-width: 1px;
      border-color: ${(props: { error?: boolean; warning?: boolean; success?: boolean }) => {
    return props.error ? '#F03738 !important' : props.success ? '#3CC13B' : props.warning ? '#F3BB1C' : props.theme.color.primary;
  }};
    }
    &.Mui-disabled fieldset {
      border-color: rgb(194 201 209 / 50%);
      background-color: rgb(194 201 209 / 25%);
    }
    &:placeholder {
      color: rgb(0 0 0 / 30%);
    }
    &:-ms-input-placeholder {
      color: rgb(0 0 0 / 30%);
    }
    &:-ms-input-placeholder {
      color: rgb(0 0 0 / 30%);
    }
  }
`;

export const Modal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: ${(props: { secondary?: boolean; codeEditor?: boolean }) => (props?.codeEditor ? '5%' : '0')};
  left: ${(props: { secondary?: boolean; codeEditor?: boolean }) => (props?.codeEditor ? '5%' : '0')};
  max-width: ${(props: { secondary?: boolean; codeEditor?: boolean; integration?: boolean }) => (props?.codeEditor ? '90%' : props?.integration ? "600px" : '80%')};
  max-height: ${(props: { secondary?: boolean; codeEditor?: boolean; integration?: boolean }) => props?.codeEditor ? '780px' : "fit-content"};
  transform: translateY(60px) translateX(50px);
  background-color: ${(props: { secondary?: boolean }) => (props.secondary ? '#333' : ' #fff !important')};
  @media ${device.mobileS} {
    max-width: ${(props: { secondary?: boolean; codeEditor?: boolean }) => (props?.codeEditor ? '90%' : '100%')};
    transform: translateY(0) translateX(0);
  }
  &.MuiModal-hidden {
    visibility: hidden;
  }
  .scrollable-force-tabpanel {
    max-height: 800px;
  }
`;

export const ModalWrapper = styled(Box)`
  width: 400;
  min-height: 'auto';
  max-height: 550;
  overflow: 'auto';
  padding: '16px 32px 24px 32px';
`;

export const ModalHeader = styled(Box)`
  border-bottom: ${(props: { secondary?: boolean }) => (props.secondary ? 'none' : '1px solid #e1e4e8')};
  font-size: 1.4rem;
  font-weight: 400;
  padding:12px 16px;
  display:flex;
  align-items:center;
  justify-content:space-between;
`;

export const ModalContent = styled(Box)`
  padding: ${(props: { secondary?: boolean; codeEditor?: boolean }) =>
    props?.codeEditor ? '1.5rem 1.5rem' : '2.4rem 2.4rem'};
  overflow-y: hidden;
  padding:0px;
  margin:0px;
  max-height: calc(100vh - 4.8rem);
  height: 100vh;
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
`;

export const EditorModalContent = styled(Box)`
  padding: ${(props: { secondary?: boolean; codeEditor?: boolean }) =>
    props?.codeEditor ? '1.5rem 1.5rem' : '2.4rem 2.4rem'};
  overflow-y: hidden;
  padding:0px;
  margin:0px;
  max-height: 100vh;
  height: 100vh;
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
`;

export const ModalFooter = styled(Box)`
  border-top: 1px solid #e1e4e8;
`;

export const InfoPill = styled.div`
  border-radius: 4px;
  border: ${(props) => `1px solid ${props.theme.color.secondary}`};
  background: ${(props) => props.theme.color.tertiary};
  color: ${(props) => props.theme.color.secondary};
  font-size: 12px;
  padding: 4px;
`
