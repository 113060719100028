import Editor from '@monaco-editor/react';
import { useJSEditor } from 'lowcode/hooks/useJSEditor';
import { useState } from 'react';

interface IZJSEditor {
  setJsScript: any;
  isAPIBody?: boolean;
}

export const ZJSEditor = ({ setJsScript, isAPIBody } : IZJSEditor) => {
  const {     
    scriptEditorOptions: { config: { script = '' } = {}, defaultEditorLanguage, isEditorDisabled }
  } = useJSEditor();

  const [code, setCode] = useState(script);
  const [disabledState] = useState(isEditorDisabled);
  const [editorLanguageState] = useState(defaultEditorLanguage);

  const settings = {
    minimap: false,
    wordWrap: false,
    fontSize: 15,
    vimMode: false,
    theme: 'vs-dark',
  };

  return (
    <Editor
    height={isAPIBody ? '33vh' : '100%'}
      language={editorLanguageState}
      value={code}
      onChange={(value) => {
        setCode(value);
        setJsScript(value);
      }}  
      theme={settings.theme}
      options={{
        minimap: { enabled: settings.minimap },
        wordWrap: settings.wordWrap ? 'on' : 'off',
        fontSize: `${settings.fontSize || 0}px`,
        automaticLayout: true,
        readOnly: disabledState,
      }}
    />
  );
};