import { Stepper } from '@mui/material'
import React, { useEffect } from 'react'
import { fetchLogs  } from './handlers/TransitionHandler'
import ZStepper from './ZStepper'

type Props = {
    type?: "task" | "workflow" | "job"
    taskId?: string
}

const Logs = ({type,taskId=""}: Props) => {

    const [logs, setLogs] = React.useState<any[] | undefined>([])
    const [loading, setLoading] = React.useState<boolean>(false)
    const handleTaskLogs = async (taskId:string) => {
        try {
          setLoading(true)
          const res = await fetchLogs(taskId)
          setLogs(res)
          setLoading(false)
        } catch (error) {
          setLogs([])
          setLoading(false)
        }
        
    }

    useEffect(() => {
      if(type === "task") {
        handleTaskLogs(taskId)
      }
    },[taskId])

    

    /**
     * Depending on the type of log, we will render fetch the data from
     * the backend and render the logs
     * 
     * Different handle will be called that will fetch data from BE and tranform it
     * to required format for ZStepper
     * 
     * The type will be passed as a prop to ZStepper which will render the UI.
     */

  return (
        loading ? <p> Loading... </p> :
        logs?.length === 0 ? (
          <p> No Task logs found! </p>
        ) : (
          logs ? <ZStepper logs={logs}/> : <p> Loading... </p>
        )
      
    
  )
}

export default Logs