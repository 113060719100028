import styled from "styled-components";
import { useDispatch } from "react-redux";
import { IAutomationData, openView } from "./utils";
import { MdDangerous } from "react-icons/md"
import { setShowWorkflowErrors } from "../reducers/workflowAction";
import useAutomation from "views/automationBuilder/hooks/useAutomation";

const Container = styled.div`
  display: flex;
  border-radius: "4px";
  height: auto;
  background-color: #F6F6F6;
  padding: 8px;
  &:hover {
    cursor: pointer;
  }
`;

const Info = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  width: 100%;
  margin-left: 4px;
  padding: 8px;
  color: rgba(0, 0, 0, 0.8);
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

type PropTypes = {
  location: string;
  info: string;
  payload: any;
};

const SingleError = ({ location, payload, info }: PropTypes) => {
  const dispatch = useDispatch();
  const { metadataType, selectedActionBlock, selectedAutomation } = useAutomation();

  const handleClick = (errorLocation: any, errorPayload: any) => {
    const automationMetaData: IAutomationData = {
      selectedAutomationId: selectedAutomation?.id,
      selectedActionBlockId: selectedActionBlock?.actionBlockId,
      automationMetaData: metadataType
    }
    openView(errorLocation, errorPayload, dispatch, automationMetaData);
    // dispatch(setShowWorkflowErrors(false));
  };

  return (
    <li style={{ padding: "8px" }}>
      <Container>
        <IconContainer>
          <MdDangerous
            style={{ display: "inline" }}
            size={"1.5rem"}
            color="red"
          />{" "}
        </IconContainer>
        <div>
          <Info onClick={() => handleClick(location, payload)}>
            {info}
          </Info>
        </div>
      </Container>
      <hr />
    </li>
  );
};

export default SingleError;
