import { Card, CardContent, CardHeader } from "@mui/material";
import React, { FC } from "react";

interface PlaceHolderProps {
  url: string;
}

const CarbonMasters_Ops: FC<PlaceHolderProps> = ({ url }) => {
  // url = 'https://external-app.app.zorp.one/app/carbonlites-admin#/products';
  const teams: any = JSON.parse(localStorage.getItem("teams") || "[]")
    .map((team: any) => `${team.teamId}`)
    .join(",");
  url =
    "https://external-app.app.zorp.one/app/carbonlites-operations#/order-management?teams=" +
    teams;
  if (url && url.length > 0) {
    return (
      <Card>
        <div>
          <iframe
            title="iframe"
            src={`${url}`}
            frameBorder="0"
            width="100%"
            height={window.screen.height}
            allowTransparency
          ></iframe>
        </div>
      </Card>
    );
  }
  return (
    <Card>
      <CardHeader title="Welcome to Zorp!" />
      <CardContent></CardContent>
    </Card>
  );
};

export default CarbonMasters_Ops;
