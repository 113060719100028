import styled from "styled-components";
import { AiOutlineNodeIndex } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { setShowWorkflowConfigModal } from "views/workflows/reducers/workflowAction";
import { WorkflowSettingsIcon } from "assests";
import { ZButton } from "views/commonComponents/commonComponents.styles";
//@ts-ignore

const Button = styled.button`
  background-color: #f2f2f2;
  border: 1px solid #c2c9d1;
  color: #616569;
  padding: 10px 16px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  margin-right: 10px;
`;

const ConfigureWorkflowButton = () => {
  const dispatch = useDispatch();
  const handleOpen = () => dispatch(setShowWorkflowConfigModal(true));

  return (
    <>
      <ZButton
        secondary
        variant="contained"
        onClick={handleOpen}
        startIcon={WorkflowSettingsIcon}
      >
        {" "}
        Workflow Settings{" "}
      </ZButton>
    </>
  );
};

export default ConfigureWorkflowButton;
