import { createHashHistory } from "history";
import authProvider from "providers/auth/authProvider";
import zorpDataProvider from "providers/data/ZorpDataProvider";
import { useEffect } from "react";
import { Admin, Resource } from "react-admin";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { Sprig } from 'utils/Sprig.util';
import RoleEdit from "views/roles/RoleEdit";
import TeamEdit from "views/teams/TeamEdit";
import Flow from "views/workflows/WorkflowGraph";
import routes from "./routes/routes";
import store from "./store";
import "./styles/App.css";
import theme from "./styles/theme";
import { AlertShow } from "./views/alerts/AlertDetails";
import { AlertList } from "./views/alerts/AlertList";
import ZorpLayout from "./views/layouts/layout";
import ZorpLoginPage from "./views/layouts/Login";
import NotFound from "./views/NotFound/NotFound";
import RoleCreate from "./views/roles/RoleCreate";
import RolesList from "./views/roles/RoleList";
import RoleShow from "./views/roles/RoleShow";
import TaskList from "./views/tasks/screens/task/list/TaskList";
import TeamCreate from "./views/teams/TeamCreate";
import { TeamShow } from "./views/teams/TeamShow";
import { TeamsList } from "./views/teams/TeamsList";
import {
  TaskCreate,
  TaskEditSection,
  TaskShowSection,
} from "./views/users/tasks";
import UserCreate from "./views/users/UserCreate";
import { UserEdit, UserList, UserShow } from "./views/users/UsersList";
import WorkflowList from "./views/workflows/WorkflowList";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ZThemeProvider from "views/layouts/ZThemeProvider";

const history = createHashHistory();

function App() {
  const { getIdentity, checkAuth } = authProvider;

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'prod' || process.env.REACT_APP_ENVIRONMENT === 'production') {
      import('./tracing')
        .then(module => {
          // Module is imported successfully
          console.log('Tracing module imported');
        })
        .catch(err => {
          // Handle the error case
          console.error('Error importing the tracing module:', err);
        });
    }
  }, []);

  useEffect(() => {
    // if (checkAuth()) {
      Promise.all([getIdentity(), checkAuth()]).then((response) => {
        const userData = { ...response?.[0], ...response?.[1] };
        window.pendo.initialize({
          visitor: {
            id: userData?.id,
            fullName: userData?.fullName,
            accountId: userData?.accountId,
            role: userData?.role,
          },
        });
        const preview = window.location.href.includes("preview=true");
        if (!preview) {
          localStorage.removeItem("isPreview");
          localStorage.removeItem("previewWorkflowId");
        }
        // Sprig.setEmail(userData?.accountId);
        // Sprig.setUserId(userData?.id);
        // Sprig.setAttributes({
        //   ROLE: userData?.role,
        //   FULLNAME: userData?.fullName,
        // });
      }).catch((err) => {
        console.log(err);
      });
    // }
  }, [getIdentity, checkAuth]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <GoogleOAuthProvider clientId="1063036466845-5nq4h8eou11gncjmd2n5dq5ahc7osmh9.apps.googleusercontent.com">
      <Provider
        store={store({
          authProvider,
          zorpDataProvider,
          history,
        })}
      >
        <ZThemeProvider>
          <Admin
            layout={ZorpLayout}
            loginPage={ZorpLoginPage}
            theme={theme}
            catchAll={NotFound}
            customRoutes={routes}
            dataProvider={zorpDataProvider}
            authProvider={authProvider}
            history={history}
          >
            {() => [
              <Resource
                name="tasks"
                list={TaskList}
                create={TaskCreate}
                show={TaskShowSection}
                edit={TaskEditSection}
              />,
              <Resource name="alerts" list={AlertList} show={AlertShow} />,
              <Resource
                name="users"
                list={UserList}
                create={UserCreate}
                show={UserShow}
                edit={UserEdit}
              />,
              <Resource
                name="teams"
                list={TeamsList}
                create={TeamCreate}
                show={TeamShow}
                edit={TeamEdit}
              />,
              <Resource
                name="roles"
                list={RolesList}
                create={RoleCreate}
                show={RoleShow}
                edit={RoleEdit}
              />,
              <Resource
                name="workflows"
                list={WorkflowList}
                create={Flow}
                show={Flow}
              />,
            ]}
          </Admin>

          <ToastContainer
            icon={false}
            closeOnClick
            hideProgressBar
            autoClose={3000}
            limit={5}
            style={{ height: "2px" }}
          />
        </ZThemeProvider>
      </Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
