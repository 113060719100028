import { embedDashboard } from "zorp-bi-embedded-sdk";
import jwt_decode from "jwt-decode";
import { BffService } from "providers/data/services/BffService";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useZMenu from "../layouts/reducer/useMenu";
import { getConfig as Config } from "../../config/envConfig/Config";

interface Team {
  teamId: string;
  children: Team[];
}

const BiDashboard = () => {
  const { label } = useParams();
  const { embedLinks } = useZMenu();
  const [teamStructure, setTeamStructure] = useState<string[]>([]);
  const getGuestToken = async (dashboardPayload: any) => {
    const fetchGuestToken = await BffService.getBiGuestToken(dashboardPayload);
    return fetchGuestToken?.data.token || "";
  };

  function getAllTeamIds(team: Team, teamIds: string[] = []): string[] {
    teamIds.push(team.teamId);
    for (const child of team.children) {
      getAllTeamIds(child, teamIds);
    }
    return teamIds;
  }

  useEffect(() => {
    BffService.getTeamDescendants()
      .then((res: any) => {
        if (res?.length > 0) {
          const descendentTeamIds = getAllTeamIds(res[0] || {}, []);
          setTeamStructure(descendentTeamIds || []);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (document.getElementById("dashboard") && embedLinks?.length > 0) {
      const at: any = localStorage.getItem("at");
      const decoded: any = jwt_decode(at);

      const embedData = embedLinks.find(
        (data: any) =>
          data?.data?.type == "ZORP_BI" && data?.data.label == label
      );
      const dashBoardId = embedData?.data?.data?.dashboardId;
      const paramData = embedData?.data?.data?.params;

      const guestTokenPayload = {
        payloadData: {
          resources: [
            {
              type: "dashboard",
              id: dashBoardId,
            },
          ],
          rls: [],
          user: {
            username: decoded.name,
            first_name: decoded.name,
            last_name: decoded.name,
          },
        },
      };

      const chronosDomainURL = `${Config("services.zorpBiSvc.host")}:${Config(
        "services.zorpBiSvc.port"
      )}`;

      const embed = async () => {
        const embeddedPayload = {
          id: dashBoardId, // given by the Superset embedding UI
          supersetDomain: chronosDomainURL,
          mountPoint: document.getElementById("dashboard"), // html element in which iframe render
          fetchGuestToken: () => getGuestToken(guestTokenPayload),
          dashboardUiConfig: {
            hideTitle: true,
            hideChartControls: false,
            hideTab: false,
            filters: {
              visible: true,
              expanded: false,
            },
            urlParams:
              { ...(paramData || {}), descendentTeamIds: teamStructure } ?? {},
          },
        };

        // @ts-ignore
        await embedDashboard(embeddedPayload);
      };
      embed();
    }
  }, [embedLinks?.length, teamStructure?.length]);

  return (
    <div
      className="bi-iframe-container"
      style={{ width: "100%", height: "100%" }}
    >
      <div id="dashboard" style={{ width: "100%", height: "100%" }}></div>
    </div>
  );
};

export default BiDashboard;
