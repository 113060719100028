import { Checkbox } from '@material-ui/core';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { IZComponentPropType } from "render-engine/models/ITaskTypeWebBodyRenderModel";
import Select from "react-select";
import { TaskFormLabelForCreateAndEdit } from 'render-engine/render.styles';

export const ZChoice: FunctionComponent<IZComponentPropType> = ({ config, onChange, register, errors,setValue,control }) => {
  const [send, setSend] = useState<String[]>([]);
  const [searchText, setSearchText] = useState<string>('');

  const handleArray = (checked: boolean, checked_value: string) => {
    var curr_state_arr = send;

    var map1 = new Map();
    config.options?.map((o:any) => {
      const found = curr_state_arr.find((element) => element === o.value);
      if (found === undefined) {
        map1.set(`${o.value}`, false);
      } else {
        map1.set(`${o.value}`, true);
      }
    });
    map1.forEach((value: boolean, key: string) => {
      if (checked_value === key) {
        map1.set(checked_value, checked);
      }
    });
    var arr_name: string[] = [];
    map1.forEach((value: boolean, key: string) => {
      if (value) {
        arr_name.push(key);
      }
    });
    setSend(arr_name);
    onChange(config.key, arr_name);
  };
  // Construct validation
  const validation: any = {};
  if (config.isRequired) validation.required = `required`;

  // This is needed to store the value in redux store
  useEffect(() => {
    if (config.value) {
      setValue(config.key, config.value)
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const containsText = (text: string, searchText: string) =>
    text?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1;

  const configOptions = React.useMemo(
    () => config?.options?.filter((option:any) => containsText(option?.label, searchText)),
    [searchText, config] // eslint-disable-line react-hooks/exhaustive-deps
  );

  // Based on the shape fetch the corresponding UI component
  const getUIComponent = () => {
    switch (config.shape.toUpperCase()) {
      case 'RADIO':
        return (
          <>
            <RadioGroup aria-label={config.key} onChange={(e) => onChange(config.key, e.target.value)}>
              {config.options?.map((o:any) => {
                return <FormControlLabel key={o.value} value={o.value} control={<Radio />} label={o.label} />;
              })}
            </RadioGroup>
          </>
        );
      case 'CHECKBOX':
        return (
          <>
            {config.options?.map((o:any) => {
              return (
                <FormControlLabel
                  key={o.value}
                  value={o.value}
                  control={<Checkbox onChange={(e) => handleArray(e.target.checked, e.target.value)} />}
                  label={o.label}
                />
              );
            })}
          </>
        );

      case 'DROPDOWN':
        return (
          <>
            
            <Controller
              name={config?.key}
              // defaultValue={true}
              rules={{
                required : "Task Type is required"
              }}
              control={control}
              render={({
                field: { onChange: fieldOnChange, value, name, ref },
              }) => (
                <Select
                  placeholder="Start typing or select from dropdown"
                  // styles={customStyles}
                  value={config?.options.find((c:any) => c.value === value)}
                  isDisabled={config?.disabled}
                  isClearable
                  isSearchable={true}
                  className="react-dropdown"
                  classNamePrefix="dropdown"
                  onChange={(selectedOption: any) => {
                    fieldOnChange(selectedOption?.value);
                    onChange(config.key, selectedOption?.value);
                  }}
                  options={config?.options}
                />
          )}
        />
            {errors[config.key]?.message?.length > 0 && (
              <div className='font-semibold text-sm text-red-500'>{errors[config.key]?.message}</div>
            )}
          </>
        );
      default:
        return <>Yet to be implemented!</>;
    }
  };

  return (
    <>
      
      <div className='col-span-4'>
        {
        config?.label?.isVisible && 
        <TaskFormLabelForCreateAndEdit fontSize={config?.label?.fontSize || 14} color={config?.label?.color}  className='col-span-1 font-semibold flex items-center mb-2'>
          {config?.label?.val}{config?.isRequired && <span className='text-red-500'>*</span>}
        </TaskFormLabelForCreateAndEdit >}
        {getUIComponent()}
      </div>
    </>
  );
};
