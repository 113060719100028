import { useSelector } from 'react-redux';

type Props = {
    name:string
}

const useListInfo = ({name}:Props) => {
  const {listInfo} = useSelector((state:any) => state)

  
  const metaData = listInfo?.metaData[name]
  const showViewAlreadyCreatedItems = listInfo.createdItems[name]
  const uiElements = listInfo?.uiElements[name]
  const listData = listInfo?.listData[name]


  return {
    metaData,
    showViewAlreadyCreatedItems,
    uiElements,
    listData
  };
};

export default useListInfo;
