import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { IActionType } from '@zorp/common-libs-js/dist/monitor';
import ActionDeleteIcon from 'assests/action_delete_icon.svg';
import FilterSelect from "assests/filter_dropdown_arrow.svg";
import SlackActionIcon from 'assests/slack_action_icon.svg';
import SlackChannel from "assests/slack_channel_icon.svg";
import SlackUsers from "assests/user_icon.svg";
import { RudderStackAutomationBuilderEvents } from 'constants/RudderStackConstants';
import React, { useState } from 'react';
import { Controller } from "react-hook-form";
import { components } from "react-select";
import styled from "styled-components";
import { recordRSEvent } from 'utils/CommonUtils';
import { ZButton } from 'views/commonComponents/commonComponents.styles';
import useAccount from "views/layouts/reducer/useAccount";
import { getConfig as Config } from '../../../config/envConfig/Config';
import { LabelText, VariableHelperText } from '../Monitor.styles';
import ColumnsListCapsure from '../MonitorInterfaceComponents/ColumnsListCapsure';
import useMonitor from '../reducer/useMonitor';
import MonitorInterfaceSelect from './MonitorInterfaceSelect';

const { Option } = components;
interface ISlackActionConfiguration {
  setValue: any;
  watch: any;
  control: any;
  errors: any;
  onRemove: (index: number) => void;
  fields: any;
  slackMember: any;
}

const SlackBotText = styled.p`
color: var(--Gray-600, #475467);
font-family: Inter;
font-size: 14px;
margin-left:12px;
font-style: normal;
font-weight: 400;
`;

const SlackActionConfiguration = ({ setValue, watch, control, errors, fields, onRemove, slackMember }: ISlackActionConfiguration) => {
  const { monitorInterfaceContext } = useMonitor();
  const [configureSlackConfirmation, setConfigureSlackConfirmation] = useState(false);
  const { account } = useAccount();

  const fieldsIndex = fields.findIndex((data: any) => (data.actionId == monitorInterfaceContext?.monitorComponentsData?.selectedActionDetails?.actionId && data.actionType == IActionType.SLACK));
  const columnsListArray = monitorInterfaceContext.monitorComponentsData.tablesColumnsList || [];

  const IconOption = (props: any) => {
    const {
      data: { label, Icon },
    } = props;

    return (
      <Option {...props}>
        <div className="flex items-center gap-2">
          {

            props.data.type == 'USER' ?
              <img
                src={SlackUsers}
                style={{ paddingRight: "12px", width: '30px', height: '30px' }}
                width={'12px'}
                className="cursor-pointer"
              /> : <img
                src={SlackChannel}
                style={{ paddingRight: "12px", width: '30px', height: '30px' }}
                width={'12px'}
                className="cursor-pointer"
              />
          }

          <span style={{
            color: '#101828',
          }}>{label}</span>
        </div>
      </Option>
    );
  }

  const getSlackURL = async () => {
    const slackRedirectionUrl = Config("slackRedirection.url");
    // Get the current URL
    const currentUrl = window.location.href;
    // Construct the state object with accountId and currentUrl
    const stateObject = {
      currentUrl: currentUrl,

      authToken: 'Bearer ' + localStorage.getItem('at')
    };

    const stateParameter = encodeURIComponent(JSON.stringify(stateObject));


    const slackURL = `https://slack.com/oauth/v2/authorize?state=${stateParameter}&redirect_uri=${slackRedirectionUrl}&client_id=2149933522689.5288293884738&scope=channels:read,chat:write,chat:write.customize,chat:write.public,groups:read,users:read,users:read.email,files:read,files:write&user_scope=`;

    window.location.href = slackURL;
  }

  return (
    <>
      <Box width={'100%'} padding={'24px'} borderRadius={'4px'} border={'1px solid var(--Gray-200, #EAECF0)'} boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)'}>
        {
          account?.accountConfig?.slackVerificationToken &&
          <>
            <Box display={'flex'} gap='8px' width={'100%'}>
              <Box width={'60%'}>
                <MonitorInterfaceSelect
                  control={control}
                  singleSelectImg={<img src={FilterSelect} style={{ marginRight: '4px' }} />}
                  isMulti
                  showSingleSelectImg
                  name={`actionData[${fieldsIndex}].payload.channelData`}
                  IconOption={IconOption}
                  errors={errors}
                  options={slackMember}
                  isClearable={true}
                  isLoading={false}
                  placeholder='Select Channel / User'
                  isDisabled={false}
                  onChange={async (datasourceValue: any) => {
                    setValue(`actionData[${fieldsIndex}].payload.channelData`, datasourceValue);
                  }}
                /></Box>
            </Box>

            <LabelText style={{ marginTop: '16px' }}>Slack Message</LabelText>
            <Controller
              name={`actionData[${fieldsIndex}].payload.message`}
              control={control}
              render={({ field }) => (
                <>
                  <TextareaAutosize
                    {...field} // destructuring field state provided by Controller
                    maxRows={5}
                    placeholder={`Hey Team, \nI would like to bring to you notice that...`}
                    minRows={5}
                    
                    style={{
                      width: "100%",
                      border: "1px solid #c2c9d1",
                      borderRadius: "4px",
                      padding: "6px 8px",
                      marginTop: '6px',
                      color:'black !important',
                      fontSize:'12px !important'
                    }}
                  />
                  {columnsListArray.length > 0 &&
                    <VariableHelperText>Add Variables to your Slack Description</VariableHelperText>
                  }
                </>
              )}
            />

            <Box width={'100%'} marginTop={'8px'}>
              <ColumnsListCapsure
                onCardClick={(columnName: string) => {
                  setValue(`actionData[${fieldsIndex}].payload.message`, `${watch()?.actionData?.[fieldsIndex]?.payload?.message} \${${columnName}}`)
                }}
                columnsList={columnsListArray} />

              <Box display={'flex'} width={'100%'} justifyContent={'space-between'}>
                <Box ml={'11px'}>
                  <Controller
                    name={`actionData[${fieldsIndex}].payload.attachCSV`}
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <input
                          {...field}
                          type="checkbox"
                          style={{ marginRight: "10px", marginTop: "5px", marginBottom: "5px", height: '16px', width: '16px' }}
                          checked={field.value}
                      />
                        }
                        label={
                          <LabelText style={{
                            marginBottom:'0px'
                          }}>Attach matching results as CSV</LabelText>
                        }
                        style={{ marginTop: '20px' }}
                      />
                    )}
                  />
                  <SlackBotText>Add Zorp Alert Bot to your channel first</SlackBotText>
                </Box>
                <Box>
                  <ZButton
                    onClick={() => {
                      onRemove(fieldsIndex);
                      recordRSEvent(RudderStackAutomationBuilderEvents.slackActionDelete, {
                        context: RudderStackAutomationBuilderEvents.monitorContext
                      });
                    }}
                    style={{ padding: '0px', fontFamily: 'Inter', marginTop: '16px', borderRadius: '4px', height: '40px', color: '#B42318', fontSize: '12px', fontWeight: 500, backgroundColor: '#fff', textTransform: 'none' }}
                    sx={{ height: '40px' }}
                    variant="text">
                    <img style={{ paddingRight: '8px' }} src={ActionDeleteIcon} alt='Icon' />
                    Delete</ZButton>
                </Box>
              </Box>
            </Box>
          </>
        }
        {
          !account?.accountConfig?.slackVerificationToken &&
          <LabelText>Your Slack is not connected</LabelText>
        }
        {
          !account?.accountConfig?.slackVerificationToken &&
          <>
            <ZButton
              onClick={() => {
                getSlackURL();
              }}
              style={{
                borderRadius: '4px',
                height: '36px',
                width: '100%',
                color: '#344054',
                fontSize: '14px',
                fontWeight: 600,
                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                border: '1px solid var(--Gray-300, #D0D5DD)',
                padding: '8px 14px'
              }}
              sx={{ height: '36px' }} secondary variant="contained">
              <img style={{ paddingRight: '8px' }} src={SlackActionIcon} alt='Icon' />
              Connect my Slack
            </ZButton>

            <Box width={'100%'} display={'flex'} justifyContent={'center'}>
              <ZButton
                onClick={() => {
                  onRemove(fieldsIndex);
                  recordRSEvent(RudderStackAutomationBuilderEvents.slackActionDelete, {
                    context: RudderStackAutomationBuilderEvents.monitorContext
                  });
                }}
                style={{ padding: '0px', fontFamily: 'Inter', marginTop: '16px', borderRadius: '4px', height: '40px', color: '#B42318', fontSize: '12px', fontWeight: 500, backgroundColor: '#fff', textTransform: 'none' }}
                sx={{ height: '40px' }}
                variant="text">
                <img style={{ paddingRight: '8px' }} src={ActionDeleteIcon} alt='Icon' />
                Delete</ZButton>
            </Box>
          </>
        }
      </Box>

      {/* <ConfirmationDialog
                id={'slack_configure_confirmation'}
                deletionTitle={'Are you sure you want to continue?'}
                deletionText={`You will lose all of your configured monitor data, while configuring slack.`}
                isOpen={configureSlackConfirmation}
                primaryButtonName="Yes"
                variant="primary"
                secondaryButtonName="No"
                onPrimaryBtnClick={() => {
                  getSlackURL();
                  setConfigureSlackConfirmation(false);
                }}
                onSecondaryClick={() => {
                    setConfigureSlackConfirmation(false);
                }}
                onClose={() => {
                  setConfigureSlackConfirmation(false);
                }}
            /> */}
    </>
  )
}

export default React.memo(SlackActionConfiguration);