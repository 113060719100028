import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IAuthState {
    templateId: string | null;
}

const initialState:IAuthState = {
    templateId: null
};

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setTemplateId: (state, action:PayloadAction<string|null>) => {
        state.templateId = action.payload;
    }
    
  },
  extraReducers: (builder) => {},
});

export const {
    setTemplateId
} = AuthSlice.actions;

export default AuthSlice.reducer;
