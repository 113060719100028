import axios, { AxiosResponse } from 'axios';
import HttpUtil from 'config/AxiosConfig';
import { ZorpResponse } from 'providers/data/models/ZorpResponse';
import { getConfig as Config } from "../../../config/envConfig/Config";

export class AccountService {
  public static createAccount(account: any): Promise<ZorpResponse | undefined> {
    return HttpUtil.accountInstance.post('/account', account).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static updateAccount({ accountId, body }: { accountId: string, body: any }): Promise<ZorpResponse | undefined> {
    return HttpUtil.accountInstance.patch(`/account/${accountId}`, { ...body }).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static getAccount(accountId: string): Promise<ZorpResponse | undefined> {
    return HttpUtil.accountInstance.get(`/account/${accountId}/config`).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }


  public static getAccountName(accountId: string): Promise<ZorpResponse | undefined> {
    return HttpUtil.accountInstance.get(`/account/${accountId}`).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static deleteAccount(accountId: string): Promise<ZorpResponse | undefined> {
    return HttpUtil.accountInstance.delete(`/account/${accountId}`).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static generateUnmaskedToken = (data: any): Promise<ZorpResponse | undefined> => {
    return HttpUtil.accountInstance.post(`/auth/secretkey`, data).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static getUnmaskedToken = (): Promise<ZorpResponse | undefined> => {
    return HttpUtil.accountInstance.get(`/auth/token`).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static deleteSecretKey = (keyType: string): Promise<ZorpResponse | undefined> => {
    return HttpUtil.accountInstance.delete(`/auth/secretkey/${keyType}`).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static getSecretKeys = (): Promise<ZorpResponse | undefined> => {
    return HttpUtil.accountInstance.get(`/auth/secretkey`).then((response: AxiosResponse) => {
      return response?.data as ZorpResponse;
    });
  }

  public static getCompanyLogo = (companyName: string): Promise<ZorpResponse | undefined> => {
    return HttpUtil.accountInstance.get(`/account/${companyName}/minimal`).then((response: AxiosResponse) => {
      return response?.data as ZorpResponse; 
    });
  }


  public static enableAnalytics = (accountId: string): Promise<ZorpResponse | undefined> => {
    const payload = {
      enabled: true
    };
    return HttpUtil.accountInstance.patch(`/account/${accountId}/analytics`, payload).then((response: AxiosResponse) => {
      return response?.data as ZorpResponse;
    });
  }

  public static updateAutomationData = (accountId:string, automationUIFormat:any): Promise<ZorpResponse | undefined> => {
    const updateAutomationDataUrl = (Config("services.accountSvc.api.updateAutomation") as string).replace("{accountId}", accountId);
    return HttpUtil.accountInstance.patch(updateAutomationDataUrl, automationUIFormat).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

  public static updateAccountMockdata = (accountId:string, mockData:any): Promise<ZorpResponse | undefined> => {
    const updateAccountMockDataUrl = (Config("services.accountSvc.api.updateMockData") as string).replace("{accountId}", accountId);
    return HttpUtil.accountInstance.patch(updateAccountMockDataUrl, {mockData}).then((response: AxiosResponse) => {
      return response.data as ZorpResponse;
    });
  }

}
