const ZTabPanel = (props: any) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
      className='scrollable-force-tabpanel'
    >
      {value === index && children}
    </div>
  );
};

export default ZTabPanel;
