import React from 'react'
//@ts-ignore
import fnIcon from 'assests/functionlogo.svg';
import { IScriptButton } from './IScriptButton';

const SmartInputScriptButton = (props: IScriptButton) => {
    const { handleClick, isVisible, isInline, isDisabled } = props;
    const style = isInline
      ? {
          marginLeft: '0px',
          marginTop: '0px',
          maxHeight: '31px',
          display: 'inline',
        }
      : {
          marginLeft: '0px',
          marginTop: '0px',
          maxHeight: '31px',
          // marginTop: '5px',
        };

  return (
    <>
      {isVisible && !isDisabled ? (
        <img style={style} src={fnIcon} alt='fnIcon' onClick={handleClick} className={'pointer'} />
      ) : (
        <img style={style} src={fnIcon} alt='fnIcon' className={'disabledImg'} title='No Data Selected' />
      )}
      
    </>
  );
}

export default SmartInputScriptButton

SmartInputScriptButton.defaultProps = {
  handleClick: () => {},
  isVisible: true,
  isDisabled: false,
};
