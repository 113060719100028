import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { getMiddleXAxisPoint, getNextPoint } from './edgeUtils';

import { EdgeProps, EdgeText, getBezierPath, getEdgeCenter, getMarkerEnd, useStoreState } from 'react-flow-renderer';
import { setDeleteIconOnTransition } from 'views/workflows/reducers/workflowAction';
import useWorkFlow from './hooks/useWorkFlow';
//@ts-ignore

const ZEdgeText = styled(EdgeText)`
  &:hover {
  }
`;

const ZorpTransitionEdge: FC<EdgeProps> = (edgeProps) => {
  const {
    id,
    source,
    target,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    data,
    arrowHeadType,
    markerEndId,
  } = edgeProps;
  const nodes = useStoreState((state) => state.nodes);
  // const allTransitions = useStoreState((state) => state.edges)

  const dispatch = useDispatch();

  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const { selectedTransition, deleteIconOnTransition } = useWorkFlow();
  const sourceNode = useMemo(() => nodes.find((n) => n.id === source), [source, nodes]);
  const targetNode = useMemo(() => nodes.find((n) => n.id === target), [target, nodes]);

  if (!sourceNode || !targetNode) {
    return null;
  }

  // // If this function return true, we show an edge with different path, so that it doesn't overlap with the node or another transition
  // const showCurvedEdge = () => {
  //   let checkIfThisAnReverseEdge = false
  //   allTransitions.forEach((transition) => {
  //     if(transition.source === target && transition.target === source){
  //       checkIfThisAnReverseEdge = true
  //     }
  //   })

  //   return checkIfThisAnReverseEdge
  // }

  let edgePath = ""
  if(source !== target){
    edgePath = getBezierPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetPosition,
      targetX,
      targetY,
    });
  } else {
    const radiusX = (sourceX - targetX) * 0.7;
    const radiusY = 150;
    edgePath = `M ${sourceX} ${sourceY} A ${radiusX} ${radiusY} 0 1 0 ${targetX} ${targetY}`;
  }

  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

  let hasError = data?.errors?.length > 0 ? true : false;
  data?.actions?.forEach((item: any) => {
    if (item?.errors?.length > 0) {
      hasError = true;
    }
  });

  data?.guards?.forEach((item: any) => {
    if (item?.errors?.length > 0) {
      hasError = true;
    }
  });

  const onHover = (event: any, type: string) => {
    let centres = getMiddleXAxisPoint(event.pageX, event.pageY);
    if (type === 'enter') {
      dispatch(
        setDeleteIconOnTransition({
          posX: Math.trunc(centres.xCenter - 100),
          posY: Math.trunc(centres.yUp - 150),
          transition: edgeProps,
          onStrip: false,
        })
      );
    } else {
      const { left, right, top, bottom } = getNextPoint(event.pageX, event.pageY);
      if (left || right || top || bottom) {
        dispatch(
          setDeleteIconOnTransition({
            posX: null,
            posY: null,
            transition: {},
            onStrip: false,
          })
        );
      }
    }
  };

  // TODO: Add the edge info to redux on mouse hover for deleting and selecting the transition.

  const onEdgeClick = (evt: any, id: any) => {
    evt.stopPropagation();
    alert(`remove ${id}`);
  };

  return (
    <>
      <path
        style={{
          strokeWidth: '3px',
          stroke: `${data?.errors?.length > 0 ? '#FF2D55' : '#999999'}`,
        }}
        id={id}
        className='react-flow__edge-path'
        d={edgePath}
        markerEnd={markerEnd}
      />
      {sourceNode.type !== 'zorpDecisionNode' && (
        <>
          <ZEdgeText
            x={source === target ? 
              edgeCenterX + 120
              : edgeCenterX}
            y={source === target ? edgeCenterY - 120 : edgeCenterY}
            // label={data?.errors?.length > 0 ? "Error" : data.event}
            // label={hasError ? "Error" : data.event}
            label={data.event === '' ? 'Error' : data.event}
            labelStyle={{
              fontSize: 12,
              fill: '#fff',
              padding: '10px 15px 10px 15px',
              borderRadius: '0',
            }}
            labelShowBg
            labelBgStyle={{
              // fill: data?.errors?.length > 0 ? "red" : '#3E3E3E',
              fill: hasError ? '#FF2D55' : selectedTransition?.data?.id === data.id ? '#007AFF' : '#3E3E3E',
              color: '#fff',
              fillOpacity: 0.9,
            }}
            labelBgPadding={[15, 8]}
            labelBgBorderRadius={8}
            onMouseEnter={(e) => onHover(e, 'enter')}
            onMouseLeave={(e) => onHover(e, 'leave')}
          />
        </>
      )}
    </>
  );
};

export default ZorpTransitionEdge;
