import { ActionToPerform } from "constants/CommonConstants";
import { cloneDeep } from "lodash";
import { ZJSEditorLayout } from "lowcode/ZJSEditorLayout";
import { useEffect, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { getAccountId } from "utils/CommonUtils";
import useAutomation from "views/automationBuilder/hooks/useAutomation";
import { Heading } from "views/DataField/DataField.styles";
import useWorkFlow from "views/workflows/hooks/useWorkFlow";
import { setSelectedAction } from "views/workflows/reducers/workflowAction";
import { guardTypes } from "views/workflows/workflowactions/api/guard.types";
import {
  generateTempId,
  getActionNameForTempId,
} from "views/workflows/workflowactions/api/utils/utils";
import { HeadingWrapper } from "views/workflows/workflowactions/ModalContent.styles";
import {
  ABText,
  SearchInput,
  ActionTypeHeading,
} from "../automationBuilder.styles";
import { setSelectedConfiguration } from "../reducer/automationReducer";
import {
  ABMetadataTypeEnum,
  actionType,
  conditionTypes,
} from "../types/ABType";
import { updateMockDataValue } from "../utils/util";
import CronTrigger from "./CronTrigger";
import ListCard from "./ListCard";
import SelectTrigger from "./SelectTrigger";
import {
  ZorpTaskActionIds,
  ZorpTableActionIds,
} from "@zorp/common-libs-js/dist/common/types/types";
import { WebhookTrigger } from "./WebhookTrigger";

type IShowActionOrConditionSelectionDropdown = {
  type: "action" | "condition" | "guard" | "trigger";
};

// create a search component that takes in state, setState and array as props and returns a search input field
const SearchComponent = ({ state, setState }: any) => {
  return (
    <SearchInput
      type="text"
      placeholder="Search"
      value={state}
      onChange={(e) => setState(e.target.value)}
    />
  );
};

const ShowActionOrConditionSelectionDropdown = ({
  type,
}: IShowActionOrConditionSelectionDropdown) => {
  const store = useStore();
  const accountId = getAccountId();
  const { mockData } = useSelector((state: any) => state.scriptEditor);
  const [guardMenu, setGuardMenu] = useState<any[]>([]);
  const [actionMenuItems, setActionMenuItems] = useState<any[]>([]);
  const [conditionMenuItems, setConditionMenuItems] = useState<any[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const dispatch = useDispatch();
  const { selectedEntity } = store?.getState()?.entity;
  const { actions, conditions, guards, metadataType, selectedAutomation } =
    useAutomation();

  const { taskTypeId } = useWorkFlow();
  const { scriptEditorOpen } = useSelector(
    (state: any) => state.scriptEditorOpen
  );

  const add = async (
    action: any,
    type: "action" | "guard" | "condition" | actionType
  ) => {
    const metaDataValue = cloneDeep(action);
    // convert enum to array
    const zorpActions = [
      ...Object.values(ZorpTaskActionIds),
      ...Object.values(ZorpTableActionIds),
    ];

    if (type === "action") {
      const actionId = generateTempId(
        metaDataValue,
        selectedAutomation,
        getActionNameForTempId(metaDataValue),
        type
      );
      metaDataValue.actionId = actionId;
      metaDataValue.errors = [];
      if (
        metaDataValue.actionType == actionType.EXPRESSION ||
        metaDataValue.actionType == actionType.JS_FUNCTION_ACTION
      ) {
        updateMockDataOnRunScript(metaDataValue.actionId);
      } else if (zorpActions.includes(metaDataValue.actionType)) {
        updateMockDataForZorpActions(metaDataValue.actionId);
      }
      dispatch(
        setSelectedAction({
          ...metaDataValue,
          hasChanged: false,
          actionToPerform: ActionToPerform.CREATE,
        })
      );
    } else if (type === "guard") {
      const actionId = generateTempId(
        metaDataValue,
        selectedAutomation,
        getActionNameForTempId(metaDataValue),
        type
      );
      metaDataValue.guardId = actionId;
      metaDataValue.errors = [];
      if (metaDataValue.actionType === guardTypes.LOCATION_CHECK) {
        metaDataValue.validationType = "HAVERSINE";
      }

      if (metaDataValue.guardType == guardTypes.API_GUARD) {
        updateMockDataOnRunScript(actionId);
      }

      dispatch(
        setSelectedAction({
          ...metaDataValue,
          hasChanged: false,
          actionToPerform: ActionToPerform.CREATE,
        })
      );
    } else if (type == "condition") {
      metaDataValue.conditionId = generateTempId(
        metaDataValue,
        selectedAutomation,
        getActionNameForTempId(metaDataValue),
        type
      );
      metaDataValue.errors = [];

      if (metaDataValue.conditionType == conditionTypes.EXPRESSION) {
        updateMockDataOnRunScript(metaDataValue.conditionId);
      }

      dispatch(
        setSelectedAction({
          ...metaDataValue,
          hasChanged: false,
          actionToPerform: ActionToPerform.CREATE,
        })
      );
    }

    dispatch(setSelectedConfiguration({ type: null }));
  };

  const updateMockDataOnRunScript = async (id: string) => {
    const mockDataPayload = cloneDeep(mockData);
    mockDataPayload[`response_${id}`] = {};
    await updateMockDataValue(store, dispatch, mockDataPayload);
  };

  const updateMockDataForZorpActions = async (id: string) => {
    const mockDataPayload = cloneDeep(mockData);
    mockDataPayload[`response_${id}`] = {
      headers: {},
      status: "",
      body: {},
    };
    await updateMockDataValue(store, dispatch, mockDataPayload);
  };

  // when search text changes, filter the actions, guards and conditions based on the search text
  useEffect(() => {
    console.log("searchText", actions);
    const actionMenus = actions
      .filter(
        (action: any) =>
          searchText.trim() === "" ||
          action?.actionName?.toLowerCase()?.includes(searchText.toLowerCase()) ||
          action?.description?.toLowerCase()?.includes(searchText.toLowerCase())
      )
      .map((action: any) => {
        return {
          label: action.actionName,
          value: action.actiontype,
          data: action,
        };
      });

    const guardMenus = guards
      ?.filter(
        (guard: any) =>
          searchText.trim() === "" ||
          guard?.guardName?.toLowerCase()?.includes(searchText.toLowerCase())
      )
      .map((guard: any) => {
        return {
          label: guard.guardName,
          value: guard.guardType,
          data: guard,
        };
      });

    const conditionMenu = conditions
      ?.filter(
        (condition: any) =>
          searchText.trim() === "" ||
          condition?.conditionName
            ?.toLowerCase()
            ?.includes(searchText.toLowerCase())
      )
      .map((condition: any) => {
        return {
          label: condition.conditionName,
          value: condition.conditionType,
          data: condition,
        };
      });

    setGuardMenu(guardMenus);
    setActionMenuItems(actionMenus);
    setConditionMenuItems(conditionMenu);
  }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const actionMenus = actions.map((action: any) => {
      return {
        label: action.actionName,
        value: action.actiontype,
        data: action,
      };
    });

    const guardMenus = guards?.map((guard: any) => {
      return {
        label: guard.guardName,
        value: guard.guardType,
        data: guard,
      };
    });

    const conditionMenu = conditions?.map((condition: any) => {
      return {
        label: condition.conditionName,
        value: condition.conditionType,
        data: condition,
      };
    });

    setGuardMenu(guardMenus);
    setActionMenuItems(actionMenus);
    setConditionMenuItems(conditionMenu);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {type == "trigger" &&
        metadataType != ABMetadataTypeEnum.WORKFLOW_SCREEN_SMART_COMPONENT &&
        metadataType != ABMetadataTypeEnum.CRON &&
        metadataType != ABMetadataTypeEnum.WEBHOOK && (
          <HeadingWrapper>
            <Heading> Start by Selecting {type} </Heading>
          </HeadingWrapper>
        )}

      {type === "action" && (
        <>
          <Heading> Actions </Heading>
          <div style={{ marginBottom: "8px" }}></div>
          <ABText>
            {" "}
            Configure the actions that need to be executed once the automation
            is triggered and the condition is true.
          </ABText>
          <div style={{ marginBottom: "12px" }}></div>
          <SearchComponent state={searchText} setState={setSearchText} />
          <div style={{ marginBottom: "12px" }}></div>
          <ActionTypeHeading> Tables </ActionTypeHeading>
          {actionMenuItems
            .filter((data) => data.data.type === "TABLE")
            .map((data) => (
              <ListCard
                event={data}
                clickList={() => {
                  add(data.data, type);
                }}
              />
            ))}
          <ActionTypeHeading> Tasks </ActionTypeHeading>
          {actionMenuItems
            .filter((data) => data.data.type === "TASK")
            .map((data) => (
              <ListCard
                event={data}
                clickList={() => {
                  add(data.data, type);
                }}
              />
            ))}
          <ActionTypeHeading> Other Actions </ActionTypeHeading>
          {actionMenuItems
            .filter((data) => data.data.type === undefined)
            .map((data) => (
              <ListCard
                event={data}
                clickList={() => {
                  add(data.data, type);
                }}
              />
            ))}
          {/* {actionMenuItems.map((data) => (
            <ListCard
              event={data}
              clickList={() => {
                add(data.data, type);
              }}
            />
          ))} */}
        </>
      )}

      {type == "guard" && (
        <>
          <Heading> Validations </Heading>
          <ABText>
            Validations are like checkpoints for automation. They make sure
            everything's ready before moving to the next step
          </ABText>
          <SearchComponent state={searchText} setState={setSearchText} />
          <div style={{ marginBottom: "12px" }}></div>
          {guardMenu.map((data) => (
            <ListCard
              event={data}
              clickList={() => {
                add(data.data, type);
              }}
            />
          ))}
        </>
      )}

      {type == "condition" && (
        <>
          <Heading> Conditions </Heading>
          <ABText>
            Conditions are checks that need to pass to allow the automation to
            proceed after the automation is triggered
          </ABText>
          <SearchComponent state={searchText} setState={setSearchText} />
          <div style={{ marginBottom: "12px" }}></div>
          {conditionMenuItems.map((data) => (
            <ListCard
              event={data}
              clickList={() => {
                add(data?.data, type);
              }}
            />
          ))}
        </>
      )}

      {type == "trigger" &&
        metadataType != ABMetadataTypeEnum.WORKFLOW_SCREEN_SMART_COMPONENT &&
        metadataType != ABMetadataTypeEnum.CRON &&
        metadataType != ABMetadataTypeEnum.WEBHOOK && (
          <>
            <ABText>
              Triggers are events that occur to let the user proceed to the next
              step. You can use triggers to initiate an automation.
            </ABText>
            <div style={{ marginBottom: "8px" }}></div>
            <Heading> Triggers </Heading>
            <div style={{ marginBottom: "8px" }}></div>
          </>
        )}

      {type == "trigger" && metadataType == ABMetadataTypeEnum.CRON ? (
        <CronTrigger />
      ) : type == "trigger" && metadataType == ABMetadataTypeEnum.WEBHOOK ? (
        <WebhookTrigger />
      ) : type == "trigger" ? (
        <SelectTrigger />
      ) : (
        <></>
      )}

      {scriptEditorOpen && <ZJSEditorLayout />}
    </>
  );
};

export default ShowActionOrConditionSelectionDropdown;
