import Select from "react-select";
import React, { FunctionComponent, useEffect } from "react";
import { IZComponentPropType } from "render-engine/models/ITaskTypeWebBodyRenderModel";
import { TaskFormLabelForCreateAndEdit } from "render-engine/render.styles";
import ZSelect from "views/commonComponents/ZSelect";
import { ZDropdown } from "views/commonComponents/commonComponents.styles";
import { Controller } from "react-hook-form";
import { EntityService } from "providers/data/services/EntityService";
import notification from "notifications/notifications";
import { isArray, union } from "lodash";
import { IRelationReferringEntityType } from "views/entities/types";
import { UserService } from "providers/data/services/UserService";
import { InfoTooltip } from "views/workflows/Tootltips/InfoTooltip";
import InfoIcon from "views/workflows/Info/InfoIcon";
import { RelationCreateOrEdit } from "views/workflows/TooltipContent/TooltipContent";
import ErrorMessage from "views/DataField/ErrorMessage";
import { AiOutlineWarning } from "react-icons/ai";
import useTaskShow from "views/workflows/hooks/useTaskShow";
import { useDispatch } from "react-redux";
import {
  setInEditState,
  setTaskData,
} from "render-engine/reducers/show/TaskShowReducer";
import { AsyncPaginate } from "react-select-async-paginate";
import { TaskService } from "views/tasks/services/TaskService";
import { TeamService } from "providers/data/services/TeamService";

const ZRelation: FunctionComponent<IZComponentPropType> = ({
  config,
  onChange,
  register,
  errors,
  setValue,
  control,
  getValues,
}) => {
  const customStyles = {
    container: (provided) => ({ ...provided, width: "100%" }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#101828",
        fontSize: "12px",
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        border: "1px solid #D0D5DD",
        borderRadius: "6px",
        backgroundColor: "none", // change background color of the pill
      };
    },
    multiValueLabel: (styles) => {
      return {
        ...styles,
        color: "#101828", // change text color inside the pill
      };
    },
  };
  const [options, setOptions] = React.useState<any[] | null>(null);
  const [defaultValues, setDefaultValues] = React.useState<any[]>([]);
  const { convertDataTypeToString } = useTaskShow();
  const dispatch = useDispatch();
  const value = convertDataTypeToString(config.key, false, []);
  const fetchRecordFromTableType = async ({
    page,
    pageSize,
    currentValue,
    searchText = "",
  }: {
    page: number;
    pageSize: number;
    currentValue: string[];
    searchText?: string;
  }) => {
    try {
      const res = await EntityService.getRelationRecords({
        page: page,
        pageSize: pageSize,
        currentValue,
        tableType: config.relationDetails.referringEntityType,
        searchText,
      });
      console.log(">>>", res);

      if (res.data.records.length === 0) {
        const displayColumn = res.data.displayColumn;
        const allOptions = res.data.records.map((data: any) => {
          return {
            label: data[displayColumn],
            value: data.recordId,
          };
        });

        // def value currently applies only in case of edit.
        let defVal = [];
        // if it is multiselect then default value should array else it should be an object
        if (config?.isMultiselect) {
          defVal = isArray(config?.value)
            ? config?.value?.map((val: any) => {
                return {
                  label:
                    res.data.currentValue.find(
                      (data: any) => data.recordId === val
                    )?.[displayColumn] || val,
                  value: val,
                };
              })
            : [];
        } else {
          // if there is no selected value, we will get an empty array. if its empty assign {} to default value
          defVal =
            config?.value?.length > 0
              ? {
                  // response only contains the array of recordId. so from record id we fetch record details and refer to the display column
                  //@ts-ignore
                  label:
                    res?.data?.currentValue.find(
                      (data: any) => data.recordId === config?.value?.[0]
                    )?.[displayColumn] || config?.value?.[0],
                  value: config?.value?.[0],
                }
              : {};
        }

        console.log(">>>", defVal);

        setDefaultValues(defVal);
        setOptions(allOptions || []);

        return {
          result: allOptions,
          hasMore: res?.data?.count > (page + 1) * pageSize,
        };
      }
    } catch (error) {
      console.log(error);
      notification("error", "Something Went Worng");
    }
  };

  const fetchTaskFromTaskType = async () => {
    try {
      const res = await TaskService.getTasksFromTaskType({
        taskType: config.relationDetails.referringEntityType,
      });
      const allOptions = res.data.tasks.map((data: any) => {
        return {
          label: data.taskId,
          value: data.taskId,
        };
      });
      // def value currently applies only in case of edit.
      let defVal = [];
      // if it is multiselect then default value should array else it should be an object
      if (config?.isMultiselect) {
        defVal = isArray(config?.value)
          ? config?.value?.map((val: any) => {
              return {
                label: val,
                value: val,
              };
            })
          : [];
      } else {
        // if there is no selected value, we will get an empty array. if its empty assign {} to default value
        defVal =
          config?.value?.length > 0
            ? {
                // response only contains the array of recordId. so from record id we fetch record details and refer to the display column
                //@ts-ignore
                label: config?.value?.[0],
                value: config?.value?.[0],
              }
            : {};
      }

      setDefaultValues(defVal);
      setOptions(allOptions);
    } catch (error) {
      notification("error", "Something Went Worng");
    }
  };

  const fetchAllUser = async () => {
    try {
      const users = await EntityService.getUsersForAccount(0, 3000, undefined);
      const allOptions = users?.data.users.map((data: any) => {
        return {
          label: data.name,
          value: data.userId,
        };
      });

      const allDefVal: any[] = union(config.val || [], value || []);

      // def value currently applies only in case of edit.
      let defVal = [];
      // if it is multiselect then default value should array else it should be an object
      if (config?.isMultiselect) {
        defVal = isArray(allDefVal)
          ? allDefVal?.map((val: any) => {
              return {
                label: users?.data.users.find(
                  (user: any) => user.userId === val
                )?.name,
                value: val,
              };
            })
          : [];
      } else {
        // if there is no selected value, we will get an empty array. if its empty assign {} to default value
        defVal =
          allDefVal?.length > 0
            ? {
                // response only contains the array of recordId. so from record id we fetch record details and refer to the display column
                //@ts-ignore
                label: users?.data.users.find(
                  (user: any) => user.userId === allDefVal?.[0]
                )?.name,
                value: allDefVal?.[0],
              }
            : {};
      }
      setDefaultValues(defVal);
      setOptions(allOptions);
    } catch (error) {
      console.log(error);
      notification("error", "Error while fetching users");
    }
  };

  const fetchAllTeams = async () => {
    try {
      const teams = await TeamService.listTeams({
        pagination: {
          page: 1,
          perPage: 100,
        },
        filter: {},
      });
      const allOptions = teams?.data.teams.map((data: any) => {
        return {
          label: data.name,
          value: data.teamId,
        };
      });

      const allDefVal: any[] = union(config.val || [], value || []);

      // def value currently applies only in case of edit.
      let defVal = [];
      // if it is multiselect then default value should array else it should be an object
      if (config?.isMultiselect) {
        defVal = isArray(allDefVal)
          ? allDefVal?.map((val: any) => {
              return {
                label: teams?.data.teams.find(
                  (team: any) => team.teamId === val
                )?.name,
                value: val,
              };
            })
          : [];
      } else {
        // if there is no selected value, we will get an empty array. if its empty assign {} to default value
        defVal =
          allDefVal?.length > 0
            ? {
                // response only contains the array of recordId. so from record id we fetch record details and refer to the display column
                //@ts-ignore
                label: teams?.data.teams.find(
                  (team: any) => team.teamId === allDefVal?.[0]
                )?.name,
                value: allDefVal?.[0],
              }
            : {};
      }
      setDefaultValues(defVal);
      setOptions(allOptions);
    } catch (error) {
      console.log(error);
      notification("error", "Error while fetching teams");
    }
  
  }

  useEffect(() => {
    if (
      config.relationDetails.referringEntity ===
      IRelationReferringEntityType.TASK
    ) {
      fetchTaskFromTaskType();
    } else if (
      config.relationDetails.referringEntity ===
      IRelationReferringEntityType.CUSTOM
    ) {
      fetchRecordFromTableType({
        page: 0,
        pageSize: 10,
        currentValue: config.value,
      });
    } else if (
      config.relationDetails.referringEntity ===
      IRelationReferringEntityType.USER
    ) {
      fetchAllUser();
    } else if (
      config.relationDetails.referringEntity ===
      IRelationReferringEntityType.TEAM
    ) {
      fetchAllTeams();
    }
    
    const newConfig = { ...config };
    if (!isArray(newConfig.value)) {
      newConfig.value = [];
    }
    setValue(config.key, newConfig?.value);
  }, []);

  const handleBlur = () => {
    console.log("handleBlur", getValues());
    const curValue = getValues();
    dispatch(setInEditState({ nodeId: config.key, inEditState: false }));
    // KEY CAN BE location.address or location.latitude or location.longitude
    // GET THE MAIN KEY AND SET THE VALUE
    const mainKey = config.key.split(".")[0];
    dispatch(setTaskData({ nodeId: mainKey, data: curValue[`${mainKey}`] }));
    // dispatch(
    //   setTaskData({ nodeId: config.key, data: curValue[`${config.key}`] })
    // )
  };

  //@ts-ignore
  async function loadOptions(search, loadedOptions, { page }) {
    console.log(">>>> page", page, search);
    let response = { result: [], hasMore: false };
    if (
      config.relationDetails.referringEntity ===
      IRelationReferringEntityType.CUSTOM
    ) {
      response = await fetchRecordFromTableType({
        page: page,
        pageSize: 10,
        currentValue: config.value,
        searchText: search,
      });
    }

    return {
      options: response.result,
      hasMore: response.hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  const filterOptions = async (
    candidate: { label: string; value: string; data: any },
    input: string
  ) => {
    if (input) {
      console.log(">>>>", input);
      const options = await fetchRecordFromTableType({
        page: 0,
        pageSize: 10,
        currentValue: config.value,
        searchText: input,
      });
      return options;
    }
    return true;
  };

  return (
    <>
      <div className="col-span-4">
        <TaskFormLabelForCreateAndEdit
          fontSize={config?.label?.fontSize ? config.label.fontSize : 14}
          color={config?.label?.color ? config.label.color : "black"}
          className="col-span-1 font-medium"
          style={{ padding: "20px 0px 4px 0px" }}
        >
          {config?.label?.val}
          {config?.isRequired && <span className="text-red-500">*</span>}
          {/* <InfoIcon
                isCollectLocation={false}
                item={RelationCreateOrEdit}
                type={"info"}
              /> */}
        </TaskFormLabelForCreateAndEdit>
        {options &&
          (config.relationDetails.referringEntity ===
          IRelationReferringEntityType.CUSTOM ? (
            <Controller
              name={config?.key}
              control={control}
              rules={{
                required: config?.isRequired ? config.isRequired : false,
              }}
              render={({ field: { onChange: fieldOnChange, value } }) => (
                <AsyncPaginate
                  loadOptions={loadOptions}
                  defaultOptions={options || []}
                  // onChange={setValue}
                  onChange={(selectedOption: any) => {
                    // if multiple select is not allowed, then send only the value (as the selectedOption is an object)
                    let val = [];
                    if (!config.isMultiselect) {
                      val = [selectedOption.value];
                    } else {
                      val = selectedOption.map((option: any) => option.value);
                    }

                    fieldOnChange(val);
                    onChange(config.key, val);
                  }}
                  isMulti={config.isMultiselect}
                  additional={{
                    page: 0,
                  }}
                  defaultValue={defaultValues}
                  isSearchable={true}
                />
              )}
            />
          ) : (
            <Controller
              name={config?.key}
              control={control}
              rules={{
                required: config?.isRequired
                  ? `${config.key} is required`
                  : false,
              }}
              render={({ field: { onChange: fieldOnChange, value } }) => (
                <Select
                  styles={customStyles}
                  // onBlur={handleBlur}
                  defaultValue={defaultValues}
                  isMulti={config.isMultiselect}
                  // isClearable
                  isSearchable={true}
                  className="react-dropdown"
                  classNamePrefix="dropdown"
                  onChange={(selectedOption: any) => {
                    // if multiple select is not allowed, then send only the value (as the selectedOption is an object)
                    let val = [];
                    if (!config.isMultiselect) {
                      val = [selectedOption.value];
                    } else {
                      val = selectedOption.map((option: any) => option.value);
                    }

                    fieldOnChange(val);
                    onChange(config.key, val);
                    dispatch(setTaskData({ nodeId: config.key, data: val }));
                  }}
                  options={options}
                />
              )}
            />
          ))}

        {errors[config.key]?.message?.length > 0 && (
          <div className="font-semibold text-xs text-red-500 mt-1">
            <AiOutlineWarning
              size={"1rem"}
              color="red"
              style={{ display: "inline", marginRight: "4px" }}
            />{" "}
            {errors[config.key]?.message}
          </div>
        )}
      </div>
    </>
  );
};

export default ZRelation;
