//@ts-nocheck
import { useRef } from 'react';
import useWidth from "hooks/useWidth";
import { FiFilter, FiX } from "react-icons/fi";
import styled from 'styled-components';
import { ZIconButton } from 'views/commonComponents/commonComponents.styles';
import { ZFilterButton, ZFilterIcon } from 'views/layouts/Layout.styles';
import filter from "assests/filter.svg";
import { ZIconButton2 } from 'views/entities/styles';

const Button = styled.button`
  background-color: #E9EEFE;
  border-radius: 4px;
  color:  #254EC3;
  border: 1.5px solid #254EC3;
  border-style: solid;
  font-weight: 600;
  border-color:#254EC3;
  font-size: 12px;
  line-height: 16px;
  display: flex;
`;

const DotDiv = styled.div`
  height: 25px;
  width: 25px;
  background-color: #254EC3;
  border-radius: 50%;
  color:white;
  display:flex;
  justify-content:center;
  align-items:center;
  align-content:center;
`

interface IFilterButton {
    filterCount: number;
    onRemoveFilterClick: () => void;
    onOpenFilterClick: (position:number[]) => void;
}


const FilterButton = ({ filterCount = 0, onRemoveFilterClick, onOpenFilterClick }: IFilterButton) => {
    const large = useWidth();
    const filterCTAPositionRef = useRef();

    return (
        <>
            {
                filterCount == 0 &&
                (

                    large ?
                    (

                        <ZFilterButton
                            ref={filterCTAPositionRef}
                            size='medium'
                            style={{
                                border: "1px solid #c2c9d1",
                                color: "#344054",
                                fontSize: "1rem",
                                fontWeight: "600",
                                backgroundColor: "#ffffff",
                                marginRight: "8px",
                                padding: "6px 16px",
                                borderRadius:"4px"
                            }}
                            color='primary'
                            onClick={() => {
                                onOpenFilterClick(filterCTAPositionRef)
                            }}
                            startIcon={<FiFilter
                                size={"1rem"}
                                style={{ background: "none" }}
                                className="svg_icons_l"
                            />}
                        >
                            Filter
                        </ZFilterButton>


                    ) : (
                        <ZIconButton2
                            ref={filterCTAPositionRef}
                            
                            onClick={() => onOpenFilterClick(filterCTAPositionRef)}
                            style={{
                                backgroundColor: "#F2F2F2",
                                marginRight: 0,
                            }}>
                            <ZFilterIcon className="svg_icons_l" />
                        </ZIconButton2>
                    )
                    )

            }

            {
                filterCount > 0 &&
                <Button 
                style={{
                    marginRight: "12px"
                }}
                ref={filterCTAPositionRef}
                onClick={() => {
                    onOpenFilterClick(filterCTAPositionRef)
                }}>
                    <span onClick={(e) => {
                        e.stopPropagation();
                        onRemoveFilterClick();
                    }} style={{ marginLeft: '8px', paddingTop: '10px', paddingRight: '8px' }}>
                        {' '}
                        <FiX size={'1.4rem'} />{' '}
                    </span>
                    <div style={{ height: '36px', width: '0px', backgroundColor: 'white' }} className='ajscnajs'></div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <DotDiv className="dot">{filterCount}</DotDiv>
                        <span style={{ padding: '10px 16px' }}> Filters Applied</span>
                    </div>
                </Button>
            }

        </>
    )
}

export default FilterButton